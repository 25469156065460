import { config } from 'app-config'
import { actions_bool_exp, actions_order_by } from 'graphql/chaingraph'
import { BankHistoryFilterProps, HistoryFilterProps } from './bitcash-onchain.types'

export const getExchangeHistoryFilter = ({
  account,
  orderBy = 'desc',
}: HistoryFilterProps): {
  where?: actions_bool_exp
  order_by?: actions_order_by[]
  limit?: number
  offset?: number
} => ({
  where: {
    chain: {
      _eq: config.eosChainName,
    },
    contract: {
      _eq: config.contracts.bitcashBank,
    },
    action: {
      _in: ['fxtrdlog'],
    },
    data: {
      _contains: {
        account,
      },
    },
  },
  order_by: [{ global_sequence: orderBy }],
  limit: 200,
})

function getExchangeHistory(props: HistoryFilterProps) {
  return {
    actions: {
      __args: getExchangeHistoryFilter(props),
      data: true,
      transaction_id: true,
    },
  }
}

function getBankTransactionHistory(props: BankHistoryFilterProps) {
  return {
    actions: {
      __args: props,
      action: true,
      data: true,
      transaction_id: true,
    },
  }
}

export const onChainHistoryService = {
  getExchangeHistory,
  getBankTransactionHistory,
}
