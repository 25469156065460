import * as React from 'react'
import { SVGProps } from 'react'
const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 12 12"
    {...props}
  >
    <path fill="#000" d="M12 5.478H6.522V0H5.478v5.478H0v1.044h5.478V12h1.044V6.522H12V5.478Z" />
  </svg>
)
export default PlusIcon
