// profile.ts

export default {
  heading: 'Tùy chọn Hồ sơ',
  payment_methods: 'Phương thức thanh toán',
  profile_information: 'Thông tin Hồ sơ',
  phone_number: 'Số điện thoại',
  email: 'Địa chỉ Email',
  newsletter: 'Đăng ký nhận bản tin',
  phone_number_placeholder: '{{phoneNumberCountryCode}}số điện thoại#',
  include_country_code: 'Bao gồm mã quốc gia',
  phone_valid: 'Số điện thoại hợp lệ!',
  delete_payment_option_warning: 'Bạn có chắc chắn muốn xóa phương thức thanh toán này?',
  delete: 'Xóa',
  cancel: 'Hủy',
  no_methods: 'Không có Phương thức thanh toán trước đó được thêm vào',
  save: 'Lưu thay đổi',
  revert: 'Hủy bỏ',
  confirm: 'Xác nhận',
  select_method: `Chọn phương thức thanh toán
	mà bạn muốn lưu.`,
  profile_update_success: 'Hô sơ được cập nhật thành công',
  verification_status: 'Trạng thái:',
}
