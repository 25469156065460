import { StoreSlice } from 'app-engine/store'

type P2POpenOfferFilterState = {
  p2pOpenOfferFilterType: string
  isMultipleCurrency: boolean
  currencies: string[]
  paymentMethods: string[]
  prices: string[]
  isApplied: boolean
  p2pOpenFilterError: string
}

export type P2POpenOfferFilterSlice = P2POpenOfferFilterState & {
  setCurrencies: (currencies: string[]) => void
  setPaymentMethod: (paymentMethods: string[]) => void
  setPrices: (prices: string[]) => void
  toggleIsMultipleCurrency: () => void
  resetP2POpenOfferFilter: () => void
  restoreFilter: () => void
  updateLocalStorage: () => void
  setApplied: () => void
  setOpenOfferFilterType: (type: string) => void
  setP2POpenFilterError: (error: string) => void
  reset: () => void
}

const defaultP2POpenOfferFilterState = {
  isMultipleCurrency: false,
  currencies: [],
  paymentMethods: [],
  prices: [],
  p2pOpenOfferFilterType: 'public',
  isApplied: false,
  p2pOpenFilterError: '',
}

export const createP2POpenOfferFilterSlice: StoreSlice<P2POpenOfferFilterSlice> = (set, get) => ({
  ...defaultP2POpenOfferFilterState,
  resetP2POpenOfferFilter: () => {
    set({ ...defaultP2POpenOfferFilterState, p2pOpenOfferFilterType: get().p2pOpenOfferFilterType })
    localStorage.setItem(
      `p2pOfferFilter_${get().p2pOpenOfferFilterType}`,
      JSON.stringify({
        ...defaultP2POpenOfferFilterState,
        p2pOpenOfferFilterType: get().p2pOpenOfferFilterType,
      }),
    )
  },
  setCurrencies: (currencies) => {
    set({ currencies })
  },
  setPaymentMethod: (paymentMethods) => {
    set({ paymentMethods })
  },
  setPrices: (prices) => {
    set({ prices })
  },
  toggleIsMultipleCurrency: () => {
    const { isMultipleCurrency } = get()
    set({ isMultipleCurrency: !isMultipleCurrency })
  },
  restoreFilter: () => {
    const filterString = localStorage.getItem(`p2pOfferFilter_${get().p2pOpenOfferFilterType}`)
    let filter = {
      ...defaultP2POpenOfferFilterState,
      p2pOpenOfferFilterType: get().p2pOpenOfferFilterType,
    }
    if (filterString) {
      filter = JSON.parse(filterString)
    }
    set(filter)
  },
  updateLocalStorage: () => {
    localStorage.setItem(
      `p2pOfferFilter_${get().p2pOpenOfferFilterType}`,
      JSON.stringify({
        isApplied: get().isApplied,
        isMultipleCurrency: get().isMultipleCurrency,
        currencies: get().currencies,
        paymentMethods: get().paymentMethods,
        prices: get().prices,
        p2pOpenOfferFilterType: get().p2pOpenOfferFilterType,
      }),
    )
  },
  setOpenOfferFilterType: (type) => {
    set({ p2pOpenOfferFilterType: type })
    get().restoreFilter()
  },
  setApplied: () => {
    set({ isApplied: true })
    get().updateLocalStorage()
  },
  setP2POpenFilterError: (error: string) => {
    set({ p2pOpenFilterError: error })
  },
  reset: () => {
    get().resetP2POpenOfferFilter()
  },
})
