export default {
  notifications: 'Notifikasi',
  all: 'Semua',
  unread: 'Belum Dibaca',
  marked_read: 'Tandai Sebagai Dibaca',
  remove: 'Hapus',
  completed: 'selesai!',
  cancelled: 'dibatalkan',
  coin_status:
    '<0>Pesanan {{order_type}} {{asset}} Anda</0> telah {{status}}. Silakan cek rincian di pesanan.',
  coin_message: '<0>Pesanan {{order_type}} {{asset}} Anda</0> memiliki pesan baru.',
  coin_title: 'Aplikasi Bitcash | Pesanan Koin',
  p2p_title: 'Aplikasi Bitcash | Tawaran P2P',
  coin_message_not: 'Anda memiliki pesan baru pada pesanan koin Anda',
  coin_update: 'Anda memiliki pembaruan pada pesanan koin Anda.',
  p2p_not_message: 'Anda memiliki tawaran p2p baru yang terbuka! Klik untuk melihat lebih banyak.',
  p2p_status: 'Tawaran {{order_type}} Anda telah {{status}}! Klik untuk melihat lebih banyak.',
  p2p_message: 'Tawaran {{order_type}} Anda memiliki pesan baru. Klik untuk melihat lebih banyak.',
  p2p_trust_message:
    'Pengguna {{from}} telah mengirimkan Anda pesan. Klik untuk melihat lebih banyak.',
}
