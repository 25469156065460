import React from 'react'

const PlusFillIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" rx="12" fill="#D8D8D8" />
    <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill="white" />
  </svg>
)

export default PlusFillIcon
