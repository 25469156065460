import React, { useEffect } from 'react'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { HintError, Input, InputSection, InputWrapper } from 'app-view/components/InputField'
import { ButtonWrapper, ScrollableFlexBoxArea, Text } from 'app-view/components/Styled'
import { Label } from 'pages/AccountView/components/InputField'
import { LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { Form } from 'pages/AccountView/components/TextElements'
import styled from 'app-view/styles/styled'
import theme from 'app-config/theme'
import { useForm } from 'react-hook-form'
import { BiCopy } from 'react-icons/bi'
import { ButtonTransparent } from 'app-view/components/buttons'
import { useSetState } from 'react-use'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { checkAccountExt } from 'pages/AccountView/utils'

const createNewShareLink = async (_set: { website: string; referrer: string }) =>
  await apolloClient.mutate<
    Bitcash.CreateNewShareLinkMutation,
    Bitcash.CreateNewShareLinkMutationVariables
  >({
    mutation: Bitcash.CreateNewShareLinkDocument,
    variables: {
      _set,
    },
  })

export const AdminGenerateShareLink = () => {
  // add react-form hook here and set website and referrer as required fields
  const { register, formState, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      website: '',
      referrer: '',
    },
  })
  const [{ loading, copied, error, generatedLink }, setState] = useSetState({
    loading: false,
    error: '',
    generatedLink: '',
    copied: false,
  })

  useEffect(() => {
    if (!copied) return

    const timeout = setTimeout(() => {
      setState({ copied: false })

      return () => clearTimeout(timeout)
    }, 3000)
  }, [copied, setState])

  const setWebsiteValue = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue('website', event.target.value)

  const setReferrerValue = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue('referrer', event.target.value)

  const generateShareLink = async ({
    website,
    referrer,
  }: {
    website: string
    referrer: string
  }) => {
    // add logic to generate share link here
    console.log('generating share link')

    try {
      const generatedShareLink = await createNewShareLink({
        website,
        referrer: checkAccountExt(referrer),
      })

      console.log('generatedShareLink', generatedShareLink)

      if (generatedShareLink.errors?.length) throw new Error(generatedShareLink.errors[0].message)

      setState({ generatedLink: generatedShareLink.data?.generate_short_link })

      return true
    } catch (error) {
      console.error('generateShareLink::submit::ERROR::', error)
      setState({ error: (error as Error).message })

      return false
    }
  }

  const copyNewShareLink = () => {
    const el = document.createElement('textarea')
    el.value = generatedLink
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    setState({ copied: true })
  }

  const websiteValue = watch('website')
  const referrerValue = watch('referrer')

  return (
    <ScrollableFlexBoxArea>
      <ShareLinkContainer>
        <Text color="black" style={{ fontSize: '1.5rem' }}>
          Generate share links by specifying the <b>Website</b> and <b>Referrer</b> that will be
          inside of the share link.
        </Text>

        <Form
          id="generate-share-link"
          onSubmit={handleSubmit(generateShareLink)}
          style={{ position: 'relative' }}
        >
          <InputWrapper>
            <Label size="lg" pl={0} htmlFor="website">
              Website:
            </Label>
            <InputSection
              border="light-grey"
              error={Boolean(
                websiteValue && !formState.isValidating && formState.errors.website?.message,
              )}
            >
              <InputPrefixSuffixContainer>
                <Text color={theme.colors.darkGray}>
                  <b>https://</b>
                </Text>
              </InputPrefixSuffixContainer>
              <Input
                {...register('website')}
                id="website"
                value={websiteValue}
                onChange={setWebsiteValue}
                type="text"
                fullradius={1}
                fontWeight="500"
                paddingspace="thigh"
              />
            </InputSection>
            {websiteValue && !formState.isValidating && formState.errors.website?.message && (
              <HintError align="left">{formState.errors.website?.message}</HintError>
            )}
          </InputWrapper>

          <InputWrapper>
            <Label size="lg" pl={0} htmlFor="referrer">
              Referrer:
            </Label>
            <InputSection
              error={Boolean(
                websiteValue && !formState.isValidating && formState.errors.website?.message,
              )}
            >
              <Input
                {...register('referrer')}
                id="referrer"
                value={referrerValue}
                onChange={setReferrerValue}
                placeholder="janedoe"
                type="text"
                fullradius={1}
                fontWeight="500"
                paddingspace="thigh"
              />
              <InputPrefixSuffixContainer>
                <Text color={theme.colors.darkGray}>
                  <b>.bk</b>
                </Text>
              </InputPrefixSuffixContainer>
            </InputSection>
            {referrerValue && !formState.isValidating && formState.errors.referrer?.message && (
              <HintError align="left">{formState.errors.referrer?.message}</HintError>
            )}
          </InputWrapper>

          <ButtonWrapper>
            <LargeGreenButton type="submit" disabled={formState.isSubmitting || loading}>
              Generate
            </LargeGreenButton>
          </ButtonWrapper>

          <HintError align="center" style={{ fontSize: '1rem' }} error={Boolean(error)}>
            {copied && <b>Copied Successfully!</b>}
            {formState.isSubmitting || (loading && <b>Generating...</b>)}
            {error && <b>{error}</b>}
          </HintError>

          <GeneratedLinkContainer>
            <Text color={theme.colors.darkGray}>Generated Share Link:</Text>
            {'\n'}
            {generatedLink && (
              <ButtonTransparent onClick={copyNewShareLink}>
                <b>{generatedLink}</b>
                <BiCopy width={24} height={24} color={theme.colors.green} />
              </ButtonTransparent>
            )}
          </GeneratedLinkContainer>
        </Form>
      </ShareLinkContainer>
    </ScrollableFlexBoxArea>
  )
}

const ShareLinkContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-block: 1rem;
  text-align: center;
`

const InputPrefixSuffixContainer = styled.span`
  padding-block: 1rem;
  padding-inline: 0.5rem;
  min-width: 3rem;
  width: max-content;
  display: flex;
  align-items: center;
  background: #f5f5f5;

  &:first-child {
    border-radius: 10px 0 0 10px;
    border-right: 2px solid #eee;
    padding-right: 1rem;
  }
  &:last-child {
    border-radius: 0 10px 10px 0;
    border-left: 2px solid #eee;
  }
`

const GeneratedLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin-inline: auto;
  border: 1px solid #eee;
  border-radius: 24px;
  background-color: #fafafa;
  padding: 1rem;
  margin-block: 1rem;

  > p {
    width: calc(100% - 24px);
    white-space: pre;
  }

  button {
    margin: 1rem;
    white-space: pre-line;
    cursor: pointer;
    color: ${theme.colors.darkGray};
    gap: 0.5rem;

    &:hover {
      color: ${theme.colors.green};
    }
  }
`
