import { getAssetLabel } from 'app-view/components/WheelSelect/utils'

export const fixedAmountDecimals = (
  amount: string | number,
  asset: string | undefined | null,
  isOnlyAmount?: boolean,
) => {
  switch (asset) {
    case 'PETH':
    case 'ETH':
      return isOnlyAmount
        ? // @ts-ignore TODO: fix types
          parseFloat(amount.toFixed(6))
        : `${parseFloat(amount as string).toFixed(6)} ${getAssetLabel(asset)}`
    case 'PBTC':
    case 'BTC':
      return isOnlyAmount
        ? // @ts-ignore TODO: fix types
          parseFloat(amount.toFixed(8))
        : `${parseFloat(amount as string).toFixed(8)} ${getAssetLabel(asset)}`
    default:
      return isOnlyAmount
        ? // @ts-ignore TODO: fix types
          parseFloat(amount.toFixed(asset?.match(/^USDT/) ? 2 : 4))
        : `${parseFloat(amount as string).toFixed(asset?.match(/^USDT/) ? 2 : 4)} ${getAssetLabel(
            asset,
          )}`
  }
}
