export const TradeView = () => {
  // const { t } = useTranslation(['wallet', 'trade'])
  // const [, globalModalActions] = useGlobalModal()
  // const {
  //   userGuidePreferences: { last_crypto },
  //   setUserGuidePreferences,
  //   tokenData,
  // } = useStore()
  // const userPositionsHook = useUserPositions()
  // const findUserPosition = userPositionsHook.findUserPosition
  // const { pushTransaction } = useStore()
  // const account = useStore.useAccount()
  // const [tradeAmount, setTradeAmount] = useState<number>(0)
  // const [userInteract, setUserInteract] = useState(false)
  // const [coin, setCoin] = useState(last_crypto)

  // const position = useMemo(() => findUserPosition(coin), [coin, findUserPosition])
  // // TODO: Need for later version, to show balance at Trade View if we have to make it to it
  // // const bitcashAmount = _positions.find((p) => p.symbol_code === 'BITUSD')

  // const trade = async (order_type: string) => {
  //   try {
  //     const transaction = {
  //       actions: [
  //         {
  //           account: config.contracts.bitcashBank,
  //           name: 'trade',
  //           authorization: [
  //             {
  //               actor: account,
  //               permission: 'active',
  //             },
  //           ],
  //           data: {
  //             account: account,
  //             order_type,
  //             stable_asset: `${tradeAmount}.00 BITUSD`,
  //             crypto_symcode: coin.toUpperCase(),
  //           },
  //         },
  //       ],
  //     }
  //     await pushTransaction(transaction)
  //     const modal_success_opt: GlobalModalOpenProps = {
  //       content: () => <ModalSuccess text={t('wallet:success')} />,
  //       showClose: false,
  //       autoHide: true,
  //       iconType: 'LOADER',
  //     }
  //     globalModalActions.open(modal_success_opt)
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       const modal_error_opt = get_error_modal_props(() => (
  //         <ModalError error={(err as Error).message} />
  //       ))

  //       globalModalActions.open(modal_error_opt)
  //     }
  //   } finally {
  //     setUserGuidePreferences('last_crypto', coin.toUpperCase())
  //   }
  // }

  // const coinSelectorAttr = {
  //   setCoin,
  //   coinOpt:
  //     tokenData
  //       ?.map(({ token_symbol }) => token_symbol.code().toString())
  //       .filter((tkn) => tkn !== 'BITUSD' && tkn !== 'USDT') || [],
  //   indicatorProps: {
  //     screen: 'trade' as ScreenTypes,
  //     type: 'coin-selector',
  //   },
  //   userInteract,
  //   setUserInteract,
  // }

  return (
    <section>
      {/* <TradeContainer>
        <Position position={position} />

        <BuySellButton>
          <BuyButtonIcon
            onClick={() => trade('buy')}
            style={buyButtonIconStyles}
            whiletap={{ scale: 0.9, transformOrigin: 'top' }}
          />
        </BuySellButton>

        <TradeMainActions
          coinSelectorAttr={coinSelectorAttr}
          price={coin}
          setTradeAmount={setTradeAmount}
        />

        <BuySellButton>
          <SellButtonIcon
            onClick={() => trade('sell')}
            style={buyButtonIconStyles}
            whiletap={{ scale: 0.9, transformOrigin: 'top' }}
          />
        </BuySellButton>
      </TradeContainer> */}
    </section>
  )
}
