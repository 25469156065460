/* eslint-disable eol-last */
/* eslint-disable no-trailing-spaces */
/* eslint-disable padded-blocks */
import React from 'react'
import { useWizard } from 'app-view/components/Wizard'
import { ModalText, ModalButtonsContainer, ModalError } from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import styled from 'app-view/styles/styled'
import { formattedDate } from 'app-view/lib/utils'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { CancelButton, GreenButton } from 'app-view/components/buttons'
import { useTranslation } from 'react-i18next'
import { useChat } from 'app-view/components/Chat/hooks/useChat'
import { ChatType } from 'app-view/components/Chat/types'
import Chat from 'app-view/components/Chat'

export const NotificationDetail: React.FC = () => {
  const { t, i18n } = useTranslation(['p2p', 'global'])
  const [, { goTo }] = useWizard()
  const [{ loading, error, offer }, { cancelOffer, resetError }] = useP2POffer()
  const [, { onOpenWithProps }] = useChat()

  const handleChat = () => {
    onOpenWithProps({
      from: offer.messages.length ? (offer.messages[0].from as string) : '',
      to: offer.messages.length ? (offer.messages[0].to as string) : '',
      readOnly: true,
      id: {
        type: 'p2p' as ChatType,
        id: offer.messages.length ? offer.messages[0].p2p_id : '',
      },
    })
  }

  const onCancel = async () => {
    const { success } = await cancelOffer()
    if (success) {
      return goTo('cancel-complete')
    }
    return false
  }

  if (error) {
    return (
      <ModalError
        error={`Error cancelling P2P Offer:
          ${error}`}
        resetError={resetError}
      />
    )
  }

  if (loading) return <Loader text={'Cancelling P2P Offer...'} />

  return (
    <>
      <AdminModalText>
        Offer Details:{'\n'}
        <span>
          <OfferDataLabel>Buyer:</OfferDataLabel> {offer.buyer}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Seller:</OfferDataLabel> {offer.seller}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Initiator:</OfferDataLabel> {offer.initiator}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Amount:</OfferDataLabel> {offer.amount}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Buyer Confirmed Payment:</OfferDataLabel>{' '}
          {offer.buyer_confirmed_payment ? 'Yes' : 'No'}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Seller Confirmed Payment:</OfferDataLabel>{' '}
          {offer.seller_confirmed_payment ? 'Yes' : 'No'}
          {'\n'}
        </span>
        {offer.cancel_request_datetime && (
          <>
            <span>
              <OfferDataLabel>Offer Matched At:</OfferDataLabel>{' '}
              {formattedDate(i18n.language, offer.matched_datetime)}
              {'\n'}
            </span>

            <span>
              <OfferDataLabel>Offer Last Update At:</OfferDataLabel>{' '}
              {formattedDate(i18n.language, offer.updated_at)}
              {'\n'}
            </span>
          </>
        )}
      </AdminModalText>
      <br />
      <br />
      <ModalButtonsContainer>
        <MediumGreenButton onClick={handleChat}>See conversation</MediumGreenButton>
        <CancelButton onClick={onCancel}>{t('global:cancel')}</CancelButton>
      </ModalButtonsContainer>
      <Chat />
    </>
  )
}

const OfferDataLabel = styled.b`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  text-align: left;
`
const AdminModalText = styled(ModalText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;

  > span {
    display: flex;
    width: 100%;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    padding-block: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
`

export const MediumGreenButton = styled(GreenButton)`
  min-width: 60%;
  margin-bottom: 34px;
`
