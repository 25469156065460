import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ItemContainer,
  ItemName,
  SectionContainer,
  SectionHeader,
  SectionTitle,
} from 'pages/DeviceKeysView/styles'
import { useWizard } from 'app-view/components/Wizard'
import { RECOVERY_STEPS } from 'pages/DeviceKeysView/steps/recovery-steps'
import styled from 'app-view/styles/styled'
import { formatPublicKey } from 'app-engine/library/utils'
import { BaseButton } from 'app-view/components/buttons/BaseButton'

const StyledGreenButton = styled(BaseButton)`
  max-width: max-content;
  background-color: ${(props) => props.theme.colors.green};
`

export const KeyRecoverySection: React.FC<{ recoveryId: string }> = ({ recoveryId }) => {
  const { t } = useTranslation(['settings'])
  const [, { start }] = useWizard()

  const hasRecoveryId = Number(recoveryId) === 0

  return (
    <SectionContainer>
      <SectionHeader>
        <SectionTitle>{t('key_recovery')}</SectionTitle>
        <StyledGreenButton onClick={() => start(RECOVERY_STEPS)} size="sm">
          {hasRecoveryId ? t('add_partners') : t('update_partner')}
        </StyledGreenButton>
      </SectionHeader>
      {!hasRecoveryId && recoveryId ? (
        <ItemContainer>
          <ItemName>{formatPublicKey(recoveryId)}</ItemName>
        </ItemContainer>
      ) : null}
    </SectionContainer>
  )
}
