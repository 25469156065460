// account.ts

export default {
  create_account_title: 'Tạo Tài Khoản Người Dùng Của Bạn',
  create_account_format_description:
    'Vui lòng tạo một tên tài khoản độc đáo và dễ nhớ có 9 ký tự bằng cách sử dụng các chữ cái từ <b>a-z</b> và các số <b>1-5</b>.',
  create_account_user_account_placeholder: 'Nhập tên tài khoản của bạn',
  create_account_user_account_error_required: 'Vui lòng nhập tên tài khoản',
  create_account_user_account_error_pattern: 'Chỉ cho phép a-z và 1-5',
  create_account_referrer_account_error_pattern:
    'Chỉ cho phép a-z, 1-5 và các tài khoản kết thúc bằng .bk',
  create_account_user_account_error_unavailable:
    'Tài khoản không khả dụng! Vui lòng thử lại với tài khoản khác.',
  create_account_referrer_label: 'Tài khoản giới thiệu',
  create_account_referrer_account_placeholder: 'referrer1',
  create_account_referrer_error_required: 'Vui lòng nhập tài khoản giới thiệu của bạn',
  create_account_referrer_error_not_registered: 'Tài khoản giới thiệu không được đăng ký',
  create_account_accounts_submit: 'Xác nhận',
  create_account_referrer_error_unexistent:
    'Tài khoản hiện tại không được đăng ký hoặc được chấp thuận bởi bitcashBank.',
  create_account_key_title: 'Thêm Khóa Điện Thoại thông minh',
  create_account_key_description:
    "Thiết bị điện thoại thông minh của bạn sẽ là khóa chính để truy cập vào tài khoản của bạn. Vui lòng chọn tùy chọn 'Sử dụng thiết bị này với khóa màn hình' khi được nhắc sau khi nhấp vào nút bên dưới.",
  create_account_key_device_label: 'Tên thiết bị',
  create_account_key_device_placeholder: 'Nhập tên thiết bị của bạn',
  create_account_key_device_error_required: 'Vui lòng nhập tên thiết bị',
  create_account_key_submit: 'Tiếp theo',
  register_account_success_text:
    'Đăng ký của bạn đã được gửi thành công. Vui lòng thông báo cho người giới thiệu của bạn hoặc tham gia vào nhóm Discord của bitcashBank để xúc tiến việc phê duyệt.',
  create_account_success_text:
    'Việc tạo tài khoản của bạn đã hoàn thành thành công. Vui lòng thông báo cho người giới thiệu của bạn hoặc tham gia vào nhóm Discord của bitcashBank để xúc tiến việc phê duyệt.',
  register_account_success_button: 'Mở Discord',
  register_account_title: 'Đăng ký Tài Khoản Người Dùng Của Bạn',
  account_title: 'Tên Tài Khoản',
  account_title_description: 'Vui lòng nhập tên tài khoản bạn muốn đăng nhập vào với thiết bị này.',
  account_setup_account_button: 'Đăng Nhập',
  account_user_account_available: 'Tài khoản này đang khả dụng',
  account_user_account_registered: 'Tài khoản này là hợp lệ',
  account_user_account_not_registered: 'Tài khoản không được đăng ký',
  add_device_account_title: 'Nhập Tên Tài Khoản Đang Tồn Tại',
  migrate_device_account_title: 'Nhập Tên Tài Khoản Đang Tồn Tại',
  add_device_account_description:
    'Đây phải là một tài khoản bạn đã sử dụng trên một thiết bị trước đây. Bạn sẽ cần truy cập vào thiết bị đó để phê duyệt thêm một khóa khác.',
  migrate_device_account_description:
    'Đây phải là một tài khoản bạn đã sử dụng trên một thiết bị trước đây.',
  add_device_key_title: 'Khóa Thiết Bị',
  add_device_key_description:
    '<p>Bạn có muốn thêm điện thoại thông minh này hoặc một khóa bảo mật (ví dụ: Yubikey) làm một khóa bổ sung để truy cập <b>{{account}}</b> không?</p><p>Nếu bạn muốn sử dụng điện thoại thông minh này, hãy chọn <b>Touch hoặc Face ID</b> để sử dụng thiết bị này như một khóa khi được nhắc sau khi nhấp vào nút bên dưới.</p><p>Nếu không, nếu bạn có một khóa bảo mật, hãy chọn tùy chọn <b>Sử Dụng Khóa Bảo Mật</b> khi được nhắc.</p>',
  add_device_key_submit: 'Thêm Khóa',
  add_device_success_description:
    'Vui lòng sử dụng thiết bị gốc của bạn để chấp thuận thêm khóa mới này vào <b>{{account}}</b>',
  migrate_device_success_description: 'Vui lòng chờ quản trị viên.',
  select_device_title: 'Chọn Một Thiết Bị',
  select_device_description: 'Vui lòng, chọn một trong các thiết bị của bạn để đăng nhập.',
  new_device: 'Thiết Bị Mới',
  continue: 'Tiếp tục',
  warning_apple_user: 'Cảnh báo người dùng Apple',
  warning_apple_user_modal_info_browser:
    'Nhớ đăng ký trình duyệt mới như một <0>Thiết Bị Mới</0> nếu bạn sẽ đăng ký tài khoản này với sinh trắc học.',
  warning_apple_user_modal_info_confirm:
    'Đã hiểu, tôi sẽ chắc chắn đăng ký trình duyệt mới trước khi sử dụng nó.',
  list_devices: 'Danh sách thiết bị của {{account}}',
  singup_invitation:
    'Bitcash App được hỗ trợ bởi bitcashBank beta chỉ dành cho người được mời.<br /> <br /> Vui lòng truy cập <a href="https://bitcash.org">bitcash.org</a> để đăng ký nhận lời mời.',
  approved_accounts: 'Tài Khoản Được Chấp Thuận',
  pending_accounts: 'Tài Khoản Đang Chờ',
  approving_account: 'Đang Phê Duyệt Tài Khoản',
  rejecting_account: 'Đang Từ Chối Tài Khoản',
  successfully_rejected: 'Tài khoản {{account}} đã được từ chối thành công!',
  successfully_approved: 'Tài khoản {{acct.account}} đã được chấp thuận thành công!',

  country_id_label: 'ID',
  phone_number_label: 'Số điện thoại:',
  email_address_label: 'Địa chỉ email:',
  subscribe_to_newsletter: 'Giữ tôi được cập nhật về sự kiện crypto và giáo dục.',
  phone_valid: 'Số điện thoại hợp lệ!',
  phone_number_placeholder: '{{phoneNumberCountryCode}}số điện thoại#',
  include_country_code: 'Bao gồm mã quốc gia',
  create_account_kyc: 'Thông Tin Tài Khoản',
  create_account_kyc_title: 'Xác minh Tài Khoản',
  create_account_kyc_description:
    'Vui lòng nhập số điện thoại và địa chỉ email của bạn dưới đây để hoàn tất việc đăng ký tài khoản. Chúng tôi sẽ sử dụng thông tin này để xác minh danh tính của bạn và khôi phục tài khoản của bạn nếu bạn mất thiết bị.',
  invalid_phone_provenance: 'Số điện thoại không hợp lệ. Hãy thử lại hoặc với số khác.',
  account_details: 'Vui lòng nhập thông tin bên dưới cho mục đích khôi phục tài khoản:',
  this_country_id_already_registered: 'ID này đã được đăng ký.',
  phone_invalid: 'Số điện thoại không hợp lệ.',
  id_invalid: 'ID không hợp lệ.',
  email_invalid: 'Email không hợp lệ.',
  full_name_invalid: 'Tên không hợp lệ.',
  this_email_already_registered: 'Email này đã được đăng ký.',
  email_valid: 'E-mail được xác nhận.',
  this_phone_number_already_registered: 'Số điện thoại này đã được đăng ký.',
  loading_action: 'Đang xử lý chữ ký...',
  user_verified: 'Người dùng đã xác minh',
  created_at: 'Được tạo tại',
  approve: 'Chấp thuận',
  selfie_label: 'Giấy tờ tùy thân có ảnh',
  photo_invalid: 'Nhận dạng ảnh không hợp lệ.',
  continue_verification: 'Tiếp tục xác minh',
  cancel_verification: 'Có lẽ sau này',
  selfie_notification_regular: `Ưu tiên bảo mật của bạn, chúng tôi yêu cầu xác minh danh tính để khôi phục tài khoản, bảo vệ và thưởng ứng dụng.`,
  selfie_notification_investor: 'Đảm bảo ảnh selfie và giấy tờ tùy thân có ảnh của bạn bao gồm:',
  selfie_notifications_1: `<ul>
    <li>Hiển thị toàn bộ khuôn mặt và ID.</li>
    <li>Không có phụ kiện.</li>
    <li>Cả hai mắt đều mở to, biểu cảm trung tính.</li>
   </ul>`,
  selfie_notifications_2: `<ul>
  <li>Khung cảnh rõ ràng, đủ ánh sáng.</li>
  <li>Nền trơn.</li>
  <li>Không có bộ lọc hoặc thay đổi.</li>
  </ul>`,
  photo_id_notifications: `<ul>
  <li>Căn chỉnh giấy tờ tùy thân của bạn trong khung trên màn hình và nó sẽ hiển thị.</li>
  <li>Ảnh phải được chụp trong môi trường đủ ánh sáng, không bị chói.</li>
  <li>Không được che phần nào của giấy tờ tùy thân bằng ngón tay hoặc bất kỳ đồ vật nào.</li>
  </ul>`,
  require_permission:
    'Chúng tôi yêu cầu máy ảnh của bạn và quyền GPS để đủ điều kiện chụp ảnh tự sướng.',
  grant_permission: 'Cấp quyền',
  granting_permission: 'Cấp quyền...',
  take_selfie: 'Chụp ảnh tự sướng',
  retake_selfie: 'Chụp lại ảnh selfie',
  verifying: 'Đang xác minh...',
  photo_is_ok: 'Ảnh ổn.',
  processing_error: 'Đã xảy ra lỗi khi xử lý ảnh của bạn.',
  invalid_photo: 'Ảnh không đáp ứng yêu cầu.',
  processing: 'Xử lý...',
  download_selfie: 'Tải xuống ảnh tự sướng',
  take_selfie_id: 'Chụp ảnh selfie ID',
  retake_photos: 'Chụp lại ảnh',
  invalid_photo_id: 'Tài liệu không hiển thị.',
  take_selfie_instructions: 'Hướng dẫn chụp ảnh selfie',
  take_id_card_photo_instructions: 'Hướng dẫn chụp ảnh thẻ CMND',
  question_kyc_reason: 'Tại sao tôi phải cấp quyền?',
  return: 'Trở lại',
  kyc_help_text_1:
    'Các biện pháp bảo mật này bảo vệ tài khoản của bạn khỏi bị truy cập trái phép và đảm bảo bạn là chủ sở hữu hợp pháp. Nó cũng cho phép chúng tôi trao phần thưởng ứng dụng một cách chính xác phù hợp với khu vực của bạn.',
  kyc_help_text_2:
    "Khi bạn đã sẵn sàng, hãy quay lại và nhấp vào 'Tiếp tục xác minh' để tiếp tục hoặc chọn 'Có thể sau' nếu bạn cần chuẩn bị giấy tờ tùy thân hoặc điều chỉnh cài đặt thiết bị của mình.",
  how_to_grant_permission: 'LÀM THẾ NÀO ĐỂ CẤP PHÉP?',
  please_grant_bitcash_app_permission:
    'Vui lòng cấp quyền cho ứng dụng bitcash để truy cập vào máy ảnh và vị trí của bạn',
  for_android: 'Dành cho Android',
  android_setting: `<ol>
    <li>Đi tới 'Cài đặt' > 'Ứng dụng và thông báo' > 'Quyền ứng dụng.'</li>
    <li>Chọn 'ứng dụng bitcoin' và bật 'Camera' và 'Vị trí'.</li>
  </ol> `,
  additional_resources: 'Tài nguyên bổ sung',
  for_android_devices_via: 'Dành cho thiết bị Android thông qua Cổng trợ giúp Scribble Maps',
  for_ios: 'Dành cho iOS',
  ios_setting: `<ol>
    <li>Đi tới 'Cài đặt' > 'Quyền riêng tư' > 'Dịch vụ Định vị.'</li>
    <li>Tìm 'ứng dụng bitcoin' trong danh sách và đặt quyền thành 'Trong khi sử dụng ứng dụng'.</li>
  </ol> `,
  for_ios_devices_via: 'Dành cho thiết bị iOS thông qua Cổng trợ giúp Scribble Maps',
  for_chrome_specific: 'Để biết hướng dẫn dành riêng cho Chrome',
  enable_gps_location: 'Bật GPS/Vị trí',
  google_chrome_support: 'Hỗ trợ Google Chrome',
}
