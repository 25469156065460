export default {
  deposit: 'Deposit',
  withdrawal: 'Penarikan',
  success: 'Perdagangan berhasil!',
  with_memo: 'Dengan memo?',
  send: 'Kirim',
  loan: 'Pinjaman {{ticker}}',
  leverage: 'Leverage',
  tab_usd_title: 'USD',
  tab_crypto_title: 'Crypto',
  deposit_success: 'Deposit berhasil!',
  amount_deposit: 'Jumlah untuk deposit:',
  currency_receive: 'Mata uang yang diterima:',
  amount_placeholder: 'Masukkan jumlah',
  transaction_id: 'ID Transaksi',
  deposit_limit: 'Batas Deposit!',
  deposit_confirm:
    'Konfirmasi deposit Anda sebesar\n<span>{{base_amount}}</span> untuk <span>{{target_amount}}</span>.',
  exchange_rate_amount: 'Nilai Tukar: {{exchange_rate}}',
  exchange_rate: 'Nilai Tukar',
  exchange_rate_fee: 'Biaya:',
  not_rate_found: 'Tidak tersedia',
  account_label: 'Kirim ke:',
  currency_label: 'Mata Uang',
  amount_label: 'Jumlah:',
  amount: 'Jumlah',
  send_success: 'Pengiriman berhasil!',
  confirm_action: 'Konfirmasi',
  send_action: 'Kirim',
  memo_label: 'Memo',
  back_action: 'Kembali',
  next_action: 'Lanjut',
  bitcash_transfer: 'transfer bitcash',
  memo_placeholder: 'Masukkan pesan di sini',
  loading_action: 'Memproses transaksi...',
  exchange: 'Penukaran',
  amount_withdraw: 'Jumlah untuk ditarik:',
  limit_alert: 'batas maks. 24 jam',
  withdraw_fee: 'Biaya',
  withdraw_confirm:
    'Konfirmasi penarikan sebesar\n<span>{{base_amount}}</span> untuk <span>{{target_amount}}<span>.',
  withdraw_success: 'Penarikan berhasil!',
  exchange_success: 'Penukaran berhasil!',
  exchange_send: 'Anda Mengirim',
  exchange_get: 'Anda Mendapatkan',
  invalid_account_format: 'Format akun tidak valid',
  indicate_account_send: 'Tunjukkan akun untuk dikirim',
  invalid_account_send: 'Akun tidak valid untuk dikirim',
  indicate_amount_send: 'Tunjukkan jumlah yang akan dikirim',
  invalid_amount: 'Jumlah tidak valid',
  invalid_amount_send: 'Jumlah pengiriman tidak valid',
  required_memo: 'Tunjukkan memo yang diperlukan',
  memo_empty: 'Memo kosong',
  withdraw_helper: 'Batas harian adalah 5% hingga 10% dari total saldo di bank Anda.',
  withdraw_reset_label: `
	
	Reset harian:
	`,
}
