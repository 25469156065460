export default {
  heading: 'Opsi Profil',
  payment_methods: 'Metode Pembayaran',
  profile_information: 'Informasi Profil',
  phone_number: 'Nomor Telepon',
  email: 'Alamat Email',
  newsletter: 'Berlangganan Newsletter',
  phone_number_placeholder: '{{phoneNumberCountryCode}}#Nomor telp',
  include_country_code: 'Sertakan node negara:',
  phone_valid: 'Nomor telepon valid!',
  delete_payment_option_warning: 'Anda yakin ingin menghapus metode pembayaran ini?',
  delete: 'Hapus',
  cancel: 'Batal',
  no_methods: 'Tidak Ada Metode Pembayaran Sebelumnya yang Ditambahkan',
  save: 'Simpan Perubahan',
  revert: 'Kembalikan',
  confirm: 'Konfirmasi',
  select_method: `Pilih metode pembayaran
	yang ingin Anda simpan.`,
  profile_update_success: 'Profil berhasil diperbarui',
  verification_status: 'Status:',
}
