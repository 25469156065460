import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import { SkeletonLoader } from 'app-view/components/Skeletons'

export const globeAnimOpt = {
  animate: {
    opacity: 1,
    clipPath: 'insert(0% 0% 0% 0%)',
  },
  initial: {
    opacity: 0.5,
    clipPath: 'insert(100% 0% 0% 0%)',
  },
  transition: {
    duration: 0.12,
  },
}

const ChatGlobesLoader = () => (
  <>
    <motion.div {...globeAnimOpt} style={{ marginRight: 'auto', marginBottom: 8 }}>
      <SkeletonLoader height="63px" width="282px" borderRadius="0px" count={1} />
    </motion.div>
    <motion.div
      {...globeAnimOpt}
      style={{ marginRight: 'auto', direction: 'rtl', marginTop: 4, marginBottom: 8 }}
    >
      <SkeletonLoader height="63px" width="282px" borderRadius="0px" count={2} />
    </motion.div>
  </>
)

export { ChatGlobesLoader }
