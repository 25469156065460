import { ModalButtonsContainer } from 'app-view/components/Modal'
import { DownloadLinkButton } from './DownloadLinkButton'

export const DownloadSelfieButton = ({ downloadLink }: { downloadLink: string }) => {
  return (
    <ModalButtonsContainer style={{ marginTop: 0 }} cancel>
      <DownloadLinkButton downloadLink={downloadLink} />
    </ModalButtonsContainer>
  )
}
