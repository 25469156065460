import styled from 'app-view/styles/styled'

export const HistoryTextLabel = styled.p`
  color: ${(props) => props.color};
  font-size: ${(props: any) => (props.dollars ? '0.8125rem' : '1rem')};
  margin-bottom: 0;
  font-weight: ${(props: any) => (props.dollars ? '400' : '600')};
  user-select: text;

  > span {
    font-size: ${(props: any) => (props.dollars ? '	0.75rem' : '0.8125rem')};
    font-weight: bold;
  }
`

export const GrinStatusParagraph = styled.div`
  background-color: #f7f8f9;
  border-radius: 10px;
  padding: 24px;
  width: 90%;
  text-align: center;
  margin-top: 11px;
  margin-bottom: 24px;
  color: #f34040;
  max-width: 360px;
  white-space: pre-line;
  font-size: 0.875rem;
`

export const HistoryTextValue = styled.p`
  color: ${(props) => props.color};
  font-size: 0.875rem;
  margin-bottom: 0;
  font-weight: 600;
  user-select: text;
  text-align: right;
`
export const HistoryContentContainer = styled.div`
  margin-top: 15px;
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
`

export const HistoryTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 8px;

  > p {
    white-space: pre-line;

    &:first-child {
      max-width: 40%;
    }
  }
`
