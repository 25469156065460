import React, { useEffect } from 'react'
import styled from 'app-view/styles/styled'
import { WaveIcon } from './icons'
import { scrollbar_css_access } from 'app-view/components/Styled'
import { BsBellContainer as TabCounterContainer, Counter } from './Header'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'

const HeaderContainer = styled.div`
  padding: 1rem 0;
  display: inline-flex;
  width: 100%;
  align-items: center;
  min-height: 60px;
  overflow-y: hidden;
  overflow: scroll;

  ${scrollbar_css_access}
`
const Option = styled.button`
  font-size: 1.05rem;
  letter-spacing: 0.035em;
  border: none;
  color: #787878;
  background-color: #fff0;
  outline: none;
  cursor: pointer;
  display: inline-block;
  margin-right: 0.5rem;
`
const SelectedLine = styled.div`
  width: 100%;
  height: 0.25rem;
  border-radius: 0.15rem;
  background-color: ${(props) => props.color};
  margin-top: 0.2rem;
`
const FlexCenter = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > svg {
    margin-right: 3px;
  }
`

export type Tab = {
  label: string
  value: string
  counter?: number
}

type ITabs<T> = {
  tabs: Array<T>
  currentTab: string
  setCurrentTab: Function
  style?: React.CSSProperties | undefined
}

const TabCounter = styled(Counter)<{ active?: boolean; total?: number }>`
  background-color: ${({ active }) => (active ? '#f34040' : '#757575')};
  right: -15px;
  font-size: ${({ total }) => (total && total > 99 ? '0.5rem' : '0.625rem')};
`

export const Tabs: React.FC<ITabs<Tab>> = ({ tabs, currentTab, setCurrentTab, style }) => {
  useEffect(() => {
    if (document) {
      const element = document.getElementById(`header-tab-name__${currentTab}`)

      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [currentTab])

  const tabHandler = (tab: Tab) => setCurrentTab(tab)

  const non_stable_tab =
    /^(p2p|bank|swap|crypto|all|unread|forex|open|filled|approved_accounts|pending_accounts|public|private|network|cancelled|system|share_link)$/

  return (
    <HeaderContainer style={style}>
      {tabs.map((tab) => (
        <Option
          onClick={() => tabHandler(tab)}
          key={`header-tab-name__${tab.value}`}
          id={`header-tab-name__${tab.value}`}
          style={{ fontWeight: tab.value === currentTab ? 'bolder' : undefined }}
        >
          {!tab.value.match(non_stable_tab) ? (
            <FlexCenter>
              <WaveIcon />
              {tab.label.replace('GBR', 'GBP')}
            </FlexCenter>
          ) : (
            <>
              {tab.counter ? (
                <TabCounterContainer>
                  <AnimatePresence>
                    <TabCounter active={tab.value === currentTab} total={tab.counter}>
                      {tab.counter > 0 ? tab.counter : 0}
                    </TabCounter>
                  </AnimatePresence>
                  {tab.label.replace('GBR', 'GBP')}
                </TabCounterContainer>
              ) : (
                tab.label.replace('GBR', 'GBP')
              )}
            </>
          )}
          <SelectedLine color={tab.value === currentTab ? '#29E0AD' : 'transparent'} />
        </Option>
      ))}
    </HeaderContainer>
  )
}
