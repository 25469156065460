import React from 'react'

const FLOWIcon = (props: any) => (
  <svg viewBox="0 0 54 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3918 0.00123349C16.1944 -0.0809876 3.08809 3.92948 0.703618 17.3766C-0.423696 23.7327 -0.0243682 29.893 0.703618 36.2699C2.19535 49.3284 15.2301 54.3118 26.9997 53.985C38.6862 53.6607 51.7657 49.6724 53.2966 36.2695C54.0245 29.893 54.4234 23.7327 53.2966 17.3766C51.2067 5.59227 39.4908 0.0767767 28.3918 0.00123349Z"
      fill="#00EF8B"
      stroke="#00EF8B"
      strokeWidth="0.5"
    />
    <path
      d="M27 42C35.2843 42 42 35.2843 42 27C42 18.7157 35.2843 12 27 12C18.7157 12 12 18.7157 12 27C12 35.2843 18.7157 42 27 42Z"
      fill="#00EF8B"
    />
    <path d="M33.57 24.6597H29.34V28.8897H33.57V24.6597Z" fill="white" />
    <path
      d="M25.1099 30.4797C25.1099 31.3497 24.3899 32.0697 23.5199 32.0697C22.6499 32.0697 21.9299 31.3497 21.9299 30.4797C21.9299 29.6097 22.6499 28.8897 23.5199 28.8897H25.1099V24.6597H23.5199C20.3099 24.6597 17.7 27.2697 17.7 30.4797C17.7 33.6897 20.3099 36.2997 23.5199 36.2997C26.7299 36.2997 29.3399 33.6897 29.3399 30.4797V28.8897H25.1099V30.4797Z"
      fill="white"
    />
    <path
      d="M30.9301 22.5303H35.7001V18.3003H30.9301C27.7201 18.3003 25.1101 20.9103 25.1101 24.1203V24.6603H29.3401V24.1203C29.3401 23.2503 30.0601 22.5303 30.9301 22.5303Z"
      fill="white"
    />
    <path d="M29.3401 24.6597H25.1101V28.8897H29.3401V24.6597Z" fill="#16FF99" />
  </svg>
)

export default FLOWIcon
