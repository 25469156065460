import React from 'react'

const RemoveIcon = (props: any) => (
  <svg width={14} height={14} {...props} viewBox="0 0 14 14" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.313 7A5.689 5.689 0 0 1 7 1.312 5.689 5.689 0 0 1 12.688 7 5.689 5.689 0 0 1 7 12.688 5.689 5.689 0 0 1 1.312 7ZM7 2.187A4.814 4.814 0 0 0 2.187 7 4.814 4.814 0 0 0 7 11.813 4.814 4.814 0 0 0 11.813 7 4.814 4.814 0 0 0 7 2.187Z"
      fill="#F34040"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.94 4.94c.171-.17.448-.17.62 0L7 6.382l1.44-1.44a.438.438 0 0 1 .62.618L7.618 7l1.44 1.44a.438.438 0 0 1-.618.62L7 7.618l-1.44 1.44a.438.438 0 0 1-.62-.618L6.382 7l-1.44-1.44a.438.438 0 0 1 0-.62Z"
      fill="#F34040"
    />
  </svg>
)

export default RemoveIcon
