import { useWizard } from 'app-view/components/Wizard'
import {
  getRegionLabel,
  isMethodWithDetails,
  methodDetailsLabel,
  titleCase,
} from 'app-view/lib/utils'
import isEmpty from 'lodash.isempty'
import { PaymentMethodDetails, useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

export const useP2PLabels = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { formatRegion }] = useP2POffer()
  const [
    {
      isMethodDetails,
      showDetails,
      copied,
      sellerMethodDetailsLabelData,
      buyerMethodDetailsLabelData,
    },
    setP2PLabelState,
  ] = useSetState<{
    isMethodDetails: boolean
    showDetails: boolean
    copied: boolean
    sellerMethodDetailsLabelData: string
    buyerMethodDetailsLabelData: string
  }>({
    isMethodDetails: isMethodWithDetails(offer.method),
    showDetails: false,
    copied: false,
    sellerMethodDetailsLabelData: '',
    buyerMethodDetailsLabelData: '',
  })
  const [{ step, open }] = useWizard()

  let sellerParsedMethodDetail: PaymentMethodDetails
  let buyerParsedMethodDetail: PaymentMethodDetails

  try {
    sellerParsedMethodDetail = JSON.parse(offer.seller_method_details || '{}')
  } catch (error) {
    console.log('[LEGACY]::Error parsing seller method details', { error })
    sellerParsedMethodDetail = {}
  }
  try {
    buyerParsedMethodDetail = JSON.parse(offer.buyer_method_details || '{}')
  } catch (error) {
    console.log('[LEGACY]::Error parsing buyer method details', { error })
    buyerParsedMethodDetail = {}
  }

  // * Setting seller and buyer labels depending the offer method info and scenario
  const setP2PLabelData = () => {
    const methodRegionLabel = t('p2p:bank_transfer_region', {
      region: titleCase(
        formatRegion(offer.region as string, false) ||
          (offer.region || '').split('-')[(offer.region || '').split('-').length - 1],
      ),
      isRegion: getRegionLabel(offer.region as string, t('bank_transfer_region_label')),
    })

    let sellerMethodDetailLabelData = ''

    const kudaSellerMethodDetailLabelData = t('p2p:seller_method_account_detail_label', {
      accountNumber: !isEmpty(sellerParsedMethodDetail)
        ? sellerParsedMethodDetail.accountNumber
        : '',
      region: methodRegionLabel,
    })
    const oxxoPalmPayOpaySellerMethodDetailLabelData = t(
      'p2p:seller_method_phone_name_detail_label',
      {
        fullName: !isEmpty(sellerParsedMethodDetail) ? sellerParsedMethodDetail.fullName : '',
        phone: !isEmpty(sellerParsedMethodDetail) ? sellerParsedMethodDetail.phoneNumber : '',
        region: methodRegionLabel,
      },
    )
    const bankSellerMethodDetailLabelData = t('p2p:buyer_method_bank_detail_label', {
      fullName: !isEmpty(sellerParsedMethodDetail) ? sellerParsedMethodDetail.fullName : '',
      accountName: !isEmpty(sellerParsedMethodDetail) ? sellerParsedMethodDetail.accountName : '',
      accountNumber: !isEmpty(sellerParsedMethodDetail)
        ? sellerParsedMethodDetail.accountNumber
        : '',
      region: methodRegionLabel,
    })
    const getSellerMethodLabelData = () => {
      switch (offer.method) {
        case 'kuda':
          return kudaSellerMethodDetailLabelData
        case 'oxxo':
        case 'palmpay':
        case 'opay':
          return oxxoPalmPayOpaySellerMethodDetailLabelData
        default:
          return bankSellerMethodDetailLabelData
      }
    }

    sellerMethodDetailLabelData = !offer.method.match(/(bank_transfer|oxxo|palmpay|opay|kuda)/)
      ? t('p2p:seller_method_phone_detail_label', {
          phone: !isEmpty(sellerParsedMethodDetail) ? sellerParsedMethodDetail.phoneNumber : '',
          region: methodRegionLabel,
        })
      : getSellerMethodLabelData()

    let buyerMethodDetailLabelData = ''

    const kudaBuyerMethodDetailLabelData = t('p2p:buyer_method_account_detail_label', {
      accountNumber: !isEmpty(buyerParsedMethodDetail) ? buyerParsedMethodDetail.accountNumber : '',
      region: methodRegionLabel,
    })
    const oxxoPalmPayOpayBuyerMethodDetailLabelData = t(
      'p2p:buyer_method_phone_name_detail_label',
      {
        fullName: !isEmpty(buyerParsedMethodDetail) ? buyerParsedMethodDetail.fullName : '',
        phone: !isEmpty(buyerParsedMethodDetail) ? buyerParsedMethodDetail.phoneNumber : '',
        region: methodRegionLabel,
      },
    )
    const bankBuyerMethodDetailLabelData = t('p2p:buyer_method_bank_detail_label', {
      fullName: !isEmpty(buyerParsedMethodDetail) ? buyerParsedMethodDetail.fullName : '',
      accountName: !isEmpty(buyerParsedMethodDetail) ? buyerParsedMethodDetail.accountName : '',
      accountNumber: !isEmpty(buyerParsedMethodDetail) ? buyerParsedMethodDetail.accountNumber : '',
      region: methodRegionLabel,
    })
    const getBuyerMethodLabelData = () => {
      switch (offer.method) {
        case 'kuda':
          return kudaBuyerMethodDetailLabelData
        case 'oxxo':
        case 'palmpay':
        case 'opay':
          return oxxoPalmPayOpayBuyerMethodDetailLabelData
        default:
          return bankBuyerMethodDetailLabelData
      }
    }

    buyerMethodDetailLabelData = !Boolean(
      offer.method.match(/(bank_transfer|oxxo|palmpay|opay|kuda)/),
    )
      ? t('p2p:buyer_method_phone_detail_label', {
          phone: !isEmpty(sellerParsedMethodDetail) ? sellerParsedMethodDetail.phoneNumber : '',
          region: methodRegionLabel,
        })
      : getBuyerMethodLabelData()

    const buyerMethodDetailsLabelData = !showDetails
      ? t('p2p:show_more')
      : methodDetailsLabel(`${buyerMethodDetailLabelData}${t('p2p:show_less')}`, offer.method)
    const sellerMethodDetailsLabelData = !showDetails
      ? t('p2p:show_more')
      : methodDetailsLabel(`${sellerMethodDetailLabelData}${t('p2p:show_less')}`, offer.method)

    setP2PLabelState({
      sellerMethodDetailsLabelData: !isEmpty(sellerParsedMethodDetail)
        ? sellerMethodDetailsLabelData
        : methodDetailsLabel(methodRegionLabel, offer.method),
      buyerMethodDetailsLabelData: !isEmpty(buyerParsedMethodDetail)
        ? buyerMethodDetailsLabelData
        : methodDetailsLabel(methodRegionLabel, offer.method),
    })
  }

  // * Triggering initial render
  useEffect(() => {
    setP2PLabelData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, open, offer, isMethodDetails, showDetails])

  const toggleP2PMethodDetails = () => {
    setP2PLabelState((prevState) => ({ ...prevState, showDetails: !prevState.showDetails }))
  }

  const copyToClipboard = useCallback(async (text: string) => {
    const textArea = document.createElement('textarea')

    textArea.style.position = 'fixed'
    textArea.style.top = '0'
    textArea.style.left = '0'
    textArea.style.opacity = '0'
    textArea.value = text

    document.body.appendChild(textArea)

    textArea.focus()
    textArea.select()

    try {
      await navigator.clipboard.writeText(text)

      setP2PLabelState({
        copied: true,
      })
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    } finally {
      document.body.removeChild(textArea)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // * Creating show details listeners for the toggler
  useEffect(() => {
    // * We can change this to the <Trans /> component that i18n provides, but it's not worth it either
    // ? So it is better to keep the dangerouslySetInnerHTML to set event listeners, else we will have to refactor a lot...
    if (!sellerMethodDetailsLabelData || !buyerMethodDetailsLabelData) {
      const showMoreDetails = document.getElementById('show-more-details')
      const showLessDetails = document.getElementById('show-less-details')

      return () => {
        if (showMoreDetails) showMoreDetails.removeEventListener('click', toggleP2PMethodDetails)
        if (showLessDetails) showLessDetails.removeEventListener('click', toggleP2PMethodDetails)
      }
    }

    const showMoreDetails = document.getElementById('show-more-details')
    const showLessDetails = document.getElementById('show-less-details')

    if (showMoreDetails) showMoreDetails.addEventListener('click', toggleP2PMethodDetails)

    if (showLessDetails) showLessDetails.addEventListener('click', toggleP2PMethodDetails)

    return () => {
      if (showMoreDetails) showMoreDetails.removeEventListener('click', toggleP2PMethodDetails)
      if (showLessDetails) showLessDetails.removeEventListener('click', toggleP2PMethodDetails)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDetails, sellerMethodDetailsLabelData, buyerMethodDetailsLabelData])

  // * Creating copy to clipboard listeners for the method details
  useEffect(() => {
    // * We can change this to the <Trans /> component that i18n provides, but it's not worth it either
    // ? So it is better to keep the dangerouslySetInnerHTML to set event listeners, else we will have to refactor a lot...
    if (!showDetails && (!sellerMethodDetailsLabelData || !buyerMethodDetailsLabelData)) {
      return () => {
        const offerMethodDetails = document.getElementById('method-detail')

        // Copy to clipboard the text inside the element
        if (offerMethodDetails) {
          offerMethodDetails.removeEventListener('click', () =>
            copyToClipboard(offerMethodDetails.innerText),
          )
        }
      }
    }

    const offerMethodDetails = document.getElementById('method-detail')

    // Copy to clipboard the text inside the element
    if (offerMethodDetails)
      offerMethodDetails.addEventListener('click', () =>
        copyToClipboard(offerMethodDetails.innerText),
      )

    return () => {
      offerMethodDetails?.removeEventListener('click', () =>
        copyToClipboard(offerMethodDetails.innerText),
      )
    }
  }, [
    step,
    open,
    copyToClipboard,
    showDetails,
    sellerMethodDetailsLabelData,
    buyerMethodDetailsLabelData,
  ])

  useEffect(() => {
    if (copied) {
      // * I do not like the idea of alerts, but it's the only way to notify the user that the address has been copied
      // ? Else we can use a toast, but it's not part of the design
      // ! TODO: Status Components - UX boost
      // eslint-disable-next-line no-alert
      alert(t('copied'))

      setP2PLabelState({
        copied: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copied])

  return {
    isMethodDetails,
    buyerMethodDetailsLabelData,
    sellerMethodDetailsLabelData,
  }
}
