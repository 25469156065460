import React from 'react'

const TLOSIcon = (props: any) => (
  <svg viewBox="0 0 54 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3918 0.00123349C16.1944 -0.0809876 3.08809 3.92948 0.703618 17.3766C-0.423696 23.7327 -0.0243682 29.893 0.703618 36.2699C2.19535 49.3284 15.2301 54.3118 26.9997 53.985C38.6862 53.6607 51.7657 49.6724 53.2966 36.2695C54.0245 29.893 54.4234 23.7327 53.2966 17.3766C51.2067 5.59227 39.4908 0.0767767 28.3918 0.00123349Z"
      fill="white"
      stroke="#571AFF"
      strokeWidth="0.5"
    />
    <circle cx="26.75" cy="26.75" r="13.75" fill="#571AFF" />
  </svg>
)

export default TLOSIcon
