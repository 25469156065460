export default {
  scalars: [
    0, 2, 3, 5, 8, 19, 40, 55, 56, 57, 61, 73, 84, 96, 109, 121, 125, 135, 153, 163, 177, 189, 198,
    208, 212, 214, 223, 234, 244, 248, 261, 285, 295, 299, 305, 313, 317, 329, 339, 340, 341, 345,
    347, 349, 361, 372, 373, 374, 378, 390, 400, 401, 402, 406, 414, 426, 430, 436, 445, 449, 456,
    466, 474, 487, 495, 499, 510, 520, 524, 530, 538, 542, 564, 576, 577, 578, 579, 580, 581, 582,
    583, 584, 596, 612, 622, 626, 636, 647, 651, 657, 658, 668, 672, 679, 691, 695, 697, 699, 713,
    728, 738, 742, 748, 749, 750, 756, 764, 768, 772, 785, 798, 813, 830, 838, 842,
  ],
  types: {
    Boolean: {},
    Boolean_comparison_exp: {
      _eq: [0],
      _gt: [0],
      _gte: [0],
      _in: [0],
      _is_null: [0],
      _lt: [0],
      _lte: [0],
      _neq: [0],
      _nin: [0],
      __typename: [8],
    },
    Float: {},
    Int: {},
    Int_comparison_exp: {
      _eq: [3],
      _gt: [3],
      _gte: [3],
      _in: [3],
      _is_null: [0],
      _lt: [3],
      _lte: [3],
      _neq: [3],
      _nin: [3],
      __typename: [8],
    },
    JSON: {},
    PushSubscriptionInput: {
      account: [8],
      endpoint: [8],
      expirationTime: [8],
      keys: [7],
      __typename: [8],
    },
    PushSubscriptionKeysInput: {
      auth: [8],
      p256dh: [8],
      __typename: [8],
    },
    String: {},
    String_array_comparison_exp: {
      _contained_in: [8],
      _contains: [8],
      _eq: [8],
      _gt: [8],
      _gte: [8],
      _in: [8],
      _is_null: [0],
      _lt: [8],
      _lte: [8],
      _neq: [8],
      _nin: [8],
      __typename: [8],
    },
    String_comparison_exp: {
      _eq: [8],
      _gt: [8],
      _gte: [8],
      _ilike: [8],
      _in: [8],
      _iregex: [8],
      _is_null: [0],
      _like: [8],
      _lt: [8],
      _lte: [8],
      _neq: [8],
      _nilike: [8],
      _nin: [8],
      _niregex: [8],
      _nlike: [8],
      _nregex: [8],
      _nsimilar: [8],
      _regex: [8],
      _similar: [8],
      __typename: [8],
    },
    account_statistics: {
      orders_cancelled: [163],
      orders_completed: [163],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_aggregate: {
      aggregate: [13],
      nodes: [11],
      __typename: [8],
    },
    account_statistics_aggregate_fields: {
      avg: [14],
      count: [
        3,
        {
          columns: [19, '[account_statistics_select_column!]'],
          distinct: [0],
        },
      ],
      max: [16],
      min: [17],
      stddev: [20],
      stddev_pop: [21],
      stddev_samp: [22],
      sum: [25],
      var_pop: [26],
      var_samp: [27],
      variance: [28],
      __typename: [8],
    },
    account_statistics_avg_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_bool_exp: {
      _and: [15],
      _not: [15],
      _or: [15],
      orders_cancelled: [164],
      orders_completed: [164],
      user_id: [10],
      __typename: [8],
    },
    account_statistics_max_fields: {
      orders_cancelled: [163],
      orders_completed: [163],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_min_fields: {
      orders_cancelled: [163],
      orders_completed: [163],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_order_by: {
      orders_cancelled: [349],
      orders_completed: [349],
      user_id: [349],
      __typename: [8],
    },
    account_statistics_select_column: {},
    account_statistics_stddev_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_stddev_pop_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_stddev_samp_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_stream_cursor_input: {
      initial_value: [24],
      ordering: [189],
      __typename: [8],
    },
    account_statistics_stream_cursor_value_input: {
      orders_cancelled: [163],
      orders_completed: [163],
      user_id: [8],
      __typename: [8],
    },
    account_statistics_sum_fields: {
      orders_cancelled: [163],
      orders_completed: [163],
      __typename: [8],
    },
    account_statistics_var_pop_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_var_samp_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    account_statistics_variance_fields: {
      orders_cancelled: [2],
      orders_completed: [2],
      __typename: [8],
    },
    accounts_information: {
      account: [8],
      country_id: [
        223,
        {
          path: [8],
        },
      ],
      date_of_birth: [699],
      email: [8],
      full_name: [8],
      id: [798],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [
        223,
        {
          path: [8],
        },
      ],
      reg_account: [432],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_aggregate: {
      aggregate: [35],
      nodes: [29],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp: {
      bool_and: [32],
      bool_or: [33],
      count: [34],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp_bool_and: {
      arguments: [56],
      distinct: [0],
      filter: [39],
      predicate: [1],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp_bool_or: {
      arguments: [57],
      distinct: [0],
      filter: [39],
      predicate: [1],
      __typename: [8],
    },
    accounts_information_aggregate_bool_exp_count: {
      arguments: [55],
      distinct: [0],
      filter: [39],
      predicate: [4],
      __typename: [8],
    },
    accounts_information_aggregate_fields: {
      count: [
        3,
        {
          columns: [55, '[accounts_information_select_column!]'],
          distinct: [0],
        },
      ],
      max: [45],
      min: [47],
      __typename: [8],
    },
    accounts_information_aggregate_order_by: {
      count: [349],
      max: [46],
      min: [48],
      __typename: [8],
    },
    accounts_information_append_input: {
      country_id: [223],
      recovery_partners: [223],
      __typename: [8],
    },
    accounts_information_arr_rel_insert_input: {
      data: [44],
      on_conflict: [51],
      __typename: [8],
    },
    accounts_information_bool_exp: {
      _and: [39],
      _not: [39],
      _or: [39],
      account: [10],
      country_id: [225],
      date_of_birth: [700],
      email: [10],
      full_name: [10],
      id: [799],
      newsletter_subscription: [1],
      phone: [10],
      photo_id: [10],
      recovery_partners: [225],
      reg_account: [435],
      registration_ip: [10],
      selfie: [10],
      __typename: [8],
    },
    accounts_information_constraint: {},
    accounts_information_delete_at_path_input: {
      country_id: [8],
      recovery_partners: [8],
      __typename: [8],
    },
    accounts_information_delete_elem_input: {
      country_id: [3],
      recovery_partners: [3],
      __typename: [8],
    },
    accounts_information_delete_key_input: {
      country_id: [8],
      recovery_partners: [8],
      __typename: [8],
    },
    accounts_information_insert_input: {
      account: [8],
      country_id: [223],
      date_of_birth: [699],
      email: [8],
      full_name: [8],
      id: [798],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [223],
      reg_account: [441],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_max_fields: {
      account: [8],
      date_of_birth: [699],
      email: [8],
      full_name: [8],
      id: [798],
      phone: [8],
      photo_id: [8],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_max_order_by: {
      account: [349],
      date_of_birth: [349],
      email: [349],
      full_name: [349],
      id: [349],
      phone: [349],
      photo_id: [349],
      registration_ip: [349],
      selfie: [349],
      __typename: [8],
    },
    accounts_information_min_fields: {
      account: [8],
      date_of_birth: [699],
      email: [8],
      full_name: [8],
      id: [798],
      phone: [8],
      photo_id: [8],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_min_order_by: {
      account: [349],
      date_of_birth: [349],
      email: [349],
      full_name: [349],
      id: [349],
      phone: [349],
      photo_id: [349],
      registration_ip: [349],
      selfie: [349],
      __typename: [8],
    },
    accounts_information_mutation_response: {
      affected_rows: [3],
      returning: [29],
      __typename: [8],
    },
    accounts_information_obj_rel_insert_input: {
      data: [44],
      on_conflict: [51],
      __typename: [8],
    },
    accounts_information_on_conflict: {
      constraint: [40],
      update_columns: [61],
      where: [39],
      __typename: [8],
    },
    accounts_information_order_by: {
      account: [349],
      country_id: [349],
      date_of_birth: [349],
      email: [349],
      full_name: [349],
      id: [349],
      newsletter_subscription: [349],
      phone: [349],
      photo_id: [349],
      recovery_partners: [349],
      reg_account: [443],
      registration_ip: [349],
      selfie: [349],
      __typename: [8],
    },
    accounts_information_pk_columns_input: {
      account: [8],
      id: [798],
      __typename: [8],
    },
    accounts_information_prepend_input: {
      country_id: [223],
      recovery_partners: [223],
      __typename: [8],
    },
    accounts_information_select_column: {},
    accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_and_arguments_columns:
      {},
    accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_or_arguments_columns:
      {},
    accounts_information_set_input: {
      account: [8],
      country_id: [223],
      date_of_birth: [699],
      email: [8],
      full_name: [8],
      id: [798],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [223],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_stream_cursor_input: {
      initial_value: [60],
      ordering: [189],
      __typename: [8],
    },
    accounts_information_stream_cursor_value_input: {
      account: [8],
      country_id: [223],
      date_of_birth: [699],
      email: [8],
      full_name: [8],
      id: [798],
      newsletter_subscription: [0],
      phone: [8],
      photo_id: [8],
      recovery_partners: [223],
      registration_ip: [8],
      selfie: [8],
      __typename: [8],
    },
    accounts_information_update_column: {},
    accounts_information_updates: {
      _append: [37],
      _delete_at_path: [41],
      _delete_elem: [42],
      _delete_key: [43],
      _prepend: [54],
      _set: [58],
      where: [39],
      __typename: [8],
    },
    accounts_referral: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      reg_account: [432],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_aggregate: {
      aggregate: [67],
      nodes: [63],
      __typename: [8],
    },
    accounts_referral_aggregate_bool_exp: {
      count: [66],
      __typename: [8],
    },
    accounts_referral_aggregate_bool_exp_count: {
      arguments: [84],
      distinct: [0],
      filter: [72],
      predicate: [4],
      __typename: [8],
    },
    accounts_referral_aggregate_fields: {
      avg: [70],
      count: [
        3,
        {
          columns: [84, '[accounts_referral_select_column!]'],
          distinct: [0],
        },
      ],
      max: [76],
      min: [78],
      stddev: [86],
      stddev_pop: [88],
      stddev_samp: [90],
      sum: [94],
      var_pop: [98],
      var_samp: [100],
      variance: [102],
      __typename: [8],
    },
    accounts_referral_aggregate_order_by: {
      avg: [71],
      count: [349],
      max: [77],
      min: [79],
      stddev: [87],
      stddev_pop: [89],
      stddev_samp: [91],
      sum: [95],
      var_pop: [99],
      var_samp: [101],
      variance: [103],
      __typename: [8],
    },
    accounts_referral_arr_rel_insert_input: {
      data: [75],
      on_conflict: [81],
      __typename: [8],
    },
    accounts_referral_avg_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_avg_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_bool_exp: {
      _and: [72],
      _not: [72],
      _or: [72],
      account: [10],
      clicks: [4],
      link: [10],
      referrer: [10],
      reg_account: [435],
      registrations: [4],
      website: [10],
      __typename: [8],
    },
    accounts_referral_constraint: {},
    accounts_referral_inc_input: {
      clicks: [3],
      registrations: [3],
      __typename: [8],
    },
    accounts_referral_insert_input: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      reg_account: [441],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_max_fields: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_max_order_by: {
      account: [349],
      clicks: [349],
      link: [349],
      referrer: [349],
      registrations: [349],
      website: [349],
      __typename: [8],
    },
    accounts_referral_min_fields: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_min_order_by: {
      account: [349],
      clicks: [349],
      link: [349],
      referrer: [349],
      registrations: [349],
      website: [349],
      __typename: [8],
    },
    accounts_referral_mutation_response: {
      affected_rows: [3],
      returning: [63],
      __typename: [8],
    },
    accounts_referral_on_conflict: {
      constraint: [73],
      update_columns: [96],
      where: [72],
      __typename: [8],
    },
    accounts_referral_order_by: {
      account: [349],
      clicks: [349],
      link: [349],
      referrer: [349],
      reg_account: [443],
      registrations: [349],
      website: [349],
      __typename: [8],
    },
    accounts_referral_pk_columns_input: {
      link: [8],
      __typename: [8],
    },
    accounts_referral_select_column: {},
    accounts_referral_set_input: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_stddev_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_stddev_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_stddev_pop_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_stddev_pop_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_stddev_samp_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_stddev_samp_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_stream_cursor_input: {
      initial_value: [93],
      ordering: [189],
      __typename: [8],
    },
    accounts_referral_stream_cursor_value_input: {
      account: [8],
      clicks: [3],
      link: [8],
      referrer: [8],
      registrations: [3],
      website: [8],
      __typename: [8],
    },
    accounts_referral_sum_fields: {
      clicks: [3],
      registrations: [3],
      __typename: [8],
    },
    accounts_referral_sum_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_update_column: {},
    accounts_referral_updates: {
      _inc: [74],
      _set: [85],
      where: [72],
      __typename: [8],
    },
    accounts_referral_var_pop_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_var_pop_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_var_samp_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_var_samp_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    accounts_referral_variance_fields: {
      clicks: [2],
      registrations: [2],
      __typename: [8],
    },
    accounts_referral_variance_order_by: {
      clicks: [349],
      registrations: [349],
      __typename: [8],
    },
    actions: {
      action: [8],
      auth: [
        223,
        {
          path: [8],
        },
      ],
      contract: [8],
      data: [
        223,
        {
          path: [8],
        },
      ],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_aggregate: {
      aggregate: [106],
      nodes: [104],
      __typename: [8],
    },
    actions_aggregate_fields: {
      count: [
        3,
        {
          columns: [121, '[actions_select_column!]'],
          distinct: [0],
        },
      ],
      max: [114],
      min: [115],
      __typename: [8],
    },
    actions_append_input: {
      auth: [223],
      data: [223],
      __typename: [8],
    },
    actions_bool_exp: {
      _and: [108],
      _not: [108],
      _or: [108],
      action: [10],
      auth: [225],
      contract: [10],
      data: [225],
      global_sequence: [10],
      transaction_id: [10],
      __typename: [8],
    },
    actions_constraint: {},
    actions_delete_at_path_input: {
      auth: [8],
      data: [8],
      __typename: [8],
    },
    actions_delete_elem_input: {
      auth: [3],
      data: [3],
      __typename: [8],
    },
    actions_delete_key_input: {
      auth: [8],
      data: [8],
      __typename: [8],
    },
    actions_insert_input: {
      action: [8],
      auth: [223],
      contract: [8],
      data: [223],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_max_fields: {
      action: [8],
      contract: [8],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_min_fields: {
      action: [8],
      contract: [8],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_mutation_response: {
      affected_rows: [3],
      returning: [104],
      __typename: [8],
    },
    actions_on_conflict: {
      constraint: [109],
      update_columns: [125],
      where: [108],
      __typename: [8],
    },
    actions_order_by: {
      action: [349],
      auth: [349],
      contract: [349],
      data: [349],
      global_sequence: [349],
      transaction_id: [349],
      __typename: [8],
    },
    actions_pk_columns_input: {
      global_sequence: [8],
      __typename: [8],
    },
    actions_prepend_input: {
      auth: [223],
      data: [223],
      __typename: [8],
    },
    actions_select_column: {},
    actions_set_input: {
      action: [8],
      auth: [223],
      contract: [8],
      data: [223],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_stream_cursor_input: {
      initial_value: [124],
      ordering: [189],
      __typename: [8],
    },
    actions_stream_cursor_value_input: {
      action: [8],
      auth: [223],
      contract: [8],
      data: [223],
      global_sequence: [8],
      transaction_id: [8],
      __typename: [8],
    },
    actions_update_column: {},
    actions_updates: {
      _append: [107],
      _delete_at_path: [110],
      _delete_elem: [111],
      _delete_key: [112],
      _prepend: [120],
      _set: [122],
      where: [108],
      __typename: [8],
    },
    avg_pay_time: {
      avg_pay_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_aggregate: {
      aggregate: [129],
      nodes: [127],
      __typename: [8],
    },
    avg_pay_time_aggregate_fields: {
      avg: [130],
      count: [
        3,
        {
          columns: [135, '[avg_pay_time_select_column!]'],
          distinct: [0],
        },
      ],
      max: [132],
      min: [133],
      stddev: [136],
      stddev_pop: [137],
      stddev_samp: [138],
      sum: [141],
      var_pop: [142],
      var_samp: [143],
      variance: [144],
      __typename: [8],
    },
    avg_pay_time_avg_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_bool_exp: {
      _and: [131],
      _not: [131],
      _or: [131],
      avg_pay_time_seconds: [348],
      user_id: [10],
      __typename: [8],
    },
    avg_pay_time_max_fields: {
      avg_pay_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_min_fields: {
      avg_pay_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_order_by: {
      avg_pay_time_seconds: [349],
      user_id: [349],
      __typename: [8],
    },
    avg_pay_time_select_column: {},
    avg_pay_time_stddev_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_stddev_pop_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_stddev_samp_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_stream_cursor_input: {
      initial_value: [140],
      ordering: [189],
      __typename: [8],
    },
    avg_pay_time_stream_cursor_value_input: {
      avg_pay_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_pay_time_sum_fields: {
      avg_pay_time_seconds: [347],
      __typename: [8],
    },
    avg_pay_time_var_pop_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_var_samp_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_pay_time_variance_fields: {
      avg_pay_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time: {
      avg_release_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_aggregate: {
      aggregate: [147],
      nodes: [145],
      __typename: [8],
    },
    avg_release_time_aggregate_fields: {
      avg: [148],
      count: [
        3,
        {
          columns: [153, '[avg_release_time_select_column!]'],
          distinct: [0],
        },
      ],
      max: [150],
      min: [151],
      stddev: [154],
      stddev_pop: [155],
      stddev_samp: [156],
      sum: [159],
      var_pop: [160],
      var_samp: [161],
      variance: [162],
      __typename: [8],
    },
    avg_release_time_avg_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_bool_exp: {
      _and: [149],
      _not: [149],
      _or: [149],
      avg_release_time_seconds: [348],
      user_id: [10],
      __typename: [8],
    },
    avg_release_time_max_fields: {
      avg_release_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_min_fields: {
      avg_release_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_order_by: {
      avg_release_time_seconds: [349],
      user_id: [349],
      __typename: [8],
    },
    avg_release_time_select_column: {},
    avg_release_time_stddev_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_stddev_pop_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_stddev_samp_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_stream_cursor_input: {
      initial_value: [158],
      ordering: [189],
      __typename: [8],
    },
    avg_release_time_stream_cursor_value_input: {
      avg_release_time_seconds: [347],
      user_id: [8],
      __typename: [8],
    },
    avg_release_time_sum_fields: {
      avg_release_time_seconds: [347],
      __typename: [8],
    },
    avg_release_time_var_pop_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_var_samp_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    avg_release_time_variance_fields: {
      avg_release_time_seconds: [2],
      __typename: [8],
    },
    bigint: {},
    bigint_comparison_exp: {
      _eq: [163],
      _gt: [163],
      _gte: [163],
      _in: [163],
      _is_null: [0],
      _lt: [163],
      _lte: [163],
      _neq: [163],
      _nin: [163],
      __typename: [8],
    },
    cancel_p2p_approval_input: {
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      id: [8],
      __typename: [8],
    },
    cancel_p2p_offer_input: {
      cancelled_by: [8],
      id: [8],
      __typename: [8],
    },
    cancel_wallet_request_approval_input: {
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      id: [8],
      __typename: [8],
    },
    cancel_wallet_request_input: {
      cancelled_by: [8],
      id: [8],
      __typename: [8],
    },
    combined_avg_times: {
      avg_pay_time_minutes: [347],
      avg_release_time_minutes: [347],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_aggregate: {
      aggregate: [171],
      nodes: [169],
      __typename: [8],
    },
    combined_avg_times_aggregate_fields: {
      avg: [172],
      count: [
        3,
        {
          columns: [177, '[combined_avg_times_select_column!]'],
          distinct: [0],
        },
      ],
      max: [174],
      min: [175],
      stddev: [178],
      stddev_pop: [179],
      stddev_samp: [180],
      sum: [183],
      var_pop: [184],
      var_samp: [185],
      variance: [186],
      __typename: [8],
    },
    combined_avg_times_avg_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_bool_exp: {
      _and: [173],
      _not: [173],
      _or: [173],
      avg_pay_time_minutes: [348],
      avg_release_time_minutes: [348],
      user_id: [10],
      __typename: [8],
    },
    combined_avg_times_max_fields: {
      avg_pay_time_minutes: [347],
      avg_release_time_minutes: [347],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_min_fields: {
      avg_pay_time_minutes: [347],
      avg_release_time_minutes: [347],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_order_by: {
      avg_pay_time_minutes: [349],
      avg_release_time_minutes: [349],
      user_id: [349],
      __typename: [8],
    },
    combined_avg_times_select_column: {},
    combined_avg_times_stddev_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_stddev_pop_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_stddev_samp_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_stream_cursor_input: {
      initial_value: [182],
      ordering: [189],
      __typename: [8],
    },
    combined_avg_times_stream_cursor_value_input: {
      avg_pay_time_minutes: [347],
      avg_release_time_minutes: [347],
      user_id: [8],
      __typename: [8],
    },
    combined_avg_times_sum_fields: {
      avg_pay_time_minutes: [347],
      avg_release_time_minutes: [347],
      __typename: [8],
    },
    combined_avg_times_var_pop_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_var_samp_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    combined_avg_times_variance_fields: {
      avg_pay_time_minutes: [2],
      avg_release_time_minutes: [2],
      __typename: [8],
    },
    confirm_p2p_offer_input: {
      buyer: [8],
      id: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      __typename: [8],
    },
    confirm_wallet_request_input: {
      buyer: [8],
      id: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      __typename: [8],
    },
    cursor_ordering: {},
    devices: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      reg_account: [432],
      __typename: [8],
    },
    devices_aggregate: {
      aggregate: [194],
      nodes: [190],
      __typename: [8],
    },
    devices_aggregate_bool_exp: {
      count: [193],
      __typename: [8],
    },
    devices_aggregate_bool_exp_count: {
      arguments: [208],
      distinct: [0],
      filter: [197],
      predicate: [4],
      __typename: [8],
    },
    devices_aggregate_fields: {
      count: [
        3,
        {
          columns: [208, '[devices_select_column!]'],
          distinct: [0],
        },
      ],
      max: [200],
      min: [202],
      __typename: [8],
    },
    devices_aggregate_order_by: {
      count: [349],
      max: [201],
      min: [203],
      __typename: [8],
    },
    devices_arr_rel_insert_input: {
      data: [199],
      on_conflict: [205],
      __typename: [8],
    },
    devices_bool_exp: {
      _and: [197],
      _not: [197],
      _or: [197],
      account: [10],
      created_at: [700],
      cred_id: [10],
      device_name: [10],
      public_key: [10],
      reg_account: [435],
      __typename: [8],
    },
    devices_constraint: {},
    devices_insert_input: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      reg_account: [441],
      __typename: [8],
    },
    devices_max_fields: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_max_order_by: {
      account: [349],
      created_at: [349],
      cred_id: [349],
      device_name: [349],
      public_key: [349],
      __typename: [8],
    },
    devices_min_fields: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_min_order_by: {
      account: [349],
      created_at: [349],
      cred_id: [349],
      device_name: [349],
      public_key: [349],
      __typename: [8],
    },
    devices_mutation_response: {
      affected_rows: [3],
      returning: [190],
      __typename: [8],
    },
    devices_on_conflict: {
      constraint: [198],
      update_columns: [212],
      where: [197],
      __typename: [8],
    },
    devices_order_by: {
      account: [349],
      created_at: [349],
      cred_id: [349],
      device_name: [349],
      public_key: [349],
      reg_account: [443],
      __typename: [8],
    },
    devices_pk_columns_input: {
      cred_id: [8],
      __typename: [8],
    },
    devices_select_column: {},
    devices_set_input: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_stream_cursor_input: {
      initial_value: [211],
      ordering: [189],
      __typename: [8],
    },
    devices_stream_cursor_value_input: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    devices_update_column: {},
    devices_updates: {
      _set: [209],
      where: [197],
      __typename: [8],
    },
    float8: {},
    float8_comparison_exp: {
      _eq: [214],
      _gt: [214],
      _gte: [214],
      _in: [214],
      _is_null: [0],
      _lt: [214],
      _lte: [214],
      _neq: [214],
      _nin: [214],
      __typename: [8],
    },
    get_inactive_accounts_args: {
      p_days_ago: [3],
      __typename: [8],
    },
    get_login_counts_args: {
      p_interval: [8],
      __typename: [8],
    },
    get_p2p_offers_with_old_or_no_messages_args: {
      completed_status: [0],
      hours: [3],
      matched_status: [0],
      __typename: [8],
    },
    get_tx_statistics_args: {
      p_interval: [8],
      __typename: [8],
    },
    get_volume_statistics_args: {
      p_interval: [8],
      __typename: [8],
    },
    id_verification_input: {
      country: [8],
      id: [8],
      type: [8],
      __typename: [8],
    },
    id_verification_output: {
      existingRecord: [0],
      __typename: [8],
    },
    jsonb: {},
    jsonb_cast_exp: {
      String: [10],
      __typename: [8],
    },
    jsonb_comparison_exp: {
      _cast: [224],
      _contained_in: [223],
      _contains: [223],
      _eq: [223],
      _gt: [223],
      _gte: [223],
      _has_key: [8],
      _has_keys_all: [8],
      _has_keys_any: [8],
      _in: [223],
      _is_null: [0],
      _lt: [223],
      _lte: [223],
      _neq: [223],
      _nin: [223],
      __typename: [8],
    },
    login_account: {
      account: [8],
      created_at: [699],
      id: [798],
      reg_account: [432],
      __typename: [8],
    },
    login_account_aggregate: {
      aggregate: [230],
      nodes: [226],
      __typename: [8],
    },
    login_account_aggregate_bool_exp: {
      count: [229],
      __typename: [8],
    },
    login_account_aggregate_bool_exp_count: {
      arguments: [244],
      distinct: [0],
      filter: [233],
      predicate: [4],
      __typename: [8],
    },
    login_account_aggregate_fields: {
      count: [
        3,
        {
          columns: [244, '[login_account_select_column!]'],
          distinct: [0],
        },
      ],
      max: [236],
      min: [238],
      __typename: [8],
    },
    login_account_aggregate_order_by: {
      count: [349],
      max: [237],
      min: [239],
      __typename: [8],
    },
    login_account_arr_rel_insert_input: {
      data: [235],
      on_conflict: [241],
      __typename: [8],
    },
    login_account_bool_exp: {
      _and: [233],
      _not: [233],
      _or: [233],
      account: [10],
      created_at: [700],
      id: [799],
      reg_account: [435],
      __typename: [8],
    },
    login_account_constraint: {},
    login_account_insert_input: {
      account: [8],
      created_at: [699],
      id: [798],
      reg_account: [441],
      __typename: [8],
    },
    login_account_max_fields: {
      account: [8],
      created_at: [699],
      id: [798],
      __typename: [8],
    },
    login_account_max_order_by: {
      account: [349],
      created_at: [349],
      id: [349],
      __typename: [8],
    },
    login_account_min_fields: {
      account: [8],
      created_at: [699],
      id: [798],
      __typename: [8],
    },
    login_account_min_order_by: {
      account: [349],
      created_at: [349],
      id: [349],
      __typename: [8],
    },
    login_account_mutation_response: {
      affected_rows: [3],
      returning: [226],
      __typename: [8],
    },
    login_account_on_conflict: {
      constraint: [234],
      update_columns: [248],
      where: [233],
      __typename: [8],
    },
    login_account_order_by: {
      account: [349],
      created_at: [349],
      id: [349],
      reg_account: [443],
      __typename: [8],
    },
    login_account_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    login_account_select_column: {},
    login_account_set_input: {
      account: [8],
      created_at: [699],
      id: [798],
      __typename: [8],
    },
    login_account_stream_cursor_input: {
      initial_value: [247],
      ordering: [189],
      __typename: [8],
    },
    login_account_stream_cursor_value_input: {
      account: [8],
      created_at: [699],
      id: [798],
      __typename: [8],
    },
    login_account_update_column: {},
    login_account_updates: {
      _set: [245],
      where: [233],
      __typename: [8],
    },
    login_counts: {
      accounts: [8],
      date: [699],
      login_count: [163],
      __typename: [8],
    },
    login_counts_aggregate: {
      aggregate: [252],
      nodes: [250],
      __typename: [8],
    },
    login_counts_aggregate_fields: {
      avg: [253],
      count: [
        3,
        {
          columns: [261, '[login_counts_select_column!]'],
          distinct: [0],
        },
      ],
      max: [257],
      min: [258],
      stddev: [263],
      stddev_pop: [264],
      stddev_samp: [265],
      sum: [268],
      var_pop: [270],
      var_samp: [271],
      variance: [272],
      __typename: [8],
    },
    login_counts_avg_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_bool_exp: {
      _and: [254],
      _not: [254],
      _or: [254],
      accounts: [9],
      date: [700],
      login_count: [164],
      __typename: [8],
    },
    login_counts_inc_input: {
      login_count: [163],
      __typename: [8],
    },
    login_counts_insert_input: {
      accounts: [8],
      date: [699],
      login_count: [163],
      __typename: [8],
    },
    login_counts_max_fields: {
      accounts: [8],
      date: [699],
      login_count: [163],
      __typename: [8],
    },
    login_counts_min_fields: {
      accounts: [8],
      date: [699],
      login_count: [163],
      __typename: [8],
    },
    login_counts_mutation_response: {
      affected_rows: [3],
      returning: [250],
      __typename: [8],
    },
    login_counts_order_by: {
      accounts: [349],
      date: [349],
      login_count: [349],
      __typename: [8],
    },
    login_counts_select_column: {},
    login_counts_set_input: {
      accounts: [8],
      date: [699],
      login_count: [163],
      __typename: [8],
    },
    login_counts_stddev_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_stddev_pop_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_stddev_samp_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_stream_cursor_input: {
      initial_value: [267],
      ordering: [189],
      __typename: [8],
    },
    login_counts_stream_cursor_value_input: {
      accounts: [8],
      date: [699],
      login_count: [163],
      __typename: [8],
    },
    login_counts_sum_fields: {
      login_count: [163],
      __typename: [8],
    },
    login_counts_updates: {
      _inc: [255],
      _set: [262],
      where: [254],
      __typename: [8],
    },
    login_counts_var_pop_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_var_samp_fields: {
      login_count: [2],
      __typename: [8],
    },
    login_counts_variance_fields: {
      login_count: [2],
      __typename: [8],
    },
    make_p2p_buy_offer_input: {
      amount: [8],
      buyer: [8],
      buyer_method_details: [8],
      id: [8],
      method: [8],
      region: [8],
      __typename: [8],
    },
    make_p2p_sell_offer_input: {
      amount: [8],
      bitcashbank_id: [8],
      id: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      __typename: [8],
    },
    make_wallet_deposit_request_input: {
      amount: [8],
      buyer: [8],
      buyer_method_details: [8],
      id: [8],
      method: [8],
      region: [8],
      __typename: [8],
    },
    make_wallet_withdraw_request_input: {
      amount: [8],
      bitcashbank_id: [8],
      id: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      __typename: [8],
    },
    messages: {
      from: [8],
      id: [798],
      message: [8],
      p2p_id: [798],
      p2p_offer: [351],
      regAccountByTo: [432],
      reg_account: [432],
      support_id: [798],
      swap_id: [798],
      swap_order: [544],
      timestamp: [699],
      to: [8],
      __typename: [8],
    },
    messages_aggregate: {
      aggregate: [281],
      nodes: [277],
      __typename: [8],
    },
    messages_aggregate_bool_exp: {
      count: [280],
      __typename: [8],
    },
    messages_aggregate_bool_exp_count: {
      arguments: [295],
      distinct: [0],
      filter: [284],
      predicate: [4],
      __typename: [8],
    },
    messages_aggregate_fields: {
      count: [
        3,
        {
          columns: [295, '[messages_select_column!]'],
          distinct: [0],
        },
      ],
      max: [287],
      min: [289],
      __typename: [8],
    },
    messages_aggregate_order_by: {
      count: [349],
      max: [288],
      min: [290],
      __typename: [8],
    },
    messages_arr_rel_insert_input: {
      data: [286],
      on_conflict: [292],
      __typename: [8],
    },
    messages_bool_exp: {
      _and: [284],
      _not: [284],
      _or: [284],
      from: [10],
      id: [799],
      message: [10],
      p2p_id: [799],
      p2p_offer: [360],
      regAccountByTo: [435],
      reg_account: [435],
      support_id: [799],
      swap_id: [799],
      swap_order: [563],
      timestamp: [700],
      to: [10],
      __typename: [8],
    },
    messages_constraint: {},
    messages_insert_input: {
      from: [8],
      id: [798],
      message: [8],
      p2p_id: [798],
      p2p_offer: [368],
      regAccountByTo: [441],
      reg_account: [441],
      support_id: [798],
      swap_id: [798],
      swap_order: [572],
      timestamp: [699],
      to: [8],
      __typename: [8],
    },
    messages_max_fields: {
      from: [8],
      id: [798],
      message: [8],
      p2p_id: [798],
      support_id: [798],
      swap_id: [798],
      timestamp: [699],
      to: [8],
      __typename: [8],
    },
    messages_max_order_by: {
      from: [349],
      id: [349],
      message: [349],
      p2p_id: [349],
      support_id: [349],
      swap_id: [349],
      timestamp: [349],
      to: [349],
      __typename: [8],
    },
    messages_min_fields: {
      from: [8],
      id: [798],
      message: [8],
      p2p_id: [798],
      support_id: [798],
      swap_id: [798],
      timestamp: [699],
      to: [8],
      __typename: [8],
    },
    messages_min_order_by: {
      from: [349],
      id: [349],
      message: [349],
      p2p_id: [349],
      support_id: [349],
      swap_id: [349],
      timestamp: [349],
      to: [349],
      __typename: [8],
    },
    messages_mutation_response: {
      affected_rows: [3],
      returning: [277],
      __typename: [8],
    },
    messages_on_conflict: {
      constraint: [285],
      update_columns: [299],
      where: [284],
      __typename: [8],
    },
    messages_order_by: {
      from: [349],
      id: [349],
      message: [349],
      p2p_id: [349],
      p2p_offer: [370],
      regAccountByTo: [443],
      reg_account: [443],
      support_id: [349],
      swap_id: [349],
      swap_order: [574],
      timestamp: [349],
      to: [349],
      __typename: [8],
    },
    messages_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    messages_select_column: {},
    messages_set_input: {
      from: [8],
      id: [798],
      message: [8],
      p2p_id: [798],
      support_id: [798],
      swap_id: [798],
      timestamp: [699],
      to: [8],
      __typename: [8],
    },
    messages_stream_cursor_input: {
      initial_value: [298],
      ordering: [189],
      __typename: [8],
    },
    messages_stream_cursor_value_input: {
      from: [8],
      id: [798],
      message: [8],
      p2p_id: [798],
      support_id: [798],
      swap_id: [798],
      timestamp: [699],
      to: [8],
      __typename: [8],
    },
    messages_update_column: {},
    messages_updates: {
      _set: [296],
      where: [284],
      __typename: [8],
    },
    migrate_device: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_aggregate: {
      aggregate: [303],
      nodes: [301],
      __typename: [8],
    },
    migrate_device_aggregate_fields: {
      count: [
        3,
        {
          columns: [313, '[migrate_device_select_column!]'],
          distinct: [0],
        },
      ],
      max: [307],
      min: [308],
      __typename: [8],
    },
    migrate_device_bool_exp: {
      _and: [304],
      _not: [304],
      _or: [304],
      account: [10],
      created_at: [700],
      cred_id: [10],
      device_name: [10],
      public_key: [10],
      __typename: [8],
    },
    migrate_device_constraint: {},
    migrate_device_insert_input: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_max_fields: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_min_fields: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_mutation_response: {
      affected_rows: [3],
      returning: [301],
      __typename: [8],
    },
    migrate_device_on_conflict: {
      constraint: [305],
      update_columns: [317],
      where: [304],
      __typename: [8],
    },
    migrate_device_order_by: {
      account: [349],
      created_at: [349],
      cred_id: [349],
      device_name: [349],
      public_key: [349],
      __typename: [8],
    },
    migrate_device_pk_columns_input: {
      cred_id: [8],
      __typename: [8],
    },
    migrate_device_select_column: {},
    migrate_device_set_input: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_stream_cursor_input: {
      initial_value: [316],
      ordering: [189],
      __typename: [8],
    },
    migrate_device_stream_cursor_value_input: {
      account: [8],
      created_at: [699],
      cred_id: [8],
      device_name: [8],
      public_key: [8],
      __typename: [8],
    },
    migrate_device_update_column: {},
    migrate_device_updates: {
      _set: [314],
      where: [304],
      __typename: [8],
    },
    notifications: {
      content_id: [798],
      created_at: [699],
      from: [8],
      id: [798],
      notifications_reg_account: [432],
      read: [0],
      reg_account: [432],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_aggregate: {
      aggregate: [325],
      nodes: [319],
      __typename: [8],
    },
    notifications_aggregate_bool_exp: {
      bool_and: [322],
      bool_or: [323],
      count: [324],
      __typename: [8],
    },
    notifications_aggregate_bool_exp_bool_and: {
      arguments: [340],
      distinct: [0],
      filter: [328],
      predicate: [1],
      __typename: [8],
    },
    notifications_aggregate_bool_exp_bool_or: {
      arguments: [341],
      distinct: [0],
      filter: [328],
      predicate: [1],
      __typename: [8],
    },
    notifications_aggregate_bool_exp_count: {
      arguments: [339],
      distinct: [0],
      filter: [328],
      predicate: [4],
      __typename: [8],
    },
    notifications_aggregate_fields: {
      count: [
        3,
        {
          columns: [339, '[notifications_select_column!]'],
          distinct: [0],
        },
      ],
      max: [331],
      min: [333],
      __typename: [8],
    },
    notifications_aggregate_order_by: {
      count: [349],
      max: [332],
      min: [334],
      __typename: [8],
    },
    notifications_arr_rel_insert_input: {
      data: [330],
      on_conflict: [336],
      __typename: [8],
    },
    notifications_bool_exp: {
      _and: [328],
      _not: [328],
      _or: [328],
      content_id: [799],
      created_at: [700],
      from: [10],
      id: [799],
      notifications_reg_account: [435],
      read: [1],
      reg_account: [435],
      to: [10],
      type: [10],
      __typename: [8],
    },
    notifications_constraint: {},
    notifications_insert_input: {
      content_id: [798],
      created_at: [699],
      from: [8],
      id: [798],
      notifications_reg_account: [441],
      read: [0],
      reg_account: [441],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_max_fields: {
      content_id: [798],
      created_at: [699],
      from: [8],
      id: [798],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_max_order_by: {
      content_id: [349],
      created_at: [349],
      from: [349],
      id: [349],
      to: [349],
      type: [349],
      __typename: [8],
    },
    notifications_min_fields: {
      content_id: [798],
      created_at: [699],
      from: [8],
      id: [798],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_min_order_by: {
      content_id: [349],
      created_at: [349],
      from: [349],
      id: [349],
      to: [349],
      type: [349],
      __typename: [8],
    },
    notifications_mutation_response: {
      affected_rows: [3],
      returning: [319],
      __typename: [8],
    },
    notifications_on_conflict: {
      constraint: [329],
      update_columns: [345],
      where: [328],
      __typename: [8],
    },
    notifications_order_by: {
      content_id: [349],
      created_at: [349],
      from: [349],
      id: [349],
      notifications_reg_account: [443],
      read: [349],
      reg_account: [443],
      to: [349],
      type: [349],
      __typename: [8],
    },
    notifications_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    notifications_select_column: {},
    notifications_select_column_notifications_aggregate_bool_exp_bool_and_arguments_columns: {},
    notifications_select_column_notifications_aggregate_bool_exp_bool_or_arguments_columns: {},
    notifications_set_input: {
      content_id: [798],
      created_at: [699],
      from: [8],
      id: [798],
      read: [0],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_stream_cursor_input: {
      initial_value: [344],
      ordering: [189],
      __typename: [8],
    },
    notifications_stream_cursor_value_input: {
      content_id: [798],
      created_at: [699],
      from: [8],
      id: [798],
      read: [0],
      to: [8],
      type: [8],
      __typename: [8],
    },
    notifications_update_column: {},
    notifications_updates: {
      _set: [342],
      where: [328],
      __typename: [8],
    },
    numeric: {},
    numeric_comparison_exp: {
      _eq: [347],
      _gt: [347],
      _gte: [347],
      _in: [347],
      _is_null: [0],
      _lt: [347],
      _lte: [347],
      _neq: [347],
      _nin: [347],
      __typename: [8],
    },
    order_by: {},
    p2p_offer_output: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [8],
      cancel_request_datetime: [8],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [8],
      id: [8],
      initiator: [8],
      matched: [0],
      matched_datetime: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [8],
      __typename: [8],
    },
    p2p_offers: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [699],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [699],
      cancel_request_datetime: [699],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [699],
      id: [798],
      initiator: [8],
      matched: [0],
      matched_datetime: [699],
      messages: [
        277,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_aggregate: [
        278,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      method: [8],
      original_offer_id: [798],
      regAccountByBuyer: [432],
      regAccountByCancellationApprovedBy: [432],
      regAccountByCancelledBy: [432],
      regAccountByInitiator: [432],
      regAccountBySeller: [432],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [699],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      system_notifications: [
        628,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      system_notifications_aggregate: [
        629,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      type: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_offers_aggregate: {
      aggregate: [357],
      nodes: [351],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp: {
      bool_and: [354],
      bool_or: [355],
      count: [356],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp_bool_and: {
      arguments: [373],
      distinct: [0],
      filter: [360],
      predicate: [1],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp_bool_or: {
      arguments: [374],
      distinct: [0],
      filter: [360],
      predicate: [1],
      __typename: [8],
    },
    p2p_offers_aggregate_bool_exp_count: {
      arguments: [372],
      distinct: [0],
      filter: [360],
      predicate: [4],
      __typename: [8],
    },
    p2p_offers_aggregate_fields: {
      count: [
        3,
        {
          columns: [372, '[p2p_offers_select_column!]'],
          distinct: [0],
        },
      ],
      max: [363],
      min: [365],
      __typename: [8],
    },
    p2p_offers_aggregate_order_by: {
      count: [349],
      max: [364],
      min: [366],
      __typename: [8],
    },
    p2p_offers_arr_rel_insert_input: {
      data: [362],
      on_conflict: [369],
      __typename: [8],
    },
    p2p_offers_bool_exp: {
      _and: [360],
      _not: [360],
      _or: [360],
      amount: [10],
      bitcashbank_id: [10],
      buyer: [10],
      buyer_confirmed_datetime: [700],
      buyer_confirmed_payment: [1],
      buyer_method_details: [10],
      cancel_approval_datetime: [700],
      cancel_request_datetime: [700],
      cancellation_approved_by: [10],
      cancellation_transaction: [10],
      cancelled: [1],
      cancelled_by: [10],
      completed: [1],
      created_at: [700],
      id: [799],
      initiator: [10],
      matched: [1],
      matched_datetime: [700],
      messages: [284],
      messages_aggregate: [279],
      method: [10],
      original_offer_id: [799],
      regAccountByBuyer: [435],
      regAccountByCancellationApprovedBy: [435],
      regAccountByCancelledBy: [435],
      regAccountByInitiator: [435],
      regAccountBySeller: [435],
      region: [10],
      reserved_by: [10],
      sell_put_transaction: [10],
      sell_settlement_transaction: [10],
      seller: [10],
      seller_confirmed_datetime: [700],
      seller_confirmed_payment: [1],
      seller_method_details: [10],
      system_notifications: [635],
      system_notifications_aggregate: [630],
      type: [10],
      updated_at: [700],
      __typename: [8],
    },
    p2p_offers_constraint: {},
    p2p_offers_insert_input: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [699],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [699],
      cancel_request_datetime: [699],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [699],
      id: [798],
      initiator: [8],
      matched: [0],
      matched_datetime: [699],
      messages: [283],
      method: [8],
      original_offer_id: [798],
      regAccountByBuyer: [441],
      regAccountByCancellationApprovedBy: [441],
      regAccountByCancelledBy: [441],
      regAccountByInitiator: [441],
      regAccountBySeller: [441],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [699],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      system_notifications: [634],
      type: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_offers_max_fields: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [699],
      buyer_method_details: [8],
      cancel_approval_datetime: [699],
      cancel_request_datetime: [699],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled_by: [8],
      created_at: [699],
      id: [798],
      initiator: [8],
      matched_datetime: [699],
      method: [8],
      original_offer_id: [798],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [699],
      seller_method_details: [8],
      type: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_offers_max_order_by: {
      amount: [349],
      bitcashbank_id: [349],
      buyer: [349],
      buyer_confirmed_datetime: [349],
      buyer_method_details: [349],
      cancel_approval_datetime: [349],
      cancel_request_datetime: [349],
      cancellation_approved_by: [349],
      cancellation_transaction: [349],
      cancelled_by: [349],
      created_at: [349],
      id: [349],
      initiator: [349],
      matched_datetime: [349],
      method: [349],
      original_offer_id: [349],
      region: [349],
      reserved_by: [349],
      sell_put_transaction: [349],
      sell_settlement_transaction: [349],
      seller: [349],
      seller_confirmed_datetime: [349],
      seller_method_details: [349],
      type: [349],
      updated_at: [349],
      __typename: [8],
    },
    p2p_offers_min_fields: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [699],
      buyer_method_details: [8],
      cancel_approval_datetime: [699],
      cancel_request_datetime: [699],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled_by: [8],
      created_at: [699],
      id: [798],
      initiator: [8],
      matched_datetime: [699],
      method: [8],
      original_offer_id: [798],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [699],
      seller_method_details: [8],
      type: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_offers_min_order_by: {
      amount: [349],
      bitcashbank_id: [349],
      buyer: [349],
      buyer_confirmed_datetime: [349],
      buyer_method_details: [349],
      cancel_approval_datetime: [349],
      cancel_request_datetime: [349],
      cancellation_approved_by: [349],
      cancellation_transaction: [349],
      cancelled_by: [349],
      created_at: [349],
      id: [349],
      initiator: [349],
      matched_datetime: [349],
      method: [349],
      original_offer_id: [349],
      region: [349],
      reserved_by: [349],
      sell_put_transaction: [349],
      sell_settlement_transaction: [349],
      seller: [349],
      seller_confirmed_datetime: [349],
      seller_method_details: [349],
      type: [349],
      updated_at: [349],
      __typename: [8],
    },
    p2p_offers_mutation_response: {
      affected_rows: [3],
      returning: [351],
      __typename: [8],
    },
    p2p_offers_obj_rel_insert_input: {
      data: [362],
      on_conflict: [369],
      __typename: [8],
    },
    p2p_offers_on_conflict: {
      constraint: [361],
      update_columns: [378],
      where: [360],
      __typename: [8],
    },
    p2p_offers_order_by: {
      amount: [349],
      bitcashbank_id: [349],
      buyer: [349],
      buyer_confirmed_datetime: [349],
      buyer_confirmed_payment: [349],
      buyer_method_details: [349],
      cancel_approval_datetime: [349],
      cancel_request_datetime: [349],
      cancellation_approved_by: [349],
      cancellation_transaction: [349],
      cancelled: [349],
      cancelled_by: [349],
      completed: [349],
      created_at: [349],
      id: [349],
      initiator: [349],
      matched: [349],
      matched_datetime: [349],
      messages_aggregate: [282],
      method: [349],
      original_offer_id: [349],
      regAccountByBuyer: [443],
      regAccountByCancellationApprovedBy: [443],
      regAccountByCancelledBy: [443],
      regAccountByInitiator: [443],
      regAccountBySeller: [443],
      region: [349],
      reserved_by: [349],
      sell_put_transaction: [349],
      sell_settlement_transaction: [349],
      seller: [349],
      seller_confirmed_datetime: [349],
      seller_confirmed_payment: [349],
      seller_method_details: [349],
      system_notifications_aggregate: [633],
      type: [349],
      updated_at: [349],
      __typename: [8],
    },
    p2p_offers_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    p2p_offers_select_column: {},
    p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_and_arguments_columns: {},
    p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_or_arguments_columns: {},
    p2p_offers_set_input: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [699],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [699],
      cancel_request_datetime: [699],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [699],
      id: [798],
      initiator: [8],
      matched: [0],
      matched_datetime: [699],
      method: [8],
      original_offer_id: [798],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [699],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_offers_stream_cursor_input: {
      initial_value: [377],
      ordering: [189],
      __typename: [8],
    },
    p2p_offers_stream_cursor_value_input: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_datetime: [699],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [699],
      cancel_request_datetime: [699],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [699],
      id: [798],
      initiator: [8],
      matched: [0],
      matched_datetime: [699],
      method: [8],
      original_offer_id: [798],
      region: [8],
      reserved_by: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_datetime: [699],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_offers_update_column: {},
    p2p_offers_updates: {
      _set: [375],
      where: [360],
      __typename: [8],
    },
    p2p_pre_sell_offers: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [699],
      id: [798],
      method: [8],
      processed: [0],
      reg_account: [432],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate: {
      aggregate: [386],
      nodes: [380],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp: {
      bool_and: [383],
      bool_or: [384],
      count: [385],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp_bool_and: {
      arguments: [401],
      distinct: [0],
      filter: [389],
      predicate: [1],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp_bool_or: {
      arguments: [402],
      distinct: [0],
      filter: [389],
      predicate: [1],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_bool_exp_count: {
      arguments: [400],
      distinct: [0],
      filter: [389],
      predicate: [4],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_fields: {
      count: [
        3,
        {
          columns: [400, '[p2p_pre_sell_offers_select_column!]'],
          distinct: [0],
        },
      ],
      max: [392],
      min: [394],
      __typename: [8],
    },
    p2p_pre_sell_offers_aggregate_order_by: {
      count: [349],
      max: [393],
      min: [395],
      __typename: [8],
    },
    p2p_pre_sell_offers_arr_rel_insert_input: {
      data: [391],
      on_conflict: [397],
      __typename: [8],
    },
    p2p_pre_sell_offers_bool_exp: {
      _and: [389],
      _not: [389],
      _or: [389],
      amount: [10],
      bitcashbank_id: [10],
      created_at: [700],
      id: [799],
      method: [10],
      processed: [1],
      reg_account: [435],
      region: [10],
      sell_put_transaction: [10],
      seller: [10],
      seller_method_details: [10],
      updated_at: [700],
      __typename: [8],
    },
    p2p_pre_sell_offers_constraint: {},
    p2p_pre_sell_offers_insert_input: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [699],
      id: [798],
      method: [8],
      processed: [0],
      reg_account: [441],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_pre_sell_offers_max_fields: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [699],
      id: [798],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_pre_sell_offers_max_order_by: {
      amount: [349],
      bitcashbank_id: [349],
      created_at: [349],
      id: [349],
      method: [349],
      region: [349],
      sell_put_transaction: [349],
      seller: [349],
      seller_method_details: [349],
      updated_at: [349],
      __typename: [8],
    },
    p2p_pre_sell_offers_min_fields: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [699],
      id: [798],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_pre_sell_offers_min_order_by: {
      amount: [349],
      bitcashbank_id: [349],
      created_at: [349],
      id: [349],
      method: [349],
      region: [349],
      sell_put_transaction: [349],
      seller: [349],
      seller_method_details: [349],
      updated_at: [349],
      __typename: [8],
    },
    p2p_pre_sell_offers_mutation_response: {
      affected_rows: [3],
      returning: [380],
      __typename: [8],
    },
    p2p_pre_sell_offers_on_conflict: {
      constraint: [390],
      update_columns: [406],
      where: [389],
      __typename: [8],
    },
    p2p_pre_sell_offers_order_by: {
      amount: [349],
      bitcashbank_id: [349],
      created_at: [349],
      id: [349],
      method: [349],
      processed: [349],
      reg_account: [443],
      region: [349],
      sell_put_transaction: [349],
      seller: [349],
      seller_method_details: [349],
      updated_at: [349],
      __typename: [8],
    },
    p2p_pre_sell_offers_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    p2p_pre_sell_offers_select_column: {},
    p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_and_arguments_columns:
      {},
    p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_or_arguments_columns:
      {},
    p2p_pre_sell_offers_set_input: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [699],
      id: [798],
      method: [8],
      processed: [0],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_pre_sell_offers_stream_cursor_input: {
      initial_value: [405],
      ordering: [189],
      __typename: [8],
    },
    p2p_pre_sell_offers_stream_cursor_value_input: {
      amount: [8],
      bitcashbank_id: [8],
      created_at: [699],
      id: [798],
      method: [8],
      processed: [0],
      region: [8],
      sell_put_transaction: [8],
      seller: [8],
      seller_method_details: [8],
      updated_at: [699],
      __typename: [8],
    },
    p2p_pre_sell_offers_update_column: {},
    p2p_pre_sell_offers_updates: {
      _set: [403],
      where: [389],
      __typename: [8],
    },
    photo_id_verification_output: {
      error: [0],
      __typename: [8],
    },
    preferences: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [
        223,
        {
          path: [8],
        },
      ],
      theme: [8],
      __typename: [8],
    },
    preferences_aggregate: {
      aggregate: [411],
      nodes: [409],
      __typename: [8],
    },
    preferences_aggregate_fields: {
      count: [
        3,
        {
          columns: [426, '[preferences_select_column!]'],
          distinct: [0],
        },
      ],
      max: [419],
      min: [420],
      __typename: [8],
    },
    preferences_append_input: {
      secondary_currency: [223],
      __typename: [8],
    },
    preferences_bool_exp: {
      _and: [413],
      _not: [413],
      _or: [413],
      account: [10],
      currency: [10],
      language: [10],
      notifications: [1],
      personalized: [1],
      region: [10],
      secondary_currency: [225],
      theme: [10],
      __typename: [8],
    },
    preferences_constraint: {},
    preferences_delete_at_path_input: {
      secondary_currency: [8],
      __typename: [8],
    },
    preferences_delete_elem_input: {
      secondary_currency: [3],
      __typename: [8],
    },
    preferences_delete_key_input: {
      secondary_currency: [8],
      __typename: [8],
    },
    preferences_insert_input: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [223],
      theme: [8],
      __typename: [8],
    },
    preferences_max_fields: {
      account: [8],
      currency: [8],
      language: [8],
      region: [8],
      theme: [8],
      __typename: [8],
    },
    preferences_min_fields: {
      account: [8],
      currency: [8],
      language: [8],
      region: [8],
      theme: [8],
      __typename: [8],
    },
    preferences_mutation_response: {
      affected_rows: [3],
      returning: [409],
      __typename: [8],
    },
    preferences_on_conflict: {
      constraint: [414],
      update_columns: [430],
      where: [413],
      __typename: [8],
    },
    preferences_order_by: {
      account: [349],
      currency: [349],
      language: [349],
      notifications: [349],
      personalized: [349],
      region: [349],
      secondary_currency: [349],
      theme: [349],
      __typename: [8],
    },
    preferences_pk_columns_input: {
      account: [8],
      __typename: [8],
    },
    preferences_prepend_input: {
      secondary_currency: [223],
      __typename: [8],
    },
    preferences_select_column: {},
    preferences_set_input: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [223],
      theme: [8],
      __typename: [8],
    },
    preferences_stream_cursor_input: {
      initial_value: [429],
      ordering: [189],
      __typename: [8],
    },
    preferences_stream_cursor_value_input: {
      account: [8],
      currency: [8],
      language: [8],
      notifications: [0],
      personalized: [0],
      region: [8],
      secondary_currency: [223],
      theme: [8],
      __typename: [8],
    },
    preferences_update_column: {},
    preferences_updates: {
      _append: [412],
      _delete_at_path: [415],
      _delete_elem: [416],
      _delete_key: [417],
      _prepend: [425],
      _set: [427],
      where: [413],
      __typename: [8],
    },
    reg_accounts: {
      account: [8],
      accounts_information: [29],
      accounts_referrals: [
        63,
        {
          distinct_on: [84, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [82, '[accounts_referral_order_by!]'],
          where: [72],
        },
      ],
      accounts_referrals_aggregate: [
        64,
        {
          distinct_on: [84, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [82, '[accounts_referral_order_by!]'],
          where: [72],
        },
      ],
      create_account: [0],
      created: [699],
      created_at: [697],
      cred_id: [8],
      device_name: [8],
      devices: [
        190,
        {
          distinct_on: [208, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [206, '[devices_order_by!]'],
          where: [197],
        },
      ],
      devices_aggregate: [
        191,
        {
          distinct_on: [208, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [206, '[devices_order_by!]'],
          where: [197],
        },
      ],
      freezed: [0],
      id: [798],
      is_verified: [0],
      login_accounts: [
        226,
        {
          distinct_on: [244, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [242, '[login_account_order_by!]'],
          where: [233],
        },
      ],
      login_accounts_aggregate: [
        227,
        {
          distinct_on: [244, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [242, '[login_account_order_by!]'],
          where: [233],
        },
      ],
      messages: [
        277,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messagesByTo: [
        277,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messagesByTo_aggregate: [
        278,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_aggregate: [
        278,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      notifications: [
        319,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notificationsByFrom: [
        319,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notificationsByFrom_aggregate: [
        320,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notifications_aggregate: [
        320,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      onboarded: [0],
      p2p_offers: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_buyer: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_buyer_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_initiator: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_initiator_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_seller: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_seller_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_pre_sell_offers: [
        380,
        {
          distinct_on: [400, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [398, '[p2p_pre_sell_offers_order_by!]'],
          where: [389],
        },
      ],
      p2p_pre_sell_offers_aggregate: [
        381,
        {
          distinct_on: [400, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [398, '[p2p_pre_sell_offers_order_by!]'],
          where: [389],
        },
      ],
      public_key: [8],
      referrer: [8],
      regAccountsByCancelledBy: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      regAccountsByCancelledBy_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      reg_accounts_accounts_information: [
        29,
        {
          distinct_on: [55, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [52, '[accounts_information_order_by!]'],
          where: [39],
        },
      ],
      reg_accounts_accounts_information_aggregate: [
        30,
        {
          distinct_on: [55, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [52, '[accounts_information_order_by!]'],
          where: [39],
        },
      ],
      reputation_score: [451],
      role: [8],
      source: [8],
      support_sessions: [
        502,
        {
          distinct_on: [520, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [518, '[support_sessions_order_by!]'],
          where: [509],
        },
      ],
      support_sessions_aggregate: [
        503,
        {
          distinct_on: [520, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [518, '[support_sessions_order_by!]'],
          where: [509],
        },
      ],
      swap_orders: [
        544,
        {
          distinct_on: [576, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [574, '[swap_orders_order_by!]'],
          where: [563],
        },
      ],
      swap_orders_aggregate: [
        545,
        {
          distinct_on: [576, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [574, '[swap_orders_order_by!]'],
          where: [563],
        },
      ],
      system_notification_read_statuses: [
        604,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_notification_read_statuses_aggregate: [
        605,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      trustNetworkNotificationsByTrust: [
        720,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trustNetworkNotificationsByTrust_aggregate: [
        721,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_network_notifications: [
        720,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_network_notifications_aggregate: [
        721,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_networks_by_account: [
        703,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_networks_by_account_aggregate: [
        704,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_networks_by_trust: [
        703,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_networks_by_trust_aggregate: [
        704,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_aggregate: {
      aggregate: [434],
      nodes: [432],
      __typename: [8],
    },
    reg_accounts_aggregate_fields: {
      count: [
        3,
        {
          columns: [445, '[reg_accounts_select_column!]'],
          distinct: [0],
        },
      ],
      max: [438],
      min: [439],
      __typename: [8],
    },
    reg_accounts_bool_exp: {
      _and: [435],
      _not: [435],
      _or: [435],
      account: [10],
      accounts_information: [39],
      accounts_referrals: [72],
      accounts_referrals_aggregate: [65],
      create_account: [1],
      created: [700],
      created_at: [698],
      cred_id: [10],
      device_name: [10],
      devices: [197],
      devices_aggregate: [192],
      freezed: [1],
      id: [799],
      is_verified: [1],
      login_accounts: [233],
      login_accounts_aggregate: [228],
      messages: [284],
      messagesByTo: [284],
      messagesByTo_aggregate: [279],
      messages_aggregate: [279],
      notifications: [328],
      notificationsByFrom: [328],
      notificationsByFrom_aggregate: [321],
      notifications_aggregate: [321],
      onboarded: [1],
      p2p_offers: [360],
      p2p_offers_aggregate: [353],
      p2p_offers_buyer: [360],
      p2p_offers_buyer_aggregate: [353],
      p2p_offers_initiator: [360],
      p2p_offers_initiator_aggregate: [353],
      p2p_offers_seller: [360],
      p2p_offers_seller_aggregate: [353],
      p2p_pre_sell_offers: [389],
      p2p_pre_sell_offers_aggregate: [382],
      public_key: [10],
      referrer: [10],
      regAccountsByCancelledBy: [360],
      regAccountsByCancelledBy_aggregate: [353],
      reg_accounts_accounts_information: [39],
      reg_accounts_accounts_information_aggregate: [31],
      reputation_score: [455],
      role: [10],
      source: [10],
      support_sessions: [509],
      support_sessions_aggregate: [504],
      swap_orders: [563],
      swap_orders_aggregate: [546],
      system_notification_read_statuses: [611],
      system_notification_read_statuses_aggregate: [606],
      trustNetworkNotificationsByTrust: [727],
      trustNetworkNotificationsByTrust_aggregate: [722],
      trust_network_notifications: [727],
      trust_network_notifications_aggregate: [722],
      trust_networks_by_account: [712],
      trust_networks_by_account_aggregate: [705],
      trust_networks_by_trust: [712],
      trust_networks_by_trust_aggregate: [705],
      txid: [10],
      __typename: [8],
    },
    reg_accounts_constraint: {},
    reg_accounts_insert_input: {
      account: [8],
      accounts_information: [50],
      accounts_referrals: [69],
      create_account: [0],
      created: [699],
      created_at: [697],
      cred_id: [8],
      device_name: [8],
      devices: [196],
      freezed: [0],
      id: [798],
      is_verified: [0],
      login_accounts: [232],
      messages: [283],
      messagesByTo: [283],
      notifications: [327],
      notificationsByFrom: [327],
      onboarded: [0],
      p2p_offers: [359],
      p2p_offers_buyer: [359],
      p2p_offers_initiator: [359],
      p2p_offers_seller: [359],
      p2p_pre_sell_offers: [388],
      public_key: [8],
      referrer: [8],
      regAccountsByCancelledBy: [359],
      reg_accounts_accounts_information: [38],
      reputation_score: [462],
      role: [8],
      source: [8],
      support_sessions: [508],
      swap_orders: [560],
      system_notification_read_statuses: [610],
      trustNetworkNotificationsByTrust: [726],
      trust_network_notifications: [726],
      trust_networks_by_account: [711],
      trust_networks_by_trust: [711],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_max_fields: {
      account: [8],
      created: [699],
      created_at: [697],
      cred_id: [8],
      device_name: [8],
      id: [798],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_min_fields: {
      account: [8],
      created: [699],
      created_at: [697],
      cred_id: [8],
      device_name: [8],
      id: [798],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_mutation_response: {
      affected_rows: [3],
      returning: [432],
      __typename: [8],
    },
    reg_accounts_obj_rel_insert_input: {
      data: [437],
      on_conflict: [442],
      __typename: [8],
    },
    reg_accounts_on_conflict: {
      constraint: [436],
      update_columns: [449],
      where: [435],
      __typename: [8],
    },
    reg_accounts_order_by: {
      account: [349],
      accounts_information: [52],
      accounts_referrals_aggregate: [68],
      create_account: [349],
      created: [349],
      created_at: [349],
      cred_id: [349],
      device_name: [349],
      devices_aggregate: [195],
      freezed: [349],
      id: [349],
      is_verified: [349],
      login_accounts_aggregate: [231],
      messagesByTo_aggregate: [282],
      messages_aggregate: [282],
      notificationsByFrom_aggregate: [326],
      notifications_aggregate: [326],
      onboarded: [349],
      p2p_offers_aggregate: [358],
      p2p_offers_buyer_aggregate: [358],
      p2p_offers_initiator_aggregate: [358],
      p2p_offers_seller_aggregate: [358],
      p2p_pre_sell_offers_aggregate: [387],
      public_key: [349],
      referrer: [349],
      regAccountsByCancelledBy_aggregate: [358],
      reg_accounts_accounts_information_aggregate: [36],
      reputation_score: [464],
      role: [349],
      source: [349],
      support_sessions_aggregate: [507],
      swap_orders_aggregate: [559],
      system_notification_read_statuses_aggregate: [609],
      trustNetworkNotificationsByTrust_aggregate: [725],
      trust_network_notifications_aggregate: [725],
      trust_networks_by_account_aggregate: [710],
      trust_networks_by_trust_aggregate: [710],
      txid: [349],
      __typename: [8],
    },
    reg_accounts_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    reg_accounts_select_column: {},
    reg_accounts_set_input: {
      account: [8],
      create_account: [0],
      created: [699],
      created_at: [697],
      cred_id: [8],
      device_name: [8],
      freezed: [0],
      id: [798],
      is_verified: [0],
      onboarded: [0],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_stream_cursor_input: {
      initial_value: [448],
      ordering: [189],
      __typename: [8],
    },
    reg_accounts_stream_cursor_value_input: {
      account: [8],
      create_account: [0],
      created: [699],
      created_at: [697],
      cred_id: [8],
      device_name: [8],
      freezed: [0],
      id: [798],
      is_verified: [0],
      onboarded: [0],
      public_key: [8],
      referrer: [8],
      role: [8],
      source: [8],
      txid: [8],
      __typename: [8],
    },
    reg_accounts_update_column: {},
    reg_accounts_updates: {
      _set: [446],
      where: [435],
      __typename: [8],
    },
    reputation_score: {
      account: [8],
      reg_account: [432],
      score: [347],
      updated_at: [699],
      __typename: [8],
    },
    reputation_score_aggregate: {
      aggregate: [453],
      nodes: [451],
      __typename: [8],
    },
    reputation_score_aggregate_fields: {
      avg: [454],
      count: [
        3,
        {
          columns: [466, '[reputation_score_select_column!]'],
          distinct: [0],
        },
      ],
      max: [459],
      min: [460],
      stddev: [468],
      stddev_pop: [469],
      stddev_samp: [470],
      sum: [473],
      var_pop: [476],
      var_samp: [477],
      variance: [478],
      __typename: [8],
    },
    reputation_score_avg_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_bool_exp: {
      _and: [455],
      _not: [455],
      _or: [455],
      account: [10],
      reg_account: [435],
      score: [348],
      updated_at: [700],
      __typename: [8],
    },
    reputation_score_constraint: {},
    reputation_score_inc_input: {
      score: [347],
      __typename: [8],
    },
    reputation_score_insert_input: {
      account: [8],
      reg_account: [441],
      score: [347],
      updated_at: [699],
      __typename: [8],
    },
    reputation_score_max_fields: {
      account: [8],
      score: [347],
      updated_at: [699],
      __typename: [8],
    },
    reputation_score_min_fields: {
      account: [8],
      score: [347],
      updated_at: [699],
      __typename: [8],
    },
    reputation_score_mutation_response: {
      affected_rows: [3],
      returning: [451],
      __typename: [8],
    },
    reputation_score_obj_rel_insert_input: {
      data: [458],
      on_conflict: [463],
      __typename: [8],
    },
    reputation_score_on_conflict: {
      constraint: [456],
      update_columns: [474],
      where: [455],
      __typename: [8],
    },
    reputation_score_order_by: {
      account: [349],
      reg_account: [443],
      score: [349],
      updated_at: [349],
      __typename: [8],
    },
    reputation_score_pk_columns_input: {
      account: [8],
      __typename: [8],
    },
    reputation_score_select_column: {},
    reputation_score_set_input: {
      account: [8],
      score: [347],
      updated_at: [699],
      __typename: [8],
    },
    reputation_score_stddev_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_stddev_pop_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_stddev_samp_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_stream_cursor_input: {
      initial_value: [472],
      ordering: [189],
      __typename: [8],
    },
    reputation_score_stream_cursor_value_input: {
      account: [8],
      score: [347],
      updated_at: [699],
      __typename: [8],
    },
    reputation_score_sum_fields: {
      score: [347],
      __typename: [8],
    },
    reputation_score_update_column: {},
    reputation_score_updates: {
      _inc: [457],
      _set: [467],
      where: [455],
      __typename: [8],
    },
    reputation_score_var_pop_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_var_samp_fields: {
      score: [2],
      __typename: [8],
    },
    reputation_score_variance_fields: {
      score: [2],
      __typename: [8],
    },
    request_new_account_input: {
      account: [8],
      country_id: [5],
      create_account: [0],
      cred_id: [8],
      device_name: [8],
      email_address: [8],
      full_name: [8],
      newsletter_subscription: [0],
      phone_number: [8],
      photo_id: [8],
      public_key: [8],
      referrer: [8],
      selfie: [8],
      source: [8],
      __typename: [8],
    },
    request_new_account_output: {
      id: [8],
      __typename: [8],
    },
    selfie_verification_output: {
      error: [0],
      eye: [0],
      face: [0],
      __typename: [8],
    },
    short_link_input: {
      referrer: [8],
      website: [8],
      __typename: [8],
    },
    signing_requests: {
      createdAt: [699],
      esr: [8],
      id: [798],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [699],
      __typename: [8],
    },
    signing_requests_aggregate: {
      aggregate: [485],
      nodes: [483],
      __typename: [8],
    },
    signing_requests_aggregate_fields: {
      count: [
        3,
        {
          columns: [495, '[signing_requests_select_column!]'],
          distinct: [0],
        },
      ],
      max: [489],
      min: [490],
      __typename: [8],
    },
    signing_requests_bool_exp: {
      _and: [486],
      _not: [486],
      _or: [486],
      createdAt: [700],
      esr: [10],
      id: [799],
      signature: [10],
      signer: [10],
      status: [10],
      transactionId: [10],
      updatedAt: [700],
      __typename: [8],
    },
    signing_requests_constraint: {},
    signing_requests_insert_input: {
      createdAt: [699],
      esr: [8],
      id: [798],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [699],
      __typename: [8],
    },
    signing_requests_max_fields: {
      createdAt: [699],
      esr: [8],
      id: [798],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [699],
      __typename: [8],
    },
    signing_requests_min_fields: {
      createdAt: [699],
      esr: [8],
      id: [798],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [699],
      __typename: [8],
    },
    signing_requests_mutation_response: {
      affected_rows: [3],
      returning: [483],
      __typename: [8],
    },
    signing_requests_on_conflict: {
      constraint: [487],
      update_columns: [499],
      where: [486],
      __typename: [8],
    },
    signing_requests_order_by: {
      createdAt: [349],
      esr: [349],
      id: [349],
      signature: [349],
      signer: [349],
      status: [349],
      transactionId: [349],
      updatedAt: [349],
      __typename: [8],
    },
    signing_requests_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    signing_requests_select_column: {},
    signing_requests_set_input: {
      createdAt: [699],
      esr: [8],
      id: [798],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [699],
      __typename: [8],
    },
    signing_requests_stream_cursor_input: {
      initial_value: [498],
      ordering: [189],
      __typename: [8],
    },
    signing_requests_stream_cursor_value_input: {
      createdAt: [699],
      esr: [8],
      id: [798],
      signature: [8],
      signer: [8],
      status: [8],
      transactionId: [8],
      updatedAt: [699],
      __typename: [8],
    },
    signing_requests_update_column: {},
    signing_requests_updates: {
      _set: [496],
      where: [486],
      __typename: [8],
    },
    store_ip_address_output: {
      success: [0],
      __typename: [8],
    },
    support_sessions: {
      assistant: [8],
      closed_by: [8],
      created_at: [699],
      id: [798],
      reg_account: [432],
      session_close_datetime: [699],
      session_open_datetime: [699],
      status: [8],
      updated_at: [699],
      user: [8],
      __typename: [8],
    },
    support_sessions_aggregate: {
      aggregate: [506],
      nodes: [502],
      __typename: [8],
    },
    support_sessions_aggregate_bool_exp: {
      count: [505],
      __typename: [8],
    },
    support_sessions_aggregate_bool_exp_count: {
      arguments: [520],
      distinct: [0],
      filter: [509],
      predicate: [4],
      __typename: [8],
    },
    support_sessions_aggregate_fields: {
      count: [
        3,
        {
          columns: [520, '[support_sessions_select_column!]'],
          distinct: [0],
        },
      ],
      max: [512],
      min: [514],
      __typename: [8],
    },
    support_sessions_aggregate_order_by: {
      count: [349],
      max: [513],
      min: [515],
      __typename: [8],
    },
    support_sessions_arr_rel_insert_input: {
      data: [511],
      on_conflict: [517],
      __typename: [8],
    },
    support_sessions_bool_exp: {
      _and: [509],
      _not: [509],
      _or: [509],
      assistant: [10],
      closed_by: [10],
      created_at: [700],
      id: [799],
      reg_account: [435],
      session_close_datetime: [700],
      session_open_datetime: [700],
      status: [10],
      updated_at: [700],
      user: [10],
      __typename: [8],
    },
    support_sessions_constraint: {},
    support_sessions_insert_input: {
      assistant: [8],
      closed_by: [8],
      created_at: [699],
      id: [798],
      reg_account: [441],
      session_close_datetime: [699],
      session_open_datetime: [699],
      status: [8],
      updated_at: [699],
      user: [8],
      __typename: [8],
    },
    support_sessions_max_fields: {
      assistant: [8],
      closed_by: [8],
      created_at: [699],
      id: [798],
      session_close_datetime: [699],
      session_open_datetime: [699],
      status: [8],
      updated_at: [699],
      user: [8],
      __typename: [8],
    },
    support_sessions_max_order_by: {
      assistant: [349],
      closed_by: [349],
      created_at: [349],
      id: [349],
      session_close_datetime: [349],
      session_open_datetime: [349],
      status: [349],
      updated_at: [349],
      user: [349],
      __typename: [8],
    },
    support_sessions_min_fields: {
      assistant: [8],
      closed_by: [8],
      created_at: [699],
      id: [798],
      session_close_datetime: [699],
      session_open_datetime: [699],
      status: [8],
      updated_at: [699],
      user: [8],
      __typename: [8],
    },
    support_sessions_min_order_by: {
      assistant: [349],
      closed_by: [349],
      created_at: [349],
      id: [349],
      session_close_datetime: [349],
      session_open_datetime: [349],
      status: [349],
      updated_at: [349],
      user: [349],
      __typename: [8],
    },
    support_sessions_mutation_response: {
      affected_rows: [3],
      returning: [502],
      __typename: [8],
    },
    support_sessions_on_conflict: {
      constraint: [510],
      update_columns: [524],
      where: [509],
      __typename: [8],
    },
    support_sessions_order_by: {
      assistant: [349],
      closed_by: [349],
      created_at: [349],
      id: [349],
      reg_account: [443],
      session_close_datetime: [349],
      session_open_datetime: [349],
      status: [349],
      updated_at: [349],
      user: [349],
      __typename: [8],
    },
    support_sessions_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    support_sessions_select_column: {},
    support_sessions_set_input: {
      assistant: [8],
      closed_by: [8],
      created_at: [699],
      id: [798],
      session_close_datetime: [699],
      session_open_datetime: [699],
      status: [8],
      updated_at: [699],
      user: [8],
      __typename: [8],
    },
    support_sessions_stream_cursor_input: {
      initial_value: [523],
      ordering: [189],
      __typename: [8],
    },
    support_sessions_stream_cursor_value_input: {
      assistant: [8],
      closed_by: [8],
      created_at: [699],
      id: [798],
      session_close_datetime: [699],
      session_open_datetime: [699],
      status: [8],
      updated_at: [699],
      user: [8],
      __typename: [8],
    },
    support_sessions_update_column: {},
    support_sessions_updates: {
      _set: [521],
      where: [509],
      __typename: [8],
    },
    swap_assets: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_aggregate: {
      aggregate: [528],
      nodes: [526],
      __typename: [8],
    },
    swap_assets_aggregate_fields: {
      count: [
        3,
        {
          columns: [538, '[swap_assets_select_column!]'],
          distinct: [0],
        },
      ],
      max: [532],
      min: [533],
      __typename: [8],
    },
    swap_assets_bool_exp: {
      _and: [529],
      _not: [529],
      _or: [529],
      active_swaps: [1],
      asset: [10],
      asset_name: [10],
      chain: [10],
      wallet_address: [10],
      __typename: [8],
    },
    swap_assets_constraint: {},
    swap_assets_insert_input: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_max_fields: {
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_min_fields: {
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_mutation_response: {
      affected_rows: [3],
      returning: [526],
      __typename: [8],
    },
    swap_assets_on_conflict: {
      constraint: [530],
      update_columns: [542],
      where: [529],
      __typename: [8],
    },
    swap_assets_order_by: {
      active_swaps: [349],
      asset: [349],
      asset_name: [349],
      chain: [349],
      wallet_address: [349],
      __typename: [8],
    },
    swap_assets_pk_columns_input: {
      asset: [8],
      chain: [8],
      __typename: [8],
    },
    swap_assets_select_column: {},
    swap_assets_set_input: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_stream_cursor_input: {
      initial_value: [541],
      ordering: [189],
      __typename: [8],
    },
    swap_assets_stream_cursor_value_input: {
      active_swaps: [0],
      asset: [8],
      asset_name: [8],
      chain: [8],
      wallet_address: [8],
      __typename: [8],
    },
    swap_assets_update_column: {},
    swap_assets_updates: {
      _set: [539],
      where: [529],
      __typename: [8],
    },
    swap_orders: {
      asset: [8],
      asset_amount: [214],
      bitcash_account: [8],
      bitcash_amount: [214],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [699],
      gems_id: [8],
      id: [798],
      messages: [
        277,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_aggregate: [
        278,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      order_status: [8],
      order_type: [8],
      price: [214],
      reg_account: [432],
      updated_at: [699],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_aggregate: {
      aggregate: [558],
      nodes: [544],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp: {
      avg: [547],
      corr: [548],
      count: [550],
      covar_samp: [551],
      max: [553],
      min: [554],
      stddev_samp: [555],
      sum: [556],
      var_samp: [557],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_avg: {
      arguments: [577],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_corr: {
      arguments: [549],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_corr_arguments: {
      X: [578],
      Y: [578],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_count: {
      arguments: [576],
      distinct: [0],
      filter: [563],
      predicate: [4],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_covar_samp: {
      arguments: [552],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_covar_samp_arguments: {
      X: [579],
      Y: [579],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_max: {
      arguments: [580],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_min: {
      arguments: [581],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_stddev_samp: {
      arguments: [582],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_sum: {
      arguments: [583],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_bool_exp_var_samp: {
      arguments: [584],
      distinct: [0],
      filter: [563],
      predicate: [215],
      __typename: [8],
    },
    swap_orders_aggregate_fields: {
      avg: [561],
      count: [
        3,
        {
          columns: [576, '[swap_orders_select_column!]'],
          distinct: [0],
        },
      ],
      max: [567],
      min: [569],
      stddev: [586],
      stddev_pop: [588],
      stddev_samp: [590],
      sum: [594],
      var_pop: [598],
      var_samp: [600],
      variance: [602],
      __typename: [8],
    },
    swap_orders_aggregate_order_by: {
      avg: [562],
      count: [349],
      max: [568],
      min: [570],
      stddev: [587],
      stddev_pop: [589],
      stddev_samp: [591],
      sum: [595],
      var_pop: [599],
      var_samp: [601],
      variance: [603],
      __typename: [8],
    },
    swap_orders_arr_rel_insert_input: {
      data: [566],
      on_conflict: [573],
      __typename: [8],
    },
    swap_orders_avg_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_avg_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_bool_exp: {
      _and: [563],
      _not: [563],
      _or: [563],
      asset: [10],
      asset_amount: [215],
      bitcash_account: [10],
      bitcash_amount: [215],
      bitcash_currency: [10],
      bitcash_trx: [10],
      created_at: [700],
      gems_id: [10],
      id: [799],
      messages: [284],
      messages_aggregate: [279],
      order_status: [10],
      order_type: [10],
      price: [215],
      reg_account: [435],
      updated_at: [700],
      wallet_address: [10],
      __typename: [8],
    },
    swap_orders_constraint: {},
    swap_orders_inc_input: {
      asset_amount: [214],
      bitcash_amount: [214],
      price: [214],
      __typename: [8],
    },
    swap_orders_insert_input: {
      asset: [8],
      asset_amount: [214],
      bitcash_account: [8],
      bitcash_amount: [214],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [699],
      gems_id: [8],
      id: [798],
      messages: [283],
      order_status: [8],
      order_type: [8],
      price: [214],
      reg_account: [441],
      updated_at: [699],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_max_fields: {
      asset: [8],
      asset_amount: [214],
      bitcash_account: [8],
      bitcash_amount: [214],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [699],
      gems_id: [8],
      id: [798],
      order_status: [8],
      order_type: [8],
      price: [214],
      updated_at: [699],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_max_order_by: {
      asset: [349],
      asset_amount: [349],
      bitcash_account: [349],
      bitcash_amount: [349],
      bitcash_currency: [349],
      bitcash_trx: [349],
      created_at: [349],
      gems_id: [349],
      id: [349],
      order_status: [349],
      order_type: [349],
      price: [349],
      updated_at: [349],
      wallet_address: [349],
      __typename: [8],
    },
    swap_orders_min_fields: {
      asset: [8],
      asset_amount: [214],
      bitcash_account: [8],
      bitcash_amount: [214],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [699],
      gems_id: [8],
      id: [798],
      order_status: [8],
      order_type: [8],
      price: [214],
      updated_at: [699],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_min_order_by: {
      asset: [349],
      asset_amount: [349],
      bitcash_account: [349],
      bitcash_amount: [349],
      bitcash_currency: [349],
      bitcash_trx: [349],
      created_at: [349],
      gems_id: [349],
      id: [349],
      order_status: [349],
      order_type: [349],
      price: [349],
      updated_at: [349],
      wallet_address: [349],
      __typename: [8],
    },
    swap_orders_mutation_response: {
      affected_rows: [3],
      returning: [544],
      __typename: [8],
    },
    swap_orders_obj_rel_insert_input: {
      data: [566],
      on_conflict: [573],
      __typename: [8],
    },
    swap_orders_on_conflict: {
      constraint: [564],
      update_columns: [596],
      where: [563],
      __typename: [8],
    },
    swap_orders_order_by: {
      asset: [349],
      asset_amount: [349],
      bitcash_account: [349],
      bitcash_amount: [349],
      bitcash_currency: [349],
      bitcash_trx: [349],
      created_at: [349],
      gems_id: [349],
      id: [349],
      messages_aggregate: [282],
      order_status: [349],
      order_type: [349],
      price: [349],
      reg_account: [443],
      updated_at: [349],
      wallet_address: [349],
      __typename: [8],
    },
    swap_orders_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    swap_orders_select_column: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_avg_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_corr_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_covar_samp_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_max_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_min_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_sum_arguments_columns: {},
    swap_orders_select_column_swap_orders_aggregate_bool_exp_var_samp_arguments_columns: {},
    swap_orders_set_input: {
      asset: [8],
      asset_amount: [214],
      bitcash_account: [8],
      bitcash_amount: [214],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [699],
      gems_id: [8],
      id: [798],
      order_status: [8],
      order_type: [8],
      price: [214],
      updated_at: [699],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_stddev_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_stddev_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_stddev_pop_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_stddev_pop_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_stddev_samp_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_stddev_samp_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_stream_cursor_input: {
      initial_value: [593],
      ordering: [189],
      __typename: [8],
    },
    swap_orders_stream_cursor_value_input: {
      asset: [8],
      asset_amount: [214],
      bitcash_account: [8],
      bitcash_amount: [214],
      bitcash_currency: [8],
      bitcash_trx: [8],
      created_at: [699],
      gems_id: [8],
      id: [798],
      order_status: [8],
      order_type: [8],
      price: [214],
      updated_at: [699],
      wallet_address: [8],
      __typename: [8],
    },
    swap_orders_sum_fields: {
      asset_amount: [214],
      bitcash_amount: [214],
      price: [214],
      __typename: [8],
    },
    swap_orders_sum_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_update_column: {},
    swap_orders_updates: {
      _inc: [565],
      _set: [585],
      where: [563],
      __typename: [8],
    },
    swap_orders_var_pop_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_var_pop_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_var_samp_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_var_samp_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    swap_orders_variance_fields: {
      asset_amount: [2],
      bitcash_amount: [2],
      price: [2],
      __typename: [8],
    },
    swap_orders_variance_order_by: {
      asset_amount: [349],
      bitcash_amount: [349],
      price: [349],
      __typename: [8],
    },
    system_notification_read_status: {
      created_at: [699],
      notification_id: [798],
      regAccountByRegAccount: [432],
      reg_account: [798],
      system_notification: [628],
      __typename: [8],
    },
    system_notification_read_status_aggregate: {
      aggregate: [608],
      nodes: [604],
      __typename: [8],
    },
    system_notification_read_status_aggregate_bool_exp: {
      count: [607],
      __typename: [8],
    },
    system_notification_read_status_aggregate_bool_exp_count: {
      arguments: [622],
      distinct: [0],
      filter: [611],
      predicate: [4],
      __typename: [8],
    },
    system_notification_read_status_aggregate_fields: {
      count: [
        3,
        {
          columns: [622, '[system_notification_read_status_select_column!]'],
          distinct: [0],
        },
      ],
      max: [614],
      min: [616],
      __typename: [8],
    },
    system_notification_read_status_aggregate_order_by: {
      count: [349],
      max: [615],
      min: [617],
      __typename: [8],
    },
    system_notification_read_status_arr_rel_insert_input: {
      data: [613],
      on_conflict: [619],
      __typename: [8],
    },
    system_notification_read_status_bool_exp: {
      _and: [611],
      _not: [611],
      _or: [611],
      created_at: [700],
      notification_id: [799],
      regAccountByRegAccount: [435],
      reg_account: [799],
      system_notification: [635],
      __typename: [8],
    },
    system_notification_read_status_constraint: {},
    system_notification_read_status_insert_input: {
      created_at: [699],
      notification_id: [798],
      regAccountByRegAccount: [441],
      reg_account: [798],
      system_notification: [643],
      __typename: [8],
    },
    system_notification_read_status_max_fields: {
      created_at: [699],
      notification_id: [798],
      reg_account: [798],
      __typename: [8],
    },
    system_notification_read_status_max_order_by: {
      created_at: [349],
      notification_id: [349],
      reg_account: [349],
      __typename: [8],
    },
    system_notification_read_status_min_fields: {
      created_at: [699],
      notification_id: [798],
      reg_account: [798],
      __typename: [8],
    },
    system_notification_read_status_min_order_by: {
      created_at: [349],
      notification_id: [349],
      reg_account: [349],
      __typename: [8],
    },
    system_notification_read_status_mutation_response: {
      affected_rows: [3],
      returning: [604],
      __typename: [8],
    },
    system_notification_read_status_on_conflict: {
      constraint: [612],
      update_columns: [626],
      where: [611],
      __typename: [8],
    },
    system_notification_read_status_order_by: {
      created_at: [349],
      notification_id: [349],
      regAccountByRegAccount: [443],
      reg_account: [349],
      system_notification: [645],
      __typename: [8],
    },
    system_notification_read_status_pk_columns_input: {
      notification_id: [798],
      reg_account: [798],
      __typename: [8],
    },
    system_notification_read_status_select_column: {},
    system_notification_read_status_set_input: {
      created_at: [699],
      notification_id: [798],
      reg_account: [798],
      __typename: [8],
    },
    system_notification_read_status_stream_cursor_input: {
      initial_value: [625],
      ordering: [189],
      __typename: [8],
    },
    system_notification_read_status_stream_cursor_value_input: {
      created_at: [699],
      notification_id: [798],
      reg_account: [798],
      __typename: [8],
    },
    system_notification_read_status_update_column: {},
    system_notification_read_status_updates: {
      _set: [623],
      where: [611],
      __typename: [8],
    },
    system_notifications: {
      created_at: [699],
      id: [798],
      message: [8],
      offer_id: [798],
      p2p_offer: [351],
      system_notification_read_statuses: [
        604,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_notification_read_statuses_aggregate: [
        605,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_type_notification: [653],
      type: [658],
      updated_at: [699],
      __typename: [8],
    },
    system_notifications_aggregate: {
      aggregate: [632],
      nodes: [628],
      __typename: [8],
    },
    system_notifications_aggregate_bool_exp: {
      count: [631],
      __typename: [8],
    },
    system_notifications_aggregate_bool_exp_count: {
      arguments: [647],
      distinct: [0],
      filter: [635],
      predicate: [4],
      __typename: [8],
    },
    system_notifications_aggregate_fields: {
      count: [
        3,
        {
          columns: [647, '[system_notifications_select_column!]'],
          distinct: [0],
        },
      ],
      max: [638],
      min: [640],
      __typename: [8],
    },
    system_notifications_aggregate_order_by: {
      count: [349],
      max: [639],
      min: [641],
      __typename: [8],
    },
    system_notifications_arr_rel_insert_input: {
      data: [637],
      on_conflict: [644],
      __typename: [8],
    },
    system_notifications_bool_exp: {
      _and: [635],
      _not: [635],
      _or: [635],
      created_at: [700],
      id: [799],
      message: [10],
      offer_id: [799],
      p2p_offer: [360],
      system_notification_read_statuses: [611],
      system_notification_read_statuses_aggregate: [606],
      system_type_notification: [656],
      type: [659],
      updated_at: [700],
      __typename: [8],
    },
    system_notifications_constraint: {},
    system_notifications_insert_input: {
      created_at: [699],
      id: [798],
      message: [8],
      offer_id: [798],
      p2p_offer: [368],
      system_notification_read_statuses: [610],
      system_type_notification: [664],
      type: [658],
      updated_at: [699],
      __typename: [8],
    },
    system_notifications_max_fields: {
      created_at: [699],
      id: [798],
      message: [8],
      offer_id: [798],
      updated_at: [699],
      __typename: [8],
    },
    system_notifications_max_order_by: {
      created_at: [349],
      id: [349],
      message: [349],
      offer_id: [349],
      updated_at: [349],
      __typename: [8],
    },
    system_notifications_min_fields: {
      created_at: [699],
      id: [798],
      message: [8],
      offer_id: [798],
      updated_at: [699],
      __typename: [8],
    },
    system_notifications_min_order_by: {
      created_at: [349],
      id: [349],
      message: [349],
      offer_id: [349],
      updated_at: [349],
      __typename: [8],
    },
    system_notifications_mutation_response: {
      affected_rows: [3],
      returning: [628],
      __typename: [8],
    },
    system_notifications_obj_rel_insert_input: {
      data: [637],
      on_conflict: [644],
      __typename: [8],
    },
    system_notifications_on_conflict: {
      constraint: [636],
      update_columns: [651],
      where: [635],
      __typename: [8],
    },
    system_notifications_order_by: {
      created_at: [349],
      id: [349],
      message: [349],
      offer_id: [349],
      p2p_offer: [370],
      system_notification_read_statuses_aggregate: [609],
      system_type_notification: [666],
      type: [349],
      updated_at: [349],
      __typename: [8],
    },
    system_notifications_pk_columns_input: {
      id: [798],
      __typename: [8],
    },
    system_notifications_select_column: {},
    system_notifications_set_input: {
      created_at: [699],
      id: [798],
      message: [8],
      offer_id: [798],
      type: [658],
      updated_at: [699],
      __typename: [8],
    },
    system_notifications_stream_cursor_input: {
      initial_value: [650],
      ordering: [189],
      __typename: [8],
    },
    system_notifications_stream_cursor_value_input: {
      created_at: [699],
      id: [798],
      message: [8],
      offer_id: [798],
      type: [658],
      updated_at: [699],
      __typename: [8],
    },
    system_notifications_update_column: {},
    system_notifications_updates: {
      _set: [648],
      where: [635],
      __typename: [8],
    },
    system_type_notification: {
      system_notifications: [
        628,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      system_notifications_aggregate: [
        629,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      type: [8],
      __typename: [8],
    },
    system_type_notification_aggregate: {
      aggregate: [655],
      nodes: [653],
      __typename: [8],
    },
    system_type_notification_aggregate_fields: {
      count: [
        3,
        {
          columns: [668, '[system_type_notification_select_column!]'],
          distinct: [0],
        },
      ],
      max: [661],
      min: [662],
      __typename: [8],
    },
    system_type_notification_bool_exp: {
      _and: [656],
      _not: [656],
      _or: [656],
      system_notifications: [635],
      system_notifications_aggregate: [630],
      type: [10],
      __typename: [8],
    },
    system_type_notification_constraint: {},
    system_type_notification_enum: {},
    system_type_notification_enum_comparison_exp: {
      _eq: [658],
      _in: [658],
      _is_null: [0],
      _neq: [658],
      _nin: [658],
      __typename: [8],
    },
    system_type_notification_insert_input: {
      system_notifications: [634],
      type: [8],
      __typename: [8],
    },
    system_type_notification_max_fields: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_min_fields: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_mutation_response: {
      affected_rows: [3],
      returning: [653],
      __typename: [8],
    },
    system_type_notification_obj_rel_insert_input: {
      data: [660],
      on_conflict: [665],
      __typename: [8],
    },
    system_type_notification_on_conflict: {
      constraint: [657],
      update_columns: [672],
      where: [656],
      __typename: [8],
    },
    system_type_notification_order_by: {
      system_notifications_aggregate: [633],
      type: [349],
      __typename: [8],
    },
    system_type_notification_pk_columns_input: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_select_column: {},
    system_type_notification_set_input: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_stream_cursor_input: {
      initial_value: [671],
      ordering: [189],
      __typename: [8],
    },
    system_type_notification_stream_cursor_value_input: {
      type: [8],
      __typename: [8],
    },
    system_type_notification_update_column: {},
    system_type_notification_updates: {
      _set: [669],
      where: [656],
      __typename: [8],
    },
    table_rows: {
      contract: [8],
      data: [
        223,
        {
          path: [8],
        },
      ],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_aggregate: {
      aggregate: [676],
      nodes: [674],
      __typename: [8],
    },
    table_rows_aggregate_fields: {
      count: [
        3,
        {
          columns: [691, '[table_rows_select_column!]'],
          distinct: [0],
        },
      ],
      max: [684],
      min: [685],
      __typename: [8],
    },
    table_rows_append_input: {
      data: [223],
      __typename: [8],
    },
    table_rows_bool_exp: {
      _and: [678],
      _not: [678],
      _or: [678],
      contract: [10],
      data: [225],
      primary_key: [10],
      scope: [10],
      table: [10],
      __typename: [8],
    },
    table_rows_constraint: {},
    table_rows_delete_at_path_input: {
      data: [8],
      __typename: [8],
    },
    table_rows_delete_elem_input: {
      data: [3],
      __typename: [8],
    },
    table_rows_delete_key_input: {
      data: [8],
      __typename: [8],
    },
    table_rows_insert_input: {
      contract: [8],
      data: [223],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_max_fields: {
      contract: [8],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_min_fields: {
      contract: [8],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_mutation_response: {
      affected_rows: [3],
      returning: [674],
      __typename: [8],
    },
    table_rows_on_conflict: {
      constraint: [679],
      update_columns: [695],
      where: [678],
      __typename: [8],
    },
    table_rows_order_by: {
      contract: [349],
      data: [349],
      primary_key: [349],
      scope: [349],
      table: [349],
      __typename: [8],
    },
    table_rows_pk_columns_input: {
      contract: [8],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_prepend_input: {
      data: [223],
      __typename: [8],
    },
    table_rows_select_column: {},
    table_rows_set_input: {
      contract: [8],
      data: [223],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_stream_cursor_input: {
      initial_value: [694],
      ordering: [189],
      __typename: [8],
    },
    table_rows_stream_cursor_value_input: {
      contract: [8],
      data: [223],
      primary_key: [8],
      scope: [8],
      table: [8],
      __typename: [8],
    },
    table_rows_update_column: {},
    table_rows_updates: {
      _append: [677],
      _delete_at_path: [680],
      _delete_elem: [681],
      _delete_key: [682],
      _prepend: [690],
      _set: [692],
      where: [678],
      __typename: [8],
    },
    timestamp: {},
    timestamp_comparison_exp: {
      _eq: [697],
      _gt: [697],
      _gte: [697],
      _in: [697],
      _is_null: [0],
      _lt: [697],
      _lte: [697],
      _neq: [697],
      _nin: [697],
      __typename: [8],
    },
    timestamptz: {},
    timestamptz_comparison_exp: {
      _eq: [699],
      _gt: [699],
      _gte: [699],
      _in: [699],
      _is_null: [0],
      _lt: [699],
      _lte: [699],
      _neq: [699],
      _nin: [699],
      __typename: [8],
    },
    toggle_trust_network_input: {
      account: [8],
      reject: [0],
      __typename: [8],
    },
    toggle_trust_network_output: {
      success: [0],
      __typename: [8],
    },
    trust_network: {
      account: [8],
      created_at: [699],
      is_mutual: [0],
      trust: [8],
      trust_by: [432],
      trust_network: [432],
      __typename: [8],
    },
    trust_network_aggregate: {
      aggregate: [709],
      nodes: [703],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp: {
      bool_and: [706],
      bool_or: [707],
      count: [708],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp_bool_and: {
      arguments: [749],
      distinct: [0],
      filter: [712],
      predicate: [1],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp_bool_or: {
      arguments: [750],
      distinct: [0],
      filter: [712],
      predicate: [1],
      __typename: [8],
    },
    trust_network_aggregate_bool_exp_count: {
      arguments: [748],
      distinct: [0],
      filter: [712],
      predicate: [4],
      __typename: [8],
    },
    trust_network_aggregate_fields: {
      count: [
        3,
        {
          columns: [748, '[trust_network_select_column!]'],
          distinct: [0],
        },
      ],
      max: [715],
      min: [717],
      __typename: [8],
    },
    trust_network_aggregate_order_by: {
      count: [349],
      max: [716],
      min: [718],
      __typename: [8],
    },
    trust_network_arr_rel_insert_input: {
      data: [714],
      on_conflict: [744],
      __typename: [8],
    },
    trust_network_bool_exp: {
      _and: [712],
      _not: [712],
      _or: [712],
      account: [10],
      created_at: [700],
      is_mutual: [1],
      trust: [10],
      trust_by: [435],
      trust_network: [435],
      __typename: [8],
    },
    trust_network_constraint: {},
    trust_network_insert_input: {
      account: [8],
      created_at: [699],
      is_mutual: [0],
      trust: [8],
      trust_by: [441],
      trust_network: [441],
      __typename: [8],
    },
    trust_network_max_fields: {
      account: [8],
      created_at: [699],
      trust: [8],
      __typename: [8],
    },
    trust_network_max_order_by: {
      account: [349],
      created_at: [349],
      trust: [349],
      __typename: [8],
    },
    trust_network_min_fields: {
      account: [8],
      created_at: [699],
      trust: [8],
      __typename: [8],
    },
    trust_network_min_order_by: {
      account: [349],
      created_at: [349],
      trust: [349],
      __typename: [8],
    },
    trust_network_mutation_response: {
      affected_rows: [3],
      returning: [703],
      __typename: [8],
    },
    trust_network_notification: {
      account: [8],
      created_at: [699],
      regAccountByTrust: [432],
      reg_account: [432],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_aggregate: {
      aggregate: [724],
      nodes: [720],
      __typename: [8],
    },
    trust_network_notification_aggregate_bool_exp: {
      count: [723],
      __typename: [8],
    },
    trust_network_notification_aggregate_bool_exp_count: {
      arguments: [738],
      distinct: [0],
      filter: [727],
      predicate: [4],
      __typename: [8],
    },
    trust_network_notification_aggregate_fields: {
      count: [
        3,
        {
          columns: [738, '[trust_network_notification_select_column!]'],
          distinct: [0],
        },
      ],
      max: [730],
      min: [732],
      __typename: [8],
    },
    trust_network_notification_aggregate_order_by: {
      count: [349],
      max: [731],
      min: [733],
      __typename: [8],
    },
    trust_network_notification_arr_rel_insert_input: {
      data: [729],
      on_conflict: [735],
      __typename: [8],
    },
    trust_network_notification_bool_exp: {
      _and: [727],
      _not: [727],
      _or: [727],
      account: [10],
      created_at: [700],
      regAccountByTrust: [435],
      reg_account: [435],
      trust: [10],
      __typename: [8],
    },
    trust_network_notification_constraint: {},
    trust_network_notification_insert_input: {
      account: [8],
      created_at: [699],
      regAccountByTrust: [441],
      reg_account: [441],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_max_fields: {
      account: [8],
      created_at: [699],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_max_order_by: {
      account: [349],
      created_at: [349],
      trust: [349],
      __typename: [8],
    },
    trust_network_notification_min_fields: {
      account: [8],
      created_at: [699],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_min_order_by: {
      account: [349],
      created_at: [349],
      trust: [349],
      __typename: [8],
    },
    trust_network_notification_mutation_response: {
      affected_rows: [3],
      returning: [720],
      __typename: [8],
    },
    trust_network_notification_on_conflict: {
      constraint: [728],
      update_columns: [742],
      where: [727],
      __typename: [8],
    },
    trust_network_notification_order_by: {
      account: [349],
      created_at: [349],
      regAccountByTrust: [443],
      reg_account: [443],
      trust: [349],
      __typename: [8],
    },
    trust_network_notification_pk_columns_input: {
      account: [8],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_select_column: {},
    trust_network_notification_set_input: {
      account: [8],
      created_at: [699],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_stream_cursor_input: {
      initial_value: [741],
      ordering: [189],
      __typename: [8],
    },
    trust_network_notification_stream_cursor_value_input: {
      account: [8],
      created_at: [699],
      trust: [8],
      __typename: [8],
    },
    trust_network_notification_update_column: {},
    trust_network_notification_updates: {
      _set: [739],
      where: [727],
      __typename: [8],
    },
    trust_network_on_conflict: {
      constraint: [713],
      update_columns: [772],
      where: [712],
      __typename: [8],
    },
    trust_network_order_by: {
      account: [349],
      created_at: [349],
      is_mutual: [349],
      trust: [349],
      trust_by: [443],
      trust_network: [443],
      __typename: [8],
    },
    trust_network_output: {
      trusted_network: [8],
      __typename: [8],
    },
    trust_network_pk_columns_input: {
      account: [8],
      trust: [8],
      __typename: [8],
    },
    trust_network_select_column: {},
    trust_network_select_column_trust_network_aggregate_bool_exp_bool_and_arguments_columns: {},
    trust_network_select_column_trust_network_aggregate_bool_exp_bool_or_arguments_columns: {},
    trust_network_set_input: {
      account: [8],
      created_at: [699],
      is_mutual: [0],
      trust: [8],
      __typename: [8],
    },
    trust_network_status: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_aggregate: {
      aggregate: [754],
      nodes: [752],
      __typename: [8],
    },
    trust_network_status_aggregate_fields: {
      count: [
        3,
        {
          columns: [764, '[trust_network_status_select_column!]'],
          distinct: [0],
        },
      ],
      max: [758],
      min: [759],
      __typename: [8],
    },
    trust_network_status_bool_exp: {
      _and: [755],
      _not: [755],
      _or: [755],
      status: [10],
      __typename: [8],
    },
    trust_network_status_constraint: {},
    trust_network_status_insert_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_max_fields: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_min_fields: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_mutation_response: {
      affected_rows: [3],
      returning: [752],
      __typename: [8],
    },
    trust_network_status_on_conflict: {
      constraint: [756],
      update_columns: [768],
      where: [755],
      __typename: [8],
    },
    trust_network_status_order_by: {
      status: [349],
      __typename: [8],
    },
    trust_network_status_pk_columns_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_select_column: {},
    trust_network_status_set_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_stream_cursor_input: {
      initial_value: [767],
      ordering: [189],
      __typename: [8],
    },
    trust_network_status_stream_cursor_value_input: {
      status: [8],
      __typename: [8],
    },
    trust_network_status_update_column: {},
    trust_network_status_updates: {
      _set: [765],
      where: [755],
      __typename: [8],
    },
    trust_network_stream_cursor_input: {
      initial_value: [771],
      ordering: [189],
      __typename: [8],
    },
    trust_network_stream_cursor_value_input: {
      account: [8],
      created_at: [699],
      is_mutual: [0],
      trust: [8],
      __typename: [8],
    },
    trust_network_update_column: {},
    trust_network_updates: {
      _set: [751],
      where: [712],
      __typename: [8],
    },
    tx_statistics: {
      currency: [8],
      date: [699],
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_aggregate: {
      aggregate: [776],
      nodes: [774],
      __typename: [8],
    },
    tx_statistics_aggregate_fields: {
      avg: [777],
      count: [
        3,
        {
          columns: [785, '[tx_statistics_select_column!]'],
          distinct: [0],
        },
      ],
      max: [781],
      min: [782],
      stddev: [787],
      stddev_pop: [788],
      stddev_samp: [789],
      sum: [792],
      var_pop: [794],
      var_samp: [795],
      variance: [796],
      __typename: [8],
    },
    tx_statistics_avg_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_bool_exp: {
      _and: [778],
      _not: [778],
      _or: [778],
      currency: [10],
      date: [700],
      total_transactions: [164],
      total_transactions_cancelled: [164],
      total_transactions_completed: [164],
      __typename: [8],
    },
    tx_statistics_inc_input: {
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_insert_input: {
      currency: [8],
      date: [699],
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_max_fields: {
      currency: [8],
      date: [699],
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_min_fields: {
      currency: [8],
      date: [699],
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_mutation_response: {
      affected_rows: [3],
      returning: [774],
      __typename: [8],
    },
    tx_statistics_order_by: {
      currency: [349],
      date: [349],
      total_transactions: [349],
      total_transactions_cancelled: [349],
      total_transactions_completed: [349],
      __typename: [8],
    },
    tx_statistics_select_column: {},
    tx_statistics_set_input: {
      currency: [8],
      date: [699],
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_stddev_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_stddev_pop_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_stddev_samp_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_stream_cursor_input: {
      initial_value: [791],
      ordering: [189],
      __typename: [8],
    },
    tx_statistics_stream_cursor_value_input: {
      currency: [8],
      date: [699],
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_sum_fields: {
      total_transactions: [163],
      total_transactions_cancelled: [163],
      total_transactions_completed: [163],
      __typename: [8],
    },
    tx_statistics_updates: {
      _inc: [779],
      _set: [786],
      where: [778],
      __typename: [8],
    },
    tx_statistics_var_pop_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_var_samp_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    tx_statistics_variance_fields: {
      total_transactions: [2],
      total_transactions_cancelled: [2],
      total_transactions_completed: [2],
      __typename: [8],
    },
    upload_image_output: {
      success: [0],
      __typename: [8],
    },
    uuid: {},
    uuid_comparison_exp: {
      _eq: [798],
      _gt: [798],
      _gte: [798],
      _in: [798],
      _is_null: [0],
      _lt: [798],
      _lte: [798],
      _neq: [798],
      _nin: [798],
      __typename: [8],
    },
    validate_short_link_input: {
      link: [8],
      __typename: [8],
    },
    validate_short_link_output: {
      count: [3],
      referrer: [8],
      website: [8],
      __typename: [8],
    },
    volume_statistics: {
      currency: [8],
      date: [699],
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_aggregate: {
      aggregate: [804],
      nodes: [802],
      __typename: [8],
    },
    volume_statistics_aggregate_fields: {
      avg: [805],
      count: [
        3,
        {
          columns: [813, '[volume_statistics_select_column!]'],
          distinct: [0],
        },
      ],
      max: [809],
      min: [810],
      stddev: [815],
      stddev_pop: [816],
      stddev_samp: [817],
      sum: [820],
      var_pop: [822],
      var_samp: [823],
      variance: [824],
      __typename: [8],
    },
    volume_statistics_avg_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_bool_exp: {
      _and: [806],
      _not: [806],
      _or: [806],
      currency: [10],
      date: [700],
      total_cancelled: [348],
      total_completed: [348],
      total_completed_not_cancelled: [348],
      __typename: [8],
    },
    volume_statistics_inc_input: {
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_insert_input: {
      currency: [8],
      date: [699],
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_max_fields: {
      currency: [8],
      date: [699],
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_min_fields: {
      currency: [8],
      date: [699],
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_mutation_response: {
      affected_rows: [3],
      returning: [802],
      __typename: [8],
    },
    volume_statistics_order_by: {
      currency: [349],
      date: [349],
      total_cancelled: [349],
      total_completed: [349],
      total_completed_not_cancelled: [349],
      __typename: [8],
    },
    volume_statistics_select_column: {},
    volume_statistics_set_input: {
      currency: [8],
      date: [699],
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_stddev_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_stddev_pop_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_stddev_samp_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_stream_cursor_input: {
      initial_value: [819],
      ordering: [189],
      __typename: [8],
    },
    volume_statistics_stream_cursor_value_input: {
      currency: [8],
      date: [699],
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_sum_fields: {
      total_cancelled: [347],
      total_completed: [347],
      total_completed_not_cancelled: [347],
      __typename: [8],
    },
    volume_statistics_updates: {
      _inc: [807],
      _set: [814],
      where: [806],
      __typename: [8],
    },
    volume_statistics_var_pop_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_var_samp_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    volume_statistics_variance_fields: {
      total_cancelled: [2],
      total_completed: [2],
      total_completed_not_cancelled: [2],
      __typename: [8],
    },
    wallet_request_output: {
      amount: [8],
      bitcashbank_id: [8],
      buyer: [8],
      buyer_confirmed_payment: [0],
      buyer_method_details: [8],
      cancel_approval_datetime: [8],
      cancel_request_datetime: [8],
      cancellation_approved_by: [8],
      cancellation_transaction: [8],
      cancelled: [0],
      cancelled_by: [8],
      completed: [0],
      created_at: [8],
      id: [8],
      initiator: [8],
      matched: [0],
      matched_datetime: [8],
      method: [8],
      region: [8],
      sell_put_transaction: [8],
      sell_settlement_transaction: [8],
      seller: [8],
      seller_confirmed_payment: [0],
      seller_method_details: [8],
      type: [8],
      updated_at: [8],
      __typename: [8],
    },
    web_push: {
      account: [8],
      auth: [8],
      created_at: [699],
      endpoint: [8],
      expiration_time: [699],
      p256dh: [8],
      __typename: [8],
    },
    web_push_aggregate: {
      aggregate: [828],
      nodes: [826],
      __typename: [8],
    },
    web_push_aggregate_fields: {
      count: [
        3,
        {
          columns: [838, '[web_push_select_column!]'],
          distinct: [0],
        },
      ],
      max: [832],
      min: [833],
      __typename: [8],
    },
    web_push_bool_exp: {
      _and: [829],
      _not: [829],
      _or: [829],
      account: [10],
      auth: [10],
      created_at: [700],
      endpoint: [10],
      expiration_time: [700],
      p256dh: [10],
      __typename: [8],
    },
    web_push_constraint: {},
    web_push_insert_input: {
      account: [8],
      auth: [8],
      created_at: [699],
      endpoint: [8],
      expiration_time: [699],
      p256dh: [8],
      __typename: [8],
    },
    web_push_max_fields: {
      account: [8],
      auth: [8],
      created_at: [699],
      endpoint: [8],
      expiration_time: [699],
      p256dh: [8],
      __typename: [8],
    },
    web_push_min_fields: {
      account: [8],
      auth: [8],
      created_at: [699],
      endpoint: [8],
      expiration_time: [699],
      p256dh: [8],
      __typename: [8],
    },
    web_push_mutation_response: {
      affected_rows: [3],
      returning: [826],
      __typename: [8],
    },
    web_push_on_conflict: {
      constraint: [830],
      update_columns: [842],
      where: [829],
      __typename: [8],
    },
    web_push_order_by: {
      account: [349],
      auth: [349],
      created_at: [349],
      endpoint: [349],
      expiration_time: [349],
      p256dh: [349],
      __typename: [8],
    },
    web_push_pk_columns_input: {
      auth: [8],
      p256dh: [8],
      __typename: [8],
    },
    web_push_select_column: {},
    web_push_set_input: {
      account: [8],
      auth: [8],
      created_at: [699],
      endpoint: [8],
      expiration_time: [699],
      p256dh: [8],
      __typename: [8],
    },
    web_push_stream_cursor_input: {
      initial_value: [841],
      ordering: [189],
      __typename: [8],
    },
    web_push_stream_cursor_value_input: {
      account: [8],
      auth: [8],
      created_at: [699],
      endpoint: [8],
      expiration_time: [699],
      p256dh: [8],
      __typename: [8],
    },
    web_push_update_column: {},
    web_push_updates: {
      _set: [839],
      where: [829],
      __typename: [8],
    },
    Query: {
      account_statistics: [
        11,
        {
          distinct_on: [19, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [18, '[account_statistics_order_by!]'],
          where: [15],
        },
      ],
      account_statistics_aggregate: [
        12,
        {
          distinct_on: [19, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [18, '[account_statistics_order_by!]'],
          where: [15],
        },
      ],
      accounts_information: [
        29,
        {
          distinct_on: [55, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [52, '[accounts_information_order_by!]'],
          where: [39],
        },
      ],
      accounts_information_aggregate: [
        30,
        {
          distinct_on: [55, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [52, '[accounts_information_order_by!]'],
          where: [39],
        },
      ],
      accounts_information_by_pk: [
        29,
        {
          account: [8, 'String!'],
          id: [798, 'uuid!'],
        },
      ],
      accounts_referral: [
        63,
        {
          distinct_on: [84, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [82, '[accounts_referral_order_by!]'],
          where: [72],
        },
      ],
      accounts_referral_aggregate: [
        64,
        {
          distinct_on: [84, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [82, '[accounts_referral_order_by!]'],
          where: [72],
        },
      ],
      accounts_referral_by_pk: [
        63,
        {
          link: [8, 'String!'],
        },
      ],
      actions: [
        104,
        {
          distinct_on: [121, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [118, '[actions_order_by!]'],
          where: [108],
        },
      ],
      actions_aggregate: [
        105,
        {
          distinct_on: [121, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [118, '[actions_order_by!]'],
          where: [108],
        },
      ],
      actions_by_pk: [
        104,
        {
          global_sequence: [8, 'String!'],
        },
      ],
      apollo_auth_health_check: [8],
      apollo_health_check: [0],
      avg_pay_time: [
        127,
        {
          distinct_on: [135, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [134, '[avg_pay_time_order_by!]'],
          where: [131],
        },
      ],
      avg_pay_time_aggregate: [
        128,
        {
          distinct_on: [135, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [134, '[avg_pay_time_order_by!]'],
          where: [131],
        },
      ],
      avg_release_time: [
        145,
        {
          distinct_on: [153, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [152, '[avg_release_time_order_by!]'],
          where: [149],
        },
      ],
      avg_release_time_aggregate: [
        146,
        {
          distinct_on: [153, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [152, '[avg_release_time_order_by!]'],
          where: [149],
        },
      ],
      combined_avg_times: [
        169,
        {
          distinct_on: [177, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [176, '[combined_avg_times_order_by!]'],
          where: [173],
        },
      ],
      combined_avg_times_aggregate: [
        170,
        {
          distinct_on: [177, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [176, '[combined_avg_times_order_by!]'],
          where: [173],
        },
      ],
      devices: [
        190,
        {
          distinct_on: [208, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [206, '[devices_order_by!]'],
          where: [197],
        },
      ],
      devices_aggregate: [
        191,
        {
          distinct_on: [208, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [206, '[devices_order_by!]'],
          where: [197],
        },
      ],
      devices_by_pk: [
        190,
        {
          cred_id: [8, 'String!'],
        },
      ],
      get_inactive_accounts: [
        432,
        {
          args: [216, 'get_inactive_accounts_args!'],
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      get_inactive_accounts_aggregate: [
        433,
        {
          args: [216, 'get_inactive_accounts_args!'],
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      get_login_counts: [
        250,
        {
          args: [217, 'get_login_counts_args!'],
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      get_login_counts_aggregate: [
        251,
        {
          args: [217, 'get_login_counts_args!'],
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      get_p2p_offers_with_old_or_no_messages: [
        351,
        {
          args: [218, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      get_p2p_offers_with_old_or_no_messages_aggregate: [
        352,
        {
          args: [218, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      get_trust_network: [
        746,
        {
          account: [8],
        },
      ],
      id_verification: [
        222,
        {
          id_data: [221],
        },
      ],
      login_account: [
        226,
        {
          distinct_on: [244, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [242, '[login_account_order_by!]'],
          where: [233],
        },
      ],
      login_account_aggregate: [
        227,
        {
          distinct_on: [244, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [242, '[login_account_order_by!]'],
          where: [233],
        },
      ],
      login_account_by_pk: [
        226,
        {
          id: [798, 'uuid!'],
        },
      ],
      login_counts: [
        250,
        {
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      login_counts_aggregate: [
        251,
        {
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      messages: [
        277,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_aggregate: [
        278,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_by_pk: [
        277,
        {
          id: [798, 'uuid!'],
        },
      ],
      migrate_device: [
        301,
        {
          distinct_on: [313, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [311, '[migrate_device_order_by!]'],
          where: [304],
        },
      ],
      migrate_device_aggregate: [
        302,
        {
          distinct_on: [313, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [311, '[migrate_device_order_by!]'],
          where: [304],
        },
      ],
      migrate_device_by_pk: [
        301,
        {
          cred_id: [8, 'String!'],
        },
      ],
      notifications: [
        319,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notifications_aggregate: [
        320,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notifications_by_pk: [
        319,
        {
          id: [798, 'uuid!'],
        },
      ],
      p2p_offers: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_by_pk: [
        351,
        {
          id: [798, 'uuid!'],
        },
      ],
      p2p_pre_sell_offers: [
        380,
        {
          distinct_on: [400, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [398, '[p2p_pre_sell_offers_order_by!]'],
          where: [389],
        },
      ],
      p2p_pre_sell_offers_aggregate: [
        381,
        {
          distinct_on: [400, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [398, '[p2p_pre_sell_offers_order_by!]'],
          where: [389],
        },
      ],
      p2p_pre_sell_offers_by_pk: [
        380,
        {
          id: [798, 'uuid!'],
        },
      ],
      photo_id_verification: [
        408,
        {
          photo_id: [8],
        },
      ],
      preferences: [
        409,
        {
          distinct_on: [426, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [423, '[preferences_order_by!]'],
          where: [413],
        },
      ],
      preferences_aggregate: [
        410,
        {
          distinct_on: [426, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [423, '[preferences_order_by!]'],
          where: [413],
        },
      ],
      preferences_by_pk: [
        409,
        {
          account: [8, 'String!'],
        },
      ],
      reg_accounts: [
        432,
        {
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      reg_accounts_aggregate: [
        433,
        {
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      reg_accounts_by_pk: [
        432,
        {
          id: [798, 'uuid!'],
        },
      ],
      reputation_score: [
        451,
        {
          distinct_on: [466, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [464, '[reputation_score_order_by!]'],
          where: [455],
        },
      ],
      reputation_score_aggregate: [
        452,
        {
          distinct_on: [466, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [464, '[reputation_score_order_by!]'],
          where: [455],
        },
      ],
      reputation_score_by_pk: [
        451,
        {
          account: [8, 'String!'],
        },
      ],
      selfie_verification: [
        481,
        {
          selfie: [8],
        },
      ],
      signing_requests: [
        483,
        {
          distinct_on: [495, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [493, '[signing_requests_order_by!]'],
          where: [486],
        },
      ],
      signing_requests_aggregate: [
        484,
        {
          distinct_on: [495, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [493, '[signing_requests_order_by!]'],
          where: [486],
        },
      ],
      signing_requests_by_pk: [
        483,
        {
          id: [798, 'uuid!'],
        },
      ],
      support_sessions: [
        502,
        {
          distinct_on: [520, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [518, '[support_sessions_order_by!]'],
          where: [509],
        },
      ],
      support_sessions_aggregate: [
        503,
        {
          distinct_on: [520, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [518, '[support_sessions_order_by!]'],
          where: [509],
        },
      ],
      support_sessions_by_pk: [
        502,
        {
          id: [798, 'uuid!'],
        },
      ],
      swap_assets: [
        526,
        {
          distinct_on: [538, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [536, '[swap_assets_order_by!]'],
          where: [529],
        },
      ],
      swap_assets_aggregate: [
        527,
        {
          distinct_on: [538, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [536, '[swap_assets_order_by!]'],
          where: [529],
        },
      ],
      swap_assets_by_pk: [
        526,
        {
          asset: [8, 'String!'],
          chain: [8, 'String!'],
        },
      ],
      swap_orders: [
        544,
        {
          distinct_on: [576, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [574, '[swap_orders_order_by!]'],
          where: [563],
        },
      ],
      swap_orders_aggregate: [
        545,
        {
          distinct_on: [576, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [574, '[swap_orders_order_by!]'],
          where: [563],
        },
      ],
      swap_orders_by_pk: [
        544,
        {
          id: [798, 'uuid!'],
        },
      ],
      system_notification_read_status: [
        604,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_notification_read_status_aggregate: [
        605,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_notification_read_status_by_pk: [
        604,
        {
          notification_id: [798, 'uuid!'],
          reg_account: [798, 'uuid!'],
        },
      ],
      system_notifications: [
        628,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      system_notifications_aggregate: [
        629,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      system_notifications_by_pk: [
        628,
        {
          id: [798, 'uuid!'],
        },
      ],
      system_type_notification: [
        653,
        {
          distinct_on: [668, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [666, '[system_type_notification_order_by!]'],
          where: [656],
        },
      ],
      system_type_notification_aggregate: [
        654,
        {
          distinct_on: [668, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [666, '[system_type_notification_order_by!]'],
          where: [656],
        },
      ],
      system_type_notification_by_pk: [
        653,
        {
          type: [8, 'String!'],
        },
      ],
      table_rows: [
        674,
        {
          distinct_on: [691, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [688, '[table_rows_order_by!]'],
          where: [678],
        },
      ],
      table_rows_aggregate: [
        675,
        {
          distinct_on: [691, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [688, '[table_rows_order_by!]'],
          where: [678],
        },
      ],
      table_rows_by_pk: [
        674,
        {
          contract: [8, 'String!'],
          primary_key: [8, 'String!'],
          scope: [8, 'String!'],
          table: [8, 'String!'],
        },
      ],
      trust_network: [
        703,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_network_aggregate: [
        704,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_network_by_pk: [
        703,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_notification: [
        720,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_network_notification_aggregate: [
        721,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_network_notification_by_pk: [
        720,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_status: [
        752,
        {
          distinct_on: [764, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [762, '[trust_network_status_order_by!]'],
          where: [755],
        },
      ],
      trust_network_status_aggregate: [
        753,
        {
          distinct_on: [764, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [762, '[trust_network_status_order_by!]'],
          where: [755],
        },
      ],
      trust_network_status_by_pk: [
        752,
        {
          status: [8, 'String!'],
        },
      ],
      tx_statistics: [
        774,
        {
          distinct_on: [785, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [784, '[tx_statistics_order_by!]'],
          where: [778],
        },
      ],
      tx_statistics_aggregate: [
        775,
        {
          distinct_on: [785, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [784, '[tx_statistics_order_by!]'],
          where: [778],
        },
      ],
      volume_statistics: [
        802,
        {
          distinct_on: [813, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [812, '[volume_statistics_order_by!]'],
          where: [806],
        },
      ],
      volume_statistics_aggregate: [
        803,
        {
          distinct_on: [813, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [812, '[volume_statistics_order_by!]'],
          where: [806],
        },
      ],
      web_push: [
        826,
        {
          distinct_on: [838, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [836, '[web_push_order_by!]'],
          where: [829],
        },
      ],
      web_push_aggregate: [
        827,
        {
          distinct_on: [838, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [836, '[web_push_order_by!]'],
          where: [829],
        },
      ],
      web_push_by_pk: [
        826,
        {
          auth: [8, 'String!'],
          p256dh: [8, 'String!'],
        },
      ],
      __typename: [8],
    },
    Mutation: {
      approve_new_account: [
        8,
        {
          is_verified: [0],
          request_id: [8],
        },
      ],
      auto_cancel_p2p_offer: [
        350,
        {
          p2p_data: [166],
        },
      ],
      cancel_p2p_approval: [
        350,
        {
          p2p_data: [165],
        },
      ],
      cancel_p2p_offer: [
        350,
        {
          p2p_data: [166],
        },
      ],
      cancel_wallet_request: [
        825,
        {
          p2p_data: [168],
        },
      ],
      cancel_wallet_request_approval: [
        825,
        {
          p2p_data: [167],
        },
      ],
      confirm_p2p_payment: [
        350,
        {
          p2p_data: [187],
        },
      ],
      confirm_wallet_request_payment: [
        825,
        {
          p2p_data: [188],
        },
      ],
      delete_accounts_information: [
        49,
        {
          where: [39, 'accounts_information_bool_exp!'],
        },
      ],
      delete_accounts_information_by_pk: [
        29,
        {
          account: [8, 'String!'],
          id: [798, 'uuid!'],
        },
      ],
      delete_accounts_referral: [
        80,
        {
          where: [72, 'accounts_referral_bool_exp!'],
        },
      ],
      delete_accounts_referral_by_pk: [
        63,
        {
          link: [8, 'String!'],
        },
      ],
      delete_actions: [
        116,
        {
          where: [108, 'actions_bool_exp!'],
        },
      ],
      delete_actions_by_pk: [
        104,
        {
          global_sequence: [8, 'String!'],
        },
      ],
      delete_devices: [
        204,
        {
          where: [197, 'devices_bool_exp!'],
        },
      ],
      delete_devices_by_pk: [
        190,
        {
          cred_id: [8, 'String!'],
        },
      ],
      delete_login_account: [
        240,
        {
          where: [233, 'login_account_bool_exp!'],
        },
      ],
      delete_login_account_by_pk: [
        226,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_login_counts: [
        259,
        {
          where: [254, 'login_counts_bool_exp!'],
        },
      ],
      delete_messages: [
        291,
        {
          where: [284, 'messages_bool_exp!'],
        },
      ],
      delete_messages_by_pk: [
        277,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_migrate_device: [
        309,
        {
          where: [304, 'migrate_device_bool_exp!'],
        },
      ],
      delete_migrate_device_by_pk: [
        301,
        {
          cred_id: [8, 'String!'],
        },
      ],
      delete_notifications: [
        335,
        {
          where: [328, 'notifications_bool_exp!'],
        },
      ],
      delete_notifications_by_pk: [
        319,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_p2p_offers: [
        367,
        {
          where: [360, 'p2p_offers_bool_exp!'],
        },
      ],
      delete_p2p_offers_by_pk: [
        351,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_p2p_pre_sell_offers: [
        396,
        {
          where: [389, 'p2p_pre_sell_offers_bool_exp!'],
        },
      ],
      delete_p2p_pre_sell_offers_by_pk: [
        380,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_preferences: [
        421,
        {
          where: [413, 'preferences_bool_exp!'],
        },
      ],
      delete_preferences_by_pk: [
        409,
        {
          account: [8, 'String!'],
        },
      ],
      delete_reg_accounts: [
        440,
        {
          where: [435, 'reg_accounts_bool_exp!'],
        },
      ],
      delete_reg_accounts_by_pk: [
        432,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_reputation_score: [
        461,
        {
          where: [455, 'reputation_score_bool_exp!'],
        },
      ],
      delete_reputation_score_by_pk: [
        451,
        {
          account: [8, 'String!'],
        },
      ],
      delete_signing_requests: [
        491,
        {
          where: [486, 'signing_requests_bool_exp!'],
        },
      ],
      delete_signing_requests_by_pk: [
        483,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_support_sessions: [
        516,
        {
          where: [509, 'support_sessions_bool_exp!'],
        },
      ],
      delete_support_sessions_by_pk: [
        502,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_swap_assets: [
        534,
        {
          where: [529, 'swap_assets_bool_exp!'],
        },
      ],
      delete_swap_assets_by_pk: [
        526,
        {
          asset: [8, 'String!'],
          chain: [8, 'String!'],
        },
      ],
      delete_swap_orders: [
        571,
        {
          where: [563, 'swap_orders_bool_exp!'],
        },
      ],
      delete_swap_orders_by_pk: [
        544,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_system_notification_read_status: [
        618,
        {
          where: [611, 'system_notification_read_status_bool_exp!'],
        },
      ],
      delete_system_notification_read_status_by_pk: [
        604,
        {
          notification_id: [798, 'uuid!'],
          reg_account: [798, 'uuid!'],
        },
      ],
      delete_system_notifications: [
        642,
        {
          where: [635, 'system_notifications_bool_exp!'],
        },
      ],
      delete_system_notifications_by_pk: [
        628,
        {
          id: [798, 'uuid!'],
        },
      ],
      delete_system_type_notification: [
        663,
        {
          where: [656, 'system_type_notification_bool_exp!'],
        },
      ],
      delete_system_type_notification_by_pk: [
        653,
        {
          type: [8, 'String!'],
        },
      ],
      delete_table_rows: [
        686,
        {
          where: [678, 'table_rows_bool_exp!'],
        },
      ],
      delete_table_rows_by_pk: [
        674,
        {
          contract: [8, 'String!'],
          primary_key: [8, 'String!'],
          scope: [8, 'String!'],
          table: [8, 'String!'],
        },
      ],
      delete_trust_network: [
        719,
        {
          where: [712, 'trust_network_bool_exp!'],
        },
      ],
      delete_trust_network_by_pk: [
        703,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      delete_trust_network_notification: [
        734,
        {
          where: [727, 'trust_network_notification_bool_exp!'],
        },
      ],
      delete_trust_network_notification_by_pk: [
        720,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      delete_trust_network_status: [
        760,
        {
          where: [755, 'trust_network_status_bool_exp!'],
        },
      ],
      delete_trust_network_status_by_pk: [
        752,
        {
          status: [8, 'String!'],
        },
      ],
      delete_tx_statistics: [
        783,
        {
          where: [778, 'tx_statistics_bool_exp!'],
        },
      ],
      delete_volume_statistics: [
        811,
        {
          where: [806, 'volume_statistics_bool_exp!'],
        },
      ],
      delete_web_push: [
        834,
        {
          where: [829, 'web_push_bool_exp!'],
        },
      ],
      delete_web_push_by_pk: [
        826,
        {
          auth: [8, 'String!'],
          p256dh: [8, 'String!'],
        },
      ],
      generate_short_link: [
        8,
        {
          short_link_input: [482, 'short_link_input!'],
        },
      ],
      get_tx_statistics: [
        774,
        {
          args: [219, 'get_tx_statistics_args!'],
          distinct_on: [785, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [784, '[tx_statistics_order_by!]'],
          where: [778],
        },
      ],
      get_volume_statistics: [
        802,
        {
          args: [220, 'get_volume_statistics_args!'],
          distinct_on: [813, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [812, '[volume_statistics_order_by!]'],
          where: [806],
        },
      ],
      insert_accounts_information: [
        49,
        {
          objects: [44, '[accounts_information_insert_input!]!'],
          on_conflict: [51],
        },
      ],
      insert_accounts_information_one: [
        29,
        {
          object: [44, 'accounts_information_insert_input!'],
          on_conflict: [51],
        },
      ],
      insert_accounts_referral: [
        80,
        {
          objects: [75, '[accounts_referral_insert_input!]!'],
          on_conflict: [81],
        },
      ],
      insert_accounts_referral_one: [
        63,
        {
          object: [75, 'accounts_referral_insert_input!'],
          on_conflict: [81],
        },
      ],
      insert_actions: [
        116,
        {
          objects: [113, '[actions_insert_input!]!'],
          on_conflict: [117],
        },
      ],
      insert_actions_one: [
        104,
        {
          object: [113, 'actions_insert_input!'],
          on_conflict: [117],
        },
      ],
      insert_devices: [
        204,
        {
          objects: [199, '[devices_insert_input!]!'],
          on_conflict: [205],
        },
      ],
      insert_devices_one: [
        190,
        {
          object: [199, 'devices_insert_input!'],
          on_conflict: [205],
        },
      ],
      insert_login_account: [
        240,
        {
          objects: [235, '[login_account_insert_input!]!'],
          on_conflict: [241],
        },
      ],
      insert_login_account_one: [
        226,
        {
          object: [235, 'login_account_insert_input!'],
          on_conflict: [241],
        },
      ],
      insert_login_counts: [
        259,
        {
          objects: [256, '[login_counts_insert_input!]!'],
        },
      ],
      insert_login_counts_one: [
        250,
        {
          object: [256, 'login_counts_insert_input!'],
        },
      ],
      insert_messages: [
        291,
        {
          objects: [286, '[messages_insert_input!]!'],
          on_conflict: [292],
        },
      ],
      insert_messages_one: [
        277,
        {
          object: [286, 'messages_insert_input!'],
          on_conflict: [292],
        },
      ],
      insert_migrate_device: [
        309,
        {
          objects: [306, '[migrate_device_insert_input!]!'],
          on_conflict: [310],
        },
      ],
      insert_migrate_device_one: [
        301,
        {
          object: [306, 'migrate_device_insert_input!'],
          on_conflict: [310],
        },
      ],
      insert_notifications: [
        335,
        {
          objects: [330, '[notifications_insert_input!]!'],
          on_conflict: [336],
        },
      ],
      insert_notifications_one: [
        319,
        {
          object: [330, 'notifications_insert_input!'],
          on_conflict: [336],
        },
      ],
      insert_p2p_offers: [
        367,
        {
          objects: [362, '[p2p_offers_insert_input!]!'],
          on_conflict: [369],
        },
      ],
      insert_p2p_offers_one: [
        351,
        {
          object: [362, 'p2p_offers_insert_input!'],
          on_conflict: [369],
        },
      ],
      insert_p2p_pre_sell_offers: [
        396,
        {
          objects: [391, '[p2p_pre_sell_offers_insert_input!]!'],
          on_conflict: [397],
        },
      ],
      insert_p2p_pre_sell_offers_one: [
        380,
        {
          object: [391, 'p2p_pre_sell_offers_insert_input!'],
          on_conflict: [397],
        },
      ],
      insert_preferences: [
        421,
        {
          objects: [418, '[preferences_insert_input!]!'],
          on_conflict: [422],
        },
      ],
      insert_preferences_one: [
        409,
        {
          object: [418, 'preferences_insert_input!'],
          on_conflict: [422],
        },
      ],
      insert_reg_accounts: [
        440,
        {
          objects: [437, '[reg_accounts_insert_input!]!'],
          on_conflict: [442],
        },
      ],
      insert_reg_accounts_one: [
        432,
        {
          object: [437, 'reg_accounts_insert_input!'],
          on_conflict: [442],
        },
      ],
      insert_reputation_score: [
        461,
        {
          objects: [458, '[reputation_score_insert_input!]!'],
          on_conflict: [463],
        },
      ],
      insert_reputation_score_one: [
        451,
        {
          object: [458, 'reputation_score_insert_input!'],
          on_conflict: [463],
        },
      ],
      insert_signing_requests: [
        491,
        {
          objects: [488, '[signing_requests_insert_input!]!'],
          on_conflict: [492],
        },
      ],
      insert_signing_requests_one: [
        483,
        {
          object: [488, 'signing_requests_insert_input!'],
          on_conflict: [492],
        },
      ],
      insert_support_sessions: [
        516,
        {
          objects: [511, '[support_sessions_insert_input!]!'],
          on_conflict: [517],
        },
      ],
      insert_support_sessions_one: [
        502,
        {
          object: [511, 'support_sessions_insert_input!'],
          on_conflict: [517],
        },
      ],
      insert_swap_assets: [
        534,
        {
          objects: [531, '[swap_assets_insert_input!]!'],
          on_conflict: [535],
        },
      ],
      insert_swap_assets_one: [
        526,
        {
          object: [531, 'swap_assets_insert_input!'],
          on_conflict: [535],
        },
      ],
      insert_swap_orders: [
        571,
        {
          objects: [566, '[swap_orders_insert_input!]!'],
          on_conflict: [573],
        },
      ],
      insert_swap_orders_one: [
        544,
        {
          object: [566, 'swap_orders_insert_input!'],
          on_conflict: [573],
        },
      ],
      insert_system_notification_read_status: [
        618,
        {
          objects: [613, '[system_notification_read_status_insert_input!]!'],
          on_conflict: [619],
        },
      ],
      insert_system_notification_read_status_one: [
        604,
        {
          object: [613, 'system_notification_read_status_insert_input!'],
          on_conflict: [619],
        },
      ],
      insert_system_notifications: [
        642,
        {
          objects: [637, '[system_notifications_insert_input!]!'],
          on_conflict: [644],
        },
      ],
      insert_system_notifications_one: [
        628,
        {
          object: [637, 'system_notifications_insert_input!'],
          on_conflict: [644],
        },
      ],
      insert_system_type_notification: [
        663,
        {
          objects: [660, '[system_type_notification_insert_input!]!'],
          on_conflict: [665],
        },
      ],
      insert_system_type_notification_one: [
        653,
        {
          object: [660, 'system_type_notification_insert_input!'],
          on_conflict: [665],
        },
      ],
      insert_table_rows: [
        686,
        {
          objects: [683, '[table_rows_insert_input!]!'],
          on_conflict: [687],
        },
      ],
      insert_table_rows_one: [
        674,
        {
          object: [683, 'table_rows_insert_input!'],
          on_conflict: [687],
        },
      ],
      insert_trust_network: [
        719,
        {
          objects: [714, '[trust_network_insert_input!]!'],
          on_conflict: [744],
        },
      ],
      insert_trust_network_notification: [
        734,
        {
          objects: [729, '[trust_network_notification_insert_input!]!'],
          on_conflict: [735],
        },
      ],
      insert_trust_network_notification_one: [
        720,
        {
          object: [729, 'trust_network_notification_insert_input!'],
          on_conflict: [735],
        },
      ],
      insert_trust_network_one: [
        703,
        {
          object: [714, 'trust_network_insert_input!'],
          on_conflict: [744],
        },
      ],
      insert_trust_network_status: [
        760,
        {
          objects: [757, '[trust_network_status_insert_input!]!'],
          on_conflict: [761],
        },
      ],
      insert_trust_network_status_one: [
        752,
        {
          object: [757, 'trust_network_status_insert_input!'],
          on_conflict: [761],
        },
      ],
      insert_tx_statistics: [
        783,
        {
          objects: [780, '[tx_statistics_insert_input!]!'],
        },
      ],
      insert_tx_statistics_one: [
        774,
        {
          object: [780, 'tx_statistics_insert_input!'],
        },
      ],
      insert_volume_statistics: [
        811,
        {
          objects: [808, '[volume_statistics_insert_input!]!'],
        },
      ],
      insert_volume_statistics_one: [
        802,
        {
          object: [808, 'volume_statistics_insert_input!'],
        },
      ],
      insert_web_push: [
        834,
        {
          objects: [831, '[web_push_insert_input!]!'],
          on_conflict: [835],
        },
      ],
      insert_web_push_one: [
        826,
        {
          object: [831, 'web_push_insert_input!'],
          on_conflict: [835],
        },
      ],
      make_p2p_buy_offer: [
        350,
        {
          offer: [273],
        },
      ],
      make_p2p_sell_offer: [
        350,
        {
          offer: [274],
        },
      ],
      make_wallet_deposit_request: [
        825,
        {
          offer: [275],
        },
      ],
      make_wallet_withdraw_request: [
        825,
        {
          offer: [276],
        },
      ],
      reject_new_account: [
        8,
        {
          request_id: [8],
        },
      ],
      request_new_account: [
        480,
        {
          account_data: [479],
        },
      ],
      reserve_offer: [
        0,
        {
          offerId: [8, 'String!'],
        },
      ],
      send_push_notification: [0],
      store_ip_address: [
        501,
        {
          account: [8],
        },
      ],
      subscribe_push_notification: [
        0,
        {
          subscription: [6, 'PushSubscriptionInput!'],
        },
      ],
      toggle_trust_network: [
        702,
        {
          input: [701],
        },
      ],
      unreserve_offer: [
        0,
        {
          offerId: [8, 'String!'],
        },
      ],
      update_accounts_information: [
        49,
        {
          _append: [37],
          _delete_at_path: [41],
          _delete_elem: [42],
          _delete_key: [43],
          _prepend: [54],
          _set: [58],
          where: [39, 'accounts_information_bool_exp!'],
        },
      ],
      update_accounts_information_by_pk: [
        29,
        {
          _append: [37],
          _delete_at_path: [41],
          _delete_elem: [42],
          _delete_key: [43],
          _prepend: [54],
          _set: [58],
          pk_columns: [53, 'accounts_information_pk_columns_input!'],
        },
      ],
      update_accounts_information_many: [
        49,
        {
          updates: [62, '[accounts_information_updates!]!'],
        },
      ],
      update_accounts_referral: [
        80,
        {
          _inc: [74],
          _set: [85],
          where: [72, 'accounts_referral_bool_exp!'],
        },
      ],
      update_accounts_referral_by_pk: [
        63,
        {
          _inc: [74],
          _set: [85],
          pk_columns: [83, 'accounts_referral_pk_columns_input!'],
        },
      ],
      update_accounts_referral_many: [
        80,
        {
          updates: [97, '[accounts_referral_updates!]!'],
        },
      ],
      update_actions: [
        116,
        {
          _append: [107],
          _delete_at_path: [110],
          _delete_elem: [111],
          _delete_key: [112],
          _prepend: [120],
          _set: [122],
          where: [108, 'actions_bool_exp!'],
        },
      ],
      update_actions_by_pk: [
        104,
        {
          _append: [107],
          _delete_at_path: [110],
          _delete_elem: [111],
          _delete_key: [112],
          _prepend: [120],
          _set: [122],
          pk_columns: [119, 'actions_pk_columns_input!'],
        },
      ],
      update_actions_many: [
        116,
        {
          updates: [126, '[actions_updates!]!'],
        },
      ],
      update_devices: [
        204,
        {
          _set: [209],
          where: [197, 'devices_bool_exp!'],
        },
      ],
      update_devices_by_pk: [
        190,
        {
          _set: [209],
          pk_columns: [207, 'devices_pk_columns_input!'],
        },
      ],
      update_devices_many: [
        204,
        {
          updates: [213, '[devices_updates!]!'],
        },
      ],
      update_login_account: [
        240,
        {
          _set: [245],
          where: [233, 'login_account_bool_exp!'],
        },
      ],
      update_login_account_by_pk: [
        226,
        {
          _set: [245],
          pk_columns: [243, 'login_account_pk_columns_input!'],
        },
      ],
      update_login_account_many: [
        240,
        {
          updates: [249, '[login_account_updates!]!'],
        },
      ],
      update_login_counts: [
        259,
        {
          _inc: [255],
          _set: [262],
          where: [254, 'login_counts_bool_exp!'],
        },
      ],
      update_login_counts_many: [
        259,
        {
          updates: [269, '[login_counts_updates!]!'],
        },
      ],
      update_messages: [
        291,
        {
          _set: [296],
          where: [284, 'messages_bool_exp!'],
        },
      ],
      update_messages_by_pk: [
        277,
        {
          _set: [296],
          pk_columns: [294, 'messages_pk_columns_input!'],
        },
      ],
      update_messages_many: [
        291,
        {
          updates: [300, '[messages_updates!]!'],
        },
      ],
      update_migrate_device: [
        309,
        {
          _set: [314],
          where: [304, 'migrate_device_bool_exp!'],
        },
      ],
      update_migrate_device_by_pk: [
        301,
        {
          _set: [314],
          pk_columns: [312, 'migrate_device_pk_columns_input!'],
        },
      ],
      update_migrate_device_many: [
        309,
        {
          updates: [318, '[migrate_device_updates!]!'],
        },
      ],
      update_notifications: [
        335,
        {
          _set: [342],
          where: [328, 'notifications_bool_exp!'],
        },
      ],
      update_notifications_by_pk: [
        319,
        {
          _set: [342],
          pk_columns: [338, 'notifications_pk_columns_input!'],
        },
      ],
      update_notifications_many: [
        335,
        {
          updates: [346, '[notifications_updates!]!'],
        },
      ],
      update_p2p_offers: [
        367,
        {
          _set: [375],
          where: [360, 'p2p_offers_bool_exp!'],
        },
      ],
      update_p2p_offers_by_pk: [
        351,
        {
          _set: [375],
          pk_columns: [371, 'p2p_offers_pk_columns_input!'],
        },
      ],
      update_p2p_offers_many: [
        367,
        {
          updates: [379, '[p2p_offers_updates!]!'],
        },
      ],
      update_p2p_pre_sell_offers: [
        396,
        {
          _set: [403],
          where: [389, 'p2p_pre_sell_offers_bool_exp!'],
        },
      ],
      update_p2p_pre_sell_offers_by_pk: [
        380,
        {
          _set: [403],
          pk_columns: [399, 'p2p_pre_sell_offers_pk_columns_input!'],
        },
      ],
      update_p2p_pre_sell_offers_many: [
        396,
        {
          updates: [407, '[p2p_pre_sell_offers_updates!]!'],
        },
      ],
      update_preferences: [
        421,
        {
          _append: [412],
          _delete_at_path: [415],
          _delete_elem: [416],
          _delete_key: [417],
          _prepend: [425],
          _set: [427],
          where: [413, 'preferences_bool_exp!'],
        },
      ],
      update_preferences_by_pk: [
        409,
        {
          _append: [412],
          _delete_at_path: [415],
          _delete_elem: [416],
          _delete_key: [417],
          _prepend: [425],
          _set: [427],
          pk_columns: [424, 'preferences_pk_columns_input!'],
        },
      ],
      update_preferences_many: [
        421,
        {
          updates: [431, '[preferences_updates!]!'],
        },
      ],
      update_reg_accounts: [
        440,
        {
          _set: [446],
          where: [435, 'reg_accounts_bool_exp!'],
        },
      ],
      update_reg_accounts_by_pk: [
        432,
        {
          _set: [446],
          pk_columns: [444, 'reg_accounts_pk_columns_input!'],
        },
      ],
      update_reg_accounts_many: [
        440,
        {
          updates: [450, '[reg_accounts_updates!]!'],
        },
      ],
      update_reputation_score: [
        461,
        {
          _inc: [457],
          _set: [467],
          where: [455, 'reputation_score_bool_exp!'],
        },
      ],
      update_reputation_score_by_pk: [
        451,
        {
          _inc: [457],
          _set: [467],
          pk_columns: [465, 'reputation_score_pk_columns_input!'],
        },
      ],
      update_reputation_score_many: [
        461,
        {
          updates: [475, '[reputation_score_updates!]!'],
        },
      ],
      update_signing_requests: [
        491,
        {
          _set: [496],
          where: [486, 'signing_requests_bool_exp!'],
        },
      ],
      update_signing_requests_by_pk: [
        483,
        {
          _set: [496],
          pk_columns: [494, 'signing_requests_pk_columns_input!'],
        },
      ],
      update_signing_requests_many: [
        491,
        {
          updates: [500, '[signing_requests_updates!]!'],
        },
      ],
      update_support_sessions: [
        516,
        {
          _set: [521],
          where: [509, 'support_sessions_bool_exp!'],
        },
      ],
      update_support_sessions_by_pk: [
        502,
        {
          _set: [521],
          pk_columns: [519, 'support_sessions_pk_columns_input!'],
        },
      ],
      update_support_sessions_many: [
        516,
        {
          updates: [525, '[support_sessions_updates!]!'],
        },
      ],
      update_swap_assets: [
        534,
        {
          _set: [539],
          where: [529, 'swap_assets_bool_exp!'],
        },
      ],
      update_swap_assets_by_pk: [
        526,
        {
          _set: [539],
          pk_columns: [537, 'swap_assets_pk_columns_input!'],
        },
      ],
      update_swap_assets_many: [
        534,
        {
          updates: [543, '[swap_assets_updates!]!'],
        },
      ],
      update_swap_orders: [
        571,
        {
          _inc: [565],
          _set: [585],
          where: [563, 'swap_orders_bool_exp!'],
        },
      ],
      update_swap_orders_by_pk: [
        544,
        {
          _inc: [565],
          _set: [585],
          pk_columns: [575, 'swap_orders_pk_columns_input!'],
        },
      ],
      update_swap_orders_many: [
        571,
        {
          updates: [597, '[swap_orders_updates!]!'],
        },
      ],
      update_system_notification_read_status: [
        618,
        {
          _set: [623],
          where: [611, 'system_notification_read_status_bool_exp!'],
        },
      ],
      update_system_notification_read_status_by_pk: [
        604,
        {
          _set: [623],
          pk_columns: [621, 'system_notification_read_status_pk_columns_input!'],
        },
      ],
      update_system_notification_read_status_many: [
        618,
        {
          updates: [627, '[system_notification_read_status_updates!]!'],
        },
      ],
      update_system_notifications: [
        642,
        {
          _set: [648],
          where: [635, 'system_notifications_bool_exp!'],
        },
      ],
      update_system_notifications_by_pk: [
        628,
        {
          _set: [648],
          pk_columns: [646, 'system_notifications_pk_columns_input!'],
        },
      ],
      update_system_notifications_many: [
        642,
        {
          updates: [652, '[system_notifications_updates!]!'],
        },
      ],
      update_system_type_notification: [
        663,
        {
          _set: [669],
          where: [656, 'system_type_notification_bool_exp!'],
        },
      ],
      update_system_type_notification_by_pk: [
        653,
        {
          _set: [669],
          pk_columns: [667, 'system_type_notification_pk_columns_input!'],
        },
      ],
      update_system_type_notification_many: [
        663,
        {
          updates: [673, '[system_type_notification_updates!]!'],
        },
      ],
      update_table_rows: [
        686,
        {
          _append: [677],
          _delete_at_path: [680],
          _delete_elem: [681],
          _delete_key: [682],
          _prepend: [690],
          _set: [692],
          where: [678, 'table_rows_bool_exp!'],
        },
      ],
      update_table_rows_by_pk: [
        674,
        {
          _append: [677],
          _delete_at_path: [680],
          _delete_elem: [681],
          _delete_key: [682],
          _prepend: [690],
          _set: [692],
          pk_columns: [689, 'table_rows_pk_columns_input!'],
        },
      ],
      update_table_rows_many: [
        686,
        {
          updates: [696, '[table_rows_updates!]!'],
        },
      ],
      update_trust_network: [
        719,
        {
          _set: [751],
          where: [712, 'trust_network_bool_exp!'],
        },
      ],
      update_trust_network_by_pk: [
        703,
        {
          _set: [751],
          pk_columns: [747, 'trust_network_pk_columns_input!'],
        },
      ],
      update_trust_network_many: [
        719,
        {
          updates: [773, '[trust_network_updates!]!'],
        },
      ],
      update_trust_network_notification: [
        734,
        {
          _set: [739],
          where: [727, 'trust_network_notification_bool_exp!'],
        },
      ],
      update_trust_network_notification_by_pk: [
        720,
        {
          _set: [739],
          pk_columns: [737, 'trust_network_notification_pk_columns_input!'],
        },
      ],
      update_trust_network_notification_many: [
        734,
        {
          updates: [743, '[trust_network_notification_updates!]!'],
        },
      ],
      update_trust_network_status: [
        760,
        {
          _set: [765],
          where: [755, 'trust_network_status_bool_exp!'],
        },
      ],
      update_trust_network_status_by_pk: [
        752,
        {
          _set: [765],
          pk_columns: [763, 'trust_network_status_pk_columns_input!'],
        },
      ],
      update_trust_network_status_many: [
        760,
        {
          updates: [769, '[trust_network_status_updates!]!'],
        },
      ],
      update_tx_statistics: [
        783,
        {
          _inc: [779],
          _set: [786],
          where: [778, 'tx_statistics_bool_exp!'],
        },
      ],
      update_tx_statistics_many: [
        783,
        {
          updates: [793, '[tx_statistics_updates!]!'],
        },
      ],
      update_volume_statistics: [
        811,
        {
          _inc: [807],
          _set: [814],
          where: [806, 'volume_statistics_bool_exp!'],
        },
      ],
      update_volume_statistics_many: [
        811,
        {
          updates: [821, '[volume_statistics_updates!]!'],
        },
      ],
      update_web_push: [
        834,
        {
          _set: [839],
          where: [829, 'web_push_bool_exp!'],
        },
      ],
      update_web_push_by_pk: [
        826,
        {
          _set: [839],
          pk_columns: [837, 'web_push_pk_columns_input!'],
        },
      ],
      update_web_push_many: [
        834,
        {
          updates: [843, '[web_push_updates!]!'],
        },
      ],
      upload_image: [
        797,
        {
          account: [8, 'String!'],
          image: [8, 'String!'],
          selfie: [0],
        },
      ],
      validate_short_link: [
        801,
        {
          validate_short_link_input: [800, 'validate_short_link_input!'],
        },
      ],
      __typename: [8],
    },
    Subscription: {
      account_statistics: [
        11,
        {
          distinct_on: [19, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [18, '[account_statistics_order_by!]'],
          where: [15],
        },
      ],
      account_statistics_aggregate: [
        12,
        {
          distinct_on: [19, '[account_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [18, '[account_statistics_order_by!]'],
          where: [15],
        },
      ],
      account_statistics_stream: [
        11,
        {
          batch_size: [3, 'Int!'],
          cursor: [23, '[account_statistics_stream_cursor_input]!'],
          where: [15],
        },
      ],
      accounts_information: [
        29,
        {
          distinct_on: [55, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [52, '[accounts_information_order_by!]'],
          where: [39],
        },
      ],
      accounts_information_aggregate: [
        30,
        {
          distinct_on: [55, '[accounts_information_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [52, '[accounts_information_order_by!]'],
          where: [39],
        },
      ],
      accounts_information_by_pk: [
        29,
        {
          account: [8, 'String!'],
          id: [798, 'uuid!'],
        },
      ],
      accounts_information_stream: [
        29,
        {
          batch_size: [3, 'Int!'],
          cursor: [59, '[accounts_information_stream_cursor_input]!'],
          where: [39],
        },
      ],
      accounts_referral: [
        63,
        {
          distinct_on: [84, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [82, '[accounts_referral_order_by!]'],
          where: [72],
        },
      ],
      accounts_referral_aggregate: [
        64,
        {
          distinct_on: [84, '[accounts_referral_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [82, '[accounts_referral_order_by!]'],
          where: [72],
        },
      ],
      accounts_referral_by_pk: [
        63,
        {
          link: [8, 'String!'],
        },
      ],
      accounts_referral_stream: [
        63,
        {
          batch_size: [3, 'Int!'],
          cursor: [92, '[accounts_referral_stream_cursor_input]!'],
          where: [72],
        },
      ],
      actions: [
        104,
        {
          distinct_on: [121, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [118, '[actions_order_by!]'],
          where: [108],
        },
      ],
      actions_aggregate: [
        105,
        {
          distinct_on: [121, '[actions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [118, '[actions_order_by!]'],
          where: [108],
        },
      ],
      actions_by_pk: [
        104,
        {
          global_sequence: [8, 'String!'],
        },
      ],
      actions_stream: [
        104,
        {
          batch_size: [3, 'Int!'],
          cursor: [123, '[actions_stream_cursor_input]!'],
          where: [108],
        },
      ],
      avg_pay_time: [
        127,
        {
          distinct_on: [135, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [134, '[avg_pay_time_order_by!]'],
          where: [131],
        },
      ],
      avg_pay_time_aggregate: [
        128,
        {
          distinct_on: [135, '[avg_pay_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [134, '[avg_pay_time_order_by!]'],
          where: [131],
        },
      ],
      avg_pay_time_stream: [
        127,
        {
          batch_size: [3, 'Int!'],
          cursor: [139, '[avg_pay_time_stream_cursor_input]!'],
          where: [131],
        },
      ],
      avg_release_time: [
        145,
        {
          distinct_on: [153, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [152, '[avg_release_time_order_by!]'],
          where: [149],
        },
      ],
      avg_release_time_aggregate: [
        146,
        {
          distinct_on: [153, '[avg_release_time_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [152, '[avg_release_time_order_by!]'],
          where: [149],
        },
      ],
      avg_release_time_stream: [
        145,
        {
          batch_size: [3, 'Int!'],
          cursor: [157, '[avg_release_time_stream_cursor_input]!'],
          where: [149],
        },
      ],
      combined_avg_times: [
        169,
        {
          distinct_on: [177, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [176, '[combined_avg_times_order_by!]'],
          where: [173],
        },
      ],
      combined_avg_times_aggregate: [
        170,
        {
          distinct_on: [177, '[combined_avg_times_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [176, '[combined_avg_times_order_by!]'],
          where: [173],
        },
      ],
      combined_avg_times_stream: [
        169,
        {
          batch_size: [3, 'Int!'],
          cursor: [181, '[combined_avg_times_stream_cursor_input]!'],
          where: [173],
        },
      ],
      devices: [
        190,
        {
          distinct_on: [208, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [206, '[devices_order_by!]'],
          where: [197],
        },
      ],
      devices_aggregate: [
        191,
        {
          distinct_on: [208, '[devices_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [206, '[devices_order_by!]'],
          where: [197],
        },
      ],
      devices_by_pk: [
        190,
        {
          cred_id: [8, 'String!'],
        },
      ],
      devices_stream: [
        190,
        {
          batch_size: [3, 'Int!'],
          cursor: [210, '[devices_stream_cursor_input]!'],
          where: [197],
        },
      ],
      get_inactive_accounts: [
        432,
        {
          args: [216, 'get_inactive_accounts_args!'],
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      get_inactive_accounts_aggregate: [
        433,
        {
          args: [216, 'get_inactive_accounts_args!'],
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      get_login_counts: [
        250,
        {
          args: [217, 'get_login_counts_args!'],
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      get_login_counts_aggregate: [
        251,
        {
          args: [217, 'get_login_counts_args!'],
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      get_p2p_offers_with_old_or_no_messages: [
        351,
        {
          args: [218, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      get_p2p_offers_with_old_or_no_messages_aggregate: [
        352,
        {
          args: [218, 'get_p2p_offers_with_old_or_no_messages_args!'],
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      login_account: [
        226,
        {
          distinct_on: [244, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [242, '[login_account_order_by!]'],
          where: [233],
        },
      ],
      login_account_aggregate: [
        227,
        {
          distinct_on: [244, '[login_account_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [242, '[login_account_order_by!]'],
          where: [233],
        },
      ],
      login_account_by_pk: [
        226,
        {
          id: [798, 'uuid!'],
        },
      ],
      login_account_stream: [
        226,
        {
          batch_size: [3, 'Int!'],
          cursor: [246, '[login_account_stream_cursor_input]!'],
          where: [233],
        },
      ],
      login_counts: [
        250,
        {
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      login_counts_aggregate: [
        251,
        {
          distinct_on: [261, '[login_counts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [260, '[login_counts_order_by!]'],
          where: [254],
        },
      ],
      login_counts_stream: [
        250,
        {
          batch_size: [3, 'Int!'],
          cursor: [266, '[login_counts_stream_cursor_input]!'],
          where: [254],
        },
      ],
      messages: [
        277,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_aggregate: [
        278,
        {
          distinct_on: [295, '[messages_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [293, '[messages_order_by!]'],
          where: [284],
        },
      ],
      messages_by_pk: [
        277,
        {
          id: [798, 'uuid!'],
        },
      ],
      messages_stream: [
        277,
        {
          batch_size: [3, 'Int!'],
          cursor: [297, '[messages_stream_cursor_input]!'],
          where: [284],
        },
      ],
      migrate_device: [
        301,
        {
          distinct_on: [313, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [311, '[migrate_device_order_by!]'],
          where: [304],
        },
      ],
      migrate_device_aggregate: [
        302,
        {
          distinct_on: [313, '[migrate_device_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [311, '[migrate_device_order_by!]'],
          where: [304],
        },
      ],
      migrate_device_by_pk: [
        301,
        {
          cred_id: [8, 'String!'],
        },
      ],
      migrate_device_stream: [
        301,
        {
          batch_size: [3, 'Int!'],
          cursor: [315, '[migrate_device_stream_cursor_input]!'],
          where: [304],
        },
      ],
      notifications: [
        319,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notifications_aggregate: [
        320,
        {
          distinct_on: [339, '[notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [337, '[notifications_order_by!]'],
          where: [328],
        },
      ],
      notifications_by_pk: [
        319,
        {
          id: [798, 'uuid!'],
        },
      ],
      notifications_stream: [
        319,
        {
          batch_size: [3, 'Int!'],
          cursor: [343, '[notifications_stream_cursor_input]!'],
          where: [328],
        },
      ],
      p2p_offers: [
        351,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_aggregate: [
        352,
        {
          distinct_on: [372, '[p2p_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [370, '[p2p_offers_order_by!]'],
          where: [360],
        },
      ],
      p2p_offers_by_pk: [
        351,
        {
          id: [798, 'uuid!'],
        },
      ],
      p2p_offers_stream: [
        351,
        {
          batch_size: [3, 'Int!'],
          cursor: [376, '[p2p_offers_stream_cursor_input]!'],
          where: [360],
        },
      ],
      p2p_pre_sell_offers: [
        380,
        {
          distinct_on: [400, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [398, '[p2p_pre_sell_offers_order_by!]'],
          where: [389],
        },
      ],
      p2p_pre_sell_offers_aggregate: [
        381,
        {
          distinct_on: [400, '[p2p_pre_sell_offers_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [398, '[p2p_pre_sell_offers_order_by!]'],
          where: [389],
        },
      ],
      p2p_pre_sell_offers_by_pk: [
        380,
        {
          id: [798, 'uuid!'],
        },
      ],
      p2p_pre_sell_offers_stream: [
        380,
        {
          batch_size: [3, 'Int!'],
          cursor: [404, '[p2p_pre_sell_offers_stream_cursor_input]!'],
          where: [389],
        },
      ],
      preferences: [
        409,
        {
          distinct_on: [426, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [423, '[preferences_order_by!]'],
          where: [413],
        },
      ],
      preferences_aggregate: [
        410,
        {
          distinct_on: [426, '[preferences_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [423, '[preferences_order_by!]'],
          where: [413],
        },
      ],
      preferences_by_pk: [
        409,
        {
          account: [8, 'String!'],
        },
      ],
      preferences_stream: [
        409,
        {
          batch_size: [3, 'Int!'],
          cursor: [428, '[preferences_stream_cursor_input]!'],
          where: [413],
        },
      ],
      reg_accounts: [
        432,
        {
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      reg_accounts_aggregate: [
        433,
        {
          distinct_on: [445, '[reg_accounts_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [443, '[reg_accounts_order_by!]'],
          where: [435],
        },
      ],
      reg_accounts_by_pk: [
        432,
        {
          id: [798, 'uuid!'],
        },
      ],
      reg_accounts_stream: [
        432,
        {
          batch_size: [3, 'Int!'],
          cursor: [447, '[reg_accounts_stream_cursor_input]!'],
          where: [435],
        },
      ],
      reputation_score: [
        451,
        {
          distinct_on: [466, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [464, '[reputation_score_order_by!]'],
          where: [455],
        },
      ],
      reputation_score_aggregate: [
        452,
        {
          distinct_on: [466, '[reputation_score_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [464, '[reputation_score_order_by!]'],
          where: [455],
        },
      ],
      reputation_score_by_pk: [
        451,
        {
          account: [8, 'String!'],
        },
      ],
      reputation_score_stream: [
        451,
        {
          batch_size: [3, 'Int!'],
          cursor: [471, '[reputation_score_stream_cursor_input]!'],
          where: [455],
        },
      ],
      signing_requests: [
        483,
        {
          distinct_on: [495, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [493, '[signing_requests_order_by!]'],
          where: [486],
        },
      ],
      signing_requests_aggregate: [
        484,
        {
          distinct_on: [495, '[signing_requests_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [493, '[signing_requests_order_by!]'],
          where: [486],
        },
      ],
      signing_requests_by_pk: [
        483,
        {
          id: [798, 'uuid!'],
        },
      ],
      signing_requests_stream: [
        483,
        {
          batch_size: [3, 'Int!'],
          cursor: [497, '[signing_requests_stream_cursor_input]!'],
          where: [486],
        },
      ],
      support_sessions: [
        502,
        {
          distinct_on: [520, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [518, '[support_sessions_order_by!]'],
          where: [509],
        },
      ],
      support_sessions_aggregate: [
        503,
        {
          distinct_on: [520, '[support_sessions_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [518, '[support_sessions_order_by!]'],
          where: [509],
        },
      ],
      support_sessions_by_pk: [
        502,
        {
          id: [798, 'uuid!'],
        },
      ],
      support_sessions_stream: [
        502,
        {
          batch_size: [3, 'Int!'],
          cursor: [522, '[support_sessions_stream_cursor_input]!'],
          where: [509],
        },
      ],
      swap_assets: [
        526,
        {
          distinct_on: [538, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [536, '[swap_assets_order_by!]'],
          where: [529],
        },
      ],
      swap_assets_aggregate: [
        527,
        {
          distinct_on: [538, '[swap_assets_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [536, '[swap_assets_order_by!]'],
          where: [529],
        },
      ],
      swap_assets_by_pk: [
        526,
        {
          asset: [8, 'String!'],
          chain: [8, 'String!'],
        },
      ],
      swap_assets_stream: [
        526,
        {
          batch_size: [3, 'Int!'],
          cursor: [540, '[swap_assets_stream_cursor_input]!'],
          where: [529],
        },
      ],
      swap_orders: [
        544,
        {
          distinct_on: [576, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [574, '[swap_orders_order_by!]'],
          where: [563],
        },
      ],
      swap_orders_aggregate: [
        545,
        {
          distinct_on: [576, '[swap_orders_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [574, '[swap_orders_order_by!]'],
          where: [563],
        },
      ],
      swap_orders_by_pk: [
        544,
        {
          id: [798, 'uuid!'],
        },
      ],
      swap_orders_stream: [
        544,
        {
          batch_size: [3, 'Int!'],
          cursor: [592, '[swap_orders_stream_cursor_input]!'],
          where: [563],
        },
      ],
      system_notification_read_status: [
        604,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_notification_read_status_aggregate: [
        605,
        {
          distinct_on: [622, '[system_notification_read_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [620, '[system_notification_read_status_order_by!]'],
          where: [611],
        },
      ],
      system_notification_read_status_by_pk: [
        604,
        {
          notification_id: [798, 'uuid!'],
          reg_account: [798, 'uuid!'],
        },
      ],
      system_notification_read_status_stream: [
        604,
        {
          batch_size: [3, 'Int!'],
          cursor: [624, '[system_notification_read_status_stream_cursor_input]!'],
          where: [611],
        },
      ],
      system_notifications: [
        628,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      system_notifications_aggregate: [
        629,
        {
          distinct_on: [647, '[system_notifications_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [645, '[system_notifications_order_by!]'],
          where: [635],
        },
      ],
      system_notifications_by_pk: [
        628,
        {
          id: [798, 'uuid!'],
        },
      ],
      system_notifications_stream: [
        628,
        {
          batch_size: [3, 'Int!'],
          cursor: [649, '[system_notifications_stream_cursor_input]!'],
          where: [635],
        },
      ],
      system_type_notification: [
        653,
        {
          distinct_on: [668, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [666, '[system_type_notification_order_by!]'],
          where: [656],
        },
      ],
      system_type_notification_aggregate: [
        654,
        {
          distinct_on: [668, '[system_type_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [666, '[system_type_notification_order_by!]'],
          where: [656],
        },
      ],
      system_type_notification_by_pk: [
        653,
        {
          type: [8, 'String!'],
        },
      ],
      system_type_notification_stream: [
        653,
        {
          batch_size: [3, 'Int!'],
          cursor: [670, '[system_type_notification_stream_cursor_input]!'],
          where: [656],
        },
      ],
      table_rows: [
        674,
        {
          distinct_on: [691, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [688, '[table_rows_order_by!]'],
          where: [678],
        },
      ],
      table_rows_aggregate: [
        675,
        {
          distinct_on: [691, '[table_rows_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [688, '[table_rows_order_by!]'],
          where: [678],
        },
      ],
      table_rows_by_pk: [
        674,
        {
          contract: [8, 'String!'],
          primary_key: [8, 'String!'],
          scope: [8, 'String!'],
          table: [8, 'String!'],
        },
      ],
      table_rows_stream: [
        674,
        {
          batch_size: [3, 'Int!'],
          cursor: [693, '[table_rows_stream_cursor_input]!'],
          where: [678],
        },
      ],
      trust_network: [
        703,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_network_aggregate: [
        704,
        {
          distinct_on: [748, '[trust_network_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [745, '[trust_network_order_by!]'],
          where: [712],
        },
      ],
      trust_network_by_pk: [
        703,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_notification: [
        720,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_network_notification_aggregate: [
        721,
        {
          distinct_on: [738, '[trust_network_notification_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [736, '[trust_network_notification_order_by!]'],
          where: [727],
        },
      ],
      trust_network_notification_by_pk: [
        720,
        {
          account: [8, 'String!'],
          trust: [8, 'String!'],
        },
      ],
      trust_network_notification_stream: [
        720,
        {
          batch_size: [3, 'Int!'],
          cursor: [740, '[trust_network_notification_stream_cursor_input]!'],
          where: [727],
        },
      ],
      trust_network_status: [
        752,
        {
          distinct_on: [764, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [762, '[trust_network_status_order_by!]'],
          where: [755],
        },
      ],
      trust_network_status_aggregate: [
        753,
        {
          distinct_on: [764, '[trust_network_status_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [762, '[trust_network_status_order_by!]'],
          where: [755],
        },
      ],
      trust_network_status_by_pk: [
        752,
        {
          status: [8, 'String!'],
        },
      ],
      trust_network_status_stream: [
        752,
        {
          batch_size: [3, 'Int!'],
          cursor: [766, '[trust_network_status_stream_cursor_input]!'],
          where: [755],
        },
      ],
      trust_network_stream: [
        703,
        {
          batch_size: [3, 'Int!'],
          cursor: [770, '[trust_network_stream_cursor_input]!'],
          where: [712],
        },
      ],
      tx_statistics: [
        774,
        {
          distinct_on: [785, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [784, '[tx_statistics_order_by!]'],
          where: [778],
        },
      ],
      tx_statistics_aggregate: [
        775,
        {
          distinct_on: [785, '[tx_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [784, '[tx_statistics_order_by!]'],
          where: [778],
        },
      ],
      tx_statistics_stream: [
        774,
        {
          batch_size: [3, 'Int!'],
          cursor: [790, '[tx_statistics_stream_cursor_input]!'],
          where: [778],
        },
      ],
      volume_statistics: [
        802,
        {
          distinct_on: [813, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [812, '[volume_statistics_order_by!]'],
          where: [806],
        },
      ],
      volume_statistics_aggregate: [
        803,
        {
          distinct_on: [813, '[volume_statistics_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [812, '[volume_statistics_order_by!]'],
          where: [806],
        },
      ],
      volume_statistics_stream: [
        802,
        {
          batch_size: [3, 'Int!'],
          cursor: [818, '[volume_statistics_stream_cursor_input]!'],
          where: [806],
        },
      ],
      web_push: [
        826,
        {
          distinct_on: [838, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [836, '[web_push_order_by!]'],
          where: [829],
        },
      ],
      web_push_aggregate: [
        827,
        {
          distinct_on: [838, '[web_push_select_column!]'],
          limit: [3],
          offset: [3],
          order_by: [836, '[web_push_order_by!]'],
          where: [829],
        },
      ],
      web_push_by_pk: [
        826,
        {
          auth: [8, 'String!'],
          p256dh: [8, 'String!'],
        },
      ],
      web_push_stream: [
        826,
        {
          batch_size: [3, 'Int!'],
          cursor: [840, '[web_push_stream_cursor_input]!'],
          where: [829],
        },
      ],
      __typename: [8],
    },
  },
}
