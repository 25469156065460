import { config } from 'app-config'
import { colyseus } from './colyseus'

import { BitcashRoomState, BitcashPrivateRoomState } from './rooms'

const reactiveSessionWS = config.reactiveSessionWS

// const reactiveSessionWS = 'ws://localhost:2567'

export const bitcashRoom = colyseus<BitcashRoomState>(reactiveSessionWS)
export const bitcashPrivateRoom = colyseus<BitcashPrivateRoomState>(reactiveSessionWS)
