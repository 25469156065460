import React from 'react'

const EmptyCheckIcon = (props: any) => (
  <svg {...props} viewBox="0 0 14 14" fill="none">
    <path
      d="M2.18751 7.00001C2.18738 6.15517 2.40965 5.32519 2.83198 4.59349C3.25431 3.86179 3.86182 3.25417 4.59343 2.83169C5.32505 2.40922 6.15499 2.18679 6.99982 2.18675C7.84465 2.18672 8.67461 2.40909 9.40626 2.83151C9.50669 2.88861 9.62563 2.90367 9.73712 2.8734C9.84862 2.84313 9.94361 2.76999 10.0014 2.66993C10.0591 2.56988 10.075 2.45105 10.0455 2.33935C10.0159 2.22766 9.94343 2.13218 9.84376 2.07376C8.75951 1.44776 7.49901 1.19702 6.25773 1.36042C5.01646 1.52382 3.86378 2.09222 2.97847 2.97748C2.09315 3.86273 1.52468 5.01537 1.3612 6.25664C1.19772 7.4979 1.44838 8.75842 2.07431 9.84271C2.70023 10.927 3.66643 11.7745 4.82307 12.2537C5.97972 12.7329 7.26216 12.817 8.47151 12.4931C9.68087 12.1692 10.7495 11.4553 11.5118 10.4621C12.2741 9.46893 12.6873 8.25199 12.6875 7.00001C12.6875 6.88397 12.6414 6.77269 12.5594 6.69065C12.4773 6.6086 12.366 6.56251 12.25 6.56251C12.134 6.56251 12.0227 6.6086 11.9406 6.69065C11.8586 6.77269 11.8125 6.88397 11.8125 7.00001C11.8125 8.27636 11.3055 9.50044 10.403 10.403C9.50044 11.3055 8.27636 11.8125 7.00001 11.8125C5.72365 11.8125 4.49958 11.3055 3.59706 10.403C2.69454 9.50044 2.18751 8.27636 2.18751 7.00001Z"
      fill="black"
    />
    <path
      d="M13.4348 2.93475C13.4755 2.89408 13.5078 2.84579 13.5298 2.79264C13.5518 2.73949 13.5631 2.68253 13.5631 2.625C13.5631 2.56748 13.5518 2.51051 13.5298 2.45737C13.5078 2.40422 13.4755 2.35593 13.4348 2.31525C13.3941 2.27458 13.3458 2.24231 13.2927 2.2203C13.2396 2.19828 13.1826 2.18695 13.1251 2.18695C13.0675 2.18695 13.0106 2.19828 12.9574 2.2203C12.9043 2.24231 12.856 2.27458 12.8153 2.31525L7.00006 8.13138L4.68481 5.81525C4.64414 5.77458 4.59585 5.74231 4.5427 5.72029C4.48955 5.69828 4.43259 5.68695 4.37506 5.68695C4.31754 5.68695 4.26058 5.69828 4.20743 5.72029C4.15428 5.74231 4.10599 5.77458 4.06531 5.81525C4.02464 5.85593 3.99237 5.90422 3.97036 5.95737C3.94834 6.01051 3.93701 6.06748 3.93701 6.125C3.93701 6.18253 3.94834 6.23949 3.97036 6.29264C3.99237 6.34579 4.02464 6.39408 4.06531 6.43475L6.69031 9.05975C6.73095 9.1005 6.77923 9.13282 6.83239 9.15488C6.88554 9.17693 6.94252 9.18828 7.00006 9.18828C7.05761 9.18828 7.11459 9.17693 7.16774 9.15488C7.2209 9.13282 7.26917 9.1005 7.30981 9.05975L13.4348 2.93475Z"
      fill="black"
    />
  </svg>
)

export default EmptyCheckIcon
