import { useStore } from 'app-engine/store'
import { scrollbar_css } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { useSigningRequest } from 'hooks/use-signing-request'
import {
  Html5QrcodeCameraScanConfig,
  Html5QrcodeScanType,
  Html5QrcodeScanner,
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from 'html5-qrcode'
import { useEffect } from 'react'

export function QrScan({ onSuccess, onError, ...config }: QrScanProps) {
  const account = useStore.useAccount()
  const pubKey = useStore.usePub_key()
  const [{ actions }, { requestSignature }] = useSigningRequest()

  useEffect(() => {
    let html5QrcodeScanner: Html5QrcodeScanner

    const timeout = setTimeout(() => {
      html5QrcodeScanner = new Html5QrcodeScanner(
        'qrcode-scanner',
        {
          ...config,
          aspectRatio: 1,
          fps: 10,
          qrbox: 300,
          disableFlip: true,
          supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        } as Html5QrcodeCameraScanConfig,
        false, // verbose false
      )
      html5QrcodeScanner.render(async (text) => {
        console.log('actions', actions)
        console.log('text', text)
        if (actions.length > 0) return

        html5QrcodeScanner.pause()
        console.log('paused...')
        requestSignature(text, { account, pubKey })
        console.log('requested...')
      }, onError)
    }, 1000)

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner &&
        html5QrcodeScanner.clear().catch((error) => {
          console.error('Failed to clear html5QrcodeScanner. ', error)
        })
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, pubKey])

  // if (actions.length > 0)
  //   return <RawDataObjectContainer>{JSON.stringify(actions, null, 2)}</RawDataObjectContainer>

  return <QRCodeScannerContainer id="qrcode-scanner" />
}

// { height: 400, maxHeight: 400, width: 400, maxWidth: 400, borderRadius: 10, background: '#fafafa', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }
const QRCodeScannerContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 325px;
  height: 100%;
  max-height: 390px;
  overflow: hidden;
  border: 4px solid #eaeaea;
  background: #fafafa;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button#html5-qrcode-button-camera-permission {
    border-radius: 24px;
    line-height: 1.25;
    font-size: 1.25rem;
    width: 100%;
    max-width: 220px;
    padding: 6px 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: 0;
    transition: 0.24s all ease-in-out;
    background: ${({ theme }) => theme.colors.black};
    color: #ffffff;
    margin-inline: auto;
    cursor: pointer;

    :hover,
    :focus-within {
      transform: scale(1.05);
    }
  }

  div#qr-shaded-region,
  div#qrcode-scanner__scan_region {
    height: 100%;
    max-height: 390px;
  }

  div#qrcode-scanner__dashboard {
    height: 0px;
  }
`

export const RawDataObjectContainer = styled.code`
  height: 400px;
  max-height: 400px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.darkGray};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  display: flex;
  align-items: start;
  padding: 16px;
  justify-content: start;
  flex-direction: column;
  white-space: pre-wrap;
  font-family: monospace;
  margin-bottom: 24px;
  overflow-y: auto;
  font-size: 0.92rem;
  border: 3px solid #eaeaea;

  ${scrollbar_css}

  overflow-x: auto;
`

interface QrScanProps extends Omit<Html5QrcodeCameraScanConfig, 'fps'> {
  onSuccess: QrcodeSuccessCallback
  onError?: QrcodeErrorCallback | undefined
}
