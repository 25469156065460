import React from 'react'

const USDTFTMIcon = (props: any) => (
  <svg viewBox="0 0 62 58" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.495.001C17.394-.087 3.317 4.221.755 18.664c-1.21 6.827-.781 13.443 0 20.293C2.359 52.982 16.359 58.335 29 57.984c12.552-.348 26.6-4.632 28.244-19.028.782-6.849 1.21-13.465 0-20.292C55 6.007 42.416.082 30.494 0z"
      fill="#26A17B"
    />
    <path
      d="M31.96 29.779v-.004c-.17.012-1.049.063-3.003.063-1.563 0-2.66-.043-3.047-.063v.004c-6.01-.265-10.494-1.31-10.494-2.564 0-1.25 4.488-2.3 10.494-2.564v4.087c.392.028 1.52.095 3.075.095 1.867 0 2.801-.079 2.975-.095v-4.083c5.999.269 10.47 1.313 10.47 2.564 0 1.25-4.475 2.295-10.47 2.564v-.004zm0-5.548v-3.656h8.369V15H17.545v5.575h8.369v3.656C19.112 24.544 14 25.89 14 27.504c0 1.614 5.116 2.96 11.914 3.276V42.5h6.05V30.78c6.79-.313 11.894-1.658 11.894-3.272 0-1.61-5.104-2.96-11.894-3.273"
      fill="#fff"
    />
    <circle cx={49} cy={45} r={13} fill="#1969FF" />
    <g clipPath="url(#prefix__clip0_4237:5580)">
      <path d="M56.68 35H41v20h15.68V35z" fill="#1969FF" />
      <path
        d="M47.979 35.217c.483-.255 1.218-.255 1.7 0l4.929 2.603c.29.154.45.383.479.62h.005v13.085c-.007.257-.168.513-.484.68L49.68 54.81c-.483.255-1.218.255-1.701 0l-4.928-2.604c-.315-.166-.466-.424-.474-.68a.96.96 0 010-.066V38.528v-.089h.002c.022-.239.175-.462.472-.619l4.928-2.603zm6.337 10.522l-4.636 2.45c-.483.255-1.218.255-1.701 0l-4.626-2.444v5.754l4.626 2.43c.273.146.557.289.834.295h.016c.276.001.545-.14.817-.272l4.67-2.474v-5.739zm-12.54 5.501c0 .501.058.83.174 1.063.095.192.24.34.502.518l.015.01c.058.04.121.08.199.128l.09.056.28.169-.4.664-.314-.19-.053-.032a7.01 7.01 0 01-.236-.151c-.748-.505-1.027-1.055-1.033-2.2v-.035h.776zm6.665-9.008a.705.705 0 00-.1.043l-4.928 2.603a.395.395 0 00-.015.008l-.004.003.008.004.011.006 4.928 2.604c.03.016.065.03.1.042v-5.313zm.776 0v5.313a.717.717 0 00.1-.042l4.928-2.604a.395.395 0 00.015-.008l.004-.002-.007-.005-.012-.006-4.928-2.603a.705.705 0 00-.1-.043zm5.099-2.864l-4.42 2.335 4.42 2.335v-4.67zm-10.963.005v4.66l4.41-2.33-4.41-2.33zm5.964-3.47c-.256-.135-.72-.135-.976 0l-4.928 2.603-.015.008-.004.003.008.004.011.007 4.928 2.603c.256.135.72.135.976 0l4.928-2.603.015-.009.004-.002-.007-.005-.012-.006-4.928-2.603zm5.727.286l.313.189.053.032c.09.056.166.104.236.151.748.505 1.028 1.056 1.033 2.2v.035h-.776c0-.501-.058-.83-.173-1.063-.096-.192-.24-.34-.503-.518l-.015-.01a5.962 5.962 0 00-.198-.128l-.091-.056-.28-.169.401-.663z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0_4237:5580">
        <path fill="#fff" transform="translate(41 35)" d="M0 0h15.679v20H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default USDTFTMIcon
