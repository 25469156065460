import { FlexBox } from 'app-view/components/Styled'
import { CheckIcon, ErrorIcon } from 'app-view/components/icons'
import { CSSProperties } from 'react'

const accountVerificationStatusTagStyle = (isVerified: boolean): CSSProperties => ({
  position: 'absolute',
  top: -12,
  right: 0,
  backgroundColor: isVerified ? '#00C853' : '#FF1744',
  borderRadius: '24px',
  padding: '2px 12px',
  color: 'white',
  fontSize: '13px',
  fontWeight: 'bold',
})
const baseAccountVerificationStatusTagIconStyle = (
  isVerified: boolean,
): { fill: string; style: CSSProperties } => ({
  fill: 'white',
  style: {
    height: '13px',
    width: '13px',
    stroke: isVerified ? '#00C853' : '#FF1744',
  },
})

export function AccountVerificationStatusTag({
  isVerified,
  isMissingInfo,
  style,
}: {
  isVerified: boolean
  isMissingInfo: boolean
  style?: CSSProperties
}) {
  const notFullyVerifiedLabel = isMissingInfo ? 'info missing' : 'not verified'

  return (
    <FlexBox
      style={{ ...accountVerificationStatusTagStyle(isVerified), ...style }}
      title={isVerified ? 'verified ✔️' : `${notFullyVerifiedLabel} ❌`}
    >
      {isVerified ? 'verified' : notFullyVerifiedLabel}
      {isVerified ? (
        <CheckIcon {...baseAccountVerificationStatusTagIconStyle(isVerified)} />
      ) : (
        <ErrorIcon {...baseAccountVerificationStatusTagIconStyle(isVerified)} />
      )}
    </FlexBox>
  )
}
