import React from 'react'

const TakePhotoIcon = (props: any) => (
  <svg viewBox="0 0 50 50" fill="none" {...props}>
    <path
      d="M48.111 37.4443V44.5554C48.111 45.4984 47.7362 46.4029 47.0696 47.0696C46.4029 47.7362 45.4984 48.111 44.5554 48.111H37.4443"
      stroke="#000001"
      strokeWidth="3.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.4443 1.88867H44.5554C45.4984 1.88867 46.4029 2.26329 47.0696 2.93009C47.7362 3.59687 48.111 4.50126 48.111 5.44423V12.5553"
      stroke="#000001"
      strokeWidth="3.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.88867 12.5553V5.44423C1.88867 4.50126 2.26327 3.59687 2.93007 2.93009C3.59687 2.26329 4.50122 1.88867 5.44423 1.88867H12.5553"
      stroke="#000001"
      strokeWidth="3.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5553 48.111H5.44423C4.50122 48.111 3.59687 47.7362 2.93007 47.0696C2.26327 46.4029 1.88867 45.4984 1.88867 44.5554V37.4443"
      stroke="#000001"
      strokeWidth="3.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.9998 23.2222C28.9271 23.2222 32.1109 20.0385 32.1109 16.1111C32.1109 12.1838 28.9271 9 24.9998 9C21.0724 9 17.8887 12.1838 17.8887 16.1111C17.8887 20.0385 21.0724 23.2222 24.9998 23.2222Z"
      stroke="#000001"
      strokeWidth="3.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.2347 37.4448C35.2237 35.3214 33.6316 33.528 31.6431 32.2725C29.6546 31.017 27.3509 30.3506 24.9992 30.3506C22.6475 30.3506 20.3439 31.017 18.3554 32.2725C16.3668 33.528 14.7748 35.3214 13.7637 37.4448"
      stroke="#000001"
      strokeWidth="3.55556"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TakePhotoIcon
