import React from 'react'

import { SVGProps } from 'react'

interface FilterTimesIconProps extends SVGProps<SVGSVGElement> {
  color?: string
}

const FilterTimesIcon = ({ color, ...props }: FilterTimesIconProps) => {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        d="M14.7253 1.41L13.3153 0L7.72534 5.59L2.13534 0L0.725342 1.41L6.31534 7L0.725342 12.59L2.13534 14L7.72534 8.41L13.3153 14L14.7253 12.59L9.13534 7L14.7253 1.41Z"
        fill={color || 'black'}
      />
      <path
        d="m320 170.666667c28.289759 0 55.420843 11.238063 75.424723 31.241943 20.003881 20.00388 31.241944 47.134964 31.241944 75.424723 0 58.910374-47.756294 106.666667-106.666667 106.666667s-106.666667-47.756293-106.666667-106.666667c0-58.910373 47.756294-106.666666 106.666667-106.666666zm0 21.333333c-47.128299 0-85.333333 38.205035-85.333333 85.333333 0 47.128299 38.205034 85.333334 85.333333 85.333334s85.333333-38.205035 85.333333-85.333334c0-47.128298-38.205034-85.333333-85.333333-85.333333zm36.543147 33.705237 15.084949 15.08495-36.544192 36.54305 36.544192 36.543243-15.084949 15.084949-36.543243-36.544192-36.543051 36.544192-15.084949-15.084949 36.543-36.543243-36.543-36.54305 15.084949-15.08495 36.543051 36.543zm-15.209814-225.705237-149.333333 181.999v122.001h-42.666667l-.000333-122-149.333-182z"
        fill-rule="evenodd"
        transform="translate(42.666667 85.333333)"
        fill={color || 'black'}
      />
    </svg>
  )
}

export default FilterTimesIcon
