import React from 'react'

const SendMsgIcon = (props: any) => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" {...props}>
    <path
      d="M0.000488281 20.8571L21.0005 10.5714L0.000488281 0.285713V8.28571L15.0005 10.5714L0.000488281 12.8571V20.8571Z"
      fill="#29E0AD"
    />
  </svg>
)

export default SendMsgIcon
