import React from 'react'
import { ModalTextProps } from './types'
import { ContainerText, StyledModalText } from './Styled'

export const ModalText: ModalTextProps = ({ children, align = 'center', ...props }) => (
  <ContainerText role="alert" style={{ ...props.styleContainer }}>
    <StyledModalText {...props} align={align}>
      {typeof children === 'string' ? children.replace(/^(Error:\s|error:\s)/g, '') : children}
    </StyledModalText>
  </ContainerText>
)
