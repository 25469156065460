export default {
  total: 'Total Tersedia',
  total_stable: 'bitcash Tersedia',
  buy: 'Beli',
  swap: 'Tukar',
  sell: 'Jual',
  all: 'Semua',
  p2p: 'P2P',
  share_link: 'Pembuat Tautan',
  open: 'Buka',
  cancelled: 'Dibatalkan',
  filled: 'Terisi',
  cancelled_on: 'Dibatalkan pada',
  filled_on: 'Diisi pada',
  support_channel: 'Chat dukungan',
  support: 'Dukungan',
  available: 'bitUSD Tersedia',
  error: 'Terjadi kesalahan.',
  loading: 'Memuat data Anda',
  ok: 'OK',
  back: 'Kembali',
  no_messages: 'Belum ada pesan',
  cancel: 'Batal',
  received: 'Diterima',
  generic_error: 'Maaf, terjadi gangguan. Silakan coba lagi.',
  appStatePausedMsn: 'Maaf, perdagangan saat ini dihentikan sementara untuk pemeliharaan.',
  push_transaction_error: `Kami minta maaf!
	Kami tidak bisa {{action}} tawaran,
	silakan coba lagi dalam beberapa detik.
	`,
  push_transaction_error_action_process: 'memproses',
  non_auth_user: `Pengguna yang masuk belum
	disetujui. Sambungkan ke
	saluran Discord kami:`,
  remind_me_later: 'Ingatkan Saya Nanti',
  add_trust: 'Tambahkan ke Jaringan',
  remove_trust: 'Hapus dari Jaringan',
  trust_request_sent: 'Menunggu',
  added_trust: 'Ditambahkan ke jaringan kepercayaan Anda',
  add_second_device:
    'Ingatlah untuk menambahkan Perangkat Kedua untuk mencadangkan akun Anda untuk menghindari kehilangan data.',
  navigation_trade: 'Perdagangan',
  navigation_wallet: 'Dompet',
  navigation_history: 'Riwayat',
  navigation_p2p: 'P2P',
  navigation_swap: 'Tukar',
  navigation_bank: 'Bank',
  order_type: 'Jenis Pesanan:',
  last_update: 'Pembaruan Terakhir:',
  order_amount: 'Jumlah Koin:',
  net_amount: 'Jumlah yang Diterima:',
  order_cost: 'Biaya Pesanan:',
  minus_network: '(dikurangi biaya jaringan)',
  confirm: 'Konfirmasi',
  continue_question: `Apakah Anda ingin melanjutkan?
	  
	`,
  no_data: 'Tidak Ada Data',
  take_me_there: 'Bawa aku kesana',
  required_action: 'Tindakan yang Diperlukan',
  data_warning_message:
    'Agar memenuhi syarat untuk mendapatkan hadiah dan mengaktifkan pemulihan akun, harap perbarui informasi Anda.',

  login_request: 'Permintaan Masuk',
  login_request_description: 'Apakah Anda ingin masuk ke <0>{{appName}}</0>?',
  signature_request: 'Permintaan Tanda Tangan',
  dboard_voting_transaction: `Harap konfirmasikan bahwa Anda ingin
  <0>memilih {{option}}</0> di <1>dBoard</1>.`,
  evm_transfer_transaction: `Apakah Anda ingin mentransfer <0>{{amount}}</0>
  ke <1>{{account}}</1> dari <2>{{appName}}</2>?`,
  pomelo_grant_donation: `Harap kon
  firmasikan bahwa Anda ingin
  <0>menyumbang ke {{grant}}</0> di <1>Pomelo</1>.`,
  overview: 'Ringkasan',
  details: 'Detail',
  transaction: 'Transaksi',
  data: 'Data',
  raw_data: 'Data Mentah',
  qr_reader: 'Pembaca QR',
  sign_success: 'Tanda Tangan Berhasil',
}
