import React from 'react'
import { useTranslation } from 'react-i18next'
import { OfferListSkeleton } from './OfferListSkeleton'
import { AccountItem } from './AccountItem'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import theme from 'app-config/theme'
import { ListTitle, NoItems, OfferContainer } from './Shared'
import { useRealTimeTrustNetworkIncomingTrustRequests } from 'app-view/hooks/use-realtime/use-realtime-trust-network-incoming-requests'

type TrustRequestListProps = {
  title: string
}

export const TrustRequestList: React.FC<TrustRequestListProps> = ({ title }) => {
  const { t } = useTranslation(['p2p'])
  const incomingTrustRequests = useRealTimeTrustNetworkIncomingTrustRequests()

  return (
    <OfferContainer>
      <ListTitle color={theme.colors?.darkGray}>{title}</ListTitle>
      {!incomingTrustRequests?.length && <NoItems>{t('no_request', { filter: '' })}</NoItems>}
      <AnimatePresence>
        {incomingTrustRequests?.length &&
          incomingTrustRequests?.map((account, index) => (
            <AccountItem
              key={`r_acct_${account}_${index}`}
              account={account}
              type="trust_request"
            />
          ))}
      </AnimatePresence>
      {!incomingTrustRequests ? <OfferListSkeleton /> : null}
    </OfferContainer>
  )
}
