import { ModalSuccess } from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { useKeyRecovery } from 'pages/DeviceKeysView/hooks/useKeyRecovery'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

export const RecoveryGenerating = () => {
  const [{ loading }, { submitAccount, pushTransactionKeyRecovery, setState }] = useKeyRecovery()
  const { t } = useTranslation('settings')

  const generateRecoveryKey = async () => {
    let response = null
    try {
      response = await pushTransactionKeyRecovery()

      if (!response.success) throw new Error((response.transactionResponse as Error).message)

      await submitAccount().then((res) => {
        console.log('submitAccount', res)
      })
    } catch (error) {
      console.log('error pushTransactionKeyRecovery: ', error)
      setState({ error: (error as Error).message })
    } finally {
      setState({ loading: false })
    }
  }

  useEffectOnce(() => {
    generateRecoveryKey()
  })

  return (
    <div>
      {loading ? (
        <Loader text={`${t('generating_recovery_keys')}...`} />
      ) : (
        <ModalSuccess text="Recovery Key Created!" />
      )}
    </div>
  )
}
