export default {
  create_account_title: 'Create Your User Account',
  create_account_format_description:
    'Please create a unique and memorable 9 character account name using letters between <b>a-z</b> and numbers <b>1-5</b>.',
  create_account_user_account_placeholder: 'Type your user account',
  create_account_user_account_error_required: 'Please enter your account name',
  create_account_user_account_error_pattern: 'Only a-z and 1-5 allowed',
  create_account_referrer_account_error_pattern: 'Only a-z, 1-5 and accounts ending in .bk allowed',
  create_account_user_account_error_unavailable: 'Account unavailable! Please try with another.',
  create_account_referrer_label: 'Referrer account',
  create_account_referrer_account_placeholder: 'referrer1',
  create_account_referrer_error_required: 'Please enter your referrer',
  create_account_referrer_error_not_registered: 'Referrer is not registered',
  create_account_accounts_submit: 'Confirm',
  create_account_referrer_error_unexistent:
    'Account not currently registered or approved by bitcashBank.',
  create_account_key_title: 'Add Smartphone Key',
  create_account_key_description:
    "Your smartphone device will be the primary key to access your account. Please select the 'Use this device with screen lock' option when prompted after clicking the button bellow.",
  create_account_key_device_label: 'Device name',
  create_account_key_device_placeholder: 'Enter your device name',
  create_account_key_device_error_required: 'Please enter your device name',
  create_account_key_submit: 'Next',
  register_account_success_text:
    'Your registration was submitted successfully.  Please inform your referrer or join the bitcashBank Discord group to expedite approval.',
  create_account_success_text:
    'Your account creation was completed successfully.  Please inform your referrer or join the bitcashBank Discord group to expedite approval.',
  register_account_success_button: 'Open Discord',
  register_account_title: 'Register Your User Account',
  account_title: 'Account Name',
  account_title_description: 'Please enter the account name you want to log into with this device.',
  account_setup_account_button: 'Log In',
  account_user_account_available: 'This account is available',
  account_user_account_registered: 'This account is valid',
  account_user_account_not_registered: 'Account is not registered',
  add_device_account_title: 'Enter Existing Account Name',
  migrate_device_account_title: 'Enter Existing Account Name',
  add_device_account_description:
    'This should be an account you have used on a previous device. You will need access to that device to approve adding another key.',
  migrate_device_account_description:
    'This should be an account you have used on a previous device.',
  add_device_key_title: 'Device Key',
  add_device_key_description:
    '<p>Would you like to add this smartphone or a security key (ie. Yubikey) as an additional key to access <b>{{account}}</b>?</p><p>If you want to use this smartphone select <b>Touch or Face ID</b> to use this device as a key when prompted after clicking the button below.</p><p>Otherwise if you have a security key select <b>Use Security Key</b> option when prompted.</p>',
  add_device_key_submit: 'Add Key',
  add_device_success_description:
    'Please use your original device to approve adding this new key to <b>{{account}}</b>',
  migrate_device_success_description: 'Please wait for the admin.',
  select_device_title: 'Select A Device',
  select_device_description: 'Please, select one of your devices to login.',
  new_device: 'New Device',
  continue: 'Continue',
  warning_apple_user: 'Warning Apple user',
  warning_apple_user_modal_info_browser:
    'Remember to register new browser as a <0>New Device</0> if you will register this account with biometrics.',
  warning_apple_user_modal_info_confirm:
    "Got it, I'll make sure to register new browser before using it.",
  list_devices: '{{account}} devices list',
  singup_invitation:
    'Bitcash App powered by bitcashBank beta is by invite only.<br /> <br /> Please visit <a href="https://bitcash.org">bitcash.org</a> to sign up for an invitation.',
  approved_accounts: 'Approved Accts',
  pending_accounts: 'Pending Accts',
  approving_account: 'Approving Account',
  rejecting_account: 'Rejecting Account',
  successfully_rejected: 'Account {{account}} successfully rejected!',
  successfully_approved: 'Account {{acct.account}} successfully approved!',

  country_id_label: 'ID',
  phone_number_label: 'Phone Number:',
  email_address_label: 'Email Address:',
  subscribe_to_newsletter: 'Keep me informed about crypto events and education.',
  phone_valid: 'Phone number is valid!',
  country_id_valid: 'ID is valid!',
  phone_number_placeholder: '{{phoneNumberCountryCode}}phone #',
  include_country_code: 'Include country code',
  create_account_kyc: 'Account Information',
  create_account_kyc_title: 'Verify Account',
  create_account_kyc_description:
    'Please enter your phone number and email address below to complete your account registration. We will use this information to verify your identity and recover your account if you lose your device.',
  invalid_phone_provenance: 'Invalid phone number provenance. Try again or with another one.',
  account_details: 'Please enter the information below for account recovery purposes:',
  this_country_id_already_registered: 'This ID was already registered.',
  this_phone_number_already_registered: 'This phone number was already registered.',
  this_email_already_registered: 'This email was already registered.',
  loading_action: 'Processing Signature...',
  user_verified: 'User Verified',
  created_at: 'Created At',
  approve: 'Approve',
  selfie_label: 'Photo ID',
  id_invalid: 'ID not valid.',
  email_invalid: 'Email is not valid.',
  email_valid: 'Email is valid.',
  full_name_invalid: 'Name not valid.',
  phone_invalid: 'Phone number is not valid.',
  photo_invalid: 'Photo identification not valid.',
  continue_verification: 'Continue Verification',
  cancel_verification: 'Maybe Later',
  selfie_notification_regular: `Prioritizing your security, we require identity verification for account recovery, protection and app rewards.`,
  selfie_notification_investor: 'Ensure your selfie and photo ID includes:',
  selfie_notifications_1: `<ul>
  <li>Full face and ID visible.</li>
  <li>No accessories.</li>
  <li>Both eyes open, neutral expression.</li>
  </ul>`,
  selfie_notifications_2: `<ul>
  <li>Clear, well-lit setting.</li>
  <li>Plain background.</li>
  <li>No filters or alterations.</li>
  </ul>`,
  photo_id_notifications: `<ul>
  <li>Align your ID within the frame on your screen and it is visible.</li>
  <li>The photo should be taken in a well-lit environment without glare.</li>
  <li>No part of the ID should be covered by fingers or any objects.</li>
  </ul>`,
  require_permission: 'We require your camera, and GPS permission to qualify for the selfie.',
  grant_permission: 'Grant Permissions',
  granting_permission: 'Granting Permissions...',
  take_selfie: 'Take Selfie',
  retake_selfie: 'Retake Selfie',
  verifying: 'Verifying...',
  photo_is_ok: 'Photo is OK.',
  processing_error: 'Something went wrong processing your photo.',
  invalid_photo: "Photo doesn't meet requirements.",
  processing: 'Processing...',
  download_selfie: 'Download selfie',
  take_selfie_id: 'Take Selfie ID',
  take_photo_id: 'Take Photo ID',
  download_photo_id: 'Download Photo ID',
  retake_photos: 'Retake Photos',
  invalid_photo_id: 'Document not visible.',
  take_selfie_instructions: 'Selfie Photo Instructions',
  take_id_card_photo_instructions: 'Card ID Photo Instructions',
  question_kyc_reason: 'Why do I have to grant permissions?',
  return: 'Return',
  kyc_help_text_1:
    'These security measures protect your account from unauthorized access and ensure you are the rightful owner. It also enables us to accurately award app rewards tailored to your region.',
  kyc_help_text_2:
    "Once you're ready, return and click 'Continue Verification' to proceed or select 'Maybe Later' if you need to prepare your ID or adjust your device settings.",
  how_to_grant_permission: 'HOW TO GRANT PERMISSION?',
  please_grant_bitcash_app_permission:
    'Please grant bitcash app permission to access your camera and location',
  for_android: 'For Android',
  android_setting: `<ol>
  <li>Go to 'Settings' > 'Apps & notifications' > 'App permissions.'</li>
  <li>Select ’bitcash app’ and toggle on 'Camera' and 'Location.'</li>
</ol>`,
  additional_resources: 'Additional Resources',
  for_android_devices_via: 'For Android devices via Scribble Maps Help Portal',
  for_ios: 'For iOS',
  ios_setting: `<ol>
  <li>Go to 'Settings' > 'Privacy' > 'Location Services.'</li>
  <li>Find 'bitcash app' in the list and set the permission to 'While Using the App.'</li>
</ol> `,
  for_ios_devices_via: 'For iOS devices via Scribble Maps Help Portal',
  for_chrome_specific: 'For Chrome-specific instructions',
  enable_gps_location: 'Enable GPS/Location',
  google_chrome_support: 'Google Chrome Support',
}
