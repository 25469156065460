import {
  AbiProvider,
  ABISerializable,
  APIClient,
  CallbackPayload,
  ResolvedSigningRequest,
  SigningRequest,
  SigningRequestEncodingOptions,
  TransactResult,
} from 'anchor-link'
import { Actions } from 'app-engine/graphql/generated/chaingraph'
import { getErrorMessage } from 'app-engine/library/errors'
import { useStore } from 'app-engine/store'
import { SendTransaction2Response } from 'app-engine/store/eos-slice'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { SIGNING_REQUEST_STEPS } from 'app-view/components/SignRequest/steps'
import { useWizard, Wizard, WizardProvider } from 'app-view/components/Wizard'
import pako from 'pako'
import { createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn, useLocalStorage, useSetState } from 'react-use'
import { PersistStorage } from 'zustand/middleware'

interface UseSigningRequestState {
  code: string
  esr: SigningRequest | null
  esrType: 'login' | 'transaction'
  resolved: ResolvedSigningRequest | null
  actions: ABISerializable[] | Actions[]
  trxId: string
  callbackStatus: 'pending' | 'ok' | 'error'
  error: string
  params: URLSearchParams
  callbackUrl?: string
}

const defaultState: UseSigningRequestState = {
  error: '',
  code: '',
  esrType: 'transaction',
  esr: null,
  resolved: null,
  actions: [],
  trxId: null,
  callbackStatus: 'pending',
  callbackUrl: '',
  params: new URLSearchParams(),
}

const SigningRequestContext = createContext<
  | [
      UseSigningRequestState & Partial<TransactResult | SendTransaction2Response>,
      {
        requestSignature: (code: string, accountData: { account?: string; pubKey?: string }) => void
        resetError: () => void
        pushSignRequest: () => Promise<{ callbackUrl: string; error: string }>
        cancelRequest: () => void
        setCallbackUrl: (params: URLSearchParams, callbackUrl: string) => void
      },
    ]
  | undefined
>(undefined)

export function useSigningRequest() {
  const context = useContext(SigningRequestContext)
  if (!context) {
    throw new Error('useSigningRequest must be used within a SigningRequestProvider')
  }
  return context
}

function SigningRequestProviderComponent({ children }: { children: React.ReactNode }) {
  const account = useStore.useAccount()
  const pushTransaction = useStore.usePushTransaction()
  const [state, setState] = useSetState(defaultState)
  const [{ step, steps, open }, { goTo, start }] = useWizard()
  const [, globalModalActions] = useGlobalModal()
  const { t } = useTranslation('global')

  const [requestState, requestSignature] = useAsyncFn(
    async (code: string, accountData: { account?: string; pubKey?: string }) => {
      try {
        // make user protocol esr:// is there only once
        const req = `esr://${code.replace('esr://', '')}`
        const esr = SigningRequest.from(req, esrOptions)

        let esrInfo = {}

        esr.data.info.forEach((i) => {
          esrInfo = {
            ...esrInfo,
            [i.key]: i.value.utf8String,
          }
        })

        if (
          !esrInfo.hasOwnProperty('appName') ||
          (esrInfo.hasOwnProperty('appName') && esrInfo['appName'] !== 'Bitlauncher') ||
          !(state?.callbackUrl || esr.data?.callback).match(
            /^(https:\/\/bitlauncher.ai\/|https:\/\/dev.bitlauncher.ai\/).*/,
          )
        ) {
          setState({
            // TODO: Translate this...
            error: 'Invalid signature request: Signatures are only valid for Bitlauncher app.',
          })
          return
        }

        if (!accountData.account && !accountData.pubKey) {
          setState({
            error:
              // TODO: Translate this...
              'Invalid signature request: No account found on device. Please log in first and retry',
          })
          return
        }

        // In order to resolve the transaction, we need a recent block to form it into a signable transaction
        const head = (await eos.v1.chain.get_info()).head_block_num
        const block = await eos.v1.chain.get_block(head)

        // Fetch the ABIs needed for decoding
        const abis = await esr.fetchAbis(esrOptions.abiProvider)

        // An authorization to resolve the transaction to
        const authorization = {
          actor: accountData.account,
          permission: 'active',
        }

        // Resolve the transaction as a specific user
        const resolved = await esr.resolve(abis, authorization, block)

        const actions = resolved.transaction.actions.map((action) => ({
          ...action,
          data: action.decodeData(abis.get(action.account.toString())),
        }))

        console.log('resolved...', resolved)

        setState({
          resolved,
          actions,
          esr,
          esrType:
            actions.length &&
            actions.some(
              (action) =>
                action.name.toString() === 'login' &&
                action.account.toString().match(/^(bkbaccountst|accounts.bk)$/),
            )
              ? 'login'
              : 'transaction',
        })

        if (!open && steps.length === 0) start(SIGNING_REQUEST_STEPS)

        goTo('sign_request')
      } catch (error) {
        setState({ callbackStatus: 'error', error: getErrorMessage(error) })
        globalModalActions.open({
          content: () => <ModalError error={getErrorMessage(error)} />,
          iconType: 'ERROR',
          showClose: true,
          title: 'Error',
        })
      }
    },
  )

  const resetError = () => {
    setState({ error: '' })
  }

  const pushSignRequest = async () => {
    try {
      console.log('broadcasting transaction')
      const tx = await pushTransaction(state.resolved.transaction, true)
      console.log('successful transaction', tx.processed.id)
      setState({ trxId: tx.processed.id })
      console.log('Current state ', state)
      console.log('calling callback')
      const response = await sendDataToCallback(
        {
          sa: account,
          sp: 'active',
          tx: tx.processed.id,
          req: state.esr?.toString() || '',
          rbn: tx.processed.block_num.toString(),
          ex: '',
          rid: '',
          sig: '',
        },
        state.esr.data.callback,
      )
      console.log('🦚 successfully called callback', response)

      if (!tx) return

      const cbParams = new URLSearchParams()
      cbParams.append(
        'esr_code',
        (state.params?.get('esr_code') || state.esr?.toString() || '').replace('esr://', ''),
      )
      cbParams.append('trx_id', tx.processed.id)

      const separator = state.callbackUrl.includes('?') ? '&' : '?'
      const callbackUrl = state.callbackUrl
        ? state.callbackUrl + separator + cbParams.toString()
        : state.callbackUrl

      console.log('✅', callbackUrl)
      console.log('✅!! state.esr.data.callback', state.esr.data.callback)
      setState({ ...tx, callbackUrl, callbackStatus: 'ok', error: '' })

      return {
        callbackUrl,
        error: '',
      }
    } catch (error) {
      setState({ error: getErrorMessage(error) })
      return {
        callbackUrl: '',
        error: getErrorMessage(error),
      }
    }
  }

  const cancelRequest = () => {
    setState({ ...defaultState })
  }

  const setCallbackUrl = (params: URLSearchParams, callbackUrl: string) => {
    setState({ params, callbackUrl })
  }

  const signRequestTitle = state.esrType === 'login' ? 'login_request' : 'signature_request'

  return (
    <SigningRequestContext.Provider
      value={[
        {
          ...state,
          ...requestState,
          error: state.error || requestState?.error?.message || '',
        },
        {
          requestSignature,
          resetError,
          pushSignRequest,
          cancelRequest,
          setCallbackUrl,
        },
      ]}
    >
      {children}
      <Wizard
        title={t(step === 'sign_request' ? signRequestTitle : step)}
        error={state.error}
        resetError={resetError}
      />
    </SigningRequestContext.Provider>
  )
}

export function SigningRequestProvider({ children }: { children: React.ReactNode }) {
  return (
    // @ts-ignore
    <WizardProvider>
      <SigningRequestProviderComponent>{children}</SigningRequestProviderComponent>
    </WizardProvider>
  )
}

async function sendDataToCallback(data: CallbackPayload, callbackUrl: string) {
  return fetch(callbackUrl, {
    mode: 'no-cors',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) => {
    // if (!response.ok) {
    //   throw new Error('Failed to send data to callback URL')
    // }
    console.log('Data sent successfully to callback URL')
    return response
  })
}

const eos = new APIClient({
  url: 'https://eos.dfuse.eosnation.io',
})

const esrOptions: SigningRequestEncodingOptions = {
  abiProvider: {
    getAbi: async (account) => {
      const response = await eos.v1.chain.get_abi(account)
      return response.abi
    },
  } as AbiProvider,
  zlib: pako,
}
