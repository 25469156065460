import { createContextHook } from '@blockmatic/hooks-utils'
import { useSetState } from 'react-use'
import { UseWizardAPI, UseWizardState, WizardStep } from '../types'

const initialState: UseWizardState = {
  step: 'error',
  steps: [],
  open: false,
  index: 0,
}

const useWizardFn = (): [UseWizardState, UseWizardAPI] => {
  const [state, setState] = useSetState(initialState)

  const close = () => setState({ open: false })

  const getCurrentStepIndex = () => {
    const currentStep = state.steps.find((s) => s.name === state.step)
    return state.steps.indexOf(currentStep!)
  }

  const prev = () => {
    const prevStepIndex = getCurrentStepIndex() - 1
    if (prevStepIndex >= 0) {
      return setState({ step: state.steps[prevStepIndex].name, index: prevStepIndex })
    }
    return close()
  }

  const next = () => {
    const nextStepIndex = getCurrentStepIndex() + 1
    if (nextStepIndex < state.steps.length)
      setState({ step: state.steps[nextStepIndex].name, index: nextStepIndex })
  }

  const goTo = (step: string) => setState({ step })

  const start = (newSteps: WizardStep[]) =>
    setState({
      steps: newSteps,
      step: newSteps[0].name,
      open: true,
      index: 0,
    })

  return [state, { start, goTo, next, prev, close }]
}

export const [useWizard, WizardProvider] = createContextHook(
  useWizardFn,
  'You must wrap your application with <WizardProvider /> in order to useWizard().',
)
