import { Link } from 'pages/AccountView/components/TextElements'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BiLinkExternal } from 'react-icons/bi'
import { SuccessAnimation } from '../SuccessAnimation'
import { ModalText } from './Text'

export const ModalSuccess: React.FC<{ text: string; redirect?: string }> = ({ text, redirect }) => {
  const { t } = useTranslation('wallet')
  const transactionIDLabelLink = redirect?.replace('https://bloks.io/transaction/', '')
  const transactionLabelLink = transactionIDLabelLink
    ? `${transactionIDLabelLink.substring(0, 4)}...${transactionIDLabelLink.substring(
        transactionIDLabelLink?.length - 4,
        transactionIDLabelLink?.length,
      )}`
    : ''

  return (
    <>
      <ModalText style={{ textAlign: 'center' }}>
        <SuccessAnimation />
        {`${text}
        `}
        {redirect ? (
          <Link href={redirect} target="__blank" style={{ justifyContent: 'center' }}>
            {transactionLabelLink ? `${t('transaction_id')}: ${transactionLabelLink}` : redirect}
            <BiLinkExternal />
          </Link>
        ) : null}
      </ModalText>
    </>
  )
}
