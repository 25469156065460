import { bitcashPrivateRoom } from 'app-view/hooks/use-realtime'
import { IUserPositionEntry } from './user-positions.schema'

export const useRealTimeUserPositions = () => {
  const _userPositions = bitcashPrivateRoom.useColyseusState((state) => state.userPositions)
  const userPositions = _userPositions
    ? (Object.values(_userPositions.toJSON()) as IUserPositionEntry[])
    : []
  return userPositions
}
