import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store'
import { AddressInput } from 'pages/SwapView/steps/common/AddressInput'
import styled from 'app-view/styles/styled'

interface PasteAddressProps {
  onPaste: (address: string) => void
}

const StyledPasteAddress = styled.div`
  margin-bottom: 40px;
  width: 100%;
  padding: 0 1.25rem;
`

export const PasteAddress = ({ onPaste }: PasteAddressProps) => {
  const [trimAddress, setTrimAddress] = useState(false)
  const [addressError, setAddressError] = useState('')
  const { t } = useTranslation(['swap'])
  const {
    coinSwap: { swap },
  } = useStore()

  const onChangeHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onPaste(value)
    setAddressError(value === '' ? t('swap:general.errorInputEmpty') : '')
  }

  return (
    <StyledPasteAddress>
      <AddressInput
        onChange={onChangeHandler}
        onBlur={() => setTrimAddress(swap.wallet_address ? true : false)}
        onFocus={() => setTrimAddress(false)}
        value={
          trimAddress
            ? `…${swap.wallet_address?.substring(
                swap.wallet_address!.length - 4,
                swap.wallet_address!.length,
              )}`
            : swap.wallet_address || ''
        }
        placeholder={t('swap:general.pressToPaste')}
        error={addressError}
      />
    </StyledPasteAddress>
  )
}
