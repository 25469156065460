import { ApolloProvider } from '@apollo/client'
import { TransitProvider } from '@blockmatic/eosio-hooks-transit'
import { ThemeProvider } from '@emotion/react'
import { SocketProvider } from 'SocketProvider'
import { config } from 'app-config'
import i18n from 'app-config/i18n'
import theme from 'app-config/theme'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { GlobalModalProvider } from 'app-view/components/GlobalModal/useGlobalModal'
import { ProfileProvider } from 'app-view/hooks/use-profile.hook'
import { NavigationProvider } from 'app-view/hooks/useNavigation'
import usePageTracking from 'app-view/hooks/usePageTracking'
import { initAccessContext } from 'eos-transit'
import anchor from 'eos-transit-anchorlink-provider'
import { SigningRequestProvider } from 'hooks/use-signing-request'
import React from 'react'
import { I18nextProvider } from 'react-i18next'

const eosioRpcUrl = new URL(config.services.chainRpc)

const transitAccessContext = initAccessContext({
  appName: 'bitcash_app',
  network: {
    host: eosioRpcUrl.hostname,
    port: eosioRpcUrl.protocol === 'https:' ? 443 : 80,
    protocol: eosioRpcUrl.protocol.replace(':', ''),
    chainId: config.eosChainId,
  },
  walletProviders: [anchor('bitcash_app')],
})

// TODO: review this autologin
const usingWebAuthnN = Boolean(localStorage.getItem('user-account-webauthn') === 'true')
export const AppProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  usePageTracking()
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18n}>
          {/* @ts-ignore */}
          <TransitProvider accessContext={transitAccessContext} autoLogin={!usingWebAuthnN}>
            {/* @ts-ignore */}
            <NavigationProvider>
              <SocketProvider>
                <ProfileProvider>
                  {/* @ts-ignore */}
                  <GlobalModalProvider>
                    <SigningRequestProvider>{children}</SigningRequestProvider>
                  </GlobalModalProvider>
                </ProfileProvider>
              </SocketProvider>
            </NavigationProvider>
          </TransitProvider>
        </I18nextProvider>
      </ApolloProvider>
    </ThemeProvider>
  )
}
