import React from 'react'
import { HeaderStep } from './HeaderStep'
import { FooterStep } from './FooterStep'
import { KeySubmit } from '../../components/KeyRecovery/KeySubmit'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useKeyRecovery } from 'pages/DeviceKeysView/hooks/useKeyRecovery'
import { useStore } from 'app-engine/store'

export const RecoverySubmit = ({ next }: WizardStepProps) => {
  const [{}, { setState }] = useKeyRecovery()
  const {
    keyRecoveryData: { partnersDao },
  } = useStore()

  const submit = () => {
    setState({ loading: true })
    next()
  }

  return (
    <>
      <HeaderStep />
      <KeySubmit partners={partnersDao?.partners || []} submit={submit} />
      <FooterStep />
    </>
  )
}
