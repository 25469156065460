export default {
  recently_trust: '最近信任了你。',
  trust_back: '你想回信吗？',
  chat: '聊天',
  untrust_user: '不信任用户',
  matched: '匹配成功',
  unmatched: '未匹配',
  unmatched_user: '未匹配 - 你',
  unmatched_all: '未匹配 - 全部',
  private_offers: '私人优惠',
  private_my_offers: '我的优惠',
  trusted: '信任',
  failure_message: '失败消息',
  trust_request: '请求',
  error_body: `我们在创建你的
  $优惠时遇到了困难。`,
  error_body_payment: `我们在确认你的
  $优惠时遇到了困难。`,
  request_success: '请求发送成功',
  do_you_want: `您是否想要接受 {{cryptoAmount}}
  {{methodDetails}} 的报价？`,
  error_body_cancel: `我们在取消你的
  $优惠时遇到了困难。`,
  sell: '出售',
  buy: '购买',
  offers: '已完成优惠',
  mutual: '相互',
  trust_users: '网络',
  cancelled_offer: '取消优惠',
  closed_offer: '关闭优惠',
  open_offer: '打开优惠',

  clearing_filters: '清除 P2P 提供过滤器...',
  applyng_filters: '应用 P2P 提供过滤器...',
  clear_filter_btn_text: '清除过滤器',
  select_multiple_currency: '选择多种货币',
  payment_method: '付款方式',
  prices: '价格',

  paypal: '贝宝',
  venmo: 'Venmo',
  cash_app: 'Cash App',
  zelle: 'Zelle',
  bank_transfer: '银行转账',
  sinpe_movil: 'SINPE Móvil',
  oxxo: 'OXXO',
  ovo: 'OVO',
  duitnow: 'DuitNow',
  grab: 'Grab',
  gojek: 'Gojek',
  opay: 'OPay',
  palmpay: 'PalmPay',
  kuda: 'Kuda',
  wechat: '微信',
  alipay: '支付宝',
  revolut: 'Revolut',
  bizum: 'Bizum',
  lyf: 'Lyf',

  network: '网络',
  public: '公开',
  private: '私有',

  offer: '优惠',
  sell_now: '现在出售',
  buy_now: '现在购买',
  filter_by_title: '筛选条件',
  filter_option_buy: '购买',
  filter_option_sell: '出售',
  filter_option_recent: '最近',
  filter_option_older: '较旧',
  filter_option_open: '开启',
  filterOptionCancelled: '已取消',
  filterOptionCompleted: '已完成',
  no_offers: '目前没有{{filter}}优惠',
  no_request: '目前没有请求',
  no_accounts: '目前没有账户',
  amount_to_sell: '你想出售多少{{symbol}}？',
  how_you_want_get_paid: `你想如何
  收款？`,
  sell_confirm: `确认你出售
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 手续费) 的优惠。

  你将使用 <span>{{method}}</span> 收取 <span>{{amount}}</span>。`,

  amount_to_buy: '你想购买多少{{symbol}}？',
  how_you_want_to_pay: `你想如何
  付款？`,
  buy_confirm: `确认你购买
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 手续费) 的优惠。

  你将使用 <span>{{method}}</span> 发送 <span>{{amount}}</span>。`,

  buy_duplicating: `复制 {{amount}} {{method}} 购买报价`,
  sell_duplicating: `复制 {{amount}} {{method}} 卖出报价`,

  from: '来自',
  using: '使用',
  ok: '确定',

  creating_buy_offer: '创建购买优惠',
  'Congrats we have found a match': '恭喜，我们找到了匹配',
  'wants to sell': '想出售',
  please_contact: '请联系',
  'and send': '并发送',

  'You are matched': '你被匹配了',
  'Did you send': '你发送了吗',
  to: '至',
  on: '在',
  'send you': '发送给你',

  'You have sent': '你已发送',
  'Please remind': '请提醒',
  'to confirm receipt of': '确认收到',
  Contact: '联系',

  cancel_approved: '取消已批准',
  cancel_requested: '取消已请求',
  confirmed_uncompleted_offer: '已确认未完成的优惠',
  confirmed_uncompleted_offer_error: '已确认未完成的优惠错误',
  on_chain_matching_preoffer_complete: '链上匹配优惠完成',
  on_chain_matching_preoffer_start: '链上匹配优惠开始',
  on_chain_matching_preoffer_timeout_error: '链上匹配优惠超时错误',
  unconfirmed_offer_12h: '未确认的优惠12小时',
  unconfirmed_offer_12h_error: '未确认的优惠12小时错误',
  unexpected_error: '意外错误',
  unmatched_offer_48h: '未匹配的优惠48小时',
  unmatched_offer_48h_error: '未匹配的优惠48小时错误',
  unprocessed_indexed_confirm_offer: '未处理的索引确认优惠',
  unprocessed_indexed_offer: '未处理的索引优惠',
  cancel_by_request: '取消请求',
  failed_to_complete_offer: '无法完成优惠',

  Next: '下一步',
  Back: '返回',
  Confirm: '确认',
  Done: '完成',

  'If the problem persists, ask for support on Telegram':
    '如果问题仍然存在，请在Telegram上寻求支持',

  no_match_yet: '对不起，还没有匹配！',
  will_connect_with_buyer_soon: '我们将尽快为您找到买家',
  will_connect_with_seller_soon: '我们将尽快为您找到卖家',
  check_back_in_few_minutes: '请稍后再检查',

  confirming_payment: '确认付款',
  Not_yet: '还没有',
  Yes: '是',
  No: '否',
  Retry: '重试',
  'Work in progress!': '工作进行中！',
  'You have sold': '你已经出售了',
  creating_sell: '创建销售订单',
  'Congrats we have found a match!': '恭喜，我们已经找到了匹配的交易！',
  'wants to buy': '想要购买',
  'Please ask': '请要求',
  'to send you': '给你发送',
  'Did you receive': '你收到了吗',
  zero_balance: '你没有足够的{{symbol}}余额',

  confirmed_with: '你已经和',
  but_did_not_send: '确认，但没有发送',
  'but have not': '但是还没有',
  would_cancel_offer: `你想取消
  这个订单吗？`,
  not_now: '现在不',
  cancel_request_by: '取消请求由',
  order_cancelled: '你的订单已经取消。',
  'Did you contact': '你联系过',
  'Did you confirm sale with': '你确认过和',
  we_are_sorry: '我们很抱歉',
  no_longer_wants_to_sell: '不再想要出售',
  'Would you like to keep this offer': '你想要保留这个报价吗',
  'and find a new match': '并找到新的匹配',
  no_longer_wants_to_buy: '不再想要购买',
  'You have cancelled your offer to buy': '你已经取消了购买报价',
  you_cancelled_offer_to_sell: '你已经取消了出售报价',
  'Offer has been cancelled to buy': '购买报价已经被取消',
  offer_been_cancelled_to_sell: '出售报价已经被取消',
  confirmed_sending_you: '已经确认给你发送',
  bank_transfer_region_label: ' 地区。',
  bank_transfer_region: ' 在{{region}}{{isRegion}}',
  push_transaction_error_action_cancel: '取消你的',
  push_transaction_error_action_confirm: '确认你的',
  push_transaction_error_action_sell: '开始你的新',
  buy_matched_alt_offer: `<span>{{seller}}</span> 想要出售
  <span>{{amount}}</span> (+{{cryptoFee}} 费用)。

  你联系过 <span>{{seller}}</span>
  并确认了购买吗？

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> 想要出售
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 费用)。

  请联系 <span>{{seller}}</span>
  并发送 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_matched_offer: `
  恭喜我们已经找到了匹配！
  <span>{{seller}}</span> 想要出售
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 费用)。

  请联系 <span>{{seller}}</span>
  并发送 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_payment: `你已经匹配。
  <span>{{seller}}</span> 想要出售
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 费用)。

  你给 <span>{{seller}}</span> 发送了 <span>{{amount}}</span> 使用 <span>{{method}}</span> {{methodDetails}} 吗？

  `,
  buy_payment_confirmed: `你已经给 <span>{{seller}}</span> 发送了 <span>{{amount}}</span> 使用 <span>{{method}}</span> {{methodDetails}}

  请提醒 <span>{{seller}}</span> 确认收到 <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}}
  <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{methodDetails}}
  取消日期 {{date}}`,
  sell_cancel_deny: `我们很抱歉,
  <span>{{buyer}}</span> 已确认发出给您 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{methodDetails}}


  在 {{date}} 取消`,
  sell_matched_alt_offer: `<span>{{buyer}}</span> 想要购买
  <span>{{amount}}</span> (+{{cryptoFee}} 费用)。

  你确认过和
<span>{{buyer}}</span>的交易吗？

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> 想要购买
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 费用)。

  请要求 <span>{{buyer}}</span>
  给你发送 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_matched_offer: `
  恭喜我们已经找到了匹配！
  <span>{{buyer}}</span> 想要购买
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 费用)。

  请要求 <span>{{buyer}}</span>
  给你发送 <span>{{amount}}</span> 使用 <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_payment: `你已经匹配。
  <span>{{buyer}}</span> 想要购买
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} 费用)。

  你从 <span>{{buyer}}</span> 收到了 <span>{{amount}}</span> 使用 <span>{{method}}</span> {{methodDetails}} 吗？

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} 费用) 使用 <span>{{method}}</span>
  {{methodDetails}}
  在 {{date}} 取消`,
  accept: '接受',
  reject: '拒绝',
  accepted: '已接受',
  rejected: '已拒绝',
  loader: '加载中的信任网络列表...',
  select_region_country_search: `请，选择地区/国家或，
  输入进行搜索。`,

  sell_select_stable: `您想出售什么？
  `,
  buy_select_stable: `您想购买什么？
  `,

  phone_number_label: '{{phoneNumberCountryCode}} | 手机号码 #',
  account_number_label: '账户号码 #',
  bank_name_label: '银行名称',
  full_name_label: '全名',
  seller_method_details: '请提供您的银行信息，以便接收资金',
  seller_method_details_name_account: '请提供接收资金的姓名和账号。',
  seller_method_details_phone: `请提供接收资金的电话号码
  。`,
  seller_method_details_account: `请提供接收资金的账户号码
  。`,
  buyer_method_details: '请提供您的银行信息，以便发送资金。',
  buyer_method_details_name_account: '请提供发送资金的姓名和账号。',
  buyer_method_details_phone: `请提供发送资金的电话号码
  。`,
  buyer_method_details_account: `请提供发送资金的账户号码
  。`,

  purchase_completed: `购买完成。
  您已经购买了 <span> {{ amount }}</span> (+{{cryptoFee}} 费用)
  来自 <span> {{ seller }}</span> 使用
  <span> {{ method }}</span> {{sellerMethodDetails}} 在 {{date}}。

  {{ fundsSent }}
  {{ methodDetails }} `,
  sell_complete: `销售完成。
  您已经销售了 <span> {{ amount }}</span> (+{{cryptoFee}} 费用)
  给 <span> {{ buyer }}</span> 使用 <span>{{method}}</span>
  {{ buyerMethodDetails }} 在 {{ date }。

  {{ fundsReceived }}
  {{ methodDetails }} `,

  funds_sent: '您已收到资金 ',

  seller_method_phone_detail_label: `至电话号码 #
  <span class="p2p-method-detail-phone" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span> {{ region }}`,
  seller_method_bank_detail_label: `至 <span> {{ fullName }}</span> 在 <span>{{accountName}}</span> 账号 #
  <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
    {{ region }}`,
  seller_method_account_detail_label: `至账户 # <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,
  seller_method_phone_name_detail_label: `至 <span> {{ fullName }}</span>
的电话号码 # <span class="p2p-method-detail-account" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,
  buyer_method_phone_detail_label: `从电话号码 #
  <span class="p2p-method-detail-phone" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span> {{ region }}`,
  buyer_method_bank_detail_label: `从 <span> {{ fullName }}</span> 在 <span>{{accountName}}</span> 账号 #
  <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
    {{ region }}`,
  buyer_method_account_detail_label: `从账户 # <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,
  buyer_method_phone_name_detail_label: `从 <span> {{ fullName }}</span>
的电话号码 # <span class="p2p-method-detail-account" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,

  copied: '复制成功。',

  show_more: '<b class="p2p-show-details" id="show-more-details">… 显示更多</b>',
  show_less: '<b class="p2p-show-details" id="show-less-details"> 显示更少</b>',
}
