// about.ts

export default {
  heading:
    'Chào mừng đến với Ứng dụng Bitcash, một cách dễ dàng và an toàn để mua và giao dịch tiền điện tử chất lượng.',
  t1: 'Làm thế nào để mua tiền điện tử?',
  p1: '• Đi đến P2P và nhấn Mua để kết nối với người khác để mua bitUSD bằng Venmo, Paypal hoặc Zelle từ những người khác trong cộng đồng của chúng tôi.\n\n• Sử dụng bitUSD để giao dịch thành các loại tiền điện tử khác.\n\nĐó là tất cả. Bảo tồn sự tự do và quyền riêng tư của bạn khỏi anh trai lớn. Không cần đăng ký ID hoặc SSN của bạn. Không có KYC / AML.',
  t2: 'Bạn có thể làm gì khác với bitUSD?',
  p2: 'Bạn có thể trả tiền cho người khác cho hàng hóa và dịch vụ và cho việc sử dụng hàng ngày giống như đô la Mỹ!',
  home_screen:
    'Bạn có thể thêm Ứng dụng Bitcash vào Màn hình chính của bạn và sử dụng nó giống như một ứng dụng thông thường!',
  what_is_web: 'Ứng dụng web là gì?',
  do_it: 'hãy làm điều đó!',
}
