// App Routes
export const routes = {
  account_view: [
    '/account',
    '/create-account',
    '/create-account/kyc',
    '/register-account/kyc',
    '/register-account',
    '/create-account/success',
    '/register-account/success',
    '/create-account/webauthn-cred',
    '/add-device/account',
    '/add-device/webauthn-cred',
    '/add-device/success',
    '/admin-migrate',
    '/migrate',
    '/migrate/success',
  ],
  access_view: ['/', '/login', '/signup'],
  pwa_available_screens: ['/trade', '/swap', '/wallet', '/history', '/p2p', '/bank'],
}
