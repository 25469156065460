import { P2POffer } from 'app-engine/store/p2p-slice'
import { bitcashPrivateRoom } from 'app-view/hooks/use-realtime'

export const useRealTimeUncompletedMutualTrustP2POffers = () => {
  const rawOffers = bitcashPrivateRoom.useColyseusState(
    (state) => state.uncompletedTrustNetworkP2POffers,
  )
  const mutualTrustP2POffers = rawOffers ? (Object.values(rawOffers.toJSON()) as P2POffer[]) : []
  return mutualTrustP2POffers
}
