import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { validateGraphqlError } from 'app-engine/library/utils'
import { StoreSlice } from 'app-engine/store'
import { Subscription } from 'zen-observable-ts'
import { apolloClient } from '../graphql/apollo-client'

export const p2pBuyFee = 0.05
export const p2pBuyPercentage = 0.95
export const p2pDAOFee = 0.01
export const p2pSellFee = 0.04

const hasAccount = (data: Bitcash.RegisterAccountSubscription | null | undefined) =>
  data && data.accounts && data.accounts.length >= 0

export type P2POffer = Bitcash.P2POffersSubscription['p2p_offers'][number]

export type AccountRelationship = {
  account: string
  is_mutual: boolean
}

export type RegisterAccount = Bitcash.RegisterAccountSubscription['accounts'][number]
export type P2PRegisterAccount = RegisterAccount
type RegisterAccounts = Record<string, RegisterAccount>

type P2PSliceAttributes = {
  registerAccounts: RegisterAccounts
  isRegisterAccountsSubscribed: boolean
  isRegisterAccountsLoading: boolean
  registerAccount: P2PRegisterAccount | undefined
  isRegisterAccountSubscribed: boolean
  isRegisterAccountLoading: boolean
  totalAccountsCount: number
}

type P2PSliceActions = {
  subscribeRegisterAccounts: (offset?: number, limit?: number, keyword?: string) => void
  unsubscribeSubscribeRegisterAccounts: () => void
  subscribeRegisterAccount: () => void
  unsubscribeSubscribeRegisterAccount: () => void
  reset: () => void
}

export type P2PSlice = P2PSliceAttributes & P2PSliceActions

const defaultP2PState: P2PSliceAttributes = {
  registerAccounts: {},
  isRegisterAccountsSubscribed: false,
  isRegisterAccountsLoading: false,
  registerAccount: undefined,
  isRegisterAccountSubscribed: false,
  isRegisterAccountLoading: false,
  totalAccountsCount: 0,
}

let registerAccountsSubscription: Subscription | undefined
let registerAccountSubscription: Subscription | undefined

const unsubscribeRegisterAccountsData = () => {
  if (registerAccountsSubscription) {
    registerAccountsSubscription.unsubscribe()
    registerAccountsSubscription = undefined
  }
}

const unsubscribeRegisterAccountData = () => {
  if (registerAccountSubscription) {
    registerAccountSubscription.unsubscribe()
    registerAccountSubscription = undefined
  }
}

const createRegisterAccountObservable = (account: string) =>
  apolloClient.subscribe<
    Bitcash.RegisterAccountSubscription,
    Bitcash.RegisterAccountSubscriptionVariables
  >({
    query: Bitcash.RegisterAccountDocument,
    variables: {
      account,
    },
    fetchPolicy: 'no-cache',
  })

export const createP2PSlice: StoreSlice<P2PSlice> = (set, get) => ({
  ...defaultP2PState,
  reset: () => {
    set(defaultP2PState)
  },
  subscribeRegisterAccounts: (offset = 0, limit = 20, keyword) => {
    set({ isRegisterAccountsLoading: true })
    const fetchAccounts = async () => {
      try {
        const response = await apolloClient.query({
          query: Bitcash.RegisterAccountsDocument,
          variables: {
            offset,
            limit,
            ...(keyword
              ? {
                  where: {
                    account: {
                      _iregex: keyword,
                    },
                  },
                }
              : {}),
          },
          fetchPolicy: 'network-only',
        })
        // Validate and process the response data
        if (response.errors) {
          console.error('Error fetching accounts:', response.errors)
          set({ isRegisterAccountsLoading: false })
        } else {
          const fetchedAccounts = response.data.accounts.nodes
          set((state) => {
            // Merge the newly fetched accounts with the existing ones in the state
            const updatedAccounts = { ...state.registerAccounts }
            fetchedAccounts.forEach((account) => {
              updatedAccounts[account.id] = account
            })
            // Update the state with the merged accounts and new total count
            return {
              registerAccounts: updatedAccounts,
              totalAccountsCount: response.data.accounts.aggregate.count,
              isRegisterAccountsLoading: false,
            }
          })
        }
      } catch (error) {
        console.error('Error fetching accounts:', error)
        set({ isRegisterAccountsLoading: false })
      }
    }
    // Execute the fetch function.
    fetchAccounts()
  },

  unsubscribeSubscribeRegisterAccounts: () => {
    unsubscribeRegisterAccountsData()
    set({ isRegisterAccountsSubscribed: false })
  },
  subscribeRegisterAccount: () => {
    try {
      set({ isRegisterAccountLoading: true })
      const { account: userAccount } = get()
      unsubscribeRegisterAccountData()
      const registerAccountObservable = createRegisterAccountObservable(userAccount)
      registerAccountsSubscription = registerAccountObservable.subscribe(({ data, errors }) => {
        try {
          validateGraphqlError(errors)
          if (!hasAccount(data)) return
          const registerAccount = data!.accounts[0]
          console.log('registerAccount', registerAccount)
          set({
            isRegisterAccountLoading: false,
            registerAccount,
          })
        } catch (error) {
          console.log('[ERROR] [RegisterAccount]', (error as Error).message)
        }
      })
      set({ isRegisterAccountSubscribed: true })
    } catch (error) {
      console.log('[ERROR] [RegisterAccount]', (error as Error).message)
      set({ isRegisterAccountSubscribed: false, isRegisterAccountLoading: false })
    }
  },
  unsubscribeSubscribeRegisterAccount: () => {
    unsubscribeRegisterAccountData()
    set({ isRegisterAccountSubscribed: false })
  },
})
