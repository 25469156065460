import {
  Order_By,
  SwapOrdersSubscriptionVariables,
  useSwapOrdersSubscription,
} from 'app-engine/graphql/generated/bitcash'
import { isMobile } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { getOrderWithFee } from 'app-engine/store/swap-slice'
import { ChatProvider } from 'app-view/components/Chat/hooks/useChat'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { Select } from 'app-view/components/Select'
import { ActionsContainer, HistoryFilterContainer } from 'app-view/components/Styled'
import isEqual from 'lodash.isequal'
import { HistoryContentProps } from 'pages/HistoryView'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { HistorySkeleton } from '../HistorySkeleton'
import { ModalHistory } from '../Modal'
import { HighlightText } from '../Styled'
import { SwapOrderItem } from './Order'

type FilterOption = {
  label: string
  value: {
    variables: SwapOrdersSubscriptionVariables
  }
}

const baseVars = {
  where: {
    order_status: { _in: ['filled', 'open', 'cancelled'] },
  },
}
const rawSelectOptions: FilterOption[] = [
  {
    label: 'history:older',
    value: {
      variables: {
        where: baseVars.where,
        order_by: {
          created_at: Order_By.Asc,
        },
      },
    },
  },
  {
    label: 'history:recent',
    value: {
      variables: {
        where: baseVars.where,
        order_by: {
          created_at: Order_By.Desc,
        },
      },
    },
  },
  {
    label: 'global:buy',
    value: {
      variables: {
        where: {
          order_type: {
            _eq: 'buy',
          },
        },
      },
    },
  },
  {
    label: 'global:sell',
    value: {
      variables: {
        where: {
          order_type: {
            _eq: 'sell',
          },
        },
      },
    },
  },
]

export const Swap: React.FC<HistoryContentProps> = ({ content_id }) => {
  const { t } = useTranslation(['history, global'])
  const account = useStore.useAccount()
  const navigate = useNavigate()
  const location = useLocation()
  const [, globalModalActions] = useGlobalModal()
  const selectOptions: FilterOption[] = rawSelectOptions.map((rawSelOpt) => ({
    ...rawSelOpt,
    label: t(rawSelOpt.label),
  }))
  const [variables, setVariables] = useState<SwapOrdersSubscriptionVariables>(
    selectOptions[0].value.variables,
  )
  const { data, loading } = useSwapOrdersSubscription({
    variables,
    skip: !variables.where?.bitcash_account,
    fetchPolicy: 'no-cache',
  })

  const onFilterChange = (filter_option: FilterOption) => {
    if (!filter_option) return

    const { value } = filter_option
    const newVariables = {
      ...value.variables,
      where: {
        ...value.variables.where,
        ...baseVars.where,
        bitcash_account: {
          _eq: account,
        },
      },
    }

    if (!isEqual(variables, newVariables)) {
      setVariables(newVariables)
    }
  }

  useEffect(() => {
    if (loading || !data?.swap_orders || !content_id) return
    const offer = data?.swap_orders.find((offer) => offer.id === content_id)

    if (!offer) return

    globalModalActions.open({
      content: () => (
        // @ts-expect-error
        <ChatProvider>
          <ModalHistory order={getOrderWithFee(offer)} />
        </ChatProvider>
      ),
      showClose: true,
      autoHide: false,
    })

    navigate({ pathname: location.pathname, search: '' }, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content_id, data?.swap_orders, loading])

  return (
    <>
      <HistoryFilterContainer isMobile={Boolean(isMobile)}>
        <Select
          label={t('history:filterBy')}
          options={selectOptions}
          onChange={onFilterChange}
          gray
          width="100%"
        />
      </HistoryFilterContainer>
      <ActionsContainer>
        {loading && <HistorySkeleton />}
        {!data?.swap_orders?.length && !loading && (
          <HighlightText>{t('history:There is currently no history')}</HighlightText>
        )}
        {data?.swap_orders?.length! > 0 &&
          data?.swap_orders?.map((order) => (
            <SwapOrderItem order={getOrderWithFee(order)} key={order.id} />
          ))}
      </ActionsContainer>
    </>
  )
}
