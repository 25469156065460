import React from 'react'
import AnchorLink from 'anchor-link'
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport'
import { config } from 'app-config/index'
import { RegistrationData } from 'app-engine/types/registration'
import { UseFormReturn } from 'react-hook-form'

export interface AccountInputHandlerProps {
  form: any
  field: 'account' | 'referrer'
  message: string
  domain?: boolean
  referrer?: boolean
}
export type FormData = Pick<RegistrationData, 'account' | 'referrer'>

let timeout: any

export const checkAccountExt = (account: string) =>
  !account.includes('.') && account.length < 12 ? `${account}.bk` : account

export const accountInputHandler = (
  { target: { name, value } }: React.ChangeEvent<HTMLInputElement>,
  { form, field, message, domain, referrer }: AccountInputHandlerProps,
  verifyExistence?: (
    form?: UseFormReturn<any, object>,
    isRegister?: boolean | undefined,
  ) => Promise<void>,
) => {
  const regExpFullAcct = /^([a-z]|[1-5]|.){1,12}$/
  const regExpAcct = /^([a-z]|[1-5]){1,9}$/

  if (timeout) clearTimeout(timeout)

  if (!value.match(referrer || domain ? regExpFullAcct : regExpAcct) && value.length > 0) {
    form.setError(field, { message })
  } else {
    form.clearErrors(field)

    timeout =
      name === 'account' &&
      verifyExistence &&
      value &&
      setTimeout(() => {
        verifyExistence()

        clearTimeout(timeout)
      }, 1125)
  }

  return form.setValue(name as keyof FormData, value.trim().toLowerCase())
}

export const newAnchorLink = new AnchorLink({
  chains: [
    {
      chainId: config.eosChainId,
      nodeUrl: config.services.chainRpc,
    },
  ],
  transport: new AnchorLinkBrowserTransport({}),
})
