import { StoreSlice } from 'app-engine/store'
import { NumberFormatValues } from 'react-number-format'

export type ExchangeSlice = {
  loadingExchangeTx: boolean
  stableCoinFrom: string
  stableCoinTo: string
  stableAmountFrom: NumberFormatValues | null
  setStableCoinFrom: (from: string) => void
  setStableAmountFrom: (props: NumberFormatValues | null) => void
  setStableCoinTo: (to: string) => void
  swapStableCoins: () => void
  reset: () => void
}

const defaultTokenState = {
  loadingExchangeTx: false,
  stableCoinFrom: '',
  stableCoinTo: '',
  stableAmountFrom: null,
}

export const createExchangeSlice: StoreSlice<ExchangeSlice> = (set, get) => ({
  ...defaultTokenState,
  reset: () => {
    set(defaultTokenState)
  },
  setStableCoinFrom: (from) => {
    set({ stableCoinFrom: from })
  },
  setStableAmountFrom: (from) => {
    set({ stableAmountFrom: from })
  },
  setStableCoinTo: (to) => {
    set({ stableCoinTo: to })
  },
  swapStableCoins: () => {
    const { stableCoinTo, stableCoinFrom } = get()
    set({ stableCoinFrom: stableCoinTo, stableCoinTo: stableCoinFrom })
  },
})
