import React from 'react'
import styled from 'app-view/styles/styled'
import Lottie from 'react-lottie'
import cameraLoaderData from 'app-view/assets/lotties/camera-loader.json'

const CameraLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.24);
  > div:first-of-type {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

export const CameraLoaderAnimation: React.FC = () => {
  const defaultOpt = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const dimensions = {
    width: 300,
    height: 200,
  }

  return (
    <CameraLoaderWrapper>
      <Lottie options={{ ...defaultOpt, animationData: cameraLoaderData }} {...dimensions} />
    </CameraLoaderWrapper>
  )
}
