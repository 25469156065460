import React from 'react'
import styled from 'app-view/styles/styled'
import { MinusCircleIcon } from 'app-view/components/icons'
import { hideBkExt } from 'app-engine/library/utils'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10%;
  padding-top: 18px;
`
const Item = styled.div`
  display: flex;
  width: 45%;
  font-weight: 700;
  letter-spacing: -0.1px;
  justify-content: space-between;
  gap: 5px;
  color: #495057;
  align-items: center;
  margin-bottom: 32px;
  svg {
    cursor: pointer;
  }
  svg:hover path {
    fill: ${(props) => props.theme.colors.green};
    transition: all 0.3s;
  }
`

export const ListPartner: React.FC<{
  partners: string[]
  removePartner: (item: string) => void
}> = ({ partners, removePartner }) => {
  if (!partners.length) return null

  return (
    <Container>
      {partners.map((item) => (
        <Item key={item}>
          {hideBkExt(item)} <MinusCircleIcon onClick={() => removePartner(item)} />
        </Item>
      ))}
    </Container>
  )
}
