import { toggleTrustNetwork } from 'app-engine/services'
import { useStore } from 'app-engine/store'
import Chat from 'app-view/components/Chat'
import {
  ModalButtonsContainer,
  ModalError,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ToggleTrustButton } from 'app-view/components/buttons'
import { buyerCryptoAmountLessP2PFee, formattedDate, getDAOAmountFee } from 'app-view/lib/utils'
import { checkAccountExt } from 'pages/AccountView/utils'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useChat, ChatProvider } from 'app-view/components/Chat/hooks/useChat'
import { ChatType } from 'app-view/components/Chat/types'
import { useRealTimeTrustNetworkFollowingAccounts } from 'app-view/hooks/use-realtime/use-realtime-trust-network-following-account'

const SellCompleteComponent = ({ close }: WizardStepProps) => {
  const { t, i18n } = useTranslation(['p2p', 'global'])
  const location = useLocation()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, buyerMethodDetailsLabelData } = useP2PLabels()
  const [{ new_message }, { onOpenWithProps, notifyNewMessage }] = useChat()
  const followingAccounts = useRealTimeTrustNetworkFollowingAccounts()
  const { authErrorFallback } = useStore()

  const partnerAccount = checkAccountExt(offer.buyerLabel || '')
  const isFollowingAccepted = followingAccounts.includes(partnerAccount)
  const trustLabel = isFollowingAccepted ? t('global:remove_trust') : t('global:add_trust')

  const toggleTrustAccount = async () => {
    setLoading(true)
    try {
      await toggleTrustNetwork(partnerAccount)
    } catch (error) {
      authErrorFallback(error as Error)
      setError((error as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const onContact = () => {
    notifyNewMessage(0)
    onOpenWithProps({
      from: offer.seller as string,
      to: offer.buyer as string,
      id: {
        type: 'p2p' as ChatType,
        id: offer.id,
      },
    })
  }

  if (loading) return <Loader />

  return (
    <>
      {!loading && !error && (
        <>
          <ModalText
            dangerouslySetInnerHTML={{
              __html: t('sell_complete', {
                amount: buyerCryptoAmountLessP2PFee(offer.amount),
                cryptoFee: getDAOAmountFee(offer.amount),
                buyer: offer.buyerLabel,
                method: methodLabel(offer.method),
                buyerMethodDetails: isMethodDetails
                  ? buyerMethodDetailsLabelData.replace(/(?:.(?!\.))+$/, '')
                  : '',
                date: formattedDate(i18n.language, offer.updated_at || offer.created_at),
              }),
            }}
          />
          <ModalButtonsContainer>
            <ModalStepBlackButton onClick={onContact}>
              {/* TODO: To create a new msg counter for green button */}
              {new_message > 0 && (
                <span
                  style={{ background: '#0f4', fontWeight: 700, padding: 4, borderRadius: '50%' }}
                >
                  {new_message}
                </span>
              )}
              {t('p2p:Contact')}
            </ModalStepBlackButton>
            <ModalStepGreenButton onClick={close}>{t('p2p:ok')}</ModalStepGreenButton>
          </ModalButtonsContainer>
          <Chat />
          {!location.pathname.includes('/p2p') && (
            <ToggleTrustButton onClick={toggleTrustAccount} remove={isFollowingAccepted}>
              {trustLabel}
            </ToggleTrustButton>
          )}
        </>
      )}
      {loading && <Loader />}
      {error && <ModalError error={error} resetError={() => setError('')} />}
    </>
  )
}

export const SellComplete = (props: WizardStepProps) => (
  // @ts-expect-error
  <ChatProvider>
    <SellCompleteComponent {...props} />
  </ChatProvider>
)
