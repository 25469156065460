import React from 'react'

const KeyRecoveryIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" {...props}>
    <path
      d="M9 7C9.26522 7 9.51957 7.10536 9.70711 7.29289C9.89464 7.48043 10 7.73478 10 8C10 8.26522 9.89464 8.51957 9.70711 8.70711C9.51957 8.89464 9.26522 9 9 9C8.73478 9 8.48043 8.89464 8.29289 8.70711C8.10536 8.51957 8 8.26522 8 8C8 7.73478 8.10536 7.48043 8.29289 7.29289C8.48043 7.10536 8.73478 7 9 7ZM18 10C18 15.55 14.16 20.74 9 22C3.84 20.74 0 15.55 0 10V4L9 0L18 4V10ZM9 5C8.20435 5 7.44129 5.31607 6.87868 5.87868C6.31607 6.44129 6 7.20435 6 8C6 9.31 6.83 10.42 8 10.83V17H10V15H12V13H10V10.83C11.17 10.42 12 9.31 12 8C12 7.20435 11.6839 6.44129 11.1213 5.87868C10.5587 5.31607 9.79565 5 9 5Z"
      fill="white"
    />
  </svg>
)

export default KeyRecoveryIcon
