import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalButtonsContainer } from 'app-view/components/Modal'
import { useStore } from 'app-engine/store/index'
import { BlackButton, GreenButton } from 'app-view/components/buttons'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { ModalText } from 'app-view/components/Modal'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import { SwapOrder } from '../../../../app-engine/store/swap-slice'
import { getOrderWithFee } from 'app-engine/store/swap-slice'
import { get_amount } from 'app-view/lib/utils'

export const BuyGrin = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['swap', 'global'])
  const {
    submitSwap,
    coinSwap: { swap, loading },
  } = useStore()

  const confirmBuy = async () => {
    const { success } = await submitSwap()
    success && next()
  }

  if (loading) return <Loader text={`${t('buy.loader')}...`} />

  const { asset, bitcash_currency, fee_percentage, bitcash_amount } = swap
  const { total_crypto } = getOrderWithFee(swap as SwapOrder)
  const totalToReceive = get_amount(`${bitcash_amount} ${bitcash_currency}`)
  const totalToSend = fixedAmountDecimals(total_crypto, asset)

  return (
    <>
      <ModalText>
        {"You're buying "}
        <span>{totalToSend}</span>
        {`
        with a ${fee_percentage}% fee for `}
        <span>{totalToReceive}</span>
        {'.\n\n'}
        {t('buy.slatepack.text2')} {t('global:minus_network')}
      </ModalText>

      <ModalButtonsContainer>
        <BlackButton onClick={prev}>{t('back')}</BlackButton>
        <GreenButton onClick={confirmBuy}>{t('confirm')}</GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
