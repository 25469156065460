/** @tsx tsx */
import theme from 'app-config/theme'
import { hideBkExt } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import Chat from 'app-view/components/Chat'
import { useChat } from 'app-view/components/Chat/hooks/useChat'
import {
  AccountIcon,
  CardBoldText,
  CardBoldTextAlt,
  CardText,
  FlexBox,
  InfoContainer,
  TransactionCardContainer,
  p2pIconProps,
} from 'app-view/components/Styled'
import { useWizard } from 'app-view/components/Wizard'
import { DuplicateIcon, IndicatorIcon, TagIcon } from 'app-view/components/icons'
import { get_amount, hasAdminPermissions } from 'app-view/lib/utils'
import styled from 'app-view/styles/styled'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { DuplicateRoundIconButton } from '../Styled'
import { useP2POffer } from '../hooks/useP2POffer'
import { Badge } from './P2POffersTrustGlobalNetwork'

const LeftContainer = styled(FlexBox)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span:last-of-type {
    position: absolute;
    top: 0;
    right: -1.5rem;
  }
`

export const TransactionCardContainerShadow = styled(TransactionCardContainer)<{
  count?: number
  number?: number
}>`
  position: ${(props) => (props?.number === 1 ? 'relative' : 'absolute')};
  top: ${(props) => (props?.number === 1 ? '' : '0px')};
  left: ${(props) => (props?.number === 1 ? '' : '0px')};
  border: ${(props) => (props?.number !== 1 ? '1px solid #bfbfbf3a' : 'none')};
  transform: ${(props) =>
    props?.number === 1
      ? props?.count > 2
        ? 'translate(4px, -4px)'
        : 'none'
      : props?.number === 3
      ? 'translate(-4px, 4px)'
      : 'none'};
  z-index: ${(props) => (props?.number ? 4 - props.number : '')};
  box-shadow: ${(props) =>
    props?.number === 1
      ? props?.count > 1
        ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
        : 'none'
      : props?.number === 2
      ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
      : 'none'};
  -webkit-box-shadow: ${(props) =>
    props?.number === 1
      ? props?.count > 1
        ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
        : 'none'
      : props?.number === 2
      ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
      : 'none'};
`

const OfferText: React.FC<{ offer: P2POffer }> = ({ offer }) => {
  const { t } = useTranslation(['p2p'])
  const account = useStore.useAccount()
  const location = useLocation()

  const isAdmin = hasAdminPermissions(account) && location.pathname.includes('/admin')

  // ? If it is on admin view, then we show the regular card instead the cancelled card
  if (offer.cancelled && !location.pathname.includes('/admin')) {
    return (
      <FlexBox>
        <TagIcon {...p2pIconProps} />
        <CardBoldText color={theme.colors?.black}>{t('cancelled_offer')}</CardBoldText>
      </FlexBox>
    )
  }

  if (offer.cancelled && isAdmin) {
    return (
      <FlexBox>
        <TagIcon {...p2pIconProps} />
        <CardBoldText color={theme.colors?.black}>
          {'cancelled by: '}
          {offer.cancelled_by}
        </CardBoldText>
      </FlexBox>
    )
  }

  if (!offer.matched && !offer.cancelled) {
    return (
      <FlexBox>
        <TagIcon {...p2pIconProps} />
        <CardBoldText color={theme.colors?.black}>
          {offer.completed ? t('closed_offer') : t('open_offer')}
        </CardBoldText>
      </FlexBox>
    )
  }

  return (
    <FlexBox>
      <AccountIcon />
      <CardBoldText color={theme.colors?.black}>
        {offer.buyer === account ? hideBkExt(offer.seller || '') : hideBkExt(offer.buyer || '')}
      </CardBoldText>
      {/* IMPORTANT: we don´t show own online status */}
    </FlexBox>
  )
}

interface OfferProps {
  offer: P2POffer & { count?: number }
  onClick: () => void
}

const statusRegExp =
  /^(matched|buyer_confirmed_payment|buyer_confirmed_contact|seller_confirmed_payment|seller_confirmed_contact|completed|cancelled|no-match)$/

export const Offer: React.FC<OfferProps> = ({ offer, onClick }) => {
  const { t } = useTranslation(['p2p'])
  const location = useLocation()
  const [, { start, goTo }] = useWizard()
  const [{ offer: p2p_open_offer }, { startNewOffer, updateOffer }] = useP2POffer()
  const { p2p } = useStore()
  const account = useStore.useAccount()
  const [{ new_message }] = useChat()
  const { amount, id, seller, buyer, type } = offer
  const isBuyerSeller = p2p_open_offer.seller !== '' && p2p_open_offer.buyer !== ''
  const to_chat: string =
    isBuyerSeller && p2p_open_offer.buyer === account
      ? `${p2p_open_offer.seller}`
      : `${p2p_open_offer.buyer}`
  // User Experience: Has user been previously notified on a P2P Offer...
  const notifiedUser = p2p.find((p2p) =>
    p2p?.status && p2p.status.match(statusRegExp) && p2p?.id === offer.id ? true : false,
  )
  const isChatReady = p2p_open_offer.id === id && account && to_chat !== ''

  const isSell = account !== seller && account !== buyer ? type === 'buy' : seller === account
  const isAdmin = hasAdminPermissions(account) && location.pathname.includes('/admin')

  const duplicateOffer = async (offer: P2POffer) => {
    const newSteps = await startNewOffer(offer.type as 'sell' | 'buy')
    start(newSteps)
    goTo('duplicate')
    let offerData: any = {
      amount: offer.amount,
      method: offer.method,
      region: offer.region,
    }
    if (offer.type === 'sell') {
      offerData = {
        ...offerData,
        seller_method_details: offer.seller_method_details,
      }
    } else {
      offerData = {
        ...offerData,
        buyer_method_details: offer.buyer_method_details,
      }
    }
    updateOffer(offerData)
  }

  return (
    <div style={{ position: 'relative' }}>
      <TransactionCardContainerShadow
        role="button"
        onClick={onClick}
        number={1}
        count={offer.matched ? 0 : offer?.count}
      >
        {(new_message || !notifiedUser) && (
          <span style={{ position: 'absolute', right: -5, top: -5 }}>
            <IndicatorIcon type="pulse" />
          </span>
        )}
        {!offer.matched && offer.initiator === account && (
          <DuplicateRoundIconButton
            type="button"
            aria-label="DuplicateRoundIconButton"
            onClick={(e) => {
              e.stopPropagation()
              duplicateOffer(offer)
            }}
          >
            <DuplicateIcon style={{ width: '17px', height: '17px' }} stroke="white" />
            {offer?.count > 2 && (
              <Badge style={{ height: '14px', width: '14px', right: '2px' }}>
                {offer?.count > 9 ? '+' : ''}
                {offer?.count > 9 ? 9 : offer?.count}
              </Badge>
            )}
          </DuplicateRoundIconButton>
        )}
        <LeftContainer>
          <OfferText offer={offer} />
          <InfoContainer>
            <CardText
              style={{
                fontSize: 13,
              }}
            >
              {t(offer.method)}
            </CardText>
          </InfoContainer>
        </LeftContainer>
        <FlexBox justifyContent="flex-end">
          <CardBoldText
            dollars
            color={isSell || isAdmin ? theme.colors?.red : theme.colors?.green}
            style={{ marginRight: '0.3rem' }}
          >
            {isSell || isAdmin ? t('sell') : t('buy')}
          </CardBoldText>
          <CardBoldTextAlt color={theme.colors?.black} dollars>
            {get_amount(amount)}
          </CardBoldTextAlt>
        </FlexBox>
      </TransactionCardContainerShadow>
      {!offer.matched && offer?.count > 2 && (
        <TransactionCardContainerShadow
          role="button"
          number={2}
          count={offer?.count || 0}
        ></TransactionCardContainerShadow>
      )}
      {!offer.matched && offer?.count !== 1 && (
        <TransactionCardContainerShadow
          role="button"
          number={3}
          count={offer?.count || 0}
        ></TransactionCardContainerShadow>
      )}
      {isBuyerSeller && isChatReady && <Chat />}
    </div>
  )
}
