import { AuthType } from 'app-engine/store/auth-slice'
import { GraphQLError } from 'graphql'
import isEmpty from 'lodash.isempty'
import util from 'util'

export const isAndroid = navigator.userAgent.match(/(Android)/i)
export const isIOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/i)
// @ts-ignore
export const isMac = (navigator?.userAgentData?.platform || navigator.platform).includes('Mac')
export const isApple = isIOS || isMac
export const isTokenPocket = navigator.userAgent.match(/(TokenPocket_Android|TokenPocket_iOS)/i)
export const isMobile = isAndroid || isIOS
// iOS is failing to do consecutive signs, so we have to refresh. It is better than nothing... 🤷
export const afterPushTransactionReload = (authType: AuthType | undefined) =>
  isIOS && authType === AuthType.WEBAUTHN && window.location.reload()

// TODO: review this, not longer using transit
export const getWalletUA = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const wallets = [{ agent: 'tokenpocket', provider: 'tokenpocket' }]
  for (let i = 0; wallets.length > i; i++) {
    if (userAgent.includes(wallets[i]?.agent)) return wallets[i].provider
  }
  return undefined
}

export const getUserMatched = ({
  seller,
  buyer,
  type,
}: {
  seller: string
  buyer: string
  type: string
}): string => (type === 'sell' ? buyer : seller)

export const notEmpty = (variable?: any) => !isEmpty(variable)

export const hideBkExt = (str: string) =>
  str.split('.').pop() === 'bk' ? str.replace('.bk', '') : str

export const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const formatPublicKey = (key: string) => `${key.slice(0, 11)}...${key.slice(-4)}`

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

export const toArrayLiteral = (arr: Array<any>) =>
  JSON.stringify(arr).replace('[', '{').replace(']', '}')

export const parseJwt = (token: any) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const xLog = (object: any) =>
  console.log(util.inspect(object, { showHidden: false, depth: null, colors: true }))

export const validateGraphqlError = (errors: readonly GraphQLError[] | undefined) => {
  if (errors) throw new Error(errors[0].message)
}

// sleep time expects milliseconds
export async function sleep(time: number) {
  return new Promise((resolve) => setTimeout(resolve, time))
}
