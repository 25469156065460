import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { Select } from 'app-view/components/Select'
import { useWizard } from 'app-view/components/Wizard'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import { AmountOption, getAmountOptions } from 'app-view/lib/amounts'
import { SectionAmount } from 'pages/P2PView/Styled'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useTranslation } from 'react-i18next'

// TODO: getAmountOptions will receive the symbol_code
//

export type AmountProps = {
  onNext: () => void
  onChange: (amount: string) => void
  text: string
  disabled?: string
}

export const Amount = ({ onNext, onChange, text, disabled }: AmountProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }] = useP2POffer()
  const [, { prev }] = useWizard()
  const offerSymbol = offer.amount ? offer.amount.split(' ')[1] : 'BITUSD'
  // TODO: Do a stable_positions and a cripto_positions list
  const userPositionsHook = useUserPositions()
  const userPositionsList = userPositionsHook.user_positions_list

  const onChangeMethod = (opt: AmountOption) => onChange(opt.value)
  const isMultiStable =
    userPositionsList.filter((pos) => pos.symbol_code.includes('BIT')).length > 1

  return (
    <>
      <ModalSelectWrapper>
        <SectionAmount>
          <Select
            label={'Amount'}
            options={getAmountOptions(offerSymbol)}
            initialPosition={3}
            onChange={onChangeMethod}
            topFix={5.5}
            width={'100%'}
          />
        </SectionAmount>
        <ModalText style={{ marginBottom: 0 }} styleContainer={{ flexGrow: 0 }}>
          {disabled || text}
        </ModalText>
      </ModalSelectWrapper>

      <ModalButtonsContainer>
        {isMultiStable || offer.type === 'buy' ? (
          <ModalStepBlackButton onClick={prev}>{t('Back')}</ModalStepBlackButton>
        ) : null}
        <ModalStepGreenButton onClick={() => !disabled && onNext()} disabled={Boolean(disabled)}>
          {t('Next')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}
