import * as React from 'react'
import { SVGProps } from 'react'

const ExchangeCoinsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={64}
    height={64}
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.479 23.686c0 8.904 5.819 15.914 12.767 15.914 6.948 0 12.768-7.01 12.768-15.914 0-8.903-5.82-15.913-12.768-15.913S13.48 14.783 13.48 23.686Z"
      fill="#fff"
      stroke="#29E0AD"
      strokeWidth={1.1}
    />
    <path
      d="M7.55 23.687c0 4.675 1.57 9.026 4.55 12.014 2.158 2.165 5.083 3.642 8.753 3.998-1.956-.905-3.666-2.377-5.038-4.213-2.344-3.14-3.731-7.373-3.731-11.8 0-7.223 3.39-13.612 8.585-16.043-3.715.358-6.605 1.73-8.71 3.804-2.921 2.878-4.41 7.182-4.41 12.24Z"
      fill="#fff"
      stroke="#29E0AD"
      strokeWidth={1.1}
    />
    <path d="m15.302 34.883 5.235 4.683h-1.406l-6.017-2.493-1.797-2.19h3.985Z" fill="#29E0AD" />
    <path stroke="#29E0AD" strokeWidth={0.667} d="M19.808 8.134h-2.49" />
    <path stroke="#29E0AD" strokeWidth={6} d="M12.486 21.93H7.51" />
    <path stroke="#29E0AD" strokeWidth={1.667} d="M13.077 28.488H7.81" />
    <path stroke="#29E0AD" strokeWidth={1.167} d="M15.416 34.446h-4.683" />
    <path stroke="#29E0AD" strokeWidth={0.833} d="M13.955 15.734H8.687M16.001 11.782h-4.537" />
    <path
      d="m18.916 7.794-3.177 3.971h-3.97s3.97-3.97 7.147-3.97ZM32.859 26.582a3.994 3.994 0 0 1-.41 1.926A3.965 3.965 0 0 1 31.173 30a6.31 6.31 0 0 1-2.391 1.034 2.091 2.091 0 0 1-.637 1.557 2.064 2.064 0 0 1-2.865 0 2.091 2.091 0 0 1-.637-1.622A8.7 8.7 0 0 1 21 29.144l1.821-2.55a5.824 5.824 0 0 0 3.916 1.491 3.68 3.68 0 0 0 1.578-.28.902.902 0 0 0 .595-.815.728.728 0 0 0-.162-.481 1.286 1.286 0 0 0-.554-.375 4.573 4.573 0 0 0-.732-.26c-.223-.066-.555-.151-.983-.249l-.725-.2c-.182-.04-.428-.11-.752-.2a7.15 7.15 0 0 1-.777-.26c-.19-.077-.433-.191-.704-.322a2.984 2.984 0 0 1-.68-.427 5.494 5.494 0 0 1-.55-.513 2.497 2.497 0 0 1-.457-.664 4.5 4.5 0 0 1-.271-.815 4.286 4.286 0 0 1-.11-.99c-.02-.88.256-1.74.782-2.444a4.493 4.493 0 0 1 2.043-1.499c.123-.05.25-.088.38-.114a2.093 2.093 0 0 1 .612-1.572 2.065 2.065 0 0 1 2.868-.048c.392.367.628.871.659 1.409a8.928 8.928 0 0 1 3.807 1.661l-1.84 2.546a6.091 6.091 0 0 0-3.484-1.222 3.302 3.302 0 0 0-1.364.24.81.81 0 0 0-.538.75.7.7 0 0 0 .134.407c.084.12.19.222.311.302.171.103.355.183.546.24.212.077.428.141.648.191.19.053.46.11.809.196l.837.18c.215.052.498.125.842.227.297.083.588.188.87.314l.773.407c.25.137.479.313.675.522.195.211.373.439.53.68.18.268.305.568.372.884.09.357.135.723.134 1.091Z"
      fill="#29E0AD"
    />
    <path
      d="M31.811 40.354c0 8.904 5.82 15.914 12.768 15.914s12.767-7.01 12.767-15.914-5.82-15.914-12.767-15.914c-6.949 0-12.768 7.01-12.768 15.914Z"
      fill="#fff"
      stroke="#29E0AD"
      strokeWidth={1.1}
    />
    <path
      d="M25.882 40.354c0 4.675 1.57 9.026 4.55 12.014 2.159 2.165 5.083 3.642 8.754 3.999-1.957-.906-3.666-2.377-5.038-4.214-2.345-3.139-3.732-7.372-3.732-11.799 0-7.224 3.39-13.612 8.585-16.044-3.715.358-6.605 1.731-8.71 3.804-2.92 2.878-4.409 7.183-4.409 12.24Z"
      fill="#fff"
      stroke="#29E0AD"
      strokeWidth={1.1}
    />
    <path d="m33.635 51.55 5.235 4.682h-1.407l-6.016-2.492-1.797-2.19h3.985Z" fill="#29E0AD" />
    <path stroke="#29E0AD" strokeWidth={0.667} d="M38.14 24.8h-2.49" />
    <path stroke="#29E0AD" strokeWidth={6} d="M30.818 38.598h-4.976" />
    <path stroke="#29E0AD" strokeWidth={1.667} d="M31.41 45.155h-5.268" />
    <path stroke="#29E0AD" strokeWidth={1.167} d="M33.748 51.112h-4.683" />
    <path stroke="#29E0AD" strokeWidth={0.833} d="M32.287 32.4H27.02M34.333 28.45h-4.537" />
    <path
      d="m37.248 24.46-3.177 3.972H30.1s3.97-3.971 7.148-3.971ZM46.272 35.294a2.434 2.434 0 0 0 1.026-.83c.249-.359.381-.773.383-1.196a2.102 2.102 0 0 0-.374-1.198c-.247-.36-.6-.65-1.02-.837a2.732 2.732 0 0 0-1.354-.222 2.654 2.654 0 0 0-1.28.46 2.29 2.29 0 0 0-.816 1.003c-.162.396-.199.826-.105 1.24l2.45-.454 1.09 2.034ZM43.732 47.341c-.07.328-.22.637-.44.907-.218.269-.5.49-.825.65a2.738 2.738 0 0 1-2.133.106 2.518 2.518 0 0 1-.898-.566 2.218 2.218 0 0 1-.546-.857 2.055 2.055 0 0 1-.088-.987c.052-.33.184-.647.386-.926l2.09 1.242 2.454.431Z"
      fill="#29E0AD"
    />
    <path
      d="m39.029 45.763-.245-.254c-.557-.612-.994-1.354-1.31-2.225-.316-.871-.474-1.844-.474-2.92 0-1.265.21-2.388.632-3.368.437-.98 1.047-1.776 1.83-2.389a6.185 6.185 0 0 1 2.702-1.245l.865-.257.926-.002c.361.014.602.034 1.038.075.437.04 1.088.191 1.062.184-.026-.008 1.383.306 1.383.306 0 .452.091.793.091.793l-.277-.773c.27.096.55.232.835.409.301.177.58.388.836.633l-1.152 2.409c-.512-.477-1.054-.803-1.626-.98a6.004 6.004 0 0 0-1.784-.266c-1.22 0-2.145.395-2.777 1.184-.617.776-.926 1.872-.926 3.288 0 1.51.331 2.64.994 3.389.677.735 1.58 1.102 2.71 1.102a6 6 0 0 0 1.783-.265c.572-.177 1.114-.504 1.626-.98l1.152 2.409c-.542.517-1.242.918-2.1 1.204a8.377 8.377 0 0 1-2.665.429c-.376 0-.73-.02-1.061-.061l.284.03-1.346-.214a7.153 7.153 0 0 1-1.106-.367 4.763 4.763 0 0 1-.994-.572l-.906-.706Z"
      fill="#29E0AD"
    />
  </svg>
)

export default ExchangeCoinsIcon
