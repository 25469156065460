import { Asset } from '@greymass/eosio'
import { createContextHook } from '@blockmatic/hooks-utils'
import { useEffect, useRef } from 'react'
import isEqual from 'lodash.isequal'
import { useStore } from 'app-engine/store'
import { timeout } from 'app-engine/library/utils'
import { useSetState } from 'react-use'
import { SmartContractError } from 'app-engine/library/errors'
import { WizardStep } from 'app-view/components/Wizard/types'
import { useWizard } from 'app-view/components/Wizard'
import { DEPOSIT_STEPS } from '../steps/deposit-steps/index'
import { SEND_STEPS } from '../steps/send-steps/index'
import { useTranslation } from 'react-i18next'
// TODO: Reemplace with @greymass/eosio
import { asset } from 'eos-common'
import React from 'react'
import { InputHandlerProps } from '../steps/send-steps/SendToAccount'
import { TransactResult } from 'anchor-link'
import { stringToAsset } from 'app-engine/library/eosio/utils'
import { TokenData } from 'app-engine/store/token-slice'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'
import { useRealTimeCoinPrices } from 'app-view/hooks/use-realtime/use-realtime-coin-prices'
import hash from 'object-hash'

interface UseWalletStateActionProps {
  type: 'deposit' | 'send' | ''
  amount: Asset
  sender: string
  receiver: string
  memo?: string
  target: string
}

type UseWalletState = {
  action: UseWalletStateActionProps
  loading: boolean
  error: string
  submitted: boolean
  with_memo: boolean
  isReady: boolean
  onramped: boolean
  transaction_data: TransactResult | null
}

const initialState: UseWalletState = {
  wallet_total: 0.0,
  onramped: false,
  action: {
    type: '',
    sender: '',
    receiver: '',
    // @ts-ignore TODO: fix types
    amount: asset('0.00 USDT'),
    target: '',
    memo: '',
  },
  transaction_data: null,
  with_memo: false,
  isReady: false,
  loading: false,
  error: '',
  submitted: false, // will be always false unless just initiated
}

type WalletActionResponse = Promise<{
  success: boolean
}>

const usePreviousCoinPricesHash = (coinPrices) => {
  const prevHashRef = useRef(hash(coinPrices))
  const currentHash = hash(coinPrices)
  const hasChangedSignificantly = currentHash !== prevHashRef.current

  useEffect(() => {
    if (hasChangedSignificantly) {
      prevHashRef.current = currentHash
    }
  }, [currentHash, hasChangedSignificantly]) // Only re-run if currentHash or hasChangedSignificantly changes

  return hasChangedSignificantly
}

export interface UseWalletActions {
  updateAction: (action_update: Partial<UseWalletStateActionProps>) => void
  updateAmountSymbol: (token: TokenData) => void
  setNewAction: (type: 'deposit' | 'send') => void
  submitAction: (data: any) => WalletActionResponse
  resetError: () => void
  startNewAction: (type: 'deposit' | 'send') => WizardStep[]
  setError: (error: string) => void
  setReady: (ready: boolean) => void
  setMemo: (with_memo: boolean) => void
  onChangeSendHandler: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputProps?: InputHandlerProps,
  ) => void
}

// TODO: Zustand State
export const useWalletFn = (): [UseWalletState, UseWalletActions] => {
  const coinPrices = useRealTimeCoinPrices()
  const { pushTransaction, account, fetchWalletTotal } = useStore()
  const { t } = useTranslation(['errors'])
  const [{ open }] = useWizard()
  const [state, set_state] = useSetState(initialState)
  const hasPricesChanged = usePreviousCoinPricesHash(coinPrices)

  useEffect(() => {
    if (open) {
      set_state({ error: '' })
    } else {
      set_state({
        ...initialState,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, set_state])

  const resetError = () => {
    set_state({
      error: '',
    })
  }

  const setMemo = (with_memo?: boolean) => set_state({ with_memo })

  const setReady = (ready: boolean) => set_state({ isReady: ready })

  const updateAction = (action_update: any) => {
    const updated_action = { ...state.action, ...action_update }

    if (!isEqual(state.action, updated_action)) set_state({ action: updated_action })
  }

  const updateAmountSymbol = (token: TokenData) => {
    const updated_action: any = {
      ...state.action,
      amount: stringToAsset(state.action.amount.value?.toString() ?? '0', token),
    }

    if (!isEqual(state.action, updated_action)) set_state({ action: updated_action })
  }

  const setNewAction = (type: any) =>
    set_state({ action: { ...initialState.action, type, sender: account } })

  const pushWalletTransaction = async (data: any) => {
    // TODO: important
    // @ts-ignore TODO: fix types
    const symbol = state.action.amount.symbol.code().toString()
    const token = findTokenBySymbolCode(symbol)

    try {
      const transaction = {
        actions: [
          {
            account: token?.token_contract,
            // TODO: if v2: token.token_contract === 'USDT' ? 'stbtransfer' : 'crptransfer',
            name: 'transfer',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data,
          },
        ],
      }

      console.log('pushP2PTransaction ==> transaction: ', transaction)

      const transaction_data = await pushTransaction(transaction)

      // @ts-ignore TODO: fix types
      set_state({ transaction_data: transaction_data ?? null })
    } catch (error) {
      if (error instanceof Error) {
        throw new SmartContractError(
          error.message.includes('quantity is less then min equal stable deposit')
            ? t('errors:minimum_quantity')
            : error.message,
        )
      }
    }
  }

  const submitAction = async (data: any) => {
    set_state({ loading: true })

    try {
      await pushWalletTransaction(data)

      await timeout(3000)

      set_state({ submitted: true })
      return { success: true }
    } catch (err) {
      set_state({ error: (err as Error).message })
      return { success: false }
    } finally {
      set_state({ loading: false })
    }
  }

  const startNewAction = (type: 'deposit' | 'send') => {
    const new_steps = type === 'deposit' ? DEPOSIT_STEPS : SEND_STEPS

    setNewAction(type)
    return new_steps
  }

  const setError = (error: string) => set_state({ error })

  const onChangeSendHandler = (
    { target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputProps: InputHandlerProps | undefined,
  ) => {
    const key: 'receiver' | 'amount' | 'memo' = name as 'receiver' | 'amount' | 'memo'
    if (name.match(/(receiver|memo|amount)/g)) {
      const isFormReady =
        Boolean(inputProps!.form.getValues('amount')) &&
        Boolean(inputProps!.form.getValues('receiver'))
      const formatted_value = name.match(/memo/g) ? value.toLowerCase() : value.trim().toLowerCase()
      updateAction({
        [key]: typeof value === 'string' ? formatted_value : value,
      })
      set_state({
        // NOTE: Ready if either receiver or amount have value
        isReady: inputProps ? isFormReady : true,
      })
      if (inputProps)
        inputProps.form.setValue(
          inputProps.field,
          typeof value === 'string' ? value.trim().toLowerCase() : value,
        )
      if (name === 'receiver' && formatted_value === 'eosbndeposit') {
        setMemo(true)
      }
    }
  }

  useEffect(() => {
    if (hasPricesChanged) {
      fetchWalletTotal(coinPrices)
      console.log('🚀 WalletView hasPricesChanged fetchWalletTotal', coinPrices)
    }
  }, [hasPricesChanged]) //? This useEffect depends on hasPricesChanged

  // TODO: is it necessary?
  useEffect(() => {
    if (state.submitted) fetchWalletTotal(coinPrices)
    console.log('🚀 WalletView state.submitted fetchWalletTotal', coinPrices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.submitted])

  return [
    state,
    {
      updateAction,
      updateAmountSymbol,
      startNewAction,
      resetError,
      setError,
      submitAction,
      setNewAction,
      setReady,
      setMemo,
      onChangeSendHandler,
    },
  ]
}

export const [useWallet, WalletProvider] = createContextHook(
  useWalletFn,
  'You must wrap your application with <WalletProvider /> in order to useWallet().',
)
