import { WizardStep } from 'app-view/components/Wizard'
import { BuyAddress } from './BuyAddress'
import { BuyComplete } from './BuyComplete'
import { BuyCompleteGrin } from './BuyCompleteGrin'
import { BuyGrin } from './BuyGrin'
import { BuySwapAmount } from 'pages/SwapView/steps/buy-steps/BuySwapAmount'

export const BUY_STEPS: WizardStep[] = [
  {
    name: 'swap-amount',
    component: BuySwapAmount,
  },
  {
    name: 'address',
    component: BuyAddress,
  },
  {
    name: 'complete',
    component: BuyComplete,
  },
]

export const BUY_STEPS_GRIN: WizardStep[] = [
  {
    name: 'swap-amount',
    component: BuySwapAmount,
  },
  {
    name: 'grin-from',
    component: BuyGrin,
  },
  {
    name: 'complete-grin',
    component: BuyCompleteGrin,
  },
]
