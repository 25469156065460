import React from 'react'

const DownloadIcon = (props: any) => (
  <svg viewBox="0 0 11 11" fill="none" {...props}>
    <path
      d="M4.21387 0.416992V4.27637H1.64095L5.50033 8.13574L9.3597 4.27637H6.78678V0.416992H4.21387ZM0.354492 9.4222V10.7087H10.6462V9.4222H0.354492Z"
      fill="#0075FF"
    />
  </svg>
)

export default DownloadIcon
