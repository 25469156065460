import React from 'react'
import { BlackButton, GreenButton } from 'app-view/components/buttons'
import { ModalButtonsContainer } from 'app-view/components/Modal'
import { useTranslation } from 'react-i18next'
import { PasteSlate } from 'pages/SwapView/steps/common/PasteSlate'
import { useStore } from 'app-engine/store/index'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ModalText } from 'app-view/components/Modal'
import { SwapOrder, getOrderWithFee } from 'app-engine/store/swap-slice'
import { get_amount } from 'app-view/lib/utils'

export const SlatepackForm = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['swap'])
  const { setSwapAddress, submitSwap } = useStore()
  const {
    coinSwap: { swap },
  } = useStore()

  const confirmSell = async () => {
    const { success } = await submitSwap()
    success && next()
  }

  const { asset, bitcash_currency, fee_percentage, bitcash_amount } = swap
  const { total_crypto } = getOrderWithFee(swap as SwapOrder)
  const totalToReceive = get_amount(`${bitcash_amount} ${bitcash_currency}`)
  const totalToSend = fixedAmountDecimals(total_crypto, asset)

  return (
    <>
      <ModalText>
        {"You're selling "}
        <span>{totalToSend}</span>
        {`
        with a ${fee_percentage}% fee for `}
        <span>{totalToReceive}</span>
        {'.\n\n'}
        {t('sell.slatepackForm.text2')}
      </ModalText>
      <br />
      <PasteSlate
        showEye
        onPaste={setSwapAddress}
        placeholder={t('sell.slatepackForm.placeholder')}
      />

      <ModalButtonsContainer>
        <BlackButton onClick={prev}>{t('back')}</BlackButton>
        <GreenButton disabled={swap.wallet_address === ''} onClick={confirmSell}>
          {t('next')}
        </GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
