export default {
  fullscreen: 'Pantalla completa',
  language: 'Idioma',
  deviceKeys: 'Dispositivos y Claves',
  telegram: 'Soporte en Discord',
  support: 'Soporte en Discord',
  about: 'Acerca de bitcash',
  api_status: 'Estado de conexión',
  logout: 'Cerrar sesión',
  referralLinkLabel: 'Referir',
  referralLinkSubLabel: 'Comparte este enlace para ganar tokens de bitcash',
  copiedLinkBtnLabel: 'Copiado ✓',
  copyLinkBtnLabel: 'Copiar Link',
  devices: 'Dispositivos',
  no_devices: 'No tienes ningún dispositivo',
  delete_device: '¿Quieres eliminar este dispositivo?',
  keys: 'Claves',
  no_keys: 'No tienes ninguna clave',
  delete_key: '¿Quieres eliminar esta clave?',
  delete: 'Borrar',
  loading_devices_keys: 'Cargando dispositivos y llaves',
  enter_public_key: 'Por favor ingrese una llave pública',
  enter_valid_public_key: 'Por favor ingrese una llave pública válida',
  public_key_modal_title:
    'Introduzca una clave pública para añadirla como método de firma válido a su cuenta',
  public_key: 'Llave pública',
  add_key: 'Agregar llave',
  profile: 'Opciones de Perfil',
  key_recovery: 'Recuperación de Claves',
  add_partners: 'Agregar Socios',
  update_partner: 'Actualizar Socio',
  generating_recovery_keys: 'Generando ID de Recuperación',
  id_type: 'Tipo de ID',
  country: 'País',
  not_found: 'Cuenta de bitcash no encontrada',
  partners_recommended: 'Se recomiendan al menos 3 socios',
  dao_recovery: 'Recuperación DAO',
  info_primary:
    'Los socios de recuperación son personas en las que confías para identificarte y verificarte en caso de que pierdas tu teléfono o dispositivo y necesites recuperar tu cuenta.',
  info_secondary:
    'Por favor ingrese la cuenta de bitcash de cada uno de sus socios de recuperación.',
  info_tertiary:
    'La recuperación DAO permite que la organización de bitcash actúe como uno de sus socios de recuperación.',
  warning: 'Advertencia',
  warning_secondary:
    'Si desea eliminar/agregar algún socio, debe pasar por el proceso de recuperación.',
  recovery_partner: 'Socios de recuperación',
  agree: 'Estoy de acuerdo en agregar estos socios para mi proceso de recuperación de claves',
  Submit: 'enviar',
  date_of_birth: 'Fecha de nacimiento',
  register_ip: 'IP de registro',
}
