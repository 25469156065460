import { CurrencyArray } from 'app-view/hooks/use-realtime/bitcash-room.schema'
import { parseCountriesCurrencyFromColyseus } from 'app-view/hooks/use-realtime/realtime.utils'
import { MapSchema } from '@colyseus/schema'

export const getCountriesByCurrencyCode = async (
  currency: string,
  rawCountriesData: MapSchema<CurrencyArray, string>,
) => {
  if (!currency || !rawCountriesData) return []
  const countriesData = parseCountriesCurrencyFromColyseus(rawCountriesData)
  if (!countriesData) return []
  const countries = countriesData[currency] || []
  return countries
}

export const getCurrencyData = async (
  stablecoin: string,
  rawCountriesData: MapSchema<CurrencyArray, string>,
) => {
  const countriesData = parseCountriesCurrencyFromColyseus(rawCountriesData)
  if (!stablecoin || !countriesData || stablecoin.trim() === '') return null

  const formattedCurrency = stablecoin.replace('BIT', '')
  const offerSymbol = stablecoin.replace('YEN', 'JP')
  const countries = countriesData[formattedCurrency]

  if (!countries) return null
  const country =
    countries?.filter((country) => {
      return (
        offerSymbol.includes(country.cca2) ||
        (offerSymbol.includes('EUR') && country.region === 'europe')
      )
    })[0] || countries[0]
  return country
}
