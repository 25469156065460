import React, { CSSProperties } from 'react'
import { ModalContainerProps } from './types'
import { StyledModalButtonsContainer } from './Styled'

interface CustomModalContainerProps extends ModalContainerProps {
  style?: CSSProperties
}

export const ModalButtonsContainer: React.FC<CustomModalContainerProps> = ({
  children,
  cancel,
  noPadding,
  ...props
}) => (
  <StyledModalButtonsContainer cancel={cancel} noPadding={noPadding} {...props}>
    {children}
  </StyledModalButtonsContainer>
)
