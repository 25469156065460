import React from 'react'
import styled from 'app-view/styles/styled'
import { useWizard } from 'app-view/components/Wizard'
import { KeyArrowIcon } from 'app-view/components/icons'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  @media (min-width: 500px) {
    padding-bottom: 115px;
  }
  svg {
    display: flex;
    margin: 23px auto 0 16px;
    cursor: pointer;
  }
`

const TextWrapper = styled.div`
  margin-top: 12px;
  padding: 0 45px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 28px;
  letter-spacing: -0.33px;
  & p {
    margin: 0;
  }
  & strong {
    display: block;
    margin-bottom: 25px;
  }
`

export const Info = () => {
  const { t } = useTranslation('settings')
  const [{}, { goTo }] = useWizard()

  return (
    <Container>
      <KeyArrowIcon onClick={() => goTo('partner')} />

      <TextWrapper>
        <p>{t('info_primary')}</p>
        <strong>{t('info_secondary')} </strong>
        <p>{t('info_tertiary')}</p>
      </TextWrapper>
    </Container>
  )
}
