import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ModalButtonsContainer,
  ModalText,
  ModalStepBlackButton,
  ModalStepGreenButton,
} from 'app-view/components/Modal'

export type ConfirmProps = {
  onPrev: () => void
  onConfirm: () => void
  text: string
  styleContainer?: React.CSSProperties
  cancelText?: string
  confirmText?: string
}

export const Confirm = ({
  onPrev,
  onConfirm,
  text,
  styleContainer,
  cancelText,
  confirmText,
}: ConfirmProps) => {
  const { t } = useTranslation(['p2p'])
  return (
    <>
      <ModalText
        styleContainer={styleContainer}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <ModalButtonsContainer>
        <ModalStepBlackButton onClick={onPrev}>{cancelText || t('Back')}</ModalStepBlackButton>
        <ModalStepGreenButton onClick={onConfirm}>
          {confirmText || t('Confirm')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}
