import { StoreSlice } from 'app-engine/store'
import { NotificationsSlice } from 'app-engine/types/notifications'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { Subscription } from 'zen-observable-ts'
import { groupBy } from 'lodash'
import isEqual from 'lodash.isequal'
import {
  createSubscription,
  getNotificationContentData,
  processNotifications,
} from 'app-engine/library/notification-helper'

let notificationsSubscriptions: Subscription[] = []

const unsubscribeToNotificationsData = () => {
  notificationsSubscriptions.forEach((notification) => notification.unsubscribe())
  notificationsSubscriptions = []
}

const createNotificationsObservable = (account) => {
  return createSubscription(apolloClient, Bitcash.BitcashRegisterNotificationsDocument, {
    where: { to: { _eq: account } },
    order_by: [{ read: Bitcash.Order_By.Asc }],
  })
}

const defaultNotificationsState = {
  notify: false,
  notifications: [],
  stakedNotification: [],
  last_unread: 0,
}

export const createNotificationsSlice: StoreSlice<NotificationsSlice> = (set, get) => ({
  ...defaultNotificationsState,
  reset: () => {
    set(defaultNotificationsState)
  },
  unreadNotifications: () =>
    get().notifications.filter((n) => !n.read && n.to === get().account) || [],
  setLastUnread: () => set({ last_unread: get().unreadNotifications().length }),
  getNotifications: (): Subscription => {
    unsubscribeToNotificationsData()
    const observable = createNotificationsObservable(get().account)
    const subscription = observable.subscribe({
      next: async ({ data, errors }) => {
        if (errors) throw new Error(errors[0].message)
        if (data && !isEqual(data.notifications, get().notifications)) {
          const processedNotifications = await processNotifications(data.notifications, get)
          const stacked = Object.values(groupBy(processedNotifications, 'content_id'))
          set({ notifications: processedNotifications, notify: true, stakedNotification: stacked })
        }
      },
      error: (err) => {
        console.error('Subscription error:', err)
      },
    })
    notificationsSubscriptions.push(subscription)
    return subscription
  },
  setNotify: () => set({ notify: false }),
  getNotificationContentSwap: async (content_id_array) => {
    return getNotificationContentData(
      apolloClient,
      Bitcash.BitcashSwapNotificationsDataDocument,
      content_id_array,
    )
  },
  getNotificationContentP2p: async (content_id_array) => {
    return getNotificationContentData(
      apolloClient,
      Bitcash.BitcashP2POffersNotificationsDataDocument,
      content_id_array,
    )
  },
})
