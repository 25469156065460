// header.ts
export default {
  settings: 'Cài đặt',
  device_keys: 'Thiết bị &\nKhóa',
  trade: 'Giao dịch',
  wallet: 'Ví',
  history: 'Lịch sử',
  p2p: 'P2P',
  swap: 'Hoán đổi',
  bank: 'Ngân hàng',
  deposit: 'Nạp tiền',
  send: 'Gửi',
  withdrawal: 'Rút tiền',
  faucet: 'Vòi nước',
  account: 'Đăng nhập',
  create_account: 'Tạo',
  create_account_webauthn_cred: 'Tạo',
  register_account: 'Đăng ký',
  register_account_success: 'Đã đăng ký',
  about: 'Giới thiệu',
  add_device_account: 'Thêm thiết bị',
  add_device_webauthn_cred: 'Thêm thiết bị',
  add_device_success: 'Đã thêm thiết bị',
  notifications: 'Thông báo',
  create_account_success: 'Tài khoản đã được tạo',
  create_account_kyc: 'Thông tin tài khoản',
  register_account_kyc: 'Thông tin tài khoản',
}
