import React, { useEffect, useMemo, useState } from 'react'
import { CoinSelector } from 'app-view/components/CoinSelector'
import { InputSection, InputWrapper, CenteredLabel } from 'app-view/components/InputField'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { useWallet } from 'pages/WalletView/hooks/useWallet'
import { CurrencySelectorWrapper } from 'pages/WalletView/steps/common/Styled'
import { useStore } from 'app-engine/store'
import { useRealTimePairs } from 'app-view/hooks/use-realtime/use-realtime-pairs'
import { IPair } from 'app-view/hooks/use-realtime/pairs.schema'
import { useProfile } from 'app-view/hooks/use-profile.hook'

const getCoinOptions = (pairs: IPair, baseCrypto: string) => {
  if (!pairs) return []
  const tokenData =
    Object.values(pairs)
      .filter((pair) => pair.sysCryptoToken === baseCrypto)
      .map((value) => value.sysStableToken) || []
  const opts = tokenData.filter((tkn) => tkn.match(/BIT/)) || []
  const coinOptions = Array.from(new Set(opts.sort((coin) => (coin.match(/BITUSD/) ? -1 : 0))))
  return coinOptions
}

interface ReceiveCurrencyProps {
  label: string
}

export const WalletCurrencyReceive: React.FC<ReceiveCurrencyProps> = ({ label }) => {
  const [localCurrency, setLocalCurrency] = useState(-1)
  const [userInteract, setUserInteract] = useState(false)
  const [{ action }, walletActions] = useWallet()
  const [{ preferences }, {}] = useProfile()
  const pairs = useRealTimePairs()

  // @ts-ignore TODO: fix types
  const base_crypto = action.amount.symbol.code().toString()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const coinOpt = useMemo(() => getCoinOptions(pairs, base_crypto), [base_crypto, pairs])

  const receiveCurrencyUpdated = (newCurrency: string) => {
    walletActions.updateAction({ target: newCurrency })
  }

  const coinSelectorAttr = {
    setCoin: receiveCurrencyUpdated,
    coinOpt,
    indicatorProps: {
      screen: 'wallet' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract,
    setUserInteract,
  }

  useEffect(() => {
    const getLocalCurrency = async () => {
      let currency = 'BITUSD'

      if (preferences.currency !== 'BITUSD') {
        currency = preferences.currency
      } else {
        const response = await fetch('https://ipapi.co/json/').then((response) => response.json())
        currency = `BIT${response?.data?.currency}`
      }

      if (!coinOpt.includes(currency)) currency = 'BITUSD'

      walletActions.updateAction({ target: currency })
      setLocalCurrency(coinOpt.indexOf(currency))
      receiveCurrencyUpdated(currency)
    }
    getLocalCurrency()
    // eslint-disable-next-line
  }, [])

  return (
    <InputWrapper>
      <CenteredLabel size="md">{label}</CenteredLabel>
      <InputSection error={false} style={{ width: '50%', marginInline: 'auto' }}>
        <CurrencySelectorWrapper>
          {localCurrency > -1 && (
            <CoinSelector {...coinSelectorAttr} initialIndex={localCurrency} />
          )}
        </CurrencySelectorWrapper>
      </InputSection>
    </InputWrapper>
  )
}
