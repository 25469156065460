import React from 'react'
import { GreenButton } from 'app-view/components/buttons'
import { useTranslation } from 'react-i18next'
import styled from 'app-view/styles/styled'
import { useStore } from 'app-engine/store/index'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ModalText, ModalButtonsContainer } from 'app-view/components/Modal'

const Paragraph = styled.div`
  margin-top: 20px;
  background-color: #f7f8f9;
  border-radius: 10px;
  padding: 25px 5px;
  color: #f34040;
`

export const SellGrinComplete = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['swap'])
  const {
    coinSwap: { swap },
  } = useStore()

  return (
    <>
      <ModalText>
        {`${t('sell.complete.text2')}`}
        <span>${swap.total_to_receive}</span>
        {`
        ${t('sell.complete.text3')}
        
        `}
        <Paragraph>{t('sell.complete.slatepackMessage')}</Paragraph>
      </ModalText>
      <ModalButtonsContainer>
        <GreenButton onClick={close}>{t('ok')}</GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
