import React, { useEffect } from 'react'
import { ModalSuccess } from 'app-view/components/Modal'
import { useWizard } from 'app-view/components/Wizard'

export const Success = () => {
  const [{}, { close }] = useWizard()

  useEffect(() => {
    const autoClose = setTimeout(() => {
      close()
    }, 3000)
    return () => {
      clearTimeout(autoClose)
    }
  }, [close])

  return <ModalSuccess text="Offer created successfully" />
}
