import styled from 'app-view/styles/styled'

export const WalletStepMainContentWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  > div > div {
    border-color: #f2f2f2;
  }
`

export const WalletStepConfirmWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  > label {
    max-width: 320px;
  }
`

export const CoinAmountWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  > div > div {
    border-color: #f2f2f2;
  }
`

export const CurrencySelectorWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 16px;
  height: 100%;

  * {
    transition: all 0.24s cubic-cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  > div {
    position: relative;
    min-width: 100%;

    span.indicator {
      position: absolute;
      top: 1rem;
      left: 2.25rem;
      height: 64px;
      width: 32px;
      z-index: 10;

      > svg {
        height: 100%;
      }
    }
  }
`
