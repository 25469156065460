import * as React from 'react'
import { SVGProps } from 'react'
const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 11 11"
    {...props}
  >
    <path
      fill="#F34040"
      d="M.847.322a.74.74 0 0 1 1.046 0L5.81 4.24 9.727.322a.74.74 0 1 1 1.047 1.047L6.857 5.286l3.917 3.917a.74.74 0 0 1-1.047 1.046L5.81 6.332 1.893 10.25A.74.74 0 0 1 .847 9.204l3.917-3.917L.847 1.369a.74.74 0 0 1 0-1.047Z"
    />
  </svg>
)
export default DeleteIcon
