import { motion } from 'framer-motion/dist/framer-motion'
import styled from 'app-view/styles/styled'

export const SimpleTransparentButton = styled(motion.button)`
  background: transparent;
  border: none;
  cursor: pointer;
`

export const ButtonTransparent = styled(motion.button)`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`
