import { Asset } from 'anchor-link'
import { useStore } from 'app-engine/store'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { TokenPrice, useTokenPrices } from 'app-view/hooks/use-token-prices'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import styled from 'app-view/styles/styled'
import Decimal from 'decimal.js'
import { asset } from 'eos-common'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getCurrencyLabel } from '../lib/amounts'
import { Label } from './Styled'
import { useProfile } from 'app-view/hooks/use-profile.hook'

const CurrencyBase = styled.div`
  font-size: 2rem;
  color: #1b1b1b;
  letter-spacing: -0.27px;
  margin-top: 3px;
  line-height: 1;
  width: 100%;

  > span {
    height: 28px;
    margin: 0;
  }
`

const Currency = ({ children }: { children: React.ReactNode }) => (
  <CurrencyBase>{children}</CurrencyBase>
)

const Card = styled.div`
  min-height: 120px;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin: 0.75rem 0;
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
`

export interface BalanceBoxProps {
  total?: boolean
  stable?: boolean
  coin?: string
}

export interface WalletBalanceBoxProps {
  amount: number
}

export const BalanceBox: React.FC<BalanceBoxProps> = ({ total, stable, coin = 'BITUSD' }) => {
  const { t } = useTranslation(['global'])
  const tokenPricesHook = useTokenPrices()
  const userPositionsHook = useUserPositions()
  const userPositionsReady = userPositionsHook.userPositionsReady
  const findUserPosition = userPositionsHook.findUserPosition
  const tokenPrices = bitcashRoom.useColyseusState((state) => state.tokenPrices)
  const tokenPriceReady = tokenPrices && Object.keys(tokenPrices).length > 0

  const convertToAsset = tokenPricesHook.convertToAsset
  const findTokenPriceBySymbolCode = tokenPricesHook.findTokenPriceBySymbolCode
  const [{ preferences }, {}] = useProfile()
  const usdAmount = findUserPosition('TOTAL').usd_value
  const totalAsset =
    typeof usdAmount === 'number' ? asset(`${usdAmount} ${preferences.currency}`) : usdAmount
  let totalAmount = parseFloat(
    convertToAsset(totalAsset, preferences.currency).toString().split(' ')[0],
  )
  if (totalAmount >= 100000) {
    totalAmount = Math.floor(totalAmount)
  }
  const stableUsdAmount = findUserPosition('STABLE').usd_value
  const stableAsset =
    typeof stableUsdAmount === 'number'
      ? asset(`${stableUsdAmount} ${preferences.currency}`)
      : stableUsdAmount
  let stableAmount = parseFloat(
    convertToAsset(stableAsset, preferences.currency).toString().split(' ')[0],
  )
  if (stableAmount >= 100000) {
    stableAmount = Math.floor(stableAmount)
  }

  if (total) {
    return (
      <Card>
        <Label>{t('total')}</Label>
        {tokenPriceReady && userPositionsReady ? (
          <Currency>{getCurrencyLabel(preferences.currency, totalAmount)}</Currency>
        ) : (
          <div className="dots-bars-2" />
        )}
      </Card>
    )
  }

  if (stable) {
    return (
      <Card>
        <Label>{t('total_stable')}</Label>
        {tokenPriceReady && userPositionsReady ? (
          <Currency>{getCurrencyLabel(preferences.currency, stableAmount)}</Currency>
        ) : (
          <div className="dots-bars-2" />
        )}
      </Card>
    )
  }

  const preferredCurrencyTokenPrice = findTokenPriceBySymbolCode(preferences.currency)
  const coinBalance = findUserPosition(coin).balance
  const availableBalance = Asset.from(
    `${
      ((preferredCurrencyTokenPrice as TokenPrice).usd_price.amount.toJSNumber() *
        coinBalance.amount.toJSNumber()) /
      10000
    } ${preferredCurrencyTokenPrice?.symbol_code}`,
  )
  const availableBalanceValue =
    availableBalance.value >= 100000 ? Math.floor(availableBalance.value) : availableBalance.value

  return (
    <Card>
      <Label>{t('available')}</Label>
      {tokenPriceReady ? (
        <Currency>
          {getCurrencyLabel(availableBalance.symbol.code.toString(), availableBalanceValue)}
        </Currency>
      ) : (
        <div className="dots-bars-2" />
      )}
    </Card>
  )
}

export const WalletBalanceBox: React.FC<WalletBalanceBoxProps> = ({ amount }) => {
  const { t } = useTranslation(['global'])
  const tokenPricesHook = useTokenPrices()
  const tokenPrices = bitcashRoom.useColyseusState((state) => state.tokenPrices)
  const tokenPriceReady = Object.keys(tokenPrices || {}).length > 0
  const findTokenPriceBySymbolCode = tokenPricesHook.findTokenPriceBySymbolCode
  const userPositionsHook = useUserPositions()
  const userPositionsReady = userPositionsHook.userPositionsReady
  const [{ preferences }, {}] = useProfile()
  const preferredCurrencyTokenPrice = findTokenPriceBySymbolCode(preferences.currency)
  const usdPrice = new Decimal(
    (preferredCurrencyTokenPrice as TokenPrice).usd_price.toString().split(' ')[0],
  )
  const balanceAssetAmount = usdPrice.mul(amount)
  const amountAsset = Asset.from(
    `${balanceAssetAmount.toNumber()} ${preferredCurrencyTokenPrice?.symbol_code}`,
  )

  let currency = '-'

  try {
    const currencyAmountValue =
      amountAsset.value >= 100000 ? Math.floor(amountAsset.value) : amountAsset.value
    currency = getCurrencyLabel(amountAsset.symbol.code.toString(), currencyAmountValue)
  } catch (error) {
    const usdPrice = new Decimal(
      (preferredCurrencyTokenPrice as TokenPrice).usd_price.amount.toString(),
    )
    const balanceAssetAmount = usdPrice.mul(amount)
    console.log({ balanceAssetAmount, amount, usdPrice })
    console.error(error)
  }

  return (
    <Card>
      <Label>{t('total')}</Label>
      {tokenPriceReady && userPositionsReady && preferences ? (
        <Currency>{currency}</Currency>
      ) : (
        <div className="dots-bars-2" />
      )}
    </Card>
  )
}
