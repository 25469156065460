import { useEffect, useRef } from 'react'

export const useRenderCount = (scope = '') => {
  const count = useRef(0)
  useEffect(() => {
    count.current++
    // console.log(`Rendered ${count.current} times at:`, scope)
  })
  return count.current
}
