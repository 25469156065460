import { WizardStep } from 'app-view/components/Wizard'
import { RecoveryProfile } from 'pages/DeviceKeysView/steps/recovery-steps/RecoveryProfile'
import { RecoveryIdType } from './RecoveryIdType'
import { RecoveryPartner } from './RecoveryPartner'
import { RecoveryInfo } from './RecoveryInfo'
import { RecoverySubmit } from './RecoverySubmit'
import { RecoveryGenerating } from './RecoveryGenerating'

export const RECOVERY_STEPS: WizardStep[] = [
  {
    name: 'profile',
    component: RecoveryProfile,
  },
  {
    name: 'id-type',
    component: RecoveryIdType,
  },
  {
    name: 'partner',
    component: RecoveryPartner,
  },
  {
    name: 'submit',
    component: RecoverySubmit,
  },
  {
    name: 'generating',
    component: RecoveryGenerating,
  },
  {
    name: 'info',
    component: RecoveryInfo,
  },
]
