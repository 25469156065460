// Examples

// This file is used for non-logged in users
import { CountryCode } from 'libphonenumber-js'

// https://www.iban.com/country-codes

// So ngn => Nigeria, ugx => Uganda, KES => Kenia, IDR => Indonesia, MYR => Malasia

// Validate IP Address here https://ipapi.co/

// https://ipapi.co/150.107.126.1/json/
// country_code: "NG"
// country_name: "Nigeria"

// https://ipapi.co/101.128.64.0/json/
// country_code: "ID"
// country_name: "Indonesia"

// https://ipapi.co/1.32.0.0/json/
// country_code: "MY"
// country_name: "Malaysia"

// ID - Grab, Gojeck, Bank Transfer
// MY - Grab, Bank Transfer
// VN - Grab, Bank Transfer
// PH - Grab, Bank Transfer
// TH - Grab, Bank Transfer
// SG - Grab, Bank Transfer

export const getCountryByIp = async (): Promise<IpAPIResponse> =>
  await new Promise(async (resolve, reject) => {
    try {
      const response = await fetch('https://ipapi.co/json', {
        cache: 'no-store',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }).then((res) => res.json())

      return resolve({ data: response, error: null }) as unknown as IpAPIDataResponse
    } catch (error) {
      return reject({ data: null, error }) as unknown as Error
    }
  })

export type IpAPIResponse = {
  data: IpAPIDataResponse | null
  error: Error | null
}

export interface IpAPIDataResponse {
  ip: string
  network: string
  version: string
  city: string
  region: string
  region_code: string
  country: string
  country_name: string
  country_code: CountryCode
  country_code_iso3: string
  country_capital: string
  country_tld: string
  continent_code: string
  in_eu: false
  postal: string
  latitude: number
  longitude: number
  timezone: string
  utc_offset: string
  country_calling_code: string
  currency: string
  currency_name: string
  languages: string
  country_area: number
  country_population: number
  asn: string
  org: string
}
