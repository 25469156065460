import { useSocket } from 'SocketProvider'
import { useStore } from 'app-engine/store'
import { formatTimestamp } from 'app-view/lib/utils'

// TODO: add internalization - online and dates should be translated
export const useOnlineStatus = (account: string) => {
  const loggedAccount = useStore.useAccount()
  const { previousSessions, onlineUsers } = useSocket()

  if (account === loggedAccount) {
    return {
      onlineStatus: 'online',
      isOnline: true,
      showOnlineStatus: false,
    }
  }

  const previousSession = previousSessions[account]
  const isOnline = Boolean(onlineUsers[account])
  const previousTimestamp = previousSession ? formatTimestamp(previousSession) : ''
  const lastSeen = previousTimestamp ? `last seen ${previousTimestamp}` : ''
  const onlineStatus = isOnline ? 'online' : lastSeen

  return {
    onlineStatus,
    isOnline,
    showOnlineStatus: true,
  }
}
