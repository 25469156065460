import { ModalSuccess } from 'app-view/components/Modal'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useSigningRequest } from 'hooks/use-signing-request'
import { useEffect, useState } from 'react'

const INITIAL_COUNTDOWN_SECONDS = 5

export function SignSuccess({ close }: WizardStepProps) {
  const [{ trxId, callbackUrl, callbackStatus }] = useSigningRequest()
  const [seconds, setSeconds] = useState(INITIAL_COUNTDOWN_SECONDS)

  const redirectUser = async () => {
    close()
    if (callbackStatus !== 'ok' || !callbackUrl) return

    window.location.href = callbackUrl
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds <= 0) return redirectUser()
      setSeconds((prevState) => prevState - 1)
    }, 1000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds])

  return (
    <ModalSuccess
      text={`Closing in ${seconds} second${seconds > 1 ? 's' : ''}.
      
      Signed successfully!`}
      redirect={`https://bloks.io/transaction/${trxId}`}
    />
  )
}
