import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'
import styled from 'app-view/styles/styled'
import { BaseButton } from './BaseButton'

const StyledBigBtn = styled(BaseButton)`
  height: 60px;
  min-width: 100%;
  line-height: 1.5em;
`

export const BigButton: React.FC<any> = (props) => {
  return (
    <motion.span
      whileTap={{
        scaleX: 0.95,
        scaleY: 0.85,
      }}
    >
      <StyledBigBtn {...props} />
    </motion.span>
  )
}
