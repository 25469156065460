import React from 'react'

const KeyCheckedIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="1" y="1" width="18" height="18" rx="1" fill="#3FE4B6" />
    <path
      d="M7.88348 11.9298L13.9066 5.90663L14.8995 6.89945L7.94967 13.8492L4.47477 10.3743L5.40141 9.44771L7.88348 11.9298Z"
      fill="#0F121F"
    />
    <rect x="1" y="1" width="18" height="18" rx="1" stroke="#FEFEFE" strokeWidth="2" />
  </svg>
)

export default KeyCheckedIcon
