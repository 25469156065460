import { Asset } from '@greymass/eosio'
import { StoreSlice } from 'app-engine/store'

export type ExchangeHistoryData = {
  transactionId: string
  timestamp: number
  quantityFee: Asset
  quantityAmountIn: Asset
  quantityAmountOut: Asset
  quantityUnitPrice: Asset
}

export type HistorySlice = {
  historyTab: HistoryTabType
  setHistoryTab: (tab: HistoryTabType) => void
  reset: () => void
}

export type HistoryTabType = {
  label: string
  value: HistoryTabValueType
  content_id: string
}

export type HistoryTabValueType = 'bank' | 'p2p' | 'swap' | 'forex' | 'trade'

const defaultHistoryState = {
  historyTab: {
    label: 'history:bank',
    value: 'bank',
    content_id: '',
  } as HistoryTabType,
  exchangeHistory: [],
  isExchangeHistoryLoading: true,
  exchangeHistoryFilterOptions: [],
  isExchangeHistorySubscribed: false,
}

export const createHistorySlice: StoreSlice<HistorySlice> = (set, get) => ({
  ...defaultHistoryState,
  reset: () => {
    set(defaultHistoryState)
  },
  setHistoryTab: (tab: HistoryTabType) => set({ historyTab: tab }),
})
