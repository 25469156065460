import { WizardStepProps } from 'app-view/components/Wizard'
import React, { useCallback } from 'react'
import { useEffectOnce, useSetState } from 'react-use'
import { useTranslation } from 'react-i18next'
import { AiFillExclamationCircle } from 'react-icons/ai'
import { Form } from 'pages/AccountView/components/TextElements'
import { BankCoinAmount } from 'app-view/components/CoinAmount'
import { Loader } from 'app-view/components/Spinner'
import { ModalButtonsContainer, ModalStepGreenButton } from 'app-view/components/Modal'
import { WalletStepMainContentWrapper } from 'pages/WalletView/steps/common/Styled'
import { useBankWallet } from 'pages/BankView/hooks/useBankWallet'
import { WithdrawLimitWrapper } from 'pages/BankView/steps/common/Styled'
import theme from 'app-config/theme'
import { useStore } from 'app-engine/store'
import {
  ToolTipArrow,
  ToolTipComponent,
  ToolTipText,
  TooltipContainer,
} from 'app-view/components/Tooltip'
import { useTokenPrices } from 'app-view/hooks/use-token-prices'

const ONE_DAY = 24 * 60 * 60 * 1000

export const WithdrawalAmount = ({ next }: WizardStepProps) => {
  const { t, i18n } = useTranslation('wallet')
  const tokenPricesHook = useTokenPrices()
  const getUsdTokenValue = tokenPricesHook.getUsdTokenValue
  const { account } = useStore()
  const [{ action, loading, error }, { getUserLimits }] = useBankWallet()
  const [{ openTooltip, resetDate }, setState] = useSetState<{
    openTooltip: boolean
    resetDate: number
  }>({ openTooltip: false, resetDate: 0 })

  useEffectOnce(() => {
    const updateWithdrawLimit = async () => {
      const limit = await getUserLimits(account)
      if (limit !== '') setState({ resetDate: Date.parse(`${limit}Z`) + ONE_DAY })
    }
    updateWithdrawLimit()
  })

  const validateAmount = useCallback(() => {
    const target = getUsdTokenValue(action.amount)
    const amount = target.amount.toJSNumber() / 100
    return amount > 0 && amount <= action.limit
  }, [action.amount, action.limit, getUsdTokenValue])

  const dailyResetLabel = useCallback(
    () =>
      Date.now() >= resetDate
        ? 'No withdraws made today'
        : new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full', timeStyle: 'short' }).format(
            new Date(resetDate),
          ),
    [i18n.language, resetDate],
  )

  if (loading) return <Loader text={t('loading_action')} />

  return (
    <Form wallet={!Boolean(error)} onClick={() => setState({ openTooltip: false })}>
      <WalletStepMainContentWrapper>
        <BankCoinAmount label={t('amount_withdraw')} isBank />
        <WithdrawLimitWrapper>
          {/* // ! TODO: Use Tooltip component */}
          {openTooltip && (
            <TooltipContainer role="dialog" aria-details="withdraw limit details">
              <ToolTipArrow />
              <ToolTipComponent id="withdraw_limits_exp">
                <ToolTipText style={{ textAlign: 'center', fontWeight: 500, width: 240 }}>
                  {t('withdraw_helper')}
                  {t('withdraw_reset_label')}
                  <strong>{dailyResetLabel()}</strong>
                </ToolTipText>
              </ToolTipComponent>
            </TooltipContainer>
          )}
          <br />
          <p>
            <strong>
              {t('limit_alert')}
              <div style={{ position: 'relative' }}>
                <AiFillExclamationCircle
                  color={theme.colors.green}
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    setState({ openTooltip: true })
                    e.stopPropagation()
                  }}
                />{' '}
                :
              </div>
            </strong>
            <span>{action.limit} USDT</span>
          </p>
          <p>
            <strong>{t('withdraw_fee')}:</strong>
            <span>{action.fee} bitUSD</span>
          </p>
        </WithdrawLimitWrapper>
      </WalletStepMainContentWrapper>
      <ModalButtonsContainer>
        <ModalStepGreenButton type="button" onClick={next} disabled={!validateAmount()}>
          {t('next')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
