import React from 'react'
import styled from 'app-view/styles/styled'
import { ClipboardIcon } from 'app-view/components/icons'

const InputError = styled.div`
  padding-top: 10px;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 500;
  color: #f34040;
  text-align: center;
`

const InputMessage = styled.div`
  padding-top: 10px;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
`

const InputContainer = styled.div`
  position: relative;
`

const StyledClipboardIcon = styled(ClipboardIcon)`
  position: absolute;
  bottom: 16px;
  right: 19px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-color: #fff;
  padding-left: 4px;
`

export const StyledInput = styled.input`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 10px;
  font-family: Mulish, sans-serif;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.66;
  font-weight: 400;
  text-align: center;
  padding: 14px 25px 14px 1.5rem;
  outline: 1px 1px;
  outline-color: #cccccc;
  user-select: auto;
  color: #3f3f3f;

  &::placeholder {
    transition: all 0.14s ease-in-out;
    font-weight: 400;
    text-align: left;
    user-select: all;
    pointer-events: all;
    font-size: 1rem;
    color: #a4a4a4;
  }

  &:focus-visible {
    outline-color: #cccccc;
  }
`

// Extend html <input> props
interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  error?: string
  message?: string
  icon?: string
  onCopy?: () => void
}

export const AddressInput: React.FC<InputProps> = ({ error, message, onCopy, ...props }) => {
  return (
    <>
      <InputContainer>
        {onCopy && <StyledClipboardIcon onClick={onCopy} />}
        <StyledInput
          type="text"
          onClick={onCopy ? onCopy : props.onClick}
          name="copy-text-input"
          style={{ borderColor: error ? '#f34040 !important' : '#ccc' }}
          {...props}
        />
      </InputContainer>
      {error && <InputError>{error}</InputError>}
      {message && <InputMessage>{message}</InputMessage>}
    </>
  )
}
