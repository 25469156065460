import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalText,
  ModalStepGreenButton,
} from 'app-view/components/Modal'
import { SectionStable } from 'pages/P2PView/Styled'
import { CoinSelectorWrapper } from 'app-view/components/Styled'
import { CoinSelector, CoinSelectorProps } from 'app-view/components/CoinSelector'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { useStore } from 'app-engine/store'
import { useSetState } from 'react-use'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useProfile } from 'app-view/hooks/use-profile.hook'

export type AmountOption = {
  label: string
  value: number
}

export type AmountProps = {
  onNext: () => void
  text: string
  isBuy?: boolean
  disabled?: string
}

export const SelectStable = ({ onNext, text, isBuy, disabled }: AmountProps) => {
  const { t } = useTranslation(['p2p'])
  const userPositionsHook = useUserPositions()
  const user_positions_list = userPositionsHook.user_positions_list
  const [{ preferences }, {}] = useProfile()
  const stableCoinOptions = bitcashRoom.useColyseusState((state) => state.stableCoins)

  const [{ coin, userInteract }, setState] = useSetState({
    coin: preferences.currency || 'BITUSD',
    userInteract: false,
  })
  const [, { updateOffer }] = useP2POffer()

  const coinOpt = !isBuy
    ? stableCoinOptions.filter((opt) => user_positions_list.some((pos) => pos.symbol_code === opt))
    : stableCoinOptions
  const sendCoinSelectorAttr: CoinSelectorProps = {
    setCoin: (coin: any) => setState({ coin }),
    coinOpt,
    indicatorProps: {
      screen: 'p2p' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract,
    setUserInteract: (userInteract: boolean) => setState({ userInteract }),
    initialIndex: coinOpt.findIndex((opt) => opt === preferences.currency),
  }

  const goToNextStep = () => {
    if (disabled) return

    updateOffer({ amount: `0.00 ${coin}` })
    onNext()
  }

  return (
    <>
      <ModalSelectWrapper>
        <SectionStable>
          <CoinSelectorWrapper>
            <CoinSelector {...sendCoinSelectorAttr} />
          </CoinSelectorWrapper>
        </SectionStable>
        <ModalText style={{ marginBottom: 0 }} styleContainer={{ flexGrow: 0 }}>
          {disabled || text}
        </ModalText>
      </ModalSelectWrapper>

      <ModalButtonsContainer>
        <ModalStepGreenButton onClick={goToNextStep} disabled={Boolean(disabled)}>
          {t('Next')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}
