import { FlexBox } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'

export const CameraWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
  max-width: 325px;
`

export const CameraImageFrame = styled.img<CameraImageFrameStatusProps>`
  width: 100%;
  height: auto;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: ${(props: CameraImageFrameStatusProps) =>
    props.selfieStatus === null ? '#757575' : props.selfieStatus ? '#1ED761' : '#DA291C'};
`

export const CameraImageFrameContainer = styled.div<CameraImageFrameStatusProps>`
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  border-color: ${(props: CameraImageFrameStatusProps) =>
    props.selfieStatus === null ? '#757575' : props.selfieStatus ? '#1ED761' : '#DA291C'};
`

export const CameraVideoBorder = styled.div<CameraImageFrameStatusProps>`
  width: 100%;
  position: absolute;
  height: 100%;
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  top: 0;
  border-color: ${(props: CameraImageFrameStatusProps) =>
    props.selfieStatus === null ? '#757575' : props.selfieStatus ? '#1ED761' : '#DA291C'};
`
export const SelfieStatusHint = styled.div<CameraImageFrameStatusProps>`
  text-align: left;
  width: 100%;
  font-size: 13px;
  border-color: ${(props: CameraImageFrameStatusProps) =>
    props.selfieStatus === null ? '#757575' : props.selfieStatus ? '#1ED761' : '#DA291C'};
`

export const PhotoIdMask = styled.div`
  position: absolute;
  box-shadow: 0 0 0 100vmax rgba(255, 255, 255, 0.2);
  pointer-events: none;
  border-radius: 10px;
`
export const MediaContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const InstructionTitle = styled.div`
  width: 100%;
  margin-inline: auto;
  padding-inline: 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`

export const PlainButton = styled.button`
  padding: 0;
  border: none;
  outline: none;
  color: #0075ff;
  font-weight: 700;
  margin-left: 3px;
  cursor: pointer;
  background-color: transparent;
`

export const PermissionText = styled.span`
  font-weight: 700;
  padding: 24px 16px;
  color: #040316;
  background-color: #f7f8f9;
  border-radius: 10px;
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const HelpText = styled.span<HelpTextProps>`
  font-weight: 600;
  width: 100%;
  text-align: ${(props: HelpTextProps) => props.textAlign ?? 'left'};
  & > ol,
  & > ul {
    list-style: outside;
    margin-left: 20px;
  }
`
export const HelpGrantPermissionTexts = styled(FlexBox)`
  flex-direction: column;
  width: 100%;
  gap: 24px;
  & * {
    line-height: 26px;
  }
`

export const HelpLink = styled.a`
  color: #0075ff;
  text-decoration: none;
`

interface HelpTextProps {
  textAlign?: string | null
}

interface CameraImageFrameStatusProps {
  selfieStatus: boolean | null
}
