/* eslint-disable no-param-reassign */
/* eslint-disable no-trailing-spaces */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'app-config/theme'
import { ToolTipOptionsProps, Tooltip } from 'app-view/components/Tooltip'
import { P2PNotificationIcon, SettingButtonIcon } from 'app-view/components/icons'
import {
  DateText,
  IconContainer,
  MSGContainer,
  NotificationItem,
  NotificationItemLink,
  SettingButtonContainer,
  TextContainer,
} from './NotificationItemStyles'
import { EmptyCheckIcon } from 'app-view/components/icons'
import moment from 'moment'
import { useWizard } from 'app-view/components/Wizard'
import { SYSTEMNOTIFICATION_STEPS } from '../steps/common/index'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import {
  Maybe,
  GetRegAccountsDocument,
  GetRegAccountsQuery,
  GetRegAccountsQueryVariables,
} from 'app-engine/graphql/generated/bitcash'
import { useStore } from 'app-engine/store'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { useSystemNotifications } from 'app-view/hooks/use-systemNotification.hook'

const getAccountData = (account: string) =>
  apolloClient.query<GetRegAccountsQuery, GetRegAccountsQueryVariables>({
    query: GetRegAccountsDocument,
    variables: {
      account,
    },
  })

const { colors } = theme

const Notification: React.FC<{
  item
  className?: string
  pos?: number
  bottomPos?: number
}> = ({ item, className, pos, bottomPos }) => {
  const { i18n } = useTranslation(['notifications', 'p2p'])
  const { created_at, id, message, type, offer_id } = item
  const formattedTimeAgo = useMemo(() => {
    moment.locale(i18n.language)
    return moment(created_at).fromNow()
  }, [i18n.language, created_at])
  const [openToolTip, setOpenToolTip] = useState(false)
  const account = useStore.useAccount()
  const [, { start }] = useWizard()
  const [, { updateOffer }] = useP2POffer()
  const { setReadStatus } = useSystemNotifications()
  const read = item?.system_notification_read_statuses?.[0]?.reg_account ? true : false
  const isUnmatched = type.includes('UNMATCHED_OFFER_48H' || 'UNCONFIRMED_OFFER_12H')
  const redirectAdminP2P = 'admin?tab=p2p'
  const redirect = getRedirectLink({ redirectTo: redirectAdminP2P, contentId: offer_id })

  const getUser = async () => {
    try {
      const { data, error } = await getAccountData(account)
      if (error) {
        console.log('error', error)
        return null
      }

      if (data && data.reg_accounts) {
        const matchingAccount = data.reg_accounts.find(
          (accountData) => accountData.account === account,
        )
        if (matchingAccount) {
          const matchingId = matchingAccount.id
          return matchingId
        }
      }
      return null
    } catch (error) {
      console.error('Error in getUser', error)
      return null
    }
  }

  const loadWizard = () => {
    const wizardSteps = SYSTEMNOTIFICATION_STEPS
    updateOffer(item?.p2p_offer)
    start(wizardSteps)
  }

  const markAsRead = async () => {
    const regAccount = await getUser()
    if (regAccount) {
      const notification_id = id
      setReadStatus(notification_id, regAccount)
    } else {
      console.log('No matching reg_account found for', account)
    }
  }

  const onToolTipOptionsClick = async (option: string) => {
    try {
      if (option === 'marked_read') {
        setOpenToolTip(false)
        await markAsRead()
      }
    } catch (error) {
      console.log('Mutation error', (error as Error).message)
    }
  }

  const toggleToolTip = () => setOpenToolTip(!openToolTip)

  const toolTipOptions = [
    !read && {
      text: 'notifications:marked_read',
      onClick: (e: MouseEvent) => {
        e.preventDefault()
        onToolTipOptionsClick('marked_read')
      },
      icon: <EmptyCheckIcon />,
    },
  ].filter(Boolean) as unknown as ToolTipOptionsProps[]

  const truncateMessage = (message: Maybe<string> | undefined) => {
    const maxUuidLength = 8
    const maxTotalLength = 80
    if (message) {
      const uuidMatch = message.match(offer_id)
      if (uuidMatch && uuidMatch[0].length > maxUuidLength) {
        message = message.replace(uuidMatch[0], `${uuidMatch[0].substring(0, maxUuidLength)}...`)
      }
      if (message.length > maxTotalLength) {
        message = `${message.substring(0, maxTotalLength)}...`
      }
    }
    return message
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const truncatedMessage = useMemo(() => truncateMessage(message), [message])

  return (
    <>
      <NotificationItem className={className} as="li" pos={pos} bottomPos={bottomPos} read={read}>
        <NotificationItemLink
          to={isUnmatched ? redirect : '#'}
          onClick={async (e: any) => {
            if (e.target.closest('#notification-settings') || openToolTip) {
              e.preventDefault()
              return
            }
            if (!read) {
              onToolTipOptionsClick('marked_read')
            }
            if (!isUnmatched) {
              loadWizard()
            }
          }}
        >
          <IconContainer>
            <P2PNotificationIcon width="42px" height="42px" />
          </IconContainer>
          <TextContainer>
            <MSGContainer>{truncatedMessage}</MSGContainer>
            <DateText color={colors?.darkGray}>{formattedTimeAgo}</DateText>
          </TextContainer>
          <SettingButtonContainer id="notification-settings" onClick={toggleToolTip}>
            <SettingButtonIcon style={{ height: 16 }} />
          </SettingButtonContainer>
          <Tooltip
            id={`notification-item-tooltip-${id}`}
            open={openToolTip}
            content={toolTipOptions}
            setOpen={setOpenToolTip}
          />
        </NotificationItemLink>
      </NotificationItem>
    </>
  )
}

type GetRedirectLinkProps = {
  redirectTo: string
  contentId: string | null
}

const getRedirectLink = ({ redirectTo, contentId }: GetRedirectLinkProps) => {
  return `/${redirectTo}${redirectTo.includes('?') ? '&' : '?'}content_id=${contentId}`
}

export default Notification
