import { Confirmation } from './Confirmation'
import { Success } from './Success'
import { WizardStep } from 'app-view/components/Wizard'

export const TAKE_OFFER_STEPS: WizardStep[] = [
  {
    name: 'confirm',
    component: Confirmation,
  },
  {
    name: 'success',
    component: Success,
  },
]
