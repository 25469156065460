import React from 'react'
import {
  WarningIcon,
  ErrorIcon,
  ExchangeCoinsIcon,
  TranslateIcon,
  NotificationIcon,
  SuccessIcon,
  KeyRecoveryIcon,
  FilterIcon,
} from 'app-view/components/icons'
import { ModalTypeIconProps } from './types'
import { TipIcon } from 'app-view/components/icons/TipIcon'
import { StyledIcon } from './Styled'

export const ModalTypeIcon: React.FC<ModalTypeIconProps> = ({
  type,
  ...props
}: ModalTypeIconProps) => {
  switch (type.toUpperCase()) {
    case 'WARNING':
      return (
        <StyledIcon {...props}>
          <WarningIcon />
        </StyledIcon>
      )
    case 'ERROR':
      return (
        <StyledIcon {...props}>
          <ErrorIcon />
        </StyledIcon>
      )
    case 'TIP':
      return (
        <StyledIcon {...props}>
          <TipIcon />
        </StyledIcon>
      )
    case 'EXCHANGE':
      return <ExchangeCoinsIcon width={32} height={32} />
    case 'LANGUAGE':
      return <TranslateIcon {...props} />
    case 'CURRENCY':
      return <ExchangeCoinsIcon width={32} height={32} />
    case 'NOTIFICATION':
      return <NotificationIcon {...props} />
    case 'SUCCESS':
      return <SuccessIcon {...props} />
    case 'FILTER':
      return <FilterIcon {...props} style={{ fill: 'white' }} />
    case 'RECOVERY':
      return <KeyRecoveryIcon width={22} height={22} />
    default:
      return null
  }
}
