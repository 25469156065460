import { useStore } from 'app-engine/store'
import { ErrorFallback } from 'app-view/components/ErrorFallback'
import {
  ModalStyled,
  Overlay,
  Wrapper,
  modal_animation_props,
  modal_overlay_animation_props,
} from 'app-view/components/Modal'
import { SkeletonText } from 'app-view/components/Skeletons'
import { ButtonTransparent } from 'app-view/components/buttons/ButtonTransparent'
import { SendMsgIcon } from 'app-view/components/icons'
import { hasAdminPermissions } from 'app-view/lib/utils'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { AccountActivityIndicator } from 'pages/P2PView/components/Shared'
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import TextareaAutoSize from 'react-textarea-autosize'
import { ChatGlobes } from './common/ChatGlobes'
import { ChatGlobesLoader } from './common/ChatGlobesLoader'
import { ChatHeader } from './common/ChatHeader'
import { ContainerModalChat } from './common/Styled'
import { useChat } from './hooks/useChat'
import { ChatComponentProps } from './types'

const Chat: React.FC<ChatComponentProps> = () => {
  const [
    { isOpen, messages, loading, message, sending, readOnly, id, from, to },
    { setConversation, resetError, onClose, sendMessage, onTextChange, getTime, noConsecutive },
  ] = useChat()
  const account = useStore.useAccount()
  const { t } = useTranslation(['global'])
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const chatAreaRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!id && !from && !to) return
    if (
      id &&
      ((id.type === 'p2p' && id.id !== '') ||
        (id.type === 'support' && from !== '') ||
        (id.type === 'swap' && from !== ''))
    ) {
      setConversation(from, to, id)
    }
  }, [id, from, to, setConversation])

  const [prevMessagesLength, setPrevMessagesLength] = useState(messages.length)

  useEffect(() => {
    const isNewMessage = messages.length > prevMessagesLength
    if (isNewMessage && chatAreaRef.current) {
      chatAreaRef.current.scrollTo({
        top: chatAreaRef.current.scrollHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
    setPrevMessagesLength(messages.length)
  }, [messages, prevMessagesLength])

  const loadAnimOpt = {
    scale: sending ? 0.75 : 1,
    opacity: sending ? 0.5 : 1,
  }

  return ReactDOM.createPortal(
    <ErrorBoundary
      onReset={() => {
        resetError()
      }}
      FallbackComponent={ErrorFallback}
    >
      <AnimatePresence>
        {isOpen && (
          <>
            <Overlay {...modal_overlay_animation_props} />
            <Wrapper aria-modal aria-hidden tabIndex={-1} role="dialog" {...modal_animation_props}>
              <ModalStyled style={{ height: '79vh' }}>
                <ChatHeader onClose={onClose}>
                  <b>
                    {hasAdminPermissions(to) && (id?.type === 'support' || id?.type === 'swap') ? (
                      t('support_channel')
                    ) : (
                      <AccountActivityIndicator account={to} type="chat" />
                    )}
                  </b>
                </ChatHeader>
                <ContainerModalChat>
                  <div className="container-modal__messages" ref={chatAreaRef}>
                    {loading ? (
                      <ChatGlobesLoader />
                    ) : (
                      <ChatGlobes
                        messages={messages}
                        account={account}
                        getTime={getTime}
                        noConsecutive={noConsecutive}
                      />
                    )}
                    {sending && <SkeletonText />}
                  </div>
                  {!readOnly && (
                    <form className="container-modal__input" onSubmit={sendMessage}>
                      <div className="container-modal__textarea">
                        <TextareaAutoSize
                          spellCheck
                          ref={textareaRef}
                          minRows={1}
                          maxRows={3}
                          onChange={onTextChange}
                          value={message}
                        />
                      </div>
                      <ButtonTransparent
                        type="submit"
                        whileTap={{ scale: 0.75, opacity: 0.75 }}
                        animate={loadAnimOpt}
                        style={{ width: '15%', height: '100%', padding: 0 }}
                        disabled={loading || sending}
                      >
                        <SendMsgIcon style={{ width: 21, height: 21 }} />
                      </ButtonTransparent>
                    </form>
                  )}
                </ContainerModalChat>
              </ModalStyled>
            </Wrapper>
          </>
        )}
      </AnimatePresence>
    </ErrorBoundary>,
    document.body,
  )
}

export default Chat
