import React from 'react'
import { BlackButton, GreenButton, RedButton } from 'app-view/components/buttons'

export const ModalCancelButton = BlackButton

export const ModalConfirmButton: React.FC<
  { isDestructive?: boolean } & React.ComponentProps<typeof GreenButton>
> = ({ isDestructive, ...props }) => {
  if (isDestructive) return <RedButton {...props} />
  return <GreenButton {...props} />
}
