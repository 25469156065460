import React from 'react'

const FTMIcon = (props: any) => (
  <svg viewBox="0 0 56 56" {...props} id="ftm-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.2966 18.3766C52.2067 6.59227 40.4908 1.07678 29.3918 1.00123C17.1944 0.919012 4.08809 4.92948 1.70362 18.3766C0.576304 24.7327 0.975632 30.893 1.70362 37.2699C3.19535 50.3284 16.2301 55.3118 27.9997 54.985C39.6862 54.6607 52.7657 50.6724 54.2966 37.2695C55.0245 30.893 55.4234 24.7327 54.2966 18.3766Z"
      fill="url(#pattern-ftm)"
      stroke="#979797"
      strokeWidth="0.5"
      strokeLinecap="round"
    />
    <defs>
      <pattern id="pattern-ftm" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use xlinkHref="#ftm:2540" transform="scale(0.015625)" />
      </pattern>
      <image
        id="ftm:2540"
        width="64"
        height="64"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAC2ElEQVR4Xu2avWpVQRSF8xpaWFlaiG9g4QsIFoKVnSJioaiFlVooKCIoVmIhorEQ/GkERSxyrQRBkKAWViYgeEHiT4w5uoth5qzZc+4+M3vmCjMLPshZa+8zkwUh5JKFLYe6rmYW0KiNVgAatdEKQKM2WgFo1EYrAI2SfFjpuievfb8kcykgJJwrQdECULP8EhQpwNWzt37OzZEwN2w/NpyPIWsBKMxDuPqxPpxjNpYsBaAwl+JqabmfXXpsM9wbg2oBN1/YS6VezLDvSv+d247Y7OW79HPUCnCFmRbcGaQLD/1ZKeoFoK8NnkP68s2fk6JegBHmqaAwjyVbAUY4N5aQcC4W9QLwGX0prjb++D7Ox5KtAPRJm5t+jqBCOfqxZC+A2HrY5qSDN/yZ6Vp/BnPJOTEUKcCw+6ydI33/1X8m4Q4inZNStAAD/Uyjzj3w5ziM0I+laAEHrtm5kHAHkc5JKVKA+evNaM95f+fjqn02HsesfCzZC3C19pPPOI/LQjspZCvA1dFb/jy340JluZLsxKBeAArnuB30uRkUzsWSrQDMOWJmx+xIUC8A/SFK7QyhXgBpxwk/5zBCn4M+S3SFeSxZCjDCGUQyd/y2nXOFc7GoF+B+7Xoc0px0byLbGUuWAtDjstAO7q1O+Qx3YslaAGY4E3omvfnkv4fbSaVIATgzpN8b/h73DvRjKVoAzqJwjmPMrIS5FGB4/u9X2/Wnvj9EzDlDzLWAGLTPaQWgEYv2xUJon9MKQCMW7YuF0D6nFYCGFLwIPudC+xz1AlxPk8VJnjOiCyBIey/7ntHFR/7OWFCYp5JcAOn+q75/ZtFmJNyTcPJO+jskJBVADF1y8n4459h5qr+z67Q/o0lyAcSsbw6FOTf3+auf50ClACkr0/43uf9q191d6nv0T1G4l5OiBRg4rc/4HCAXcyngf6IVgEZttALQqI1WABq10QpAozaqL+AvtXxg0JVJtNkAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
)

export default FTMIcon
