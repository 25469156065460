import { APIClient } from '@greymass/eosio'
import { config } from 'app-config'
import { signWithWebAuthn, SignWithWebAuthNParams } from './webauthn'
import _ from 'lodash'

export const eosCoreApi = new APIClient({ url: config.services.chainRpc }).v1.chain

export const pushTransactionWebAuthN = async ({
  public_key,
  actions,
  cred_id,
}: SignWithWebAuthNParams) => {
  // console.info('pushTransactionWebAuthN', { public_key, actions, cred_id })

  const signedTransactionWebAuthN = await signWithWebAuthn({ public_key, actions, cred_id })

  // console.info('pushTransactionWebAuthN::signedTransactionWebAuthN', signedTransactionWebAuthN)
  const response = await eosCoreApi.send_transaction2(signedTransactionWebAuthN, {
    retry_trx: true,
    retry_trx_num_blocks: 6,
  })

  const exception = _.get(response, 'processed.except')

  if (exception) {
    // @ts-expect-error
    const message = exception.message.includes('eosio_assert_message assertion failure')
      ? // @ts-expect-error
        `${exception.message}:${exception.stack[0].data.s}`
      : // @ts-expect-error
        exception.message
    throw new Error(message)
  }

  // console.log('pushTransactionWebAuthN::eosCoreApi.push_transaction::response.transaction_id', response.transaction_id)

  return response
}
