import { getCountriesByCurrencyCode, getCurrencyData } from 'app-engine/library/currency-countries'
import { useWizard, WizardStepProps } from 'app-view/components/Wizard'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useLoggedUser } from 'app-view/hooks/use-realtime/use-logged-user'
import { isMethodWithDetails } from 'app-view/lib/utils'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { Method } from 'pages/P2PView/steps/common/Method'
import { useTranslation } from 'react-i18next'

export const BuyMethod = ({ prev, next, goTo }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const countryDataLoggedUser = useLoggedUser()
  const countriesData = bitcashRoom.useColyseusState((state) => state.countryData)
  const [{ offer }, { updateOffer }] = useP2POffer()
  const [{ step }] = useWizard()
  const offerAmountSymbol = offer.amount.split(' ')[1]

  const onChange = async (option: string) => {
    const countryData = await getCurrencyData(offerAmountSymbol, countriesData)

    let countryOption = `${countryData?.region}-${countryData?.subregion.replace(/\s/g, '_')}-${
      countryData?.commonName
    }`.toLowerCase()

    if (offerAmountSymbol.includes('EUR')) countryOption = countryData.region.toLowerCase()

    if (step === 'method' && !offerAmountSymbol.includes('USD') && countryOption) {
      return updateOffer({ method: option, region: countryOption })
    }
    // Getting country data by IP by using getCountriesByCurrencyCode to match exact types and values
    if (step === 'method' && offerAmountSymbol.includes('USD')) {
      const regionOption = (
        await getCountriesByCurrencyCode(countryDataLoggedUser?.currency || 'USD', countriesData)
      ).filter((country) => country.commonName === countryDataLoggedUser?.countryCommonName)[0]

      return updateOffer({
        method: option,
        region: `${regionOption?.region}-${regionOption?.subregion?.replace(/\s/g, '_')}-${
          regionOption?.commonName
        }`.toLowerCase(),
      })
    }

    if (step === 'method') return updateOffer({ method: option })

    return updateOffer({ region: option })
  }

  const onNext = async () => {
    if (isMethodWithDetails(offer.method) && step === 'method' && offer.region) {
      return goTo('method-details')
    }
    if (offer.method.match(/(bank_transfer|oxxo)/) && step.match(/^method/)) return next()

    return goTo('confirm')
  }

  // TODO: Not liking how l18n text props are coming, it should come one text only with white-space (<Text> component already has it). For now it's low priority
  const method_text = {
    method: t('how_you_want_to_pay'),
    'method-bank': t('select_region_country_search'),
  }

  return (
    <Method
      onChange={onChange}
      onPrev={prev}
      onNext={onNext}
      text={method_text[step as keyof typeof method_text]}
    />
  )
}
