import React, { useEffect, useState } from 'react'
import { useUpdatePreferencesMutation } from 'app-engine/graphql/generated/bitcash'
import { isMobile } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { useWizard } from 'app-view/components/Wizard'
import {
  OnboardingModalHeader,
  OnboardingModalFooter,
  OnboardingModalContent,
  LanguageSelectContent,
  LanguageItem,
} from '../common'

export type SelectOption = {
  value: string
  label: string
}

export const languageOptions: SelectOption[] = [
  { value: 'en', label: 'English' },
  { value: 'cn', label: '中文' },
  { value: 'es', label: 'Español' },
  { value: 'vi', label: 'Tiếng Việt' },
  { value: 'id', label: 'Bahasa Indonesia' },
]

export const LanguageSelect = () => {
  const [, { next }] = useWizard()
  const [updatePreferencesMutation] = useUpdatePreferencesMutation()
  const { account, authErrorFallback, onboarding, setOnboardingLanguage } = useStore()
  const [language, setLanguage] = useState('')

  useEffect(() => {
    setLanguage(onboarding.language || 'en')
  }, [onboarding.language])

  const onNext = () => {
    updatePreferencesMutation({
      variables: { account, _set: { language } },
      onError: (err) => {
        authErrorFallback(err)
      },
    })
    next()
  }

  return (
    <OnboardingModalContent>
      <OnboardingModalHeader />
      <LanguageSelectContent>
        <div>
          {languageOptions.map((lang) => (
            <LanguageItem
              className={language === lang.value ? 'active' : ''}
              onClick={() => setOnboardingLanguage(lang.value)}
              key={lang.value}
            >
              {lang.label}
            </LanguageItem>
          ))}
        </div>
      </LanguageSelectContent>
      <OnboardingModalFooter step={1} limit={isMobile ? 2 : 3} onNext={onNext} />
    </OnboardingModalContent>
  )
}
