import styled from 'app-view/styles/styled'

export const OnboardingModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  width: 100%;
  flex: 1;
`

export const OnboardingModalHeaderWrap = styled.div`
  height: 40px;
`

export const OnboardingModalFooterWrap = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: end;
`

export const StepperContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3px;
  width: 100%;
`

export const Stepper = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #d9d9d9;
  &.active {
    background-color: #29e0ad;
  }
`

export const LanguageSelectContent = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 300px;
  }
`

export const LanguageItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: rgba(217, 217, 217, 0.27);
  font-weight: bold;
  cursor: pointer;

  &.active {
    border-color: #29e0ad;
  }
  &:after {
    content: '';
    margin-top: 100%;
  }
`

export const NotificationEnableContent = styled.div`
  display: flex;
  justify-content: center;
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    width: 100%;
    max-width: 250px;
    text-align: center;
  }
`

export const NotificationEnableActions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;

  & > button {
    flex: 1;
  }
`

export const SuccessContent = styled.div`
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SuccessAction = styled.span`
  margin-top: 20px;
  cursor: pointer;
  color: #6c6c6e;
  font-weight: bold;
`
