import { TFunction } from 'i18next'
import { LocalBankData } from 'app-engine/types/p2pPaymentMethods'
import { getCurrencyLabel } from 'app-view/lib/amounts'
import { minus, times } from 'number-precision'
import { p2pDAOFee, p2pBuyFee, p2pBuyPercentage, p2pSellFee } from 'app-engine/store/p2p-slice'
import { Asset } from 'eos-common'
import { CountryData } from './utils.types'
import { config } from 'app-config'

// NOTE: canAccessAdminView & hasAdminPermissions are the same at this point but
// we could distinguish them in the future if needed
// canAccessAdminView means if the account can see the admin view
// hasAdminPermissions means if the account can perform admin actions like cancel or fill orders

export const canAccessAdminView = (account: string) =>
  config.bitcashSupervisors.includes(account) || account === config.bitcashAdmin

export const hasAdminPermissions = (account: string) =>
  config.bitcashSupervisors.includes(account) || account === config.bitcashAdmin

/**
 *
 * @returns Formatted date from timestamp or date. e.g.: **April 16, 2023 at 10: 33AM**
 */
export const formattedDate = (language: string, timestampt: Date | string, short?: boolean) => {
  const date = new Date(timestampt)
  let formatted_date = ''

  try {
    formatted_date = new Intl.DateTimeFormat(
      language,
      short
        ? {
            dateStyle: 'short',
          }
        : {
            dateStyle: 'long',
            timeStyle: 'short',
          },
    ).format(date)
  } catch (error) {
    formatted_date = new Intl.DateTimeFormat(
      language,
      short
        ? {
            dateStyle: 'short',
          }
        : {
            dateStyle: 'long',
            timeStyle: 'short',
          },
    ).format(new Date(parseInt(timestampt as string, 10)))
  }

  return formatted_date
}

export const sellAmountLessP2PFee = (amount: string) => {
  const cryptoAmount = amount.split(' ')[0]
  return `${times(cryptoAmount, minus(1, p2pSellFee))} ${amount
    .split(' ')[1]
    .replace(/BIT/g, 'bit')
    .replace(/GBR/g, 'GBP')}`
}

export const buyAmountLessP2PFee = (amount: string) => {
  const cryptoAmount = amount.split(' ')[0]
  return `${times(cryptoAmount, minus(1, p2pBuyFee))} ${amount
    .split(' ')[1]
    .replace(/BIT/g, 'bit')
    .replace(/GBR/g, 'GBP')}`
}

export const sellerAssetAmountLessP2PFee = (amount: string): string => {
  const asset = new Asset(amount)
  const newAmount = asset.amount.multiply(times(100, minus(1, p2pSellFee))).divide(100)
  asset.set_amount(newAmount)
  const assentString = asset.toString()
  return assentString
}

export const buyerCryptoAmountLessP2PFee = (amount: string) => {
  const cryptoAmount = amount.split(' ')[0]
  const fee = times(p2pBuyPercentage, cryptoAmount)
  return `${fee} ${amount.split(' ')[1].replace(/BIT/g, 'bit').replace(/GBR/g, 'GBP')}`
}

export const getAmountFee = (amount: string) => {
  const cryptoAmount = amount.split(' ')[0]
  const fee = times(p2pBuyFee, cryptoAmount)

  return fee
}

export const getDAOAmountFee = (amount: string, fixed?: boolean) => {
  const cryptoAmount = amount.split(' ')[0]
  const fee = times(p2pDAOFee, cryptoAmount)
  const amountFee = `${fixed ? fee.toFixed(2) : fee} ${amount
    .split(' ')[1]
    .replace(/BIT/g, 'bit')
    .replace(/GBR/g, 'GBP')}`
  const formattedAmountFee = fixed ? amountFee.toUpperCase() : fee

  return formattedAmountFee
}

export const get_cryptoFee = (amount: string, full?: number) =>
  getCurrencyLabel(
    amount.split(' ')[1].replace(/GBR/g, 'GBP'),
    Number(times(amount.split(' ')[0], p2pBuyFee)),
    full,
  )

export const cryptoAmount = (amount: string) =>
  `${parseFloat(amount.split(' ')[0])} ${amount
    .split(' ')[1]
    .replace(/BIT/g, 'bit')
    .replace(/GBR/g, 'GBP')}`
export const get_amount = (amount: string, full?: number) =>
  getCurrencyLabel(amount.split(' ')[1].replace(/GBR/g, 'GBP'), Number(amount.split(' ')[0]), full)

export const isMethodWithDetails = (method: string) =>
  Boolean(
    method
      .toLowerCase()
      .split(' ')
      .join('_')
      .match(/(bank_transfer|grab|gojek|sinpe_movil|ovo|duitnow|oxxo|opay|palmpay|kuda)/),
  )

export const methodDetailsLabel = (i18n_text: string, method: string) =>
  isMethodWithDetails(method) ? i18n_text : ''

export const getRegionLabel = (region: string, label: TFunction) =>
  (region || '').split('-').length === 1 ? label : '.'
export const regions_whitelist = ['US_&_CA', 'europe', 'LATAM', 'asia']
export const countries_whitelist = [
  'United States',
  'Canada',
  'Costa Rica',
  'Mexico',
  'Venezuela',
  'Cuba',
  'Argentina',
  'Brazil',
  'Taiwan',
  'Malaysia',
  'Indonesia',
  'Vietnam',
]
export const bank_transfer_data: LocalBankData = {
  region: {
    'US_&_CA': {
      subregion: {
        north_american: ['Canada', 'United States'],
      },
    },
    europe: {
      subregion: {
        eastern_europe: [
          'Russia',
          'Belarus',
          'Poland',
          'Czechia',
          'Slovakia',
          'Moldova',
          'Hungary',
          'Romania',
          'Bulgaria',
          'Ukraine',
        ],
        western_europe: [
          'Germany',
          'Netherlands',
          'Belgium',
          'Luxembourg',
          'Austria',
          'Switzerland',
          'France',
          'Switzerland',
          'Monaco',
          'Liechtenstein',
        ],
        northern_europe: [
          'Iceland',
          'United Kingdom',
          'Ireland',
          'Denmark',
          'Lithuania',
          'Latvia',
          'Estonia',
          'Finland',
          'Sweden',
          'Norway',
        ],
        southern_europe: [
          'Croatia',
          'Slovenia',
          'Bosnia and Herzegovina',
          'Andorra',
          'Germany',
          'Greece',
          'Italy',
          'Luxembourg',
          'Netherlands',
          'Portugal',
          'Spain',
          'Serbia',
          'North Macedonia',
          'Albania',
          'Malta',
          'Montenegro',
          'San Marino',
        ],
      },
    },
    LATAM: {
      subregion: {
        north_american: ['Mexico'],
        central_american: [
          'Belize',
          'Costa Rica',
          'El Salvador',
          'Guatemala',
          'Honduras',
          'Nicaragua',
          'Panama',
        ],
        south_american: [
          'Venezuela',
          'Argentina',
          'Bolivia',
          'Brazil',
          'Chile',
          'Colombia',
          'Guyana',
          'Ecuador',
          'Suriname',
          'Paraguay',
          'Peru',
          'Uruguay',
        ],
        caribbean_american: [
          'Antigua And Barbuda',
          'The Bahamas',
          'Barbados',
          'Cuba',
          'Dominica',
          'Dominican Republic',
          'Grenada',
          'Haiti',
          'Jamaica',
          'St. Kitts and Nevis',
          'St. Lucia',
          'St. Vincent and the Grenadines',
          'Trinidad and Tobago',
        ],
      },
    },
    asia: {
      subregion: {
        east_asia: ['China', 'Hong Kong', 'Japan', 'Macau', 'Mongolia', 'South Korea', 'Taiwan'],
        south_asia: ['Nepal', 'India', 'Pakistan'],
        central_asia: ['Kazakhstan', 'Kyrgyz Republic', 'Turkmenistan', 'Uzbekistan'],
        southeast_asia: [
          'Myanmar',
          'Vietnam',
          'Thailand',
          'Malaysia',
          'Indonesia',
          'Philippines',
          'Singapore',
        ],
        western_asia: [
          'Bahrain',
          'Jordan',
          'Kuwait',
          'Lebanon',
          'Oman',
          'State of Palestine',
          'Qatar',
          'Saudi Arabia',
          'Syrian Arab Republic',
          'United Arab Emirates',
          'Yemen',
        ],
      },
    },
    oceania: {
      subregion: {
        'australia_&_new_zealand': [
          'Australia',
          'Christmas Island',
          'Cocos (Keeling) Islands',
          'Heard Island and McDonald Islands',
          'New Zealand',
          'Norfolk Island',
        ],
        melanesia: ['Fiji', 'New Caledonia', 'Papua New Guinea', 'Solomon Islands', 'Vanuatu'],
        micronesia: [
          'Guam',
          'Kiribati',
          'Marshall Islands',
          'Micronesia',
          'Nauru',
          'Northern Mariana Islands',
          'Palau',
        ],
        polynesia: [
          'American Samoa',
          'Cook Islands',
          'French Polynesia',
          'Niue',
          'Pitcairn',
          'Samoa',
          'Tokelau',
          'Tonga',
          'Tuvalu',
          'Wallis and Futuna',
        ],
      },
    },
    africa: {
      subregion: {
        northern_africa: [
          'Algeria',
          'Egypt',
          'Libya',
          'Morocco',
          'Sudan',
          'Tunisia',
          'Western Sahara',
        ],
        eastern_africa: [
          'Burundi',
          'Comoros',
          'Djibouti',
          'Ethiopia',
          'Eritrea',
          'Kenya',
          'Madagascar',
          'Malawi',
          'Mauritius',
          'Mozambique',
          'Réunion',
          'Rwanda',
          'Seychelles',
          'Somalia',
          'Somaliland',
          'Tanzania',
          'Uganda',
          'Zambia',
          'Zimbabwe',
        ],
        middle_africa: [
          'Democratic Republic of the Congo',
          'Cameroon',
          'Chad',
          'Central African Republic',
          'Congo',
          'Gabon',
          'Equatorial Guinea',
          'Sao Tome and Principe',
        ],
        southern_africa: [
          'Angola',
          'Botswana',
          'Lesotho',
          'Mozambique',
          'Namibia',
          'South Africa',
          'Swaziland',
          'Zambia',
          'Zimbabwe',
        ],
        western_africa: [
          'Benin',
          'Burkina Faso',
          'Cape Verde',
          "Côte D'Ivoire",
          'Gambia',
          'Ghana',
          'Guinea',
          'Guinea-Bissau',
        ],
      },
    },
  },
}

export const titleCase = (str: string) => {
  if (!str) return ''

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ')
}

export const formatTimestamp = (timestamp: number) => {
  try {
    if (!timestamp) {
      return ''
    }
    const now = Date.now()
    const differenceInSeconds = Math.floor((now - timestamp) / 1000)
    if (differenceInSeconds === 0) {
      return 'just know'
    }
    const rtf1 = new Intl.RelativeTimeFormat('en', { style: 'short' })

    if (differenceInSeconds < 60) {
      return rtf1.format(-differenceInSeconds, 'second')
    }

    const differenceInMinutes = Math.floor(differenceInSeconds / 60)
    if (differenceInMinutes < 60) {
      return rtf1.format(-differenceInMinutes, 'minute')
    }

    const differenceInHours = Math.floor(differenceInMinutes / 60)
    if (differenceInHours < 24) {
      return rtf1.format(-differenceInHours, 'hour')
    }

    const differenceInDays = Math.floor(differenceInHours / 24)
    if (differenceInDays < 30) {
      return rtf1.format(-differenceInDays, 'day')
    }

    const differenceInMonths = Math.floor(differenceInDays / 30)
    return rtf1.format(-differenceInMonths, 'month')
  } catch (error) {
    console.error(error)
    return ''
  }
}
