// about.ts

export default {
  heading:
    'Selamat datang di Bitcash App, cara mudah dan aman untuk membeli dan berdagang kripto berkualitas.',
  t1: 'Bagaimana cara membeli kripto?',
  p1: '• Pergi ke P2P dan tekan Buy untuk terhubung dengan orang lain untuk membeli bitUSD menggunakan Venmo, Paypal atau Zelle dari orang lain di komunitas kami.\n\n• Gunakan bitUSD untuk ditukar dengan kripto lain.\n\nItulah caranya. Pertahankan kebebasan dan privasi Anda dari big brother. Tidak perlu mendaftarkan ID atau SSN Anda. Tidak ada KYC/AML.',
  t2: 'Apa lagi yang bisa Anda lakukan dengan bitUSD?',
  p2: 'Anda dapat membayar orang lain untuk barang dan jasa dan untuk penggunaan sehari-hari seperti dolar AS!',
  home_screen:
    'Anda dapat menambahkan Bitcash App ke Layar Beranda Anda dan menggunakannya seperti aplikasi biasa!',
  what_is_web: 'Apa itu aplikasi web?',
  do_it: 'ayo lakukan!',
}
