// trade.ts
export default {
  amount: 'Số tiền',
  leverage: 'Đòn bẩy',
  ltv: 'LTV',
  loan: 'Khoản vay',
  position: 'Vị trí',
  price: 'Giá',
  custom: 'Tùy chỉnh',
  select: 'Chọn',
  available: 'Số dư USD',
}
