import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { buyerCryptoAmountLessP2PFee, getAmountFee, get_amount } from 'app-view/lib/utils'
import { Matched } from '../common/Matched'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const BuyMatchedConfirmOffer = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()

  return (
    <>
      <Matched
        confirmed
        text={t('buy_matched_confirm_offer', {
          seller: offer.sellerLabel,
          amount: get_amount(offer.amount),
          cryptoAmount: buyerCryptoAmountLessP2PFee(offer.amount),
          cryptoFee: getAmountFee(offer.amount),
          method: methodLabel(offer.method),
          methodDetails: isMethodDetails ? sellerMethodDetailsLabelData : '',
        })}
      />
    </>
  )
}
