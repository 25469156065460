import React from 'react'

const EOSUSDTIcon = (props: any) => (
  <svg viewBox="0 0 62 58" {...props} id="tron-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4949 0.00132486C17.394 -0.0869867 3.31684 4.22055 0.755737 18.6637C-0.455081 25.4906 -0.0261733 32.1073 0.755737 38.9566C2.35797 52.9824 16.3583 58.3349 28.9996 57.9839C41.5518 57.6356 55.6002 53.3518 57.2444 38.9561C58.0264 32.1073 58.4548 25.4906 57.2444 18.6637C54.9998 6.00651 42.416 0.0824639 30.4949 0.00132486Z"
      fill="#26A17B"
    />
    <path
      d="M31.9601 29.7788V29.7748C31.7899 29.7867 30.9115 29.8381 28.9568 29.8381C27.3939 29.8381 26.2979 29.7946 25.9101 29.7748V29.7788C19.8996 29.5137 15.4165 28.4691 15.4165 27.2147C15.4165 25.9644 19.9036 24.9158 25.9101 24.6507V28.7381C26.3018 28.7658 27.4295 28.8331 28.9845 28.8331C30.8522 28.8331 31.786 28.754 31.9601 28.7381V24.6547C37.9587 24.9237 42.4299 25.9683 42.4299 27.2187C42.4299 28.4691 37.9547 29.5137 31.9601 29.7827V29.7788ZM31.9601 24.2313V20.5752H40.3288V15H17.5453V20.5752H25.914V24.2313C19.1122 24.5439 14 25.8892 14 27.5036C14 29.118 19.1162 30.4633 25.914 30.7799V42.5H31.964V30.7799C38.754 30.4673 43.8583 29.1219 43.8583 27.5076C43.8583 25.8971 38.754 24.5478 31.964 24.2353"
      fill="white"
    />
    <circle cx="49" cy="45" r="13" fill="#151830" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.9723 35L44.3833 41.3514L42.0693 51.0123L48.9736 55L55.9155 51.0158L53.6625 41.4351L53.6435 41.3549L48.9723 35ZM45.2145 41.6143L48.978 36.4053L52.7693 41.5628L49.0024 53.2078L45.2145 41.6143ZM53.1766 43.0069L54.9658 50.615L49.7467 53.6101L53.1766 43.0069ZM43.0209 50.6143L44.8223 43.0933L48.2703 53.6462L43.0209 50.6143Z"
      fill="white"
    />
  </svg>
)

export default EOSUSDTIcon
