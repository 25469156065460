import React from 'react'
import { useEffectOnce } from 'react-use'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { useStore } from 'app-engine/store'
import {
  ModalButtonsContainer,
  ModalStepGreenButton,
  ModalStepBlackButton,
  ModalText,
} from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Form } from 'pages/AccountView/components/TextElements'
import { useBankWallet } from 'pages/BankView/hooks/useBankWallet'
import { WalletStepConfirmWrapper } from 'pages/WalletView/steps/common/Styled'
import { cryptoAmount } from 'app-view/lib/utils'
import { findTokenBySymbolCode, useTokenPrices } from 'app-view/hooks/use-token-prices'

interface WithdrawConfirmState {
  baseAmount: string
  targetAmount: string
  exchangeRate: string
}

const initialState: WithdrawConfirmState = {
  baseAmount: '',
  targetAmount: '',
  exchangeRate: '',
}

export const WithdrawConfirm = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['wallet'])
  const tokenPricesHook = useTokenPrices()
  const getUsdTokenValue = tokenPricesHook.getUsdTokenValue
  const findTokenPriceBySymbolCode = tokenPricesHook.findTokenPriceBySymbolCode
  const { account } = useStore()
  const [{ action, loading, error }, { submitAction, setError }] = useBankWallet()

  const [{ baseAmount, targetAmount }, setState] = useSetState<WithdrawConfirmState>(initialState)

  useEffectOnce(() => {
    const getExchangePair = () => {
      try {
        const amount = action.amount.amount.toString()
        const symbol = action.amount.symbol
        const sym_precision = amount.length - symbol.precision()
        const digested_amount = `${amount.substring(0, sym_precision)}.${amount.substring(
          sym_precision,
        )}`
        const symbol_code = symbol.code().toString()
        const trnx_quantity = `${digested_amount} ${symbol_code}`
        setState({ baseAmount: trnx_quantity })

        const target_symbol = 'USDT'
        const target = getUsdTokenValue(action.amount)
        const target_amount = target.amount.toString()
        const target_precision = target_amount.length - target.symbol.precision()
        const target_formatted = `${target_amount.substring(
          0,
          target_precision,
        )}.${target_amount.substring(target_precision)}`
        const target_quantity = `${target_formatted} ${target_symbol}`
        setState({ targetAmount: target_quantity })

        // TODO: Exchange rate temporally removed. Only bitUSD withdraw is possible
        const price = findTokenPriceBySymbolCode(symbol_code)
        if (!price) throw new Error('token_price_not_found')
        const priceAmountStr = price.usd_price.amount.toString()
        const pricePrecision = priceAmountStr.length - price.usd_price.symbol.precision()
        const rate = `${priceAmountStr.substring(0, pricePrecision)}.${priceAmountStr.substring(
          pricePrecision,
        )}`
        setState({ exchangeRate: `${rate} ${symbol_code.replace('BIT', '')}` })
      } catch (err) {
        setError((err as Error).message)
      }
    }

    getExchangePair()
  })

  const onWithdrawal = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const symbol = action.amount.symbol
      const symbol_code = symbol.code().toString()
      const token = findTokenBySymbolCode(symbol_code)

      const { success } = await submitAction({
        account,
        quantity: {
          contract: token.token_contract,
          quantity: baseAmount,
        },
      })

      if (success) next()
    } catch (err) {
      setError((err as Error).message)
    }
  }

  if (loading) return <Loader text={t('loading_action')} />

  const baseAmountLabel = baseAmount
    ? cryptoAmount(
        `${parseFloat(baseAmount.split(' ')[0]) + action.fee} ${baseAmount.split(' ')[1]}`,
      )
    : `${parseFloat(baseAmount.split(' ')[0]) + action.fee} ${baseAmount.split(' ')[1]}`

  return (
    <Form onSubmit={onWithdrawal} wallet={!Boolean(error)}>
      <WalletStepConfirmWrapper>
        <ModalText
          dangerouslySetInnerHTML={{
            __html: `${t('withdraw_confirm', {
              base_amount: baseAmountLabel,
              target_amount: targetAmount ? cryptoAmount(targetAmount) : targetAmount,
            })}`,
          }}
        />
        {/*

          ${t('exchange_rate_amount', { exchange_rate: exchangeRate })} */}
      </WalletStepConfirmWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={prev}>
          {t('back_action')}
        </ModalStepBlackButton>
        <ModalStepGreenButton type="submit">{t('confirm_action')}</ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
