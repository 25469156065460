import React, { useEffect } from 'react'
import styled from 'app-view/styles/styled'
import {
  ActionContainer as CoinOrderContainer,
  BoldText,
  RowContainer,
} from 'app-view/components/Styled'
import { SwapIcon } from 'app-view/components/SwapIcon'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { CopyAddress } from 'app-view/components/CopyAddress'
import {
  HistoryContentContainer,
  HistoryTextContainer,
  HistoryTextLabel,
  HistoryTextValue,
  ModalButtonsContainer,
} from 'app-view/components/Modal'
import { BlackButton, GreenButton, RedButton } from 'app-view/components/buttons'
import moment from 'moment'
import { AdminActionsProvider, useAdminActions } from '../hooks/useAdminActions'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { useTranslation } from 'react-i18next'
import { get_amount } from 'app-view/lib/utils'
import { SwapOrder, getOrderWithFee } from 'app-engine/store/swap-slice'
import { divide } from 'number-precision'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import {
  GetChainGraphTableRowsDocument,
  GetChainGraphTableRowsQuery,
  GetChainGraphTableRowsQueryVariables,
} from 'app-engine/graphql/generated/chaingraph'
import { config } from 'app-config'

const Text = styled.p<{ dollars?: boolean }>`
  color: ${(props) => props.color};
  font-size: ${(props: any) => (props.dollars ? '0.8125rem' : '1rem')};
  margin-bottom: 0;
  font-weight: ${(props: any) => (props.dollars ? '400' : '600')};
  user-select: text;

  > span {
    font-size: ${(props: any) => (props.dollars ? '	0.75rem' : '0.8125rem')};
    font-weight: bold;
  }
`

type AdminModalContentProps = {
  order: SwapOrder
  onChat: (data: { account: string; id: string }) => void
  onCancelOrder: (order: SwapOrder) => void
  onFilledOrder: (order: SwapOrder) => void
}

type ErrorComponentProps = {
  error: string
  reset: () => void
}

const getChaingraphTableRowsActiveOffers = async (order: SwapOrder) =>
  await apolloClient.query<GetChainGraphTableRowsQuery, GetChainGraphTableRowsQueryVariables>({
    query: GetChainGraphTableRowsDocument,
    variables: {
      where: {
        table: { _eq: order.order_type === 'buy' ? 'buygemsords' : 'sellgemsords' },
        contract: { _eq: config.contracts.bitcashBank },
        data: {
          _contains: {
            id: Number(order.gems_id),
          },
        },
        scope: { _eq: order.bitcash_account },
      },
    },
  })

export const AdminModalContent = ({
  order,
  onChat,
  onCancelOrder,
  onFilledOrder,
}: AdminModalContentProps) => {
  const [, globalModalActions] = useGlobalModal()
  const { t } = useTranslation(['global', 'history'])
  const [shouldRetryCancel, setShouldRetryCancel] = React.useState(true)
  const [shouldRetryFill, setShouldRetryFill] = React.useState(true)
  const [totalCryptoLabel, setTotalToSendLabel] = React.useState(
    `${fixedAmountDecimals(parseFloat(order.asset_amount.toFixed(8)), order.asset)} …`,
  )
  const [{ error, loading }, { fillOrder, cancelOrder, setError }] = useAdminActions()

  useEffect(() => {
    if (order.order_status === 'open') return

    const fetchChaingraphOrder = async () => {
      const results = await getChaingraphTableRowsActiveOffers(order)

      setShouldRetryCancel(order.order_status === 'cancelled' && results.data.table_rows.length > 0)
      setShouldRetryFill(order.order_status === 'filled' && results.data.table_rows.length > 0)
    }

    fetchChaingraphOrder()
  }, [order])

  const handleFillOrder = async () => {
    await fillOrder(order).then((result) => {
      if (!result.success) return
      if (order.order_status !== 'filled') onFilledOrder(order)

      globalModalActions.close()
    })
  }

  const handleCancelOrder = async () => {
    await cancelOrder(order).then((result) => {
      if (!result.success) return
      if (order.order_status !== 'cancelled') onCancelOrder(order)

      globalModalActions.close()
    })
  }

  const openChat = () => {
    onChat({ account: order.bitcash_account || '', id: order.id })
  }

  const historyOrderLabels = (order: SwapOrder) => {
    const {
      asset,
      bitcash_currency,
      asset_amount,
      bitcash_amount,
      fee,
      fee_percentage,
      price,
      order_type,
    } = order
    const totalBitcash = get_amount(`${bitcash_amount} ${bitcash_currency}`)
    const totalCrypto = parseFloat(asset_amount.toFixed(8))
    const isSell = order_type === 'sell'

    const toggleTotalCrypto = () => {
      const cryptoLabel = !totalCryptoLabel.includes('…')
        ? `${fixedAmountDecimals(totalCrypto, asset)}…`
        : `${fixedAmountDecimals(totalCrypto, asset)}
        fee ${divide(fee, price).toFixed(4)} ${asset} (${fee_percentage}%)`

      setTotalToSendLabel(cryptoLabel)

      return cryptoLabel
    }

    return [
      {
        label: 'last_update',
        value: moment(order.updated_at).format('LLLL'),
      },
      {
        label: 'order_type',
        value: order.order_type?.toUpperCase(),
      },
      {
        label: 'Amount to Send:',
        value: isSell ? totalCryptoLabel : totalBitcash,
        onClick: isSell ? toggleTotalCrypto : undefined,
      },
      {
        label: 'Amount to Receive:',
        value: isSell ? totalBitcash : totalCryptoLabel,
        onClick: isSell ? undefined : toggleTotalCrypto,
      },
    ]
  }

  return (
    <div>
      <HistoryContentContainer>
        {historyOrderLabels(order).map((history_order) => (
          <HistoryTextContainer
            onClick={history_order.onClick}
            style={{
              cursor: history_order.onClick ? 'pointer' : 'default',
            }}
          >
            <HistoryTextLabel>{t(`global:${history_order.label}`)}</HistoryTextLabel>
            <HistoryTextValue>{history_order.value}</HistoryTextValue>
          </HistoryTextContainer>
        ))}
        <br />
      </HistoryContentContainer>

      {order.asset !== 'GRIN' && <CopyAddress trimTo={8} address={order.wallet_address || ''} />}

      {/* // TODO: [!TECH DEPTH] Attach Wizard here... */}
      {error ? (
        <ErrorComponent error={error} reset={() => setError('')} />
      ) : (
        <>
          <ModalButtonsContainer>
            <GreenButton disabled={loading || !shouldRetryFill} onClick={handleFillOrder}>
              Fill
            </GreenButton>
            <RedButton disabled={loading || !shouldRetryCancel} onClick={handleCancelOrder}>
              Cancel
            </RedButton>
          </ModalButtonsContainer>
          <ModalButtonsContainer>
            <BlackButton disabled={loading} onClick={openChat}>
              Chat
            </BlackButton>
          </ModalButtonsContainer>
        </>
      )}
    </div>
  )
}

type SwapOrderItemProps = {
  order: SwapOrder
  onChat: (chat: { account: string; id: string }) => void
  onCancelOrder: (order: SwapOrder) => void
  onFilledOrder: (order: SwapOrder) => void
}

export const SwapOrderItem = ({
  order,
  onChat,
  onCancelOrder,
  onFilledOrder,
}: SwapOrderItemProps) => {
  const [, globalModalActions] = useGlobalModal()
  const seeMore = () => {
    // eslint-disable-next-line no-alert
    globalModalActions.open({
      content: () => (
        // @ts-expect-error
        <AdminActionsProvider>
          <AdminModalContent
            order={getOrderWithFee(order)}
            onChat={onChat}
            onCancelOrder={onCancelOrder}
            onFilledOrder={onFilledOrder}
          />
        </AdminActionsProvider>
      ),
      showClose: true,
      autoHide: false,
    })
  }
  return (
    <CoinOrderContainer onClick={seeMore}>
      <RowContainer>
        <SwapIcon coin={order.asset} />
        <div>
          {/* <Text>{moment(order.updated_at).format('LLLL')}</Text> */}
          <Text color="#787878">{fixedAmountDecimals(order.asset_amount, order.asset)}</Text>
          <BoldText color="#787878">
            {order.order_type?.toUpperCase()} {order.asset}
          </BoldText>
        </div>
      </RowContainer>
      <RowContainer>
        <div>
          <BoldText dollars color="#0F121F">
            ${order.bitcash_amount}
          </BoldText>
          <Text dollars color="#0F121F">
            @ <span>${order.price}</span>
          </Text>
        </div>
      </RowContainer>
    </CoinOrderContainer>
  )
}

const ErrorWrapper = styled.div`
  max-width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`

// TODO: [!TECH DEPTH] Move to global components (use Wizard)
const ErrorComponent = ({ error, reset }: ErrorComponentProps) => (
  <ErrorWrapper>
    <Text as="code">{error}</Text>

    <RedButton
      size="sm"
      onClick={() => {
        reset()
      }}
    >
      Retry
    </RedButton>
  </ErrorWrapper>
)
