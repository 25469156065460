import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  split,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import * as Sentry from '@sentry/react'
import { SentryLink } from 'apollo-link-sentry'
import { config } from 'app-config'
import { resetAllStores } from 'app-engine/store'

const chaingraph_headers = {
  'x-chaingraph-api-key': config.chaingraphKey,
}

const splitWs = (wsLink: WebSocketLink, httpLink: HttpLink) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    httpLink,
  )
}

// Setting http services to the client
const httpLinkBitcash = new HttpLink({
  uri: config.services.bitcashGraphQL,
})

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('bitcash_session')
  // console.log('🍓 token sent in headers', token)
  if (token) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    operation.setContext({
      headers: {
        'x-hasura-role': 'anonymous',
      },
    })
  }
  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  console.log('[Operation Error - Name]:', operation.operationName)
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message.match(/(JWT|JWTError)/g)) {
        const headers = operation.getContext().headers

        // TODO: Check and test this @Nathan...
        const token = localStorage.getItem('bitcash_session')
        operation.setContext({
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        })
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        return forward(operation)
      }
      return forward(operation)
    })
  } else {
    console.log('[GraphQL error]: Message: NO ERRORS')
  }

  if (networkError && networkError.message.match(/(JWT|JWTError)/g)) {
    console.log('[Network error]: ', networkError.message)
  }
})

// Setting websocket services to the client
// https://github.com/hasura/graphql-engine/issues/994
// TODO: review permisions for anonymous role
const wsLinkBitcash = new WebSocketLink({
  uri: config.services.bitcashGraphQL.replace('http', 'ws'),
  options: {
    connectionParams: () => {
      const token = localStorage.getItem('bitcash_session')

      if (!token) return {}

      // console.log('🍓 token sent in websocket headers', token)
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    },
    reconnect: true,
    timeout: 30000,
  },
})

const bitcashLink = splitWs(wsLinkBitcash, httpLinkBitcash)

const httpLinkChainGraph = new HttpLink({
  uri: config.services.chaingraphGraphQL,
  headers: chaingraph_headers,
})

const wsLinkChainGraph = new WebSocketLink({
  uri: config.services.chaingraphGraphQL.replace('http', 'ws'),
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: {
      headers: chaingraph_headers,
    },
  },
})

export const chaingraphLink = splitWs(wsLinkChainGraph, httpLinkChainGraph)

// NOTE: it would be nice to inject context data with graphql codegen instead of naming convention for filtering
const applicationLink = split(
  (op) => {
    // console.log('GraphQL Operation => ', pick(op, 'operationName', 'variables'))
    const isChainGraphOp = op.operationName.includes('ChainGraph')
    return isChainGraphOp
  },
  chaingraphLink,
  bitcashLink,
)

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
  },
}

const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([
    new SentryLink({
      setTransaction: true,
      setFingerprint: true,
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeFetchResult: true,
        includeError: true,
        includeCache: true,
      },
    }),
    errorLink,
    ApolloLink.concat(authMiddleware, applicationLink),
  ]),
  cache: new InMemoryCache({
    addTypename: true,
    typePolicies: {
      Subscription: {
        fields: {
          table_rows: {
            // ref: https://stackoverflow.com/questions/63123558/apollo-graphql-merge-cached-data
            // merge(existing = [], incoming: any) {
            //  return { ...existing, ...incoming };
            merge: false,
          },
        },
      },
    },
    dataIdFromObject(responseObject) {
      switch (
        responseObject.__typename // primary_key
      ) {
        case 'table_rows':
          let uniq
          if (responseObject.data && typeof responseObject.data === 'object') {
            const id = (responseObject.data as any).id
            uniq = id ? `${id}:${responseObject.__typename}` : responseObject.__typename
          }
          return `chain:${responseObject.chain}:contract:${responseObject.contract}:primary_key:${responseObject.primary_key}:scope:${responseObject.scope}:table:${responseObject.table}:${uniq}`
        default:
          return defaultDataIdFromObject(responseObject)
      }
    },
  }),
  defaultOptions,
})

// https://www.apollographql.com/docs/react/caching/advanced-topics#resetting-the-cache
export async function resetCache() {
  try {
    resetAllStores()
    await apolloClient.clearStore()
    console.log('Cache cleared successfully!')
  } catch (error) {
    console.log('Error clearing cache: ', error)

    if (error instanceof Error) {
      const user = localStorage.getItem('bitcashapp-v6')
        ? JSON.parse(localStorage.getItem('bitcashapp-v6') || '{}')
        : {}
      Sentry.captureException({
        ...error,
        name: 'ResetApolloCache',
        message: error.message,
        extras: {
          transactionErrorDetails: { ...error },
        },
        user: {
          username: user.state?.account || 'N/A',
        },
      })
    }
  }
}

export { apolloClient }
