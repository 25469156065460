import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { WizardStepProps } from 'app-view/components/Wizard'
import { SelectStable } from 'pages/P2PView/steps/common/SelectStable'

export const SellStableCoin = ({ next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [offer_state] = useP2POffer()

  // TODO: please check offer.disabled attribute. I think it is not used anymore
  // @ts-ignore TODO: fix types
  const disabledMsg = offer_state.offer.disabled ? 'Not enough balance' : undefined

  return <SelectStable onNext={next} text={t('sell_select_stable')} disabled={disabledMsg} />
}
