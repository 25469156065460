import React from 'react'
import { GreenButton } from 'app-view/components/buttons'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store/index'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { ModalText, ModalButtonsContainer, GrinStatusParagraph } from 'app-view/components/Modal'
import { SwapOrder, getOrderWithFee } from 'app-engine/store/swap-slice'

export const BuyCompleteGrin = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['swap', 'global'])
  const {
    coinSwap: { swap },
  } = useStore()

  const { total_crypto } = getOrderWithFee(swap as SwapOrder)
  const totalToSend = fixedAmountDecimals(total_crypto, swap.asset)

  return (
    <>
      <ModalText>
        {`${t('buy.complete.text1')}
        `}
        <span>{totalToSend}</span>
        {`
        ${t('global:minus_network')}
        ${t('buy.complete.text2')}
        
        `}
      </ModalText>
      <GrinStatusParagraph>{t('buy.complete.text3')}</GrinStatusParagraph>
      <ModalButtonsContainer>
        <GreenButton onClick={close}>{t('ok')}</GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
