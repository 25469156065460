import React from 'react'

const BUSDIcon = (props: any) => (
  <svg viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.392.001C16.194-.08 3.088 3.93.704 17.377c-1.128 6.356-.728 12.516 0 18.893C2.195 49.328 15.23 54.312 27 53.985c11.686-.324 24.766-4.313 26.297-17.715.728-6.377 1.126-12.537 0-18.893C51.207 5.592 39.49.077 28.392 0z"
      fill="#F3BA2F"
    />
    <path
      d="M26.907 14l3.198 3.275-8.053 8.052-3.197-3.198L26.907 14zM31.761 18.855l3.198 3.274-12.907 12.907-3.197-3.198L31.76 18.855zM17.198 23.709l3.198 3.275-3.198 3.198L14 26.984l3.198-3.275zM36.616 23.709l3.198 3.275L26.907 39.89l-3.198-3.198 12.907-12.984z"
      fill="#fff"
      stroke="#fff"
    />
  </svg>
)

export default BUSDIcon
