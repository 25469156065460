// errors.ts
export default {
  bitcashbank_acct_no_valid: 'Không thể hoàn tất thao tác:\nTài khoản không thuộc BitcashBank.',
  bitcashbank_acct_no_registered:
    'Không thể hoàn tất thao tác:\nTài khoản chưa được BitcashBank phê duyệt.',
  unable_find_icon: 'Không thể tìm thấy biểu tượng cho {{icon}}',
  reading_token_precision: 'Lỗi khi đọc độ chính xác của token',
  pair_not_found: 'Không tìm thấy cặp cho {{crypto_token}}/{{stable_token}}',
  position_not_found: 'Không tìm thấy vị trí {{tokenSymbolCode}}',
  token_not_found: 'Không tìm thấy token {{symbolCode}}',
  getting_last_price: 'Có vấn đề khi lấy giá cuối cùng',
  price_not_found: 'Không tìm thấy giá {{symbolCode}}',
  cannot_convert: 'Không thể chuyển đổi {{quantity}} thành {{symbolCode}}',
  key_required: 'Cần ít nhất một khóa',
  getting_escrow_id: 'Lỗi khi lấy id giao dịch p2p từ EOS, vui lòng liên hệ hỗ trợ',
}
