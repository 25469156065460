import React from 'react'
import { useTranslation } from 'react-i18next'
import theme from 'app-config/theme'
import { ExchangeHistoryData } from 'app-engine/store/history-slice'
import {
  ActionContainer,
  BoldText,
  RowContainer,
  RowTextContainer,
  Text,
} from 'app-view/components/Styled'
import { SwapIcon } from 'app-view/components/SwapIcon'
import { get_amount } from 'app-view/lib/utils'
import { useToggle } from 'react-use'
import styled from 'app-view/styles/styled'
import { getCurrencyLabel } from 'app-view/lib/amounts'
import Decimal from 'decimal.js'

export type ExchangeHistoryCardProps = ExchangeHistoryData

const { colors } = theme

type AmountIconProp = {
  coin: string
}
const AmountInIcon = ({ coin }: AmountIconProp) => <SwapIcon style={{ width: 46 }} coin={coin} />
const AmountOutIcon = ({ coin }: AmountIconProp) => <SwapIcon style={{ width: 46 }} coin={coin} />

const ExchangeColum = styled(RowContainer)`
  min-width: 50%;

  > div:first-of-type {
    margin-right: 8px;
    width: min-content;

    svg {
      width: inherit;
      height: inherit;
    }
  }
`

const ExchangeBoldText = styled(BoldText)`
  text-align: left;
`

const ExchangeText = styled(Text)`
  text-align: left;
  white-space: pre-line;
`

export const ExchangeHistoryCard: React.FC<{ actionData: ExchangeHistoryCardProps }> = ({
  actionData: { quantityAmountOut, quantityAmountIn, quantityFee, quantityUnitPrice },
}) => {
  const [showFee, toggleFee] = useToggle(false)
  const { t } = useTranslation(['history'])
  const amountIn = get_amount(quantityAmountIn.toString())
  const symOut = quantityAmountOut.symbol.code.toString()
  const symIn = quantityAmountIn.symbol.code.toString()
  const amountOut = get_amount(quantityAmountOut.toString())
  const fee = `${t('fee')} ${get_amount(quantityFee.toString())}`
  const swapPrice = new Decimal(1).div(quantityUnitPrice.value).toNumber()
  const price = getCurrencyLabel(
    quantityUnitPrice.symbol.code.toString(),
    symOut === 'BITUSD' ? swapPrice : quantityUnitPrice.value,
    swapPrice <= 0.009 ? 6 : 4,
  )

  return (
    <ActionContainer>
      <ExchangeColum>
        <AmountInIcon coin={symIn} />
        <RowTextContainer>
          <BoldText dollars color={colors?.red} style={{ userSelect: 'text' }}>
            {'-'}
            {amountIn}
          </BoldText>
          <BoldText color={colors?.white}>{'-'}</BoldText>
        </RowTextContainer>
      </ExchangeColum>
      <ExchangeColum>
        <AmountOutIcon coin={symOut} />
        <RowTextContainer>
          <ExchangeBoldText dollars color={colors?.black}>
            {amountOut}
          </ExchangeBoldText>
          <ExchangeText dollars color={colors?.black} onClick={() => toggleFee(!showFee)}>
            <span>{showFee ? `@ ${price} - ${fee}` : `@ ${price} …`}</span>
          </ExchangeText>
        </RowTextContainer>
      </ExchangeColum>
    </ActionContainer>
  )
}
