export default {
  heading: 'Welcome to Bitcash App, an easy and secure way to buy and trade quality crypto.',
  t1: 'How do you buy crypto?',
  p1: '• Go to P2P and tap Buy to connect with others to buy bitUSD using Venmo, Paypal or Zelle from others in our community.\n\n• Use bitUSD to trade into other cryptocurrencies.\n\nThat’s it. Preserve your freedom and privacy from big brother. No need to register your ID or SSN. There is no KYC/AML.',
  t2: 'What else can you do with bitUSD?',
  p2: 'You can pay others for goods and services and for everyday use just like US dollars!',
  home_screen: 'You can add Bitcash App to your Home Screen and use it just like a regular app!',
  what_is_web: 'What is a web application?',
  do_it: "let's do it!",
}
