export default {
  recently_trust: 'ha consfiado en vos.', // TODO: translate
  trust_back: 'Do you want to trust back?', // TODO: translate
  chat: 'Chat', // TODO: translate
  untrust_user: 'Untrust User', // TODO: translate
  trusted: 'Confianza', // TODO: translate
  matched: 'Emparejados',
  unmatched: 'Sin emparejar',
  unmatched_user: 'Sin emparejar -  Propias', // TODO: translate
  unmatched_all: 'Sin emparejar - Todas', // TODO: translate
  private_offers: 'Ofertas',
  private_my_offers: 'Mis Ofertas',
  failure_message: 'Mensaje de error',
  error_body: `Tuvimos un inconveniente
  al intentar crear su
  oferta de $.`,
  error_body_payment: `Tuvimos un inconveniente
  al intentar confirmar su
  oferta de $.`,
  error_body_cancel: `Tuvimos un inconveniente
  al intentar cancelar su
  oferta de $.`,
  sell: 'Venta',
  request_success: 'Solicitud enviada con éxito',
  do_you_want: `¿Deseas aceptar la oferta de
  {{cryptoAmount}} {{methodDetails}}?`,
  buy: 'Compra',
  cancelled_offer: 'Oferta cancelada',
  closed_offer: 'Oferta Cerrada',
  open_offer: 'Oferta Abierta',

  clearing_filters: 'Limpiar P2P ofrece filtros...',
  applyng_filters: 'La aplicación de P2P ofrece filtros...',
  clear_filter_btn_text: 'Limpiar filtros',
  select_multiple_currency: 'Seleccione varias monedas',
  payment_method: 'Método de pago',
  prices: 'Precios',

  ovo: 'OVO',
  duitnow: 'DuitNow',
  paypal: 'PayPal',
  oxxo: 'OXXO',
  venmo: 'Venmo',
  cash_app: 'Cash App',
  zelle: 'Zelle',
  bank_transfer: 'Bank Transfer',
  network: 'Red',
  public: 'Público',
  private: 'Privado',
  trust_request: 'Solicitudes',

  offer: 'oferta',
  filter_by_title: 'Filtrar por',
  filter_option_buy: 'Compra',
  filter_option_sell: 'Venta',
  filter_option_recent: 'Reciente',
  filter_option_older: 'Antiguo',
  filter_option_open: 'Activo',
  filterOptionCancelled: 'Cancelado',
  filterOptionCompleted: 'Completado',
  no_offers: 'Actualmente no hay {{filter}} ofertas',
  no_request: 'No hay solicitudes',
  no_accounts: 'No hay cuentas',

  offers: 'Ofertas Completadas',
  mutual: 'Confianza Mutua', // TODO: translate
  amount_to_sell: `¿Cuánto {{symbol}}
  quieres vender?`,
  how_you_want_get_paid: `¿Cómo quieres
  que sea el pago?`,
  sell_confirm:
    'Confirme su oferta de venta de <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo). Usted recibirá <span>{{amount}}</span> usando <span>{{method}}</span>.',
  amount_to_buy: `¿Cuánto {{symbol}}
  quieres comprar?`,
  how_you_want_to_pay: '¿Cómo quieres pagar?',

  buy_confirm: `Confirma tu oferta para comprar
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).
  Usted enviará <span>{{amount}}</span> usando
  <span>{{method}}</span>.`,

  buy_duplicating: `Duplicar oferta de compra de {{method}} de {{amount}}`,
  sell_duplicating: `Duplicar oferta de venta de {{method}} de {{amount}}`,

  from: 'de',
  using: 'usando',
  ok: 'Ok',
  creating_buy_offer: 'Creando oferta de compra',
  'Congrats we have found a match': 'Felicidades, hemos encontrado una coincidencia',
  'wants to sell': 'quiere vender',
  please_contact: 'Por favor contactar',
  'and send': 'y enviar',

  'You are matched': 'Estás emparejado',
  'Did you send': 'Enviaste',
  to: 'a',
  'send you': 'enviarte',

  'You have sent': 'Has enviado',
  'Please remind': 'Por favor recuerda',
  'to confirm receipt of': 'para confirmar la recepción de',
  Contact: 'Contacto',

  Next: 'Siguiente',
  Back: 'Regresar',
  Confirm: 'Confirmar',
  Done: 'Listo',

  'If the problem persists, ask for support on Telegram':
    'Si el problema persiste, solicite soporte en Telegram',

  no_match_yet: '¡Lo sentimos, todavía no hay ninguna coincidencia!',
  will_connect_with_buyer_soon: 'Lo conectaremos con un comprador pronto',
  will_connect_with_seller_soon: 'Lo conectaremos con un vendedor pronto',
  check_back_in_few_minutes: 'Por favor verifique nuevamente en unos minutos',

  confirming_payment: 'Confirmando pago',
  Not_yet: 'No aún',
  Yes: 'Sí',
  No: 'No',
  Retry: 'Reintentar',
  on: 'el',

  'Work in progress!': '¡Trabajo en progreso!',
  'Sale complete': 'Venta completada',
  'You have sold': 'Has vendido',
  creating_sell: 'Creando oferta de venta',
  'Congrats we have found a match!': '¡Felicidades, hemos encontrado una coincidencia!',
  'wants to buy': 'quiere comprar',
  'Please ask': 'Por favor pregunte a',
  'to send you': 'enviarte',
  'Did you receive': '¿Recibiste',
  zero_balance: 'No tienes suficiente saldo de {{symbol}}',

  confirmed_uncompleted_offer: 'Confirmado Oferta incompleta',
  confirmed_uncompleted_offer_error: 'Error de confirmación oferta incompleta',
  on_chain_matching_preoffer_complete: 'En cadena coincidente preoferta completa',
  on_chain_matching_preoffer_start: 'En cadena coincidente preoferta inicio',
  on_chain_matching_preoffer_timeout_error:
    'En cadena coincidente preoferta tiempo de espera error',
  unconfirmed_offer_12h: 'Oferta no confirmada 12h',
  unconfirmed_offer_12h_error: 'Error de oferta no confirmada 12h',
  unexpected_error: 'Error inesperado',
  unmatched_offer_48h: 'Oferta sin emparejar 48h',
  unmatched_offer_48h_error: 'Error de oferta sin emparejar 48h',
  unprocessed_indexed_confirm_offer: 'Oferta de confirmación indexada no procesada',
  unprocessed_indexed_offer: 'Oferta indexada no procesada',
  cancel_by_request: 'Cancelado por solicitud',
  failed_to_complete_offer: 'Error al completar la oferta',

  confirmed_with: 'Has confirmado con',
  but_did_not_send: 'pero no enviado',
  'but have not': 'pero no se ha',
  would_cancel_offer: `¿Le gustaria cancelar
  la oferta?`,
  not_now: 'Ahora no',
  cancel_request_by: 'Cancelar solicitud por',
  order_cancelled: 'Su orden ha sido cancelada.',
  'Did you contact': '¿Te pusiste en contacto',
  'Did you confirm sale with': '¿Confirmaste la venta con',
  we_are_sorry: 'Lo sentimos',
  no_longer_wants_to_sell: 'ya no quiere vender',
  'Would you like to keep this offer': '¿Le gustaría mantener esta oferta?',
  'and find a new match': 'y encuentra una nueva persona interesada',
  no_longer_wants_to_buy: 'ya no quiere comprar',
  'You have cancelled your offer to buy': 'Haz cancelado tu oferta para comprar',
  you_cancelled_offer_to_sell: 'Haz cancelado tu oferta para vender',
  'Offer has been cancelled to buy': 'La oferta ha sido cancelada para comprar',
  offer_been_cancelled_to_sell: 'La oferta ha sido cancelada para vender',
  confirmed_sending_you: 'ha confirmado enviarte',
  bank_transfer_region_label: ' región.',
  bank_transfer_region: ' en {{region}}{{isRegion}}',
  push_transaction_error_action_cancel: 'cancelar tu',
  push_transaction_error_action_confirm: 'confirmar tu',
  push_transaction_error_action_sell: 'crear una nueva',
  buy_matched_alt_offer: `<span>{{seller}}</span> quiere vender
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo).

  ¿Te pusiste en contacto con <span>{{seller}}</span>
  y confirmaste la compra?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> quiere vender
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Por favor contactar a <span>{{seller}}</span>
  y enviar <span>{{amount}}</span> usando <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_matched_offer: `
  Felicidades hemos encontrado una coincidencia!
  <span>{{seller}}</span> quiere vender
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Por favor contactar a <span>{{seller}}</span>
  y enviar <span>{{amount}}</span> usando <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_payment: `Estás emparejado.
  <span>{{seller}}</span> quiere vender
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Enviaste <span>{{amount}}</span> a
  <span>{{seller}}</span> usando <span>{{method}}</span>
  {{methodDetails}}?

  `,
  buy_payment_confirmed: `Has enviado <span>{{amount}}</span> a
  <span>{{seller}}</span> usando <span>{{method}}</span>
  {{methodDetails}}

  Por favor recuerda a <span>{{seller}}</span>
  confirmar la recepción de <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo) usando <span>{{method}}</span>
  {{methodDetails}}
  Cancelado el {{date}}`,
  sell_cancel_deny: `Lo sentimos,
  <span>{{buyer}}</span> ha confirmado enviarte <span>{{amount}}</span> (+{{cryptoFee}} de cargo) usando <span>{{method}}</span>
  {{methodDetails}}

  Por favor contactar a <span>{{buyer}}</span>.

  `,
  sell_matched_alt_offer: `<span>{{buyer}}</span> quiere comprar
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo).

  ¿Confirmaste la venta con
  <span>{{buyer}}</span>?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> quiere comprar
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  Por favor pide a <span>{{buyer}}</span>
  enviarte <span>{{amount}}</span> usando <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_matched_offer: `
  {t('p2p:Congrats we have found a match!')}
  <span>{{buyer}}</span> {t('p2p:wants to buy')}
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  {t('p2p:Please ask')} <span>{{buyer}}</span> {t('p2p:to send you')}
  <span>{{amount}}</span> {t('p2p:using')} <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_payment: `Estás emparejado.
  <span>{{buyer}}</span> quiere comprar <span>{{cryptoAmount}}</span> (+{{cryptoFee}} de cargo).

  ¿Recibiste <span>{{amount}}</span> de
  <span>{{buyer}}</span> usando <span>{{method}}</span>
  {{methodDetails}}?

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} de cargo) usando <span>{{method}}</span>
  {{methodDetails}}
  Cancelado el {{date}}`,
  accept: 'Aceptar',
  reject: 'Eliminar',
  loader: 'cargando lista de redes de confianza...',
  select_region_country_search: `Por favor, seleccione la región/país o
  escríbalo para la búsqueda.`,
  sell_select_stable: `Que le gustaría
  vender?`,
  buy_select_stable: `Que le gustaría
  comprar?`,
  sell_now: 'Vender ahora',
  buy_now: 'Comprar ahora',

  purchase_completed: `Compra completada.
    Has comprado <span>{{amount}}</span> (+{{cryptoFee}} comisión)
    de <span>{{seller}}</span> usando
    <span>{{method}}</span> {{sellerMethodDetails}} en {{date}}.

    {{fundsSent}}
    {{methodDetails}}`,
  sell_complete: `Venta completada.
    Has vendido <span>{{amount}}</span> (+{{cryptoFee}} comisión)
    a <span>{{buyer}}</span> usando <span>{{method}}</span>
    {{buyerMethodDetails}} en {{date}}.

    {{fundsReceived}}
    {{methodDetails}}`,

  funds_sent: 'Has enviado los fondos ',

  seller_method_phone_detail_label: `al número de teléfono #
  <span class="p2p-method-detail-phone" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span> {{region}}`,
  seller_method_bank_detail_label: `a la cuenta # <span>{{fullName}}</span> en el banco <span>{{accountName}}</span>
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  seller_method_account_detail_label: `a la cuenta # <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  seller_method_phone_name_detail_label: `a <span>{{fullName}}</span> con el número de teléfono #
  <span class="p2p-method-detail-account" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_phone_detail_label: `desde el número de teléfono #
  <span class="p2p-method-detail-phone" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span> {{region}}`,
  buyer_method_bank_detail_label: `desde la cuenta # <span>{{fullName}}</span> en el banco <span>{{accountName}}</span>
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_account_detail_label: `desde la cuenta # <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_phone_name_detail_label: `desde <span>{{fullName}}</span> con el número de teléfono #
  <span class="p2p-method-detail-account" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span>
  {{region}}`,

  seller_method_details: 'Por favor, proporcione su información bancaria para recibir los fondos.',
  seller_method_details_name_account:
    'Por favor, proporcione el nombre y la cuenta para recibir los fondos.',
  seller_method_details_phone: `Por favor, proporcione el número de teléfono
  para recibir los fondos.`,
  seller_method_details_account: `Por favor, proporcione el número de cuenta
  para recibir los fondos.`,
  buyer_method_details: 'Por favor, proporcione su información bancaria para enviar los fondos.',
  buyer_method_details_name_account:
    'Por favor, proporcione el nombre y la cuenta para enviar los fondos.',
  buyer_method_details_phone: `Por favor, proporcione el número de teléfono
  para enviar los fondos.`,
  buyer_method_details_account: `Por favor, proporcione el número de cuenta
  para enviar los fondos.`,

  copied: 'Copiado Exitósamente.',

  bank_name_label: 'Nombre banco',
  full_name_label: 'Nombre completo',

  show_more: '<b class="p2p-show-details" id="show-more-details">… mostrar más</b>',
  show_less: '<b class="p2p-show-details" id="show-less-details"> mostrar menos</b>',
}
