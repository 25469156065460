import { ChevronRightIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { motion } from 'framer-motion/dist/framer-motion'
import React, { useRef } from 'react'

type LoginItemTypes = {
  isPrevUser?: boolean
  isMoreOption?: boolean
  secondary: boolean
}

const Label = styled.div<{ oneLine?: boolean }>`
  white-space: pre-line;
  text-align: left;
  line-height: 1.5;

  span:first-of-type {
    font-size: ${({ oneLine }) => (oneLine ? '0.875rem' : '0.625rem')};
    line-height: ${({ oneLine }) => (oneLine ? 1.33 : 0.95)};
    font-weight: ${({ oneLine }) => (oneLine ? 800 : 400)};
  }
`
const LoginItem = styled(motion.button)<LoginItemTypes>`
  transition: all 0.24s ease-in-out;
  border-color: transparent;
  min-width: 100%;
  width: 100%;
  line-height: 1.5em;
  background: ${({ isPrevUser }) => (isPrevUser ? '#29e085bf' : '#40424f')};
  height: ${({ secondary }) => (secondary ? '50px' : '60px')};
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  padding: 8px 20px;

  &:hover,
  &:active,
  &:focus,
  &:focus-within {
    background: ${({ isPrevUser }) => (isPrevUser ? '#29e085' : '#40424fbf')};
  }

  > span {
    text-align: center;
    justify-content: center;
    align-content: center;
    display: flex;
    align-items: center;

    &:nth-of-type(1) {
      justify-content: flex-end;

      > div {
        font-size: ${({ secondary }) => (secondary ? '0.875rem' : '1.063rem')};
        text-transform: capitalize;
        font-weight: bold;
        padding-left: 0.5em;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }
    }
    &:nth-of-type(2) {
      justify-content: flex-end;
      flex-grow: 1;
      padding-left: 5%;
      font-size: 1.5em;

      svg,
      path {
        height: 24px;
        width: 16px;
        transition: 0.14s all ease-in-out;
        stroke: white;
        stroke-width: 64px;
      }
    }
  }
`

const ChevronWrapper = styled(motion.span)`
  transform-origin: center center;
  display: flex;
  align-items: center;
  justify-content: center;
`

export interface AccessViewButtonProps extends React.HtmlHTMLAttributes<HTMLButtonElement> {
  // eslint-disable-next-line no-undef
  Icon?: (props?: any) => JSX.Element
  label: string
  chevron?: boolean
  animate?: any
}

export const AccessViewButton: React.FC<AccessViewButtonProps & LoginItemTypes> = ({
  onClick,
  Icon,
  label,
  chevron = true,
  animate = {},
  isMoreOption,
  isPrevUser,
  secondary,
}) => {
  const labelRef = useRef<HTMLDivElement | null>(null)
  const oneLine = labelRef?.current?.children.length === 1

  return (
    <LoginItem
      onClick={onClick}
      isMoreOption={isMoreOption}
      secondary={secondary}
      isPrevUser={isPrevUser}
    >
      <span>
        {Icon && <Icon width="36px" height="36px" style={{ marginRight: 20 }} />}
        <Label dangerouslySetInnerHTML={{ __html: label }} oneLine={oneLine} ref={labelRef} />
      </span>
      {chevron && (
        <span>
          <ChevronWrapper {...animate}>
            <ChevronRightIcon />
          </ChevronWrapper>
        </span>
      )}
    </LoginItem>
  )
}
