export default {
  fullscreen: 'Layar Penuh',
  language: 'Bahasa',
  primary_currency: 'Mata Uang Utama',
  notifications: 'Izinkan Notifikasi',
  deviceKeys: 'Daftar Perangkat',
  support: 'Chat Dukungan',
  about: 'Tentang bitcash',
  api_status: 'Status Koneksi',
  logout: 'Keluar',
  referralLinkLabel: 'Link Referral',
  referralLinkSubLabel: 'Bagikan link ini untuk mendapatkan token bitcash',
  copiedLinkBtnLabel: 'Tersalin ✓',
  copyLinkBtnLabel: 'Salin Link',
  devices: 'Perangkat',
  no_devices: 'Anda tidak memiliki perangkat',
  delete_device: 'Apakah Anda ingin menghapus perangkat ini?',
  keys: 'Kunci',
  no_keys: 'Anda tidak memiliki kunci',
  delete_key: 'Apakah Anda ingin menghapus kunci ini?',
  delete: 'Hapus',
  loading_devices_keys: 'Memuat Perangkat dan Kunci',
  enter_public_key: 'Silakan masukkan kunci publik',
  enter_valid_public_key: 'Silakan masukkan kunci publik yang valid',
  public_key_modal_title:
    'Masukkan kunci publik untuk menambahkan sebagai metode tanda tangan yang valid ke akun Anda',
  public_key: 'Kunci Publik',
  add_key: 'Tambah kunci',
  this_session: 'Sesi ini',
  profile: 'Opsi Profil',
  key_recovery: 'Pemulihan Kunci',
  add_partners: 'Tambahkan Mitra',
  update_partner: 'Perbarui Mitra',
  generating_recovery_keys: 'Menghasilkan ID Pemulihan',
  id_type: 'Jenis ID',
  country: 'Negara',
  not_found: 'akun bitcash tidak ditemukan',
  partners_recommended: '3 mitra minimum direkomendasikan',
  dao_recovery: 'Pemulihan DAO',
  info_primary:
    'Mitra Pemulihan adalah orang yang Anda percayai untuk mengidentifikasi dan memverifikasi Anda jika Anda kehilangan ponsel atau perangkat dan perlu memulihkan akun Anda.',
  info_secondary: 'Silakan masukkan akun bitcash dari masing-masing Mitra Pemulihan Anda.',
  info_tertiary:
    'Pemulihan DAO memungkinkan organisasi bitcash untuk bertindak sebagai salah satu Mitra Pemulihan Anda.',
  warning: 'Peringatan',
  warning_secondary:
    'Jika Anda ingin menghapus/menambahkan mitra apa pun, Anda harus melalui proses pemulihan.',
  recovery_partner: 'Mitra Pemulihan',
  agree: 'Saya setuju untuk menambahkan mitra ini untuk proses pemulihan kunci saya',
  Submit: 'kirim',
  date_of_birth: 'Tanggal Lahir',
  register_ip: 'IP Pendaftaran',
}
