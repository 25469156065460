import React, { useEffect, useMemo, useState } from 'react'
import styled from 'app-view/styles/styled'
import {
  NumberFormatValues,
  TokenQuantityMaskedInput,
} from 'app-view/components/TokenQuantityMaskedInput'
import { CoinSelector } from 'app-view/components/CoinSelector'
// TODO: Reemplace with @greymass/eosio... use Asset.from instead?
import { stringToAsset } from 'app-engine/library/eosio/utils'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { InputSection, InputWrapper, Label } from 'app-view/components/InputField'
import { useEffectOnce } from 'react-use'
import { CoinSelectorWrapper } from 'app-view/components/Styled'
import { useWallet, UseWalletActions } from 'pages/WalletView/hooks/useWallet'
// TODO: Reemplace with @greymass/eosio
import { asset } from 'eos-common'
import { showTokenSymbol } from 'app-engine/library/tokens'
import { useTranslation } from 'react-i18next'
import { InputHandlerProps } from 'pages/WalletView/steps/send-steps/SendToAccount'
import { useBankWallet, UseBankWalletActions } from 'pages/BankView/hooks/useBankWallet'
import { useStore } from 'app-engine/store'
import { static_token_data, TokenData } from 'app-engine/store/token-slice'
import { config } from 'app-config'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'
import { UserPosition, useUserPositions } from 'app-view/hooks/use-user-positions'

interface CoinAmountProps {
  label: string
  inputProps?: InputHandlerProps
  isSend?: boolean
  isWallet?: boolean
  isBank?: boolean
  coinOptions?: string[]
  lastUsedToken?: string
}

interface WithdrawLimit {
  min: number
  max: number
  limit: number
  fee: number
}

// TODO: FIX INDEXER FIRST, in order to get limits table correctly, it is returning primary_key as undefined => p_key: max_range::extended_quantity_value
// const getWithdrawLimits = async () => await apolloClient.query<
//   ChainGraph.GetChainGraphTableRowsQuery,
//   ChainGraph.GetChainGraphTableRowsQueryVariables
// >({
//   query: ChainGraph.GetChainGraphTableRowsDocument,
//   variables: {
//     where: {
//       chain: {
//         _eq: config.eosChainName,
//       },
//       contract: {
//         _eq: config.contracts.bitcashBank,
//       },
//       table: {
//         _eq: 'limits',
//       },
//     },
//   },
// })

const withdrawLimits: WithdrawLimit[] = [
  { min: 0, max: 50, limit: 5, fee: 0.1 },
  { min: 50, max: 100, limit: 10, fee: 0.2 },
  { min: 100, max: 250, limit: 25, fee: 0.4 },
  { min: 250, max: 500, limit: 50, fee: 0.8 },
  { min: 500, max: 1000, limit: 100, fee: 1 },
  { min: 1000, max: 2500, limit: 250, fee: 2.5 },
  { min: 2500, max: 5000, limit: 500, fee: 2.5 },
  { min: 5000, max: 10000, limit: 1000, fee: 2.5 },
  { min: 10000, max: 25000, limit: 2500, fee: 2.5 },
  { min: 25000, max: 50000, limit: 5000, fee: 5 },
  { min: 50000, max: 100000, limit: 10000, fee: 5 },
]

const StyledTokenQuantityMaskedInput = styled(TokenQuantityMaskedInput)`
  height: 100%;
  border-left: 1px solid #eae7e7 !important;
  border-radius: 0 10px 10px 0 !important;
  max-width: 50%;
  width: 100%;
`
// NOTE: Not good idea, but it is extensive to transfer this to a zustand state...
// TODO: Transform to zustand slice state...

const coinOptions = (
  tokenData: TokenData[],
  isSend?: boolean,
  isWallet?: boolean,
  isBank?: boolean,
) => {
  const { usdtDepositOnly } = config.features
  if (isWallet && usdtDepositOnly && !isSend) return ['USDT']
  if (isBank && usdtDepositOnly && !isSend) return ['BITUSD']

  const reg_exp_chk = !isSend && isWallet ? /(BIT)/ : /(USDT|EOS)/
  const sort_chk = isSend ? 'USDT' : 'EOS'
  const token_data_str_arr = tokenData?.map(({ token_symbol }) => token_symbol.code().toString())
  const opts =
    token_data_str_arr.filter((tkn) => !tkn.match(isSend && isWallet ? /BIT/ : reg_exp_chk)) || []

  // make usdt the first option
  return opts.sort((coin) => (coin === sort_chk ? -1 : 0))
  // eslint-disable-next-line react-hooks/exhaustive-deps
}

const amount_controller = (
  { value }: NumberFormatValues,
  input_props: InputHandlerProps | undefined,
  action: UseBankWalletActions | UseWalletActions,
  token: TokenData,
) => {
  let amount_value = stringToAsset('0', token)

  try {
    // * If the value is not a valid number, then this will fail and will fallback to the default value until a valid value is set
    amount_value = stringToAsset(value, token)
  } catch (error) {
    console.log('ERROR@amount_controller ==>', (error as Error).message)
  } finally {
    // @ts-expect-error
    action.updateAction({ amount: amount_value })
    input_props?.form.setValue(input_props.field, amount_value)
    action.setReady(Boolean(input_props?.form.getValues('receiver')) && Boolean(value))
  }
}

const withdraw_limit = (position: UserPosition) => {
  const value =
    typeof position.usd_value === 'number'
      ? position.usd_value
      : position.usd_value.amount.toJSNumber() / 100 || 0
  const limit = withdrawLimits.find((p) => p.min <= value && p.max >= value)
  if (!limit) throw new Error('Balance exceed valid range')
  return limit
}

export const WalletCoinAmount: React.FC<CoinAmountProps> = ({
  label,
  inputProps,
  isSend,
  isWallet,
  ...props
}) => {
  const { t } = useTranslation('wallet')
  const [coin, setCoin] = useState('USDT')
  const { tokenData } = useStore()
  const token = findTokenBySymbolCode(coin)
  const [userInteract, setUserInteract] = useState(false)
  const [, walletActions] = useWallet()

  useEffectOnce(() => {
    // @ts-expect-error
    walletActions.updateAction({ amount: asset(0, token.token_symbol) })
  })

  useEffect(() => {
    walletActions.updateAmountSymbol(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const coinOpt = useMemo(() => coinOptions(tokenData, isSend, isWallet), [tokenData])

  const coinSelectorAttr = {
    setCoin,
    coinOpt,
    indicatorProps: {
      screen: 'wallet' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract,
    setUserInteract,
    coin,
  }

  const coin_code = showTokenSymbol(coin)
  const amount_placeholder = t('amount_placeholder', { coin_code })

  return (
    <InputWrapper>
      <Label
        size="md"
        htmlFor="deposit-amount-input"
        style={{ width: '100%', textAlign: 'center' }}
      >
        {label}
      </Label>
      <InputSection error={false}>
        <CoinSelectorWrapper>
          <CoinSelector {...coinSelectorAttr} />
        </CoinSelectorWrapper>

        <StyledTokenQuantityMaskedInput
          {...props}
          token={coin}
          id="deposit-amount-input"
          onBlur={() => window.scroll(0, 0)}
          onValueChange={(e: NumberFormatValues) =>
            amount_controller(e, inputProps, walletActions, token)
          }
          placeholder={amount_placeholder}
          autoComplete="off"
        />
      </InputSection>
    </InputWrapper>
  )
}

// Necessary so CoinAmount wont crash when we are missing bank provider at wallet or wallet provider at bank...
// TODO: Turn this to zustand slice state...
export const BankCoinAmount: React.FC<CoinAmountProps> = ({
  label,
  inputProps,
  isSend,
  isBank,
  lastUsedToken,
  ...props
}) => {
  const { t } = useTranslation('wallet')
  const [coin, setCoin] = useState(lastUsedToken || 'USDT')
  const userPositionsHook = useUserPositions()
  const user_positions = userPositionsHook.user_positions
  const tokenData = static_token_data
  const findUserPosition = userPositionsHook.findUserPosition

  const token = findTokenBySymbolCode(coin)
  const [userInteract, setUserInteract] = useState(false)
  const [, BankActions] = useBankWallet()

  useEffectOnce(() => {
    BankActions.updateAction({ amount: asset(0, token?.token_symbol) })
  })

  const coinOpt = useMemo(
    () =>
      coinOptions(tokenData, isSend, false, isBank).filter((coin) =>
        user_positions.get(coin.toUpperCase()),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tokenData, user_positions],
  )

  const coinSelectorAttr = {
    setCoin,
    coinOpt,
    indicatorProps: {
      screen: 'bank' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract,
    setUserInteract,
    initialIndex: 0, // USDT as default
    lastUsedToken,
    coin,
  }

  const coin_code = showTokenSymbol(coin)
  const amount_placeholder = t('amount_placeholder', { coin_code })

  useEffect(() => {
    try {
      const user_position = findUserPosition(coin)
      const limit = withdraw_limit(user_position)
      BankActions.updateAction({ limit: limit.limit, fee: limit.fee })
    } catch {
      BankActions.updateAction({ limit: 0, fee: 0 })
    }
  }, [coin, BankActions, findUserPosition])

  useEffect(() => {
    if (coin) {
      localStorage.setItem('bitcash:last_crypto_to', coin)
    }
  }, [coin])

  return (
    <InputWrapper>
      <Label
        size="md"
        htmlFor="withdraw-amount-input"
        style={{ width: '100%', textAlign: 'center' }}
      >
        {label}
      </Label>
      <InputSection error={false}>
        <CoinSelectorWrapper>
          <CoinSelector {...coinSelectorAttr} />
        </CoinSelectorWrapper>

        <StyledTokenQuantityMaskedInput
          {...props}
          token={coin}
          id="withdraw-amount-input"
          onBlur={() => window.scroll(0, 0)}
          onValueChange={(e: NumberFormatValues) =>
            amount_controller(e, inputProps, BankActions, token)
          }
          placeholder={amount_placeholder}
          autoComplete="off"
        />
      </InputSection>
    </InputWrapper>
  )
}
