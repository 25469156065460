import { WizardStepProps } from 'app-view/components/Wizard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MethodDetails } from 'pages/P2PView/steps/common/MethodDetails'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'

export const SellMethodDetails = ({ prev, next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }] = useP2POffer()

  const text = () => {
    switch (offer.method) {
      case 'bank_transfer':
        return t('buyer_method_details')
      case 'kuda':
        return t('buyer_method_details_account')
      case 'oxxo':
      case 'palmpay':
      case 'opay':
        return t('buyer_method_details_name_account')
      default:
        return t('buyer_method_details_phone')
    }
  }

  return <MethodDetails onPrev={prev} onNext={next} text={text()} />
}
