import React from 'react'
import {
  NumericFormat,
  NumericFormatProps,
  NumberFormatValues,
  PatternFormat,
} from 'react-number-format'
import { useTranslation } from 'react-i18next'
import styled from 'app-view/styles/styled'
import { useStore } from 'app-engine/store'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'

export const StyledNumericFormat = styled(NumericFormat)`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.66;
  font-weight: 400;
  text-align: center;
  border: none !important;
`
export const StyledInputPatternFormat = styled(PatternFormat)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  line-height: 1.66;
  font-weight: 400;
  text-align: center;
  border: none !important;
`
export type { NumberFormatValues } from 'react-number-format'
export interface TokenQuantityMaskedInputProps extends NumericFormatProps {
  token: string // token is necessary to know precision.
  onValueChange: (props: NumberFormatValues) => void
}

export const TokenQuantityMaskedInput: React.FC<TokenQuantityMaskedInputProps> = ({
  token,
  onValueChange,
  ...props
}) => {
  const { t } = useTranslation('errors')
  const precision = findTokenBySymbolCode(token)?.token_symbol?.precision()
  if (precision === undefined) throw new Error(t('reading_token_precision'))

  return (
    <StyledNumericFormat
      thousandSeparator
      valueIsNumericString
      decimalScale={precision}
      onValueChange={onValueChange}
      {...(props as React.ComponentType)}
    />
  )
}
