import { StoreSlice } from 'app-engine/store'
import { pushTransactionWebAuthN } from 'app-engine/library/eosio'
import * as Sentry from '@sentry/react'
import { AnchorError, WebAuthError } from 'app-engine/library/errors'
import { AuthType } from 'app-engine/store/auth-slice'
import { TransactResult } from 'anchor-link'
import { newAnchorLink } from 'pages/AccountView/utils'

const default_eos_state = {}

export const createEOSSlice: StoreSlice<EOSSlice> = (set, get) => ({
  ...default_eos_state,
  reset: () => {
    set(default_eos_state)
  },
  pushTransaction: async (transaction, skipTokenCheck = false) => {
    const { anchorLink, devices, authType, pub_key, account, refreshSession } = get()

    let response: any

    try {
      const isValidToken = skipTokenCheck || (await refreshSession())
      if (!isValidToken) throw new Error('Invalid token.')
      // console.log('🟡 pushTransaction::transaction-data', JSON.stringify(transaction, null, 2))
      switch (authType) {
        case AuthType.WEBAUTHN:
          console.info('Attempting to sign with WebAuthn')
          const device = devices.find((d) => d.public_key === pub_key?.toString())

          if (!device) throw new WebAuthError('Error reading device to request signature')

          response = await pushTransactionWebAuthN({
            actions: transaction.actions,
            public_key: device.public_key,
            cred_id: device.cred_id,
          })
          break

        case AuthType.ANCHOR:
          const currentAnchorLink = anchorLink || newAnchorLink

          console.info('⚓ ⚓ ⚓ Attempting to sign with Anchor ⚓ ⚓ ⚓')

          if (!anchorLink) {
            console.error('⚓ ⚓ ⚓ ⚓ AnchorLink not found ⚓ ⚓ ⚓ ⚓')
          }

          const session = await currentAnchorLink.restoreSession('bitcash_app')

          if (!session) {
            const identity = await currentAnchorLink.login('bitcash_app')
            if (!identity.signer.actor.equals(account)) throw new Error('Invalid account')
          }
          response = await currentAnchorLink.transact({ actions: transaction.actions })
          break
        case null:
        default:
          throw new Error('auth type cache missing. login and retry')
      }

      const transaction_id = response?.transaction?.id?.toString() || response.transaction_id

      // TODO: remove logs
      console.log('🟣 pushTransaction::transaction_id', transaction_id)
      console.info('🟢 pushTransaction::response', response)

      return response
    } catch (error) {
      const errorMessage = (error as Error)?.message
      const message =
        (error as any)?.response?.json?.error?.details[0]?.message?.replace(
          'assertion failure with message: ',
          '',
        ) || errorMessage

      console.error('❌ error transactionErrorDetails ❌', error)
      console.error('❌ transactionResponse ❌', { response })

      if (error instanceof Error) {
        Sentry.captureException({
          ...error,
          name: 'PushTransactionError',
          message,
          extras: {
            transactionErrorDetails: { ...error },
            transactionResponse: response,
          },
          user: {
            username: get().account,
          },
        })
      }

      if (message) {
        throw new Error(message)
      } else {
        throw error
      }
    }
  },
})

// ? Not exporting from @greymass/eosio... Probably @wharfkit/eosio does
export interface SendTransaction2Response {
  transaction_id: string
  processed: {
    id: string
    block_num: number
    block_time: string
    receipt: {
      status: string
      cpu_usage_us: number
      net_usage_words: number
    }
    elapsed: number
    net_usage: number
    scheduled: boolean
    action_traces: any[]
    account_ram_delta: any
  }
}

export type EOSSlice = {
  pushTransaction: (
    transaction: any,
    skipTokenCheck?: boolean,
  ) => Promise<TransactResult | SendTransaction2Response>
  reset: () => void
}
