import theme from 'app-config/theme'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { FlexBox } from 'app-view/components/Styled'
import { WizardProvider, useWizard } from 'app-view/components/Wizard'
import { FilterIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TakeOfferProvider } from '../hooks/useTakeOffer'
import { OPEN_OFFER_FILTER_STEPS } from '../steps/open-offer-filter'
import { OfferListSkeleton } from './OfferListSkeleton'
import { OfferTrustGlobalNetwork } from './OfferTrustGlobalNetwork'
import { OpenOfferFilterWizard } from './OpenOfferFilterWizard'
import {
  ListTitle,
  NoItems,
  OfferContainer,
  offerAnimationVariants,
  useGroupSimilarOffers,
} from './Shared'
import { TakeOfferWizard } from './TakeOfferWizard'

export const Badge = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 0.6rem;
  line-height: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.darkGray};
  border-radius: 50%;
  transform: translate(50%, -50%);
  padding: 1px;
  color: ${theme.colors.white};
`

type P2POffersTrustGlobalNetworkProps = {
  title: string
  offers: P2POffer[]
}

export type ModalContentProps = Pick<P2POffersTrustGlobalNetworkProps, 'offers'>

export type P2PMutualRelationship = {
  account: string
  mutual: boolean
}
const FilterButton = () => {
  const [{}, { start }] = useWizard()
  const { isApplied, currencies, prices, paymentMethods } = useStore()
  const [filterCount, setFilterCount] = useState<number | null>(null)

  useEffect(() => {
    if (isApplied) {
      if (currencies.length > 1) {
        setFilterCount(currencies.length + paymentMethods.length)
      } else {
        setFilterCount(currencies.length + paymentMethods.length + prices.length)
      }
    }
  }, [isApplied, currencies, prices, paymentMethods])

  return (
    <div style={{ position: 'relative' }}>
      <FilterIcon
        onClick={() => {
          start(OPEN_OFFER_FILTER_STEPS)
        }}
        style={{ cursor: 'pointer', fill: '#757575' }}
      />
      <OpenOfferFilterWizard />
      {isApplied && <Badge>+{filterCount}</Badge>}
    </div>
  )
}

export const P2POffersTrustGlobalNetwork: React.FC<P2POffersTrustGlobalNetworkProps> = ({
  title,
  offers,
}) => {
  const { t } = useTranslation(['p2p'])
  const processedOffers = useGroupSimilarOffers(offers)

  return (
    <OfferContainer>
      {/* @ts-expect-error */}
      <WizardProvider>
        <FlexBox flexDirection="row" justifyContent="space-between" alignItems="flex-start">
          <ListTitle color={theme.colors?.darkGray}>{title}</ListTitle>
          <FilterButton />
        </FlexBox>
      </WizardProvider>
      {/* @ts-expect-error */}
      <WizardProvider>
        {/* @ts-expect-error */}
        <TakeOfferProvider>
          <AnimatePresence>
            {processedOffers?.length ? (
              processedOffers.map((offer) => (
                <motion.div
                  key={offer.id}
                  variants={offerAnimationVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <OfferTrustGlobalNetwork offer={offer} />
                </motion.div>
              ))
            ) : (
              <motion.div
                key="placeholder"
                variants={offerAnimationVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                {!processedOffers ? (
                  <OfferListSkeleton />
                ) : (
                  <NoItems>{t('no_offers', { filter: '' })}</NoItems>
                )}
              </motion.div>
            )}
          </AnimatePresence>
          <TakeOfferWizard />
        </TakeOfferProvider>
      </WizardProvider>
    </OfferContainer>
  )
}
