import { CancelButton } from 'app-view/components/buttons'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useStore } from 'app-engine/store'
import { useWizard, WizardStepProps } from 'app-view/components/Wizard'
import { ModalText, ModalButtonsContainer, ModalStepGreenButton } from 'app-view/components/Modal'

export const NoMatch = ({ close, next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [, { goTo }] = useWizard()
  const [{ offer }] = useP2POffer()
  const account = useStore.useAccount()
  const isBuyer = offer.buyer === account

  // skip to SellOfferMatched and BuyOfferMatched
  useEffect(() => {
    if (offer.matched) next()
  }, [offer, next])

  const onCancel = () => {
    if (offer.type === 'sell' && offer.buyer_confirmed_payment) {
      return goTo('cancel-deny')
    }

    return goTo('cancel')
  }

  const connect_soon = isBuyer ? 'will_connect_with_seller_soon' : 'will_connect_with_buyer_soon'

  return (
    <>
      <br />
      <br />
      <ModalText>
        {`${t('no_match_yet')}

        ${t(connect_soon)}.
        ${t('check_back_in_few_minutes')}.`}
      </ModalText>
      <br />
      <br />
      <br />
      <ModalButtonsContainer cancel>
        <ModalStepGreenButton onClick={close}>{t('ok')}</ModalStepGreenButton>
        <CancelButton onClick={onCancel}>{t('global:cancel')}</CancelButton>
      </ModalButtonsContainer>
    </>
  )
}
