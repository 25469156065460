import React from 'react'

const ClipboardIcon = (props: any) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" {...props}>
    <path
      d="M11.25 0H0.46875C0.21 0 0 0.21 0 0.46875V11.25C0 11.5087 0.21 11.7188 0.46875 11.7188H1.875V10.7812H0.9375V0.9375H10.7812V1.875H11.7188V0.46875C11.7188 0.21 11.5087 0 11.25 0Z"
      fill="#A4A4A4"
    />
    <path
      d="M14.5312 2.8125H3.28125C3.0225 2.8125 2.8125 3.0225 2.8125 3.28125V14.5312C2.8125 14.79 3.0225 15 3.28125 15H14.5312C14.79 15 15 14.79 15 14.5312V3.28125C15 3.0225 14.79 2.8125 14.5312 2.8125ZM14.0625 14.0625H3.75V3.75H14.0625V14.0625Z"
      fill="#A4A4A4"
    />
  </svg>
)

export default ClipboardIcon
