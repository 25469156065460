// access.ts

export default {
  slogan: 'Mua Tiền điện tử. Thanh toán Tiền điện tử.',
  login: 'Đăng nhập',
  signup: 'Đăng ký',
  smartphone: 'Điện thoại thông minh',
  login_with: '<span>$t(login) với</span><span>{{label}}</span>',
  login_with_different_account:
    '<span>$t(login) với tài khoản</span><span>Khác hoặc Thiết bị khác</span>',
  add_key: '<span>Thêm thiết bị vào</span><span>Tài khoản đã đăng ký</span>',
  signup_with: '<span>$t(signup) với</span><span>{{label}}</span>',
  create_account: 'Tạo Tài khoản',
  more_options: 'Nhiều lựa chọn hơn',
  registered_modal_text: `Bạn đã đăng ký với Bitcash chưa?
	
	`,
  auto_login_modal_text: `Bạn có muốn sử dụng tài khoản đã đăng nhập cuối cùng không?
	
	`,
  no_devices: `Lỗi khi tải thông tin xác thực của thiết bị.
	Hãy thử nhập lại tài khoản của bạn để đăng nhập.`,
  no: 'Không',
  yes: 'Có',
  register: 'Đăng ký',
  keep_session: 'Giữ Phiên',
  logout: 'Đăng xuất',
  verification_complete: 'Xác minh hoàn tất.',
  your_session_expired: 'Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại.',
  session_expired: 'Phiên Đã Hết Hạn',
  your_session_expiring: 'Phiên Làm Việc của Bạn Đang Sắp Hết Hạn',
  session_about_expire:
    'Phiên làm việc của bạn sắp hết\n trong ít hơn 5 phút.\n Bạn có muốn giữ không?\n',
  Validating_credentials: 'Đang xác minh thông tin xác thực',
}
