import styled from 'app-view/styles/styled'

type ToggleProps = {
  isOn: boolean
}

export const Toggle = styled.button`
  --size: 1.25rem;
  background-color: ${(props: ToggleProps) => (props.isOn ? '#29e0ad' : '#e5e5e5')};
  border: 1px solid #ddd;
  border-radius: 2rem;
  box-sizing: content-box;
  cursor: pointer;
  height: var(--size);
  padding: 0;
  position: relative;
  transition: background-color 0.15s ease-in-out;
  width: calc(var(--size) * 2);

  &:after {
    content: '';
    background-color: white;
    border-radius: 50%;
    height: var(--size);
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(${(props: ToggleProps) => (props.isOn ? '100%' : '0%')});
    transition: transform 0.15s ease-in-out;
    width: var(--size);
  }
`
