import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { Confirm } from 'pages/P2PView/steps/common/Confirm'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import { buyAmountLessP2PFee, getDAOAmountFee, get_amount } from 'app-view/lib/utils'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const SellConfirmation = ({ next, goTo }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer, loading }, { submitOffer, methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()

  const onConfirm = async () => {
    const { success } = await submitOffer()
    // TODO: To send a notification to the user that we did match
    if (success) next()
  }
  const onPrev = () => {
    return goTo('method')
  }

  if (loading) return <Loader text={`${t('p2p:creating_sell')}...`} />

  const confirm_text = t('sell_confirm', {
    cryptoAmount: buyAmountLessP2PFee(offer.amount),
    cryptoFee: getDAOAmountFee(offer.amount),
    amount: get_amount(offer.amount),
    method: methodLabel(offer.method),
  })

  return (
    <Confirm
      onPrev={onPrev}
      onConfirm={onConfirm}
      text={`${confirm_text.substring(
        0,
        isMethodDetails ? confirm_text.length - 1 : confirm_text.length,
      )} ${isMethodDetails ? sellerMethodDetailsLabelData : '\n\n'}`}
    />
  )
}
