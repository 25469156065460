import React from 'react'
import { GreenButton } from 'app-view/components/buttons'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store/index'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ModalText, ModalButtonsContainer } from 'app-view/components/Modal'

export const SellComplete = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['swap'])
  const {
    coinSwap: { swap },
  } = useStore()

  return (
    <>
      <ModalText>
        {`${t('sell.complete.text2')}`}
        <span>${swap.total_to_receive}</span>
        {`
        ${t('sell.complete.text3')}
        
        `}
      </ModalText>

      <ModalButtonsContainer>
        <GreenButton onClick={close}>{t('ok')}</GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
