/** @tsx tsx */
import theme from 'app-config/theme'
import { hideBkExt } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { ActivityIndicator } from 'app-view/components/ActivityIndicator'
import Chat from 'app-view/components/Chat'
import { SkeletonOffer } from 'app-view/components/Skeletons'
import {
  AccountIcon,
  CardBoldText,
  CardBoldTextAlt,
  CardText,
  FlexBox,
  InfoContainer,
  p2pIconProps,
} from 'app-view/components/Styled'
import { useWizard } from 'app-view/components/Wizard'
import { GreenButton, RedButton } from 'app-view/components/buttons/ColoredButtons'
import { TagIcon } from 'app-view/components/icons'
import { useOnlineStatus } from 'app-view/hooks/useOnlineStatus'
import { cryptoAmount, getRegionLabel, get_amount, titleCase } from 'app-view/lib/utils'
import styled from 'app-view/styles/styled'
import { MotionProps, motion } from 'framer-motion/dist/framer-motion'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from '../hooks/useP2POffer'
import { useTakeOffer } from '../hooks/useTakeOffer'
import { TAKE_OFFER_STEPS } from '../steps/take-offer'
import { ActivityIndicatorContainer } from './Shared'

const LeftContainer = styled(FlexBox)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > span:last-of-type {
    position: absolute;
    top: 0;
    right: -1.5rem;
  }
`

export const CardContainer = styled(motion.div)<{ count?: number; number?: number }>`
  padding: 1rem;
  margin-bottom: 1.5rem;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0.83rem 1rem;
  height: 94px;
  position: ${(props) => (props?.number === 1 ? 'relative' : 'absolute')};
  top: ${(props) => (props?.number === 1 ? '' : '0px')};
  left: ${(props) => (props?.number === 1 ? '' : '0px')};
  border: ${(props) => (props?.number !== 1 ? '1px solid #bfbfbf3a' : 'none')};
  transform: ${(props) =>
    props?.number === 1
      ? props?.count > 2
        ? 'translate(4px, -4px)'
        : 'none'
      : props?.number === 3
      ? 'translate(-4px, 4px)'
      : 'none'};
  z-index: ${(props) => (props?.number ? 4 - props.number : '')};
  box-shadow: ${(props) =>
    props?.number === 1
      ? props?.count > 1
        ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
        : 'none'
      : props?.number === 2
      ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
      : 'none'};
  -webkit-box-shadow: ${(props) =>
    props?.number === 1
      ? props?.count > 1
        ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
        : 'none'
      : props?.number === 2
      ? '-2px 2px 8px 3px rgba(51, 51, 51, 0.08)'
      : 'none'};
`

const TextSmall = styled.p`
  font-size: 11px;
`

const OfferText: React.FC<{ offer: P2POffer }> = ({ offer }) => {
  const offerAccount = (offer.type === 'sell' ? offer.seller : offer.buyer) || ''
  const normalizedAccount = hideBkExt(offerAccount)
  const { isOnline, showOnlineStatus } = useOnlineStatus(offerAccount)
  return (
    <FlexBox style={{ marginBottom: 30 }}>
      <AccountIcon />
      <CardBoldText style={{ fontSize: 13 }} color={theme.colors?.darkGray}>
        {normalizedAccount}
      </CardBoldText>
      {showOnlineStatus && (
        <ActivityIndicatorContainer>
          <ActivityIndicator isOnline={isOnline} />
        </ActivityIndicatorContainer>
      )}
    </FlexBox>
  )
}

interface OfferProps {
  offer: P2POffer & {
    disabled?: boolean
    count?: number
  }
}

const animationProps: MotionProps = {
  initial: { opacity: 0.33, position: 'relative' },
  animate: { opacity: 1, position: 'relative' },
  exit: { opacity: 0.16, position: 'absolute' },
  transition: { duration: 0.14 },
}

export const OfferTrustGlobalNetwork: React.FC<OfferProps> = ({ offer }) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer: p2p_open_offer }, { startNewOffer, formatRegion }] = useP2POffer()
  const account = useStore.useAccount()
  const { amount, id } = offer
  const isBuyerSeller = p2p_open_offer.seller !== '' && p2p_open_offer.buyer !== ''
  const to_chat: string =
    isBuyerSeller && p2p_open_offer.buyer === account
      ? `${p2p_open_offer.seller}`
      : `${p2p_open_offer.buyer}`
  const isChatReady = p2p_open_offer.id === id && account && to_chat !== ''
  const isSell = offer.type === 'sell'
  const [{ loading }, { setConfirmStates, setOffer }] = useTakeOffer()
  const [{}, { start }] = useWizard()

  const handleOffer = () => {
    startNewOffer(offer.type as 'sell' | 'buy')
    setOffer(offer)

    const methodRegionLabel = t('p2p:bank_transfer_region', {
      region: titleCase(
        formatRegion(offer.region as string, false) ||
          (offer.region || '').split('-')[(offer.region || '').split('-').length - 1],
      ),
      isRegion: getRegionLabel(offer.region as string, t('bank_transfer_region_label')),
    })
    setConfirmStates(
      `${t('do_you_want', {
        cryptoAmount: cryptoAmount(offer.amount),
        methodDetails: methodRegionLabel.replace('.', ''),
      })}`,
      isSell ? t('buy_now') : t('sell_now'),
    )
    start(TAKE_OFFER_STEPS)
  }

  const actionLabel = offer.type === 'sell' ? t('buy_now') : t('sell_now')

  // TODO: we need to improve the type offer.disabled it is set on usePublicP2POffersFilter.tsx
  const actionButton = () =>
    offer.type === 'sell' ? (
      <GreenButton
        onClick={() => handleOffer()}
        style={{ minHeight: 30, paddingInline: 15, maxWidth: 100, minWidth: 100 }}
        // @ts-ignore // TODO: fix type
        disabled={offer.disabled}
      >
        <TextSmall>{actionLabel}</TextSmall>
      </GreenButton>
    ) : (
      <RedButton
        onClick={() => handleOffer()}
        style={{ minHeight: 30, paddingInline: 15, maxWidth: 100, minWidth: 100 }}
        // @ts-ignore // TODO: fix type
        disabled={offer.disabled}
      >
        <TextSmall>{actionLabel}</TextSmall>
      </RedButton>
    )
  return (
    <div style={{ position: 'relative' }}>
      {loading ? (
        <SkeletonOffer key={`skeleton_offer_loader__${offer.id}`} {...animationProps} />
      ) : (
        <CardContainer
          number={1}
          count={offer.matched ? 0 : offer?.count}
          key={`offer_card__${offer.id}`}
          {...animationProps}
        >
          <LeftContainer>
            <OfferText offer={offer} />
            <InfoContainer>
              <TagIcon {...p2pIconProps} />
              <CardText
                style={{
                  fontSize: 13,
                }}
              >
                {t('open_offer')} - {t(offer.method)}
              </CardText>
            </InfoContainer>
          </LeftContainer>
          <FlexBox alignItems="flex-end" style={{ flexDirection: 'column' }}>
            <div
              style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, marginRight: 3 }}
            >
              <CardBoldText
                dollars
                color={isSell ? theme.colors?.green : theme.colors?.red}
                style={{ marginRight: '0.3rem' }}
              >
                {offer.type === 'sell' ? t('buy') : t('sell')}
              </CardBoldText>
              <CardBoldTextAlt color={theme.colors?.black} dollars>
                {get_amount(amount)}
              </CardBoldTextAlt>
            </div>
            {actionButton()}
          </FlexBox>
        </CardContainer>
      )}
      {isBuyerSeller && isChatReady && <Chat />}
      {!loading && offer?.count > 2 && (
        <CardContainer number={2} count={offer?.count || 0}></CardContainer>
      )}
      {!loading && offer?.count !== 1 && (
        <CardContainer number={3} count={offer?.count || 0}></CardContainer>
      )}
    </div>
  )
}
