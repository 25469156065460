import React from 'react'

const PolygonIcon: React.FC = (props) => (
  <svg viewBox="0 0 54 55" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.2966 18.2167C51.2067 6.43235 39.4908 0.916865 28.3918 0.841321C16.1944 0.7591 3.08809 4.76957 0.703618 18.2167C-0.423696 24.5727 -0.0243682 30.7331 0.703618 37.11C2.19535 50.1685 15.2301 55.1519 26.9997 54.8251C38.6862 54.5008 51.7657 50.5124 53.2966 37.1096C54.0245 30.7331 54.4234 24.5727 53.2966 18.2167Z"
      fill="#151830"
    />
    <path
      d="M34.5994 22.9466C34.0594 22.638 33.365 22.638 32.7478 22.9466L28.4273 25.4926L25.4956 27.1128L21.2522 29.6588C20.7122 29.9674 20.0178 29.9674 19.4006 29.6588L16.0831 27.6528C15.543 27.3442 15.1573 26.727 15.1573 26.0326V22.1751C15.1573 21.5579 15.4659 20.9407 16.0831 20.5549L19.4006 18.6261C19.9407 18.3175 20.635 18.3175 21.2522 18.6261L24.5697 20.632C25.1098 20.9407 25.4956 21.5579 25.4956 22.2522V24.7982L28.4273 23.1009V20.4777C28.4273 19.8605 28.1187 19.2433 27.5015 18.8576L21.3294 15.2315C20.7893 14.9228 20.095 14.9228 19.4778 15.2315L13.1513 18.9347C12.5341 19.2433 12.2255 19.8605 12.2255 20.4777V27.73C12.2255 28.3472 12.5341 28.9644 13.1513 29.3501L19.4006 32.9763C19.9407 33.2849 20.635 33.2849 21.2522 32.9763L25.4956 30.5074L28.4273 28.8101L32.6706 26.3412C33.2107 26.0326 33.9051 26.0326 34.5223 26.3412L37.8398 28.27C38.3798 28.5786 38.7656 29.1958 38.7656 29.8902V33.7478C38.7656 34.365 38.457 34.9822 37.8398 35.368L34.5994 37.2967C34.0594 37.6053 33.365 37.6053 32.7478 37.2967L29.4303 35.368C28.8902 35.0593 28.5045 34.4421 28.5045 33.7478V31.2789L25.5727 32.9763V35.5223C25.5727 36.1395 25.8813 36.7567 26.4985 37.1424L32.7478 40.7685C33.2878 41.0771 33.9822 41.0771 34.5994 40.7685L40.8487 37.1424C41.3887 36.8338 41.7745 36.2166 41.7745 35.5223V28.1929C41.7745 27.5757 41.4659 26.9585 40.8487 26.5727L34.5994 22.9466Z"
      fill="#8247E5"
    />
  </svg>
)

export default PolygonIcon
