import React from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { H2, Form, Text, AccountFormWrapper } from 'pages/AccountView/components/TextElements'
import { useInsertMigrateDeviceMutation } from 'app-engine/graphql/generated/bitcash'
import { HintError, Input, InputWrapper } from 'app-view/components/InputField'
import { InputSection } from 'app-view/components/InputField'
import { FormData, accountInputHandler, checkAccountExt } from 'pages/AccountView/utils'
import { useNavigate } from 'react-router-dom'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { useAppleWarning } from 'app-view/hooks/useIosWarning'
import { createWebAuthNKey } from 'app-engine/library/eosio/webauthn'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { get_error_modal_props } from 'app-view/components/GlobalModal/lib/utility'
import { ModalError } from 'app-view/components/Modal'
import { AccessLoader } from 'app-view/components/Spinner'
import { checkIfAccountIsRegistered } from 'app-engine/services'

export const MigrateDeviceAccount: React.FC = () => {
  const { t } = useTranslation(['account', 'global'])
  const navigate = useNavigate()
  const openAppleWarning = useAppleWarning()
  const [loading, setLoading] = React.useState(false)
  const [insertMigrateDevice] = useInsertMigrateDeviceMutation()
  const [, globalModalActions] = useGlobalModal()

  const form = useForm<FormData>({
    defaultValues: {
      account: '',
    },
  })

  const onSubmit = async ({ account }: FormData) => {
    const formatted_account = checkAccountExt(account)
    openAppleWarning(async () => {
      try {
        setLoading(true)
        const { pubKey, credId } = await createWebAuthNKey(formatted_account)
        const res = await insertMigrateDevice({
          variables: {
            objects: [
              {
                account: formatted_account,
                cred_id: credId,
                public_key: pubKey,
                device_name: 'USE-OLD-DEVICE-NAME',
              },
            ],
          },
        })
        setLoading(false)
        console.log('registring account response', res)
        navigate('/migrate/success')
      } catch (err) {
        setLoading(false)
        globalModalActions.open(
          get_error_modal_props(() => <ModalError error={(err as Error).message} />),
        )
      }
    })
  }

  return (
    <>
      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <AccountFormWrapper>
          <div>
            <H2>{t('migrate_device_account_title')}</H2>
            <Text
              dangerouslySetInnerHTML={{
                __html: t('migrate_device_account_description'),
              }}
            />
          </div>
          <Controller
            name="account"
            control={form.control}
            rules={{
              required: t('create_account_user_account_error_required') as string,
              pattern: {
                value: /^([a-z]|[1-5]|\.bk){1,12}$/,
                message: t('create_account_user_account_error_pattern'),
              },
              validate: async (account) => {
                const exists = await checkIfAccountIsRegistered(checkAccountExt(account))
                return !exists ? (t('account_user_account_not_registered') as string) : true
              },
            }}
            render={({ field, fieldState }) => (
              <InputWrapper mt={42}>
                <InputSection inputSize="sm">
                  <Input
                    {...field}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      accountInputHandler(event, {
                        form,
                        field: 'account',
                        message: t('create_account_user_account_error_pattern'),
                        domain: true,
                      })
                    }}
                    type="text"
                    placeholder={t('create_account_user_account_placeholder')}
                    maxLength={12}
                    fullradius={1}
                    fontWeight="500"
                    error={Boolean(!form.formState.isValidating && fieldState.error)}
                  />
                </InputSection>
                {!form.formState.isValidating && fieldState.error && (
                  <HintError align="left">{fieldState.error.message}</HintError>
                )}
              </InputWrapper>
            )}
          />
        </AccountFormWrapper>
        {loading && <AccessLoader />}
        <div>
          <LargeGreenButton
            type="submit"
            disabled={form.formState.isValidating || form.formState.isSubmitting}
          >
            {t('p2p:Confirm')}
          </LargeGreenButton>
          <LargeBlackButton type="button" onClick={() => navigate('/')}>
            {t('global:back')}
          </LargeBlackButton>
        </div>
      </Form>
    </>
  )
}
