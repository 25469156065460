// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Scalars = {
  Boolean: boolean
  Float: number
  Int: number
  JSON: any
  String: string
  bigint: any
  float8: any
  jsonb: any
  numeric: any
  timestamp: any
  timestamptz: any
  uuid: any
}

/** columns and relationships of "account_statistics" */
export interface account_statistics {
  orders_cancelled: Scalars['bigint'] | null
  orders_completed: Scalars['bigint'] | null
  user_id: Scalars['String'] | null
  __typename: 'account_statistics'
}

/** aggregated selection of "account_statistics" */
export interface account_statistics_aggregate {
  aggregate: account_statistics_aggregate_fields | null
  nodes: account_statistics[]
  __typename: 'account_statistics_aggregate'
}

/** aggregate fields of "account_statistics" */
export interface account_statistics_aggregate_fields {
  avg: account_statistics_avg_fields | null
  count: Scalars['Int']
  max: account_statistics_max_fields | null
  min: account_statistics_min_fields | null
  stddev: account_statistics_stddev_fields | null
  stddev_pop: account_statistics_stddev_pop_fields | null
  stddev_samp: account_statistics_stddev_samp_fields | null
  sum: account_statistics_sum_fields | null
  var_pop: account_statistics_var_pop_fields | null
  var_samp: account_statistics_var_samp_fields | null
  variance: account_statistics_variance_fields | null
  __typename: 'account_statistics_aggregate_fields'
}

/** aggregate avg on columns */
export interface account_statistics_avg_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_avg_fields'
}

/** aggregate max on columns */
export interface account_statistics_max_fields {
  orders_cancelled: Scalars['bigint'] | null
  orders_completed: Scalars['bigint'] | null
  user_id: Scalars['String'] | null
  __typename: 'account_statistics_max_fields'
}

/** aggregate min on columns */
export interface account_statistics_min_fields {
  orders_cancelled: Scalars['bigint'] | null
  orders_completed: Scalars['bigint'] | null
  user_id: Scalars['String'] | null
  __typename: 'account_statistics_min_fields'
}

/** select columns of table "account_statistics" */
export type account_statistics_select_column = 'orders_cancelled' | 'orders_completed' | 'user_id'

/** aggregate stddev on columns */
export interface account_statistics_stddev_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface account_statistics_stddev_pop_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface account_statistics_stddev_samp_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface account_statistics_sum_fields {
  orders_cancelled: Scalars['bigint'] | null
  orders_completed: Scalars['bigint'] | null
  __typename: 'account_statistics_sum_fields'
}

/** aggregate var_pop on columns */
export interface account_statistics_var_pop_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface account_statistics_var_samp_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_var_samp_fields'
}

/** aggregate variance on columns */
export interface account_statistics_variance_fields {
  orders_cancelled: Scalars['Float'] | null
  orders_completed: Scalars['Float'] | null
  __typename: 'account_statistics_variance_fields'
}

/** Additional information for account restoration and for kyc */
export interface accounts_information {
  account: Scalars['String']
  country_id: Scalars['jsonb']
  date_of_birth: Scalars['timestamptz']
  email: Scalars['String']
  full_name: Scalars['String']
  id: Scalars['uuid']
  newsletter_subscription: Scalars['Boolean'] | null
  phone: Scalars['String']
  photo_id: Scalars['String'] | null
  recovery_partners: Scalars['jsonb'] | null
  /** An object relationship */
  reg_account: reg_accounts
  registration_ip: Scalars['String']
  selfie: Scalars['String'] | null
  __typename: 'accounts_information'
}

/** aggregated selection of "accounts_information" */
export interface accounts_information_aggregate {
  aggregate: accounts_information_aggregate_fields | null
  nodes: accounts_information[]
  __typename: 'accounts_information_aggregate'
}

/** aggregate fields of "accounts_information" */
export interface accounts_information_aggregate_fields {
  count: Scalars['Int']
  max: accounts_information_max_fields | null
  min: accounts_information_min_fields | null
  __typename: 'accounts_information_aggregate_fields'
}

/** unique or primary key constraints on table "accounts_information" */
export type accounts_information_constraint =
  | 'accounts_information_account_key'
  | 'accounts_information_id_key'
  | 'accounts_information_phone_key'
  | 'accounts_information_pkey'
  | 'unique_country_id'
  | 'unique_email'
  | 'unique_phone'

/** aggregate max on columns */
export interface accounts_information_max_fields {
  account: Scalars['String'] | null
  date_of_birth: Scalars['timestamptz'] | null
  email: Scalars['String'] | null
  full_name: Scalars['String'] | null
  id: Scalars['uuid'] | null
  phone: Scalars['String'] | null
  photo_id: Scalars['String'] | null
  registration_ip: Scalars['String'] | null
  selfie: Scalars['String'] | null
  __typename: 'accounts_information_max_fields'
}

/** aggregate min on columns */
export interface accounts_information_min_fields {
  account: Scalars['String'] | null
  date_of_birth: Scalars['timestamptz'] | null
  email: Scalars['String'] | null
  full_name: Scalars['String'] | null
  id: Scalars['uuid'] | null
  phone: Scalars['String'] | null
  photo_id: Scalars['String'] | null
  registration_ip: Scalars['String'] | null
  selfie: Scalars['String'] | null
  __typename: 'accounts_information_min_fields'
}

/** response of any mutation on the table "accounts_information" */
export interface accounts_information_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: accounts_information[]
  __typename: 'accounts_information_mutation_response'
}

/** select columns of table "accounts_information" */
export type accounts_information_select_column =
  | 'account'
  | 'country_id'
  | 'date_of_birth'
  | 'email'
  | 'full_name'
  | 'id'
  | 'newsletter_subscription'
  | 'phone'
  | 'photo_id'
  | 'recovery_partners'
  | 'registration_ip'
  | 'selfie'

/** select "accounts_information_aggregate_bool_exp_bool_and_arguments_columns" columns of table "accounts_information" */
export type accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_and_arguments_columns =
  'newsletter_subscription'

/** select "accounts_information_aggregate_bool_exp_bool_or_arguments_columns" columns of table "accounts_information" */
export type accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_or_arguments_columns =
  'newsletter_subscription'

/** update columns of table "accounts_information" */
export type accounts_information_update_column =
  | 'account'
  | 'country_id'
  | 'date_of_birth'
  | 'email'
  | 'full_name'
  | 'id'
  | 'newsletter_subscription'
  | 'phone'
  | 'photo_id'
  | 'recovery_partners'
  | 'registration_ip'
  | 'selfie'

/** columns and relationships of "accounts_referral" */
export interface accounts_referral {
  account: Scalars['String']
  clicks: Scalars['Int']
  link: Scalars['String']
  referrer: Scalars['String']
  /** An object relationship */
  reg_account: reg_accounts
  registrations: Scalars['Int']
  website: Scalars['String']
  __typename: 'accounts_referral'
}

/** aggregated selection of "accounts_referral" */
export interface accounts_referral_aggregate {
  aggregate: accounts_referral_aggregate_fields | null
  nodes: accounts_referral[]
  __typename: 'accounts_referral_aggregate'
}

/** aggregate fields of "accounts_referral" */
export interface accounts_referral_aggregate_fields {
  avg: accounts_referral_avg_fields | null
  count: Scalars['Int']
  max: accounts_referral_max_fields | null
  min: accounts_referral_min_fields | null
  stddev: accounts_referral_stddev_fields | null
  stddev_pop: accounts_referral_stddev_pop_fields | null
  stddev_samp: accounts_referral_stddev_samp_fields | null
  sum: accounts_referral_sum_fields | null
  var_pop: accounts_referral_var_pop_fields | null
  var_samp: accounts_referral_var_samp_fields | null
  variance: accounts_referral_variance_fields | null
  __typename: 'accounts_referral_aggregate_fields'
}

/** aggregate avg on columns */
export interface accounts_referral_avg_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_avg_fields'
}

/** unique or primary key constraints on table "accounts_referral" */
export type accounts_referral_constraint = 'accounts_referral_pkey'

/** aggregate max on columns */
export interface accounts_referral_max_fields {
  account: Scalars['String'] | null
  clicks: Scalars['Int'] | null
  link: Scalars['String'] | null
  referrer: Scalars['String'] | null
  registrations: Scalars['Int'] | null
  website: Scalars['String'] | null
  __typename: 'accounts_referral_max_fields'
}

/** aggregate min on columns */
export interface accounts_referral_min_fields {
  account: Scalars['String'] | null
  clicks: Scalars['Int'] | null
  link: Scalars['String'] | null
  referrer: Scalars['String'] | null
  registrations: Scalars['Int'] | null
  website: Scalars['String'] | null
  __typename: 'accounts_referral_min_fields'
}

/** response of any mutation on the table "accounts_referral" */
export interface accounts_referral_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: accounts_referral[]
  __typename: 'accounts_referral_mutation_response'
}

/** select columns of table "accounts_referral" */
export type accounts_referral_select_column =
  | 'account'
  | 'clicks'
  | 'link'
  | 'referrer'
  | 'registrations'
  | 'website'

/** aggregate stddev on columns */
export interface accounts_referral_stddev_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface accounts_referral_stddev_pop_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface accounts_referral_stddev_samp_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface accounts_referral_sum_fields {
  clicks: Scalars['Int'] | null
  registrations: Scalars['Int'] | null
  __typename: 'accounts_referral_sum_fields'
}

/** update columns of table "accounts_referral" */
export type accounts_referral_update_column =
  | 'account'
  | 'clicks'
  | 'link'
  | 'referrer'
  | 'registrations'
  | 'website'

/** aggregate var_pop on columns */
export interface accounts_referral_var_pop_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface accounts_referral_var_samp_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_var_samp_fields'
}

/** aggregate variance on columns */
export interface accounts_referral_variance_fields {
  clicks: Scalars['Float'] | null
  registrations: Scalars['Float'] | null
  __typename: 'accounts_referral_variance_fields'
}

/** Blockchain Actions */
export interface actions {
  action: Scalars['String']
  auth: Scalars['jsonb']
  contract: Scalars['String']
  data: Scalars['jsonb']
  global_sequence: Scalars['String']
  transaction_id: Scalars['String']
  __typename: 'actions'
}

/** aggregated selection of "actions" */
export interface actions_aggregate {
  aggregate: actions_aggregate_fields | null
  nodes: actions[]
  __typename: 'actions_aggregate'
}

/** aggregate fields of "actions" */
export interface actions_aggregate_fields {
  count: Scalars['Int']
  max: actions_max_fields | null
  min: actions_min_fields | null
  __typename: 'actions_aggregate_fields'
}

/** unique or primary key constraints on table "actions" */
export type actions_constraint = 'actions_pkey' | 'actions_transaction_id_key'

/** aggregate max on columns */
export interface actions_max_fields {
  action: Scalars['String'] | null
  contract: Scalars['String'] | null
  global_sequence: Scalars['String'] | null
  transaction_id: Scalars['String'] | null
  __typename: 'actions_max_fields'
}

/** aggregate min on columns */
export interface actions_min_fields {
  action: Scalars['String'] | null
  contract: Scalars['String'] | null
  global_sequence: Scalars['String'] | null
  transaction_id: Scalars['String'] | null
  __typename: 'actions_min_fields'
}

/** response of any mutation on the table "actions" */
export interface actions_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: actions[]
  __typename: 'actions_mutation_response'
}

/** select columns of table "actions" */
export type actions_select_column =
  | 'action'
  | 'auth'
  | 'contract'
  | 'data'
  | 'global_sequence'
  | 'transaction_id'

/** update columns of table "actions" */
export type actions_update_column =
  | 'action'
  | 'auth'
  | 'contract'
  | 'data'
  | 'global_sequence'
  | 'transaction_id'

/** columns and relationships of "avg_pay_time" */
export interface avg_pay_time {
  avg_pay_time_seconds: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'avg_pay_time'
}

/** aggregated selection of "avg_pay_time" */
export interface avg_pay_time_aggregate {
  aggregate: avg_pay_time_aggregate_fields | null
  nodes: avg_pay_time[]
  __typename: 'avg_pay_time_aggregate'
}

/** aggregate fields of "avg_pay_time" */
export interface avg_pay_time_aggregate_fields {
  avg: avg_pay_time_avg_fields | null
  count: Scalars['Int']
  max: avg_pay_time_max_fields | null
  min: avg_pay_time_min_fields | null
  stddev: avg_pay_time_stddev_fields | null
  stddev_pop: avg_pay_time_stddev_pop_fields | null
  stddev_samp: avg_pay_time_stddev_samp_fields | null
  sum: avg_pay_time_sum_fields | null
  var_pop: avg_pay_time_var_pop_fields | null
  var_samp: avg_pay_time_var_samp_fields | null
  variance: avg_pay_time_variance_fields | null
  __typename: 'avg_pay_time_aggregate_fields'
}

/** aggregate avg on columns */
export interface avg_pay_time_avg_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_avg_fields'
}

/** aggregate max on columns */
export interface avg_pay_time_max_fields {
  avg_pay_time_seconds: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'avg_pay_time_max_fields'
}

/** aggregate min on columns */
export interface avg_pay_time_min_fields {
  avg_pay_time_seconds: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'avg_pay_time_min_fields'
}

/** select columns of table "avg_pay_time" */
export type avg_pay_time_select_column = 'avg_pay_time_seconds' | 'user_id'

/** aggregate stddev on columns */
export interface avg_pay_time_stddev_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface avg_pay_time_stddev_pop_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface avg_pay_time_stddev_samp_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface avg_pay_time_sum_fields {
  avg_pay_time_seconds: Scalars['numeric'] | null
  __typename: 'avg_pay_time_sum_fields'
}

/** aggregate var_pop on columns */
export interface avg_pay_time_var_pop_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface avg_pay_time_var_samp_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_var_samp_fields'
}

/** aggregate variance on columns */
export interface avg_pay_time_variance_fields {
  avg_pay_time_seconds: Scalars['Float'] | null
  __typename: 'avg_pay_time_variance_fields'
}

/** columns and relationships of "avg_release_time" */
export interface avg_release_time {
  avg_release_time_seconds: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'avg_release_time'
}

/** aggregated selection of "avg_release_time" */
export interface avg_release_time_aggregate {
  aggregate: avg_release_time_aggregate_fields | null
  nodes: avg_release_time[]
  __typename: 'avg_release_time_aggregate'
}

/** aggregate fields of "avg_release_time" */
export interface avg_release_time_aggregate_fields {
  avg: avg_release_time_avg_fields | null
  count: Scalars['Int']
  max: avg_release_time_max_fields | null
  min: avg_release_time_min_fields | null
  stddev: avg_release_time_stddev_fields | null
  stddev_pop: avg_release_time_stddev_pop_fields | null
  stddev_samp: avg_release_time_stddev_samp_fields | null
  sum: avg_release_time_sum_fields | null
  var_pop: avg_release_time_var_pop_fields | null
  var_samp: avg_release_time_var_samp_fields | null
  variance: avg_release_time_variance_fields | null
  __typename: 'avg_release_time_aggregate_fields'
}

/** aggregate avg on columns */
export interface avg_release_time_avg_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_avg_fields'
}

/** aggregate max on columns */
export interface avg_release_time_max_fields {
  avg_release_time_seconds: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'avg_release_time_max_fields'
}

/** aggregate min on columns */
export interface avg_release_time_min_fields {
  avg_release_time_seconds: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'avg_release_time_min_fields'
}

/** select columns of table "avg_release_time" */
export type avg_release_time_select_column = 'avg_release_time_seconds' | 'user_id'

/** aggregate stddev on columns */
export interface avg_release_time_stddev_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface avg_release_time_stddev_pop_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface avg_release_time_stddev_samp_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface avg_release_time_sum_fields {
  avg_release_time_seconds: Scalars['numeric'] | null
  __typename: 'avg_release_time_sum_fields'
}

/** aggregate var_pop on columns */
export interface avg_release_time_var_pop_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface avg_release_time_var_samp_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_var_samp_fields'
}

/** aggregate variance on columns */
export interface avg_release_time_variance_fields {
  avg_release_time_seconds: Scalars['Float'] | null
  __typename: 'avg_release_time_variance_fields'
}

/** columns and relationships of "combined_avg_times" */
export interface combined_avg_times {
  avg_pay_time_minutes: Scalars['numeric'] | null
  avg_release_time_minutes: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'combined_avg_times'
}

/** aggregated selection of "combined_avg_times" */
export interface combined_avg_times_aggregate {
  aggregate: combined_avg_times_aggregate_fields | null
  nodes: combined_avg_times[]
  __typename: 'combined_avg_times_aggregate'
}

/** aggregate fields of "combined_avg_times" */
export interface combined_avg_times_aggregate_fields {
  avg: combined_avg_times_avg_fields | null
  count: Scalars['Int']
  max: combined_avg_times_max_fields | null
  min: combined_avg_times_min_fields | null
  stddev: combined_avg_times_stddev_fields | null
  stddev_pop: combined_avg_times_stddev_pop_fields | null
  stddev_samp: combined_avg_times_stddev_samp_fields | null
  sum: combined_avg_times_sum_fields | null
  var_pop: combined_avg_times_var_pop_fields | null
  var_samp: combined_avg_times_var_samp_fields | null
  variance: combined_avg_times_variance_fields | null
  __typename: 'combined_avg_times_aggregate_fields'
}

/** aggregate avg on columns */
export interface combined_avg_times_avg_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_avg_fields'
}

/** aggregate max on columns */
export interface combined_avg_times_max_fields {
  avg_pay_time_minutes: Scalars['numeric'] | null
  avg_release_time_minutes: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'combined_avg_times_max_fields'
}

/** aggregate min on columns */
export interface combined_avg_times_min_fields {
  avg_pay_time_minutes: Scalars['numeric'] | null
  avg_release_time_minutes: Scalars['numeric'] | null
  user_id: Scalars['String'] | null
  __typename: 'combined_avg_times_min_fields'
}

/** select columns of table "combined_avg_times" */
export type combined_avg_times_select_column =
  | 'avg_pay_time_minutes'
  | 'avg_release_time_minutes'
  | 'user_id'

/** aggregate stddev on columns */
export interface combined_avg_times_stddev_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface combined_avg_times_stddev_pop_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface combined_avg_times_stddev_samp_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface combined_avg_times_sum_fields {
  avg_pay_time_minutes: Scalars['numeric'] | null
  avg_release_time_minutes: Scalars['numeric'] | null
  __typename: 'combined_avg_times_sum_fields'
}

/** aggregate var_pop on columns */
export interface combined_avg_times_var_pop_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface combined_avg_times_var_samp_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_var_samp_fields'
}

/** aggregate variance on columns */
export interface combined_avg_times_variance_fields {
  avg_pay_time_minutes: Scalars['Float'] | null
  avg_release_time_minutes: Scalars['Float'] | null
  __typename: 'combined_avg_times_variance_fields'
}

/** ordering argument of a cursor */
export type cursor_ordering = 'ASC' | 'DESC'

/** columns and relationships of "devices" */
export interface devices {
  account: Scalars['String']
  created_at: Scalars['timestamptz'] | null
  cred_id: Scalars['String']
  device_name: Scalars['String']
  public_key: Scalars['String']
  /** An object relationship */
  reg_account: reg_accounts
  __typename: 'devices'
}

/** aggregated selection of "devices" */
export interface devices_aggregate {
  aggregate: devices_aggregate_fields | null
  nodes: devices[]
  __typename: 'devices_aggregate'
}

/** aggregate fields of "devices" */
export interface devices_aggregate_fields {
  count: Scalars['Int']
  max: devices_max_fields | null
  min: devices_min_fields | null
  __typename: 'devices_aggregate_fields'
}

/** unique or primary key constraints on table "devices" */
export type devices_constraint = 'devices_pkey'

/** aggregate max on columns */
export interface devices_max_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  public_key: Scalars['String'] | null
  __typename: 'devices_max_fields'
}

/** aggregate min on columns */
export interface devices_min_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  public_key: Scalars['String'] | null
  __typename: 'devices_min_fields'
}

/** response of any mutation on the table "devices" */
export interface devices_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: devices[]
  __typename: 'devices_mutation_response'
}

/** select columns of table "devices" */
export type devices_select_column =
  | 'account'
  | 'created_at'
  | 'cred_id'
  | 'device_name'
  | 'public_key'

/** update columns of table "devices" */
export type devices_update_column =
  | 'account'
  | 'created_at'
  | 'cred_id'
  | 'device_name'
  | 'public_key'

export interface id_verification_output {
  existingRecord: Scalars['Boolean']
  __typename: 'id_verification_output'
}

/** columns and relationships of "login_account" */
export interface login_account {
  account: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  reg_account: reg_accounts
  __typename: 'login_account'
}

/** aggregated selection of "login_account" */
export interface login_account_aggregate {
  aggregate: login_account_aggregate_fields | null
  nodes: login_account[]
  __typename: 'login_account_aggregate'
}

/** aggregate fields of "login_account" */
export interface login_account_aggregate_fields {
  count: Scalars['Int']
  max: login_account_max_fields | null
  min: login_account_min_fields | null
  __typename: 'login_account_aggregate_fields'
}

/** unique or primary key constraints on table "login_account" */
export type login_account_constraint = 'login_account_pkey'

/** aggregate max on columns */
export interface login_account_max_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  __typename: 'login_account_max_fields'
}

/** aggregate min on columns */
export interface login_account_min_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  __typename: 'login_account_min_fields'
}

/** response of any mutation on the table "login_account" */
export interface login_account_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: login_account[]
  __typename: 'login_account_mutation_response'
}

/** select columns of table "login_account" */
export type login_account_select_column = 'account' | 'created_at' | 'id'

/** update columns of table "login_account" */
export type login_account_update_column = 'account' | 'created_at' | 'id'

/** columns and relationships of "login_counts" */
export interface login_counts {
  accounts: Scalars['String'][] | null
  date: Scalars['timestamptz'] | null
  login_count: Scalars['bigint'] | null
  __typename: 'login_counts'
}

export interface login_counts_aggregate {
  aggregate: login_counts_aggregate_fields | null
  nodes: login_counts[]
  __typename: 'login_counts_aggregate'
}

/** aggregate fields of "login_counts" */
export interface login_counts_aggregate_fields {
  avg: login_counts_avg_fields | null
  count: Scalars['Int']
  max: login_counts_max_fields | null
  min: login_counts_min_fields | null
  stddev: login_counts_stddev_fields | null
  stddev_pop: login_counts_stddev_pop_fields | null
  stddev_samp: login_counts_stddev_samp_fields | null
  sum: login_counts_sum_fields | null
  var_pop: login_counts_var_pop_fields | null
  var_samp: login_counts_var_samp_fields | null
  variance: login_counts_variance_fields | null
  __typename: 'login_counts_aggregate_fields'
}

/** aggregate avg on columns */
export interface login_counts_avg_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_avg_fields'
}

/** aggregate max on columns */
export interface login_counts_max_fields {
  accounts: Scalars['String'][] | null
  date: Scalars['timestamptz'] | null
  login_count: Scalars['bigint'] | null
  __typename: 'login_counts_max_fields'
}

/** aggregate min on columns */
export interface login_counts_min_fields {
  accounts: Scalars['String'][] | null
  date: Scalars['timestamptz'] | null
  login_count: Scalars['bigint'] | null
  __typename: 'login_counts_min_fields'
}

/** response of any mutation on the table "login_counts" */
export interface login_counts_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: login_counts[]
  __typename: 'login_counts_mutation_response'
}

/** select columns of table "login_counts" */
export type login_counts_select_column = 'accounts' | 'date' | 'login_count'

/** aggregate stddev on columns */
export interface login_counts_stddev_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface login_counts_stddev_pop_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface login_counts_stddev_samp_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface login_counts_sum_fields {
  login_count: Scalars['bigint'] | null
  __typename: 'login_counts_sum_fields'
}

/** aggregate var_pop on columns */
export interface login_counts_var_pop_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface login_counts_var_samp_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_var_samp_fields'
}

/** aggregate variance on columns */
export interface login_counts_variance_fields {
  login_count: Scalars['Float'] | null
  __typename: 'login_counts_variance_fields'
}

/** columns and relationships of "messages" */
export interface messages {
  from: Scalars['String']
  id: Scalars['uuid']
  message: Scalars['String']
  p2p_id: Scalars['uuid'] | null
  /** An object relationship */
  p2p_offer: p2p_offers | null
  /** An object relationship */
  regAccountByTo: reg_accounts
  /** An object relationship */
  reg_account: reg_accounts
  support_id: Scalars['uuid'] | null
  swap_id: Scalars['uuid'] | null
  /** An object relationship */
  swap_order: swap_orders | null
  timestamp: Scalars['timestamptz']
  to: Scalars['String']
  __typename: 'messages'
}

/** aggregated selection of "messages" */
export interface messages_aggregate {
  aggregate: messages_aggregate_fields | null
  nodes: messages[]
  __typename: 'messages_aggregate'
}

/** aggregate fields of "messages" */
export interface messages_aggregate_fields {
  count: Scalars['Int']
  max: messages_max_fields | null
  min: messages_min_fields | null
  __typename: 'messages_aggregate_fields'
}

/** unique or primary key constraints on table "messages" */
export type messages_constraint = 'messages_pkey'

/** aggregate max on columns */
export interface messages_max_fields {
  from: Scalars['String'] | null
  id: Scalars['uuid'] | null
  message: Scalars['String'] | null
  p2p_id: Scalars['uuid'] | null
  support_id: Scalars['uuid'] | null
  swap_id: Scalars['uuid'] | null
  timestamp: Scalars['timestamptz'] | null
  to: Scalars['String'] | null
  __typename: 'messages_max_fields'
}

/** aggregate min on columns */
export interface messages_min_fields {
  from: Scalars['String'] | null
  id: Scalars['uuid'] | null
  message: Scalars['String'] | null
  p2p_id: Scalars['uuid'] | null
  support_id: Scalars['uuid'] | null
  swap_id: Scalars['uuid'] | null
  timestamp: Scalars['timestamptz'] | null
  to: Scalars['String'] | null
  __typename: 'messages_min_fields'
}

/** response of any mutation on the table "messages" */
export interface messages_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: messages[]
  __typename: 'messages_mutation_response'
}

/** select columns of table "messages" */
export type messages_select_column =
  | 'from'
  | 'id'
  | 'message'
  | 'p2p_id'
  | 'support_id'
  | 'swap_id'
  | 'timestamp'
  | 'to'

/** update columns of table "messages" */
export type messages_update_column =
  | 'from'
  | 'id'
  | 'message'
  | 'p2p_id'
  | 'support_id'
  | 'swap_id'
  | 'timestamp'
  | 'to'

/** columns and relationships of "migrate_device" */
export interface migrate_device {
  account: Scalars['String']
  created_at: Scalars['timestamptz'] | null
  cred_id: Scalars['String']
  device_name: Scalars['String']
  public_key: Scalars['String']
  __typename: 'migrate_device'
}

/** aggregated selection of "migrate_device" */
export interface migrate_device_aggregate {
  aggregate: migrate_device_aggregate_fields | null
  nodes: migrate_device[]
  __typename: 'migrate_device_aggregate'
}

/** aggregate fields of "migrate_device" */
export interface migrate_device_aggregate_fields {
  count: Scalars['Int']
  max: migrate_device_max_fields | null
  min: migrate_device_min_fields | null
  __typename: 'migrate_device_aggregate_fields'
}

/** unique or primary key constraints on table "migrate_device" */
export type migrate_device_constraint = 'migrate_device_pkey'

/** aggregate max on columns */
export interface migrate_device_max_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  public_key: Scalars['String'] | null
  __typename: 'migrate_device_max_fields'
}

/** aggregate min on columns */
export interface migrate_device_min_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  public_key: Scalars['String'] | null
  __typename: 'migrate_device_min_fields'
}

/** response of any mutation on the table "migrate_device" */
export interface migrate_device_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: migrate_device[]
  __typename: 'migrate_device_mutation_response'
}

/** select columns of table "migrate_device" */
export type migrate_device_select_column =
  | 'account'
  | 'created_at'
  | 'cred_id'
  | 'device_name'
  | 'public_key'

/** update columns of table "migrate_device" */
export type migrate_device_update_column =
  | 'account'
  | 'created_at'
  | 'cred_id'
  | 'device_name'
  | 'public_key'

/** mutation root */
export interface mutation_root {
  approve_new_account: Scalars['String'] | null
  auto_cancel_p2p_offer: p2p_offer_output | null
  cancel_p2p_approval: p2p_offer_output | null
  cancel_p2p_offer: p2p_offer_output | null
  cancel_wallet_request: wallet_request_output | null
  cancel_wallet_request_approval: wallet_request_output | null
  confirm_p2p_payment: p2p_offer_output | null
  confirm_wallet_request_payment: wallet_request_output | null
  /** delete data from the table: "accounts_information" */
  delete_accounts_information: accounts_information_mutation_response | null
  /** delete single row from the table: "accounts_information" */
  delete_accounts_information_by_pk: accounts_information | null
  /** delete data from the table: "accounts_referral" */
  delete_accounts_referral: accounts_referral_mutation_response | null
  /** delete single row from the table: "accounts_referral" */
  delete_accounts_referral_by_pk: accounts_referral | null
  /** delete data from the table: "actions" */
  delete_actions: actions_mutation_response | null
  /** delete single row from the table: "actions" */
  delete_actions_by_pk: actions | null
  /** delete data from the table: "devices" */
  delete_devices: devices_mutation_response | null
  /** delete single row from the table: "devices" */
  delete_devices_by_pk: devices | null
  /** delete data from the table: "login_account" */
  delete_login_account: login_account_mutation_response | null
  /** delete single row from the table: "login_account" */
  delete_login_account_by_pk: login_account | null
  /** delete data from the table: "login_counts" */
  delete_login_counts: login_counts_mutation_response | null
  /** delete data from the table: "messages" */
  delete_messages: messages_mutation_response | null
  /** delete single row from the table: "messages" */
  delete_messages_by_pk: messages | null
  /** delete data from the table: "migrate_device" */
  delete_migrate_device: migrate_device_mutation_response | null
  /** delete single row from the table: "migrate_device" */
  delete_migrate_device_by_pk: migrate_device | null
  /** delete data from the table: "notifications" */
  delete_notifications: notifications_mutation_response | null
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk: notifications | null
  /** delete data from the table: "p2p_offers" */
  delete_p2p_offers: p2p_offers_mutation_response | null
  /** delete single row from the table: "p2p_offers" */
  delete_p2p_offers_by_pk: p2p_offers | null
  /** delete data from the table: "p2p_pre_sell_offers" */
  delete_p2p_pre_sell_offers: p2p_pre_sell_offers_mutation_response | null
  /** delete single row from the table: "p2p_pre_sell_offers" */
  delete_p2p_pre_sell_offers_by_pk: p2p_pre_sell_offers | null
  /** delete data from the table: "preferences" */
  delete_preferences: preferences_mutation_response | null
  /** delete single row from the table: "preferences" */
  delete_preferences_by_pk: preferences | null
  /** delete data from the table: "reg_accounts" */
  delete_reg_accounts: reg_accounts_mutation_response | null
  /** delete single row from the table: "reg_accounts" */
  delete_reg_accounts_by_pk: reg_accounts | null
  /** delete data from the table: "reputation_score" */
  delete_reputation_score: reputation_score_mutation_response | null
  /** delete single row from the table: "reputation_score" */
  delete_reputation_score_by_pk: reputation_score | null
  /** delete data from the table: "signing_requests" */
  delete_signing_requests: signing_requests_mutation_response | null
  /** delete single row from the table: "signing_requests" */
  delete_signing_requests_by_pk: signing_requests | null
  /** delete data from the table: "support_sessions" */
  delete_support_sessions: support_sessions_mutation_response | null
  /** delete single row from the table: "support_sessions" */
  delete_support_sessions_by_pk: support_sessions | null
  /** delete data from the table: "swap_assets" */
  delete_swap_assets: swap_assets_mutation_response | null
  /** delete single row from the table: "swap_assets" */
  delete_swap_assets_by_pk: swap_assets | null
  /** delete data from the table: "swap_orders" */
  delete_swap_orders: swap_orders_mutation_response | null
  /** delete single row from the table: "swap_orders" */
  delete_swap_orders_by_pk: swap_orders | null
  /** delete data from the table: "system_notification_read_status" */
  delete_system_notification_read_status: system_notification_read_status_mutation_response | null
  /** delete single row from the table: "system_notification_read_status" */
  delete_system_notification_read_status_by_pk: system_notification_read_status | null
  /** delete data from the table: "system_notifications" */
  delete_system_notifications: system_notifications_mutation_response | null
  /** delete single row from the table: "system_notifications" */
  delete_system_notifications_by_pk: system_notifications | null
  /** delete data from the table: "system_type_notification" */
  delete_system_type_notification: system_type_notification_mutation_response | null
  /** delete single row from the table: "system_type_notification" */
  delete_system_type_notification_by_pk: system_type_notification | null
  /** delete data from the table: "table_rows" */
  delete_table_rows: table_rows_mutation_response | null
  /** delete single row from the table: "table_rows" */
  delete_table_rows_by_pk: table_rows | null
  /** delete data from the table: "trust_network" */
  delete_trust_network: trust_network_mutation_response | null
  /** delete single row from the table: "trust_network" */
  delete_trust_network_by_pk: trust_network | null
  /** delete data from the table: "trust_network_notification" */
  delete_trust_network_notification: trust_network_notification_mutation_response | null
  /** delete single row from the table: "trust_network_notification" */
  delete_trust_network_notification_by_pk: trust_network_notification | null
  /** delete data from the table: "trust_network_status" */
  delete_trust_network_status: trust_network_status_mutation_response | null
  /** delete single row from the table: "trust_network_status" */
  delete_trust_network_status_by_pk: trust_network_status | null
  /** delete data from the table: "tx_statistics" */
  delete_tx_statistics: tx_statistics_mutation_response | null
  /** delete data from the table: "volume_statistics" */
  delete_volume_statistics: volume_statistics_mutation_response | null
  /** delete data from the table: "web_push" */
  delete_web_push: web_push_mutation_response | null
  /** delete single row from the table: "web_push" */
  delete_web_push_by_pk: web_push | null
  generate_short_link: Scalars['String']
  /** execute VOLATILE function "get_tx_statistics" which returns "tx_statistics" */
  get_tx_statistics: tx_statistics[]
  /** execute VOLATILE function "get_volume_statistics" which returns "volume_statistics" */
  get_volume_statistics: volume_statistics[]
  /** insert data into the table: "accounts_information" */
  insert_accounts_information: accounts_information_mutation_response | null
  /** insert a single row into the table: "accounts_information" */
  insert_accounts_information_one: accounts_information | null
  /** insert data into the table: "accounts_referral" */
  insert_accounts_referral: accounts_referral_mutation_response | null
  /** insert a single row into the table: "accounts_referral" */
  insert_accounts_referral_one: accounts_referral | null
  /** insert data into the table: "actions" */
  insert_actions: actions_mutation_response | null
  /** insert a single row into the table: "actions" */
  insert_actions_one: actions | null
  /** insert data into the table: "devices" */
  insert_devices: devices_mutation_response | null
  /** insert a single row into the table: "devices" */
  insert_devices_one: devices | null
  /** insert data into the table: "login_account" */
  insert_login_account: login_account_mutation_response | null
  /** insert a single row into the table: "login_account" */
  insert_login_account_one: login_account | null
  /** insert data into the table: "login_counts" */
  insert_login_counts: login_counts_mutation_response | null
  /** insert a single row into the table: "login_counts" */
  insert_login_counts_one: login_counts | null
  /** insert data into the table: "messages" */
  insert_messages: messages_mutation_response | null
  /** insert a single row into the table: "messages" */
  insert_messages_one: messages | null
  /** insert data into the table: "migrate_device" */
  insert_migrate_device: migrate_device_mutation_response | null
  /** insert a single row into the table: "migrate_device" */
  insert_migrate_device_one: migrate_device | null
  /** insert data into the table: "notifications" */
  insert_notifications: notifications_mutation_response | null
  /** insert a single row into the table: "notifications" */
  insert_notifications_one: notifications | null
  /** insert data into the table: "p2p_offers" */
  insert_p2p_offers: p2p_offers_mutation_response | null
  /** insert a single row into the table: "p2p_offers" */
  insert_p2p_offers_one: p2p_offers | null
  /** insert data into the table: "p2p_pre_sell_offers" */
  insert_p2p_pre_sell_offers: p2p_pre_sell_offers_mutation_response | null
  /** insert a single row into the table: "p2p_pre_sell_offers" */
  insert_p2p_pre_sell_offers_one: p2p_pre_sell_offers | null
  /** insert data into the table: "preferences" */
  insert_preferences: preferences_mutation_response | null
  /** insert a single row into the table: "preferences" */
  insert_preferences_one: preferences | null
  /** insert data into the table: "reg_accounts" */
  insert_reg_accounts: reg_accounts_mutation_response | null
  /** insert a single row into the table: "reg_accounts" */
  insert_reg_accounts_one: reg_accounts | null
  /** insert data into the table: "reputation_score" */
  insert_reputation_score: reputation_score_mutation_response | null
  /** insert a single row into the table: "reputation_score" */
  insert_reputation_score_one: reputation_score | null
  /** insert data into the table: "signing_requests" */
  insert_signing_requests: signing_requests_mutation_response | null
  /** insert a single row into the table: "signing_requests" */
  insert_signing_requests_one: signing_requests | null
  /** insert data into the table: "support_sessions" */
  insert_support_sessions: support_sessions_mutation_response | null
  /** insert a single row into the table: "support_sessions" */
  insert_support_sessions_one: support_sessions | null
  /** insert data into the table: "swap_assets" */
  insert_swap_assets: swap_assets_mutation_response | null
  /** insert a single row into the table: "swap_assets" */
  insert_swap_assets_one: swap_assets | null
  /** insert data into the table: "swap_orders" */
  insert_swap_orders: swap_orders_mutation_response | null
  /** insert a single row into the table: "swap_orders" */
  insert_swap_orders_one: swap_orders | null
  /** insert data into the table: "system_notification_read_status" */
  insert_system_notification_read_status: system_notification_read_status_mutation_response | null
  /** insert a single row into the table: "system_notification_read_status" */
  insert_system_notification_read_status_one: system_notification_read_status | null
  /** insert data into the table: "system_notifications" */
  insert_system_notifications: system_notifications_mutation_response | null
  /** insert a single row into the table: "system_notifications" */
  insert_system_notifications_one: system_notifications | null
  /** insert data into the table: "system_type_notification" */
  insert_system_type_notification: system_type_notification_mutation_response | null
  /** insert a single row into the table: "system_type_notification" */
  insert_system_type_notification_one: system_type_notification | null
  /** insert data into the table: "table_rows" */
  insert_table_rows: table_rows_mutation_response | null
  /** insert a single row into the table: "table_rows" */
  insert_table_rows_one: table_rows | null
  /** insert data into the table: "trust_network" */
  insert_trust_network: trust_network_mutation_response | null
  /** insert data into the table: "trust_network_notification" */
  insert_trust_network_notification: trust_network_notification_mutation_response | null
  /** insert a single row into the table: "trust_network_notification" */
  insert_trust_network_notification_one: trust_network_notification | null
  /** insert a single row into the table: "trust_network" */
  insert_trust_network_one: trust_network | null
  /** insert data into the table: "trust_network_status" */
  insert_trust_network_status: trust_network_status_mutation_response | null
  /** insert a single row into the table: "trust_network_status" */
  insert_trust_network_status_one: trust_network_status | null
  /** insert data into the table: "tx_statistics" */
  insert_tx_statistics: tx_statistics_mutation_response | null
  /** insert a single row into the table: "tx_statistics" */
  insert_tx_statistics_one: tx_statistics | null
  /** insert data into the table: "volume_statistics" */
  insert_volume_statistics: volume_statistics_mutation_response | null
  /** insert a single row into the table: "volume_statistics" */
  insert_volume_statistics_one: volume_statistics | null
  /** insert data into the table: "web_push" */
  insert_web_push: web_push_mutation_response | null
  /** insert a single row into the table: "web_push" */
  insert_web_push_one: web_push | null
  make_p2p_buy_offer: p2p_offer_output | null
  make_p2p_sell_offer: p2p_offer_output | null
  make_wallet_deposit_request: wallet_request_output | null
  make_wallet_withdraw_request: wallet_request_output | null
  reject_new_account: Scalars['String'] | null
  request_new_account: request_new_account_output | null
  reserve_offer: Scalars['Boolean'] | null
  send_push_notification: Scalars['Boolean']
  store_ip_address: store_ip_address_output | null
  subscribe_push_notification: Scalars['Boolean']
  toggle_trust_network: toggle_trust_network_output | null
  unreserve_offer: Scalars['Boolean'] | null
  /** update data of the table: "accounts_information" */
  update_accounts_information: accounts_information_mutation_response | null
  /** update single row of the table: "accounts_information" */
  update_accounts_information_by_pk: accounts_information | null
  /** update multiples rows of table: "accounts_information" */
  update_accounts_information_many: (accounts_information_mutation_response | null)[] | null
  /** update data of the table: "accounts_referral" */
  update_accounts_referral: accounts_referral_mutation_response | null
  /** update single row of the table: "accounts_referral" */
  update_accounts_referral_by_pk: accounts_referral | null
  /** update multiples rows of table: "accounts_referral" */
  update_accounts_referral_many: (accounts_referral_mutation_response | null)[] | null
  /** update data of the table: "actions" */
  update_actions: actions_mutation_response | null
  /** update single row of the table: "actions" */
  update_actions_by_pk: actions | null
  /** update multiples rows of table: "actions" */
  update_actions_many: (actions_mutation_response | null)[] | null
  /** update data of the table: "devices" */
  update_devices: devices_mutation_response | null
  /** update single row of the table: "devices" */
  update_devices_by_pk: devices | null
  /** update multiples rows of table: "devices" */
  update_devices_many: (devices_mutation_response | null)[] | null
  /** update data of the table: "login_account" */
  update_login_account: login_account_mutation_response | null
  /** update single row of the table: "login_account" */
  update_login_account_by_pk: login_account | null
  /** update multiples rows of table: "login_account" */
  update_login_account_many: (login_account_mutation_response | null)[] | null
  /** update data of the table: "login_counts" */
  update_login_counts: login_counts_mutation_response | null
  /** update multiples rows of table: "login_counts" */
  update_login_counts_many: (login_counts_mutation_response | null)[] | null
  /** update data of the table: "messages" */
  update_messages: messages_mutation_response | null
  /** update single row of the table: "messages" */
  update_messages_by_pk: messages | null
  /** update multiples rows of table: "messages" */
  update_messages_many: (messages_mutation_response | null)[] | null
  /** update data of the table: "migrate_device" */
  update_migrate_device: migrate_device_mutation_response | null
  /** update single row of the table: "migrate_device" */
  update_migrate_device_by_pk: migrate_device | null
  /** update multiples rows of table: "migrate_device" */
  update_migrate_device_many: (migrate_device_mutation_response | null)[] | null
  /** update data of the table: "notifications" */
  update_notifications: notifications_mutation_response | null
  /** update single row of the table: "notifications" */
  update_notifications_by_pk: notifications | null
  /** update multiples rows of table: "notifications" */
  update_notifications_many: (notifications_mutation_response | null)[] | null
  /** update data of the table: "p2p_offers" */
  update_p2p_offers: p2p_offers_mutation_response | null
  /** update single row of the table: "p2p_offers" */
  update_p2p_offers_by_pk: p2p_offers | null
  /** update multiples rows of table: "p2p_offers" */
  update_p2p_offers_many: (p2p_offers_mutation_response | null)[] | null
  /** update data of the table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers: p2p_pre_sell_offers_mutation_response | null
  /** update single row of the table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers_by_pk: p2p_pre_sell_offers | null
  /** update multiples rows of table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers_many: (p2p_pre_sell_offers_mutation_response | null)[] | null
  /** update data of the table: "preferences" */
  update_preferences: preferences_mutation_response | null
  /** update single row of the table: "preferences" */
  update_preferences_by_pk: preferences | null
  /** update multiples rows of table: "preferences" */
  update_preferences_many: (preferences_mutation_response | null)[] | null
  /** update data of the table: "reg_accounts" */
  update_reg_accounts: reg_accounts_mutation_response | null
  /** update single row of the table: "reg_accounts" */
  update_reg_accounts_by_pk: reg_accounts | null
  /** update multiples rows of table: "reg_accounts" */
  update_reg_accounts_many: (reg_accounts_mutation_response | null)[] | null
  /** update data of the table: "reputation_score" */
  update_reputation_score: reputation_score_mutation_response | null
  /** update single row of the table: "reputation_score" */
  update_reputation_score_by_pk: reputation_score | null
  /** update multiples rows of table: "reputation_score" */
  update_reputation_score_many: (reputation_score_mutation_response | null)[] | null
  /** update data of the table: "signing_requests" */
  update_signing_requests: signing_requests_mutation_response | null
  /** update single row of the table: "signing_requests" */
  update_signing_requests_by_pk: signing_requests | null
  /** update multiples rows of table: "signing_requests" */
  update_signing_requests_many: (signing_requests_mutation_response | null)[] | null
  /** update data of the table: "support_sessions" */
  update_support_sessions: support_sessions_mutation_response | null
  /** update single row of the table: "support_sessions" */
  update_support_sessions_by_pk: support_sessions | null
  /** update multiples rows of table: "support_sessions" */
  update_support_sessions_many: (support_sessions_mutation_response | null)[] | null
  /** update data of the table: "swap_assets" */
  update_swap_assets: swap_assets_mutation_response | null
  /** update single row of the table: "swap_assets" */
  update_swap_assets_by_pk: swap_assets | null
  /** update multiples rows of table: "swap_assets" */
  update_swap_assets_many: (swap_assets_mutation_response | null)[] | null
  /** update data of the table: "swap_orders" */
  update_swap_orders: swap_orders_mutation_response | null
  /** update single row of the table: "swap_orders" */
  update_swap_orders_by_pk: swap_orders | null
  /** update multiples rows of table: "swap_orders" */
  update_swap_orders_many: (swap_orders_mutation_response | null)[] | null
  /** update data of the table: "system_notification_read_status" */
  update_system_notification_read_status: system_notification_read_status_mutation_response | null
  /** update single row of the table: "system_notification_read_status" */
  update_system_notification_read_status_by_pk: system_notification_read_status | null
  /** update multiples rows of table: "system_notification_read_status" */
  update_system_notification_read_status_many:
    | (system_notification_read_status_mutation_response | null)[]
    | null
  /** update data of the table: "system_notifications" */
  update_system_notifications: system_notifications_mutation_response | null
  /** update single row of the table: "system_notifications" */
  update_system_notifications_by_pk: system_notifications | null
  /** update multiples rows of table: "system_notifications" */
  update_system_notifications_many: (system_notifications_mutation_response | null)[] | null
  /** update data of the table: "system_type_notification" */
  update_system_type_notification: system_type_notification_mutation_response | null
  /** update single row of the table: "system_type_notification" */
  update_system_type_notification_by_pk: system_type_notification | null
  /** update multiples rows of table: "system_type_notification" */
  update_system_type_notification_many: (system_type_notification_mutation_response | null)[] | null
  /** update data of the table: "table_rows" */
  update_table_rows: table_rows_mutation_response | null
  /** update single row of the table: "table_rows" */
  update_table_rows_by_pk: table_rows | null
  /** update multiples rows of table: "table_rows" */
  update_table_rows_many: (table_rows_mutation_response | null)[] | null
  /** update data of the table: "trust_network" */
  update_trust_network: trust_network_mutation_response | null
  /** update single row of the table: "trust_network" */
  update_trust_network_by_pk: trust_network | null
  /** update multiples rows of table: "trust_network" */
  update_trust_network_many: (trust_network_mutation_response | null)[] | null
  /** update data of the table: "trust_network_notification" */
  update_trust_network_notification: trust_network_notification_mutation_response | null
  /** update single row of the table: "trust_network_notification" */
  update_trust_network_notification_by_pk: trust_network_notification | null
  /** update multiples rows of table: "trust_network_notification" */
  update_trust_network_notification_many:
    | (trust_network_notification_mutation_response | null)[]
    | null
  /** update data of the table: "trust_network_status" */
  update_trust_network_status: trust_network_status_mutation_response | null
  /** update single row of the table: "trust_network_status" */
  update_trust_network_status_by_pk: trust_network_status | null
  /** update multiples rows of table: "trust_network_status" */
  update_trust_network_status_many: (trust_network_status_mutation_response | null)[] | null
  /** update data of the table: "tx_statistics" */
  update_tx_statistics: tx_statistics_mutation_response | null
  /** update multiples rows of table: "tx_statistics" */
  update_tx_statistics_many: (tx_statistics_mutation_response | null)[] | null
  /** update data of the table: "volume_statistics" */
  update_volume_statistics: volume_statistics_mutation_response | null
  /** update multiples rows of table: "volume_statistics" */
  update_volume_statistics_many: (volume_statistics_mutation_response | null)[] | null
  /** update data of the table: "web_push" */
  update_web_push: web_push_mutation_response | null
  /** update single row of the table: "web_push" */
  update_web_push_by_pk: web_push | null
  /** update multiples rows of table: "web_push" */
  update_web_push_many: (web_push_mutation_response | null)[] | null
  upload_image: upload_image_output | null
  validate_short_link: validate_short_link_output | null
  __typename: 'mutation_root'
}

/** columns and relationships of "notifications" */
export interface notifications {
  content_id: Scalars['uuid'] | null
  created_at: Scalars['timestamptz']
  from: Scalars['String']
  id: Scalars['uuid']
  /** An object relationship */
  notifications_reg_account: reg_accounts
  read: Scalars['Boolean']
  /** An object relationship */
  reg_account: reg_accounts
  to: Scalars['String']
  type: Scalars['String']
  __typename: 'notifications'
}

/** aggregated selection of "notifications" */
export interface notifications_aggregate {
  aggregate: notifications_aggregate_fields | null
  nodes: notifications[]
  __typename: 'notifications_aggregate'
}

/** aggregate fields of "notifications" */
export interface notifications_aggregate_fields {
  count: Scalars['Int']
  max: notifications_max_fields | null
  min: notifications_min_fields | null
  __typename: 'notifications_aggregate_fields'
}

/** unique or primary key constraints on table "notifications" */
export type notifications_constraint = 'notifications_pkey'

/** aggregate max on columns */
export interface notifications_max_fields {
  content_id: Scalars['uuid'] | null
  created_at: Scalars['timestamptz'] | null
  from: Scalars['String'] | null
  id: Scalars['uuid'] | null
  to: Scalars['String'] | null
  type: Scalars['String'] | null
  __typename: 'notifications_max_fields'
}

/** aggregate min on columns */
export interface notifications_min_fields {
  content_id: Scalars['uuid'] | null
  created_at: Scalars['timestamptz'] | null
  from: Scalars['String'] | null
  id: Scalars['uuid'] | null
  to: Scalars['String'] | null
  type: Scalars['String'] | null
  __typename: 'notifications_min_fields'
}

/** response of any mutation on the table "notifications" */
export interface notifications_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: notifications[]
  __typename: 'notifications_mutation_response'
}

/** select columns of table "notifications" */
export type notifications_select_column =
  | 'content_id'
  | 'created_at'
  | 'from'
  | 'id'
  | 'read'
  | 'to'
  | 'type'

/** select "notifications_aggregate_bool_exp_bool_and_arguments_columns" columns of table "notifications" */
export type notifications_select_column_notifications_aggregate_bool_exp_bool_and_arguments_columns =
  'read'

/** select "notifications_aggregate_bool_exp_bool_or_arguments_columns" columns of table "notifications" */
export type notifications_select_column_notifications_aggregate_bool_exp_bool_or_arguments_columns =
  'read'

/** update columns of table "notifications" */
export type notifications_update_column =
  | 'content_id'
  | 'created_at'
  | 'from'
  | 'id'
  | 'read'
  | 'to'
  | 'type'

/** column ordering options */
export type order_by =
  | 'asc'
  | 'asc_nulls_first'
  | 'asc_nulls_last'
  | 'desc'
  | 'desc_nulls_first'
  | 'desc_nulls_last'

export interface p2p_offer_output {
  amount: Scalars['String'] | null
  bitcashbank_id: Scalars['String'] | null
  buyer: Scalars['String'] | null
  buyer_confirmed_payment: Scalars['Boolean'] | null
  buyer_method_details: Scalars['String'] | null
  cancel_approval_datetime: Scalars['String'] | null
  cancel_request_datetime: Scalars['String'] | null
  cancellation_approved_by: Scalars['String'] | null
  cancellation_transaction: Scalars['String'] | null
  cancelled: Scalars['Boolean'] | null
  cancelled_by: Scalars['String'] | null
  completed: Scalars['Boolean'] | null
  created_at: Scalars['String'] | null
  id: Scalars['String'] | null
  initiator: Scalars['String'] | null
  matched: Scalars['Boolean'] | null
  matched_datetime: Scalars['String'] | null
  method: Scalars['String'] | null
  region: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  sell_settlement_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_confirmed_payment: Scalars['Boolean'] | null
  seller_method_details: Scalars['String'] | null
  type: Scalars['String'] | null
  updated_at: Scalars['String'] | null
  __typename: 'p2p_offer_output'
}

/** columns and relationships of "p2p_offers" */
export interface p2p_offers {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String'] | null
  buyer: Scalars['String'] | null
  buyer_confirmed_datetime: Scalars['timestamptz'] | null
  buyer_confirmed_payment: Scalars['Boolean']
  buyer_method_details: Scalars['String'] | null
  cancel_approval_datetime: Scalars['timestamptz'] | null
  cancel_request_datetime: Scalars['timestamptz'] | null
  cancellation_approved_by: Scalars['String'] | null
  cancellation_transaction: Scalars['String'] | null
  cancelled: Scalars['Boolean']
  cancelled_by: Scalars['String'] | null
  completed: Scalars['Boolean']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  initiator: Scalars['String']
  matched: Scalars['Boolean']
  matched_datetime: Scalars['timestamptz'] | null
  /** An array relationship */
  messages: messages[]
  /** An aggregate relationship */
  messages_aggregate: messages_aggregate
  method: Scalars['String']
  original_offer_id: Scalars['uuid'] | null
  /** An object relationship */
  regAccountByBuyer: reg_accounts | null
  /** An object relationship */
  regAccountByCancellationApprovedBy: reg_accounts | null
  /** An object relationship */
  regAccountByCancelledBy: reg_accounts | null
  /** An object relationship */
  regAccountByInitiator: reg_accounts
  /** An object relationship */
  regAccountBySeller: reg_accounts | null
  region: Scalars['String'] | null
  reserved_by: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  sell_settlement_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_confirmed_datetime: Scalars['timestamptz'] | null
  seller_confirmed_payment: Scalars['Boolean']
  seller_method_details: Scalars['String'] | null
  /** An array relationship */
  system_notifications: system_notifications[]
  /** An aggregate relationship */
  system_notifications_aggregate: system_notifications_aggregate
  type: Scalars['String']
  updated_at: Scalars['timestamptz']
  __typename: 'p2p_offers'
}

/** aggregated selection of "p2p_offers" */
export interface p2p_offers_aggregate {
  aggregate: p2p_offers_aggregate_fields | null
  nodes: p2p_offers[]
  __typename: 'p2p_offers_aggregate'
}

/** aggregate fields of "p2p_offers" */
export interface p2p_offers_aggregate_fields {
  count: Scalars['Int']
  max: p2p_offers_max_fields | null
  min: p2p_offers_min_fields | null
  __typename: 'p2p_offers_aggregate_fields'
}

/** unique or primary key constraints on table "p2p_offers" */
export type p2p_offers_constraint =
  | 'p2p_offers_cancellation_transaction_key'
  | 'p2p_offers_id_key'
  | 'p2p_offers_pkey'
  | 'p2p_offers_sell_put_transaction_key'
  | 'p2p_offers_sell_settlement_transaction_key'

/** aggregate max on columns */
export interface p2p_offers_max_fields {
  amount: Scalars['String'] | null
  bitcashbank_id: Scalars['String'] | null
  buyer: Scalars['String'] | null
  buyer_confirmed_datetime: Scalars['timestamptz'] | null
  buyer_method_details: Scalars['String'] | null
  cancel_approval_datetime: Scalars['timestamptz'] | null
  cancel_request_datetime: Scalars['timestamptz'] | null
  cancellation_approved_by: Scalars['String'] | null
  cancellation_transaction: Scalars['String'] | null
  cancelled_by: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  initiator: Scalars['String'] | null
  matched_datetime: Scalars['timestamptz'] | null
  method: Scalars['String'] | null
  original_offer_id: Scalars['uuid'] | null
  region: Scalars['String'] | null
  reserved_by: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  sell_settlement_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_confirmed_datetime: Scalars['timestamptz'] | null
  seller_method_details: Scalars['String'] | null
  type: Scalars['String'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'p2p_offers_max_fields'
}

/** aggregate min on columns */
export interface p2p_offers_min_fields {
  amount: Scalars['String'] | null
  bitcashbank_id: Scalars['String'] | null
  buyer: Scalars['String'] | null
  buyer_confirmed_datetime: Scalars['timestamptz'] | null
  buyer_method_details: Scalars['String'] | null
  cancel_approval_datetime: Scalars['timestamptz'] | null
  cancel_request_datetime: Scalars['timestamptz'] | null
  cancellation_approved_by: Scalars['String'] | null
  cancellation_transaction: Scalars['String'] | null
  cancelled_by: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  initiator: Scalars['String'] | null
  matched_datetime: Scalars['timestamptz'] | null
  method: Scalars['String'] | null
  original_offer_id: Scalars['uuid'] | null
  region: Scalars['String'] | null
  reserved_by: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  sell_settlement_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_confirmed_datetime: Scalars['timestamptz'] | null
  seller_method_details: Scalars['String'] | null
  type: Scalars['String'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'p2p_offers_min_fields'
}

/** response of any mutation on the table "p2p_offers" */
export interface p2p_offers_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: p2p_offers[]
  __typename: 'p2p_offers_mutation_response'
}

/** select columns of table "p2p_offers" */
export type p2p_offers_select_column =
  | 'amount'
  | 'bitcashbank_id'
  | 'buyer'
  | 'buyer_confirmed_datetime'
  | 'buyer_confirmed_payment'
  | 'buyer_method_details'
  | 'cancel_approval_datetime'
  | 'cancel_request_datetime'
  | 'cancellation_approved_by'
  | 'cancellation_transaction'
  | 'cancelled'
  | 'cancelled_by'
  | 'completed'
  | 'created_at'
  | 'id'
  | 'initiator'
  | 'matched'
  | 'matched_datetime'
  | 'method'
  | 'original_offer_id'
  | 'region'
  | 'reserved_by'
  | 'sell_put_transaction'
  | 'sell_settlement_transaction'
  | 'seller'
  | 'seller_confirmed_datetime'
  | 'seller_confirmed_payment'
  | 'seller_method_details'
  | 'type'
  | 'updated_at'

/** select "p2p_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "p2p_offers" */
export type p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_and_arguments_columns =
  | 'buyer_confirmed_payment'
  | 'cancelled'
  | 'completed'
  | 'matched'
  | 'seller_confirmed_payment'

/** select "p2p_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "p2p_offers" */
export type p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_or_arguments_columns =
  | 'buyer_confirmed_payment'
  | 'cancelled'
  | 'completed'
  | 'matched'
  | 'seller_confirmed_payment'

/** update columns of table "p2p_offers" */
export type p2p_offers_update_column =
  | 'amount'
  | 'bitcashbank_id'
  | 'buyer'
  | 'buyer_confirmed_datetime'
  | 'buyer_confirmed_payment'
  | 'buyer_method_details'
  | 'cancel_approval_datetime'
  | 'cancel_request_datetime'
  | 'cancellation_approved_by'
  | 'cancellation_transaction'
  | 'cancelled'
  | 'cancelled_by'
  | 'completed'
  | 'created_at'
  | 'id'
  | 'initiator'
  | 'matched'
  | 'matched_datetime'
  | 'method'
  | 'original_offer_id'
  | 'region'
  | 'reserved_by'
  | 'sell_put_transaction'
  | 'sell_settlement_transaction'
  | 'seller'
  | 'seller_confirmed_datetime'
  | 'seller_confirmed_payment'
  | 'seller_method_details'
  | 'type'
  | 'updated_at'

/** columns and relationships of "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  method: Scalars['String']
  processed: Scalars['Boolean']
  /** An object relationship */
  reg_account: reg_accounts
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller: Scalars['String']
  seller_method_details: Scalars['String'] | null
  updated_at: Scalars['timestamptz']
  __typename: 'p2p_pre_sell_offers'
}

/** aggregated selection of "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_aggregate {
  aggregate: p2p_pre_sell_offers_aggregate_fields | null
  nodes: p2p_pre_sell_offers[]
  __typename: 'p2p_pre_sell_offers_aggregate'
}

/** aggregate fields of "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_aggregate_fields {
  count: Scalars['Int']
  max: p2p_pre_sell_offers_max_fields | null
  min: p2p_pre_sell_offers_min_fields | null
  __typename: 'p2p_pre_sell_offers_aggregate_fields'
}

/** unique or primary key constraints on table "p2p_pre_sell_offers" */
export type p2p_pre_sell_offers_constraint =
  | 'p2p_pre_sell_offers_pkey'
  | 'p2p_pre_sell_offers_sell_put_transaction_key'

/** aggregate max on columns */
export interface p2p_pre_sell_offers_max_fields {
  amount: Scalars['String'] | null
  bitcashbank_id: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  method: Scalars['String'] | null
  region: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_method_details: Scalars['String'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'p2p_pre_sell_offers_max_fields'
}

/** aggregate min on columns */
export interface p2p_pre_sell_offers_min_fields {
  amount: Scalars['String'] | null
  bitcashbank_id: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  method: Scalars['String'] | null
  region: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_method_details: Scalars['String'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'p2p_pre_sell_offers_min_fields'
}

/** response of any mutation on the table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: p2p_pre_sell_offers[]
  __typename: 'p2p_pre_sell_offers_mutation_response'
}

/** select columns of table "p2p_pre_sell_offers" */
export type p2p_pre_sell_offers_select_column =
  | 'amount'
  | 'bitcashbank_id'
  | 'created_at'
  | 'id'
  | 'method'
  | 'processed'
  | 'region'
  | 'sell_put_transaction'
  | 'seller'
  | 'seller_method_details'
  | 'updated_at'

/** select "p2p_pre_sell_offers_aggregate_bool_exp_bool_and_arguments_columns" columns of table "p2p_pre_sell_offers" */
export type p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_and_arguments_columns =
  'processed'

/** select "p2p_pre_sell_offers_aggregate_bool_exp_bool_or_arguments_columns" columns of table "p2p_pre_sell_offers" */
export type p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_or_arguments_columns =
  'processed'

/** update columns of table "p2p_pre_sell_offers" */
export type p2p_pre_sell_offers_update_column =
  | 'amount'
  | 'bitcashbank_id'
  | 'created_at'
  | 'id'
  | 'method'
  | 'processed'
  | 'region'
  | 'sell_put_transaction'
  | 'seller'
  | 'seller_method_details'
  | 'updated_at'

export interface photo_id_verification_output {
  error: Scalars['Boolean']
  __typename: 'photo_id_verification_output'
}

/** columns and relationships of "preferences" */
export interface preferences {
  account: Scalars['String']
  currency: Scalars['String']
  language: Scalars['String']
  notifications: Scalars['Boolean']
  personalized: Scalars['Boolean']
  region: Scalars['String']
  secondary_currency: Scalars['jsonb'] | null
  theme: Scalars['String']
  __typename: 'preferences'
}

/** aggregated selection of "preferences" */
export interface preferences_aggregate {
  aggregate: preferences_aggregate_fields | null
  nodes: preferences[]
  __typename: 'preferences_aggregate'
}

/** aggregate fields of "preferences" */
export interface preferences_aggregate_fields {
  count: Scalars['Int']
  max: preferences_max_fields | null
  min: preferences_min_fields | null
  __typename: 'preferences_aggregate_fields'
}

/** unique or primary key constraints on table "preferences" */
export type preferences_constraint = 'preferences_pkey'

/** aggregate max on columns */
export interface preferences_max_fields {
  account: Scalars['String'] | null
  currency: Scalars['String'] | null
  language: Scalars['String'] | null
  region: Scalars['String'] | null
  theme: Scalars['String'] | null
  __typename: 'preferences_max_fields'
}

/** aggregate min on columns */
export interface preferences_min_fields {
  account: Scalars['String'] | null
  currency: Scalars['String'] | null
  language: Scalars['String'] | null
  region: Scalars['String'] | null
  theme: Scalars['String'] | null
  __typename: 'preferences_min_fields'
}

/** response of any mutation on the table "preferences" */
export interface preferences_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: preferences[]
  __typename: 'preferences_mutation_response'
}

/** select columns of table "preferences" */
export type preferences_select_column =
  | 'account'
  | 'currency'
  | 'language'
  | 'notifications'
  | 'personalized'
  | 'region'
  | 'secondary_currency'
  | 'theme'

/** update columns of table "preferences" */
export type preferences_update_column =
  | 'account'
  | 'currency'
  | 'language'
  | 'notifications'
  | 'personalized'
  | 'region'
  | 'secondary_currency'
  | 'theme'

export interface query_root {
  /** fetch data from the table: "account_statistics" */
  account_statistics: account_statistics[]
  /** fetch aggregated fields from the table: "account_statistics" */
  account_statistics_aggregate: account_statistics_aggregate
  /** fetch data from the table: "accounts_information" */
  accounts_information: accounts_information[]
  /** fetch aggregated fields from the table: "accounts_information" */
  accounts_information_aggregate: accounts_information_aggregate
  /** fetch data from the table: "accounts_information" using primary key columns */
  accounts_information_by_pk: accounts_information | null
  /** fetch data from the table: "accounts_referral" */
  accounts_referral: accounts_referral[]
  /** fetch aggregated fields from the table: "accounts_referral" */
  accounts_referral_aggregate: accounts_referral_aggregate
  /** fetch data from the table: "accounts_referral" using primary key columns */
  accounts_referral_by_pk: accounts_referral | null
  /** fetch data from the table: "actions" */
  actions: actions[]
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: actions_aggregate
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk: actions | null
  apollo_auth_health_check: Scalars['String'] | null
  apollo_health_check: Scalars['Boolean'] | null
  /** fetch data from the table: "avg_pay_time" */
  avg_pay_time: avg_pay_time[]
  /** fetch aggregated fields from the table: "avg_pay_time" */
  avg_pay_time_aggregate: avg_pay_time_aggregate
  /** fetch data from the table: "avg_release_time" */
  avg_release_time: avg_release_time[]
  /** fetch aggregated fields from the table: "avg_release_time" */
  avg_release_time_aggregate: avg_release_time_aggregate
  /** fetch data from the table: "combined_avg_times" */
  combined_avg_times: combined_avg_times[]
  /** fetch aggregated fields from the table: "combined_avg_times" */
  combined_avg_times_aggregate: combined_avg_times_aggregate
  /** An array relationship */
  devices: devices[]
  /** An aggregate relationship */
  devices_aggregate: devices_aggregate
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk: devices | null
  /** execute function "get_inactive_accounts" which returns "reg_accounts" */
  get_inactive_accounts: reg_accounts[]
  /** execute function "get_inactive_accounts" and query aggregates on result of table type "reg_accounts" */
  get_inactive_accounts_aggregate: reg_accounts_aggregate
  /** execute function "get_login_counts" which returns "login_counts" */
  get_login_counts: login_counts[]
  /** execute function "get_login_counts" and query aggregates on result of table type "login_counts" */
  get_login_counts_aggregate: login_counts_aggregate
  /** execute function "get_p2p_offers_with_old_or_no_messages" which returns "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages: p2p_offers[]
  /** execute function "get_p2p_offers_with_old_or_no_messages" and query aggregates on result of table type "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages_aggregate: p2p_offers_aggregate
  get_trust_network: trust_network_output | null
  id_verification: id_verification_output | null
  /** fetch data from the table: "login_account" */
  login_account: login_account[]
  /** fetch aggregated fields from the table: "login_account" */
  login_account_aggregate: login_account_aggregate
  /** fetch data from the table: "login_account" using primary key columns */
  login_account_by_pk: login_account | null
  /** fetch data from the table: "login_counts" */
  login_counts: login_counts[]
  /** fetch aggregated fields from the table: "login_counts" */
  login_counts_aggregate: login_counts_aggregate
  /** An array relationship */
  messages: messages[]
  /** An aggregate relationship */
  messages_aggregate: messages_aggregate
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk: messages | null
  /** fetch data from the table: "migrate_device" */
  migrate_device: migrate_device[]
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate: migrate_device_aggregate
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk: migrate_device | null
  /** An array relationship */
  notifications: notifications[]
  /** An aggregate relationship */
  notifications_aggregate: notifications_aggregate
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk: notifications | null
  /** An array relationship */
  p2p_offers: p2p_offers[]
  /** An aggregate relationship */
  p2p_offers_aggregate: p2p_offers_aggregate
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk: p2p_offers | null
  /** An array relationship */
  p2p_pre_sell_offers: p2p_pre_sell_offers[]
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate: p2p_pre_sell_offers_aggregate
  /** fetch data from the table: "p2p_pre_sell_offers" using primary key columns */
  p2p_pre_sell_offers_by_pk: p2p_pre_sell_offers | null
  photo_id_verification: photo_id_verification_output | null
  /** fetch data from the table: "preferences" */
  preferences: preferences[]
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: preferences_aggregate
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk: preferences | null
  /** fetch data from the table: "reg_accounts" */
  reg_accounts: reg_accounts[]
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate: reg_accounts_aggregate
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk: reg_accounts | null
  /** fetch data from the table: "reputation_score" */
  reputation_score: reputation_score[]
  /** fetch aggregated fields from the table: "reputation_score" */
  reputation_score_aggregate: reputation_score_aggregate
  /** fetch data from the table: "reputation_score" using primary key columns */
  reputation_score_by_pk: reputation_score | null
  selfie_verification: selfie_verification_output | null
  /** fetch data from the table: "signing_requests" */
  signing_requests: signing_requests[]
  /** fetch aggregated fields from the table: "signing_requests" */
  signing_requests_aggregate: signing_requests_aggregate
  /** fetch data from the table: "signing_requests" using primary key columns */
  signing_requests_by_pk: signing_requests | null
  /** An array relationship */
  support_sessions: support_sessions[]
  /** An aggregate relationship */
  support_sessions_aggregate: support_sessions_aggregate
  /** fetch data from the table: "support_sessions" using primary key columns */
  support_sessions_by_pk: support_sessions | null
  /** fetch data from the table: "swap_assets" */
  swap_assets: swap_assets[]
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate: swap_assets_aggregate
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk: swap_assets | null
  /** An array relationship */
  swap_orders: swap_orders[]
  /** An aggregate relationship */
  swap_orders_aggregate: swap_orders_aggregate
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk: swap_orders | null
  /** fetch data from the table: "system_notification_read_status" */
  system_notification_read_status: system_notification_read_status[]
  /** fetch aggregated fields from the table: "system_notification_read_status" */
  system_notification_read_status_aggregate: system_notification_read_status_aggregate
  /** fetch data from the table: "system_notification_read_status" using primary key columns */
  system_notification_read_status_by_pk: system_notification_read_status | null
  /** An array relationship */
  system_notifications: system_notifications[]
  /** An aggregate relationship */
  system_notifications_aggregate: system_notifications_aggregate
  /** fetch data from the table: "system_notifications" using primary key columns */
  system_notifications_by_pk: system_notifications | null
  /** fetch data from the table: "system_type_notification" */
  system_type_notification: system_type_notification[]
  /** fetch aggregated fields from the table: "system_type_notification" */
  system_type_notification_aggregate: system_type_notification_aggregate
  /** fetch data from the table: "system_type_notification" using primary key columns */
  system_type_notification_by_pk: system_type_notification | null
  /** fetch data from the table: "table_rows" */
  table_rows: table_rows[]
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: table_rows_aggregate
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk: table_rows | null
  /** fetch data from the table: "trust_network" */
  trust_network: trust_network[]
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate: trust_network_aggregate
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk: trust_network | null
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification: trust_network_notification[]
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate: trust_network_notification_aggregate
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk: trust_network_notification | null
  /** fetch data from the table: "trust_network_status" */
  trust_network_status: trust_network_status[]
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate: trust_network_status_aggregate
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk: trust_network_status | null
  /** fetch data from the table: "tx_statistics" */
  tx_statistics: tx_statistics[]
  /** fetch aggregated fields from the table: "tx_statistics" */
  tx_statistics_aggregate: tx_statistics_aggregate
  /** fetch data from the table: "volume_statistics" */
  volume_statistics: volume_statistics[]
  /** fetch aggregated fields from the table: "volume_statistics" */
  volume_statistics_aggregate: volume_statistics_aggregate
  /** fetch data from the table: "web_push" */
  web_push: web_push[]
  /** fetch aggregated fields from the table: "web_push" */
  web_push_aggregate: web_push_aggregate
  /** fetch data from the table: "web_push" using primary key columns */
  web_push_by_pk: web_push | null
  __typename: 'query_root'
}

/** columns and relationships of "reg_accounts" */
export interface reg_accounts {
  account: Scalars['String']
  /** An object relationship */
  accounts_information: accounts_information | null
  /** An array relationship */
  accounts_referrals: accounts_referral[]
  /** An aggregate relationship */
  accounts_referrals_aggregate: accounts_referral_aggregate
  create_account: Scalars['Boolean'] | null
  created: Scalars['timestamptz'] | null
  created_at: Scalars['timestamp'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  /** An array relationship */
  devices: devices[]
  /** An aggregate relationship */
  devices_aggregate: devices_aggregate
  freezed: Scalars['Boolean']
  id: Scalars['uuid']
  is_verified: Scalars['Boolean'] | null
  /** An array relationship */
  login_accounts: login_account[]
  /** An aggregate relationship */
  login_accounts_aggregate: login_account_aggregate
  /** An array relationship */
  messages: messages[]
  /** An array relationship */
  messagesByTo: messages[]
  /** An aggregate relationship */
  messagesByTo_aggregate: messages_aggregate
  /** An aggregate relationship */
  messages_aggregate: messages_aggregate
  /** An array relationship */
  notifications: notifications[]
  /** An array relationship */
  notificationsByFrom: notifications[]
  /** An aggregate relationship */
  notificationsByFrom_aggregate: notifications_aggregate
  /** An aggregate relationship */
  notifications_aggregate: notifications_aggregate
  onboarded: Scalars['Boolean'] | null
  /** An array relationship */
  p2p_offers: p2p_offers[]
  /** An aggregate relationship */
  p2p_offers_aggregate: p2p_offers_aggregate
  /** An array relationship */
  p2p_offers_buyer: p2p_offers[]
  /** An aggregate relationship */
  p2p_offers_buyer_aggregate: p2p_offers_aggregate
  /** An array relationship */
  p2p_offers_initiator: p2p_offers[]
  /** An aggregate relationship */
  p2p_offers_initiator_aggregate: p2p_offers_aggregate
  /** An array relationship */
  p2p_offers_seller: p2p_offers[]
  /** An aggregate relationship */
  p2p_offers_seller_aggregate: p2p_offers_aggregate
  /** An array relationship */
  p2p_pre_sell_offers: p2p_pre_sell_offers[]
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate: p2p_pre_sell_offers_aggregate
  public_key: Scalars['String'] | null
  referrer: Scalars['String']
  /** An array relationship */
  regAccountsByCancelledBy: p2p_offers[]
  /** An aggregate relationship */
  regAccountsByCancelledBy_aggregate: p2p_offers_aggregate
  /** An array relationship */
  reg_accounts_accounts_information: accounts_information[]
  /** An aggregate relationship */
  reg_accounts_accounts_information_aggregate: accounts_information_aggregate
  /** An object relationship */
  reputation_score: reputation_score | null
  role: Scalars['String']
  source: Scalars['String'] | null
  /** An array relationship */
  support_sessions: support_sessions[]
  /** An aggregate relationship */
  support_sessions_aggregate: support_sessions_aggregate
  /** An array relationship */
  swap_orders: swap_orders[]
  /** An aggregate relationship */
  swap_orders_aggregate: swap_orders_aggregate
  /** An array relationship */
  system_notification_read_statuses: system_notification_read_status[]
  /** An aggregate relationship */
  system_notification_read_statuses_aggregate: system_notification_read_status_aggregate
  /** An array relationship */
  trustNetworkNotificationsByTrust: trust_network_notification[]
  /** An aggregate relationship */
  trustNetworkNotificationsByTrust_aggregate: trust_network_notification_aggregate
  /** An array relationship */
  trust_network_notifications: trust_network_notification[]
  /** An aggregate relationship */
  trust_network_notifications_aggregate: trust_network_notification_aggregate
  /** An array relationship */
  trust_networks_by_account: trust_network[]
  /** An aggregate relationship */
  trust_networks_by_account_aggregate: trust_network_aggregate
  /** An array relationship */
  trust_networks_by_trust: trust_network[]
  /** An aggregate relationship */
  trust_networks_by_trust_aggregate: trust_network_aggregate
  txid: Scalars['String'] | null
  __typename: 'reg_accounts'
}

export interface reg_accounts_aggregate {
  aggregate: reg_accounts_aggregate_fields | null
  nodes: reg_accounts[]
  __typename: 'reg_accounts_aggregate'
}

/** aggregate fields of "reg_accounts" */
export interface reg_accounts_aggregate_fields {
  count: Scalars['Int']
  max: reg_accounts_max_fields | null
  min: reg_accounts_min_fields | null
  __typename: 'reg_accounts_aggregate_fields'
}

/** unique or primary key constraints on table "reg_accounts" */
export type reg_accounts_constraint = 'accounts_pkey' | 'reg_accounts_account_key'

/** aggregate max on columns */
export interface reg_accounts_max_fields {
  account: Scalars['String'] | null
  created: Scalars['timestamptz'] | null
  created_at: Scalars['timestamp'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  id: Scalars['uuid'] | null
  public_key: Scalars['String'] | null
  referrer: Scalars['String'] | null
  role: Scalars['String'] | null
  source: Scalars['String'] | null
  txid: Scalars['String'] | null
  __typename: 'reg_accounts_max_fields'
}

/** aggregate min on columns */
export interface reg_accounts_min_fields {
  account: Scalars['String'] | null
  created: Scalars['timestamptz'] | null
  created_at: Scalars['timestamp'] | null
  cred_id: Scalars['String'] | null
  device_name: Scalars['String'] | null
  id: Scalars['uuid'] | null
  public_key: Scalars['String'] | null
  referrer: Scalars['String'] | null
  role: Scalars['String'] | null
  source: Scalars['String'] | null
  txid: Scalars['String'] | null
  __typename: 'reg_accounts_min_fields'
}

/** response of any mutation on the table "reg_accounts" */
export interface reg_accounts_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: reg_accounts[]
  __typename: 'reg_accounts_mutation_response'
}

/** select columns of table "reg_accounts" */
export type reg_accounts_select_column =
  | 'account'
  | 'create_account'
  | 'created'
  | 'created_at'
  | 'cred_id'
  | 'device_name'
  | 'freezed'
  | 'id'
  | 'is_verified'
  | 'onboarded'
  | 'public_key'
  | 'referrer'
  | 'role'
  | 'source'
  | 'txid'

/** update columns of table "reg_accounts" */
export type reg_accounts_update_column =
  | 'account'
  | 'create_account'
  | 'created'
  | 'created_at'
  | 'cred_id'
  | 'device_name'
  | 'freezed'
  | 'id'
  | 'is_verified'
  | 'onboarded'
  | 'public_key'
  | 'referrer'
  | 'role'
  | 'source'
  | 'txid'

/** columns and relationships of "reputation_score" */
export interface reputation_score {
  account: Scalars['String']
  /** An object relationship */
  reg_account: reg_accounts
  score: Scalars['numeric']
  updated_at: Scalars['timestamptz']
  __typename: 'reputation_score'
}

/** aggregated selection of "reputation_score" */
export interface reputation_score_aggregate {
  aggregate: reputation_score_aggregate_fields | null
  nodes: reputation_score[]
  __typename: 'reputation_score_aggregate'
}

/** aggregate fields of "reputation_score" */
export interface reputation_score_aggregate_fields {
  avg: reputation_score_avg_fields | null
  count: Scalars['Int']
  max: reputation_score_max_fields | null
  min: reputation_score_min_fields | null
  stddev: reputation_score_stddev_fields | null
  stddev_pop: reputation_score_stddev_pop_fields | null
  stddev_samp: reputation_score_stddev_samp_fields | null
  sum: reputation_score_sum_fields | null
  var_pop: reputation_score_var_pop_fields | null
  var_samp: reputation_score_var_samp_fields | null
  variance: reputation_score_variance_fields | null
  __typename: 'reputation_score_aggregate_fields'
}

/** aggregate avg on columns */
export interface reputation_score_avg_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_avg_fields'
}

/** unique or primary key constraints on table "reputation_score" */
export type reputation_score_constraint = 'reputation_score_pkey'

/** aggregate max on columns */
export interface reputation_score_max_fields {
  account: Scalars['String'] | null
  score: Scalars['numeric'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'reputation_score_max_fields'
}

/** aggregate min on columns */
export interface reputation_score_min_fields {
  account: Scalars['String'] | null
  score: Scalars['numeric'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'reputation_score_min_fields'
}

/** response of any mutation on the table "reputation_score" */
export interface reputation_score_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: reputation_score[]
  __typename: 'reputation_score_mutation_response'
}

/** select columns of table "reputation_score" */
export type reputation_score_select_column = 'account' | 'score' | 'updated_at'

/** aggregate stddev on columns */
export interface reputation_score_stddev_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface reputation_score_stddev_pop_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface reputation_score_stddev_samp_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface reputation_score_sum_fields {
  score: Scalars['numeric'] | null
  __typename: 'reputation_score_sum_fields'
}

/** update columns of table "reputation_score" */
export type reputation_score_update_column = 'account' | 'score' | 'updated_at'

/** aggregate var_pop on columns */
export interface reputation_score_var_pop_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface reputation_score_var_samp_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_var_samp_fields'
}

/** aggregate variance on columns */
export interface reputation_score_variance_fields {
  score: Scalars['Float'] | null
  __typename: 'reputation_score_variance_fields'
}

export interface request_new_account_output {
  id: Scalars['String']
  __typename: 'request_new_account_output'
}

export interface selfie_verification_output {
  error: Scalars['Boolean']
  eye: Scalars['Boolean']
  face: Scalars['Boolean']
  __typename: 'selfie_verification_output'
}

/** columns and relationships of "signing_requests" */
export interface signing_requests {
  createdAt: Scalars['timestamptz']
  esr: Scalars['String']
  id: Scalars['uuid']
  signature: Scalars['String'] | null
  signer: Scalars['String']
  status: Scalars['String']
  transactionId: Scalars['String'] | null
  updatedAt: Scalars['timestamptz']
  __typename: 'signing_requests'
}

/** aggregated selection of "signing_requests" */
export interface signing_requests_aggregate {
  aggregate: signing_requests_aggregate_fields | null
  nodes: signing_requests[]
  __typename: 'signing_requests_aggregate'
}

/** aggregate fields of "signing_requests" */
export interface signing_requests_aggregate_fields {
  count: Scalars['Int']
  max: signing_requests_max_fields | null
  min: signing_requests_min_fields | null
  __typename: 'signing_requests_aggregate_fields'
}

/** unique or primary key constraints on table "signing_requests" */
export type signing_requests_constraint = 'signing_requests_pkey'

/** aggregate max on columns */
export interface signing_requests_max_fields {
  createdAt: Scalars['timestamptz'] | null
  esr: Scalars['String'] | null
  id: Scalars['uuid'] | null
  signature: Scalars['String'] | null
  signer: Scalars['String'] | null
  status: Scalars['String'] | null
  transactionId: Scalars['String'] | null
  updatedAt: Scalars['timestamptz'] | null
  __typename: 'signing_requests_max_fields'
}

/** aggregate min on columns */
export interface signing_requests_min_fields {
  createdAt: Scalars['timestamptz'] | null
  esr: Scalars['String'] | null
  id: Scalars['uuid'] | null
  signature: Scalars['String'] | null
  signer: Scalars['String'] | null
  status: Scalars['String'] | null
  transactionId: Scalars['String'] | null
  updatedAt: Scalars['timestamptz'] | null
  __typename: 'signing_requests_min_fields'
}

/** response of any mutation on the table "signing_requests" */
export interface signing_requests_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: signing_requests[]
  __typename: 'signing_requests_mutation_response'
}

/** select columns of table "signing_requests" */
export type signing_requests_select_column =
  | 'createdAt'
  | 'esr'
  | 'id'
  | 'signature'
  | 'signer'
  | 'status'
  | 'transactionId'
  | 'updatedAt'

/** update columns of table "signing_requests" */
export type signing_requests_update_column =
  | 'createdAt'
  | 'esr'
  | 'id'
  | 'signature'
  | 'signer'
  | 'status'
  | 'transactionId'
  | 'updatedAt'

export interface store_ip_address_output {
  success: Scalars['Boolean']
  __typename: 'store_ip_address_output'
}

export interface subscription_root {
  /** fetch data from the table: "account_statistics" */
  account_statistics: account_statistics[]
  /** fetch aggregated fields from the table: "account_statistics" */
  account_statistics_aggregate: account_statistics_aggregate
  /** fetch data from the table in a streaming manner: "account_statistics" */
  account_statistics_stream: account_statistics[]
  /** fetch data from the table: "accounts_information" */
  accounts_information: accounts_information[]
  /** fetch aggregated fields from the table: "accounts_information" */
  accounts_information_aggregate: accounts_information_aggregate
  /** fetch data from the table: "accounts_information" using primary key columns */
  accounts_information_by_pk: accounts_information | null
  /** fetch data from the table in a streaming manner: "accounts_information" */
  accounts_information_stream: accounts_information[]
  /** fetch data from the table: "accounts_referral" */
  accounts_referral: accounts_referral[]
  /** fetch aggregated fields from the table: "accounts_referral" */
  accounts_referral_aggregate: accounts_referral_aggregate
  /** fetch data from the table: "accounts_referral" using primary key columns */
  accounts_referral_by_pk: accounts_referral | null
  /** fetch data from the table in a streaming manner: "accounts_referral" */
  accounts_referral_stream: accounts_referral[]
  /** fetch data from the table: "actions" */
  actions: actions[]
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: actions_aggregate
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk: actions | null
  /** fetch data from the table in a streaming manner: "actions" */
  actions_stream: actions[]
  /** fetch data from the table: "avg_pay_time" */
  avg_pay_time: avg_pay_time[]
  /** fetch aggregated fields from the table: "avg_pay_time" */
  avg_pay_time_aggregate: avg_pay_time_aggregate
  /** fetch data from the table in a streaming manner: "avg_pay_time" */
  avg_pay_time_stream: avg_pay_time[]
  /** fetch data from the table: "avg_release_time" */
  avg_release_time: avg_release_time[]
  /** fetch aggregated fields from the table: "avg_release_time" */
  avg_release_time_aggregate: avg_release_time_aggregate
  /** fetch data from the table in a streaming manner: "avg_release_time" */
  avg_release_time_stream: avg_release_time[]
  /** fetch data from the table: "combined_avg_times" */
  combined_avg_times: combined_avg_times[]
  /** fetch aggregated fields from the table: "combined_avg_times" */
  combined_avg_times_aggregate: combined_avg_times_aggregate
  /** fetch data from the table in a streaming manner: "combined_avg_times" */
  combined_avg_times_stream: combined_avg_times[]
  /** An array relationship */
  devices: devices[]
  /** An aggregate relationship */
  devices_aggregate: devices_aggregate
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk: devices | null
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream: devices[]
  /** execute function "get_inactive_accounts" which returns "reg_accounts" */
  get_inactive_accounts: reg_accounts[]
  /** execute function "get_inactive_accounts" and query aggregates on result of table type "reg_accounts" */
  get_inactive_accounts_aggregate: reg_accounts_aggregate
  /** execute function "get_login_counts" which returns "login_counts" */
  get_login_counts: login_counts[]
  /** execute function "get_login_counts" and query aggregates on result of table type "login_counts" */
  get_login_counts_aggregate: login_counts_aggregate
  /** execute function "get_p2p_offers_with_old_or_no_messages" which returns "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages: p2p_offers[]
  /** execute function "get_p2p_offers_with_old_or_no_messages" and query aggregates on result of table type "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages_aggregate: p2p_offers_aggregate
  /** fetch data from the table: "login_account" */
  login_account: login_account[]
  /** fetch aggregated fields from the table: "login_account" */
  login_account_aggregate: login_account_aggregate
  /** fetch data from the table: "login_account" using primary key columns */
  login_account_by_pk: login_account | null
  /** fetch data from the table in a streaming manner: "login_account" */
  login_account_stream: login_account[]
  /** fetch data from the table: "login_counts" */
  login_counts: login_counts[]
  /** fetch aggregated fields from the table: "login_counts" */
  login_counts_aggregate: login_counts_aggregate
  /** fetch data from the table in a streaming manner: "login_counts" */
  login_counts_stream: login_counts[]
  /** An array relationship */
  messages: messages[]
  /** An aggregate relationship */
  messages_aggregate: messages_aggregate
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk: messages | null
  /** fetch data from the table in a streaming manner: "messages" */
  messages_stream: messages[]
  /** fetch data from the table: "migrate_device" */
  migrate_device: migrate_device[]
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate: migrate_device_aggregate
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk: migrate_device | null
  /** fetch data from the table in a streaming manner: "migrate_device" */
  migrate_device_stream: migrate_device[]
  /** An array relationship */
  notifications: notifications[]
  /** An aggregate relationship */
  notifications_aggregate: notifications_aggregate
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk: notifications | null
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: notifications[]
  /** An array relationship */
  p2p_offers: p2p_offers[]
  /** An aggregate relationship */
  p2p_offers_aggregate: p2p_offers_aggregate
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk: p2p_offers | null
  /** fetch data from the table in a streaming manner: "p2p_offers" */
  p2p_offers_stream: p2p_offers[]
  /** An array relationship */
  p2p_pre_sell_offers: p2p_pre_sell_offers[]
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate: p2p_pre_sell_offers_aggregate
  /** fetch data from the table: "p2p_pre_sell_offers" using primary key columns */
  p2p_pre_sell_offers_by_pk: p2p_pre_sell_offers | null
  /** fetch data from the table in a streaming manner: "p2p_pre_sell_offers" */
  p2p_pre_sell_offers_stream: p2p_pre_sell_offers[]
  /** fetch data from the table: "preferences" */
  preferences: preferences[]
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate: preferences_aggregate
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk: preferences | null
  /** fetch data from the table in a streaming manner: "preferences" */
  preferences_stream: preferences[]
  /** fetch data from the table: "reg_accounts" */
  reg_accounts: reg_accounts[]
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate: reg_accounts_aggregate
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk: reg_accounts | null
  /** fetch data from the table in a streaming manner: "reg_accounts" */
  reg_accounts_stream: reg_accounts[]
  /** fetch data from the table: "reputation_score" */
  reputation_score: reputation_score[]
  /** fetch aggregated fields from the table: "reputation_score" */
  reputation_score_aggregate: reputation_score_aggregate
  /** fetch data from the table: "reputation_score" using primary key columns */
  reputation_score_by_pk: reputation_score | null
  /** fetch data from the table in a streaming manner: "reputation_score" */
  reputation_score_stream: reputation_score[]
  /** fetch data from the table: "signing_requests" */
  signing_requests: signing_requests[]
  /** fetch aggregated fields from the table: "signing_requests" */
  signing_requests_aggregate: signing_requests_aggregate
  /** fetch data from the table: "signing_requests" using primary key columns */
  signing_requests_by_pk: signing_requests | null
  /** fetch data from the table in a streaming manner: "signing_requests" */
  signing_requests_stream: signing_requests[]
  /** An array relationship */
  support_sessions: support_sessions[]
  /** An aggregate relationship */
  support_sessions_aggregate: support_sessions_aggregate
  /** fetch data from the table: "support_sessions" using primary key columns */
  support_sessions_by_pk: support_sessions | null
  /** fetch data from the table in a streaming manner: "support_sessions" */
  support_sessions_stream: support_sessions[]
  /** fetch data from the table: "swap_assets" */
  swap_assets: swap_assets[]
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate: swap_assets_aggregate
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk: swap_assets | null
  /** fetch data from the table in a streaming manner: "swap_assets" */
  swap_assets_stream: swap_assets[]
  /** An array relationship */
  swap_orders: swap_orders[]
  /** An aggregate relationship */
  swap_orders_aggregate: swap_orders_aggregate
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk: swap_orders | null
  /** fetch data from the table in a streaming manner: "swap_orders" */
  swap_orders_stream: swap_orders[]
  /** fetch data from the table: "system_notification_read_status" */
  system_notification_read_status: system_notification_read_status[]
  /** fetch aggregated fields from the table: "system_notification_read_status" */
  system_notification_read_status_aggregate: system_notification_read_status_aggregate
  /** fetch data from the table: "system_notification_read_status" using primary key columns */
  system_notification_read_status_by_pk: system_notification_read_status | null
  /** fetch data from the table in a streaming manner: "system_notification_read_status" */
  system_notification_read_status_stream: system_notification_read_status[]
  /** An array relationship */
  system_notifications: system_notifications[]
  /** An aggregate relationship */
  system_notifications_aggregate: system_notifications_aggregate
  /** fetch data from the table: "system_notifications" using primary key columns */
  system_notifications_by_pk: system_notifications | null
  /** fetch data from the table in a streaming manner: "system_notifications" */
  system_notifications_stream: system_notifications[]
  /** fetch data from the table: "system_type_notification" */
  system_type_notification: system_type_notification[]
  /** fetch aggregated fields from the table: "system_type_notification" */
  system_type_notification_aggregate: system_type_notification_aggregate
  /** fetch data from the table: "system_type_notification" using primary key columns */
  system_type_notification_by_pk: system_type_notification | null
  /** fetch data from the table in a streaming manner: "system_type_notification" */
  system_type_notification_stream: system_type_notification[]
  /** fetch data from the table: "table_rows" */
  table_rows: table_rows[]
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: table_rows_aggregate
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk: table_rows | null
  /** fetch data from the table in a streaming manner: "table_rows" */
  table_rows_stream: table_rows[]
  /** fetch data from the table: "trust_network" */
  trust_network: trust_network[]
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate: trust_network_aggregate
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk: trust_network | null
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification: trust_network_notification[]
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate: trust_network_notification_aggregate
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk: trust_network_notification | null
  /** fetch data from the table in a streaming manner: "trust_network_notification" */
  trust_network_notification_stream: trust_network_notification[]
  /** fetch data from the table: "trust_network_status" */
  trust_network_status: trust_network_status[]
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate: trust_network_status_aggregate
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk: trust_network_status | null
  /** fetch data from the table in a streaming manner: "trust_network_status" */
  trust_network_status_stream: trust_network_status[]
  /** fetch data from the table in a streaming manner: "trust_network" */
  trust_network_stream: trust_network[]
  /** fetch data from the table: "tx_statistics" */
  tx_statistics: tx_statistics[]
  /** fetch aggregated fields from the table: "tx_statistics" */
  tx_statistics_aggregate: tx_statistics_aggregate
  /** fetch data from the table in a streaming manner: "tx_statistics" */
  tx_statistics_stream: tx_statistics[]
  /** fetch data from the table: "volume_statistics" */
  volume_statistics: volume_statistics[]
  /** fetch aggregated fields from the table: "volume_statistics" */
  volume_statistics_aggregate: volume_statistics_aggregate
  /** fetch data from the table in a streaming manner: "volume_statistics" */
  volume_statistics_stream: volume_statistics[]
  /** fetch data from the table: "web_push" */
  web_push: web_push[]
  /** fetch aggregated fields from the table: "web_push" */
  web_push_aggregate: web_push_aggregate
  /** fetch data from the table: "web_push" using primary key columns */
  web_push_by_pk: web_push | null
  /** fetch data from the table in a streaming manner: "web_push" */
  web_push_stream: web_push[]
  __typename: 'subscription_root'
}

/** columns and relationships of "support_sessions" */
export interface support_sessions {
  assistant: Scalars['String']
  closed_by: Scalars['String']
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  /** An object relationship */
  reg_account: reg_accounts
  session_close_datetime: Scalars['timestamptz']
  session_open_datetime: Scalars['timestamptz']
  status: Scalars['String']
  updated_at: Scalars['timestamptz']
  user: Scalars['String']
  __typename: 'support_sessions'
}

/** aggregated selection of "support_sessions" */
export interface support_sessions_aggregate {
  aggregate: support_sessions_aggregate_fields | null
  nodes: support_sessions[]
  __typename: 'support_sessions_aggregate'
}

/** aggregate fields of "support_sessions" */
export interface support_sessions_aggregate_fields {
  count: Scalars['Int']
  max: support_sessions_max_fields | null
  min: support_sessions_min_fields | null
  __typename: 'support_sessions_aggregate_fields'
}

/** unique or primary key constraints on table "support_sessions" */
export type support_sessions_constraint = 'support_sessions_pkey'

/** aggregate max on columns */
export interface support_sessions_max_fields {
  assistant: Scalars['String'] | null
  closed_by: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  session_close_datetime: Scalars['timestamptz'] | null
  session_open_datetime: Scalars['timestamptz'] | null
  status: Scalars['String'] | null
  updated_at: Scalars['timestamptz'] | null
  user: Scalars['String'] | null
  __typename: 'support_sessions_max_fields'
}

/** aggregate min on columns */
export interface support_sessions_min_fields {
  assistant: Scalars['String'] | null
  closed_by: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  session_close_datetime: Scalars['timestamptz'] | null
  session_open_datetime: Scalars['timestamptz'] | null
  status: Scalars['String'] | null
  updated_at: Scalars['timestamptz'] | null
  user: Scalars['String'] | null
  __typename: 'support_sessions_min_fields'
}

/** response of any mutation on the table "support_sessions" */
export interface support_sessions_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: support_sessions[]
  __typename: 'support_sessions_mutation_response'
}

/** select columns of table "support_sessions" */
export type support_sessions_select_column =
  | 'assistant'
  | 'closed_by'
  | 'created_at'
  | 'id'
  | 'session_close_datetime'
  | 'session_open_datetime'
  | 'status'
  | 'updated_at'
  | 'user'

/** update columns of table "support_sessions" */
export type support_sessions_update_column =
  | 'assistant'
  | 'closed_by'
  | 'created_at'
  | 'id'
  | 'session_close_datetime'
  | 'session_open_datetime'
  | 'status'
  | 'updated_at'
  | 'user'

/** columns and relationships of "swap_assets" */
export interface swap_assets {
  active_swaps: Scalars['Boolean']
  asset: Scalars['String']
  asset_name: Scalars['String']
  chain: Scalars['String']
  wallet_address: Scalars['String']
  __typename: 'swap_assets'
}

/** aggregated selection of "swap_assets" */
export interface swap_assets_aggregate {
  aggregate: swap_assets_aggregate_fields | null
  nodes: swap_assets[]
  __typename: 'swap_assets_aggregate'
}

/** aggregate fields of "swap_assets" */
export interface swap_assets_aggregate_fields {
  count: Scalars['Int']
  max: swap_assets_max_fields | null
  min: swap_assets_min_fields | null
  __typename: 'swap_assets_aggregate_fields'
}

/** unique or primary key constraints on table "swap_assets" */
export type swap_assets_constraint = 'swap_assets_pkey'

/** aggregate max on columns */
export interface swap_assets_max_fields {
  asset: Scalars['String'] | null
  asset_name: Scalars['String'] | null
  chain: Scalars['String'] | null
  wallet_address: Scalars['String'] | null
  __typename: 'swap_assets_max_fields'
}

/** aggregate min on columns */
export interface swap_assets_min_fields {
  asset: Scalars['String'] | null
  asset_name: Scalars['String'] | null
  chain: Scalars['String'] | null
  wallet_address: Scalars['String'] | null
  __typename: 'swap_assets_min_fields'
}

/** response of any mutation on the table "swap_assets" */
export interface swap_assets_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: swap_assets[]
  __typename: 'swap_assets_mutation_response'
}

/** select columns of table "swap_assets" */
export type swap_assets_select_column =
  | 'active_swaps'
  | 'asset'
  | 'asset_name'
  | 'chain'
  | 'wallet_address'

/** update columns of table "swap_assets" */
export type swap_assets_update_column =
  | 'active_swaps'
  | 'asset'
  | 'asset_name'
  | 'chain'
  | 'wallet_address'

/** columns and relationships of "swap_orders" */
export interface swap_orders {
  asset: Scalars['String']
  asset_amount: Scalars['float8']
  bitcash_account: Scalars['String']
  bitcash_amount: Scalars['float8']
  bitcash_currency: Scalars['String']
  bitcash_trx: Scalars['String'] | null
  created_at: Scalars['timestamptz']
  gems_id: Scalars['String'] | null
  id: Scalars['uuid']
  /** An array relationship */
  messages: messages[]
  /** An aggregate relationship */
  messages_aggregate: messages_aggregate
  order_status: Scalars['String']
  order_type: Scalars['String']
  price: Scalars['float8']
  /** An object relationship */
  reg_account: reg_accounts
  updated_at: Scalars['timestamptz']
  wallet_address: Scalars['String']
  __typename: 'swap_orders'
}

/** aggregated selection of "swap_orders" */
export interface swap_orders_aggregate {
  aggregate: swap_orders_aggregate_fields | null
  nodes: swap_orders[]
  __typename: 'swap_orders_aggregate'
}

/** aggregate fields of "swap_orders" */
export interface swap_orders_aggregate_fields {
  avg: swap_orders_avg_fields | null
  count: Scalars['Int']
  max: swap_orders_max_fields | null
  min: swap_orders_min_fields | null
  stddev: swap_orders_stddev_fields | null
  stddev_pop: swap_orders_stddev_pop_fields | null
  stddev_samp: swap_orders_stddev_samp_fields | null
  sum: swap_orders_sum_fields | null
  var_pop: swap_orders_var_pop_fields | null
  var_samp: swap_orders_var_samp_fields | null
  variance: swap_orders_variance_fields | null
  __typename: 'swap_orders_aggregate_fields'
}

/** aggregate avg on columns */
export interface swap_orders_avg_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_avg_fields'
}

/** unique or primary key constraints on table "swap_orders" */
export type swap_orders_constraint = 'swap_orders_pkey'

/** aggregate max on columns */
export interface swap_orders_max_fields {
  asset: Scalars['String'] | null
  asset_amount: Scalars['float8'] | null
  bitcash_account: Scalars['String'] | null
  bitcash_amount: Scalars['float8'] | null
  bitcash_currency: Scalars['String'] | null
  bitcash_trx: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  gems_id: Scalars['String'] | null
  id: Scalars['uuid'] | null
  order_status: Scalars['String'] | null
  order_type: Scalars['String'] | null
  price: Scalars['float8'] | null
  updated_at: Scalars['timestamptz'] | null
  wallet_address: Scalars['String'] | null
  __typename: 'swap_orders_max_fields'
}

/** aggregate min on columns */
export interface swap_orders_min_fields {
  asset: Scalars['String'] | null
  asset_amount: Scalars['float8'] | null
  bitcash_account: Scalars['String'] | null
  bitcash_amount: Scalars['float8'] | null
  bitcash_currency: Scalars['String'] | null
  bitcash_trx: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  gems_id: Scalars['String'] | null
  id: Scalars['uuid'] | null
  order_status: Scalars['String'] | null
  order_type: Scalars['String'] | null
  price: Scalars['float8'] | null
  updated_at: Scalars['timestamptz'] | null
  wallet_address: Scalars['String'] | null
  __typename: 'swap_orders_min_fields'
}

/** response of any mutation on the table "swap_orders" */
export interface swap_orders_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: swap_orders[]
  __typename: 'swap_orders_mutation_response'
}

/** select columns of table "swap_orders" */
export type swap_orders_select_column =
  | 'asset'
  | 'asset_amount'
  | 'bitcash_account'
  | 'bitcash_amount'
  | 'bitcash_currency'
  | 'bitcash_trx'
  | 'created_at'
  | 'gems_id'
  | 'id'
  | 'order_status'
  | 'order_type'
  | 'price'
  | 'updated_at'
  | 'wallet_address'

/** select "swap_orders_aggregate_bool_exp_avg_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_avg_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** select "swap_orders_aggregate_bool_exp_corr_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_corr_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** select "swap_orders_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_covar_samp_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** select "swap_orders_aggregate_bool_exp_max_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_max_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** select "swap_orders_aggregate_bool_exp_min_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_min_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** select "swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns =
  'asset_amount' | 'bitcash_amount' | 'price'

/** select "swap_orders_aggregate_bool_exp_sum_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_sum_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** select "swap_orders_aggregate_bool_exp_var_samp_arguments_columns" columns of table "swap_orders" */
export type swap_orders_select_column_swap_orders_aggregate_bool_exp_var_samp_arguments_columns =
  | 'asset_amount'
  | 'bitcash_amount'
  | 'price'

/** aggregate stddev on columns */
export interface swap_orders_stddev_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface swap_orders_stddev_pop_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface swap_orders_stddev_samp_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface swap_orders_sum_fields {
  asset_amount: Scalars['float8'] | null
  bitcash_amount: Scalars['float8'] | null
  price: Scalars['float8'] | null
  __typename: 'swap_orders_sum_fields'
}

/** update columns of table "swap_orders" */
export type swap_orders_update_column =
  | 'asset'
  | 'asset_amount'
  | 'bitcash_account'
  | 'bitcash_amount'
  | 'bitcash_currency'
  | 'bitcash_trx'
  | 'created_at'
  | 'gems_id'
  | 'id'
  | 'order_status'
  | 'order_type'
  | 'price'
  | 'updated_at'
  | 'wallet_address'

/** aggregate var_pop on columns */
export interface swap_orders_var_pop_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface swap_orders_var_samp_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_var_samp_fields'
}

/** aggregate variance on columns */
export interface swap_orders_variance_fields {
  asset_amount: Scalars['Float'] | null
  bitcash_amount: Scalars['Float'] | null
  price: Scalars['Float'] | null
  __typename: 'swap_orders_variance_fields'
}

/** columns and relationships of "system_notification_read_status" */
export interface system_notification_read_status {
  created_at: Scalars['timestamptz']
  notification_id: Scalars['uuid']
  /** An object relationship */
  regAccountByRegAccount: reg_accounts
  reg_account: Scalars['uuid']
  /** An object relationship */
  system_notification: system_notifications
  __typename: 'system_notification_read_status'
}

/** aggregated selection of "system_notification_read_status" */
export interface system_notification_read_status_aggregate {
  aggregate: system_notification_read_status_aggregate_fields | null
  nodes: system_notification_read_status[]
  __typename: 'system_notification_read_status_aggregate'
}

/** aggregate fields of "system_notification_read_status" */
export interface system_notification_read_status_aggregate_fields {
  count: Scalars['Int']
  max: system_notification_read_status_max_fields | null
  min: system_notification_read_status_min_fields | null
  __typename: 'system_notification_read_status_aggregate_fields'
}

/** unique or primary key constraints on table "system_notification_read_status" */
export type system_notification_read_status_constraint = 'system_notification_read_status_pkey'

/** aggregate max on columns */
export interface system_notification_read_status_max_fields {
  created_at: Scalars['timestamptz'] | null
  notification_id: Scalars['uuid'] | null
  reg_account: Scalars['uuid'] | null
  __typename: 'system_notification_read_status_max_fields'
}

/** aggregate min on columns */
export interface system_notification_read_status_min_fields {
  created_at: Scalars['timestamptz'] | null
  notification_id: Scalars['uuid'] | null
  reg_account: Scalars['uuid'] | null
  __typename: 'system_notification_read_status_min_fields'
}

/** response of any mutation on the table "system_notification_read_status" */
export interface system_notification_read_status_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: system_notification_read_status[]
  __typename: 'system_notification_read_status_mutation_response'
}

/** select columns of table "system_notification_read_status" */
export type system_notification_read_status_select_column =
  | 'created_at'
  | 'notification_id'
  | 'reg_account'

/** update columns of table "system_notification_read_status" */
export type system_notification_read_status_update_column =
  | 'created_at'
  | 'notification_id'
  | 'reg_account'

/** columns and relationships of "system_notifications" */
export interface system_notifications {
  created_at: Scalars['timestamptz']
  id: Scalars['uuid']
  message: Scalars['String'] | null
  offer_id: Scalars['uuid'] | null
  /** An object relationship */
  p2p_offer: p2p_offers | null
  /** An array relationship */
  system_notification_read_statuses: system_notification_read_status[]
  /** An aggregate relationship */
  system_notification_read_statuses_aggregate: system_notification_read_status_aggregate
  /** An object relationship */
  system_type_notification: system_type_notification
  type: system_type_notification_enum
  updated_at: Scalars['timestamptz']
  __typename: 'system_notifications'
}

/** aggregated selection of "system_notifications" */
export interface system_notifications_aggregate {
  aggregate: system_notifications_aggregate_fields | null
  nodes: system_notifications[]
  __typename: 'system_notifications_aggregate'
}

/** aggregate fields of "system_notifications" */
export interface system_notifications_aggregate_fields {
  count: Scalars['Int']
  max: system_notifications_max_fields | null
  min: system_notifications_min_fields | null
  __typename: 'system_notifications_aggregate_fields'
}

/** unique or primary key constraints on table "system_notifications" */
export type system_notifications_constraint = 'system_notifications_pkey'

/** aggregate max on columns */
export interface system_notifications_max_fields {
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  message: Scalars['String'] | null
  offer_id: Scalars['uuid'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'system_notifications_max_fields'
}

/** aggregate min on columns */
export interface system_notifications_min_fields {
  created_at: Scalars['timestamptz'] | null
  id: Scalars['uuid'] | null
  message: Scalars['String'] | null
  offer_id: Scalars['uuid'] | null
  updated_at: Scalars['timestamptz'] | null
  __typename: 'system_notifications_min_fields'
}

/** response of any mutation on the table "system_notifications" */
export interface system_notifications_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: system_notifications[]
  __typename: 'system_notifications_mutation_response'
}

/** select columns of table "system_notifications" */
export type system_notifications_select_column =
  | 'created_at'
  | 'id'
  | 'message'
  | 'offer_id'
  | 'type'
  | 'updated_at'

/** update columns of table "system_notifications" */
export type system_notifications_update_column =
  | 'created_at'
  | 'id'
  | 'message'
  | 'offer_id'
  | 'type'
  | 'updated_at'

/** columns and relationships of "system_type_notification" */
export interface system_type_notification {
  /** An array relationship */
  system_notifications: system_notifications[]
  /** An aggregate relationship */
  system_notifications_aggregate: system_notifications_aggregate
  type: Scalars['String']
  __typename: 'system_type_notification'
}

/** aggregated selection of "system_type_notification" */
export interface system_type_notification_aggregate {
  aggregate: system_type_notification_aggregate_fields | null
  nodes: system_type_notification[]
  __typename: 'system_type_notification_aggregate'
}

/** aggregate fields of "system_type_notification" */
export interface system_type_notification_aggregate_fields {
  count: Scalars['Int']
  max: system_type_notification_max_fields | null
  min: system_type_notification_min_fields | null
  __typename: 'system_type_notification_aggregate_fields'
}

/** unique or primary key constraints on table "system_type_notification" */
export type system_type_notification_constraint = 'system_type_notification_pkey'

export type system_type_notification_enum =
  | 'CONFIRMED_UNCOMPLETED_OFFER'
  | 'CONFIRMED_UNCOMPLETED_OFFER_ERROR'
  | 'ON_CHAIN_MATCHING_PREOFFER_COMPLETE'
  | 'ON_CHAIN_MATCHING_PREOFFER_START'
  | 'ON_CHAIN_MATCHING_PREOFFER_TIMEOUT_ERROR'
  | 'UNCONFIRMED_OFFER_12H'
  | 'UNCONFIRMED_OFFER_12H_ERROR'
  | 'UNEXPECTED_ERROR'
  | 'UNMATCHED_OFFER_48H'
  | 'UNMATCHED_OFFER_48H_ERROR'
  | 'UNPROCESSED_INDEXED_CONFIRM_OFFER'
  | 'UNPROCESSED_INDEXED_OFFER'

/** aggregate max on columns */
export interface system_type_notification_max_fields {
  type: Scalars['String'] | null
  __typename: 'system_type_notification_max_fields'
}

/** aggregate min on columns */
export interface system_type_notification_min_fields {
  type: Scalars['String'] | null
  __typename: 'system_type_notification_min_fields'
}

/** response of any mutation on the table "system_type_notification" */
export interface system_type_notification_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: system_type_notification[]
  __typename: 'system_type_notification_mutation_response'
}

/** select columns of table "system_type_notification" */
export type system_type_notification_select_column = 'type'

/** update columns of table "system_type_notification" */
export type system_type_notification_update_column = 'type'

/** columns and relationships of "table_rows" */
export interface table_rows {
  contract: Scalars['String']
  data: Scalars['jsonb']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
  __typename: 'table_rows'
}

/** aggregated selection of "table_rows" */
export interface table_rows_aggregate {
  aggregate: table_rows_aggregate_fields | null
  nodes: table_rows[]
  __typename: 'table_rows_aggregate'
}

/** aggregate fields of "table_rows" */
export interface table_rows_aggregate_fields {
  count: Scalars['Int']
  max: table_rows_max_fields | null
  min: table_rows_min_fields | null
  __typename: 'table_rows_aggregate_fields'
}

/** unique or primary key constraints on table "table_rows" */
export type table_rows_constraint = 'table_rows_pkey'

/** aggregate max on columns */
export interface table_rows_max_fields {
  contract: Scalars['String'] | null
  primary_key: Scalars['String'] | null
  scope: Scalars['String'] | null
  table: Scalars['String'] | null
  __typename: 'table_rows_max_fields'
}

/** aggregate min on columns */
export interface table_rows_min_fields {
  contract: Scalars['String'] | null
  primary_key: Scalars['String'] | null
  scope: Scalars['String'] | null
  table: Scalars['String'] | null
  __typename: 'table_rows_min_fields'
}

/** response of any mutation on the table "table_rows" */
export interface table_rows_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: table_rows[]
  __typename: 'table_rows_mutation_response'
}

/** select columns of table "table_rows" */
export type table_rows_select_column = 'contract' | 'data' | 'primary_key' | 'scope' | 'table'

/** update columns of table "table_rows" */
export type table_rows_update_column = 'contract' | 'data' | 'primary_key' | 'scope' | 'table'

export interface toggle_trust_network_output {
  success: Scalars['Boolean'] | null
  __typename: 'toggle_trust_network_output'
}

/** columns and relationships of "trust_network" */
export interface trust_network {
  account: Scalars['String']
  created_at: Scalars['timestamptz']
  is_mutual: Scalars['Boolean']
  trust: Scalars['String']
  /** An object relationship */
  trust_by: reg_accounts
  /** An object relationship */
  trust_network: reg_accounts
  __typename: 'trust_network'
}

/** aggregated selection of "trust_network" */
export interface trust_network_aggregate {
  aggregate: trust_network_aggregate_fields | null
  nodes: trust_network[]
  __typename: 'trust_network_aggregate'
}

/** aggregate fields of "trust_network" */
export interface trust_network_aggregate_fields {
  count: Scalars['Int']
  max: trust_network_max_fields | null
  min: trust_network_min_fields | null
  __typename: 'trust_network_aggregate_fields'
}

/** unique or primary key constraints on table "trust_network" */
export type trust_network_constraint = 'trust_network_pkey'

/** aggregate max on columns */
export interface trust_network_max_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  trust: Scalars['String'] | null
  __typename: 'trust_network_max_fields'
}

/** aggregate min on columns */
export interface trust_network_min_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  trust: Scalars['String'] | null
  __typename: 'trust_network_min_fields'
}

/** response of any mutation on the table "trust_network" */
export interface trust_network_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: trust_network[]
  __typename: 'trust_network_mutation_response'
}

/** columns and relationships of "trust_network_notification" */
export interface trust_network_notification {
  account: Scalars['String']
  created_at: Scalars['timestamptz']
  /** An object relationship */
  regAccountByTrust: reg_accounts
  /** An object relationship */
  reg_account: reg_accounts
  trust: Scalars['String']
  __typename: 'trust_network_notification'
}

/** aggregated selection of "trust_network_notification" */
export interface trust_network_notification_aggregate {
  aggregate: trust_network_notification_aggregate_fields | null
  nodes: trust_network_notification[]
  __typename: 'trust_network_notification_aggregate'
}

/** aggregate fields of "trust_network_notification" */
export interface trust_network_notification_aggregate_fields {
  count: Scalars['Int']
  max: trust_network_notification_max_fields | null
  min: trust_network_notification_min_fields | null
  __typename: 'trust_network_notification_aggregate_fields'
}

/** unique or primary key constraints on table "trust_network_notification" */
export type trust_network_notification_constraint = 'trust_network_notification_pkey'

/** aggregate max on columns */
export interface trust_network_notification_max_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  trust: Scalars['String'] | null
  __typename: 'trust_network_notification_max_fields'
}

/** aggregate min on columns */
export interface trust_network_notification_min_fields {
  account: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  trust: Scalars['String'] | null
  __typename: 'trust_network_notification_min_fields'
}

/** response of any mutation on the table "trust_network_notification" */
export interface trust_network_notification_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: trust_network_notification[]
  __typename: 'trust_network_notification_mutation_response'
}

/** select columns of table "trust_network_notification" */
export type trust_network_notification_select_column = 'account' | 'created_at' | 'trust'

/** update columns of table "trust_network_notification" */
export type trust_network_notification_update_column = 'account' | 'created_at' | 'trust'

export interface trust_network_output {
  trusted_network: (Scalars['String'] | null)[] | null
  __typename: 'trust_network_output'
}

/** select columns of table "trust_network" */
export type trust_network_select_column = 'account' | 'created_at' | 'is_mutual' | 'trust'

/** select "trust_network_aggregate_bool_exp_bool_and_arguments_columns" columns of table "trust_network" */
export type trust_network_select_column_trust_network_aggregate_bool_exp_bool_and_arguments_columns =
  'is_mutual'

/** select "trust_network_aggregate_bool_exp_bool_or_arguments_columns" columns of table "trust_network" */
export type trust_network_select_column_trust_network_aggregate_bool_exp_bool_or_arguments_columns =
  'is_mutual'

/** columns and relationships of "trust_network_status" */
export interface trust_network_status {
  status: Scalars['String']
  __typename: 'trust_network_status'
}

/** aggregated selection of "trust_network_status" */
export interface trust_network_status_aggregate {
  aggregate: trust_network_status_aggregate_fields | null
  nodes: trust_network_status[]
  __typename: 'trust_network_status_aggregate'
}

/** aggregate fields of "trust_network_status" */
export interface trust_network_status_aggregate_fields {
  count: Scalars['Int']
  max: trust_network_status_max_fields | null
  min: trust_network_status_min_fields | null
  __typename: 'trust_network_status_aggregate_fields'
}

/** unique or primary key constraints on table "trust_network_status" */
export type trust_network_status_constraint = 'trusted_network_status_pkey'

/** aggregate max on columns */
export interface trust_network_status_max_fields {
  status: Scalars['String'] | null
  __typename: 'trust_network_status_max_fields'
}

/** aggregate min on columns */
export interface trust_network_status_min_fields {
  status: Scalars['String'] | null
  __typename: 'trust_network_status_min_fields'
}

/** response of any mutation on the table "trust_network_status" */
export interface trust_network_status_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: trust_network_status[]
  __typename: 'trust_network_status_mutation_response'
}

/** select columns of table "trust_network_status" */
export type trust_network_status_select_column = 'status'

/** update columns of table "trust_network_status" */
export type trust_network_status_update_column = 'status'

/** update columns of table "trust_network" */
export type trust_network_update_column = 'account' | 'created_at' | 'is_mutual' | 'trust'

/** columns and relationships of "tx_statistics" */
export interface tx_statistics {
  currency: Scalars['String'] | null
  date: Scalars['timestamptz'] | null
  total_transactions: Scalars['bigint'] | null
  total_transactions_cancelled: Scalars['bigint'] | null
  total_transactions_completed: Scalars['bigint'] | null
  __typename: 'tx_statistics'
}

/** aggregated selection of "tx_statistics" */
export interface tx_statistics_aggregate {
  aggregate: tx_statistics_aggregate_fields | null
  nodes: tx_statistics[]
  __typename: 'tx_statistics_aggregate'
}

/** aggregate fields of "tx_statistics" */
export interface tx_statistics_aggregate_fields {
  avg: tx_statistics_avg_fields | null
  count: Scalars['Int']
  max: tx_statistics_max_fields | null
  min: tx_statistics_min_fields | null
  stddev: tx_statistics_stddev_fields | null
  stddev_pop: tx_statistics_stddev_pop_fields | null
  stddev_samp: tx_statistics_stddev_samp_fields | null
  sum: tx_statistics_sum_fields | null
  var_pop: tx_statistics_var_pop_fields | null
  var_samp: tx_statistics_var_samp_fields | null
  variance: tx_statistics_variance_fields | null
  __typename: 'tx_statistics_aggregate_fields'
}

/** aggregate avg on columns */
export interface tx_statistics_avg_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_avg_fields'
}

/** aggregate max on columns */
export interface tx_statistics_max_fields {
  currency: Scalars['String'] | null
  date: Scalars['timestamptz'] | null
  total_transactions: Scalars['bigint'] | null
  total_transactions_cancelled: Scalars['bigint'] | null
  total_transactions_completed: Scalars['bigint'] | null
  __typename: 'tx_statistics_max_fields'
}

/** aggregate min on columns */
export interface tx_statistics_min_fields {
  currency: Scalars['String'] | null
  date: Scalars['timestamptz'] | null
  total_transactions: Scalars['bigint'] | null
  total_transactions_cancelled: Scalars['bigint'] | null
  total_transactions_completed: Scalars['bigint'] | null
  __typename: 'tx_statistics_min_fields'
}

/** response of any mutation on the table "tx_statistics" */
export interface tx_statistics_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: tx_statistics[]
  __typename: 'tx_statistics_mutation_response'
}

/** select columns of table "tx_statistics" */
export type tx_statistics_select_column =
  | 'currency'
  | 'date'
  | 'total_transactions'
  | 'total_transactions_cancelled'
  | 'total_transactions_completed'

/** aggregate stddev on columns */
export interface tx_statistics_stddev_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface tx_statistics_stddev_pop_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface tx_statistics_stddev_samp_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface tx_statistics_sum_fields {
  total_transactions: Scalars['bigint'] | null
  total_transactions_cancelled: Scalars['bigint'] | null
  total_transactions_completed: Scalars['bigint'] | null
  __typename: 'tx_statistics_sum_fields'
}

/** aggregate var_pop on columns */
export interface tx_statistics_var_pop_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface tx_statistics_var_samp_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_var_samp_fields'
}

/** aggregate variance on columns */
export interface tx_statistics_variance_fields {
  total_transactions: Scalars['Float'] | null
  total_transactions_cancelled: Scalars['Float'] | null
  total_transactions_completed: Scalars['Float'] | null
  __typename: 'tx_statistics_variance_fields'
}

export interface upload_image_output {
  success: Scalars['Boolean']
  __typename: 'upload_image_output'
}

export interface validate_short_link_output {
  count: Scalars['Int']
  referrer: Scalars['String']
  website: Scalars['String']
  __typename: 'validate_short_link_output'
}

/** columns and relationships of "volume_statistics" */
export interface volume_statistics {
  currency: Scalars['String'] | null
  date: Scalars['timestamptz'] | null
  total_cancelled: Scalars['numeric'] | null
  total_completed: Scalars['numeric'] | null
  total_completed_not_cancelled: Scalars['numeric'] | null
  __typename: 'volume_statistics'
}

/** aggregated selection of "volume_statistics" */
export interface volume_statistics_aggregate {
  aggregate: volume_statistics_aggregate_fields | null
  nodes: volume_statistics[]
  __typename: 'volume_statistics_aggregate'
}

/** aggregate fields of "volume_statistics" */
export interface volume_statistics_aggregate_fields {
  avg: volume_statistics_avg_fields | null
  count: Scalars['Int']
  max: volume_statistics_max_fields | null
  min: volume_statistics_min_fields | null
  stddev: volume_statistics_stddev_fields | null
  stddev_pop: volume_statistics_stddev_pop_fields | null
  stddev_samp: volume_statistics_stddev_samp_fields | null
  sum: volume_statistics_sum_fields | null
  var_pop: volume_statistics_var_pop_fields | null
  var_samp: volume_statistics_var_samp_fields | null
  variance: volume_statistics_variance_fields | null
  __typename: 'volume_statistics_aggregate_fields'
}

/** aggregate avg on columns */
export interface volume_statistics_avg_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_avg_fields'
}

/** aggregate max on columns */
export interface volume_statistics_max_fields {
  currency: Scalars['String'] | null
  date: Scalars['timestamptz'] | null
  total_cancelled: Scalars['numeric'] | null
  total_completed: Scalars['numeric'] | null
  total_completed_not_cancelled: Scalars['numeric'] | null
  __typename: 'volume_statistics_max_fields'
}

/** aggregate min on columns */
export interface volume_statistics_min_fields {
  currency: Scalars['String'] | null
  date: Scalars['timestamptz'] | null
  total_cancelled: Scalars['numeric'] | null
  total_completed: Scalars['numeric'] | null
  total_completed_not_cancelled: Scalars['numeric'] | null
  __typename: 'volume_statistics_min_fields'
}

/** response of any mutation on the table "volume_statistics" */
export interface volume_statistics_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: volume_statistics[]
  __typename: 'volume_statistics_mutation_response'
}

/** select columns of table "volume_statistics" */
export type volume_statistics_select_column =
  | 'currency'
  | 'date'
  | 'total_cancelled'
  | 'total_completed'
  | 'total_completed_not_cancelled'

/** aggregate stddev on columns */
export interface volume_statistics_stddev_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_stddev_fields'
}

/** aggregate stddev_pop on columns */
export interface volume_statistics_stddev_pop_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_stddev_pop_fields'
}

/** aggregate stddev_samp on columns */
export interface volume_statistics_stddev_samp_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_stddev_samp_fields'
}

/** aggregate sum on columns */
export interface volume_statistics_sum_fields {
  total_cancelled: Scalars['numeric'] | null
  total_completed: Scalars['numeric'] | null
  total_completed_not_cancelled: Scalars['numeric'] | null
  __typename: 'volume_statistics_sum_fields'
}

/** aggregate var_pop on columns */
export interface volume_statistics_var_pop_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_var_pop_fields'
}

/** aggregate var_samp on columns */
export interface volume_statistics_var_samp_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_var_samp_fields'
}

/** aggregate variance on columns */
export interface volume_statistics_variance_fields {
  total_cancelled: Scalars['Float'] | null
  total_completed: Scalars['Float'] | null
  total_completed_not_cancelled: Scalars['Float'] | null
  __typename: 'volume_statistics_variance_fields'
}

export interface wallet_request_output {
  amount: Scalars['String'] | null
  bitcashbank_id: Scalars['String'] | null
  buyer: Scalars['String'] | null
  buyer_confirmed_payment: Scalars['Boolean'] | null
  buyer_method_details: Scalars['String'] | null
  cancel_approval_datetime: Scalars['String'] | null
  cancel_request_datetime: Scalars['String'] | null
  cancellation_approved_by: Scalars['String'] | null
  cancellation_transaction: Scalars['String'] | null
  cancelled: Scalars['Boolean'] | null
  cancelled_by: Scalars['String'] | null
  completed: Scalars['Boolean'] | null
  created_at: Scalars['String'] | null
  id: Scalars['String'] | null
  initiator: Scalars['String'] | null
  matched: Scalars['Boolean'] | null
  matched_datetime: Scalars['String'] | null
  method: Scalars['String'] | null
  region: Scalars['String'] | null
  sell_put_transaction: Scalars['String'] | null
  sell_settlement_transaction: Scalars['String'] | null
  seller: Scalars['String'] | null
  seller_confirmed_payment: Scalars['Boolean'] | null
  seller_method_details: Scalars['String'] | null
  type: Scalars['String'] | null
  updated_at: Scalars['String'] | null
  __typename: 'wallet_request_output'
}

/** columns and relationships of "web_push" */
export interface web_push {
  account: Scalars['String']
  auth: Scalars['String']
  created_at: Scalars['timestamptz']
  endpoint: Scalars['String']
  expiration_time: Scalars['timestamptz']
  p256dh: Scalars['String']
  __typename: 'web_push'
}

/** aggregated selection of "web_push" */
export interface web_push_aggregate {
  aggregate: web_push_aggregate_fields | null
  nodes: web_push[]
  __typename: 'web_push_aggregate'
}

/** aggregate fields of "web_push" */
export interface web_push_aggregate_fields {
  count: Scalars['Int']
  max: web_push_max_fields | null
  min: web_push_min_fields | null
  __typename: 'web_push_aggregate_fields'
}

/** unique or primary key constraints on table "web_push" */
export type web_push_constraint = 'web_push_pkey'

/** aggregate max on columns */
export interface web_push_max_fields {
  account: Scalars['String'] | null
  auth: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  endpoint: Scalars['String'] | null
  expiration_time: Scalars['timestamptz'] | null
  p256dh: Scalars['String'] | null
  __typename: 'web_push_max_fields'
}

/** aggregate min on columns */
export interface web_push_min_fields {
  account: Scalars['String'] | null
  auth: Scalars['String'] | null
  created_at: Scalars['timestamptz'] | null
  endpoint: Scalars['String'] | null
  expiration_time: Scalars['timestamptz'] | null
  p256dh: Scalars['String'] | null
  __typename: 'web_push_min_fields'
}

/** response of any mutation on the table "web_push" */
export interface web_push_mutation_response {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: web_push[]
  __typename: 'web_push_mutation_response'
}

/** select columns of table "web_push" */
export type web_push_select_column =
  | 'account'
  | 'auth'
  | 'created_at'
  | 'endpoint'
  | 'expiration_time'
  | 'p256dh'

/** update columns of table "web_push" */
export type web_push_update_column =
  | 'account'
  | 'auth'
  | 'created_at'
  | 'endpoint'
  | 'expiration_time'
  | 'p256dh'

export type Query = query_root
export type Mutation = mutation_root
export type Subscription = subscription_root

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export interface Boolean_comparison_exp {
  _eq?: Scalars['Boolean'] | null
  _gt?: Scalars['Boolean'] | null
  _gte?: Scalars['Boolean'] | null
  _in?: Scalars['Boolean'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['Boolean'] | null
  _lte?: Scalars['Boolean'] | null
  _neq?: Scalars['Boolean'] | null
  _nin?: Scalars['Boolean'][] | null
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export interface Int_comparison_exp {
  _eq?: Scalars['Int'] | null
  _gt?: Scalars['Int'] | null
  _gte?: Scalars['Int'] | null
  _in?: Scalars['Int'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['Int'] | null
  _lte?: Scalars['Int'] | null
  _neq?: Scalars['Int'] | null
  _nin?: Scalars['Int'][] | null
}

export interface PushSubscriptionInput {
  account: Scalars['String']
  endpoint: Scalars['String']
  expirationTime?: Scalars['String'] | null
  keys: PushSubscriptionKeysInput
}

export interface PushSubscriptionKeysInput {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_array_comparison_exp {
  /** is the array contained in the given array value */
  _contained_in?: Scalars['String'][] | null
  /** does the array contain the given value */
  _contains?: Scalars['String'][] | null
  _eq?: Scalars['String'][] | null
  _gt?: Scalars['String'][] | null
  _gte?: Scalars['String'][] | null
  _in?: Scalars['String'][][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['String'][] | null
  _lte?: Scalars['String'][] | null
  _neq?: Scalars['String'][] | null
  _nin?: Scalars['String'][][] | null
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export interface String_comparison_exp {
  _eq?: Scalars['String'] | null
  _gt?: Scalars['String'] | null
  _gte?: Scalars['String'] | null
  /** does the column match the given case-insensitive pattern */
  _ilike?: Scalars['String'] | null
  _in?: Scalars['String'][] | null
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Scalars['String'] | null
  _is_null?: Scalars['Boolean'] | null
  /** does the column match the given pattern */
  _like?: Scalars['String'] | null
  _lt?: Scalars['String'] | null
  _lte?: Scalars['String'] | null
  _neq?: Scalars['String'] | null
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Scalars['String'] | null
  _nin?: Scalars['String'][] | null
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Scalars['String'] | null
  /** does the column NOT match the given pattern */
  _nlike?: Scalars['String'] | null
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Scalars['String'] | null
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Scalars['String'] | null
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Scalars['String'] | null
  /** does the column match the given SQL regular expression */
  _similar?: Scalars['String'] | null
}

/** columns and relationships of "account_statistics" */
export interface account_statisticsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "account_statistics" */
export interface account_statistics_aggregateGenqlSelection {
  aggregate?: account_statistics_aggregate_fieldsGenqlSelection
  nodes?: account_statisticsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "account_statistics" */
export interface account_statistics_aggregate_fieldsGenqlSelection {
  avg?: account_statistics_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: account_statistics_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: account_statistics_max_fieldsGenqlSelection
  min?: account_statistics_min_fieldsGenqlSelection
  stddev?: account_statistics_stddev_fieldsGenqlSelection
  stddev_pop?: account_statistics_stddev_pop_fieldsGenqlSelection
  stddev_samp?: account_statistics_stddev_samp_fieldsGenqlSelection
  sum?: account_statistics_sum_fieldsGenqlSelection
  var_pop?: account_statistics_var_pop_fieldsGenqlSelection
  var_samp?: account_statistics_var_samp_fieldsGenqlSelection
  variance?: account_statistics_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface account_statistics_avg_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "account_statistics". All fields are combined with a logical 'AND'. */
export interface account_statistics_bool_exp {
  _and?: account_statistics_bool_exp[] | null
  _not?: account_statistics_bool_exp | null
  _or?: account_statistics_bool_exp[] | null
  orders_cancelled?: bigint_comparison_exp | null
  orders_completed?: bigint_comparison_exp | null
  user_id?: String_comparison_exp | null
}

/** aggregate max on columns */
export interface account_statistics_max_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface account_statistics_min_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "account_statistics". */
export interface account_statistics_order_by {
  orders_cancelled?: order_by | null
  orders_completed?: order_by | null
  user_id?: order_by | null
}

/** aggregate stddev on columns */
export interface account_statistics_stddev_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface account_statistics_stddev_pop_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface account_statistics_stddev_samp_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "account_statistics" */
export interface account_statistics_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: account_statistics_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface account_statistics_stream_cursor_value_input {
  orders_cancelled?: Scalars['bigint'] | null
  orders_completed?: Scalars['bigint'] | null
  user_id?: Scalars['String'] | null
}

/** aggregate sum on columns */
export interface account_statistics_sum_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface account_statistics_var_pop_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface account_statistics_var_samp_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface account_statistics_variance_fieldsGenqlSelection {
  orders_cancelled?: boolean | number
  orders_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Additional information for account restoration and for kyc */
export interface accounts_informationGenqlSelection {
  account?: boolean | number
  country_id?:
    | {
        __args: {
          /** JSON select path */
          path?: Scalars['String'] | null
        }
      }
    | boolean
    | number
  date_of_birth?: boolean | number
  email?: boolean | number
  full_name?: boolean | number
  id?: boolean | number
  newsletter_subscription?: boolean | number
  phone?: boolean | number
  photo_id?: boolean | number
  recovery_partners?:
    | {
        __args: {
          /** JSON select path */
          path?: Scalars['String'] | null
        }
      }
    | boolean
    | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  registration_ip?: boolean | number
  selfie?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "accounts_information" */
export interface accounts_information_aggregateGenqlSelection {
  aggregate?: accounts_information_aggregate_fieldsGenqlSelection
  nodes?: accounts_informationGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface accounts_information_aggregate_bool_exp {
  bool_and?: accounts_information_aggregate_bool_exp_bool_and | null
  bool_or?: accounts_information_aggregate_bool_exp_bool_or | null
  count?: accounts_information_aggregate_bool_exp_count | null
}

export interface accounts_information_aggregate_bool_exp_bool_and {
  arguments: accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_and_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: accounts_information_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface accounts_information_aggregate_bool_exp_bool_or {
  arguments: accounts_information_select_column_accounts_information_aggregate_bool_exp_bool_or_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: accounts_information_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface accounts_information_aggregate_bool_exp_count {
  arguments?: accounts_information_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: accounts_information_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "accounts_information" */
export interface accounts_information_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: accounts_information_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: accounts_information_max_fieldsGenqlSelection
  min?: accounts_information_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "accounts_information" */
export interface accounts_information_aggregate_order_by {
  count?: order_by | null
  max?: accounts_information_max_order_by | null
  min?: accounts_information_min_order_by | null
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface accounts_information_append_input {
  country_id?: Scalars['jsonb'] | null
  recovery_partners?: Scalars['jsonb'] | null
}

/** input type for inserting array relation for remote table "accounts_information" */
export interface accounts_information_arr_rel_insert_input {
  data: accounts_information_insert_input[]
  /** upsert condition */
  on_conflict?: accounts_information_on_conflict | null
}

/** Boolean expression to filter rows from the table "accounts_information". All fields are combined with a logical 'AND'. */
export interface accounts_information_bool_exp {
  _and?: accounts_information_bool_exp[] | null
  _not?: accounts_information_bool_exp | null
  _or?: accounts_information_bool_exp[] | null
  account?: String_comparison_exp | null
  country_id?: jsonb_comparison_exp | null
  date_of_birth?: timestamptz_comparison_exp | null
  email?: String_comparison_exp | null
  full_name?: String_comparison_exp | null
  id?: uuid_comparison_exp | null
  newsletter_subscription?: Boolean_comparison_exp | null
  phone?: String_comparison_exp | null
  photo_id?: String_comparison_exp | null
  recovery_partners?: jsonb_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  registration_ip?: String_comparison_exp | null
  selfie?: String_comparison_exp | null
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface accounts_information_delete_at_path_input {
  country_id?: Scalars['String'][] | null
  recovery_partners?: Scalars['String'][] | null
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface accounts_information_delete_elem_input {
  country_id?: Scalars['Int'] | null
  recovery_partners?: Scalars['Int'] | null
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface accounts_information_delete_key_input {
  country_id?: Scalars['String'] | null
  recovery_partners?: Scalars['String'] | null
}

/** input type for inserting data into table "accounts_information" */
export interface accounts_information_insert_input {
  account?: Scalars['String'] | null
  country_id?: Scalars['jsonb'] | null
  date_of_birth?: Scalars['timestamptz'] | null
  email?: Scalars['String'] | null
  full_name?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  newsletter_subscription?: Scalars['Boolean'] | null
  phone?: Scalars['String'] | null
  photo_id?: Scalars['String'] | null
  recovery_partners?: Scalars['jsonb'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  registration_ip?: Scalars['String'] | null
  selfie?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface accounts_information_max_fieldsGenqlSelection {
  account?: boolean | number
  date_of_birth?: boolean | number
  email?: boolean | number
  full_name?: boolean | number
  id?: boolean | number
  phone?: boolean | number
  photo_id?: boolean | number
  registration_ip?: boolean | number
  selfie?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "accounts_information" */
export interface accounts_information_max_order_by {
  account?: order_by | null
  date_of_birth?: order_by | null
  email?: order_by | null
  full_name?: order_by | null
  id?: order_by | null
  phone?: order_by | null
  photo_id?: order_by | null
  registration_ip?: order_by | null
  selfie?: order_by | null
}

/** aggregate min on columns */
export interface accounts_information_min_fieldsGenqlSelection {
  account?: boolean | number
  date_of_birth?: boolean | number
  email?: boolean | number
  full_name?: boolean | number
  id?: boolean | number
  phone?: boolean | number
  photo_id?: boolean | number
  registration_ip?: boolean | number
  selfie?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "accounts_information" */
export interface accounts_information_min_order_by {
  account?: order_by | null
  date_of_birth?: order_by | null
  email?: order_by | null
  full_name?: order_by | null
  id?: order_by | null
  phone?: order_by | null
  photo_id?: order_by | null
  registration_ip?: order_by | null
  selfie?: order_by | null
}

/** response of any mutation on the table "accounts_information" */
export interface accounts_information_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: accounts_informationGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "accounts_information" */
export interface accounts_information_obj_rel_insert_input {
  data: accounts_information_insert_input
  /** upsert condition */
  on_conflict?: accounts_information_on_conflict | null
}

/** on_conflict condition type for table "accounts_information" */
export interface accounts_information_on_conflict {
  constraint: accounts_information_constraint
  update_columns?: accounts_information_update_column[]
  where?: accounts_information_bool_exp | null
}

/** Ordering options when selecting data from "accounts_information". */
export interface accounts_information_order_by {
  account?: order_by | null
  country_id?: order_by | null
  date_of_birth?: order_by | null
  email?: order_by | null
  full_name?: order_by | null
  id?: order_by | null
  newsletter_subscription?: order_by | null
  phone?: order_by | null
  photo_id?: order_by | null
  recovery_partners?: order_by | null
  reg_account?: reg_accounts_order_by | null
  registration_ip?: order_by | null
  selfie?: order_by | null
}

/** primary key columns input for table: accounts_information */
export interface accounts_information_pk_columns_input {
  account: Scalars['String']
  id: Scalars['uuid']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface accounts_information_prepend_input {
  country_id?: Scalars['jsonb'] | null
  recovery_partners?: Scalars['jsonb'] | null
}

/** input type for updating data in table "accounts_information" */
export interface accounts_information_set_input {
  account?: Scalars['String'] | null
  country_id?: Scalars['jsonb'] | null
  date_of_birth?: Scalars['timestamptz'] | null
  email?: Scalars['String'] | null
  full_name?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  newsletter_subscription?: Scalars['Boolean'] | null
  phone?: Scalars['String'] | null
  photo_id?: Scalars['String'] | null
  recovery_partners?: Scalars['jsonb'] | null
  registration_ip?: Scalars['String'] | null
  selfie?: Scalars['String'] | null
}

/** Streaming cursor of the table "accounts_information" */
export interface accounts_information_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: accounts_information_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface accounts_information_stream_cursor_value_input {
  account?: Scalars['String'] | null
  country_id?: Scalars['jsonb'] | null
  date_of_birth?: Scalars['timestamptz'] | null
  email?: Scalars['String'] | null
  full_name?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  newsletter_subscription?: Scalars['Boolean'] | null
  phone?: Scalars['String'] | null
  photo_id?: Scalars['String'] | null
  recovery_partners?: Scalars['jsonb'] | null
  registration_ip?: Scalars['String'] | null
  selfie?: Scalars['String'] | null
}

export interface accounts_information_updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: accounts_information_append_input | null
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: accounts_information_delete_at_path_input | null
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: accounts_information_delete_elem_input | null
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: accounts_information_delete_key_input | null
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: accounts_information_prepend_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: accounts_information_set_input | null
  /** filter the rows which have to be updated */
  where: accounts_information_bool_exp
}

/** columns and relationships of "accounts_referral" */
export interface accounts_referralGenqlSelection {
  account?: boolean | number
  clicks?: boolean | number
  link?: boolean | number
  referrer?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  registrations?: boolean | number
  website?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "accounts_referral" */
export interface accounts_referral_aggregateGenqlSelection {
  aggregate?: accounts_referral_aggregate_fieldsGenqlSelection
  nodes?: accounts_referralGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface accounts_referral_aggregate_bool_exp {
  count?: accounts_referral_aggregate_bool_exp_count | null
}

export interface accounts_referral_aggregate_bool_exp_count {
  arguments?: accounts_referral_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: accounts_referral_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "accounts_referral" */
export interface accounts_referral_aggregate_fieldsGenqlSelection {
  avg?: accounts_referral_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: accounts_referral_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: accounts_referral_max_fieldsGenqlSelection
  min?: accounts_referral_min_fieldsGenqlSelection
  stddev?: accounts_referral_stddev_fieldsGenqlSelection
  stddev_pop?: accounts_referral_stddev_pop_fieldsGenqlSelection
  stddev_samp?: accounts_referral_stddev_samp_fieldsGenqlSelection
  sum?: accounts_referral_sum_fieldsGenqlSelection
  var_pop?: accounts_referral_var_pop_fieldsGenqlSelection
  var_samp?: accounts_referral_var_samp_fieldsGenqlSelection
  variance?: accounts_referral_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "accounts_referral" */
export interface accounts_referral_aggregate_order_by {
  avg?: accounts_referral_avg_order_by | null
  count?: order_by | null
  max?: accounts_referral_max_order_by | null
  min?: accounts_referral_min_order_by | null
  stddev?: accounts_referral_stddev_order_by | null
  stddev_pop?: accounts_referral_stddev_pop_order_by | null
  stddev_samp?: accounts_referral_stddev_samp_order_by | null
  sum?: accounts_referral_sum_order_by | null
  var_pop?: accounts_referral_var_pop_order_by | null
  var_samp?: accounts_referral_var_samp_order_by | null
  variance?: accounts_referral_variance_order_by | null
}

/** input type for inserting array relation for remote table "accounts_referral" */
export interface accounts_referral_arr_rel_insert_input {
  data: accounts_referral_insert_input[]
  /** upsert condition */
  on_conflict?: accounts_referral_on_conflict | null
}

/** aggregate avg on columns */
export interface accounts_referral_avg_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by avg() on columns of table "accounts_referral" */
export interface accounts_referral_avg_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** Boolean expression to filter rows from the table "accounts_referral". All fields are combined with a logical 'AND'. */
export interface accounts_referral_bool_exp {
  _and?: accounts_referral_bool_exp[] | null
  _not?: accounts_referral_bool_exp | null
  _or?: accounts_referral_bool_exp[] | null
  account?: String_comparison_exp | null
  clicks?: Int_comparison_exp | null
  link?: String_comparison_exp | null
  referrer?: String_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  registrations?: Int_comparison_exp | null
  website?: String_comparison_exp | null
}

/** input type for incrementing numeric columns in table "accounts_referral" */
export interface accounts_referral_inc_input {
  clicks?: Scalars['Int'] | null
  registrations?: Scalars['Int'] | null
}

/** input type for inserting data into table "accounts_referral" */
export interface accounts_referral_insert_input {
  account?: Scalars['String'] | null
  clicks?: Scalars['Int'] | null
  link?: Scalars['String'] | null
  referrer?: Scalars['String'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  registrations?: Scalars['Int'] | null
  website?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface accounts_referral_max_fieldsGenqlSelection {
  account?: boolean | number
  clicks?: boolean | number
  link?: boolean | number
  referrer?: boolean | number
  registrations?: boolean | number
  website?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "accounts_referral" */
export interface accounts_referral_max_order_by {
  account?: order_by | null
  clicks?: order_by | null
  link?: order_by | null
  referrer?: order_by | null
  registrations?: order_by | null
  website?: order_by | null
}

/** aggregate min on columns */
export interface accounts_referral_min_fieldsGenqlSelection {
  account?: boolean | number
  clicks?: boolean | number
  link?: boolean | number
  referrer?: boolean | number
  registrations?: boolean | number
  website?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "accounts_referral" */
export interface accounts_referral_min_order_by {
  account?: order_by | null
  clicks?: order_by | null
  link?: order_by | null
  referrer?: order_by | null
  registrations?: order_by | null
  website?: order_by | null
}

/** response of any mutation on the table "accounts_referral" */
export interface accounts_referral_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: accounts_referralGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "accounts_referral" */
export interface accounts_referral_on_conflict {
  constraint: accounts_referral_constraint
  update_columns?: accounts_referral_update_column[]
  where?: accounts_referral_bool_exp | null
}

/** Ordering options when selecting data from "accounts_referral". */
export interface accounts_referral_order_by {
  account?: order_by | null
  clicks?: order_by | null
  link?: order_by | null
  referrer?: order_by | null
  reg_account?: reg_accounts_order_by | null
  registrations?: order_by | null
  website?: order_by | null
}

/** primary key columns input for table: accounts_referral */
export interface accounts_referral_pk_columns_input {
  link: Scalars['String']
}

/** input type for updating data in table "accounts_referral" */
export interface accounts_referral_set_input {
  account?: Scalars['String'] | null
  clicks?: Scalars['Int'] | null
  link?: Scalars['String'] | null
  referrer?: Scalars['String'] | null
  registrations?: Scalars['Int'] | null
  website?: Scalars['String'] | null
}

/** aggregate stddev on columns */
export interface accounts_referral_stddev_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by stddev() on columns of table "accounts_referral" */
export interface accounts_referral_stddev_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** aggregate stddev_pop on columns */
export interface accounts_referral_stddev_pop_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by stddev_pop() on columns of table "accounts_referral" */
export interface accounts_referral_stddev_pop_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** aggregate stddev_samp on columns */
export interface accounts_referral_stddev_samp_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by stddev_samp() on columns of table "accounts_referral" */
export interface accounts_referral_stddev_samp_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** Streaming cursor of the table "accounts_referral" */
export interface accounts_referral_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: accounts_referral_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface accounts_referral_stream_cursor_value_input {
  account?: Scalars['String'] | null
  clicks?: Scalars['Int'] | null
  link?: Scalars['String'] | null
  referrer?: Scalars['String'] | null
  registrations?: Scalars['Int'] | null
  website?: Scalars['String'] | null
}

/** aggregate sum on columns */
export interface accounts_referral_sum_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by sum() on columns of table "accounts_referral" */
export interface accounts_referral_sum_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

export interface accounts_referral_updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: accounts_referral_inc_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: accounts_referral_set_input | null
  /** filter the rows which have to be updated */
  where: accounts_referral_bool_exp
}

/** aggregate var_pop on columns */
export interface accounts_referral_var_pop_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by var_pop() on columns of table "accounts_referral" */
export interface accounts_referral_var_pop_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** aggregate var_samp on columns */
export interface accounts_referral_var_samp_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by var_samp() on columns of table "accounts_referral" */
export interface accounts_referral_var_samp_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** aggregate variance on columns */
export interface accounts_referral_variance_fieldsGenqlSelection {
  clicks?: boolean | number
  registrations?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by variance() on columns of table "accounts_referral" */
export interface accounts_referral_variance_order_by {
  clicks?: order_by | null
  registrations?: order_by | null
}

/** Blockchain Actions */
export interface actionsGenqlSelection {
  action?: boolean | number
  auth?:
    | {
        __args: {
          /** JSON select path */
          path?: Scalars['String'] | null
        }
      }
    | boolean
    | number
  contract?: boolean | number
  data?:
    | {
        __args: {
          /** JSON select path */
          path?: Scalars['String'] | null
        }
      }
    | boolean
    | number
  global_sequence?: boolean | number
  transaction_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "actions" */
export interface actions_aggregateGenqlSelection {
  aggregate?: actions_aggregate_fieldsGenqlSelection
  nodes?: actionsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "actions" */
export interface actions_aggregate_fieldsGenqlSelection {
  count?:
    | { __args: { columns?: actions_select_column[] | null; distinct?: Scalars['Boolean'] | null } }
    | boolean
    | number
  max?: actions_max_fieldsGenqlSelection
  min?: actions_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface actions_append_input {
  auth?: Scalars['jsonb'] | null
  data?: Scalars['jsonb'] | null
}

/** Boolean expression to filter rows from the table "actions". All fields are combined with a logical 'AND'. */
export interface actions_bool_exp {
  _and?: actions_bool_exp[] | null
  _not?: actions_bool_exp | null
  _or?: actions_bool_exp[] | null
  action?: String_comparison_exp | null
  auth?: jsonb_comparison_exp | null
  contract?: String_comparison_exp | null
  data?: jsonb_comparison_exp | null
  global_sequence?: String_comparison_exp | null
  transaction_id?: String_comparison_exp | null
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface actions_delete_at_path_input {
  auth?: Scalars['String'][] | null
  data?: Scalars['String'][] | null
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface actions_delete_elem_input {
  auth?: Scalars['Int'] | null
  data?: Scalars['Int'] | null
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface actions_delete_key_input {
  auth?: Scalars['String'] | null
  data?: Scalars['String'] | null
}

/** input type for inserting data into table "actions" */
export interface actions_insert_input {
  action?: Scalars['String'] | null
  auth?: Scalars['jsonb'] | null
  contract?: Scalars['String'] | null
  data?: Scalars['jsonb'] | null
  global_sequence?: Scalars['String'] | null
  transaction_id?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface actions_max_fieldsGenqlSelection {
  action?: boolean | number
  contract?: boolean | number
  global_sequence?: boolean | number
  transaction_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface actions_min_fieldsGenqlSelection {
  action?: boolean | number
  contract?: boolean | number
  global_sequence?: boolean | number
  transaction_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "actions" */
export interface actions_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: actionsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "actions" */
export interface actions_on_conflict {
  constraint: actions_constraint
  update_columns?: actions_update_column[]
  where?: actions_bool_exp | null
}

/** Ordering options when selecting data from "actions". */
export interface actions_order_by {
  action?: order_by | null
  auth?: order_by | null
  contract?: order_by | null
  data?: order_by | null
  global_sequence?: order_by | null
  transaction_id?: order_by | null
}

/** primary key columns input for table: actions */
export interface actions_pk_columns_input {
  global_sequence: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface actions_prepend_input {
  auth?: Scalars['jsonb'] | null
  data?: Scalars['jsonb'] | null
}

/** input type for updating data in table "actions" */
export interface actions_set_input {
  action?: Scalars['String'] | null
  auth?: Scalars['jsonb'] | null
  contract?: Scalars['String'] | null
  data?: Scalars['jsonb'] | null
  global_sequence?: Scalars['String'] | null
  transaction_id?: Scalars['String'] | null
}

/** Streaming cursor of the table "actions" */
export interface actions_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: actions_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface actions_stream_cursor_value_input {
  action?: Scalars['String'] | null
  auth?: Scalars['jsonb'] | null
  contract?: Scalars['String'] | null
  data?: Scalars['jsonb'] | null
  global_sequence?: Scalars['String'] | null
  transaction_id?: Scalars['String'] | null
}

export interface actions_updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: actions_append_input | null
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: actions_delete_at_path_input | null
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: actions_delete_elem_input | null
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: actions_delete_key_input | null
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: actions_prepend_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: actions_set_input | null
  /** filter the rows which have to be updated */
  where: actions_bool_exp
}

/** columns and relationships of "avg_pay_time" */
export interface avg_pay_timeGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "avg_pay_time" */
export interface avg_pay_time_aggregateGenqlSelection {
  aggregate?: avg_pay_time_aggregate_fieldsGenqlSelection
  nodes?: avg_pay_timeGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "avg_pay_time" */
export interface avg_pay_time_aggregate_fieldsGenqlSelection {
  avg?: avg_pay_time_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: avg_pay_time_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: avg_pay_time_max_fieldsGenqlSelection
  min?: avg_pay_time_min_fieldsGenqlSelection
  stddev?: avg_pay_time_stddev_fieldsGenqlSelection
  stddev_pop?: avg_pay_time_stddev_pop_fieldsGenqlSelection
  stddev_samp?: avg_pay_time_stddev_samp_fieldsGenqlSelection
  sum?: avg_pay_time_sum_fieldsGenqlSelection
  var_pop?: avg_pay_time_var_pop_fieldsGenqlSelection
  var_samp?: avg_pay_time_var_samp_fieldsGenqlSelection
  variance?: avg_pay_time_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface avg_pay_time_avg_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "avg_pay_time". All fields are combined with a logical 'AND'. */
export interface avg_pay_time_bool_exp {
  _and?: avg_pay_time_bool_exp[] | null
  _not?: avg_pay_time_bool_exp | null
  _or?: avg_pay_time_bool_exp[] | null
  avg_pay_time_seconds?: numeric_comparison_exp | null
  user_id?: String_comparison_exp | null
}

/** aggregate max on columns */
export interface avg_pay_time_max_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface avg_pay_time_min_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "avg_pay_time". */
export interface avg_pay_time_order_by {
  avg_pay_time_seconds?: order_by | null
  user_id?: order_by | null
}

/** aggregate stddev on columns */
export interface avg_pay_time_stddev_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface avg_pay_time_stddev_pop_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface avg_pay_time_stddev_samp_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "avg_pay_time" */
export interface avg_pay_time_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: avg_pay_time_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface avg_pay_time_stream_cursor_value_input {
  avg_pay_time_seconds?: Scalars['numeric'] | null
  user_id?: Scalars['String'] | null
}

/** aggregate sum on columns */
export interface avg_pay_time_sum_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface avg_pay_time_var_pop_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface avg_pay_time_var_samp_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface avg_pay_time_variance_fieldsGenqlSelection {
  avg_pay_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "avg_release_time" */
export interface avg_release_timeGenqlSelection {
  avg_release_time_seconds?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "avg_release_time" */
export interface avg_release_time_aggregateGenqlSelection {
  aggregate?: avg_release_time_aggregate_fieldsGenqlSelection
  nodes?: avg_release_timeGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "avg_release_time" */
export interface avg_release_time_aggregate_fieldsGenqlSelection {
  avg?: avg_release_time_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: avg_release_time_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: avg_release_time_max_fieldsGenqlSelection
  min?: avg_release_time_min_fieldsGenqlSelection
  stddev?: avg_release_time_stddev_fieldsGenqlSelection
  stddev_pop?: avg_release_time_stddev_pop_fieldsGenqlSelection
  stddev_samp?: avg_release_time_stddev_samp_fieldsGenqlSelection
  sum?: avg_release_time_sum_fieldsGenqlSelection
  var_pop?: avg_release_time_var_pop_fieldsGenqlSelection
  var_samp?: avg_release_time_var_samp_fieldsGenqlSelection
  variance?: avg_release_time_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface avg_release_time_avg_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "avg_release_time". All fields are combined with a logical 'AND'. */
export interface avg_release_time_bool_exp {
  _and?: avg_release_time_bool_exp[] | null
  _not?: avg_release_time_bool_exp | null
  _or?: avg_release_time_bool_exp[] | null
  avg_release_time_seconds?: numeric_comparison_exp | null
  user_id?: String_comparison_exp | null
}

/** aggregate max on columns */
export interface avg_release_time_max_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface avg_release_time_min_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "avg_release_time". */
export interface avg_release_time_order_by {
  avg_release_time_seconds?: order_by | null
  user_id?: order_by | null
}

/** aggregate stddev on columns */
export interface avg_release_time_stddev_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface avg_release_time_stddev_pop_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface avg_release_time_stddev_samp_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "avg_release_time" */
export interface avg_release_time_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: avg_release_time_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface avg_release_time_stream_cursor_value_input {
  avg_release_time_seconds?: Scalars['numeric'] | null
  user_id?: Scalars['String'] | null
}

/** aggregate sum on columns */
export interface avg_release_time_sum_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface avg_release_time_var_pop_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface avg_release_time_var_samp_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface avg_release_time_variance_fieldsGenqlSelection {
  avg_release_time_seconds?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export interface bigint_comparison_exp {
  _eq?: Scalars['bigint'] | null
  _gt?: Scalars['bigint'] | null
  _gte?: Scalars['bigint'] | null
  _in?: Scalars['bigint'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['bigint'] | null
  _lte?: Scalars['bigint'] | null
  _neq?: Scalars['bigint'] | null
  _nin?: Scalars['bigint'][] | null
}

export interface cancel_p2p_approval_input {
  cancellation_approved_by: Scalars['String']
  cancellation_transaction: Scalars['String']
  id: Scalars['String']
}

export interface cancel_p2p_offer_input {
  cancelled_by: Scalars['String']
  id: Scalars['String']
}

export interface cancel_wallet_request_approval_input {
  cancellation_approved_by: Scalars['String']
  cancellation_transaction: Scalars['String']
  id: Scalars['String']
}

export interface cancel_wallet_request_input {
  cancelled_by: Scalars['String']
  id: Scalars['String']
}

/** columns and relationships of "combined_avg_times" */
export interface combined_avg_timesGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "combined_avg_times" */
export interface combined_avg_times_aggregateGenqlSelection {
  aggregate?: combined_avg_times_aggregate_fieldsGenqlSelection
  nodes?: combined_avg_timesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "combined_avg_times" */
export interface combined_avg_times_aggregate_fieldsGenqlSelection {
  avg?: combined_avg_times_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: combined_avg_times_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: combined_avg_times_max_fieldsGenqlSelection
  min?: combined_avg_times_min_fieldsGenqlSelection
  stddev?: combined_avg_times_stddev_fieldsGenqlSelection
  stddev_pop?: combined_avg_times_stddev_pop_fieldsGenqlSelection
  stddev_samp?: combined_avg_times_stddev_samp_fieldsGenqlSelection
  sum?: combined_avg_times_sum_fieldsGenqlSelection
  var_pop?: combined_avg_times_var_pop_fieldsGenqlSelection
  var_samp?: combined_avg_times_var_samp_fieldsGenqlSelection
  variance?: combined_avg_times_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface combined_avg_times_avg_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "combined_avg_times". All fields are combined with a logical 'AND'. */
export interface combined_avg_times_bool_exp {
  _and?: combined_avg_times_bool_exp[] | null
  _not?: combined_avg_times_bool_exp | null
  _or?: combined_avg_times_bool_exp[] | null
  avg_pay_time_minutes?: numeric_comparison_exp | null
  avg_release_time_minutes?: numeric_comparison_exp | null
  user_id?: String_comparison_exp | null
}

/** aggregate max on columns */
export interface combined_avg_times_max_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface combined_avg_times_min_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  user_id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "combined_avg_times". */
export interface combined_avg_times_order_by {
  avg_pay_time_minutes?: order_by | null
  avg_release_time_minutes?: order_by | null
  user_id?: order_by | null
}

/** aggregate stddev on columns */
export interface combined_avg_times_stddev_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface combined_avg_times_stddev_pop_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface combined_avg_times_stddev_samp_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "combined_avg_times" */
export interface combined_avg_times_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: combined_avg_times_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface combined_avg_times_stream_cursor_value_input {
  avg_pay_time_minutes?: Scalars['numeric'] | null
  avg_release_time_minutes?: Scalars['numeric'] | null
  user_id?: Scalars['String'] | null
}

/** aggregate sum on columns */
export interface combined_avg_times_sum_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_pop on columns */
export interface combined_avg_times_var_pop_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface combined_avg_times_var_samp_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface combined_avg_times_variance_fieldsGenqlSelection {
  avg_pay_time_minutes?: boolean | number
  avg_release_time_minutes?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface confirm_p2p_offer_input {
  buyer?: Scalars['String'] | null
  id: Scalars['String']
  sell_settlement_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
}

export interface confirm_wallet_request_input {
  buyer?: Scalars['String'] | null
  id: Scalars['String']
  sell_settlement_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
}

/** columns and relationships of "devices" */
export interface devicesGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  public_key?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "devices" */
export interface devices_aggregateGenqlSelection {
  aggregate?: devices_aggregate_fieldsGenqlSelection
  nodes?: devicesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface devices_aggregate_bool_exp {
  count?: devices_aggregate_bool_exp_count | null
}

export interface devices_aggregate_bool_exp_count {
  arguments?: devices_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: devices_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "devices" */
export interface devices_aggregate_fieldsGenqlSelection {
  count?:
    | { __args: { columns?: devices_select_column[] | null; distinct?: Scalars['Boolean'] | null } }
    | boolean
    | number
  max?: devices_max_fieldsGenqlSelection
  min?: devices_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "devices" */
export interface devices_aggregate_order_by {
  count?: order_by | null
  max?: devices_max_order_by | null
  min?: devices_min_order_by | null
}

/** input type for inserting array relation for remote table "devices" */
export interface devices_arr_rel_insert_input {
  data: devices_insert_input[]
  /** upsert condition */
  on_conflict?: devices_on_conflict | null
}

/** Boolean expression to filter rows from the table "devices". All fields are combined with a logical 'AND'. */
export interface devices_bool_exp {
  _and?: devices_bool_exp[] | null
  _not?: devices_bool_exp | null
  _or?: devices_bool_exp[] | null
  account?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  cred_id?: String_comparison_exp | null
  device_name?: String_comparison_exp | null
  public_key?: String_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
}

/** input type for inserting data into table "devices" */
export interface devices_insert_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  public_key?: Scalars['String'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
}

/** aggregate max on columns */
export interface devices_max_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  public_key?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "devices" */
export interface devices_max_order_by {
  account?: order_by | null
  created_at?: order_by | null
  cred_id?: order_by | null
  device_name?: order_by | null
  public_key?: order_by | null
}

/** aggregate min on columns */
export interface devices_min_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  public_key?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "devices" */
export interface devices_min_order_by {
  account?: order_by | null
  created_at?: order_by | null
  cred_id?: order_by | null
  device_name?: order_by | null
  public_key?: order_by | null
}

/** response of any mutation on the table "devices" */
export interface devices_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: devicesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "devices" */
export interface devices_on_conflict {
  constraint: devices_constraint
  update_columns?: devices_update_column[]
  where?: devices_bool_exp | null
}

/** Ordering options when selecting data from "devices". */
export interface devices_order_by {
  account?: order_by | null
  created_at?: order_by | null
  cred_id?: order_by | null
  device_name?: order_by | null
  public_key?: order_by | null
  reg_account?: reg_accounts_order_by | null
}

/** primary key columns input for table: devices */
export interface devices_pk_columns_input {
  cred_id: Scalars['String']
}

/** input type for updating data in table "devices" */
export interface devices_set_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  public_key?: Scalars['String'] | null
}

/** Streaming cursor of the table "devices" */
export interface devices_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: devices_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface devices_stream_cursor_value_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  public_key?: Scalars['String'] | null
}

export interface devices_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: devices_set_input | null
  /** filter the rows which have to be updated */
  where: devices_bool_exp
}

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export interface float8_comparison_exp {
  _eq?: Scalars['float8'] | null
  _gt?: Scalars['float8'] | null
  _gte?: Scalars['float8'] | null
  _in?: Scalars['float8'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['float8'] | null
  _lte?: Scalars['float8'] | null
  _neq?: Scalars['float8'] | null
  _nin?: Scalars['float8'][] | null
}

export interface get_inactive_accounts_args {
  p_days_ago?: Scalars['Int'] | null
}

export interface get_login_counts_args {
  p_interval?: Scalars['String'] | null
}

export interface get_p2p_offers_with_old_or_no_messages_args {
  completed_status?: Scalars['Boolean'] | null
  hours?: Scalars['Int'] | null
  matched_status?: Scalars['Boolean'] | null
}

export interface get_tx_statistics_args {
  p_interval?: Scalars['String'] | null
}

export interface get_volume_statistics_args {
  p_interval?: Scalars['String'] | null
}

export interface id_verification_input {
  country: Scalars['String']
  id: Scalars['String']
  type: Scalars['String']
}

export interface id_verification_outputGenqlSelection {
  existingRecord?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface jsonb_cast_exp {
  String?: String_comparison_exp | null
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export interface jsonb_comparison_exp {
  _cast?: jsonb_cast_exp | null
  /** is the column contained in the given json value */
  _contained_in?: Scalars['jsonb'] | null
  /** does the column contain the given json value at the top level */
  _contains?: Scalars['jsonb'] | null
  _eq?: Scalars['jsonb'] | null
  _gt?: Scalars['jsonb'] | null
  _gte?: Scalars['jsonb'] | null
  /** does the string exist as a top-level key in the column */
  _has_key?: Scalars['String'] | null
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Scalars['String'][] | null
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Scalars['String'][] | null
  _in?: Scalars['jsonb'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['jsonb'] | null
  _lte?: Scalars['jsonb'] | null
  _neq?: Scalars['jsonb'] | null
  _nin?: Scalars['jsonb'][] | null
}

/** columns and relationships of "login_account" */
export interface login_accountGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "login_account" */
export interface login_account_aggregateGenqlSelection {
  aggregate?: login_account_aggregate_fieldsGenqlSelection
  nodes?: login_accountGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface login_account_aggregate_bool_exp {
  count?: login_account_aggregate_bool_exp_count | null
}

export interface login_account_aggregate_bool_exp_count {
  arguments?: login_account_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: login_account_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "login_account" */
export interface login_account_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: login_account_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: login_account_max_fieldsGenqlSelection
  min?: login_account_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "login_account" */
export interface login_account_aggregate_order_by {
  count?: order_by | null
  max?: login_account_max_order_by | null
  min?: login_account_min_order_by | null
}

/** input type for inserting array relation for remote table "login_account" */
export interface login_account_arr_rel_insert_input {
  data: login_account_insert_input[]
  /** upsert condition */
  on_conflict?: login_account_on_conflict | null
}

/** Boolean expression to filter rows from the table "login_account". All fields are combined with a logical 'AND'. */
export interface login_account_bool_exp {
  _and?: login_account_bool_exp[] | null
  _not?: login_account_bool_exp | null
  _or?: login_account_bool_exp[] | null
  account?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: uuid_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
}

/** input type for inserting data into table "login_account" */
export interface login_account_insert_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
}

/** aggregate max on columns */
export interface login_account_max_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "login_account" */
export interface login_account_max_order_by {
  account?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
}

/** aggregate min on columns */
export interface login_account_min_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "login_account" */
export interface login_account_min_order_by {
  account?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
}

/** response of any mutation on the table "login_account" */
export interface login_account_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: login_accountGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "login_account" */
export interface login_account_on_conflict {
  constraint: login_account_constraint
  update_columns?: login_account_update_column[]
  where?: login_account_bool_exp | null
}

/** Ordering options when selecting data from "login_account". */
export interface login_account_order_by {
  account?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  reg_account?: reg_accounts_order_by | null
}

/** primary key columns input for table: login_account */
export interface login_account_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "login_account" */
export interface login_account_set_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
}

/** Streaming cursor of the table "login_account" */
export interface login_account_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: login_account_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface login_account_stream_cursor_value_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
}

export interface login_account_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: login_account_set_input | null
  /** filter the rows which have to be updated */
  where: login_account_bool_exp
}

/** columns and relationships of "login_counts" */
export interface login_countsGenqlSelection {
  accounts?: boolean | number
  date?: boolean | number
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface login_counts_aggregateGenqlSelection {
  aggregate?: login_counts_aggregate_fieldsGenqlSelection
  nodes?: login_countsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "login_counts" */
export interface login_counts_aggregate_fieldsGenqlSelection {
  avg?: login_counts_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: login_counts_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: login_counts_max_fieldsGenqlSelection
  min?: login_counts_min_fieldsGenqlSelection
  stddev?: login_counts_stddev_fieldsGenqlSelection
  stddev_pop?: login_counts_stddev_pop_fieldsGenqlSelection
  stddev_samp?: login_counts_stddev_samp_fieldsGenqlSelection
  sum?: login_counts_sum_fieldsGenqlSelection
  var_pop?: login_counts_var_pop_fieldsGenqlSelection
  var_samp?: login_counts_var_samp_fieldsGenqlSelection
  variance?: login_counts_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface login_counts_avg_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "login_counts". All fields are combined with a logical 'AND'. */
export interface login_counts_bool_exp {
  _and?: login_counts_bool_exp[] | null
  _not?: login_counts_bool_exp | null
  _or?: login_counts_bool_exp[] | null
  accounts?: String_array_comparison_exp | null
  date?: timestamptz_comparison_exp | null
  login_count?: bigint_comparison_exp | null
}

/** input type for incrementing numeric columns in table "login_counts" */
export interface login_counts_inc_input {
  login_count?: Scalars['bigint'] | null
}

/** input type for inserting data into table "login_counts" */
export interface login_counts_insert_input {
  accounts?: Scalars['String'][] | null
  date?: Scalars['timestamptz'] | null
  login_count?: Scalars['bigint'] | null
}

/** aggregate max on columns */
export interface login_counts_max_fieldsGenqlSelection {
  accounts?: boolean | number
  date?: boolean | number
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface login_counts_min_fieldsGenqlSelection {
  accounts?: boolean | number
  date?: boolean | number
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "login_counts" */
export interface login_counts_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: login_countsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "login_counts". */
export interface login_counts_order_by {
  accounts?: order_by | null
  date?: order_by | null
  login_count?: order_by | null
}

/** input type for updating data in table "login_counts" */
export interface login_counts_set_input {
  accounts?: Scalars['String'][] | null
  date?: Scalars['timestamptz'] | null
  login_count?: Scalars['bigint'] | null
}

/** aggregate stddev on columns */
export interface login_counts_stddev_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface login_counts_stddev_pop_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface login_counts_stddev_samp_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "login_counts" */
export interface login_counts_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: login_counts_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface login_counts_stream_cursor_value_input {
  accounts?: Scalars['String'][] | null
  date?: Scalars['timestamptz'] | null
  login_count?: Scalars['bigint'] | null
}

/** aggregate sum on columns */
export interface login_counts_sum_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface login_counts_updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: login_counts_inc_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: login_counts_set_input | null
  /** filter the rows which have to be updated */
  where: login_counts_bool_exp
}

/** aggregate var_pop on columns */
export interface login_counts_var_pop_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface login_counts_var_samp_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface login_counts_variance_fieldsGenqlSelection {
  login_count?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface make_p2p_buy_offer_input {
  amount: Scalars['String']
  buyer: Scalars['String']
  buyer_method_details?: Scalars['String'] | null
  id?: Scalars['String'] | null
  method: Scalars['String']
  region: Scalars['String']
}

export interface make_p2p_sell_offer_input {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  id?: Scalars['String'] | null
  method: Scalars['String']
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller: Scalars['String']
  seller_method_details?: Scalars['String'] | null
}

export interface make_wallet_deposit_request_input {
  amount: Scalars['String']
  buyer: Scalars['String']
  buyer_method_details?: Scalars['String'] | null
  id?: Scalars['String'] | null
  method: Scalars['String']
  region: Scalars['String']
}

export interface make_wallet_withdraw_request_input {
  amount: Scalars['String']
  bitcashbank_id: Scalars['String']
  id?: Scalars['String'] | null
  method: Scalars['String']
  region: Scalars['String']
  sell_put_transaction: Scalars['String']
  seller: Scalars['String']
  seller_method_details?: Scalars['String'] | null
}

/** columns and relationships of "messages" */
export interface messagesGenqlSelection {
  from?: boolean | number
  id?: boolean | number
  message?: boolean | number
  p2p_id?: boolean | number
  /** An object relationship */
  p2p_offer?: p2p_offersGenqlSelection
  /** An object relationship */
  regAccountByTo?: reg_accountsGenqlSelection
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  support_id?: boolean | number
  swap_id?: boolean | number
  /** An object relationship */
  swap_order?: swap_ordersGenqlSelection
  timestamp?: boolean | number
  to?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "messages" */
export interface messages_aggregateGenqlSelection {
  aggregate?: messages_aggregate_fieldsGenqlSelection
  nodes?: messagesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface messages_aggregate_bool_exp {
  count?: messages_aggregate_bool_exp_count | null
}

export interface messages_aggregate_bool_exp_count {
  arguments?: messages_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: messages_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "messages" */
export interface messages_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: { columns?: messages_select_column[] | null; distinct?: Scalars['Boolean'] | null }
      }
    | boolean
    | number
  max?: messages_max_fieldsGenqlSelection
  min?: messages_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "messages" */
export interface messages_aggregate_order_by {
  count?: order_by | null
  max?: messages_max_order_by | null
  min?: messages_min_order_by | null
}

/** input type for inserting array relation for remote table "messages" */
export interface messages_arr_rel_insert_input {
  data: messages_insert_input[]
  /** upsert condition */
  on_conflict?: messages_on_conflict | null
}

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export interface messages_bool_exp {
  _and?: messages_bool_exp[] | null
  _not?: messages_bool_exp | null
  _or?: messages_bool_exp[] | null
  from?: String_comparison_exp | null
  id?: uuid_comparison_exp | null
  message?: String_comparison_exp | null
  p2p_id?: uuid_comparison_exp | null
  p2p_offer?: p2p_offers_bool_exp | null
  regAccountByTo?: reg_accounts_bool_exp | null
  reg_account?: reg_accounts_bool_exp | null
  support_id?: uuid_comparison_exp | null
  swap_id?: uuid_comparison_exp | null
  swap_order?: swap_orders_bool_exp | null
  timestamp?: timestamptz_comparison_exp | null
  to?: String_comparison_exp | null
}

/** input type for inserting data into table "messages" */
export interface messages_insert_input {
  from?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  message?: Scalars['String'] | null
  p2p_id?: Scalars['uuid'] | null
  p2p_offer?: p2p_offers_obj_rel_insert_input | null
  regAccountByTo?: reg_accounts_obj_rel_insert_input | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  support_id?: Scalars['uuid'] | null
  swap_id?: Scalars['uuid'] | null
  swap_order?: swap_orders_obj_rel_insert_input | null
  timestamp?: Scalars['timestamptz'] | null
  to?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface messages_max_fieldsGenqlSelection {
  from?: boolean | number
  id?: boolean | number
  message?: boolean | number
  p2p_id?: boolean | number
  support_id?: boolean | number
  swap_id?: boolean | number
  timestamp?: boolean | number
  to?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "messages" */
export interface messages_max_order_by {
  from?: order_by | null
  id?: order_by | null
  message?: order_by | null
  p2p_id?: order_by | null
  support_id?: order_by | null
  swap_id?: order_by | null
  timestamp?: order_by | null
  to?: order_by | null
}

/** aggregate min on columns */
export interface messages_min_fieldsGenqlSelection {
  from?: boolean | number
  id?: boolean | number
  message?: boolean | number
  p2p_id?: boolean | number
  support_id?: boolean | number
  swap_id?: boolean | number
  timestamp?: boolean | number
  to?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "messages" */
export interface messages_min_order_by {
  from?: order_by | null
  id?: order_by | null
  message?: order_by | null
  p2p_id?: order_by | null
  support_id?: order_by | null
  swap_id?: order_by | null
  timestamp?: order_by | null
  to?: order_by | null
}

/** response of any mutation on the table "messages" */
export interface messages_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: messagesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "messages" */
export interface messages_on_conflict {
  constraint: messages_constraint
  update_columns?: messages_update_column[]
  where?: messages_bool_exp | null
}

/** Ordering options when selecting data from "messages". */
export interface messages_order_by {
  from?: order_by | null
  id?: order_by | null
  message?: order_by | null
  p2p_id?: order_by | null
  p2p_offer?: p2p_offers_order_by | null
  regAccountByTo?: reg_accounts_order_by | null
  reg_account?: reg_accounts_order_by | null
  support_id?: order_by | null
  swap_id?: order_by | null
  swap_order?: swap_orders_order_by | null
  timestamp?: order_by | null
  to?: order_by | null
}

/** primary key columns input for table: messages */
export interface messages_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "messages" */
export interface messages_set_input {
  from?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  message?: Scalars['String'] | null
  p2p_id?: Scalars['uuid'] | null
  support_id?: Scalars['uuid'] | null
  swap_id?: Scalars['uuid'] | null
  timestamp?: Scalars['timestamptz'] | null
  to?: Scalars['String'] | null
}

/** Streaming cursor of the table "messages" */
export interface messages_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: messages_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface messages_stream_cursor_value_input {
  from?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  message?: Scalars['String'] | null
  p2p_id?: Scalars['uuid'] | null
  support_id?: Scalars['uuid'] | null
  swap_id?: Scalars['uuid'] | null
  timestamp?: Scalars['timestamptz'] | null
  to?: Scalars['String'] | null
}

export interface messages_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: messages_set_input | null
  /** filter the rows which have to be updated */
  where: messages_bool_exp
}

/** columns and relationships of "migrate_device" */
export interface migrate_deviceGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  public_key?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "migrate_device" */
export interface migrate_device_aggregateGenqlSelection {
  aggregate?: migrate_device_aggregate_fieldsGenqlSelection
  nodes?: migrate_deviceGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "migrate_device" */
export interface migrate_device_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: migrate_device_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: migrate_device_max_fieldsGenqlSelection
  min?: migrate_device_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "migrate_device". All fields are combined with a logical 'AND'. */
export interface migrate_device_bool_exp {
  _and?: migrate_device_bool_exp[] | null
  _not?: migrate_device_bool_exp | null
  _or?: migrate_device_bool_exp[] | null
  account?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  cred_id?: String_comparison_exp | null
  device_name?: String_comparison_exp | null
  public_key?: String_comparison_exp | null
}

/** input type for inserting data into table "migrate_device" */
export interface migrate_device_insert_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  public_key?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface migrate_device_max_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  public_key?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface migrate_device_min_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  public_key?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "migrate_device" */
export interface migrate_device_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: migrate_deviceGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "migrate_device" */
export interface migrate_device_on_conflict {
  constraint: migrate_device_constraint
  update_columns?: migrate_device_update_column[]
  where?: migrate_device_bool_exp | null
}

/** Ordering options when selecting data from "migrate_device". */
export interface migrate_device_order_by {
  account?: order_by | null
  created_at?: order_by | null
  cred_id?: order_by | null
  device_name?: order_by | null
  public_key?: order_by | null
}

/** primary key columns input for table: migrate_device */
export interface migrate_device_pk_columns_input {
  cred_id: Scalars['String']
}

/** input type for updating data in table "migrate_device" */
export interface migrate_device_set_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  public_key?: Scalars['String'] | null
}

/** Streaming cursor of the table "migrate_device" */
export interface migrate_device_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: migrate_device_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface migrate_device_stream_cursor_value_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  public_key?: Scalars['String'] | null
}

export interface migrate_device_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: migrate_device_set_input | null
  /** filter the rows which have to be updated */
  where: migrate_device_bool_exp
}

/** mutation root */
export interface mutation_rootGenqlSelection {
  approve_new_account?:
    | { __args: { is_verified?: Scalars['Boolean'] | null; request_id?: Scalars['String'] | null } }
    | boolean
    | number
  auto_cancel_p2p_offer?: p2p_offer_outputGenqlSelection & {
    __args?: { p2p_data?: cancel_p2p_offer_input | null }
  }
  cancel_p2p_approval?: p2p_offer_outputGenqlSelection & {
    __args?: { p2p_data?: cancel_p2p_approval_input | null }
  }
  cancel_p2p_offer?: p2p_offer_outputGenqlSelection & {
    __args?: { p2p_data?: cancel_p2p_offer_input | null }
  }
  cancel_wallet_request?: wallet_request_outputGenqlSelection & {
    __args?: { p2p_data?: cancel_wallet_request_input | null }
  }
  cancel_wallet_request_approval?: wallet_request_outputGenqlSelection & {
    __args?: { p2p_data?: cancel_wallet_request_approval_input | null }
  }
  confirm_p2p_payment?: p2p_offer_outputGenqlSelection & {
    __args?: { p2p_data?: confirm_p2p_offer_input | null }
  }
  confirm_wallet_request_payment?: wallet_request_outputGenqlSelection & {
    __args?: { p2p_data?: confirm_wallet_request_input | null }
  }
  /** delete data from the table: "accounts_information" */
  delete_accounts_information?: accounts_information_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: accounts_information_bool_exp
    }
  }
  /** delete single row from the table: "accounts_information" */
  delete_accounts_information_by_pk?: accounts_informationGenqlSelection & {
    __args: { account: Scalars['String']; id: Scalars['uuid'] }
  }
  /** delete data from the table: "accounts_referral" */
  delete_accounts_referral?: accounts_referral_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: accounts_referral_bool_exp
    }
  }
  /** delete single row from the table: "accounts_referral" */
  delete_accounts_referral_by_pk?: accounts_referralGenqlSelection & {
    __args: { link: Scalars['String'] }
  }
  /** delete data from the table: "actions" */
  delete_actions?: actions_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: actions_bool_exp
    }
  }
  /** delete single row from the table: "actions" */
  delete_actions_by_pk?: actionsGenqlSelection & { __args: { global_sequence: Scalars['String'] } }
  /** delete data from the table: "devices" */
  delete_devices?: devices_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: devices_bool_exp
    }
  }
  /** delete single row from the table: "devices" */
  delete_devices_by_pk?: devicesGenqlSelection & { __args: { cred_id: Scalars['String'] } }
  /** delete data from the table: "login_account" */
  delete_login_account?: login_account_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: login_account_bool_exp
    }
  }
  /** delete single row from the table: "login_account" */
  delete_login_account_by_pk?: login_accountGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** delete data from the table: "login_counts" */
  delete_login_counts?: login_counts_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: login_counts_bool_exp
    }
  }
  /** delete data from the table: "messages" */
  delete_messages?: messages_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: messages_bool_exp
    }
  }
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: messagesGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** delete data from the table: "migrate_device" */
  delete_migrate_device?: migrate_device_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: migrate_device_bool_exp
    }
  }
  /** delete single row from the table: "migrate_device" */
  delete_migrate_device_by_pk?: migrate_deviceGenqlSelection & {
    __args: { cred_id: Scalars['String'] }
  }
  /** delete data from the table: "notifications" */
  delete_notifications?: notifications_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: notifications_bool_exp
    }
  }
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: notificationsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** delete data from the table: "p2p_offers" */
  delete_p2p_offers?: p2p_offers_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: p2p_offers_bool_exp
    }
  }
  /** delete single row from the table: "p2p_offers" */
  delete_p2p_offers_by_pk?: p2p_offersGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** delete data from the table: "p2p_pre_sell_offers" */
  delete_p2p_pre_sell_offers?: p2p_pre_sell_offers_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: p2p_pre_sell_offers_bool_exp
    }
  }
  /** delete single row from the table: "p2p_pre_sell_offers" */
  delete_p2p_pre_sell_offers_by_pk?: p2p_pre_sell_offersGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** delete data from the table: "preferences" */
  delete_preferences?: preferences_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: preferences_bool_exp
    }
  }
  /** delete single row from the table: "preferences" */
  delete_preferences_by_pk?: preferencesGenqlSelection & { __args: { account: Scalars['String'] } }
  /** delete data from the table: "reg_accounts" */
  delete_reg_accounts?: reg_accounts_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: reg_accounts_bool_exp
    }
  }
  /** delete single row from the table: "reg_accounts" */
  delete_reg_accounts_by_pk?: reg_accountsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** delete data from the table: "reputation_score" */
  delete_reputation_score?: reputation_score_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: reputation_score_bool_exp
    }
  }
  /** delete single row from the table: "reputation_score" */
  delete_reputation_score_by_pk?: reputation_scoreGenqlSelection & {
    __args: { account: Scalars['String'] }
  }
  /** delete data from the table: "signing_requests" */
  delete_signing_requests?: signing_requests_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: signing_requests_bool_exp
    }
  }
  /** delete single row from the table: "signing_requests" */
  delete_signing_requests_by_pk?: signing_requestsGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** delete data from the table: "support_sessions" */
  delete_support_sessions?: support_sessions_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: support_sessions_bool_exp
    }
  }
  /** delete single row from the table: "support_sessions" */
  delete_support_sessions_by_pk?: support_sessionsGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** delete data from the table: "swap_assets" */
  delete_swap_assets?: swap_assets_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: swap_assets_bool_exp
    }
  }
  /** delete single row from the table: "swap_assets" */
  delete_swap_assets_by_pk?: swap_assetsGenqlSelection & {
    __args: { asset: Scalars['String']; chain: Scalars['String'] }
  }
  /** delete data from the table: "swap_orders" */
  delete_swap_orders?: swap_orders_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: swap_orders_bool_exp
    }
  }
  /** delete single row from the table: "swap_orders" */
  delete_swap_orders_by_pk?: swap_ordersGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** delete data from the table: "system_notification_read_status" */
  delete_system_notification_read_status?: system_notification_read_status_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: system_notification_read_status_bool_exp
    }
  }
  /** delete single row from the table: "system_notification_read_status" */
  delete_system_notification_read_status_by_pk?: system_notification_read_statusGenqlSelection & {
    __args: { notification_id: Scalars['uuid']; reg_account: Scalars['uuid'] }
  }
  /** delete data from the table: "system_notifications" */
  delete_system_notifications?: system_notifications_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: system_notifications_bool_exp
    }
  }
  /** delete single row from the table: "system_notifications" */
  delete_system_notifications_by_pk?: system_notificationsGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** delete data from the table: "system_type_notification" */
  delete_system_type_notification?: system_type_notification_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: system_type_notification_bool_exp
    }
  }
  /** delete single row from the table: "system_type_notification" */
  delete_system_type_notification_by_pk?: system_type_notificationGenqlSelection & {
    __args: { type: Scalars['String'] }
  }
  /** delete data from the table: "table_rows" */
  delete_table_rows?: table_rows_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: table_rows_bool_exp
    }
  }
  /** delete single row from the table: "table_rows" */
  delete_table_rows_by_pk?: table_rowsGenqlSelection & {
    __args: {
      contract: Scalars['String']
      primary_key: Scalars['String']
      scope: Scalars['String']
      table: Scalars['String']
    }
  }
  /** delete data from the table: "trust_network" */
  delete_trust_network?: trust_network_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: trust_network_bool_exp
    }
  }
  /** delete single row from the table: "trust_network" */
  delete_trust_network_by_pk?: trust_networkGenqlSelection & {
    __args: { account: Scalars['String']; trust: Scalars['String'] }
  }
  /** delete data from the table: "trust_network_notification" */
  delete_trust_network_notification?: trust_network_notification_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: trust_network_notification_bool_exp
    }
  }
  /** delete single row from the table: "trust_network_notification" */
  delete_trust_network_notification_by_pk?: trust_network_notificationGenqlSelection & {
    __args: { account: Scalars['String']; trust: Scalars['String'] }
  }
  /** delete data from the table: "trust_network_status" */
  delete_trust_network_status?: trust_network_status_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: trust_network_status_bool_exp
    }
  }
  /** delete single row from the table: "trust_network_status" */
  delete_trust_network_status_by_pk?: trust_network_statusGenqlSelection & {
    __args: { status: Scalars['String'] }
  }
  /** delete data from the table: "tx_statistics" */
  delete_tx_statistics?: tx_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: tx_statistics_bool_exp
    }
  }
  /** delete data from the table: "volume_statistics" */
  delete_volume_statistics?: volume_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: volume_statistics_bool_exp
    }
  }
  /** delete data from the table: "web_push" */
  delete_web_push?: web_push_mutation_responseGenqlSelection & {
    __args: {
      /** filter the rows which have to be deleted */
      where: web_push_bool_exp
    }
  }
  /** delete single row from the table: "web_push" */
  delete_web_push_by_pk?: web_pushGenqlSelection & {
    __args: { auth: Scalars['String']; p256dh: Scalars['String'] }
  }
  generate_short_link?: { __args: { short_link_input: short_link_input } }
  /** execute VOLATILE function "get_tx_statistics" which returns "tx_statistics" */
  get_tx_statistics?: tx_statisticsGenqlSelection & {
    __args: {
      /** input parameters for function "get_tx_statistics" */
      args: get_tx_statistics_args
      /** distinct select on columns */
      distinct_on?: tx_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: tx_statistics_order_by[] | null
      /** filter the rows returned */
      where?: tx_statistics_bool_exp | null
    }
  }
  /** execute VOLATILE function "get_volume_statistics" which returns "volume_statistics" */
  get_volume_statistics?: volume_statisticsGenqlSelection & {
    __args: {
      /** input parameters for function "get_volume_statistics" */
      args: get_volume_statistics_args
      /** distinct select on columns */
      distinct_on?: volume_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: volume_statistics_order_by[] | null
      /** filter the rows returned */
      where?: volume_statistics_bool_exp | null
    }
  }
  /** insert data into the table: "accounts_information" */
  insert_accounts_information?: accounts_information_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: accounts_information_insert_input[]
      /** upsert condition */
      on_conflict?: accounts_information_on_conflict | null
    }
  }
  /** insert a single row into the table: "accounts_information" */
  insert_accounts_information_one?: accounts_informationGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: accounts_information_insert_input
      /** upsert condition */
      on_conflict?: accounts_information_on_conflict | null
    }
  }
  /** insert data into the table: "accounts_referral" */
  insert_accounts_referral?: accounts_referral_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: accounts_referral_insert_input[]
      /** upsert condition */
      on_conflict?: accounts_referral_on_conflict | null
    }
  }
  /** insert a single row into the table: "accounts_referral" */
  insert_accounts_referral_one?: accounts_referralGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: accounts_referral_insert_input
      /** upsert condition */
      on_conflict?: accounts_referral_on_conflict | null
    }
  }
  /** insert data into the table: "actions" */
  insert_actions?: actions_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: actions_insert_input[]
      /** upsert condition */
      on_conflict?: actions_on_conflict | null
    }
  }
  /** insert a single row into the table: "actions" */
  insert_actions_one?: actionsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: actions_insert_input
      /** upsert condition */
      on_conflict?: actions_on_conflict | null
    }
  }
  /** insert data into the table: "devices" */
  insert_devices?: devices_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: devices_insert_input[]
      /** upsert condition */
      on_conflict?: devices_on_conflict | null
    }
  }
  /** insert a single row into the table: "devices" */
  insert_devices_one?: devicesGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: devices_insert_input
      /** upsert condition */
      on_conflict?: devices_on_conflict | null
    }
  }
  /** insert data into the table: "login_account" */
  insert_login_account?: login_account_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: login_account_insert_input[]
      /** upsert condition */
      on_conflict?: login_account_on_conflict | null
    }
  }
  /** insert a single row into the table: "login_account" */
  insert_login_account_one?: login_accountGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: login_account_insert_input
      /** upsert condition */
      on_conflict?: login_account_on_conflict | null
    }
  }
  /** insert data into the table: "login_counts" */
  insert_login_counts?: login_counts_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: login_counts_insert_input[]
    }
  }
  /** insert a single row into the table: "login_counts" */
  insert_login_counts_one?: login_countsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: login_counts_insert_input
    }
  }
  /** insert data into the table: "messages" */
  insert_messages?: messages_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: messages_insert_input[]
      /** upsert condition */
      on_conflict?: messages_on_conflict | null
    }
  }
  /** insert a single row into the table: "messages" */
  insert_messages_one?: messagesGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: messages_insert_input
      /** upsert condition */
      on_conflict?: messages_on_conflict | null
    }
  }
  /** insert data into the table: "migrate_device" */
  insert_migrate_device?: migrate_device_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: migrate_device_insert_input[]
      /** upsert condition */
      on_conflict?: migrate_device_on_conflict | null
    }
  }
  /** insert a single row into the table: "migrate_device" */
  insert_migrate_device_one?: migrate_deviceGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: migrate_device_insert_input
      /** upsert condition */
      on_conflict?: migrate_device_on_conflict | null
    }
  }
  /** insert data into the table: "notifications" */
  insert_notifications?: notifications_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: notifications_insert_input[]
      /** upsert condition */
      on_conflict?: notifications_on_conflict | null
    }
  }
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: notificationsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: notifications_insert_input
      /** upsert condition */
      on_conflict?: notifications_on_conflict | null
    }
  }
  /** insert data into the table: "p2p_offers" */
  insert_p2p_offers?: p2p_offers_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: p2p_offers_insert_input[]
      /** upsert condition */
      on_conflict?: p2p_offers_on_conflict | null
    }
  }
  /** insert a single row into the table: "p2p_offers" */
  insert_p2p_offers_one?: p2p_offersGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: p2p_offers_insert_input
      /** upsert condition */
      on_conflict?: p2p_offers_on_conflict | null
    }
  }
  /** insert data into the table: "p2p_pre_sell_offers" */
  insert_p2p_pre_sell_offers?: p2p_pre_sell_offers_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: p2p_pre_sell_offers_insert_input[]
      /** upsert condition */
      on_conflict?: p2p_pre_sell_offers_on_conflict | null
    }
  }
  /** insert a single row into the table: "p2p_pre_sell_offers" */
  insert_p2p_pre_sell_offers_one?: p2p_pre_sell_offersGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: p2p_pre_sell_offers_insert_input
      /** upsert condition */
      on_conflict?: p2p_pre_sell_offers_on_conflict | null
    }
  }
  /** insert data into the table: "preferences" */
  insert_preferences?: preferences_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: preferences_insert_input[]
      /** upsert condition */
      on_conflict?: preferences_on_conflict | null
    }
  }
  /** insert a single row into the table: "preferences" */
  insert_preferences_one?: preferencesGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: preferences_insert_input
      /** upsert condition */
      on_conflict?: preferences_on_conflict | null
    }
  }
  /** insert data into the table: "reg_accounts" */
  insert_reg_accounts?: reg_accounts_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: reg_accounts_insert_input[]
      /** upsert condition */
      on_conflict?: reg_accounts_on_conflict | null
    }
  }
  /** insert a single row into the table: "reg_accounts" */
  insert_reg_accounts_one?: reg_accountsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: reg_accounts_insert_input
      /** upsert condition */
      on_conflict?: reg_accounts_on_conflict | null
    }
  }
  /** insert data into the table: "reputation_score" */
  insert_reputation_score?: reputation_score_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: reputation_score_insert_input[]
      /** upsert condition */
      on_conflict?: reputation_score_on_conflict | null
    }
  }
  /** insert a single row into the table: "reputation_score" */
  insert_reputation_score_one?: reputation_scoreGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: reputation_score_insert_input
      /** upsert condition */
      on_conflict?: reputation_score_on_conflict | null
    }
  }
  /** insert data into the table: "signing_requests" */
  insert_signing_requests?: signing_requests_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: signing_requests_insert_input[]
      /** upsert condition */
      on_conflict?: signing_requests_on_conflict | null
    }
  }
  /** insert a single row into the table: "signing_requests" */
  insert_signing_requests_one?: signing_requestsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: signing_requests_insert_input
      /** upsert condition */
      on_conflict?: signing_requests_on_conflict | null
    }
  }
  /** insert data into the table: "support_sessions" */
  insert_support_sessions?: support_sessions_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: support_sessions_insert_input[]
      /** upsert condition */
      on_conflict?: support_sessions_on_conflict | null
    }
  }
  /** insert a single row into the table: "support_sessions" */
  insert_support_sessions_one?: support_sessionsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: support_sessions_insert_input
      /** upsert condition */
      on_conflict?: support_sessions_on_conflict | null
    }
  }
  /** insert data into the table: "swap_assets" */
  insert_swap_assets?: swap_assets_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: swap_assets_insert_input[]
      /** upsert condition */
      on_conflict?: swap_assets_on_conflict | null
    }
  }
  /** insert a single row into the table: "swap_assets" */
  insert_swap_assets_one?: swap_assetsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: swap_assets_insert_input
      /** upsert condition */
      on_conflict?: swap_assets_on_conflict | null
    }
  }
  /** insert data into the table: "swap_orders" */
  insert_swap_orders?: swap_orders_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: swap_orders_insert_input[]
      /** upsert condition */
      on_conflict?: swap_orders_on_conflict | null
    }
  }
  /** insert a single row into the table: "swap_orders" */
  insert_swap_orders_one?: swap_ordersGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: swap_orders_insert_input
      /** upsert condition */
      on_conflict?: swap_orders_on_conflict | null
    }
  }
  /** insert data into the table: "system_notification_read_status" */
  insert_system_notification_read_status?: system_notification_read_status_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: system_notification_read_status_insert_input[]
      /** upsert condition */
      on_conflict?: system_notification_read_status_on_conflict | null
    }
  }
  /** insert a single row into the table: "system_notification_read_status" */
  insert_system_notification_read_status_one?: system_notification_read_statusGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: system_notification_read_status_insert_input
      /** upsert condition */
      on_conflict?: system_notification_read_status_on_conflict | null
    }
  }
  /** insert data into the table: "system_notifications" */
  insert_system_notifications?: system_notifications_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: system_notifications_insert_input[]
      /** upsert condition */
      on_conflict?: system_notifications_on_conflict | null
    }
  }
  /** insert a single row into the table: "system_notifications" */
  insert_system_notifications_one?: system_notificationsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: system_notifications_insert_input
      /** upsert condition */
      on_conflict?: system_notifications_on_conflict | null
    }
  }
  /** insert data into the table: "system_type_notification" */
  insert_system_type_notification?: system_type_notification_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: system_type_notification_insert_input[]
      /** upsert condition */
      on_conflict?: system_type_notification_on_conflict | null
    }
  }
  /** insert a single row into the table: "system_type_notification" */
  insert_system_type_notification_one?: system_type_notificationGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: system_type_notification_insert_input
      /** upsert condition */
      on_conflict?: system_type_notification_on_conflict | null
    }
  }
  /** insert data into the table: "table_rows" */
  insert_table_rows?: table_rows_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: table_rows_insert_input[]
      /** upsert condition */
      on_conflict?: table_rows_on_conflict | null
    }
  }
  /** insert a single row into the table: "table_rows" */
  insert_table_rows_one?: table_rowsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: table_rows_insert_input
      /** upsert condition */
      on_conflict?: table_rows_on_conflict | null
    }
  }
  /** insert data into the table: "trust_network" */
  insert_trust_network?: trust_network_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: trust_network_insert_input[]
      /** upsert condition */
      on_conflict?: trust_network_on_conflict | null
    }
  }
  /** insert data into the table: "trust_network_notification" */
  insert_trust_network_notification?: trust_network_notification_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: trust_network_notification_insert_input[]
      /** upsert condition */
      on_conflict?: trust_network_notification_on_conflict | null
    }
  }
  /** insert a single row into the table: "trust_network_notification" */
  insert_trust_network_notification_one?: trust_network_notificationGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: trust_network_notification_insert_input
      /** upsert condition */
      on_conflict?: trust_network_notification_on_conflict | null
    }
  }
  /** insert a single row into the table: "trust_network" */
  insert_trust_network_one?: trust_networkGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: trust_network_insert_input
      /** upsert condition */
      on_conflict?: trust_network_on_conflict | null
    }
  }
  /** insert data into the table: "trust_network_status" */
  insert_trust_network_status?: trust_network_status_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: trust_network_status_insert_input[]
      /** upsert condition */
      on_conflict?: trust_network_status_on_conflict | null
    }
  }
  /** insert a single row into the table: "trust_network_status" */
  insert_trust_network_status_one?: trust_network_statusGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: trust_network_status_insert_input
      /** upsert condition */
      on_conflict?: trust_network_status_on_conflict | null
    }
  }
  /** insert data into the table: "tx_statistics" */
  insert_tx_statistics?: tx_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: tx_statistics_insert_input[]
    }
  }
  /** insert a single row into the table: "tx_statistics" */
  insert_tx_statistics_one?: tx_statisticsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: tx_statistics_insert_input
    }
  }
  /** insert data into the table: "volume_statistics" */
  insert_volume_statistics?: volume_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: volume_statistics_insert_input[]
    }
  }
  /** insert a single row into the table: "volume_statistics" */
  insert_volume_statistics_one?: volume_statisticsGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: volume_statistics_insert_input
    }
  }
  /** insert data into the table: "web_push" */
  insert_web_push?: web_push_mutation_responseGenqlSelection & {
    __args: {
      /** the rows to be inserted */
      objects: web_push_insert_input[]
      /** upsert condition */
      on_conflict?: web_push_on_conflict | null
    }
  }
  /** insert a single row into the table: "web_push" */
  insert_web_push_one?: web_pushGenqlSelection & {
    __args: {
      /** the row to be inserted */
      object: web_push_insert_input
      /** upsert condition */
      on_conflict?: web_push_on_conflict | null
    }
  }
  make_p2p_buy_offer?: p2p_offer_outputGenqlSelection & {
    __args?: { offer?: make_p2p_buy_offer_input | null }
  }
  make_p2p_sell_offer?: p2p_offer_outputGenqlSelection & {
    __args?: { offer?: make_p2p_sell_offer_input | null }
  }
  make_wallet_deposit_request?: wallet_request_outputGenqlSelection & {
    __args?: { offer?: make_wallet_deposit_request_input | null }
  }
  make_wallet_withdraw_request?: wallet_request_outputGenqlSelection & {
    __args?: { offer?: make_wallet_withdraw_request_input | null }
  }
  reject_new_account?: { __args: { request_id?: Scalars['String'] | null } } | boolean | number
  request_new_account?: request_new_account_outputGenqlSelection & {
    __args?: { account_data?: request_new_account_input | null }
  }
  reserve_offer?: { __args: { offerId: Scalars['String'] } }
  send_push_notification?: boolean | number
  store_ip_address?: store_ip_address_outputGenqlSelection & {
    __args?: { account?: Scalars['String'] | null }
  }
  subscribe_push_notification?: { __args: { subscription: PushSubscriptionInput } }
  toggle_trust_network?: toggle_trust_network_outputGenqlSelection & {
    __args?: { input?: toggle_trust_network_input | null }
  }
  unreserve_offer?: { __args: { offerId: Scalars['String'] } }
  /** update data of the table: "accounts_information" */
  update_accounts_information?: accounts_information_mutation_responseGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: accounts_information_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: accounts_information_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: accounts_information_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: accounts_information_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: accounts_information_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: accounts_information_set_input | null
      /** filter the rows which have to be updated */
      where: accounts_information_bool_exp
    }
  }
  /** update single row of the table: "accounts_information" */
  update_accounts_information_by_pk?: accounts_informationGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: accounts_information_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: accounts_information_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: accounts_information_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: accounts_information_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: accounts_information_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: accounts_information_set_input | null
      pk_columns: accounts_information_pk_columns_input
    }
  }
  /** update multiples rows of table: "accounts_information" */
  update_accounts_information_many?: accounts_information_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: accounts_information_updates[]
    }
  }
  /** update data of the table: "accounts_referral" */
  update_accounts_referral?: accounts_referral_mutation_responseGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: accounts_referral_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: accounts_referral_set_input | null
      /** filter the rows which have to be updated */
      where: accounts_referral_bool_exp
    }
  }
  /** update single row of the table: "accounts_referral" */
  update_accounts_referral_by_pk?: accounts_referralGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: accounts_referral_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: accounts_referral_set_input | null
      pk_columns: accounts_referral_pk_columns_input
    }
  }
  /** update multiples rows of table: "accounts_referral" */
  update_accounts_referral_many?: accounts_referral_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: accounts_referral_updates[]
    }
  }
  /** update data of the table: "actions" */
  update_actions?: actions_mutation_responseGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: actions_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: actions_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: actions_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: actions_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: actions_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: actions_set_input | null
      /** filter the rows which have to be updated */
      where: actions_bool_exp
    }
  }
  /** update single row of the table: "actions" */
  update_actions_by_pk?: actionsGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: actions_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: actions_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: actions_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: actions_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: actions_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: actions_set_input | null
      pk_columns: actions_pk_columns_input
    }
  }
  /** update multiples rows of table: "actions" */
  update_actions_many?: actions_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: actions_updates[]
    }
  }
  /** update data of the table: "devices" */
  update_devices?: devices_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: devices_set_input | null
      /** filter the rows which have to be updated */
      where: devices_bool_exp
    }
  }
  /** update single row of the table: "devices" */
  update_devices_by_pk?: devicesGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: devices_set_input | null
      pk_columns: devices_pk_columns_input
    }
  }
  /** update multiples rows of table: "devices" */
  update_devices_many?: devices_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: devices_updates[]
    }
  }
  /** update data of the table: "login_account" */
  update_login_account?: login_account_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: login_account_set_input | null
      /** filter the rows which have to be updated */
      where: login_account_bool_exp
    }
  }
  /** update single row of the table: "login_account" */
  update_login_account_by_pk?: login_accountGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: login_account_set_input | null
      pk_columns: login_account_pk_columns_input
    }
  }
  /** update multiples rows of table: "login_account" */
  update_login_account_many?: login_account_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: login_account_updates[]
    }
  }
  /** update data of the table: "login_counts" */
  update_login_counts?: login_counts_mutation_responseGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: login_counts_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: login_counts_set_input | null
      /** filter the rows which have to be updated */
      where: login_counts_bool_exp
    }
  }
  /** update multiples rows of table: "login_counts" */
  update_login_counts_many?: login_counts_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: login_counts_updates[]
    }
  }
  /** update data of the table: "messages" */
  update_messages?: messages_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: messages_set_input | null
      /** filter the rows which have to be updated */
      where: messages_bool_exp
    }
  }
  /** update single row of the table: "messages" */
  update_messages_by_pk?: messagesGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: messages_set_input | null
      pk_columns: messages_pk_columns_input
    }
  }
  /** update multiples rows of table: "messages" */
  update_messages_many?: messages_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: messages_updates[]
    }
  }
  /** update data of the table: "migrate_device" */
  update_migrate_device?: migrate_device_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: migrate_device_set_input | null
      /** filter the rows which have to be updated */
      where: migrate_device_bool_exp
    }
  }
  /** update single row of the table: "migrate_device" */
  update_migrate_device_by_pk?: migrate_deviceGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: migrate_device_set_input | null
      pk_columns: migrate_device_pk_columns_input
    }
  }
  /** update multiples rows of table: "migrate_device" */
  update_migrate_device_many?: migrate_device_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: migrate_device_updates[]
    }
  }
  /** update data of the table: "notifications" */
  update_notifications?: notifications_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: notifications_set_input | null
      /** filter the rows which have to be updated */
      where: notifications_bool_exp
    }
  }
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: notificationsGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: notifications_set_input | null
      pk_columns: notifications_pk_columns_input
    }
  }
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: notifications_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: notifications_updates[]
    }
  }
  /** update data of the table: "p2p_offers" */
  update_p2p_offers?: p2p_offers_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: p2p_offers_set_input | null
      /** filter the rows which have to be updated */
      where: p2p_offers_bool_exp
    }
  }
  /** update single row of the table: "p2p_offers" */
  update_p2p_offers_by_pk?: p2p_offersGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: p2p_offers_set_input | null
      pk_columns: p2p_offers_pk_columns_input
    }
  }
  /** update multiples rows of table: "p2p_offers" */
  update_p2p_offers_many?: p2p_offers_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: p2p_offers_updates[]
    }
  }
  /** update data of the table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers?: p2p_pre_sell_offers_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: p2p_pre_sell_offers_set_input | null
      /** filter the rows which have to be updated */
      where: p2p_pre_sell_offers_bool_exp
    }
  }
  /** update single row of the table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers_by_pk?: p2p_pre_sell_offersGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: p2p_pre_sell_offers_set_input | null
      pk_columns: p2p_pre_sell_offers_pk_columns_input
    }
  }
  /** update multiples rows of table: "p2p_pre_sell_offers" */
  update_p2p_pre_sell_offers_many?: p2p_pre_sell_offers_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: p2p_pre_sell_offers_updates[]
    }
  }
  /** update data of the table: "preferences" */
  update_preferences?: preferences_mutation_responseGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: preferences_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: preferences_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: preferences_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: preferences_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: preferences_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: preferences_set_input | null
      /** filter the rows which have to be updated */
      where: preferences_bool_exp
    }
  }
  /** update single row of the table: "preferences" */
  update_preferences_by_pk?: preferencesGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: preferences_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: preferences_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: preferences_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: preferences_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: preferences_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: preferences_set_input | null
      pk_columns: preferences_pk_columns_input
    }
  }
  /** update multiples rows of table: "preferences" */
  update_preferences_many?: preferences_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: preferences_updates[]
    }
  }
  /** update data of the table: "reg_accounts" */
  update_reg_accounts?: reg_accounts_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: reg_accounts_set_input | null
      /** filter the rows which have to be updated */
      where: reg_accounts_bool_exp
    }
  }
  /** update single row of the table: "reg_accounts" */
  update_reg_accounts_by_pk?: reg_accountsGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: reg_accounts_set_input | null
      pk_columns: reg_accounts_pk_columns_input
    }
  }
  /** update multiples rows of table: "reg_accounts" */
  update_reg_accounts_many?: reg_accounts_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: reg_accounts_updates[]
    }
  }
  /** update data of the table: "reputation_score" */
  update_reputation_score?: reputation_score_mutation_responseGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: reputation_score_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: reputation_score_set_input | null
      /** filter the rows which have to be updated */
      where: reputation_score_bool_exp
    }
  }
  /** update single row of the table: "reputation_score" */
  update_reputation_score_by_pk?: reputation_scoreGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: reputation_score_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: reputation_score_set_input | null
      pk_columns: reputation_score_pk_columns_input
    }
  }
  /** update multiples rows of table: "reputation_score" */
  update_reputation_score_many?: reputation_score_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: reputation_score_updates[]
    }
  }
  /** update data of the table: "signing_requests" */
  update_signing_requests?: signing_requests_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: signing_requests_set_input | null
      /** filter the rows which have to be updated */
      where: signing_requests_bool_exp
    }
  }
  /** update single row of the table: "signing_requests" */
  update_signing_requests_by_pk?: signing_requestsGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: signing_requests_set_input | null
      pk_columns: signing_requests_pk_columns_input
    }
  }
  /** update multiples rows of table: "signing_requests" */
  update_signing_requests_many?: signing_requests_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: signing_requests_updates[]
    }
  }
  /** update data of the table: "support_sessions" */
  update_support_sessions?: support_sessions_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: support_sessions_set_input | null
      /** filter the rows which have to be updated */
      where: support_sessions_bool_exp
    }
  }
  /** update single row of the table: "support_sessions" */
  update_support_sessions_by_pk?: support_sessionsGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: support_sessions_set_input | null
      pk_columns: support_sessions_pk_columns_input
    }
  }
  /** update multiples rows of table: "support_sessions" */
  update_support_sessions_many?: support_sessions_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: support_sessions_updates[]
    }
  }
  /** update data of the table: "swap_assets" */
  update_swap_assets?: swap_assets_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: swap_assets_set_input | null
      /** filter the rows which have to be updated */
      where: swap_assets_bool_exp
    }
  }
  /** update single row of the table: "swap_assets" */
  update_swap_assets_by_pk?: swap_assetsGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: swap_assets_set_input | null
      pk_columns: swap_assets_pk_columns_input
    }
  }
  /** update multiples rows of table: "swap_assets" */
  update_swap_assets_many?: swap_assets_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: swap_assets_updates[]
    }
  }
  /** update data of the table: "swap_orders" */
  update_swap_orders?: swap_orders_mutation_responseGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: swap_orders_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: swap_orders_set_input | null
      /** filter the rows which have to be updated */
      where: swap_orders_bool_exp
    }
  }
  /** update single row of the table: "swap_orders" */
  update_swap_orders_by_pk?: swap_ordersGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: swap_orders_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: swap_orders_set_input | null
      pk_columns: swap_orders_pk_columns_input
    }
  }
  /** update multiples rows of table: "swap_orders" */
  update_swap_orders_many?: swap_orders_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: swap_orders_updates[]
    }
  }
  /** update data of the table: "system_notification_read_status" */
  update_system_notification_read_status?: system_notification_read_status_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: system_notification_read_status_set_input | null
      /** filter the rows which have to be updated */
      where: system_notification_read_status_bool_exp
    }
  }
  /** update single row of the table: "system_notification_read_status" */
  update_system_notification_read_status_by_pk?: system_notification_read_statusGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: system_notification_read_status_set_input | null
      pk_columns: system_notification_read_status_pk_columns_input
    }
  }
  /** update multiples rows of table: "system_notification_read_status" */
  update_system_notification_read_status_many?: system_notification_read_status_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: system_notification_read_status_updates[]
    }
  }
  /** update data of the table: "system_notifications" */
  update_system_notifications?: system_notifications_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: system_notifications_set_input | null
      /** filter the rows which have to be updated */
      where: system_notifications_bool_exp
    }
  }
  /** update single row of the table: "system_notifications" */
  update_system_notifications_by_pk?: system_notificationsGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: system_notifications_set_input | null
      pk_columns: system_notifications_pk_columns_input
    }
  }
  /** update multiples rows of table: "system_notifications" */
  update_system_notifications_many?: system_notifications_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: system_notifications_updates[]
    }
  }
  /** update data of the table: "system_type_notification" */
  update_system_type_notification?: system_type_notification_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: system_type_notification_set_input | null
      /** filter the rows which have to be updated */
      where: system_type_notification_bool_exp
    }
  }
  /** update single row of the table: "system_type_notification" */
  update_system_type_notification_by_pk?: system_type_notificationGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: system_type_notification_set_input | null
      pk_columns: system_type_notification_pk_columns_input
    }
  }
  /** update multiples rows of table: "system_type_notification" */
  update_system_type_notification_many?: system_type_notification_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: system_type_notification_updates[]
    }
  }
  /** update data of the table: "table_rows" */
  update_table_rows?: table_rows_mutation_responseGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: table_rows_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: table_rows_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: table_rows_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: table_rows_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: table_rows_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: table_rows_set_input | null
      /** filter the rows which have to be updated */
      where: table_rows_bool_exp
    }
  }
  /** update single row of the table: "table_rows" */
  update_table_rows_by_pk?: table_rowsGenqlSelection & {
    __args: {
      /** append existing jsonb value of filtered columns with new jsonb value */
      _append?: table_rows_append_input | null
      /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
      _delete_at_path?: table_rows_delete_at_path_input | null
      /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
      _delete_elem?: table_rows_delete_elem_input | null
      /** delete key/value pair or string element. key/value pairs are matched based on their key value */
      _delete_key?: table_rows_delete_key_input | null
      /** prepend existing jsonb value of filtered columns with new jsonb value */
      _prepend?: table_rows_prepend_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: table_rows_set_input | null
      pk_columns: table_rows_pk_columns_input
    }
  }
  /** update multiples rows of table: "table_rows" */
  update_table_rows_many?: table_rows_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: table_rows_updates[]
    }
  }
  /** update data of the table: "trust_network" */
  update_trust_network?: trust_network_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: trust_network_set_input | null
      /** filter the rows which have to be updated */
      where: trust_network_bool_exp
    }
  }
  /** update single row of the table: "trust_network" */
  update_trust_network_by_pk?: trust_networkGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: trust_network_set_input | null
      pk_columns: trust_network_pk_columns_input
    }
  }
  /** update multiples rows of table: "trust_network" */
  update_trust_network_many?: trust_network_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: trust_network_updates[]
    }
  }
  /** update data of the table: "trust_network_notification" */
  update_trust_network_notification?: trust_network_notification_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: trust_network_notification_set_input | null
      /** filter the rows which have to be updated */
      where: trust_network_notification_bool_exp
    }
  }
  /** update single row of the table: "trust_network_notification" */
  update_trust_network_notification_by_pk?: trust_network_notificationGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: trust_network_notification_set_input | null
      pk_columns: trust_network_notification_pk_columns_input
    }
  }
  /** update multiples rows of table: "trust_network_notification" */
  update_trust_network_notification_many?: trust_network_notification_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: trust_network_notification_updates[]
    }
  }
  /** update data of the table: "trust_network_status" */
  update_trust_network_status?: trust_network_status_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: trust_network_status_set_input | null
      /** filter the rows which have to be updated */
      where: trust_network_status_bool_exp
    }
  }
  /** update single row of the table: "trust_network_status" */
  update_trust_network_status_by_pk?: trust_network_statusGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: trust_network_status_set_input | null
      pk_columns: trust_network_status_pk_columns_input
    }
  }
  /** update multiples rows of table: "trust_network_status" */
  update_trust_network_status_many?: trust_network_status_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: trust_network_status_updates[]
    }
  }
  /** update data of the table: "tx_statistics" */
  update_tx_statistics?: tx_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: tx_statistics_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: tx_statistics_set_input | null
      /** filter the rows which have to be updated */
      where: tx_statistics_bool_exp
    }
  }
  /** update multiples rows of table: "tx_statistics" */
  update_tx_statistics_many?: tx_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: tx_statistics_updates[]
    }
  }
  /** update data of the table: "volume_statistics" */
  update_volume_statistics?: volume_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** increments the numeric columns with given value of the filtered values */
      _inc?: volume_statistics_inc_input | null
      /** sets the columns of the filtered rows to the given values */
      _set?: volume_statistics_set_input | null
      /** filter the rows which have to be updated */
      where: volume_statistics_bool_exp
    }
  }
  /** update multiples rows of table: "volume_statistics" */
  update_volume_statistics_many?: volume_statistics_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: volume_statistics_updates[]
    }
  }
  /** update data of the table: "web_push" */
  update_web_push?: web_push_mutation_responseGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: web_push_set_input | null
      /** filter the rows which have to be updated */
      where: web_push_bool_exp
    }
  }
  /** update single row of the table: "web_push" */
  update_web_push_by_pk?: web_pushGenqlSelection & {
    __args: {
      /** sets the columns of the filtered rows to the given values */
      _set?: web_push_set_input | null
      pk_columns: web_push_pk_columns_input
    }
  }
  /** update multiples rows of table: "web_push" */
  update_web_push_many?: web_push_mutation_responseGenqlSelection & {
    __args: {
      /** updates to execute, in order */
      updates: web_push_updates[]
    }
  }
  upload_image?: upload_image_outputGenqlSelection & {
    __args: {
      account: Scalars['String']
      image: Scalars['String']
      selfie?: Scalars['Boolean'] | null
    }
  }
  validate_short_link?: validate_short_link_outputGenqlSelection & {
    __args: { validate_short_link_input: validate_short_link_input }
  }
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "notifications" */
export interface notificationsGenqlSelection {
  content_id?: boolean | number
  created_at?: boolean | number
  from?: boolean | number
  id?: boolean | number
  /** An object relationship */
  notifications_reg_account?: reg_accountsGenqlSelection
  read?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  to?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "notifications" */
export interface notifications_aggregateGenqlSelection {
  aggregate?: notifications_aggregate_fieldsGenqlSelection
  nodes?: notificationsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface notifications_aggregate_bool_exp {
  bool_and?: notifications_aggregate_bool_exp_bool_and | null
  bool_or?: notifications_aggregate_bool_exp_bool_or | null
  count?: notifications_aggregate_bool_exp_count | null
}

export interface notifications_aggregate_bool_exp_bool_and {
  arguments: notifications_select_column_notifications_aggregate_bool_exp_bool_and_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: notifications_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface notifications_aggregate_bool_exp_bool_or {
  arguments: notifications_select_column_notifications_aggregate_bool_exp_bool_or_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: notifications_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface notifications_aggregate_bool_exp_count {
  arguments?: notifications_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: notifications_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "notifications" */
export interface notifications_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: notifications_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: notifications_max_fieldsGenqlSelection
  min?: notifications_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "notifications" */
export interface notifications_aggregate_order_by {
  count?: order_by | null
  max?: notifications_max_order_by | null
  min?: notifications_min_order_by | null
}

/** input type for inserting array relation for remote table "notifications" */
export interface notifications_arr_rel_insert_input {
  data: notifications_insert_input[]
  /** upsert condition */
  on_conflict?: notifications_on_conflict | null
}

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export interface notifications_bool_exp {
  _and?: notifications_bool_exp[] | null
  _not?: notifications_bool_exp | null
  _or?: notifications_bool_exp[] | null
  content_id?: uuid_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  from?: String_comparison_exp | null
  id?: uuid_comparison_exp | null
  notifications_reg_account?: reg_accounts_bool_exp | null
  read?: Boolean_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  to?: String_comparison_exp | null
  type?: String_comparison_exp | null
}

/** input type for inserting data into table "notifications" */
export interface notifications_insert_input {
  content_id?: Scalars['uuid'] | null
  created_at?: Scalars['timestamptz'] | null
  from?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  notifications_reg_account?: reg_accounts_obj_rel_insert_input | null
  read?: Scalars['Boolean'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  to?: Scalars['String'] | null
  type?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface notifications_max_fieldsGenqlSelection {
  content_id?: boolean | number
  created_at?: boolean | number
  from?: boolean | number
  id?: boolean | number
  to?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "notifications" */
export interface notifications_max_order_by {
  content_id?: order_by | null
  created_at?: order_by | null
  from?: order_by | null
  id?: order_by | null
  to?: order_by | null
  type?: order_by | null
}

/** aggregate min on columns */
export interface notifications_min_fieldsGenqlSelection {
  content_id?: boolean | number
  created_at?: boolean | number
  from?: boolean | number
  id?: boolean | number
  to?: boolean | number
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "notifications" */
export interface notifications_min_order_by {
  content_id?: order_by | null
  created_at?: order_by | null
  from?: order_by | null
  id?: order_by | null
  to?: order_by | null
  type?: order_by | null
}

/** response of any mutation on the table "notifications" */
export interface notifications_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: notificationsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "notifications" */
export interface notifications_on_conflict {
  constraint: notifications_constraint
  update_columns?: notifications_update_column[]
  where?: notifications_bool_exp | null
}

/** Ordering options when selecting data from "notifications". */
export interface notifications_order_by {
  content_id?: order_by | null
  created_at?: order_by | null
  from?: order_by | null
  id?: order_by | null
  notifications_reg_account?: reg_accounts_order_by | null
  read?: order_by | null
  reg_account?: reg_accounts_order_by | null
  to?: order_by | null
  type?: order_by | null
}

/** primary key columns input for table: notifications */
export interface notifications_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "notifications" */
export interface notifications_set_input {
  content_id?: Scalars['uuid'] | null
  created_at?: Scalars['timestamptz'] | null
  from?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  read?: Scalars['Boolean'] | null
  to?: Scalars['String'] | null
  type?: Scalars['String'] | null
}

/** Streaming cursor of the table "notifications" */
export interface notifications_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: notifications_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface notifications_stream_cursor_value_input {
  content_id?: Scalars['uuid'] | null
  created_at?: Scalars['timestamptz'] | null
  from?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  read?: Scalars['Boolean'] | null
  to?: Scalars['String'] | null
  type?: Scalars['String'] | null
}

export interface notifications_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: notifications_set_input | null
  /** filter the rows which have to be updated */
  where: notifications_bool_exp
}

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export interface numeric_comparison_exp {
  _eq?: Scalars['numeric'] | null
  _gt?: Scalars['numeric'] | null
  _gte?: Scalars['numeric'] | null
  _in?: Scalars['numeric'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['numeric'] | null
  _lte?: Scalars['numeric'] | null
  _neq?: Scalars['numeric'] | null
  _nin?: Scalars['numeric'][] | null
}

export interface p2p_offer_outputGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  buyer?: boolean | number
  buyer_confirmed_payment?: boolean | number
  buyer_method_details?: boolean | number
  cancel_approval_datetime?: boolean | number
  cancel_request_datetime?: boolean | number
  cancellation_approved_by?: boolean | number
  cancellation_transaction?: boolean | number
  cancelled?: boolean | number
  cancelled_by?: boolean | number
  completed?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  initiator?: boolean | number
  matched?: boolean | number
  matched_datetime?: boolean | number
  method?: boolean | number
  region?: boolean | number
  sell_put_transaction?: boolean | number
  sell_settlement_transaction?: boolean | number
  seller?: boolean | number
  seller_confirmed_payment?: boolean | number
  seller_method_details?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "p2p_offers" */
export interface p2p_offersGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  buyer?: boolean | number
  buyer_confirmed_datetime?: boolean | number
  buyer_confirmed_payment?: boolean | number
  buyer_method_details?: boolean | number
  cancel_approval_datetime?: boolean | number
  cancel_request_datetime?: boolean | number
  cancellation_approved_by?: boolean | number
  cancellation_transaction?: boolean | number
  cancelled?: boolean | number
  cancelled_by?: boolean | number
  completed?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  initiator?: boolean | number
  matched?: boolean | number
  matched_datetime?: boolean | number
  /** An array relationship */
  messages?: messagesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An aggregate relationship */
  messages_aggregate?: messages_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  method?: boolean | number
  original_offer_id?: boolean | number
  /** An object relationship */
  regAccountByBuyer?: reg_accountsGenqlSelection
  /** An object relationship */
  regAccountByCancellationApprovedBy?: reg_accountsGenqlSelection
  /** An object relationship */
  regAccountByCancelledBy?: reg_accountsGenqlSelection
  /** An object relationship */
  regAccountByInitiator?: reg_accountsGenqlSelection
  /** An object relationship */
  regAccountBySeller?: reg_accountsGenqlSelection
  region?: boolean | number
  reserved_by?: boolean | number
  sell_put_transaction?: boolean | number
  sell_settlement_transaction?: boolean | number
  seller?: boolean | number
  seller_confirmed_datetime?: boolean | number
  seller_confirmed_payment?: boolean | number
  seller_method_details?: boolean | number
  /** An array relationship */
  system_notifications?: system_notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  system_notifications_aggregate?: system_notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "p2p_offers" */
export interface p2p_offers_aggregateGenqlSelection {
  aggregate?: p2p_offers_aggregate_fieldsGenqlSelection
  nodes?: p2p_offersGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface p2p_offers_aggregate_bool_exp {
  bool_and?: p2p_offers_aggregate_bool_exp_bool_and | null
  bool_or?: p2p_offers_aggregate_bool_exp_bool_or | null
  count?: p2p_offers_aggregate_bool_exp_count | null
}

export interface p2p_offers_aggregate_bool_exp_bool_and {
  arguments: p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_and_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: p2p_offers_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface p2p_offers_aggregate_bool_exp_bool_or {
  arguments: p2p_offers_select_column_p2p_offers_aggregate_bool_exp_bool_or_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: p2p_offers_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface p2p_offers_aggregate_bool_exp_count {
  arguments?: p2p_offers_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: p2p_offers_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "p2p_offers" */
export interface p2p_offers_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: p2p_offers_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: p2p_offers_max_fieldsGenqlSelection
  min?: p2p_offers_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "p2p_offers" */
export interface p2p_offers_aggregate_order_by {
  count?: order_by | null
  max?: p2p_offers_max_order_by | null
  min?: p2p_offers_min_order_by | null
}

/** input type for inserting array relation for remote table "p2p_offers" */
export interface p2p_offers_arr_rel_insert_input {
  data: p2p_offers_insert_input[]
  /** upsert condition */
  on_conflict?: p2p_offers_on_conflict | null
}

/** Boolean expression to filter rows from the table "p2p_offers". All fields are combined with a logical 'AND'. */
export interface p2p_offers_bool_exp {
  _and?: p2p_offers_bool_exp[] | null
  _not?: p2p_offers_bool_exp | null
  _or?: p2p_offers_bool_exp[] | null
  amount?: String_comparison_exp | null
  bitcashbank_id?: String_comparison_exp | null
  buyer?: String_comparison_exp | null
  buyer_confirmed_datetime?: timestamptz_comparison_exp | null
  buyer_confirmed_payment?: Boolean_comparison_exp | null
  buyer_method_details?: String_comparison_exp | null
  cancel_approval_datetime?: timestamptz_comparison_exp | null
  cancel_request_datetime?: timestamptz_comparison_exp | null
  cancellation_approved_by?: String_comparison_exp | null
  cancellation_transaction?: String_comparison_exp | null
  cancelled?: Boolean_comparison_exp | null
  cancelled_by?: String_comparison_exp | null
  completed?: Boolean_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: uuid_comparison_exp | null
  initiator?: String_comparison_exp | null
  matched?: Boolean_comparison_exp | null
  matched_datetime?: timestamptz_comparison_exp | null
  messages?: messages_bool_exp | null
  messages_aggregate?: messages_aggregate_bool_exp | null
  method?: String_comparison_exp | null
  original_offer_id?: uuid_comparison_exp | null
  regAccountByBuyer?: reg_accounts_bool_exp | null
  regAccountByCancellationApprovedBy?: reg_accounts_bool_exp | null
  regAccountByCancelledBy?: reg_accounts_bool_exp | null
  regAccountByInitiator?: reg_accounts_bool_exp | null
  regAccountBySeller?: reg_accounts_bool_exp | null
  region?: String_comparison_exp | null
  reserved_by?: String_comparison_exp | null
  sell_put_transaction?: String_comparison_exp | null
  sell_settlement_transaction?: String_comparison_exp | null
  seller?: String_comparison_exp | null
  seller_confirmed_datetime?: timestamptz_comparison_exp | null
  seller_confirmed_payment?: Boolean_comparison_exp | null
  seller_method_details?: String_comparison_exp | null
  system_notifications?: system_notifications_bool_exp | null
  system_notifications_aggregate?: system_notifications_aggregate_bool_exp | null
  type?: String_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** input type for inserting data into table "p2p_offers" */
export interface p2p_offers_insert_input {
  amount?: Scalars['String'] | null
  bitcashbank_id?: Scalars['String'] | null
  buyer?: Scalars['String'] | null
  buyer_confirmed_datetime?: Scalars['timestamptz'] | null
  buyer_confirmed_payment?: Scalars['Boolean'] | null
  buyer_method_details?: Scalars['String'] | null
  cancel_approval_datetime?: Scalars['timestamptz'] | null
  cancel_request_datetime?: Scalars['timestamptz'] | null
  cancellation_approved_by?: Scalars['String'] | null
  cancellation_transaction?: Scalars['String'] | null
  cancelled?: Scalars['Boolean'] | null
  cancelled_by?: Scalars['String'] | null
  completed?: Scalars['Boolean'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  initiator?: Scalars['String'] | null
  matched?: Scalars['Boolean'] | null
  matched_datetime?: Scalars['timestamptz'] | null
  messages?: messages_arr_rel_insert_input | null
  method?: Scalars['String'] | null
  original_offer_id?: Scalars['uuid'] | null
  regAccountByBuyer?: reg_accounts_obj_rel_insert_input | null
  regAccountByCancellationApprovedBy?: reg_accounts_obj_rel_insert_input | null
  regAccountByCancelledBy?: reg_accounts_obj_rel_insert_input | null
  regAccountByInitiator?: reg_accounts_obj_rel_insert_input | null
  regAccountBySeller?: reg_accounts_obj_rel_insert_input | null
  region?: Scalars['String'] | null
  reserved_by?: Scalars['String'] | null
  sell_put_transaction?: Scalars['String'] | null
  sell_settlement_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
  seller_confirmed_datetime?: Scalars['timestamptz'] | null
  seller_confirmed_payment?: Scalars['Boolean'] | null
  seller_method_details?: Scalars['String'] | null
  system_notifications?: system_notifications_arr_rel_insert_input | null
  type?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** aggregate max on columns */
export interface p2p_offers_max_fieldsGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  buyer?: boolean | number
  buyer_confirmed_datetime?: boolean | number
  buyer_method_details?: boolean | number
  cancel_approval_datetime?: boolean | number
  cancel_request_datetime?: boolean | number
  cancellation_approved_by?: boolean | number
  cancellation_transaction?: boolean | number
  cancelled_by?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  initiator?: boolean | number
  matched_datetime?: boolean | number
  method?: boolean | number
  original_offer_id?: boolean | number
  region?: boolean | number
  reserved_by?: boolean | number
  sell_put_transaction?: boolean | number
  sell_settlement_transaction?: boolean | number
  seller?: boolean | number
  seller_confirmed_datetime?: boolean | number
  seller_method_details?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "p2p_offers" */
export interface p2p_offers_max_order_by {
  amount?: order_by | null
  bitcashbank_id?: order_by | null
  buyer?: order_by | null
  buyer_confirmed_datetime?: order_by | null
  buyer_method_details?: order_by | null
  cancel_approval_datetime?: order_by | null
  cancel_request_datetime?: order_by | null
  cancellation_approved_by?: order_by | null
  cancellation_transaction?: order_by | null
  cancelled_by?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  initiator?: order_by | null
  matched_datetime?: order_by | null
  method?: order_by | null
  original_offer_id?: order_by | null
  region?: order_by | null
  reserved_by?: order_by | null
  sell_put_transaction?: order_by | null
  sell_settlement_transaction?: order_by | null
  seller?: order_by | null
  seller_confirmed_datetime?: order_by | null
  seller_method_details?: order_by | null
  type?: order_by | null
  updated_at?: order_by | null
}

/** aggregate min on columns */
export interface p2p_offers_min_fieldsGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  buyer?: boolean | number
  buyer_confirmed_datetime?: boolean | number
  buyer_method_details?: boolean | number
  cancel_approval_datetime?: boolean | number
  cancel_request_datetime?: boolean | number
  cancellation_approved_by?: boolean | number
  cancellation_transaction?: boolean | number
  cancelled_by?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  initiator?: boolean | number
  matched_datetime?: boolean | number
  method?: boolean | number
  original_offer_id?: boolean | number
  region?: boolean | number
  reserved_by?: boolean | number
  sell_put_transaction?: boolean | number
  sell_settlement_transaction?: boolean | number
  seller?: boolean | number
  seller_confirmed_datetime?: boolean | number
  seller_method_details?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "p2p_offers" */
export interface p2p_offers_min_order_by {
  amount?: order_by | null
  bitcashbank_id?: order_by | null
  buyer?: order_by | null
  buyer_confirmed_datetime?: order_by | null
  buyer_method_details?: order_by | null
  cancel_approval_datetime?: order_by | null
  cancel_request_datetime?: order_by | null
  cancellation_approved_by?: order_by | null
  cancellation_transaction?: order_by | null
  cancelled_by?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  initiator?: order_by | null
  matched_datetime?: order_by | null
  method?: order_by | null
  original_offer_id?: order_by | null
  region?: order_by | null
  reserved_by?: order_by | null
  sell_put_transaction?: order_by | null
  sell_settlement_transaction?: order_by | null
  seller?: order_by | null
  seller_confirmed_datetime?: order_by | null
  seller_method_details?: order_by | null
  type?: order_by | null
  updated_at?: order_by | null
}

/** response of any mutation on the table "p2p_offers" */
export interface p2p_offers_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: p2p_offersGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "p2p_offers" */
export interface p2p_offers_obj_rel_insert_input {
  data: p2p_offers_insert_input
  /** upsert condition */
  on_conflict?: p2p_offers_on_conflict | null
}

/** on_conflict condition type for table "p2p_offers" */
export interface p2p_offers_on_conflict {
  constraint: p2p_offers_constraint
  update_columns?: p2p_offers_update_column[]
  where?: p2p_offers_bool_exp | null
}

/** Ordering options when selecting data from "p2p_offers". */
export interface p2p_offers_order_by {
  amount?: order_by | null
  bitcashbank_id?: order_by | null
  buyer?: order_by | null
  buyer_confirmed_datetime?: order_by | null
  buyer_confirmed_payment?: order_by | null
  buyer_method_details?: order_by | null
  cancel_approval_datetime?: order_by | null
  cancel_request_datetime?: order_by | null
  cancellation_approved_by?: order_by | null
  cancellation_transaction?: order_by | null
  cancelled?: order_by | null
  cancelled_by?: order_by | null
  completed?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  initiator?: order_by | null
  matched?: order_by | null
  matched_datetime?: order_by | null
  messages_aggregate?: messages_aggregate_order_by | null
  method?: order_by | null
  original_offer_id?: order_by | null
  regAccountByBuyer?: reg_accounts_order_by | null
  regAccountByCancellationApprovedBy?: reg_accounts_order_by | null
  regAccountByCancelledBy?: reg_accounts_order_by | null
  regAccountByInitiator?: reg_accounts_order_by | null
  regAccountBySeller?: reg_accounts_order_by | null
  region?: order_by | null
  reserved_by?: order_by | null
  sell_put_transaction?: order_by | null
  sell_settlement_transaction?: order_by | null
  seller?: order_by | null
  seller_confirmed_datetime?: order_by | null
  seller_confirmed_payment?: order_by | null
  seller_method_details?: order_by | null
  system_notifications_aggregate?: system_notifications_aggregate_order_by | null
  type?: order_by | null
  updated_at?: order_by | null
}

/** primary key columns input for table: p2p_offers */
export interface p2p_offers_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "p2p_offers" */
export interface p2p_offers_set_input {
  amount?: Scalars['String'] | null
  bitcashbank_id?: Scalars['String'] | null
  buyer?: Scalars['String'] | null
  buyer_confirmed_datetime?: Scalars['timestamptz'] | null
  buyer_confirmed_payment?: Scalars['Boolean'] | null
  buyer_method_details?: Scalars['String'] | null
  cancel_approval_datetime?: Scalars['timestamptz'] | null
  cancel_request_datetime?: Scalars['timestamptz'] | null
  cancellation_approved_by?: Scalars['String'] | null
  cancellation_transaction?: Scalars['String'] | null
  cancelled?: Scalars['Boolean'] | null
  cancelled_by?: Scalars['String'] | null
  completed?: Scalars['Boolean'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  initiator?: Scalars['String'] | null
  matched?: Scalars['Boolean'] | null
  matched_datetime?: Scalars['timestamptz'] | null
  method?: Scalars['String'] | null
  original_offer_id?: Scalars['uuid'] | null
  region?: Scalars['String'] | null
  reserved_by?: Scalars['String'] | null
  sell_put_transaction?: Scalars['String'] | null
  sell_settlement_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
  seller_confirmed_datetime?: Scalars['timestamptz'] | null
  seller_confirmed_payment?: Scalars['Boolean'] | null
  seller_method_details?: Scalars['String'] | null
  type?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** Streaming cursor of the table "p2p_offers" */
export interface p2p_offers_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: p2p_offers_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface p2p_offers_stream_cursor_value_input {
  amount?: Scalars['String'] | null
  bitcashbank_id?: Scalars['String'] | null
  buyer?: Scalars['String'] | null
  buyer_confirmed_datetime?: Scalars['timestamptz'] | null
  buyer_confirmed_payment?: Scalars['Boolean'] | null
  buyer_method_details?: Scalars['String'] | null
  cancel_approval_datetime?: Scalars['timestamptz'] | null
  cancel_request_datetime?: Scalars['timestamptz'] | null
  cancellation_approved_by?: Scalars['String'] | null
  cancellation_transaction?: Scalars['String'] | null
  cancelled?: Scalars['Boolean'] | null
  cancelled_by?: Scalars['String'] | null
  completed?: Scalars['Boolean'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  initiator?: Scalars['String'] | null
  matched?: Scalars['Boolean'] | null
  matched_datetime?: Scalars['timestamptz'] | null
  method?: Scalars['String'] | null
  original_offer_id?: Scalars['uuid'] | null
  region?: Scalars['String'] | null
  reserved_by?: Scalars['String'] | null
  sell_put_transaction?: Scalars['String'] | null
  sell_settlement_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
  seller_confirmed_datetime?: Scalars['timestamptz'] | null
  seller_confirmed_payment?: Scalars['Boolean'] | null
  seller_method_details?: Scalars['String'] | null
  type?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
}

export interface p2p_offers_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: p2p_offers_set_input | null
  /** filter the rows which have to be updated */
  where: p2p_offers_bool_exp
}

/** columns and relationships of "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offersGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  method?: boolean | number
  processed?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  region?: boolean | number
  sell_put_transaction?: boolean | number
  seller?: boolean | number
  seller_method_details?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_aggregateGenqlSelection {
  aggregate?: p2p_pre_sell_offers_aggregate_fieldsGenqlSelection
  nodes?: p2p_pre_sell_offersGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface p2p_pre_sell_offers_aggregate_bool_exp {
  bool_and?: p2p_pre_sell_offers_aggregate_bool_exp_bool_and | null
  bool_or?: p2p_pre_sell_offers_aggregate_bool_exp_bool_or | null
  count?: p2p_pre_sell_offers_aggregate_bool_exp_count | null
}

export interface p2p_pre_sell_offers_aggregate_bool_exp_bool_and {
  arguments: p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_and_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: p2p_pre_sell_offers_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface p2p_pre_sell_offers_aggregate_bool_exp_bool_or {
  arguments: p2p_pre_sell_offers_select_column_p2p_pre_sell_offers_aggregate_bool_exp_bool_or_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: p2p_pre_sell_offers_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface p2p_pre_sell_offers_aggregate_bool_exp_count {
  arguments?: p2p_pre_sell_offers_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: p2p_pre_sell_offers_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: p2p_pre_sell_offers_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: p2p_pre_sell_offers_max_fieldsGenqlSelection
  min?: p2p_pre_sell_offers_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_aggregate_order_by {
  count?: order_by | null
  max?: p2p_pre_sell_offers_max_order_by | null
  min?: p2p_pre_sell_offers_min_order_by | null
}

/** input type for inserting array relation for remote table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_arr_rel_insert_input {
  data: p2p_pre_sell_offers_insert_input[]
  /** upsert condition */
  on_conflict?: p2p_pre_sell_offers_on_conflict | null
}

/** Boolean expression to filter rows from the table "p2p_pre_sell_offers". All fields are combined with a logical 'AND'. */
export interface p2p_pre_sell_offers_bool_exp {
  _and?: p2p_pre_sell_offers_bool_exp[] | null
  _not?: p2p_pre_sell_offers_bool_exp | null
  _or?: p2p_pre_sell_offers_bool_exp[] | null
  amount?: String_comparison_exp | null
  bitcashbank_id?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: uuid_comparison_exp | null
  method?: String_comparison_exp | null
  processed?: Boolean_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  region?: String_comparison_exp | null
  sell_put_transaction?: String_comparison_exp | null
  seller?: String_comparison_exp | null
  seller_method_details?: String_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** input type for inserting data into table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_insert_input {
  amount?: Scalars['String'] | null
  bitcashbank_id?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  method?: Scalars['String'] | null
  processed?: Scalars['Boolean'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  region?: Scalars['String'] | null
  sell_put_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
  seller_method_details?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** aggregate max on columns */
export interface p2p_pre_sell_offers_max_fieldsGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  method?: boolean | number
  region?: boolean | number
  sell_put_transaction?: boolean | number
  seller?: boolean | number
  seller_method_details?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_max_order_by {
  amount?: order_by | null
  bitcashbank_id?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  method?: order_by | null
  region?: order_by | null
  sell_put_transaction?: order_by | null
  seller?: order_by | null
  seller_method_details?: order_by | null
  updated_at?: order_by | null
}

/** aggregate min on columns */
export interface p2p_pre_sell_offers_min_fieldsGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  method?: boolean | number
  region?: boolean | number
  sell_put_transaction?: boolean | number
  seller?: boolean | number
  seller_method_details?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_min_order_by {
  amount?: order_by | null
  bitcashbank_id?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  method?: order_by | null
  region?: order_by | null
  sell_put_transaction?: order_by | null
  seller?: order_by | null
  seller_method_details?: order_by | null
  updated_at?: order_by | null
}

/** response of any mutation on the table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: p2p_pre_sell_offersGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_on_conflict {
  constraint: p2p_pre_sell_offers_constraint
  update_columns?: p2p_pre_sell_offers_update_column[]
  where?: p2p_pre_sell_offers_bool_exp | null
}

/** Ordering options when selecting data from "p2p_pre_sell_offers". */
export interface p2p_pre_sell_offers_order_by {
  amount?: order_by | null
  bitcashbank_id?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  method?: order_by | null
  processed?: order_by | null
  reg_account?: reg_accounts_order_by | null
  region?: order_by | null
  sell_put_transaction?: order_by | null
  seller?: order_by | null
  seller_method_details?: order_by | null
  updated_at?: order_by | null
}

/** primary key columns input for table: p2p_pre_sell_offers */
export interface p2p_pre_sell_offers_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_set_input {
  amount?: Scalars['String'] | null
  bitcashbank_id?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  method?: Scalars['String'] | null
  processed?: Scalars['Boolean'] | null
  region?: Scalars['String'] | null
  sell_put_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
  seller_method_details?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** Streaming cursor of the table "p2p_pre_sell_offers" */
export interface p2p_pre_sell_offers_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: p2p_pre_sell_offers_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface p2p_pre_sell_offers_stream_cursor_value_input {
  amount?: Scalars['String'] | null
  bitcashbank_id?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  method?: Scalars['String'] | null
  processed?: Scalars['Boolean'] | null
  region?: Scalars['String'] | null
  sell_put_transaction?: Scalars['String'] | null
  seller?: Scalars['String'] | null
  seller_method_details?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
}

export interface p2p_pre_sell_offers_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: p2p_pre_sell_offers_set_input | null
  /** filter the rows which have to be updated */
  where: p2p_pre_sell_offers_bool_exp
}

export interface photo_id_verification_outputGenqlSelection {
  error?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "preferences" */
export interface preferencesGenqlSelection {
  account?: boolean | number
  currency?: boolean | number
  language?: boolean | number
  notifications?: boolean | number
  personalized?: boolean | number
  region?: boolean | number
  secondary_currency?:
    | {
        __args: {
          /** JSON select path */
          path?: Scalars['String'] | null
        }
      }
    | boolean
    | number
  theme?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "preferences" */
export interface preferences_aggregateGenqlSelection {
  aggregate?: preferences_aggregate_fieldsGenqlSelection
  nodes?: preferencesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "preferences" */
export interface preferences_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: preferences_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: preferences_max_fieldsGenqlSelection
  min?: preferences_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface preferences_append_input {
  secondary_currency?: Scalars['jsonb'] | null
}

/** Boolean expression to filter rows from the table "preferences". All fields are combined with a logical 'AND'. */
export interface preferences_bool_exp {
  _and?: preferences_bool_exp[] | null
  _not?: preferences_bool_exp | null
  _or?: preferences_bool_exp[] | null
  account?: String_comparison_exp | null
  currency?: String_comparison_exp | null
  language?: String_comparison_exp | null
  notifications?: Boolean_comparison_exp | null
  personalized?: Boolean_comparison_exp | null
  region?: String_comparison_exp | null
  secondary_currency?: jsonb_comparison_exp | null
  theme?: String_comparison_exp | null
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface preferences_delete_at_path_input {
  secondary_currency?: Scalars['String'][] | null
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface preferences_delete_elem_input {
  secondary_currency?: Scalars['Int'] | null
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface preferences_delete_key_input {
  secondary_currency?: Scalars['String'] | null
}

/** input type for inserting data into table "preferences" */
export interface preferences_insert_input {
  account?: Scalars['String'] | null
  currency?: Scalars['String'] | null
  language?: Scalars['String'] | null
  notifications?: Scalars['Boolean'] | null
  personalized?: Scalars['Boolean'] | null
  region?: Scalars['String'] | null
  secondary_currency?: Scalars['jsonb'] | null
  theme?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface preferences_max_fieldsGenqlSelection {
  account?: boolean | number
  currency?: boolean | number
  language?: boolean | number
  region?: boolean | number
  theme?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface preferences_min_fieldsGenqlSelection {
  account?: boolean | number
  currency?: boolean | number
  language?: boolean | number
  region?: boolean | number
  theme?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "preferences" */
export interface preferences_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: preferencesGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "preferences" */
export interface preferences_on_conflict {
  constraint: preferences_constraint
  update_columns?: preferences_update_column[]
  where?: preferences_bool_exp | null
}

/** Ordering options when selecting data from "preferences". */
export interface preferences_order_by {
  account?: order_by | null
  currency?: order_by | null
  language?: order_by | null
  notifications?: order_by | null
  personalized?: order_by | null
  region?: order_by | null
  secondary_currency?: order_by | null
  theme?: order_by | null
}

/** primary key columns input for table: preferences */
export interface preferences_pk_columns_input {
  account: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface preferences_prepend_input {
  secondary_currency?: Scalars['jsonb'] | null
}

/** input type for updating data in table "preferences" */
export interface preferences_set_input {
  account?: Scalars['String'] | null
  currency?: Scalars['String'] | null
  language?: Scalars['String'] | null
  notifications?: Scalars['Boolean'] | null
  personalized?: Scalars['Boolean'] | null
  region?: Scalars['String'] | null
  secondary_currency?: Scalars['jsonb'] | null
  theme?: Scalars['String'] | null
}

/** Streaming cursor of the table "preferences" */
export interface preferences_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: preferences_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface preferences_stream_cursor_value_input {
  account?: Scalars['String'] | null
  currency?: Scalars['String'] | null
  language?: Scalars['String'] | null
  notifications?: Scalars['Boolean'] | null
  personalized?: Scalars['Boolean'] | null
  region?: Scalars['String'] | null
  secondary_currency?: Scalars['jsonb'] | null
  theme?: Scalars['String'] | null
}

export interface preferences_updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: preferences_append_input | null
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: preferences_delete_at_path_input | null
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: preferences_delete_elem_input | null
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: preferences_delete_key_input | null
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: preferences_prepend_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: preferences_set_input | null
  /** filter the rows which have to be updated */
  where: preferences_bool_exp
}

export interface query_rootGenqlSelection {
  /** fetch data from the table: "account_statistics" */
  account_statistics?: account_statisticsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: account_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: account_statistics_order_by[] | null
      /** filter the rows returned */
      where?: account_statistics_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "account_statistics" */
  account_statistics_aggregate?: account_statistics_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: account_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: account_statistics_order_by[] | null
      /** filter the rows returned */
      where?: account_statistics_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_information" */
  accounts_information?: accounts_informationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_information_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_information_order_by[] | null
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "accounts_information" */
  accounts_information_aggregate?: accounts_information_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_information_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_information_order_by[] | null
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_information" using primary key columns */
  accounts_information_by_pk?: accounts_informationGenqlSelection & {
    __args: { account: Scalars['String']; id: Scalars['uuid'] }
  }
  /** fetch data from the table: "accounts_referral" */
  accounts_referral?: accounts_referralGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_referral_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_referral_order_by[] | null
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "accounts_referral" */
  accounts_referral_aggregate?: accounts_referral_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_referral_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_referral_order_by[] | null
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_referral" using primary key columns */
  accounts_referral_by_pk?: accounts_referralGenqlSelection & {
    __args: { link: Scalars['String'] }
  }
  /** fetch data from the table: "actions" */
  actions?: actionsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: actions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: actions_order_by[] | null
      /** filter the rows returned */
      where?: actions_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate?: actions_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: actions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: actions_order_by[] | null
      /** filter the rows returned */
      where?: actions_bool_exp | null
    }
  }
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: actionsGenqlSelection & { __args: { global_sequence: Scalars['String'] } }
  apollo_auth_health_check?: boolean | number
  apollo_health_check?: boolean | number
  /** fetch data from the table: "avg_pay_time" */
  avg_pay_time?: avg_pay_timeGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_pay_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_pay_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_pay_time_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "avg_pay_time" */
  avg_pay_time_aggregate?: avg_pay_time_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_pay_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_pay_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_pay_time_bool_exp | null
    }
  }
  /** fetch data from the table: "avg_release_time" */
  avg_release_time?: avg_release_timeGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_release_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_release_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_release_time_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "avg_release_time" */
  avg_release_time_aggregate?: avg_release_time_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_release_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_release_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_release_time_bool_exp | null
    }
  }
  /** fetch data from the table: "combined_avg_times" */
  combined_avg_times?: combined_avg_timesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: combined_avg_times_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: combined_avg_times_order_by[] | null
      /** filter the rows returned */
      where?: combined_avg_times_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "combined_avg_times" */
  combined_avg_times_aggregate?: combined_avg_times_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: combined_avg_times_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: combined_avg_times_order_by[] | null
      /** filter the rows returned */
      where?: combined_avg_times_bool_exp | null
    }
  }
  /** An array relationship */
  devices?: devicesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: devices_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: devices_order_by[] | null
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  /** An aggregate relationship */
  devices_aggregate?: devices_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: devices_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: devices_order_by[] | null
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: devicesGenqlSelection & { __args: { cred_id: Scalars['String'] } }
  /** execute function "get_inactive_accounts" which returns "reg_accounts" */
  get_inactive_accounts?: reg_accountsGenqlSelection & {
    __args: {
      /** input parameters for function "get_inactive_accounts" */
      args: get_inactive_accounts_args
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** execute function "get_inactive_accounts" and query aggregates on result of table type "reg_accounts" */
  get_inactive_accounts_aggregate?: reg_accounts_aggregateGenqlSelection & {
    __args: {
      /** input parameters for function "get_inactive_accounts_aggregate" */
      args: get_inactive_accounts_args
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** execute function "get_login_counts" which returns "login_counts" */
  get_login_counts?: login_countsGenqlSelection & {
    __args: {
      /** input parameters for function "get_login_counts" */
      args: get_login_counts_args
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** execute function "get_login_counts" and query aggregates on result of table type "login_counts" */
  get_login_counts_aggregate?: login_counts_aggregateGenqlSelection & {
    __args: {
      /** input parameters for function "get_login_counts_aggregate" */
      args: get_login_counts_args
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** execute function "get_p2p_offers_with_old_or_no_messages" which returns "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages?: p2p_offersGenqlSelection & {
    __args: {
      /** input parameters for function "get_p2p_offers_with_old_or_no_messages" */
      args: get_p2p_offers_with_old_or_no_messages_args
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** execute function "get_p2p_offers_with_old_or_no_messages" and query aggregates on result of table type "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args: {
      /** input parameters for function "get_p2p_offers_with_old_or_no_messages_aggregate" */
      args: get_p2p_offers_with_old_or_no_messages_args
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  get_trust_network?: trust_network_outputGenqlSelection & {
    __args?: { account?: Scalars['String'] | null }
  }
  id_verification?: id_verification_outputGenqlSelection & {
    __args?: { id_data?: id_verification_input | null }
  }
  /** fetch data from the table: "login_account" */
  login_account?: login_accountGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_account_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_account_order_by[] | null
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "login_account" */
  login_account_aggregate?: login_account_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_account_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_account_order_by[] | null
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** fetch data from the table: "login_account" using primary key columns */
  login_account_by_pk?: login_accountGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table: "login_counts" */
  login_counts?: login_countsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "login_counts" */
  login_counts_aggregate?: login_counts_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** An array relationship */
  messages?: messagesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An aggregate relationship */
  messages_aggregate?: messages_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: messagesGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table: "migrate_device" */
  migrate_device?: migrate_deviceGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: migrate_device_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: migrate_device_order_by[] | null
      /** filter the rows returned */
      where?: migrate_device_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate?: migrate_device_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: migrate_device_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: migrate_device_order_by[] | null
      /** filter the rows returned */
      where?: migrate_device_bool_exp | null
    }
  }
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk?: migrate_deviceGenqlSelection & { __args: { cred_id: Scalars['String'] } }
  /** An array relationship */
  notifications?: notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  notifications_aggregate?: notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: notificationsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** An array relationship */
  p2p_offers?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_offers_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk?: p2p_offersGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** An array relationship */
  p2p_pre_sell_offers?: p2p_pre_sell_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_pre_sell_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_pre_sell_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate?: p2p_pre_sell_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_pre_sell_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_pre_sell_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  /** fetch data from the table: "p2p_pre_sell_offers" using primary key columns */
  p2p_pre_sell_offers_by_pk?: p2p_pre_sell_offersGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  photo_id_verification?: photo_id_verification_outputGenqlSelection & {
    __args?: { photo_id?: Scalars['String'] | null }
  }
  /** fetch data from the table: "preferences" */
  preferences?: preferencesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: preferences_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: preferences_order_by[] | null
      /** filter the rows returned */
      where?: preferences_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate?: preferences_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: preferences_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: preferences_order_by[] | null
      /** filter the rows returned */
      where?: preferences_bool_exp | null
    }
  }
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: preferencesGenqlSelection & { __args: { account: Scalars['String'] } }
  /** fetch data from the table: "reg_accounts" */
  reg_accounts?: reg_accountsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate?: reg_accounts_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk?: reg_accountsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table: "reputation_score" */
  reputation_score?: reputation_scoreGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reputation_score_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reputation_score_order_by[] | null
      /** filter the rows returned */
      where?: reputation_score_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "reputation_score" */
  reputation_score_aggregate?: reputation_score_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reputation_score_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reputation_score_order_by[] | null
      /** filter the rows returned */
      where?: reputation_score_bool_exp | null
    }
  }
  /** fetch data from the table: "reputation_score" using primary key columns */
  reputation_score_by_pk?: reputation_scoreGenqlSelection & {
    __args: { account: Scalars['String'] }
  }
  selfie_verification?: selfie_verification_outputGenqlSelection & {
    __args?: { selfie?: Scalars['String'] | null }
  }
  /** fetch data from the table: "signing_requests" */
  signing_requests?: signing_requestsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: signing_requests_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: signing_requests_order_by[] | null
      /** filter the rows returned */
      where?: signing_requests_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "signing_requests" */
  signing_requests_aggregate?: signing_requests_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: signing_requests_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: signing_requests_order_by[] | null
      /** filter the rows returned */
      where?: signing_requests_bool_exp | null
    }
  }
  /** fetch data from the table: "signing_requests" using primary key columns */
  signing_requests_by_pk?: signing_requestsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** An array relationship */
  support_sessions?: support_sessionsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: support_sessions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: support_sessions_order_by[] | null
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** An aggregate relationship */
  support_sessions_aggregate?: support_sessions_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: support_sessions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: support_sessions_order_by[] | null
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** fetch data from the table: "support_sessions" using primary key columns */
  support_sessions_by_pk?: support_sessionsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table: "swap_assets" */
  swap_assets?: swap_assetsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_assets_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_assets_order_by[] | null
      /** filter the rows returned */
      where?: swap_assets_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate?: swap_assets_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_assets_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_assets_order_by[] | null
      /** filter the rows returned */
      where?: swap_assets_bool_exp | null
    }
  }
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk?: swap_assetsGenqlSelection & {
    __args: { asset: Scalars['String']; chain: Scalars['String'] }
  }
  /** An array relationship */
  swap_orders?: swap_ordersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_orders_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_orders_order_by[] | null
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** An aggregate relationship */
  swap_orders_aggregate?: swap_orders_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_orders_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_orders_order_by[] | null
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk?: swap_ordersGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table: "system_notification_read_status" */
  system_notification_read_status?: system_notification_read_statusGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "system_notification_read_status" */
  system_notification_read_status_aggregate?: system_notification_read_status_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** fetch data from the table: "system_notification_read_status" using primary key columns */
  system_notification_read_status_by_pk?: system_notification_read_statusGenqlSelection & {
    __args: { notification_id: Scalars['uuid']; reg_account: Scalars['uuid'] }
  }
  /** An array relationship */
  system_notifications?: system_notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  system_notifications_aggregate?: system_notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** fetch data from the table: "system_notifications" using primary key columns */
  system_notifications_by_pk?: system_notificationsGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** fetch data from the table: "system_type_notification" */
  system_type_notification?: system_type_notificationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_type_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_type_notification_order_by[] | null
      /** filter the rows returned */
      where?: system_type_notification_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "system_type_notification" */
  system_type_notification_aggregate?: system_type_notification_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_type_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_type_notification_order_by[] | null
      /** filter the rows returned */
      where?: system_type_notification_bool_exp | null
    }
  }
  /** fetch data from the table: "system_type_notification" using primary key columns */
  system_type_notification_by_pk?: system_type_notificationGenqlSelection & {
    __args: { type: Scalars['String'] }
  }
  /** fetch data from the table: "table_rows" */
  table_rows?: table_rowsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: table_rows_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: table_rows_order_by[] | null
      /** filter the rows returned */
      where?: table_rows_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate?: table_rows_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: table_rows_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: table_rows_order_by[] | null
      /** filter the rows returned */
      where?: table_rows_bool_exp | null
    }
  }
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk?: table_rowsGenqlSelection & {
    __args: {
      contract: Scalars['String']
      primary_key: Scalars['String']
      scope: Scalars['String']
      table: Scalars['String']
    }
  }
  /** fetch data from the table: "trust_network" */
  trust_network?: trust_networkGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate?: trust_network_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk?: trust_networkGenqlSelection & {
    __args: { account: Scalars['String']; trust: Scalars['String'] }
  }
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification?: trust_network_notificationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate?: trust_network_notification_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk?: trust_network_notificationGenqlSelection & {
    __args: { account: Scalars['String']; trust: Scalars['String'] }
  }
  /** fetch data from the table: "trust_network_status" */
  trust_network_status?: trust_network_statusGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_status_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_status_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate?: trust_network_status_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_status_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_status_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk?: trust_network_statusGenqlSelection & {
    __args: { status: Scalars['String'] }
  }
  /** fetch data from the table: "tx_statistics" */
  tx_statistics?: tx_statisticsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: tx_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: tx_statistics_order_by[] | null
      /** filter the rows returned */
      where?: tx_statistics_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "tx_statistics" */
  tx_statistics_aggregate?: tx_statistics_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: tx_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: tx_statistics_order_by[] | null
      /** filter the rows returned */
      where?: tx_statistics_bool_exp | null
    }
  }
  /** fetch data from the table: "volume_statistics" */
  volume_statistics?: volume_statisticsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: volume_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: volume_statistics_order_by[] | null
      /** filter the rows returned */
      where?: volume_statistics_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "volume_statistics" */
  volume_statistics_aggregate?: volume_statistics_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: volume_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: volume_statistics_order_by[] | null
      /** filter the rows returned */
      where?: volume_statistics_bool_exp | null
    }
  }
  /** fetch data from the table: "web_push" */
  web_push?: web_pushGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: web_push_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: web_push_order_by[] | null
      /** filter the rows returned */
      where?: web_push_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "web_push" */
  web_push_aggregate?: web_push_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: web_push_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: web_push_order_by[] | null
      /** filter the rows returned */
      where?: web_push_bool_exp | null
    }
  }
  /** fetch data from the table: "web_push" using primary key columns */
  web_push_by_pk?: web_pushGenqlSelection & {
    __args: { auth: Scalars['String']; p256dh: Scalars['String'] }
  }
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "reg_accounts" */
export interface reg_accountsGenqlSelection {
  account?: boolean | number
  /** An object relationship */
  accounts_information?: accounts_informationGenqlSelection
  /** An array relationship */
  accounts_referrals?: accounts_referralGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_referral_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_referral_order_by[] | null
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  /** An aggregate relationship */
  accounts_referrals_aggregate?: accounts_referral_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_referral_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_referral_order_by[] | null
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  create_account?: boolean | number
  created?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  /** An array relationship */
  devices?: devicesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: devices_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: devices_order_by[] | null
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  /** An aggregate relationship */
  devices_aggregate?: devices_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: devices_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: devices_order_by[] | null
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  freezed?: boolean | number
  id?: boolean | number
  is_verified?: boolean | number
  /** An array relationship */
  login_accounts?: login_accountGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_account_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_account_order_by[] | null
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** An aggregate relationship */
  login_accounts_aggregate?: login_account_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_account_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_account_order_by[] | null
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** An array relationship */
  messages?: messagesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An array relationship */
  messagesByTo?: messagesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An aggregate relationship */
  messagesByTo_aggregate?: messages_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An aggregate relationship */
  messages_aggregate?: messages_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An array relationship */
  notifications?: notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** An array relationship */
  notificationsByFrom?: notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  notificationsByFrom_aggregate?: notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  notifications_aggregate?: notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  onboarded?: boolean | number
  /** An array relationship */
  p2p_offers?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_offers_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An array relationship */
  p2p_offers_buyer?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_offers_buyer_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An array relationship */
  p2p_offers_initiator?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_offers_initiator_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An array relationship */
  p2p_offers_seller?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_offers_seller_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An array relationship */
  p2p_pre_sell_offers?: p2p_pre_sell_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_pre_sell_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_pre_sell_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate?: p2p_pre_sell_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_pre_sell_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_pre_sell_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  public_key?: boolean | number
  referrer?: boolean | number
  /** An array relationship */
  regAccountsByCancelledBy?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  regAccountsByCancelledBy_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An array relationship */
  reg_accounts_accounts_information?: accounts_informationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_information_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_information_order_by[] | null
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** An aggregate relationship */
  reg_accounts_accounts_information_aggregate?: accounts_information_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_information_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_information_order_by[] | null
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** An object relationship */
  reputation_score?: reputation_scoreGenqlSelection
  role?: boolean | number
  source?: boolean | number
  /** An array relationship */
  support_sessions?: support_sessionsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: support_sessions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: support_sessions_order_by[] | null
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** An aggregate relationship */
  support_sessions_aggregate?: support_sessions_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: support_sessions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: support_sessions_order_by[] | null
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** An array relationship */
  swap_orders?: swap_ordersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_orders_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_orders_order_by[] | null
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** An aggregate relationship */
  swap_orders_aggregate?: swap_orders_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_orders_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_orders_order_by[] | null
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** An array relationship */
  system_notification_read_statuses?: system_notification_read_statusGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** An aggregate relationship */
  system_notification_read_statuses_aggregate?: system_notification_read_status_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** An array relationship */
  trustNetworkNotificationsByTrust?: trust_network_notificationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** An aggregate relationship */
  trustNetworkNotificationsByTrust_aggregate?: trust_network_notification_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** An array relationship */
  trust_network_notifications?: trust_network_notificationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** An aggregate relationship */
  trust_network_notifications_aggregate?: trust_network_notification_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** An array relationship */
  trust_networks_by_account?: trust_networkGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** An aggregate relationship */
  trust_networks_by_account_aggregate?: trust_network_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** An array relationship */
  trust_networks_by_trust?: trust_networkGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** An aggregate relationship */
  trust_networks_by_trust_aggregate?: trust_network_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  txid?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface reg_accounts_aggregateGenqlSelection {
  aggregate?: reg_accounts_aggregate_fieldsGenqlSelection
  nodes?: reg_accountsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "reg_accounts" */
export interface reg_accounts_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: reg_accounts_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: reg_accounts_max_fieldsGenqlSelection
  min?: reg_accounts_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "reg_accounts". All fields are combined with a logical 'AND'. */
export interface reg_accounts_bool_exp {
  _and?: reg_accounts_bool_exp[] | null
  _not?: reg_accounts_bool_exp | null
  _or?: reg_accounts_bool_exp[] | null
  account?: String_comparison_exp | null
  accounts_information?: accounts_information_bool_exp | null
  accounts_referrals?: accounts_referral_bool_exp | null
  accounts_referrals_aggregate?: accounts_referral_aggregate_bool_exp | null
  create_account?: Boolean_comparison_exp | null
  created?: timestamptz_comparison_exp | null
  created_at?: timestamp_comparison_exp | null
  cred_id?: String_comparison_exp | null
  device_name?: String_comparison_exp | null
  devices?: devices_bool_exp | null
  devices_aggregate?: devices_aggregate_bool_exp | null
  freezed?: Boolean_comparison_exp | null
  id?: uuid_comparison_exp | null
  is_verified?: Boolean_comparison_exp | null
  login_accounts?: login_account_bool_exp | null
  login_accounts_aggregate?: login_account_aggregate_bool_exp | null
  messages?: messages_bool_exp | null
  messagesByTo?: messages_bool_exp | null
  messagesByTo_aggregate?: messages_aggregate_bool_exp | null
  messages_aggregate?: messages_aggregate_bool_exp | null
  notifications?: notifications_bool_exp | null
  notificationsByFrom?: notifications_bool_exp | null
  notificationsByFrom_aggregate?: notifications_aggregate_bool_exp | null
  notifications_aggregate?: notifications_aggregate_bool_exp | null
  onboarded?: Boolean_comparison_exp | null
  p2p_offers?: p2p_offers_bool_exp | null
  p2p_offers_aggregate?: p2p_offers_aggregate_bool_exp | null
  p2p_offers_buyer?: p2p_offers_bool_exp | null
  p2p_offers_buyer_aggregate?: p2p_offers_aggregate_bool_exp | null
  p2p_offers_initiator?: p2p_offers_bool_exp | null
  p2p_offers_initiator_aggregate?: p2p_offers_aggregate_bool_exp | null
  p2p_offers_seller?: p2p_offers_bool_exp | null
  p2p_offers_seller_aggregate?: p2p_offers_aggregate_bool_exp | null
  p2p_pre_sell_offers?: p2p_pre_sell_offers_bool_exp | null
  p2p_pre_sell_offers_aggregate?: p2p_pre_sell_offers_aggregate_bool_exp | null
  public_key?: String_comparison_exp | null
  referrer?: String_comparison_exp | null
  regAccountsByCancelledBy?: p2p_offers_bool_exp | null
  regAccountsByCancelledBy_aggregate?: p2p_offers_aggregate_bool_exp | null
  reg_accounts_accounts_information?: accounts_information_bool_exp | null
  reg_accounts_accounts_information_aggregate?: accounts_information_aggregate_bool_exp | null
  reputation_score?: reputation_score_bool_exp | null
  role?: String_comparison_exp | null
  source?: String_comparison_exp | null
  support_sessions?: support_sessions_bool_exp | null
  support_sessions_aggregate?: support_sessions_aggregate_bool_exp | null
  swap_orders?: swap_orders_bool_exp | null
  swap_orders_aggregate?: swap_orders_aggregate_bool_exp | null
  system_notification_read_statuses?: system_notification_read_status_bool_exp | null
  system_notification_read_statuses_aggregate?: system_notification_read_status_aggregate_bool_exp | null
  trustNetworkNotificationsByTrust?: trust_network_notification_bool_exp | null
  trustNetworkNotificationsByTrust_aggregate?: trust_network_notification_aggregate_bool_exp | null
  trust_network_notifications?: trust_network_notification_bool_exp | null
  trust_network_notifications_aggregate?: trust_network_notification_aggregate_bool_exp | null
  trust_networks_by_account?: trust_network_bool_exp | null
  trust_networks_by_account_aggregate?: trust_network_aggregate_bool_exp | null
  trust_networks_by_trust?: trust_network_bool_exp | null
  trust_networks_by_trust_aggregate?: trust_network_aggregate_bool_exp | null
  txid?: String_comparison_exp | null
}

/** input type for inserting data into table "reg_accounts" */
export interface reg_accounts_insert_input {
  account?: Scalars['String'] | null
  accounts_information?: accounts_information_obj_rel_insert_input | null
  accounts_referrals?: accounts_referral_arr_rel_insert_input | null
  create_account?: Scalars['Boolean'] | null
  created?: Scalars['timestamptz'] | null
  created_at?: Scalars['timestamp'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  devices?: devices_arr_rel_insert_input | null
  freezed?: Scalars['Boolean'] | null
  id?: Scalars['uuid'] | null
  is_verified?: Scalars['Boolean'] | null
  login_accounts?: login_account_arr_rel_insert_input | null
  messages?: messages_arr_rel_insert_input | null
  messagesByTo?: messages_arr_rel_insert_input | null
  notifications?: notifications_arr_rel_insert_input | null
  notificationsByFrom?: notifications_arr_rel_insert_input | null
  onboarded?: Scalars['Boolean'] | null
  p2p_offers?: p2p_offers_arr_rel_insert_input | null
  p2p_offers_buyer?: p2p_offers_arr_rel_insert_input | null
  p2p_offers_initiator?: p2p_offers_arr_rel_insert_input | null
  p2p_offers_seller?: p2p_offers_arr_rel_insert_input | null
  p2p_pre_sell_offers?: p2p_pre_sell_offers_arr_rel_insert_input | null
  public_key?: Scalars['String'] | null
  referrer?: Scalars['String'] | null
  regAccountsByCancelledBy?: p2p_offers_arr_rel_insert_input | null
  reg_accounts_accounts_information?: accounts_information_arr_rel_insert_input | null
  reputation_score?: reputation_score_obj_rel_insert_input | null
  role?: Scalars['String'] | null
  source?: Scalars['String'] | null
  support_sessions?: support_sessions_arr_rel_insert_input | null
  swap_orders?: swap_orders_arr_rel_insert_input | null
  system_notification_read_statuses?: system_notification_read_status_arr_rel_insert_input | null
  trustNetworkNotificationsByTrust?: trust_network_notification_arr_rel_insert_input | null
  trust_network_notifications?: trust_network_notification_arr_rel_insert_input | null
  trust_networks_by_account?: trust_network_arr_rel_insert_input | null
  trust_networks_by_trust?: trust_network_arr_rel_insert_input | null
  txid?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface reg_accounts_max_fieldsGenqlSelection {
  account?: boolean | number
  created?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  id?: boolean | number
  public_key?: boolean | number
  referrer?: boolean | number
  role?: boolean | number
  source?: boolean | number
  txid?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface reg_accounts_min_fieldsGenqlSelection {
  account?: boolean | number
  created?: boolean | number
  created_at?: boolean | number
  cred_id?: boolean | number
  device_name?: boolean | number
  id?: boolean | number
  public_key?: boolean | number
  referrer?: boolean | number
  role?: boolean | number
  source?: boolean | number
  txid?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "reg_accounts" */
export interface reg_accounts_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: reg_accountsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "reg_accounts" */
export interface reg_accounts_obj_rel_insert_input {
  data: reg_accounts_insert_input
  /** upsert condition */
  on_conflict?: reg_accounts_on_conflict | null
}

/** on_conflict condition type for table "reg_accounts" */
export interface reg_accounts_on_conflict {
  constraint: reg_accounts_constraint
  update_columns?: reg_accounts_update_column[]
  where?: reg_accounts_bool_exp | null
}

/** Ordering options when selecting data from "reg_accounts". */
export interface reg_accounts_order_by {
  account?: order_by | null
  accounts_information?: accounts_information_order_by | null
  accounts_referrals_aggregate?: accounts_referral_aggregate_order_by | null
  create_account?: order_by | null
  created?: order_by | null
  created_at?: order_by | null
  cred_id?: order_by | null
  device_name?: order_by | null
  devices_aggregate?: devices_aggregate_order_by | null
  freezed?: order_by | null
  id?: order_by | null
  is_verified?: order_by | null
  login_accounts_aggregate?: login_account_aggregate_order_by | null
  messagesByTo_aggregate?: messages_aggregate_order_by | null
  messages_aggregate?: messages_aggregate_order_by | null
  notificationsByFrom_aggregate?: notifications_aggregate_order_by | null
  notifications_aggregate?: notifications_aggregate_order_by | null
  onboarded?: order_by | null
  p2p_offers_aggregate?: p2p_offers_aggregate_order_by | null
  p2p_offers_buyer_aggregate?: p2p_offers_aggregate_order_by | null
  p2p_offers_initiator_aggregate?: p2p_offers_aggregate_order_by | null
  p2p_offers_seller_aggregate?: p2p_offers_aggregate_order_by | null
  p2p_pre_sell_offers_aggregate?: p2p_pre_sell_offers_aggregate_order_by | null
  public_key?: order_by | null
  referrer?: order_by | null
  regAccountsByCancelledBy_aggregate?: p2p_offers_aggregate_order_by | null
  reg_accounts_accounts_information_aggregate?: accounts_information_aggregate_order_by | null
  reputation_score?: reputation_score_order_by | null
  role?: order_by | null
  source?: order_by | null
  support_sessions_aggregate?: support_sessions_aggregate_order_by | null
  swap_orders_aggregate?: swap_orders_aggregate_order_by | null
  system_notification_read_statuses_aggregate?: system_notification_read_status_aggregate_order_by | null
  trustNetworkNotificationsByTrust_aggregate?: trust_network_notification_aggregate_order_by | null
  trust_network_notifications_aggregate?: trust_network_notification_aggregate_order_by | null
  trust_networks_by_account_aggregate?: trust_network_aggregate_order_by | null
  trust_networks_by_trust_aggregate?: trust_network_aggregate_order_by | null
  txid?: order_by | null
}

/** primary key columns input for table: reg_accounts */
export interface reg_accounts_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "reg_accounts" */
export interface reg_accounts_set_input {
  account?: Scalars['String'] | null
  create_account?: Scalars['Boolean'] | null
  created?: Scalars['timestamptz'] | null
  created_at?: Scalars['timestamp'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  freezed?: Scalars['Boolean'] | null
  id?: Scalars['uuid'] | null
  is_verified?: Scalars['Boolean'] | null
  onboarded?: Scalars['Boolean'] | null
  public_key?: Scalars['String'] | null
  referrer?: Scalars['String'] | null
  role?: Scalars['String'] | null
  source?: Scalars['String'] | null
  txid?: Scalars['String'] | null
}

/** Streaming cursor of the table "reg_accounts" */
export interface reg_accounts_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: reg_accounts_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface reg_accounts_stream_cursor_value_input {
  account?: Scalars['String'] | null
  create_account?: Scalars['Boolean'] | null
  created?: Scalars['timestamptz'] | null
  created_at?: Scalars['timestamp'] | null
  cred_id?: Scalars['String'] | null
  device_name?: Scalars['String'] | null
  freezed?: Scalars['Boolean'] | null
  id?: Scalars['uuid'] | null
  is_verified?: Scalars['Boolean'] | null
  onboarded?: Scalars['Boolean'] | null
  public_key?: Scalars['String'] | null
  referrer?: Scalars['String'] | null
  role?: Scalars['String'] | null
  source?: Scalars['String'] | null
  txid?: Scalars['String'] | null
}

export interface reg_accounts_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: reg_accounts_set_input | null
  /** filter the rows which have to be updated */
  where: reg_accounts_bool_exp
}

/** columns and relationships of "reputation_score" */
export interface reputation_scoreGenqlSelection {
  account?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  score?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "reputation_score" */
export interface reputation_score_aggregateGenqlSelection {
  aggregate?: reputation_score_aggregate_fieldsGenqlSelection
  nodes?: reputation_scoreGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "reputation_score" */
export interface reputation_score_aggregate_fieldsGenqlSelection {
  avg?: reputation_score_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: reputation_score_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: reputation_score_max_fieldsGenqlSelection
  min?: reputation_score_min_fieldsGenqlSelection
  stddev?: reputation_score_stddev_fieldsGenqlSelection
  stddev_pop?: reputation_score_stddev_pop_fieldsGenqlSelection
  stddev_samp?: reputation_score_stddev_samp_fieldsGenqlSelection
  sum?: reputation_score_sum_fieldsGenqlSelection
  var_pop?: reputation_score_var_pop_fieldsGenqlSelection
  var_samp?: reputation_score_var_samp_fieldsGenqlSelection
  variance?: reputation_score_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface reputation_score_avg_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "reputation_score". All fields are combined with a logical 'AND'. */
export interface reputation_score_bool_exp {
  _and?: reputation_score_bool_exp[] | null
  _not?: reputation_score_bool_exp | null
  _or?: reputation_score_bool_exp[] | null
  account?: String_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  score?: numeric_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** input type for incrementing numeric columns in table "reputation_score" */
export interface reputation_score_inc_input {
  score?: Scalars['numeric'] | null
}

/** input type for inserting data into table "reputation_score" */
export interface reputation_score_insert_input {
  account?: Scalars['String'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  score?: Scalars['numeric'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** aggregate max on columns */
export interface reputation_score_max_fieldsGenqlSelection {
  account?: boolean | number
  score?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface reputation_score_min_fieldsGenqlSelection {
  account?: boolean | number
  score?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "reputation_score" */
export interface reputation_score_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: reputation_scoreGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "reputation_score" */
export interface reputation_score_obj_rel_insert_input {
  data: reputation_score_insert_input
  /** upsert condition */
  on_conflict?: reputation_score_on_conflict | null
}

/** on_conflict condition type for table "reputation_score" */
export interface reputation_score_on_conflict {
  constraint: reputation_score_constraint
  update_columns?: reputation_score_update_column[]
  where?: reputation_score_bool_exp | null
}

/** Ordering options when selecting data from "reputation_score". */
export interface reputation_score_order_by {
  account?: order_by | null
  reg_account?: reg_accounts_order_by | null
  score?: order_by | null
  updated_at?: order_by | null
}

/** primary key columns input for table: reputation_score */
export interface reputation_score_pk_columns_input {
  account: Scalars['String']
}

/** input type for updating data in table "reputation_score" */
export interface reputation_score_set_input {
  account?: Scalars['String'] | null
  score?: Scalars['numeric'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** aggregate stddev on columns */
export interface reputation_score_stddev_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface reputation_score_stddev_pop_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface reputation_score_stddev_samp_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "reputation_score" */
export interface reputation_score_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: reputation_score_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface reputation_score_stream_cursor_value_input {
  account?: Scalars['String'] | null
  score?: Scalars['numeric'] | null
  updated_at?: Scalars['timestamptz'] | null
}

/** aggregate sum on columns */
export interface reputation_score_sum_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface reputation_score_updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: reputation_score_inc_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: reputation_score_set_input | null
  /** filter the rows which have to be updated */
  where: reputation_score_bool_exp
}

/** aggregate var_pop on columns */
export interface reputation_score_var_pop_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface reputation_score_var_samp_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface reputation_score_variance_fieldsGenqlSelection {
  score?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface request_new_account_input {
  account: Scalars['String']
  country_id: Scalars['JSON']
  create_account?: Scalars['Boolean'] | null
  cred_id: Scalars['String']
  device_name: Scalars['String']
  email_address: Scalars['String']
  full_name: Scalars['String']
  newsletter_subscription: Scalars['Boolean']
  phone_number: Scalars['String']
  photo_id: Scalars['String']
  public_key: Scalars['String']
  referrer: Scalars['String']
  selfie: Scalars['String']
  source?: Scalars['String'] | null
}

export interface request_new_account_outputGenqlSelection {
  id?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface selfie_verification_outputGenqlSelection {
  error?: boolean | number
  eye?: boolean | number
  face?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface short_link_input {
  referrer: Scalars['String']
  website: Scalars['String']
}

/** columns and relationships of "signing_requests" */
export interface signing_requestsGenqlSelection {
  createdAt?: boolean | number
  esr?: boolean | number
  id?: boolean | number
  signature?: boolean | number
  signer?: boolean | number
  status?: boolean | number
  transactionId?: boolean | number
  updatedAt?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "signing_requests" */
export interface signing_requests_aggregateGenqlSelection {
  aggregate?: signing_requests_aggregate_fieldsGenqlSelection
  nodes?: signing_requestsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "signing_requests" */
export interface signing_requests_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: signing_requests_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: signing_requests_max_fieldsGenqlSelection
  min?: signing_requests_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "signing_requests". All fields are combined with a logical 'AND'. */
export interface signing_requests_bool_exp {
  _and?: signing_requests_bool_exp[] | null
  _not?: signing_requests_bool_exp | null
  _or?: signing_requests_bool_exp[] | null
  createdAt?: timestamptz_comparison_exp | null
  esr?: String_comparison_exp | null
  id?: uuid_comparison_exp | null
  signature?: String_comparison_exp | null
  signer?: String_comparison_exp | null
  status?: String_comparison_exp | null
  transactionId?: String_comparison_exp | null
  updatedAt?: timestamptz_comparison_exp | null
}

/** input type for inserting data into table "signing_requests" */
export interface signing_requests_insert_input {
  createdAt?: Scalars['timestamptz'] | null
  esr?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  signature?: Scalars['String'] | null
  signer?: Scalars['String'] | null
  status?: Scalars['String'] | null
  transactionId?: Scalars['String'] | null
  updatedAt?: Scalars['timestamptz'] | null
}

/** aggregate max on columns */
export interface signing_requests_max_fieldsGenqlSelection {
  createdAt?: boolean | number
  esr?: boolean | number
  id?: boolean | number
  signature?: boolean | number
  signer?: boolean | number
  status?: boolean | number
  transactionId?: boolean | number
  updatedAt?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface signing_requests_min_fieldsGenqlSelection {
  createdAt?: boolean | number
  esr?: boolean | number
  id?: boolean | number
  signature?: boolean | number
  signer?: boolean | number
  status?: boolean | number
  transactionId?: boolean | number
  updatedAt?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "signing_requests" */
export interface signing_requests_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: signing_requestsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "signing_requests" */
export interface signing_requests_on_conflict {
  constraint: signing_requests_constraint
  update_columns?: signing_requests_update_column[]
  where?: signing_requests_bool_exp | null
}

/** Ordering options when selecting data from "signing_requests". */
export interface signing_requests_order_by {
  createdAt?: order_by | null
  esr?: order_by | null
  id?: order_by | null
  signature?: order_by | null
  signer?: order_by | null
  status?: order_by | null
  transactionId?: order_by | null
  updatedAt?: order_by | null
}

/** primary key columns input for table: signing_requests */
export interface signing_requests_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "signing_requests" */
export interface signing_requests_set_input {
  createdAt?: Scalars['timestamptz'] | null
  esr?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  signature?: Scalars['String'] | null
  signer?: Scalars['String'] | null
  status?: Scalars['String'] | null
  transactionId?: Scalars['String'] | null
  updatedAt?: Scalars['timestamptz'] | null
}

/** Streaming cursor of the table "signing_requests" */
export interface signing_requests_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: signing_requests_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface signing_requests_stream_cursor_value_input {
  createdAt?: Scalars['timestamptz'] | null
  esr?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  signature?: Scalars['String'] | null
  signer?: Scalars['String'] | null
  status?: Scalars['String'] | null
  transactionId?: Scalars['String'] | null
  updatedAt?: Scalars['timestamptz'] | null
}

export interface signing_requests_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: signing_requests_set_input | null
  /** filter the rows which have to be updated */
  where: signing_requests_bool_exp
}

export interface store_ip_address_outputGenqlSelection {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface subscription_rootGenqlSelection {
  /** fetch data from the table: "account_statistics" */
  account_statistics?: account_statisticsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: account_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: account_statistics_order_by[] | null
      /** filter the rows returned */
      where?: account_statistics_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "account_statistics" */
  account_statistics_aggregate?: account_statistics_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: account_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: account_statistics_order_by[] | null
      /** filter the rows returned */
      where?: account_statistics_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "account_statistics" */
  account_statistics_stream?: account_statisticsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (account_statistics_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: account_statistics_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_information" */
  accounts_information?: accounts_informationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_information_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_information_order_by[] | null
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "accounts_information" */
  accounts_information_aggregate?: accounts_information_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_information_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_information_order_by[] | null
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_information" using primary key columns */
  accounts_information_by_pk?: accounts_informationGenqlSelection & {
    __args: { account: Scalars['String']; id: Scalars['uuid'] }
  }
  /** fetch data from the table in a streaming manner: "accounts_information" */
  accounts_information_stream?: accounts_informationGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (accounts_information_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: accounts_information_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_referral" */
  accounts_referral?: accounts_referralGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_referral_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_referral_order_by[] | null
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "accounts_referral" */
  accounts_referral_aggregate?: accounts_referral_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: accounts_referral_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: accounts_referral_order_by[] | null
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  /** fetch data from the table: "accounts_referral" using primary key columns */
  accounts_referral_by_pk?: accounts_referralGenqlSelection & {
    __args: { link: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "accounts_referral" */
  accounts_referral_stream?: accounts_referralGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (accounts_referral_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: accounts_referral_bool_exp | null
    }
  }
  /** fetch data from the table: "actions" */
  actions?: actionsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: actions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: actions_order_by[] | null
      /** filter the rows returned */
      where?: actions_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate?: actions_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: actions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: actions_order_by[] | null
      /** filter the rows returned */
      where?: actions_bool_exp | null
    }
  }
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: actionsGenqlSelection & { __args: { global_sequence: Scalars['String'] } }
  /** fetch data from the table in a streaming manner: "actions" */
  actions_stream?: actionsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (actions_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: actions_bool_exp | null
    }
  }
  /** fetch data from the table: "avg_pay_time" */
  avg_pay_time?: avg_pay_timeGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_pay_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_pay_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_pay_time_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "avg_pay_time" */
  avg_pay_time_aggregate?: avg_pay_time_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_pay_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_pay_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_pay_time_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "avg_pay_time" */
  avg_pay_time_stream?: avg_pay_timeGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (avg_pay_time_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: avg_pay_time_bool_exp | null
    }
  }
  /** fetch data from the table: "avg_release_time" */
  avg_release_time?: avg_release_timeGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_release_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_release_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_release_time_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "avg_release_time" */
  avg_release_time_aggregate?: avg_release_time_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: avg_release_time_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: avg_release_time_order_by[] | null
      /** filter the rows returned */
      where?: avg_release_time_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "avg_release_time" */
  avg_release_time_stream?: avg_release_timeGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (avg_release_time_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: avg_release_time_bool_exp | null
    }
  }
  /** fetch data from the table: "combined_avg_times" */
  combined_avg_times?: combined_avg_timesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: combined_avg_times_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: combined_avg_times_order_by[] | null
      /** filter the rows returned */
      where?: combined_avg_times_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "combined_avg_times" */
  combined_avg_times_aggregate?: combined_avg_times_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: combined_avg_times_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: combined_avg_times_order_by[] | null
      /** filter the rows returned */
      where?: combined_avg_times_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "combined_avg_times" */
  combined_avg_times_stream?: combined_avg_timesGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (combined_avg_times_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: combined_avg_times_bool_exp | null
    }
  }
  /** An array relationship */
  devices?: devicesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: devices_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: devices_order_by[] | null
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  /** An aggregate relationship */
  devices_aggregate?: devices_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: devices_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: devices_order_by[] | null
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  /** fetch data from the table: "devices" using primary key columns */
  devices_by_pk?: devicesGenqlSelection & { __args: { cred_id: Scalars['String'] } }
  /** fetch data from the table in a streaming manner: "devices" */
  devices_stream?: devicesGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (devices_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: devices_bool_exp | null
    }
  }
  /** execute function "get_inactive_accounts" which returns "reg_accounts" */
  get_inactive_accounts?: reg_accountsGenqlSelection & {
    __args: {
      /** input parameters for function "get_inactive_accounts" */
      args: get_inactive_accounts_args
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** execute function "get_inactive_accounts" and query aggregates on result of table type "reg_accounts" */
  get_inactive_accounts_aggregate?: reg_accounts_aggregateGenqlSelection & {
    __args: {
      /** input parameters for function "get_inactive_accounts_aggregate" */
      args: get_inactive_accounts_args
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** execute function "get_login_counts" which returns "login_counts" */
  get_login_counts?: login_countsGenqlSelection & {
    __args: {
      /** input parameters for function "get_login_counts" */
      args: get_login_counts_args
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** execute function "get_login_counts" and query aggregates on result of table type "login_counts" */
  get_login_counts_aggregate?: login_counts_aggregateGenqlSelection & {
    __args: {
      /** input parameters for function "get_login_counts_aggregate" */
      args: get_login_counts_args
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** execute function "get_p2p_offers_with_old_or_no_messages" which returns "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages?: p2p_offersGenqlSelection & {
    __args: {
      /** input parameters for function "get_p2p_offers_with_old_or_no_messages" */
      args: get_p2p_offers_with_old_or_no_messages_args
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** execute function "get_p2p_offers_with_old_or_no_messages" and query aggregates on result of table type "p2p_offers" */
  get_p2p_offers_with_old_or_no_messages_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args: {
      /** input parameters for function "get_p2p_offers_with_old_or_no_messages_aggregate" */
      args: get_p2p_offers_with_old_or_no_messages_args
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** fetch data from the table: "login_account" */
  login_account?: login_accountGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_account_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_account_order_by[] | null
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "login_account" */
  login_account_aggregate?: login_account_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_account_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_account_order_by[] | null
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** fetch data from the table: "login_account" using primary key columns */
  login_account_by_pk?: login_accountGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "login_account" */
  login_account_stream?: login_accountGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (login_account_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: login_account_bool_exp | null
    }
  }
  /** fetch data from the table: "login_counts" */
  login_counts?: login_countsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "login_counts" */
  login_counts_aggregate?: login_counts_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: login_counts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: login_counts_order_by[] | null
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "login_counts" */
  login_counts_stream?: login_countsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (login_counts_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: login_counts_bool_exp | null
    }
  }
  /** An array relationship */
  messages?: messagesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An aggregate relationship */
  messages_aggregate?: messages_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: messagesGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "messages" */
  messages_stream?: messagesGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (messages_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** fetch data from the table: "migrate_device" */
  migrate_device?: migrate_deviceGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: migrate_device_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: migrate_device_order_by[] | null
      /** filter the rows returned */
      where?: migrate_device_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "migrate_device" */
  migrate_device_aggregate?: migrate_device_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: migrate_device_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: migrate_device_order_by[] | null
      /** filter the rows returned */
      where?: migrate_device_bool_exp | null
    }
  }
  /** fetch data from the table: "migrate_device" using primary key columns */
  migrate_device_by_pk?: migrate_deviceGenqlSelection & { __args: { cred_id: Scalars['String'] } }
  /** fetch data from the table in a streaming manner: "migrate_device" */
  migrate_device_stream?: migrate_deviceGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (migrate_device_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: migrate_device_bool_exp | null
    }
  }
  /** An array relationship */
  notifications?: notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  notifications_aggregate?: notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: notifications_order_by[] | null
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: notificationsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream?: notificationsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (notifications_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: notifications_bool_exp | null
    }
  }
  /** An array relationship */
  p2p_offers?: p2p_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_offers_aggregate?: p2p_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** fetch data from the table: "p2p_offers" using primary key columns */
  p2p_offers_by_pk?: p2p_offersGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "p2p_offers" */
  p2p_offers_stream?: p2p_offersGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (p2p_offers_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: p2p_offers_bool_exp | null
    }
  }
  /** An array relationship */
  p2p_pre_sell_offers?: p2p_pre_sell_offersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_pre_sell_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_pre_sell_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  /** An aggregate relationship */
  p2p_pre_sell_offers_aggregate?: p2p_pre_sell_offers_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: p2p_pre_sell_offers_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: p2p_pre_sell_offers_order_by[] | null
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  /** fetch data from the table: "p2p_pre_sell_offers" using primary key columns */
  p2p_pre_sell_offers_by_pk?: p2p_pre_sell_offersGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** fetch data from the table in a streaming manner: "p2p_pre_sell_offers" */
  p2p_pre_sell_offers_stream?: p2p_pre_sell_offersGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (p2p_pre_sell_offers_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: p2p_pre_sell_offers_bool_exp | null
    }
  }
  /** fetch data from the table: "preferences" */
  preferences?: preferencesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: preferences_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: preferences_order_by[] | null
      /** filter the rows returned */
      where?: preferences_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "preferences" */
  preferences_aggregate?: preferences_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: preferences_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: preferences_order_by[] | null
      /** filter the rows returned */
      where?: preferences_bool_exp | null
    }
  }
  /** fetch data from the table: "preferences" using primary key columns */
  preferences_by_pk?: preferencesGenqlSelection & { __args: { account: Scalars['String'] } }
  /** fetch data from the table in a streaming manner: "preferences" */
  preferences_stream?: preferencesGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (preferences_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: preferences_bool_exp | null
    }
  }
  /** fetch data from the table: "reg_accounts" */
  reg_accounts?: reg_accountsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "reg_accounts" */
  reg_accounts_aggregate?: reg_accounts_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reg_accounts_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reg_accounts_order_by[] | null
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** fetch data from the table: "reg_accounts" using primary key columns */
  reg_accounts_by_pk?: reg_accountsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "reg_accounts" */
  reg_accounts_stream?: reg_accountsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (reg_accounts_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: reg_accounts_bool_exp | null
    }
  }
  /** fetch data from the table: "reputation_score" */
  reputation_score?: reputation_scoreGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reputation_score_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reputation_score_order_by[] | null
      /** filter the rows returned */
      where?: reputation_score_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "reputation_score" */
  reputation_score_aggregate?: reputation_score_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: reputation_score_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: reputation_score_order_by[] | null
      /** filter the rows returned */
      where?: reputation_score_bool_exp | null
    }
  }
  /** fetch data from the table: "reputation_score" using primary key columns */
  reputation_score_by_pk?: reputation_scoreGenqlSelection & {
    __args: { account: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "reputation_score" */
  reputation_score_stream?: reputation_scoreGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (reputation_score_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: reputation_score_bool_exp | null
    }
  }
  /** fetch data from the table: "signing_requests" */
  signing_requests?: signing_requestsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: signing_requests_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: signing_requests_order_by[] | null
      /** filter the rows returned */
      where?: signing_requests_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "signing_requests" */
  signing_requests_aggregate?: signing_requests_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: signing_requests_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: signing_requests_order_by[] | null
      /** filter the rows returned */
      where?: signing_requests_bool_exp | null
    }
  }
  /** fetch data from the table: "signing_requests" using primary key columns */
  signing_requests_by_pk?: signing_requestsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "signing_requests" */
  signing_requests_stream?: signing_requestsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (signing_requests_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: signing_requests_bool_exp | null
    }
  }
  /** An array relationship */
  support_sessions?: support_sessionsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: support_sessions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: support_sessions_order_by[] | null
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** An aggregate relationship */
  support_sessions_aggregate?: support_sessions_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: support_sessions_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: support_sessions_order_by[] | null
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** fetch data from the table: "support_sessions" using primary key columns */
  support_sessions_by_pk?: support_sessionsGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "support_sessions" */
  support_sessions_stream?: support_sessionsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (support_sessions_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: support_sessions_bool_exp | null
    }
  }
  /** fetch data from the table: "swap_assets" */
  swap_assets?: swap_assetsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_assets_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_assets_order_by[] | null
      /** filter the rows returned */
      where?: swap_assets_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "swap_assets" */
  swap_assets_aggregate?: swap_assets_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_assets_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_assets_order_by[] | null
      /** filter the rows returned */
      where?: swap_assets_bool_exp | null
    }
  }
  /** fetch data from the table: "swap_assets" using primary key columns */
  swap_assets_by_pk?: swap_assetsGenqlSelection & {
    __args: { asset: Scalars['String']; chain: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "swap_assets" */
  swap_assets_stream?: swap_assetsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (swap_assets_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: swap_assets_bool_exp | null
    }
  }
  /** An array relationship */
  swap_orders?: swap_ordersGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_orders_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_orders_order_by[] | null
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** An aggregate relationship */
  swap_orders_aggregate?: swap_orders_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: swap_orders_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: swap_orders_order_by[] | null
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** fetch data from the table: "swap_orders" using primary key columns */
  swap_orders_by_pk?: swap_ordersGenqlSelection & { __args: { id: Scalars['uuid'] } }
  /** fetch data from the table in a streaming manner: "swap_orders" */
  swap_orders_stream?: swap_ordersGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (swap_orders_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: swap_orders_bool_exp | null
    }
  }
  /** fetch data from the table: "system_notification_read_status" */
  system_notification_read_status?: system_notification_read_statusGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "system_notification_read_status" */
  system_notification_read_status_aggregate?: system_notification_read_status_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** fetch data from the table: "system_notification_read_status" using primary key columns */
  system_notification_read_status_by_pk?: system_notification_read_statusGenqlSelection & {
    __args: { notification_id: Scalars['uuid']; reg_account: Scalars['uuid'] }
  }
  /** fetch data from the table in a streaming manner: "system_notification_read_status" */
  system_notification_read_status_stream?: system_notification_read_statusGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (system_notification_read_status_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** An array relationship */
  system_notifications?: system_notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  system_notifications_aggregate?: system_notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** fetch data from the table: "system_notifications" using primary key columns */
  system_notifications_by_pk?: system_notificationsGenqlSelection & {
    __args: { id: Scalars['uuid'] }
  }
  /** fetch data from the table in a streaming manner: "system_notifications" */
  system_notifications_stream?: system_notificationsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (system_notifications_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** fetch data from the table: "system_type_notification" */
  system_type_notification?: system_type_notificationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_type_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_type_notification_order_by[] | null
      /** filter the rows returned */
      where?: system_type_notification_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "system_type_notification" */
  system_type_notification_aggregate?: system_type_notification_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_type_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_type_notification_order_by[] | null
      /** filter the rows returned */
      where?: system_type_notification_bool_exp | null
    }
  }
  /** fetch data from the table: "system_type_notification" using primary key columns */
  system_type_notification_by_pk?: system_type_notificationGenqlSelection & {
    __args: { type: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "system_type_notification" */
  system_type_notification_stream?: system_type_notificationGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (system_type_notification_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: system_type_notification_bool_exp | null
    }
  }
  /** fetch data from the table: "table_rows" */
  table_rows?: table_rowsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: table_rows_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: table_rows_order_by[] | null
      /** filter the rows returned */
      where?: table_rows_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate?: table_rows_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: table_rows_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: table_rows_order_by[] | null
      /** filter the rows returned */
      where?: table_rows_bool_exp | null
    }
  }
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk?: table_rowsGenqlSelection & {
    __args: {
      contract: Scalars['String']
      primary_key: Scalars['String']
      scope: Scalars['String']
      table: Scalars['String']
    }
  }
  /** fetch data from the table in a streaming manner: "table_rows" */
  table_rows_stream?: table_rowsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (table_rows_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: table_rows_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network" */
  trust_network?: trust_networkGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "trust_network" */
  trust_network_aggregate?: trust_network_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network" using primary key columns */
  trust_network_by_pk?: trust_networkGenqlSelection & {
    __args: { account: Scalars['String']; trust: Scalars['String'] }
  }
  /** fetch data from the table: "trust_network_notification" */
  trust_network_notification?: trust_network_notificationGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "trust_network_notification" */
  trust_network_notification_aggregate?: trust_network_notification_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_notification_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_notification_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network_notification" using primary key columns */
  trust_network_notification_by_pk?: trust_network_notificationGenqlSelection & {
    __args: { account: Scalars['String']; trust: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "trust_network_notification" */
  trust_network_notification_stream?: trust_network_notificationGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (trust_network_notification_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: trust_network_notification_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network_status" */
  trust_network_status?: trust_network_statusGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_status_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_status_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "trust_network_status" */
  trust_network_status_aggregate?: trust_network_status_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: trust_network_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: trust_network_status_order_by[] | null
      /** filter the rows returned */
      where?: trust_network_status_bool_exp | null
    }
  }
  /** fetch data from the table: "trust_network_status" using primary key columns */
  trust_network_status_by_pk?: trust_network_statusGenqlSelection & {
    __args: { status: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "trust_network_status" */
  trust_network_status_stream?: trust_network_statusGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (trust_network_status_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: trust_network_status_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "trust_network" */
  trust_network_stream?: trust_networkGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (trust_network_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: trust_network_bool_exp | null
    }
  }
  /** fetch data from the table: "tx_statistics" */
  tx_statistics?: tx_statisticsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: tx_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: tx_statistics_order_by[] | null
      /** filter the rows returned */
      where?: tx_statistics_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "tx_statistics" */
  tx_statistics_aggregate?: tx_statistics_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: tx_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: tx_statistics_order_by[] | null
      /** filter the rows returned */
      where?: tx_statistics_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "tx_statistics" */
  tx_statistics_stream?: tx_statisticsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (tx_statistics_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: tx_statistics_bool_exp | null
    }
  }
  /** fetch data from the table: "volume_statistics" */
  volume_statistics?: volume_statisticsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: volume_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: volume_statistics_order_by[] | null
      /** filter the rows returned */
      where?: volume_statistics_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "volume_statistics" */
  volume_statistics_aggregate?: volume_statistics_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: volume_statistics_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: volume_statistics_order_by[] | null
      /** filter the rows returned */
      where?: volume_statistics_bool_exp | null
    }
  }
  /** fetch data from the table in a streaming manner: "volume_statistics" */
  volume_statistics_stream?: volume_statisticsGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (volume_statistics_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: volume_statistics_bool_exp | null
    }
  }
  /** fetch data from the table: "web_push" */
  web_push?: web_pushGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: web_push_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: web_push_order_by[] | null
      /** filter the rows returned */
      where?: web_push_bool_exp | null
    }
  }
  /** fetch aggregated fields from the table: "web_push" */
  web_push_aggregate?: web_push_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: web_push_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: web_push_order_by[] | null
      /** filter the rows returned */
      where?: web_push_bool_exp | null
    }
  }
  /** fetch data from the table: "web_push" using primary key columns */
  web_push_by_pk?: web_pushGenqlSelection & {
    __args: { auth: Scalars['String']; p256dh: Scalars['String'] }
  }
  /** fetch data from the table in a streaming manner: "web_push" */
  web_push_stream?: web_pushGenqlSelection & {
    __args: {
      /** maximum number of rows returned in a single batch */
      batch_size: Scalars['Int']
      /** cursor to stream the results returned by the query */
      cursor: (web_push_stream_cursor_input | null)[]
      /** filter the rows returned */
      where?: web_push_bool_exp | null
    }
  }
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "support_sessions" */
export interface support_sessionsGenqlSelection {
  assistant?: boolean | number
  closed_by?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  session_close_datetime?: boolean | number
  session_open_datetime?: boolean | number
  status?: boolean | number
  updated_at?: boolean | number
  user?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "support_sessions" */
export interface support_sessions_aggregateGenqlSelection {
  aggregate?: support_sessions_aggregate_fieldsGenqlSelection
  nodes?: support_sessionsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface support_sessions_aggregate_bool_exp {
  count?: support_sessions_aggregate_bool_exp_count | null
}

export interface support_sessions_aggregate_bool_exp_count {
  arguments?: support_sessions_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: support_sessions_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "support_sessions" */
export interface support_sessions_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: support_sessions_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: support_sessions_max_fieldsGenqlSelection
  min?: support_sessions_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "support_sessions" */
export interface support_sessions_aggregate_order_by {
  count?: order_by | null
  max?: support_sessions_max_order_by | null
  min?: support_sessions_min_order_by | null
}

/** input type for inserting array relation for remote table "support_sessions" */
export interface support_sessions_arr_rel_insert_input {
  data: support_sessions_insert_input[]
  /** upsert condition */
  on_conflict?: support_sessions_on_conflict | null
}

/** Boolean expression to filter rows from the table "support_sessions". All fields are combined with a logical 'AND'. */
export interface support_sessions_bool_exp {
  _and?: support_sessions_bool_exp[] | null
  _not?: support_sessions_bool_exp | null
  _or?: support_sessions_bool_exp[] | null
  assistant?: String_comparison_exp | null
  closed_by?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  id?: uuid_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  session_close_datetime?: timestamptz_comparison_exp | null
  session_open_datetime?: timestamptz_comparison_exp | null
  status?: String_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
  user?: String_comparison_exp | null
}

/** input type for inserting data into table "support_sessions" */
export interface support_sessions_insert_input {
  assistant?: Scalars['String'] | null
  closed_by?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  session_close_datetime?: Scalars['timestamptz'] | null
  session_open_datetime?: Scalars['timestamptz'] | null
  status?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
  user?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface support_sessions_max_fieldsGenqlSelection {
  assistant?: boolean | number
  closed_by?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  session_close_datetime?: boolean | number
  session_open_datetime?: boolean | number
  status?: boolean | number
  updated_at?: boolean | number
  user?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "support_sessions" */
export interface support_sessions_max_order_by {
  assistant?: order_by | null
  closed_by?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  session_close_datetime?: order_by | null
  session_open_datetime?: order_by | null
  status?: order_by | null
  updated_at?: order_by | null
  user?: order_by | null
}

/** aggregate min on columns */
export interface support_sessions_min_fieldsGenqlSelection {
  assistant?: boolean | number
  closed_by?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  session_close_datetime?: boolean | number
  session_open_datetime?: boolean | number
  status?: boolean | number
  updated_at?: boolean | number
  user?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "support_sessions" */
export interface support_sessions_min_order_by {
  assistant?: order_by | null
  closed_by?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  session_close_datetime?: order_by | null
  session_open_datetime?: order_by | null
  status?: order_by | null
  updated_at?: order_by | null
  user?: order_by | null
}

/** response of any mutation on the table "support_sessions" */
export interface support_sessions_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: support_sessionsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "support_sessions" */
export interface support_sessions_on_conflict {
  constraint: support_sessions_constraint
  update_columns?: support_sessions_update_column[]
  where?: support_sessions_bool_exp | null
}

/** Ordering options when selecting data from "support_sessions". */
export interface support_sessions_order_by {
  assistant?: order_by | null
  closed_by?: order_by | null
  created_at?: order_by | null
  id?: order_by | null
  reg_account?: reg_accounts_order_by | null
  session_close_datetime?: order_by | null
  session_open_datetime?: order_by | null
  status?: order_by | null
  updated_at?: order_by | null
  user?: order_by | null
}

/** primary key columns input for table: support_sessions */
export interface support_sessions_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "support_sessions" */
export interface support_sessions_set_input {
  assistant?: Scalars['String'] | null
  closed_by?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  session_close_datetime?: Scalars['timestamptz'] | null
  session_open_datetime?: Scalars['timestamptz'] | null
  status?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
  user?: Scalars['String'] | null
}

/** Streaming cursor of the table "support_sessions" */
export interface support_sessions_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: support_sessions_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface support_sessions_stream_cursor_value_input {
  assistant?: Scalars['String'] | null
  closed_by?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  session_close_datetime?: Scalars['timestamptz'] | null
  session_open_datetime?: Scalars['timestamptz'] | null
  status?: Scalars['String'] | null
  updated_at?: Scalars['timestamptz'] | null
  user?: Scalars['String'] | null
}

export interface support_sessions_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: support_sessions_set_input | null
  /** filter the rows which have to be updated */
  where: support_sessions_bool_exp
}

/** columns and relationships of "swap_assets" */
export interface swap_assetsGenqlSelection {
  active_swaps?: boolean | number
  asset?: boolean | number
  asset_name?: boolean | number
  chain?: boolean | number
  wallet_address?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "swap_assets" */
export interface swap_assets_aggregateGenqlSelection {
  aggregate?: swap_assets_aggregate_fieldsGenqlSelection
  nodes?: swap_assetsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "swap_assets" */
export interface swap_assets_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: swap_assets_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: swap_assets_max_fieldsGenqlSelection
  min?: swap_assets_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "swap_assets". All fields are combined with a logical 'AND'. */
export interface swap_assets_bool_exp {
  _and?: swap_assets_bool_exp[] | null
  _not?: swap_assets_bool_exp | null
  _or?: swap_assets_bool_exp[] | null
  active_swaps?: Boolean_comparison_exp | null
  asset?: String_comparison_exp | null
  asset_name?: String_comparison_exp | null
  chain?: String_comparison_exp | null
  wallet_address?: String_comparison_exp | null
}

/** input type for inserting data into table "swap_assets" */
export interface swap_assets_insert_input {
  active_swaps?: Scalars['Boolean'] | null
  asset?: Scalars['String'] | null
  asset_name?: Scalars['String'] | null
  chain?: Scalars['String'] | null
  wallet_address?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface swap_assets_max_fieldsGenqlSelection {
  asset?: boolean | number
  asset_name?: boolean | number
  chain?: boolean | number
  wallet_address?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface swap_assets_min_fieldsGenqlSelection {
  asset?: boolean | number
  asset_name?: boolean | number
  chain?: boolean | number
  wallet_address?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "swap_assets" */
export interface swap_assets_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: swap_assetsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "swap_assets" */
export interface swap_assets_on_conflict {
  constraint: swap_assets_constraint
  update_columns?: swap_assets_update_column[]
  where?: swap_assets_bool_exp | null
}

/** Ordering options when selecting data from "swap_assets". */
export interface swap_assets_order_by {
  active_swaps?: order_by | null
  asset?: order_by | null
  asset_name?: order_by | null
  chain?: order_by | null
  wallet_address?: order_by | null
}

/** primary key columns input for table: swap_assets */
export interface swap_assets_pk_columns_input {
  asset: Scalars['String']
  chain: Scalars['String']
}

/** input type for updating data in table "swap_assets" */
export interface swap_assets_set_input {
  active_swaps?: Scalars['Boolean'] | null
  asset?: Scalars['String'] | null
  asset_name?: Scalars['String'] | null
  chain?: Scalars['String'] | null
  wallet_address?: Scalars['String'] | null
}

/** Streaming cursor of the table "swap_assets" */
export interface swap_assets_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: swap_assets_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface swap_assets_stream_cursor_value_input {
  active_swaps?: Scalars['Boolean'] | null
  asset?: Scalars['String'] | null
  asset_name?: Scalars['String'] | null
  chain?: Scalars['String'] | null
  wallet_address?: Scalars['String'] | null
}

export interface swap_assets_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: swap_assets_set_input | null
  /** filter the rows which have to be updated */
  where: swap_assets_bool_exp
}

/** columns and relationships of "swap_orders" */
export interface swap_ordersGenqlSelection {
  asset?: boolean | number
  asset_amount?: boolean | number
  bitcash_account?: boolean | number
  bitcash_amount?: boolean | number
  bitcash_currency?: boolean | number
  bitcash_trx?: boolean | number
  created_at?: boolean | number
  gems_id?: boolean | number
  id?: boolean | number
  /** An array relationship */
  messages?: messagesGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  /** An aggregate relationship */
  messages_aggregate?: messages_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: messages_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: messages_order_by[] | null
      /** filter the rows returned */
      where?: messages_bool_exp | null
    }
  }
  order_status?: boolean | number
  order_type?: boolean | number
  price?: boolean | number
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  updated_at?: boolean | number
  wallet_address?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "swap_orders" */
export interface swap_orders_aggregateGenqlSelection {
  aggregate?: swap_orders_aggregate_fieldsGenqlSelection
  nodes?: swap_ordersGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface swap_orders_aggregate_bool_exp {
  avg?: swap_orders_aggregate_bool_exp_avg | null
  corr?: swap_orders_aggregate_bool_exp_corr | null
  count?: swap_orders_aggregate_bool_exp_count | null
  covar_samp?: swap_orders_aggregate_bool_exp_covar_samp | null
  max?: swap_orders_aggregate_bool_exp_max | null
  min?: swap_orders_aggregate_bool_exp_min | null
  stddev_samp?: swap_orders_aggregate_bool_exp_stddev_samp | null
  sum?: swap_orders_aggregate_bool_exp_sum | null
  var_samp?: swap_orders_aggregate_bool_exp_var_samp | null
}

export interface swap_orders_aggregate_bool_exp_avg {
  arguments: swap_orders_select_column_swap_orders_aggregate_bool_exp_avg_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_corr {
  arguments: swap_orders_aggregate_bool_exp_corr_arguments
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_corr_arguments {
  X: swap_orders_select_column_swap_orders_aggregate_bool_exp_corr_arguments_columns
  Y: swap_orders_select_column_swap_orders_aggregate_bool_exp_corr_arguments_columns
}

export interface swap_orders_aggregate_bool_exp_count {
  arguments?: swap_orders_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: Int_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_covar_samp {
  arguments: swap_orders_aggregate_bool_exp_covar_samp_arguments
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_covar_samp_arguments {
  X: swap_orders_select_column_swap_orders_aggregate_bool_exp_covar_samp_arguments_columns
  Y: swap_orders_select_column_swap_orders_aggregate_bool_exp_covar_samp_arguments_columns
}

export interface swap_orders_aggregate_bool_exp_max {
  arguments: swap_orders_select_column_swap_orders_aggregate_bool_exp_max_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_min {
  arguments: swap_orders_select_column_swap_orders_aggregate_bool_exp_min_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_stddev_samp {
  arguments: swap_orders_select_column_swap_orders_aggregate_bool_exp_stddev_samp_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_sum {
  arguments: swap_orders_select_column_swap_orders_aggregate_bool_exp_sum_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

export interface swap_orders_aggregate_bool_exp_var_samp {
  arguments: swap_orders_select_column_swap_orders_aggregate_bool_exp_var_samp_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: swap_orders_bool_exp | null
  predicate: float8_comparison_exp
}

/** aggregate fields of "swap_orders" */
export interface swap_orders_aggregate_fieldsGenqlSelection {
  avg?: swap_orders_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: swap_orders_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: swap_orders_max_fieldsGenqlSelection
  min?: swap_orders_min_fieldsGenqlSelection
  stddev?: swap_orders_stddev_fieldsGenqlSelection
  stddev_pop?: swap_orders_stddev_pop_fieldsGenqlSelection
  stddev_samp?: swap_orders_stddev_samp_fieldsGenqlSelection
  sum?: swap_orders_sum_fieldsGenqlSelection
  var_pop?: swap_orders_var_pop_fieldsGenqlSelection
  var_samp?: swap_orders_var_samp_fieldsGenqlSelection
  variance?: swap_orders_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "swap_orders" */
export interface swap_orders_aggregate_order_by {
  avg?: swap_orders_avg_order_by | null
  count?: order_by | null
  max?: swap_orders_max_order_by | null
  min?: swap_orders_min_order_by | null
  stddev?: swap_orders_stddev_order_by | null
  stddev_pop?: swap_orders_stddev_pop_order_by | null
  stddev_samp?: swap_orders_stddev_samp_order_by | null
  sum?: swap_orders_sum_order_by | null
  var_pop?: swap_orders_var_pop_order_by | null
  var_samp?: swap_orders_var_samp_order_by | null
  variance?: swap_orders_variance_order_by | null
}

/** input type for inserting array relation for remote table "swap_orders" */
export interface swap_orders_arr_rel_insert_input {
  data: swap_orders_insert_input[]
  /** upsert condition */
  on_conflict?: swap_orders_on_conflict | null
}

/** aggregate avg on columns */
export interface swap_orders_avg_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by avg() on columns of table "swap_orders" */
export interface swap_orders_avg_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** Boolean expression to filter rows from the table "swap_orders". All fields are combined with a logical 'AND'. */
export interface swap_orders_bool_exp {
  _and?: swap_orders_bool_exp[] | null
  _not?: swap_orders_bool_exp | null
  _or?: swap_orders_bool_exp[] | null
  asset?: String_comparison_exp | null
  asset_amount?: float8_comparison_exp | null
  bitcash_account?: String_comparison_exp | null
  bitcash_amount?: float8_comparison_exp | null
  bitcash_currency?: String_comparison_exp | null
  bitcash_trx?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  gems_id?: String_comparison_exp | null
  id?: uuid_comparison_exp | null
  messages?: messages_bool_exp | null
  messages_aggregate?: messages_aggregate_bool_exp | null
  order_status?: String_comparison_exp | null
  order_type?: String_comparison_exp | null
  price?: float8_comparison_exp | null
  reg_account?: reg_accounts_bool_exp | null
  updated_at?: timestamptz_comparison_exp | null
  wallet_address?: String_comparison_exp | null
}

/** input type for incrementing numeric columns in table "swap_orders" */
export interface swap_orders_inc_input {
  asset_amount?: Scalars['float8'] | null
  bitcash_amount?: Scalars['float8'] | null
  price?: Scalars['float8'] | null
}

/** input type for inserting data into table "swap_orders" */
export interface swap_orders_insert_input {
  asset?: Scalars['String'] | null
  asset_amount?: Scalars['float8'] | null
  bitcash_account?: Scalars['String'] | null
  bitcash_amount?: Scalars['float8'] | null
  bitcash_currency?: Scalars['String'] | null
  bitcash_trx?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  gems_id?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  messages?: messages_arr_rel_insert_input | null
  order_status?: Scalars['String'] | null
  order_type?: Scalars['String'] | null
  price?: Scalars['float8'] | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  updated_at?: Scalars['timestamptz'] | null
  wallet_address?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface swap_orders_max_fieldsGenqlSelection {
  asset?: boolean | number
  asset_amount?: boolean | number
  bitcash_account?: boolean | number
  bitcash_amount?: boolean | number
  bitcash_currency?: boolean | number
  bitcash_trx?: boolean | number
  created_at?: boolean | number
  gems_id?: boolean | number
  id?: boolean | number
  order_status?: boolean | number
  order_type?: boolean | number
  price?: boolean | number
  updated_at?: boolean | number
  wallet_address?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "swap_orders" */
export interface swap_orders_max_order_by {
  asset?: order_by | null
  asset_amount?: order_by | null
  bitcash_account?: order_by | null
  bitcash_amount?: order_by | null
  bitcash_currency?: order_by | null
  bitcash_trx?: order_by | null
  created_at?: order_by | null
  gems_id?: order_by | null
  id?: order_by | null
  order_status?: order_by | null
  order_type?: order_by | null
  price?: order_by | null
  updated_at?: order_by | null
  wallet_address?: order_by | null
}

/** aggregate min on columns */
export interface swap_orders_min_fieldsGenqlSelection {
  asset?: boolean | number
  asset_amount?: boolean | number
  bitcash_account?: boolean | number
  bitcash_amount?: boolean | number
  bitcash_currency?: boolean | number
  bitcash_trx?: boolean | number
  created_at?: boolean | number
  gems_id?: boolean | number
  id?: boolean | number
  order_status?: boolean | number
  order_type?: boolean | number
  price?: boolean | number
  updated_at?: boolean | number
  wallet_address?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "swap_orders" */
export interface swap_orders_min_order_by {
  asset?: order_by | null
  asset_amount?: order_by | null
  bitcash_account?: order_by | null
  bitcash_amount?: order_by | null
  bitcash_currency?: order_by | null
  bitcash_trx?: order_by | null
  created_at?: order_by | null
  gems_id?: order_by | null
  id?: order_by | null
  order_status?: order_by | null
  order_type?: order_by | null
  price?: order_by | null
  updated_at?: order_by | null
  wallet_address?: order_by | null
}

/** response of any mutation on the table "swap_orders" */
export interface swap_orders_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: swap_ordersGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "swap_orders" */
export interface swap_orders_obj_rel_insert_input {
  data: swap_orders_insert_input
  /** upsert condition */
  on_conflict?: swap_orders_on_conflict | null
}

/** on_conflict condition type for table "swap_orders" */
export interface swap_orders_on_conflict {
  constraint: swap_orders_constraint
  update_columns?: swap_orders_update_column[]
  where?: swap_orders_bool_exp | null
}

/** Ordering options when selecting data from "swap_orders". */
export interface swap_orders_order_by {
  asset?: order_by | null
  asset_amount?: order_by | null
  bitcash_account?: order_by | null
  bitcash_amount?: order_by | null
  bitcash_currency?: order_by | null
  bitcash_trx?: order_by | null
  created_at?: order_by | null
  gems_id?: order_by | null
  id?: order_by | null
  messages_aggregate?: messages_aggregate_order_by | null
  order_status?: order_by | null
  order_type?: order_by | null
  price?: order_by | null
  reg_account?: reg_accounts_order_by | null
  updated_at?: order_by | null
  wallet_address?: order_by | null
}

/** primary key columns input for table: swap_orders */
export interface swap_orders_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "swap_orders" */
export interface swap_orders_set_input {
  asset?: Scalars['String'] | null
  asset_amount?: Scalars['float8'] | null
  bitcash_account?: Scalars['String'] | null
  bitcash_amount?: Scalars['float8'] | null
  bitcash_currency?: Scalars['String'] | null
  bitcash_trx?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  gems_id?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  order_status?: Scalars['String'] | null
  order_type?: Scalars['String'] | null
  price?: Scalars['float8'] | null
  updated_at?: Scalars['timestamptz'] | null
  wallet_address?: Scalars['String'] | null
}

/** aggregate stddev on columns */
export interface swap_orders_stddev_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by stddev() on columns of table "swap_orders" */
export interface swap_orders_stddev_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** aggregate stddev_pop on columns */
export interface swap_orders_stddev_pop_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by stddev_pop() on columns of table "swap_orders" */
export interface swap_orders_stddev_pop_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** aggregate stddev_samp on columns */
export interface swap_orders_stddev_samp_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by stddev_samp() on columns of table "swap_orders" */
export interface swap_orders_stddev_samp_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** Streaming cursor of the table "swap_orders" */
export interface swap_orders_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: swap_orders_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface swap_orders_stream_cursor_value_input {
  asset?: Scalars['String'] | null
  asset_amount?: Scalars['float8'] | null
  bitcash_account?: Scalars['String'] | null
  bitcash_amount?: Scalars['float8'] | null
  bitcash_currency?: Scalars['String'] | null
  bitcash_trx?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  gems_id?: Scalars['String'] | null
  id?: Scalars['uuid'] | null
  order_status?: Scalars['String'] | null
  order_type?: Scalars['String'] | null
  price?: Scalars['float8'] | null
  updated_at?: Scalars['timestamptz'] | null
  wallet_address?: Scalars['String'] | null
}

/** aggregate sum on columns */
export interface swap_orders_sum_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by sum() on columns of table "swap_orders" */
export interface swap_orders_sum_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

export interface swap_orders_updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: swap_orders_inc_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: swap_orders_set_input | null
  /** filter the rows which have to be updated */
  where: swap_orders_bool_exp
}

/** aggregate var_pop on columns */
export interface swap_orders_var_pop_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by var_pop() on columns of table "swap_orders" */
export interface swap_orders_var_pop_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** aggregate var_samp on columns */
export interface swap_orders_var_samp_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by var_samp() on columns of table "swap_orders" */
export interface swap_orders_var_samp_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** aggregate variance on columns */
export interface swap_orders_variance_fieldsGenqlSelection {
  asset_amount?: boolean | number
  bitcash_amount?: boolean | number
  price?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by variance() on columns of table "swap_orders" */
export interface swap_orders_variance_order_by {
  asset_amount?: order_by | null
  bitcash_amount?: order_by | null
  price?: order_by | null
}

/** columns and relationships of "system_notification_read_status" */
export interface system_notification_read_statusGenqlSelection {
  created_at?: boolean | number
  notification_id?: boolean | number
  /** An object relationship */
  regAccountByRegAccount?: reg_accountsGenqlSelection
  reg_account?: boolean | number
  /** An object relationship */
  system_notification?: system_notificationsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "system_notification_read_status" */
export interface system_notification_read_status_aggregateGenqlSelection {
  aggregate?: system_notification_read_status_aggregate_fieldsGenqlSelection
  nodes?: system_notification_read_statusGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface system_notification_read_status_aggregate_bool_exp {
  count?: system_notification_read_status_aggregate_bool_exp_count | null
}

export interface system_notification_read_status_aggregate_bool_exp_count {
  arguments?: system_notification_read_status_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: system_notification_read_status_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "system_notification_read_status" */
export interface system_notification_read_status_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: system_notification_read_status_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: system_notification_read_status_max_fieldsGenqlSelection
  min?: system_notification_read_status_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "system_notification_read_status" */
export interface system_notification_read_status_aggregate_order_by {
  count?: order_by | null
  max?: system_notification_read_status_max_order_by | null
  min?: system_notification_read_status_min_order_by | null
}

/** input type for inserting array relation for remote table "system_notification_read_status" */
export interface system_notification_read_status_arr_rel_insert_input {
  data: system_notification_read_status_insert_input[]
  /** upsert condition */
  on_conflict?: system_notification_read_status_on_conflict | null
}

/** Boolean expression to filter rows from the table "system_notification_read_status". All fields are combined with a logical 'AND'. */
export interface system_notification_read_status_bool_exp {
  _and?: system_notification_read_status_bool_exp[] | null
  _not?: system_notification_read_status_bool_exp | null
  _or?: system_notification_read_status_bool_exp[] | null
  created_at?: timestamptz_comparison_exp | null
  notification_id?: uuid_comparison_exp | null
  regAccountByRegAccount?: reg_accounts_bool_exp | null
  reg_account?: uuid_comparison_exp | null
  system_notification?: system_notifications_bool_exp | null
}

/** input type for inserting data into table "system_notification_read_status" */
export interface system_notification_read_status_insert_input {
  created_at?: Scalars['timestamptz'] | null
  notification_id?: Scalars['uuid'] | null
  regAccountByRegAccount?: reg_accounts_obj_rel_insert_input | null
  reg_account?: Scalars['uuid'] | null
  system_notification?: system_notifications_obj_rel_insert_input | null
}

/** aggregate max on columns */
export interface system_notification_read_status_max_fieldsGenqlSelection {
  created_at?: boolean | number
  notification_id?: boolean | number
  reg_account?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "system_notification_read_status" */
export interface system_notification_read_status_max_order_by {
  created_at?: order_by | null
  notification_id?: order_by | null
  reg_account?: order_by | null
}

/** aggregate min on columns */
export interface system_notification_read_status_min_fieldsGenqlSelection {
  created_at?: boolean | number
  notification_id?: boolean | number
  reg_account?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "system_notification_read_status" */
export interface system_notification_read_status_min_order_by {
  created_at?: order_by | null
  notification_id?: order_by | null
  reg_account?: order_by | null
}

/** response of any mutation on the table "system_notification_read_status" */
export interface system_notification_read_status_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: system_notification_read_statusGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "system_notification_read_status" */
export interface system_notification_read_status_on_conflict {
  constraint: system_notification_read_status_constraint
  update_columns?: system_notification_read_status_update_column[]
  where?: system_notification_read_status_bool_exp | null
}

/** Ordering options when selecting data from "system_notification_read_status". */
export interface system_notification_read_status_order_by {
  created_at?: order_by | null
  notification_id?: order_by | null
  regAccountByRegAccount?: reg_accounts_order_by | null
  reg_account?: order_by | null
  system_notification?: system_notifications_order_by | null
}

/** primary key columns input for table: system_notification_read_status */
export interface system_notification_read_status_pk_columns_input {
  notification_id: Scalars['uuid']
  reg_account: Scalars['uuid']
}

/** input type for updating data in table "system_notification_read_status" */
export interface system_notification_read_status_set_input {
  created_at?: Scalars['timestamptz'] | null
  notification_id?: Scalars['uuid'] | null
  reg_account?: Scalars['uuid'] | null
}

/** Streaming cursor of the table "system_notification_read_status" */
export interface system_notification_read_status_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: system_notification_read_status_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface system_notification_read_status_stream_cursor_value_input {
  created_at?: Scalars['timestamptz'] | null
  notification_id?: Scalars['uuid'] | null
  reg_account?: Scalars['uuid'] | null
}

export interface system_notification_read_status_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: system_notification_read_status_set_input | null
  /** filter the rows which have to be updated */
  where: system_notification_read_status_bool_exp
}

/** columns and relationships of "system_notifications" */
export interface system_notificationsGenqlSelection {
  created_at?: boolean | number
  id?: boolean | number
  message?: boolean | number
  offer_id?: boolean | number
  /** An object relationship */
  p2p_offer?: p2p_offersGenqlSelection
  /** An array relationship */
  system_notification_read_statuses?: system_notification_read_statusGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** An aggregate relationship */
  system_notification_read_statuses_aggregate?: system_notification_read_status_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notification_read_status_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notification_read_status_order_by[] | null
      /** filter the rows returned */
      where?: system_notification_read_status_bool_exp | null
    }
  }
  /** An object relationship */
  system_type_notification?: system_type_notificationGenqlSelection
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "system_notifications" */
export interface system_notifications_aggregateGenqlSelection {
  aggregate?: system_notifications_aggregate_fieldsGenqlSelection
  nodes?: system_notificationsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface system_notifications_aggregate_bool_exp {
  count?: system_notifications_aggregate_bool_exp_count | null
}

export interface system_notifications_aggregate_bool_exp_count {
  arguments?: system_notifications_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: system_notifications_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "system_notifications" */
export interface system_notifications_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: system_notifications_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: system_notifications_max_fieldsGenqlSelection
  min?: system_notifications_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "system_notifications" */
export interface system_notifications_aggregate_order_by {
  count?: order_by | null
  max?: system_notifications_max_order_by | null
  min?: system_notifications_min_order_by | null
}

/** input type for inserting array relation for remote table "system_notifications" */
export interface system_notifications_arr_rel_insert_input {
  data: system_notifications_insert_input[]
  /** upsert condition */
  on_conflict?: system_notifications_on_conflict | null
}

/** Boolean expression to filter rows from the table "system_notifications". All fields are combined with a logical 'AND'. */
export interface system_notifications_bool_exp {
  _and?: system_notifications_bool_exp[] | null
  _not?: system_notifications_bool_exp | null
  _or?: system_notifications_bool_exp[] | null
  created_at?: timestamptz_comparison_exp | null
  id?: uuid_comparison_exp | null
  message?: String_comparison_exp | null
  offer_id?: uuid_comparison_exp | null
  p2p_offer?: p2p_offers_bool_exp | null
  system_notification_read_statuses?: system_notification_read_status_bool_exp | null
  system_notification_read_statuses_aggregate?: system_notification_read_status_aggregate_bool_exp | null
  system_type_notification?: system_type_notification_bool_exp | null
  type?: system_type_notification_enum_comparison_exp | null
  updated_at?: timestamptz_comparison_exp | null
}

/** input type for inserting data into table "system_notifications" */
export interface system_notifications_insert_input {
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  message?: Scalars['String'] | null
  offer_id?: Scalars['uuid'] | null
  p2p_offer?: p2p_offers_obj_rel_insert_input | null
  system_notification_read_statuses?: system_notification_read_status_arr_rel_insert_input | null
  system_type_notification?: system_type_notification_obj_rel_insert_input | null
  type?: system_type_notification_enum | null
  updated_at?: Scalars['timestamptz'] | null
}

/** aggregate max on columns */
export interface system_notifications_max_fieldsGenqlSelection {
  created_at?: boolean | number
  id?: boolean | number
  message?: boolean | number
  offer_id?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "system_notifications" */
export interface system_notifications_max_order_by {
  created_at?: order_by | null
  id?: order_by | null
  message?: order_by | null
  offer_id?: order_by | null
  updated_at?: order_by | null
}

/** aggregate min on columns */
export interface system_notifications_min_fieldsGenqlSelection {
  created_at?: boolean | number
  id?: boolean | number
  message?: boolean | number
  offer_id?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "system_notifications" */
export interface system_notifications_min_order_by {
  created_at?: order_by | null
  id?: order_by | null
  message?: order_by | null
  offer_id?: order_by | null
  updated_at?: order_by | null
}

/** response of any mutation on the table "system_notifications" */
export interface system_notifications_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: system_notificationsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "system_notifications" */
export interface system_notifications_obj_rel_insert_input {
  data: system_notifications_insert_input
  /** upsert condition */
  on_conflict?: system_notifications_on_conflict | null
}

/** on_conflict condition type for table "system_notifications" */
export interface system_notifications_on_conflict {
  constraint: system_notifications_constraint
  update_columns?: system_notifications_update_column[]
  where?: system_notifications_bool_exp | null
}

/** Ordering options when selecting data from "system_notifications". */
export interface system_notifications_order_by {
  created_at?: order_by | null
  id?: order_by | null
  message?: order_by | null
  offer_id?: order_by | null
  p2p_offer?: p2p_offers_order_by | null
  system_notification_read_statuses_aggregate?: system_notification_read_status_aggregate_order_by | null
  system_type_notification?: system_type_notification_order_by | null
  type?: order_by | null
  updated_at?: order_by | null
}

/** primary key columns input for table: system_notifications */
export interface system_notifications_pk_columns_input {
  id: Scalars['uuid']
}

/** input type for updating data in table "system_notifications" */
export interface system_notifications_set_input {
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  message?: Scalars['String'] | null
  offer_id?: Scalars['uuid'] | null
  type?: system_type_notification_enum | null
  updated_at?: Scalars['timestamptz'] | null
}

/** Streaming cursor of the table "system_notifications" */
export interface system_notifications_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: system_notifications_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface system_notifications_stream_cursor_value_input {
  created_at?: Scalars['timestamptz'] | null
  id?: Scalars['uuid'] | null
  message?: Scalars['String'] | null
  offer_id?: Scalars['uuid'] | null
  type?: system_type_notification_enum | null
  updated_at?: Scalars['timestamptz'] | null
}

export interface system_notifications_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: system_notifications_set_input | null
  /** filter the rows which have to be updated */
  where: system_notifications_bool_exp
}

/** columns and relationships of "system_type_notification" */
export interface system_type_notificationGenqlSelection {
  /** An array relationship */
  system_notifications?: system_notificationsGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  /** An aggregate relationship */
  system_notifications_aggregate?: system_notifications_aggregateGenqlSelection & {
    __args?: {
      /** distinct select on columns */
      distinct_on?: system_notifications_select_column[] | null
      /** limit the number of rows returned */
      limit?: Scalars['Int'] | null
      /** skip the first n rows. Use only with order_by */
      offset?: Scalars['Int'] | null
      /** sort the rows by one or more columns */
      order_by?: system_notifications_order_by[] | null
      /** filter the rows returned */
      where?: system_notifications_bool_exp | null
    }
  }
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "system_type_notification" */
export interface system_type_notification_aggregateGenqlSelection {
  aggregate?: system_type_notification_aggregate_fieldsGenqlSelection
  nodes?: system_type_notificationGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "system_type_notification" */
export interface system_type_notification_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: system_type_notification_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: system_type_notification_max_fieldsGenqlSelection
  min?: system_type_notification_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "system_type_notification". All fields are combined with a logical 'AND'. */
export interface system_type_notification_bool_exp {
  _and?: system_type_notification_bool_exp[] | null
  _not?: system_type_notification_bool_exp | null
  _or?: system_type_notification_bool_exp[] | null
  system_notifications?: system_notifications_bool_exp | null
  system_notifications_aggregate?: system_notifications_aggregate_bool_exp | null
  type?: String_comparison_exp | null
}

/** Boolean expression to compare columns of type "system_type_notification_enum". All fields are combined with logical 'AND'. */
export interface system_type_notification_enum_comparison_exp {
  _eq?: system_type_notification_enum | null
  _in?: system_type_notification_enum[] | null
  _is_null?: Scalars['Boolean'] | null
  _neq?: system_type_notification_enum | null
  _nin?: system_type_notification_enum[] | null
}

/** input type for inserting data into table "system_type_notification" */
export interface system_type_notification_insert_input {
  system_notifications?: system_notifications_arr_rel_insert_input | null
  type?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface system_type_notification_max_fieldsGenqlSelection {
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface system_type_notification_min_fieldsGenqlSelection {
  type?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "system_type_notification" */
export interface system_type_notification_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: system_type_notificationGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** input type for inserting object relation for remote table "system_type_notification" */
export interface system_type_notification_obj_rel_insert_input {
  data: system_type_notification_insert_input
  /** upsert condition */
  on_conflict?: system_type_notification_on_conflict | null
}

/** on_conflict condition type for table "system_type_notification" */
export interface system_type_notification_on_conflict {
  constraint: system_type_notification_constraint
  update_columns?: system_type_notification_update_column[]
  where?: system_type_notification_bool_exp | null
}

/** Ordering options when selecting data from "system_type_notification". */
export interface system_type_notification_order_by {
  system_notifications_aggregate?: system_notifications_aggregate_order_by | null
  type?: order_by | null
}

/** primary key columns input for table: system_type_notification */
export interface system_type_notification_pk_columns_input {
  type: Scalars['String']
}

/** input type for updating data in table "system_type_notification" */
export interface system_type_notification_set_input {
  type?: Scalars['String'] | null
}

/** Streaming cursor of the table "system_type_notification" */
export interface system_type_notification_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: system_type_notification_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface system_type_notification_stream_cursor_value_input {
  type?: Scalars['String'] | null
}

export interface system_type_notification_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: system_type_notification_set_input | null
  /** filter the rows which have to be updated */
  where: system_type_notification_bool_exp
}

/** columns and relationships of "table_rows" */
export interface table_rowsGenqlSelection {
  contract?: boolean | number
  data?:
    | {
        __args: {
          /** JSON select path */
          path?: Scalars['String'] | null
        }
      }
    | boolean
    | number
  primary_key?: boolean | number
  scope?: boolean | number
  table?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "table_rows" */
export interface table_rows_aggregateGenqlSelection {
  aggregate?: table_rows_aggregate_fieldsGenqlSelection
  nodes?: table_rowsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "table_rows" */
export interface table_rows_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: table_rows_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: table_rows_max_fieldsGenqlSelection
  min?: table_rows_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** append existing jsonb value of filtered columns with new jsonb value */
export interface table_rows_append_input {
  data?: Scalars['jsonb'] | null
}

/** Boolean expression to filter rows from the table "table_rows". All fields are combined with a logical 'AND'. */
export interface table_rows_bool_exp {
  _and?: table_rows_bool_exp[] | null
  _not?: table_rows_bool_exp | null
  _or?: table_rows_bool_exp[] | null
  contract?: String_comparison_exp | null
  data?: jsonb_comparison_exp | null
  primary_key?: String_comparison_exp | null
  scope?: String_comparison_exp | null
  table?: String_comparison_exp | null
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export interface table_rows_delete_at_path_input {
  data?: Scalars['String'][] | null
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export interface table_rows_delete_elem_input {
  data?: Scalars['Int'] | null
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export interface table_rows_delete_key_input {
  data?: Scalars['String'] | null
}

/** input type for inserting data into table "table_rows" */
export interface table_rows_insert_input {
  contract?: Scalars['String'] | null
  data?: Scalars['jsonb'] | null
  primary_key?: Scalars['String'] | null
  scope?: Scalars['String'] | null
  table?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface table_rows_max_fieldsGenqlSelection {
  contract?: boolean | number
  primary_key?: boolean | number
  scope?: boolean | number
  table?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface table_rows_min_fieldsGenqlSelection {
  contract?: boolean | number
  primary_key?: boolean | number
  scope?: boolean | number
  table?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "table_rows" */
export interface table_rows_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: table_rowsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "table_rows" */
export interface table_rows_on_conflict {
  constraint: table_rows_constraint
  update_columns?: table_rows_update_column[]
  where?: table_rows_bool_exp | null
}

/** Ordering options when selecting data from "table_rows". */
export interface table_rows_order_by {
  contract?: order_by | null
  data?: order_by | null
  primary_key?: order_by | null
  scope?: order_by | null
  table?: order_by | null
}

/** primary key columns input for table: table_rows */
export interface table_rows_pk_columns_input {
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export interface table_rows_prepend_input {
  data?: Scalars['jsonb'] | null
}

/** input type for updating data in table "table_rows" */
export interface table_rows_set_input {
  contract?: Scalars['String'] | null
  data?: Scalars['jsonb'] | null
  primary_key?: Scalars['String'] | null
  scope?: Scalars['String'] | null
  table?: Scalars['String'] | null
}

/** Streaming cursor of the table "table_rows" */
export interface table_rows_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: table_rows_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface table_rows_stream_cursor_value_input {
  contract?: Scalars['String'] | null
  data?: Scalars['jsonb'] | null
  primary_key?: Scalars['String'] | null
  scope?: Scalars['String'] | null
  table?: Scalars['String'] | null
}

export interface table_rows_updates {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: table_rows_append_input | null
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: table_rows_delete_at_path_input | null
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: table_rows_delete_elem_input | null
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: table_rows_delete_key_input | null
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: table_rows_prepend_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: table_rows_set_input | null
  /** filter the rows which have to be updated */
  where: table_rows_bool_exp
}

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export interface timestamp_comparison_exp {
  _eq?: Scalars['timestamp'] | null
  _gt?: Scalars['timestamp'] | null
  _gte?: Scalars['timestamp'] | null
  _in?: Scalars['timestamp'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['timestamp'] | null
  _lte?: Scalars['timestamp'] | null
  _neq?: Scalars['timestamp'] | null
  _nin?: Scalars['timestamp'][] | null
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export interface timestamptz_comparison_exp {
  _eq?: Scalars['timestamptz'] | null
  _gt?: Scalars['timestamptz'] | null
  _gte?: Scalars['timestamptz'] | null
  _in?: Scalars['timestamptz'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['timestamptz'] | null
  _lte?: Scalars['timestamptz'] | null
  _neq?: Scalars['timestamptz'] | null
  _nin?: Scalars['timestamptz'][] | null
}

export interface toggle_trust_network_input {
  account: Scalars['String']
  reject?: Scalars['Boolean'] | null
}

export interface toggle_trust_network_outputGenqlSelection {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "trust_network" */
export interface trust_networkGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  is_mutual?: boolean | number
  trust?: boolean | number
  /** An object relationship */
  trust_by?: reg_accountsGenqlSelection
  /** An object relationship */
  trust_network?: reg_accountsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "trust_network" */
export interface trust_network_aggregateGenqlSelection {
  aggregate?: trust_network_aggregate_fieldsGenqlSelection
  nodes?: trust_networkGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface trust_network_aggregate_bool_exp {
  bool_and?: trust_network_aggregate_bool_exp_bool_and | null
  bool_or?: trust_network_aggregate_bool_exp_bool_or | null
  count?: trust_network_aggregate_bool_exp_count | null
}

export interface trust_network_aggregate_bool_exp_bool_and {
  arguments: trust_network_select_column_trust_network_aggregate_bool_exp_bool_and_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: trust_network_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface trust_network_aggregate_bool_exp_bool_or {
  arguments: trust_network_select_column_trust_network_aggregate_bool_exp_bool_or_arguments_columns
  distinct?: Scalars['Boolean'] | null
  filter?: trust_network_bool_exp | null
  predicate: Boolean_comparison_exp
}

export interface trust_network_aggregate_bool_exp_count {
  arguments?: trust_network_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: trust_network_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "trust_network" */
export interface trust_network_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: trust_network_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: trust_network_max_fieldsGenqlSelection
  min?: trust_network_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "trust_network" */
export interface trust_network_aggregate_order_by {
  count?: order_by | null
  max?: trust_network_max_order_by | null
  min?: trust_network_min_order_by | null
}

/** input type for inserting array relation for remote table "trust_network" */
export interface trust_network_arr_rel_insert_input {
  data: trust_network_insert_input[]
  /** upsert condition */
  on_conflict?: trust_network_on_conflict | null
}

/** Boolean expression to filter rows from the table "trust_network". All fields are combined with a logical 'AND'. */
export interface trust_network_bool_exp {
  _and?: trust_network_bool_exp[] | null
  _not?: trust_network_bool_exp | null
  _or?: trust_network_bool_exp[] | null
  account?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  is_mutual?: Boolean_comparison_exp | null
  trust?: String_comparison_exp | null
  trust_by?: reg_accounts_bool_exp | null
  trust_network?: reg_accounts_bool_exp | null
}

/** input type for inserting data into table "trust_network" */
export interface trust_network_insert_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  is_mutual?: Scalars['Boolean'] | null
  trust?: Scalars['String'] | null
  trust_by?: reg_accounts_obj_rel_insert_input | null
  trust_network?: reg_accounts_obj_rel_insert_input | null
}

/** aggregate max on columns */
export interface trust_network_max_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  trust?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "trust_network" */
export interface trust_network_max_order_by {
  account?: order_by | null
  created_at?: order_by | null
  trust?: order_by | null
}

/** aggregate min on columns */
export interface trust_network_min_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  trust?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "trust_network" */
export interface trust_network_min_order_by {
  account?: order_by | null
  created_at?: order_by | null
  trust?: order_by | null
}

/** response of any mutation on the table "trust_network" */
export interface trust_network_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: trust_networkGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "trust_network_notification" */
export interface trust_network_notificationGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  /** An object relationship */
  regAccountByTrust?: reg_accountsGenqlSelection
  /** An object relationship */
  reg_account?: reg_accountsGenqlSelection
  trust?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "trust_network_notification" */
export interface trust_network_notification_aggregateGenqlSelection {
  aggregate?: trust_network_notification_aggregate_fieldsGenqlSelection
  nodes?: trust_network_notificationGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface trust_network_notification_aggregate_bool_exp {
  count?: trust_network_notification_aggregate_bool_exp_count | null
}

export interface trust_network_notification_aggregate_bool_exp_count {
  arguments?: trust_network_notification_select_column[] | null
  distinct?: Scalars['Boolean'] | null
  filter?: trust_network_notification_bool_exp | null
  predicate: Int_comparison_exp
}

/** aggregate fields of "trust_network_notification" */
export interface trust_network_notification_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: trust_network_notification_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: trust_network_notification_max_fieldsGenqlSelection
  min?: trust_network_notification_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by aggregate values of table "trust_network_notification" */
export interface trust_network_notification_aggregate_order_by {
  count?: order_by | null
  max?: trust_network_notification_max_order_by | null
  min?: trust_network_notification_min_order_by | null
}

/** input type for inserting array relation for remote table "trust_network_notification" */
export interface trust_network_notification_arr_rel_insert_input {
  data: trust_network_notification_insert_input[]
  /** upsert condition */
  on_conflict?: trust_network_notification_on_conflict | null
}

/** Boolean expression to filter rows from the table "trust_network_notification". All fields are combined with a logical 'AND'. */
export interface trust_network_notification_bool_exp {
  _and?: trust_network_notification_bool_exp[] | null
  _not?: trust_network_notification_bool_exp | null
  _or?: trust_network_notification_bool_exp[] | null
  account?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  regAccountByTrust?: reg_accounts_bool_exp | null
  reg_account?: reg_accounts_bool_exp | null
  trust?: String_comparison_exp | null
}

/** input type for inserting data into table "trust_network_notification" */
export interface trust_network_notification_insert_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  regAccountByTrust?: reg_accounts_obj_rel_insert_input | null
  reg_account?: reg_accounts_obj_rel_insert_input | null
  trust?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface trust_network_notification_max_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  trust?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by max() on columns of table "trust_network_notification" */
export interface trust_network_notification_max_order_by {
  account?: order_by | null
  created_at?: order_by | null
  trust?: order_by | null
}

/** aggregate min on columns */
export interface trust_network_notification_min_fieldsGenqlSelection {
  account?: boolean | number
  created_at?: boolean | number
  trust?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** order by min() on columns of table "trust_network_notification" */
export interface trust_network_notification_min_order_by {
  account?: order_by | null
  created_at?: order_by | null
  trust?: order_by | null
}

/** response of any mutation on the table "trust_network_notification" */
export interface trust_network_notification_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: trust_network_notificationGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "trust_network_notification" */
export interface trust_network_notification_on_conflict {
  constraint: trust_network_notification_constraint
  update_columns?: trust_network_notification_update_column[]
  where?: trust_network_notification_bool_exp | null
}

/** Ordering options when selecting data from "trust_network_notification". */
export interface trust_network_notification_order_by {
  account?: order_by | null
  created_at?: order_by | null
  regAccountByTrust?: reg_accounts_order_by | null
  reg_account?: reg_accounts_order_by | null
  trust?: order_by | null
}

/** primary key columns input for table: trust_network_notification */
export interface trust_network_notification_pk_columns_input {
  account: Scalars['String']
  trust: Scalars['String']
}

/** input type for updating data in table "trust_network_notification" */
export interface trust_network_notification_set_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  trust?: Scalars['String'] | null
}

/** Streaming cursor of the table "trust_network_notification" */
export interface trust_network_notification_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: trust_network_notification_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface trust_network_notification_stream_cursor_value_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  trust?: Scalars['String'] | null
}

export interface trust_network_notification_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: trust_network_notification_set_input | null
  /** filter the rows which have to be updated */
  where: trust_network_notification_bool_exp
}

/** on_conflict condition type for table "trust_network" */
export interface trust_network_on_conflict {
  constraint: trust_network_constraint
  update_columns?: trust_network_update_column[]
  where?: trust_network_bool_exp | null
}

/** Ordering options when selecting data from "trust_network". */
export interface trust_network_order_by {
  account?: order_by | null
  created_at?: order_by | null
  is_mutual?: order_by | null
  trust?: order_by | null
  trust_by?: reg_accounts_order_by | null
  trust_network?: reg_accounts_order_by | null
}

export interface trust_network_outputGenqlSelection {
  trusted_network?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** primary key columns input for table: trust_network */
export interface trust_network_pk_columns_input {
  account: Scalars['String']
  trust: Scalars['String']
}

/** input type for updating data in table "trust_network" */
export interface trust_network_set_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  is_mutual?: Scalars['Boolean'] | null
  trust?: Scalars['String'] | null
}

/** columns and relationships of "trust_network_status" */
export interface trust_network_statusGenqlSelection {
  status?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "trust_network_status" */
export interface trust_network_status_aggregateGenqlSelection {
  aggregate?: trust_network_status_aggregate_fieldsGenqlSelection
  nodes?: trust_network_statusGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "trust_network_status" */
export interface trust_network_status_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: {
          columns?: trust_network_status_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: trust_network_status_max_fieldsGenqlSelection
  min?: trust_network_status_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "trust_network_status". All fields are combined with a logical 'AND'. */
export interface trust_network_status_bool_exp {
  _and?: trust_network_status_bool_exp[] | null
  _not?: trust_network_status_bool_exp | null
  _or?: trust_network_status_bool_exp[] | null
  status?: String_comparison_exp | null
}

/** input type for inserting data into table "trust_network_status" */
export interface trust_network_status_insert_input {
  status?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface trust_network_status_max_fieldsGenqlSelection {
  status?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface trust_network_status_min_fieldsGenqlSelection {
  status?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "trust_network_status" */
export interface trust_network_status_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: trust_network_statusGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "trust_network_status" */
export interface trust_network_status_on_conflict {
  constraint: trust_network_status_constraint
  update_columns?: trust_network_status_update_column[]
  where?: trust_network_status_bool_exp | null
}

/** Ordering options when selecting data from "trust_network_status". */
export interface trust_network_status_order_by {
  status?: order_by | null
}

/** primary key columns input for table: trust_network_status */
export interface trust_network_status_pk_columns_input {
  status: Scalars['String']
}

/** input type for updating data in table "trust_network_status" */
export interface trust_network_status_set_input {
  status?: Scalars['String'] | null
}

/** Streaming cursor of the table "trust_network_status" */
export interface trust_network_status_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: trust_network_status_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface trust_network_status_stream_cursor_value_input {
  status?: Scalars['String'] | null
}

export interface trust_network_status_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: trust_network_status_set_input | null
  /** filter the rows which have to be updated */
  where: trust_network_status_bool_exp
}

/** Streaming cursor of the table "trust_network" */
export interface trust_network_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: trust_network_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface trust_network_stream_cursor_value_input {
  account?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  is_mutual?: Scalars['Boolean'] | null
  trust?: Scalars['String'] | null
}

export interface trust_network_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: trust_network_set_input | null
  /** filter the rows which have to be updated */
  where: trust_network_bool_exp
}

/** columns and relationships of "tx_statistics" */
export interface tx_statisticsGenqlSelection {
  currency?: boolean | number
  date?: boolean | number
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "tx_statistics" */
export interface tx_statistics_aggregateGenqlSelection {
  aggregate?: tx_statistics_aggregate_fieldsGenqlSelection
  nodes?: tx_statisticsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "tx_statistics" */
export interface tx_statistics_aggregate_fieldsGenqlSelection {
  avg?: tx_statistics_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: tx_statistics_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: tx_statistics_max_fieldsGenqlSelection
  min?: tx_statistics_min_fieldsGenqlSelection
  stddev?: tx_statistics_stddev_fieldsGenqlSelection
  stddev_pop?: tx_statistics_stddev_pop_fieldsGenqlSelection
  stddev_samp?: tx_statistics_stddev_samp_fieldsGenqlSelection
  sum?: tx_statistics_sum_fieldsGenqlSelection
  var_pop?: tx_statistics_var_pop_fieldsGenqlSelection
  var_samp?: tx_statistics_var_samp_fieldsGenqlSelection
  variance?: tx_statistics_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface tx_statistics_avg_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "tx_statistics". All fields are combined with a logical 'AND'. */
export interface tx_statistics_bool_exp {
  _and?: tx_statistics_bool_exp[] | null
  _not?: tx_statistics_bool_exp | null
  _or?: tx_statistics_bool_exp[] | null
  currency?: String_comparison_exp | null
  date?: timestamptz_comparison_exp | null
  total_transactions?: bigint_comparison_exp | null
  total_transactions_cancelled?: bigint_comparison_exp | null
  total_transactions_completed?: bigint_comparison_exp | null
}

/** input type for incrementing numeric columns in table "tx_statistics" */
export interface tx_statistics_inc_input {
  total_transactions?: Scalars['bigint'] | null
  total_transactions_cancelled?: Scalars['bigint'] | null
  total_transactions_completed?: Scalars['bigint'] | null
}

/** input type for inserting data into table "tx_statistics" */
export interface tx_statistics_insert_input {
  currency?: Scalars['String'] | null
  date?: Scalars['timestamptz'] | null
  total_transactions?: Scalars['bigint'] | null
  total_transactions_cancelled?: Scalars['bigint'] | null
  total_transactions_completed?: Scalars['bigint'] | null
}

/** aggregate max on columns */
export interface tx_statistics_max_fieldsGenqlSelection {
  currency?: boolean | number
  date?: boolean | number
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface tx_statistics_min_fieldsGenqlSelection {
  currency?: boolean | number
  date?: boolean | number
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "tx_statistics" */
export interface tx_statistics_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: tx_statisticsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "tx_statistics". */
export interface tx_statistics_order_by {
  currency?: order_by | null
  date?: order_by | null
  total_transactions?: order_by | null
  total_transactions_cancelled?: order_by | null
  total_transactions_completed?: order_by | null
}

/** input type for updating data in table "tx_statistics" */
export interface tx_statistics_set_input {
  currency?: Scalars['String'] | null
  date?: Scalars['timestamptz'] | null
  total_transactions?: Scalars['bigint'] | null
  total_transactions_cancelled?: Scalars['bigint'] | null
  total_transactions_completed?: Scalars['bigint'] | null
}

/** aggregate stddev on columns */
export interface tx_statistics_stddev_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface tx_statistics_stddev_pop_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface tx_statistics_stddev_samp_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "tx_statistics" */
export interface tx_statistics_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: tx_statistics_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface tx_statistics_stream_cursor_value_input {
  currency?: Scalars['String'] | null
  date?: Scalars['timestamptz'] | null
  total_transactions?: Scalars['bigint'] | null
  total_transactions_cancelled?: Scalars['bigint'] | null
  total_transactions_completed?: Scalars['bigint'] | null
}

/** aggregate sum on columns */
export interface tx_statistics_sum_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface tx_statistics_updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: tx_statistics_inc_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: tx_statistics_set_input | null
  /** filter the rows which have to be updated */
  where: tx_statistics_bool_exp
}

/** aggregate var_pop on columns */
export interface tx_statistics_var_pop_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface tx_statistics_var_samp_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface tx_statistics_variance_fieldsGenqlSelection {
  total_transactions?: boolean | number
  total_transactions_cancelled?: boolean | number
  total_transactions_completed?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface upload_image_outputGenqlSelection {
  success?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export interface uuid_comparison_exp {
  _eq?: Scalars['uuid'] | null
  _gt?: Scalars['uuid'] | null
  _gte?: Scalars['uuid'] | null
  _in?: Scalars['uuid'][] | null
  _is_null?: Scalars['Boolean'] | null
  _lt?: Scalars['uuid'] | null
  _lte?: Scalars['uuid'] | null
  _neq?: Scalars['uuid'] | null
  _nin?: Scalars['uuid'][] | null
}

export interface validate_short_link_input {
  link: Scalars['String']
}

export interface validate_short_link_outputGenqlSelection {
  count?: boolean | number
  referrer?: boolean | number
  website?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "volume_statistics" */
export interface volume_statisticsGenqlSelection {
  currency?: boolean | number
  date?: boolean | number
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "volume_statistics" */
export interface volume_statistics_aggregateGenqlSelection {
  aggregate?: volume_statistics_aggregate_fieldsGenqlSelection
  nodes?: volume_statisticsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "volume_statistics" */
export interface volume_statistics_aggregate_fieldsGenqlSelection {
  avg?: volume_statistics_avg_fieldsGenqlSelection
  count?:
    | {
        __args: {
          columns?: volume_statistics_select_column[] | null
          distinct?: Scalars['Boolean'] | null
        }
      }
    | boolean
    | number
  max?: volume_statistics_max_fieldsGenqlSelection
  min?: volume_statistics_min_fieldsGenqlSelection
  stddev?: volume_statistics_stddev_fieldsGenqlSelection
  stddev_pop?: volume_statistics_stddev_pop_fieldsGenqlSelection
  stddev_samp?: volume_statistics_stddev_samp_fieldsGenqlSelection
  sum?: volume_statistics_sum_fieldsGenqlSelection
  var_pop?: volume_statistics_var_pop_fieldsGenqlSelection
  var_samp?: volume_statistics_var_samp_fieldsGenqlSelection
  variance?: volume_statistics_variance_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate avg on columns */
export interface volume_statistics_avg_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "volume_statistics". All fields are combined with a logical 'AND'. */
export interface volume_statistics_bool_exp {
  _and?: volume_statistics_bool_exp[] | null
  _not?: volume_statistics_bool_exp | null
  _or?: volume_statistics_bool_exp[] | null
  currency?: String_comparison_exp | null
  date?: timestamptz_comparison_exp | null
  total_cancelled?: numeric_comparison_exp | null
  total_completed?: numeric_comparison_exp | null
  total_completed_not_cancelled?: numeric_comparison_exp | null
}

/** input type for incrementing numeric columns in table "volume_statistics" */
export interface volume_statistics_inc_input {
  total_cancelled?: Scalars['numeric'] | null
  total_completed?: Scalars['numeric'] | null
  total_completed_not_cancelled?: Scalars['numeric'] | null
}

/** input type for inserting data into table "volume_statistics" */
export interface volume_statistics_insert_input {
  currency?: Scalars['String'] | null
  date?: Scalars['timestamptz'] | null
  total_cancelled?: Scalars['numeric'] | null
  total_completed?: Scalars['numeric'] | null
  total_completed_not_cancelled?: Scalars['numeric'] | null
}

/** aggregate max on columns */
export interface volume_statistics_max_fieldsGenqlSelection {
  currency?: boolean | number
  date?: boolean | number
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface volume_statistics_min_fieldsGenqlSelection {
  currency?: boolean | number
  date?: boolean | number
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "volume_statistics" */
export interface volume_statistics_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: volume_statisticsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Ordering options when selecting data from "volume_statistics". */
export interface volume_statistics_order_by {
  currency?: order_by | null
  date?: order_by | null
  total_cancelled?: order_by | null
  total_completed?: order_by | null
  total_completed_not_cancelled?: order_by | null
}

/** input type for updating data in table "volume_statistics" */
export interface volume_statistics_set_input {
  currency?: Scalars['String'] | null
  date?: Scalars['timestamptz'] | null
  total_cancelled?: Scalars['numeric'] | null
  total_completed?: Scalars['numeric'] | null
  total_completed_not_cancelled?: Scalars['numeric'] | null
}

/** aggregate stddev on columns */
export interface volume_statistics_stddev_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_pop on columns */
export interface volume_statistics_stddev_pop_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate stddev_samp on columns */
export interface volume_statistics_stddev_samp_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Streaming cursor of the table "volume_statistics" */
export interface volume_statistics_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: volume_statistics_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface volume_statistics_stream_cursor_value_input {
  currency?: Scalars['String'] | null
  date?: Scalars['timestamptz'] | null
  total_cancelled?: Scalars['numeric'] | null
  total_completed?: Scalars['numeric'] | null
  total_completed_not_cancelled?: Scalars['numeric'] | null
}

/** aggregate sum on columns */
export interface volume_statistics_sum_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface volume_statistics_updates {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: volume_statistics_inc_input | null
  /** sets the columns of the filtered rows to the given values */
  _set?: volume_statistics_set_input | null
  /** filter the rows which have to be updated */
  where: volume_statistics_bool_exp
}

/** aggregate var_pop on columns */
export interface volume_statistics_var_pop_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate var_samp on columns */
export interface volume_statistics_var_samp_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate variance on columns */
export interface volume_statistics_variance_fieldsGenqlSelection {
  total_cancelled?: boolean | number
  total_completed?: boolean | number
  total_completed_not_cancelled?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

export interface wallet_request_outputGenqlSelection {
  amount?: boolean | number
  bitcashbank_id?: boolean | number
  buyer?: boolean | number
  buyer_confirmed_payment?: boolean | number
  buyer_method_details?: boolean | number
  cancel_approval_datetime?: boolean | number
  cancel_request_datetime?: boolean | number
  cancellation_approved_by?: boolean | number
  cancellation_transaction?: boolean | number
  cancelled?: boolean | number
  cancelled_by?: boolean | number
  completed?: boolean | number
  created_at?: boolean | number
  id?: boolean | number
  initiator?: boolean | number
  matched?: boolean | number
  matched_datetime?: boolean | number
  method?: boolean | number
  region?: boolean | number
  sell_put_transaction?: boolean | number
  sell_settlement_transaction?: boolean | number
  seller?: boolean | number
  seller_confirmed_payment?: boolean | number
  seller_method_details?: boolean | number
  type?: boolean | number
  updated_at?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** columns and relationships of "web_push" */
export interface web_pushGenqlSelection {
  account?: boolean | number
  auth?: boolean | number
  created_at?: boolean | number
  endpoint?: boolean | number
  expiration_time?: boolean | number
  p256dh?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregated selection of "web_push" */
export interface web_push_aggregateGenqlSelection {
  aggregate?: web_push_aggregate_fieldsGenqlSelection
  nodes?: web_pushGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate fields of "web_push" */
export interface web_push_aggregate_fieldsGenqlSelection {
  count?:
    | {
        __args: { columns?: web_push_select_column[] | null; distinct?: Scalars['Boolean'] | null }
      }
    | boolean
    | number
  max?: web_push_max_fieldsGenqlSelection
  min?: web_push_min_fieldsGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** Boolean expression to filter rows from the table "web_push". All fields are combined with a logical 'AND'. */
export interface web_push_bool_exp {
  _and?: web_push_bool_exp[] | null
  _not?: web_push_bool_exp | null
  _or?: web_push_bool_exp[] | null
  account?: String_comparison_exp | null
  auth?: String_comparison_exp | null
  created_at?: timestamptz_comparison_exp | null
  endpoint?: String_comparison_exp | null
  expiration_time?: timestamptz_comparison_exp | null
  p256dh?: String_comparison_exp | null
}

/** input type for inserting data into table "web_push" */
export interface web_push_insert_input {
  account?: Scalars['String'] | null
  auth?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  endpoint?: Scalars['String'] | null
  expiration_time?: Scalars['timestamptz'] | null
  p256dh?: Scalars['String'] | null
}

/** aggregate max on columns */
export interface web_push_max_fieldsGenqlSelection {
  account?: boolean | number
  auth?: boolean | number
  created_at?: boolean | number
  endpoint?: boolean | number
  expiration_time?: boolean | number
  p256dh?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** aggregate min on columns */
export interface web_push_min_fieldsGenqlSelection {
  account?: boolean | number
  auth?: boolean | number
  created_at?: boolean | number
  endpoint?: boolean | number
  expiration_time?: boolean | number
  p256dh?: boolean | number
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** response of any mutation on the table "web_push" */
export interface web_push_mutation_responseGenqlSelection {
  /** number of rows affected by the mutation */
  affected_rows?: boolean | number
  /** data from the rows affected by the mutation */
  returning?: web_pushGenqlSelection
  __typename?: boolean | number
  __scalar?: boolean | number
}

/** on_conflict condition type for table "web_push" */
export interface web_push_on_conflict {
  constraint: web_push_constraint
  update_columns?: web_push_update_column[]
  where?: web_push_bool_exp | null
}

/** Ordering options when selecting data from "web_push". */
export interface web_push_order_by {
  account?: order_by | null
  auth?: order_by | null
  created_at?: order_by | null
  endpoint?: order_by | null
  expiration_time?: order_by | null
  p256dh?: order_by | null
}

/** primary key columns input for table: web_push */
export interface web_push_pk_columns_input {
  auth: Scalars['String']
  p256dh: Scalars['String']
}

/** input type for updating data in table "web_push" */
export interface web_push_set_input {
  account?: Scalars['String'] | null
  auth?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  endpoint?: Scalars['String'] | null
  expiration_time?: Scalars['timestamptz'] | null
  p256dh?: Scalars['String'] | null
}

/** Streaming cursor of the table "web_push" */
export interface web_push_stream_cursor_input {
  /** Stream column input with initial value */
  initial_value: web_push_stream_cursor_value_input
  /** cursor ordering */
  ordering?: cursor_ordering | null
}

/** Initial value of the column from where the streaming should start */
export interface web_push_stream_cursor_value_input {
  account?: Scalars['String'] | null
  auth?: Scalars['String'] | null
  created_at?: Scalars['timestamptz'] | null
  endpoint?: Scalars['String'] | null
  expiration_time?: Scalars['timestamptz'] | null
  p256dh?: Scalars['String'] | null
}

export interface web_push_updates {
  /** sets the columns of the filtered rows to the given values */
  _set?: web_push_set_input | null
  /** filter the rows which have to be updated */
  where: web_push_bool_exp
}

export type QueryGenqlSelection = query_rootGenqlSelection
export type MutationGenqlSelection = mutation_rootGenqlSelection
export type SubscriptionGenqlSelection = subscription_rootGenqlSelection

const account_statistics_possibleTypes: string[] = ['account_statistics']
export const isaccount_statistics = (
  obj?: { __typename?: any } | null,
): obj is account_statistics => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccount_statistics"')
  return account_statistics_possibleTypes.includes(obj.__typename)
}

const account_statistics_aggregate_possibleTypes: string[] = ['account_statistics_aggregate']
export const isaccount_statistics_aggregate = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccount_statistics_aggregate"')
  return account_statistics_aggregate_possibleTypes.includes(obj.__typename)
}

const account_statistics_aggregate_fields_possibleTypes: string[] = [
  'account_statistics_aggregate_fields',
]
export const isaccount_statistics_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_aggregate_fields"')
  return account_statistics_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_avg_fields_possibleTypes: string[] = ['account_statistics_avg_fields']
export const isaccount_statistics_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_avg_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_avg_fields"')
  return account_statistics_avg_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_max_fields_possibleTypes: string[] = ['account_statistics_max_fields']
export const isaccount_statistics_max_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_max_fields"')
  return account_statistics_max_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_min_fields_possibleTypes: string[] = ['account_statistics_min_fields']
export const isaccount_statistics_min_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_min_fields"')
  return account_statistics_min_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_stddev_fields_possibleTypes: string[] = [
  'account_statistics_stddev_fields',
]
export const isaccount_statistics_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_stddev_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_stddev_fields"')
  return account_statistics_stddev_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_stddev_pop_fields_possibleTypes: string[] = [
  'account_statistics_stddev_pop_fields',
]
export const isaccount_statistics_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_stddev_pop_fields"')
  return account_statistics_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_stddev_samp_fields_possibleTypes: string[] = [
  'account_statistics_stddev_samp_fields',
]
export const isaccount_statistics_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_stddev_samp_fields"')
  return account_statistics_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_sum_fields_possibleTypes: string[] = ['account_statistics_sum_fields']
export const isaccount_statistics_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_sum_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_sum_fields"')
  return account_statistics_sum_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_var_pop_fields_possibleTypes: string[] = [
  'account_statistics_var_pop_fields',
]
export const isaccount_statistics_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_var_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_var_pop_fields"')
  return account_statistics_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_var_samp_fields_possibleTypes: string[] = [
  'account_statistics_var_samp_fields',
]
export const isaccount_statistics_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_var_samp_fields"')
  return account_statistics_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const account_statistics_variance_fields_possibleTypes: string[] = [
  'account_statistics_variance_fields',
]
export const isaccount_statistics_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is account_statistics_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccount_statistics_variance_fields"')
  return account_statistics_variance_fields_possibleTypes.includes(obj.__typename)
}

const accounts_information_possibleTypes: string[] = ['accounts_information']
export const isaccounts_information = (
  obj?: { __typename?: any } | null,
): obj is accounts_information => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_information"')
  return accounts_information_possibleTypes.includes(obj.__typename)
}

const accounts_information_aggregate_possibleTypes: string[] = ['accounts_information_aggregate']
export const isaccounts_information_aggregate = (
  obj?: { __typename?: any } | null,
): obj is accounts_information_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_information_aggregate"')
  return accounts_information_aggregate_possibleTypes.includes(obj.__typename)
}

const accounts_information_aggregate_fields_possibleTypes: string[] = [
  'accounts_information_aggregate_fields',
]
export const isaccounts_information_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_information_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_information_aggregate_fields"')
  return accounts_information_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const accounts_information_max_fields_possibleTypes: string[] = ['accounts_information_max_fields']
export const isaccounts_information_max_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_information_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_information_max_fields"')
  return accounts_information_max_fields_possibleTypes.includes(obj.__typename)
}

const accounts_information_min_fields_possibleTypes: string[] = ['accounts_information_min_fields']
export const isaccounts_information_min_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_information_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_information_min_fields"')
  return accounts_information_min_fields_possibleTypes.includes(obj.__typename)
}

const accounts_information_mutation_response_possibleTypes: string[] = [
  'accounts_information_mutation_response',
]
export const isaccounts_information_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is accounts_information_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_information_mutation_response"')
  return accounts_information_mutation_response_possibleTypes.includes(obj.__typename)
}

const accounts_referral_possibleTypes: string[] = ['accounts_referral']
export const isaccounts_referral = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_referral"')
  return accounts_referral_possibleTypes.includes(obj.__typename)
}

const accounts_referral_aggregate_possibleTypes: string[] = ['accounts_referral_aggregate']
export const isaccounts_referral_aggregate = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_referral_aggregate"')
  return accounts_referral_aggregate_possibleTypes.includes(obj.__typename)
}

const accounts_referral_aggregate_fields_possibleTypes: string[] = [
  'accounts_referral_aggregate_fields',
]
export const isaccounts_referral_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_aggregate_fields"')
  return accounts_referral_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_avg_fields_possibleTypes: string[] = ['accounts_referral_avg_fields']
export const isaccounts_referral_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_referral_avg_fields"')
  return accounts_referral_avg_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_max_fields_possibleTypes: string[] = ['accounts_referral_max_fields']
export const isaccounts_referral_max_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_referral_max_fields"')
  return accounts_referral_max_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_min_fields_possibleTypes: string[] = ['accounts_referral_min_fields']
export const isaccounts_referral_min_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_referral_min_fields"')
  return accounts_referral_min_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_mutation_response_possibleTypes: string[] = [
  'accounts_referral_mutation_response',
]
export const isaccounts_referral_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_mutation_response"')
  return accounts_referral_mutation_response_possibleTypes.includes(obj.__typename)
}

const accounts_referral_stddev_fields_possibleTypes: string[] = ['accounts_referral_stddev_fields']
export const isaccounts_referral_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_stddev_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_stddev_fields"')
  return accounts_referral_stddev_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_stddev_pop_fields_possibleTypes: string[] = [
  'accounts_referral_stddev_pop_fields',
]
export const isaccounts_referral_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_stddev_pop_fields"')
  return accounts_referral_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_stddev_samp_fields_possibleTypes: string[] = [
  'accounts_referral_stddev_samp_fields',
]
export const isaccounts_referral_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_stddev_samp_fields"')
  return accounts_referral_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_sum_fields_possibleTypes: string[] = ['accounts_referral_sum_fields']
export const isaccounts_referral_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isaccounts_referral_sum_fields"')
  return accounts_referral_sum_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_var_pop_fields_possibleTypes: string[] = [
  'accounts_referral_var_pop_fields',
]
export const isaccounts_referral_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_var_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_var_pop_fields"')
  return accounts_referral_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_var_samp_fields_possibleTypes: string[] = [
  'accounts_referral_var_samp_fields',
]
export const isaccounts_referral_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_var_samp_fields"')
  return accounts_referral_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const accounts_referral_variance_fields_possibleTypes: string[] = [
  'accounts_referral_variance_fields',
]
export const isaccounts_referral_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is accounts_referral_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isaccounts_referral_variance_fields"')
  return accounts_referral_variance_fields_possibleTypes.includes(obj.__typename)
}

const actions_possibleTypes: string[] = ['actions']
export const isactions = (obj?: { __typename?: any } | null): obj is actions => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isactions"')
  return actions_possibleTypes.includes(obj.__typename)
}

const actions_aggregate_possibleTypes: string[] = ['actions_aggregate']
export const isactions_aggregate = (
  obj?: { __typename?: any } | null,
): obj is actions_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isactions_aggregate"')
  return actions_aggregate_possibleTypes.includes(obj.__typename)
}

const actions_aggregate_fields_possibleTypes: string[] = ['actions_aggregate_fields']
export const isactions_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is actions_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isactions_aggregate_fields"')
  return actions_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const actions_max_fields_possibleTypes: string[] = ['actions_max_fields']
export const isactions_max_fields = (
  obj?: { __typename?: any } | null,
): obj is actions_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isactions_max_fields"')
  return actions_max_fields_possibleTypes.includes(obj.__typename)
}

const actions_min_fields_possibleTypes: string[] = ['actions_min_fields']
export const isactions_min_fields = (
  obj?: { __typename?: any } | null,
): obj is actions_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isactions_min_fields"')
  return actions_min_fields_possibleTypes.includes(obj.__typename)
}

const actions_mutation_response_possibleTypes: string[] = ['actions_mutation_response']
export const isactions_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is actions_mutation_response => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isactions_mutation_response"')
  return actions_mutation_response_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_possibleTypes: string[] = ['avg_pay_time']
export const isavg_pay_time = (obj?: { __typename?: any } | null): obj is avg_pay_time => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time"')
  return avg_pay_time_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_aggregate_possibleTypes: string[] = ['avg_pay_time_aggregate']
export const isavg_pay_time_aggregate = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_aggregate"')
  return avg_pay_time_aggregate_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_aggregate_fields_possibleTypes: string[] = ['avg_pay_time_aggregate_fields']
export const isavg_pay_time_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_pay_time_aggregate_fields"')
  return avg_pay_time_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_avg_fields_possibleTypes: string[] = ['avg_pay_time_avg_fields']
export const isavg_pay_time_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_avg_fields"')
  return avg_pay_time_avg_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_max_fields_possibleTypes: string[] = ['avg_pay_time_max_fields']
export const isavg_pay_time_max_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_max_fields"')
  return avg_pay_time_max_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_min_fields_possibleTypes: string[] = ['avg_pay_time_min_fields']
export const isavg_pay_time_min_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_min_fields"')
  return avg_pay_time_min_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_stddev_fields_possibleTypes: string[] = ['avg_pay_time_stddev_fields']
export const isavg_pay_time_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_stddev_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_stddev_fields"')
  return avg_pay_time_stddev_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_stddev_pop_fields_possibleTypes: string[] = ['avg_pay_time_stddev_pop_fields']
export const isavg_pay_time_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_pay_time_stddev_pop_fields"')
  return avg_pay_time_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_stddev_samp_fields_possibleTypes: string[] = ['avg_pay_time_stddev_samp_fields']
export const isavg_pay_time_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_pay_time_stddev_samp_fields"')
  return avg_pay_time_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_sum_fields_possibleTypes: string[] = ['avg_pay_time_sum_fields']
export const isavg_pay_time_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_sum_fields"')
  return avg_pay_time_sum_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_var_pop_fields_possibleTypes: string[] = ['avg_pay_time_var_pop_fields']
export const isavg_pay_time_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_var_pop_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_var_pop_fields"')
  return avg_pay_time_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_var_samp_fields_possibleTypes: string[] = ['avg_pay_time_var_samp_fields']
export const isavg_pay_time_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_var_samp_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_var_samp_fields"')
  return avg_pay_time_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const avg_pay_time_variance_fields_possibleTypes: string[] = ['avg_pay_time_variance_fields']
export const isavg_pay_time_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_pay_time_variance_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_pay_time_variance_fields"')
  return avg_pay_time_variance_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_possibleTypes: string[] = ['avg_release_time']
export const isavg_release_time = (obj?: { __typename?: any } | null): obj is avg_release_time => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_release_time"')
  return avg_release_time_possibleTypes.includes(obj.__typename)
}

const avg_release_time_aggregate_possibleTypes: string[] = ['avg_release_time_aggregate']
export const isavg_release_time_aggregate = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_release_time_aggregate"')
  return avg_release_time_aggregate_possibleTypes.includes(obj.__typename)
}

const avg_release_time_aggregate_fields_possibleTypes: string[] = [
  'avg_release_time_aggregate_fields',
]
export const isavg_release_time_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_aggregate_fields"')
  return avg_release_time_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_avg_fields_possibleTypes: string[] = ['avg_release_time_avg_fields']
export const isavg_release_time_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_release_time_avg_fields"')
  return avg_release_time_avg_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_max_fields_possibleTypes: string[] = ['avg_release_time_max_fields']
export const isavg_release_time_max_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_release_time_max_fields"')
  return avg_release_time_max_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_min_fields_possibleTypes: string[] = ['avg_release_time_min_fields']
export const isavg_release_time_min_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_release_time_min_fields"')
  return avg_release_time_min_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_stddev_fields_possibleTypes: string[] = ['avg_release_time_stddev_fields']
export const isavg_release_time_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_stddev_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_stddev_fields"')
  return avg_release_time_stddev_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_stddev_pop_fields_possibleTypes: string[] = [
  'avg_release_time_stddev_pop_fields',
]
export const isavg_release_time_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_stddev_pop_fields"')
  return avg_release_time_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_stddev_samp_fields_possibleTypes: string[] = [
  'avg_release_time_stddev_samp_fields',
]
export const isavg_release_time_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_stddev_samp_fields"')
  return avg_release_time_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_sum_fields_possibleTypes: string[] = ['avg_release_time_sum_fields']
export const isavg_release_time_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isavg_release_time_sum_fields"')
  return avg_release_time_sum_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_var_pop_fields_possibleTypes: string[] = ['avg_release_time_var_pop_fields']
export const isavg_release_time_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_var_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_var_pop_fields"')
  return avg_release_time_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_var_samp_fields_possibleTypes: string[] = [
  'avg_release_time_var_samp_fields',
]
export const isavg_release_time_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_var_samp_fields"')
  return avg_release_time_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const avg_release_time_variance_fields_possibleTypes: string[] = [
  'avg_release_time_variance_fields',
]
export const isavg_release_time_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is avg_release_time_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isavg_release_time_variance_fields"')
  return avg_release_time_variance_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_possibleTypes: string[] = ['combined_avg_times']
export const iscombined_avg_times = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times => {
  if (!obj?.__typename) throw new Error('__typename is missing in "iscombined_avg_times"')
  return combined_avg_times_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_aggregate_possibleTypes: string[] = ['combined_avg_times_aggregate']
export const iscombined_avg_times_aggregate = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "iscombined_avg_times_aggregate"')
  return combined_avg_times_aggregate_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_aggregate_fields_possibleTypes: string[] = [
  'combined_avg_times_aggregate_fields',
]
export const iscombined_avg_times_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_aggregate_fields"')
  return combined_avg_times_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_avg_fields_possibleTypes: string[] = ['combined_avg_times_avg_fields']
export const iscombined_avg_times_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_avg_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_avg_fields"')
  return combined_avg_times_avg_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_max_fields_possibleTypes: string[] = ['combined_avg_times_max_fields']
export const iscombined_avg_times_max_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_max_fields"')
  return combined_avg_times_max_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_min_fields_possibleTypes: string[] = ['combined_avg_times_min_fields']
export const iscombined_avg_times_min_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_min_fields"')
  return combined_avg_times_min_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_stddev_fields_possibleTypes: string[] = [
  'combined_avg_times_stddev_fields',
]
export const iscombined_avg_times_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_stddev_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_stddev_fields"')
  return combined_avg_times_stddev_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_stddev_pop_fields_possibleTypes: string[] = [
  'combined_avg_times_stddev_pop_fields',
]
export const iscombined_avg_times_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_stddev_pop_fields"')
  return combined_avg_times_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_stddev_samp_fields_possibleTypes: string[] = [
  'combined_avg_times_stddev_samp_fields',
]
export const iscombined_avg_times_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_stddev_samp_fields"')
  return combined_avg_times_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_sum_fields_possibleTypes: string[] = ['combined_avg_times_sum_fields']
export const iscombined_avg_times_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_sum_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_sum_fields"')
  return combined_avg_times_sum_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_var_pop_fields_possibleTypes: string[] = [
  'combined_avg_times_var_pop_fields',
]
export const iscombined_avg_times_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_var_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_var_pop_fields"')
  return combined_avg_times_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_var_samp_fields_possibleTypes: string[] = [
  'combined_avg_times_var_samp_fields',
]
export const iscombined_avg_times_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_var_samp_fields"')
  return combined_avg_times_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const combined_avg_times_variance_fields_possibleTypes: string[] = [
  'combined_avg_times_variance_fields',
]
export const iscombined_avg_times_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is combined_avg_times_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "iscombined_avg_times_variance_fields"')
  return combined_avg_times_variance_fields_possibleTypes.includes(obj.__typename)
}

const devices_possibleTypes: string[] = ['devices']
export const isdevices = (obj?: { __typename?: any } | null): obj is devices => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isdevices"')
  return devices_possibleTypes.includes(obj.__typename)
}

const devices_aggregate_possibleTypes: string[] = ['devices_aggregate']
export const isdevices_aggregate = (
  obj?: { __typename?: any } | null,
): obj is devices_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isdevices_aggregate"')
  return devices_aggregate_possibleTypes.includes(obj.__typename)
}

const devices_aggregate_fields_possibleTypes: string[] = ['devices_aggregate_fields']
export const isdevices_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is devices_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isdevices_aggregate_fields"')
  return devices_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const devices_max_fields_possibleTypes: string[] = ['devices_max_fields']
export const isdevices_max_fields = (
  obj?: { __typename?: any } | null,
): obj is devices_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isdevices_max_fields"')
  return devices_max_fields_possibleTypes.includes(obj.__typename)
}

const devices_min_fields_possibleTypes: string[] = ['devices_min_fields']
export const isdevices_min_fields = (
  obj?: { __typename?: any } | null,
): obj is devices_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isdevices_min_fields"')
  return devices_min_fields_possibleTypes.includes(obj.__typename)
}

const devices_mutation_response_possibleTypes: string[] = ['devices_mutation_response']
export const isdevices_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is devices_mutation_response => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isdevices_mutation_response"')
  return devices_mutation_response_possibleTypes.includes(obj.__typename)
}

const id_verification_output_possibleTypes: string[] = ['id_verification_output']
export const isid_verification_output = (
  obj?: { __typename?: any } | null,
): obj is id_verification_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isid_verification_output"')
  return id_verification_output_possibleTypes.includes(obj.__typename)
}

const login_account_possibleTypes: string[] = ['login_account']
export const islogin_account = (obj?: { __typename?: any } | null): obj is login_account => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_account"')
  return login_account_possibleTypes.includes(obj.__typename)
}

const login_account_aggregate_possibleTypes: string[] = ['login_account_aggregate']
export const islogin_account_aggregate = (
  obj?: { __typename?: any } | null,
): obj is login_account_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_account_aggregate"')
  return login_account_aggregate_possibleTypes.includes(obj.__typename)
}

const login_account_aggregate_fields_possibleTypes: string[] = ['login_account_aggregate_fields']
export const islogin_account_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is login_account_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "islogin_account_aggregate_fields"')
  return login_account_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const login_account_max_fields_possibleTypes: string[] = ['login_account_max_fields']
export const islogin_account_max_fields = (
  obj?: { __typename?: any } | null,
): obj is login_account_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_account_max_fields"')
  return login_account_max_fields_possibleTypes.includes(obj.__typename)
}

const login_account_min_fields_possibleTypes: string[] = ['login_account_min_fields']
export const islogin_account_min_fields = (
  obj?: { __typename?: any } | null,
): obj is login_account_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_account_min_fields"')
  return login_account_min_fields_possibleTypes.includes(obj.__typename)
}

const login_account_mutation_response_possibleTypes: string[] = ['login_account_mutation_response']
export const islogin_account_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is login_account_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "islogin_account_mutation_response"')
  return login_account_mutation_response_possibleTypes.includes(obj.__typename)
}

const login_counts_possibleTypes: string[] = ['login_counts']
export const islogin_counts = (obj?: { __typename?: any } | null): obj is login_counts => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts"')
  return login_counts_possibleTypes.includes(obj.__typename)
}

const login_counts_aggregate_possibleTypes: string[] = ['login_counts_aggregate']
export const islogin_counts_aggregate = (
  obj?: { __typename?: any } | null,
): obj is login_counts_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_aggregate"')
  return login_counts_aggregate_possibleTypes.includes(obj.__typename)
}

const login_counts_aggregate_fields_possibleTypes: string[] = ['login_counts_aggregate_fields']
export const islogin_counts_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "islogin_counts_aggregate_fields"')
  return login_counts_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_avg_fields_possibleTypes: string[] = ['login_counts_avg_fields']
export const islogin_counts_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_avg_fields"')
  return login_counts_avg_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_max_fields_possibleTypes: string[] = ['login_counts_max_fields']
export const islogin_counts_max_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_max_fields"')
  return login_counts_max_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_min_fields_possibleTypes: string[] = ['login_counts_min_fields']
export const islogin_counts_min_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_min_fields"')
  return login_counts_min_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_mutation_response_possibleTypes: string[] = ['login_counts_mutation_response']
export const islogin_counts_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is login_counts_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "islogin_counts_mutation_response"')
  return login_counts_mutation_response_possibleTypes.includes(obj.__typename)
}

const login_counts_stddev_fields_possibleTypes: string[] = ['login_counts_stddev_fields']
export const islogin_counts_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_stddev_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_stddev_fields"')
  return login_counts_stddev_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_stddev_pop_fields_possibleTypes: string[] = ['login_counts_stddev_pop_fields']
export const islogin_counts_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "islogin_counts_stddev_pop_fields"')
  return login_counts_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_stddev_samp_fields_possibleTypes: string[] = ['login_counts_stddev_samp_fields']
export const islogin_counts_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "islogin_counts_stddev_samp_fields"')
  return login_counts_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_sum_fields_possibleTypes: string[] = ['login_counts_sum_fields']
export const islogin_counts_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_sum_fields"')
  return login_counts_sum_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_var_pop_fields_possibleTypes: string[] = ['login_counts_var_pop_fields']
export const islogin_counts_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_var_pop_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_var_pop_fields"')
  return login_counts_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_var_samp_fields_possibleTypes: string[] = ['login_counts_var_samp_fields']
export const islogin_counts_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_var_samp_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_var_samp_fields"')
  return login_counts_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const login_counts_variance_fields_possibleTypes: string[] = ['login_counts_variance_fields']
export const islogin_counts_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is login_counts_variance_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "islogin_counts_variance_fields"')
  return login_counts_variance_fields_possibleTypes.includes(obj.__typename)
}

const messages_possibleTypes: string[] = ['messages']
export const ismessages = (obj?: { __typename?: any } | null): obj is messages => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismessages"')
  return messages_possibleTypes.includes(obj.__typename)
}

const messages_aggregate_possibleTypes: string[] = ['messages_aggregate']
export const ismessages_aggregate = (
  obj?: { __typename?: any } | null,
): obj is messages_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismessages_aggregate"')
  return messages_aggregate_possibleTypes.includes(obj.__typename)
}

const messages_aggregate_fields_possibleTypes: string[] = ['messages_aggregate_fields']
export const ismessages_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is messages_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismessages_aggregate_fields"')
  return messages_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const messages_max_fields_possibleTypes: string[] = ['messages_max_fields']
export const ismessages_max_fields = (
  obj?: { __typename?: any } | null,
): obj is messages_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismessages_max_fields"')
  return messages_max_fields_possibleTypes.includes(obj.__typename)
}

const messages_min_fields_possibleTypes: string[] = ['messages_min_fields']
export const ismessages_min_fields = (
  obj?: { __typename?: any } | null,
): obj is messages_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismessages_min_fields"')
  return messages_min_fields_possibleTypes.includes(obj.__typename)
}

const messages_mutation_response_possibleTypes: string[] = ['messages_mutation_response']
export const ismessages_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is messages_mutation_response => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismessages_mutation_response"')
  return messages_mutation_response_possibleTypes.includes(obj.__typename)
}

const migrate_device_possibleTypes: string[] = ['migrate_device']
export const ismigrate_device = (obj?: { __typename?: any } | null): obj is migrate_device => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismigrate_device"')
  return migrate_device_possibleTypes.includes(obj.__typename)
}

const migrate_device_aggregate_possibleTypes: string[] = ['migrate_device_aggregate']
export const ismigrate_device_aggregate = (
  obj?: { __typename?: any } | null,
): obj is migrate_device_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismigrate_device_aggregate"')
  return migrate_device_aggregate_possibleTypes.includes(obj.__typename)
}

const migrate_device_aggregate_fields_possibleTypes: string[] = ['migrate_device_aggregate_fields']
export const ismigrate_device_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is migrate_device_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "ismigrate_device_aggregate_fields"')
  return migrate_device_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const migrate_device_max_fields_possibleTypes: string[] = ['migrate_device_max_fields']
export const ismigrate_device_max_fields = (
  obj?: { __typename?: any } | null,
): obj is migrate_device_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismigrate_device_max_fields"')
  return migrate_device_max_fields_possibleTypes.includes(obj.__typename)
}

const migrate_device_min_fields_possibleTypes: string[] = ['migrate_device_min_fields']
export const ismigrate_device_min_fields = (
  obj?: { __typename?: any } | null,
): obj is migrate_device_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismigrate_device_min_fields"')
  return migrate_device_min_fields_possibleTypes.includes(obj.__typename)
}

const migrate_device_mutation_response_possibleTypes: string[] = [
  'migrate_device_mutation_response',
]
export const ismigrate_device_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is migrate_device_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "ismigrate_device_mutation_response"')
  return migrate_device_mutation_response_possibleTypes.includes(obj.__typename)
}

const mutation_root_possibleTypes: string[] = ['mutation_root']
export const ismutation_root = (obj?: { __typename?: any } | null): obj is mutation_root => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ismutation_root"')
  return mutation_root_possibleTypes.includes(obj.__typename)
}

const notifications_possibleTypes: string[] = ['notifications']
export const isnotifications = (obj?: { __typename?: any } | null): obj is notifications => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isnotifications"')
  return notifications_possibleTypes.includes(obj.__typename)
}

const notifications_aggregate_possibleTypes: string[] = ['notifications_aggregate']
export const isnotifications_aggregate = (
  obj?: { __typename?: any } | null,
): obj is notifications_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isnotifications_aggregate"')
  return notifications_aggregate_possibleTypes.includes(obj.__typename)
}

const notifications_aggregate_fields_possibleTypes: string[] = ['notifications_aggregate_fields']
export const isnotifications_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is notifications_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isnotifications_aggregate_fields"')
  return notifications_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const notifications_max_fields_possibleTypes: string[] = ['notifications_max_fields']
export const isnotifications_max_fields = (
  obj?: { __typename?: any } | null,
): obj is notifications_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isnotifications_max_fields"')
  return notifications_max_fields_possibleTypes.includes(obj.__typename)
}

const notifications_min_fields_possibleTypes: string[] = ['notifications_min_fields']
export const isnotifications_min_fields = (
  obj?: { __typename?: any } | null,
): obj is notifications_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isnotifications_min_fields"')
  return notifications_min_fields_possibleTypes.includes(obj.__typename)
}

const notifications_mutation_response_possibleTypes: string[] = ['notifications_mutation_response']
export const isnotifications_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is notifications_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isnotifications_mutation_response"')
  return notifications_mutation_response_possibleTypes.includes(obj.__typename)
}

const p2p_offer_output_possibleTypes: string[] = ['p2p_offer_output']
export const isp2p_offer_output = (obj?: { __typename?: any } | null): obj is p2p_offer_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offer_output"')
  return p2p_offer_output_possibleTypes.includes(obj.__typename)
}

const p2p_offers_possibleTypes: string[] = ['p2p_offers']
export const isp2p_offers = (obj?: { __typename?: any } | null): obj is p2p_offers => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offers"')
  return p2p_offers_possibleTypes.includes(obj.__typename)
}

const p2p_offers_aggregate_possibleTypes: string[] = ['p2p_offers_aggregate']
export const isp2p_offers_aggregate = (
  obj?: { __typename?: any } | null,
): obj is p2p_offers_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offers_aggregate"')
  return p2p_offers_aggregate_possibleTypes.includes(obj.__typename)
}

const p2p_offers_aggregate_fields_possibleTypes: string[] = ['p2p_offers_aggregate_fields']
export const isp2p_offers_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is p2p_offers_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offers_aggregate_fields"')
  return p2p_offers_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const p2p_offers_max_fields_possibleTypes: string[] = ['p2p_offers_max_fields']
export const isp2p_offers_max_fields = (
  obj?: { __typename?: any } | null,
): obj is p2p_offers_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offers_max_fields"')
  return p2p_offers_max_fields_possibleTypes.includes(obj.__typename)
}

const p2p_offers_min_fields_possibleTypes: string[] = ['p2p_offers_min_fields']
export const isp2p_offers_min_fields = (
  obj?: { __typename?: any } | null,
): obj is p2p_offers_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offers_min_fields"')
  return p2p_offers_min_fields_possibleTypes.includes(obj.__typename)
}

const p2p_offers_mutation_response_possibleTypes: string[] = ['p2p_offers_mutation_response']
export const isp2p_offers_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is p2p_offers_mutation_response => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_offers_mutation_response"')
  return p2p_offers_mutation_response_possibleTypes.includes(obj.__typename)
}

const p2p_pre_sell_offers_possibleTypes: string[] = ['p2p_pre_sell_offers']
export const isp2p_pre_sell_offers = (
  obj?: { __typename?: any } | null,
): obj is p2p_pre_sell_offers => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isp2p_pre_sell_offers"')
  return p2p_pre_sell_offers_possibleTypes.includes(obj.__typename)
}

const p2p_pre_sell_offers_aggregate_possibleTypes: string[] = ['p2p_pre_sell_offers_aggregate']
export const isp2p_pre_sell_offers_aggregate = (
  obj?: { __typename?: any } | null,
): obj is p2p_pre_sell_offers_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isp2p_pre_sell_offers_aggregate"')
  return p2p_pre_sell_offers_aggregate_possibleTypes.includes(obj.__typename)
}

const p2p_pre_sell_offers_aggregate_fields_possibleTypes: string[] = [
  'p2p_pre_sell_offers_aggregate_fields',
]
export const isp2p_pre_sell_offers_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is p2p_pre_sell_offers_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isp2p_pre_sell_offers_aggregate_fields"')
  return p2p_pre_sell_offers_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const p2p_pre_sell_offers_max_fields_possibleTypes: string[] = ['p2p_pre_sell_offers_max_fields']
export const isp2p_pre_sell_offers_max_fields = (
  obj?: { __typename?: any } | null,
): obj is p2p_pre_sell_offers_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isp2p_pre_sell_offers_max_fields"')
  return p2p_pre_sell_offers_max_fields_possibleTypes.includes(obj.__typename)
}

const p2p_pre_sell_offers_min_fields_possibleTypes: string[] = ['p2p_pre_sell_offers_min_fields']
export const isp2p_pre_sell_offers_min_fields = (
  obj?: { __typename?: any } | null,
): obj is p2p_pre_sell_offers_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isp2p_pre_sell_offers_min_fields"')
  return p2p_pre_sell_offers_min_fields_possibleTypes.includes(obj.__typename)
}

const p2p_pre_sell_offers_mutation_response_possibleTypes: string[] = [
  'p2p_pre_sell_offers_mutation_response',
]
export const isp2p_pre_sell_offers_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is p2p_pre_sell_offers_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isp2p_pre_sell_offers_mutation_response"')
  return p2p_pre_sell_offers_mutation_response_possibleTypes.includes(obj.__typename)
}

const photo_id_verification_output_possibleTypes: string[] = ['photo_id_verification_output']
export const isphoto_id_verification_output = (
  obj?: { __typename?: any } | null,
): obj is photo_id_verification_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isphoto_id_verification_output"')
  return photo_id_verification_output_possibleTypes.includes(obj.__typename)
}

const preferences_possibleTypes: string[] = ['preferences']
export const ispreferences = (obj?: { __typename?: any } | null): obj is preferences => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ispreferences"')
  return preferences_possibleTypes.includes(obj.__typename)
}

const preferences_aggregate_possibleTypes: string[] = ['preferences_aggregate']
export const ispreferences_aggregate = (
  obj?: { __typename?: any } | null,
): obj is preferences_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ispreferences_aggregate"')
  return preferences_aggregate_possibleTypes.includes(obj.__typename)
}

const preferences_aggregate_fields_possibleTypes: string[] = ['preferences_aggregate_fields']
export const ispreferences_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is preferences_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ispreferences_aggregate_fields"')
  return preferences_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const preferences_max_fields_possibleTypes: string[] = ['preferences_max_fields']
export const ispreferences_max_fields = (
  obj?: { __typename?: any } | null,
): obj is preferences_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ispreferences_max_fields"')
  return preferences_max_fields_possibleTypes.includes(obj.__typename)
}

const preferences_min_fields_possibleTypes: string[] = ['preferences_min_fields']
export const ispreferences_min_fields = (
  obj?: { __typename?: any } | null,
): obj is preferences_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "ispreferences_min_fields"')
  return preferences_min_fields_possibleTypes.includes(obj.__typename)
}

const preferences_mutation_response_possibleTypes: string[] = ['preferences_mutation_response']
export const ispreferences_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is preferences_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "ispreferences_mutation_response"')
  return preferences_mutation_response_possibleTypes.includes(obj.__typename)
}

const query_root_possibleTypes: string[] = ['query_root']
export const isquery_root = (obj?: { __typename?: any } | null): obj is query_root => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isquery_root"')
  return query_root_possibleTypes.includes(obj.__typename)
}

const reg_accounts_possibleTypes: string[] = ['reg_accounts']
export const isreg_accounts = (obj?: { __typename?: any } | null): obj is reg_accounts => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreg_accounts"')
  return reg_accounts_possibleTypes.includes(obj.__typename)
}

const reg_accounts_aggregate_possibleTypes: string[] = ['reg_accounts_aggregate']
export const isreg_accounts_aggregate = (
  obj?: { __typename?: any } | null,
): obj is reg_accounts_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreg_accounts_aggregate"')
  return reg_accounts_aggregate_possibleTypes.includes(obj.__typename)
}

const reg_accounts_aggregate_fields_possibleTypes: string[] = ['reg_accounts_aggregate_fields']
export const isreg_accounts_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is reg_accounts_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreg_accounts_aggregate_fields"')
  return reg_accounts_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const reg_accounts_max_fields_possibleTypes: string[] = ['reg_accounts_max_fields']
export const isreg_accounts_max_fields = (
  obj?: { __typename?: any } | null,
): obj is reg_accounts_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreg_accounts_max_fields"')
  return reg_accounts_max_fields_possibleTypes.includes(obj.__typename)
}

const reg_accounts_min_fields_possibleTypes: string[] = ['reg_accounts_min_fields']
export const isreg_accounts_min_fields = (
  obj?: { __typename?: any } | null,
): obj is reg_accounts_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreg_accounts_min_fields"')
  return reg_accounts_min_fields_possibleTypes.includes(obj.__typename)
}

const reg_accounts_mutation_response_possibleTypes: string[] = ['reg_accounts_mutation_response']
export const isreg_accounts_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is reg_accounts_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreg_accounts_mutation_response"')
  return reg_accounts_mutation_response_possibleTypes.includes(obj.__typename)
}

const reputation_score_possibleTypes: string[] = ['reputation_score']
export const isreputation_score = (obj?: { __typename?: any } | null): obj is reputation_score => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreputation_score"')
  return reputation_score_possibleTypes.includes(obj.__typename)
}

const reputation_score_aggregate_possibleTypes: string[] = ['reputation_score_aggregate']
export const isreputation_score_aggregate = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreputation_score_aggregate"')
  return reputation_score_aggregate_possibleTypes.includes(obj.__typename)
}

const reputation_score_aggregate_fields_possibleTypes: string[] = [
  'reputation_score_aggregate_fields',
]
export const isreputation_score_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_aggregate_fields"')
  return reputation_score_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_avg_fields_possibleTypes: string[] = ['reputation_score_avg_fields']
export const isreputation_score_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreputation_score_avg_fields"')
  return reputation_score_avg_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_max_fields_possibleTypes: string[] = ['reputation_score_max_fields']
export const isreputation_score_max_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreputation_score_max_fields"')
  return reputation_score_max_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_min_fields_possibleTypes: string[] = ['reputation_score_min_fields']
export const isreputation_score_min_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreputation_score_min_fields"')
  return reputation_score_min_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_mutation_response_possibleTypes: string[] = [
  'reputation_score_mutation_response',
]
export const isreputation_score_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_mutation_response"')
  return reputation_score_mutation_response_possibleTypes.includes(obj.__typename)
}

const reputation_score_stddev_fields_possibleTypes: string[] = ['reputation_score_stddev_fields']
export const isreputation_score_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_stddev_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_stddev_fields"')
  return reputation_score_stddev_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_stddev_pop_fields_possibleTypes: string[] = [
  'reputation_score_stddev_pop_fields',
]
export const isreputation_score_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_stddev_pop_fields"')
  return reputation_score_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_stddev_samp_fields_possibleTypes: string[] = [
  'reputation_score_stddev_samp_fields',
]
export const isreputation_score_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_stddev_samp_fields"')
  return reputation_score_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_sum_fields_possibleTypes: string[] = ['reputation_score_sum_fields']
export const isreputation_score_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isreputation_score_sum_fields"')
  return reputation_score_sum_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_var_pop_fields_possibleTypes: string[] = ['reputation_score_var_pop_fields']
export const isreputation_score_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_var_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_var_pop_fields"')
  return reputation_score_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_var_samp_fields_possibleTypes: string[] = [
  'reputation_score_var_samp_fields',
]
export const isreputation_score_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_var_samp_fields"')
  return reputation_score_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const reputation_score_variance_fields_possibleTypes: string[] = [
  'reputation_score_variance_fields',
]
export const isreputation_score_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is reputation_score_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isreputation_score_variance_fields"')
  return reputation_score_variance_fields_possibleTypes.includes(obj.__typename)
}

const request_new_account_output_possibleTypes: string[] = ['request_new_account_output']
export const isrequest_new_account_output = (
  obj?: { __typename?: any } | null,
): obj is request_new_account_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isrequest_new_account_output"')
  return request_new_account_output_possibleTypes.includes(obj.__typename)
}

const selfie_verification_output_possibleTypes: string[] = ['selfie_verification_output']
export const isselfie_verification_output = (
  obj?: { __typename?: any } | null,
): obj is selfie_verification_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isselfie_verification_output"')
  return selfie_verification_output_possibleTypes.includes(obj.__typename)
}

const signing_requests_possibleTypes: string[] = ['signing_requests']
export const issigning_requests = (obj?: { __typename?: any } | null): obj is signing_requests => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issigning_requests"')
  return signing_requests_possibleTypes.includes(obj.__typename)
}

const signing_requests_aggregate_possibleTypes: string[] = ['signing_requests_aggregate']
export const issigning_requests_aggregate = (
  obj?: { __typename?: any } | null,
): obj is signing_requests_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issigning_requests_aggregate"')
  return signing_requests_aggregate_possibleTypes.includes(obj.__typename)
}

const signing_requests_aggregate_fields_possibleTypes: string[] = [
  'signing_requests_aggregate_fields',
]
export const issigning_requests_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is signing_requests_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issigning_requests_aggregate_fields"')
  return signing_requests_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const signing_requests_max_fields_possibleTypes: string[] = ['signing_requests_max_fields']
export const issigning_requests_max_fields = (
  obj?: { __typename?: any } | null,
): obj is signing_requests_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issigning_requests_max_fields"')
  return signing_requests_max_fields_possibleTypes.includes(obj.__typename)
}

const signing_requests_min_fields_possibleTypes: string[] = ['signing_requests_min_fields']
export const issigning_requests_min_fields = (
  obj?: { __typename?: any } | null,
): obj is signing_requests_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issigning_requests_min_fields"')
  return signing_requests_min_fields_possibleTypes.includes(obj.__typename)
}

const signing_requests_mutation_response_possibleTypes: string[] = [
  'signing_requests_mutation_response',
]
export const issigning_requests_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is signing_requests_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issigning_requests_mutation_response"')
  return signing_requests_mutation_response_possibleTypes.includes(obj.__typename)
}

const store_ip_address_output_possibleTypes: string[] = ['store_ip_address_output']
export const isstore_ip_address_output = (
  obj?: { __typename?: any } | null,
): obj is store_ip_address_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isstore_ip_address_output"')
  return store_ip_address_output_possibleTypes.includes(obj.__typename)
}

const subscription_root_possibleTypes: string[] = ['subscription_root']
export const issubscription_root = (
  obj?: { __typename?: any } | null,
): obj is subscription_root => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issubscription_root"')
  return subscription_root_possibleTypes.includes(obj.__typename)
}

const support_sessions_possibleTypes: string[] = ['support_sessions']
export const issupport_sessions = (obj?: { __typename?: any } | null): obj is support_sessions => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issupport_sessions"')
  return support_sessions_possibleTypes.includes(obj.__typename)
}

const support_sessions_aggregate_possibleTypes: string[] = ['support_sessions_aggregate']
export const issupport_sessions_aggregate = (
  obj?: { __typename?: any } | null,
): obj is support_sessions_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issupport_sessions_aggregate"')
  return support_sessions_aggregate_possibleTypes.includes(obj.__typename)
}

const support_sessions_aggregate_fields_possibleTypes: string[] = [
  'support_sessions_aggregate_fields',
]
export const issupport_sessions_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is support_sessions_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issupport_sessions_aggregate_fields"')
  return support_sessions_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const support_sessions_max_fields_possibleTypes: string[] = ['support_sessions_max_fields']
export const issupport_sessions_max_fields = (
  obj?: { __typename?: any } | null,
): obj is support_sessions_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issupport_sessions_max_fields"')
  return support_sessions_max_fields_possibleTypes.includes(obj.__typename)
}

const support_sessions_min_fields_possibleTypes: string[] = ['support_sessions_min_fields']
export const issupport_sessions_min_fields = (
  obj?: { __typename?: any } | null,
): obj is support_sessions_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issupport_sessions_min_fields"')
  return support_sessions_min_fields_possibleTypes.includes(obj.__typename)
}

const support_sessions_mutation_response_possibleTypes: string[] = [
  'support_sessions_mutation_response',
]
export const issupport_sessions_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is support_sessions_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issupport_sessions_mutation_response"')
  return support_sessions_mutation_response_possibleTypes.includes(obj.__typename)
}

const swap_assets_possibleTypes: string[] = ['swap_assets']
export const isswap_assets = (obj?: { __typename?: any } | null): obj is swap_assets => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_assets"')
  return swap_assets_possibleTypes.includes(obj.__typename)
}

const swap_assets_aggregate_possibleTypes: string[] = ['swap_assets_aggregate']
export const isswap_assets_aggregate = (
  obj?: { __typename?: any } | null,
): obj is swap_assets_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_assets_aggregate"')
  return swap_assets_aggregate_possibleTypes.includes(obj.__typename)
}

const swap_assets_aggregate_fields_possibleTypes: string[] = ['swap_assets_aggregate_fields']
export const isswap_assets_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_assets_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_assets_aggregate_fields"')
  return swap_assets_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const swap_assets_max_fields_possibleTypes: string[] = ['swap_assets_max_fields']
export const isswap_assets_max_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_assets_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_assets_max_fields"')
  return swap_assets_max_fields_possibleTypes.includes(obj.__typename)
}

const swap_assets_min_fields_possibleTypes: string[] = ['swap_assets_min_fields']
export const isswap_assets_min_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_assets_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_assets_min_fields"')
  return swap_assets_min_fields_possibleTypes.includes(obj.__typename)
}

const swap_assets_mutation_response_possibleTypes: string[] = ['swap_assets_mutation_response']
export const isswap_assets_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is swap_assets_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isswap_assets_mutation_response"')
  return swap_assets_mutation_response_possibleTypes.includes(obj.__typename)
}

const swap_orders_possibleTypes: string[] = ['swap_orders']
export const isswap_orders = (obj?: { __typename?: any } | null): obj is swap_orders => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders"')
  return swap_orders_possibleTypes.includes(obj.__typename)
}

const swap_orders_aggregate_possibleTypes: string[] = ['swap_orders_aggregate']
export const isswap_orders_aggregate = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_aggregate"')
  return swap_orders_aggregate_possibleTypes.includes(obj.__typename)
}

const swap_orders_aggregate_fields_possibleTypes: string[] = ['swap_orders_aggregate_fields']
export const isswap_orders_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_aggregate_fields"')
  return swap_orders_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_avg_fields_possibleTypes: string[] = ['swap_orders_avg_fields']
export const isswap_orders_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_avg_fields"')
  return swap_orders_avg_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_max_fields_possibleTypes: string[] = ['swap_orders_max_fields']
export const isswap_orders_max_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_max_fields"')
  return swap_orders_max_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_min_fields_possibleTypes: string[] = ['swap_orders_min_fields']
export const isswap_orders_min_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_min_fields"')
  return swap_orders_min_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_mutation_response_possibleTypes: string[] = ['swap_orders_mutation_response']
export const isswap_orders_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isswap_orders_mutation_response"')
  return swap_orders_mutation_response_possibleTypes.includes(obj.__typename)
}

const swap_orders_stddev_fields_possibleTypes: string[] = ['swap_orders_stddev_fields']
export const isswap_orders_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_stddev_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_stddev_fields"')
  return swap_orders_stddev_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_stddev_pop_fields_possibleTypes: string[] = ['swap_orders_stddev_pop_fields']
export const isswap_orders_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isswap_orders_stddev_pop_fields"')
  return swap_orders_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_stddev_samp_fields_possibleTypes: string[] = ['swap_orders_stddev_samp_fields']
export const isswap_orders_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isswap_orders_stddev_samp_fields"')
  return swap_orders_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_sum_fields_possibleTypes: string[] = ['swap_orders_sum_fields']
export const isswap_orders_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_sum_fields"')
  return swap_orders_sum_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_var_pop_fields_possibleTypes: string[] = ['swap_orders_var_pop_fields']
export const isswap_orders_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_var_pop_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_var_pop_fields"')
  return swap_orders_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_var_samp_fields_possibleTypes: string[] = ['swap_orders_var_samp_fields']
export const isswap_orders_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_var_samp_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_var_samp_fields"')
  return swap_orders_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const swap_orders_variance_fields_possibleTypes: string[] = ['swap_orders_variance_fields']
export const isswap_orders_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is swap_orders_variance_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isswap_orders_variance_fields"')
  return swap_orders_variance_fields_possibleTypes.includes(obj.__typename)
}

const system_notification_read_status_possibleTypes: string[] = ['system_notification_read_status']
export const issystem_notification_read_status = (
  obj?: { __typename?: any } | null,
): obj is system_notification_read_status => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notification_read_status"')
  return system_notification_read_status_possibleTypes.includes(obj.__typename)
}

const system_notification_read_status_aggregate_possibleTypes: string[] = [
  'system_notification_read_status_aggregate',
]
export const issystem_notification_read_status_aggregate = (
  obj?: { __typename?: any } | null,
): obj is system_notification_read_status_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notification_read_status_aggregate"')
  return system_notification_read_status_aggregate_possibleTypes.includes(obj.__typename)
}

const system_notification_read_status_aggregate_fields_possibleTypes: string[] = [
  'system_notification_read_status_aggregate_fields',
]
export const issystem_notification_read_status_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is system_notification_read_status_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notification_read_status_aggregate_fields"')
  return system_notification_read_status_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const system_notification_read_status_max_fields_possibleTypes: string[] = [
  'system_notification_read_status_max_fields',
]
export const issystem_notification_read_status_max_fields = (
  obj?: { __typename?: any } | null,
): obj is system_notification_read_status_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notification_read_status_max_fields"')
  return system_notification_read_status_max_fields_possibleTypes.includes(obj.__typename)
}

const system_notification_read_status_min_fields_possibleTypes: string[] = [
  'system_notification_read_status_min_fields',
]
export const issystem_notification_read_status_min_fields = (
  obj?: { __typename?: any } | null,
): obj is system_notification_read_status_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notification_read_status_min_fields"')
  return system_notification_read_status_min_fields_possibleTypes.includes(obj.__typename)
}

const system_notification_read_status_mutation_response_possibleTypes: string[] = [
  'system_notification_read_status_mutation_response',
]
export const issystem_notification_read_status_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is system_notification_read_status_mutation_response => {
  if (!obj?.__typename)
    throw new Error(
      '__typename is missing in "issystem_notification_read_status_mutation_response"',
    )
  return system_notification_read_status_mutation_response_possibleTypes.includes(obj.__typename)
}

const system_notifications_possibleTypes: string[] = ['system_notifications']
export const issystem_notifications = (
  obj?: { __typename?: any } | null,
): obj is system_notifications => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issystem_notifications"')
  return system_notifications_possibleTypes.includes(obj.__typename)
}

const system_notifications_aggregate_possibleTypes: string[] = ['system_notifications_aggregate']
export const issystem_notifications_aggregate = (
  obj?: { __typename?: any } | null,
): obj is system_notifications_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notifications_aggregate"')
  return system_notifications_aggregate_possibleTypes.includes(obj.__typename)
}

const system_notifications_aggregate_fields_possibleTypes: string[] = [
  'system_notifications_aggregate_fields',
]
export const issystem_notifications_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is system_notifications_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notifications_aggregate_fields"')
  return system_notifications_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const system_notifications_max_fields_possibleTypes: string[] = ['system_notifications_max_fields']
export const issystem_notifications_max_fields = (
  obj?: { __typename?: any } | null,
): obj is system_notifications_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notifications_max_fields"')
  return system_notifications_max_fields_possibleTypes.includes(obj.__typename)
}

const system_notifications_min_fields_possibleTypes: string[] = ['system_notifications_min_fields']
export const issystem_notifications_min_fields = (
  obj?: { __typename?: any } | null,
): obj is system_notifications_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notifications_min_fields"')
  return system_notifications_min_fields_possibleTypes.includes(obj.__typename)
}

const system_notifications_mutation_response_possibleTypes: string[] = [
  'system_notifications_mutation_response',
]
export const issystem_notifications_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is system_notifications_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_notifications_mutation_response"')
  return system_notifications_mutation_response_possibleTypes.includes(obj.__typename)
}

const system_type_notification_possibleTypes: string[] = ['system_type_notification']
export const issystem_type_notification = (
  obj?: { __typename?: any } | null,
): obj is system_type_notification => {
  if (!obj?.__typename) throw new Error('__typename is missing in "issystem_type_notification"')
  return system_type_notification_possibleTypes.includes(obj.__typename)
}

const system_type_notification_aggregate_possibleTypes: string[] = [
  'system_type_notification_aggregate',
]
export const issystem_type_notification_aggregate = (
  obj?: { __typename?: any } | null,
): obj is system_type_notification_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_type_notification_aggregate"')
  return system_type_notification_aggregate_possibleTypes.includes(obj.__typename)
}

const system_type_notification_aggregate_fields_possibleTypes: string[] = [
  'system_type_notification_aggregate_fields',
]
export const issystem_type_notification_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is system_type_notification_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_type_notification_aggregate_fields"')
  return system_type_notification_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const system_type_notification_max_fields_possibleTypes: string[] = [
  'system_type_notification_max_fields',
]
export const issystem_type_notification_max_fields = (
  obj?: { __typename?: any } | null,
): obj is system_type_notification_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_type_notification_max_fields"')
  return system_type_notification_max_fields_possibleTypes.includes(obj.__typename)
}

const system_type_notification_min_fields_possibleTypes: string[] = [
  'system_type_notification_min_fields',
]
export const issystem_type_notification_min_fields = (
  obj?: { __typename?: any } | null,
): obj is system_type_notification_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_type_notification_min_fields"')
  return system_type_notification_min_fields_possibleTypes.includes(obj.__typename)
}

const system_type_notification_mutation_response_possibleTypes: string[] = [
  'system_type_notification_mutation_response',
]
export const issystem_type_notification_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is system_type_notification_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "issystem_type_notification_mutation_response"')
  return system_type_notification_mutation_response_possibleTypes.includes(obj.__typename)
}

const table_rows_possibleTypes: string[] = ['table_rows']
export const istable_rows = (obj?: { __typename?: any } | null): obj is table_rows => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istable_rows"')
  return table_rows_possibleTypes.includes(obj.__typename)
}

const table_rows_aggregate_possibleTypes: string[] = ['table_rows_aggregate']
export const istable_rows_aggregate = (
  obj?: { __typename?: any } | null,
): obj is table_rows_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istable_rows_aggregate"')
  return table_rows_aggregate_possibleTypes.includes(obj.__typename)
}

const table_rows_aggregate_fields_possibleTypes: string[] = ['table_rows_aggregate_fields']
export const istable_rows_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is table_rows_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istable_rows_aggregate_fields"')
  return table_rows_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const table_rows_max_fields_possibleTypes: string[] = ['table_rows_max_fields']
export const istable_rows_max_fields = (
  obj?: { __typename?: any } | null,
): obj is table_rows_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istable_rows_max_fields"')
  return table_rows_max_fields_possibleTypes.includes(obj.__typename)
}

const table_rows_min_fields_possibleTypes: string[] = ['table_rows_min_fields']
export const istable_rows_min_fields = (
  obj?: { __typename?: any } | null,
): obj is table_rows_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istable_rows_min_fields"')
  return table_rows_min_fields_possibleTypes.includes(obj.__typename)
}

const table_rows_mutation_response_possibleTypes: string[] = ['table_rows_mutation_response']
export const istable_rows_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is table_rows_mutation_response => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istable_rows_mutation_response"')
  return table_rows_mutation_response_possibleTypes.includes(obj.__typename)
}

const toggle_trust_network_output_possibleTypes: string[] = ['toggle_trust_network_output']
export const istoggle_trust_network_output = (
  obj?: { __typename?: any } | null,
): obj is toggle_trust_network_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istoggle_trust_network_output"')
  return toggle_trust_network_output_possibleTypes.includes(obj.__typename)
}

const trust_network_possibleTypes: string[] = ['trust_network']
export const istrust_network = (obj?: { __typename?: any } | null): obj is trust_network => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network"')
  return trust_network_possibleTypes.includes(obj.__typename)
}

const trust_network_aggregate_possibleTypes: string[] = ['trust_network_aggregate']
export const istrust_network_aggregate = (
  obj?: { __typename?: any } | null,
): obj is trust_network_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network_aggregate"')
  return trust_network_aggregate_possibleTypes.includes(obj.__typename)
}

const trust_network_aggregate_fields_possibleTypes: string[] = ['trust_network_aggregate_fields']
export const istrust_network_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_aggregate_fields"')
  return trust_network_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_max_fields_possibleTypes: string[] = ['trust_network_max_fields']
export const istrust_network_max_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network_max_fields"')
  return trust_network_max_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_min_fields_possibleTypes: string[] = ['trust_network_min_fields']
export const istrust_network_min_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network_min_fields"')
  return trust_network_min_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_mutation_response_possibleTypes: string[] = ['trust_network_mutation_response']
export const istrust_network_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is trust_network_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_mutation_response"')
  return trust_network_mutation_response_possibleTypes.includes(obj.__typename)
}

const trust_network_notification_possibleTypes: string[] = ['trust_network_notification']
export const istrust_network_notification = (
  obj?: { __typename?: any } | null,
): obj is trust_network_notification => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network_notification"')
  return trust_network_notification_possibleTypes.includes(obj.__typename)
}

const trust_network_notification_aggregate_possibleTypes: string[] = [
  'trust_network_notification_aggregate',
]
export const istrust_network_notification_aggregate = (
  obj?: { __typename?: any } | null,
): obj is trust_network_notification_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_notification_aggregate"')
  return trust_network_notification_aggregate_possibleTypes.includes(obj.__typename)
}

const trust_network_notification_aggregate_fields_possibleTypes: string[] = [
  'trust_network_notification_aggregate_fields',
]
export const istrust_network_notification_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_notification_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_notification_aggregate_fields"')
  return trust_network_notification_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_notification_max_fields_possibleTypes: string[] = [
  'trust_network_notification_max_fields',
]
export const istrust_network_notification_max_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_notification_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_notification_max_fields"')
  return trust_network_notification_max_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_notification_min_fields_possibleTypes: string[] = [
  'trust_network_notification_min_fields',
]
export const istrust_network_notification_min_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_notification_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_notification_min_fields"')
  return trust_network_notification_min_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_notification_mutation_response_possibleTypes: string[] = [
  'trust_network_notification_mutation_response',
]
export const istrust_network_notification_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is trust_network_notification_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_notification_mutation_response"')
  return trust_network_notification_mutation_response_possibleTypes.includes(obj.__typename)
}

const trust_network_output_possibleTypes: string[] = ['trust_network_output']
export const istrust_network_output = (
  obj?: { __typename?: any } | null,
): obj is trust_network_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network_output"')
  return trust_network_output_possibleTypes.includes(obj.__typename)
}

const trust_network_status_possibleTypes: string[] = ['trust_network_status']
export const istrust_network_status = (
  obj?: { __typename?: any } | null,
): obj is trust_network_status => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istrust_network_status"')
  return trust_network_status_possibleTypes.includes(obj.__typename)
}

const trust_network_status_aggregate_possibleTypes: string[] = ['trust_network_status_aggregate']
export const istrust_network_status_aggregate = (
  obj?: { __typename?: any } | null,
): obj is trust_network_status_aggregate => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_status_aggregate"')
  return trust_network_status_aggregate_possibleTypes.includes(obj.__typename)
}

const trust_network_status_aggregate_fields_possibleTypes: string[] = [
  'trust_network_status_aggregate_fields',
]
export const istrust_network_status_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_status_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_status_aggregate_fields"')
  return trust_network_status_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_status_max_fields_possibleTypes: string[] = ['trust_network_status_max_fields']
export const istrust_network_status_max_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_status_max_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_status_max_fields"')
  return trust_network_status_max_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_status_min_fields_possibleTypes: string[] = ['trust_network_status_min_fields']
export const istrust_network_status_min_fields = (
  obj?: { __typename?: any } | null,
): obj is trust_network_status_min_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_status_min_fields"')
  return trust_network_status_min_fields_possibleTypes.includes(obj.__typename)
}

const trust_network_status_mutation_response_possibleTypes: string[] = [
  'trust_network_status_mutation_response',
]
export const istrust_network_status_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is trust_network_status_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istrust_network_status_mutation_response"')
  return trust_network_status_mutation_response_possibleTypes.includes(obj.__typename)
}

const tx_statistics_possibleTypes: string[] = ['tx_statistics']
export const istx_statistics = (obj?: { __typename?: any } | null): obj is tx_statistics => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics"')
  return tx_statistics_possibleTypes.includes(obj.__typename)
}

const tx_statistics_aggregate_possibleTypes: string[] = ['tx_statistics_aggregate']
export const istx_statistics_aggregate = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_aggregate"')
  return tx_statistics_aggregate_possibleTypes.includes(obj.__typename)
}

const tx_statistics_aggregate_fields_possibleTypes: string[] = ['tx_statistics_aggregate_fields']
export const istx_statistics_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istx_statistics_aggregate_fields"')
  return tx_statistics_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_avg_fields_possibleTypes: string[] = ['tx_statistics_avg_fields']
export const istx_statistics_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_avg_fields"')
  return tx_statistics_avg_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_max_fields_possibleTypes: string[] = ['tx_statistics_max_fields']
export const istx_statistics_max_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_max_fields"')
  return tx_statistics_max_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_min_fields_possibleTypes: string[] = ['tx_statistics_min_fields']
export const istx_statistics_min_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_min_fields"')
  return tx_statistics_min_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_mutation_response_possibleTypes: string[] = ['tx_statistics_mutation_response']
export const istx_statistics_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istx_statistics_mutation_response"')
  return tx_statistics_mutation_response_possibleTypes.includes(obj.__typename)
}

const tx_statistics_stddev_fields_possibleTypes: string[] = ['tx_statistics_stddev_fields']
export const istx_statistics_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_stddev_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_stddev_fields"')
  return tx_statistics_stddev_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_stddev_pop_fields_possibleTypes: string[] = ['tx_statistics_stddev_pop_fields']
export const istx_statistics_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istx_statistics_stddev_pop_fields"')
  return tx_statistics_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_stddev_samp_fields_possibleTypes: string[] = [
  'tx_statistics_stddev_samp_fields',
]
export const istx_statistics_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istx_statistics_stddev_samp_fields"')
  return tx_statistics_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_sum_fields_possibleTypes: string[] = ['tx_statistics_sum_fields']
export const istx_statistics_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_sum_fields"')
  return tx_statistics_sum_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_var_pop_fields_possibleTypes: string[] = ['tx_statistics_var_pop_fields']
export const istx_statistics_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_var_pop_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "istx_statistics_var_pop_fields"')
  return tx_statistics_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_var_samp_fields_possibleTypes: string[] = ['tx_statistics_var_samp_fields']
export const istx_statistics_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istx_statistics_var_samp_fields"')
  return tx_statistics_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const tx_statistics_variance_fields_possibleTypes: string[] = ['tx_statistics_variance_fields']
export const istx_statistics_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is tx_statistics_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "istx_statistics_variance_fields"')
  return tx_statistics_variance_fields_possibleTypes.includes(obj.__typename)
}

const upload_image_output_possibleTypes: string[] = ['upload_image_output']
export const isupload_image_output = (
  obj?: { __typename?: any } | null,
): obj is upload_image_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isupload_image_output"')
  return upload_image_output_possibleTypes.includes(obj.__typename)
}

const validate_short_link_output_possibleTypes: string[] = ['validate_short_link_output']
export const isvalidate_short_link_output = (
  obj?: { __typename?: any } | null,
): obj is validate_short_link_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvalidate_short_link_output"')
  return validate_short_link_output_possibleTypes.includes(obj.__typename)
}

const volume_statistics_possibleTypes: string[] = ['volume_statistics']
export const isvolume_statistics = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvolume_statistics"')
  return volume_statistics_possibleTypes.includes(obj.__typename)
}

const volume_statistics_aggregate_possibleTypes: string[] = ['volume_statistics_aggregate']
export const isvolume_statistics_aggregate = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvolume_statistics_aggregate"')
  return volume_statistics_aggregate_possibleTypes.includes(obj.__typename)
}

const volume_statistics_aggregate_fields_possibleTypes: string[] = [
  'volume_statistics_aggregate_fields',
]
export const isvolume_statistics_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_aggregate_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_aggregate_fields"')
  return volume_statistics_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_avg_fields_possibleTypes: string[] = ['volume_statistics_avg_fields']
export const isvolume_statistics_avg_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_avg_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvolume_statistics_avg_fields"')
  return volume_statistics_avg_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_max_fields_possibleTypes: string[] = ['volume_statistics_max_fields']
export const isvolume_statistics_max_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvolume_statistics_max_fields"')
  return volume_statistics_max_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_min_fields_possibleTypes: string[] = ['volume_statistics_min_fields']
export const isvolume_statistics_min_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvolume_statistics_min_fields"')
  return volume_statistics_min_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_mutation_response_possibleTypes: string[] = [
  'volume_statistics_mutation_response',
]
export const isvolume_statistics_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_mutation_response => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_mutation_response"')
  return volume_statistics_mutation_response_possibleTypes.includes(obj.__typename)
}

const volume_statistics_stddev_fields_possibleTypes: string[] = ['volume_statistics_stddev_fields']
export const isvolume_statistics_stddev_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_stddev_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_stddev_fields"')
  return volume_statistics_stddev_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_stddev_pop_fields_possibleTypes: string[] = [
  'volume_statistics_stddev_pop_fields',
]
export const isvolume_statistics_stddev_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_stddev_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_stddev_pop_fields"')
  return volume_statistics_stddev_pop_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_stddev_samp_fields_possibleTypes: string[] = [
  'volume_statistics_stddev_samp_fields',
]
export const isvolume_statistics_stddev_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_stddev_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_stddev_samp_fields"')
  return volume_statistics_stddev_samp_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_sum_fields_possibleTypes: string[] = ['volume_statistics_sum_fields']
export const isvolume_statistics_sum_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_sum_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isvolume_statistics_sum_fields"')
  return volume_statistics_sum_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_var_pop_fields_possibleTypes: string[] = [
  'volume_statistics_var_pop_fields',
]
export const isvolume_statistics_var_pop_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_var_pop_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_var_pop_fields"')
  return volume_statistics_var_pop_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_var_samp_fields_possibleTypes: string[] = [
  'volume_statistics_var_samp_fields',
]
export const isvolume_statistics_var_samp_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_var_samp_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_var_samp_fields"')
  return volume_statistics_var_samp_fields_possibleTypes.includes(obj.__typename)
}

const volume_statistics_variance_fields_possibleTypes: string[] = [
  'volume_statistics_variance_fields',
]
export const isvolume_statistics_variance_fields = (
  obj?: { __typename?: any } | null,
): obj is volume_statistics_variance_fields => {
  if (!obj?.__typename)
    throw new Error('__typename is missing in "isvolume_statistics_variance_fields"')
  return volume_statistics_variance_fields_possibleTypes.includes(obj.__typename)
}

const wallet_request_output_possibleTypes: string[] = ['wallet_request_output']
export const iswallet_request_output = (
  obj?: { __typename?: any } | null,
): obj is wallet_request_output => {
  if (!obj?.__typename) throw new Error('__typename is missing in "iswallet_request_output"')
  return wallet_request_output_possibleTypes.includes(obj.__typename)
}

const web_push_possibleTypes: string[] = ['web_push']
export const isweb_push = (obj?: { __typename?: any } | null): obj is web_push => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isweb_push"')
  return web_push_possibleTypes.includes(obj.__typename)
}

const web_push_aggregate_possibleTypes: string[] = ['web_push_aggregate']
export const isweb_push_aggregate = (
  obj?: { __typename?: any } | null,
): obj is web_push_aggregate => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isweb_push_aggregate"')
  return web_push_aggregate_possibleTypes.includes(obj.__typename)
}

const web_push_aggregate_fields_possibleTypes: string[] = ['web_push_aggregate_fields']
export const isweb_push_aggregate_fields = (
  obj?: { __typename?: any } | null,
): obj is web_push_aggregate_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isweb_push_aggregate_fields"')
  return web_push_aggregate_fields_possibleTypes.includes(obj.__typename)
}

const web_push_max_fields_possibleTypes: string[] = ['web_push_max_fields']
export const isweb_push_max_fields = (
  obj?: { __typename?: any } | null,
): obj is web_push_max_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isweb_push_max_fields"')
  return web_push_max_fields_possibleTypes.includes(obj.__typename)
}

const web_push_min_fields_possibleTypes: string[] = ['web_push_min_fields']
export const isweb_push_min_fields = (
  obj?: { __typename?: any } | null,
): obj is web_push_min_fields => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isweb_push_min_fields"')
  return web_push_min_fields_possibleTypes.includes(obj.__typename)
}

const web_push_mutation_response_possibleTypes: string[] = ['web_push_mutation_response']
export const isweb_push_mutation_response = (
  obj?: { __typename?: any } | null,
): obj is web_push_mutation_response => {
  if (!obj?.__typename) throw new Error('__typename is missing in "isweb_push_mutation_response"')
  return web_push_mutation_response_possibleTypes.includes(obj.__typename)
}

export const enumAccountStatisticsSelectColumn = {
  orders_cancelled: 'orders_cancelled' as const,
  orders_completed: 'orders_completed' as const,
  user_id: 'user_id' as const,
}

export const enumAccountsInformationConstraint = {
  accounts_information_account_key: 'accounts_information_account_key' as const,
  accounts_information_id_key: 'accounts_information_id_key' as const,
  accounts_information_phone_key: 'accounts_information_phone_key' as const,
  accounts_information_pkey: 'accounts_information_pkey' as const,
  unique_country_id: 'unique_country_id' as const,
  unique_email: 'unique_email' as const,
  unique_phone: 'unique_phone' as const,
}

export const enumAccountsInformationSelectColumn = {
  account: 'account' as const,
  country_id: 'country_id' as const,
  date_of_birth: 'date_of_birth' as const,
  email: 'email' as const,
  full_name: 'full_name' as const,
  id: 'id' as const,
  newsletter_subscription: 'newsletter_subscription' as const,
  phone: 'phone' as const,
  photo_id: 'photo_id' as const,
  recovery_partners: 'recovery_partners' as const,
  registration_ip: 'registration_ip' as const,
  selfie: 'selfie' as const,
}

export const enumAccountsInformationSelectColumnAccountsInformationAggregateBoolExpBoolAndArgumentsColumns =
  {
    newsletter_subscription: 'newsletter_subscription' as const,
  }

export const enumAccountsInformationSelectColumnAccountsInformationAggregateBoolExpBoolOrArgumentsColumns =
  {
    newsletter_subscription: 'newsletter_subscription' as const,
  }

export const enumAccountsInformationUpdateColumn = {
  account: 'account' as const,
  country_id: 'country_id' as const,
  date_of_birth: 'date_of_birth' as const,
  email: 'email' as const,
  full_name: 'full_name' as const,
  id: 'id' as const,
  newsletter_subscription: 'newsletter_subscription' as const,
  phone: 'phone' as const,
  photo_id: 'photo_id' as const,
  recovery_partners: 'recovery_partners' as const,
  registration_ip: 'registration_ip' as const,
  selfie: 'selfie' as const,
}

export const enumAccountsReferralConstraint = {
  accounts_referral_pkey: 'accounts_referral_pkey' as const,
}

export const enumAccountsReferralSelectColumn = {
  account: 'account' as const,
  clicks: 'clicks' as const,
  link: 'link' as const,
  referrer: 'referrer' as const,
  registrations: 'registrations' as const,
  website: 'website' as const,
}

export const enumAccountsReferralUpdateColumn = {
  account: 'account' as const,
  clicks: 'clicks' as const,
  link: 'link' as const,
  referrer: 'referrer' as const,
  registrations: 'registrations' as const,
  website: 'website' as const,
}

export const enumActionsConstraint = {
  actions_pkey: 'actions_pkey' as const,
  actions_transaction_id_key: 'actions_transaction_id_key' as const,
}

export const enumActionsSelectColumn = {
  action: 'action' as const,
  auth: 'auth' as const,
  contract: 'contract' as const,
  data: 'data' as const,
  global_sequence: 'global_sequence' as const,
  transaction_id: 'transaction_id' as const,
}

export const enumActionsUpdateColumn = {
  action: 'action' as const,
  auth: 'auth' as const,
  contract: 'contract' as const,
  data: 'data' as const,
  global_sequence: 'global_sequence' as const,
  transaction_id: 'transaction_id' as const,
}

export const enumAvgPayTimeSelectColumn = {
  avg_pay_time_seconds: 'avg_pay_time_seconds' as const,
  user_id: 'user_id' as const,
}

export const enumAvgReleaseTimeSelectColumn = {
  avg_release_time_seconds: 'avg_release_time_seconds' as const,
  user_id: 'user_id' as const,
}

export const enumCombinedAvgTimesSelectColumn = {
  avg_pay_time_minutes: 'avg_pay_time_minutes' as const,
  avg_release_time_minutes: 'avg_release_time_minutes' as const,
  user_id: 'user_id' as const,
}

export const enumCursorOrdering = {
  ASC: 'ASC' as const,
  DESC: 'DESC' as const,
}

export const enumDevicesConstraint = {
  devices_pkey: 'devices_pkey' as const,
}

export const enumDevicesSelectColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  cred_id: 'cred_id' as const,
  device_name: 'device_name' as const,
  public_key: 'public_key' as const,
}

export const enumDevicesUpdateColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  cred_id: 'cred_id' as const,
  device_name: 'device_name' as const,
  public_key: 'public_key' as const,
}

export const enumLoginAccountConstraint = {
  login_account_pkey: 'login_account_pkey' as const,
}

export const enumLoginAccountSelectColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
}

export const enumLoginAccountUpdateColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
}

export const enumLoginCountsSelectColumn = {
  accounts: 'accounts' as const,
  date: 'date' as const,
  login_count: 'login_count' as const,
}

export const enumMessagesConstraint = {
  messages_pkey: 'messages_pkey' as const,
}

export const enumMessagesSelectColumn = {
  from: 'from' as const,
  id: 'id' as const,
  message: 'message' as const,
  p2p_id: 'p2p_id' as const,
  support_id: 'support_id' as const,
  swap_id: 'swap_id' as const,
  timestamp: 'timestamp' as const,
  to: 'to' as const,
}

export const enumMessagesUpdateColumn = {
  from: 'from' as const,
  id: 'id' as const,
  message: 'message' as const,
  p2p_id: 'p2p_id' as const,
  support_id: 'support_id' as const,
  swap_id: 'swap_id' as const,
  timestamp: 'timestamp' as const,
  to: 'to' as const,
}

export const enumMigrateDeviceConstraint = {
  migrate_device_pkey: 'migrate_device_pkey' as const,
}

export const enumMigrateDeviceSelectColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  cred_id: 'cred_id' as const,
  device_name: 'device_name' as const,
  public_key: 'public_key' as const,
}

export const enumMigrateDeviceUpdateColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  cred_id: 'cred_id' as const,
  device_name: 'device_name' as const,
  public_key: 'public_key' as const,
}

export const enumNotificationsConstraint = {
  notifications_pkey: 'notifications_pkey' as const,
}

export const enumNotificationsSelectColumn = {
  content_id: 'content_id' as const,
  created_at: 'created_at' as const,
  from: 'from' as const,
  id: 'id' as const,
  read: 'read' as const,
  to: 'to' as const,
  type: 'type' as const,
}

export const enumNotificationsSelectColumnNotificationsAggregateBoolExpBoolAndArgumentsColumns = {
  read: 'read' as const,
}

export const enumNotificationsSelectColumnNotificationsAggregateBoolExpBoolOrArgumentsColumns = {
  read: 'read' as const,
}

export const enumNotificationsUpdateColumn = {
  content_id: 'content_id' as const,
  created_at: 'created_at' as const,
  from: 'from' as const,
  id: 'id' as const,
  read: 'read' as const,
  to: 'to' as const,
  type: 'type' as const,
}

export const enumOrderBy = {
  asc: 'asc' as const,
  asc_nulls_first: 'asc_nulls_first' as const,
  asc_nulls_last: 'asc_nulls_last' as const,
  desc: 'desc' as const,
  desc_nulls_first: 'desc_nulls_first' as const,
  desc_nulls_last: 'desc_nulls_last' as const,
}

export const enumP2POffersConstraint = {
  p2p_offers_cancellation_transaction_key: 'p2p_offers_cancellation_transaction_key' as const,
  p2p_offers_id_key: 'p2p_offers_id_key' as const,
  p2p_offers_pkey: 'p2p_offers_pkey' as const,
  p2p_offers_sell_put_transaction_key: 'p2p_offers_sell_put_transaction_key' as const,
  p2p_offers_sell_settlement_transaction_key: 'p2p_offers_sell_settlement_transaction_key' as const,
}

export const enumP2POffersSelectColumn = {
  amount: 'amount' as const,
  bitcashbank_id: 'bitcashbank_id' as const,
  buyer: 'buyer' as const,
  buyer_confirmed_datetime: 'buyer_confirmed_datetime' as const,
  buyer_confirmed_payment: 'buyer_confirmed_payment' as const,
  buyer_method_details: 'buyer_method_details' as const,
  cancel_approval_datetime: 'cancel_approval_datetime' as const,
  cancel_request_datetime: 'cancel_request_datetime' as const,
  cancellation_approved_by: 'cancellation_approved_by' as const,
  cancellation_transaction: 'cancellation_transaction' as const,
  cancelled: 'cancelled' as const,
  cancelled_by: 'cancelled_by' as const,
  completed: 'completed' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
  initiator: 'initiator' as const,
  matched: 'matched' as const,
  matched_datetime: 'matched_datetime' as const,
  method: 'method' as const,
  original_offer_id: 'original_offer_id' as const,
  region: 'region' as const,
  reserved_by: 'reserved_by' as const,
  sell_put_transaction: 'sell_put_transaction' as const,
  sell_settlement_transaction: 'sell_settlement_transaction' as const,
  seller: 'seller' as const,
  seller_confirmed_datetime: 'seller_confirmed_datetime' as const,
  seller_confirmed_payment: 'seller_confirmed_payment' as const,
  seller_method_details: 'seller_method_details' as const,
  type: 'type' as const,
  updated_at: 'updated_at' as const,
}

export const enumP2POffersSelectColumnP2POffersAggregateBoolExpBoolAndArgumentsColumns = {
  buyer_confirmed_payment: 'buyer_confirmed_payment' as const,
  cancelled: 'cancelled' as const,
  completed: 'completed' as const,
  matched: 'matched' as const,
  seller_confirmed_payment: 'seller_confirmed_payment' as const,
}

export const enumP2POffersSelectColumnP2POffersAggregateBoolExpBoolOrArgumentsColumns = {
  buyer_confirmed_payment: 'buyer_confirmed_payment' as const,
  cancelled: 'cancelled' as const,
  completed: 'completed' as const,
  matched: 'matched' as const,
  seller_confirmed_payment: 'seller_confirmed_payment' as const,
}

export const enumP2POffersUpdateColumn = {
  amount: 'amount' as const,
  bitcashbank_id: 'bitcashbank_id' as const,
  buyer: 'buyer' as const,
  buyer_confirmed_datetime: 'buyer_confirmed_datetime' as const,
  buyer_confirmed_payment: 'buyer_confirmed_payment' as const,
  buyer_method_details: 'buyer_method_details' as const,
  cancel_approval_datetime: 'cancel_approval_datetime' as const,
  cancel_request_datetime: 'cancel_request_datetime' as const,
  cancellation_approved_by: 'cancellation_approved_by' as const,
  cancellation_transaction: 'cancellation_transaction' as const,
  cancelled: 'cancelled' as const,
  cancelled_by: 'cancelled_by' as const,
  completed: 'completed' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
  initiator: 'initiator' as const,
  matched: 'matched' as const,
  matched_datetime: 'matched_datetime' as const,
  method: 'method' as const,
  original_offer_id: 'original_offer_id' as const,
  region: 'region' as const,
  reserved_by: 'reserved_by' as const,
  sell_put_transaction: 'sell_put_transaction' as const,
  sell_settlement_transaction: 'sell_settlement_transaction' as const,
  seller: 'seller' as const,
  seller_confirmed_datetime: 'seller_confirmed_datetime' as const,
  seller_confirmed_payment: 'seller_confirmed_payment' as const,
  seller_method_details: 'seller_method_details' as const,
  type: 'type' as const,
  updated_at: 'updated_at' as const,
}

export const enumP2PPreSellOffersConstraint = {
  p2p_pre_sell_offers_pkey: 'p2p_pre_sell_offers_pkey' as const,
  p2p_pre_sell_offers_sell_put_transaction_key:
    'p2p_pre_sell_offers_sell_put_transaction_key' as const,
}

export const enumP2PPreSellOffersSelectColumn = {
  amount: 'amount' as const,
  bitcashbank_id: 'bitcashbank_id' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
  method: 'method' as const,
  processed: 'processed' as const,
  region: 'region' as const,
  sell_put_transaction: 'sell_put_transaction' as const,
  seller: 'seller' as const,
  seller_method_details: 'seller_method_details' as const,
  updated_at: 'updated_at' as const,
}

export const enumP2PPreSellOffersSelectColumnP2PPreSellOffersAggregateBoolExpBoolAndArgumentsColumns =
  {
    processed: 'processed' as const,
  }

export const enumP2PPreSellOffersSelectColumnP2PPreSellOffersAggregateBoolExpBoolOrArgumentsColumns =
  {
    processed: 'processed' as const,
  }

export const enumP2PPreSellOffersUpdateColumn = {
  amount: 'amount' as const,
  bitcashbank_id: 'bitcashbank_id' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
  method: 'method' as const,
  processed: 'processed' as const,
  region: 'region' as const,
  sell_put_transaction: 'sell_put_transaction' as const,
  seller: 'seller' as const,
  seller_method_details: 'seller_method_details' as const,
  updated_at: 'updated_at' as const,
}

export const enumPreferencesConstraint = {
  preferences_pkey: 'preferences_pkey' as const,
}

export const enumPreferencesSelectColumn = {
  account: 'account' as const,
  currency: 'currency' as const,
  language: 'language' as const,
  notifications: 'notifications' as const,
  personalized: 'personalized' as const,
  region: 'region' as const,
  secondary_currency: 'secondary_currency' as const,
  theme: 'theme' as const,
}

export const enumPreferencesUpdateColumn = {
  account: 'account' as const,
  currency: 'currency' as const,
  language: 'language' as const,
  notifications: 'notifications' as const,
  personalized: 'personalized' as const,
  region: 'region' as const,
  secondary_currency: 'secondary_currency' as const,
  theme: 'theme' as const,
}

export const enumRegAccountsConstraint = {
  accounts_pkey: 'accounts_pkey' as const,
  reg_accounts_account_key: 'reg_accounts_account_key' as const,
}

export const enumRegAccountsSelectColumn = {
  account: 'account' as const,
  create_account: 'create_account' as const,
  created: 'created' as const,
  created_at: 'created_at' as const,
  cred_id: 'cred_id' as const,
  device_name: 'device_name' as const,
  freezed: 'freezed' as const,
  id: 'id' as const,
  is_verified: 'is_verified' as const,
  onboarded: 'onboarded' as const,
  public_key: 'public_key' as const,
  referrer: 'referrer' as const,
  role: 'role' as const,
  source: 'source' as const,
  txid: 'txid' as const,
}

export const enumRegAccountsUpdateColumn = {
  account: 'account' as const,
  create_account: 'create_account' as const,
  created: 'created' as const,
  created_at: 'created_at' as const,
  cred_id: 'cred_id' as const,
  device_name: 'device_name' as const,
  freezed: 'freezed' as const,
  id: 'id' as const,
  is_verified: 'is_verified' as const,
  onboarded: 'onboarded' as const,
  public_key: 'public_key' as const,
  referrer: 'referrer' as const,
  role: 'role' as const,
  source: 'source' as const,
  txid: 'txid' as const,
}

export const enumReputationScoreConstraint = {
  reputation_score_pkey: 'reputation_score_pkey' as const,
}

export const enumReputationScoreSelectColumn = {
  account: 'account' as const,
  score: 'score' as const,
  updated_at: 'updated_at' as const,
}

export const enumReputationScoreUpdateColumn = {
  account: 'account' as const,
  score: 'score' as const,
  updated_at: 'updated_at' as const,
}

export const enumSigningRequestsConstraint = {
  signing_requests_pkey: 'signing_requests_pkey' as const,
}

export const enumSigningRequestsSelectColumn = {
  createdAt: 'createdAt' as const,
  esr: 'esr' as const,
  id: 'id' as const,
  signature: 'signature' as const,
  signer: 'signer' as const,
  status: 'status' as const,
  transactionId: 'transactionId' as const,
  updatedAt: 'updatedAt' as const,
}

export const enumSigningRequestsUpdateColumn = {
  createdAt: 'createdAt' as const,
  esr: 'esr' as const,
  id: 'id' as const,
  signature: 'signature' as const,
  signer: 'signer' as const,
  status: 'status' as const,
  transactionId: 'transactionId' as const,
  updatedAt: 'updatedAt' as const,
}

export const enumSupportSessionsConstraint = {
  support_sessions_pkey: 'support_sessions_pkey' as const,
}

export const enumSupportSessionsSelectColumn = {
  assistant: 'assistant' as const,
  closed_by: 'closed_by' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
  session_close_datetime: 'session_close_datetime' as const,
  session_open_datetime: 'session_open_datetime' as const,
  status: 'status' as const,
  updated_at: 'updated_at' as const,
  user: 'user' as const,
}

export const enumSupportSessionsUpdateColumn = {
  assistant: 'assistant' as const,
  closed_by: 'closed_by' as const,
  created_at: 'created_at' as const,
  id: 'id' as const,
  session_close_datetime: 'session_close_datetime' as const,
  session_open_datetime: 'session_open_datetime' as const,
  status: 'status' as const,
  updated_at: 'updated_at' as const,
  user: 'user' as const,
}

export const enumSwapAssetsConstraint = {
  swap_assets_pkey: 'swap_assets_pkey' as const,
}

export const enumSwapAssetsSelectColumn = {
  active_swaps: 'active_swaps' as const,
  asset: 'asset' as const,
  asset_name: 'asset_name' as const,
  chain: 'chain' as const,
  wallet_address: 'wallet_address' as const,
}

export const enumSwapAssetsUpdateColumn = {
  active_swaps: 'active_swaps' as const,
  asset: 'asset' as const,
  asset_name: 'asset_name' as const,
  chain: 'chain' as const,
  wallet_address: 'wallet_address' as const,
}

export const enumSwapOrdersConstraint = {
  swap_orders_pkey: 'swap_orders_pkey' as const,
}

export const enumSwapOrdersSelectColumn = {
  asset: 'asset' as const,
  asset_amount: 'asset_amount' as const,
  bitcash_account: 'bitcash_account' as const,
  bitcash_amount: 'bitcash_amount' as const,
  bitcash_currency: 'bitcash_currency' as const,
  bitcash_trx: 'bitcash_trx' as const,
  created_at: 'created_at' as const,
  gems_id: 'gems_id' as const,
  id: 'id' as const,
  order_status: 'order_status' as const,
  order_type: 'order_type' as const,
  price: 'price' as const,
  updated_at: 'updated_at' as const,
  wallet_address: 'wallet_address' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpAvgArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpCorrArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpCovarSampArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpMaxArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpMinArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpStddevSampArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpSumArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersSelectColumnSwapOrdersAggregateBoolExpVarSampArgumentsColumns = {
  asset_amount: 'asset_amount' as const,
  bitcash_amount: 'bitcash_amount' as const,
  price: 'price' as const,
}

export const enumSwapOrdersUpdateColumn = {
  asset: 'asset' as const,
  asset_amount: 'asset_amount' as const,
  bitcash_account: 'bitcash_account' as const,
  bitcash_amount: 'bitcash_amount' as const,
  bitcash_currency: 'bitcash_currency' as const,
  bitcash_trx: 'bitcash_trx' as const,
  created_at: 'created_at' as const,
  gems_id: 'gems_id' as const,
  id: 'id' as const,
  order_status: 'order_status' as const,
  order_type: 'order_type' as const,
  price: 'price' as const,
  updated_at: 'updated_at' as const,
  wallet_address: 'wallet_address' as const,
}

export const enumSystemNotificationReadStatusConstraint = {
  system_notification_read_status_pkey: 'system_notification_read_status_pkey' as const,
}

export const enumSystemNotificationReadStatusSelectColumn = {
  created_at: 'created_at' as const,
  notification_id: 'notification_id' as const,
  reg_account: 'reg_account' as const,
}

export const enumSystemNotificationReadStatusUpdateColumn = {
  created_at: 'created_at' as const,
  notification_id: 'notification_id' as const,
  reg_account: 'reg_account' as const,
}

export const enumSystemNotificationsConstraint = {
  system_notifications_pkey: 'system_notifications_pkey' as const,
}

export const enumSystemNotificationsSelectColumn = {
  created_at: 'created_at' as const,
  id: 'id' as const,
  message: 'message' as const,
  offer_id: 'offer_id' as const,
  type: 'type' as const,
  updated_at: 'updated_at' as const,
}

export const enumSystemNotificationsUpdateColumn = {
  created_at: 'created_at' as const,
  id: 'id' as const,
  message: 'message' as const,
  offer_id: 'offer_id' as const,
  type: 'type' as const,
  updated_at: 'updated_at' as const,
}

export const enumSystemTypeNotificationConstraint = {
  system_type_notification_pkey: 'system_type_notification_pkey' as const,
}

export const enumSystemTypeNotificationEnum = {
  CONFIRMED_UNCOMPLETED_OFFER: 'CONFIRMED_UNCOMPLETED_OFFER' as const,
  CONFIRMED_UNCOMPLETED_OFFER_ERROR: 'CONFIRMED_UNCOMPLETED_OFFER_ERROR' as const,
  ON_CHAIN_MATCHING_PREOFFER_COMPLETE: 'ON_CHAIN_MATCHING_PREOFFER_COMPLETE' as const,
  ON_CHAIN_MATCHING_PREOFFER_START: 'ON_CHAIN_MATCHING_PREOFFER_START' as const,
  ON_CHAIN_MATCHING_PREOFFER_TIMEOUT_ERROR: 'ON_CHAIN_MATCHING_PREOFFER_TIMEOUT_ERROR' as const,
  UNCONFIRMED_OFFER_12H: 'UNCONFIRMED_OFFER_12H' as const,
  UNCONFIRMED_OFFER_12H_ERROR: 'UNCONFIRMED_OFFER_12H_ERROR' as const,
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR' as const,
  UNMATCHED_OFFER_48H: 'UNMATCHED_OFFER_48H' as const,
  UNMATCHED_OFFER_48H_ERROR: 'UNMATCHED_OFFER_48H_ERROR' as const,
  UNPROCESSED_INDEXED_CONFIRM_OFFER: 'UNPROCESSED_INDEXED_CONFIRM_OFFER' as const,
  UNPROCESSED_INDEXED_OFFER: 'UNPROCESSED_INDEXED_OFFER' as const,
}

export const enumSystemTypeNotificationSelectColumn = {
  type: 'type' as const,
}

export const enumSystemTypeNotificationUpdateColumn = {
  type: 'type' as const,
}

export const enumTableRowsConstraint = {
  table_rows_pkey: 'table_rows_pkey' as const,
}

export const enumTableRowsSelectColumn = {
  contract: 'contract' as const,
  data: 'data' as const,
  primary_key: 'primary_key' as const,
  scope: 'scope' as const,
  table: 'table' as const,
}

export const enumTableRowsUpdateColumn = {
  contract: 'contract' as const,
  data: 'data' as const,
  primary_key: 'primary_key' as const,
  scope: 'scope' as const,
  table: 'table' as const,
}

export const enumTrustNetworkConstraint = {
  trust_network_pkey: 'trust_network_pkey' as const,
}

export const enumTrustNetworkNotificationConstraint = {
  trust_network_notification_pkey: 'trust_network_notification_pkey' as const,
}

export const enumTrustNetworkNotificationSelectColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  trust: 'trust' as const,
}

export const enumTrustNetworkNotificationUpdateColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  trust: 'trust' as const,
}

export const enumTrustNetworkSelectColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  is_mutual: 'is_mutual' as const,
  trust: 'trust' as const,
}

export const enumTrustNetworkSelectColumnTrustNetworkAggregateBoolExpBoolAndArgumentsColumns = {
  is_mutual: 'is_mutual' as const,
}

export const enumTrustNetworkSelectColumnTrustNetworkAggregateBoolExpBoolOrArgumentsColumns = {
  is_mutual: 'is_mutual' as const,
}

export const enumTrustNetworkStatusConstraint = {
  trusted_network_status_pkey: 'trusted_network_status_pkey' as const,
}

export const enumTrustNetworkStatusSelectColumn = {
  status: 'status' as const,
}

export const enumTrustNetworkStatusUpdateColumn = {
  status: 'status' as const,
}

export const enumTrustNetworkUpdateColumn = {
  account: 'account' as const,
  created_at: 'created_at' as const,
  is_mutual: 'is_mutual' as const,
  trust: 'trust' as const,
}

export const enumTxStatisticsSelectColumn = {
  currency: 'currency' as const,
  date: 'date' as const,
  total_transactions: 'total_transactions' as const,
  total_transactions_cancelled: 'total_transactions_cancelled' as const,
  total_transactions_completed: 'total_transactions_completed' as const,
}

export const enumVolumeStatisticsSelectColumn = {
  currency: 'currency' as const,
  date: 'date' as const,
  total_cancelled: 'total_cancelled' as const,
  total_completed: 'total_completed' as const,
  total_completed_not_cancelled: 'total_completed_not_cancelled' as const,
}

export const enumWebPushConstraint = {
  web_push_pkey: 'web_push_pkey' as const,
}

export const enumWebPushSelectColumn = {
  account: 'account' as const,
  auth: 'auth' as const,
  created_at: 'created_at' as const,
  endpoint: 'endpoint' as const,
  expiration_time: 'expiration_time' as const,
  p256dh: 'p256dh' as const,
}

export const enumWebPushUpdateColumn = {
  account: 'account' as const,
  auth: 'auth' as const,
  created_at: 'created_at' as const,
  endpoint: 'endpoint' as const,
  expiration_time: 'expiration_time' as const,
  p256dh: 'p256dh' as const,
}
