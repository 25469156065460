import { CancelButton } from 'app-view/components/buttons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useWizard } from 'app-view/components/Wizard'
import { ModalText, ModalButtonsContainer, ModalError } from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import styled from 'app-view/styles/styled'
import { formattedDate } from 'app-view/lib/utils'

export const Admin: React.FC = () => {
  const { t, i18n } = useTranslation(['p2p', 'global'])
  const [, { goTo }] = useWizard()
  const [{ loading, error, offer }, { cancelOffer, resetError }] = useP2POffer()

  const onCancel = async () => {
    const { success } = await cancelOffer()

    if (success) {
      return goTo('cancel-complete')
    }

    return false
  }

  if (error) {
    return (
      <ModalError
        error={`Error cancelling P2P Offer:
        ${error}`}
        resetError={resetError}
      />
    )
  }

  if (loading) return <Loader text={'Cancelling P2P Offer...'} />

  return (
    <>
      <AdminModalText>
        Offer Data:{'\n'}
        <span>
          <OfferDataLabel>Buyer:</OfferDataLabel> {offer.buyer}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Seller:</OfferDataLabel> {offer.seller}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Amount:</OfferDataLabel> {offer.amount}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Cancelled By:</OfferDataLabel> {offer.cancelled_by}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Buyer Confirmed Payment:</OfferDataLabel>{' '}
          {offer.buyer_confirmed_payment ? 'Yes' : 'No'}
          {'\n'}
        </span>
        <span>
          <OfferDataLabel>Seller Confirmed Payment:</OfferDataLabel>{' '}
          {offer.seller_confirmed_payment ? 'Yes' : 'No'}
          {'\n'}
        </span>
        {offer.cancel_request_datetime && (
          <span>
            <OfferDataLabel>Offer Cancelled Requested At:</OfferDataLabel>{' '}
            {formattedDate(i18n.language, offer.cancel_request_datetime)}
            {'\n'}
          </span>
        )}
        <span>
          <OfferDataLabel>Offer Last update:</OfferDataLabel>{' '}
          {formattedDate(i18n.language, offer.updated_at)}
          {'\n'}
        </span>
      </AdminModalText>
      <br />
      <br />
      <ModalButtonsContainer cancel>
        <CancelButton onClick={onCancel}>{t('global:cancel')}</CancelButton>
      </ModalButtonsContainer>
    </>
  )
}

const OfferDataLabel = styled.b`
  display: flex;
  width: 50%;
  justify-content: flex-start;
  text-align: left;
`
const AdminModalText = styled(ModalText)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;

  > span {
    display: flex;
    width: 100%;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    padding-block: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }
`
