// p2p.ts
export default {
  recently_trust: 'đã tin tưởng bạn gần đây.',
  trust_back: 'Bạn có muốn tin tưởng lại không?',
  chat: 'Trò chuyện',
  untrust_user: 'Không tin tưởng người dùng',
  matched: 'Phù hợp',
  unmatched: 'Không phù hợp',
  unmatched_user: 'Không phù hợp - Bạn',
  unmatched_all: 'Không phù hợp - Tất cả',
  private_offers: 'Các đề nghị',
  private_my_offers: 'Đề nghị của tôi',
  trusted: 'Đã tin tưởng',
  failure_message: 'Tin nhắn thất bại',
  trust_request: 'Yêu cầu',
  error_body: `Chúng tôi đã gặp sự cố
	khi cố gắng tạo
	đề nghị $ của bạn.`,
  error_body_payment: `Chúng tôi đã gặp sự cố
	khi cố gắng xác nhận
	đề nghị $ của bạn.`,
  request_success: 'Gửi yêu cầu thành công',
  do_you_want: `Bạn có muốn nhận lời đề nghị
  {{cryptoAmount}} {{methodDetails}} không?`,
  error_body_cancel: `Chúng tôi đã gặp sự cố
	khi cố gắng hủy bỏ
	đề nghị $ của bạn.`,
  sell: 'Bán',
  buy: 'Mua',
  offers: 'Đề nghị đã hoàn thành',
  mutual: 'Lẫn nhau',
  trust_users: 'Mạng lưới',
  cancelled_offer: 'Đề nghị đã hủy',
  closed_offer: 'Đề nghị đã đóng',
  open_offer: 'Đề nghị mở',

  clearing_filters: 'Xóa P2P cung cấp các bộ lọc...',
  applyng_filters: 'Áp dụng P2P cung cấp các bộ lọc...',
  clear_filter_btn_text: 'Xóa bộ lọc',
  select_multiple_currency: 'Chọn nhiều loại tiền tệ',
  payment_method: 'Phương thức thanh toán',
  prices: 'Giá cả',

  paypal: 'PayPal',
  venmo: 'Venmo',
  cash_app: 'Cash App',
  zelle: 'Zelle',
  bank_transfer: 'Chuyển khoản ngân hàng',
  sinpe_movil: 'SINPE Móvil',
  oxxo: 'OXXO',
  ovo: 'OVO',
  duitnow: 'DuitNow',
  grab: 'Grab',
  gojek: 'Gojek',
  opay: 'OPay',
  palmpay: 'PalmPay',
  kuda: 'Kuda',
  wechat: 'WeChat',
  alipay: 'Alipay',
  revolut: 'Revolut',
  bizum: 'Bizum',
  lyf: 'Lyf',

  network: 'Mạng',
  public: 'Công cộng',
  private: 'Riêng tư',

  offer: 'đề nghị',
  sell_now: 'Bán ngay',
  buy_now: 'Mua ngay',
  filter_by_title: 'Lọc theo',
  filter_option_buy: 'Mua',
  filter_option_sell: 'Bán',
  filter_option_recent: 'Gần đây',
  filter_option_older: 'Cũ hơn',
  filter_option_open: 'Mở',
  filterOptionCancelled: 'Đã hủy',
  filterOptionCompleted: 'Đã hoàn thành',
  no_offers: 'Hiện tại không có {{filter}} đề nghị nào',
  no_request: 'Hiện tại không có yêu cầu nào',
  no_accounts: 'Hiện tại không có tài khoản nào',
  amount_to_sell: 'Bạn muốn bán bao nhiêu {{symbol}}?',
  how_you_want_get_paid: `Bạn muốn
	nhận thanh toán như thế nào?`,
  sell_confirm: `Xác nhận đề xuất của bạn bán
	<span>{{cryptoAmount}}</span> (+{{cryptoFee}} phí).

	Bạn sẽ nhận được <span>{{amount}}</span> sử dụng
	<span>{{method}}</span>.`,

  amount_to_buy: `Bạn muốn mua bao nhiêu {{symbol}}
	?`,
  how_you_want_to_pay: `Bạn muốn
	thanh toán như thế nào?`,
  buy_confirm: `Xác nhận đề xuất của bạn mua
	<span>{{cryptoAmount}}</span> (+{{cryptoFee}} phí).

	Bạn sẽ gửi <span>{{amount}}</span> sử dụng
	<span>{{method}}</span>.`,

  buy_duplicating: `Nhân bản ưu đãi mua {{method}} trị giá {{amount}}`,
  sell_duplicating: `Nhân bản ưu đãi bán {{amount}} của {{method}}`,

  from: 'từ',
  using: 'sử dụng',
  ok: 'Đồng ý',

  creating_buy_offer: 'Tạo đề xuất mua',
  'Congrats we have found a match': 'Chúc mừng, chúng tôi đã tìm thấy một trận đấu',
  'wants to sell': 'muốn bán',
  please_contact: 'Vui lòng liên hệ',
  'and send': 'và gửi',

  'You are matched': 'Bạn đã được ghép đôi',
  'Did you send': 'Bạn đã gửi',
  to: 'tới',
  on: 'trên',
  'send you': 'gửi cho bạn',

  cancel_approved: 'Bạn đã hủy lời đề nghị',
  cancel_requested: 'Yêu cầu hủy',
  confirmed_uncompleted_offer: 'Đề nghị đã xác nhận chưa hoàn thành',
  confirmed_uncompleted_offer_error: 'Đề nghị đã xác nhận chưa hoàn thành',
  on_chain_matching_preoffer_complete: 'Đề nghị trước khi khớp chuỗi hoàn thành',
  on_chain_matching_preoffer_start: 'Đề nghị trước khi khớp chuỗi bắt đầu',
  on_chain_matching_preoffer_timeout_error: 'Đề nghị trước khi khớp chuỗi lỗi hết thời gian',
  unconfirmed_offer_12h: 'Đề nghị chưa xác nhận 12 giờ',
  unconfirmed_offer_12h_error: 'Đề nghị chưa xác nhận 12 giờ lỗi',
  unexpected_error: 'Lỗi không mong muốn',
  unmatched_offer_48h: 'Đề nghị không phù hợp 48 giờ',
  unmatched_offer_48h_error: 'Đề nghị không phù hợp 48 giờ lỗi',
  unprocessed_indexed_confirm_offer: 'Đề nghị xác nhận chưa được xử lý',
  unprocessed_indexed_offer: 'Đề nghị chưa được xử lý',
  cancel_by_request: 'Hủy bởi yêu cầu',
  failed_to_complete_offer: 'Không thể hoàn thành đề nghị',

  'You have sent': 'Bạn đã gửi',
  'Please remind': 'Xin nhắc nhở',
  'to confirm receipt of': 'để xác nhận đã nhận được',
  Contact: 'Liên hệ',

  Next: 'Tiếp theo',
  Back: 'Trở lại',
  Confirm: 'Xác nhận',
  Done: 'Hoàn tất',

  'If the problem persists, ask for support on Telegram':
    'Nếu vấn đề vẫn tiếp tục, yêu cầu hỗ trợ trên Telegram',

  no_match_yet: 'Xin lỗi, chúng tôi chưa tìm thấy trận đấu nào!',
  will_connect_with_buyer_soon: 'Chúng tôi sẽ kết nối bạn với người mua sớm',
  will_connect_with_seller_soon: 'Chúng tôi sẽ kết nối bạn với người bán sớm',
  check_back_in_few_minutes: 'Vui lòng kiểm tra lại sau vài phút',

  confirming_payment: 'Xác nhận thanh toán',
  Not_yet: 'Chưa',
  Yes: 'Đúng',
  No: 'Không',
  Retry: 'Thử lại',
  'Work in progress!': 'Đang tiến hành!',
  'You have sold': 'Bạn đã bán',
  creating_sell: 'Tạo đề xuất bán',
  'Congrats we have found a match!': 'Chúc mừng, chúng tôi đã tìm thấy một trận đấu!',
  'wants to buy': 'muốn mua',
  'Please ask': 'Vui lòng hỏi',
  'to send you': 'để gửi cho bạn',
  'Did you receive': 'Bạn đã nhận được',
  zero_balance: 'Bạn không đủ số dư {{symbol}}',
  confirmed_with: 'Bạn đã xác nhận với',
  but_did_not_send: 'nhưng không gửi',
  'but have not': 'nhưng chưa',
  would_cancel_offer: `Bạn có muốn hủy
  lời đề nghị không?`,
  not_now: 'Không phải bây giờ',
  cancel_request_by: 'Yêu cầu hủy bởi',
  order_cancelled: 'Đơn hàng của bạn đã bị hủy.',
  'Did you contact': 'Bạn có liên hệ với',
  'Did you confirm sale with': 'Bạn có xác nhận bán cho',
  we_are_sorry: 'Chúng tôi rất tiếc',
  no_longer_wants_to_sell: 'không còn muốn bán',
  'Would you like to keep this offer': 'Bạn có muốn giữ lời đề nghị này',
  'and find a new match': 'và tìm một trận đấu mới',
  no_longer_wants_to_buy: 'không còn muốn mua',
  'You have cancelled your offer to buy': 'Bạn đã hủy lời đề nghị mua của mình',
  you_cancelled_offer_to_sell: 'Bạn đã hủy lời đề nghị bán của mình',
  'Offer has been cancelled to buy': 'Đã hủy lời đề nghị mua',
  offer_been_cancelled_to_sell: 'Đã hủy lời đề nghị bán',
  confirmed_sending_you: 'đã xác nhận gửi cho bạn',
  bank_transfer_region_label: ' khu vực.',
  bank_transfer_region: ' tại {{region}}{{isRegion}}',
  push_transaction_error_action_cancel: 'hủy',
  push_transaction_error_action_confirm: 'xác nhận',
  push_transaction_error_action_sell: 'bắt đầu mới của bạn',
  buy_matched_alt_offer: `<span>{{seller}}</span> muốn bán
  <span>{{amount}}</span> (+phí {{cryptoFee}}).

  Bạn có liên hệ <span>{{seller}}</span>
  và xác nhận mua không?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> muốn bán
  <span>{{cryptoAmount}}</span> (+phí {{cryptoFee}}).

  Vui lòng liên hệ <span>{{seller}}</span>
  và gửi <span>{{amount}}</span> bằng <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_matched_offer: `
  Chúc mừng, chúng tôi đã tìm thấy một trận đấu!
  <span>{{seller}}</span> muốn bán
  <span>{{cryptoAmount}}</span> (+phí {{cryptoFee}}).

  Vui lòng liên hệ <span>{{seller}}</span>
  và gửi <span>{{amount}}</span> bằng <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_payment: `Bạn đã được ghép đôi.
  <span>{{seller}}</span> muốn bán
  <span>{{cryptoAmount}}</span> (+phí {{cryptoFee}}).

  Bạn có gửi <span>{{amount}}</span> cho <span>{{seller}}</span> bằng <span>{{method}}</span> {{methodDetails}} không

  `,
  buy_payment_confirmed: `Bạn đã gửi <span>{{amount}}</span> cho <span>{{seller}}</span> bằng <span>{{method}}</span> {{methodDetails}}

  Vui lòng nhắc nhở <span>{{seller}}</span> xác nhận nhận <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}} <span>{{amount}}</span> (+phí {{cryptoFee}}) bằng <span>{{method}}</span> {{methodDetails}}
  Đã hủy vào {{date}}`,
  sell_cancel_deny: `Chúng tôi rất tiếc,
  <span>{{buyer}}</span> đã xác nhận gửi cho bạn <span>{{amount}}</span> (+phí {{cryptoFee}}) bằng <span>{{method}}</span> {{methodDetails}}

  Vui lòng liên hệ <span>{{buyer}}</span>.

  `,
  sell_matched_alt_offer: `<span>{{buyer}}</span> muốn mua
  <span>{{amount}}</span> (+phí {{cryptoFee}}).

  Bạn có xác nhận bán cho
<span>{{buyer}}</span> không?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> muốn mua
  <span>{{cryptoAmount}}</span> (+phí {{cryptoFee}}).

  Vui lòng yêu cầu <span>{{buyer}}</span>
  gửi cho bạn <span>{{amount}}</span> bằng <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_matched_offer: `
  Chúc mừng, chúng tôi đã tìm thấy một trận đấu!
  <span>{{buyer}}</span> muốn mua
  <span>{{cryptoAmount}}</span> (+phí {{cryptoFee}}).

  Vui lòng yêu cầu <span>{{buyer}}</span>
  gửi cho bạn <span>{{amount}}</span> bằng <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_payment: `Bạn đã được ghép đôi.
  <span>{{buyer}}</span> muốn mua
  <span>{{cryptoAmount}}</span> (+phí {{cryptoFee}}).

  Bạn có nhận <span>{{amount}}</span> từ <span>{{buyer}}</span> bằng <span>{{method}}</span> {{methodDetails}} không

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+phí {{cryptoFee}}) bằng <span>{{method}}</span>
  {{methodDetails}}
  Đã hủy vào {{date}}`,
  accept: 'Chấp nhận',
  reject: 'Từ chối',
  accepted: 'Đã chấp nhận',
  rejected: 'Đã từ chối',
  loader: 'đang tải danh sách mạng tin tưởng...',
  select_region_country_search: `Vui lòng, chọn khu vực/quốc gia hoặc,
  gõ để tìm kiếm.`,
  sell_select_stable: `Bạn muốn
  bán gì?`,
  buy_select_stable: `Bạn muốn
  mua gì?`,

  phone_number_label: '{{phoneNumberCountryCode}} | số điện thoại',
  account_number_label: 'Số tài khoản',
  bank_name_label: 'Tên ngân hàng',
  full_name_label: 'Họ và tên',

  seller_method_details: 'Vui lòng cung cấp thông tin ngân hàng của bạn để nhận khoản tiền.',
  seller_method_details_name_account: 'Vui lòng cung cấp tên và số tài khoản để nhận khoản tiền.',
  seller_method_details_phone: `Vui lòng cung cấp số điện thoại
  để nhận khoản tiền.`,
  seller_method_details_account: `Vui lòng cung cấp số tài khoản
  để nhận khoản tiền.`,
  buyer_method_details: 'Vui lòng cung cấp thông tin ngân hàng của bạn để gửi khoản tiền.',
  buyer_method_details_name_account: 'Vui lòng cung cấp tên và số tài khoản để gửi khoản tiền.',
  buyer_method_details_phone: `Vui lòng cung cấp số điện thoại
  để gửi khoản tiền.`,
  buyer_method_details_account: `Vui lòng cung cấp số tài khoản
  để gửi khoản tiền.`,

  copied: 'Đã sao chép thành công.',
  show_more: '<b class="p2p-show-details" id="show-more-details">… hiển thị thêm</b>',
  show_less: '<b class="p2p-show-details" id="show-less-details"> hiển thị ít hơn</b>',

  purchase_completed: `Giao dịch mua đã hoàn tất.
  Bạn đã mua <span>{{amount}}</span> (+phí {{cryptoFee}})
  từ <span>{{seller}}</span> sử dụng
  <span>{{method}}</span> {{sellerMethodDetails}} vào {{date}}.

  {{fundsSent}}
  {{methodDetails}}`,
  sell_complete: `Giao dịch bán đã hoàn tất.
  Bạn đã bán <span>{{amount}}</span> (+phí {{cryptoFee}})
  cho <span>{{buyer}}</span> sử dụng <span>{{method}}</span>
  {{buyerMethodDetails}} vào {{date}}.

  {{fundsReceived}}
  {{methodDetails}}`,

  funds_sent: 'Bạn đã gửi tiền ',

  seller_method_phone_detail_label: `tới số điện thoại #
  <span class="p2p-method-detail-phone" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span> {{region}}`,
  seller_method_bank_detail_label: `tới tài khoản # <span>{{fullName}}</span> ở ngân hàng <span>{{accountName}}</span>
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  seller_method_account_detail_label: `tới tài khoản # <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  seller_method_phone_name_detail_label: `tới <span>{{fullName}}</span> với số điện thoại #
  <span class="p2p-method-detail-account" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_phone_detail_label: `từ số điện thoại #
  <span class="p2p-method-detail-phone" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span> {{region}}`,
  buyer_method_bank_detail_label: `từ tài khoản # <span>{{fullName}}</span> ở ngân hàng <span>{{accountName}}</span>
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_account_detail_label: `từ tài khoản # <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_phone_name_detail_label: `từ <span>{{fullName}}</span> với số điện thoại #
  <span class="p2p-method-detail-account" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span>
    {{ region }}`,
}
