import React from 'react'
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { useStore } from 'app-engine/store'
import { routes } from '../../routes'

export const AuthedRoute = () => {
  const { token, previous_route, joined, setPreviousRoute, setJoined } = useStore()
  const navigate = useNavigate()
  const location = useLocation()

  useEffectOnce(() => {
    if (!joined) setJoined()
  })

  if (
    !token &&
    !routes.access_view.some((av) => av.includes(location.pathname)) &&
    !routes.account_view.some((av) => av.includes(location.pathname))
  ) {
    if (!previous_route || previous_route === '/') {
      setPreviousRoute(`${location.pathname}${location.search || ''}`)
    }
    console.log('Redirecting to /...')
    navigate('/login')
  }

  return <Outlet />
}
