import { UserAddress } from './UserAddress'
import { DepositAddress } from './DepositAddress'
import { SellComplete } from './SellComplete'
import { SellGrinComplete } from './SellGrinComplete'
import { SlatepackForm } from './SlatepackForm'
import { SellSwapAmount } from './SellSwapAmount'
import { WizardStep } from 'app-view/components/Wizard'

export const SELL_STEPS: WizardStep[] = [
  {
    name: 'swap-amount',
    component: SellSwapAmount,
  },
  {
    name: 'deposit-address',
    component: DepositAddress,
  },
  {
    name: 'user-address',
    component: UserAddress,
  },
  {
    name: 'complete',
    component: SellComplete,
  },
]

export const GRIN_SELL_STEPS: WizardStep[] = [
  {
    name: 'swap-amount',
    component: SellSwapAmount,
  },
  {
    name: 'slatepack-address',
    component: SlatepackForm,
  },
  {
    name: 'complete-grin',
    component: SellGrinComplete,
  },
]
