import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { buyAmountLessP2PFee, get_amount, getAmountFee } from 'app-view/lib/utils'
import { Payment } from '../common/Payment'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const BuyPayment = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()

  return (
    // @ts-ignore // TODO: fix type
    <Payment>
      {t('buy_payment', {
        seller: offer.sellerLabel,
        cryptoAmount: buyAmountLessP2PFee(offer.amount),
        cryptoFee: getAmountFee(offer.amount),
        amount: get_amount(offer.amount),
        method: `${methodLabel(offer.method)}${
          !offer.method.match(/(bank_transfer|oxxo)/) ? '?' : ''
        }`,
        methodDetails: isMethodDetails
          ? sellerMethodDetailsLabelData.replace(/(?:.(?!\.))+$/, '?')
          : '',
      })}
    </Payment>
  )
}
