import React, { FC } from 'react'
import { ChatHeaderProps } from '../types'
import { ButtonTransparent } from 'app-view/components/buttons/ButtonTransparent'
import { ModalHeaderChat, StyledTimesIcon } from './Styled'

const ChatHeader: FC<ChatHeaderProps> = ({ children, onClose }) => (
  <ModalHeaderChat>
    {children}
    <ButtonTransparent onClick={onClose}>
      <StyledTimesIcon />
    </ButtonTransparent>
  </ModalHeaderChat>
)

export { ChatHeader }
