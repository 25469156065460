import React from 'react'

const KeyCheckIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="1" y="1" width="18" height="18" rx="1" fill="#E0E1E3" />
    <rect x="1" y="1" width="18" height="18" rx="1" stroke="#FEFEFE" strokeWidth="2" />
  </svg>
)

export default KeyCheckIcon
