import React from 'react'

const ErrorIconWhite = (props: any) => {
  return (
    <svg width="26" height="26" viewBox="0 0 19 19" {...props}>
      <path
        d="M9.5 0.5C14.7535 0.5 19 4.7465 19 10C19 15.2535 14.7535 19.5 9.5 19.5C4.2465 19.5 0 15.2535 0 10C0 4.7465 4.2465 0.5 9.5 0.5ZM12.9105 5.25L9.5 8.6605L6.0895 5.25L4.75 6.5895L8.1605 10L4.75 13.4105L6.0895 14.75L9.5 11.3395L12.9105 14.75L14.25 13.4105L10.8395 10L14.25 6.5895L12.9105 5.25Z"
        fill="#FA0004"
      />
    </svg>
  )
}

export default ErrorIconWhite
