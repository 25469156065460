import { AuthedRoute } from 'app-view/components/AuthedRoute'
import { GlobalModal } from 'app-view/components/GlobalModal'
import { AboutView } from 'pages/AboutView'
import { AccessView } from 'pages/AccessView'
import { AccountView } from 'pages/AccountView'
import { AdminView } from 'pages/AdminView'
import { BankView } from 'pages/BankView'
import { DeviceKeysView } from 'pages/DeviceKeysView'
import { FaucetView } from 'pages/FaucetView'
import { HistoryView } from 'pages/HistoryView'
import { NotFound } from 'pages/NotFound'
import { NotificationsView } from 'pages/NotificationsView'
import { P2PView } from 'pages/P2PView'
import { ProfileView } from 'pages/ProfileView/ProfileView'
import { SettingsView } from 'pages/SettingsView'
import { SwapView } from 'pages/SwapView'
import { TradeView } from 'pages/TradeView'
import { WalletView } from 'pages/WalletView'
import { useRoutes } from 'react-router-dom'
import { routes } from './routes'

// TODO: Fix TS here
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const AppRoutes = () => {
  const pathElement = (Element: React.FC) => (
    <>
      <Element />
      <GlobalModal />
    </>
  )
  const routing = useRoutes(
    [
      { path: '/*', element: pathElement(AccountView) },
      ...(routes.access_view
        ? routes.access_view.map((path) => ({ path, element: pathElement(AccessView) }))
        : []),
      {
        path: '/',
        element: pathElement(AuthedRoute),
        children: [
          { path: 'trade', element: pathElement(TradeView) },
          { path: 'swap', element: pathElement(SwapView) },
          { path: 'wallet', element: pathElement(WalletView) },
          { path: 'bank', element: pathElement(BankView) },
          { path: 'history', element: pathElement(HistoryView) },
          { path: 'settings', element: pathElement(SettingsView) },
          { path: 'device-keys', element: pathElement(DeviceKeysView) },
          { path: 'about', element: pathElement(AboutView) },
          { path: 'profile', element: pathElement(ProfileView) },
          { path: 'faucet', element: pathElement(FaucetView) },
          { path: 'p2p', element: pathElement(P2PView) },
          { path: 'notifications', element: pathElement(NotificationsView) },
          { path: 'admin', element: pathElement(AdminView) },
        ],
      },
      {
        path: '*',
        element: pathElement(NotFound),
      },
    ].filter(Boolean),
  )

  return routing
}
