import React from 'react'
// TODO: Replace them. Package will be rm
import { Segment } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { SettingsContainer } from 'app-view/components/settings'

export const AboutView = () => {
  const { t } = useTranslation(['about'])

  // TODO: Improve
  return (
    <SettingsContainer>
      <Segment piled style={{ marginTop: 20 }} className="body-scroll-lock-ignore">
        <h2>{t('about:heading')}</h2>
        <h3>{t('t1')}</h3>
        <p>{t('p1')}</p>
        <h3>{t('t2')}</h3>
        <p>{t('p2')}</p>
      </Segment>
    </SettingsContainer>
  )
}
