import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { xLog } from 'app-engine/library/utils'
import { IpAPIDataResponse, getCountryByIp } from 'app-engine/services/ip-lookup'
import { StoreSlice } from 'app-engine/store'
import i18next from 'i18next'
import { Subscription } from 'zen-observable-ts'

export interface AccountDevices extends Omit<Bitcash.Devices, 'account'> {
  logged?: boolean
}

export type AccountSliceState = {
  account: string
  userCountryData: IpAPIDataResponse | null
  devices: AccountDevices[]
}

type AccountSliceActions = {
  getAccountCountryByIp: () => void
  setAccount: (account: string, session?: boolean) => Promise<AccountSliceState>
  resetAccount: () => void
  reset: () => void
}

export type AccountSlice = AccountSliceState & AccountSliceActions

const defaultAccountSlideState = {
  account: '',
  userCountryData: null,
  devices: [],
}

let accountSubscriptions: Subscription[] = []

const unsubscribeToAccountData = () => {
  accountSubscriptions.forEach((subscription) => subscription.unsubscribe())
  accountSubscriptions = []
}

const createDevicesObservable = (account: string) =>
  apolloClient.subscribe<Bitcash.DevicesSubscription, Bitcash.DevicesSubscriptionVariables>({
    query: Bitcash.DevicesDocument,
    variables: { where: { account: { _eq: account } } },
    fetchPolicy: 'no-cache',
  })

export const createAccountSlice: StoreSlice<AccountSlice> = (set, get) => ({
  ...defaultAccountSlideState,
  // sets an account in state along with its devices
  setAccount: async (account: string, session?: boolean) => {
    unsubscribeToAccountData()
    // logout user, clear auth data
    if (!session) await get().logout()
    return new Promise(async (resolve) => {
      const initialized = {
        devices: false,
      }

      const devicesObservable = createDevicesObservable(account)

      const devicesSubscription = devicesObservable.subscribe(({ data, errors }) => {
        try {
          if (errors) throw new Error(errors[0].message)

          if (data) {
            // console.log('got devices data', data)

            set({ devices: data.devices as Bitcash.Devices[] })
            initialized.devices = true
          }
        } catch (error) {
          console.log('did not get devices data => ', (error as Error).message)
        }
      })

      // add accountSubscriptions to accountSubscriptions array for unsubscription later
      accountSubscriptions.push(devicesSubscription)

      // update state
      set(() => ({ account }))

      const interval = setInterval(() => {
        // console.log('checking initialization', JSON.stringify(initialized))

        if (initialized.devices) {
          clearInterval(interval)
          const { account, devices } = get()

          resolve({ account, devices } as AccountSliceState)
        }

        if (get().authed) clearInterval(interval)
        return false
      }, 250)
    })
  },

  // resets account data to default empty state
  resetAccount: () => {
    unsubscribeToAccountData()
  },

  getAccountCountryByIp: async () => {
    if (get().userCountryData) return

    const { data, error } = await getCountryByIp()

    if (error) {
      xLog({
        ORIGIN: '—————————  [ERROR] From account-slice.ts > getAccountCountryByIp  —————————',
        error,
      })
    }

    set({ userCountryData: data })
  },
  reset: () => {
    set(defaultAccountSlideState)
  },
})
