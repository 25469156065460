import { create, StateCreator } from 'zustand'
import { persist } from 'zustand/middleware'
import { createSelectorHooks, ZustandHookSelectors } from 'auto-zustand-selectors-hook'
import { mountStoreDevtool } from 'simple-zustand-devtools'

import { createAccountSlice, AccountSlice } from './account-slice'
import { createAuthSlice, AuthSlice } from './auth-slice'
import { createNotificationsSlice } from './notifications-slice'
import { config } from 'app-config'
import { createPWASlice, PWASlice } from 'app-engine/store/pwa-slice'
import { createTokenSlice, TokenSlice } from 'app-engine/store/token-slice'
import { NotificationsSlice } from 'app-engine/types/notifications'
import { createUserGuideSlice, UserGuideSlice } from './userguide-slice'
import { createSwapSlice, SwapSlice } from './swap-slice'
import { createExchangeSlice, ExchangeSlice } from './exchange-slice'
import { createOnboardingSlice, OnboardingSlice } from './onboarding-slice'
import { createEOSSlice, EOSSlice } from './eos-slice'
import { createWalletSlice, WalletSlice } from './wallet-slice'
import { createHistorySlice, HistorySlice } from './history-slice'
import { createP2PSlice, P2PSlice } from './p2p-slice'
import { createProfileSlice, ProfileSlice } from './profile-slice'
import { createKeyRecoverySlice, KeyRecoverySlice } from './key-recovery-slice'
import {
  createP2POpenOfferFilterSlice,
  P2POpenOfferFilterSlice,
} from './p2p-open-offer-filter-slice'

// typescript slicing: https://bit.ly/3qgvLbn
export type AppState = AccountSlice &
  AuthSlice &
  PWASlice &
  NotificationsSlice &
  TokenSlice &
  UserGuideSlice &
  SwapSlice &
  ExchangeSlice &
  OnboardingSlice &
  EOSSlice &
  WalletSlice &
  HistorySlice &
  P2POpenOfferFilterSlice &
  P2PSlice &
  ProfileSlice &
  KeyRecoverySlice

export type StoreSlice<T> = StateCreator<AppState, [], [], T>

const resetters: (() => void)[] = []

export const resetAllStores = () => {
  for (const resetter of resetters) {
    resetter()
  }
}

const useStoreBase = create<AppState>()(
  persist(
    (...a) => {
      const slices = [
        createAccountSlice(...a),
        createAuthSlice(...a),
        createPWASlice(...a),
        createNotificationsSlice(...a),
        createTokenSlice(...a),
        createUserGuideSlice(...a),
        createSwapSlice(...a),
        createExchangeSlice(...a),
        createOnboardingSlice(...a),
        createEOSSlice(...a),
        createWalletSlice(...a),
        createHistorySlice(...a),
        createP2POpenOfferFilterSlice(...a),
        createP2PSlice(...a),
        createProfileSlice(...a),
        createKeyRecoverySlice(...a),
      ]

      // add resetters to global array
      // https://bit.ly/3qgvLbn [typescript slicing]
      // * resetters are used to reset all stores while respecting
      // * the partialize whitelist by omit() in the resetter functions
      resetters.push(...slices.map((slice) => slice.reset).filter(Boolean))
      // * Checking if old bitcashapp localStorage versions exists and removing it
      // * if it does this is to prevent the app from crashing when a user
      // * has an old version of the app installed and then installs a new version
      const bitcashOldVersions = [
        localStorage.getItem('bitcashapp-v5'),
        localStorage.getItem('bitcashapp-v4'),
        localStorage.getItem('bitcashapp-v3'),
        localStorage.getItem('bitcashapp-v2'),
        localStorage.getItem('bitcashapp'),
      ]

      bitcashOldVersions.forEach((oldVersion) => {
        if (oldVersion) {
          localStorage.removeItem(oldVersion)
        }
      })

      return slices.reduce((acc, slice) => ({ ...acc, ...slice }), {}) as AppState
    },
    {
      // localstorage prefix
      name: 'bitcashapp-v6',
      // whitelist state to be stored in localstarage
      partialize: (state) => ({
        account: state.account,
        anchor_permission_level: state.anchor_permission_level,
        devices: state.devices,
        pub_key: state.pub_key,
        cred_id: state.cred_id,
        authType: state.authType,
        userGuidePreferences: state.userGuidePreferences,
        actionLimit: state.actionLimit,
        token: state.token,
        onboarding: state.onboarding,
        joined: state.joined,
      }),
    },
  ),
)

// enable devtools on testing environment https://github.com/beerose/simple-zustand-devtools
if (config.environment === 'testing') mountStoreDevtool('bitcashAppStore', useStoreBase as any)

// typescript selector hooks: https://bit.ly/3fbBHfo
export const useStore = createSelectorHooks(useStoreBase) as typeof useStoreBase &
  ZustandHookSelectors<AppState>
