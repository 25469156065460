import React, { useMemo, useState } from 'react'
import { Card, RowContainer } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { SwapIcon } from './SwapIcon'
import { showTokenSymbol } from 'app-engine/library/tokens'
import { useStore } from 'app-engine/store'
// TODO: Change to @greymass/eosio
import { Asset } from 'eos-common'
import { getCurrencyLabel } from '../lib/amounts'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useTokenPrices } from 'app-view/hooks/use-token-prices'
import { UserPosition } from 'app-view/hooks/use-user-positions'
import { useProfile } from 'app-view/hooks/use-profile.hook'

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 13px 11px;
  min-height: 80px;
  text-align: center;
`

const ContentContainer = styled.div`
  display: flex;
  text-align: left;
  font-weight: 800;
  flex: 1;
  width: 100%;
  font-size: 1.188rem;

  span {
    width: 50%;
  }
`

type CardPositionProps = {
  position: UserPosition
  tab: string
}

export const CardPosition: React.FC<CardPositionProps> = ({ position, tab }) => {
  const isFiat = tab !== 'crypto'
  const tokenPricesHook = useTokenPrices()
  const findTokenPriceBySymbolCode = tokenPricesHook.findTokenPriceBySymbolCode
  const [{ preferences }, {}] = useProfile()
  const [fullFraction, toggleFullFraction] = useState(false)
  const isStable = position.symbol_code.includes('BIT')

  const usdBalance = (position.usd_value as Asset).amount
    ? (position.usd_value as Asset).amount.toJSNumber() / 100 || 0
    : position.usd_value
  const preferencesPrice = findTokenPriceBySymbolCode(preferences.currency)
  if (!preferencesPrice) throw new Error('invalid_local_preferencesPrice')
  const localStableBalance = findTokenPriceBySymbolCode(preferences.currency)?.usd_price
    ? (preferencesPrice.usd_price.amount.toJSNumber() * (usdBalance as number)) / 100
    : preferencesPrice.usd_price.amount.toJSNumber()
  const rawStableBalance = preferences.currency.includes('BITUSD') ? usdBalance : localStableBalance
  let stableBalance: string = getCurrencyLabel(preferences.currency, rawStableBalance as number)
  if (typeof rawStableBalance === 'number' && rawStableBalance >= 100000) {
    stableBalance = stableBalance.split('.')[0]
  }
  const [balanceValue, name] = position.balance.toString().split(' ', 2)

  const isNegative: boolean = useMemo(
    () => (stableBalance.match(/^-/) || balanceValue.match(/^-/) ? true : false),
    [balanceValue, stableBalance],
  )

  if (position.symbol_code === 'TOTAL' || position.symbol_code === 'STABLE') return null

  const cryptoBalance =
    fullFraction && !showTokenSymbol(name).includes('USDT')
      ? balanceValue
      : `${balanceValue.substring(0, !isStable ? balanceValue.length - 2 : balanceValue.length)}`

  return (
    <CardContainer>
      <RowContainer>
        <SwapIcon coin={position.symbol_code} />
      </RowContainer>
      <ContentContainer
        style={{
          cursor:
            !isFiat && !isStable && !showTokenSymbol(name).includes('USDT') ? 'pointer' : undefined,
        }}
        onClick={
          !isFiat && !isStable && !showTokenSymbol(name).includes('USDT')
            ? () => toggleFullFraction(!fullFraction)
            : undefined
        }
      >
        <span style={{ color: '#787878' }}>{showTokenSymbol(name)}</span>
        <span style={{ color: isNegative ? '#F34040' : '#787878' }}>
          {isFiat ? stableBalance : cryptoBalance}
          {!isFiat && !isStable && !fullFraction && !showTokenSymbol(name).includes('USDT') ? (
            <span style={{ fontSize: 14 }}>...</span>
          ) : null}
        </span>
      </ContentContainer>
    </CardContainer>
  )
}
