import React, { useState } from 'react'
import styled from 'app-view/styles/styled'
import { KeyCheckIcon, KeyCheckedIcon } from 'app-view/components/icons'
import { BaseButton } from 'app-view/components/buttons/BaseButton'
import { useTranslation } from 'react-i18next'
import { config } from 'app-config'
import { hideBkExt } from 'app-engine/library/utils'
type KeySubmitProps = {
  partners: string[]
  submit: () => void
}

const Container = styled.div`
  padding: 0 25px;
  button {
    margin: 0 auto;
  }
`
const WrapperItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 12px 0 20px;
`
const Item = styled.div`
  color: white;
  background-color: ${(props) => props.theme.colors.green};
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 13px;
`
const Text = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${(props) =>
    // TODO: fix this
    // @ts-ignore
    props.theme.colors.grayLead};
  text-align: center;
  line-height: 28px;
  strong {
    font-weight: 700;
    padding-top: 30px;
    display: block;
  }
`
const ButtonSubmit = styled(BaseButton)`
  border-radius: 22px;
  font-weight: 800;
`
const WrapperCheckbox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f7f8f9;
  border-radius: 10px;
  padding: 10px;
  color: #000;
  margin-bottom: 18px;
  p {
    text-align: center;
    font-weight: 600;
    letter-spacing: -0.33px;
  }
  svg {
    cursor: pointer;
    position: relative;
  }
`
const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  position: absolute;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
`

export const KeySubmit: React.FC<KeySubmitProps> = ({ partners = [], submit }) => {
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation('settings')
  const DaoRecovery = config.features.daoRecoveryPartner

  return (
    <Container>
      <Text>{t('warning')}:</Text>
      <Text>{t('warning_secondary')} </Text>
      <Text>
        <strong>{t('Recovery Partners')}:</strong>
      </Text>
      <WrapperItems>
        {partners.map((item) => (
          <Item key={item}>{item === DaoRecovery ? 'DAO partner' : hideBkExt(item)}</Item>
        ))}
      </WrapperItems>
      <WrapperCheckbox>
        <div>
          <CheckboxInput type="checkbox" onChange={(e) => setChecked(e.target.checked)} />
          {checked ? <KeyCheckedIcon /> : <KeyCheckIcon />}
        </div>
        <p>{t('agree')}</p>
      </WrapperCheckbox>

      <ButtonSubmit variant="green" onClick={submit} disabled={!checked}>
        {t('submit')}
      </ButtonSubmit>
    </Container>
  )
}
