import React from 'react'
import styled from 'app-view/styles/styled'
import { PlusFillIcon, InfoIcon } from 'app-view/components/icons'
import { InputSection } from 'app-view/components/InputField'
import { Input } from 'app-view/components/InputField'
import { ListPartner } from './ListPartner'
import { Toggle } from 'app-view/components/Toggle'
import { useWizard } from 'app-view/components/Wizard'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px 25px 0 25px;
  position: relative;
  .iconInfo {
    position: absolute;
    top: -40px;
    right: 50px;
    z-index: 2;
    cursor: pointer;
  }
`
const WrapperDao = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 57px;
  padding: 0 15px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.colors.gray};
  p {
    font-weight: 800;
    color: ${(props) => props.theme.colors.darkGray};
    letter-spacing: -0.1px;
    margin: 0;
  }
`
const InputSectionStyled = styled(InputSection)`
  max-width: 100%;
`
const Text = styled.p`
  font-weight: 600;
  font-size: 13px;
  color: ${(props) => props.theme.colors.darkGray};
  margin: 5px 0 31px;
`
const TextError = styled(Text)`
  color: ${(props) => props.theme.colors.red};
  margin: 0;
`
const ButtonPlus = styled.button<{ active: boolean }>`
  border: 0;
  background: transparent;
  margin-right: 25px;
  padding: 0;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
  rect {
    fill: ${({ active, ...props }) => (active ? props.theme.colors.green : '')};
    transition: all 0.3s;
  }
`

const ToggleStyled = styled(Toggle)`
  --size: 0.95rem;
  width: calc(var(--size) * 2.64);
  background-color: ${(props) => (props.isOn ? props.theme.colors.green : '#93efd6')};
  border: 5px solid ${(props) => (props.isOn ? props.theme.colors.green : '#93efd6')};
  transition: all 0.15s ease-in-out;
  &:after {
    transform: translateX(${(props) => (props.isOn ? '160%' : '0%')});
  }
`

type KeyRecoveryPartnerProps = {
  partners: string[]
  removePartner: (partner: string) => void
  addPartner: () => void
  setAccount: (value: string) => void
  account: string
  accountIsRegistered: boolean
  hasDao: boolean
  setHasDao: (value: boolean) => void
}

export const KeyRecoveryPartner: React.FC<KeyRecoveryPartnerProps> = ({
  partners,
  removePartner,
  addPartner,
  setAccount,
  account,
  accountIsRegistered,
  hasDao,
  setHasDao,
}) => {
  const [{}, { goTo }] = useWizard()
  const { t } = useTranslation('settings')
  const isDisabled = Boolean(account) && accountIsRegistered

  return (
    <Container id="KeyRecoveryPartner">
      <InfoIcon className="iconInfo" onClick={() => goTo('info')} />
      <InputSectionStyled
        inputSize="md"
        border="grey"
        error={!accountIsRegistered && Boolean(account)}
      >
        <Input
          radius="soft"
          placeholder="Enter Recovery Partner"
          fullradius={1}
          fontWeight="700"
          value={account}
          onChange={(e) => setAccount(e.target.value.trim())}
        />
        <ButtonPlus active={isDisabled} disabled={!isDisabled} onClick={addPartner}>
          <PlusFillIcon />
        </ButtonPlus>
      </InputSectionStyled>

      {!accountIsRegistered && account ? <TextError>{t('not_found')}</TextError> : null}
      {!partners.length ? <Text>{t('partners_recommended')}</Text> : null}

      <ListPartner partners={partners} removePartner={removePartner} />

      <WrapperDao>
        <p>{t('dao_recovery')}</p>
        <ToggleStyled isOn={hasDao} onClick={() => setHasDao(!hasDao)} />
      </WrapperDao>
    </Container>
  )
}
