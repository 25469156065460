import React, { useRef, useEffect, useState, useMemo } from 'react'
import {
  Icon,
  IconsContainer,
  SelectInnerWrapper,
  SelectItemText,
  WheelSelectItem,
  WheelSelectMask,
  WheelSelectWrapper,
} from 'app-view/components/WheelSelect/styled'
import { useWheelSelect } from './useWheelSelect'
import { getUSDTLabel } from './utils'
import isEqual from 'lodash.isequal'

export interface WheelSelectOption {
  icon?: string
  label: string
  value: any
}

interface WheelSelectProps {
  id?: string
  options: Array<WheelSelectOption>
  onChange: (selectedOption: WheelSelectOption, pressing: boolean) => void
  initialPosition?: number
  oneThird?: boolean
  topFix?: number
  icon?: boolean
  isCoinSelector?: boolean
  label?: boolean
}

export const WheelSelect: React.FC<WheelSelectProps> = ({
  icon,
  options,
  onChange,
  oneThird = false,
  topFix = 0,
  label = false,
  initialPosition,
}) => {
  const initialWheelState = useMemo(() => {
    const default_index = Math.floor(options.length / 2) - 1
    return {
      middleIndex: initialPosition ?? default_index,
      selected: initialPosition ?? default_index,
      pressing: false,
      interacted: false,
      initialY: 0,
      currentY: 0,
      previousY: 0,
      optionsTop: 0,
      direction: null,
      options,
    }
    // eslint-disable-next-line
  }, [options, options.length, initialPosition])

  const [state, dispatch] = useWheelSelect(initialWheelState)

  const [isDarkForce, setIsDarkForce] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const stop = () => dispatch({ type: 'STOP', payload: { ref, x: icon ? 1.95 : 1.7 } })
  const move = (clientY: number) => dispatch({ type: 'MOVE', payload: { clientY, ref } })

  // mouse events
  const start = (mouseStartEvent: React.MouseEvent) => {
    mouseStartEvent.preventDefault()
    dispatch({ type: 'START', payload: { clientY: mouseStartEvent.clientY } })
  }
  const handleMouseMove = (mouseMoveEvent: React.MouseEvent) => move(mouseMoveEvent.clientY)

  // touch events
  const handleTouchMove = (touchMoveEvent: React.TouchEvent) =>
    move(touchMoveEvent.targetTouches[0].pageY)
  const startTouch = (touchStartEvent: React.TouchEvent) =>
    dispatch({ type: 'START', payload: { clientY: touchStartEvent.targetTouches[0].pageY } })

  // hacking mode
  useEffect(() => {
    if (navigator.userAgent.match(/Android/g)) {
      setIsDarkForce(true)
    }
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch({ type: 'INIT', payload: { options, ref } }), [ref, dispatch, options])

  useEffect(() => {
    if (!isEqual(state.options, options)) {
      dispatch({
        type: 'RESET',
        payload: {
          ...initialWheelState,
          ref,
        },
      })
    }
  }, [state.options, initialWheelState, options, options.length, dispatch, ref])

  useEffect(() => {
    onChange(state.options[state.selected], state.interacted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selected, state.options, state.interacted])

  return (
    <WheelSelectWrapper
      onTouchStart={startTouch}
      onTouchMove={handleTouchMove}
      onTouchEnd={stop}
      // The following event handlers are for mouse compatibility:
      onMouseDown={start}
      onMouseMove={handleMouseMove}
      onMouseUp={stop}
      onMouseLeave={stop}
      oneThird={oneThird}
      label={label ? 'true' : null}
    >
      <WheelSelectMask isAndroid={isDarkForce} />
      <SelectInnerWrapper ref={ref} style={{ top: state.optionsTop - topFix }}>
        {options.map((option, i) => (
          <WheelSelectItem
            isAndroid={isDarkForce}
            className={state.selected === i ? 'selected' : ''}
            key={`select-option-${option.label}`}
            icon={option.icon}
          >
            {option.icon && (
              <IconsContainer>
                <Icon coin={option.icon} />
              </IconsContainer>
            )}
            <SelectItemText>{getUSDTLabel(option.label)}</SelectItemText>
          </WheelSelectItem>
        ))}
      </SelectInnerWrapper>
    </WheelSelectWrapper>
  )
}
