import { toggleTrustNetwork } from 'app-engine/services'
import { useStore } from 'app-engine/store'
import Chat from 'app-view/components/Chat'
import {
  ModalButtonsContainer,
  ModalError,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { WizardProvider, WizardStepProps } from 'app-view/components/Wizard'
import { ToggleTrustButton } from 'app-view/components/buttons'
import { buyAmountLessP2PFee, formattedDate, getAmountFee } from 'app-view/lib/utils'
import { checkAccountExt } from 'pages/AccountView/utils'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { ChatProvider, useChat } from 'app-view/components/Chat/hooks/useChat'
import { ChatType } from 'app-view/components/Chat/types'
import { useRealTimeTrustNetworkFollowingAccounts } from 'app-view/hooks/use-realtime/use-realtime-trust-network-following-account'

const BuyCompleteComponent = ({ close }: WizardStepProps) => {
  const { t, i18n } = useTranslation(['p2p'])
  const location = useLocation()
  const [{ offer }, { methodLabel }] = useP2POffer()

  const [{ new_message }, { onOpenWithProps, notifyNewMessage }] = useChat()
  const { authErrorFallback } = useStore()
  const followingAccounts = useRealTimeTrustNetworkFollowingAccounts()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()
  const partnerAccount = checkAccountExt(offer.sellerLabel || '')
  const isFollowingAccepted = followingAccounts.includes(partnerAccount)
  const trustLabel = isFollowingAccepted ? t('global:remove_trust') : t('global:add_trust')

  const toggleTrustAccount = async () => {
    setLoading(true)
    try {
      await toggleTrustNetwork(partnerAccount)
    } catch (error) {
      authErrorFallback(error as Error)
      setError((error as Error).message)
    } finally {
      setLoading(false)
    }
  }

  const onContact = () => {
    notifyNewMessage(0)
    onOpenWithProps({
      from: offer.buyer as string,
      to: offer.seller as string,
      id: {
        type: 'p2p' as ChatType,
        id: offer.id,
      },
    })
  }

  if (loading) return <Loader />

  return (
    <>
      {!loading && !error && (
        <>
          <ModalText
            dangerouslySetInnerHTML={{
              __html: t('purchase_completed', {
                amount: buyAmountLessP2PFee(offer.amount),
                cryptoFee: getAmountFee(offer.amount),
                seller: partnerAccount,
                method: methodLabel(offer.method),
                fundsSent: isMethodDetails && offer.buyer_method_details ? t('funds_sent') : '',
                sellerMethodDetails: isMethodDetails
                  ? sellerMethodDetailsLabelData.replace(/(?:.(?!\.))+$/, '')
                  : '',
                date: formattedDate(i18n.language, offer.updated_at || offer.created_at),
              }),
            }}
          />
          <ModalButtonsContainer>
            <ModalStepBlackButton onClick={onContact}>
              {/* TODO: To create a new msg counter for green button */}
              {new_message > 0 && (
                <span
                  style={{ background: '#0f4', fontWeight: 700, padding: 4, borderRadius: '50%' }}
                >
                  {new_message}
                </span>
              )}
              {t('Contact')}
            </ModalStepBlackButton>
            <ModalStepGreenButton onClick={close}>{t('ok')}</ModalStepGreenButton>
          </ModalButtonsContainer>
          <Chat />
          {!location.pathname.includes('/p2p') && (
            // TODO: RUBEN ANDLER
            <ToggleTrustButton onClick={toggleTrustAccount} remove={isFollowingAccepted}>
              {trustLabel}
            </ToggleTrustButton>
          )}
        </>
      )}
      {error && <ModalError error={error} resetError={() => setError('')} />}
    </>
  )
}

export const BuyComplete = (props: WizardStepProps) => (
  // @ts-expect-error
  <WizardProvider>
    {/* @ts-ignore */}
    <ChatProvider>
      <BuyCompleteComponent {...props} />
    </ChatProvider>
  </WizardProvider>
)
