import React from 'react'
import { KeySuccessIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { ButtonTransparent } from 'app-view/components/buttons'
import { useWizard } from 'app-view/components/Wizard'

type FooterStepProps = {
  isSuccessStep?: boolean
  clickSuccess?: () => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  padding-bottom: 31px;
`

const Dot = styled.div<{ $step?: boolean }>`
  background-color: #d9d9d9;
  background-color: ${({ theme, $step }) => ($step ? theme.colors.green : '#d9d9d9')};
  border-radius: 50%;
  width: 9px;
  height: 9px;
`

const WrapperDots = styled.div`
  margin-top: 17px;
  display: flex;
  align-self: center;
  gap: 3px;
`

const ButtonSuccess = styled(ButtonTransparent)`
  padding-top: 13px;
`

export const FooterStep: React.FC<FooterStepProps> = ({
  isSuccessStep = false,
  clickSuccess = () => {},
}) => {
  const [{ index: indexState, steps }] = useWizard()

  const stepsWizard = steps.slice(0, steps.length - 2)

  return (
    <Container>
      {stepsWizard.length - 1 !== indexState ? (
        <ButtonSuccess onClick={clickSuccess} disabled={!isSuccessStep}>
          <KeySuccessIcon />
        </ButtonSuccess>
      ) : null}
      <WrapperDots>
        {stepsWizard.map((_, index) => (
          <Dot key={index} $step={index === indexState ? true : false} />
        ))}
      </WrapperDots>
    </Container>
  )
}
