/* eslint-disable no-nested-ternary */
import { useStore } from 'app-engine/store'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { Wizard, WizardProvider } from 'app-view/components/Wizard'
import { DevicesSection, KeysSection } from 'pages/DeviceKeysView/components'
import { useDeviceKeysActions } from 'pages/DeviceKeysView/hooks/useDeviceKeysActions'
import { Container } from 'pages/DeviceKeysView/styles'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { KeyRecoverySection } from './components/KeyRecovery'
import { KeyRecoveryProvider, useKeyRecovery } from './hooks/useKeyRecovery'
import { useChainGraphTableRowsSubscription } from 'app-engine/graphql/generated/chaingraph'
import { config } from 'app-config'

const DeviceKeysViewComponent: React.FC = () => {
  const [
    { loading, error, active_keys },
    { handleToggleKey, isKeyActive, ifKeyIsActive, resetError },
  ] = useDeviceKeysActions()
  const [, globalModalActions] = useGlobalModal()
  const { t } = useTranslation('settings')
  const { account, devices } = useStore()
  const [keyRecoveryState, keyRecoveryActions] = useKeyRecovery()

  const { data } = useChainGraphTableRowsSubscription({
    variables: {
      where: {
        primary_key: { _eq: account },
        contract: { _eq: config.contracts.bitcashAccounts },
        table: { _eq: 'accountsv2' },
        scope: { _eq: config.contracts.bitcashAccounts },
      },
    },
  })

  useEffect(() => {
    if (error) {
      globalModalActions.open({
        content: () => <ModalError error={error} resetError={resetError} />,
        iconType: 'ERROR',
        title: 'ERROR',
        showClose: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return useMemo(
    () => (
      <Container>
        {loading ? (
          <Loader text={`${t('loading_devices_keys')}...`} />
        ) : (
          <>
            <DevicesSection
              // @ts-ignore
              devices={devices ?? []}
              ifKeyIsActive={ifKeyIsActive}
              isKeyActive={isKeyActive}
              onToggleKey={handleToggleKey}
            />
            <KeysSection
              keys={active_keys.filter((key) =>
                devices?.find((device) => device.public_key === key) ? false : true,
              )}
              ifKeyIsActive={ifKeyIsActive}
              onToggleKey={handleToggleKey}
            />
            <KeyRecoverySection recoveryId={data?.table_rows[0].data.recovery_id} />
          </>
        )}
        <Wizard
          error={keyRecoveryState.error}
          resetError={() => keyRecoveryActions.setState({ error: '' })}
          title={t('key_recovery')}
          iconType="RECOVERY"
        />
      </Container>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [active_keys.length, devices, handleToggleKey, ifKeyIsActive, isKeyActive, loading, data],
  )
}

export const DeviceKeysView = () => {
  return (
    // @ts-expect-error
    <WizardProvider>
      {/* @ts-ignore */}
      <KeyRecoveryProvider>
        <DeviceKeysViewComponent />
      </KeyRecoveryProvider>
    </WizardProvider>
  )
}
