import { stablecoinMask } from 'app-engine/library/dinero'
import { useStore } from 'app-engine/store/index'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { CoinSelector, CoinSelectorProps } from 'app-view/components/CoinSelector'
import { CoinSelectorWrapper, StyledPrice } from 'app-view/components/Styled'
import { useRealTimeCoinPrices } from 'app-view/hooks/use-realtime/use-realtime-coin-prices'
import { Price, PriceAmountWrapper } from 'pages/P2PView/Styled'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

export const SwapActions: React.FC = () => {
  const { t } = useTranslation(['swap'])
  const [userInteract, setUserInteract] = useState(false)
  const coinPrices = useRealTimeCoinPrices()
  const crypto_options = useStore.useCrypto_options()
  const coinSwap = useStore.useCoinSwap()

  const setSwapAsset = useStore.useSetSwapAsset()
  const fetchCryptoOptions = useStore.useFetchCryptoOptions()

  const swap = coinSwap.swap

  useEffectOnce(() => {
    fetchCryptoOptions()
  })

  const coinSelectorAttr: CoinSelectorProps = {
    setCoin: setSwapAsset,
    coinOpt: crypto_options,
    indicatorProps: {
      screen: 'wallet' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract,
    setUserInteract,
    coin: swap.asset,
  }

  return (
    <PriceAmountWrapper>
      <Price>
        <CoinSelectorWrapper>
          <CoinSelector {...coinSelectorAttr} />
        </CoinSelectorWrapper>
        <StyledPrice>
          <b>{t('swap:price')}</b>
          <p>
            {coinPrices && swap.asset in coinPrices
              ? stablecoinMask(coinPrices[swap.asset!]?.usd || 0)
              : 0}
          </p>
        </StyledPrice>
      </Price>
    </PriceAmountWrapper>
  )
}
