import { config } from 'app-config'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { bitcashClient } from 'graphql/bitcash'
import { checkAccountExt } from '../../pages/AccountView/utils'

export interface RequestAccountParams {
  referrer: string
  requestedName: string
  key: string
}

// checks if an account is already registered on the accounts contract
export const checkIfAccountIsRegistered = async (account: string) => {
  const result = await apolloClient.query<
    ChainGraph.GetChainGraphTableRowsQuery,
    ChainGraph.GetChainGraphTableRowsQueryVariables
  >({
    query: ChainGraph.GetChainGraphTableRowsDocument,
    variables: {
      where: {
        chain: {
          _eq: config.eosChainName,
        },
        contract: {
          _eq: config.contracts.bitcashAccounts,
        },
        table: {
          // production & test accounts tables
          _in: ['accounts', 'accountsv2'],
        },
        primary_key: {
          _eq: account,
        },
      },
    },
  })
  return Boolean(result.data.table_rows.length)
}

export const checkPendingRegistration = async (account: string) => {
  const result = await apolloClient.query<
    Bitcash.GetRegAccountsQuery,
    Bitcash.GetRegAccountsQueryVariables
  >({
    query: Bitcash.GetRegAccountsDocument,
    variables: { account: checkAccountExt(account) },
  })
  return Boolean(result.data.reg_accounts.length)
}

export const toggleTrustNetwork = async (trustAccount: string, reject = false) => {
  try {
    await apolloClient.mutate<
      Bitcash.BitcashToggleTrustNetworkMutation,
      Bitcash.BitcashToggleTrustNetworkMutationVariables
    >({
      mutation: Bitcash.BitcashToggleTrustNetworkDocument,
      variables: {
        input: {
          account: trustAccount,
          reject,
        },
      },
    })
  } catch (error) {
    if (error) throw new Error((error as Error).message)
  }
}

export const bitcashService = {
  checkIfAccountIsRegistered,
  checkPendingRegistration,
}

export const devicesCount = async (account: string) => {
  const result = await apolloClient.query<
    Bitcash.AccountDeviceListQuery,
    Bitcash.AccountDeviceListQueryVariables
  >({
    query: Bitcash.AccountDeviceListDocument,
    variables: { account: checkAccountExt(account) },
  })
  return result.data.devices.length || 0
}

// checks if the country id is already registered
export const checkIfCountryIdIsRegistered = async (id_data: Bitcash.Id_Verification_Input) => {
  const result = await apolloClient.query<
    Bitcash.IdVerificationQuery,
    Bitcash.IdVerificationQueryVariables
  >({
    query: Bitcash.IdVerificationDocument,
    variables: { id_data: id_data },
  })
  return result.data.id_verification.existingRecord
}

// checks if the phone number is already registered
export const checkIfPhoneIsRegistered = async (phone: string) => {
  const result = await apolloClient.query<
    Bitcash.GetRegAccountsQuery,
    Bitcash.GetRegAccountsByPhoneNumberQueryVariables
  >({
    query: Bitcash.GetRegAccountsByPhoneNumberDocument,
    variables: { phone: phone },
  })
  return Boolean(result.data.reg_accounts.length)
}

// verify Selfie
export const verifySelfie = async (selfie: string) => {
  const result = await apolloClient.query<
    Bitcash.SelfieVerificationQuery,
    Bitcash.SelfieVerificationQueryVariables
  >({
    query: Bitcash.SelfieVerificationDocument,
    variables: { selfie: selfie },
  })
  return result.data.selfie_verification
}

// upload selfie

export const uploadImage = async (image: string, account: string, is_selfie: boolean) => {
  await apolloClient.mutate<Bitcash.UploadImageMutation, Bitcash.UploadImageMutationVariables>({
    mutation: Bitcash.UploadImageDocument,
    variables: {
      image,
      account,
      is_selfie,
    },
  })
}

export const storeIP = async (account: string) => {
  const { store_ip_address } = await bitcashClient.mutation({
    store_ip_address: {
      __args: {
        account,
      },
      success: true,
    },
  })
  if (!store_ip_address || !store_ip_address.success) {
    throw new Error('Failed to store User IP address')
  }
}

// Check if email already registered
export const checkEmail = async (email: string) => {
  const { reg_accounts } = await bitcashClient.query({
    reg_accounts: {
      __args: {
        where: {
          accounts_information: {
            email: { _eq: email },
          },
        },
      },
      id: true,
    },
  })
  return Boolean(reg_accounts.length)
}

// verify Photo Id
export const verifyPhotoIdBE = async (photo: string) => {
  const { photo_id_verification } = await bitcashClient.query({
    photo_id_verification: {
      __args: {
        photo_id: photo,
      },
      error: true,
    },
  })
  return photo_id_verification
}

export const reserveOffer = async (offerId: string) => {
  try {
    const { reserve_offer: result } = await bitcashClient.mutation({
      reserve_offer: {
        __args: {
          offerId,
        },
      },
    })
    return result
  } catch (error) {
    console.log('did not get reserveOffer data => ', (error as Error).message)
    return undefined
  }
}

export const unreserveOffer = async (offerId: string) => {
  try {
    const { unreserve_offer: result } = await bitcashClient.mutation({
      unreserve_offer: {
        __args: {
          offerId,
        },
      },
    })
    return result
  } catch (error) {
    console.log('did not get unreserveOffer data => ', (error as Error).message)
    return undefined
  }
}
