import React from 'react'

const KeySuccessIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => (
  <svg width="41" height="41" viewBox="0 0 41 41" fill="none" {...props}>
    <path
      d="M20.5 0C25.9369 0 31.1512 2.15982 34.9957 6.00431C38.8402 9.84881 41 15.0631 41 20.5C41 25.9369 38.8402 31.1512 34.9957 34.9957C31.1512 38.8402 25.9369 41 20.5 41C15.0631 41 9.84881 38.8402 6.00431 34.9957C2.15982 31.1512 0 25.9369 0 20.5C0 15.0631 2.15982 9.84881 6.00431 6.00431C9.84881 2.15982 15.0631 0 20.5 0ZM11.5312 19.2188C11.1914 19.2188 10.8656 19.3537 10.6253 19.594C10.385 19.8343 10.25 20.1602 10.25 20.5C10.25 20.8398 10.385 21.1657 10.6253 21.406C10.8656 21.6463 11.1914 21.7812 11.5312 21.7812H26.3758L20.8741 27.2804C20.755 27.3995 20.6605 27.5409 20.596 27.6966C20.5316 27.8522 20.4984 28.019 20.4984 28.1875C20.4984 28.356 20.5316 28.5228 20.596 28.6784C20.6605 28.8341 20.755 28.9755 20.8741 29.0946C20.9932 29.2138 21.1347 29.3082 21.2903 29.3727C21.446 29.4372 21.6128 29.4704 21.7812 29.4704C21.9497 29.4704 22.1165 29.4372 22.2722 29.3727C22.4278 29.3082 22.5693 29.2138 22.6884 29.0946L30.3759 21.4071C30.4952 21.2881 30.5899 21.1467 30.6544 20.9911C30.719 20.8354 30.7523 20.6685 30.7523 20.5C30.7523 20.3315 30.719 20.1646 30.6544 20.0089C30.5899 19.8533 30.4952 19.7119 30.3759 19.5929L22.6884 11.9054C22.5693 11.7862 22.4278 11.6918 22.2722 11.6273C22.1165 11.5628 21.9497 11.5296 21.7812 11.5296C21.6128 11.5296 21.446 11.5628 21.2903 11.6273C21.1347 11.6918 20.9932 11.7862 20.8741 11.9054C20.755 12.0245 20.6605 12.1659 20.596 12.3216C20.5316 12.4772 20.4984 12.644 20.4984 12.8125C20.4984 12.981 20.5316 13.1478 20.596 13.3034C20.6605 13.4591 20.755 13.6005 20.8741 13.7196L26.3758 19.2188H11.5312Z"
      fill="#29e0ad"
    />
  </svg>
)

export default KeySuccessIcon
