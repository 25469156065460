import styled from 'app-view/styles/styled'

export const HighlightText = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  opacity: 0.7;
  color: #787878;
`

export const TopSpinnerLoader = styled.div`
  > div {
    top: -45px;
    right: 10px;
  }
`

export const TabViewWrapper = styled.div`
  position: relative;
  width: 100%;
`
