export default {
  slogan: '¡Cripto Gemas Para Llevar!',
  total: 'Total Disponible',
  total_stable: 'bitcash Disponible',
  buy: 'Comprar',
  swap: 'Canjear',
  sell: 'Vender',
  share_link: 'Generador de Enlaces',
  all: 'Todos',
  support_channel: 'Chat de Soporte',
  support: 'Soporte',
  available: 'Disponible en bitUSD',
  open: 'Pendiente',
  cancelled: 'Cancelado',
  filled: 'Completado',
  cancelled_on: 'Cancelado el',
  filled_on: 'Llenado el',
  p2p: 'P2P',
  error: 'Se ha producido un error.',
  loading: 'Cargando sus datos',
  ok: 'OK',
  back: 'Volver',
  signup_with: 'Registarse con',
  no_messages: 'Sin mensajes todavía',
  add_key: `<span>Añadir llave a</span>
  <span>Cuenta Existente</span>`,
  create_account: 'Crear una Cuenta',
  login: 'Iniciar Sesión',
  restore_account: 'Restaurar Cuenta',
  cancel: 'Cancelar',
  received: 'Recibido',
  generic_error: 'Lo siento, hubo una falla. Por favor inténtalo de nuevo',
  appStatePausedMsn: 'Lo sentimos, trading se encuentra en pausa por mantenimiento.',
  push_transaction_error: `Lo sentimos!
  No pudimos {{action}} oferta,
  por favor intenta en unos segundos.
  `,
  push_transaction_error_action_process: 'procesar tu',
  non_auth_user: `El usuario ingresado no ha
  sido aprobado aún. Conéctate
  a la red de Discord:`,
  add_trust: 'Agregar a Red', // TODO: RUBEN ANDLER
  remove_trust: 'Remover de Red', // TODO: RUBEN ANDLER
  trust_request_sent: 'Solicitud de Confianza Enviada', // TODO: RUBEN ANDLER
  added_trust: 'Agregado a tu red de confianza',
  remind_me_later: 'Recuérdame Luego',
  add_second_device:
    'Recuerda añadir un Segundo Dispositivo a tu cuenta para evitar pérdida de datos.',
  navigation_trade: 'Trade',
  navigation_wallet: 'Billetera',
  navigation_history: 'Historial',
  navigation_p2p: 'P2P',
  navigation_swap: 'Canjear',
  navigation_bank: 'Banco',
  last_update: 'Ultimá Actualización:',
  order_amount: 'Monto de la orden:',
  net_amount: 'Monto a recibir:',
  order_cost: 'Costo de la orden:',
  minus_network: '(menos tasas de red)',
  confirm: 'Confirmar',
  continue_question: `¿Quieres continuar?
    
  `,
  no_data: 'No hay datos',
  take_me_there: 'Llévame allí',
  required_action: 'Acción requerida',
  data_warning_message:
    'Para calificar para recompensas y permitir la recuperación de la cuenta, actualice su información.',

  login_request: 'Solicitud de Inicio de Sesión',
  login_request_description: '¿Quieres iniciar sesión en <0>{{appName}}</0>?',
  signature_request: 'Solicitud de Firma',
  dboard_voting_transaction: `Por favor confirma que deseas
  <0>votar {{option}}</0> en <1>dBoard</1>.`,
  evm_transfer_transaction: `¿Deseas transferir <0>{{amount}}</0>
  a <1>{{account}}</1> de <2>{{appName}}</2>?`,
  pomelo_grant_donation: `Por favor confirma que deseas
  <0>donar a {{grant}}</0> en <1>Pomelo</1>.`,
  overview: 'Resumen',
  details: 'Detalles',
  transaction: 'Transacción',
  data: 'Datos',
  raw_data: 'Datos Crudos',
  qr_reader: 'Lector de QR',
  sign_success: 'Firma Exitosa',
}
