import Decimal from 'decimal.js'

export const formatStableCoinRate = (value: string | undefined) => {
  if (!value) return ''
  try {
    const decimalValue = new Decimal(value)

    if (decimalValue.greaterThanOrEqualTo(1)) {
      return decimalValue.toFixed(2).toString()
    }

    if (decimalValue.lessThanOrEqualTo(0.0009)) {
      return decimalValue.toFixed(6).toString()
    }

    return decimalValue.toFixed(4).toString()
  } catch (error) {
    return ''
  }
}
