import { ModalSuccess } from 'app-view/components/Modal'
import { useWizard } from 'app-view/components/Wizard'
import { useBankWallet } from 'pages/BankView/hooks/useBankWallet'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'

export const ActionSuccess = () => {
  const [, { close }] = useWizard()
  const { t } = useTranslation(['wallet'])
  const [{ action }] = useBankWallet()

  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      close()

      clearTimeout(timeout)
    }, 5000)
  })

  const bankSuccessText = action.type === 'withdrawal' ? 'withdraw_success' : 'exchange_success'

  return <ModalSuccess text={t(action.type === 'send' ? 'send_success' : bankSuccessText)} />
}
