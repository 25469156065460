import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { HintError, Input, Label, InputWrapper } from 'app-view/components/InputField'
import { InputSection } from 'app-view/components/InputField'
import { H2, Form, Text, AccountFormWrapper } from 'pages/AccountView/components/TextElements'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  FormData,
  accountInputHandler,
  AccountInputHandlerProps,
  checkAccountExt,
} from 'pages/AccountView/utils'
import { useAccountValidation } from 'pages/AccountView/hooks/useAccountValidation'
import Spinner from 'app-view/components/Spinner'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { checkIfAccountIsRegistered } from 'app-engine/services'

export const CreateAccount = () => {
  const { t } = useTranslation(['account'])
  const location = useLocation()
  const navigate = useNavigate()
  const [{ referrer }, { setRegistrationData }] = useRegistration()
  const [{ loading, hint }, { validateUserAccount, updateState }] = useAccountValidation()

  const form = useForm<FormData>({
    defaultValues: {
      account: '',
      referrer: referrer || (new URLSearchParams(location.search).get('referrer') ?? ''),
    },
  })

  const onSubmit = useCallback(
    (data: FormData) => {
      setRegistrationData(data)
      navigate('/create-account/webauthn-cred')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRegistrationData], // navigate is not necessary it is a stable function
  )

  const account_input_props: AccountInputHandlerProps = {
    form,
    field: 'account',
    message: t('create_account_user_account_error_pattern'),
  }

  const isAccountInputValid = Object.keys(form.formState.errors).length === 0

  const shouldOmitSubmit =
    loading || !isAccountInputValid || form.formState.isValidating || form.formState.isSubmitting

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <div>
          <H2>{t('create_account_title')}</H2>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('create_account_format_description'),
            }}
          />
        </div>
        <Controller
          name="account"
          control={form.control}
          rules={{
            required: t('create_account_user_account_error_required') as string,
            pattern: {
              value: /^([a-z]|[1-5]){1,9}$/,
              message: t('create_account_user_account_error_pattern'),
            },
            validate: () =>
              shouldOmitSubmit
                ? (t('create_account_user_account_error_unavailable') as string)
                : true,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={12}>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (!loading) updateState({ loading: true })
                    if (hint) updateState({ hint: '' })

                    accountInputHandler(event, account_input_props, async () => {
                      await validateUserAccount(form, true)
                    })
                  }}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    if (!isAccountInputValid) return

                    accountInputHandler(event, account_input_props, async () => {
                      await validateUserAccount(form, true)
                    })
                  }}
                  type="text"
                  placeholder={t('create_account_user_account_placeholder')}
                  maxLength={9}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
                {loading && field.value && isAccountInputValid && <Spinner boxSize={20} />}
              </InputSection>
              {!form.formState.isValidating && (fieldState.error || hint) && (
                <HintError align="left">{fieldState.error?.message || hint}</HintError>
              )}
            </InputWrapper>
          )}
        />
        <Controller
          name="referrer"
          control={form.control}
          rules={{
            required: t('create_account_referrer_error_required') as string,
            pattern: {
              value: /^([a-z]|[1-5]|.){1,12}$/,
              message: t('create_account_user_account_error_pattern'),
            },
            validate: async (referrer) => {
              const accountExists = await checkIfAccountIsRegistered(
                checkAccountExt(referrer || ''),
              )
              return !accountExists
                ? (t('create_account_referrer_error_unexistent') as string)
                : true
            },
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mb={32} mt={64}>
              <Label size="lg" pl={0} mb={32}>
                {t('create_account_referrer_label')}
              </Label>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    accountInputHandler(event, {
                      form,
                      field: 'referrer',
                      message: t('create_account_referrer_account_error_pattern'),
                      referrer: true,
                    })
                  }
                  placeholder={`${t('create_account_referrer_account_placeholder')}`}
                  maxLength={12}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error.message}</HintError>
              )}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      <div>
        <LargeGreenButton type="submit" disabled={shouldOmitSubmit}>
          {t('create_account_accounts_submit')}
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={() => navigate('/')}>
          {t('p2p:not_now')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
