import { StoreSlice } from 'app-engine/store'

export type OnboardingSliceState = {
  onboarding: {
    language?: string
    currency?: string
    notification?: boolean
    lastSkip: number
  }
}

type OnboardingSliceActions = {
  setOnboardingLanguage: (value: string) => void
  setOnboardingCurrency: (value: string) => void
  setOnboardingNotification: (value: boolean) => void
  reset: () => void
}

export type OnboardingSlice = OnboardingSliceState & OnboardingSliceActions

const defaultOnboardingState = {
  onboarding: {
    lastSkip: 0,
  },
}

export const createOnboardingSlice: StoreSlice<OnboardingSlice> = (set, get) => ({
  ...defaultOnboardingState,
  reset: () => {
    set(defaultOnboardingState)
  },
  setOnboardingLanguage: (value: string) => {
    set({
      onboarding: {
        ...get().onboarding,
        language: value,
        lastSkip: Date.now(),
      },
    })
  },
  setOnboardingCurrency: (value: string) => {
    set({
      onboarding: {
        ...get().onboarding,
        currency: value,
        lastSkip: Date.now(),
      },
    })
  },
  setOnboardingNotification: (value: boolean) => {
    set({
      onboarding: {
        ...get().onboarding,
        notification: value,
        lastSkip: Date.now(),
      },
    })
  },
})
