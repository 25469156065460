import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { buyerCryptoAmountLessP2PFee, getDAOAmountFee, get_amount } from 'app-view/lib/utils'
import { Payment } from '../common/Payment'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const SellPayment = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()

  return (
    <Payment>
      {t('sell_payment', {
        buyer: offer.buyerLabel as string,
        cryptoAmount: buyerCryptoAmountLessP2PFee(offer.amount),
        cryptoFee: getDAOAmountFee(offer.amount),
        amount: get_amount(offer.amount),
        method: `${methodLabel(offer.method)}${
          !offer.method.match(/(bank_transfer|oxxo)/) ? '?' : ''
        }`,
        methodDetails: isMethodDetails
          ? sellerMethodDetailsLabelData.replace(/(?:.(?!\.))+$/, '?')
          : '',
      })}
    </Payment>
  )
}
