import { ProfileMethodStep } from './ProfileMethodStep'
import { WizardStep } from 'app-view/components/Wizard'
import { MethodDetailsStep } from './MethodDetailsStep'

export const AddPaymentMethodSteps: WizardStep[] = [
  {
    name: 'method',
    component: ProfileMethodStep,
  },
  {
    name: 'method-details',
    component: MethodDetailsStep,
  },
]

export const EditPaymentMethodSteps: WizardStep[] = [
  {
    name: 'method-details',
    component: MethodDetailsStep,
  },
]
