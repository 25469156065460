export default {
  create_account_title: 'Crea tu cuenta de usuario',
  create_account_format_description:
    'Por favor cree un nombre de cuenta único y memorable de 9 caracteres usando letras entre <b>a-z</b> y números <b>1-5</b>.',
  create_account_user_account_placeholder: 'Escriba la cuenta de usuario',
  create_account_user_account_error_required: 'Por favor ingrese el nombre de cuenta',
  create_account_user_account_error_pattern: 'Solo se permiten a-z y 1-5',
  create_account_referrer_account_error_pattern:
    'Solo se permiten a-z, 1-5 y cuentas que terminan en .bk',
  create_account_user_account_error_unavailable:
    '¡Cuenta no disponible! Por favor, intente con otra.',
  create_account_referrer_label: 'Cuenta de Referencia',
  create_account_referrer_account_placeholder: 'referrer1',
  create_account_referrer_error_required: 'Por favor ingrese su referente',
  create_account_referrer_error_not_registered: 'El referente no está registrado',
  create_account_accounts_submit: 'Confirmar',
  create_account_key_title: 'Agregar clave de Smartphone',
  create_account_key_description:
    "Su dispositivo de smartphone será la clave principal para acceder a su cuenta. Seleccione la opción 'Usar este dispositivo con bloqueo de pantalla' cuando se le solicite después de hacer clic en el botón a continuación.",
  create_account_key_device_label: 'Nombre del dispositivo',
  create_account_key_device_placeholder: 'Ingrese el nombre de su dispositivo',
  create_account_key_device_error_required: 'Por favor ingrese el nombre de su dispositivo',
  create_account_key_submit: 'Siguiente',
  register_account_success_text:
    'Su registro fue enviado con éxito. Informe a su referente o únase al grupo grupobitcashBank en Discord para acelerar la aprobación.',
  create_account_success_text:
    'Su creación de cuenta fue realizada con éxito. Informe a su referente o únase al servidor bitcashBank en Discord para acelerar la aprobación.',
  register_account_success_button: 'Abrir Discord',
  register_account_title: 'Registre su cuenta de usuario',
  account_title: 'Nombre de la cuenta',
  account_title_description:
    'Por favor ingrese el nombre de la cuenta en la que desea iniciar sesión con este dispositivo.',
  account_setup_account_button: 'Ingresar con Dispositivo',
  create_account_referrer_error_unexistent:
    'La cuenta no se encuentra registrada o aprobada por bitcashBank.',
  account_user_account_available: 'Esta cuenta está disponible',
  account_user_account_registered: 'Esta cuenta es válida',
  account_user_account_not_registered: 'La cuenta no está registrada',
  add_device_account_title: 'Ingrese el nombre de la cuenta existente',
  migrate_device_account_title: 'Ingrese el nombre de la cuenta existente',
  add_device_account_description:
    'Esta debe ser una cuenta que haya usado en un dispositivo anterior. Necesitará acceso a ese dispositivo para aprobar la adición de otra clave.',
  migrate_device_account_description:
    'Esta debe ser una cuenta que haya usado en un dispositivo anterior.',
  add_device_key_title: 'Clave del dispositivo',
  add_device_key_description:
    '<p>Le gustaría agregar este smartphone o una clave de seguridad (es decir, Yubikey) como clave adicional para acceder a <b>{{account}}</b>?</p><p>Si desea utilizar este smartphone, seleccione <b>Touch o Face ID</b> para usar este dispositivo como una clave cuando se le solicite después de hacer clic en el botón a continuación.</p><p>De lo contrario, si tiene una clave de seguridad, seleccione la opción <b>Usar clave de seguridad</b> cuando se le solicite.</p>',
  add_device_key_submit: 'Agregar Clave',
  add_device_success_description:
    'Utilice su dispositivo original para aprobar la adición de esta nueva clave a <b>{{account}}</b>',
  migrate_device_success_description: 'Espere que su cuenta sea migrada por el administrador.',
  select_device_title: 'Seleccione un dispositivo',
  select_device_description: 'Por favor, seleccione uno de sus dispositivos para iniciar sesión.',
  new_device: 'Nuevo Dispositivo',
  continue: 'Continuar',
  warning_apple_user: 'Advertencia Usuario Apple',
  warning_apple_user_modal_info_browser:
    'Recuerde registrar el navegador nuevo como un <0>Dispositivo nuevo</0> si va a registrar esta cuenta con datos biométricos.',
  warning_apple_user_modal_info_confirm:
    'Entendido, me aseguraré de registrar un nuevo navegador antes de usarlo.',
  list_devices: '{{account}} lista de dispositivos',
  singup_invitation:
    'Bitcash App powered by bitcashBank la beta es sólo por invitación.<br /> <br /> Por favor viste <a href="https://bitcash.org">bitcash.org</a> para recibir una invitación.',
  approved_accounts: 'Cuentas aprobadas',
  pending_accounts: 'Cuentas pendientes',
  approving_account: 'Aprobando Cuenta',
  rejecting_account: 'Rechazando Cuenta',
  successfully_rejected: '¡Cuenta {{account}} rechazada con éxito!',
  successfully_approved: '¡Cuenta {{account}} aprobada con éxito!',

  country_id_label: 'ID',
  phone_number_label: 'Número de Teléfono:',
  email_address_label: 'Correo Electrónico:',
  subscribe_to_newsletter: 'Manténganme informado sobre eventos y educación en criptomonedas.',
  phone_number_placeholder: '{{phoneNumberCountryCode}}#teléfono',
  include_country_code: 'Incluya el código de país',
  create_account_kyc_title: 'Verifica tu cuenta',
  create_account_kyc_description:
    'Por favor, ingrese su número de teléfono y dirección de correo electrónico a continuación para completar el registro de su cuenta. Utilizaremos esta información para verificar su identidad y recuperar su cuenta si pierde su dispositivo.',
  invalid_phone_provenance:
    'La procedencia del número de teléfono es inválida. Inténtalo de nuevo o utiliza otro número.',
  phone_valid: '¡Número de teléfono válido!',
  account_details:
    'Por favor ingrese la siguiente información para fines de recuperación de cuenta:',
  this_country_id_already_registered: 'Esta identificación ya estaba registrada.',
  this_email_already_registered: 'Este correo electrónico ya estaba registrado.',
  email_valid: 'El correo electrónico es válido.',
  id_invalid: 'Identificación no válida.',
  email_invalid: 'El correo no es válido.',
  phone_invalid: 'Número de teléfono no es válido.',
  full_name_invalid: 'Nombre no válido.',
  this_phone_number_already_registered: 'Este número de teléfono ya estaba registrado.',
  loading_action: 'Procesando firma...',
  user_verified: 'Usuario verificada',
  created_at: 'Creado en',
  approve: 'Aprobar',
  selfie_label: 'Identificación con fotografía',
  photo_invalid: 'Identificación con fotografía no válida.',
  continue_verification: 'Continuar verificación',
  cancel_verification: 'Quizas mas tarde',
  selfie_notification_regular: `Al priorizar su seguridad, requerimos verificación de identidad para la recuperación de la cuenta, la protección y las recompensas de la aplicación.`,
  selfie_notification_investor: 'Asegúrese de que su selfie y su identificación con foto incluyan:',
  selfie_notifications_1: `<ul>
    <li>Rostro completo e identificación visible.</li>
    <li>Sin accesorios.</li>
    <li>Ambos ojos abiertos, expresión neutra.</li>
   </ul>`,
  selfie_notifications_2: `<ul>
  <li>Entorno claro y bien iluminado.</li>
  <li>Fondo liso.</li>
  <li>Sin filtros ni modificaciones.</li>
  </ul>`,
  photo_id_notifications: `<ul>
  <li>Alinee su identificación dentro del marco de su pantalla y será visible.</li>
  <li>La fotografía debe tomarse en un ambiente bien iluminado y sin reflejos.</li>
  <li>Ninguna parte del documento de identidad debe quedar cubierta por los dedos ni por ningún objeto.</li>
  </ul>`,
  require_permission: 'Requerimos su cámara y permiso de GPS para calificar para la selfie.',
  grant_permission: 'Conceder permisos',
  granting_permission: 'Concediendo permisos...',
  take_selfie: 'Tomarse una selfie',
  retake_selfie: 'Retomar selfie',
  verifying: 'Verificando...',
  photo_is_ok: 'La foto está bien.',
  processing_error: 'Algo salió mal al procesar tu foto.',
  invalid_photo: 'La foto no cumple con los requisitos.',
  processing: 'Procesando...',
  download_selfie: 'Descargar selfie',
  take_selfie_id: 'Tomar selfie ID',
  retake_photos: 'Retomar fotos',
  invalid_photo_id: 'Documento no visible.',
  take_selfie_instructions: 'Instrucciones para tomar fotos selfies',
  take_id_card_photo_instructions:
    'Instrucciones para la fotografía de la tarjeta de identificación',
  question_kyc_reason: '¿Por qué tengo que otorgar permisos?',
  return: 'Devolver',
  kyc_help_text_1:
    'Estas medidas de seguridad protegen su cuenta del acceso no autorizado y garantizan que usted sea el propietario legítimo. También nos permite otorgar con precisión recompensas de aplicaciones adaptadas a su región.',
  kyc_help_text_2:
    'Una vez que esté listo, regrese y haga clic en "Continuar verificación" para continuar o seleccione "Quizás más tarde" si necesita preparar su identificación o ajustar la configuración de su dispositivo.',
  how_to_grant_permission: '¿CÓMO CONCEDER EL PERMISO?',
  please_grant_bitcash_app_permission:
    'Otorgue permiso a la aplicación Bitcash para acceder a su cámara y ubicación.',
  for_android: 'Para Android',
  android_setting: `<ol>
    <li>Vaya a 'Configuración' > 'Aplicaciones y notificaciones' > 'Permisos de aplicaciones'.</li>
    <li>Seleccione "aplicación bitcash" y active "Cámara" y "Ubicación".</li>
  </ol> `,
  additional_resources: 'Recursos adicionales',
  for_android_devices_via:
    'Para dispositivos Android a través del portal de ayuda de Scribble Maps',
  for_ios: 'Para iOS',
  ios_setting: `<ol>
    <li>Vaya a 'Configuración' > 'Privacidad' > 'Servicios de ubicación'.</li>
    <li>Busque la "aplicación bitcash" en la lista y establezca el permiso en "Mientras se usa la aplicación".</li>
  </ol> `,
  for_ios_devices_via: 'Para dispositivos iOS a través del portal de ayuda de Scribble Maps',
  for_chrome_specific: 'Para obtener instrucciones específicas de Chrome',
  enable_gps_location: 'Habilitar GPS/Ubicación',
  google_chrome_support: 'Soporte de Google Chrome',
}
