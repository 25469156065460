export const globalCurrencies = [
  {
    name: 'andorra',
    cca2: 'AD',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'andorra',
    flag: 'https://flagcdn.com/ad.svg',
  },
  {
    name: 'united arab emirates',
    cca2: 'AE',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'united arab emirates',
    flag: 'https://flagcdn.com/ae.svg',
  },
  {
    name: 'afghanistan',
    cca2: 'AF',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'afghanistan',
    flag: 'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg',
  },
  {
    name: 'antigua and barbuda',
    cca2: 'AG',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'antigua and barbuda',
    flag: 'https://flagcdn.com/ag.svg',
  },
  {
    name: 'anguilla',
    cca2: 'AI',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'anguilla',
    flag: 'https://flagcdn.com/ai.svg',
  },
  {
    name: 'albania',
    cca2: 'AL',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'albania',
    flag: 'https://flagcdn.com/al.svg',
  },
  {
    name: 'armenia',
    cca2: 'AM',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'armenia',
    flag: 'https://flagcdn.com/am.svg',
  },
  {
    name: 'angola',
    cca2: 'AO',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'angola',
    flag: 'https://flagcdn.com/ao.svg',
  },
  {
    name: 'antarctica',
    cca2: 'AQ',
    region: 'antarctic',
    subregion: '',
    commonName: 'antarctica',
    flag: 'https://flagcdn.com/aq.svg',
  },
  {
    name: 'argentina',
    cca2: 'AR',
    region: 'americas',
    subregion: 'south america',
    commonName: 'argentina',
    flag: 'https://flagcdn.com/ar.svg',
  },
  {
    name: 'american samoa',
    cca2: 'AS',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'american samoa',
    flag: 'https://flagcdn.com/as.svg',
  },
  {
    name: 'austria',
    cca2: 'AT',
    region: 'europe',
    subregion: 'central europe',
    commonName: 'austria',
    flag: 'https://flagcdn.com/at.svg',
  },
  {
    name: 'australia',
    cca2: 'AU',
    region: 'oceania',
    subregion: 'australia and new zealand',
    commonName: 'australia',
    flag: 'https://flagcdn.com/au.svg',
  },
  {
    name: 'aruba',
    cca2: 'AW',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'aruba',
    flag: 'https://flagcdn.com/aw.svg',
  },
  {
    name: 'åland islands',
    cca2: 'AX',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'åland islands',
    flag: 'https://flagcdn.com/ax.svg',
  },
  {
    name: 'azerbaijan',
    cca2: 'AZ',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'azerbaijan',
    flag: 'https://flagcdn.com/az.svg',
  },
  {
    name: 'bosnia and herzegovina',
    cca2: 'BA',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'bosnia and herzegovina',
    flag: 'https://flagcdn.com/ba.svg',
  },
  {
    name: 'barbados',
    cca2: 'BB',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'barbados',
    flag: 'https://flagcdn.com/bb.svg',
  },
  {
    name: 'bangladesh',
    cca2: 'BD',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'bangladesh',
    flag: 'https://flagcdn.com/bd.svg',
  },
  {
    name: 'belgium',
    cca2: 'BE',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'belgium',
    flag: 'https://flagcdn.com/be.svg',
  },
  {
    name: 'burkina faso',
    cca2: 'BF',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'burkina faso',
    flag: 'https://flagcdn.com/bf.svg',
  },
  {
    name: 'bulgaria',
    cca2: 'BG',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'bulgaria',
    flag: 'https://flagcdn.com/bg.svg',
  },
  {
    name: 'bahrain',
    cca2: 'BH',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'bahrain',
    flag: 'https://flagcdn.com/bh.svg',
  },
  {
    name: 'burundi',
    cca2: 'BI',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'burundi',
    flag: 'https://flagcdn.com/bi.svg',
  },
  {
    name: 'benin',
    cca2: 'BJ',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'benin',
    flag: 'https://flagcdn.com/bj.svg',
  },
  {
    name: 'saint barthélemy',
    cca2: 'BL',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'saint barthélemy',
    flag: 'https://flagcdn.com/bl.svg',
  },
  {
    name: 'bermuda',
    cca2: 'BM',
    region: 'americas',
    subregion: 'north america',
    commonName: 'bermuda',
    flag: 'https://flagcdn.com/bm.svg',
  },
  {
    name: 'brunei',
    cca2: 'BN',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'brunei',
    flag: 'https://flagcdn.com/bn.svg',
  },
  {
    name: 'bolivia',
    cca2: 'BO',
    region: 'americas',
    subregion: 'south america',
    commonName: 'bolivia',
    flag: 'https://flagcdn.com/bo.svg',
  },
  {
    name: 'caribbean netherlands',
    cca2: 'BQ',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'caribbean netherlands',
    flag: 'https://flagcdn.com/bq.svg',
  },
  {
    name: 'brazil',
    cca2: 'BR',
    region: 'americas',
    subregion: 'south america',
    commonName: 'brazil',
    flag: 'https://flagcdn.com/br.svg',
  },
  {
    name: 'bahamas',
    cca2: 'BS',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'bahamas',
    flag: 'https://flagcdn.com/bs.svg',
  },
  {
    name: 'bhutan',
    cca2: 'BT',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'bhutan',
    flag: 'https://flagcdn.com/bt.svg',
  },
  {
    name: 'bouvet island',
    cca2: 'BV',
    region: 'antarctic',
    subregion: '',
    commonName: 'bouvet island',
    flag: 'https://flagcdn.com/bv.svg',
  },
  {
    name: 'botswana',
    cca2: 'BW',
    region: 'africa',
    subregion: 'southern africa',
    commonName: 'botswana',
    flag: 'https://flagcdn.com/bw.svg',
  },
  {
    name: 'belarus',
    cca2: 'BY',
    region: 'europe',
    subregion: 'eastern europe',
    commonName: 'belarus',
    flag: 'https://flagcdn.com/by.svg',
  },
  {
    name: 'belize',
    cca2: 'BZ',
    region: 'americas',
    subregion: 'central america',
    commonName: 'belize',
    flag: 'https://flagcdn.com/bz.svg',
  },
  {
    name: 'canada',
    cca2: 'CA',
    region: 'americas',
    subregion: 'north america',
    commonName: 'canada',
    flag: 'https://flagcdn.com/ca.svg',
  },
  {
    name: 'cocos (keeling) islands',
    cca2: 'CC',
    region: 'oceania',
    subregion: 'australia and new zealand',
    commonName: 'cocos (keeling) islands',
    flag: 'https://flagcdn.com/cc.svg',
  },
  {
    name: 'dr congo',
    cca2: 'CD',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'dr congo',
    flag: 'https://flagcdn.com/cd.svg',
  },
  {
    name: 'central african republic',
    cca2: 'CF',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'central african republic',
    flag: 'https://flagcdn.com/cf.svg',
  },
  {
    name: 'republic of the congo',
    cca2: 'CG',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'republic of the congo',
    flag: 'https://flagcdn.com/cg.svg',
  },
  {
    name: 'switzerland',
    cca2: 'CH',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'switzerland',
    flag: 'https://flagcdn.com/ch.svg',
  },
  {
    name: 'ivory coast',
    cca2: 'CI',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'ivory coast',
    flag: 'https://flagcdn.com/ci.svg',
  },
  {
    name: 'cook islands',
    cca2: 'CK',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'cook islands',
    flag: 'https://flagcdn.com/ck.svg',
  },
  {
    name: 'chile',
    cca2: 'CL',
    region: 'americas',
    subregion: 'south america',
    commonName: 'chile',
    flag: 'https://flagcdn.com/cl.svg',
  },
  {
    name: 'cameroon',
    cca2: 'CM',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'cameroon',
    flag: 'https://flagcdn.com/cm.svg',
  },
  {
    name: 'china',
    cca2: 'CN',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'china',
    flag: 'https://flagcdn.com/cn.svg',
  },
  {
    name: 'colombia',
    cca2: 'CO',
    region: 'americas',
    subregion: 'south america',
    commonName: 'colombia',
    flag: 'https://flagcdn.com/co.svg',
  },
  {
    name: 'costa rica',
    cca2: 'CR',
    region: 'americas',
    subregion: 'central america',
    commonName: 'costa rica',
    flag: 'https://flagcdn.com/cr.svg',
  },
  {
    name: 'cuba',
    cca2: 'CU',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'cuba',
    flag: 'https://flagcdn.com/cu.svg',
  },
  {
    name: 'cape verde',
    cca2: 'CV',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'cape verde',
    flag: 'https://flagcdn.com/cv.svg',
  },
  {
    name: 'curaçao',
    cca2: 'CW',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'curaçao',
    flag: 'https://flagcdn.com/cw.svg',
  },
  {
    name: 'christmas island',
    cca2: 'CX',
    region: 'oceania',
    subregion: 'australia and new zealand',
    commonName: 'christmas island',
    flag: 'https://flagcdn.com/cx.svg',
  },
  {
    name: 'cyprus',
    cca2: 'CY',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'cyprus',
    flag: 'https://flagcdn.com/cy.svg',
  },
  {
    name: 'czechia',
    cca2: 'CZ',
    region: 'europe',
    subregion: 'central europe',
    commonName: 'czechia',
    flag: 'https://flagcdn.com/cz.svg',
  },
  {
    name: 'germany',
    cca2: 'DE',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'germany',
    flag: 'https://flagcdn.com/de.svg',
  },
  {
    name: 'djibouti',
    cca2: 'DJ',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'djibouti',
    flag: 'https://flagcdn.com/dj.svg',
  },
  {
    name: 'denmark',
    cca2: 'DK',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'denmark',
    flag: 'https://flagcdn.com/dk.svg',
  },
  {
    name: 'dominica',
    cca2: 'DM',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'dominica',
    flag: 'https://flagcdn.com/dm.svg',
  },
  {
    name: 'dominican republic',
    cca2: 'DO',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'dominican republic',
    flag: 'https://flagcdn.com/do.svg',
  },
  {
    name: 'algeria',
    cca2: 'DZ',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'algeria',
    flag: 'https://flagcdn.com/dz.svg',
  },
  {
    name: 'ecuador',
    cca2: 'EC',
    region: 'americas',
    subregion: 'south america',
    commonName: 'ecuador',
    flag: 'https://flagcdn.com/ec.svg',
  },
  {
    name: 'estonia',
    cca2: 'EE',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'estonia',
    flag: 'https://flagcdn.com/ee.svg',
  },
  {
    name: 'egypt',
    cca2: 'EG',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'egypt',
    flag: 'https://flagcdn.com/eg.svg',
  },
  {
    name: 'western sahara',
    cca2: 'EH',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'western sahara',
    flag: 'https://flagcdn.com/eh.svg',
  },
  {
    name: 'eritrea',
    cca2: 'ER',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'eritrea',
    flag: 'https://flagcdn.com/er.svg',
  },
  {
    name: 'spain',
    cca2: 'ES',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'spain',
    flag: 'https://flagcdn.com/es.svg',
  },
  {
    name: 'ethiopia',
    cca2: 'ET',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'ethiopia',
    flag: 'https://flagcdn.com/et.svg',
  },
  {
    name: 'finland',
    cca2: 'FI',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'finland',
    flag: 'https://flagcdn.com/fi.svg',
  },
  {
    name: 'fiji',
    cca2: 'FJ',
    region: 'oceania',
    subregion: 'melanesia',
    commonName: 'fiji',
    flag: 'https://flagcdn.com/fj.svg',
  },
  {
    name: 'falkland islands',
    cca2: 'FK',
    region: 'americas',
    subregion: 'south america',
    commonName: 'falkland islands',
    flag: 'https://flagcdn.com/fk.svg',
  },
  {
    name: 'micronesia',
    cca2: 'FM',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'micronesia',
    flag: 'https://flagcdn.com/fm.svg',
  },
  {
    name: 'faroe islands',
    cca2: 'FO',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'faroe islands',
    flag: 'https://flagcdn.com/fo.svg',
  },
  {
    name: 'france',
    cca2: 'FR',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'france',
    flag: 'https://flagcdn.com/fr.svg',
  },
  {
    name: 'gabon',
    cca2: 'GA',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'gabon',
    flag: 'https://flagcdn.com/ga.svg',
  },
  {
    name: 'united kingdom',
    cca2: 'GB',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'united kingdom',
    flag: 'https://flagcdn.com/gb.svg',
  },
  {
    name: 'grenada',
    cca2: 'GD',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'grenada',
    flag: 'https://flagcdn.com/gd.svg',
  },
  {
    name: 'georgia',
    cca2: 'GE',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'georgia',
    flag: 'https://flagcdn.com/ge.svg',
  },
  {
    name: 'french guiana',
    cca2: 'GF',
    region: 'americas',
    subregion: 'south america',
    commonName: 'french guiana',
    flag: 'https://flagcdn.com/gf.svg',
  },
  {
    name: 'guernsey',
    cca2: 'GG',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'guernsey',
    flag: 'https://flagcdn.com/gg.svg',
  },
  {
    name: 'ghana',
    cca2: 'GH',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'ghana',
    flag: 'https://flagcdn.com/gh.svg',
  },
  {
    name: 'gibraltar',
    cca2: 'GI',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'gibraltar',
    flag: 'https://flagcdn.com/gi.svg',
  },
  {
    name: 'greenland',
    cca2: 'GL',
    region: 'americas',
    subregion: 'north america',
    commonName: 'greenland',
    flag: 'https://flagcdn.com/gl.svg',
  },
  {
    name: 'gambia',
    cca2: 'GM',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'gambia',
    flag: 'https://flagcdn.com/gm.svg',
  },
  {
    name: 'guinea',
    cca2: 'GN',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'guinea',
    flag: 'https://flagcdn.com/gn.svg',
  },
  {
    name: 'guadeloupe',
    cca2: 'GP',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'guadeloupe',
    flag: 'https://flagcdn.com/gp.svg',
  },
  {
    name: 'equatorial guinea',
    cca2: 'GQ',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'equatorial guinea',
    flag: 'https://flagcdn.com/gq.svg',
  },
  {
    name: 'greece',
    cca2: 'GR',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'greece',
    flag: 'https://flagcdn.com/gr.svg',
  },
  {
    name: 'south georgia',
    cca2: 'GS',
    region: 'antarctic',
    subregion: '',
    commonName: 'south georgia',
    flag: 'https://flagcdn.com/gs.svg',
  },
  {
    name: 'guatemala',
    cca2: 'GT',
    region: 'americas',
    subregion: 'central america',
    commonName: 'guatemala',
    flag: 'https://flagcdn.com/gt.svg',
  },
  {
    name: 'guam',
    cca2: 'GU',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'guam',
    flag: 'https://flagcdn.com/gu.svg',
  },
  {
    name: 'guinea-bissau',
    cca2: 'GW',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'guinea-bissau',
    flag: 'https://flagcdn.com/gw.svg',
  },
  {
    name: 'guyana',
    cca2: 'GY',
    region: 'americas',
    subregion: 'south america',
    commonName: 'guyana',
    flag: 'https://flagcdn.com/gy.svg',
  },
  {
    name: 'hong kong',
    cca2: 'HK',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'hong kong',
    flag: 'https://flagcdn.com/hk.svg',
  },
  {
    name: 'heard island and mcdonald islands',
    cca2: 'HM',
    region: 'antarctic',
    subregion: '',
    commonName: 'heard island and mcdonald islands',
    flag: 'https://flagcdn.com/hm.svg',
  },
  {
    name: 'honduras',
    cca2: 'HN',
    region: 'americas',
    subregion: 'central america',
    commonName: 'honduras',
    flag: 'https://flagcdn.com/hn.svg',
  },
  {
    name: 'croatia',
    cca2: 'HR',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'croatia',
    flag: 'https://flagcdn.com/hr.svg',
  },
  {
    name: 'haiti',
    cca2: 'HT',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'haiti',
    flag: 'https://flagcdn.com/ht.svg',
  },
  {
    name: 'hungary',
    cca2: 'HU',
    region: 'europe',
    subregion: 'central europe',
    commonName: 'hungary',
    flag: 'https://flagcdn.com/hu.svg',
  },
  {
    name: 'indonesia',
    cca2: 'ID',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'indonesia',
    flag: 'https://flagcdn.com/id.svg',
  },
  {
    name: 'ireland',
    cca2: 'IE',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'ireland',
    flag: 'https://flagcdn.com/ie.svg',
  },
  {
    name: 'israel',
    cca2: 'IL',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'israel',
    flag: 'https://flagcdn.com/il.svg',
  },
  {
    name: 'isle of man',
    cca2: 'IM',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'isle of man',
    flag: 'https://flagcdn.com/im.svg',
  },
  {
    name: 'india',
    cca2: 'IN',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'india',
    flag: 'https://flagcdn.com/in.svg',
  },
  {
    name: 'british indian ocean territory',
    cca2: 'IO',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'british indian ocean territory',
    flag: 'https://flagcdn.com/io.svg',
  },
  {
    name: 'iraq',
    cca2: 'IQ',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'iraq',
    flag: 'https://flagcdn.com/iq.svg',
  },
  {
    name: 'iran',
    cca2: 'IR',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'iran',
    flag: 'https://flagcdn.com/ir.svg',
  },
  {
    name: 'iceland',
    cca2: 'IS',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'iceland',
    flag: 'https://flagcdn.com/is.svg',
  },
  {
    name: 'italy',
    cca2: 'IT',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'italy',
    flag: 'https://flagcdn.com/it.svg',
  },
  {
    name: 'jersey',
    cca2: 'JE',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'jersey',
    flag: 'https://flagcdn.com/je.svg',
  },
  {
    name: 'jamaica',
    cca2: 'JM',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'jamaica',
    flag: 'https://flagcdn.com/jm.svg',
  },
  {
    name: 'jordan',
    cca2: 'JO',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'jordan',
    flag: 'https://flagcdn.com/jo.svg',
  },
  {
    name: 'japan',
    cca2: 'JP',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'japan',
    flag: 'https://flagcdn.com/jp.svg',
  },
  {
    name: 'kenya',
    cca2: 'KE',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'kenya',
    flag: 'https://flagcdn.com/ke.svg',
  },
  {
    name: 'kyrgyzstan',
    cca2: 'KG',
    region: 'asia',
    subregion: 'central asia',
    commonName: 'kyrgyzstan',
    flag: 'https://flagcdn.com/kg.svg',
  },
  {
    name: 'cambodia',
    cca2: 'KH',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'cambodia',
    flag: 'https://flagcdn.com/kh.svg',
  },
  {
    name: 'kiribati',
    cca2: 'KI',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'kiribati',
    flag: 'https://flagcdn.com/ki.svg',
  },
  {
    name: 'comoros',
    cca2: 'KM',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'comoros',
    flag: 'https://flagcdn.com/km.svg',
  },
  {
    name: 'saint kitts and nevis',
    cca2: 'KN',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'saint kitts and nevis',
    flag: 'https://flagcdn.com/kn.svg',
  },
  {
    name: 'north korea',
    cca2: 'KP',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'north korea',
    flag: 'https://flagcdn.com/kp.svg',
  },
  {
    name: 'south korea',
    cca2: 'KR',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'south korea',
    flag: 'https://flagcdn.com/kr.svg',
  },
  {
    name: 'kuwait',
    cca2: 'KW',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'kuwait',
    flag: 'https://flagcdn.com/kw.svg',
  },
  {
    name: 'cayman islands',
    cca2: 'KY',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'cayman islands',
    flag: 'https://flagcdn.com/ky.svg',
  },
  {
    name: 'kazakhstan',
    cca2: 'KZ',
    region: 'asia',
    subregion: 'central asia',
    commonName: 'kazakhstan',
    flag: 'https://flagcdn.com/kz.svg',
  },
  {
    name: 'laos',
    cca2: 'LA',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'laos',
    flag: 'https://flagcdn.com/la.svg',
  },
  {
    name: 'lebanon',
    cca2: 'LB',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'lebanon',
    flag: 'https://flagcdn.com/lb.svg',
  },
  {
    name: 'saint lucia',
    cca2: 'LC',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'saint lucia',
    flag: 'https://flagcdn.com/lc.svg',
  },
  {
    name: 'liechtenstein',
    cca2: 'LI',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'liechtenstein',
    flag: 'https://flagcdn.com/li.svg',
  },
  {
    name: 'sri lanka',
    cca2: 'LK',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'sri lanka',
    flag: 'https://flagcdn.com/lk.svg',
  },
  {
    name: 'liberia',
    cca2: 'LR',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'liberia',
    flag: 'https://flagcdn.com/lr.svg',
  },
  {
    name: 'lesotho',
    cca2: 'LS',
    region: 'africa',
    subregion: 'southern africa',
    commonName: 'lesotho',
    flag: 'https://flagcdn.com/ls.svg',
  },
  {
    name: 'lithuania',
    cca2: 'LT',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'lithuania',
    flag: 'https://flagcdn.com/lt.svg',
  },
  {
    name: 'luxembourg',
    cca2: 'LU',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'luxembourg',
    flag: 'https://flagcdn.com/lu.svg',
  },
  {
    name: 'latvia',
    cca2: 'LV',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'latvia',
    flag: 'https://flagcdn.com/lv.svg',
  },
  {
    name: 'libya',
    cca2: 'LY',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'libya',
    flag: 'https://flagcdn.com/ly.svg',
  },
  {
    name: 'morocco',
    cca2: 'MA',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'morocco',
    flag: 'https://flagcdn.com/ma.svg',
  },
  {
    name: 'monaco',
    cca2: 'MC',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'monaco',
    flag: 'https://flagcdn.com/mc.svg',
  },
  {
    name: 'moldova',
    cca2: 'MD',
    region: 'europe',
    subregion: 'eastern europe',
    commonName: 'moldova',
    flag: 'https://flagcdn.com/md.svg',
  },
  {
    name: 'montenegro',
    cca2: 'ME',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'montenegro',
    flag: 'https://flagcdn.com/me.svg',
  },
  {
    name: 'saint martin',
    cca2: 'MF',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'saint martin',
    flag: 'https://flagcdn.com/mf.svg',
  },
  {
    name: 'madagascar',
    cca2: 'MG',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'madagascar',
    flag: 'https://flagcdn.com/mg.svg',
  },
  {
    name: 'marshall islands',
    cca2: 'MH',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'marshall islands',
    flag: 'https://flagcdn.com/mh.svg',
  },
  {
    name: 'north macedonia',
    cca2: 'MK',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'north macedonia',
    flag: 'https://flagcdn.com/mk.svg',
  },
  {
    name: 'mali',
    cca2: 'ML',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'mali',
    flag: 'https://flagcdn.com/ml.svg',
  },
  {
    name: 'myanmar',
    cca2: 'MM',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'myanmar',
    flag: 'https://flagcdn.com/mm.svg',
  },
  {
    name: 'mongolia',
    cca2: 'MN',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'mongolia',
    flag: 'https://flagcdn.com/mn.svg',
  },
  {
    name: 'macau',
    cca2: 'MO',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'macau',
    flag: 'https://flagcdn.com/mo.svg',
  },
  {
    name: 'northern mariana islands',
    cca2: 'MP',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'northern mariana islands',
    flag: 'https://flagcdn.com/mp.svg',
  },
  {
    name: 'martinique',
    cca2: 'MQ',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'martinique',
    flag: 'https://flagcdn.com/mq.svg',
  },
  {
    name: 'mauritania',
    cca2: 'MR',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'mauritania',
    flag: 'https://flagcdn.com/mr.svg',
  },
  {
    name: 'montserrat',
    cca2: 'MS',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'montserrat',
    flag: 'https://flagcdn.com/ms.svg',
  },
  {
    name: 'malta',
    cca2: 'MT',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'malta',
    flag: 'https://flagcdn.com/mt.svg',
  },
  {
    name: 'mauritius',
    cca2: 'MU',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'mauritius',
    flag: 'https://flagcdn.com/mu.svg',
  },
  {
    name: 'maldives',
    cca2: 'MV',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'maldives',
    flag: 'https://flagcdn.com/mv.svg',
  },
  {
    name: 'malawi',
    cca2: 'MW',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'malawi',
    flag: 'https://flagcdn.com/mw.svg',
  },
  {
    name: 'mexico',
    cca2: 'MX',
    region: 'americas',
    subregion: 'north america',
    commonName: 'mexico',
    flag: 'https://flagcdn.com/mx.svg',
  },
  {
    name: 'malaysia',
    cca2: 'MY',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'malaysia',
    flag: 'https://flagcdn.com/my.svg',
  },
  {
    name: 'mozambique',
    cca2: 'MZ',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'mozambique',
    flag: 'https://flagcdn.com/mz.svg',
  },
  {
    name: 'namibia',
    cca2: 'NA',
    region: 'africa',
    subregion: 'southern africa',
    commonName: 'namibia',
    flag: 'https://flagcdn.com/na.svg',
  },
  {
    name: 'new caledonia',
    cca2: 'NC',
    region: 'oceania',
    subregion: 'melanesia',
    commonName: 'new caledonia',
    flag: 'https://flagcdn.com/nc.svg',
  },
  {
    name: 'niger',
    cca2: 'NE',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'niger',
    flag: 'https://flagcdn.com/ne.svg',
  },
  {
    name: 'norfolk island',
    cca2: 'NF',
    region: 'oceania',
    subregion: 'australia and new zealand',
    commonName: 'norfolk island',
    flag: 'https://flagcdn.com/nf.svg',
  },
  {
    name: 'nigeria',
    cca2: 'NG',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'nigeria',
    flag: 'https://flagcdn.com/ng.svg',
  },
  {
    name: 'nicaragua',
    cca2: 'NI',
    region: 'americas',
    subregion: 'central america',
    commonName: 'nicaragua',
    flag: 'https://flagcdn.com/ni.svg',
  },
  {
    name: 'netherlands',
    cca2: 'NL',
    region: 'europe',
    subregion: 'western europe',
    commonName: 'netherlands',
    flag: 'https://flagcdn.com/nl.svg',
  },
  {
    name: 'norway',
    cca2: 'NO',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'norway',
    flag: 'https://flagcdn.com/no.svg',
  },
  {
    name: 'nepal',
    cca2: 'NP',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'nepal',
    flag: 'https://flagcdn.com/np.svg',
  },
  {
    name: 'nauru',
    cca2: 'NR',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'nauru',
    flag: 'https://flagcdn.com/nr.svg',
  },
  {
    name: 'niue',
    cca2: 'NU',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'niue',
    flag: 'https://flagcdn.com/nu.svg',
  },
  {
    name: 'new zealand',
    cca2: 'NZ',
    region: 'oceania',
    subregion: 'australia and new zealand',
    commonName: 'new zealand',
    flag: 'https://flagcdn.com/nz.svg',
  },
  {
    name: 'oman',
    cca2: 'OM',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'oman',
    flag: 'https://flagcdn.com/om.svg',
  },
  {
    name: 'panama',
    cca2: 'PA',
    region: 'americas',
    subregion: 'central america',
    commonName: 'panama',
    flag: 'https://flagcdn.com/pa.svg',
  },
  {
    name: 'peru',
    cca2: 'PE',
    region: 'americas',
    subregion: 'south america',
    commonName: 'peru',
    flag: 'https://flagcdn.com/pe.svg',
  },
  {
    name: 'french polynesia',
    cca2: 'PF',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'french polynesia',
    flag: 'https://flagcdn.com/pf.svg',
  },
  {
    name: 'papua new guinea',
    cca2: 'PG',
    region: 'oceania',
    subregion: 'melanesia',
    commonName: 'papua new guinea',
    flag: 'https://flagcdn.com/pg.svg',
  },
  {
    name: 'philippines',
    cca2: 'PH',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'philippines',
    flag: 'https://flagcdn.com/ph.svg',
  },
  {
    name: 'pakistan',
    cca2: 'PK',
    region: 'asia',
    subregion: 'southern asia',
    commonName: 'pakistan',
    flag: 'https://flagcdn.com/pk.svg',
  },
  {
    name: 'poland',
    cca2: 'PL',
    region: 'europe',
    subregion: 'central europe',
    commonName: 'poland',
    flag: 'https://flagcdn.com/pl.svg',
  },
  {
    name: 'saint pierre and miquelon',
    cca2: 'PM',
    region: 'americas',
    subregion: 'north america',
    commonName: 'saint pierre and miquelon',
    flag: 'https://flagcdn.com/pm.svg',
  },
  {
    name: 'pitcairn islands',
    cca2: 'PN',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'pitcairn islands',
    flag: 'https://flagcdn.com/pn.svg',
  },
  {
    name: 'puerto rico',
    cca2: 'PR',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'puerto rico',
    flag: 'https://flagcdn.com/pr.svg',
  },
  {
    name: 'palestine',
    cca2: 'PS',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'palestine',
    flag: 'https://flagcdn.com/ps.svg',
  },
  {
    name: 'portugal',
    cca2: 'PT',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'portugal',
    flag: 'https://flagcdn.com/pt.svg',
  },
  {
    name: 'palau',
    cca2: 'PW',
    region: 'oceania',
    subregion: 'micronesia',
    commonName: 'palau',
    flag: 'https://flagcdn.com/pw.svg',
  },
  {
    name: 'paraguay',
    cca2: 'PY',
    region: 'americas',
    subregion: 'south america',
    commonName: 'paraguay',
    flag: 'https://flagcdn.com/py.svg',
  },
  {
    name: 'qatar',
    cca2: 'QA',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'qatar',
    flag: 'https://flagcdn.com/qa.svg',
  },
  {
    name: 'réunion',
    cca2: 'RE',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'réunion',
    flag: 'https://flagcdn.com/re.svg',
  },
  {
    name: 'romania',
    cca2: 'RO',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'romania',
    flag: 'https://flagcdn.com/ro.svg',
  },
  {
    name: 'serbia',
    cca2: 'RS',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'serbia',
    flag: 'https://flagcdn.com/rs.svg',
  },
  {
    name: 'russia',
    cca2: 'RU',
    region: 'europe',
    subregion: 'eastern europe',
    commonName: 'russia',
    flag: 'https://flagcdn.com/ru.svg',
  },
  {
    name: 'rwanda',
    cca2: 'RW',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'rwanda',
    flag: 'https://flagcdn.com/rw.svg',
  },
  {
    name: 'saudi arabia',
    cca2: 'SA',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'saudi arabia',
    flag: 'https://flagcdn.com/sa.svg',
  },
  {
    name: 'solomon islands',
    cca2: 'SB',
    region: 'oceania',
    subregion: 'melanesia',
    commonName: 'solomon islands',
    flag: 'https://flagcdn.com/sb.svg',
  },
  {
    name: 'seychelles',
    cca2: 'SC',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'seychelles',
    flag: 'https://flagcdn.com/sc.svg',
  },
  {
    name: 'sudan',
    cca2: 'SD',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'sudan',
    flag: 'https://flagcdn.com/sd.svg',
  },
  {
    name: 'sweden',
    cca2: 'SE',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'sweden',
    flag: 'https://flagcdn.com/se.svg',
  },
  {
    name: 'singapore',
    cca2: 'SG',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'singapore',
    flag: 'https://flagcdn.com/sg.svg',
  },
  {
    name: 'saint helena, ascension and tristan da cunha',
    cca2: 'SH',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'saint helena, ascension and tristan da cunha',
    flag: 'https://flagcdn.com/sh.svg',
  },
  {
    name: 'slovenia',
    cca2: 'SI',
    region: 'europe',
    subregion: 'central europe',
    commonName: 'slovenia',
    flag: 'https://flagcdn.com/si.svg',
  },
  {
    name: 'svalbard and jan mayen',
    cca2: 'SJ',
    region: 'europe',
    subregion: 'northern europe',
    commonName: 'svalbard and jan mayen',
    flag: 'https://flagcdn.com/sj.svg',
  },
  {
    name: 'slovakia',
    cca2: 'SK',
    region: 'europe',
    subregion: 'central europe',
    commonName: 'slovakia',
    flag: 'https://flagcdn.com/sk.svg',
  },
  {
    name: 'sierra leone',
    cca2: 'SL',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'sierra leone',
    flag: 'https://flagcdn.com/sl.svg',
  },
  {
    name: 'san marino',
    cca2: 'SM',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'san marino',
    flag: 'https://flagcdn.com/sm.svg',
  },
  {
    name: 'senegal',
    cca2: 'SN',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'senegal',
    flag: 'https://flagcdn.com/sn.svg',
  },
  {
    name: 'somalia',
    cca2: 'SO',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'somalia',
    flag: 'https://flagcdn.com/so.svg',
  },
  {
    name: 'suriname',
    cca2: 'SR',
    region: 'americas',
    subregion: 'south america',
    commonName: 'suriname',
    flag: 'https://flagcdn.com/sr.svg',
  },
  {
    name: 'south sudan',
    cca2: 'SS',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'south sudan',
    flag: 'https://flagcdn.com/ss.svg',
  },
  {
    name: 'são tomé and príncipe',
    cca2: 'ST',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'são tomé and príncipe',
    flag: 'https://flagcdn.com/st.svg',
  },
  {
    name: 'el salvador',
    cca2: 'SV',
    region: 'americas',
    subregion: 'central america',
    commonName: 'el salvador',
    flag: 'https://flagcdn.com/sv.svg',
  },
  {
    name: 'sint maarten',
    cca2: 'SX',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'sint maarten',
    flag: 'https://flagcdn.com/sx.svg',
  },
  {
    name: 'syria',
    cca2: 'SY',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'syria',
    flag: 'https://flagcdn.com/sy.svg',
  },
  {
    name: 'eswatini',
    cca2: 'SZ',
    region: 'africa',
    subregion: 'southern africa',
    commonName: 'eswatini',
    flag: 'https://flagcdn.com/sz.svg',
  },
  {
    name: 'turks and caicos islands',
    cca2: 'TC',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'turks and caicos islands',
    flag: 'https://flagcdn.com/tc.svg',
  },
  {
    name: 'chad',
    cca2: 'TD',
    region: 'africa',
    subregion: 'middle africa',
    commonName: 'chad',
    flag: 'https://flagcdn.com/td.svg',
  },
  {
    name: 'french southern and antarctic lands',
    cca2: 'TF',
    region: 'antarctic',
    subregion: '',
    commonName: 'french southern and antarctic lands',
    flag: 'https://flagcdn.com/tf.svg',
  },
  {
    name: 'togo',
    cca2: 'TG',
    region: 'africa',
    subregion: 'western africa',
    commonName: 'togo',
    flag: 'https://flagcdn.com/tg.svg',
  },
  {
    name: 'thailand',
    cca2: 'TH',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'thailand',
    flag: 'https://flagcdn.com/th.svg',
  },
  {
    name: 'tajikistan',
    cca2: 'TJ',
    region: 'asia',
    subregion: 'central asia',
    commonName: 'tajikistan',
    flag: 'https://flagcdn.com/tj.svg',
  },
  {
    name: 'tokelau',
    cca2: 'TK',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'tokelau',
    flag: 'https://flagcdn.com/tk.svg',
  },
  {
    name: 'timor-leste',
    cca2: 'TL',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'timor-leste',
    flag: 'https://flagcdn.com/tl.svg',
  },
  {
    name: 'turkmenistan',
    cca2: 'TM',
    region: 'asia',
    subregion: 'central asia',
    commonName: 'turkmenistan',
    flag: 'https://flagcdn.com/tm.svg',
  },
  {
    name: 'tunisia',
    cca2: 'TN',
    region: 'africa',
    subregion: 'northern africa',
    commonName: 'tunisia',
    flag: 'https://flagcdn.com/tn.svg',
  },
  {
    name: 'tonga',
    cca2: 'TO',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'tonga',
    flag: 'https://flagcdn.com/to.svg',
  },
  {
    name: 'turkey',
    cca2: 'TR',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'turkey',
    flag: 'https://flagcdn.com/tr.svg',
  },
  {
    name: 'trinidad and tobago',
    cca2: 'TT',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'trinidad and tobago',
    flag: 'https://flagcdn.com/tt.svg',
  },
  {
    name: 'tuvalu',
    cca2: 'TV',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'tuvalu',
    flag: 'https://flagcdn.com/tv.svg',
  },
  {
    name: 'taiwan',
    cca2: 'TW',
    region: 'asia',
    subregion: 'eastern asia',
    commonName: 'taiwan',
    flag: 'https://flagcdn.com/tw.svg',
  },
  {
    name: 'tanzania',
    cca2: 'TZ',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'tanzania',
    flag: 'https://flagcdn.com/tz.svg',
  },
  {
    name: 'ukraine',
    cca2: 'UA',
    region: 'europe',
    subregion: 'eastern europe',
    commonName: 'ukraine',
    flag: 'https://flagcdn.com/ua.svg',
  },
  {
    name: 'uganda',
    cca2: 'UG',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'uganda',
    flag: 'https://flagcdn.com/ug.svg',
  },
  {
    name: 'united states minor outlying islands',
    cca2: 'UM',
    region: 'americas',
    subregion: 'north america',
    commonName: 'united states minor outlying islands',
    flag: 'https://flagcdn.com/um.svg',
  },
  {
    name: 'united states',
    cca2: 'US',
    region: 'americas',
    subregion: 'north america',
    commonName: 'united states',
    flag: 'https://flagcdn.com/us.svg',
  },
  {
    name: 'uruguay',
    cca2: 'UY',
    region: 'americas',
    subregion: 'south america',
    commonName: 'uruguay',
    flag: 'https://flagcdn.com/uy.svg',
  },
  {
    name: 'uzbekistan',
    cca2: 'UZ',
    region: 'asia',
    subregion: 'central asia',
    commonName: 'uzbekistan',
    flag: 'https://flagcdn.com/uz.svg',
  },
  {
    name: 'vatican city',
    cca2: 'VA',
    region: 'europe',
    subregion: 'southern europe',
    commonName: 'vatican city',
    flag: 'https://flagcdn.com/va.svg',
  },
  {
    name: 'saint vincent and the grenadines',
    cca2: 'VC',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'saint vincent and the grenadines',
    flag: 'https://flagcdn.com/vc.svg',
  },
  {
    name: 'venezuela',
    cca2: 'VE',
    region: 'americas',
    subregion: 'south america',
    commonName: 'venezuela',
    flag: 'https://flagcdn.com/ve.svg',
  },
  {
    name: 'british virgin islands',
    cca2: 'VG',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'british virgin islands',
    flag: 'https://flagcdn.com/vg.svg',
  },
  {
    name: 'united states virgin islands',
    cca2: 'VI',
    region: 'americas',
    subregion: 'caribbean',
    commonName: 'united states virgin islands',
    flag: 'https://flagcdn.com/vi.svg',
  },
  {
    name: 'vietnam',
    cca2: 'VN',
    region: 'asia',
    subregion: 'south-eastern asia',
    commonName: 'vietnam',
    flag: 'https://flagcdn.com/vn.svg',
  },
  {
    name: 'vanuatu',
    cca2: 'VU',
    region: 'oceania',
    subregion: 'melanesia',
    commonName: 'vanuatu',
    flag: 'https://flagcdn.com/vu.svg',
  },
  {
    name: 'wallis and futuna',
    cca2: 'WF',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'wallis and futuna',
    flag: 'https://flagcdn.com/wf.svg',
  },
  {
    name: 'samoa',
    cca2: 'WS',
    region: 'oceania',
    subregion: 'polynesia',
    commonName: 'samoa',
    flag: 'https://flagcdn.com/ws.svg',
  },
  {
    name: 'kosovo',
    cca2: 'XK',
    region: 'europe',
    subregion: 'southeast europe',
    commonName: 'kosovo',
    flag: 'https://flagcdn.com/xk.svg',
  },
  {
    name: 'yemen',
    cca2: 'YE',
    region: 'asia',
    subregion: 'western asia',
    commonName: 'yemen',
    flag: 'https://flagcdn.com/ye.svg',
  },
  {
    name: 'mayotte',
    cca2: 'YT',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'mayotte',
    flag: 'https://flagcdn.com/yt.svg',
  },
  {
    name: 'south africa',
    cca2: 'ZA',
    region: 'africa',
    subregion: 'southern africa',
    commonName: 'south africa',
    flag: 'https://flagcdn.com/za.svg',
  },
  {
    name: 'zambia',
    cca2: 'ZM',
    region: 'africa',
    subregion: 'eastern africa',
    commonName: 'zambia',
    flag: 'https://flagcdn.com/zm.svg',
  },
  {
    name: 'zimbabwe',
    cca2: 'ZW',
    region: 'africa',
    subregion: 'southern africa',
    commonName: 'zimbabwe',
    flag: 'https://flagcdn.com/zw.svg',
  },
]
