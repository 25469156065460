import React from 'react'

const EOSIcon = (props: any) => (
  <svg viewBox="0 0 55 55 " {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M28.392.001C16.194-.08 3.088 3.93.704 17.377c-1.128 6.356-.728 12.516 0 18.893C2.195 49.328 15.23 54.312 27 53.985c11.686-.324 24.766-4.313 26.297-17.716.728-6.376 1.126-12.536 0-18.892C51.207 5.592 39.49.077 28.392 0z"
        fill="#15182F"
      />
      <path
        d="M26.971 14l-6.562 8.257L17.1 34.816 26.973 40l9.927-5.18-3.222-12.454-.027-.105L26.971 14zm-5.373 8.599l5.381-6.772 5.422 6.705-5.387 15.138L21.598 22.6zm11.385 1.81l2.559 9.89-7.463 3.894 4.904-13.784zm-14.522 9.89l2.576-9.778 4.93 13.719-7.506-3.941z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default EOSIcon
