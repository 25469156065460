import React, { useEffect } from 'react'
import styled from 'app-view/styles/styled'
import { BalanceBox } from 'app-view/components/BalanceBox'
import { ChatProvider } from 'app-view/components/Chat/hooks/useChat'
import { SupportChannel } from './components/SupportChannel'
import { SwapHeaderButtons } from './components/SwapHeaderButtons'
import { SwapActions } from './components/SwapActions'
import { Wizard, WizardProvider } from 'app-view/components/Wizard'
import { useSwapOrdersSubscription } from 'app-engine/graphql/generated/bitcash'
import { useStore } from 'app-engine/store'
import { ScrollableFlexBoxArea, HeaderContainer } from 'app-view/components/Styled'

const MainContent = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% + 1.5rem);
  right: 0.75rem;
  background: #fff;
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 32px 16px 24px;
  flex: 1;

  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 auto;
  }
`

const CoinProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    // @ts-ignore
    <WizardProvider>
      {/* @ts-ignore */}
      <ChatProvider>{children}</ChatProvider>
    </WizardProvider>
  )
}

const CoinComponents = () => {
  const {
    coinSwap: { error },
    resetSwapError,
  } = useStore()

  const account = useStore.useAccount()
  const { data } = useSwapOrdersSubscription({
    variables: {
      where: {
        order_status: { _in: ['open', 'filled'] },
        bitcash_account: { _eq: account },
      },
      limit: 10,
    },
    fetchPolicy: 'no-cache',
  })

  const openFilledOrdersCount = data?.swap_orders.length || 0

  return (
    <ScrollableFlexBoxArea>
      <BalanceBox />

      <HeaderContainer>
        <SwapHeaderButtons />
      </HeaderContainer>

      {/* scroll area fix */}
      <MainContent>
        <SwapActions />
        {openFilledOrdersCount !== 0 && <SupportChannel />}
      </MainContent>

      <Wizard error={error} resetError={resetSwapError} />
    </ScrollableFlexBoxArea>
  )
}

export const SwapView = () => (
  <CoinProvider>
    <CoinComponents />
  </CoinProvider>
)
