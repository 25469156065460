import React, { useEffect, useState } from 'react'
import { GrinStatusParagraph } from './Styled'
import { useChat } from 'app-view/components/Chat/hooks/useChat'
import { useStore } from 'app-engine/store'
import Chat from 'app-view/components/Chat'
import { CopyAddress } from 'app-view/components/CopyAddress'
import { useTranslation } from 'react-i18next'
import { ModalButtonsContainer, ModalText } from 'app-view/components/Modal'
import { BlackButton } from 'app-view/components/buttons'
import { config } from 'app-config'
import { SwapOrder } from 'app-engine/store/swap-slice'
import { formattedDate, get_amount } from 'app-view/lib/utils'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { useBitcashSwapAssetsQuery } from 'app-engine/graphql/generated/bitcash'

export type ModalHistoryProps = {
  order: SwapOrder
  onCancel?: () => void
}

export const ModalHistory = ({ order }: ModalHistoryProps) => {
  const [{ messages }, { onOpenWithProps }] = useChat()
  const { t, i18n } = useTranslation(['global', 'history'])
  const { account } = useStore()
  const [warning, setWarning] = useState(
    order.order_type === 'sell' ? t('history:sell_slate_no_msg') : t('history:buy_slate_no_msg'),
  )
  const { data } = useBitcashSwapAssetsQuery()

  useEffect(() => {
    if (messages.length > 1) {
      messages.map((msg) => {
        if (
          msg?.message.includes('BEGINSLATEPACK.') &&
          msg?.message.includes('ENDSLATEPACK.') &&
          msg?.from === config.bitcashAdmin
        ) {
          setWarning(
            order.order_type === 'sell'
              ? t('history:sell_slate_with_msg')
              : t('history:buy_slate_with_msg'),
          )
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages])

  const handleChat = () => {
    console.log('Opening modalChat with:', order.id)
    onOpenWithProps({
      id: {
        type: 'swap',
        id: order.id,
      },
      from: account,
      to: config.bitcashAdmin,
    })
  }

  const {
    asset,
    order_status,
    bitcash_currency,
    order_type,
    fee_percentage,
    total_crypto,
    bitcash_amount,
  } = order
  const totalToReceive = get_amount(`${bitcash_amount} ${bitcash_currency}`)
  const totalCrypto = parseFloat(total_crypto.toFixed(8))
  const totalToSend = fixedAmountDecimals(totalCrypto, asset)
  const depositAddress =
    data?.swap_assets.find((a) => a.asset === order.asset)?.wallet_address || ''

  return (
    <div>
      <ModalText>
        {`${
          order_status !== 'filled'
            ? `You're ${order_type}ing`
            : `You ${order_type === 'buy' ? 'bought' : 'sold'}`
        }`}
        <span>{totalToSend}</span>
        {`
        with a ${fee_percentage}% fee for `}
        <span>{totalToReceive}</span>
        {`.

        ${
          (order.order_status === 'open' && t('last_update')) ||
          (order.order_status === 'filled' && t('filled_on')) ||
          (order.order_status === 'cancelled' && t('cancelled_on')) ||
          ''
        }
        ${formattedDate(i18n.language, order.updated_at)}
        
        `}
      </ModalText>
      {order.asset !== 'GRIN' && (
        <CopyAddress
          trimTo={8}
          address={order_type === 'sell' ? depositAddress : order.wallet_address || ''}
        />
      )}
      {order.asset === 'GRIN' && order.order_status === 'open' && (
        <GrinStatusParagraph>{warning}</GrinStatusParagraph>
      )}
      <ModalButtonsContainer>
        <BlackButton onClick={handleChat}>Chat</BlackButton>
      </ModalButtonsContainer>
      <Chat />
    </div>
  )
}
