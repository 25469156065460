import { useStore } from 'app-engine/store'
import React, { useState } from 'react'
import { AccountWrapper, ListTitle, NoItems, OfferContainer } from './Shared'
import theme from 'app-config/theme'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { useTranslation } from 'react-i18next'
import { OfferListSkeleton } from './OfferListSkeleton'
import { AccountRelationship } from 'app-engine/store/p2p-slice'
import { ClipIcon, SettingButtonIcon, UntrustIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { ActionContainer } from 'app-view/components/Styled'
import { RemoveIcon, SendMsgIcon } from 'app-view/components/icons'
import { ToolTipOptionsProps, Tooltip } from 'app-view/components/Tooltip'
import { SettingButtonContainer } from 'pages/NotificationsView/components/NotificationItemStyles'
import { toggleTrustNetwork } from 'app-engine/services'
import { ActivityIndicator } from 'app-view/components/ActivityIndicator'
import { useOnlineStatus } from 'app-view/hooks/useOnlineStatus'
import { useRealTimeTrustNetworkFollowingAccounts } from 'app-view/hooks/use-realtime/use-realtime-trust-network-following-account'
import { useRealTimeTrustNetworkMutualTrust } from 'app-view/hooks/use-realtime/use-realtime-trust-network-mutual-trust'

type TrustNetworkProps = {
  title: string
  onChat: (account: string) => void
}

type TrustRequestProps = { network: AccountRelationship; onChat: (account: string) => void }

const TrustRequest = ({ network, onChat }: TrustRequestProps) => {
  const account = network?.account
  const { isOnline } = useOnlineStatus(account)

  const [isTooltipOpen, setToolTipOpen] = useState<boolean>(false)

  const modifyTrustNetwork = async (account: string) => {
    try {
      await toggleTrustNetwork(account)
    } catch (error) {
      console.trace(error)
    }
  }

  const tooltipOptions = [
    network.is_mutual && {
      text: 'chat',
      icon: <SendMsgIcon />,
      onClick: () => {
        onChat(network.account)
        setToolTipOpen(false)
      },
    },
    {
      remove: true,
      text: 'untrust_user',
      icon: <RemoveIcon />,
      onClick: async () => {
        await modifyTrustNetwork(network.account)
        setToolTipOpen(false)
      },
    },
  ].filter(Boolean) as ToolTipOptionsProps[]

  return (
    <RequestContainer>
      <div>
        <AccountWrapper account={network.account} />
        <ActivityIndicator isOnline={isOnline} />
        <RelationshipContainer>
          {network.is_mutual ? <ClipIcon /> : <UntrustIcon />}
        </RelationshipContainer>
      </div>
      <SettingButtonContainer onClick={() => setToolTipOpen(!isTooltipOpen)}>
        <SettingButtonIcon style={{ height: 16 }} />
      </SettingButtonContainer>
      <Tooltip
        id={`trust-network-item-tooltip-${network.account}`}
        content={tooltipOptions}
        open={isTooltipOpen}
        setOpen={setToolTipOpen}
      />
    </RequestContainer>
  )
}

export const TrustNetwork: React.FC<TrustNetworkProps> = ({ title, onChat }) => {
  const followingAccounts = useRealTimeTrustNetworkFollowingAccounts()
  const mutualTrust = useRealTimeTrustNetworkMutualTrust()
  const { t } = useTranslation(['p2p'])

  return (
    <OfferContainer>
      <ListTitle color={theme.colors?.darkGray}>{title}</ListTitle>
      <AnimatePresence>
        {followingAccounts.length === 0 && <NoItems>{t('no_accounts')}</NoItems>}
        {followingAccounts.length > 0 &&
          followingAccounts.map((account, index) => (
            <TrustRequest
              network={{
                is_mutual: mutualTrust.includes(account),
                account,
              }}
              key={`${account}:${index}`}
              onChat={onChat}
            />
          ))}
      </AnimatePresence>
    </OfferContainer>
  )
}

const RelationshipContainer = styled('div')`
  justify-content: end;
  min-width: 30px;
  display: inline-flex;
`

const RequestContainer = styled(ActionContainer)`
  position: relative;
  margin-left: 16px;
  margin-right: 16px;
  height: 52px;
  padding-left: 16px;

  > div {
    flex: 1;
    display: flex;
  }
`
