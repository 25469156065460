import React, { Dispatch, useCallback, useMemo, useState, useRef } from 'react'
import styled from 'app-view/styles/styled'
import { IndicatorIcon } from 'app-view/components/icons'
import { showTokenSymbol } from 'app-engine/library/tokens'
import { WheelSelect, WheelSelectOption } from 'app-view/components/WheelSelect'
import { useStore } from 'app-engine/store'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { isMobile } from 'app-engine/library/utils'
import { Select } from './Select'
import CoinAutocomplete, { TAutocompleteOption } from './CoinAutocomplete'

const Wrapper = styled.div<{ wheelActive: boolean }>`
  display: flex;
  align-items: center;
  place-self: stretch stretch;
  justify-content: center;
  flex-basis: 66px;
  grid-column: 2;
  user-select: none;
  margin: 0 10px;

  & > div {
    /* nested cos of padding */
    width: 100%;
    height: 100%;
    ${(props) => props.wheelActive && 'overflow-y: hidden;'};
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding-left: 6px;
    align-items: center;
    justify-content: center;

    > div,
    > span {
      font-size: 19px;
      line-height: 1.5;
      margin: 0 auto 0;
    }

    > span.gray {
      color: #787878;
    }
  }

  span.text {
    text-align: left;
  }
`

const CoinContainer = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`

export type OnChangeCallbackPropTypes = {
  userInteract: boolean
  setUserInteract: Dispatch<boolean>
}

export type CoinSelectorProps = OnChangeCallbackPropTypes & {
  id?: string // Just for debugging
  coin?: any // TODO: fix type
  setCoin: Function
  coinOpt: Array<any>
  indicatorProps: {
    screen: ScreenTypes
    type: string
  }
  initialIndex?: number
  isStableCoin?: boolean
  lastUsedToken?: string
}

export const CoinSelector: React.FC<CoinSelectorProps> = ({
  id,
  coin,
  setCoin,
  coinOpt,
  indicatorProps,
  userInteract,
  setUserInteract,
  initialIndex,
  lastUsedToken,
  isStableCoin,
}) => {
  const start = useRef<number>(0)
  const [autocomplete, setAutocomplete] = useState<{
    active: boolean
    selectedPosition: null | number
  }>({
    active: false,
    selectedPosition: null,
  })
  const {
    wallet,
    trade,
    actionLimit,
    userGuidePreferences: { last_crypto },
    setInstructions,
  } = useStore()

  const onChangeMethod = useCallback(
    (opt: WheelSelectOption, interacted: boolean) => {
      if (!opt) return

      if (coinOpt.some((co) => co.value === opt.value)) return
      const action =
        wallet?.find((w) => w?.action === indicatorProps.type) ||
        trade?.actions?.find((t) => t?.action === indicatorProps.type)
      if ((action?.times && action?.times < 3) || !action?.times) {
        setInstructions(indicatorProps.screen, {
          action: indicatorProps.type,
          times: (action?.times ?? 0) + 1 ?? 1,
        })
      }
      setUserInteract(interacted)
      setCoin(opt.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      coinOpt,
      initialIndex,
      indicatorProps.screen,
      indicatorProps.type,
      setCoin,
      setInstructions,
      trade?.actions,
      wallet,
      setUserInteract,
    ],
  )

  const onChangeCurrFn = useCallback(
    (opt: WheelSelectOption, interacted: boolean) => onChangeMethod(opt, interacted),
    [onChangeMethod],
  )

  const coinsList = useMemo(
    () =>
      coinOpt.map((coinName) => ({
        icon: coinName,
        value: coinName,
        label: showTokenSymbol(coinName),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coinOpt.length],
  )

  const isUserAware: boolean = useMemo(
    () =>
      wallet.some((w) => w?.action === 'coin-selector' && w.times === actionLimit) ||
      trade.actions.some((t) => t?.action === 'coin-selector' && t.times === actionLimit),
    [wallet, trade.actions, actionLimit],
  )

  const initialPosition = useMemo(() => {
    const lastUsedIndex = lastUsedToken ? coinOpt.findIndex((c) => c === lastUsedToken) : -1
    if (lastUsedIndex !== -1) return lastUsedIndex
    if ((initialIndex ?? -1) > -1) return initialIndex
    const coin_index =
      isStableCoin || coin
        ? coinsList.findIndex((c) => c.value === coin)
        : coinsList.findIndex((c) => c.value === last_crypto)
    const index = coin_index !== -1 ? coin_index : Math.floor(coinsList.length / 2) - 1
    return index
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinsList.length, lastUsedToken, last_crypto, initialIndex, coin, isStableCoin])

  const handleAutocompleteSelectedPosition = (option: TAutocompleteOption) =>
    setAutocomplete({ active: false, selectedPosition: option.id })

  const on_press_counter = {
    // decides if autocomplete should be rendered based on tap/press duration
    // a short tap/press renders autocomplete feature instead of wheel
    start: () => {
      if (!autocomplete.active) start.current = Date.now()
    },
    end: () => {
      if (!autocomplete.active && start.current) {
        const end = Date.now()
        const timeDifferenceForActivation = 7
        const diff = end - start.current
        const longPress = diff > timeDifferenceForActivation
        console.log({ event: 'click.7', longPress, diff, timeDifferenceForActivation })
        if (longPress) setAutocomplete({ active: true, selectedPosition: null })
      }
    },
  }

  return isMobile ? (
    <Wrapper wheelActive={!autocomplete.active}>
      {!userInteract && !isUserAware && (
        <span className="indicator">
          <IndicatorIcon type="swipe" />
        </span>
      )}
      <div>
        <CoinContainer
          onMouseDown={on_press_counter.start}
          onMouseUp={on_press_counter.end}
          onBlur={() => setAutocomplete({ active: false, selectedPosition: null })}
          onTouchMove={() => setAutocomplete({ active: false, selectedPosition: null })}
        >
          {coinsList.length && !autocomplete.active ? (
            <WheelSelect
              id={id}
              icon
              options={coinsList}
              initialPosition={autocomplete.selectedPosition ?? initialPosition}
              onChange={onChangeCurrFn}
              topFix={6}
              isCoinSelector
            />
          ) : null}
          {coinsList.length && autocomplete.active ? (
            <CoinAutocomplete
              options={coinsList}
              handleOnSelect={handleAutocompleteSelectedPosition}
            />
          ) : null}
        </CoinContainer>
      </div>
    </Wrapper>
  ) : (
    <Select
      id={id}
      value={coin}
      compact
      options={coinsList}
      onChange={(option) => onChangeCurrFn(option, true)}
      initialPosition={initialPosition}
      isCoinSelector
      width="100%"
    />
  )
}
