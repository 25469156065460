import 'semantic-ui-css/semantic.min.css'
import './app-view/assets/css/app.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { AppProvider } from './AppProvider'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import styled from './app-view/styles/styled'
import { config } from 'app-config'
import { BrowserRouter } from 'react-router-dom'
import { excludeGraphQLFetch } from 'apollo-link-sentry'
import { isMobile } from 'app-engine/library/utils'
import { registerServiceWorker } from './serviceWorkerRegistration'
import { RegistrationProvider } from 'pages/AccountView/hooks/useRegistration'
import { scrollbar_css } from 'app-view/components/Styled'
import AppVerifier from './AppVerifier'
import { Header } from 'app-view/components/Header'
import { Navigation } from 'app-view/components/Navigation'
import { AppRoutes } from 'AppRoutes'
import { MaintenanceView } from './pages/MaintenanceView/index'
import { DesktopAccessView } from './pages/DesktopAccessView/index'

if (config.environment === 'testing') isMobile && import('app-engine/library/devtools') // testing devTools

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    integrations: [new Integrations.BrowserTracing({ traceFetch: false })],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    beforeBreadcrumb: excludeGraphQLFetch,
  })
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Main = styled.div`
  display: flex;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: overlay;
  background-color: #f2f2f2;
  ${scrollbar_css}
`

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const isMaintenanceMode = config.maintenanceMode
const isDesktopAccessible = config.desktopAcess

root.render(
  <>
    <React.StrictMode>
      {isMaintenanceMode && <MaintenanceView />}
      {!isMaintenanceMode && !isMobile && !isDesktopAccessible && <DesktopAccessView />}
      {!isMaintenanceMode && (isMobile || (!isMobile && isDesktopAccessible)) && (
        <BrowserRouter>
          <AppProvider>
            {/* @ts-ignore */}
            <RegistrationProvider>
              <Wrapper key="site-wrapper">
                {/* TODO: implement header? */}
                <Header />
                <Main>
                  <AppVerifier />
                  <AppRoutes />
                </Main>
                <Navigation />
              </Wrapper>
            </RegistrationProvider>
          </AppProvider>
        </BrowserRouter>
      )}
    </React.StrictMode>
  </>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (config.environment === 'development') {
  serviceWorkerRegistration.unregister()
  console.log('Service worker unregistered.')
} else {
  registerServiceWorker({
    onUpdate: () => {
      // ? This is a fallback. It will hit up to the 50% of users due browser/device compatibility. Service Worker will update automatically from service-worker.js 'activate' event.
      // eslint-disable-next-line no-alert
      alert(
        'New version available! The current tab will reload in 1 second to apply update.\n\nMake sure to close all bitcash app tabs in your browser to see changes.',
      )

      const timeout = setTimeout(() => {
        window.location.reload()

        clearTimeout(timeout)
      }, 1000)
    },
  })
  // console.log('Service worker registered.')
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
