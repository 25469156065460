// notifications.ts

export default {
  notifications: 'Thông báo',
  all: 'Tất cả',
  unread: 'Chưa đọc',
  marked_read: 'Đánh dấu đã đọc',
  remove: 'Xóa',
  completed: 'đã hoàn thành!',
  cancelled: 'đã hủy',
  coin_status:
    'Lệnh <0>{{asset}} {{order_type}} của bạn</0> đã được {{status}}. Đi đến lệnh để xem chi tiết.',
  coin_message: 'Lệnh <0>{{asset}} {{order_type}} của bạn</0> có một tin nhắn mới.',
  coin_title: 'Bitcash App | Đặt Coin',
  p2p_title: 'Bitcash App | Đề nghị P2P',
  coin_message_not: 'Bạn có một tin nhắn mới trong lệnh coin của mình',
  coin_update: 'Bạn có một cập nhật về lệnh coin của mình.',
  p2p_not_message: 'Bạn có một đề nghị P2P mở mới! Nhấp để xem thêm.',
  p2p_status: 'Đề nghị {{order_type}} của bạn đã được {{status}}! Nhấp để xem thêm.',
  p2p_message: 'Đề nghị {{order_type}} của bạn có một tin nhắn mới. Nhấp để xem thêm.',
  p2p_trust_message: 'Người dùng {{from}} đã gửi cho bạn một tin nhắn. Nhấp để xem thêm.',
}
