import React from 'react'

const ClipIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.24939 7.52865C7.00472 6.77331 8.32205 6.77331 9.07739 7.52865L9.54872 7.99998L10.4914 7.05731L10.0201 6.58598C9.39139 5.95665 8.55405 5.60931 7.66339 5.60931C6.77272 5.60931 5.93539 5.95665 5.30672 6.58598L3.89205 7.99998C3.26825 8.62579 2.91797 9.47337 2.91797 10.357C2.91797 11.2406 3.26825 12.0882 3.89205 12.714C4.2013 13.0237 4.5687 13.2692 4.97313 13.4365C5.37757 13.6037 5.81106 13.6894 6.24872 13.6886C6.68649 13.6896 7.12013 13.6039 7.52469 13.4366C7.92925 13.2694 8.29675 13.0238 8.60605 12.714L9.07739 12.2426L8.13472 11.3L7.66339 11.7713C7.2877 12.1453 6.77917 12.3553 6.24905 12.3553C5.71894 12.3553 5.21041 12.1453 4.83472 11.7713C4.46039 11.3958 4.25019 10.8872 4.25019 10.357C4.25019 9.82676 4.46039 9.31816 4.83472 8.94265L6.24939 7.52865Z"
      fill="#4A81BD"
    />
    <path
      d="M8.60604 3.28597L8.1347 3.75731L9.07737 4.69998L9.5487 4.22864C9.92439 3.85464 10.4329 3.64466 10.963 3.64466C11.4932 3.64466 12.0017 3.85464 12.3774 4.22864C12.7517 4.60416 12.9619 5.11275 12.9619 5.64298C12.9619 6.1732 12.7517 6.68179 12.3774 7.05731L10.9627 8.47131C10.2074 9.22664 8.89004 9.22664 8.1347 8.47131L7.66337 7.99998L6.7207 8.94264L7.19204 9.41398C7.8207 10.0433 8.65804 10.3906 9.5487 10.3906C10.4394 10.3906 11.2767 10.0433 11.9054 9.41398L13.32 7.99998C13.9438 7.37417 14.2941 6.52659 14.2941 5.64298C14.2941 4.75936 13.9438 3.91179 13.32 3.28597C12.6944 2.66185 11.8468 2.31134 10.963 2.31134C10.0793 2.31134 9.23167 2.66185 8.60604 3.28597Z"
      fill="#4A81BD"
    />
  </svg>
)

export default ClipIcon
