import { ApolloError } from '@apollo/client/errors'
import AnchorLink, { ChainId, PermissionLevel, PublicKey } from 'anchor-link'
import { config } from 'app-config'
import { apolloClient, resetCache } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { pushTransactionWebAuthN } from 'app-engine/library/eosio'
import { WebAuthError } from 'app-engine/library/errors'
import { parseJwt, sleep } from 'app-engine/library/utils'
import { bitcashAuthService } from 'app-engine/services'
import { AppState } from 'app-engine/store'
import { AccountDevices } from 'app-engine/store/account-slice'
import { newAnchorLink } from '../../pages/AccountView/utils'

export enum AuthType {
  WEBAUTHN,
  ANCHOR,
}

export type AuthSlice = {
  anchorLink?: AnchorLink
  authed: boolean
  token: string
  anchor_permission_level: string
  session_expired: boolean
  authType?: AuthType
  cred_id?: string
  pub_key?: string
  previous_route?: string
  setPreviousRoute: (route: string) => void
  loginWithAnchor: () => Promise<void>
  loginWithWebAuthN: (device?: Bitcash.Devices | AccountDevices) => Promise<void>
  logout: () => void
  setSessionToken: ({ token, authType }: { token?: string; authType?: AuthType }) => Promise<void>
  refreshSession: (forceRefresh?: boolean) => Promise<boolean>
  authErrorFallback: (error: Error | ApolloError) => Promise<void>
  verifyBitcashbankRegistration: () => Promise<void>
  setAnchorPermissionLevel: (permission_level: string) => void
  reset: () => void
}

const authSliceDefaultState = {
  token: '',
  authed: false,
  anchor_permission_level: '',
  session_expired: false,
  authType: undefined,
  cred_id: undefined,
  pub_key: undefined,
  previous_route: '',
}

export const checkSessionStatus = (session) => {
  const currentTime = Math.floor(Date.now() / 1000) // convert to seconds
  const expiryTime = session.exp
  const timeLeft = expiryTime - currentTime // time left in seconds

  const almostExpiredThreshold = 30 // 30 seconds threshold

  if (timeLeft <= 0) {
    return 'expired'
  } else if (timeLeft <= almostExpiredThreshold) {
    return 'almost_expired'
  } else {
    return 'valid'
  }
}

export function createAuthSlice(
  set: (
    partial: AppState | Partial<AppState> | ((state: AppState) => AppState | Partial<AppState>),
    replace?: boolean | undefined,
  ) => void,
  get: () => AppState,
  // ? We are not using this store prop, is ok to have it as any
  _: any,
): AuthSlice {
  return {
    ...authSliceDefaultState,
    reset: () => {
      set(authSliceDefaultState)
    },
    setPreviousRoute: (route: string) => set({ previous_route: route }),
    setSessionToken: async ({ token, authType }: { token?: string; authType?: AuthType }) => {
      if (!token) {
        set({ token: '', authed: false, authType: undefined })
        localStorage.removeItem('bitcash_session')
        return
      }
      set({ authed: true, authType, token })
      localStorage.setItem('bitcash_session', token)
    },
    loginWithAnchor: async () => {
      try {
        get().logout()
        const anchorLink = get().anchorLink || newAnchorLink
        if (!get().anchorLink) set({ anchorLink })

        const identity = await anchorLink.login('bitcash_app')
        const pubKey = PublicKey.from(identity.session.publicKey)

        const result = await bitcashAuthService.getTokenWithIdentity({
          pubKey: pubKey.toString(),
          signature: identity.signatures.map((sign) => sign.toString())[0],
          account: identity.signer.actor.toString(),
          digest: identity.transaction.signingDigest(identity.session.chainId).toString(),
        })

        if (result.error) throw new Error(result.error)

        get().setAnchorPermissionLevel(identity.signer.permission.toString())
        set({ pub_key: pubKey.toString() })
        await get().setSessionToken({ token: result.token, authType: AuthType.ANCHOR })
        get().setAccount(identity.signer.actor.toString(), true)
      } catch (error) {
        get().logout()
        throw error
      }
    },

    loginWithWebAuthN: async (device?: Bitcash.Devices | AccountDevices) => {
      try {
        //  account must be already set to read its
        // TODO: Create i18n keys and read them once we pass message to ModalError Component...
        if (!get().account) throw new WebAuthError('Error reading account info')
        // get cred_id of default device
        if (!get().devices[0]) throw new WebAuthError('access:no_devices')

        const { cred_id, public_key } = device || get().devices[0]
        const pub_key = PublicKey.from(public_key)
        const response = await pushTransactionWebAuthN({
          public_key,
          actions: [
            {
              account: config.contracts.bitcashAccounts,
              name: 'login',
              authorization: [
                {
                  actor: get().account,
                  permission: 'active',
                },
              ],
              data: { account: get().account },
            },
          ],
          cred_id,
        })

        // give chaingraph some time to index the transaction
        await sleep(2000)

        const result = await bitcashAuthService.getTokenWithTransactionId({
          transactionId: response?.transaction_id || 'nada',
          account: get().account,
        })

        if (result.error) throw new Error(result.error)

        const devices = get().devices.map((d) =>
          d.public_key === pub_key.toString() ? { ...d, logged: true } : d,
        )

        set({ pub_key: pub_key.toString(), devices })
        get().setSessionToken({ token: result.token, authType: AuthType.WEBAUTHN })
        // TODO: is this really nessesary? Isn't the account already set at this point? - Gabo
        get().setAccount(get().account, true)
      } catch (error) {
        get().logout()
        throw error
      }
    },
    setAnchorPermissionLevel: (permission_level: string) =>
      set({ anchor_permission_level: permission_level }),
    refreshSession: async (forceRefresh) => {
      try {
        const bitcashToken = localStorage.getItem('bitcash_session') || ''
        const session = parseJwt(bitcashToken)
        console.log(
          'Checking user status with - checkSessionStatus(session) at refreshSession fn',
          checkSessionStatus(session),
        )
        let result
        if (checkSessionStatus(session) === 'expired') throw new Error('Session expired.')
        if (checkSessionStatus(session) === 'almost_expired' || forceRefresh) {
          // prevent db failures before pushing to blockchain by checking active session
          // we only need to refresh the session if it is almost expired
          result = await bitcashAuthService.refreshToken(bitcashToken)
        } else {
          // if session is valid, we don't need to refresh it
          result = { token: bitcashToken, error: null }
        }

        if (result.error) throw new Error(result.error)

        get().setSessionToken({ token: result.token, authType: get().authType })
        return true
      } catch (error) {
        get().authErrorFallback(error as Error | ApolloError)
        return false
      }
    },
    authErrorFallback: async (error: Error | ApolloError) => {
      console.log('AUTH ERROR FALLBACK', { ...error })
      try {
        if (
          // If Apollo Resolver has auth error, then GraphQLErrors will provide to us the status...
          ('graphQLErrors' in (error as ApolloError) &&
            (error as ApolloError).graphQLErrors[0] &&
            (error as ApolloError).graphQLErrors[0].extensions.code === '401') ||
          // Verifying regular auth error
          error.message.match(/(JWT|JWTError)/g)
        ) {
          set({ session_expired: true })
        }
      } catch (error) {
        throw error
      }
    },
    // clear account state and reset auth on logout
    logout: async () => {
      // we persist this to support esr qr code reader without logging in
      const { account, anchor_permission_level, authType, devices, pub_key, cred_id } = get()

      if (get().authType === AuthType.ANCHOR) {
        await (get().anchorLink as AnchorLink)?.removeSession(
          'bitcash_app',
          PermissionLevel.from({
            actor: get().account,
            permission: get().anchor_permission_level,
          }),
          ChainId.from(config.eosChainId),
        )
      }

      get().setSessionToken({ token: undefined, authType: undefined })

      get().resetAccount()

      await resetCache()
      set({
        ...authSliceDefaultState,
        account,
        anchor_permission_level,
        authType,
        devices,
        pub_key,
        cred_id,
      })
    },

    // Guard that verifies if the logged eos account is a registered bitcash account
    verifyBitcashbankRegistration: async () => {
      try {
        const { data } = await apolloClient.query<
          Bitcash.GetRegAccountsQuery,
          Bitcash.GetRegAccountsQueryVariables
        >({
          query: Bitcash.GetRegAccountsDocument,
          variables: {
            account: get().account,
          },
        })

        console.log('[GraphQL Data - verifyBitcashbankRegistration]: ', data)

        if (!Boolean(data.reg_accounts.length)) throw new Error('errors:bitcashbank_acct_no_valid')
        if (Boolean(data.reg_accounts[0]) && !data.reg_accounts[0].created) {
          throw new Error('errors:bitcashbank_acct_no_registered')
        }
      } catch (error) {
        throw error
      }
    },
  }
}
