import React from 'react'

const KeyArrowIcon = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" {...props}>
      <path
        d="M8 1L1 8L8 15M1 8H17"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default KeyArrowIcon
