// TODO: Translate this file!
export default {
  heading: 'Profile Options',
  payment_methods: 'Payment Methods',
  profile_information: 'Profile Information',
  phone_number: 'Phone Number',
  email: 'Email Address',
  newsletter: 'Newsletter Subscription',
  phone_number_placeholder: '{{phoneNumberCountryCode}}#teléfono',
  include_country_code: 'Incluya el código de país',
  phone_valid: 'Phone number is valid!',
  delete_payment_option_warning: 'Are you sure you want to remove this payment method?',
  delete: 'Delete',
  cancel: 'Cancel',
  no_methods: 'No Previous Payment Method Added',
  save: 'Save Changes',
  revert: 'Revert',
  confirm: 'Confirm',
  select_method: `Select the payment method
	  that you would like to save.`,
  profile_update_success: 'perfil actualizado con éxito',
  verification_status: 'Estado:',
}
