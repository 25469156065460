import en from './english'
import cn from './chinese'
import es from './spanish'
import id from './indonesian'
import vi from './vietnamese'

export default {
  en,
  cn,
  es,
  id,
  vi,
}
