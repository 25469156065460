import { CancelButton } from 'app-view/components/buttons'
import { useChat } from 'app-view/components/Chat/hooks/useChat'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useStore } from 'app-engine/store'
import { useWizard } from 'app-view/components/Wizard'
import {
  ModalText,
  ModalButtonsContainer,
  ModalStepGreenButton,
  ModalStepBlackButton,
} from 'app-view/components/Modal'

interface MatchedProps {
  secondary?: boolean
  confirmed?: boolean
  text?: string
}

export const Matched: React.FC<React.PropsWithChildren<MatchedProps>> = ({
  children,
  secondary,
  confirmed,
  text = '',
}) => {
  const { t } = useTranslation(['p2p', 'global'])
  const account = useStore.useAccount()
  const [, { next, goTo, close }] = useWizard()
  const [{ offer }] = useP2POffer()
  // TODO: To finish connection with every new message when Chat is closed
  const [{ new_message }, { onOpenWithProps, notifyNewMessage }] = useChat()

  const onContact = () => {
    if (confirmed) close()

    notifyNewMessage(0)
    onOpenWithProps({
      from: account,
      to: (offer.buyer !== account ? offer.buyer : offer.seller) || '',
      id: {
        id: offer.id,
        type: 'p2p',
      },
    })
  }

  const onCancel = () => {
    // Checking if buyer has confirmed payment
    if (offer.seller === account && offer.buyer_confirmed_payment) {
      return goTo('cancel-deny')
    }

    return goTo('cancel')
  }

  const onGo = () => goTo('match-confirmed')

  const greenText = secondary ? t('p2p:Yes') : t('p2p:Contact')
  const blackText = secondary ? t('p2p:Not_yet') : t('p2p:Done')

  return (
    <>
      {children ? (
        <ModalText>{children}</ModalText>
      ) : (
        <ModalText dangerouslySetInnerHTML={{ __html: text }} />
      )}
      <br />
      <br />
      <ModalButtonsContainer cancel={!secondary && !confirmed}>
        {!confirmed && (
          <ModalStepBlackButton onClick={secondary ? onGo : next}>{blackText}</ModalStepBlackButton>
        )}
        <ModalStepGreenButton onClick={secondary ? next : onContact}>
          {/* TODO: To create a new msg counter for green button */}
          {new_message > 0 && (
            <span style={{ background: '#0f4', fontWeight: 700, padding: 4, borderRadius: '50%' }}>
              {new_message}
            </span>
          )}
          {greenText}
        </ModalStepGreenButton>
        {!secondary && !confirmed && (
          <CancelButton onClick={onCancel}>{t('global:cancel')}</CancelButton>
        )}
      </ModalButtonsContainer>
    </>
  )
}
