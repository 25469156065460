import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardStepProps } from 'app-view/components/Wizard'
import { SelectStable } from 'pages/P2PView/steps/common/SelectStable'

export const BuyStableCoin = ({ next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])

  return <SelectStable onNext={next} text={t('buy_select_stable')} isBuy />
}
