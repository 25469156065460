import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { buyerCryptoAmountLessP2PFee, getDAOAmountFee } from 'app-view/lib/utils'
import { Matched } from '../common/Matched'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const SellCancelDeny = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, buyerMethodDetailsLabelData } = useP2PLabels()

  return (
    <Matched
      confirmed
      text={t('sell_cancel_deny', {
        buyer: offer.buyerLabel,
        amount: buyerCryptoAmountLessP2PFee(offer.amount),
        cryptoFee: getDAOAmountFee(offer.amount),
        method: methodLabel(offer.method),
        methodDetails: isMethodDetails ? buyerMethodDetailsLabelData : '',
      })}
    />
  )
}
