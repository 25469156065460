import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { buyerCryptoAmountLessP2PFee, getAmountFee } from 'app-view/lib/utils'
import { Matched } from '../common/Matched'

export const BuyMatchedAltOffer = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }] = useP2POffer()

  return (
    <>
      <Matched
        secondary
        text={t('buy_matched_alt_offer', {
          seller: offer.sellerLabel,
          amount: buyerCryptoAmountLessP2PFee(offer.amount),
          cryptoFee: getAmountFee(offer.amount),
        })}
      />
    </>
  )
}
