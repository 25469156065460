export default {
  p2p: 'P2P',
  trade: '交易',
  bank: '银行',
  swap: '硬币',
  buy: '购买',
  forex: 'Forex', // TODO: translate
  filterBy: '过滤条件',
  older: '早期交易',
  recent: '近期交易',
  from: '从',
  to: '至',
  sent: '发送',
  received: '已收到',
  p2pEmptyMsn: '确认的Bitcash ATM活动将在此处显示',
  tradeEmptyMsn: '已确认的交易活动将在此处显示。',
  paymentsEmptyMsn: '确认的转账活动将在此处显示。',
  fee: '费用',
  deposit: '充值',
  transfer: '转账',
  'There is currently no buy history': '目前无购买记录',
  'There is currently no sell history': '目前无出售记录',
  'There is currently no history': '目前无交易历史',
  'There is currently no send history': '目前无转出记录',
  'There is currently no receipt history': '目前无收款历史',
  cancel_approved: '取消已批准',
  cancel_requested: '取消已请求',
}
