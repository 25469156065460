// swap.ts
export default {
  price: 'Harga',
  available: 'Tersedia',
  confirm: 'Konfirmasi',
  back: 'Kembali',
  ok: 'OK',
  next: 'Berikutnya',
  fee: 'Biaya',
  total_to_receive: 'Total yang akan diterima',
  retry: 'Coba lagi',
  buy: {
    loader: 'Membuat pesanan pembelian Anda',
    address: {
      text1: 'Jumlah Pembelian',
      text2: 'Silakan tempel alamat dompet tempat Anda ingin menerima',
    },
    complete: {
      text1: `Selamat!
		Anda seharusnya menerima:`,
      text2: ' sebelum tengah malam (GMT)',
      text3: `Anda akan menerima pesan slatepack di chat support yang perlu Anda masukkan ke dompet Grin Anda untuk menghasilkan respon
		
		Silakan masukkan responnya di chat support untuk menyelesaikan transaksi.`,
    },
    slatepack: {
      text1: 'Jumlah Pembelian',
      text2: 'Silakan konfirmasi jumlah penerimaan',
    },
  },
  sell: {
    loader: 'Membuat pesanan penjualan Anda',
    userAddress: {
      text1: 'Jumlah Penjualan:',
      text2: `Silakan tempel alamat dompet
		dari mana Anda akan mengirim`,
    },
    depositAddress: {
      text1: 'Jumlah Penjualan:',
      text2: 'Silakan kirim ',
      text3: 'ke alamat dompet berikut:',
    },
    complete: {
      text1: 'Jumlah Penjualan:',
      text2: `Selamat!
		Anda seharusnya menerima`,
      text3: ' sebelum tengah malam (GMT)',
      text4: 'Anda seharusnya menerima:',
      slatepackMessage:
        'Anda akan menerima respon pesan slatepack di chat support yang perlu Anda masukkan ke dompet Grin Anda untuk menyelesaikan transaksi.',
    },
    slatepackForm: {
      text1: 'Jumlah Penjualan:',
      text2: 'Silakan masukkan pesan slatepack:',
      placeholder: `MULAILAH SLATEPACK. H...
  ...g. AKHIRSLATEPACK.`,
    },
  },
  general: {
    errorInputEmpty: 'Silakan isi bidang ini',
    errorInputInvalid: 'Panjang alamat tidak valid',
    copied: 'Disalin!',
    pressToPaste: 'Tempel alamat di sini',
  },
}
