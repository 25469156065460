import { SkeletonCard } from 'app-view/components/Skeletons'
import React from 'react'

const randomArrLength = Math.floor((Math.random() + 1) * 2)
const skeletonArr = [...new Array(randomArrLength)]

export const NotificationSkeleton = () => (
  <>
    {skeletonArr.map((n, i) => (
      <SkeletonCard key={`skeleton_card_${i}-${n}`} />
    ))}
  </>
)
