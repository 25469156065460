import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { BalanceBox } from 'app-view/components/BalanceBox'
import Chat from 'app-view/components/Chat'
import { ChatProvider, useChat } from 'app-view/components/Chat/hooks/useChat'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import {
  HeaderContainer,
  MainContentContainer,
  ScrollableFlexBoxArea,
} from 'app-view/components/Styled'
import { Tab, Tabs } from 'app-view/components/Tabs'
import { Wizard, WizardProvider } from 'app-view/components/Wizard'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { useRealTimeUncompletedOwnP2POffers } from 'app-view/hooks/use-realtime/use-real-time-uncompleted-own-p2p-offers'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { OfferList } from './components/OfferList'
import { P2PHeaderButtons } from './components/P2PHeaderButtons'
import { P2POffersTrustGlobalNetwork } from './components/P2POffersTrustGlobalNetwork'
import { TrustNetwork } from './components/TrustNetwork'
import { TrustRequestList } from './components/TrustRequestList'
import { P2POfferProvider } from './hooks/useP2POffer'
import { usePublicP2POffersFilter } from './hooks/usePublicP2POffersFilter'
import { useTrustP2POffersFilter } from './hooks/useTrustP2POffersFilter'

const P2PComponent = () => {
  const { t } = useTranslation(['p2p'])
  const p2pOffers = useRealTimeUncompletedOwnP2POffers()
  const { account, setOpenOfferFilterType } = useStore()
  const [{ accountProfile }, {}] = useProfile()
  const { publicMatchedP2P, publicUnmatchedP2P, publicEveryoneUnmatchedP2P } =
    usePublicP2POffersFilter()
  const { trustP2POffers, trustMyP2POffers } = useTrustP2POffersFilter()
  const [{ error, loading: isP2pTxLoading }, { resetError }] = useP2POffer()
  const navigate = useNavigate()
  const location = useLocation()
  const [notiOffer, setNotiOffer] = useState<P2POffer | null>(null)
  const [{}, { onOpenWithProps, notifyNewMessage }] = useChat()
  const [, globalModalActions] = useGlobalModal()
  const p2pTabs: Tab[] = useMemo(
    () => [
      {
        label: t('public'),
        value: 'public',
        // counter: publicP2POffersCount || -1,
      },
      {
        label: t('private'),
        value: 'private',
        // counter: trustP2POffersCount || -1,
      },
      {
        label: t('network'),
        value: 'network',
        // counter: p2pIncomingTrustRequest?.length || -1,
      },
    ],
    [t],
  )

  const p2pTabNetwork = p2pTabs[2]

  const [currentP2PTab, setCurrentP2PTab] = useState({
    label: t('public'),
    value: 'public',
  })

  useEffect(() => {
    const getSearchParameters = () => {
      const content_id = new URLSearchParams(location.search).get('content_id') ?? ''
      const from = new URLSearchParams(location.search).get('from') ?? ''

      if (from) {
        setCurrentP2PTab(p2pTabNetwork)
        onChat(from)
        navigate({ pathname: location.pathname, search: '' }, { replace: true })
        return
      }

      const offer = p2pOffers.find((offer) => offer.id === content_id) || null

      if (offer) setNotiOffer(offer)
    }

    getSearchParameters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, p2pOffers])

  useEffect(() => {
    if (currentP2PTab?.value === 'public') {
      setOpenOfferFilterType('public')
    }
    if (currentP2PTab?.value === 'private') {
      setOpenOfferFilterType('private')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentP2PTab])

  const onChat = (trustAccount: string) => {
    notifyNewMessage(0)
    onOpenWithProps({
      from: account,
      to: trustAccount,
      id: {
        type: 'p2p-trust-network',
      },
    })
  }

  useEffect(() => {
    if (
      accountProfile &&
      !accountProfile?.reg_account?.is_verified &&
      (!accountProfile?.country_id?.type || !accountProfile?.selfie || !accountProfile?.photo_id)
    ) {
      const savedTime = localStorage.getItem('bitcash_data_warning')
      const shouldShowModal = savedTime
        ? Number(savedTime) > new Date().getTime()
          ? false
          : true
        : true
      if (shouldShowModal) {
        globalModalActions.openConfirm({
          onConfirm: () => navigate('/profile'),
          onCancel: async () => {
            localStorage.setItem(
              'bitcash_data_warning',
              (new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toString(),
            )
            globalModalActions.close()
          },
          confirmText: t('global:take_me_there'),
          cancelText: t('global:remind_me_later'),
          text: t('global:data_warning_message'),
          title: t('global:required_action'),
          iconType: 'WARNING',
          wideActions: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountProfile])

  return (
    <ScrollableFlexBoxArea>
      <Chat />
      <BalanceBox stable />
      <HeaderContainer>
        <P2PHeaderButtons />
      </HeaderContainer>
      <MainContentContainer>
        <Tabs
          tabs={p2pTabs}
          currentTab={currentP2PTab.value}
          setCurrentTab={setCurrentP2PTab}
          style={{ paddingTop: 0 }}
        />
        {currentP2PTab.value === 'public' && (
          <>
            <OfferList offer={notiOffer} title={t('matched')} offers={publicMatchedP2P} />
            <OfferList offer={notiOffer} title={t('unmatched_user')} offers={publicUnmatchedP2P} />
            <P2POffersTrustGlobalNetwork
              title={t('unmatched_all')}
              offers={publicEveryoneUnmatchedP2P}
            />
          </>
        )}
        {currentP2PTab.value === 'private' && (
          <>
            <OfferList offer={notiOffer} title={t('matched')} offers={trustMyP2POffers} />
            <P2POffersTrustGlobalNetwork title={t('unmatched')} offers={trustP2POffers} />
          </>
        )}
        {currentP2PTab.value === 'network' && (
          <>
            <TrustNetwork title={t('trusted')} onChat={onChat} />
            <TrustRequestList title={t('trust_request')} />
          </>
        )}
      </MainContentContainer>
      <Wizard error={error} resetError={resetError} loading={isP2pTxLoading} />
    </ScrollableFlexBoxArea>
  )
}

export const P2PView = () => {
  return (
    // @ts-expect-error
    <WizardProvider>
      {/* @ts-expect-error */}
      <P2POfferProvider>
        {/* @ts-expect-error */}
        <ChatProvider>
          <P2PComponent />
        </ChatProvider>
      </P2POfferProvider>
    </WizardProvider>
  )
}
