import { FlexBox } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import { QrScan } from 'pages/AccessView/components/QrScan'

export function QRReaderCard({ next, prev }: WizardStepProps) {
  const qrScanSuccess = (data) => {
    console.log('QR Code data:', data)
  }

  const qrScanError = (error) => {
    console.error('QR Code error:', error)
  }

  return (
    <FlexBox
      flexDirection="column"
      style={{ width: '100%', gap: 10, overflow: 'hidden', marginBottom: 32 }}
    >
      <p style={{ fontSize: '0.92rem', fontWeight: 600 }}>
        Scan the QR code to sign a bitcash request.
      </p>
      <QrScan onSuccess={qrScanSuccess} onError={qrScanError} />
    </FlexBox>
  )
}
