export default {
  fullscreen: 'Fullscreen',
  language: 'Language',
  primary_currency: 'Primary Currency',
  notifications: 'Allow Alerts',
  deviceKeys: 'Device List',
  support: 'Support Chat',
  about: 'About bitcash',
  api_status: 'Connection Status',
  logout: 'Log Out',
  referralLinkLabel: 'Referral Link',
  referralLinkSubLabel: 'Share this link to earn bitcash tokens',
  copiedLinkBtnLabel: 'Copied ✓',
  copyLinkBtnLabel: 'Copy Link',
  devices: 'Devices',
  no_devices: "You don't have any device",
  delete_device: 'Do you want to delete this device?',
  keys: 'Keys',
  no_keys: "You don't have any key",
  delete_key: 'Do you want to delete this key?',
  delete: 'Delete',
  loading_devices_keys: 'Loading Devices and Keys',
  enter_public_key: 'Please enter a public key',
  enter_valid_public_key: 'Please enter a valid public key',
  public_key_modal_title: 'Enter a public key to add as a valid signing method to your account',
  public_key: 'Public Key',
  add_key: 'Add key',
  this_session: 'This session',
  profile: 'Profile Options',
  key_recovery: 'Key Recovery',
  add_partners: 'Add Partners',
  update_partner: 'Update Partner',
  generating_recovery_keys: 'Generating Recovery ID',
  id_type: 'Id Type',
  country: 'Country',
  not_found: 'bitcash account not found',
  partners_recommended: '3 minimum partners recommended',
  dao_recovery: 'DAO Recovery',
  info_primary:
    'Recovery Partners are people you trust to identify and verify you in case you lose your phone or device and need to recover your account.',
  info_secondary: 'Please enter the bitcash account of each of your Recovery Partners.',
  info_tertiary:
    'DAO Recovery enables the bitcash organization to act as one of your Recovery Partners.',
  warning: 'Warning',
  warning_secondary:
    'If you want to remove/add any partner, you have to go through the recovery process.',
  recovery_partner: 'Recovery Partners',
  agree: 'I agree to add these partners for my key recovery process',
  Submit: 'submit',
  date_of_birth: 'Date of Birth',
  register_ip: 'Register IP',
}
