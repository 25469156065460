import * as React from 'react'
import { SVGProps } from 'react'

const CheckIconWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 20 21"
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 .5C4.5.5 0 5 0 10.5s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10Zm-2 16-4.5-5L5 10l3 3.5 6.59-7.42L16 7.5l-8 9Z"
      fill="#29E0AD"
    />
  </svg>
)

export default CheckIconWhite
