import { WizardStep } from 'app-view/components/Wizard'
import { ActionSuccess } from 'pages/WalletView/steps/common/ActionSuccess'
import { DepositAmount } from 'pages/WalletView/steps/deposit-steps/DepositAmount'
import { DepositConfirm } from '../common/DepositConfirm'

export const DEPOSIT_STEPS: WizardStep[] = [
  {
    name: 'amount',
    component: DepositAmount,
  },
  {
    name: 'confirm',
    component: DepositConfirm,
  },
  {
    name: 'success',
    component: ActionSuccess,
  },
]
