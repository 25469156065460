import { WizardStep } from 'app-view/components/Wizard'
import { AccountInfo } from './AccountInfo'
import { NoAccountInfo } from './NoAccountInfo'
import { Success } from './Success'

export const PENDING_ACCOUNT_STEPS: WizardStep[] = [
  {
    name: 'account_info',
    component: AccountInfo,
  },
  {
    name: 'no_account_info',
    component: NoAccountInfo,
  },
  {
    name: 'success',
    component: Success,
  },
]
