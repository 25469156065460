import React from 'react'

const USDTTRONIcon = (props: any) => (
  <svg viewBox="0 0 62 58" {...props} id="tron-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4949 0.00132486C17.394 -0.0869867 3.31684 4.22055 0.755737 18.6637C-0.455081 25.4906 -0.0261733 32.1073 0.755737 38.9566C2.35797 52.9824 16.3583 58.3349 28.9996 57.9839C41.5518 57.6356 55.6002 53.3518 57.2444 38.9561C58.0264 32.1073 58.4548 25.4906 57.2444 18.6637C54.9998 6.00651 42.416 0.0824639 30.4949 0.00132486Z"
      fill="#26A17B"
    />
    <path
      d="M31.9601 29.7788V29.7748C31.7899 29.7867 30.9115 29.8381 28.9568 29.8381C27.3939 29.8381 26.2979 29.7946 25.9101 29.7748V29.7788C19.8996 29.5137 15.4165 28.4691 15.4165 27.2147C15.4165 25.9644 19.9036 24.9158 25.9101 24.6507V28.7381C26.3018 28.7658 27.4295 28.8331 28.9845 28.8331C30.8522 28.8331 31.786 28.754 31.9601 28.7381V24.6547C37.9587 24.9237 42.4299 25.9683 42.4299 27.2187C42.4299 28.4691 37.9547 29.5137 31.9601 29.7827V29.7788ZM31.9601 24.2313V20.5752H40.3288V15H17.5453V20.5752H25.914V24.2313C19.1122 24.5439 14 25.8892 14 27.5036C14 29.118 19.1162 30.4633 25.914 30.7799V42.5H31.964V30.7799C38.754 30.4673 43.8583 29.1219 43.8583 27.5076C43.8583 25.8971 38.754 24.5478 31.964 24.2353"
      fill="white"
    />
    <circle cx="49" cy="45" r="13" fill="white" />
    <path
      d="M58.75 42.0253C57.8125 41.1596 56.5156 39.8378 55.4594 38.9003L55.3969 38.8565C55.2929 38.773 55.1756 38.7075 55.05 38.6628C52.5031 38.1878 40.65 35.9721 40.4188 36.0003C40.354 36.0093 40.292 36.0328 40.2375 36.069L40.1781 36.1159C40.105 36.1901 40.0495 36.2798 40.0156 36.3784L40 36.419V36.6409V36.6753C41.3344 40.3909 46.6031 52.5628 47.6406 55.419C47.7031 55.6128 47.8219 55.9815 48.0438 56.0003H48.0938C48.2125 56.0003 48.7188 55.3315 48.7188 55.3315C48.7188 55.3315 57.7688 44.3565 58.6844 43.1878C58.8029 43.0438 58.9075 42.8889 58.9969 42.7253C59.0197 42.5972 59.0089 42.4654 58.9657 42.3427C58.9224 42.2201 58.8481 42.1107 58.75 42.0253ZM51.0406 43.3034L54.9031 40.1003L57.1688 42.1878L51.0406 43.3034ZM49.5406 43.094L42.8906 37.644L53.65 39.6284L49.5406 43.094ZM50.1406 44.5221L56.9469 43.4253L49.1656 52.8003L50.1406 44.5221ZM41.9875 38.1878L48.9844 44.1253L47.9719 52.8065L41.9875 38.1878Z"
      fill="#FF060A"
    />
  </svg>
)

export default USDTTRONIcon
