import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  jsonb: any
  timestamptz: any
  uuid: any
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>
  _gt?: InputMaybe<Scalars['Boolean']>
  _gte?: InputMaybe<Scalars['Boolean']>
  _in?: InputMaybe<Array<Scalars['Boolean']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Boolean']>
  _lte?: InputMaybe<Scalars['Boolean']>
  _neq?: InputMaybe<Scalars['Boolean']>
  _nin?: InputMaybe<Array<Scalars['Boolean']>>
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>
  _gt?: InputMaybe<Scalars['Int']>
  _gte?: InputMaybe<Scalars['Int']>
  _in?: InputMaybe<Array<Scalars['Int']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['Int']>
  _lte?: InputMaybe<Scalars['Int']>
  _neq?: InputMaybe<Scalars['Int']>
  _nin?: InputMaybe<Array<Scalars['Int']>>
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>
  _gt?: InputMaybe<Scalars['String']>
  _gte?: InputMaybe<Scalars['String']>
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>
  _in?: InputMaybe<Array<Scalars['String']>>
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>
  _is_null?: InputMaybe<Scalars['Boolean']>
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>
  _lt?: InputMaybe<Scalars['String']>
  _lte?: InputMaybe<Scalars['String']>
  _neq?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>
  _nin?: InputMaybe<Array<Scalars['String']>>
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "actions" */
export type Actions = {
  account_disk_deltas?: Maybe<Scalars['jsonb']>
  account_ram_deltas?: Maybe<Scalars['jsonb']>
  action: Scalars['String']
  action_ordinal: Scalars['Int']
  authorization: Scalars['jsonb']
  chain: Scalars['String']
  console?: Maybe<Scalars['String']>
  context_free?: Maybe<Scalars['Boolean']>
  contract: Scalars['String']
  data: Scalars['jsonb']
  global_sequence: Scalars['String']
  receipt?: Maybe<Scalars['jsonb']>
  receiver?: Maybe<Scalars['String']>
  /** An object relationship */
  transaction?: Maybe<Transactions>
  transaction_id: Scalars['String']
}

/** columns and relationships of "actions" */
export type ActionsAccount_Disk_DeltasArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "actions" */
export type ActionsAccount_Ram_DeltasArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "actions" */
export type ActionsAuthorizationArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "actions" */
export type ActionsDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "actions" */
export type ActionsReceiptArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "actions" */
export type Actions_Aggregate = {
  aggregate?: Maybe<Actions_Aggregate_Fields>
  nodes: Array<Actions>
}

/** aggregate fields of "actions" */
export type Actions_Aggregate_Fields = {
  avg?: Maybe<Actions_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Actions_Max_Fields>
  min?: Maybe<Actions_Min_Fields>
  stddev?: Maybe<Actions_Stddev_Fields>
  stddev_pop?: Maybe<Actions_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Actions_Stddev_Samp_Fields>
  sum?: Maybe<Actions_Sum_Fields>
  var_pop?: Maybe<Actions_Var_Pop_Fields>
  var_samp?: Maybe<Actions_Var_Samp_Fields>
  variance?: Maybe<Actions_Variance_Fields>
}

/** aggregate fields of "actions" */
export type Actions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Actions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Actions_Append_Input = {
  account_disk_deltas?: InputMaybe<Scalars['jsonb']>
  account_ram_deltas?: InputMaybe<Scalars['jsonb']>
  authorization?: InputMaybe<Scalars['jsonb']>
  data?: InputMaybe<Scalars['jsonb']>
  receipt?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Actions_Avg_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "actions". All fields are combined with a logical 'AND'. */
export type Actions_Bool_Exp = {
  _and?: InputMaybe<Array<Actions_Bool_Exp>>
  _not?: InputMaybe<Actions_Bool_Exp>
  _or?: InputMaybe<Array<Actions_Bool_Exp>>
  account_disk_deltas?: InputMaybe<Jsonb_Comparison_Exp>
  account_ram_deltas?: InputMaybe<Jsonb_Comparison_Exp>
  action?: InputMaybe<String_Comparison_Exp>
  action_ordinal?: InputMaybe<Int_Comparison_Exp>
  authorization?: InputMaybe<Jsonb_Comparison_Exp>
  chain?: InputMaybe<String_Comparison_Exp>
  console?: InputMaybe<String_Comparison_Exp>
  context_free?: InputMaybe<Boolean_Comparison_Exp>
  contract?: InputMaybe<String_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
  global_sequence?: InputMaybe<String_Comparison_Exp>
  receipt?: InputMaybe<Jsonb_Comparison_Exp>
  receiver?: InputMaybe<String_Comparison_Exp>
  transaction?: InputMaybe<Transactions_Bool_Exp>
  transaction_id?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "actions" */
export enum Actions_Constraint {
  /** unique or primary key constraint on columns "global_sequence", "chain" */
  ActionsPkey = 'actions_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Actions_Delete_At_Path_Input = {
  account_disk_deltas?: InputMaybe<Array<Scalars['String']>>
  account_ram_deltas?: InputMaybe<Array<Scalars['String']>>
  authorization?: InputMaybe<Array<Scalars['String']>>
  data?: InputMaybe<Array<Scalars['String']>>
  receipt?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Actions_Delete_Elem_Input = {
  account_disk_deltas?: InputMaybe<Scalars['Int']>
  account_ram_deltas?: InputMaybe<Scalars['Int']>
  authorization?: InputMaybe<Scalars['Int']>
  data?: InputMaybe<Scalars['Int']>
  receipt?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Actions_Delete_Key_Input = {
  account_disk_deltas?: InputMaybe<Scalars['String']>
  account_ram_deltas?: InputMaybe<Scalars['String']>
  authorization?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['String']>
  receipt?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "actions" */
export type Actions_Inc_Input = {
  action_ordinal?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "actions" */
export type Actions_Insert_Input = {
  account_disk_deltas?: InputMaybe<Scalars['jsonb']>
  account_ram_deltas?: InputMaybe<Scalars['jsonb']>
  action?: InputMaybe<Scalars['String']>
  action_ordinal?: InputMaybe<Scalars['Int']>
  authorization?: InputMaybe<Scalars['jsonb']>
  chain?: InputMaybe<Scalars['String']>
  console?: InputMaybe<Scalars['String']>
  context_free?: InputMaybe<Scalars['Boolean']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  global_sequence?: InputMaybe<Scalars['String']>
  receipt?: InputMaybe<Scalars['jsonb']>
  receiver?: InputMaybe<Scalars['String']>
  transaction?: InputMaybe<Transactions_Obj_Rel_Insert_Input>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Actions_Max_Fields = {
  action?: Maybe<Scalars['String']>
  action_ordinal?: Maybe<Scalars['Int']>
  chain?: Maybe<Scalars['String']>
  console?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  global_sequence?: Maybe<Scalars['String']>
  receiver?: Maybe<Scalars['String']>
  transaction_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Actions_Min_Fields = {
  action?: Maybe<Scalars['String']>
  action_ordinal?: Maybe<Scalars['Int']>
  chain?: Maybe<Scalars['String']>
  console?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  global_sequence?: Maybe<Scalars['String']>
  receiver?: Maybe<Scalars['String']>
  transaction_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "actions" */
export type Actions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Actions>
}

/** on_conflict condition type for table "actions" */
export type Actions_On_Conflict = {
  constraint: Actions_Constraint
  update_columns?: Array<Actions_Update_Column>
  where?: InputMaybe<Actions_Bool_Exp>
}

/** Ordering options when selecting data from "actions". */
export type Actions_Order_By = {
  account_disk_deltas?: InputMaybe<Order_By>
  account_ram_deltas?: InputMaybe<Order_By>
  action?: InputMaybe<Order_By>
  action_ordinal?: InputMaybe<Order_By>
  authorization?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  console?: InputMaybe<Order_By>
  context_free?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
  global_sequence?: InputMaybe<Order_By>
  receipt?: InputMaybe<Order_By>
  receiver?: InputMaybe<Order_By>
  transaction?: InputMaybe<Transactions_Order_By>
  transaction_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: actions */
export type Actions_Pk_Columns_Input = {
  chain: Scalars['String']
  global_sequence: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Actions_Prepend_Input = {
  account_disk_deltas?: InputMaybe<Scalars['jsonb']>
  account_ram_deltas?: InputMaybe<Scalars['jsonb']>
  authorization?: InputMaybe<Scalars['jsonb']>
  data?: InputMaybe<Scalars['jsonb']>
  receipt?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "actions" */
export enum Actions_Select_Column {
  /** column name */
  AccountDiskDeltas = 'account_disk_deltas',
  /** column name */
  AccountRamDeltas = 'account_ram_deltas',
  /** column name */
  Action = 'action',
  /** column name */
  ActionOrdinal = 'action_ordinal',
  /** column name */
  Authorization = 'authorization',
  /** column name */
  Chain = 'chain',
  /** column name */
  Console = 'console',
  /** column name */
  ContextFree = 'context_free',
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  GlobalSequence = 'global_sequence',
  /** column name */
  Receipt = 'receipt',
  /** column name */
  Receiver = 'receiver',
  /** column name */
  TransactionId = 'transaction_id',
}

/** input type for updating data in table "actions" */
export type Actions_Set_Input = {
  account_disk_deltas?: InputMaybe<Scalars['jsonb']>
  account_ram_deltas?: InputMaybe<Scalars['jsonb']>
  action?: InputMaybe<Scalars['String']>
  action_ordinal?: InputMaybe<Scalars['Int']>
  authorization?: InputMaybe<Scalars['jsonb']>
  chain?: InputMaybe<Scalars['String']>
  console?: InputMaybe<Scalars['String']>
  context_free?: InputMaybe<Scalars['Boolean']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  global_sequence?: InputMaybe<Scalars['String']>
  receipt?: InputMaybe<Scalars['jsonb']>
  receiver?: InputMaybe<Scalars['String']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Actions_Stddev_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Actions_Stddev_Pop_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Actions_Stddev_Samp_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "actions" */
export type Actions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Actions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Actions_Stream_Cursor_Value_Input = {
  account_disk_deltas?: InputMaybe<Scalars['jsonb']>
  account_ram_deltas?: InputMaybe<Scalars['jsonb']>
  action?: InputMaybe<Scalars['String']>
  action_ordinal?: InputMaybe<Scalars['Int']>
  authorization?: InputMaybe<Scalars['jsonb']>
  chain?: InputMaybe<Scalars['String']>
  console?: InputMaybe<Scalars['String']>
  context_free?: InputMaybe<Scalars['Boolean']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  global_sequence?: InputMaybe<Scalars['String']>
  receipt?: InputMaybe<Scalars['jsonb']>
  receiver?: InputMaybe<Scalars['String']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Actions_Sum_Fields = {
  action_ordinal?: Maybe<Scalars['Int']>
}

/** update columns of table "actions" */
export enum Actions_Update_Column {
  /** column name */
  AccountDiskDeltas = 'account_disk_deltas',
  /** column name */
  AccountRamDeltas = 'account_ram_deltas',
  /** column name */
  Action = 'action',
  /** column name */
  ActionOrdinal = 'action_ordinal',
  /** column name */
  Authorization = 'authorization',
  /** column name */
  Chain = 'chain',
  /** column name */
  Console = 'console',
  /** column name */
  ContextFree = 'context_free',
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  GlobalSequence = 'global_sequence',
  /** column name */
  Receipt = 'receipt',
  /** column name */
  Receiver = 'receiver',
  /** column name */
  TransactionId = 'transaction_id',
}

export type Actions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Actions_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Actions_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Actions_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Actions_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Actions_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Actions_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Actions_Set_Input>
  where: Actions_Bool_Exp
}

/** aggregate var_pop on columns */
export type Actions_Var_Pop_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Actions_Var_Samp_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Actions_Variance_Fields = {
  action_ordinal?: Maybe<Scalars['Float']>
}

/** columns and relationships of "api_users" */
export type Api_Users = {
  account: Scalars['String']
  api_key: Scalars['String']
  created_at?: Maybe<Scalars['timestamptz']>
  domain_names?: Maybe<Scalars['String']>
  id: Scalars['Int']
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregated selection of "api_users" */
export type Api_Users_Aggregate = {
  aggregate?: Maybe<Api_Users_Aggregate_Fields>
  nodes: Array<Api_Users>
}

/** aggregate fields of "api_users" */
export type Api_Users_Aggregate_Fields = {
  avg?: Maybe<Api_Users_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Api_Users_Max_Fields>
  min?: Maybe<Api_Users_Min_Fields>
  stddev?: Maybe<Api_Users_Stddev_Fields>
  stddev_pop?: Maybe<Api_Users_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Api_Users_Stddev_Samp_Fields>
  sum?: Maybe<Api_Users_Sum_Fields>
  var_pop?: Maybe<Api_Users_Var_Pop_Fields>
  var_samp?: Maybe<Api_Users_Var_Samp_Fields>
  variance?: Maybe<Api_Users_Variance_Fields>
}

/** aggregate fields of "api_users" */
export type Api_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Api_Users_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Api_Users_Avg_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "api_users". All fields are combined with a logical 'AND'. */
export type Api_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Api_Users_Bool_Exp>>
  _not?: InputMaybe<Api_Users_Bool_Exp>
  _or?: InputMaybe<Array<Api_Users_Bool_Exp>>
  account?: InputMaybe<String_Comparison_Exp>
  api_key?: InputMaybe<String_Comparison_Exp>
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>
  domain_names?: InputMaybe<String_Comparison_Exp>
  id?: InputMaybe<Int_Comparison_Exp>
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "api_users" */
export enum Api_Users_Constraint {
  /** unique or primary key constraint on columns "api_key" */
  ApiUsersApiKeyKey = 'api_users_api_key_key',
  /** unique or primary key constraint on columns "id" */
  ApiUsersPkey = 'api_users_pkey',
}

/** input type for incrementing numeric columns in table "api_users" */
export type Api_Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "api_users" */
export type Api_Users_Insert_Input = {
  account?: InputMaybe<Scalars['String']>
  api_key?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  domain_names?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Api_Users_Max_Fields = {
  account?: Maybe<Scalars['String']>
  api_key?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  domain_names?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate min on columns */
export type Api_Users_Min_Fields = {
  account?: Maybe<Scalars['String']>
  api_key?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  domain_names?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** response of any mutation on the table "api_users" */
export type Api_Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Api_Users>
}

/** on_conflict condition type for table "api_users" */
export type Api_Users_On_Conflict = {
  constraint: Api_Users_Constraint
  update_columns?: Array<Api_Users_Update_Column>
  where?: InputMaybe<Api_Users_Bool_Exp>
}

/** Ordering options when selecting data from "api_users". */
export type Api_Users_Order_By = {
  account?: InputMaybe<Order_By>
  api_key?: InputMaybe<Order_By>
  created_at?: InputMaybe<Order_By>
  domain_names?: InputMaybe<Order_By>
  id?: InputMaybe<Order_By>
  updated_at?: InputMaybe<Order_By>
}

/** primary key columns input for table: api_users */
export type Api_Users_Pk_Columns_Input = {
  id: Scalars['Int']
}

/** select columns of table "api_users" */
export enum Api_Users_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DomainNames = 'domain_names',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "api_users" */
export type Api_Users_Set_Input = {
  account?: InputMaybe<Scalars['String']>
  api_key?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  domain_names?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Api_Users_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Api_Users_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Api_Users_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "api_users" */
export type Api_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Api_Users_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Api_Users_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['String']>
  api_key?: InputMaybe<Scalars['String']>
  created_at?: InputMaybe<Scalars['timestamptz']>
  domain_names?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['Int']>
  updated_at?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Api_Users_Sum_Fields = {
  id?: Maybe<Scalars['Int']>
}

/** update columns of table "api_users" */
export enum Api_Users_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  ApiKey = 'api_key',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DomainNames = 'domain_names',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Api_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Api_Users_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Api_Users_Set_Input>
  where: Api_Users_Bool_Exp
}

/** aggregate var_pop on columns */
export type Api_Users_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Api_Users_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Api_Users_Variance_Fields = {
  id?: Maybe<Scalars['Float']>
}

/** columns and relationships of "blocks" */
export type Blocks = {
  block_id?: Maybe<Scalars['String']>
  block_num: Scalars['Int']
  chain: Scalars['String']
  /** An object relationship */
  chian: Chains
  producer: Scalars['String']
  timestamp: Scalars['timestamptz']
}

/** aggregated selection of "blocks" */
export type Blocks_Aggregate = {
  aggregate?: Maybe<Blocks_Aggregate_Fields>
  nodes: Array<Blocks>
}

export type Blocks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Blocks_Aggregate_Bool_Exp_Count>
}

export type Blocks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Blocks_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Blocks_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "blocks" */
export type Blocks_Aggregate_Fields = {
  avg?: Maybe<Blocks_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Blocks_Max_Fields>
  min?: Maybe<Blocks_Min_Fields>
  stddev?: Maybe<Blocks_Stddev_Fields>
  stddev_pop?: Maybe<Blocks_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Blocks_Stddev_Samp_Fields>
  sum?: Maybe<Blocks_Sum_Fields>
  var_pop?: Maybe<Blocks_Var_Pop_Fields>
  var_samp?: Maybe<Blocks_Var_Samp_Fields>
  variance?: Maybe<Blocks_Variance_Fields>
}

/** aggregate fields of "blocks" */
export type Blocks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blocks_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "blocks" */
export type Blocks_Aggregate_Order_By = {
  avg?: InputMaybe<Blocks_Avg_Order_By>
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Blocks_Max_Order_By>
  min?: InputMaybe<Blocks_Min_Order_By>
  stddev?: InputMaybe<Blocks_Stddev_Order_By>
  stddev_pop?: InputMaybe<Blocks_Stddev_Pop_Order_By>
  stddev_samp?: InputMaybe<Blocks_Stddev_Samp_Order_By>
  sum?: InputMaybe<Blocks_Sum_Order_By>
  var_pop?: InputMaybe<Blocks_Var_Pop_Order_By>
  var_samp?: InputMaybe<Blocks_Var_Samp_Order_By>
  variance?: InputMaybe<Blocks_Variance_Order_By>
}

/** input type for inserting array relation for remote table "blocks" */
export type Blocks_Arr_Rel_Insert_Input = {
  data: Array<Blocks_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Blocks_On_Conflict>
}

/** aggregate avg on columns */
export type Blocks_Avg_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "blocks" */
export type Blocks_Avg_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** Boolean expression to filter rows from the table "blocks". All fields are combined with a logical 'AND'. */
export type Blocks_Bool_Exp = {
  _and?: InputMaybe<Array<Blocks_Bool_Exp>>
  _not?: InputMaybe<Blocks_Bool_Exp>
  _or?: InputMaybe<Array<Blocks_Bool_Exp>>
  block_id?: InputMaybe<String_Comparison_Exp>
  block_num?: InputMaybe<Int_Comparison_Exp>
  chain?: InputMaybe<String_Comparison_Exp>
  chian?: InputMaybe<Chains_Bool_Exp>
  producer?: InputMaybe<String_Comparison_Exp>
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "blocks" */
export enum Blocks_Constraint {
  /** unique or primary key constraint on columns "block_id" */
  BlocksBlockIdKey = 'blocks_block_id_key',
  /** unique or primary key constraint on columns "chain", "block_num" */
  BlocksPkey = 'blocks_pkey',
}

/** input type for incrementing numeric columns in table "blocks" */
export type Blocks_Inc_Input = {
  block_num?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "blocks" */
export type Blocks_Insert_Input = {
  block_id?: InputMaybe<Scalars['String']>
  block_num?: InputMaybe<Scalars['Int']>
  chain?: InputMaybe<Scalars['String']>
  chian?: InputMaybe<Chains_Obj_Rel_Insert_Input>
  producer?: InputMaybe<Scalars['String']>
  timestamp?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Blocks_Max_Fields = {
  block_id?: Maybe<Scalars['String']>
  block_num?: Maybe<Scalars['Int']>
  chain?: Maybe<Scalars['String']>
  producer?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "blocks" */
export type Blocks_Max_Order_By = {
  block_id?: InputMaybe<Order_By>
  block_num?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  producer?: InputMaybe<Order_By>
  timestamp?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Blocks_Min_Fields = {
  block_id?: Maybe<Scalars['String']>
  block_num?: Maybe<Scalars['Int']>
  chain?: Maybe<Scalars['String']>
  producer?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "blocks" */
export type Blocks_Min_Order_By = {
  block_id?: InputMaybe<Order_By>
  block_num?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  producer?: InputMaybe<Order_By>
  timestamp?: InputMaybe<Order_By>
}

/** response of any mutation on the table "blocks" */
export type Blocks_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Blocks>
}

/** on_conflict condition type for table "blocks" */
export type Blocks_On_Conflict = {
  constraint: Blocks_Constraint
  update_columns?: Array<Blocks_Update_Column>
  where?: InputMaybe<Blocks_Bool_Exp>
}

/** Ordering options when selecting data from "blocks". */
export type Blocks_Order_By = {
  block_id?: InputMaybe<Order_By>
  block_num?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  chian?: InputMaybe<Chains_Order_By>
  producer?: InputMaybe<Order_By>
  timestamp?: InputMaybe<Order_By>
}

/** primary key columns input for table: blocks */
export type Blocks_Pk_Columns_Input = {
  block_num: Scalars['Int']
  chain: Scalars['String']
}

/** select columns of table "blocks" */
export enum Blocks_Select_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  BlockNum = 'block_num',
  /** column name */
  Chain = 'chain',
  /** column name */
  Producer = 'producer',
  /** column name */
  Timestamp = 'timestamp',
}

/** input type for updating data in table "blocks" */
export type Blocks_Set_Input = {
  block_id?: InputMaybe<Scalars['String']>
  block_num?: InputMaybe<Scalars['Int']>
  chain?: InputMaybe<Scalars['String']>
  producer?: InputMaybe<Scalars['String']>
  timestamp?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate stddev on columns */
export type Blocks_Stddev_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "blocks" */
export type Blocks_Stddev_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Blocks_Stddev_Pop_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "blocks" */
export type Blocks_Stddev_Pop_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Blocks_Stddev_Samp_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "blocks" */
export type Blocks_Stddev_Samp_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** Streaming cursor of the table "blocks" */
export type Blocks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blocks_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Blocks_Stream_Cursor_Value_Input = {
  block_id?: InputMaybe<Scalars['String']>
  block_num?: InputMaybe<Scalars['Int']>
  chain?: InputMaybe<Scalars['String']>
  producer?: InputMaybe<Scalars['String']>
  timestamp?: InputMaybe<Scalars['timestamptz']>
}

/** aggregate sum on columns */
export type Blocks_Sum_Fields = {
  block_num?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "blocks" */
export type Blocks_Sum_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** update columns of table "blocks" */
export enum Blocks_Update_Column {
  /** column name */
  BlockId = 'block_id',
  /** column name */
  BlockNum = 'block_num',
  /** column name */
  Chain = 'chain',
  /** column name */
  Producer = 'producer',
  /** column name */
  Timestamp = 'timestamp',
}

export type Blocks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Blocks_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blocks_Set_Input>
  where: Blocks_Bool_Exp
}

/** aggregate var_pop on columns */
export type Blocks_Var_Pop_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "blocks" */
export type Blocks_Var_Pop_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** aggregate var_samp on columns */
export type Blocks_Var_Samp_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "blocks" */
export type Blocks_Var_Samp_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** aggregate variance on columns */
export type Blocks_Variance_Fields = {
  block_num?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "blocks" */
export type Blocks_Variance_Order_By = {
  block_num?: InputMaybe<Order_By>
}

/** columns and relationships of "chains" */
export type Chains = {
  /** An array relationship */
  blocks: Array<Blocks>
  /** An aggregate relationship */
  blocks_aggregate: Blocks_Aggregate
  chain_id: Scalars['String']
  chain_name: Scalars['String']
  rpc_endpoint: Scalars['String']
  /** fetch data from the table: "table_rows" */
  table_rows: Array<Table_Rows>
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: Table_Rows_Aggregate
}

/** columns and relationships of "chains" */
export type ChainsBlocksArgs = {
  distinct_on?: InputMaybe<Array<Blocks_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Blocks_Order_By>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

/** columns and relationships of "chains" */
export type ChainsBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blocks_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Blocks_Order_By>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

/** columns and relationships of "chains" */
export type ChainsTable_RowsArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

/** columns and relationships of "chains" */
export type ChainsTable_Rows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

/** aggregated selection of "chains" */
export type Chains_Aggregate = {
  aggregate?: Maybe<Chains_Aggregate_Fields>
  nodes: Array<Chains>
}

/** aggregate fields of "chains" */
export type Chains_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Chains_Max_Fields>
  min?: Maybe<Chains_Min_Fields>
}

/** aggregate fields of "chains" */
export type Chains_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Chains_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "chains". All fields are combined with a logical 'AND'. */
export type Chains_Bool_Exp = {
  _and?: InputMaybe<Array<Chains_Bool_Exp>>
  _not?: InputMaybe<Chains_Bool_Exp>
  _or?: InputMaybe<Array<Chains_Bool_Exp>>
  blocks?: InputMaybe<Blocks_Bool_Exp>
  blocks_aggregate?: InputMaybe<Blocks_Aggregate_Bool_Exp>
  chain_id?: InputMaybe<String_Comparison_Exp>
  chain_name?: InputMaybe<String_Comparison_Exp>
  rpc_endpoint?: InputMaybe<String_Comparison_Exp>
  table_rows?: InputMaybe<Table_Rows_Bool_Exp>
  table_rows_aggregate?: InputMaybe<Table_Rows_Aggregate_Bool_Exp>
}

/** unique or primary key constraints on table "chains" */
export enum Chains_Constraint {
  /** unique or primary key constraint on columns "chain_name" */
  ChainsPkey = 'chains_pkey',
}

/** input type for inserting data into table "chains" */
export type Chains_Insert_Input = {
  blocks?: InputMaybe<Blocks_Arr_Rel_Insert_Input>
  chain_id?: InputMaybe<Scalars['String']>
  chain_name?: InputMaybe<Scalars['String']>
  rpc_endpoint?: InputMaybe<Scalars['String']>
  table_rows?: InputMaybe<Table_Rows_Arr_Rel_Insert_Input>
}

/** aggregate max on columns */
export type Chains_Max_Fields = {
  chain_id?: Maybe<Scalars['String']>
  chain_name?: Maybe<Scalars['String']>
  rpc_endpoint?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Chains_Min_Fields = {
  chain_id?: Maybe<Scalars['String']>
  chain_name?: Maybe<Scalars['String']>
  rpc_endpoint?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "chains" */
export type Chains_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Chains>
}

/** input type for inserting object relation for remote table "chains" */
export type Chains_Obj_Rel_Insert_Input = {
  data: Chains_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Chains_On_Conflict>
}

/** on_conflict condition type for table "chains" */
export type Chains_On_Conflict = {
  constraint: Chains_Constraint
  update_columns?: Array<Chains_Update_Column>
  where?: InputMaybe<Chains_Bool_Exp>
}

/** Ordering options when selecting data from "chains". */
export type Chains_Order_By = {
  blocks_aggregate?: InputMaybe<Blocks_Aggregate_Order_By>
  chain_id?: InputMaybe<Order_By>
  chain_name?: InputMaybe<Order_By>
  rpc_endpoint?: InputMaybe<Order_By>
  table_rows_aggregate?: InputMaybe<Table_Rows_Aggregate_Order_By>
}

/** primary key columns input for table: chains */
export type Chains_Pk_Columns_Input = {
  chain_name: Scalars['String']
}

/** select columns of table "chains" */
export enum Chains_Select_Column {
  /** column name */
  ChainId = 'chain_id',
  /** column name */
  ChainName = 'chain_name',
  /** column name */
  RpcEndpoint = 'rpc_endpoint',
}

/** input type for updating data in table "chains" */
export type Chains_Set_Input = {
  chain_id?: InputMaybe<Scalars['String']>
  chain_name?: InputMaybe<Scalars['String']>
  rpc_endpoint?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "chains" */
export type Chains_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Chains_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Chains_Stream_Cursor_Value_Input = {
  chain_id?: InputMaybe<Scalars['String']>
  chain_name?: InputMaybe<Scalars['String']>
  rpc_endpoint?: InputMaybe<Scalars['String']>
}

/** update columns of table "chains" */
export enum Chains_Update_Column {
  /** column name */
  ChainId = 'chain_id',
  /** column name */
  ChainName = 'chain_name',
  /** column name */
  RpcEndpoint = 'rpc_endpoint',
}

export type Chains_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Chains_Set_Input>
  where: Chains_Bool_Exp
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>
  _eq?: InputMaybe<Scalars['jsonb']>
  _gt?: InputMaybe<Scalars['jsonb']>
  _gte?: InputMaybe<Scalars['jsonb']>
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>
  _in?: InputMaybe<Array<Scalars['jsonb']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['jsonb']>
  _lte?: InputMaybe<Scalars['jsonb']>
  _neq?: InputMaybe<Scalars['jsonb']>
  _nin?: InputMaybe<Array<Scalars['jsonb']>>
}

/** columns and relationships of "manifests" */
export type Manifests = {
  app_id: Scalars['uuid']
  app_name: Scalars['String']
  description: Scalars['String']
  url: Scalars['String']
}

/** aggregated selection of "manifests" */
export type Manifests_Aggregate = {
  aggregate?: Maybe<Manifests_Aggregate_Fields>
  nodes: Array<Manifests>
}

/** aggregate fields of "manifests" */
export type Manifests_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Manifests_Max_Fields>
  min?: Maybe<Manifests_Min_Fields>
}

/** aggregate fields of "manifests" */
export type Manifests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Manifests_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** Boolean expression to filter rows from the table "manifests". All fields are combined with a logical 'AND'. */
export type Manifests_Bool_Exp = {
  _and?: InputMaybe<Array<Manifests_Bool_Exp>>
  _not?: InputMaybe<Manifests_Bool_Exp>
  _or?: InputMaybe<Array<Manifests_Bool_Exp>>
  app_id?: InputMaybe<Uuid_Comparison_Exp>
  app_name?: InputMaybe<String_Comparison_Exp>
  description?: InputMaybe<String_Comparison_Exp>
  url?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "manifests" */
export enum Manifests_Constraint {
  /** unique or primary key constraint on columns "app_id" */
  ManifestsPkey = 'manifests_pkey',
}

/** input type for inserting data into table "manifests" */
export type Manifests_Insert_Input = {
  app_id?: InputMaybe<Scalars['uuid']>
  app_name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Manifests_Max_Fields = {
  app_id?: Maybe<Scalars['uuid']>
  app_name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Manifests_Min_Fields = {
  app_id?: Maybe<Scalars['uuid']>
  app_name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "manifests" */
export type Manifests_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Manifests>
}

/** input type for inserting object relation for remote table "manifests" */
export type Manifests_Obj_Rel_Insert_Input = {
  data: Manifests_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Manifests_On_Conflict>
}

/** on_conflict condition type for table "manifests" */
export type Manifests_On_Conflict = {
  constraint: Manifests_Constraint
  update_columns?: Array<Manifests_Update_Column>
  where?: InputMaybe<Manifests_Bool_Exp>
}

/** Ordering options when selecting data from "manifests". */
export type Manifests_Order_By = {
  app_id?: InputMaybe<Order_By>
  app_name?: InputMaybe<Order_By>
  description?: InputMaybe<Order_By>
  url?: InputMaybe<Order_By>
}

/** primary key columns input for table: manifests */
export type Manifests_Pk_Columns_Input = {
  app_id: Scalars['uuid']
}

/** select columns of table "manifests" */
export enum Manifests_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  Description = 'description',
  /** column name */
  Url = 'url',
}

/** input type for updating data in table "manifests" */
export type Manifests_Set_Input = {
  app_id?: InputMaybe<Scalars['uuid']>
  app_name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "manifests" */
export type Manifests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Manifests_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Manifests_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['uuid']>
  app_name?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
}

/** update columns of table "manifests" */
export enum Manifests_Update_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  Description = 'description',
  /** column name */
  Url = 'url',
}

export type Manifests_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Manifests_Set_Input>
  where: Manifests_Bool_Exp
}

/** columns and relationships of "mappings" */
export type Mappings = {
  abi?: Maybe<Scalars['jsonb']>
  chain: Scalars['String']
  contract: Scalars['String']
  contract_type?: Maybe<Scalars['String']>
  tables?: Maybe<Scalars['jsonb']>
}

/** columns and relationships of "mappings" */
export type MappingsAbiArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "mappings" */
export type MappingsTablesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "mappings" */
export type Mappings_Aggregate = {
  aggregate?: Maybe<Mappings_Aggregate_Fields>
  nodes: Array<Mappings>
}

/** aggregate fields of "mappings" */
export type Mappings_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Mappings_Max_Fields>
  min?: Maybe<Mappings_Min_Fields>
}

/** aggregate fields of "mappings" */
export type Mappings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mappings_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Mappings_Append_Input = {
  abi?: InputMaybe<Scalars['jsonb']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** Boolean expression to filter rows from the table "mappings". All fields are combined with a logical 'AND'. */
export type Mappings_Bool_Exp = {
  _and?: InputMaybe<Array<Mappings_Bool_Exp>>
  _not?: InputMaybe<Mappings_Bool_Exp>
  _or?: InputMaybe<Array<Mappings_Bool_Exp>>
  abi?: InputMaybe<Jsonb_Comparison_Exp>
  chain?: InputMaybe<String_Comparison_Exp>
  contract?: InputMaybe<String_Comparison_Exp>
  contract_type?: InputMaybe<String_Comparison_Exp>
  tables?: InputMaybe<Jsonb_Comparison_Exp>
}

/** unique or primary key constraints on table "mappings" */
export enum Mappings_Constraint {
  /** unique or primary key constraint on columns "chain", "contract" */
  MappingsPkey = 'mappings_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Mappings_Delete_At_Path_Input = {
  abi?: InputMaybe<Array<Scalars['String']>>
  tables?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Mappings_Delete_Elem_Input = {
  abi?: InputMaybe<Scalars['Int']>
  tables?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Mappings_Delete_Key_Input = {
  abi?: InputMaybe<Scalars['String']>
  tables?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "mappings" */
export type Mappings_Insert_Input = {
  abi?: InputMaybe<Scalars['jsonb']>
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  contract_type?: InputMaybe<Scalars['String']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** aggregate max on columns */
export type Mappings_Max_Fields = {
  chain?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  contract_type?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Mappings_Min_Fields = {
  chain?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  contract_type?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "mappings" */
export type Mappings_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Mappings>
}

/** on_conflict condition type for table "mappings" */
export type Mappings_On_Conflict = {
  constraint: Mappings_Constraint
  update_columns?: Array<Mappings_Update_Column>
  where?: InputMaybe<Mappings_Bool_Exp>
}

/** Ordering options when selecting data from "mappings". */
export type Mappings_Order_By = {
  abi?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  contract_type?: InputMaybe<Order_By>
  tables?: InputMaybe<Order_By>
}

/** primary key columns input for table: mappings */
export type Mappings_Pk_Columns_Input = {
  chain: Scalars['String']
  contract: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Mappings_Prepend_Input = {
  abi?: InputMaybe<Scalars['jsonb']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "mappings" */
export enum Mappings_Select_Column {
  /** column name */
  Abi = 'abi',
  /** column name */
  Chain = 'chain',
  /** column name */
  Contract = 'contract',
  /** column name */
  ContractType = 'contract_type',
  /** column name */
  Tables = 'tables',
}

/** input type for updating data in table "mappings" */
export type Mappings_Set_Input = {
  abi?: InputMaybe<Scalars['jsonb']>
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  contract_type?: InputMaybe<Scalars['String']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** Streaming cursor of the table "mappings" */
export type Mappings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mappings_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Mappings_Stream_Cursor_Value_Input = {
  abi?: InputMaybe<Scalars['jsonb']>
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  contract_type?: InputMaybe<Scalars['String']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** update columns of table "mappings" */
export enum Mappings_Update_Column {
  /** column name */
  Abi = 'abi',
  /** column name */
  Chain = 'chain',
  /** column name */
  Contract = 'contract',
  /** column name */
  ContractType = 'contract_type',
  /** column name */
  Tables = 'tables',
}

export type Mappings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Mappings_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Mappings_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Mappings_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Mappings_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Mappings_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Mappings_Set_Input>
  where: Mappings_Bool_Exp
}

/** mutation root */
export type Mutation_Root = {
  /** delete data from the table: "actions" */
  delete_actions?: Maybe<Actions_Mutation_Response>
  /** delete single row from the table: "actions" */
  delete_actions_by_pk?: Maybe<Actions>
  /** delete data from the table: "api_users" */
  delete_api_users?: Maybe<Api_Users_Mutation_Response>
  /** delete single row from the table: "api_users" */
  delete_api_users_by_pk?: Maybe<Api_Users>
  /** delete data from the table: "blocks" */
  delete_blocks?: Maybe<Blocks_Mutation_Response>
  /** delete single row from the table: "blocks" */
  delete_blocks_by_pk?: Maybe<Blocks>
  /** delete data from the table: "chains" */
  delete_chains?: Maybe<Chains_Mutation_Response>
  /** delete single row from the table: "chains" */
  delete_chains_by_pk?: Maybe<Chains>
  /** delete data from the table: "manifests" */
  delete_manifests?: Maybe<Manifests_Mutation_Response>
  /** delete single row from the table: "manifests" */
  delete_manifests_by_pk?: Maybe<Manifests>
  /** delete data from the table: "mappings" */
  delete_mappings?: Maybe<Mappings_Mutation_Response>
  /** delete single row from the table: "mappings" */
  delete_mappings_by_pk?: Maybe<Mappings>
  /** delete data from the table: "table_rows" */
  delete_table_rows?: Maybe<Table_Rows_Mutation_Response>
  /** delete single row from the table: "table_rows" */
  delete_table_rows_by_pk?: Maybe<Table_Rows>
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>
  /** delete data from the table: "whitelists" */
  delete_whitelists?: Maybe<Whitelists_Mutation_Response>
  /** delete single row from the table: "whitelists" */
  delete_whitelists_by_pk?: Maybe<Whitelists>
  /** insert data into the table: "actions" */
  insert_actions?: Maybe<Actions_Mutation_Response>
  /** insert a single row into the table: "actions" */
  insert_actions_one?: Maybe<Actions>
  /** insert data into the table: "api_users" */
  insert_api_users?: Maybe<Api_Users_Mutation_Response>
  /** insert a single row into the table: "api_users" */
  insert_api_users_one?: Maybe<Api_Users>
  /** insert data into the table: "blocks" */
  insert_blocks?: Maybe<Blocks_Mutation_Response>
  /** insert a single row into the table: "blocks" */
  insert_blocks_one?: Maybe<Blocks>
  /** insert data into the table: "chains" */
  insert_chains?: Maybe<Chains_Mutation_Response>
  /** insert a single row into the table: "chains" */
  insert_chains_one?: Maybe<Chains>
  /** insert data into the table: "manifests" */
  insert_manifests?: Maybe<Manifests_Mutation_Response>
  /** insert a single row into the table: "manifests" */
  insert_manifests_one?: Maybe<Manifests>
  /** insert data into the table: "mappings" */
  insert_mappings?: Maybe<Mappings_Mutation_Response>
  /** insert a single row into the table: "mappings" */
  insert_mappings_one?: Maybe<Mappings>
  /** insert data into the table: "table_rows" */
  insert_table_rows?: Maybe<Table_Rows_Mutation_Response>
  /** insert a single row into the table: "table_rows" */
  insert_table_rows_one?: Maybe<Table_Rows>
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>
  /** insert data into the table: "whitelists" */
  insert_whitelists?: Maybe<Whitelists_Mutation_Response>
  /** insert a single row into the table: "whitelists" */
  insert_whitelists_one?: Maybe<Whitelists>
  /** update data of the table: "actions" */
  update_actions?: Maybe<Actions_Mutation_Response>
  /** update single row of the table: "actions" */
  update_actions_by_pk?: Maybe<Actions>
  /** update multiples rows of table: "actions" */
  update_actions_many?: Maybe<Array<Maybe<Actions_Mutation_Response>>>
  /** update data of the table: "api_users" */
  update_api_users?: Maybe<Api_Users_Mutation_Response>
  /** update single row of the table: "api_users" */
  update_api_users_by_pk?: Maybe<Api_Users>
  /** update multiples rows of table: "api_users" */
  update_api_users_many?: Maybe<Array<Maybe<Api_Users_Mutation_Response>>>
  /** update data of the table: "blocks" */
  update_blocks?: Maybe<Blocks_Mutation_Response>
  /** update single row of the table: "blocks" */
  update_blocks_by_pk?: Maybe<Blocks>
  /** update multiples rows of table: "blocks" */
  update_blocks_many?: Maybe<Array<Maybe<Blocks_Mutation_Response>>>
  /** update data of the table: "chains" */
  update_chains?: Maybe<Chains_Mutation_Response>
  /** update single row of the table: "chains" */
  update_chains_by_pk?: Maybe<Chains>
  /** update multiples rows of table: "chains" */
  update_chains_many?: Maybe<Array<Maybe<Chains_Mutation_Response>>>
  /** update data of the table: "manifests" */
  update_manifests?: Maybe<Manifests_Mutation_Response>
  /** update single row of the table: "manifests" */
  update_manifests_by_pk?: Maybe<Manifests>
  /** update multiples rows of table: "manifests" */
  update_manifests_many?: Maybe<Array<Maybe<Manifests_Mutation_Response>>>
  /** update data of the table: "mappings" */
  update_mappings?: Maybe<Mappings_Mutation_Response>
  /** update single row of the table: "mappings" */
  update_mappings_by_pk?: Maybe<Mappings>
  /** update multiples rows of table: "mappings" */
  update_mappings_many?: Maybe<Array<Maybe<Mappings_Mutation_Response>>>
  /** update data of the table: "table_rows" */
  update_table_rows?: Maybe<Table_Rows_Mutation_Response>
  /** update single row of the table: "table_rows" */
  update_table_rows_by_pk?: Maybe<Table_Rows>
  /** update multiples rows of table: "table_rows" */
  update_table_rows_many?: Maybe<Array<Maybe<Table_Rows_Mutation_Response>>>
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<Array<Maybe<Transactions_Mutation_Response>>>
  /** update data of the table: "whitelists" */
  update_whitelists?: Maybe<Whitelists_Mutation_Response>
  /** update single row of the table: "whitelists" */
  update_whitelists_by_pk?: Maybe<Whitelists>
  /** update multiples rows of table: "whitelists" */
  update_whitelists_many?: Maybe<Array<Maybe<Whitelists_Mutation_Response>>>
}

/** mutation root */
export type Mutation_RootDelete_ActionsArgs = {
  where: Actions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Actions_By_PkArgs = {
  chain: Scalars['String']
  global_sequence: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Api_UsersArgs = {
  where: Api_Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Api_Users_By_PkArgs = {
  id: Scalars['Int']
}

/** mutation root */
export type Mutation_RootDelete_BlocksArgs = {
  where: Blocks_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Blocks_By_PkArgs = {
  block_num: Scalars['Int']
  chain: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_ChainsArgs = {
  where: Chains_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Chains_By_PkArgs = {
  chain_name: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_ManifestsArgs = {
  where: Manifests_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Manifests_By_PkArgs = {
  app_id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_MappingsArgs = {
  where: Mappings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Mappings_By_PkArgs = {
  chain: Scalars['String']
  contract: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Table_RowsArgs = {
  where: Table_Rows_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Table_Rows_By_PkArgs = {
  chain: Scalars['String']
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  chain: Scalars['String']
  transaction_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_WhitelistsArgs = {
  where: Whitelists_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Whitelists_By_PkArgs = {
  app_id: Scalars['uuid']
  chain: Scalars['String']
  contract: Scalars['String']
}

/** mutation root */
export type Mutation_RootInsert_ActionsArgs = {
  objects: Array<Actions_Insert_Input>
  on_conflict?: InputMaybe<Actions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Actions_OneArgs = {
  object: Actions_Insert_Input
  on_conflict?: InputMaybe<Actions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Api_UsersArgs = {
  objects: Array<Api_Users_Insert_Input>
  on_conflict?: InputMaybe<Api_Users_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Api_Users_OneArgs = {
  object: Api_Users_Insert_Input
  on_conflict?: InputMaybe<Api_Users_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_BlocksArgs = {
  objects: Array<Blocks_Insert_Input>
  on_conflict?: InputMaybe<Blocks_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Blocks_OneArgs = {
  object: Blocks_Insert_Input
  on_conflict?: InputMaybe<Blocks_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ChainsArgs = {
  objects: Array<Chains_Insert_Input>
  on_conflict?: InputMaybe<Chains_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Chains_OneArgs = {
  object: Chains_Insert_Input
  on_conflict?: InputMaybe<Chains_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_ManifestsArgs = {
  objects: Array<Manifests_Insert_Input>
  on_conflict?: InputMaybe<Manifests_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Manifests_OneArgs = {
  object: Manifests_Insert_Input
  on_conflict?: InputMaybe<Manifests_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_MappingsArgs = {
  objects: Array<Mappings_Insert_Input>
  on_conflict?: InputMaybe<Mappings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Mappings_OneArgs = {
  object: Mappings_Insert_Input
  on_conflict?: InputMaybe<Mappings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Table_RowsArgs = {
  objects: Array<Table_Rows_Insert_Input>
  on_conflict?: InputMaybe<Table_Rows_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Table_Rows_OneArgs = {
  object: Table_Rows_Insert_Input
  on_conflict?: InputMaybe<Table_Rows_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>
  on_conflict?: InputMaybe<Transactions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input
  on_conflict?: InputMaybe<Transactions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_WhitelistsArgs = {
  objects: Array<Whitelists_Insert_Input>
  on_conflict?: InputMaybe<Whitelists_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Whitelists_OneArgs = {
  object: Whitelists_Insert_Input
  on_conflict?: InputMaybe<Whitelists_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_ActionsArgs = {
  _append?: InputMaybe<Actions_Append_Input>
  _delete_at_path?: InputMaybe<Actions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Actions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Actions_Delete_Key_Input>
  _inc?: InputMaybe<Actions_Inc_Input>
  _prepend?: InputMaybe<Actions_Prepend_Input>
  _set?: InputMaybe<Actions_Set_Input>
  where: Actions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Actions_By_PkArgs = {
  _append?: InputMaybe<Actions_Append_Input>
  _delete_at_path?: InputMaybe<Actions_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Actions_Delete_Elem_Input>
  _delete_key?: InputMaybe<Actions_Delete_Key_Input>
  _inc?: InputMaybe<Actions_Inc_Input>
  _prepend?: InputMaybe<Actions_Prepend_Input>
  _set?: InputMaybe<Actions_Set_Input>
  pk_columns: Actions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Actions_ManyArgs = {
  updates: Array<Actions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Api_UsersArgs = {
  _inc?: InputMaybe<Api_Users_Inc_Input>
  _set?: InputMaybe<Api_Users_Set_Input>
  where: Api_Users_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Api_Users_By_PkArgs = {
  _inc?: InputMaybe<Api_Users_Inc_Input>
  _set?: InputMaybe<Api_Users_Set_Input>
  pk_columns: Api_Users_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Api_Users_ManyArgs = {
  updates: Array<Api_Users_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_BlocksArgs = {
  _inc?: InputMaybe<Blocks_Inc_Input>
  _set?: InputMaybe<Blocks_Set_Input>
  where: Blocks_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Blocks_By_PkArgs = {
  _inc?: InputMaybe<Blocks_Inc_Input>
  _set?: InputMaybe<Blocks_Set_Input>
  pk_columns: Blocks_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Blocks_ManyArgs = {
  updates: Array<Blocks_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ChainsArgs = {
  _set?: InputMaybe<Chains_Set_Input>
  where: Chains_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Chains_By_PkArgs = {
  _set?: InputMaybe<Chains_Set_Input>
  pk_columns: Chains_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Chains_ManyArgs = {
  updates: Array<Chains_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_ManifestsArgs = {
  _set?: InputMaybe<Manifests_Set_Input>
  where: Manifests_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Manifests_By_PkArgs = {
  _set?: InputMaybe<Manifests_Set_Input>
  pk_columns: Manifests_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Manifests_ManyArgs = {
  updates: Array<Manifests_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_MappingsArgs = {
  _append?: InputMaybe<Mappings_Append_Input>
  _delete_at_path?: InputMaybe<Mappings_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Mappings_Delete_Elem_Input>
  _delete_key?: InputMaybe<Mappings_Delete_Key_Input>
  _prepend?: InputMaybe<Mappings_Prepend_Input>
  _set?: InputMaybe<Mappings_Set_Input>
  where: Mappings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Mappings_By_PkArgs = {
  _append?: InputMaybe<Mappings_Append_Input>
  _delete_at_path?: InputMaybe<Mappings_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Mappings_Delete_Elem_Input>
  _delete_key?: InputMaybe<Mappings_Delete_Key_Input>
  _prepend?: InputMaybe<Mappings_Prepend_Input>
  _set?: InputMaybe<Mappings_Set_Input>
  pk_columns: Mappings_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Mappings_ManyArgs = {
  updates: Array<Mappings_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_Table_RowsArgs = {
  _append?: InputMaybe<Table_Rows_Append_Input>
  _delete_at_path?: InputMaybe<Table_Rows_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Table_Rows_Delete_Elem_Input>
  _delete_key?: InputMaybe<Table_Rows_Delete_Key_Input>
  _prepend?: InputMaybe<Table_Rows_Prepend_Input>
  _set?: InputMaybe<Table_Rows_Set_Input>
  where: Table_Rows_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Table_Rows_By_PkArgs = {
  _append?: InputMaybe<Table_Rows_Append_Input>
  _delete_at_path?: InputMaybe<Table_Rows_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Table_Rows_Delete_Elem_Input>
  _delete_key?: InputMaybe<Table_Rows_Delete_Key_Input>
  _prepend?: InputMaybe<Table_Rows_Prepend_Input>
  _set?: InputMaybe<Table_Rows_Set_Input>
  pk_columns: Table_Rows_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Table_Rows_ManyArgs = {
  updates: Array<Table_Rows_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _inc?: InputMaybe<Transactions_Inc_Input>
  _set?: InputMaybe<Transactions_Set_Input>
  where: Transactions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _inc?: InputMaybe<Transactions_Inc_Input>
  _set?: InputMaybe<Transactions_Set_Input>
  pk_columns: Transactions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Transactions_ManyArgs = {
  updates: Array<Transactions_Updates>
}

/** mutation root */
export type Mutation_RootUpdate_WhitelistsArgs = {
  _append?: InputMaybe<Whitelists_Append_Input>
  _delete_at_path?: InputMaybe<Whitelists_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Whitelists_Delete_Elem_Input>
  _delete_key?: InputMaybe<Whitelists_Delete_Key_Input>
  _inc?: InputMaybe<Whitelists_Inc_Input>
  _prepend?: InputMaybe<Whitelists_Prepend_Input>
  _set?: InputMaybe<Whitelists_Set_Input>
  where: Whitelists_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Whitelists_By_PkArgs = {
  _append?: InputMaybe<Whitelists_Append_Input>
  _delete_at_path?: InputMaybe<Whitelists_Delete_At_Path_Input>
  _delete_elem?: InputMaybe<Whitelists_Delete_Elem_Input>
  _delete_key?: InputMaybe<Whitelists_Delete_Key_Input>
  _inc?: InputMaybe<Whitelists_Inc_Input>
  _prepend?: InputMaybe<Whitelists_Prepend_Input>
  _set?: InputMaybe<Whitelists_Set_Input>
  pk_columns: Whitelists_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Whitelists_ManyArgs = {
  updates: Array<Whitelists_Updates>
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export type Query_Root = {
  /** fetch data from the table: "actions" */
  actions: Array<Actions>
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>
  /** fetch data from the table: "api_users" */
  api_users: Array<Api_Users>
  /** fetch aggregated fields from the table: "api_users" */
  api_users_aggregate: Api_Users_Aggregate
  /** fetch data from the table: "api_users" using primary key columns */
  api_users_by_pk?: Maybe<Api_Users>
  /** An array relationship */
  blocks: Array<Blocks>
  /** An aggregate relationship */
  blocks_aggregate: Blocks_Aggregate
  /** fetch data from the table: "blocks" using primary key columns */
  blocks_by_pk?: Maybe<Blocks>
  /** fetch data from the table: "chains" */
  chains: Array<Chains>
  /** fetch aggregated fields from the table: "chains" */
  chains_aggregate: Chains_Aggregate
  /** fetch data from the table: "chains" using primary key columns */
  chains_by_pk?: Maybe<Chains>
  /** fetch data from the table: "manifests" */
  manifests: Array<Manifests>
  /** fetch aggregated fields from the table: "manifests" */
  manifests_aggregate: Manifests_Aggregate
  /** fetch data from the table: "manifests" using primary key columns */
  manifests_by_pk?: Maybe<Manifests>
  /** fetch data from the table: "mappings" */
  mappings: Array<Mappings>
  /** fetch aggregated fields from the table: "mappings" */
  mappings_aggregate: Mappings_Aggregate
  /** fetch data from the table: "mappings" using primary key columns */
  mappings_by_pk?: Maybe<Mappings>
  /** fetch data from the table: "table_rows" */
  table_rows: Array<Table_Rows>
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: Table_Rows_Aggregate
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk?: Maybe<Table_Rows>
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>
  /** fetch data from the table: "whitelists" */
  whitelists: Array<Whitelists>
  /** fetch aggregated fields from the table: "whitelists" */
  whitelists_aggregate: Whitelists_Aggregate
  /** fetch data from the table: "whitelists" using primary key columns */
  whitelists_by_pk?: Maybe<Whitelists>
}

export type Query_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Query_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Query_RootActions_By_PkArgs = {
  chain: Scalars['String']
  global_sequence: Scalars['String']
}

export type Query_RootApi_UsersArgs = {
  distinct_on?: InputMaybe<Array<Api_Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Api_Users_Order_By>>
  where?: InputMaybe<Api_Users_Bool_Exp>
}

export type Query_RootApi_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Api_Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Api_Users_Order_By>>
  where?: InputMaybe<Api_Users_Bool_Exp>
}

export type Query_RootApi_Users_By_PkArgs = {
  id: Scalars['Int']
}

export type Query_RootBlocksArgs = {
  distinct_on?: InputMaybe<Array<Blocks_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Blocks_Order_By>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

export type Query_RootBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blocks_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Blocks_Order_By>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

export type Query_RootBlocks_By_PkArgs = {
  block_num: Scalars['Int']
  chain: Scalars['String']
}

export type Query_RootChainsArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Chains_Order_By>>
  where?: InputMaybe<Chains_Bool_Exp>
}

export type Query_RootChains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Chains_Order_By>>
  where?: InputMaybe<Chains_Bool_Exp>
}

export type Query_RootChains_By_PkArgs = {
  chain_name: Scalars['String']
}

export type Query_RootManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Manifests_Order_By>>
  where?: InputMaybe<Manifests_Bool_Exp>
}

export type Query_RootManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Manifests_Order_By>>
  where?: InputMaybe<Manifests_Bool_Exp>
}

export type Query_RootManifests_By_PkArgs = {
  app_id: Scalars['uuid']
}

export type Query_RootMappingsArgs = {
  distinct_on?: InputMaybe<Array<Mappings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Mappings_Order_By>>
  where?: InputMaybe<Mappings_Bool_Exp>
}

export type Query_RootMappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mappings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Mappings_Order_By>>
  where?: InputMaybe<Mappings_Bool_Exp>
}

export type Query_RootMappings_By_PkArgs = {
  chain: Scalars['String']
  contract: Scalars['String']
}

export type Query_RootTable_RowsArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Query_RootTable_Rows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Query_RootTable_Rows_By_PkArgs = {
  chain: Scalars['String']
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

export type Query_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Transactions_Order_By>>
  where?: InputMaybe<Transactions_Bool_Exp>
}

export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Transactions_Order_By>>
  where?: InputMaybe<Transactions_Bool_Exp>
}

export type Query_RootTransactions_By_PkArgs = {
  chain: Scalars['String']
  transaction_id: Scalars['String']
}

export type Query_RootWhitelistsArgs = {
  distinct_on?: InputMaybe<Array<Whitelists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Whitelists_Order_By>>
  where?: InputMaybe<Whitelists_Bool_Exp>
}

export type Query_RootWhitelists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Whitelists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Whitelists_Order_By>>
  where?: InputMaybe<Whitelists_Bool_Exp>
}

export type Query_RootWhitelists_By_PkArgs = {
  app_id: Scalars['uuid']
  chain: Scalars['String']
  contract: Scalars['String']
}

export type Subscription_Root = {
  /** fetch data from the table: "actions" */
  actions: Array<Actions>
  /** fetch aggregated fields from the table: "actions" */
  actions_aggregate: Actions_Aggregate
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>
  /** fetch data from the table in a streaming manner: "actions" */
  actions_stream: Array<Actions>
  /** fetch data from the table: "api_users" */
  api_users: Array<Api_Users>
  /** fetch aggregated fields from the table: "api_users" */
  api_users_aggregate: Api_Users_Aggregate
  /** fetch data from the table: "api_users" using primary key columns */
  api_users_by_pk?: Maybe<Api_Users>
  /** fetch data from the table in a streaming manner: "api_users" */
  api_users_stream: Array<Api_Users>
  /** An array relationship */
  blocks: Array<Blocks>
  /** An aggregate relationship */
  blocks_aggregate: Blocks_Aggregate
  /** fetch data from the table: "blocks" using primary key columns */
  blocks_by_pk?: Maybe<Blocks>
  /** fetch data from the table in a streaming manner: "blocks" */
  blocks_stream: Array<Blocks>
  /** fetch data from the table: "chains" */
  chains: Array<Chains>
  /** fetch aggregated fields from the table: "chains" */
  chains_aggregate: Chains_Aggregate
  /** fetch data from the table: "chains" using primary key columns */
  chains_by_pk?: Maybe<Chains>
  /** fetch data from the table in a streaming manner: "chains" */
  chains_stream: Array<Chains>
  /** fetch data from the table: "manifests" */
  manifests: Array<Manifests>
  /** fetch aggregated fields from the table: "manifests" */
  manifests_aggregate: Manifests_Aggregate
  /** fetch data from the table: "manifests" using primary key columns */
  manifests_by_pk?: Maybe<Manifests>
  /** fetch data from the table in a streaming manner: "manifests" */
  manifests_stream: Array<Manifests>
  /** fetch data from the table: "mappings" */
  mappings: Array<Mappings>
  /** fetch aggregated fields from the table: "mappings" */
  mappings_aggregate: Mappings_Aggregate
  /** fetch data from the table: "mappings" using primary key columns */
  mappings_by_pk?: Maybe<Mappings>
  /** fetch data from the table in a streaming manner: "mappings" */
  mappings_stream: Array<Mappings>
  /** fetch data from the table: "table_rows" */
  table_rows: Array<Table_Rows>
  /** fetch aggregated fields from the table: "table_rows" */
  table_rows_aggregate: Table_Rows_Aggregate
  /** fetch data from the table: "table_rows" using primary key columns */
  table_rows_by_pk?: Maybe<Table_Rows>
  /** fetch data from the table in a streaming manner: "table_rows" */
  table_rows_stream: Array<Table_Rows>
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>
  /** fetch data from the table in a streaming manner: "transactions" */
  transactions_stream: Array<Transactions>
  /** fetch data from the table: "whitelists" */
  whitelists: Array<Whitelists>
  /** fetch aggregated fields from the table: "whitelists" */
  whitelists_aggregate: Whitelists_Aggregate
  /** fetch data from the table: "whitelists" using primary key columns */
  whitelists_by_pk?: Maybe<Whitelists>
  /** fetch data from the table in a streaming manner: "whitelists" */
  whitelists_stream: Array<Whitelists>
}

export type Subscription_RootActionsArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Subscription_RootActions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Actions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Actions_Order_By>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Subscription_RootActions_By_PkArgs = {
  chain: Scalars['String']
  global_sequence: Scalars['String']
}

export type Subscription_RootActions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Actions_Stream_Cursor_Input>>
  where?: InputMaybe<Actions_Bool_Exp>
}

export type Subscription_RootApi_UsersArgs = {
  distinct_on?: InputMaybe<Array<Api_Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Api_Users_Order_By>>
  where?: InputMaybe<Api_Users_Bool_Exp>
}

export type Subscription_RootApi_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Api_Users_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Api_Users_Order_By>>
  where?: InputMaybe<Api_Users_Bool_Exp>
}

export type Subscription_RootApi_Users_By_PkArgs = {
  id: Scalars['Int']
}

export type Subscription_RootApi_Users_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Api_Users_Stream_Cursor_Input>>
  where?: InputMaybe<Api_Users_Bool_Exp>
}

export type Subscription_RootBlocksArgs = {
  distinct_on?: InputMaybe<Array<Blocks_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Blocks_Order_By>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

export type Subscription_RootBlocks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blocks_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Blocks_Order_By>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

export type Subscription_RootBlocks_By_PkArgs = {
  block_num: Scalars['Int']
  chain: Scalars['String']
}

export type Subscription_RootBlocks_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Blocks_Stream_Cursor_Input>>
  where?: InputMaybe<Blocks_Bool_Exp>
}

export type Subscription_RootChainsArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Chains_Order_By>>
  where?: InputMaybe<Chains_Bool_Exp>
}

export type Subscription_RootChains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Chains_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Chains_Order_By>>
  where?: InputMaybe<Chains_Bool_Exp>
}

export type Subscription_RootChains_By_PkArgs = {
  chain_name: Scalars['String']
}

export type Subscription_RootChains_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Chains_Stream_Cursor_Input>>
  where?: InputMaybe<Chains_Bool_Exp>
}

export type Subscription_RootManifestsArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Manifests_Order_By>>
  where?: InputMaybe<Manifests_Bool_Exp>
}

export type Subscription_RootManifests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Manifests_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Manifests_Order_By>>
  where?: InputMaybe<Manifests_Bool_Exp>
}

export type Subscription_RootManifests_By_PkArgs = {
  app_id: Scalars['uuid']
}

export type Subscription_RootManifests_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Manifests_Stream_Cursor_Input>>
  where?: InputMaybe<Manifests_Bool_Exp>
}

export type Subscription_RootMappingsArgs = {
  distinct_on?: InputMaybe<Array<Mappings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Mappings_Order_By>>
  where?: InputMaybe<Mappings_Bool_Exp>
}

export type Subscription_RootMappings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mappings_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Mappings_Order_By>>
  where?: InputMaybe<Mappings_Bool_Exp>
}

export type Subscription_RootMappings_By_PkArgs = {
  chain: Scalars['String']
  contract: Scalars['String']
}

export type Subscription_RootMappings_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Mappings_Stream_Cursor_Input>>
  where?: InputMaybe<Mappings_Bool_Exp>
}

export type Subscription_RootTable_RowsArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Subscription_RootTable_Rows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Table_Rows_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Table_Rows_Order_By>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Subscription_RootTable_Rows_By_PkArgs = {
  chain: Scalars['String']
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

export type Subscription_RootTable_Rows_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Table_Rows_Stream_Cursor_Input>>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

export type Subscription_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Transactions_Order_By>>
  where?: InputMaybe<Transactions_Bool_Exp>
}

export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Transactions_Order_By>>
  where?: InputMaybe<Transactions_Bool_Exp>
}

export type Subscription_RootTransactions_By_PkArgs = {
  chain: Scalars['String']
  transaction_id: Scalars['String']
}

export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Transactions_Stream_Cursor_Input>>
  where?: InputMaybe<Transactions_Bool_Exp>
}

export type Subscription_RootWhitelistsArgs = {
  distinct_on?: InputMaybe<Array<Whitelists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Whitelists_Order_By>>
  where?: InputMaybe<Whitelists_Bool_Exp>
}

export type Subscription_RootWhitelists_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Whitelists_Select_Column>>
  limit?: InputMaybe<Scalars['Int']>
  offset?: InputMaybe<Scalars['Int']>
  order_by?: InputMaybe<Array<Whitelists_Order_By>>
  where?: InputMaybe<Whitelists_Bool_Exp>
}

export type Subscription_RootWhitelists_By_PkArgs = {
  app_id: Scalars['uuid']
  chain: Scalars['String']
  contract: Scalars['String']
}

export type Subscription_RootWhitelists_StreamArgs = {
  batch_size: Scalars['Int']
  cursor: Array<InputMaybe<Whitelists_Stream_Cursor_Input>>
  where?: InputMaybe<Whitelists_Bool_Exp>
}

/** columns and relationships of "table_rows" */
export type Table_Rows = {
  chain: Scalars['String']
  contract: Scalars['String']
  data: Scalars['jsonb']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** columns and relationships of "table_rows" */
export type Table_RowsDataArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "table_rows" */
export type Table_Rows_Aggregate = {
  aggregate?: Maybe<Table_Rows_Aggregate_Fields>
  nodes: Array<Table_Rows>
}

export type Table_Rows_Aggregate_Bool_Exp = {
  count?: InputMaybe<Table_Rows_Aggregate_Bool_Exp_Count>
}

export type Table_Rows_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Table_Rows_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Table_Rows_Bool_Exp>
  predicate: Int_Comparison_Exp
}

/** aggregate fields of "table_rows" */
export type Table_Rows_Aggregate_Fields = {
  count: Scalars['Int']
  max?: Maybe<Table_Rows_Max_Fields>
  min?: Maybe<Table_Rows_Min_Fields>
}

/** aggregate fields of "table_rows" */
export type Table_Rows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Table_Rows_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** order by aggregate values of table "table_rows" */
export type Table_Rows_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>
  max?: InputMaybe<Table_Rows_Max_Order_By>
  min?: InputMaybe<Table_Rows_Min_Order_By>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Table_Rows_Append_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** input type for inserting array relation for remote table "table_rows" */
export type Table_Rows_Arr_Rel_Insert_Input = {
  data: Array<Table_Rows_Insert_Input>
  /** upsert condition */
  on_conflict?: InputMaybe<Table_Rows_On_Conflict>
}

/** Boolean expression to filter rows from the table "table_rows". All fields are combined with a logical 'AND'. */
export type Table_Rows_Bool_Exp = {
  _and?: InputMaybe<Array<Table_Rows_Bool_Exp>>
  _not?: InputMaybe<Table_Rows_Bool_Exp>
  _or?: InputMaybe<Array<Table_Rows_Bool_Exp>>
  chain?: InputMaybe<String_Comparison_Exp>
  contract?: InputMaybe<String_Comparison_Exp>
  data?: InputMaybe<Jsonb_Comparison_Exp>
  primary_key?: InputMaybe<String_Comparison_Exp>
  scope?: InputMaybe<String_Comparison_Exp>
  table?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "table_rows" */
export enum Table_Rows_Constraint {
  /** unique or primary key constraint on columns "chain", "contract", "primary_key", "scope", "table" */
  TablesPkey = 'tables_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Table_Rows_Delete_At_Path_Input = {
  data?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Table_Rows_Delete_Elem_Input = {
  data?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Table_Rows_Delete_Key_Input = {
  data?: InputMaybe<Scalars['String']>
}

/** input type for inserting data into table "table_rows" */
export type Table_Rows_Insert_Input = {
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  primary_key?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Table_Rows_Max_Fields = {
  chain?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  primary_key?: Maybe<Scalars['String']>
  scope?: Maybe<Scalars['String']>
  table?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "table_rows" */
export type Table_Rows_Max_Order_By = {
  chain?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  primary_key?: InputMaybe<Order_By>
  scope?: InputMaybe<Order_By>
  table?: InputMaybe<Order_By>
}

/** aggregate min on columns */
export type Table_Rows_Min_Fields = {
  chain?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  primary_key?: Maybe<Scalars['String']>
  scope?: Maybe<Scalars['String']>
  table?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "table_rows" */
export type Table_Rows_Min_Order_By = {
  chain?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  primary_key?: InputMaybe<Order_By>
  scope?: InputMaybe<Order_By>
  table?: InputMaybe<Order_By>
}

/** response of any mutation on the table "table_rows" */
export type Table_Rows_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Table_Rows>
}

/** on_conflict condition type for table "table_rows" */
export type Table_Rows_On_Conflict = {
  constraint: Table_Rows_Constraint
  update_columns?: Array<Table_Rows_Update_Column>
  where?: InputMaybe<Table_Rows_Bool_Exp>
}

/** Ordering options when selecting data from "table_rows". */
export type Table_Rows_Order_By = {
  chain?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  data?: InputMaybe<Order_By>
  primary_key?: InputMaybe<Order_By>
  scope?: InputMaybe<Order_By>
  table?: InputMaybe<Order_By>
}

/** primary key columns input for table: table_rows */
export type Table_Rows_Pk_Columns_Input = {
  chain: Scalars['String']
  contract: Scalars['String']
  primary_key: Scalars['String']
  scope: Scalars['String']
  table: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Table_Rows_Prepend_Input = {
  data?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "table_rows" */
export enum Table_Rows_Select_Column {
  /** column name */
  Chain = 'chain',
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  PrimaryKey = 'primary_key',
  /** column name */
  Scope = 'scope',
  /** column name */
  Table = 'table',
}

/** input type for updating data in table "table_rows" */
export type Table_Rows_Set_Input = {
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  primary_key?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
}

/** Streaming cursor of the table "table_rows" */
export type Table_Rows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Table_Rows_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Table_Rows_Stream_Cursor_Value_Input = {
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  data?: InputMaybe<Scalars['jsonb']>
  primary_key?: InputMaybe<Scalars['String']>
  scope?: InputMaybe<Scalars['String']>
  table?: InputMaybe<Scalars['String']>
}

/** update columns of table "table_rows" */
export enum Table_Rows_Update_Column {
  /** column name */
  Chain = 'chain',
  /** column name */
  Contract = 'contract',
  /** column name */
  Data = 'data',
  /** column name */
  PrimaryKey = 'primary_key',
  /** column name */
  Scope = 'scope',
  /** column name */
  Table = 'table',
}

export type Table_Rows_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Table_Rows_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Table_Rows_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Table_Rows_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Table_Rows_Delete_Key_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Table_Rows_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Table_Rows_Set_Input>
  where: Table_Rows_Bool_Exp
}

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>
  _gt?: InputMaybe<Scalars['timestamptz']>
  _gte?: InputMaybe<Scalars['timestamptz']>
  _in?: InputMaybe<Array<Scalars['timestamptz']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['timestamptz']>
  _lte?: InputMaybe<Scalars['timestamptz']>
  _neq?: InputMaybe<Scalars['timestamptz']>
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>
}

/** columns and relationships of "transactions" */
export type Transactions = {
  block_num: Scalars['Int']
  chain: Scalars['String']
  cpu_usage_us?: Maybe<Scalars['Int']>
  net_usage?: Maybe<Scalars['Int']>
  net_usage_words?: Maybe<Scalars['Int']>
  transaction_id: Scalars['String']
}

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  aggregate?: Maybe<Transactions_Aggregate_Fields>
  nodes: Array<Transactions>
}

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  avg?: Maybe<Transactions_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Transactions_Max_Fields>
  min?: Maybe<Transactions_Min_Fields>
  stddev?: Maybe<Transactions_Stddev_Fields>
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>
  sum?: Maybe<Transactions_Sum_Fields>
  var_pop?: Maybe<Transactions_Var_Pop_Fields>
  var_samp?: Maybe<Transactions_Var_Samp_Fields>
  variance?: Maybe<Transactions_Variance_Fields>
}

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transactions_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Transactions_Bool_Exp>>
  _not?: InputMaybe<Transactions_Bool_Exp>
  _or?: InputMaybe<Array<Transactions_Bool_Exp>>
  block_num?: InputMaybe<Int_Comparison_Exp>
  chain?: InputMaybe<String_Comparison_Exp>
  cpu_usage_us?: InputMaybe<Int_Comparison_Exp>
  net_usage?: InputMaybe<Int_Comparison_Exp>
  net_usage_words?: InputMaybe<Int_Comparison_Exp>
  transaction_id?: InputMaybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "chain", "transaction_id" */
  TransactionsPkey = 'transactions_pkey',
}

/** input type for incrementing numeric columns in table "transactions" */
export type Transactions_Inc_Input = {
  block_num?: InputMaybe<Scalars['Int']>
  cpu_usage_us?: InputMaybe<Scalars['Int']>
  net_usage?: InputMaybe<Scalars['Int']>
  net_usage_words?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  block_num?: InputMaybe<Scalars['Int']>
  chain?: InputMaybe<Scalars['String']>
  cpu_usage_us?: InputMaybe<Scalars['Int']>
  net_usage?: InputMaybe<Scalars['Int']>
  net_usage_words?: InputMaybe<Scalars['Int']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  block_num?: Maybe<Scalars['Int']>
  chain?: Maybe<Scalars['String']>
  cpu_usage_us?: Maybe<Scalars['Int']>
  net_usage?: Maybe<Scalars['Int']>
  net_usage_words?: Maybe<Scalars['Int']>
  transaction_id?: Maybe<Scalars['String']>
}

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  block_num?: Maybe<Scalars['Int']>
  chain?: Maybe<Scalars['String']>
  cpu_usage_us?: Maybe<Scalars['Int']>
  net_usage?: Maybe<Scalars['Int']>
  net_usage_words?: Maybe<Scalars['Int']>
  transaction_id?: Maybe<Scalars['String']>
}

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>
}

/** input type for inserting object relation for remote table "transactions" */
export type Transactions_Obj_Rel_Insert_Input = {
  data: Transactions_Insert_Input
  /** upsert condition */
  on_conflict?: InputMaybe<Transactions_On_Conflict>
}

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint
  update_columns?: Array<Transactions_Update_Column>
  where?: InputMaybe<Transactions_Bool_Exp>
}

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  block_num?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  cpu_usage_us?: InputMaybe<Order_By>
  net_usage?: InputMaybe<Order_By>
  net_usage_words?: InputMaybe<Order_By>
  transaction_id?: InputMaybe<Order_By>
}

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  chain: Scalars['String']
  transaction_id: Scalars['String']
}

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  BlockNum = 'block_num',
  /** column name */
  Chain = 'chain',
  /** column name */
  CpuUsageUs = 'cpu_usage_us',
  /** column name */
  NetUsage = 'net_usage',
  /** column name */
  NetUsageWords = 'net_usage_words',
  /** column name */
  TransactionId = 'transaction_id',
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  block_num?: InputMaybe<Scalars['Int']>
  chain?: InputMaybe<Scalars['String']>
  cpu_usage_us?: InputMaybe<Scalars['Int']>
  net_usage?: InputMaybe<Scalars['Int']>
  net_usage_words?: InputMaybe<Scalars['Int']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transactions_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  block_num?: InputMaybe<Scalars['Int']>
  chain?: InputMaybe<Scalars['String']>
  cpu_usage_us?: InputMaybe<Scalars['Int']>
  net_usage?: InputMaybe<Scalars['Int']>
  net_usage_words?: InputMaybe<Scalars['Int']>
  transaction_id?: InputMaybe<Scalars['String']>
}

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  block_num?: Maybe<Scalars['Int']>
  cpu_usage_us?: Maybe<Scalars['Int']>
  net_usage?: Maybe<Scalars['Int']>
  net_usage_words?: Maybe<Scalars['Int']>
}

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  BlockNum = 'block_num',
  /** column name */
  Chain = 'chain',
  /** column name */
  CpuUsageUs = 'cpu_usage_us',
  /** column name */
  NetUsage = 'net_usage',
  /** column name */
  NetUsageWords = 'net_usage_words',
  /** column name */
  TransactionId = 'transaction_id',
}

export type Transactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transactions_Inc_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transactions_Set_Input>
  where: Transactions_Bool_Exp
}

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  block_num?: Maybe<Scalars['Float']>
  cpu_usage_us?: Maybe<Scalars['Float']>
  net_usage?: Maybe<Scalars['Float']>
  net_usage_words?: Maybe<Scalars['Float']>
}

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>
  _gt?: InputMaybe<Scalars['uuid']>
  _gte?: InputMaybe<Scalars['uuid']>
  _in?: InputMaybe<Array<Scalars['uuid']>>
  _is_null?: InputMaybe<Scalars['Boolean']>
  _lt?: InputMaybe<Scalars['uuid']>
  _lte?: InputMaybe<Scalars['uuid']>
  _neq?: InputMaybe<Scalars['uuid']>
  _nin?: InputMaybe<Array<Scalars['uuid']>>
}

/** columns and relationships of "whitelists" */
export type Whitelists = {
  actions: Scalars['jsonb']
  app_id: Scalars['uuid']
  chain: Scalars['String']
  contract: Scalars['String']
  history_ready: Scalars['Boolean']
  /** An object relationship */
  manifest: Manifests
  start_block: Scalars['Int']
  tables: Scalars['jsonb']
}

/** columns and relationships of "whitelists" */
export type WhitelistsActionsArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** columns and relationships of "whitelists" */
export type WhitelistsTablesArgs = {
  path?: InputMaybe<Scalars['String']>
}

/** aggregated selection of "whitelists" */
export type Whitelists_Aggregate = {
  aggregate?: Maybe<Whitelists_Aggregate_Fields>
  nodes: Array<Whitelists>
}

/** aggregate fields of "whitelists" */
export type Whitelists_Aggregate_Fields = {
  avg?: Maybe<Whitelists_Avg_Fields>
  count: Scalars['Int']
  max?: Maybe<Whitelists_Max_Fields>
  min?: Maybe<Whitelists_Min_Fields>
  stddev?: Maybe<Whitelists_Stddev_Fields>
  stddev_pop?: Maybe<Whitelists_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Whitelists_Stddev_Samp_Fields>
  sum?: Maybe<Whitelists_Sum_Fields>
  var_pop?: Maybe<Whitelists_Var_Pop_Fields>
  var_samp?: Maybe<Whitelists_Var_Samp_Fields>
  variance?: Maybe<Whitelists_Variance_Fields>
}

/** aggregate fields of "whitelists" */
export type Whitelists_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Whitelists_Select_Column>>
  distinct?: InputMaybe<Scalars['Boolean']>
}

/** append existing jsonb value of filtered columns with new jsonb value */
export type Whitelists_Append_Input = {
  actions?: InputMaybe<Scalars['jsonb']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** aggregate avg on columns */
export type Whitelists_Avg_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

/** Boolean expression to filter rows from the table "whitelists". All fields are combined with a logical 'AND'. */
export type Whitelists_Bool_Exp = {
  _and?: InputMaybe<Array<Whitelists_Bool_Exp>>
  _not?: InputMaybe<Whitelists_Bool_Exp>
  _or?: InputMaybe<Array<Whitelists_Bool_Exp>>
  actions?: InputMaybe<Jsonb_Comparison_Exp>
  app_id?: InputMaybe<Uuid_Comparison_Exp>
  chain?: InputMaybe<String_Comparison_Exp>
  contract?: InputMaybe<String_Comparison_Exp>
  history_ready?: InputMaybe<Boolean_Comparison_Exp>
  manifest?: InputMaybe<Manifests_Bool_Exp>
  start_block?: InputMaybe<Int_Comparison_Exp>
  tables?: InputMaybe<Jsonb_Comparison_Exp>
}

/** unique or primary key constraints on table "whitelists" */
export enum Whitelists_Constraint {
  /** unique or primary key constraint on columns "chain", "contract", "app_id" */
  WhitelistsPkey = 'whitelists_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Whitelists_Delete_At_Path_Input = {
  actions?: InputMaybe<Array<Scalars['String']>>
  tables?: InputMaybe<Array<Scalars['String']>>
}

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Whitelists_Delete_Elem_Input = {
  actions?: InputMaybe<Scalars['Int']>
  tables?: InputMaybe<Scalars['Int']>
}

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Whitelists_Delete_Key_Input = {
  actions?: InputMaybe<Scalars['String']>
  tables?: InputMaybe<Scalars['String']>
}

/** input type for incrementing numeric columns in table "whitelists" */
export type Whitelists_Inc_Input = {
  start_block?: InputMaybe<Scalars['Int']>
}

/** input type for inserting data into table "whitelists" */
export type Whitelists_Insert_Input = {
  actions?: InputMaybe<Scalars['jsonb']>
  app_id?: InputMaybe<Scalars['uuid']>
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  history_ready?: InputMaybe<Scalars['Boolean']>
  manifest?: InputMaybe<Manifests_Obj_Rel_Insert_Input>
  start_block?: InputMaybe<Scalars['Int']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** aggregate max on columns */
export type Whitelists_Max_Fields = {
  app_id?: Maybe<Scalars['uuid']>
  chain?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  start_block?: Maybe<Scalars['Int']>
}

/** aggregate min on columns */
export type Whitelists_Min_Fields = {
  app_id?: Maybe<Scalars['uuid']>
  chain?: Maybe<Scalars['String']>
  contract?: Maybe<Scalars['String']>
  start_block?: Maybe<Scalars['Int']>
}

/** response of any mutation on the table "whitelists" */
export type Whitelists_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']
  /** data from the rows affected by the mutation */
  returning: Array<Whitelists>
}

/** on_conflict condition type for table "whitelists" */
export type Whitelists_On_Conflict = {
  constraint: Whitelists_Constraint
  update_columns?: Array<Whitelists_Update_Column>
  where?: InputMaybe<Whitelists_Bool_Exp>
}

/** Ordering options when selecting data from "whitelists". */
export type Whitelists_Order_By = {
  actions?: InputMaybe<Order_By>
  app_id?: InputMaybe<Order_By>
  chain?: InputMaybe<Order_By>
  contract?: InputMaybe<Order_By>
  history_ready?: InputMaybe<Order_By>
  manifest?: InputMaybe<Manifests_Order_By>
  start_block?: InputMaybe<Order_By>
  tables?: InputMaybe<Order_By>
}

/** primary key columns input for table: whitelists */
export type Whitelists_Pk_Columns_Input = {
  app_id: Scalars['uuid']
  chain: Scalars['String']
  contract: Scalars['String']
}

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Whitelists_Prepend_Input = {
  actions?: InputMaybe<Scalars['jsonb']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** select columns of table "whitelists" */
export enum Whitelists_Select_Column {
  /** column name */
  Actions = 'actions',
  /** column name */
  AppId = 'app_id',
  /** column name */
  Chain = 'chain',
  /** column name */
  Contract = 'contract',
  /** column name */
  HistoryReady = 'history_ready',
  /** column name */
  StartBlock = 'start_block',
  /** column name */
  Tables = 'tables',
}

/** input type for updating data in table "whitelists" */
export type Whitelists_Set_Input = {
  actions?: InputMaybe<Scalars['jsonb']>
  app_id?: InputMaybe<Scalars['uuid']>
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  history_ready?: InputMaybe<Scalars['Boolean']>
  start_block?: InputMaybe<Scalars['Int']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** aggregate stddev on columns */
export type Whitelists_Stddev_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

/** aggregate stddev_pop on columns */
export type Whitelists_Stddev_Pop_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

/** aggregate stddev_samp on columns */
export type Whitelists_Stddev_Samp_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

/** Streaming cursor of the table "whitelists" */
export type Whitelists_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Whitelists_Stream_Cursor_Value_Input
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>
}

/** Initial value of the column from where the streaming should start */
export type Whitelists_Stream_Cursor_Value_Input = {
  actions?: InputMaybe<Scalars['jsonb']>
  app_id?: InputMaybe<Scalars['uuid']>
  chain?: InputMaybe<Scalars['String']>
  contract?: InputMaybe<Scalars['String']>
  history_ready?: InputMaybe<Scalars['Boolean']>
  start_block?: InputMaybe<Scalars['Int']>
  tables?: InputMaybe<Scalars['jsonb']>
}

/** aggregate sum on columns */
export type Whitelists_Sum_Fields = {
  start_block?: Maybe<Scalars['Int']>
}

/** update columns of table "whitelists" */
export enum Whitelists_Update_Column {
  /** column name */
  Actions = 'actions',
  /** column name */
  AppId = 'app_id',
  /** column name */
  Chain = 'chain',
  /** column name */
  Contract = 'contract',
  /** column name */
  HistoryReady = 'history_ready',
  /** column name */
  StartBlock = 'start_block',
  /** column name */
  Tables = 'tables',
}

export type Whitelists_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Whitelists_Append_Input>
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Whitelists_Delete_At_Path_Input>
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Whitelists_Delete_Elem_Input>
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Whitelists_Delete_Key_Input>
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Whitelists_Inc_Input>
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Whitelists_Prepend_Input>
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Whitelists_Set_Input>
  where: Whitelists_Bool_Exp
}

/** aggregate var_pop on columns */
export type Whitelists_Var_Pop_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

/** aggregate var_samp on columns */
export type Whitelists_Var_Samp_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

/** aggregate variance on columns */
export type Whitelists_Variance_Fields = {
  start_block?: Maybe<Scalars['Float']>
}

export type GetChainGraphTableRowsQueryVariables = Exact<{
  where?: InputMaybe<Table_Rows_Bool_Exp>
  order_by?: InputMaybe<Array<Table_Rows_Order_By> | Table_Rows_Order_By>
  offset?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetChainGraphTableRowsQuery = {
  table_rows: Array<{
    chain: string
    contract: string
    scope: string
    table: string
    primary_key: string
    data: any
  }>
}

export type ChainGraphTableRowsSubscriptionVariables = Exact<{
  where?: InputMaybe<Table_Rows_Bool_Exp>
  order_by?: InputMaybe<Array<Table_Rows_Order_By> | Table_Rows_Order_By>
  offset?: InputMaybe<Scalars['Int']>
  limit?: InputMaybe<Scalars['Int']>
}>

export type ChainGraphTableRowsSubscription = {
  table_rows: Array<{
    chain: string
    contract: string
    scope: string
    table: string
    primary_key: string
    data: any
  }>
}

export const GetChainGraphTableRowsDocument = gql`
  query GetChainGraphTableRows(
    $where: table_rows_bool_exp = {}
    $order_by: [table_rows_order_by!] = {}
    $offset: Int
    $limit: Int = 100
  ) {
    table_rows(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      chain
      contract
      scope
      table
      primary_key
      data
    }
  }
`

/**
 * __useGetChainGraphTableRowsQuery__
 *
 * To run a query within a React component, call `useGetChainGraphTableRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChainGraphTableRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChainGraphTableRowsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetChainGraphTableRowsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetChainGraphTableRowsQuery,
    GetChainGraphTableRowsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetChainGraphTableRowsQuery, GetChainGraphTableRowsQueryVariables>(
    GetChainGraphTableRowsDocument,
    options,
  )
}
export function useGetChainGraphTableRowsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetChainGraphTableRowsQuery,
    GetChainGraphTableRowsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetChainGraphTableRowsQuery, GetChainGraphTableRowsQueryVariables>(
    GetChainGraphTableRowsDocument,
    options,
  )
}
export type GetChainGraphTableRowsQueryHookResult = ReturnType<
  typeof useGetChainGraphTableRowsQuery
>
export type GetChainGraphTableRowsLazyQueryHookResult = ReturnType<
  typeof useGetChainGraphTableRowsLazyQuery
>
export type GetChainGraphTableRowsQueryResult = Apollo.QueryResult<
  GetChainGraphTableRowsQuery,
  GetChainGraphTableRowsQueryVariables
>
export const ChainGraphTableRowsDocument = gql`
  subscription ChainGraphTableRows(
    $where: table_rows_bool_exp = {}
    $order_by: [table_rows_order_by!] = {}
    $offset: Int
    $limit: Int = 100
  ) {
    table_rows(where: $where, order_by: $order_by, offset: $offset, limit: $limit) {
      chain
      contract
      scope
      table
      primary_key
      data
    }
  }
`

/**
 * __useChainGraphTableRowsSubscription__
 *
 * To run a query within a React component, call `useChainGraphTableRowsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChainGraphTableRowsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChainGraphTableRowsSubscription({
 *   variables: {
 *      where: // value for 'where'
 *      order_by: // value for 'order_by'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useChainGraphTableRowsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ChainGraphTableRowsSubscription,
    ChainGraphTableRowsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    ChainGraphTableRowsSubscription,
    ChainGraphTableRowsSubscriptionVariables
  >(ChainGraphTableRowsDocument, options)
}
export type ChainGraphTableRowsSubscriptionHookResult = ReturnType<
  typeof useChainGraphTableRowsSubscription
>
export type ChainGraphTableRowsSubscriptionResult =
  Apollo.SubscriptionResult<ChainGraphTableRowsSubscription>
