import { useCallback, useEffect, useState } from 'react'
import { useBitcashSWR } from 'hooks/use-bitcash-swr.hook'
import { systemNotificationService } from 'services/system-notifications'

export const useSystemNotifications = (customRefreshInterval = 60000) => {
  const [systemNotifications, setSystemNotifications] = useState([])
  const [statuses, setStatuses] = useState({ read: [], unread: [] })
  const { data, error, isLoading, isValidating, mutate } = useBitcashSWR(
    systemNotificationService.getSystemNotification(),
    {
      refreshInterval: customRefreshInterval,
    },
  )

  useEffect(() => {
    if (data && data.system_notifications) {
      setSystemNotifications(data.system_notifications)
      const read = data.system_notifications.filter(
        (n) => n.system_notification_read_statuses.length,
      )
      const unread = data.system_notifications.filter(
        (n) => !n.system_notification_read_statuses.length,
      )
      setStatuses({ read, unread })
    }
  }, [data, error])

  const setReadStatus = useCallback(
    async (notificationId: string, regAccount: string) => {
      try {
        await systemNotificationService.updateSystemNotificationReadStatus({
          notification_id: notificationId,
          reg_account: regAccount,
        })
        mutate()
      } catch (error) {
        console.error('Error updating read status of the system notification:', error)
      }
    },
    [mutate],
  )

  const refetch = useCallback(() => {
    mutate()
  }, [mutate])

  return {
    systemNotifications,
    statuses,
    isLoading,
    error,
    isValidating,
    setReadStatus,
    refetch,
  }
}
