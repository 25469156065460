import React from 'react'
import { NumericFormat, NumericFormatProps, NumberFormatValues } from 'react-number-format'
import styled from 'app-view/styles/styled'
import { useStore } from 'app-engine/store'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'

const StyledInput = styled(NumericFormat)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none !important;
  font-weight: 700;
  font-size: 23px;
  line-height: 29px;
  letter-spacing: -0.235446px;
  color: #1b1b1b;
`
export type { NumberFormatValues } from 'react-number-format'
export interface StableTokenQuantityMaskedInputProps extends NumericFormatProps {
  token: string // token is necessary to know precision.
  onValueChange?: (props: NumberFormatValues) => void
}

export const StableTokenQuantityMaskedInput: React.FC<StableTokenQuantityMaskedInputProps> = ({
  token,
  ...props
}) => {
  const precision = findTokenBySymbolCode(token)?.token_symbol?.precision()
  const error = `Error reading token precision ${token}`
  if (precision === undefined) throw new Error(error)
  return (
    <StyledInput thousandSeparator decimalScale={precision} {...(props as React.ComponentType)} />
  )
}
