import React from 'react'
import { BigButton2, ButtonsGrid } from 'app-view/components/buttons'
import { useTranslation } from 'react-i18next'
import { useWizard } from 'app-view/components/Wizard'
import { BUY_STEPS as BUY_STEPS_APP, BUY_STEPS_GRIN } from '../steps/buy-steps'
import { SELL_STEPS as SEll_STEPS_APP, GRIN_SELL_STEPS } from '../steps/sell-steps'
import { useStore } from 'app-engine/store/index'
import { useRealTimeCoinPrices } from 'app-view/hooks/use-realtime/use-realtime-coin-prices'

interface SwapHeaderButtonsProps {
  amount?: number
}

export const SwapHeaderButtons: React.FC<SwapHeaderButtonsProps> = () => {
  const { t } = useTranslation(['global'])
  const [, { start }] = useWizard()
  const {
    startNewSwapOrder,
    resetSwapError,
    coinSwap: { swap },
  } = useStore()
  const coinPrices = useRealTimeCoinPrices()
  const isGRIN = swap.asset === 'GRIN'
  const SELL_STEPS = isGRIN ? GRIN_SELL_STEPS : SEll_STEPS_APP
  const BUY_STEPS = isGRIN ? BUY_STEPS_GRIN : BUY_STEPS_APP
  const newOrder = (type: 'sell' | 'buy') => {
    resetSwapError()
    startNewSwapOrder(type, coinPrices)
    start(type === 'sell' ? SELL_STEPS : BUY_STEPS)
  }

  return (
    <ButtonsGrid>
      <BigButton2 variant="red" onClick={() => newOrder('sell')}>
        {t('global:sell')}
      </BigButton2>
      <BigButton2 variant="green" onClick={() => newOrder('buy')}>
        {t('global:buy')}
      </BigButton2>
    </ButtonsGrid>
  )
}
