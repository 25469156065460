import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useWizard } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import {
  ModalButtonsContainer,
  ModalText,
  ModalStepGreenButton,
  ModalStepBlackButton,
} from 'app-view/components/Modal'

export const Payment: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation(['p2p'])
  const [, { next, goTo }] = useWizard()
  const [{ loading }, { confirmPayment }] = useP2POffer()
  const onPaymentConfirmation = async () => {
    const { success } = await confirmPayment()
    if (success) next()
  }

  if (loading) return <Loader text={`${t('confirming_payment')}...`} />

  return (
    <>
      <ModalText dangerouslySetInnerHTML={{ __html: `${children}` }} />
      <ModalButtonsContainer>
        <ModalStepBlackButton onClick={() => goTo('match-2')}>{t('Not_yet')}</ModalStepBlackButton>
        <ModalStepGreenButton onClick={onPaymentConfirmation}>{t('Yes')}</ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}
