import { WizardStep } from 'app-view/components/Wizard'
import { ActionSuccess } from 'pages/BankView/steps/common/ActionSuccess'
import { SendToAccount } from 'pages/BankView/steps/send-steps/SendToAccount'
// NOTE: Memo step temp rm.
// import { SendWithMemo } from 'views/BankView/steps/send-steps/SendWithMemo'

export const SEND_STEPS: WizardStep[] = [
  {
    name: 'send-to-account',
    component: SendToAccount,
  },
  // NOTE: Memo step temp rm.
  // {
  //   name: 'memo',
  //   component: SendWithMemo,
  // },
  {
    name: 'success',
    component: ActionSuccess,
  },
]
