import React from 'react'

const SimpleCoin = (props: any) => (
  <svg id="simple-coin" viewBox="0 0 42 44" {...props} fill="none">
    <g opacity="0.6666">
      <path
        d="M33.614 22.0263C33.614 33.5009 26.1012 42.6782 16.972 42.6782C7.8428 42.6782 0.33 33.5009 0.33 22.0263C0.33 10.5518 7.8428 1.37443 16.972 1.37443C26.1012 1.37443 33.614 10.5518 33.614 22.0263Z"
        stroke="#787878"
        strokeWidth="0.66"
      />
      <path
        d="M41.17 22.0259C41.17 28.0635 39.1421 33.7088 35.2632 37.5992C31.9787 40.8933 27.3451 42.9515 21.4302 42.9093C24.8083 42.025 27.7368 39.8275 29.968 36.8407C32.9047 32.9094 34.6498 27.5934 34.6498 22.0259C34.6498 11.8745 29.362 3.16704 21.6622 1.09026C27.6577 1.11447 32.2354 3.00647 35.44 6.16317C39.2532 9.91923 41.17 15.5124 41.17 22.0259Z"
        stroke="#787878"
        strokeWidth="0.66"
      />
      <path
        d="M35.9964 36.2933H30.7483L27.5 39.5L23.5 42.5H26L30 41L33.7062 39.0848L35.9964 36.2933Z"
        fill="#787878"
      />
      <line
        x1="25.1777"
        y1="2.42877"
        x2="28.3521"
        y2="2.42877"
        stroke="#787878"
        strokeWidth="0.4"
      />
      <line
        x1="34.5071"
        y1="21.8111"
        x2="40.8483"
        y2="21.8111"
        stroke="#787878"
        strokeWidth="3.6"
      />
      <line x1="33.7573" y1="28.7062" x2="40.4715" y2="28.7062" stroke="#787878" />
      <line x1="30.771" y1="36.1295" x2="36.7392" y2="36.1295" stroke="#787878" strokeWidth="0.7" />
      <line x1="32.636" y1="12.1708" x2="39.3502" y2="12.1708" stroke="#787878" strokeWidth="0.5" />
      <line
        x1="30.0288"
        y1="7.13553"
        x2="36.3288"
        y2="7.13553"
        stroke="#787878"
        strokeWidth="0.5"
      />
      <path d="M36 7C36 7 30.5 1 23.5 1.5L27.5 4L30 7H36Z" fill="#787878" />
      <ellipse
        rx="1.12131"
        ry="1.44737"
        transform="matrix(1 -0.000750928 -0.000719967 1 16.6232 36.3276)"
        fill="#787878"
      />
      <ellipse
        rx="1.12131"
        ry="1.44737"
        transform="matrix(1 -0.000750928 -0.000719967 1 17.1305 7.38396)"
        fill="#787878"
      />
      <path
        d="M11.5895 25.817L13.7391 25.8154C13.7381 27.2049 15.2097 28.3874 16.9617 28.3861C18.7136 28.3848 20.1869 27.2 20.1879 25.8106C20.189 24.3954 19.0715 23.8816 16.7074 23.2015C14.4293 22.5213 11.5925 21.6743 11.595 18.0977C11.5967 15.7948 13.1781 13.8381 15.3604 13.1803L15.3624 10.3756L18.5868 10.3732L18.5848 13.1779C20.7662 13.8324 22.3448 15.7867 22.3431 18.0897L20.1935 18.0913C20.1945 16.7018 18.7229 15.5193 16.9709 15.5206C15.219 15.5219 13.7456 16.7066 13.7446 18.0961C13.7436 19.5113 14.8611 20.0251 17.2251 20.7052C19.5033 21.3854 22.3401 22.2323 22.3376 25.8089C22.3359 28.1119 20.7545 30.0686 18.5722 30.7264L18.5702 33.5311L15.3457 33.5335L15.3478 30.7288C13.1664 30.0743 11.5878 28.1199 11.5895 25.817Z"
        fill="#787878"
      />
    </g>
  </svg>
)

export default SimpleCoin
