import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { useStore } from 'app-engine/store'
import { accounts_information } from 'graphql/bitcash'
import { useBitcashSWR } from 'hooks/use-bitcash-swr.hook'
import { createContext, useContext, useEffect } from 'react'
import { useSetState } from 'react-use'
import { accountService } from 'services/account'

interface Preferences extends Omit<Bitcash.Preferences, 'account'> {
  notifications: boolean
}

interface ProfileContextState {
  accountProfile: accounts_information | null
  isLoadingAccountInfo: boolean
  preferences: Preferences
  isLoadingPreferences: boolean
}

interface ProfileContextActions {
  setNotificationsEnable: (value: boolean) => void
  syncCurrencyPreferences: (currency: string) => void
  refreshProfile: () => void
}

const defaultPreferences = {
  language: 'en',
  region: 'global',
  currency: 'BITUSD',
  theme: 'light',
  personalized: false,
  notifications: false,
}

const initializePreferences = (account: string) => {
  apolloClient.mutate<
    Bitcash.CreatePreferencesMutation,
    Bitcash.CreatePreferencesMutationVariables
  >({
    mutation: Bitcash.CreatePreferencesDocument,
    variables: {
      object: {
        ...defaultPreferences,
        account,
      },
    },
  })
}

type ProfileContextProps = [ProfileContextState, ProfileContextActions]

const ProfileContext = createContext<ProfileContextProps | undefined>(undefined)

const fetchAccountProfileData = (account: string) => accountService.getAccountProfile({ account })
const fetchPreferencesData = (account: string) => accountService.getPreferences({ account })

export function useProfile() {
  const context = useContext(ProfileContext)
  if (!context) {
    throw new Error('useProfileContext must be used within a ProfileProvider')
  }
  return context
}

interface ProfileProviderProps {
  children: React.ReactNode
}

const initialState = {
  preferences: defaultPreferences,
}

export function ProfileProvider({ children }: ProfileProviderProps) {
  const [state, setState] = useSetState(initialState)

  const account = useStore.useAccount()
  const {
    data: accountInfoData,
    isLoading: isLoadingAccountInfo,
    mutate,
  } = useBitcashSWR(fetchAccountProfileData(account))
  const { data: preferencesData, isLoading: isLoadingPreferences } = useBitcashSWR(
    fetchPreferencesData(account),
  )
  const accountProfile = accountInfoData?.accounts_information[0] || null

  const setNotificationsEnable = (value: boolean) => {
    setState((prevStatus) => ({
      ...prevStatus,
      preferences: { ...prevStatus.preferences, notifications: value },
    }))
  }

  const syncCurrencyPreferences = async (currency: string) => {
    setState((prevStatus) => ({
      ...prevStatus,
      preferences: { ...prevStatus.preferences, currency },
    }))
  }

  const refreshProfile = () => {
    mutate()
  }

  useEffect(() => {
    if (!preferencesData?.preferences_by_pk && !isLoadingPreferences) {
      initializePreferences(account)
    } else if (!isLoadingPreferences && preferencesData?.preferences_by_pk) {
      setState((prevStatus) => ({
        ...prevStatus,
        preferences: { ...prevStatus.preferences, ...preferencesData?.preferences_by_pk },
      }))
    } else {
      setState((prevStatus) => ({
        ...prevStatus,
        preferences: defaultPreferences,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPreferences, preferencesData, account])

  return (
    <ProfileContext.Provider
      value={[
        {
          accountProfile,
          isLoadingAccountInfo,
          preferences: state.preferences,
          isLoadingPreferences,
        },
        { syncCurrencyPreferences, setNotificationsEnable, refreshProfile },
      ]}
    >
      {children}
    </ProfileContext.Provider>
  )
}
