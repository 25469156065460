import { StoreSlice } from 'app-engine/store'
import { DeserializedProfilePaymentMethod } from 'pages/P2PView/hooks/useP2POffer'

export type ProfileSliceState = {
  availablePaymentMethods: string[]
  profilePaymentMethods: DeserializedProfilePaymentMethod[]
  currentPaymentMethod: string
}

type ProfileSliceActions = {
  setProfilePaymentMethods: (methods: DeserializedProfilePaymentMethod[]) => void
  setAvailablePaymentMethods: (methods: string[]) => void
  setCurrentPaymentMethod: (method: string) => void
  reset: () => void
}

export type ProfileSlice = ProfileSliceState & ProfileSliceActions

const defaultProfileState = {
  availablePaymentMethods: [],
  profilePaymentMethods: [],
  currentPaymentMethod: '',
}

export const createProfileSlice: StoreSlice<ProfileSlice> = (set) => ({
  ...defaultProfileState,
  reset: () => {
    set(defaultProfileState)
  },
  setAvailablePaymentMethods: (availablePaymentMethods) => {
    set({
      availablePaymentMethods,
    })
  },
  setCurrentPaymentMethod: (currentPaymentMethod) => {
    set({
      currentPaymentMethod,
    })
  },
  setProfilePaymentMethods: (profilePaymentMethods) => {
    set({
      profilePaymentMethods,
    })
  },
})
