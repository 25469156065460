import React from 'react'

const EthereumIcon = (props: any) => (
  <svg viewBox="0 0 60 60" fill="none" {...props}>
    <g clipPath="url(#clip0_3_2)">
      <path
        d="M31.4949 1.00132C18.394 0.913018 4.31684 5.22055 1.75574 19.6637C0.544918 26.4906 0.973826 33.1073 1.75574 39.9566C3.35797 53.9824 17.3583 59.3349 29.9996 58.9839C42.5518 58.6356 56.6002 54.3518 58.2444 39.9561C59.0264 33.1073 59.4548 26.4906 58.2444 19.6637C55.9998 7.00651 43.416 1.08246 31.4949 1.00132Z"
        fill="white"
        stroke="#3C3C3B"
      />
      <path
        d="M30.0735 37.115L30.3137 37.3738L41.3134 30.3586L30.3137 10.6667L30.0735 11.5478V37.115Z"
        fill="#343434"
      />
      <path d="M30.3138 37.3739V10.6667L19.3142 30.3586L30.3138 37.3739Z" fill="#8C8C8C" />
      <path
        d="M30.1784 48.9066L30.3138 49.3333L41.32 32.6093L30.3139 39.621L30.1785 39.799L30.1784 48.9066Z"
        fill="#3C3C3B"
      />
      <path d="M19.3142 32.6091L30.3138 49.3332V39.6207L19.3142 32.6091Z" fill="#8C8C8C" />
      <path d="M30.3138 24.9644V37.3737L41.3132 30.3587L30.3138 24.9644Z" fill="#141414" />
      <path d="M30.3137 24.9644L19.3142 30.3586L30.3137 37.3737V24.9644Z" fill="#393939" />
    </g>
    <defs>
      <clipPath id="clip0_3_2">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EthereumIcon
