import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import {
  Devices,
  GetRegAccountsDocument,
  GetRegAccountsQuery,
  GetRegAccountsQueryVariables,
  useDeleteDevicesByPkMutation,
} from 'app-engine/graphql/generated/bitcash'
import {
  DeviceActionsToggle,
  ItemActions,
  ItemActionsDelete,
  ItemContainer,
  ItemName,
  SectionContainer,
  SectionContent,
  SectionTitle,
} from 'pages/DeviceKeysView/styles'
import { TimesIcon } from 'app-view/components/icons'
import { GreenButton } from 'app-view/components/buttons'
import { useStore } from 'app-engine/store'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { ModalError } from 'app-view/components/Modal'

const refreshKeyAccountWhitelist = [
  'phelan.bk',
  'jshaw.bk',
  'max.bk',
  'brandon.bk',
  'meriver.bk',
  'humanist.bk',
  'andler.bk',
  'andlerios.bk',
]

const getUserData = (account: string) =>
  apolloClient.query<GetRegAccountsQuery, GetRegAccountsQueryVariables>({
    query: GetRegAccountsDocument,
    variables: {
      account,
    },
  })

export const DevicesSection: React.FC<{
  devices: Devices[]
  ifKeyIsActive: (key: string, node: React.ReactNode) => React.ReactNode
  isKeyActive: (key: string) => boolean
  onToggleKey: (key: string) => void
}> = ({ devices, ifKeyIsActive, isKeyActive, onToggleKey }) => {
  const { t } = useTranslation(['settings'])
  const [{}, globalModalActions] = useGlobalModal()
  const [deleteDeviceByPk] = useDeleteDevicesByPkMutation()
  const { account, pushTransaction } = useStore()
  const [refreshedKey, setRefreshedKey] = React.useState(false)

  const handleDeleteDevice = useCallback(
    (credId: string) => {
      globalModalActions.openConfirm({
        isDestructive: true,
        text: `${t('delete_device')}`,
        confirmText: `${t('delete')}`,
        onConfirm: () => {
          deleteDeviceByPk({ variables: { cred_id: credId } })
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deleteDeviceByPk, globalModalActions],
  )

  // ? Restoring bk@recovery ownership to the whitelist user...
  // ? This is a temporary solution to allow the user to refresh their key
  // * Once the user has a way to refresh their key, this can be removed
  const refreshUserKey = async () => {
    const { data: userData } = await getUserData(account)

    try {
      const response = await pushTransaction({
        actions: [
          {
            account: 'eosio',
            name: 'updateauth',
            authorization: [
              {
                actor: account,
                permission: 'owner',
              },
            ],
            data: {
              account: account,
              permission: 'owner',
              parent: '',
              auth: {
                threshold: 1,
                keys: [
                  {
                    key: userData.reg_accounts[0].public_key,
                    weight: 1,
                  },
                ],
                accounts: [{ weight: 1, permission: { actor: 'bk', permission: 'recovery' } }],
                waits: [],
              },
            },
          },
        ],
      })

      console.log('[REFRESHED KEY] ==>', response)

      setRefreshedKey(true)
    } catch (error) {
      console.log('[ERROR REFRESHING THE KEY] ==>', error)
      globalModalActions.open({
        content: () => <ModalError error={(error as Error).message} />,
        iconType: 'ERROR',
        showClose: true,
      })
    }
  }

  return (
    <SectionContainer>
      <SectionTitle>
        {t('devices')}
        {refreshKeyAccountWhitelist.includes(account) && (
          <GreenButton size="sm" onClick={refreshUserKey}>
            {refreshedKey ? 'Key Refreshed!' : 'Refresh Key'}
          </GreenButton>
        )}
      </SectionTitle>
      <SectionContent>
        {!devices?.length ? (
          <div>{t('no_devices')}</div>
        ) : (
          devices.map((device) => (
            <ItemContainer key={JSON.stringify(device)}>
              <ItemName>{device.device_name}</ItemName>
              <ItemActions>
                {ifKeyIsActive(
                  device.public_key,
                  <>
                    <DeviceActionsToggle
                      // @ts-ignore
                      isOn={isKeyActive(device.public_key)}
                      onClick={() => onToggleKey(device.public_key)}
                    />
                    <ItemActionsDelete onClick={() => handleDeleteDevice(device.cred_id)}>
                      <TimesIcon />
                    </ItemActionsDelete>
                  </>,
                )}
              </ItemActions>
            </ItemContainer>
          ))
        )}
      </SectionContent>
    </SectionContainer>
  )
}
