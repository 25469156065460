import { createContextHook } from '@blockmatic/hooks-utils'
import { config } from 'app-config'
import { useStore } from 'app-engine/store'
import { RegisterAccount } from 'app-engine/store/p2p-slice'
import { InputSwapOrder } from 'app-engine/store/swap-slice'
import { useEffect } from 'react'
import { useSetState } from 'react-use'
import { adminAccountService } from 'services/bitcash/admin/admin-account.service'

type UseAdminActionsState = {
  loading: boolean
  error: string
  account: RegisterAccount
  action?: 'approve' | 'reject'
}

const initialState: UseAdminActionsState = {
  loading: false,
  error: '',
  account: null,
  action: null,
}

type OrderActionResponse = Promise<{
  success: boolean
}>

interface UseAdminActionsActions {
  cancelOrder: (order: InputSwapOrder) => OrderActionResponse
  fillOrder: (order: InputSwapOrder) => OrderActionResponse
  setError: (error: string) => void
  setAccount: (account: RegisterAccount) => void
  setLoading: (loading: boolean) => void
  cancelAccountRequest: () => OrderActionResponse
  approveAccountRequest: (isVerified: boolean) => OrderActionResponse
}

export const useAdminActionsFn = (): [UseAdminActionsState, UseAdminActionsActions] => {
  const { pushTransaction } = useStore()
  const [state, setState] = useSetState(initialState)

  const setError = (error: string) => setState({ error })

  const cancelOrder = async (order: InputSwapOrder) => {
    try {
      const isBuying = order.order_type === 'buy'
      setState({ loading: true })

      const transaction = {
        actions: [
          {
            account: config.contracts.bitcashBank,
            name: isBuying ? 'cnlbuygem' : 'cnlsellgem',
            authorization: [
              {
                actor: config.contracts.bitcashBank,
                permission: 'gems',
              },
            ],
            data: {
              id: order.gems_id,
              [isBuying ? 'buyer' : 'seller']: order.bitcash_account,
            },
          },
        ],
      }
      await pushTransaction(transaction)
      return { success: true }
    } catch (err) {
      if (err instanceof Error) {
        setState({ error: err.message })
      }
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const fillOrder = async (order: InputSwapOrder) => {
    try {
      const isBuying = order.order_type === 'buy'
      setState({ loading: true })

      const transaction = {
        actions: [
          {
            account: config.contracts.bitcashBank,
            name: isBuying ? 'fillbuygem' : 'fillsellgem',
            authorization: [
              {
                actor: config.contracts.bitcashBank,
                permission: 'gems',
              },
            ],
            data: {
              id: order.gems_id,
              [isBuying ? 'buyer' : 'seller']: order.bitcash_account,
            },
          },
        ],
      }
      await pushTransaction(transaction)
      return { success: true }
    } catch (err) {
      if (err instanceof Error) {
        setState({ error: err.message })
      }
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const setAccount = (account: RegisterAccount) => {
    setState({ account })
  }

  const setLoading = (loading: boolean) => {
    setState({ loading })
  }

  const cancelAccountRequest = async () => {
    setState({ loading: true, action: 'reject' })

    try {
      const approveResponse = await adminAccountService.rejectAccountRequest({
        request_id: state.account.id,
      })

      return { success: true, response: approveResponse }
    } catch (error) {
      console.trace(error)
      setError((error as Error)?.message ?? error)
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const approveAccountRequest = async (isVerified: boolean) => {
    setState({ loading: true, action: 'approve' })
    try {
      const approveResponse = await adminAccountService.approveAccountRequest({
        request_id: state.account.id,
        is_verified: isVerified,
      })

      return { success: true, response: approveResponse }
    } catch (error) {
      console.trace({ error })
      setError((error as Error)?.message ?? error)
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  useEffect(() => console.log('😵 New error ', state.error), [state.error])

  return [
    state,
    {
      fillOrder,
      cancelOrder,
      setError,
      setAccount,
      setLoading,
      approveAccountRequest,
      cancelAccountRequest,
    },
  ]
}

export const [useAdminActions, AdminActionsProvider] = createContextHook(
  useAdminActionsFn,
  'You must wrap your application with <AdminActionsProvider /> in order to useAdminActions().',
)
