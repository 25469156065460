import Spinner from 'app-view/components/Spinner'
import { FlexBox } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import { BlackButton, GreenButton } from 'app-view/components/buttons'
import { SelfieCheckIcon, SelfieInvalidIcon } from 'app-view/components/icons'
import {
  CameraImageFrame,
  CameraImageFrameContainer,
  CameraVideoBorder,
  CameraWrapper,
  HelpText,
  InstructionTitle,
  MediaContainer,
  SelfieStatusHint,
} from 'pages/AccountView/routes/shared/step-for-upload-selfie/components/Styles'
import { useTakeImageHook } from 'pages/AccountView/routes/shared/step-for-upload-selfie/take-image/useTakeImageHook'
import { useTranslation } from 'react-i18next'
import { CameraLoaderAnimation } from '../components/CameraLoaderAnimation'
import { CloseButton } from '../components/CloseButton'
import { DownloadSelfieButton } from '../components/DownloadButton'

export function TakeSelfie({ close, next }: WizardStepProps) {
  const { t } = useTranslation(['account'])
  const [
    {
      selfieImageSrc,
      selfieDownloadLink,
      selfieStatus,
      error,
      processing,
      isCameraStarted,
      videoRef,
      frameWidth,
      frameHeight,
    },
    { takeSelfie, reTakeSelfie },
  ] = useTakeImageHook()

  return (
    <div style={{ width: '100%' }}>
      <FlexBox justifyContent="center" style={{ marginBottom: '5px' }}>
        <CameraWrapper>
          {selfieImageSrc ? (
            <CameraImageFrameContainer selfieStatus={selfieStatus} style={{ height: frameWidth }}>
              <img
                style={{
                  width: '100%',
                  height: frameWidth !== 0 && frameWidth > frameHeight ? '100%' : 'auto',
                }}
                src={selfieImageSrc}
                alt="Captured"
              />
            </CameraImageFrameContainer>
          ) : (
            <MediaContainer>
              {processing ? <CameraLoaderAnimation /> : ''}
              <video
                ref={videoRef}
                style={{
                  width: '100%',
                  borderRadius: '10px',
                  maxHeight: '325px',
                  maxWidth: '325px',
                  objectFit: 'cover',
                  height: frameWidth !== 0 ? frameWidth : 'auto',
                }}
                muted
                playsInline
                autoPlay
              />
              <CameraVideoBorder selfieStatus={selfieStatus} />
            </MediaContainer>
          )}
          {selfieImageSrc || (!selfieImageSrc && selfieStatus) ? (
            <SelfieCheckIcon style={{ position: 'absolute', top: '5px', right: '5px' }} />
          ) : (
            ''
          )}
          {!selfieImageSrc && !selfieStatus && selfieStatus !== null ? (
            <SelfieInvalidIcon style={{ position: 'absolute', top: '5px', right: '5px' }} />
          ) : (
            ''
          )}

          {isCameraStarted ? (
            <SelfieStatusHint selfieStatus={selfieStatus}>
              {processing ? t('processing') : ''}
              {selfieStatus === null && !processing ? t('verifying') : ''}
              {selfieStatus && !processing ? t('photo_is_ok') : ''}
              {!processing && !selfieStatus && selfieStatus !== null && error
                ? t('processing_error')
                : ''}
              {!processing && !selfieStatus && selfieStatus !== null && !error
                ? t('invalid_photo')
                : ''}
            </SelfieStatusHint>
          ) : (
            <div style={{ width: '100%' }}>
              <Spinner style={{ position: 'unset' }} boxSize={20} />
            </div>
          )}
        </CameraWrapper>
      </FlexBox>
      <InstructionTitle>{t('take_selfie_instructions')}:</InstructionTitle>
      <FlexBox
        style={{
          marginBlock: '24px',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: 360,
          marginInline: 'auto',
          paddingInline: 10,
          fontWeight: 600,
        }}
      >
        <HelpText
          dangerouslySetInnerHTML={{ __html: t('selfie_notifications_1') }}
          style={{ width: 'calc(50% - 5px)' }}
        />
        <HelpText
          dangerouslySetInnerHTML={{ __html: t('selfie_notifications_2') }}
          style={{ width: 'calc(50% - 5px)' }}
        />
      </FlexBox>
      {selfieImageSrc ? (
        <FlexBox style={{ padding: '0 7.5%', marginInline: 'auto' }} justifyContent="space-evenly">
          <BlackButton onClick={reTakeSelfie}>{t('retake_selfie')}</BlackButton>
          <GreenButton onClick={next}>{t('continue')}</GreenButton>
        </FlexBox>
      ) : (
        <FlexBox justifyContent="center">
          <BlackButton
            disabled={isCameraStarted ? Boolean(error) || !selfieStatus || processing : true}
            onClick={takeSelfie}
          >
            {t('take_selfie')}
          </BlackButton>
        </FlexBox>
      )}
      {selfieImageSrc ? (
        <DownloadSelfieButton downloadLink={selfieDownloadLink} />
      ) : (
        <CloseButton
          onClick={() => {
            close()
          }}
        />
      )}
    </div>
  )
}
