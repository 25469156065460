import React, { useState, useEffect } from 'react'
import { Button, Card, Message } from 'semantic-ui-react'
import { useStore } from 'app-engine/store'
import { config } from 'app-config'
import { useChainGraphTableRowsSubscription } from 'app-engine/graphql/generated/chaingraph'
import { PageContainer } from '../app-view/components/Styled'

const defaultBalances = [
  // {
  //   balance: '0.0000 EOS',
  // },
  // {
  //   balance: '0.00000000 PBTC',
  // },
  // {
  //   balance: '0.000000000 PETH',
  // },
  {
    balance: '0.0000 USDT',
  },
]

export const FaucetView = () => {
  const [faucetError, setFaucetError] = useState<string>()
  const { account, pushTransaction } = useStore()
  const [balances, setBalances] = useState(defaultBalances)
  const [disabledPushTx, setDisabledPushTx] = useState(false)
  const { data } = useChainGraphTableRowsSubscription({
    variables: {
      where: {
        chain: {
          _eq: config.eosChainName,
        },
        contract: {
          _eq: config.contracts.bitcashMockedTokens,
        },
        table: {
          // production & test accounts tables
          _in: ['accounts', 'accountsv2'],
        },
        scope: {
          _eq: account,
        },
      },
    },
    skip: !account,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (!data?.table_rows) return
    setBalances(data?.table_rows.map(({ data }) => data).flat())
  }, [data])

  useEffect(() => {
    localStorage.setItem('listener', 'false')
  }, [])

  useEffect(() => {
    localStorage.setItem('bitcash_app_account', account)
  }, [account])

  const faucetCall = React.useCallback(
    async (quantity: string, account_bitcash?: string) => {
      setFaucetError('')
      try {
        const transaction = {
          actions: [
            {
              account: config.contracts.bitcashMockedTokens,
              name: 'issue',
              authorization: [
                {
                  actor: account_bitcash || account,
                  permission: 'active',
                },
              ],
              data: {
                to: account_bitcash || account,
                quantity,
                memo: `${quantity} for bitcash app testing`,
              },
            },
          ],
        }
        await pushTransaction(transaction)
      } catch (err) {
        if (err instanceof Error) setFaucetError(err.message)
      } finally {
        setTimeout(() => {
          setDisabledPushTx(false)
        }, 5000)
        setTimeout(() => {
          setFaucetError('')
        }, 7000)
      }
    },
    [account, pushTransaction],
  )

  React.useEffect(() => {
    const push_button = document.querySelector('[id^="faucet-webauthn-trigger"]')
    console.log({ push_button })
    if (!push_button || localStorage.getItem('listener') === 'true') return
    push_button.addEventListener('click', () => {
      console.log('init')
      const local_account = localStorage.getItem('bitcash_app_account')
      setDisabledPushTx(true)
      faucetCall('1000.0000 USDT', local_account || account)
      console.log('finish')
    })
    localStorage.setItem('listener', 'true')
  }, [faucetCall, account, setDisabledPushTx])

  return (
    <PageContainer>
      <Card fluid style={{ textAlign: 'center' }}>
        <p>bitcash app testing tokens</p>

        {faucetError && faucetError !== 'This request has been cancelled by the user.' && (
          <Message negative>
            <Message.Header>We're sorry we can't apply that transaction.</Message.Header>
            <p>
              Error details: <br />
              {faucetError}
            </p>
          </Message>
        )}

        <br />
        <div
          style={{
            fontSize: '30px',
            color: '#1B1B1B',
            paddingBottom: 20,
            textAlign: 'center',
          }}
        >
          {balances.map(({ balance }) => {
            return (
              <p key={balance.split(' ')[1]} style={{ margin: 0 }}>
                {balance}
              </p>
            )
          })}
        </div>
        <br />
      </Card>

      <Button fluid primary disabled onClick={() => faucetCall('1000.0000 EOS')}>
        Get 1,000 EOS
      </Button>
      <Button fluid primary disabled onClick={() => faucetCall('5.00000000 PBTC')}>
        Get 5 BTC
      </Button>
      <Button fluid primary disabled onClick={() => faucetCall('5.000000000 PETH')}>
        Get 5 ETH
      </Button>
      <Button fluid primary id="faucet-webauthn-trigger" disabled={disabledPushTx}>
        Get 1,000 USDT
      </Button>
    </PageContainer>
  )
}
