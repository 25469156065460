import { useStore } from 'app-engine/store'
import { order_by, enumOrderBy } from 'graphql/chaingraph'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export type FilterOption = {
  label: string
  value: order_by
}

export const useExchangeHistoryFilters = () => {
  const { t } = useTranslation('history')
  const { account } = useStore()
  const [activeFilter, setActiveFilter] = useState<order_by>(enumOrderBy.desc)

  const filterOptions = useMemo<FilterOption[]>(() => {
    if (!account) return []
    return [
      {
        label: t('older'),
        value: enumOrderBy.asc,
      },
      { label: t('recent'), value: enumOrderBy.desc },
    ]
  }, [account, t])

  const updateExchangeHistory = (filter: FilterOption) => {
    setActiveFilter(filter.value)
  }

  return { filterOptions, activeFilter, updateExchangeHistory }
}
