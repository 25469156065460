import { createContextHook } from '@blockmatic/hooks-utils'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { useWizard } from 'app-view/components/Wizard'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { useP2POffer } from './useP2POffer'

type UseTakeOfferState = {
  confirmationText: string
  confirmationButtonText: string
  loading: boolean
  offer?: P2POffer
  error: string
  step: 'confirm' | 'success' | null
}

const initialState: UseTakeOfferState = {
  confirmationText: '',
  confirmationButtonText: '',
  loading: false,
  error: '',
  step: null,
}

interface TakeOfferActions {
  createOffer: (counterOffer: P2POffer) => void
  resetError: () => void
  setError: (error: string) => void
  setConfirmStates: (text: string, btnText: string) => void
  setLoading: (bool: boolean) => void
  setOffer: (offer: P2POffer) => void
}

export const useTakeOfferFn = (): [UseTakeOfferState, TakeOfferActions] => {
  const { t } = useTranslation(['p2p', 'global', 'errors'])
  const [state, setState] = useSetState(initialState)
  const userPositionsHook = useUserPositions()
  const findUserPosition = userPositionsHook.findUserPosition
  const [{ error }, { submitOffer, resetError: resetP2PError }] = useP2POffer()
  const [, { goTo }] = useWizard()

  const resetError = () => {
    setState({
      error: '',
    })
    resetP2PError()
  }

  const setOffer = (offer: P2POffer) => {
    setState({ offer })
  }

  const setConfirmStates = (text: string, btnText: string) => {
    setState((prevState) => {
      prevState.confirmationButtonText = btnText
      prevState.confirmationText = text
      return prevState
    })
  }

  const createOffer = async (counterOffer: P2POffer) => {
    const [amount, position] = counterOffer.amount.split(' ')

    if (counterOffer.type === 'sell') {
      let hasEnoughBalance = true

      try {
        findUserPosition(position).balance.amount.greaterOrEquals(Number(amount))
      } catch (err) {
        hasEnoughBalance = false
      }

      if (error || !hasEnoughBalance) {
        setState({
          error: !hasEnoughBalance
            ? `${t('zero_balance')}. ${findUserPosition(position).balance.toString()}`
            : error,
        })
        return
      }
    }

    // ? If offer is created, it would not have seller/buyer method_details and it would be a counter offer
    // ? However, they should know each other, so in this case we should implement
    // ? a way to provide such information for both parties (previously saved payment methods)
    // ! TODO: Save payment methods at localStorage, subtract it once user wants in on the chat...
    const { success } = await submitOffer(counterOffer)

    console.log('P2POffersTrustGlobalNetwork.tsx::createOffer::success', success)

    if (success) {
      goTo('success')
    } else {
      setState({ error: error })
    }
  }

  const setLoading = (bool: boolean) => setState({ loading: bool })

  const setError = (error: string) => setState({ error })

  return [
    state,
    {
      setConfirmStates,
      resetError,
      setError,
      setLoading,
      createOffer,
      setOffer,
    },
  ]
}

export const [useTakeOffer, TakeOfferProvider] = createContextHook(
  useTakeOfferFn,
  'You must wrap your application with <TakeOfferProvider /> in order to useTakeOffer().',
)
