import * as React from 'react'

interface SelfieContextProps {
  selfie: string
  setSelfie: React.Dispatch<React.SetStateAction<string>>
  idCard: string
  setIdCard: React.Dispatch<React.SetStateAction<string>>
  error: string
  setError: (error: string) => void
  downloadData: string
  setDownloadData: React.Dispatch<React.SetStateAction<string>>
  downloadPhotoIDData: string
  setDownloadPhotoIDData: React.Dispatch<React.SetStateAction<string>>
}

const SelfieContext = React.createContext<SelfieContextProps | undefined>(undefined)

export function useSelfie() {
  const context = React.useContext(SelfieContext)
  if (!context) {
    throw new Error('useSelfieContext must be used within a SelfieProvider')
  }
  return context
}

interface SelfieProviderProps {
  children: React.ReactNode
}

export const SelfieProvider = ({ children }: SelfieProviderProps) => {
  const [selfie, setSelfie] = React.useState('')
  const [idCard, setIdCard] = React.useState('')
  const [error, setError] = React.useState('')
  const [downloadData, setDownloadData] = React.useState('')
  const [downloadPhotoIDData, setDownloadPhotoIDData] = React.useState('')

  const value = React.useMemo(
    () => ({
      selfie,
      setSelfie,
      error,
      setError,
      downloadData,
      setDownloadData,
      idCard,
      setIdCard,
      downloadPhotoIDData,
      setDownloadPhotoIDData,
    }),
    [
      selfie,
      setSelfie,
      error,
      setError,
      downloadData,
      setDownloadData,
      idCard,
      setIdCard,
      downloadPhotoIDData,
      setDownloadPhotoIDData,
    ],
  )

  return <SelfieContext.Provider value={value}>{children}</SelfieContext.Provider>
}
