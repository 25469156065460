import { WizardStepProps } from 'app-view/components/Wizard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MethodDetails } from './MethodDetails'
import { useStore } from 'app-engine/store'

export const MethodDetailsStep = ({ prev, close }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const { currentPaymentMethod } = useStore()

  const text = () => {
    switch (currentPaymentMethod) {
      case 'bank_transfer':
        return t('buyer_method_details')
      case 'kuda':
        return t('buyer_method_details_account')
      case 'oxxo':
      case 'palmpay':
      case 'opay':
        return t('buyer_method_details_name_account')
      default:
        return t('buyer_method_details_phone')
    }
  }

  return <MethodDetails onPrev={prev} onNext={close} text={text()} />
}
