import React, { useEffect, useMemo } from 'react'
import { WheelSelect, WheelSelectOption } from 'app-view/components/WheelSelect'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'app-engine/library/utils'
import {
  DesktopSelectContainer,
  DesktopSelectLabel,
  Icon,
  IconsContainer,
  ReactSelect,
  SelectItemText,
  WheelSelectContainer,
} from 'app-view/components/WheelSelect/styled'
import { getUSDTLabel } from 'app-view/components/WheelSelect/utils'
import { FlexBox } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'

const SelectLabelContainer = styled(FlexBox)`
  width: 100%;
  justify-content: space-between;
`

type SelectProps = {
  id?: string // Just for debugging
  value?: any
  compact?: boolean
  isCoinSelector?: boolean
  options: WheelSelectOption[]
  label?: string
  initialPosition?: number
  onChange: (seletedOption: WheelSelectOption) => void
  gray?: boolean
  oneThird?: boolean
  topFix?: number
  isSearchable?: boolean
  width?: string
}

export const Select: React.FC<SelectProps> = ({
  value,
  compact,
  isCoinSelector,
  options = [],
  initialPosition,
  onChange,
  gray = false,
  oneThird = false,
  label,
  topFix = 0,
  isSearchable,
  width,
}) => {
  const { t } = useTranslation(['history', 'p2p'])
  const recent_regexp = useMemo(
    () => new RegExp(`^(${t('p2p:filter_option_recent')}|${t('history:recent')})$`),
    [t],
  )

  useEffect(() => {
    if (!isMobile) {
      onChange(
        initialPosition
          ? options[initialPosition]
          : options.find((opt) => opt.label.match(recent_regexp)) || options[0],
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  const desktopSelectValue = options.find((opt) => opt.value === value)

  return isMobile ? (
    <WheelSelectContainer className="mobile-select" label={label}>
      <div>
        {label && (
          <span className={gray ? 'gray' : ''} aria-label={label}>
            {label}
          </span>
        )}
        {options.length && (
          <WheelSelect
            oneThird={oneThird}
            options={options}
            initialPosition={
              initialPosition ?? options.findIndex((opt) => opt.label.match(recent_regexp))
            }
            onChange={onChange}
            topFix={topFix}
            label={Boolean(label)}
          />
        )}
      </div>
    </WheelSelectContainer>
  ) : (
    <DesktopSelectContainer
      compact={compact}
      isCoinSelector={isCoinSelector}
      width={width || '140px'}
    >
      {label ? <DesktopSelectLabel>{label}</DesktopSelectLabel> : null}
      <ReactSelect
        value={desktopSelectValue}
        menuPlacement="auto"
        maxMenuHeight={200}
        options={options}
        menuPosition="fixed"
        onChange={onChange as any}
        formatOptionLabel={(data) => (
          <SelectLabelContainer>
            {(data as WheelSelectOption).icon && (
              <IconsContainer>
                <Icon coin={(data as WheelSelectOption).icon} />
              </IconsContainer>
            )}
            <SelectItemText>{getUSDTLabel((data as WheelSelectOption).label)}</SelectItemText>
          </SelectLabelContainer>
        )}
        defaultValue={
          initialPosition
            ? options[initialPosition]
            : options.find((opt) => opt.label.match(recent_regexp)) || options[0]
        }
        isSearchable={isSearchable}
        styles={{
          container: (styles) => ({ ...styles, flex: 1 }),
          control: (styles) => ({
            ...styles,
            borderColor: 'transparent',
            boxShadow: 'none',
            width: '100%',
            paddingLeft: '13px',
            '&:hover': { borderColor: 'transparent' },
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: 0,
            justifyContent: compact ? 'unset' : 'center',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
        }}
      />
    </DesktopSelectContainer>
  )
}
