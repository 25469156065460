import React from 'react'
import Skeleton from 'react-skeleton-loader'
import styled from 'app-view/styles/styled'
import { IconBg } from './icons'
import { BlackButton } from 'app-view/components/buttons'
import { MotionProps, motion } from 'framer-motion/dist/framer-motion'
interface ISkeleton {
  height: string
  width: string
  borderRadius: string
  count: number
}

const Clipped = styled.div`
  width: 54px;
  height: 54px;
  -webkit-clip-path: url(#clip-path);
  clip-path: url(#clip-path);
  animation: pulseClip 600ms ease-in-out alternate infinite;

  @keyframes pulseClip {
    from {
      background-color: #1919191a;
    }
    to {
      background-color: #1919192f;
    }
  }
`

export const SkeletonContainer = styled(motion.div)`
  height: ${(props: any) => props.height || '80px'};
  width: 100%;
  margin-bottom: 16px;
  padding: 0.83rem 8px;
  animation: pulse 600ms ease-in-out alternate infinite;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  > span:last-of-type {
    margin-left: 1rem;
    display: relative;
    z-index: 100;
  }

  @keyframes pulse {
    from {
      background-color: #fff6;
    }
    to {
      background-color: #fff;
    }
  }
`

const SkeletonFilterContainer = styled(SkeletonContainer)`
  margin: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  > span {
    position: relative;
    top: -3px;

    &:first-of-type,
    &:last-of-type {
      opacity: 0.5;
    }

    &:first-of-type {
      top: -8px;
      clip-path: inset(42% 0% 0% 0%);
    }
    &:last-of-type {
      margin: 0;
      top: 0;
      bottom: 0;
      clip-path: inset(0% 0% 33% 0%);
    }
  }
`

const SkeletonOfferInnerContainer = styled.div`
  flex: 1;
  height: 60px;
  display: flex;
  padding-inline: 8px;

  > span,
  > div {
    width: 50%;
  }
  > span {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  &:last-of-type {
    align-items: flex-end;
    justify-content: flex-end;

    > span {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  button {
    font-size: 11px;
    min-height: 30px;
    max-height: 30px;
    min-width: 120px;
    max-width: 120px;
    padding-inline: 15px;

    &:disabled {
      opacity: 0.249;
    }
  }
`

export const IconSkeleton: React.FC = () => (
  <span>
    <IconBg />
    <Clipped />
  </span>
)

export const SkeletonLoader: React.FC<ISkeleton> = (props) => {
  return <Skeleton color="#ddd5" {...props} />
}

export const SkeletonText: React.FC = () => (
  <SkeletonContainer style={{ justifyContent: 'flex-end', paddingBottom: 0 }}>
    <SkeletonLoader height="10px" width="64px" count={1} borderRadius="8px" />
  </SkeletonContainer>
)

export const SkeletonCard: React.FC = () => (
  <SkeletonContainer>
    <IconSkeleton />
    <span>
      <SkeletonLoader height="8px" width="60px" count={2} borderRadius="8px" />
    </span>
  </SkeletonContainer>
)

export const SkeletonOffer: React.FC<MotionProps> = (props) => (
  <SkeletonContainer {...props}>
    <SkeletonOfferInnerContainer>
      <SkeletonLoader height="10px" width="120px" count={2} borderRadius="8px" />
    </SkeletonOfferInnerContainer>
    <SkeletonOfferInnerContainer>
      <BlackButton disabled />
    </SkeletonOfferInnerContainer>
  </SkeletonContainer>
)

export const SkeletonFilter: React.FC = () => (
  <SkeletonFilterContainer height="100%">
    <SkeletonLoader width="80px" height="12px" borderRadius="8px" count={1} />
    <SkeletonLoader width="80px" height="12px" borderRadius="8px" count={1} />
    <SkeletonLoader width="80px" height="12px" borderRadius="8px" count={1} />
  </SkeletonFilterContainer>
)
