import * as React from 'react'
import { SVGProps } from 'react'
const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 15"
    width="1em"
    height="1em"
    fill="none"
    {...props}
  >
    <path
      fill="#040316"
      d="m11.434 9.804.91-.909a.228.228 0 0 1 .389.162v4.132c0 .754-.612 1.365-1.365 1.365H1.364A1.365 1.365 0 0 1 0 13.189V3.185c0-.753.611-1.364 1.364-1.364h7.773c.202 0 .304.244.162.39l-.91.909a.225.225 0 0 1-.161.065H1.364V13.19h10.004V9.964c0-.06.023-.117.066-.16Zm4.45-5.735-7.463 7.463-2.57.285a1.174 1.174 0 0 1-1.295-1.296l.284-2.57L12.303.488c.651-.65 1.703-.65 2.35 0l1.229 1.228c.65.65.65 1.705.002 2.353Zm-2.808.878-1.65-1.65L6.144 8.58l-.208 1.855 1.856-.207 5.283-5.28Zm1.842-2.265L13.69 1.454a.297.297 0 0 0-.42 0l-.879.879 1.652 1.65.878-.877a.303.303 0 0 0-.003-.424Z"
    />
  </svg>
)
export default EditIcon
