import styled from 'app-view/styles/styled'

export const WalletStepMainContentWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  > div > div {
    border-color: #f2f2f2;
  }
`

export const CoinAmountWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  > div > div {
    border-color: #f2f2f2;
  }
`

export const WithdrawLimitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  > p {
    display: flex;
    white-space: pre-line;
    gap: 6px;

    > strong {
      display: flex;
      gap: 3px;
    }
  }
`
