import {
  ModalButtonsContainer,
  ModalStepBlackButton,
  ModalStepGreenButton,
} from 'app-view/components/Modal'
import React from 'react'
import { Form } from 'pages/AccountView/components/TextElements'
import { WalletStepMainContentWrapper, CoinAmountWrapper } from '../common/Styled'
import { InputWrapper, InputSection, Input, Label, HintError } from 'app-view/components/InputField'
import { useWallet } from 'pages/WalletView/hooks/useWallet'
import { useTranslation } from 'react-i18next'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import { WalletCoinAmount } from 'app-view/components/CoinAmount'
// TODO: Reemplace with @greymass/eosio
import { Asset } from 'eos-common'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { DeviceActionsToggle } from 'pages/DeviceKeysView/styles'
import { useEffectOnce } from 'react-use'

interface FormData {
  amount: Asset
  receiver: string
}
export interface InputHandlerProps {
  form: UseFormReturn<FormData, unknown>
  field: 'receiver' | 'amount'
  message: string
}

export const SendToAccount = ({ next, close, goTo }: WizardStepProps) => {
  const { t } = useTranslation(['wallet'])
  const [
    { action, loading, isReady, error, with_memo },
    { submitAction, setError, onChangeSendHandler, setReady, setMemo },
  ] = useWallet()
  const form = useForm<{ amount: Asset; receiver: string }>({
    defaultValues: {
      receiver: action.receiver,
      amount:
        action.amount.toString().split(' ')[0] === '0.00' ? undefined : action.amount.toString(),
    },
  })

  useEffectOnce(() => {
    setReady(Boolean(action.receiver !== '' && action.amount.toString().split(' ')[0] !== '0.00'))
  })

  const onBlur = () => {
    window.scroll(0, 0)
  }

  const onSubmitHandler = async () => {
    if (with_memo) {
      next()
      return
    }

    try {
      // TODO: If Memo Step is restored, pass this to memo step...
      // @ts-ignore TODO: fix types
      const amount = action.amount.amount.toString()
      const symbol = action.amount.symbol
      // @ts-ignore TODO: fix types
      const symbol_code = symbol.code().toString()
      // @ts-ignore TODO: fix types
      const sym_precision = amount.length - symbol.precision()
      const digested_amount = `${amount.substring(0, sym_precision)}.${amount.substring(
        sym_precision,
      )}`
      const trnx_quantity = `${digested_amount} ${symbol_code}`
      //  pair_id:0 for EOS transfer, pair_id:1 for USDT
      const pair_id = symbol_code === 'EOS' ? 0 : 1

      const { success } = await submitAction({
        from: action.sender,
        to: action.receiver,
        quantity: trnx_quantity,
        memo: `pair_id:${pair_id}`,
      })

      if (success) goTo('success')
    } catch (err) {
      setError((err as Error).message)
      setReady(false)
    }
  }

  if (loading) return <Loader text={t('loading_action')} />

  const receiver_input_props: InputHandlerProps = {
    form,
    field: 'receiver',
    message: t('invalid_account_format'),
  }
  const amount_input_props: InputHandlerProps = {
    form,
    field: 'amount',
    message: t('invalid_account_format'),
  }

  return (
    <Form onSubmit={form.handleSubmit(onSubmitHandler)} wallet={!Boolean(error)}>
      <WalletStepMainContentWrapper>
        <Controller
          name="receiver"
          control={form.control}
          rules={{
            required: t('indicate_account_send') as string,
            validate: () => (!isReady ? (t('invalid_account_send') as string) : true),
          }}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <Label size="md" htmlFor="send-receiver-input">
                {t('account_label')}
              </Label>
              <InputSection>
                <Input
                  {...field}
                  type="text"
                  maxLength={12}
                  onBlur={onBlur}
                  onChange={(e) => onChangeSendHandler(e, receiver_input_props)}
                  id="send-receiver-input"
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error?.message}</HintError>
              )}
            </InputWrapper>
          )}
        />
        <Controller
          name="amount"
          control={form.control}
          rules={{
            required: t('indicate_amount_send'),
            pattern: { value: /^(\d|.|,)+$/, message: t('invalid_amount') },
            validate: () => (!isReady ? t('invalid_amount_send') : true),
          }}
          render={({ field, fieldState }) => (
            <CoinAmountWrapper>
              <WalletCoinAmount
                label={t('amount_label')}
                inputProps={amount_input_props}
                isSend
                isWallet
                {...field}
              />
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error?.message}</HintError>
              )}
            </CoinAmountWrapper>
          )}
        />
        <InputWrapper style={{ alignItems: 'center', display: 'flex', columnGap: 14 }}>
          <Label size="md" htmlFor="send-with-memo-input" mb={0}>
            {t('with_memo')}
          </Label>
          <DeviceActionsToggle
            // @ts-ignore
            type="button"
            name="send-with-memo-input"
            isOn={with_memo}
            onClick={() => setMemo(!with_memo)}
          />
        </InputWrapper>
      </WalletStepMainContentWrapper>
      <div style={{ position: 'relative', width: '100%', marginBottom: 10 }}>
        <HintError align="center">ℹ Make sure to confirm if the account requires a memo.</HintError>
      </div>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={close}>
          {t('back_action')}
        </ModalStepBlackButton>
        <ModalStepGreenButton type="submit" disabled={!isReady}>
          {t(with_memo ? 'next_action' : 'send_action')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
