import theme from 'app-config/theme'
import { hideBkExt } from 'app-engine/library/utils'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { AccountIcon, SmallBoldText, Text } from 'app-view/components/Styled'
import { BaseButton } from 'app-view/components/buttons/BaseButton'
import { useOnlineStatus } from 'app-view/hooks/useOnlineStatus'
import styled from 'app-view/styles/styled'
import { useDeepCompareMemo } from 'use-deep-compare'

export const offerAnimationVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
}

export const OfferContainer = styled.div`
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  height: auto;

  &:last-of-type {
    padding-bottom: 60px;
  }

  > div {
    width: auto;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const ListTitle = styled.h3`
  font-weight: bold;
  color: ${({ color }) => color};
  padding: 0 0.5rem;
`

export const NoItems = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  opacity: 0.7;
  color: #787878;
  padding: 1rem 3rem;
`

export const ActionButton = styled(BaseButton)`
  min-width: 28px;
  width: 28px;
  min-height: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 4px;
`

export const AccountActivityIndicator = ({
  account,
  type,
}: {
  account: string
  type?: 'chat' | null
}) => {
  const normalizedAccount = hideBkExt(account)
  const { onlineStatus, showOnlineStatus } = useOnlineStatus(account)
  return onlineStatus && showOnlineStatus ? (
    <>
      {type === 'chat' ? (
        <Text
          color={type === 'chat' ? 'white' : '#C0C0C0'}
          style={{ fontSize: 18, fontFamily: 'Mulish', fontWeight: '900', wordWrap: 'break-word' }}
        >
          {normalizedAccount}
        </Text>
      ) : null}
      <Text
        color={type === 'chat' ? 'white' : '#C0C0C0'}
        style={{ fontSize: 10, fontFamily: 'Mulish', fontWeight: '600', wordWrap: 'break-word' }}
      >
        {onlineStatus}
      </Text>
    </>
  ) : null
}

export const AccountChatActivityIndicator = ({ account }: { account: string }) => {
  const { onlineStatus, showOnlineStatus } = useOnlineStatus(account)
  return onlineStatus && showOnlineStatus ? (
    <Text
      color="#C0C0C0"
      style={{ fontSize: 10, fontFamily: 'Mulish', fontWeight: '600', wordWrap: 'break-word' }}
    >
      {onlineStatus}
    </Text>
  ) : null
}

export const AccountWrapper = ({ account }: { account: string; trust?: boolean }) => {
  const normalizedAccount = hideBkExt(account)
  return (
    <div style={{ flexDirection: 'column', display: 'flex' }}>
      <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'ce' }}>
        <AccountIcon />
        <SmallBoldText color={theme.colors.lightBlack}>
          <span style={{ pointerEvents: 'all', userSelect: 'all' }}>{normalizedAccount}</span>
        </SmallBoldText>
      </div>
      <AccountActivityIndicator account={account} />
    </div>
  )
}

export const ActivityIndicatorContainer = styled.div`
  display: flex;
  align-self: baseline;
  margin-left: -10px;
`

export type ProcessedP2POffer = P2POffer & {
  count?: number
}

export const useGroupSimilarOffers = (offers: P2POffer[]) => {
  const processedOffers = useDeepCompareMemo<ProcessedP2POffer[]>(() => {
    if (!offers?.length) return []
    return offers.reduce<ProcessedP2POffer[]>((acc, offer) => {
      const isOfferIncluded = acc.some(
        (o) =>
          offer.amount === o.amount &&
          offer.method === o.method &&
          offer.type === o.type &&
          offer.initiator === o.initiator &&
          (offer.buyer_method_details === o.buyer_method_details ||
            offer.seller_method_details === o.seller_method_details),
      )
      if (isOfferIncluded) return acc
      return [
        ...acc,
        {
          ...offer,
          count: offers.filter(
            (o) =>
              offer.amount === o.amount &&
              offer.method === o.method &&
              offer.type === o.type &&
              offer.initiator === o.initiator &&
              (offer.buyer_method_details === o.buyer_method_details ||
                offer.seller_method_details === o.seller_method_details),
          ).length,
        },
      ]
    }, [])
  }, [offers])
  return processedOffers
}
