import React from 'react'
import styled from 'app-view/styles/styled'
import theme from 'app-config/theme'
import { CardText } from 'app-view/components/Styled'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion'

const animation_props = {
  exit: {
    opacity: 0,
    clipPath: 'ellipse(100% 0% at 50% 0%)',
  },
  initial: {
    opacity: 0.16,
    clipPath: 'ellipse(100% 15% at 50% 10%)',
  },
  animate: {
    opacity: 1,
    clipPath: 'ellipse(100% 150% at 50% 10%)',
  },
}

export const Tooltip = ({ open, setOpen, content, id }: ToolTipProps) => {
  const tooltipRef = React.useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation(['p2p', 'wallet', 'history', 'notifications'])
  const { colors } = theme

  // * onclick event to close the tooltip when user click outside the tooltip
  const handleClickOutside = (
    event: Event | MessageEvent<any> | ErrorEvent | PromiseRejectionEvent,
  ) => {
    const target = event.target as HTMLElement
    const tooltipId = `#${(tooltipRef.current as HTMLDivElement)?.id}`

    if (open && !target?.closest(tooltipId)) {
      setOpen(false)
    }
  }

  React.useEffect(() => {
    if (!tooltipRef.current) return () => null

    document.body.addEventListener('click', handleClickOutside)

    return () => {
      document.body.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipRef.current])

  return (
    <AnimatePresence>
      {open && (
        <TooltipContainer ref={tooltipRef} id={id} key={id}>
          <ToolTipArrow {...animation_props} />
          <ToolTipComponent {...animation_props}>
            {content.map(({ text, remove, icon, onClick }, i) => (
              <ToolTipOptionsContainer onClick={onClick} key={`tooltip-option-${text}-${i}`}>
                {icon ? <i>{icon}</i> : null}
                <ToolTipText color={remove ? colors?.red : colors?.darkGray}>{t(text)}</ToolTipText>
              </ToolTipOptionsContainer>
            ))}
          </ToolTipComponent>
        </TooltipContainer>
      )}
    </AnimatePresence>
  )
}

export const TooltipContainer = styled.div`
  position: relative;
  width: inherit;
`

export const ToolTipComponent = styled(motion.div)`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(51, 51, 51, 0.03), 0px 2px 6px 1px rgba(51, 51, 51, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  padding: 10px;
  position: absolute;
  right: -16px;
  top: 16px;
  z-index: 9998;
  min-width: 132px;

  p {
    white-space: pre-line;
  }
`

export const ToolTipArrow = styled(motion.div)`
  background: #ffffff;
  height: 11px;
  transform: rotate(45deg);
  width: 11px;
  position: absolute;
  right: 0;
  top: 10px;
  z-index: 9999;
`

export const ToolTipOptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  > i {
    width: 16px;
    height: 16px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`

export const ToolTipText = styled(CardText)`
  cursor: pointer;
  font-family: Mulish;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  justify-content: flex-end;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
`

export type ToolTipOptionsProps = {
  text: string
  onClick: () => void
  remove?: boolean
  icon?: React.ReactNode
}

export type ToolTipProps = {
  content: ToolTipOptionsProps[]
  id: string
  open: boolean
  setOpen: (open: boolean) => void
  onClick?: (() => void) | undefined
}
