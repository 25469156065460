import { css } from '@emotion/react'
import theme, { Theme } from 'app-config/theme'
import {
  CardTagProps,
  CardTextProps,
  StyledCardProps,
  StyledFlexboxProps,
} from 'app-engine/types/styled'
import { AccountIcon as AccountStyledIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { motion } from 'framer-motion/dist/framer-motion'
const { colors } = theme

export const scrollbar_css = css`
  overflow-x: hidden;
  scrollbar-color: ${theme.colors.black}4f rgba(0, 0, 0, 0);
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0) !important;
  }

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.black}4f;
    border-radius: 24px;
    border: 2px solid rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.black};
  }
`

export const scrollbar_css_desktop = css`
  ${scrollbar_css}

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
`

export const scrollbar_css_access = css`
  ${scrollbar_css}

  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.green}4f;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${theme.colors.green};
  }
`

export const ActionsContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  flex-grow: 1;
  margin-top: 2rem;
  ${scrollbar_css_access}

  @media screen and (min-width: 500px) {
    ${scrollbar_css_desktop}
  }
`

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`

export const RowTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(70px - 1rem);
`

export const BoldText = styled.p<{ color: string; dollars?: boolean }>`
  color: ${(props) => props.color};
  font-size: ${(props) => (props.dollars ? '1.105rem' : '0.875rem')};
  font-weight: 800;
  margin-bottom: 0;
  text-align: ${(props) => (props.dollars ? 'right' : 'left')};
`

export const SmallBoldText = styled.p<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0;
`

export const Text = styled.p<{ color: string; dollars?: boolean }>`
  color: ${(props) => props.color};
  font-size: ${(props) => (props.dollars ? '0.8125rem' : '1rem')};
  margin-bottom: 0;
  font-weight: ${(props) => (props.dollars ? '400' : '600')};

  > span {
    font-size: ${(props) => (props.dollars ? '	0.6875rem' : '0.8125rem')};
    font-weight: bold;
  }
`

export const HistoryFilterContainer = styled.section<{ isMobile?: boolean }>`
  align-items: center;
  background-color: white;
  border: none;
  border-radius: 8px;
  display: flex;
  gap: 0.5rem;
  text-transform: capitalize;
  width: 100%;
  min-height: 70px;
  padding-inline: ${(props) => (props.isMobile ? 'unset' : '1rem')};
`

export const Card = styled.div<StyledCardProps>`
  background-color: ${({ bg }) => bg ?? '#fff'};
  color: ${({ color, theme }) => color || (theme as Theme).colors.black};
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
`

export const ActionContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0.83rem 8px;
  height: 80px;
  margin-bottom: 1rem;

  ${(props) =>
    props.onClick &&
    `
  cursor: pointer;
  pointer-events: auto;
  `}
`

export const CoinSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 16px;
  width: 50%;
  height: 100%;

  * {
    transition: all 0.24s cubic-cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  > div {
    position: relative;
    min-width: 100%;

    span.indicator {
      position: absolute;
      top: 1rem;
      left: 2.25rem;
      height: 64px;
      width: 32px;
      z-index: 10;

      > svg {
        height: 100%;
      }
    }
  }
`

export const StyledPrice = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  line-height: 1;
  z-index: 3;

  > b {
    font-size: 1.1875rem;
    margin-bottom: 5px;
    text-align: center;
  }
  > p {
    font-size: 1.25rem;
    text-align: center;
  }
`

export const FlexBox = styled.div<Partial<StyledFlexboxProps>>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems ?? 'center'};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: 10px;
`

export const CardText = styled.p<CardTextProps>`
  color: ${({ color }) => color};
  font-size: ${({ dollars }) => (dollars ? '0.8125rem' : '0.938rem')};
  margin-bottom: 0;
  font-weight: ${({ dollars }) => (dollars ? '400' : '600')};
  display: flex;
  align-items: center;
`

export const CardTag = styled.div<CardTagProps>`
  color: ${({ color }) => color};
  background-color: ${({ approved }) => (approved ? '#F88D8D' : '#F4F6E4')};
  font-size: 0.8125rem;
  margin-top: 17px;
  margin-bottom: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  border-radius: 22px;
  padding: 2px 8px;
  &.feedbackTag {
    background-color: #bdbff3;
    margin: 0 auto 0 0;
    gap: 0;
    text-transform: lowercase;
    width: max-content;
  }
`

export const CardBoldText = styled(CardText)`
  font-weight: 700;
  text-transform: 'capitalize';
  white-space: pre-line;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const p2pIconProps = {
  width: '16px',
  height: '16px',
  style: {
    marginRight: '0.3rem',
  },
}

export const CardBoldTextAlt = styled(CardBoldText)<CardTextProps>`
  font-size: ${({ dollars }) => (dollars ? '1.105rem' : '1rem')};
  text-align: ${({ dollars }) => (dollars ? 'right' : 'left')};
  margin-bottom: 0;
`

export const TransactionCardContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  padding: 0.83rem 1rem;
  height: 96px;
  cursor: pointer;
`

export const PageContainer = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  > section,
  form {
    width: 100%;
  }
`

export const ScrollableFlexBoxArea = styled(PageContainer)`
  flex-grow: 1;
  overflow-y: scroll;
  position: relative;
  left: 2px;
  ${scrollbar_css}
`

export const MainContentContainer = styled.section`
  /* scroll area fix? */
`

type HeaderContainerProps = {
  pb?: number
  pt?: number
}
// padding-top: ${({ pb }) => pb || 0}px;0.75rem;
export const HeaderContainer = styled.section<HeaderContainerProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '0.75rem')};
  padding-bottom: ${({ pb }) => pb || 0}px;
`

export const ButtonWrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`

const SlideFixedCardWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  background: #fff;
  padding: 20px;
  width: 91.666%;
  margin: 0 4.1665%;
  border: 1px solid #0001;
  border-top: none;
  box-shadow: 0px 24px 28px rgba(0, 0, 0, 0.08), 0 12px 12px rgba(0, 0, 0, 0.04);
  z-index: 1060;

  p,
  > div {
    max-width: 400px;
    margin: 0 auto;
  }
  p {
    font-size: 1rem;
    width: 80%;
    text-align: center;
    margin: 0 auto 20px;
  }
`

export const SlideTopFixedCardWrapper = styled(SlideFixedCardWrapper)`
  top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`

export const SlideBottomFixedCardWrapper = styled(SlideFixedCardWrapper)`
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  > p {
    &:first-of-type {
      margin-top: 8px;
      margin-bottom: 24px;
    }
  }
`

export const OnramperGrid = styled.div`
  position: relative;
  grid-column: 1 / 3;
  width: 100%;

  > button {
    min-width: 230px;
    margin: 0 auto;
  }
`

export const OrderDetailLayoutLine = styled.strong`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 1.125rem;
  max-width: 83.33%;
  margin-left: auto;
  margin-right: auto;

  span:last-of-type {
    font-weight: 700;
    font-size: 1rem;
  }
`

export const AccountIcon = styled(AccountStyledIcon)`
  width: 16px;
  height: 16px;
  margin-right: 0.3rem;
  fill: ${colors.lightBlack};
`

export const Label = styled.p`
  font-size: 1.25rem;
  line-height: 0.9;
`
