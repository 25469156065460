import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { asset } from 'eos-common'
import { config } from 'app-config'
import {
  ModalButtonsContainer,
  ModalStepGreenButton,
  ModalStepBlackButton,
  ModalText,
} from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Form } from 'pages/AccountView/components/TextElements'
import { useWallet } from 'pages/WalletView/hooks/useWallet'
import { WalletStepConfirmWrapper } from 'pages/WalletView/steps/common/Styled'
import { cryptoAmount } from 'app-view/lib/utils'
import { useTokenPrices } from 'app-view/hooks/use-token-prices'
import { useRealTimePairs } from 'app-view/hooks/use-realtime/use-realtime-pairs'

interface DepositConfirmState {
  baseAmount: string
  targetAmount: string
  exchangeRate: string
  pairId: string
}

const initialState: DepositConfirmState = {
  baseAmount: '',
  targetAmount: '',
  exchangeRate: '',
  pairId: '',
}

export const DepositConfirm = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['wallet'])
  const [{ action, loading, error }, { submitAction, setError }] = useWallet()
  const tokenPricesHook = useTokenPrices()
  const convertToAsset = tokenPricesHook.convertToAsset
  const getUsdTokenValue = tokenPricesHook.getUsdTokenValue
  const pairs = useRealTimePairs()
  const [{ baseAmount, targetAmount, pairId }, setState] =
    useSetState<DepositConfirmState>(initialState)

  useEffect(() => {
    const getExchangePair = () => {
      try {
        // @ts-ignore TODO: fix types
        const amount = action.amount.amount.toString()
        const symbol = action.amount.symbol
        // @ts-ignore TODO: fix types
        const sym_precision = amount.length - symbol.precision()
        const digested_amount = `${amount.substring(0, sym_precision)}.${amount.substring(
          sym_precision,
        )}`
        // @ts-ignore TODO: fix types
        const symbol_code = symbol.code().toString()
        const trnx_quantity = `${digested_amount} ${symbol_code}`
        setState({ baseAmount: trnx_quantity })

        const target_symbol = action.target
        // @ts-ignore TODO: fix types
        const target = convertToAsset(getUsdTokenValue(action.amount), target_symbol)
        const target_amount = target.amount.toString()
        const target_precision = target_amount.length - target.symbol.precision()
        const target_formatted = `${target_amount.substring(
          0,
          target_precision,
        )}.${target_amount.substring(target_precision)}`
        const target_quantity = `${target_formatted} ${target_symbol}`
        setState({ targetAmount: target_quantity })

        const targetPrice = convertToAsset(
          // @ts-ignore TODO: fix types
          getUsdTokenValue(asset(10 ** symbol.precision(), symbol)),
          target_symbol,
        )
        const priceAmountStr = targetPrice.amount.toString()
        const pricePrecision = priceAmountStr.length - targetPrice.symbol.precision()
        const rate = `${priceAmountStr.substring(0, pricePrecision)}.${priceAmountStr.substring(
          pricePrecision,
        )}`
        setState({ exchangeRate: `${rate} ${target_symbol.replace('BIT', '')}` })

        // pair_id:0 for EOS transfer, pair_id:1 for USDT
        const pairKey = symbol_code + target_symbol
        const pairId = pairs?.[pairKey]?.id || ''
        setState({ pairId })
      } catch (err) {
        setError((err as Error).message)
      }
    }

    getExchangePair()
    // eslint-disable-next-line
  }, [])

  const onDeposit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const { success } = await submitAction({
        from: action.sender,
        to: config.contracts.bitcashBank,
        quantity: baseAmount,
        memo: `pair_id:${pairId}`,
      })

      if (success) next()
    } catch (err) {
      setError((err as Error).message)
    }
  }

  if (loading) return <Loader text={t('loading_action')} />

  return (
    <Form onSubmit={onDeposit} wallet={!Boolean(error)}>
      <WalletStepConfirmWrapper>
        <ModalText
          dangerouslySetInnerHTML={{
            __html: `${t('deposit_confirm', {
              base_amount: baseAmount ? cryptoAmount(baseAmount) : baseAmount,
              target_amount: targetAmount ? cryptoAmount(targetAmount) : targetAmount,
            })}`,
          }}
        />
        {/*

          ${t('exchange_rate_amount', { exchange_rate: exchangeRate })} */}
      </WalletStepConfirmWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={prev}>
          {t('back_action')}
        </ModalStepBlackButton>
        <ModalStepGreenButton type="submit">{t('confirm_action')}</ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
