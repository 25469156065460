import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Select } from 'app-view/components/Select'
import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalText,
  ModalStepGreenButton,
  ModalStepBlackButton,
  ModalSelectDeleteIconWrapper,
} from 'app-view/components/Modal'
import { AmountOption, getCurrencyLabel } from 'app-view/lib/amounts'
import { useWizard } from 'app-view/components/Wizard'
import { useStore } from 'app-engine/store'
import { SectionAmount } from 'pages/P2PView/Styled'
import { DeleteIcon } from 'app-view/components/icons'
import { ButtonTransparent } from 'app-view/components/buttons'
import { Input, Label } from 'app-view/components/InputField'
import { FlexBox } from 'app-view/components/Styled'

// TODO: getAmountOptions will receive the symbol_code
//

const swapOptions = [
  { label: getCurrencyLabel('USD', 25), value: 25 },
  { label: getCurrencyLabel('USD', 50), value: 50 },
  { label: getCurrencyLabel('USD', 100), value: 100 },
  { label: getCurrencyLabel('USD', 500), value: 500 },
  { label: getCurrencyLabel('USD', 2000), value: 2000 },
  { label: 'Custom', value: -1 },
]

export type AmountProps = {
  onNext: () => void
  onChange: (amount: number) => void
  text: string
  disabled?: string
}

export const SwapAmount = ({ onNext, onChange, text, disabled }: AmountProps) => {
  const { t } = useTranslation(['p2p', 'wallet'])
  const [, { close }] = useWizard()
  const {
    coinSwap: { swap },
    setSwapBitcashAmount,
  } = useStore()
  const [isCustomAmount, setIsCustomAmount] = useState(false)

  useEffect(() => {
    if (swap.bitcash_amount === -1 && !isCustomAmount) setIsCustomAmount(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swap.bitcash_amount])

  const onChangeMethod = (opt: AmountOption) => onChange(parseInt(opt.value, 10))
  return (
    <>
      <ModalSelectWrapper as="form">
        <SectionAmount>
          {isCustomAmount ? (
            <>
              <ModalSelectDeleteIconWrapper>
                <ButtonTransparent onClick={() => setIsCustomAmount(false)}>
                  <DeleteIcon width={14} />
                </ButtonTransparent>
              </ModalSelectDeleteIconWrapper>
              <FlexBox justifyContent="space-between" alignItems="center">
                <Label htmlFor="swap-amount" size="md" style={{ fontWeight: '700' }} pl={0} mb={0}>
                  {t('wallet:amount')}
                </Label>
                <Input
                  type="number"
                  size="small"
                  paddingspace="thigh"
                  placeholder={swap.bitcash_amount === -1 && '10'}
                  value={swap.bitcash_amount === -1 ? undefined : swap.bitcash_amount}
                  onChange={(e) => setSwapBitcashAmount(parseInt(e.target.value, 10))}
                  id="swap-amount"
                  name="swap-amount"
                  fontWeight="600"
                  autofocus
                />
              </FlexBox>
            </>
          ) : (
            <Select
              label={t('wallet:amount')}
              options={swapOptions}
              initialPosition={2}
              onChange={onChangeMethod}
              topFix={5.5}
              width={'100%'}
            />
          )}
        </SectionAmount>
        <ModalText style={{ marginBottom: 0 }} styleContainer={{ flexGrow: 0 }}>
          {disabled || text}
        </ModalText>
      </ModalSelectWrapper>

      <ModalButtonsContainer>
        <ModalStepBlackButton onClick={close}>{t('Back')}</ModalStepBlackButton>
        <ModalStepGreenButton
          onClick={() => !disabled && onNext()}
          disabled={Boolean(disabled) || swap.bitcash_amount <= 0 || !swap.bitcash_amount}
        >
          {t('Next')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}
