import React from 'react'

const CheckingIcon = (props: any) => (
  <svg width="23" height="18" viewBox="0 0 23 18" fill="none" {...props}>
    <path
      d="M8.84804 14.5489L3.47361 9.17447C3.15072 8.85158 3.15072 8.32806 3.47361 8.00514L4.6429 6.83582C4.96579 6.5129 5.48934 6.5129 5.81223 6.83582L9.43271 10.4563L17.1874 2.70164C17.5102 2.37875 18.0338 2.37875 18.3567 2.70164L19.526 3.87096C19.8489 4.19385 19.8489 4.71737 19.526 5.04029L10.0174 14.5489C9.69445 14.8718 9.17093 14.8718 8.84804 14.5489Z"
      fill="white"
    />
  </svg>
)

export default CheckingIcon
