import { isMobile } from 'app-engine/library/utils'
import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { Select } from 'app-view/components/Select'
import { SkeletonFilter } from 'app-view/components/Skeletons'
import { WheelSelectOption } from 'app-view/components/WheelSelect'
import { useWizard } from 'app-view/components/Wizard'
import styled from 'app-view/styles/styled'
import { SectionAmount } from 'pages/P2PView/Styled'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
let renderCOunt = 0
export const Method = ({ onPrev, onNext, onChange, text }: MethodProps) => {
  const { t } = useTranslation(['p2p'])
  const [
    { offer, paymentOptions, region_country_options },
    { setRegionCountryOptions, setMethodOptions },
  ] = useP2POffer()
  const [{ step }] = useWizard()
  const [methodsLoaded, setMethodsLoaded] = React.useState(false)

  const verifyMethods = () => {
    setMethodsLoaded(Boolean(paymentOptions.length))

    if (paymentOptions.length === 0) return

    onChange(paymentOptions[initialPosition].value)
  }

  useEffect(() => {
    verifyMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptions.length])

  useEffect(() => {
    renderCOunt++
    if (paymentOptions.length > 0 && !step.match(/^(method|method-bank)/)) return

    switch (step) {
      case 'method':
        setMethodOptions()
        break
      case 'method-bank':
        setRegionCountryOptions()
        break
      default:
        break
    }
  }, [step])

  console.log('renderCount', renderCOunt)

  const onChangeMethod = (opt: WheelSelectOption) => {
    if (paymentOptions.length === 0 || !opt?.value) return

    onChange(opt.value)
  }

  const initialPreSelectedMethod =
    paymentOptions.findIndex((opt) => opt.value === offer?.method) !== -1
      ? paymentOptions.findIndex((opt) => opt.value === offer?.method)
      : 0
  const selectInitialPosition = isMobile
    ? Math.round(paymentOptions.length / 2) - 1
    : initialPreSelectedMethod
  const initialPosition = step === 'method' ? selectInitialPosition : 0

  return (
    <>
      <ModalSelectWrapper>
        <MethodSectionAmount>
          {paymentOptions.length === 0 ? (
            <SkeletonFilter />
          ) : (
            <Select
              options={step === 'method' ? paymentOptions : region_country_options}
              initialPosition={initialPosition}
              value={paymentOptions[initialPosition].value}
              onChange={onChangeMethod}
              topFix={6}
              width="100%"
              isSearchable
            />
          )}
        </MethodSectionAmount>
        <ModalText style={{ marginBottom: 0 }} styleContainer={{ flexGrow: 0 }}>
          {text}
        </ModalText>
      </ModalSelectWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton onClick={onPrev}>{t('Back')}</ModalStepBlackButton>
        <ModalStepGreenButton disabled={!methodsLoaded} onClick={onNext}>
          {t('Next')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}

const MethodSectionAmount = styled(SectionAmount)`
  > div {
    margin: 0 auto;
    text-align: center;

    input {
      /* Overriding default grid-area from React-Select */
      grid-area: 1 / 3 / auto / auto !important;
      text-align: center;
    }
  }
`

export type MethodProps = {
  onPrev: () => void
  onNext: () => void
  onChange: (amount: string) => void
  text: string
}
