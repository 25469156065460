import * as React from 'react'
import { SVGProps } from 'react'

const BallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 8 8"
    width={8}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={4} cy={4} r={4} fill="#29E0AD" />
  </svg>
)

export default BallIcon
