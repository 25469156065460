/**
 * Here we place all errors that <ModalError /> component can catch. Pass the error string as `errors:${default_error_message}` for example
 */
export default {
  bitcashbank_acct_no_valid: 'Operation could not be completed:\nAccount is not from BitcashBank.',
  bitcashbank_acct_no_registered:
    'Operation could not be completed:\nAccount not yet approved by BitcashBank.',
  unable_find_icon: 'Unable to find icon for {{icon}}',
  reading_token_precision: 'Error reading token precision',
  pair_not_found: 'Pair for {{crypto_token}}/{{stable_token}} not found',
  position_not_found: '{{tokenSymbolCode}} position not found',
  token_not_found: 'token not found {{symbolCode}}',
  getting_last_price: 'Problem getting last price',
  price_not_found: 'price not found {{symbolCode}}',
  cannot_convert: 'Cannot convert {{quantity}} to {{symbolCode}}',
  key_required: 'At least one key is required',
  getting_escrow_id: 'Error getting p2p escrow id from EOS, please contact support',
  minimum_quantity: 'Please deposit a minimum of at least 1 USDT',
}
