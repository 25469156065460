import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store'
import { Notifications } from 'app-engine/types/notifications'
import styled from 'app-view/styles/styled'
import { PageContainer } from 'app-view/components/Styled'
import { Tab, Tabs } from 'app-view/components/Tabs'
import { ActionsContainer } from 'app-view/components/Styled'
import { NotificationItem as NotificationItemComponent } from './components/NotificationItemStyles'
import NotificationItem from './components/NotificationItem'
import SystemNotificationItem from './components/SystemNotificationItem'
import unique from 'lodash.uniqby'
import { canAccessAdminView } from 'app-view/lib/utils'
import React from 'react'
import { WizardProvider, Wizard } from 'app-view/components/Wizard'
import { P2POfferProvider, useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { ChatProvider } from 'app-view/components/Chat/hooks/useChat'
import { NotificationSkeleton } from './components/NotificationSkeleton'
import { useSystemNotifications } from 'app-view/hooks/use-systemNotification.hook'

const StackedContainer = styled.div`
  position: relative;
  min-height: 100px;
`

const NotificationsViewComponent: React.FC = () => {
  const { stakedNotification, unreadNotifications } = useStore()
  const {
    statuses, // ? unread and read notifications are stored here
    isLoading,
  } = useSystemNotifications()
  const account = useStore.useAccount()
  const showSystemTab = canAccessAdminView(account)
  const [{ loading, error }] = useP2POffer()
  const { t } = useTranslation(['notifications'])
  const [currentTab, setCurrentTab] = useState({ label: t('all'), value: 'all' })
  const tabs: Tab[] = [
    {
      label: t('all'),
      value: 'all',
    },
    {
      label: t('unread'),
      value: 'unread',
    },
  ]
  if (showSystemTab) {
    tabs.push({
      label: t('SYSTEM'),
      value: 'system',
    })
  }

  const renderNotification = () => {
    switch (currentTab.value) {
      case 'all':
        return renderStacked(stakedNotification)
      case 'unread':
        return renderUnread(unreadNotifications())
      case 'system':
        return renderSystemNotifications()
      default:
        return null
    }
  }

  const renderStacked = (deepNotifications: Notifications[][]) => {
    if (!deepNotifications.length) return null
    return deepNotifications.map((notifications, index) => (
      <StackedContainer key={index}>
        {unique(notifications, 'id').map((notification, i) => {
          return (
            <span key={notification?.id}>
              {i === 0 && (
                <NotificationItem
                  pos={0}
                  key={notification?.id}
                  item={notification}
                  className="stacked"
                />
              )}
              {i < 3 && i !== 0 && (
                <NotificationItemComponent
                  bottomPos={i === 1 ? 10 : 0}
                  read
                  pos={i}
                  key={notification?.id}
                  className="stacked"
                />
              )}
            </span>
          )
        })}
      </StackedContainer>
    ))
  }

  const renderSystemNotifications = useCallback(() => {
    if (isLoading) return <NotificationSkeleton />
    if (error) return <div>Error loading notifications</div>
    return (
      <>
        {statuses.unread.map((unreadSystemNotification) => (
          <SystemNotificationItem
            key={unreadSystemNotification.id}
            item={unreadSystemNotification}
          />
        ))}
        <hr />
        {statuses.read.map((readSystemNotification) => (
          <SystemNotificationItem key={readSystemNotification.id} item={readSystemNotification} />
        ))}
        <Wizard error={error || ''} resetError={close} loading={isLoading} />
      </>
    )
  }, [isLoading, error, statuses.unread, statuses.read, loading])

  const renderUnread = (notifications?: Notifications[]) => {
    if (!notifications) return null
    return notifications.map((notification) => (
      <NotificationItem item={notification} key={notification?.id} />
    ))
  }
  return (
    <PageContainer>
      <Tabs tabs={tabs} currentTab={currentTab.value} setCurrentTab={setCurrentTab} />
      <ActionsContainer as="ul" style={{ padding: '8px', marginTop: '0px' }}>
        {renderNotification()}
      </ActionsContainer>
    </PageContainer>
  )
}

export const NotificationsView = () => (
  // @ts-expect-error
  <WizardProvider>
    {/* @ts-ignore */}
    <ChatProvider>
      {/* @ts-ignore */}
      <P2POfferProvider>
        <NotificationsViewComponent />
      </P2POfferProvider>
    </ChatProvider>
  </WizardProvider>
)

/* TODO
 * Fix Tabs styling ( Font family )
 * Verify traductions
 *  - New copy
 */
