import React from 'react'

const CheckIcon = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
      fill={props.fill ?? '#29E0AD'}
    />
  </svg>
)

export default CheckIcon
