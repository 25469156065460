import React from 'react'

const P2PNotificationIcon = (props: any) => (
  <svg viewBox="0 0 42 26" {...props}>
    <path
      d="M21 0.625C22.6245 0.625 24.1824 1.27031 25.331 2.41897C26.4797 3.56763 27.125 5.12555 27.125 6.75C27.125 8.37445 26.4797 9.93237 25.331 11.081C24.1824 12.2297 22.6245 12.875 21 12.875C19.3755 12.875 17.8176 12.2297 16.669 11.081C15.5203 9.93237 14.875 8.37445 14.875 6.75C14.875 5.12555 15.5203 3.56763 16.669 2.41897C17.8176 1.27031 19.3755 0.625 21 0.625ZM8.75 5C9.73 5 10.64 5.2625 11.4275 5.735C11.165 8.2375 11.9 10.7225 13.405 12.665C12.53 14.345 10.78 15.5 8.75 15.5C7.35761 15.5 6.02226 14.9469 5.03769 13.9623C4.05312 12.9777 3.5 11.6424 3.5 10.25C3.5 8.85761 4.05312 7.52226 5.03769 6.53769C6.02226 5.55312 7.35761 5 8.75 5ZM33.25 5C34.6424 5 35.9777 5.55312 36.9623 6.53769C37.9469 7.52226 38.5 8.85761 38.5 10.25C38.5 11.6424 37.9469 12.9777 36.9623 13.9623C35.9777 14.9469 34.6424 15.5 33.25 15.5C31.22 15.5 29.47 14.345 28.595 12.665C30.1 10.7225 30.835 8.2375 30.5725 5.735C31.36 5.2625 32.27 5 33.25 5ZM9.625 22.9375C9.625 19.315 14.7175 16.375 21 16.375C27.2825 16.375 32.375 19.315 32.375 22.9375V26H9.625V22.9375ZM0 26V23.375C0 20.9425 3.3075 18.895 7.7875 18.3C6.755 19.49 6.125 21.135 6.125 22.9375V26H0ZM42 26H35.875V22.9375C35.875 21.135 35.245 19.49 34.2125 18.3C38.6925 18.895 42 20.9425 42 23.375V26Z"
      fill="#787878"
    />
  </svg>
)

export default P2PNotificationIcon
