// history.ts

export default {
  p2p: 'P2P',
  trade: 'Giao dịch',
  bank: 'Ngân hàng',
  swap: 'Hoán đổi',
  filterBy: 'Lọc theo',
  older: 'Cũ hơn',
  recent: 'Gần đây',
  forex: 'Forex',
  from: 'Từ',
  to: 'Đến',
  sent: 'Đã gửi',
  received: 'Đã nhận',
  p2pEmptyMsn: 'Hoạt động P2P Bitcash đã xác nhận sẽ được phản ánh ở đây.',
  tradeEmptyMsn: 'Hoạt động Giao dịch đã được xác nhận sẽ được phản ánh ở đây.',
  paymentsEmptyMsn: 'Hoạt động Thanh toán đã được xác nhận sẽ được phản ánh ở đây.',
  fee: 'phí',

  deposit: 'tiền gửi',
  transfer: 'chuyển khoản',
  'There is currently no buy history': 'Hiện tại không có lịch sử mua hàng',
  'There is currently no sell history': 'Hiện tại không có lịch sử bán hàng',
  'There is currently no history': 'Hiện tại không có lịch sử',
  'There is currently no send history': 'Hiện tại không có lịch sử gửi',
  'There is currently no receipt history': 'Hiện tại không có lịch sử nhận',
  buy_slate_no_msg: 'Vui lòng đợi quản trị viên gửi cho bạn tin nhắn slatepack.',
  buy_slate_with_msg: 'Vui lòng đợi quản trị viên hoàn tất giao dịch.',
  sell_slate_no_msg: 'Vui lòng đợi quản trị viên nhận phản hồi slatepack để hoàn tất.',
  sell_slate_with_msg: 'Hoàn tất giao dịch với phản hồi slatepack. Chờ quản trị viên xác nhận.',
}
