import { Wizard, useWizard } from 'app-view/components/Wizard'
import { useP2POffer } from '../hooks/useP2POffer'
import { useTakeOffer } from '../hooks/useTakeOffer'

export const TakeOfferWizard = () => {
  const [{ error: takeError }, { resetError: resetTakeError }] = useTakeOffer()
  const [{ error }, { resetError }] = useP2POffer()
  const [, { close }] = useWizard()

  return (
    <Wizard
      error={error || takeError}
      resetError={() => {
        const isOfferTaken =
          error.includes('Offer already taken') || takeError.includes('Offer already taken')

        resetError()
        resetTakeError()

        if (isOfferTaken) close()
      }}
    />
  )
}
