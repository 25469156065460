import * as React from 'react'
import { SVGProps } from 'react'

const SuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 0.444458C9.71455 0.444458 7.4804 1.12218 5.5801 2.39192C3.67979 3.66166 2.19869 5.46639 1.32408 7.57789C0.449465 9.68939 0.220626 12.0128 0.6665 14.2544C1.11237 16.4959 2.21293 18.555 3.82901 20.171C5.44508 21.7871 7.50408 22.8877 9.74564 23.3335C11.9872 23.7794 14.3106 23.5506 16.4221 22.676C18.5336 21.8013 20.3384 20.3202 21.6081 18.4199C22.8779 16.5196 23.5556 14.2855 23.5556 12C23.5556 8.93529 22.3381 5.99609 20.171 3.829C18.0039 1.66191 15.0647 0.444458 12 0.444458ZM19.5472 8.12168L10.0572 17.6045L4.4528 12C4.26125 11.8085 4.15364 11.5487 4.15364 11.2778C4.15364 11.0069 4.26125 10.7471 4.4528 10.5556C4.64434 10.364 4.90413 10.2564 5.17502 10.2564C5.4459 10.2564 5.7057 10.364 5.89724 10.5556L10.0717 14.73L18.1172 6.69168C18.2121 6.59684 18.3247 6.5216 18.4486 6.47027C18.5725 6.41894 18.7053 6.39253 18.8395 6.39253C18.9736 6.39253 19.1064 6.41894 19.2303 6.47027C19.3542 6.5216 19.4668 6.59684 19.5617 6.69168C19.6565 6.78652 19.7318 6.89912 19.7831 7.02304C19.8344 7.14696 19.8608 7.27977 19.8608 7.4139C19.8608 7.54803 19.8344 7.68085 19.7831 7.80477C19.7318 7.92869 19.6565 8.04128 19.5617 8.13612L19.5472 8.12168Z"
      fill="#FFFAFA"
    />
  </svg>
)

export default SuccessIcon
