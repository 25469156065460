import styled from 'app-view/styles/styled'

export const SettingsContainer = styled.div`
  flex-grow: 1;
  p {
    white-space: pre-line;
    margin: 0 0 16px;
  }
  h3 {
    margin: 0 0 16px;
  }
`
