import { WizardStepProps } from 'app-view/components/Wizard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'pages/AccountView/components/TextElements'
import { WalletCoinAmount } from 'app-view/components/CoinAmount'
import { Loader } from 'app-view/components/Spinner'
import { useWallet } from 'pages/WalletView/hooks/useWallet'
import { ModalButtonsContainer, ModalStepGreenButton } from 'app-view/components/Modal'
import { WalletStepMainContentWrapper } from 'pages/WalletView/steps/common/Styled'
import { WalletCurrencyReceive } from 'pages/WalletView/steps/common/WalletCurrencyReceive'

export const DepositAmount = ({ next }: WizardStepProps) => {
  const { t } = useTranslation('wallet')
  const [{ action, loading, error }] = useWallet()

  if (loading) return <Loader text={t('loading_action')} />

  return (
    <Form wallet={!Boolean(error)}>
      <WalletStepMainContentWrapper>
        <WalletCoinAmount label={t('amount_deposit')} isWallet />
        <WalletCurrencyReceive label={t('currency_receive')} />
      </WalletStepMainContentWrapper>
      <ModalButtonsContainer>
        <ModalStepGreenButton
          type="button"
          onClick={next}
          // @ts-ignore TODO: fix types
          disabled={!action.amount.amount.toJSNumber()}
        >
          {t('next_action')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
