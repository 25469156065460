import React, { useEffect, useState } from 'react'
import styled from 'app-view/styles/styled'
import { Input } from 'app-view/components/InputField'
import { useStore } from 'app-engine/store'
import { useTranslation } from 'react-i18next'
import { useKeyRecovery } from 'pages/DeviceKeysView/hooks/useKeyRecovery'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import { KeySelect, OptionKeySelect } from '../../components/KeyRecovery/KeySelect'
import { HeaderStep } from './HeaderStep'
import { FooterStep } from './FooterStep'
import { WizardStepProps } from 'app-view/components/Wizard'
import { globalCurrencies } from 'app-engine/library/currency-constants'
import { useDeepCompareEffect } from 'react-use'

const Container = styled.div`
  width: 100%;
  padding: 0 25px;
`
const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  .react-select__control {
    padding-right: 54%;
  }
`
const Text = styled.div`
  font-weight: 800;
  letter-spacing: -0.1px;
  color: ${(props) => props.theme.colors.darkGray};
  margin: 14px 0 7px;
`
const InputStyled = styled(Input)`
  position: absolute !important;
  z-index: 3;
  height: 55px;
  width: 54%;
  right: 0;
  border-radius: 0 9px 9px 0;
  border-left: 1px solid ${(props) => props.theme.colors.gray};
  && input {
    color: ${(props) => props.theme.colors.black};
  }
`

export const RecoveryIdType = ({ next }: WizardStepProps) => {
  const [state, { setState, storeTypeData }] = useKeyRecovery()
  const { t } = useTranslation('settings')
  const { userCountryData } = useStore()
  const [selectType, setSelectType] = useState<OptionKeySelect>(labels[1])
  const [selectCountry, setSelectCountry] = useState<OptionKeySelect>()
  const [inputValue, setInputValue] = useState('')
  const [successStep, setSuccessStep] = useState(false)
  const [countries, setCountries] = useState<OptionKeySelect[]>()

  const submit = () => {
    const country = selectCountry!.value.toLowerCase().replace(/\s/g, '-')
    storeTypeData({ type: selectType.value, typeValue: inputValue, country })
    next()
  }

  useDeepCompareEffect(() => {
    const keySelections = globalCurrencies.map((currency) => {
      const keySelection = {
        icon: currency.flag,
        label: currency.commonName,
        value: currency.commonName,
      }
      if (currency.commonName === userCountryData?.country_name?.toLowerCase()) {
        setSelectCountry(keySelection)
      }
      return keySelection
    })
    setCountries(keySelections)
  }, [userCountryData])

  useEffect(() => {
    if (countries.length) setState({ loading: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length])

  useEffect(() => {
    inputValue ? setSuccessStep(true) : setSuccessStep(false)
  }, [inputValue])

  if (state.loading) return <Loader />

  return (
    <>
      <HeaderStep />
      <Container>
        <Text>{t('id_type')}</Text>
        <ContainerSelect>
          <KeySelect options={labels} onChange={(data) => setSelectType(data)} value={selectType} />
          <InputStyled
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value.trim())}
            radius="soft"
            placeholder="xxxxxxxxxxxxxxxxxxx"
            fontWeight="700"
          />
        </ContainerSelect>
        <Text>{t('country')}</Text>
        <KeySelect
          options={countries}
          onChange={(data) => setSelectCountry(data)}
          value={selectCountry}
        />
      </Container>
      <FooterStep isSuccessStep={successStep} clickSuccess={submit} />
    </>
  )
}

const labels = [
  { label: 'License Number', value: 'license_number' },
  { label: 'Passport', value: 'passport' },
]
