import React from 'react'

const WaveIcon = (props: any) => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.58182 1.09091C7.14545 1.58182 6.32727 2.07273 5.45455 2.07273C4.63636 2.07273 4.14545 1.8 3.70909 1.58182C3.27273 1.41818 2.83636 1.14545 2.12727 1.14545C1.47273 1.14545 0.872727 1.52727 0.545455 1.85455L0 1.03636C0.490909 0.545455 1.25455 0 2.12727 0C2.94545 0 3.43636 0.327273 3.87273 0.490909C4.30909 0.654546 4.74545 0.981818 5.45455 0.981818C6.10909 0.981818 6.70909 0.545455 7.03636 0.218182L7.58182 1.09091ZM7.63636 3.76364C7.14545 4.25455 6.38182 4.8 5.50909 4.8C4.69091 4.8 4.2 4.52727 3.76364 4.30909C3.32727 4.14545 2.89091 3.81818 2.18182 3.81818C1.52727 3.81818 0.927273 4.25455 0.6 4.58182L0.0545454 3.70909C0.545455 3.21818 1.30909 2.67273 2.18182 2.67273C3 2.67273 3.49091 2.94545 3.92727 3.16364C4.36364 3.32727 4.8 3.6 5.50909 3.6C6.16364 3.6 6.76364 3.16364 7.09091 2.83636L7.63636 3.76364Z"
      fill="#787878"
    />
  </svg>
)

export default WaveIcon
