import React, { useEffect } from 'react'
import { ModalSuccess } from 'app-view/components/Modal'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useAdminActions } from 'pages/AdminView/hooks/useAdminActions'
import { useTranslation } from 'react-i18next'

export const Success = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['account', 'global'])
  const [{ action, account }, {}] = useAdminActions()

  const text =
    action === 'approve'
      ? t('account:successfully_approved', { account: account.account })
      : t('account:successfully_rejected', { account: account.account })

  useEffect(() => {
    const autoClose = setTimeout(() => {
      close()
    }, 3000)
    return () => {
      clearTimeout(autoClose)
    }
  }, [close])

  return <ModalSuccess text={text} />
}
