import React from 'react'
import { IndicatorIcon } from 'app-view/components/icons'

type ActivityIndicatorProps = {
  isOnline?: boolean
}

export const ActivityIndicator = ({ isOnline }: ActivityIndicatorProps) => {
  return <IndicatorIcon type={isOnline ? 'static-fill' : 'static-empty'} />
}
