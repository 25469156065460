import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalButtonsContainer } from 'app-view/components/Modal'
import { useStore } from 'app-engine/store/index'
import { BlackButton, GreenButton } from 'app-view/components/buttons'
import { PasteAddress } from 'pages/SwapView/steps/common/PasteAddress'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { ModalText } from 'app-view/components/Modal'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import { get_amount } from 'app-view/lib/utils'
import { getOrderWithFee, SwapOrder } from 'app-engine/store/swap-slice'

export const BuyAddress = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['swap'])
  const {
    account,
    setSwapAddress,
    submitSwap,
    coinSwap: { swap, loading },
  } = useStore()

  useEffect(() => {
    if (swap.asset) setSwapAddress(swap.asset === 'EOS' ? account : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swap.asset, account])

  const confirmBuy = async () => {
    const { success } = await submitSwap()
    if (success) next()
  }

  if (loading) return <Loader text={`${t('buy.loader')}...`} />

  const { asset, bitcash_currency, fee_percentage, bitcash_amount, wallet_address, asset_amount } =
    swap
  const { total_crypto } = getOrderWithFee(swap as SwapOrder)
  const totalToReceive = get_amount(`${bitcash_amount} ${bitcash_currency}`)
  const totalToSend = fixedAmountDecimals(total_crypto, asset)
  console.log('asset', asset)
  return (
    <>
      <ModalText>
        {"You're buying "}
        <span>{totalToSend}</span>
        {`
        with a ${fee_percentage}% fee for `}
        <span>{totalToReceive}</span>
        {'.\n\n'}
        {t('buy.address.text2')}
        <span>{asset}</span> {t('global:minus_network')}
      </ModalText>
      <br />
      <PasteAddress onPaste={setSwapAddress} />

      <ModalButtonsContainer>
        <BlackButton onClick={prev}>{t('back')}</BlackButton>
        <GreenButton disabled={wallet_address === '' || !asset_amount} onClick={confirmBuy}>
          {t('confirm')}
        </GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
