export default {
  slogan: 'Cripto Hecho Fácil.',
  login: 'Iniciar Sesión',
  signup: 'Registrarse',
  smartphone: 'Smartphone',
  device: 'Dispositivo',
  login_with: '<span>$t(login) con</span><span>{{label}}</span>',
  login_with_different_account:
    '<span>$t(login) con diferente</span><span>Cuenta o Dispositivo</span>',
  add_key: '<span>Añadir dispositivo a</span><span>Cuenta Registrada</span>',
  signup_with: '<span>$t(signup) con</span><span>{{label}}</span>',
  create_account: 'Crear Cuenta',
  more_options: 'Más Opciones',
  registered_modal_text: `¿Estás registrado en Bitcash App?
  
  `,
  auto_login_modal_text: `¿Desea usar la última cuenta ingresada?
  
  `,
  no_devices: `Error al cargar las credenciales del dispositivo.
  Intente ingresar su cuenta de nuevo para acceder.`,
  no: 'No',
  yes: 'Si',
  register: 'Registrar',
  keep_session: 'Mantener sesión',
  logout: 'Cerrar sesión',
  verification_complete: 'Verificación completada.',
  your_session_expired: 'La sesión se ha expirado. Por favor ingrese de nuevo.',
  session_expired: 'Sesión Expirada',
  your_session_expiring: 'Tu sesión esta expirando',
  session_about_expire: 'Tu sesión se cerrará\n en menos de 5 minutos.\n Quieres continuar?\n',
  Validating_credentials: 'Validando credenciales',
}
