import { ModalSuccess } from 'app-view/components/Modal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useWallet } from 'pages/WalletView/hooks/useWallet'
import { useEffectOnce } from 'react-use'
import { useWizard } from 'app-view/components/Wizard'

export const ActionSuccess = () => {
  const { t } = useTranslation(['wallet'])
  const [, { close }] = useWizard()
  const [{ action, transaction_data }] = useWallet()

  useEffectOnce(() => {
    const timeout = setTimeout(
      () => {
        close()

        clearTimeout(timeout)
      },
      transaction_data ? 20000 : 5000,
    )
  })

  return (
    <ModalSuccess
      text={t(action.type === 'deposit' ? 'deposit_success' : 'send_success')}
      redirect={`https://bloks.io/transaction/${transaction_data?.processed?.id || ''}`}
    />
  )
}
