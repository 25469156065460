import * as React from 'react'
import { SVGProps } from 'react'
const DuplicateIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 17 17" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8577 10.4063V4.3547C14.8577 3.51915 14.1804 2.8418 13.3448 2.8418H7.29318C6.45762 2.8418 5.78027 3.51915 5.78027 4.3547V10.4063C5.78027 11.2419 6.45762 11.9192 7.29318 11.9192H13.3448C14.1804 11.9192 14.8577 11.2419 14.8577 10.4063Z"
      strokeWidth="0.756453"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.78069 5.87109H4.26779C3.43223 5.87109 2.75488 6.54844 2.75488 7.384V13.433C2.75488 14.2686 3.43223 14.9459 4.26779 14.9459H4.2704L10.322 14.9355C11.1566 14.934 11.8323 14.2571 11.8323 13.4226V11.9227"
      strokeWidth="0.756453"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3193 5.11133V9.65004"
      strokeWidth="0.756453"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5889 7.38086H8.05015"
      strokeWidth="0.756453"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default DuplicateIcon
