import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store'
import { SlateInput } from 'pages/SwapView/steps/common/SlateInput'
import styled from 'app-view/styles/styled'
import { FiEyeOff, FiEye } from 'react-icons/fi'
import { scrollbar_css_access } from 'app-view/components/Styled'

interface PasteSlateProps {
  onPaste: (address: string) => void
  placeholder?: string
  showEye?: boolean
}

const ToggleSlateContainer = styled.span`
  position: absolute;
  left: 20px;
  width: calc(25% - 1.5rem);
  height: calc(100% - 18px);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
    background: white;
    color: #a4a4a4;
  }
`

const StyledSlateAddress = styled.div`
  width: 100%;
  padding: 0 1.25rem;
  position: relative;
  margin-bottom: 40px;

  textarea {
    padding: 9px 20px;
    height: max-content;

    ${scrollbar_css_access}

    &::placeholder {
      font-weight: 600;
    }
  }
`

export const PasteSlate = ({ onPaste, placeholder, showEye }: PasteSlateProps) => {
  const [trimAddress, setTrimAddress] = useState(false)
  const [addressError, setAddressError] = useState('')
  const { t } = useTranslation(['swap'])
  const { swap } = useStore.useCoinSwap()

  const onChangeHandler = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    onPaste(value)
    setAddressError(value === '' ? t('swap:general.errorInputEmpty') : '')
  }

  return (
    <StyledSlateAddress>
      <SlateInput
        onChange={onChangeHandler}
        onBlur={() => setTrimAddress(swap.wallet_address ? true : false)}
        onFocus={() => setTrimAddress(false)}
        value={
          trimAddress
            ? `…${swap.wallet_address?.substring(
                swap.wallet_address!.length - 4,
                swap.wallet_address!.length,
              )}`
            : swap.wallet_address || ''
        }
        placeholder={placeholder || t('swap:general.pressToPaste')}
        error={addressError}
      />
      {showEye && (
        <ToggleSlateContainer onClick={() => setTrimAddress((isFull) => !isFull)}>
          {trimAddress ? <FiEyeOff /> : <FiEye />}
        </ToggleSlateContainer>
      )}
    </StyledSlateAddress>
  )
}
