export type AmountOption = {
  label: string
  value: string
}

interface P2PAmountsOptions {
  [key: string]: AmountOption[]
}

// TODO: Get user language or current language...
export const getCurrencyLabel = (currency: string, amount: number, full?: number) =>
  new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency.replace('BIT', '').replace('GBR', 'GBP'),
    // If we face an amount that are on decimal point only, then we use full currency fraction instead
    maximumFractionDigits: full ? full : 2,
    currencyDisplay: 'narrowSymbol',
    // @ts-ignore ———> Tagged as Experimental property
    trailingZeroDisplay: 'stripIfInteger',
  })
    .format(amount)
    .replace(/(KES|UGX)/, '')
    .concat(currency.includes('KES') || currency.includes('UGX') ? '/=' : '')

const amounts: P2PAmountsOptions = {
  BITUSD: [
    { label: '5', value: '5.00 BITUSD' },
    { label: '10', value: '10.00 BITUSD' },
    { label: '15', value: '15.00 BITUSD' },
    { label: '25', value: '25.00 BITUSD' },
    { label: '50', value: '50.00 BITUSD' },
    { label: '100', value: '100.00 BITUSD' },
    { label: '500', value: '500.00 BITUSD' },
    { label: '2,000', value: '2000.00 BITUSD' },
  ],
  BITEUR: [
    { label: '5', value: '5.00 BITEUR' },
    { label: '10', value: '10.00 BITEUR' },
    { label: '15', value: '15.00 BITEUR' },
    { label: '25', value: '25.00 BITEUR' },
    { label: '50', value: '50.00 BITEUR' },
    { label: '100', value: '100.00 BITEUR' },
    { label: '500', value: '500.00 BITEUR' },
    { label: '2,000', value: '2000.00 BITEUR' },
  ],
  BITGBR: [
    { label: '5', value: '5.00 BITGBR' },
    { label: '10', value: '10.00 BITGBR' },
    { label: '15', value: '15.00 BITGBR' },
    { label: '25', value: '25.00 BITGBR' },
    { label: '50', value: '50.00 BITGBR' },
    { label: '100', value: '100.00 BITGBR' },
    { label: '500', value: '500.00 BITGBR' },
    { label: '2,000', value: '2000.00 BITGBR' },
  ],
  BITCAD: [
    { label: '5', value: '5.00 BITCAD' },
    { label: '10', value: '10.00 BITCAD' },
    { label: '15', value: '15.00 BITCAD' },
    { label: '25', value: '25.00 BITCAD' },
    { label: '50', value: '50.00 BITCAD' },
    { label: '100', value: '100.00 BITCAD' },
    { label: '500', value: '500.00 BITCAD' },
    { label: '2,000', value: '2000.00 BITCAD' },
  ],
  BITAUD: [
    { label: '5', value: '5.00 BITAUD' },
    { label: '10', value: '10.00 BITAUD' },
    { label: '15', value: '15.00 BITAUD' },
    { label: '25', value: '25.00 BITAUD' },
    { label: '50', value: '50.00 BITAUD' },
    { label: '100', value: '100.00 BITAUD' },
    { label: '500', value: '500.00 BITAUD' },
    { label: '2,000', value: '2000.00 BITAUD' },
  ],
  BITCNY: [
    { label: '35', value: '35.00 BITCNY' },
    { label: '70', value: '70.00 BITCNY' },
    { label: '100', value: '100.00 BITCNY' },
    { label: '170', value: '170.00 BITCNY' },
    { label: '350', value: '350.00 BITCNY' },
    { label: '690', value: '690.00 BITCNY' },
    { label: '3,500', value: '3500.00 BITCNY' },
    { label: '14,000', value: '14000.00 BITCNY' },
  ],
  BITCRC: [
    { label: '3,000', value: '3000.00 BITCRC' },
    { label: '6,000', value: '6000.00 BITCRC' },
    { label: '10,000', value: '10000.00 BITCRC' },
    { label: '15,000', value: '15000.00 BITCRC' },
    { label: '30,000', value: '30000.00 BITCRC' },
    { label: '60,000', value: '60000.00 BITCRC' },
    { label: '290,000', value: '290000.00 BITCRC' },
    { label: '1,200,000', value: '1200000.00 BITCRC' },
  ],
  BITARS: [
    { label: '850', value: '850.00 BITARS' },
    { label: '1,700', value: '1700.00 BITARS' },
    { label: '2,500', value: '2500.00 BITARS' },
    { label: '4,200', value: '4200.00 BITARS' },
    { label: '8,500', value: '8500.00 BITARS' },
    { label: '17,000', value: '17000.00 BITARS' },
    { label: '85,000', value: '85000.00 BITARS' },
    { label: '340,000', value: '340000.00 BITARS' },
  ],
  BITMXN: [
    { label: '100', value: '100.00 BITMXN' },
    { label: '200', value: '200.00 BITMXN' },
    { label: '300', value: '300.00 BITMXN' },
    { label: '500', value: '500.00 BITMXN' },
    { label: '1,000', value: '1000.00 BITMXN' },
    { label: '2,000', value: '2000.00 BITMXN' },
    { label: '9,800', value: '9800.00 BITMXN' },
    { label: '40,000', value: '40000.00 BITMXN' },
  ],
  BITCOP: [
    { label: '25,000', value: '25000.00 BITCOP' },
    { label: '50,000', value: '50000.00 BITCOP' },
    { label: '72,500', value: '72500.00 BITCOP' },
    { label: '120,000', value: '120000.00 BITCOP' },
    { label: '240,000', value: '240000.00 BITCOP' },
    { label: '480,000', value: '480000.00 BITCOP' },
    { label: '2,400,000', value: '2400000.00 BITCOP' },
    { label: '9,600,000', value: '9600000.00 BITCOP' },
  ],
  BITSOL: [
    { label: '20', value: '20.00 BITSOL' },
    { label: '40', value: '40.00 BITSOL' },
    { label: '60', value: '60.00 BITSOL' },
    { label: '100', value: '100.00 BITSOL' },
    { label: '200', value: '200.00 BITSOL' },
    { label: '380', value: '380.00 BITSOL' },
    { label: '2,000', value: '2000.00 BITSOL' },
    { label: '7,500', value: '7500.00 BITSOL' },
  ],
  BITCLP: [
    { label: '4,300', value: '4300.00 BITCLP' },
    { label: '9,000', value: '9000.00 BITCLP' },
    { label: '12,500', value: '12500.00 BITCLP' },
    { label: '20,000', value: '20000.00 BITCLP' },
    { label: '43,000', value: '43000.00 BITCLP' },
    { label: '86,000', value: '86000.00 BITCLP' },
    { label: '430,000', value: '430000.00 BITCLP' },
    { label: '1,700,000', value: '1700000.00 BITCLP' },
  ],
  BITTWD: [
    { label: '150', value: '150.00 BITTWD' },
    { label: '300', value: '300.00 BITTWD' },
    { label: '450', value: '450.00 BITTWD' },
    { label: '750', value: '750.00 BITTWD' },
    { label: '1,500', value: '1500.00 BITTWD' },
    { label: '3,000', value: '3000.00 BITTWD' },
    { label: '15,000', value: '15000.00 BITTWD' },
    { label: '60,000', value: '60000.00 BITTWD' },
  ],
  BITVND: [
    { label: '120,000', value: '120000.00 BITVND' },
    { label: '240,000', value: '240000.00 BITVND' },
    { label: '350,000', value: '350000.00 BITVND' },
    { label: '580,000', value: '580000.00 BITVND' },
    { label: '1,000,000', value: '1000000.00 BITVND' },
    { label: '2,300,000', value: '2300000.00 BITVND' },
    { label: '4,500,000', value: '4500000.00 BITVND' },
    { label: '7,000,000', value: '7000000.00 BITVND' },
  ],
  BITYEN: [
    { label: '680', value: '680.00 BITYEN' },
    { label: '1,300', value: '1300.00 BITYEN' },
    { label: '2,000', value: '2000.00 BITYEN' },
    { label: '3,500', value: '3500.00 BITYEN' },
    { label: '6,800', value: '6800.00 BITYEN' },
    { label: '13,600', value: '13600.00 BITYEN' },
    { label: '68,000', value: '68000.00 BITYEN' },
    { label: '270,000', value: '270000.00 BITYEN' },
  ],
  BITKRW: [
    { label: '6,500', value: '6500.00 BITKRW' },
    { label: '13,000', value: '13000.00 BITKRW' },
    { label: '20,000', value: '20000.00 BITKRW' },
    { label: '32,000', value: '32000.00 BITKRW' },
    { label: '65,000', value: '65000.00 BITKRW' },
    { label: '130,000', value: '130000.00 BITKRW' },
    { label: '650,000', value: '650000.00 BITKRW' },
    { label: '2,600,000', value: '2600000.00 BITKRW' },
  ],
  BITTHB: [
    { label: '150', value: '150.00 BITTHB' },
    { label: '350', value: '350.00 BITTHB' },
    { label: '500', value: '500.00 BITTHB' },
    { label: '800', value: '800.00 BITTHB' },
    { label: '1,800', value: '1800.00 BITTHB' },
    { label: '3,200', value: '3200.00 BITTHB' },
    { label: '17,000', value: '17000.00 BITTHB' },
    { label: '65,000', value: '65000.00 BITTHB' },
  ],
  // 1 USD ~ 464.50 NGN => 1 ~ 500
  BITNGN: [
    { label: '500', value: '500.00 BITNGN' },
    { label: '1,000', value: '1000.00 BITNGN' },
    { label: '2,000', value: '2000.00 BITNGN' },
    { label: '4,000', value: '4000.00 BITNGN' },
    { label: '10,000', value: '10000.00 BITNGN' },
    { label: '20,000', value: '20000.00 BITNGN' },
    { label: '200,000', value: '200000.00 BITNGN' },
    { label: '400,000', value: '400000.00 BITNGN' },
  ],
  // 1 USD ~ 132.45 KES => 1 ~ 100
  BITKES: [
    { label: '100', value: '100.00 BITKES' },
    { label: '200', value: '200.00 BITKES' },
    { label: '400', value: '400.00 BITKES' },
    { label: '800', value: '800.00 BITKES' },
    { label: '2,000', value: '2000.00 BITKES' },
    { label: '4,000', value: '4000.00 BITKES' },
    { label: '40,000', value: '40000.00 BITKES' },
    { label: '80,000', value: '80000.00 BITKES' },
  ],
  // 1 USD ~ 3,731.49 UGX => 1 * 3000 = 3000
  BITUGX: [
    { label: '3,000', value: '3000.00 BITUGX' },
    { label: '9,000', value: '9000.00 BITUGX' },
    { label: '18,000', value: '18000.00 BITUGX' },
    { label: '36,000', value: '36000.00 BITUGX' },
    { label: '90,000', value: '90000.00 BITUGX' },
    { label: '180,000', value: '180000.00 BITUGX' },
    { label: '360,000', value: '360000.00 BITUGX' },
    { label: '720,000', value: '720000.00 BITUGX' },
  ],
  // 1 USD ~ 4.4 MYR => 5 * 4 = 20
  BITMYR: [
    { label: '5', value: '5.00 BITMYR' },
    { label: '10', value: '10.00 BITMYR' },
    { label: '20', value: '20.00 BITMYR' },
    { label: '40', value: '40.00 BITMYR' },
    { label: '100', value: '100.00 BITMYR' },
    { label: '200', value: '200.00 BITMYR' },
    { label: '2,000', value: '2000.00 BITMYR' },
    { label: '4,000', value: '4000.00 BITMYR' },
  ],
  // 1 USD ~ 14796 IDR => 5 * 14000 = 70000
  BITIDR: [
    { label: '15,000', value: '15000.00 BITIDR' },
    { label: '40,000', value: '40000.00 BITIDR' },
    { label: '75,000', value: '75000.00 BITIDR' },
    { label: '150,000', value: '150000.00 BITIDR' },
    { label: '400,000', value: '400000.00 BITIDR' },
    { label: '750,000', value: '750000.00 BITIDR' },
    { label: '1,500,000', value: '1500000.00 BITIDR' },
    { label: '3,000,000', value: '3000000.00 BITIDR' },
  ],
}

// if (process.env.NODE_ENV === 'development') {
//   for (const [, value] of Object.entries(amounts)) {
//     console.table(value)
//   }
// }

export const getAmountOptions = (symbol: string) => amounts[symbol]
