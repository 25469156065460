import { Select } from 'app-view/components/Select'
import { SkeletonFilter } from 'app-view/components/Skeletons'
import {
  P2POffersSubscriptionVariables,
  useP2POffersSubscription,
} from 'app-engine/graphql/generated/bitcash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AdminSkeleton } from './AdminSkeleton'
import { P2POfferProvider, useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { Wizard, WizardProvider } from 'app-view/components/Wizard'
import { useNavigate, useLocation } from 'react-router-dom'
import { OfferList } from 'pages/P2PView/components/OfferList'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { ActionsContainer } from 'app-view/components/Styled'
import { config } from 'app-config'
import { useGetChainGraphTableRowsQuery } from 'app-engine/graphql/generated/chaingraph'

type FilterOption = {
  label: string
  value: P2POffersSubscriptionVariables
}

type ChaingraphP2POffersFilter = {
  scope: string
}

type ChaingraphP2POffers = ChaingraphP2POffersFilter & {
  data: any // ? TODO: type this
  contract: string
  chain: string
  table: string
  primary_key: string
}

const options: FilterOption[] = [
  {
    label: 'All',
    value: {
      where: {
        cancellation_approved_by: { _is_null: true },
        cancelled: { _eq: true },
        bitcashbank_id: { _is_null: false },
      },
    },
  },
  {
    label: 'Matched',
    value: {
      where: {
        cancellation_approved_by: { _is_null: true },
        cancelled: { _eq: true },
        matched: { _eq: true },
        bitcashbank_id: { _is_null: false },
      },
    },
  },
  {
    label: 'No Matched',
    value: {
      where: {
        cancellation_approved_by: { _is_null: true },
        cancelled: { _eq: true },
        matched: { _eq: false },
        bitcashbank_id: { _is_null: false },
      },
    },
  },
] as FilterOption[]

const AdminATMComponent: React.FC = () => {
  const { t } = useTranslation(['p2p', 'global'])

  const [{ paymentOptions }, { setMethodOptions, resetError }] = useP2POffer()
  const [variables, setVariables] = useState<P2POffersSubscriptionVariables>(options[0].value)
  const [notiOffer, setNotiOffer] = useState<P2POffer | null>(null)
  const navigate = useNavigate()
  const location = useLocation()

  const { data, loading, error } = useP2POffersSubscription({
    variables: variables!,
    skip: !variables,
    fetchPolicy: 'no-cache',
  })

  const {
    data: chaingraphData,
    loading: chaingraphLoading,
    error: chaingraphError,
  } = useGetChainGraphTableRowsQuery({
    variables: {
      where: {
        table: { _eq: 'p2porders' },
        contract: { _eq: config.contracts.bitcashBank },
        scope: {
          _in: data?.p2p_offers.map((offer) => offer.bitcashbank_id?.split('-')[0]) as string[],
        },
      },
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    setMethodOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const getSearchParameters = () => {
      const content_id = new URLSearchParams(location.search).get('content_id') ?? ''
      const from = new URLSearchParams(location.search).get('from') ?? ''

      if (from) {
        navigate({ search: '' }, { replace: true })
        return
      }

      const offer = data?.p2p_offers.find((offer) => offer.id === content_id) || null

      if (offer) setNotiOffer(offer)
    }

    getSearchParameters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, data?.p2p_offers])

  const filterOptions = useMemo<FilterOption[]>(() => {
    const filterPaymentOptions = paymentOptions.map((option) => ({
      label: option.label,
      value: {
        where: {
          method: { _eq: option.value },
        },
      },
    }))

    return [...options, ...filterPaymentOptions] as FilterOption[]
  }, [paymentOptions])

  const onFilterChange = useCallback(
    (option: FilterOption) => {
      const newVariables = option.value
      if (JSON.stringify(variables) !== JSON.stringify(newVariables)) setVariables(newVariables)
    },
    [variables],
  )

  console.log('📰 useP2POffersSubscription::BEFORE-filteredOffers 📰', data)

  const filteredOffers = useMemo(() => {
    if (!Boolean(data?.p2p_offers)) return []

    return data?.p2p_offers
      .filter((offer) =>
        chaingraphData?.table_rows.find((chaingraphOffer: ChaingraphP2POffers) => {
          const [account, bitcashBankId] = offer.bitcashbank_id
            ? offer.bitcashbank_id.split('-')
            : ['', '']
          const [, fee, quantity] = Object.keys(chaingraphOffer.data)
          const [feeAmount] = chaingraphOffer.data[fee].quantity.split(' ')
          const [quantityAmount] = chaingraphOffer.data[quantity].quantity.split(' ')
          const [offerAmount] = offer.amount.split(' ')

          // console.log('📰 useP2POffersSubscription::WHILE-filteredOffers 📰', {
          //   onchainOffer: chaingraphOffer,
          //   offchainOffer: offer,
          //   shouldShowOnCancelRequestList:
          //     chaingraphOffer.scope === account &&
          //     chaingraphOffer.primary_key === bitcashBankId &&
          //     ((Number(feeAmount) + Number(quantityAmount)) / 0.97).toFixed(2) === offerAmount,
          // })

          return (
            chaingraphOffer.scope === account &&
            chaingraphOffer.primary_key === bitcashBankId &&
            ((Number(feeAmount) + Number(quantityAmount)) / 0.97).toFixed(2) === offerAmount
          )
        }),
      )
      .filter(Boolean)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.p2p_offers, chaingraphData?.table_rows, chaingraphData?.table_rows.length])

  console.log('📰 useP2POffersSubscription::AFTER-filteredOffers 📰', filteredOffers)

  return (
    <>
      {paymentOptions.length === 0 ? (
        <section>
          <SkeletonFilter />
        </section>
      ) : (
        <>
          <section>
            <Select
              width="100%"
              label={t('filter_by_title')}
              initialPosition={0}
              options={filterOptions}
              onChange={onFilterChange}
              gray
            />
          </section>
          <ActionsContainer>
            {loading || (chaingraphLoading && <AdminSkeleton />)}
            {<OfferList offer={notiOffer} title="" offers={filteredOffers || []} />}
          </ActionsContainer>
        </>
      )}
      <Wizard
        error={error?.message || chaingraphError?.message || ''}
        resetError={resetError}
        loading={loading}
      />
    </>
  )
}

export const AdminATM = () => (
  // @ts-expect-error
  <WizardProvider>
    {/* @ts-expect-error */}
    <P2POfferProvider>
      <AdminATMComponent />
    </P2POfferProvider>
  </WizardProvider>
)
