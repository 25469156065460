import React, { useEffect, useState } from 'react'
import styled from 'app-view/styles/styled'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { isMobile } from 'app-engine/library/utils'
import {
  scrollbar_css,
  scrollbar_css_desktop,
  SlideBottomFixedCardWrapper,
} from 'app-view/components/Styled'
import { modal_overlay_animation_props, Overlay } from 'app-view/components/Modal'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { useStore } from 'app-engine/store'
import { Devices } from 'app-engine/graphql/generated/bitcash'
import { ModalHeader, ModalHeaderProperties } from 'app-view/components/Modal/Header'
import { eosCoreApi } from 'app-engine/library/eosio'
import { AccountDevices } from 'app-engine/store/account-slice'

export interface DeviceSelectorProps {
  onClose: () => void
  onDeviceSelection: (device: Devices) => Promise<void>
}

const DeviceListWrapper = styled.ul<{ alt?: boolean }>`
  list-style-type: none;
  padding: ${({ alt }) => (alt ? '0 24px' : 0)};
  max-width: 500px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 18px;
  max-height: ${isMobile ? 150 : 300}px;
  overflow-y: auto;
  ${isMobile ? scrollbar_css : scrollbar_css_desktop};
`
const DeviceListItem = styled.li`
  padding: 12px 24px;
  font-size: 0.875rem;
  font-weight: bold;
  cursor: pointer;
  transition: 0.14s all ease-in-out;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    background: #fafafa;
    border-color: #ccc1;
  }
  &:not(:last-of-type) {
    border-bottom: 2px solid #ccc2;
  }
`

const device_selector_animation = {
  initial: {
    y: '100%',
    opacity: 0.5,
  },
  exit: {
    y: '100%',
    opacity: 0.5,
  },
  animate: {
    y: '0%',
    opacity: 1.0,
  },
  transition: {
    duration: 0.24,
  },
}

const inline_p_style = { paddingInline: 16, marginBlock: '8px 24px' }

export const DeviceSelector: React.FC<DeviceSelectorProps> = ({ onClose, onDeviceSelection }) => {
  const [{ content }, globalModalActions] = useGlobalModal()
  const { t } = useTranslation(['account'])
  const { devices, account } = useStore()
  const [activeKeys, setActiveKeys] = useState<AccountDevices[]>(devices)

  useEffect(() => {
    const getAccountActiveKeys = async () => {
      const userPermissions = (await eosCoreApi.get_account(account)).getPermission('active')
      const keys = userPermissions.required_auth.keys.map(({ key }) => key.toString())
      const userKeys: AccountDevices[] = []

      devices.forEach((device) => {
        if (keys.includes(device.public_key)) {
          userKeys.push(device)
        }
      })

      setActiveKeys(userKeys)
    }

    getAccountActiveKeys()
  }, [devices, account])

  const DeviceListItems = activeKeys.map((device) => (
    <DeviceListItem
      key={`${device.device_name}_key`}
      onClick={() => onDeviceSelection(device as Devices)}
      tabIndex={0}
    >
      {device.device_name}
    </DeviceListItem>
  ))

  useEffectOnce(() => {
    if (!isMobile) {
      globalModalActions.open({
        content: () => (
          <>
            <p style={inline_p_style}>{t('select_device_description')}</p>
            <DeviceListWrapper aria-details={t('list_devices', { account: account })} alt>
              {DeviceListItems}
            </DeviceListWrapper>
          </>
        ),
        showClose: true,
        onClose: () => {
          globalModalActions.close()
          onClose()
        },
        iconType: 'TIP',
        title: t('select_device_title'),
      })
    }
  })
  const modal_header_props: ModalHeaderProperties = {
    loader: true,
    showClose: true,
    onClose,
    title: t('select_device_title'),
    iconType: 'TIP',
  }

  return isMobile ? (
    <>
      <Overlay onClick={onClose} {...modal_overlay_animation_props} />
      <SlideBottomFixedCardWrapper
        style={{ zIndex: content ? 1049 : undefined }}
        {...device_selector_animation}
      >
        <ModalHeader {...modal_header_props} />
        <p>{t('select_device_description')}</p>
        <DeviceListWrapper aria-details={t('list_devices', { account: account })}>
          {DeviceListItems}
        </DeviceListWrapper>
      </SlideBottomFixedCardWrapper>
    </>
  ) : (
    <></>
  )
}
