import { Action, AnyAction, PublicKey, SignedTransaction, Transaction } from '@greymass/eosio'
import { createPublic, createSignature } from '@greymass/webauthn'
import * as Sentry from '@sentry/react'
import { config } from 'app-config'
import { WebAuthError, getErrorName, toErrorWithMessage } from 'app-engine/library/errors'
import { v4 as uuidV4, parse as uuid_parse } from 'uuid'
import { eosCoreApi } from '.'
import { base64urlToBuffer } from './utils'

export async function signWithWebAuthn({
  public_key,
  actions,
  cred_id,
}: SignWithWebAuthNParams): SignWithWebAuthNResponse {
  console.log('signWithWebAuthn', JSON.stringify(actions))
  try {
    const pubKey = PublicKey.from(public_key)

    const info = await eosCoreApi.get_info()
    const header = info.getTransactionHeader()

    const { abi } = await eosCoreApi.get_abi(actions[0].account)
    const noAbiErr = `No ABI for ${actions[0].account}`
    if (!abi) throw new WebAuthError(noAbiErr)

    const transaction = Transaction.from({
      ...header,
      actions: actions.map((a) => Action.from(a, abi)),
    })

    const transactionDigest = transaction.signingDigest(info.chain_id)

    // response is not define in Assertion type for some reason
    const assertion: any = await navigator.credentials.get({
      mediation: 'required',
      publicKey: {
        // rpId: process.env.NODE_ENV === 'development' ? window.location.host : 'bitcash.org',
        timeout: 60000,
        // credentials we created before
        allowCredentials: [
          {
            id: base64urlToBuffer(cred_id),
            type: 'public-key',
          },
        ],
        // the transaction you want to sign
        challenge: transactionDigest.array.buffer,
      },
    })

    // TODO: there seems to be a type conflict
    // we need to fix it at some point to make sure it wont cause problems
    // for now will simply catch the error, it doesnt seems to be an issue
    // @ts-ignore
    const signature = createSignature(pubKey!, assertion.response)
    const signedTransaction = SignedTransaction.from({
      ...transaction,
      // @ts-ignore
      signatures: [signature],
    })

    return signedTransaction
  } catch (error) {
    const account = localStorage.getItem('bitcashapp-v6')
      ? JSON.parse(localStorage.getItem('bitcashapp-v6')).state.account
      : 'no_user'
    handleWebAuthNError({
      error,
      account,
      uiMessage: 'There was an error signing your transaction. Contact support.',
    })
    // this dummy makes return type consistent, this line is never executed.
    return new SignedTransaction({})
  }
}

export async function createWebAuthNKey(account: string): CreateWebAuthNKeyResponse {
  console.log(`createWebAuthNKey for ${account}`)
  checkWebAuthNSupport(account)
  try {
    const cred = (await navigator.credentials.create({
      publicKey: {
        // Your website domain name and display name
        // note that your website must be served over https or signatures will not be valid
        rp: { id: window.location.host, name: 'Bitcash App.' },
        // rp: { id: process.env.NODE_ENV === 'development' ? window.location.host : 'bitcash.org', name: 'Bitcash App.' },
        user: {
          // user.id must be unique for every request. Random bytes doesn't work,
          // though with uuid is allowing me to have multiple devices
          id: new Uint8Array(uuid_parse(uuidV4())),
          // username, usually the users account name but doesn't have to be
          name: account,
          // will be displayed when the user asks to sign
          displayName: account,
        },
        // don't change this, eosio will only work with -7 == EC2
        // ! Apple requires alg -7 for ES256 since iOS ~17.
        pubKeyCredParams: [
          {
            type: 'public-key',
            alg: -7,
          },
        ],
        timeout: 60000,
        // can be any bytes, more than 16 or some browser may complain
        challenge: new Uint8Array([
          0xbe, 0xef, 0xfa, 0xce, 0x22, 0xbe, 0xef, 0xfa, 0xce, 0xbe, 0xef, 0xfa, 0xce, 0xbe, 0xef,
          0xfa, 0xce, 0x22, 0xbe, 0xef, 0xfa, 0xce, 0xbe, 0xef, 0xfa, 0xce,
        ]).buffer,
        // attestation: 'direct', // Enabling Apple Anonymous Attestation
        attestation: config.environment === 'testing' ? 'indirect' : 'direct', // Enabling more flexibility for other device authenticators systems
      },
    })) as any // For some reason Credential.response is not in Credential type.
    const eosioPublicKey = createPublic(cred.response)
    console.log('webauthn credentials', {
      pubKey: eosioPublicKey.toString(),
      credRawId: cred.rawId,
    })
    return { pubKey: eosioPublicKey.toString(), credId: cred.id }
  } catch (error) {
    handleWebAuthNError({
      error,
      account,
      uiMessage: 'There was an error creating your private key. Contact support.',
    })
    // this dummy makes return type consistent, this line is never executed.
    return { pubKey: '', credId: '' }
  }
}

async function checkWebAuthNSupport(account: string) {
  // ref https://github.com/mylofi/webauthn-local-client/blob/main/src/walc.js#L73
  const supportsWebAuthn =
    typeof navigator != 'undefined' &&
    typeof navigator.credentials != 'undefined' &&
    typeof navigator.credentials.create != 'undefined' &&
    typeof navigator.credentials.get != 'undefined' &&
    typeof PublicKeyCredential != 'undefined' &&
    typeof PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable != 'undefined' &&
    // NOTE: top-level await (requires ES2022+)
    (await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable())

  if (!supportsWebAuthn) {
    // if we detect PublicKeyCredential class is not present in the browser we show this message to the user
    const error = new WebAuthError("Your browser doesn't support Web Authentication")
    // this funciton throws stopping execution and sends show uiMessage provided to the end user
    handleWebAuthNError({
      error,
      account,
      uiMessage: error.message,
    })
  }
}

function handleWebAuthNError({
  error,
  account,
  uiMessage,
}: {
  uiMessage: string
  error: unknown
  account: string
}) {
  const errName = getErrorName(error)
  /*  These errors are typically returned by the browser or authenticator when the WebAuthn API is used. Here's an overview of some common WebAuthn errors:

NotAllowedError: This error occurs when the request is not allowed, often due to user actions. For example, if the user doesn't interact with the authentication prompt, or if the request is made in a context that's not secure (non-HTTPS).

InvalidStateError: This is returned when an operation is performed in an incorrect state. For example, if you're trying to authenticate a credential that has not been registered.

ConstraintError: This error is triggered when a parameter is not acceptable. For example, if the PublicKeyCredentialCreationOptions object has invalid parameters.

SecurityError: Returned in cases of security violations, such as if the origin of the request is not the same as the origin of the document.

AbortError: This occurs if the operation is aborted, typically by the user or possibly by the authenticator itself.

TimeoutError: Occurs when the operation takes too long and times out. This can happen during user verification if the user doesn’t respond in a timely manner.

UnknownError: A generic error for unknown or unexpected issues.

UnsupportedError: This is returned when the browser or authenticator does not support a particular feature, such as a specific algorithm (like ES256).

EncodingError: Returned when there is a problem with the encoding of the authenticator data or the client data.
*/

  const err = toErrorWithMessage(error)
  // report to sentry the error for developers
  Sentry.captureEvent({
    ...err,
    tags: {
      errorName: errName,
    },
    user: {
      username: account,
    },
    // if error.name exists we use it in the message sent to sentry, else just the error.message
    message: `WebAuthNError::${errName ? errName + '::' + err.message : err.message}`,
  })
  // throw user friendly error for ui
  throw new WebAuthError(uiMessage)
}

export interface SignWithWebAuthNParams {
  public_key: string
  cred_id: string
  actions: AnyAction[]
}
export type SignWithWebAuthNResponse = Promise<SignedTransaction>

export type CreateWebAuthNKeyResponse = Promise<{
  pubKey: string
  credId: string
}>
