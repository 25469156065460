import React from 'react'
import styled from 'app-view/styles/styled'
import { WheelSelectOption } from './WheelSelect'
import {
  Icon,
  IconsContainer,
  SelectItemText,
  WheelSelectItem,
} from 'app-view/components/WheelSelect/styled'
import { getUSDTLabel } from './WheelSelect/utils'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export interface TAutocompleteOption extends WheelSelectOption {
  id: number
  name: string
}

interface CoinAutocompleteProps {
  options: Array<WheelSelectOption>
  handleOnSelect: (arg1: TAutocompleteOption) => void
}

const CoinAutocompleteContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  height: 100%;
  margin-left: -15px;

  & .line {
    opacity: 0;
  }
`

const CoinAutocomplete = ({ options, handleOnSelect }: CoinAutocompleteProps) => {
  // note: the id and name fields are mandatory
  const items: TAutocompleteOption[] = options.map((option, index) => ({
    ...option,
    id: index,
    name: option.label,
  }))

  const formatResult = (option: any) => (
    <WheelSelectItem key={`select-option-${option.label}`} icon={option.icon}>
      {option.icon && (
        <IconsContainer>
          <Icon coin={option.icon} />
        </IconsContainer>
      )}
      <SelectItemText>{getUSDTLabel(option.label)}</SelectItemText>
    </WheelSelectItem>
  )

  return (
    <CoinAutocompleteContainer>
      <ReactSearchAutocomplete
        items={items}
        onSelect={handleOnSelect}
        autoFocus // eslint-disable-line jsx-a11y/no-autofocus
        formatResult={formatResult}
        maxResults={4}
        styling={{
          height: '55px',
          border: '0px',
          boxShadow: 'none',
        }}
      />
    </CoinAutocompleteContainer>
  )
}

export default CoinAutocomplete
