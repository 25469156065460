export default {
  select_language: 'Pilih bahasa Anda',
  select_currency: 'Pilih mata uang',
  notifications: 'Notifikasi',
  thank_you: 'Terima Kasih!',
  primary_currency: 'Mata Uang Utama',
  allow_notifications: 'Izinkan Notifikasi',
  notifications_label:
    'Anda akan diberitahu ketika menerima pesan dari dukungan atau pengguna lain.',
  allow: 'Izinkan',
  block: 'Blokir',
  default_setting: 'Pengaturan default telah dikonfigurasi dengan sukses.',
  change_setting: 'Anda dapat mengubah pengaturan ini kapan saja di tab pengaturan.',
}
