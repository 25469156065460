import { Notifications } from 'app-engine/types/notifications'

export const createSubscription = (apolloClient, queryDocument, variables = {}) => {
  return apolloClient.subscribe({
    query: queryDocument,
    variables: variables,
    fetchPolicy: 'no-cache',
  })
}

export const processNotifications = async (rawNotifications, get): Promise<Notifications[]> => {
  const swapData =
    (await get().getNotificationContentSwap(
      rawNotifications.map((n) => n.content_id).filter(Boolean),
    )) || [] // Safeguard in case data is undefined
  const p2pData =
    (await get().getNotificationContentP2p(
      rawNotifications.map((n) => n.content_id).filter(Boolean),
    )) || [] // Safeguard in case data is undefined
  if (!swapData || !p2pData) {
    console.error('Failed to fetch notification content data.')
    return []
  }
  const dataNoti = (id: string) => {
    const p2pDataItem = p2pData?.p2p_offers?.find((sd) => sd.id === id)
    const swapDataItem = swapData?.swap_orders?.find((sd) => sd.id === id)
    return p2pDataItem ?? swapDataItem ?? {}
  }
  return rawNotifications
    .map((notification) => ({
      data: dataNoti(notification.content_id) ?? {},
      ...notification,
    }))
    .sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
}

export const fetchData = async (apolloClient, queryDocument, variables) => {
  try {
    const { data, errors } = await apolloClient.query({
      query: queryDocument,
      variables: variables,
    })
    if (errors && errors.length > 0) {
      throw new Error(`Error fetching data: ${errors[0].message}`)
    } else if (!data) {
      throw new Error('Data is undefined')
    }
    return data
  } catch (error) {
    console.log('Error in fetching data: ', error.message)
    return undefined
  }
}

export const getNotificationContentData = async (apolloClient, queryDocument, content_id_array) => {
  try {
    const response = await apolloClient.query({
      query: queryDocument,
      variables: { where: { id: { _in: content_id_array } } },
    })
    if (response.errors) {
      console.error('GraphQL errors:', response.errors)
      throw new Error(response.errors.map((e) => e.message).join(', '))
    }
    if (!response.data) {
      throw new Error('No data returned by the query')
    }
    return response.data
  } catch (error) {
    console.error('Error fetching notification content data:', error)
    throw error
  }
}

export const subscribeToData = (apolloClient, queryDocument, updateStateCallback) => {
  const subscription = apolloClient
    .subscribe({
      query: queryDocument,
      fetchPolicy: 'no-cache',
    })
    .subscribe({
      next({ data, errors }) {
        if (errors) {
          console.log('Subscription error:', errors[0].message)
          return
        }
        updateStateCallback(data)
      },
      error(err) {
        console.log('Subscription failed:', err)
      },
    })

  return subscription // Return the subscription
}

export const filterReadUnreadNotifications = (notifications) => {
  const unread = notifications.filter(
    (row) => !Boolean(row.system_notification_read_statuses.length),
  )
  const read = notifications.filter((row) => Boolean(row.system_notification_read_statuses.length))
  return { unread, read }
}
