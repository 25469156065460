import { Theme } from 'app-config/theme'
import { NumericFormat } from 'react-number-format'
import styled from 'app-view/styles/styled'
// TODO: Replace them. Package will be rm
import { Input as InputSemanticUI } from 'semantic-ui-react'
import { scrollbar_css_access } from 'app-view/components/Styled'

type InputSectionProps = Partial<Theme> & {
  error?: boolean
  inputSize?: 'sm' | 'md' | 'lg'
  radius?: 'sharp' | 'soft' | 'round'
  border?: 'grey' | 'light-grey' | 'white'
}
type InputTextareaProps = Partial<Theme> &
  InputSectionProps & {
    fullradius?: number
    paddingspace?: 'none' | 'thigh' | 'medium' | 'wide'
    fontWeight: '400' | '500' | '600' | '700'
    align?: 'left' | 'center'
  }
type HintErrorProps = Partial<Theme> & {
  error?: boolean
  align: 'left' | 'center'
}
type InputWrapperProps = Partial<Theme> & {
  px?: number
  my?: number
  mt?: number | string
  mb?: number
}
type LabelProps = Partial<Theme> & {
  size?: 'sm' | 'md' | 'lg'
  pl?: number
  mb?: number
}

export const InputSection = styled.div<InputSectionProps>`
  border: 1px solid
    ${({ error = false, border }) => {
      if (error) return '#F34040'

      switch (border) {
        case 'grey':
          return '#ccc'
        case 'light-grey':
          return '#e4e4e4'
        case 'white':
          return '#fff'
        default:
          return '#fff'
      }
    }};
  height: ${({ inputSize }) => {
    switch (inputSize) {
      case 'sm':
        return '48px'
      case 'md':
        return '57px'
      case 'lg':
        return '60px'
      default:
        return '57px'
    }
  }};
  border-radius: ${({ radius }) => {
    switch (radius) {
      case 'sharp':
        return '4px'
      case 'soft':
        return '10px'
      case 'round':
        return '16px'
      default:
        return '10px'
    }
  }};
  padding: 0;
  width: 100%;
  max-width: 330px;
  font-weight: bolder;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;

  span.indicator {
    position: absolute;
    top: 1rem;
    left: 2.25rem;
    height: 64px;
    width: 32px;
    z-index: 10;

    > svg {
      height: 100%;
    }
  }
`
export const Input = styled(InputSemanticUI)<InputTextareaProps>`
  width: 100%;
  height: 100%;

  &.ui.input.error > input {
    border-color: #f34040 !important;
  }
  &.ui.input > input {
    font-family: Mulish, sans-serif;
    transition: all 0.14s ease-in-out;
    width: ${({ fullradius = 0 }) => (fullradius ? '100%' : '60%')};
    border: 1px solid transparent !important;
    position: relative;
    background-color: transparent;
    height: 100%;
    border-radius: ${({ radius, fullradius = 0 }) => {
      switch (radius) {
        case 'sharp':
          return !fullradius ? '0 4px 4px 0' : '4px'
        case 'soft':
          return !fullradius ? '0 10px 10px 0' : '10px'
        case 'round':
          return !fullradius ? '0 16px 16px 0' : '16px'
        default:
          return !fullradius ? '0 10px 10px 0' : '10px'
      }
    }} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.66;
    font-weight: ${({ fontWeight = '400' }) => fontWeight};
    text-align: center;
    z-index: 0;
    padding: ${({ paddingspace }) => {
      switch (paddingspace) {
        case 'none':
          return '0'
        case 'thigh':
          return '4px 12px'
        case 'medium':
          return '14px 24px'
        case 'wide':
          return '14px 45px'
        default:
          return '14px 18px'
      }
    }};
    border-radius: ${({ radius }) => {
      switch (radius) {
        case 'sharp':
          return '4px'
        case 'soft':
          return '10px'
        case 'round':
          return '16px'
        default:
          return '10px'
      }
    }};
    text-align: ${({ align = 'left' }) => (align === 'center' ? 'center' : 'left')};
    outline: 1px 1px;
    outline-color: #cccccc;
    user-select: auto;

    &::placeholder {
      font-weight: 400;
      color: #a4a4a4;
      text-align: left;
    }

    &:focus-visible {
      outline-color: #a4a4a4;
    }

    &::placeholder {
      user-select: all;
      pointer-events: all;
      color: #a4a4a4 !important;
      font-weight: 400;
      font-size: 1rem;
    }
    &:read-only {
      background-color: #ccc1;
      color: #aaa;
      cursor: not-allowed;
      user-select: none;
    }
  }
`
export const Textarea = styled.textarea<InputTextareaProps>`
  width: ${({ fullradius = 0 }) => (fullradius ? '100%' : '60%')};
  border: 1px solid transparent !important;
  position: relative;
  background-color: transparent;
  padding: ${({ paddingspace }) => (paddingspace === 'wide' ? '16px 24px' : 0)};
  min-height: 100%;
  height: auto;
  max-height: 100%;
  border-radius: ${({ fullradius = 0 }) => (fullradius ? '16px' : '0 16px 16px 0')} !important;
  margin: 0;

  ${scrollbar_css_access}

  &::placeholder {
    user-select: all;
    pointer-events: all;
    color: #a4a4a4 !important;
    font-weight: 400;
    font-size: 1rem;
  }
`
export const HintError = styled.span<HintErrorProps>`
  position: absolute;
  bottom: -0.5rem;
  left: 0.5rem;
  line-height: 1;
  font-size: 0.75rem;
  width: 100%;
  font-weight: 500;
  color: ${({ error }) => (error ? '#f34040' : '#5f5f5f')};
  text-align: ${({ align = 'left' }) => (align === 'center' ? 'center' : 'left')};
`

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  box-shadow: none !important;
  background-color: transparent !important;
  width: 100%;
  max-width: 330px;
  padding: 11px ${({ px }) => px ?? 0}px;
  margin: ${({ my = 0 }) => my}px auto;
  ${({ mt }) => (mt ? `margin-top: ${typeof mt === 'string' ? mt : `${mt}px`}` : '')};
  ${({ mb }) => (mb ? `margin-bottom: ${typeof mb === 'string' ? mb : `${mb}px`}` : '')};
`

export const Label = styled.label<LabelProps>`
  line-height: 1.5;
  padding-left: ${({ pl }) => pl ?? 14}px;
  margin-bottom: ${({ mb }) => mb ?? 8}px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;

  ${({ size }) => {
    switch (size) {
      case 'sm':
        return `
          font-size: 0.875rem;
          font-weight: 400;
        `
      case 'md':
        return `
          font-size: 1rem;
          font-weight: 600;
        `
      case 'lg':
        return `
          font-size: 1.125rem;
          font-weight: 700;
        `
      default:
        return `
          font-size: 0.875rem;
          font-weight: 400;
        `
    }
  }}

  span {
    height: 100%;
    position: relative;

    svg {
      transform: rotateZ(-90deg);
      position: relative;
      top: 0.35rem;
      left: 0.75rem;
      width: 4px;
      padding: 0;
      fill: #29e0ad;
    }
  }
`
export const CenteredLabel = styled(Label)`
  width: 100%;
  text-align: center;
`

export const Fieldset = styled.fieldset`
  border: none;
`
export const Legend = styled.p``
export const InputNumber = styled(NumericFormat)`
  padding: 0 !important;
`
