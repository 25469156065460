import { useReducer } from 'react'
import get from 'lodash.get'
import { WheelSelectOption } from '.'
import { WheelSelectError } from 'app-engine/library/errors'

export interface WheelSelectActionType {
  type: 'INIT' | 'START' | 'MOVE' | 'STOP' | 'SELECT' | 'RESET'
  payload?: any
}

interface WheelSelectState {
  selected?: number
  middleIndex: number
  pressing: boolean
  interacted: boolean
  initialY: number
  previousY: number
  currentY: number
  optionsTop: number
  direction: string | null
  options: Array<WheelSelectOption>
}

const wheelSelectReducer = (state: WheelSelectState, action: WheelSelectActionType) => {
  switch (action.type) {
    case 'INIT':
      if (!action.payload) return state
      // selected
      const ref1 = action.payload.ref
      const offsetHeight1 = get(ref1, 'current.offsetHeight') / state.options.length
      const fontSize = getComputedStyle(get(ref1, 'current.parentElement')).fontSize
      const emValue = fontSize ? parseFloat(fontSize) : 1
      const optionsTop1 = -(offsetHeight1 * state.middleIndex) + emValue
      return { ...state, optionsTop: optionsTop1 }

    case 'RESET': {
      if (!action.payload) return state
      const setState = action.payload
      const ref1 = action.payload.ref
      const offsetHeight1 = get(ref1, 'current.offsetHeight') / setState.options.length
      const fontSize = getComputedStyle(get(ref1, 'current.parentElement')).fontSize
      const emValue = fontSize ? parseFloat(fontSize) : 1
      const optionsTop1 = -(offsetHeight1 * setState.middleIndex) + emValue
      return { ...action.payload, optionsTop: optionsTop1 }
    }

    case 'START':
      if (!action.payload) return state
      const { clientY } = action.payload
      return {
        ...state,
        pressing: true,
        interacted: false,
        initialY: clientY,
        currentY: clientY,
        previousY: clientY,
      }

    case 'STOP':
      if (!action.payload) return state

      // trigger inertia based on velocity

      // detect selected option and center it
      const ref2 = action.payload.ref
      const fontSize2 = getComputedStyle(get(ref2, 'current.parentElement')).fontSize
      const emValue2 = fontSize2 ? parseFloat(fontSize2) : 1
      const optionHeight2 = emValue2 * action.payload.x

      let selectedIndex
      if (state.optionsTop <= 0) {
        selectedIndex = Math.round((Math.abs(state.optionsTop) + emValue2) / optionHeight2)
      } else if (state.optionsTop > emValue2 / 2) {
        selectedIndex = 0
      } else {
        selectedIndex = 1
      }

      if (selectedIndex < 0) {
        selectedIndex = 0
      } else if (selectedIndex >= state.options.length) {
        selectedIndex = state.options.length - 1
      }

      const selectedOptionsTop = -(selectedIndex * optionHeight2) + emValue2

      return {
        ...state,
        pressing: false,
        interacted: true,
        initialY: 0,
        currentY: 0,
        previousY: 0,
        selected: selectedIndex,
        optionsTop: selectedOptionsTop,
      }

    case 'MOVE':
      if (!state.pressing && action.payload) return state

      // calculate direction
      const previousY = state.currentY
      const currentY = action.payload.clientY
      if (previousY === currentY) return state
      const direction = previousY < currentY ? 'down' : 'up'
      // same point
      if (!direction) return state

      // calculate pixels to move
      const pixels = direction === 'down' ? previousY - currentY : currentY - previousY
      const optionsTop =
        direction === 'down' ? state.optionsTop - pixels : state.optionsTop + pixels

      // boundaries
      const ref = action.payload.ref
      const fontSize3 = getComputedStyle(get(ref, 'current.parentElement')).fontSize
      const emValue3 = fontSize3 ? parseFloat(fontSize3) : 1
      const offsetHeight = get(ref, 'current.offsetHeight')
      const optionHeight = emValue3 * 1.25
      const maxDown = emValue3
      const maxUp = offsetHeight - emValue3 - optionHeight

      if (direction === 'up' && Math.abs(optionsTop) > maxUp) {
        return { ...state, previousY, optionsTop: -maxUp, currentY, direction }
      }

      if (direction === 'down' && optionsTop > maxDown) {
        return { ...state, previousY, optionsTop: maxDown, currentY, direction }
      }

      return { ...state, previousY, optionsTop, currentY, direction }

    case 'SELECT':
      return { ...state, selected: action.payload.selected }
    default:
      throw new WheelSelectError(action.type)
  }
}

export const useWheelSelect = (defaults: any) => useReducer(wheelSelectReducer, defaults)
