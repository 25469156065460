import React, { useState } from 'react'
import { BalanceBox } from 'app-view/components/BalanceBox'
import { CardPosition } from 'app-view/components/CardPosition'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from 'app-view/components/Tabs'
import { BigBlackButton, BigButton2, ButtonsGrid } from 'app-view/components/buttons'
import {
  MainContentContainer,
  ScrollableFlexBoxArea,
  HeaderContainer,
  OnramperGrid,
} from 'app-view/components/Styled'
import { Wizard } from '../../app-view/components/Wizard/index'
import { useBankWallet, BankWalletProvider, BankActions } from 'pages/BankView/hooks/useBankWallet'
import { WizardProvider, useWizard } from 'app-view/components/Wizard'
import { ExchangeCoinsIcon } from 'app-view/components/icons'
import sortBy from 'lodash.sortby'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import { useProfile } from 'app-view/hooks/use-profile.hook'

const BankViewComponent = () => {
  const { t } = useTranslation(['wallet'])
  const [{ error }, { resetError, startNewAction }] = useBankWallet()
  const userPositionsHook = useUserPositions()
  const user_positions_list = userPositionsHook.user_positions_list
  const [{ preferences }, {}] = useProfile()
  const [state, { start }] = useWizard()
  const normalized_pref_currency = preferences.currency.toLowerCase().replace('bit', '')
  const default_tab = {
    label: normalized_pref_currency.toUpperCase(),
    value: normalized_pref_currency,
  }
  const [currentTab, setCurrentTab] = useState(default_tab)

  const newActionHandler = (type: BankActions) => {
    const new_steps = startNewAction(type)
    start(new_steps)
  }

  const tabs: Tab[] = [
    default_tab,
    {
      label: t('tab_crypto_title'),
      value: 'crypto',
    },
  ]

  const isExchange = state.step === 'bank-exchange-amount'
  const title = isExchange ? t('exchange') : ''
  const iconType = isExchange ? 'EXCHANGE' : ''
  const sorted_positions = sortBy(user_positions_list, 'symbol_code')

  return (
    <ScrollableFlexBoxArea>
      <BalanceBox total />

      <HeaderContainer pb={26}>
        <ButtonsGrid>
          <BigBlackButton onClick={() => newActionHandler('withdrawal')}>
            {t('withdrawal')}
          </BigBlackButton>
          <BigBlackButton onClick={() => newActionHandler('send')}>{t('send')}</BigBlackButton>
        </ButtonsGrid>
        <OnramperGrid>
          <BigButton2 variant="green" onClick={() => newActionHandler('exchange')} gap={10}>
            <ExchangeCoinsIcon width={33} height={33} /> {t('exchange')}
          </BigButton2>
        </OnramperGrid>
      </HeaderContainer>

      <MainContentContainer>
        <Tabs
          tabs={tabs}
          currentTab={currentTab.value}
          setCurrentTab={setCurrentTab}
          style={{ paddingTop: 0 }}
        />
        {sorted_positions.map((position) => (
          <CardPosition position={position} tab={currentTab.value} key={position.symbol_code} />
        ))}
      </MainContentContainer>
      <Wizard error={error} resetError={resetError} title={title} iconType={iconType} />
    </ScrollableFlexBoxArea>
  )
}

export const BankView = () => (
  // @ts-expect-error
  <WizardProvider>
    {/* @ts-ignore */}
    <BankWalletProvider>
      <BankViewComponent />
    </BankWalletProvider>
  </WizardProvider>
)
