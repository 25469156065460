import React from 'react'
import { BlackButton } from 'app-view/components/buttons'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store'
import { useWizard } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import { ModalText, ModalButtonsContainer } from 'app-view/components/Modal'
import { buyerCryptoAmountLessP2PFee, formattedDate, getAmountFee } from 'app-view/lib/utils'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const BuyCancelledOffer: React.FC = () => {
  const { t, i18n } = useTranslation(['p2p'])
  const account = useStore.useAccount()
  const [, { close }] = useWizard()
  const [{ offer, loading }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()

  if (loading) return <Loader text={`${t('creating_buy_offer')} ...`} />

  const onClose = () => {
    close()
  }

  const seller = `<span>${offer.sellerLabel}</span> ${t('no_longer_wants_to_sell')}`
  const noMatchText =
    offer.cancelled_by === account
      ? t('You have cancelled your offer to buy')
      : t('Offer has been cancelled to buy')

  return (
    <>
      <ModalText
        dangerouslySetInnerHTML={{
          __html: t('buy_cancelled_offer', {
            sorry: offer.matched || offer.cancelled_by !== account ? `${t('we_are_sorry')}!` : '',
            sellerInfo: offer.matched ? seller : noMatchText,
            amount: buyerCryptoAmountLessP2PFee(offer.amount),
            cryptoFee: getAmountFee(offer.amount),
            method: methodLabel(offer.method),
            methodDetails: isMethodDetails ? sellerMethodDetailsLabelData : '',
            date: formattedDate(i18n.language, offer.updated_at || offer.created_at),
          }),
        }}
      />
      <ModalButtonsContainer>
        <BlackButton onClick={onClose}>{t('ok')}</BlackButton>
      </ModalButtonsContainer>
    </>
  )
}
