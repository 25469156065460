import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { checkIfAccountIsRegistered, checkPendingRegistration } from 'app-engine/services'
import { checkAccountExt } from 'pages/AccountView/utils'
import { useStore } from 'app-engine/store/index'

interface AccountValidationState {
  hint: string
  readyToLogin?: boolean
  loading?: boolean
}
interface AccountValidationActions {
  validateUserAccount: (form: UseFormReturn<any>, isRegister?: boolean) => Promise<boolean>
  updateState: (new_state: Partial<AccountValidationState>) => void
}

export const useAccountValidation = (): [AccountValidationState, AccountValidationActions] => {
  const [loginState, setLoginState] = useSetState<AccountValidationState>({
    readyToLogin: false,
    loading: false,
    hint: '',
  })
  const setAccount = useStore.useSetAccount()
  const { t } = useTranslation(['account'])

  const updateState = (new_state: Partial<AccountValidationState>) => setLoginState(new_state)

  const validateUserAccount = async (form: UseFormReturn<any>, isRegister = false) => {
    const account = form.getValues('account')
    const formatted_account = checkAccountExt(account)
    const exists = isRegister
      ? await Promise.all<boolean>([
          checkIfAccountIsRegistered(formatted_account),
          checkPendingRegistration(formatted_account),
        ]).then((result) => result.includes(true))
      : await checkIfAccountIsRegistered(formatted_account)
    if ((!exists && !isRegister) || (exists && isRegister)) {
      form.setError('account', {
        message: !isRegister
          ? t('account_user_account_not_registered')
          : t('create_account_user_account_error_unavailable'),
      })
    }

    if (exists && !isRegister) {
      try {
        await setAccount(formatted_account)
      } catch (error) {
        form.setError('account', {
          message: (error as Error).message,
        })
      }
    }

    setLoginState({
      readyToLogin: exists,
      loading: false,
      hint: exists ? t('account_user_account_registered') : t('account_user_account_available'),
    })

    return exists
  }

  return [loginState, { validateUserAccount, updateState }]
}
