import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import theme from 'app-config/theme'
import { ToolTipOptionsProps, Tooltip } from 'app-view/components/Tooltip'
import { SimpleCoinIcon, P2PNotificationIcon, SettingButtonIcon } from 'app-view/components/icons'
import { useBitcashNotificationDeleteMutation } from 'app-engine/graphql/generated/bitcash'
import { config } from 'app-config'
import {
  DateText,
  IconContainer,
  MSGContainer,
  NotificationItem,
  NotificationItemLink,
  SettingButtonContainer,
  StrongText,
  TextContainer,
} from './NotificationItemStyles'
import { DataOffer, Notifications } from 'app-engine/types/notifications'
import { bitcashNotificationReadMutation } from 'app-engine/library/notifications'
import { EmptyCheckIcon, RemoveIcon } from 'app-view/components/icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export type ReadProp = {
  read?: boolean
}

const { colors } = theme

const Notification: React.FC<{
  item: Notifications
  className?: string
  pos?: number
  bottomPos?: number
}> = ({ item, className, pos, bottomPos }) => {
  const { t, i18n } = useTranslation(['notifications', 'p2p'])
  const { created_at, id, read, type, data, content_id, from } = item
  const orderType = data?.order_type || data?.type
  const { order_status, asset, completed } = data as DataOffer
  // NOTE: Sometimes doesn't return a value hence, display the first machine date => 1/1/1968. The function logic might be suffering race conditions/hoisting issues
  // const formattedTimeAgo = useFormattedTimeAgo(i18n.language, created_at) // TODO: Andler https://momentjs.com/docs/#/i18n/
  const formattedTimeAgo = useMemo(() => {
    moment.locale(i18n.language)
    return moment(created_at).fromNow()
  }, [i18n.language, created_at])
  const [openToolTip, setOpenToolTip] = useState(false)
  const [bitcashNotificationDeleteMutation] = useBitcashNotificationDeleteMutation()
  const navigate = useNavigate()

  const onToolTipOptionsClick = async (option: string) => {
    try {
      if (option === 'remove') {
        const { errors } = await bitcashNotificationDeleteMutation({
          variables: {
            id,
          },
        })
        if (errors) throw new Error(errors[0].message)
      } else {
        const { errors } = await bitcashNotificationReadMutation(id)

        if (errors) throw new Error(errors[0].message)
      }
    } catch (error) {
      console.log('Mutation error', (error as Error).message)
    } finally {
      setOpenToolTip(false)
    }
  }

  const toggleToolTip = () => setOpenToolTip(!openToolTip)

  const toolTipOptions = [
    !read && {
      text: 'notifications:marked_read',
      onClick: (e: MouseEvent) => {
        e.preventDefault()
        onToolTipOptionsClick('marked_read')
      },
      icon: <EmptyCheckIcon />,
    },
    {
      text: 'notifications:remove',
      remove: true,
      onClick: (e: MouseEvent) => {
        e.preventDefault()
        onToolTipOptionsClick('remove')
      },
      icon: <RemoveIcon />,
    },
  ].filter(Boolean) as ToolTipOptionsProps[]

  const isP2P = item.type.includes('P2P')
  const p2pRedirectionLink =
    isP2P && (item.data?.completed || item.data?.cancelled) ? 'history?tab=p2p' : 'p2p'
  const redirectionLink = isP2P ? p2pRedirectionLink : 'history?tab=swap'
  const redirectTo = config.bitcashAdmin === item.to ? 'admin' : redirectionLink

  const isTrustMessage = !content_id && type.includes('MSG')
  const p2pTranslateMessage = (() => {
    if (isTrustMessage) {
      return {
        tOptions: {
          from,
        },
        i18nKey: 'p2p_trust_message',
      }
    }
    return type.includes('MSG')
      ? {
          i18nKey: 'p2p_message',
          tOptions: {
            account: from,
            order_type: t(`p2p:${orderType}`),
          },
        }
      : {
          i18nKey: 'p2p_status',
          tOptions: {
            account: from,
            order_type: t(`p2p:${orderType}`),
            status: completed ? t('completed') : t('cancelled'),
          },
        }
  })()

  const redirect = getRedirectLink({
    from,
    isTrustMessage,
    contentId: content_id,
    redirectTo: redirectTo,
  })

  return (
    <NotificationItem read={read} className={className} as="li" pos={pos} bottomPos={bottomPos}>
      <NotificationItemLink
        to={redirect}
        onClick={(e: any) => {
          if (e.target.closest('#notification-settings') || openToolTip) {
            e.preventDefault()
            return
          }
          if (!read) onToolTipOptionsClick('marked_read')
          navigate(redirect) // Using navigate for redirection
        }}
      >
        <IconContainer>
          {type.includes('COIN') ? (
            <SimpleCoinIcon width="42px" height="42px" />
          ) : (
            <P2PNotificationIcon width="42px" height="42px" />
          )}
        </IconContainer>
        <TextContainer>
          <MSGContainer>
            {type.includes('COIN') && (
              <Trans
                i18nKey={type.includes('MSG') ? 'coin_message' : 'coin_status'}
                t={t}
                tOptions={
                  type.includes('MSG')
                    ? {
                        asset,
                        order_type: t(`p2p:${orderType}`),
                      }
                    : {
                        asset,
                        order_type: t(`p2p:${orderType}`),
                        status: order_status === 'filled' ? t('completed') : t('cancelled'),
                      }
                }
                components={[<StrongText />]}
              />
            )}
            {type.includes('P2P') && (
              <Trans
                i18nKey={p2pTranslateMessage.i18nKey}
                t={t}
                tOptions={p2pTranslateMessage.tOptions}
                components={[<StrongText />]}
              />
            )}
          </MSGContainer>
          <DateText color={colors?.darkGray}>{formattedTimeAgo}</DateText>
        </TextContainer>
        <SettingButtonContainer id="notification-settings" onClick={toggleToolTip}>
          <SettingButtonIcon style={{ height: 16 }} />
        </SettingButtonContainer>
        <Tooltip
          id={`notification-item-tooltip-${id}`}
          open={openToolTip}
          content={toolTipOptions}
          setOpen={setOpenToolTip}
        />
      </NotificationItemLink>
    </NotificationItem>
  )
}

type GetRedirectLinkProps = {
  redirectTo: string
  contentId: string | null
  isTrustMessage?: boolean
  from?: string
}

const getRedirectLink = ({
  redirectTo,
  contentId,
  isTrustMessage,
  from = '',
}: GetRedirectLinkProps) => {
  if (isTrustMessage) return `/p2p?from=${from}`
  return `/${redirectTo}${redirectTo.includes('?') ? '&' : '?'}content_id=${contentId}`
}

export default Notification
