import React, { useEffect, useState } from 'react'
import { Select } from 'app-view/components/Select'
import { useTranslation } from 'react-i18next'
import { AdminSkeleton } from './AdminSkeleton'
import {
  Order_By,
  SwapOrdersDocument,
  SwapOrdersSubscription,
  SwapOrdersSubscriptionVariables,
  UpdateSwapOrderStatusDocument,
} from 'app-engine/graphql/generated/bitcash'
import { AdminModalContent, SwapOrderItem } from './SwapOrderItem'
import { useStore } from 'app-engine/store'
import Chat from 'app-view/components/Chat'
import { useChat } from 'app-view/components/Chat/hooks/useChat'
import { NotificationTypeEnum } from 'app-engine/types/notifications'
import { ActionsContainer, HistoryFilterContainer } from 'app-view/components/Styled'
import { useBitcashInsertNotificationMutation } from 'app-engine/graphql/generated/bitcash'
import { AdminActionsProvider } from 'pages/AdminView/hooks/useAdminActions'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'app-engine/library/utils'
import { SwapOrder, getOrderWithFee } from 'app-engine/store/swap-slice'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import { useSetState } from 'react-use'

type FilterOption = {
  label: string
  value: {
    variables: SwapOrdersSubscriptionVariables
  }
}

interface AdminSwapProps {
  orderStatus: string
}

const getSwapDataSubscription = (variables: SwapOrdersSubscriptionVariables) =>
  apolloClient.subscribe<SwapOrdersSubscription, SwapOrdersSubscriptionVariables>({
    query: SwapOrdersDocument,
    variables,
    fetchPolicy: 'no-cache',
  })

const updateSwapDataMutation = ({ id, order_status }: { id: string; order_status: string }) =>
  apolloClient.mutate({
    mutation: UpdateSwapOrderStatusDocument,
    variables: {
      id,
      order_status,
    },
  })

export const AdminSwap: React.FC<AdminSwapProps> = (props) => {
  const { t } = useTranslation(['history, global'])
  const account = useStore.useAccount()
  const [, { onOpenWithProps }] = useChat()
  const [, globalModalActions] = useGlobalModal()
  const navigate = useNavigate()
  const [bitcashInsertNotificationMutation] = useBitcashInsertNotificationMutation()
  const [{ swapOrders, error, loading }, setOrdersState] = useSetState<{
    swapOrders: SwapOrdersSubscription['swap_orders']
    error: string
    loading: boolean
  }>({
    swapOrders: [],
    error: '',
    loading: false,
  })
  const selectOptions: FilterOption[] = [
    {
      label: t('history:older'),
      value: {
        variables: {
          order_by: {
            created_at: Order_By.Asc,
          },
          where: {},
        },
      },
    },
    {
      label: t('history:recent'),
      value: {
        variables: {
          order_by: {
            created_at: Order_By.Desc,
          },
          where: {},
        },
      },
    },
    {
      label: t('global:buy'),
      value: {
        variables: {
          order_by: {
            created_at: Order_By.Desc,
          },
          where: {
            order_type: {
              _eq: 'buy',
            },
          },
        },
      },
    },
    {
      label: t('global:sell'),
      value: {
        variables: {
          order_by: {
            created_at: Order_By.Desc,
          },
          where: {
            order_type: {
              _eq: 'sell',
            },
          },
        },
      },
    },
  ]

  const sendNotification = async (type: NotificationTypeEnum, order: SwapOrder) => {
    await bitcashInsertNotificationMutation({
      variables: {
        object: {
          content_id: order.id,
          from: account,
          to: order.bitcash_account,
          read: false,
          created_at: new Date(),
          type,
        },
      },
    })
  }

  const [variables, setVariables] = useState<SwapOrdersSubscriptionVariables>({
    ...selectOptions[0].value.variables,
    where: {
      ...selectOptions[0].value.variables.where,
      order_status: {
        _eq: props.orderStatus,
      },
    },
  })

  useEffect(() => {
    if (variables.where?.order_status?._eq !== props.orderStatus) {
      setVariables((prevState) => ({
        ...prevState,
        where: {
          ...prevState.where,
          order_status: {
            _eq: props.orderStatus,
          },
        },
      }))
    }
  }, [props.orderStatus, variables.where?.order_status?._eq])

  useEffect(() => {
    setOrdersState({ loading: true })

    const subscription = getSwapDataSubscription(variables).subscribe(({ data, errors }) => {
      if (errors?.length || !data) {
        setOrdersState({
          error: errors?.length ? errors[0].message : 'No active orders found',
          loading: false,
        })
        return
      }

      const orders = data.swap_orders

      if (orders.length) {
        setOrdersState({ swapOrders: orders, loading: false })
      } else {
        setOrdersState({ error: 'No active orders found', loading: false })
      }
    })

    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variables, swapOrders.length])

  const onFilterChange = ({ value }: FilterOption) => {
    const newVariables = {
      ...value.variables,
      where: {
        ...value.variables.where,
        order_status: {
          _eq: props.orderStatus,
        },
      },
    }
    if (JSON.stringify(variables) !== JSON.stringify(newVariables)) {
      setVariables(newVariables)
    }
  }

  const handleOnCancelOrder = async (order: SwapOrder) => {
    try {
      await updateSwapDataMutation({
        id: order.id,
        order_status: 'cancelled',
      })
      await sendNotification(NotificationTypeEnum.COIN_UPT, order)
    } catch (error) {
      console.error('AdminView::handleOnCancelOrder::ERROR => ', { error })
    }
  }

  const handleOnFilledOrder = async (order: SwapOrder) => {
    try {
      await updateSwapDataMutation({
        id: order.id,
        order_status: 'filled',
      })
      await sendNotification(NotificationTypeEnum.COIN_UPT, order)
    } catch (error) {
      console.error('AdminView::handleOnFilledOrder::ERROR => ', { error })
    }
  }

  const handleChat = (chat: { account: string; id: string }) => {
    onOpenWithProps({
      from: account,
      to: chat.account,
      id: {
        type: 'swap',
        id: chat.id,
      },
    })
  }

  useEffect(() => {
    const content_id = new URLSearchParams(location.search).get('content_id') ?? ''

    if (content_id !== '' && swapOrders.length !== 0) {
      const offer = swapOrders.find((offer) => offer.id === content_id)

      if (offer) {
        globalModalActions.open({
          content: () => (
            // @ts-expect-error
            <AdminActionsProvider>
              <AdminModalContent
                order={getOrderWithFee(offer)}
                onChat={handleChat}
                onCancelOrder={handleOnCancelOrder}
                onFilledOrder={handleOnFilledOrder}
              />
            </AdminActionsProvider>
          ),
          showClose: true,
          autoHide: false,
        })

        navigate(location.pathname, { replace: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapOrders, loading])

  useEffect(() => {
    console.log('Got Coin Swap Data ===>', swapOrders)
  }, [swapOrders])

  useEffect(() => {
    console.log('Got Coin Swap Error ===> ', JSON.stringify(error, null, 2))
  }, [error])

  useEffect(() => {
    console.log('Coin Swap Variables ===>', JSON.stringify(variables, null, 2))
  }, [variables])

  return (
    <>
      <HistoryFilterContainer isMobile={Boolean(isMobile)}>
        <Select
          label={t('history:filterBy')}
          options={selectOptions}
          onChange={onFilterChange}
          gray
          width="100%"
        />
      </HistoryFilterContainer>

      <ActionsContainer>
        {loading && <AdminSkeleton />}
        {!swapOrders && !loading && <div>{t('global:no_data')}</div>}
        {!loading &&
          swapOrders?.map((order) => (
            <SwapOrderItem
              order={getOrderWithFee(order)}
              key={order.id}
              onChat={handleChat}
              onCancelOrder={handleOnCancelOrder}
              onFilledOrder={handleOnFilledOrder}
            />
          ))}
      </ActionsContainer>
      <Chat />
    </>
  )
}
