import { InfoIcon } from 'app-view/components/icons'
import { PlainButton } from './Styles'
import { FlexBox } from 'app-view/components/Styled'
import { useWizard } from 'app-view/components/Wizard'
import { useTranslation } from 'react-i18next'

export const KYCReasonButton = () => {
  const { t } = useTranslation(['account'])
  const [{}, { goTo }] = useWizard()
  return (
    <FlexBox style={{ fontWeight: '600', fontSize: '14px' }} justifyContent="center">
      {t('question_kyc_reason')}
      <PlainButton
        onClick={() => {
          goTo('info')
        }}
      >
        <InfoIcon />
      </PlainButton>
    </FlexBox>
  )
}
