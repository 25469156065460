import { ModalButtonsContainer } from 'app-view/components/Modal'
import { FlexBox } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useTranslation } from 'react-i18next'
import { CloseButton } from './components/CloseButton'
import { BlackButton } from 'app-view/components/buttons'
import { HelpGrantPermissionTexts, HelpLink, HelpText } from './components/Styles'
import { isAndroid, isIOS } from 'app-engine/library/utils'

const listStyles = {
  listStyle: 'outside',
  marginLeft: '20px',
}

export const KYCInfo = ({ close, goTo }: WizardStepProps) => {
  const { t } = useTranslation(['account'])

  return (
    <FlexBox style={{ width: '100%', minHeight: '400px' }} flexDirection="column">
      <FlexBox
        flexDirection="column"
        style={{
          padding: '32px 24px',
          gap: '24px',
        }}
      >
        <FlexBox
          style={{
            gap: '24px',
            paddingBottom: '24px',
            borderBottom: '1px solid #CCCCCC',
          }}
          flexDirection="column"
        >
          <HelpText textAlign="center">{t('kyc_help_text_1')}</HelpText>
          <HelpText textAlign="center">{t('kyc_help_text_2')}</HelpText>
        </FlexBox>
        <HelpGrantPermissionTexts>
          <FlexBox flexDirection="column">
            <b>{t('how_to_grant_permission')}</b>
            <HelpText textAlign="center">{t('please_grant_bitcash_app_permission')}:</HelpText>
          </FlexBox>
          {(isAndroid || isIOS) && (
            <FlexBox flexDirection="column" alignItems="start" style={{ width: '100%' }}>
              <b>{isAndroid ? t('for_android') : t('for_ios')}: </b>
              <HelpText
                dangerouslySetInnerHTML={{
                  __html: isAndroid ? t('android_setting') : t('ios_setting'),
                }}
              />
            </FlexBox>
          )}
          <FlexBox flexDirection="column" style={{ width: '100%' }} alignItems="start">
            <b>{t('additional_resources')}:</b>
            <ul style={listStyles}>
              {(isAndroid || isIOS || true) && (
                <li>
                  <HelpText>
                    {isAndroid ? t('for_android_devices_via') : t('for_ios_devices_via')}:{' '}
                    <HelpLink
                      href="https://help.scribblemaps.com/hc/en-us/articles/360048923351-Enable-GPS-Location-for-Chrome-Safari-and-Android-iOS-Devices"
                      target="_blank"
                    >
                      {t('enable_gps_location')}
                    </HelpLink>
                  </HelpText>
                </li>
              )}
              <li>
                <HelpText>
                  {t('for_chrome_specific')}:{' '}
                  <HelpLink
                    href="https://support.google.com/chrome/answer/142065?hl=en"
                    target="_blank"
                  >
                    {t('google_chrome_support')}.
                  </HelpLink>
                </HelpText>
              </li>
            </ul>
          </FlexBox>
        </HelpGrantPermissionTexts>
      </FlexBox>
      <ModalButtonsContainer
        style={{
          flexDirection: 'column-reverse',
          flexWrap: 'wrap',
          marginBottom: 0,
        }}
      >
        <BlackButton
          onClick={() => {
            goTo('alert')
          }}
        >
          {t('return')}
        </BlackButton>
      </ModalButtonsContainer>
      <CloseButton
        onClick={() => {
          close()
        }}
      />
    </FlexBox>
  )
}
