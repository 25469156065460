import { config } from 'app-config'
import { useStore } from 'app-engine/store'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Manager, Socket } from 'socket.io-client'

export const EVENT_WELCOME_MESSAGE = 'welcome'
export const JOIN_CHANNEL = 'join_channel'
export const LEAVE_CHANNEL = 'leave_channel'
export const EVENT_UPDATE = 'update'
export const PUBLIC_CHANNEL_ONLINE_USERS = 'public_online_users'

interface UpdatePayload<T> {
  channel: string
  payload: T
  operation: string
}

type SessionActivity = Record<string, number>

type UserSessionActivity = {
  onlineAccounts: SessionActivity
  latestDisconnections: SessionActivity
}

type SocketContextValue = {
  socket: Socket | null
  isConnected: boolean
  setWssUrl: React.Dispatch<React.SetStateAction<string>>
  onlineUsers: SessionActivity
  previousSessions: SessionActivity
}

const SocketContext = createContext<SocketContextValue>({
  socket: null,
  isConnected: false,
  setWssUrl: () => {},
  onlineUsers: {},
  previousSessions: {},
})

export const SocketProvider: React.FC<{
  children: React.ReactNode
  autoConnect?: boolean
  defaultWssUrl?: string
}> = ({ children, autoConnect = true, defaultWssUrl = config.wssUrl }) => {
  const [socket, setSocket] = useState<Socket | null>(null)
  const socketRef = React.useRef<Socket | null>(null)
  const socketSubscribedRef = React.useRef<boolean>(false)
  const [isConnected, setIsConnected] = useState(false)
  const [wssUrl, setWssUrl] = useState(defaultWssUrl)
  const token = useStore((state) => state.token)
  const [onlineUsers, setOnlineUsers] = useState<SessionActivity>({})
  const [previousSessions, setPreviousSessions] = useState<SessionActivity>({})

  const onConnect = () => {
    console.log('Connected to the server')
    setIsConnected(true)
    if (socketRef.current) {
      socketRef.current.on('update', (data: UpdatePayload<UserSessionActivity>) => {
        if (data && data?.channel !== PUBLIC_CHANNEL_ONLINE_USERS) return
        setOnlineUsers(data?.payload?.onlineAccounts || {})
        setPreviousSessions(data?.payload?.latestDisconnections || {})
      })
    }
  }

  const onDisconnect = () => {
    console.log('Disconnected from the server')
    setIsConnected(false)
  }

  const onError = (err: Error) => {
    console.log(`Connect Error: ${err.message}`)
  }

  const onPing = () => {
    console.log('ping')
    setIsConnected(true)
  }

  const onReconnect = (attempt: number) => {
    console.log('reconnect', attempt)
  }

  const disconnectSocket = () => {
    if (socketRef.current) {
      socketRef.current.io.off('ping', onPing)
      socketRef.current.io.off('reconnect', onReconnect)
      socketRef.current.io.off('error', onError)
      socketRef.current.off('connect', onConnect)
      socketRef.current.off('disconnect', onDisconnect)
      socketRef.current.off(PUBLIC_CHANNEL_ONLINE_USERS)
      socketRef.current.disconnect()
      setSocket(null)
      socketRef.current = null
      socketSubscribedRef.current = false
    }
  }

  const connectSocket = () => {
    disconnectSocket()
    if (!socketRef.current && wssUrl) {
      // console.log('user-->', 'Init sockets')
      const manager = new Manager(wssUrl, {
        transports: ['websocket'],
      })

      manager.open((err) => {
        if (err) {
          console.log('Error connecting to the server', err.message)
        } else {
          console.log('Connected to the server')
        }
      })

      const socket = manager.socket('/', {
        auth: (cb) => {
          cb({
            token: token || '',
          })
        },
      })

      // console.log('[user-->]', 'socket-->', socket)

      setSocket(socket)
      socketRef.current = socket

      // Attach event listeners
      if (socketRef.current && !socketSubscribedRef.current) {
        socketRef.current.io.on('ping', onPing)
        socketRef.current.io.on('reconnect', onReconnect)
        socketRef.current.io.on('error', onError)
        socketRef.current.on('connect', onConnect)
        socketRef.current.on('disconnect', onDisconnect)
      }
    }
  }

  useEffect(() => {
    if (autoConnect && token) {
      connectSocket()
    }
    return () => {
      disconnectSocket()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const value: SocketContextValue = useMemo(
    () => ({ socket, isConnected, setWssUrl, onlineUsers, previousSessions }),
    [socket, isConnected, onlineUsers, previousSessions],
  )

  return <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
}

export const useSocket = () => useContext<SocketContextValue>(SocketContext)
