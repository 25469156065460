import React from 'react'
import { BigButton2, ButtonsGrid } from 'app-view/components/buttons'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { useWizard } from 'app-view/components/Wizard'
import { useStore } from 'app-engine/store'
import { useUserPositions } from 'app-view/hooks/use-user-positions'

export const P2PHeaderButtons: React.FC = () => {
  const { t } = useTranslation(['global'])
  const [, { start, goTo }] = useWizard()
  const [, { startNewOffer }] = useP2POffer()
  // TODO: Do a stable_positions and a cripto_positions list
  const userPositionsHook = useUserPositions()
  const user_positions_list = userPositionsHook.user_positions_list

  const startNewOfferHandler = (type: 'sell' | 'buy') => {
    const newSteps = startNewOffer(type)

    start(newSteps)

    const has_min_main_currency = user_positions_list
      .filter((pos) => pos.symbol_code.includes('BITUSD'))
      .some((pos) => pos.balance.amount.greaterOrEquals(1500))

    // TODO: Do a stable_positions and a cripto_positions list
    const stable_positions = user_positions_list.filter((pos) => pos.symbol_code.includes('BIT'))

    // If user only has Dollars and hast the minimum, then we shouldn't show the Stable Coin Selection.
    // Only If they have balances on that currency
    if (has_min_main_currency && stable_positions.length === 1 && type === 'sell') goTo('amount')
  }

  return (
    <ButtonsGrid>
      <BigButton2 variant="red" onClick={() => startNewOfferHandler('sell')}>
        {t('sell')}
      </BigButton2>
      <BigButton2 variant="green" onClick={() => startNewOfferHandler('buy')}>
        {t('buy')}
      </BigButton2>
    </ButtonsGrid>
  )
}
