import React from 'react'

const TagIcon = (props: any) => (
  <svg viewBox="0 0 14 14" {...props}>
    <path
      d="M3.20829 4.08317C2.97623 4.08317 2.75367 3.99098 2.58957 3.82689C2.42548 3.6628 2.33329 3.44024 2.33329 3.20817C2.33329 2.97611 2.42548 2.75355 2.58957 2.58945C2.75367 2.42536 2.97623 2.33317 3.20829 2.33317C3.44036 2.33317 3.66292 2.42536 3.82701 2.58945C3.99111 2.75355 4.08329 2.97611 4.08329 3.20817C4.08329 3.44024 3.99111 3.6628 3.82701 3.82689C3.66292 3.99098 3.44036 4.08317 3.20829 4.08317ZM12.4891 6.75484L7.23913 1.50484C7.02913 1.29484 6.73746 1.1665 6.41663 1.1665H2.33329C1.68579 1.1665 1.16663 1.68567 1.16663 2.33317V6.4165C1.16663 6.73734 1.29496 7.029 1.51079 7.239L6.75496 12.489C6.97079 12.699 7.26246 12.8332 7.58329 12.8332C7.90413 12.8332 8.19579 12.699 8.40579 12.489L12.4891 8.40567C12.705 8.19567 12.8333 7.904 12.8333 7.58317C12.8333 7.2565 12.6991 6.96484 12.4891 6.75484Z"
      fill="#0F121F"
    />
  </svg>
)

export default TagIcon
