import * as Sentry from '@sentry/react'
import { Devices } from 'app-engine/graphql/generated/bitcash'
import { useStore } from 'app-engine/store'
import { AccountDevices } from 'app-engine/store/account-slice'
import { get_error_modal_props } from 'app-view/components/GlobalModal/lib/utility'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { BitcashLoginLogoIcon } from 'app-view/components/icons'
import BitcashLogoBackgroundIcon from 'app-view/components/icons/stableicons/BitcashLogoBackgroundIcon'
import { AnimatePresence, MotionProps } from 'framer-motion/dist/framer-motion'
import isEqual from 'lodash.isequal'
import { useLocation, useNavigate } from 'react-router-dom'

import { config } from 'app-config'
import { useSigningRequest } from 'hooks/use-signing-request'
import { AccessViewLoader } from 'pages/AccessView/components/AccessViewLoader'
import {
  AccessViewList,
  AccessViewListItem,
  BackgroundContainer,
  Branding,
  ChooseWalletContainer,
  LogoContainer,
  SecondaryOptionsWrapper,
  Slogan,
} from 'pages/AccessView/components/styles'
import { registrationInitialState, useRegistration } from 'pages/AccountView/hooks/useRegistration'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce, useSetState } from 'react-use'
import { AccessViewButton, AccessViewButtonProps } from './components/AccessViewButton'
import { QRScanButton } from './components/QrScanButton'

type AccessViewOptions = {
  main: AccessViewButtonProps[]
  secondary: AccessViewButtonProps[]
}

type AccessUIStateProps = {
  open_secondary_options: boolean
  accessing: boolean
  register: boolean
}

const animation_children_props = {
  animate: {
    y: 0,
    opacity: 1,
  },
  transition: {
    duration: 0.24,
  },
}
const animation_props = {
  animate: {
    clipPath: 'inset(0% 0% 0% 0%)',
    height: 'auto',
  },
  initial: {
    clipPath: 'inset(0% 0% 100% 0%)',
    height: 0,
  },
  exit: {
    clipPath: 'inset(0% 0% 100% 0%)',
    height: 0,
  },
  transition: {
    duration: 0.42,
    delayChildren: 0.24,
    staggerChildren: 0.24,
  },
}
const bitcashBgLogoAnimationProps: MotionProps = {
  animate: {
    x: ['-8.333%', '42%', '-42%', '8.333%', '-8.333%'],
    // scale: [1, 1.4, 1], // Maybe we can enable it
    transition: {
      repeat: Infinity,
      duration: 110,
    },
  },
}

const default_ui_state: AccessUIStateProps = {
  open_secondary_options: false,
  accessing: false,
  register: false,
}

export const AccessView = () => {
  const account = useStore.useAccount()
  const pubKey = useStore.usePub_key()
  const [, { requestSignature, setCallbackUrl }] = useSigningRequest()
  const [, globalModalActions] = useGlobalModal()
  const { t } = useTranslation(['access', 'header'])
  const navigate = useNavigate()
  const location = useLocation()
  const [menuOptions, setMenuOptions] = useState<AccessViewOptions>({
    main: [],
    secondary: [],
  })
  const { devices, joined, loginWithAnchor, loginWithWebAuthN } = useStore()
  const [{ open_secondary_options, accessing, register }, setUIState] =
    useSetState<AccessUIStateProps>(default_ui_state)
  const [registrationState, { setInitialState, verifyAnchorAccount }] = useRegistration()

  const openModalError = (message: string) =>
    globalModalActions.open({
      content: () => <ModalError error={message} />,
      showClose: true,
      autoHide: false,
      iconType: 'ERROR',
      title: 'Error',
    })

  // resetting registration state
  useEffect(() => {
    if (!isEqual(registrationState, registrationInitialState)) setInitialState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: Custom Labels for EOS Account Registration
  useEffect(() => {
    const anchor_link_node = document.querySelector('.anchor-link')
    const anchor_success_node = document.querySelector('.anchor-link-logo.success')

    if (anchor_success_node && register) {
      const anchor_success_subtitle_node = document.querySelector('.anchor-link-subtitle')
      const success = window.getComputedStyle(anchor_success_subtitle_node!).display !== 'none'

      if (success) {
        anchor_success_subtitle_node!.textContent = t('verification_complete')
      }
    }

    if (anchor_link_node && register) {
      const anchor_title_node = anchor_link_node.querySelector('.anchor-link-title')
      const is_anchor_active = window.getComputedStyle(anchor_title_node!).display !== 'none'

      if (is_anchor_active) {
        anchor_title_node!.textContent = t('register')
      }
    }
  })

  const register_label = t('header:register_account')

  const requestLoginAccount = async (device?: AccountDevices | Devices) => {
    let error = ''
    setUIState({ accessing: true })
    try {
      await loginWithWebAuthN(device)
      console.log('[p2p] navigate', 'loginWithWebAuthN')
      navigate('/p2p')
    } catch (err) {
      if (err instanceof Error) {
        Sentry.captureEvent({
          ...err,
          user: {
            username: account,
          },
          message: `loginWithWebAuthnN::ERROR::${(err as Error).message}`,
        })
      }
      if (
        (err as Error).message.match(
          /React\s+error|Failed\s+to\s+fetch|Load\s+failed|The\s+operation\s+either\s+timed|(Expired|Duplicate)\s+(T|t)ransaction\s+at/,
        )
      ) {
        error = 'Web Authentication Error'
      } else error = t((err as Error).message)
      globalModalActions.open(get_error_modal_props(() => <ModalError error={error} />))
    } finally {
      setUIState({ accessing: false })
    }
  }

  useEffect(() => {
    // Main Actions //
    const main_options: AccessViewButtonProps[] = [
      // Login with WebAuthN
      {
        label: t('login'),
        id: 'login_with_webauthn',
        onClick: async () => {
          if (!account.includes('.bk') || !devices.length) {
            navigate(`/account${location.search}`)
            return
          }

          if (devices.length > 1 && devices.some((d) => d.logged))
            return requestLoginAccount(devices.find((d) => d.logged))

          return await requestLoginAccount()
        },
      },
      // Registration with WebAuthN
      {
        id: 'register_with_webauthn',
        label: register_label,
        onClick: () => navigate(`/create-account${location.search}`),
      },
      // More actions to choose
      {
        id: 'more_options',
        label: t('more_options'),
        onClick: () =>
          setUIState((ui_state) => ({ open_secondary_options: !ui_state.open_secondary_options })),
      },
    ]

    // Secondary Actions - Login //
    // Login with Anchor
    const secondary_options: AccessViewButtonProps[] = [
      {
        id: 'login_with_anchor',
        label: t('login_with', { label: 'Anchor' }),
        onClick: async () => {
          setUIState({ accessing: true })

          try {
            await loginWithAnchor()
            console.log('[p2p] navigate', 'loginWithAnchor')
            navigate('/p2p')
          } catch (error) {
            openModalError((error as Error).message)
          } finally {
            setUIState({ accessing: false })
          }
        },
      },
    ]

    // Login with Different Account (if prev session read from WebAuthN or if users went from Anchor to WebAuthN)
    if ((account.includes('.bk') && devices.length > 1) || account.includes('.bk')) {
      secondary_options.unshift({
        id: 'login_with_different_account',
        label: t('login_with_different_account'),
        onClick: async () => navigate(`/account${location.search}`),
      })
    }

    // Secondary Actions - Other Registrations //
    // Add A New Device
    secondary_options.push({
      id: 'add_new_device',
      label: t('add_key'),
      onClick: () => navigate('/add-device/account'),
    })

    // Signup with Anchor
    secondary_options.push({
      id: 'signup_with_anchor',
      label: t('signup_with', { label: 'Anchor' }),
      onClick: async () => {
        setUIState({ accessing: true })
        try {
          setUIState({ register: true })
          await verifyAnchorAccount()
          navigate(`/register-account${location.search}`)
        } catch (error) {
          openModalError((error as Error).message)
        } finally {
          setUIState({ accessing: false, register: false })
        }
      },
    })

    setMenuOptions({
      main: main_options,
      secondary: secondary_options,
    })
    // eslint-disable-next-line
  }, [location.search, t])

  const animate_chevron_props = {
    animate: { transform: open_secondary_options ? 'rotateZ(90deg)' : 'rotateZ(0deg)' },
    initial: { transform: open_secondary_options ? 'rotateZ(0deg)' : 'rotateZ(90deg)' },
  }

  // QR login
  const params = new URLSearchParams(location.search)
  const showQr = params.has('esr_code') || config.features.qr || (account && devices.length > 0)

  useEffectOnce(() => {
    if (!params.has('esr_code')) return

    const execRequestSignature = () => {
      const callbackUrl = decodeURIComponent(params.get('callback'))
      if (!callbackUrl) return

      console.log('broadcasting transaction')

      requestSignature(params.get('esr_code'), { account, pubKey })
      setCallbackUrl(params, callbackUrl)
    }

    execRequestSignature()
  })

  return (
    <>
      <ChooseWalletContainer>
        <div>
          <Branding>
            <BackgroundContainer {...bitcashBgLogoAnimationProps}>
              <BitcashLogoBackgroundIcon />
            </BackgroundContainer>
            <LogoContainer>
              <BitcashLoginLogoIcon width={290} />
            </LogoContainer>
          </Branding>
          <Slogan>{t('slogan')}</Slogan>
        </div>
        <AccessViewList>
          {accessing ? (
            <AccessViewLoader text="Validating credentials" />
          ) : (
            <>
              {menuOptions.main.map((option) => (
                <AccessViewListItem key={option.id}>
                  <AccessViewButton
                    isPrevUser={
                      (joined && option.label === t('login') && account.includes('.bk')) ||
                      (!joined && option.label === register_label) ||
                      (joined && option.label === register_label && !account.includes('.bk'))
                    }
                    secondary={option.label === 'More Options'}
                    animate={option.label === 'More Options' ? animate_chevron_props : undefined}
                    {...option}
                  />
                </AccessViewListItem>
              ))}
              <AnimatePresence>
                <SecondaryOptionsWrapper {...animation_props}>
                  {open_secondary_options ? (
                    <SecondaryOptions options={menuOptions.secondary} />
                  ) : null}
                </SecondaryOptionsWrapper>
              </AnimatePresence>
            </>
          )}
        </AccessViewList>
        {showQr ? (
          <div
            style={{
              position: 'absolute',
              bottom: 60,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 999999,
            }}
          >
            <QRScanButton />
          </div>
        ) : null}
      </ChooseWalletContainer>
    </>
  )
}

const SecondaryOptions: React.FC<{ options: AccessViewButtonProps[] }> = ({ options }) => {
  const { t } = useTranslation('access')

  return (
    <>
      {options.map((option, i) => (
        <AccessViewListItem
          key={`secondary-option-${(Math.random() + 1) * (i + 1)}`}
          initial={{ y: -60 * (i + 1), opacity: 0 }}
          exit={{ y: -60 * (i + 1), opacity: 0 }}
          {...animation_children_props}
        >
          <AccessViewButton
            secondary
            isPrevUser={option.label === t('login_with_different_account')}
            {...option}
          />
        </AccessViewListItem>
      ))}
    </>
  )
}
