import Spinner from 'app-view/components/Spinner'
import { FlexBox } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import { BlackButton, GreenButton } from 'app-view/components/buttons'
import { SelfieCheckIcon, SelfieInvalidIcon } from 'app-view/components/icons'
import {
  CameraImageFrame,
  CameraImageFrameContainer,
  CameraVideoBorder,
  CameraWrapper,
  HelpText,
  InstructionTitle,
  MediaContainer,
  PhotoIdMask,
  SelfieStatusHint,
} from 'pages/AccountView/routes/shared/step-for-upload-selfie/components/Styles'
import { useTakeImageHook } from 'pages/AccountView/routes/shared/step-for-upload-selfie/take-image/useTakeImageHook'
import { useTranslation } from 'react-i18next'
import { CameraLoaderAnimation } from '../components/CameraLoaderAnimation'
import { CloseButton } from '../components/CloseButton'
import { DownloadSelfieButton } from '../components/DownloadButton'
import { useEffect, useRef } from 'react'

export function TakePhotoId({ close }: WizardStepProps) {
  const { t } = useTranslation(['account'])
  const [
    {
      photoIdImageSrc,
      photoIdDownloadLink,
      photoIdStatus,
      error,
      processing,
      isCameraStarted,
      videoRef,
      frameWidth,
      frameHeight,
    },
    { takePhotoId, onConfirm, reTakePhotoId },
  ] = useTakeImageHook()
  const maskRef = useRef(null)

  useEffect(() => {
    if (maskRef?.current && videoRef?.current && isCameraStarted) {
      // Set mask dimensions based on the desired aspect ratio (16:10)
      const aspectRatio = 16 / 10
      const videoElement = videoRef.current
      if (videoElement) {
        const rect = videoElement.getBoundingClientRect()
        const videoWidth = rect.width
        const videoHeight = rect.height

        if (videoWidth / videoHeight > aspectRatio) {
          maskRef.current.style.height = videoHeight * 0.9 + 'px'
          maskRef.current.style.width = videoHeight * aspectRatio * 0.9 + 'px'
        } else {
          maskRef.current.style.width = videoWidth * 0.9 + 'px'
          maskRef.current.style.height = (videoWidth / aspectRatio) * 0.9 + 'px'
        }
      }
    }
  }, [videoRef, maskRef, isCameraStarted])

  return (
    <div style={{ width: '100%' }}>
      <FlexBox justifyContent="center" style={{ marginBottom: '5px' }}>
        <CameraWrapper>
          {photoIdImageSrc ? (
            // document identification frame wrapper with styled components
            <MediaContainer>
              {/* TODO: Remove `|| true` after updating AI */}
              <CameraImageFrameContainer
                style={{ height: frameWidth }}
                selfieStatus={photoIdStatus || true}
              >
                <img
                  style={{
                    width: '100%',
                    height: frameWidth !== 0 && frameWidth > frameHeight ? '100%' : 'auto',
                  }}
                  src={photoIdImageSrc}
                  alt="Captured"
                />
              </CameraImageFrameContainer>
              <PhotoIdMask ref={maskRef} />
            </MediaContainer>
          ) : (
            <MediaContainer>
              {processing ? <CameraLoaderAnimation /> : ''}
              <PhotoIdMask ref={maskRef} />
              <video
                ref={videoRef}
                style={{
                  width: '100%',
                  borderRadius: '10px',
                  objectFit: 'cover',
                  height: frameWidth !== 0 ? frameWidth : 'auto',
                }}
                muted
                playsInline
                autoPlay
              />
              {/* TODO: Remove `|| true` after updating AI */}
              <CameraVideoBorder selfieStatus={photoIdStatus || true} />
            </MediaContainer>
          )}
          {/* TODO: update AI */}

          {/* {photoIdImageSrc || (!photoIdImageSrc && photoIdStatus) ? (
          <SelfieCheckIcon
            style={{ position: 'absolute', top: '5px', right: '5px' }}
          />
        ) : (
          ''
        )}
        {!photoIdImageSrc && !photoIdStatus && photoIdStatus !== null ? (
          <SelfieInvalidIcon
            style={{ position: 'absolute', top: '5px', right: '5px' }}
          />
        ) : (
          ''
        )}
        {isCameraStarted ? (
          <SelfieStatusHint selfieStatus={photoIdStatus}>
            {processing ? t('processing') : ''}
            {photoIdStatus === null && !processing ? t('verifying') : ''}
            {photoIdStatus && !processing ? t('photo_is_ok') : ''}
            {!processing && !photoIdStatus && photoIdStatus !== null && error
              ? t('processing_error')
              : ''}
            {!processing && !photoIdStatus && photoIdStatus !== null && !error
              ? t('invalid_photo_id')
              : ''}
          </SelfieStatusHint>
        ) : (
          <div style={{ width: '100%' }}>
            <Spinner style={{ position: 'unset' }} boxSize={20} />
          </div>
        )} */}
        </CameraWrapper>
      </FlexBox>
      <InstructionTitle>{t('take_id_card_photo_instructions')}:</InstructionTitle>
      <FlexBox
        style={{
          marginBlock: '24px',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth: 360,
          marginInline: 'auto',
          paddingInline: 10,
          fontWeight: 600,
        }}
      >
        <HelpText
          dangerouslySetInnerHTML={{ __html: t('photo_id_notifications') }}
          style={{ width: '100%' }}
        />
      </FlexBox>
      {photoIdImageSrc ? (
        <FlexBox style={{ padding: '0 7.5%', marginInline: 'auto' }} justifyContent="space-evenly">
          <BlackButton onClick={reTakePhotoId}>{t('retake_selfie')}</BlackButton>
          <GreenButton onClick={onConfirm}>{t('create_account_accounts_submit')}</GreenButton>
        </FlexBox>
      ) : (
        <FlexBox justifyContent="center">
          <BlackButton
            // TODO: Update disabled tag after updating AI
            // disabled={isCameraStarted ? Boolean(error) || !photoIdStatus || processing : true}
            disabled={!isCameraStarted}
            style={{ maxWidth: 180 }}
            onClick={takePhotoId}
          >
            {t('take_photo_id')}
          </BlackButton>
        </FlexBox>
      )}
      {photoIdImageSrc ? (
        <DownloadSelfieButton downloadLink={photoIdDownloadLink} />
      ) : (
        <CloseButton
          onClick={() => {
            close()
          }}
        />
      )}
    </div>
  )
}
