import React from 'react'
import { useTranslation } from 'react-i18next'
import { WizardStepProps } from 'app-view/components/Wizard/types'
import { useAdminActions } from 'pages/AdminView/hooks/useAdminActions'
import { Loader } from 'app-view/components/Spinner'
import { RedButton } from 'app-view/components/buttons'
import { Text } from 'app-view/components/Styled'
import theme from 'app-config/theme'
import { TimesIcon } from 'app-view/components/icons'

export const NoAccountInfo = ({ goTo }: WizardStepProps) => {
  const { t } = useTranslation(['account'])
  const [{ loading }, { cancelAccountRequest }] = useAdminActions()
  const rejectAccount = async () => {
    const { success } = await cancelAccountRequest()
    if (success) goTo('success')
  }
  if (loading) return <Loader text={t('loading_action')} />
  return (
    <>
      <Text color={theme.colors.black} style={{ marginBottom: '3rem' }}>
        No User Information
      </Text>
      <RedButton onClick={rejectAccount}>
        {t('p2p:reject')}{' '}
        <TimesIcon
          style={{
            height: '14.15px',
            width: '14.15px',
            stroke: theme.colors.white,
            marginLeft: '8px',
          }}
          color={theme.colors.white}
        />
      </RedButton>
    </>
  )
}
