import { Asset } from '@greymass/eosio'
import { useStore } from 'app-engine/store'
import { Select } from 'app-view/components/Select'
import Spinner from 'app-view/components/Spinner'
import { ActionsContainer, HistoryFilterContainer } from 'app-view/components/Styled'
import { useChaingraphSWR } from 'hooks/use-chaingraph-swr.hook'
import uniqBy from 'lodash.uniqby'
import { ExchangeHistoryCard } from 'pages/HistoryView/components/Exchange/ExchangeTransaction'
import { useExchangeHistoryFilters } from 'pages/HistoryView/hooks/useExchangeHistoryFilters'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { HistoryFilterProps, onChainHistoryService } from 'services/bitcash/history'
import { HistorySkeleton } from '../HistorySkeleton'
import { HighlightText, TabViewWrapper, TopSpinnerLoader } from '../Styled'

const fetchExchangeHistory = (props: HistoryFilterProps) =>
  onChainHistoryService.getExchangeHistory(props)

export const ExchangeHistory: React.FC = () => {
  const { t } = useTranslation(['history, global'])
  const account = useStore.useAccount()
  const { filterOptions, activeFilter, updateExchangeHistory } = useExchangeHistoryFilters()
  const { data, error, isLoading, isValidating } = useChaingraphSWR(
    fetchExchangeHistory({ account, orderBy: activeFilter }),
    {
      // 1 minute
      refreshInterval: 60000,
    },
  )

  const exchangeHistory =
    uniqBy(data?.actions, 'transaction_id').map(
      ({ transaction_id, data: { fee, amount_in, amount_out, unit_price } }) => ({
        transactionId: transaction_id,
        timestamp: new Date().getTime(),
        quantityFee: Asset.from(fee.quantity),
        quantityAmountIn: Asset.from(amount_in.quantity),
        quantityAmountOut: Asset.from(amount_out.quantity),
        quantityUnitPrice: Asset.from(unit_price.quantity),
      }),
    ) || []

  return (
    <TabViewWrapper>
      {isValidating && (
        <TopSpinnerLoader>
          <Spinner boxSize={20} />
        </TopSpinnerLoader>
      )}
      <HistoryFilterContainer>
        <Select
          label={t('history:filterBy')}
          options={filterOptions}
          onChange={updateExchangeHistory}
          gray
          width="100%"
        />
      </HistoryFilterContainer>

      <ActionsContainer>
        {error && <HighlightText>{t('history:An error occurred')}</HighlightText>}
        {isLoading && <HistorySkeleton />}
        {!exchangeHistory.length && !isLoading && (
          <HighlightText>{t('history:There is currently no history')}</HighlightText>
        )}
        {!isLoading &&
          exchangeHistory.map((history) => (
            <ExchangeHistoryCard actionData={history} key={history.transactionId} />
          ))}
      </ActionsContainer>
    </TabViewWrapper>
  )
}
