import React from 'react'

const SelfieCheckIcon = (props: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect width="24" height="24" rx="12" fill="#1ED761" />
    <path
      d="M9.40236 17.8027L4.13761 12.5379C3.82132 12.2216 3.82132 11.7088 4.13761 11.3925L5.28304 10.247C5.59934 9.93066 6.11221 9.93066 6.42851 10.247L9.9751 13.7935L17.5715 6.19718C17.8878 5.88089 18.4007 5.88089 18.717 6.19718L19.8624 7.34265C20.1787 7.65894 20.1787 8.17178 19.8624 8.48811L10.5478 17.8027C10.2315 18.119 9.71866 18.119 9.40236 17.8027Z"
      fill="white"
    />
  </svg>
)

export default SelfieCheckIcon
