import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { WheelSelectOption } from 'app-view/components/WheelSelect'
import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalStepGreenButton,
  ModalStepBlackButton,
} from 'app-view/components/Modal'
import { ModalText } from 'app-view/components/Modal'
import { SkeletonFilter } from 'app-view/components/Skeletons'
import { Select } from 'app-view/components/Select'
import { SectionAmount } from 'pages/P2PView/Styled'
import styled from 'app-view/styles/styled'
import { MethodOption } from 'app-engine/types/p2pPaymentMethods'
import { useStore } from 'app-engine/store'

export const Method = ({ onPrev, onNext, onChange, text }: MethodProps) => {
  const { t } = useTranslation(['p2p', 'profile'])
  const { availablePaymentMethods } = useStore()
  const paymentOptions: MethodOption[] =
    availablePaymentMethods?.map((method) => ({ value: method, label: t(method) })) || []

  useEffect(() => {
    if (paymentOptions.length === 0) return

    onChange(paymentOptions[0].value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptions.length])

  const onChangeMethod = (opt: WheelSelectOption) => {
    if (paymentOptions.length === 0 || !opt?.value) return
    onChange(opt.value)
  }

  return (
    <>
      <ModalSelectWrapper>
        <MethodSectionAmount>
          {paymentOptions.length === 0 ? (
            <SkeletonFilter />
          ) : (
            <Select
              options={paymentOptions}
              initialPosition={0}
              onChange={onChangeMethod}
              topFix={6}
              width="100%"
              value={paymentOptions[0].value}
              isSearchable
            />
          )}
        </MethodSectionAmount>
        <ModalText style={{ marginBottom: 0 }} styleContainer={{ flexGrow: 0 }}>
          {text}
        </ModalText>
      </ModalSelectWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton onClick={onPrev}>{t('cancel')}</ModalStepBlackButton>
        <ModalStepGreenButton onClick={onNext}>{t('Next')}</ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}

const MethodSectionAmount = styled(SectionAmount)`
  > div {
    margin: 0 auto;
    text-align: center;

    input {
      /* Overriding default grid-area from React-Select */
      grid-area: 1 / 3 / auto / auto !important;
      text-align: center;
    }
  }
`

export type MethodProps = {
  onPrev: () => void
  onNext: () => void
  onChange: (amount: string) => void
  text: string
}
