import { WizardStep } from 'app-view/components/Wizard'
import { ActionSuccess } from 'pages/BankView/steps/common/ActionSuccess'
import { WithdrawalAmount } from 'pages/BankView/steps/withdrawal-steps/WithdrawalAmount'
import { WithdrawConfirm } from './WithdrawConfirm'

export const WITHDRAWAL_STEPS: WizardStep[] = [
  {
    name: 'amount',
    component: WithdrawalAmount,
  },
  {
    name: 'confirm',
    component: WithdrawConfirm,
  },
  {
    name: 'success',
    component: ActionSuccess,
  },
]
