import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Sentry from '@sentry/react'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import {
  registerServiceWorker,
  requestNotificationPermission,
  sendNotification,
} from 'app-engine/library/notifications'
import { NotificationTypeEnum, SendNotificationProps } from 'app-engine/types/notifications'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { useTranslation } from 'react-i18next'
import { useEffectOnce } from 'react-use'
import { useStore } from './app-engine/store/index'
import { routes } from './routes'
import { isMobile } from './app-engine/library/utils'
import { useProfile } from 'app-view/hooks/use-profile.hook'

// check if new notification
let swRegistration: ServiceWorkerRegistration | undefined

type GetMessageProps = {
  type: string
  from: string
  account?: string
  order_type?: string
  status?: string
  content_id?: string
  asset?: string
}

const validateShareLink = async (hash: string) =>
  await apolloClient.mutate<
    Bitcash.ValidateShareLinkMutation,
    Bitcash.ValidateShareLinkMutationVariables
  >({
    mutation: Bitcash.ValidateShareLinkDocument,
    variables: {
      _set: {
        link: `https://app.bitcash.org?share=${hash}`,
      },
    },
  })

export const useApp = () => {
  const navigate = useNavigate()

  const { t, i18n } = useTranslation(['notifications', 'access'])
  const {
    previous_route,
    token,
    authType,
    authed,
    session_expired,
    notify,
    notifications,
    last_unread,
    account,
    logout,
    setLastUnread,
    getNotifications,
    setPreviousRoute,
    setSessionToken,
    refreshSession,
    unreadNotifications,
  } = useStore()
  const [{ preferences }] = useProfile()

  const [, globalModalActions] = useGlobalModal()
  const [, { setRegistrationData }] = useRegistration()

  // setting user language if preference data present
  useEffect(() => {
    if (i18n.language !== preferences.language) i18n.changeLanguage(preferences.language)
  }, [i18n, preferences.language])

  useEffect(() => {
    if (token && !authed) {
      const setToken = async () => {
        try {
          await setSessionToken({ token, authType })
        } catch (error) {
          try {
            await refreshSession()
          } catch (err) {
            globalModalActions.close()
            globalModalActions.open({
              content: () => <ModalError error={t('access:your_session_expired')} />,
              iconType: 'ERROR',
              showClose: true,
              title: t('access:session_expired'),
            })
            logout()
          }
        } finally {
          setPreviousRoute(location.pathname)
        }
      }

      setToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, authed])

  useEffect(() => {
    if (
      authed &&
      token &&
      (routes.access_view.some((av) => av.match(location.pathname)) ||
        routes.account_view.some((av) => av.match(location.pathname))) &&
      !location.pathname.includes('/admin')
    ) {
      navigate(previous_route || `/p2p${location.search || ''}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, location.pathname, token, authed, previous_route])

  useEffect(() => {
    if (session_expired) {
      globalModalActions.close()
      globalModalActions.open({
        content: () => <ModalError error={t('access:your_session_expired')} />,
        iconType: 'ERROR',
        showClose: true,
        title: t('access:session_expired'),
      })
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_expired, token, authed])

  const getMessage = ({ type, from, order_type, status, content_id, asset }: GetMessageProps) => {
    let message = ''

    switch (type) {
      case NotificationTypeEnum.COIN_MSG:
        message = t('coin_message_not')
        break
      case NotificationTypeEnum.COIN_UPT:
        message = t('coin_status', {
          asset,
          status,
          order_type,
        })
        break
      case NotificationTypeEnum.P2P_NOF:
        message = t('p2p_not_message')
        break
      case NotificationTypeEnum.P2P_UPT:
        message = t('p2p_status', { order_type, status })
        break
      case NotificationTypeEnum.P2P_MSG:
        if (!content_id) {
          message = t('p2p_trust_message', { from })
        } else {
          message = t('p2p_message', { order_type })
        }
        break
      default:
        message = ''
        break
    }

    return message.replace(/<\/?[^>]+(>|$)/g, '')
  }

  const showNoti = async (swRegistration?: ServiceWorkerRegistration) => {
    // console.log('swRegistration - showNoti', swRegistration)
    const last_notification = unreadNotifications()[unreadNotifications().length - 1]

    if (!last_notification) return

    setLastUnread()

    const type = last_notification.data?.order_type || last_notification.data?.type
    const status_p2p = last_notification.data?.cancelled ? t('cancelled') : t('completed')
    const status = last_notification.type.includes('COIN')
      ? last_notification.order_status
      : status_p2p
    const message = getMessage({
      type: last_notification.type,
      from: last_notification.from,
      order_type: type,
      status,
      content_id: last_notification.content_id,
      asset: last_notification.data?.asset,
    })
    const notification_props: SendNotificationProps = {
      notification: last_notification,
      message,
      content: notifications.find((n) => n.content_id === last_notification.content_id),
      worker: swRegistration,
    }
    await sendNotification(notification_props) // const notification =
    // console.log('created notification~~~~~~~~~~~~~~~~,', notification)
  }

  useEffect(() => {
    if (!authed || !token || isMobile) return
    // TODO: Check user permissions from BE first to ask permissions
    const initWorker = async () => {
      try {
        await requestNotificationPermission().then(async (permission) => {
          try {
            if (permission) {
              // * it should be a ServiceWorkerRegistration instance from registerServiceWorker() at ./src/index.tsx
              swRegistration = await registerServiceWorker()
              showNoti(swRegistration)
            }
          } catch (error) {
            if (error instanceof Error) {
              Sentry.captureEvent({
                ...error,
                user: {
                  username: account,
                },
                message: `requestNotificationPermission::ERROR::${error.message}`,
              })
            }
          }
        })
      } catch (error) {
        if (error instanceof Error) {
          Sentry.captureEvent({
            ...error,
            user: {
              username: account,
            },
            message: `requestNotificationPermission::ERROR::${error.message}`,
          })
        }
      }
    }

    initWorker()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authed, token])

  useEffect(() => {
    const notificationSubscription = getNotifications()

    if (!authed) {
      notificationSubscription?.unsubscribe()
    }

    // Once we get notified, we then update and show notification
    if (unreadNotifications().length !== last_unread && notify) showNoti(swRegistration)

    return () => {
      notificationSubscription?.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unreadNotifications().length, notify, authed, token])

  useEffectOnce(() => {
    document.addEventListener('DOMContentLoaded', async () => {
      // Do we have a name in the queryString
      const params = new URL(window.location.href).searchParams

      if (params.has('share')) {
        try {
          const value = params.get('share') as string

          console.log('Receiving a Share parameter', value)

          const digestedShareLink = await validateShareLink(value)

          if (digestedShareLink.errors?.length) throw new Error(digestedShareLink.errors[0].message)

          setRegistrationData({
            website: digestedShareLink.data!.validate_short_link!.website,
            referrer: digestedShareLink.data!.validate_short_link!.referrer ?? '',
            link: `https://app.bitcash.org?share=${value}`,
          })
          navigate('/create-account', { replace: true })
        } catch (error) {
          console.error('DOMContentLoaded::Receiving a Share parameter::ERROR', error)
        }
      }

      if (!navigator.registerProtocolHandler) return

      navigator.registerProtocolHandler(
        'web+bitcash',
        `${window.location.origin}/?request=%s`,
        // @ts-ignore
        'Bitcash Wallet',
      )

      const timeout = setTimeout(async () => {
        if (params.has('request')) {
          const value = params?.get('request')?.split('web+bitcash:')[1]
          console.log('Receiving a Request from a Custom Protocol', value)
        }

        clearTimeout(timeout)
      }, 2000)
    })
  })
}
