import { useTranslation } from 'react-i18next'
import { Order_By, P2POffersSubscriptionVariables } from 'app-engine/graphql/generated/bitcash'
import { useStore } from 'app-engine/store'
import { useMemo } from 'react'

export type FilterOption = {
  label: string
  value: P2POffersSubscriptionVariables
}

export const useAtmFilters = () => {
  const { t } = useTranslation(['p2p'])
  const account = useStore.useAccount()
  const filterOptions = useMemo<FilterOption[]>(() => {
    if (!account) return []
    const options = [
      {
        label: t('filter_option_buy'),
        value: {
          order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
          where: {
            buyer: { _eq: account },
            _or: [{ completed: { _eq: true } }, { cancelled: { _eq: true } }],
          },
        },
      },
      {
        label: t('filter_option_sell'),
        value: {
          order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
          where: {
            seller: { _eq: account },
            _or: [{ completed: { _eq: true } }, { cancelled: { _eq: true } }],
          },
        },
      },
      {
        label: t('filter_option_recent'),
        value: {
          order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
          where: {
            type: { _in: ['buy', 'sell'] },
            _or: [
              { buyer: { _eq: account }, cancelled: { _eq: true } },
              { buyer: { _eq: account }, completed: { _eq: true } },
              { seller: { _eq: account }, cancelled: { _eq: true } },
              { seller: { _eq: account }, completed: { _eq: true } },
            ],
          },
        },
      },
      {
        label: t('filter_option_older'),
        value: {
          order_by: [{ created_at: Order_By.Asc, updated_at: Order_By.Asc }],
          where: {
            type: { _in: ['buy', 'sell'] },
            _or: [
              { buyer: { _eq: account }, completed: { _eq: false }, cancelled: { _eq: true } },
              { buyer: { _eq: account }, completed: { _eq: true }, cancelled: { _eq: false } },
              { seller: { _eq: account }, completed: { _eq: false }, cancelled: { _eq: true } },
              { seller: { _eq: account }, completed: { _eq: true }, cancelled: { _eq: false } },
            ],
          },
        },
      },
      {
        label: `${t('p2p:filterOptionCancelled')}`,
        value: {
          order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
          where: {
            cancelled: { _eq: true },
            _or: [{ buyer: { _eq: account } }, { seller: { _eq: account } }],
          },
        },
      },
      {
        label: `${t('p2p:filterOptionCompleted')}`,
        value: {
          order_by: [{ created_at: Order_By.Desc, updated_at: Order_By.Desc }],
          where: {
            completed: { _eq: true },
            _or: [{ buyer: { _eq: account } }, { seller: { _eq: account } }],
          },
        },
      },
    ]

    return options
  }, [account, t])

  return filterOptions
}
