import {
  ModalButtonsContainer,
  ModalStepBlackButton,
  ModalStepGreenButton,
} from 'app-view/components/Modal'
import React from 'react'
import { Form } from 'pages/AccountView/components/TextElements'
import { WalletStepMainContentWrapper, CoinAmountWrapper } from '../common/Styled'
import { InputWrapper, InputSection, Input, Label, HintError } from 'app-view/components/InputField'
import { useBankWallet } from 'pages/BankView/hooks/useBankWallet'
import { useTranslation } from 'react-i18next'
import { WizardStepProps } from 'app-view/components/Wizard'
import { checkAccountExt } from 'pages/AccountView/utils'
import { Loader } from 'app-view/components/Spinner'
import { BankCoinAmount } from 'app-view/components/CoinAmount'
// TODO: Reemplace with @greymass/eosio
import { Asset } from 'eos-common'
import { Controller, useForm, UseFormReturn } from 'react-hook-form'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'
import { useLocalStorage } from 'react-use'

interface FormData {
  amount: Asset
  receiver: string
}
export interface InputHandlerProps {
  form: UseFormReturn<FormData, object>
  field: 'receiver' | 'amount'
  message: string
}

export const SendToAccount = ({ next, close }: WizardStepProps) => {
  const { t } = useTranslation(['wallet'])
  const [lastCryptoUsed, setLastCryptoUsed] = useLocalStorage('lastCryptoUsed', '')
  const [
    { action, loading, isReady, error },
    { submitAction, setError, onChangeSendHandler, setReady },
  ] = useBankWallet()
  const form = useForm<{ amount: Asset; receiver: string }>({
    defaultValues: {
      receiver: '',
      amount: undefined,
    },
  })

  const onBlur = () => {
    window.scroll(0, 0)
  }

  const onSubmitHandler = async () => {
    try {
      // TODO: If Memo Step is restored, pass this to memo step...
      const amount = action.amount.amount.toString()
      const symbol = action.amount.symbol
      const symbol_code = symbol.code().toString()
      const sym_precision = amount.length - symbol.precision()
      const digested_amount = `${amount.substring(0, sym_precision)}.${amount.substring(
        sym_precision,
      )}`
      const trnx_quantity = `${digested_amount} ${symbol_code}`
      const token = findTokenBySymbolCode(symbol_code)

      const { success } = await submitAction({
        from: action.sender,
        // NOTE: helping the user to format the account to send...
        to: checkAccountExt(action.receiver),
        quantity: {
          contract: token?.token_contract,
          quantity: trnx_quantity,
        },
        memo: action.memo || t('bitcash_transfer'),
      })

      if (success) {
        setLastCryptoUsed(symbol_code)
        next()
      }
    } catch (err) {
      setError((err as Error).message)
      setReady(false)
    }
  }

  if (loading) return <Loader text={t('loading_action')} />

  const receiver_input_props: InputHandlerProps = {
    form,
    field: 'receiver',
    message: t('invalid_account_format'),
  }
  const amount_input_props: InputHandlerProps = {
    form,
    field: 'amount',
    message: t('invalid_account_format'),
  }

  return (
    <Form onSubmit={form.handleSubmit(onSubmitHandler)} wallet={!Boolean(error)}>
      <WalletStepMainContentWrapper>
        <Controller
          name="receiver"
          control={form.control}
          rules={{
            required: t('indicate_account_send'),
            pattern: { value: /^([a-z]|[1-5]|\.bk){1,12}$/, message: t('invalid_account_format') },
            validate: () => (!isReady ? t('invalid_account_send') : true),
          }}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <Label size="md" htmlFor="send-receiver-input">
                {t('account_label')}
              </Label>
              <InputSection>
                <Input
                  {...field}
                  type="text"
                  maxLength={12}
                  onBlur={onBlur}
                  onChange={(e) => onChangeSendHandler(e, receiver_input_props)}
                  id="send-receiver-input"
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error?.message}</HintError>
              )}
            </InputWrapper>
          )}
        />
        <Controller
          name="amount"
          control={form.control}
          rules={{
            required: t('indicate_amount_send'),
            pattern: { value: /^(\d|.|,)+$/, message: t('invalid_amount') },
            validate: () => (!isReady ? t('invalid_amount_send') : true),
          }}
          render={({ field, fieldState }) => (
            <CoinAmountWrapper>
              <BankCoinAmount
                label={t('amount_label')}
                inputProps={amount_input_props}
                isSend
                isBank
                lastUsedToken={lastCryptoUsed}
                {...field}
              />
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error?.message}</HintError>
              )}
            </CoinAmountWrapper>
          )}
        />
      </WalletStepMainContentWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={close}>
          {t('back_action')}
        </ModalStepBlackButton>
        <ModalStepGreenButton type="submit" disabled={!isReady}>
          {t('send_action')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
