import React, { CSSProperties } from 'react'
import styled from 'app-view/styles/styled'
import { RotateSpinner } from 'react-spinners-kit'
import { ModalText } from './Modal'
import { keyframes } from '@emotion/react'

type StyledSpinnerProperties = {
  boxSize: number
  style?: CSSProperties
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const StyledSpinner = styled.div<StyledSpinnerProperties>`
  display: inline-block;
  position: absolute;
  right: 18px;
  width: ${({ boxSize }) => boxSize * 1.25}px;
  height: ${({ boxSize }) => boxSize * 1.25}px;

  div {
    margin: ${({ boxSize }) => (boxSize / 100) * 15}px;
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ boxSize }) => boxSize}px !important;
    height: ${({ boxSize }) => boxSize}px;
    border: ${({ boxSize }) => (boxSize / 100) * 15}px solid #29e0ad;
    border-radius: 50%;
    animation: ${rotate} 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #29e0ad transparent transparent transparent;

    &:nth-of-type(1) {
      animation-delay: -0.18s;
    }
    &:nth-of-type(2) {
      animation-delay: -0.1s;
    }
    &:nth-of-type(3) {
      animation-delay: -0.05s;
    }
  }
`
const LoaderContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`
const AccessLoaderWrapper = styled.div`
  width: 16px;
  margin-left: auto;
  margin-right: auto;
`
/**
 *  NOTE: Place this inside a relative positioned element container
 *
 * @param boxSize - The size of the  spinner for equal width & height in PX and it will calculate the rest of the animation.
 */
export const Spinner: React.FC<StyledSpinnerProperties> = ({
  boxSize,
  ...props
}: StyledSpinnerProperties) => (
  <StyledSpinner boxSize={boxSize} role="alert" aria-live="assertive" {...props}>
    <div />
    <div />
    <div />
    <div />
  </StyledSpinner>
)

export const Loader: React.FC<{ text?: string }> = ({ text, ...props }) => (
  <LoaderContainer id="modal-step-loader" {...props}>
    <RotateSpinner size={72} />
    {text ? (
      <ModalText align="center" style={{ paddingTop: '2rem' }}>
        {text}
      </ModalText>
    ) : (
      ''
    )}
  </LoaderContainer>
)

export const AccessLoader: React.FC<{ text?: string }> = ({ text, ...props }) => (
  <AccessLoaderWrapper>
    <LoaderContainer id="modal-step-loader" {...props}>
      <RotateSpinner size={72} />
      {text ? (
        <ModalText align="center" style={{ paddingTop: '2rem' }}>
          {text}
        </ModalText>
      ) : (
        ''
      )}
    </LoaderContainer>
  </AccessLoaderWrapper>
)

export default Spinner
