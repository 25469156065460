export default {
  fullscreen: 'Toàn màn hình',
  language: 'Ngôn ngữ',
  primary_currency: 'Tiền tệ chính',
  notifications: 'Cho phép Thông báo',
  deviceKeys: 'Danh sách Thiết bị',
  support: 'Hỗ trợ Trò chuyện',
  about: 'Về bitcash',
  api_status: 'Trạng thái kết nối',
  logout: 'Đăng xuất',
  referralLinkLabel: 'Liên kết Giới thiệu',
  referralLinkSubLabel: 'Chia sẻ liên kết này để kiếm token bitcash',
  copiedLinkBtnLabel: 'Đã sao chép ✓',
  copyLinkBtnLabel: 'Sao chép Liên kết',
  devices: 'Thiết bị',
  no_devices: 'Bạn không có bất kỳ thiết bị nào',
  delete_device: 'Bạn có muốn xóa thiết bị này?',
  keys: 'Khóa',
  no_keys: 'Bạn không có bất kỳ khóa nào',
  delete_key: 'Bạn có muốn xóa khóa này?',
  delete: 'Xóa',
  loading_devices_keys: 'Đang tải Thiết bị và Khóa',
  enter_public_key: 'Vui lòng nhập khóa công khai',
  enter_valid_public_key: 'Vui lòng nhập một khóa công khai hợp lệ',
  public_key_modal_title:
    'Nhập một khóa công khai để thêm vào phương pháp ký hợp lệ cho tài khoản của bạn',
  public_key: 'Khóa công khai',
  add_key: 'Thêm khóa',
  this_session: 'Phiên này',
  profile: 'Tùy chọn Hồ sơ',
  key_recovery: 'Khôi phục Khóa',
  add_partners: 'Thêm Đối tác',
  update_partner: 'Cập nhật Đối tác',
  generating_recovery_keys: 'T tạo ID Khôi phục',
  id_type: 'Loại ID',
  country: 'Quốc gia',
  not_found: 'Không tìm thấy tài khoản bitcash',
  partners_recommended: 'Đề xuất ít nhất 3 đối tác',
  dao_recovery: 'Khôi phục DAO',
  info_primary:
    'Đối tác Khôi phục là những người bạn tin tưởng để xác định và xác minh bạn trong trường hợp bạn mất điện thoại hoặc thiết bị và cần phục hồi tài khoản của bạn.',
  info_secondary: 'Vui lòng nhập tài khoản bitcash của mỗi Đối tác Khôi phục của bạn.',
  info_tertiary:
    'Khôi phục DAO cho phép tổ chức bitcash hành động như một trong những Đối tác Khôi phục của bạn.',
  warning: 'Cảnh báo',
  warning_secondary:
    'Nếu bạn muốn xóa/thêm bất kỳ đối tác nào, bạn phải trải qua quá trình khôi phục.',
  recovery_partner: 'Đối tác Khôi phục',
  agree: 'Tôi đồng ý thêm những đối tác này cho quá trình khôi phục khóa của tôi',
  Submit: 'gửi',
  date_of_birth: 'Ngày sinh',
  register_ip: 'IP Đăng ký',
}
