// TODO: TBD
export const Trade: React.FC = () => {
  // const { t } = useTranslation(['history, global'])
  // const account = useStore.useAccount()
  // // TODO: fix this
  // const [variables, setVariables] = useState<ChainGraphActionsSubscriptionVariables>({
  //   // @ts-ignore
  //   chain_name: config.eosChainName,
  //   // @ts-ignore
  //   contract: config.treasuryAccount,
  //   action: ['tradelog'],
  //   timestamp: 'desc' as Order_By,
  // })
  // const [filter, setFilter] = useState('recent')
  // const { data: sellData, loading: sellLoading } = useChainGraphActionsSubscription({
  //   variables: {
  //     ...variables,
  //     // @ts-ignore
  //     data_contains: {
  //       account: account,
  //       order_type: 'sell',
  //     },
  //     data_contains_or: {
  //       account: account,
  //       order_type: 'sell',
  //     },
  //   },
  //   skip: !account,
  //   fetchPolicy: 'no-cache',
  // })
  // const { data: buyData, loading: buyLoading } = useChainGraphActionsSubscription({
  //   variables: {
  //     ...variables,
  //     // @ts-ignore
  //     data_contains: {
  //       account: account,
  //       order_type: 'buy',
  //     },
  //     data_contains_or: {
  //       account: account,
  //       order_type: 'buy',
  //     },
  //   },
  //   skip: !account,
  //   fetchPolicy: 'no-cache',
  // })
  // const { data: allData, loading: allLoading } = useChainGraphActionsSubscription({
  //   variables: {
  //     ...variables,
  //     // @ts-ignore
  //     data_contains: {
  //       account: account,
  //     },
  //     data_contains_or: {
  //       account: account,
  //     },
  //   },
  //   skip: !account,
  //   fetchPolicy: 'no-cache',
  // })

  // const selectOptions: FilterOption[] = [
  //   {
  //     label: t('history:older'),
  //     // @ts-ignore
  //     value: { variables: { ...variables, timestamp: 'asc' as Order_By }, filter: 'older' },
  //   },
  //   {
  //     label: t('history:recent'),
  //     // @ts-ignore
  //     value: { variables: { ...variables, timestamp: 'desc' as Order_By }, filter: 'recent' },
  //   },
  //   {
  //     label: t('global:buy'),
  //     // @ts-ignore
  //     value: { variables: { ...variables, timestamp: 'desc' as Order_By }, filter: 'buy' },
  //   },
  //   {
  //     label: t('global:sell'),
  //     // @ts-ignore
  //     value: { variables: { ...variables, timestamp: 'desc' as Order_By }, filter: 'sell' },
  //   },
  // ]

  // // update filter and graphql subscription variables when the <Select> value changes
  // const onFilterChange = ({ value: { variables: vVariables, filter: vFilter } }: FilterOption) => {
  //   if (filter === vFilter) return

  //   setFilter(vFilter)
  //   // @ts-ignore
  //   setVariables((v) => ({
  //     ...v,
  //     // @ts-ignore
  //     timestamp: vVariables.timestamp,
  //     // @ts-ignore
  //     order_type: vVariables.order_type,
  //   }))
  // }
  // // data filtering
  // const filteredData = filter.match(/^(buy)/) ? buyData?.actions : sellData?.actions
  // const actionsList = filter.match(/^(buy|sell)/) ? filteredData : allData?.actions
  // const loading = buyLoading && sellLoading && allLoading

  // const displayMsnOptions = (option: string) => {
  //   switch (option) {
  //     case 'buy':
  //       return t('history:There is currently no buy history')
  //     case 'sell':
  //       return t('history:There is currently no sell history')
  //     default:
  //       return t('history:There is currently no history')
  //   }
  // }

  return (
    <>
      {/* <HistoryFilterContainer isMobile={Boolean(isMobile)}>
        <Select
          label={t('history:filterBy')}
          options={selectOptions}
          onChange={onFilterChange}
          gray
          width="100%"
        />
      </HistoryFilterContainer>
      <ActionsContainer>
        {loading && <HistorySkeleton />}

        {actionsList && actionsList.length === 0 && !loading && (
          <HighlightText>{displayMsnOptions(filter)}</HighlightText>
        )}
        {actionsList?.map((action: any) => (
          <TradeAction action={action} key={action.global_sequence} />
        ))}
      </ActionsContainer> */}
    </>
  )
}
