import { isMobile } from 'app-engine/library/utils'
import { Select } from 'app-view/components/Select'
import Spinner from 'app-view/components/Spinner'
import { ActionsContainer, HistoryFilterContainer } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { useChaingraphSWR } from 'hooks/use-chaingraph-swr.hook'
import uniqueBy from 'lodash.uniqby'
import { TabViewWrapper, TopSpinnerLoader } from 'pages/HistoryView/components/Styled'
import { useBankFilters } from 'pages/HistoryView/hooks/useBankFilters'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BankHistoryFilterProps, onChainHistoryService } from 'services/bitcash/history'
import { HistorySkeleton } from '../HistorySkeleton'
import BankAction from './Action'

const P = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  opacity: 0.7;
  color: #787878;
`

const fetchBankTransactionHistory = (props: BankHistoryFilterProps) =>
  onChainHistoryService.getBankTransactionHistory(props)

export const Bank: React.FC = () => {
  const { t } = useTranslation(['history, global'])
  const { variables, filter, filterOptions, onFilterChange } = useBankFilters()
  const { data, error, isLoading, isValidating } = useChaingraphSWR(
    fetchBankTransactionHistory(variables || filterOptions[0].value),
    {
      refreshInterval: 60000,
    },
  )

  const displayMsnOptions = (option: string) => {
    switch (option) {
      case 'to':
        return t('history:There is currently no send history')
      case 'from':
        return t('history:There is currently no receipt history')
      default:
        return t('history:There is currently no history')
    }
  }

  // TODO: Temp solution. We shouldn't filter by unique transaction_id. Traces should show be one per action...
  const filteredHistory = uniqueBy(data?.actions, 'transaction_id')

  return (
    <TabViewWrapper>
      {isValidating && (
        <TopSpinnerLoader>
          <Spinner boxSize={20} />
        </TopSpinnerLoader>
      )}
      <HistoryFilterContainer isMobile={Boolean(isMobile)}>
        <Select
          label={t('history:filterBy')}
          options={filterOptions}
          onChange={onFilterChange}
          gray
          width="100%"
        />
      </HistoryFilterContainer>

      <ActionsContainer>
        {error && <P>{t('history:An error occurred')}</P>}
        {!filteredHistory?.length && !isLoading && <P>{displayMsnOptions(filter)}</P>}
        {isLoading && <HistorySkeleton />}
        {!isLoading &&
          filteredHistory.length > 0 &&
          filteredHistory?.map((action: any) => (
            <BankAction actionData={action} key={action.global_sequence} />
          ))}
      </ActionsContainer>
    </TabViewWrapper>
  )
}
