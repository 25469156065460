import React from 'react'
import { SkeletonCard } from 'app-view/components/Skeletons'

const randomArrLength = Math.floor(Math.random() * 3) + 1
const skeletonArr = [...new Array(randomArrLength)]

export const OfferListSkeleton = () => (
  <>
    {skeletonArr.map((n, i) => (
      <SkeletonCard key={`skeleton_card_${i}-${n}`} />
    ))}
  </>
)
