export default {
  slogan: '加密变得容易。',
  total: '总额',
  total_stable: '可用bitcash',
  buy: '买入',
  swap: '硬币',
  sell: '卖出',
  all: '全部',
  p2p: 'C2C',
  share_link: '分享链接',
  support_channel: '支持聊天',
  support: '支持',
  available: '可用的',
  error: '网络错误',
  loading: '数据加载中',
  ok: '好的',
  back: '返回',
  login_with: '登录',
  no_messages: '暂无信息',
  add_key: `<span>将密钥添加到</span>
  <span>现有帐户</span>：`,
  create_account: '创建账户',
  login: '登入',
  restore_account: '恢复帐户',
  cancel: '取消',
  received: '已收到',
  generic_error: '抱歉，网络错误。 请再试一次',
  appStatePausedMsn: '对不起，目前交易已暂停以进行维护。',
  push_transaction_error: `我们很抱歉！
  我们无法{{action}}报价，
  请在几秒钟后尝试。
  `,
  push_transaction_error_action_process: '处理',
  non_auth_user: `The logged user has not
  been approved yet. Connect to
  our Discord channel:`,
  add_trust: '添加到网络',
  remove_trust: '从网络中删除',
  trust_request_sent: '待定',
  navigation_trade: '交易',
  navigation_wallet: '钱包',
  navigation_history: '历史',
  navigation_p2p: 'C2C',
  navigation_swap: '硬币',
  navigation_bank: '银行',
  order_type: '订单类型',
  last_update: '最后更新',
  order_amount: '订单金额',
  net_amount: '收到的金额',
  order_cost: '订单成本',
  minus_network: '(减去网络费用)',
  confirm: '确认',
  continue_question: `您要继续吗？
    
  `,
  no_data: '没有数据',
  cancelled_on: '取消日期',
  filled_on: '完成日期',
  take_me_there: '带我到那里',
  required_action: '所需采取的行动',
  data_warning_message: '为了获得奖励资格并启用帐户恢复，请更新您的信息。',

  login_request: '登录请求',
  login_request_description: '您是否要登录到<0>{{appName}}</0>？',
  signature_request: '签名请求',
  dboard_voting_transaction: `请确认您是否要
  <0>在<1>dBoard</1>上投票{{option}}。`,
  evm_transfer_transaction: `您是否要转账<0>{{amount}}</0>
  到<1>{{to}}</1>？`,
  pomelo_grant_donation: `请确认您是否要
  <0>在<1>Pomelo</1>上捐款{{grant}}。`,
  overview: '概览',
  details: '细节',
  transaction: '交易',
  data: '数据',
  raw_data: '原始数据',
  add_second_device: '请记得添加第二个设备以备份您的帐户，以避免数据丢失。',
  remind_me_later: '稍后提醒',
  added_trust: '已添加到您的信任网络',
  qr_reader: 'QR阅读器',
  sign_success: '签名成功',
}
