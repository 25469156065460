import { createContextHook } from '@blockmatic/hooks-utils'
import { useSetState } from 'react-use'
import { config } from 'app-config'
import {
  RegistrationData,
  RegistrationActionProps,
  RegistrationStateProps,
  SignActionFnType,
  SignActionPropsType,
  RegistrationType,
} from 'app-engine/types/registration'
import { newAnchorLink } from 'pages/AccountView/utils'
import { AnchorError } from 'app-engine/library/errors'
import { PublicKey } from 'anchor-link'
import { eosCoreApi } from 'app-engine/library/eosio'

export const registrationInitialState = {
  account: '',
  referrer: '',
  phone_number: '',
  email_address: '',
  device_name: '',
  registrationType: null,
  signingActor: null,
  error: '',
  public_key: undefined,
  cred_id: undefined,
  anchorLink: undefined,
}

const signAction: SignActionFnType = ({ account, permission, referrer, name = 'reg' }) => ({
  account: config.contracts.bitcashAccounts,
  name,
  authorization: [
    {
      actor: account,
      permission,
    },
  ],
  data: { account, referrer },
})

const useRegistrationFn = (): [RegistrationStateProps, RegistrationActionProps] => {
  const [state, setState] = useSetState<RegistrationStateProps>(registrationInitialState)

  const setRegistrationData = (data: Partial<RegistrationData>) => setState(data)

  const setRegistrationError = (error: string) => setState({ error })

  const setInitialState = () => setState(registrationInitialState)

  // registration actions
  /**
   * @deprecated - No sign action required. Happening on BE already
   */
  const registerWithAnchor = async ({ account, permission, referrer }: SignActionPropsType) => {
    console.log('register with Anchor')
    try {
      const anchorLink = state.anchorLink || newAnchorLink

      const { transaction } = await anchorLink.transact({
        action: signAction({ account, permission, referrer }),
      })

      console.log('identity data', { account, transaction })

      // reset auth after success
      setState(registrationInitialState)
    } catch (error) {
      throw new AnchorError((error as Error).message)
    }
  }

  // verification actions

  const verifyAnchorAccount = async () => {
    // console.log('register with Anchor - Verify Identity')
    try {
      const anchorLink = state.anchorLink || newAnchorLink

      if (!state.anchorLink) setState({ anchorLink })

      // Use the anchor-link identity method with the chain id to establish a session
      const identify = await anchorLink.identify({ scope: 'bitcash_app' })
      const identity_data = await eosCoreApi.get_account(identify.signer.actor.toString())
      const public_key = PublicKey.from(
        // @ts-ignore
        identity_data.permissions[0].required_auth.keys[0].key,
      ).toString()

      setState({
        registrationType: RegistrationType.ANCHOR,
        signingActor: {
          actor: identify.signer.actor.toString(),
          permission: identify.signer.permission.toString(),
        },
        public_key,
      })
    } catch (error) {
      throw new AnchorError((error as Error).message)
    }
  }

  return [
    state,
    {
      setRegistrationData,
      setRegistrationError,
      setInitialState,
      registerWithAnchor,
      verifyAnchorAccount,
    },
  ]
}

export const [useRegistration, RegistrationProvider] = createContextHook(
  useRegistrationFn,
  'You must wrap your application with <RegistrationProvider /> in order to useRegistration().',
)
