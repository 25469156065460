// global.ts
export default {
  total: 'Tổng cộng',
  total_stable: 'Bitcash khả dụng',
  buy: 'Mua',
  swap: 'Đổi',
  sell: 'Bán',
  share_link: 'Tạo liên kết',
  all: 'Tất cả',
  p2p: 'P2P',
  open: 'Mở',
  cancelled: 'Đã hủy',
  filled: 'Đã lấp đầy',
  cancelled_on: 'Đã hủy vào.',
  filled_on: 'Đã hoàn thành vào.',
  support_channel: 'Kênh hỗ trợ',
  support: 'Hỗ trợ',
  available: 'BitUSD khả dụng',
  error: 'Đã xảy ra lỗi.',
  loading: 'Đang tải dữ liệu của bạn',
  ok: 'OK',
  back: 'Quay lại',
  no_messages: 'Chưa có tin nhắn',
  cancel: 'Hủy',
  received: 'Đã nhận',
  generic_error: 'Xin lỗi, đã xảy ra một lỗi. Vui lòng thử lại.',
  appStatePausedMsn: 'Xin lỗi, giao dịch hiện đang bị tạm dừng để bảo dưỡng.',
  push_transaction_error: `Rất tiếc!
	Chúng tôi không thể {{action}} offer,
	vui lòng thử lại sau vài giây.
	`,
  push_transaction_error_action_process: 'xử lý',
  non_auth_user: `Người dùng đã đăng nhập chưa
	được phê duyệt. Kết nối với
	kênh Discord của chúng tôi:`,
  remind_me_later: 'Nhắc tôi sau',
  add_trust: 'Thêm vào Mạng',
  remove_trust: 'Loại bỏ khỏi Mạng',
  trust_request_sent: 'Đang chờ',
  added_trust: 'Đã thêm vào mạng lưới tin tưởng của bạn',
  add_second_device:
    'Hãy nhớ thêm một Thiết bị thứ hai để sao lưu tài khoản của bạn để tránh mất dữ liệu.',
  navigation_trade: 'Giao dịch',
  navigation_wallet: 'Ví',
  navigation_history: 'Lịch sử',
  navigation_p2p: 'P2P',
  navigation_swap: 'Đổi',
  navigation_bank: 'Ngân hàng',
  order_type: 'Loại đơn hàng:',
  last_update: 'Cập nhật cuối cùng:',
  order_amount: 'Số lượng Coin:',
  net_amount: 'Số tiền nhận được:',
  order_cost: 'Giá đơn hàng:',
  minus_network: '(trừ phí mạng)',
  confirm: 'Xác nhận',
  continue_question: `Bạn có muốn tiếp tục không?
	  
	`,
  no_data: 'Không có dữ liệu',
  take_me_there: 'Đưa tôi tơi đo',
  required_action: 'Yêu cầu hành động',
  data_warning_message:
    'Để đủ điều kiện nhận phần thưởng và cho phép khôi phục tài khoản, vui lòng cập nhật thông tin của bạn.',

  login_request: 'Yêu cầu đăng nhập',
  login_request_description: 'Bạn có muốn đăng nhập vào <0>{{appName}}</0>?',
  signature_request: 'Yêu cầu chữ ký',
  dboard_voting_transaction: `Vui lòng xác nhận rằng bạn muốn
  <0>bầu chọn {{option}}</0> trên <1>dBoard</1>.`,
  evm_transfer_transaction: `Bạn có muốn chuyển <0>{{amount}}</0>
  đến <1>{{account}}</1> từ <2>{{appName}}</2>?`,
  pomelo_grant_donation: `Vui lòng xác nhận rằng bạn muốn
  <0>quyên góp cho {{grant}}</0> trên <1>Pomelo</1>.`,
  overview: 'Tổng quan',
  details: 'Chi tiết',
  transaction: 'Giao dịch',
  data: 'Dữ liệu',
  raw_data: 'Dữ liệu thô',
  qr_reader: 'Đọc mã QR',
  sign_success: 'Chữ ký thành công',
}
