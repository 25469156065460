// wallet.ts
export default {
  deposit: 'Gửi tiền',
  withdrawal: 'Rút tiền',
  success: 'Giao dịch thành công!',
  with_memo: 'Với ghi chú?',
  send: 'Gửi',
  loan: 'Khoản vay {{ticker}}',
  leverage: 'Đòn bẩy',
  tab_usd_title: 'USD',
  tab_crypto_title: 'Tiền điện tử',
  deposit_success: 'Gửi tiền thành công!',
  amount_deposit: 'Số tiền để gửi:',
  currency_receive: 'Đơn vị tiền tệ nhận:',
  amount_placeholder: 'Nhập số tiền',
  transaction_id: 'ID giao dịch',
  deposit_limit: 'Giới hạn gửi tiền!',
  deposit_confirm:
    'Xác nhận gửi tiền của bạn là\n<span>{{base_amount}}</span> cho <span>{{target_amount}}</span>.',
  exchange_rate_amount: 'Tỷ giá: {{exchange_rate}}',
  exchange_rate: 'Tỷ giá',
  exchange_rate_fee: 'Phí:',
  not_rate_found: 'Không có sẵn',
  account_label: 'Gửi đến:',
  currency_label: 'Tiền tệ',
  amount_label: 'Số tiền:',
  amount: 'Số tiền',
  send_success: 'Gửi thành công!',
  confirm_action: 'Xác nhận',
  send_action: 'Gửi',
  memo_label: 'Ghi chú',
  back_action: 'Quay lại',
  next_action: 'Tiếp theo',
  bitcash_transfer: 'chuyển khoản bitcash',
  memo_placeholder: 'Nhập tin nhắn tại đây',
  loading_action: 'Đang xử lý giao dịch...',
  exchange: 'Đổi',
  amount_withdraw: 'Số tiền để rút:',
  limit_alert: 'Giới hạn tối đa 24 giờ',
  withdraw_fee: 'Phí',
  withdraw_confirm:
    'Xác nhận rút tiền\n<span>{{base_amount}}</span> cho <span>{{target_amount}}<span>.',
  withdraw_success: 'Rút tiền thành công!',
  exchange_success: 'Đổi thành công!',
  exchange_send: 'Bạn gửi',
  exchange_get: 'Bạn nhận',
  invalid_account_format: 'Định dạng tài khoản không hợp lệ',
  indicate_account_send: 'Chỉ định tài khoản để gửi',
  invalid_account_send: 'Tài khoản gửi không hợp lệ',
  indicate_amount_send: 'Chỉ định số tiền để gửi',
  invalid_amount: 'Số tiền không hợp lệ',
  invalid_amount_send: 'Số tiền gửi không hợp lệ',
  required_memo: 'Chỉ định ghi chú cần thiết',
  memo_empty: 'Ghi chú trống',
  withdraw_helper: 'Giới hạn hàng ngày là 5% đến 10% của tổng số dư trong ngân hàng của bạn.',
  withdraw_reset_label: `
	
	Đặt lại hàng ngày:
	`,
}
