import React from 'react'

const ATOMCosmosIcon: React.FC = (props) => (
  <svg viewBox="0 0 54 55" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M53.2966 18.2167C51.2067 6.43235 39.4908 0.916865 28.3918 0.841321C16.1944 0.7591 3.08809 4.76957 0.703618 18.2167C-0.423696 24.5727 -0.0243682 30.7331 0.703618 37.11C2.19535 50.1685 15.2301 55.1519 26.9997 54.8251C38.6862 54.5008 51.7657 50.5124 53.2966 37.1096C54.0245 30.7331 54.4234 24.5727 53.2966 18.2167Z"
      fill="#B7B9C8"
    />
    <path
      d="M27 42.5C35.0081 42.5 41.5 36.0081 41.5 28C41.5 19.9919 35.0081 13.5 27 13.5C18.9919 13.5 12.5 19.9919 12.5 28C12.5 36.0081 18.9919 42.5 27 42.5Z"
      fill="#2E3148"
    />
    <path
      d="M27 36.4139C31.6467 36.4139 35.4136 32.647 35.4136 28.0003C35.4136 23.3536 31.6467 19.5867 27 19.5867C22.3533 19.5867 18.5864 23.3536 18.5864 28.0003C18.5864 32.647 22.3533 36.4139 27 36.4139Z"
      fill="#1B1E36"
    />
    <path
      d="M27.0299 15.3496C25.4647 15.3496 24.1956 21.0266 24.1956 28.0297C24.1956 35.0327 25.4647 40.7098 27.0299 40.7098C28.5951 40.7098 29.8642 35.0327 29.8642 28.0297C29.8642 21.0266 28.5951 15.3496 27.0299 15.3496ZM27.2256 39.9942C27.0466 40.2329 26.8676 40.0538 26.8676 40.0538C26.1468 39.2186 25.7864 37.667 25.7864 37.667C24.5256 33.6094 24.8257 24.8975 24.8257 24.8975C25.4182 17.9811 26.4961 16.3472 26.8629 15.9845C26.9003 15.9475 26.9496 15.9248 27.0021 15.9206C27.0546 15.9163 27.1069 15.9308 27.1498 15.9613C27.6821 16.3384 28.1284 17.9155 28.1284 17.9155C29.4465 22.8085 29.3272 27.4032 29.3272 27.4032C29.4465 31.4011 28.6667 35.8764 28.6667 35.8764C28.0664 39.2775 27.2256 39.9942 27.2256 39.9942Z"
      fill="#6F7390"
    />
    <path
      d="M38.0283 21.7194C37.249 20.3613 31.6937 22.0857 25.6163 25.5706C19.539 29.0555 15.2521 32.9817 16.0308 34.3393C16.8095 35.6968 22.3654 33.9729 28.4428 30.4881C34.5201 27.0032 38.807 23.0769 38.0283 21.7194V21.7194ZM16.7497 34.153C16.4514 34.1154 16.5188 33.8702 16.5188 33.8702C16.8848 32.8295 18.0494 31.7447 18.0494 31.7447C20.9428 28.6317 28.6494 24.5567 28.6494 24.5567C34.9428 21.6287 36.8977 21.7522 37.3936 21.8887C37.4445 21.903 37.4887 21.9347 37.5184 21.9784C37.5481 22.0221 37.5614 22.0749 37.556 22.1275C37.4963 22.7771 36.3476 23.9487 36.3476 23.9487C32.7618 27.5283 28.7168 29.7117 28.7168 29.7117C25.3083 31.8055 21.0384 33.3551 21.0384 33.3551C17.7893 34.5259 16.7499 34.153 16.7499 34.153H16.7497Z"
      fill="#6F7390"
    />
    <path
      d="M38.0008 34.3851C38.7861 33.0306 34.51 29.0863 28.454 25.5752C22.398 22.0641 16.8433 20.3183 16.0587 21.6752C15.2741 23.032 19.5495 26.974 25.609 30.485C31.6685 33.9961 37.2162 35.7421 38.0008 34.3851ZM16.5791 22.1986C16.4631 21.9236 16.708 21.8573 16.708 21.8573C17.7921 21.6526 19.315 22.1191 19.315 22.1191C23.4579 23.0626 30.8454 27.69 30.8454 27.69C36.5332 31.6701 37.4055 33.4232 37.5362 33.9209C37.5495 33.9719 37.5444 34.026 37.5217 34.0736C37.4989 34.1212 37.4602 34.1593 37.4121 34.181C36.819 34.4526 35.2305 34.048 35.2305 34.048C30.3345 32.7352 26.4189 30.33 26.4189 30.33C22.9007 28.4324 19.4219 25.5127 19.4219 25.5127C16.7785 23.287 16.5799 22.2016 16.5799 22.2016L16.5791 22.1986Z"
      fill="#6F7390"
    />
    <path
      d="M27 29.4916C27.8239 29.4916 28.4918 28.8237 28.4918 27.9998C28.4918 27.1759 27.8239 26.5081 27 26.5081C26.1761 26.5081 25.5082 27.1759 25.5082 27.9998C25.5082 28.8237 26.1761 29.4916 27 29.4916Z"
      fill="#B7B9C8"
    />
    <path
      d="M33.1163 23.1671C33.5941 23.1671 33.9815 22.7663 33.9815 22.272C33.9815 21.7777 33.5941 21.377 33.1163 21.377C32.6384 21.377 32.251 21.7777 32.251 22.272C32.251 22.7663 32.6384 23.1671 33.1163 23.1671Z"
      fill="#B7B9C8"
    />
    <path
      d="M18.9145 26.2095C19.3924 26.2095 19.7797 25.8088 19.7797 25.3145C19.7797 24.8202 19.3924 24.4194 18.9145 24.4194C18.4366 24.4194 18.0493 24.8202 18.0493 25.3145C18.0493 25.8088 18.4366 26.2095 18.9145 26.2095Z"
      fill="#B7B9C8"
    />
    <path
      d="M25.2398 37.1893C25.7176 37.1893 26.105 36.7886 26.105 36.2942C26.105 35.7999 25.7176 35.3992 25.2398 35.3992C24.7619 35.3992 24.3745 35.7999 24.3745 36.2942C24.3745 36.7886 24.7619 37.1893 25.2398 37.1893Z"
      fill="#B7B9C8"
    />
  </svg>
)

export default ATOMCosmosIcon
