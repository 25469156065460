import React from 'react'
import { useStore } from 'app-engine/store'
import { SwapIcon } from 'app-view/components/SwapIcon'
import { useTranslation } from 'react-i18next'
import { Asset } from '@greymass/eosio'
import theme from 'app-config/theme'
import {
  ActionContainer as OfferContainer,
  BoldText,
  RowContainer,
  RowTextContainer,
  Text,
} from 'app-view/components/Styled'
import { get_amount } from 'app-view/lib/utils'

export interface BankActionProps {
  action: string
  authorization: any
  chain: string
  contract: string
  data: any
  global_sequence: string
  transaction_id: string
  transaction?: { block?: { timestamp: any } | null | undefined } | null | undefined
}

const { colors } = theme

const BankAction: React.FC<{ actionData: BankActionProps }> = ({ actionData }) => {
  const { t } = useTranslation(['history'])
  const account = useStore.useAccount()
  const { data, action } = actionData
  const isDeposit = action === 'depositlog'
  const isTransfer = action.match(/(stbtransfer|crptransfer)/)
  const isWithdraw = action === 'stbwdllog'
  const isReceiving = isDeposit || (isTransfer && data.to === account)
  const amount = Asset.from(isDeposit ? data.bitcash_quantity.quantity : data.quantity.quantity)
  const symbol = amount.symbol.code.toString()
  const isStable = symbol.includes('BIT')
  const withdrawData = {
    fee: Asset.from(data.fee?.quantity || '0.00 USDT'),
    quantity: Asset.from(data.quantity?.quantity || '0.00 BITUSD'),
    receive_quantity: Asset.from(data.receive_quantity?.quantity || '0.0000 USDT'),
  }

  return (
    <OfferContainer>
      <RowContainer>
        <SwapIcon coin={symbol} />
        <RowTextContainer>
          <Text color={colors?.darkGray} dollars>
            <span style={{ textTransform: 'capitalize' }}>
              {isDeposit && `${t('history:deposit')}`}
              {isWithdraw && `${t('history:withdraw')}`}
            </span>
            {isTransfer && !isReceiving && `${t('history:to')}: ${data.to}`}
            {isTransfer && isReceiving && `${t('history:from')}: ${data.from}`}
          </Text>
          <BoldText color={colors?.darkGray}>{symbol.replace('BIT', 'bit')}</BoldText>
        </RowTextContainer>
      </RowContainer>
      <RowContainer>
        <RowTextContainer>
          <BoldText color={isWithdraw || !isReceiving ? colors?.red : colors?.black} dollars>
            {`${isWithdraw || !isReceiving ? '-' : ''}${
              isStable
                ? get_amount(
                    `${amount.value + withdrawData.fee.value} ${amount.symbol.code.toString()}`,
                  )
                : parseFloat(amount.toString().split(' ')[0])
            }`}
          </BoldText>
          <Text
            style={{ textAlign: 'right', textTransform: 'capitalize' }}
            color={colors?.black}
            dollars
          >
            {isDeposit && t('history:deposit')}
            {isTransfer && t('history:transfer')}
            {isWithdraw && t('history:withdraw')}
          </Text>
        </RowTextContainer>
      </RowContainer>
    </OfferContainer>
  )
}

export default BankAction
