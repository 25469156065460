import { createContextHook } from '@blockmatic/hooks-utils'
import { useEffect } from 'react'
import isEqual from 'lodash.isequal'
import { config } from 'app-config'
import { useStore } from 'app-engine/store'
import { timeout } from 'app-engine/library/utils'
import { useSetState } from 'react-use'
import { SmartContractError } from 'app-engine/library/errors'
import { WizardStep } from 'app-view/components/Wizard/types'
import { useWizard } from 'app-view/components/Wizard'
import { WITHDRAWAL_STEPS } from '../steps/withdrawal-steps/index'
import { SEND_STEPS } from '../steps/send-steps/index'
// TODO: Reemplace with @greymass/eosio
import { Asset, asset } from 'eos-common'
import React from 'react'
import { InputHandlerProps } from '../steps/send-steps/SendToAccount'
import { EXCHANGE_STEPS } from '../steps/exchange'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as ChainGraph from 'app-engine/graphql/generated/chaingraph'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'

interface UseBankWalletStateActionProps {
  type: 'withdrawal' | 'send' | 'exchange' | ''
  amount: Asset
  sender: string
  receiver: string
  memo?: string
  limit: number
  fee: number
}

type UseBankWalletState = {
  action: UseBankWalletStateActionProps
  loading: boolean
  error: string
  submitted: boolean
  isReady: boolean
}

const initialState: UseBankWalletState = {
  action: {
    type: '',
    sender: '',
    receiver: '',
    amount: asset('0.00 USDT'),
    memo: '',
    limit: 0,
    fee: 0,
  },
  isReady: false,
  loading: false,
  error: '',
  submitted: false, // will be always false unless just initiated
}

type BankWalletActionResponse = Promise<{
  success: boolean
}>

export type BankActions = 'withdrawal' | 'send' | 'exchange'
export interface UseBankWalletActions {
  updateAction: (action_update: Partial<UseBankWalletStateActionProps>) => void
  setNewAction: (type: 'withdrawal' | 'send') => void
  submitAction: (data: any) => BankWalletActionResponse
  submitExchangeAction: (data: ExchangeProps) => BankWalletActionResponse
  resetError: () => void
  startNewAction: (type: BankActions) => WizardStep[]
  setLoading: (loading?: boolean) => void
  setError: (error: string) => void
  setReady: (ready: boolean) => void
  onChangeSendHandler: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputProps?: InputHandlerProps,
  ) => void
  getUserLimits: (account: string) => Promise<string>
}

function getBankSteps(type: BankActions) {
  switch (type) {
    case 'withdrawal': {
      return WITHDRAWAL_STEPS
    }
    case 'send': {
      return SEND_STEPS
    }
    default: {
      return EXCHANGE_STEPS
    }
  }
}

type ExchangeProps = {
  pair_id: number
  amount: string
  coin: string
}

export const useBankWalletFn = (): [UseBankWalletState, UseBankWalletActions] => {
  const account = useStore.useAccount()
  const { pushTransaction } = useStore()
  const [{ open }] = useWizard()
  const [state, setState] = useSetState(initialState)

  useEffect(() => {
    if (open) {
      setState({ error: '' })
    } else {
      setState(initialState)
    }
  }, [open, setState])

  const resetError = () => {
    setState({
      error: '',
    })
  }

  const setLoading = (loading?: boolean) => setState({ loading: Boolean(loading) })

  const setReady = (ready: boolean) => setState({ isReady: ready })

  const updateAction = (action_update: any) => {
    const updated_action = { ...state.action, ...action_update }

    if (!isEqual(state.action, updated_action)) setState({ action: updated_action })
  }

  const setNewAction = (type: any) =>
    setState({ action: { ...initialState.action, type, sender: account } })

  const pushBankExchangeTransaction = async ({ pair_id, amount, coin }: ExchangeProps) => {
    try {
      const transaction = {
        actions: [
          {
            account: config.contracts.bitcashBank,
            name: 'forextrade',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data: {
              pair_id,
              account,
              quantity: {
                quantity: `${amount} ${coin}`,
                contract: config.contracts.bitcashToken,
              },
            },
          },
        ],
      }
      await pushTransaction(transaction)
    } catch (error) {
      if (error instanceof Error) {
        throw new SmartContractError(error.message)
      }
    }
  }

  const pushBankWalletTransaction = async (data: any) => {
    const symbol = state.action.amount.symbol.code().toString()
    const token = findTokenBySymbolCode(symbol)
    const isSend = state.action.type === 'send'
    console.log('token.token_contract', token?.token_contract)
    try {
      const transaction = {
        actions: [
          {
            account: config.contracts.bitcashBank,
            // TODO: if isSend, for v2 => name: symbol_code === 'BITUSD' ? 'stbtransfer' : 'crptransfer',
            name: isSend ? 'stbtransfer' : 'withdrawstbl',
            authorization: [
              {
                actor: account,
                permission: 'active',
              },
            ],
            data,
          },
        ],
      }

      console.log('pushP2PTransaction ==> transaction: ', transaction)

      await pushTransaction(transaction)
    } catch (error) {
      if (error instanceof Error) {
        throw new SmartContractError(error.message)
      }
    }
  }

  const submitAction = async (data: any) => {
    setState({ loading: true })
    try {
      await pushBankWalletTransaction(data)
      await timeout(3000)
      setState({ submitted: true })
      return { success: true }
    } catch (err) {
      setState({ error: (err as Error).message })
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const submitExchangeAction = async (data: ExchangeProps) => {
    setState({ loading: true })
    try {
      await pushBankExchangeTransaction(data)
      await timeout(3000)
      setState({ submitted: true })
      return { success: true }
    } catch (err) {
      setState({ error: (err as Error).message })
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const startNewAction = (type: BankActions) => {
    const new_steps = getBankSteps(type)
    setNewAction(type)
    return new_steps
  }

  const setError = (error: string) => setState({ error })

  const onChangeSendHandler = (
    { target: { name, value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputProps: InputHandlerProps | undefined,
  ) => {
    const key: 'receiver' | 'amount' | 'memo' = name as 'receiver' | 'amount' | 'memo'

    if (name.match(/(receiver|memo|amount)/g)) {
      const isFormReady =
        Boolean(inputProps!.form.getValues('amount')) &&
        Boolean(inputProps!.form.getValues('receiver'))
      updateAction({
        [key]: typeof value === 'string' ? value.trim().toLowerCase() : value,
      })
      setState({
        // NOTE: Ready if either receiver or amount have value
        isReady: inputProps ? isFormReady : true,
      })
      if (inputProps)
        inputProps.form.setValue(
          inputProps.field,
          typeof value === 'string' ? value.trim().toLowerCase() : value,
        )
    }
  }

  // useEffect(() => console.log('🚀 New walletActionState', state), [state])

  const getUserLimits = async (account: string) => {
    const { data } = await apolloClient.query<
      ChainGraph.GetChainGraphTableRowsQuery,
      ChainGraph.GetChainGraphTableRowsQueryVariables
    >({
      query: ChainGraph.GetChainGraphTableRowsDocument,
      variables: {
        where: {
          chain: {
            _eq: config.eosChainName,
          },
          contract: {
            _eq: config.contracts.bitcashBank,
          },
          primary_key: {
            _eq: account,
          },
          table: {
            _eq: 'wdlperiods',
          },
        },
      },
    })

    if (data?.table_rows && data?.table_rows.length >= 0) {
      const limit = data?.table_rows.find((item) => item.data.account === account)
      if (limit) {
        return limit.data.updated_at
      }
    }

    return ''
  }

  return [
    state,
    {
      updateAction,
      startNewAction,
      resetError,
      setError,
      submitAction,
      submitExchangeAction,
      setNewAction,
      setReady,
      onChangeSendHandler,
      getUserLimits,
      setLoading,
    },
  ]
}

export const [useBankWallet, BankWalletProvider] = createContextHook(
  useBankWalletFn,
  'You must wrap your application with <BankWalletProvider /> in order to useWallet().',
)
