export default {
  recently_trust: 'trusted you recently.',
  trust_back: 'Do you want to trust back?',
  chat: 'Chat',
  untrust_user: 'Untrust User',
  matched: 'Matched',
  unmatched: 'Unmatched',
  unmatched_user: 'Unmatched - You',
  unmatched_all: 'Unmatched - All',
  private_offers: 'Offers',
  private_my_offers: 'My Offers',
  trusted: 'Trusted',
  failure_message: 'Failure Message',
  trust_request: 'Requests',
  error_body: `We had an inconvenience
  while trying to create your
  $ offer.`,
  error_body_payment: `We had an inconvenience
  while trying to confirm your
  $ offer.`,
  request_success: 'Request sent successfully',
  do_you_want: `Do you want to take the offer of
  {{cryptoAmount}} {{methodDetails}}?`,
  error_body_cancel: `We had an inconvenience
  while trying to cancel your
  $ offer.`,
  sell: 'Sell',
  buy: 'Buy',
  offers: 'Completed Offers',
  mutual: 'Mutual',
  trust_users: 'Network',
  cancelled_offer: 'Cancelled offer',
  closed_offer: 'Closed Offer',
  open_offer: 'Open Offer',
  // Open Offer Filter
  clearing_filters: 'Clearing P2P offers filters...',
  applyng_filters: 'Applying P2P offers filters...',
  clear_filter_btn_text: 'Clear filters',
  select_multiple_currency: 'Select multiple currency',
  payment_method: 'Payment Method',
  prices: 'Prices',
  // ? PAYMENT METHODS
  paypal: 'PayPal',
  venmo: 'Venmo',
  cash_app: 'Cash App',
  zelle: 'Zelle',
  bank_transfer: 'Bank Transfer',
  sinpe_movil: 'SINPE Móvil',
  oxxo: 'OXXO',
  ovo: 'OVO',
  duitnow: 'DuitNow',
  grab: 'Grab',
  gojek: 'Gojek',
  opay: 'OPay',
  palmpay: 'PalmPay',
  kuda: 'Kuda',
  wechat: 'WeChat',
  alipay: 'Alipay',
  revolut: 'Revolut',
  bizum: 'Bizum',
  lyf: 'Lyf',

  network: 'Network',
  public: 'Public',
  private: 'Private',

  offer: 'offer',
  sell_now: 'Sell Now',
  buy_now: 'Buy Now',
  filter_by_title: 'Filter by',
  filter_option_buy: 'Buy',
  filter_option_sell: 'Sell',
  filter_option_recent: 'Recent',
  filter_option_older: 'Older',
  filter_option_open: 'Open',
  filterOptionCancelled: 'Cancelled',
  filterOptionCompleted: 'Completed',
  no_offers: 'There are currently no {{filter}} offers',
  no_request: 'There are currently no requests',
  no_accounts: 'There are currently no accounts',
  amount_to_sell: `How much {{symbol}}
  do you want to sell?`,
  how_you_want_get_paid: `How do you want
  to be paid?`,
  sell_confirm: `Confirm your offer to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  You will receive <span>{{amount}}</span> using
  <span>{{method}}</span>.`,

  amount_to_buy: `How much {{symbol}}
  do you want to buy?`,
  how_you_want_to_pay: `How do you want
  to pay?`,
  buy_confirm: `Confirm your offer to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  You will send <span>{{amount}}</span> using
  <span>{{method}}</span>.`,

  buy_duplicating: `Duplicating {{amount}} {{method}} buy offer`,
  sell_duplicating: `Duplicating {{amount}} {{method}} sell offer`,

  from: 'from',
  using: 'using',
  ok: 'Ok',

  creating_buy_offer: 'Creating Buy offer',
  'Congrats we have found a match': 'Congrats we have found a match',
  'wants to sell': 'wants to sell',
  please_contact: 'Please contact',
  'and send': 'and send',

  'You are matched': 'You are matched',
  'Did you send': 'Did you send',
  to: 'to',
  on: 'on',
  'send you': 'send you',

  'You have sent': 'You have sent',
  'Please remind': 'Please remind',
  'to confirm receipt of': 'to confirm receipt of',
  Contact: 'Chat',

  Next: 'Next',
  Back: 'Back',
  Confirm: 'Confirm',
  Done: 'Done',

  'If the problem persists, ask for support on Telegram':
    'If the problem persists, ask for support on Telegram',

  no_match_yet: "Sorry, there's no match yet!",
  will_connect_with_buyer_soon: 'We’ll connect you with a buyer soon',
  will_connect_with_seller_soon: 'We’ll connect you with a seller soon',
  check_back_in_few_minutes: 'Please check back in a few minutes',

  confirming_payment: 'Confirming payment',
  Not_yet: 'Not yet',
  Yes: 'Yes',
  No: 'No',
  Retry: 'Retry',
  'Work in progress!': 'Work in progress!',
  'You have sold': 'You have sold',
  creating_sell: 'Creating sell offer',
  'Congrats we have found a match!': 'Congrats we have found a match!',
  'wants to buy': 'wants to buy',
  'Please ask': 'Please ask',
  'to send you': 'to send you',
  'Did you receive': 'Did you receive',
  zero_balance: "You don't have enough {{symbol}} balance",

  confirmed_with: 'You have confirmed with',
  but_did_not_send: 'but did not send',
  'but have not': 'but have not',
  would_cancel_offer: `Would you like to cancel
  the offer?`,
  not_now: 'Not now',
  cancel_request_by: 'cancel requested by',
  order_cancelled: 'Your cancellation has been completed.',
  'Did you contact': 'Did you contact',
  'Did you confirm sale with': 'Did you confirm sale with',
  we_are_sorry: "We're sorry",
  no_longer_wants_to_sell: 'no longer wants to sell',
  'Would you like to keep this offer': 'Would you like to keep this offer',
  'and find a new match': 'and find a new match',
  no_longer_wants_to_buy: 'no longer wants to buy',
  'You have cancelled your offer to buy': 'You have cancelled your offer to buy',
  you_cancelled_offer_to_sell: 'You have cancelled your offer to sell',
  'Offer has been cancelled to buy': 'Offer has been cancelled to buy',
  offer_been_cancelled_to_sell: 'Offer has been cancelled to sell',

  cancel_approved: 'Cancel approved',
  cancel_requested: 'Cancel requested',
  confirmed_uncompleted_offer: 'confirmed uncompleted offer',
  confirmed_uncompleted_offer_error: 'confirmed uncompleted offer error',
  on_chain_matching_preoffer_complete: 'on chain matching preoffer complete',
  on_chain_matching_preoffer_start: 'on chain matching preoffer start',
  on_chain_matching_preoffer_timeout_error: 'on chain matching preoffer timeout error',
  unconfirmed_offer_12h: 'unconfirmed offer 12h',
  unconfirmed_offer_12h_error: 'unconfirmed offer 12h error',
  unexpected_error: 'unexpected error',
  unmatched_offer_48h: 'unmatched offer 48h',
  unmatched_offer_48h_error: 'unmatched offer 48h error',
  unprocessed_indexed_confirm_offer: 'unprocessed indexed confirm offer',
  unprocessed_indexed_offer: 'unprocessed indexed offer',
  cancel_by_request: 'cancel by request',
  failed_to_complete_offer: 'failed to complete offer',

  confirmed_sending_you: 'has confirmed sending you',
  bank_transfer_region_label: ' region.',
  bank_transfer_region: ' in {{region}}{{isRegion}}',
  push_transaction_error_action_cancel: 'cancel your',
  push_transaction_error_action_confirm: 'confirm your',
  push_transaction_error_action_sell: 'start your new',
  buy_matched_alt_offer: `<span>{{seller}}</span> wants to sell
  <span>{{amount}}</span> (+{{cryptoFee}} fee).

  Did you contact <span>{{seller}}</span>
  and confirmed the purchase?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> wants to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please contact <span>{{seller}}</span>
  and send <span>{{amount}}</span> using <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_matched_offer: `
  Congrats we have found a match!
  <span>{{seller}}</span> wants to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please contact <span>{{seller}}</span>
  and send <span>{{amount}}</span> using <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_payment: `You are matched.
  <span>{{seller}}</span> wants to sell
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Did you send <span>{{amount}}</span> to <span>{{seller}}</span> using <span>{{method}}</span> {{methodDetails}}

  `,
  buy_payment_confirmed: `You have sent <span>{{amount}}</span> to <span>{{seller}}</span> using <span>{{method}}</span> {{methodDetails}}

  Please remind <span>{{seller}}</span> to confirm receipt of <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}} <span>{{amount}}</span> (+{{cryptoFee}} fee) using <span>{{method}}</span> {{methodDetails}}

  Cancelled on
  {{date}}. `,
  sell_cancel_deny: `We're sorry,
  <span>{{buyer}}</span> has confirmed sending you <span>{{amount}}</span> (+{{cryptoFee}} fee) using <span>{{method}}</span> {{methodDetails}}

  Please contact <span>{{buyer}}</span>.

  `,
  sell_matched_alt_offer: `<span>{{buyer}}</span> wants to buy
  <span>{{amount}}</span> (+{{cryptoFee}} fee).

  Did you confirm sale with
<span>{{buyer}}</span>?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> wants to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please ask <span>{{buyer}}</span>
  to send you <span>{{amount}}</span> using <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_matched_offer: `
  Congrats we have found a match!
  <span>{{buyer}}</span> wants to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Please ask <span>{{buyer}}</span>
  to send you <span>{{amount}}</span> using <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_payment: `You are matched.
  <span>{{buyer}}</span> wants to buy
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} fee).

  Did you receive <span>{{amount}}</span> from <span>{{buyer}}</span> using <span>{{method}}</span> {{methodDetails}}

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} fee) using <span>{{method}}</span>
  {{methodDetails}}

  Cancelled on
  {{date}}. `,
  accept: 'Accept',
  reject: 'Reject',
  accepted: 'Accepted',
  rejected: 'Rejected',
  loader: 'loading trust network list...',
  select_region_country_search: `Please, select the region/country or,
  type it for search.`,
  sell_select_stable: `What would you like
  to sell?`,
  buy_select_stable: `What would you like
  to buy?`,

  phone_number_label: '{{phoneNumberCountryCode}} | phone #',
  account_number_label: 'Account #',
  bank_name_label: 'Bank name',
  full_name_label: 'Full name',
  seller_method_details: 'Please provide your bank information to use to receive the funds',
  seller_method_details_name_account:
    'Please provide the name and the account to use to receive the funds.',
  seller_method_details_phone: `Please provide the phone number
  to receive the funds.`,
  seller_method_details_account: `Please provide the account number
  to receive the funds.`,
  buyer_method_details: 'Please provide your bank information to use to send the funds.',
  buyer_method_details_name_account:
    'Please provide the name and the account to use to send the funds.',
  buyer_method_details_phone: `Please provide the phone number
  to use to send the funds.`,
  buyer_method_details_account: `Please provide the account number
  to use to send the funds.`,

  purchase_completed: `Purchase complete.
  You have bought <span>{{amount}}</span> (+{{cryptoFee}} fee)
  from <span>{{seller}}</span> using
  <span>{{method}}</span> {{sellerMethodDetails}} on
  {{date}}.

  {{fundsSent}}
  {{methodDetails}} `,
  sell_complete: `Sale complete.
  You have sold <span>{{amount}}</span> (+{{cryptoFee}} fee)
  to <span>{{buyer}}</span> using <span>{{method}}</span>
  {{buyerMethodDetails}} on
  {{date}}.

  {{fundsReceived}}
  {{methodDetails}} `,

  funds_sent: 'You received the funds ',

  seller_method_phone_detail_label: `to the phone #
  <span class="p2p-method-detail-phone" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span> {{ region }}`,
  seller_method_bank_detail_label: `to <span> {{ fullName }}</span> at <span>{{accountName}}</span> account #
  <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
    {{ region }}`,
  seller_method_account_detail_label: `to account # <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,
  seller_method_phone_name_detail_label: `to <span> {{ fullName }}</span>
with phone # <span class="p2p-method-detail-account" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,
  buyer_method_phone_detail_label: `from the phone #
  <span class="p2p-method-detail-phone" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span> {{ region }}`,
  buyer_method_bank_detail_label: `from <span> {{ fullName }}</span> at <span>{{accountName}}</span> account #
  <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
    {{ region }}`,
  buyer_method_account_detail_label: `from account # <span class="p2p-method-detail-account" id = "method-detail" > {{ accountNumber }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,
  buyer_method_phone_name_detail_label: `from <span> {{ fullName }}</span>
with phone # <span class="p2p-method-detail-account" id = "method-detail" > {{ phone }}<i class="gg-clipboard" > </i></span>
  {{ region }}`,

  copied: 'Copied successfully.',

  show_more: '<b class="p2p-show-details" id="show-more-details">… show more</b>',
  show_less: '<b class="p2p-show-details" id="show-less-details"> show less</b>',
}
