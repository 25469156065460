import { RegisterAccount } from 'app-engine/store/p2p-slice'
import { AccountVerificationStatusTag } from 'app-view/components/AccountVerificationStatusTag'
import { ActivityIndicator } from 'app-view/components/ActivityIndicator'
import {
  ActionContainer as CoinOrderContainer,
  FlexBox,
  RowContainer,
  Text,
} from 'app-view/components/Styled'
import { useWizard } from 'app-view/components/Wizard'
import { AccountIcon } from 'app-view/components/icons'
import { useOnlineStatus } from 'app-view/hooks/useOnlineStatus'
import styled from 'app-view/styles/styled'
import { AdminStatus } from '..'
import { BaseButton } from '../../../app-view/components/buttons/BaseButton'
import { useAdminActions } from '../hooks/useAdminActions'
import { PENDING_ACCOUNT_STEPS } from '../steps/pending_account'

const InfoButton = styled(BaseButton)`
  background-color: #0f121f;
  border-radius: 10px;
  height: 100%;
  font-size: 15px;
`

type AccountItemProps = {
  status: AdminStatus
  accountInfo: RegisterAccount
}

const StyledActivityIndicator = styled.span`
  padding-inline: 3px;
  display: inline;
  vertical-align: top;
`

export function AccountItem({ status, accountInfo }: AccountItemProps) {
  const { isOnline, showOnlineStatus } = useOnlineStatus(accountInfo.account)
  const { profile } = accountInfo
  const [{}, { start }] = useWizard()
  const [{}, { setAccount }] = useAdminActions()

  const openAccountInfo = () => {
    setAccount(accountInfo)
    start(PENDING_ACCOUNT_STEPS)
  }

  const isMissingInfo =
    !profile?.selfie ||
    !profile?.photo_id ||
    !profile?.full_name ||
    !profile?.phone ||
    !profile?.email ||
    !profile?.country_id

  return (
    <>
      <CoinOrderContainer
        style={{ height: '100px', position: 'relative' }}
        title={
          isMissingInfo
            ? `Missing:
  ${!profile?.selfie ? '\u2022 Selfie' : ''}
  ${!profile?.photo_id ? '\u2022 Photo ID' : ''}
  ${!profile?.full_name ? '\u2022 Full Name' : ''}
  ${!profile?.phone ? '\u2022 Phone' : ''}
  ${!profile?.email ? '\u2022 Email' : ''}
  ${!profile?.country_id ? '\u2022 Country ID' : ''}`
            : undefined
        }
      >
        {status === 'approved_accounts' && (
          <AccountVerificationStatusTag
            isVerified={accountInfo.is_verified}
            isMissingInfo={isMissingInfo}
          />
        )}
        <RowContainer style={{ padding: '10px' }}>
          <FlexBox flexDirection="column" alignItems="start">
            <FlexBox style={{ color: '#0F121F' }} alignItems="center">
              <AccountIcon style={{ height: '0.875rem', width: '0.875rem' }} />
              <span style={{ pointerEvents: 'all', userSelect: 'all' }}>{accountInfo.account}</span>
              {showOnlineStatus && (
                <StyledActivityIndicator>
                  {status !== 'pending_accounts' && <ActivityIndicator isOnline={isOnline} />}
                </StyledActivityIndicator>
              )}
            </FlexBox>
            <Text color="#787878">
              <span>Referrer: </span>
              <span style={{ pointerEvents: 'all', userSelect: 'all', fontWeight: '800' }}>
                {accountInfo.referrer}
              </span>
            </Text>
            <Text color="#787878">
              <span>Website: </span>
              <span style={{ pointerEvents: 'all', userSelect: 'all', fontWeight: '800' }}>
                {(accountInfo.source ?? 'N/A').replace('https://', '')}
              </span>
            </Text>
          </FlexBox>
        </RowContainer>
        <RowContainer style={{ height: '100%' }}>
          <InfoButton onClick={openAccountInfo}>Info</InfoButton>
        </RowContainer>
      </CoinOrderContainer>
    </>
  )
}
