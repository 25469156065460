/**
 * Here we place all errors that <ModalError /> component can catch. Pass the error string as `errors:${default_error_message}` for example
 */
// errors.ts
export default {
  bitcashbank_acct_no_valid: 'Operasi tidak dapat diselesaikan:\nAkun bukan dari BitcashBank.',
  bitcashbank_acct_no_registered:
    'Operasi tidak dapat diselesaikan:\nAkun belum disetujui oleh BitcashBank.',
  unable_find_icon: 'Tidak dapat menemukan ikon untuk {{icon}}',
  reading_token_precision: 'Kesalahan membaca presisi token',
  pair_not_found: 'Pasangan untuk {{crypto_token}}/{{stable_token}} tidak ditemukan',
  position_not_found: 'Posisi {{tokenSymbolCode}} tidak ditemukan',
  token_not_found: 'token tidak ditemukan {{symbolCode}}',
  getting_last_price: 'Masalah mendapatkan harga terakhir',
  price_not_found: 'harga tidak ditemukan {{symbolCode}}',
  cannot_convert: 'Tidak dapat mengonversi {{quantity}} ke {{symbolCode}}',
  key_required: 'Setidaknya satu kunci diperlukan',
  getting_escrow_id: 'Kesalahan mendapatkan id escrow p2p dari EOS, silakan hubungi dukungan',
}
