import { parseJwt } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { bitcashPrivateRoom, bitcashRoom } from 'app-view/hooks/use-realtime'
import { useRealTimeCoinPrices } from 'app-view/hooks/use-realtime/use-realtime-coin-prices'
import { useRenderCount } from 'app-view/hooks/use-render-count'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useDeepCompareEffect } from 'react-use'
import { useApp } from './App'
import { routes } from './routes'

export const useAuthVerify = () => {
  useRenderCount('AuthVerify')

  const coinPrices = useRealTimeCoinPrices()
  const account = useStore.useAccount()
  const token = useStore.useToken()
  const authed = useStore.useAuthed()
  const logout = useStore.useLogout()
  const refreshSession = useStore.useRefreshSession()
  const fetchWalletTotal = useStore.useFetchWalletTotal()

  const { t } = useTranslation(['access'])
  const [, globalModalActions] = useGlobalModal()

  const authenticated = {
    account,
    token,
    authed,
    coinPrices,
  }

  const showWarning = () =>
    globalModalActions.openConfirm({
      onConfirm: () => logout(),
      onCancel: async () => {
        await refreshSession(true)
        globalModalActions.close()
      },
      confirmText: t('logout'),
      cancelText: t('keep_session'),
      text: t('session_about_expire'),
      title: t('your_session_expiring'),
      iconType: 'WARNING',
      wideActions: true,
    })

  const location = useLocation()

  const verifyAccess = () => {
    // skip unauthenticated routed or valid expiration
    if (
      routes.access_view.includes(location.pathname) ||
      routes.account_view.includes(location.pathname)
    )
      return

    const decodedJwt = parseJwt(token)

    if (!decodedJwt || !token || !authed) return

    const currentDate = Date.now() / 1000
    const expDate = decodedJwt.exp
    const almostExpDate = decodedJwt.exp - 5 * 60
    // Just for testing
    // console.log('valid token by', expDate - currentDate, 'seconds', almostExpDate <= currentDate, currentDate <= expDate, { decodedJwt })

    // TODO: Show warning 5 minutes before expiration on a timeout if user is still on the same pages

    if (currentDate < almostExpDate) return // Valid token
    if (almostExpDate <= currentDate && currentDate <= expDate) {
      showWarning()
      return
    }
    // Case currentDate > expDate
    globalModalActions.close()
    globalModalActions.open({
      content: () => <ModalError error={t('your_session_expired')} />,
      iconType: 'ERROR',
      showClose: true,
      title: t('session_expired'),
    })
    logout()
  }

  useEffect(() => {
    verifyAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, location.pathname, authed])

  useDeepCompareEffect(() => {
    const { authed, token, account, coinPrices } = authenticated
    if (authed && token && account && coinPrices && location.pathname.includes('/wallet')) {
      console.log('[fetching wallet total]')
      fetchWalletTotal(coinPrices)
    }
  }, [authenticated, location.pathname])

  // JUST FOR DEBUGGING

  // useEffect(() => {
  //   console.log('account changed:-->', account)
  // }, [account])

  // useEffect(() => {
  //   console.log('token changed:-->', token)
  // }, [token])

  // useEffect(() => {
  //   console.log('authed changed:-->', authed)
  // }, [authed])

  // useDeepCompareEffect(() => {
  //   console.log('coinPrices changed:-->-->', coinPrices)
  // }, [coinPrices])

  useEffect(() => {
    (async () => {
      if (!authed || !token || !account) return
      console.log('Connecting to Bitcash room')
      await bitcashRoom.connectToColyseus('bitcash-room')
    })()
    ;(async () => {
      if (!authed || !token || !account) return
      console.log('Connecting to Bitcash private room')
      await bitcashPrivateRoom.connectToColyseus('bitcash-private-room')
    })()

    return () => {
      try {
        console.log('Disconnecting from Bitcash room')
        bitcashRoom.disconnectFromColyseus()
      } catch (error) {
        console.log('Error disconnecting from Bitcash room', error)
      }
      try {
        console.log('Disconnecting from Bitcash private room')
        bitcashPrivateRoom.disconnectFromColyseus()
      } catch (error) {
        console.log('Error disconnecting from Bitcash private room', error)
      }
    }
  }, [authed, token, account])

  // console.log('useAuthVerify rendered')

  return {
    showWarning,
    verifyAccess,
  }
}

// TODO: can we use memo here?
// const MainApp = memo(() => {
//   useAuthVerify()
//   useApp()
//   return <></>
// })

const MainApp = () => {
  useAuthVerify()
  useApp()
  return <></>
}

export default MainApp
