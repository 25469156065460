import { motion, MotionProps } from 'framer-motion/dist/framer-motion'
import React, { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'app-view/styles/styled'
import theme, { ThemeColors } from 'app-config/theme'

// TODO extend React HTML Button props
export interface BaseButtonProps extends MotionProps {
  color?: string
  bgcolor?: string
  to?: string
  onClick?: (e: MouseEvent<HTMLElement>) => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  outlined?: boolean
  size?: 'sm' | 'md' | 'xl'
  variant?: keyof ThemeColors
  hover?: boolean
  selected?: boolean
}

const StyledBaseButton = styled(motion.button)<BaseButtonProps>`
  border-radius: 24px;
  font-size: ${({ size }) => (size ? '0.75rem' : '1.25rem')};
  line-height: 1.25;
  width: 100%;
  max-width: ${({ size }) => (size ? '100px' : '150px')};
  padding: 0 11px;
  min-height: ${({ size }) => (size ? '30px' : '47px')};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: ${({ outlined, color }) => (outlined ? `2px solid ${color}` : 'none')};
  background: ${({ bgcolor, outlined }) => (outlined ? 'transparent' : bgcolor)};
  color: ${({ color }) => color || '#ffffff'};
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0;
  transition: 0.24s all ease-in-out;

  &:disabled {
    opacity: 0.5;
    color: ${({ color }) => color || '#ffffffe0'};
    cursor: not-allowed;
  }
  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    transform: scale(1.005);
  }
`

export const BaseButton: React.FC<React.ComponentProps<'button'> & Partial<BaseButtonProps>> = ({
  to,
  color,
  variant,
  onClick,
  outlined,
  children,
  size,
  ...props
}) => {
  return to ? (
    <Link to={to}>
      <StyledBaseButton
        color={color}
        bgcolor={theme.colors[variant || 'black']}
        outlined={outlined}
        size={size}
        {...props}
      >
        {children}
      </StyledBaseButton>
    </Link>
  ) : (
    <StyledBaseButton
      color={color}
      bgcolor={theme.colors![variant || 'black']}
      outlined={outlined}
      onClick={onClick}
      size={size}
      {...props}
    >
      {children}
    </StyledBaseButton>
  )
}
