import React, { useState } from 'react'
import ReactSelectKey from 'react-select'
import styled from 'app-view/styles/styled'
import theme from 'app-config/theme'

export type OptionKeySelect = { value: string; label: string; icon?: string }
export type keySelectProps = {
  value?: OptionKeySelect
  options: OptionKeySelect[]
  initialPosition?: number
  disabled?: boolean
  onChange?: (data: OptionKeySelect) => void
}

const SelectLabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const FlagIcon = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  object-fit: cover;
`

export const KeySelect: React.FC<keySelectProps> = ({
  value,
  options,
  onChange = () => {},
  disabled = false,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  return (
    <ReactSelectKey
      isDisabled={disabled}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      onChange={onChange as any}
      options={options}
      menuPosition="fixed"
      maxMenuHeight={100}
      menuPlacement="bottom"
      className="react-select"
      classNamePrefix="react-select"
      formatOptionLabel={(data) => (
        <SelectLabelContainer>
          {data.icon && <FlagIcon src={data.icon} />}
          <div>{data.label}</div>
        </SelectLabelContainer>
      )}
      value={value || options?.[0]}
      styles={{
        container: (styles) => ({ ...styles, ...stylesSelect.container }),
        control: (styles) => ({
          ...styles,
          ...stylesSelect.control,
          borderRadius: menuIsOpen ? '9px 9px 0 0' : '9px',
          border: 'none',
        }),
        indicatorSeparator: () => ({ ...stylesSelect.indicatorSeparator }),
        indicatorsContainer: () => ({ width: 'unset' }),
        singleValue: (styles) => ({ ...styles, ...stylesSelect.singleValue }),
        menu: (styles) => ({ ...styles, ...stylesSelect.menu, border: 'none' }),
        valueContainer: (styles) => ({ ...styles, ...stylesSelect.valueContainer, width: 'unset' }),
      }}
    />
  )
}

const stylesSelect = {
  container: { flex: 1, fontWeight: 800, color: theme.colors.black },
  control: {
    borderColor: theme.colors.gray,
    boxShadow: 'none',
    width: '100%',
    height: '57px',
    caretColor: 'transparent',
    '&:hover': { borderColor: theme.colors.gray },
  },
  indicatorSeparator: { display: 'none' },
  singleValue: { color: theme.colors.darkGray },
  menu: {
    margin: 0,
    borderRadius: '0 0 9px 9px',
    border: `1px solid ${theme.colors.gray}`,
    borderTop: 'none',
    overflow: 'hidden',
    boxShadow: 'none',
  },
  valueContainer: { paddingRight: '0px' },
}
