import React, { useState, useCallback } from 'react'
import styled from '../app-view/styles/styled'
// TODO: Replace them. Package will be rm
import { Menu } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { BaseButton } from 'app-view/components/buttons/BaseButton'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store'
import { setTimeout } from 'timers'
import { requestNotificationPermission } from 'app-engine/library/notifications'
import { config } from 'app-config'
import { Toggle } from 'app-view/components/Toggle'
import { useUpdatePreferencesMutation } from 'app-engine/graphql/generated/bitcash'
import { isTokenPocket } from 'app-engine/library/utils'
import { AiOutlineRight } from 'react-icons/ai'
import { SwapIcon } from 'app-view/components/SwapIcon'
import { showTokenSymbol } from 'app-engine/library/tokens'
import { ReactSelect } from 'app-view/components/WheelSelect/styled'
import { canAccessAdminView } from 'app-view/lib/utils'
import {
  SelectOption,
  languageOptions,
} from 'app-view/components/OnboardingModal/steps/LanguageSelect'
import { version } from '../../package.json'
import { bitcashPrivateRoom, bitcashRoom } from 'app-view/hooks/use-realtime'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { useBitcashSWR } from 'hooks/use-bitcash-swr.hook'

export const SettingsView: React.FC = () => {
  const publicRoomOnline = bitcashRoom.useIsOnline()
  const privateRoomOnline = bitcashPrivateRoom.useIsOnline()
  const [isFullscreen, setFullscreen] = useState<boolean>()
  const [copiedLink, setCopiedLink] = useState(false)
  const [updatePreferencesMutation] = useUpdatePreferencesMutation()
  const { t } = useTranslation(['settings'])
  const account = useStore.useAccount()
  const [{ preferences }, { syncCurrencyPreferences, setNotificationsEnable }] = useProfile()
  const logout = useStore.useLogout()
  const authErrorFallback = useStore.useAuthErrorFallback()
  const tokenData = useStore.useTokenData()
  const showAdminView = canAccessAdminView(account)
  const versionApp = `v.${version}`
  const onlineStatus = `${publicRoomOnline ? '✅' : '❌'}-${privateRoomOnline ? '✅' : '❌'}`
  const [currencySelecting, setCurrencySelecting] = useState<boolean>(false)
  const tokens = tokenData?.filter(({ token_symbol }) =>
    token_symbol.code().toString().match(/BIT/),
  )
  const { data } = useBitcashSWR({
    reg_accounts: {
      __args: {
        where: {
          account: {
            _eq: account,
          },
        },
      },
      referrer: true,
      source: true,
    },
  })
  const accountData = data?.reg_accounts[0] || null

  const toggleFullscreen = useCallback(() => {
    if (!isFullscreen) {
      document.documentElement.requestFullscreen()
    } else {
      document.exitFullscreen()
    }

    setFullscreen(!isFullscreen)
  }, [isFullscreen])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${config.appUrl}/?referrer=${account}`)
    setCopiedLink(true)
    setTimeout(() => setCopiedLink(false), 3000)
  }

  const updatePrimaryCurrency = (symbol: string) => {
    updatePreferencesMutation({
      variables: { account, _set: { currency: symbol } },
      onError: (err) => {
        authErrorFallback(err)
      },
      onQueryUpdated: () => {
        console.log('updatePrimaryCurrency')
      },
      optimisticResponse: () => {
        console.log('updatePrimaryCurrency optimisticResponse')
        syncCurrencyPreferences(symbol)
        return {
          update_preferences_by_pk: {
            ...preferences,
            currency: symbol,
            __typename: 'preferences',
          } as any, // TODO: Fix type
        }
      },
    })
  }

  const [currentLanguage, setCurrentLanguage] = useState<SelectOption>(
    languageOptions.find((o) => o.value === preferences?.language) || languageOptions[0],
  )

  const handleToggeNotification = async () => {
    const permission = await requestNotificationPermission()
    setNotificationsEnable(permission)
  }

  const onChangeLanguage = (option: SelectOption) => {
    if (!option || !option.value) return
    setCurrentLanguage(option) // Optimistic UI
    updatePreferencesMutation({
      variables: { account, _set: { language: option.value } },
      onError: (err) => {
        authErrorFallback(err)
      },
    })
  }

  return (
    <SettingsContainer>
      <Menu fluid vertical>
        {isTokenPocket && (
          <>
            <Menu.Item>
              <SelectContainer>
                {t('settings:fullscreen')}
                <div>
                  <Toggle isOn={isFullscreen} onClick={toggleFullscreen} />
                </div>
              </SelectContainer>
            </Menu.Item>
            <Menu.Item fitted>
              <div style={{ backgroundColor: '#f2f2f2', height: 25 }} />
            </Menu.Item>
          </>
        )}
        <Menu.Item>
          <SelectContainer>
            {t('settings:language')}
            <div>
              {languageOptions.length && (
                <ReactSelect
                  options={languageOptions}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      border: 0,
                      // This line disable the blue border
                      boxShadow: 'none',
                    }),
                  }}
                  onChange={(value) => onChangeLanguage(value as SelectOption)}
                  value={currentLanguage}
                />
              )}
            </div>
          </SelectContainer>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            setCurrencySelecting(!currencySelecting)
          }}
        >
          <DefaultContainer>
            <div>
              <ReferalTopText>{t('settings:primary_currency')}</ReferalTopText>
              <HighlightedText isHighlight={currencySelecting}>
                {showTokenSymbol(preferences.currency)}
              </HighlightedText>
            </div>
            <AiOutlineRight color={currencySelecting ? '#29e0ad' : '#787878'} />
          </DefaultContainer>
          {currencySelecting && (
            <TokenListPopup>
              <TokenListBack />
              <TokenListContainer>
                {tokens.map((token) => (
                  <TokenContainer
                    selected={token.symbol_code === preferences.currency}
                    onClick={() => {
                      updatePrimaryCurrency(token.symbol_code)
                    }}
                    key={token.symbol_code}
                  >
                    <SwapIcon coin={token.symbol_code} style={{ width: 36, height: 36 }} />
                    {showTokenSymbol(token.symbol_code)}
                  </TokenContainer>
                ))}
              </TokenListContainer>
            </TokenListPopup>
          )}
        </Menu.Item>
        <Menu.Item>
          <SelectContainer>
            {t('settings:notifications')}
            <div>
              <Toggle isOn={preferences.notifications} onClick={handleToggeNotification} />
            </div>
          </SelectContainer>
        </Menu.Item>{' '}
        {accountData &&
          (accountData?.referrer === 'bitlaunch.bk' ||
            accountData?.source?.includes('bitlauncher.ai')) && (
            <Menu.Item as={NavLink} to="/bitlauncher">
              <DefaultContainer>
                bitlauncher - AI & Web3 Launchpad
                <AiOutlineRight color="#787878" />
              </DefaultContainer>
            </Menu.Item>
          )}
        <Menu.Item as={NavLink} to="/device-keys">
          <DefaultContainer>
            {t('settings:deviceKeys')}
            <AiOutlineRight color="#787878" />
          </DefaultContainer>
        </Menu.Item>
        <Menu.Item as={NavLink} to="/about">
          <DefaultContainer>
            {t('settings:about')}
            <VersionTag>{versionApp}</VersionTag>
            <AiOutlineRight color="#787878" />
          </DefaultContainer>
        </Menu.Item>
        <Menu.Item>
          <DefaultContainer>
            {t('settings:api_status')}
            <ConnectionStatus>{onlineStatus}</ConnectionStatus>
          </DefaultContainer>
        </Menu.Item>
        <Menu.Item>
          <Anchor
            href="https://discord.com/channels/856694458388054026/896893967914893343"
            target="_blank"
          >
            <DefaultContainer>
              {t('settings:support')}
              <AiOutlineRight color="#787878" />
            </DefaultContainer>
          </Anchor>
        </Menu.Item>
        <Menu.Item as={NavLink} to="/profile">
          <DefaultContainer>
            {t('settings:profile')}
            <AiOutlineRight color="#787878" />
          </DefaultContainer>
        </Menu.Item>
        <Menu.Item>
          <DefaultContainer>
            <div>
              {t('settings:referralLinkLabel')}
              <ReferalSubText>{t('settings:referralLinkSubLabel')}</ReferalSubText>
            </div>
            <CopyLinkButton
              size="sm"
              outlined={copiedLink ? false : true}
              variant="green"
              color={copiedLink ? '#ffffff' : '#29e0ad'}
              onClick={handleCopyLink}
            >
              {copiedLink ? t('settings:copiedLinkBtnLabel') : t('settings:copyLinkBtnLabel')}
            </CopyLinkButton>
          </DefaultContainer>
        </Menu.Item>
        {showAdminView && <Menu.Item name="Admin" as={NavLink} to="/admin" />}
        <Menu.Item
          onClick={() => {
            // console.log(anchor_permission_level)
            logout()
          }}
        >
          <DefaultContainer>
            <HighlightedText isHighlight>{t('settings:logout')}</HighlightedText>
            <AiOutlineRight color="#787878" />
          </DefaultContainer>
        </Menu.Item>
      </Menu>
    </SettingsContainer>
  )
}

const SettingsContainer = styled.div`
  flex-grow: 1;
  padding-top: 20px;
`
const Anchor = styled.a`
  color: #000;
  &:hover,
  &:visited {
    color: #000;
  }
  width: 100%;
  display: inline-block;
`

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  place-self: stretch stretch;
  justify-content: space-between;
  flex-basis: 66px;
  grid-column: 2;
  margin-bottom: 0;
  user-select: none;
  padding: 0;

  & > div > div {
    padding: 0;
  }
`
const CopyLinkButton = styled(BaseButton)`
  white-space: nowrap;
`

const DefaultContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const ReferalTopText = styled.p`
  font-size: 12px;
  color: #787878;
  padding-bottom: 5px;
  margin-bottom: 0px;
`
const ReferalSubText = styled.p`
  font-size: 12px;
  color: #787878;
  padding-top: 5px;
`
const HighlightedText = styled.p`
  color: ${(props: { isHighlight: boolean }) => (props.isHighlight ? '#29e0ad' : 'initial')};
  margin: 0px;
`

const TokenListPopup = styled.div`
  position: relative;
`
const TokenListBack = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`
const TokenListContainer = styled.div`
  position: absolute;
  background: white;
  z-index: 2;
  width: 100%;
  top: 1.15em;
  border: 1px solid rgba(34, 36, 38, 0.1);
  height: 140px;
  overflow-y: auto;
`
const TokenContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background: ${(props: { selected: boolean }) => (props.selected ? '#E0EAFF' : 'initial')};
  &:hover {
    background: #467efd;
  }
`
const VersionTag = styled.div`
  font-size: 12px;
  color: #787878;
  padding: 0px 10px;
  margin-left: auto;
`

const ConnectionStatus = styled.div`
  font-size: 12px;
  color: #787878;
  padding: 0px 10px;
  margin-left: auto;
`
