import React from 'react'

const BATIcon = (props: any) => (
  <svg viewBox="0 0 54 54" {...props}>
    <g clipPath="url(#clip0_11_2)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3918 0.00123349C16.1944 -0.0809876 3.0881 3.92948 0.703622 17.3766C-0.423699 23.7327 -0.0243684 29.893 0.703622 36.2699C2.1954 49.3284 15.2301 54.3118 26.9997 53.985C38.6862 53.6607 51.7657 49.6724 53.2966 36.2695C54.0245 29.893 54.4234 23.7327 53.2966 17.3766C51.2067 5.59227 39.4908 0.0767767 28.3918 0.00123349Z"
        fill="white"
        stroke="#9E1F63"
        strokeWidth="0.5"
      />
      <g filter="url(#filter0_d_11_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0416 38.861L22.3273 34.1262L27.3502 24.3887V15C27.2177 15 27.0852 15.0977 26.9847 15.292L21.0349 26.8268L15.086 38.3616C14.9857 38.5561 14.9761 38.7328 15.0417 38.861"
          fill="#FF4724"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3503 15V24.3887L32.3729 34.1262L39.6587 38.861C39.7244 38.7328 39.7149 38.5561 39.6147 38.3616L33.6653 26.8268L27.7157 15.292C27.615 15.0973 27.4825 15 27.3503 15Z"
          fill="#9E1F63"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.6584 38.861L32.3727 34.1263H22.3274L15.0416 38.861C15.1073 38.99 15.2485 39.07 15.4496 39.07H39.2502C39.4512 39.07 39.5925 38.99 39.6584 38.861Z"
          fill="#662D91"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_11_2"
        x="-3.306"
        y="4.344"
        width="61.3126"
        height="60.682"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="7.65" />
        <feGaussianBlur stdDeviation="9.153" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0 0.196078 0 0 0 0.33 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11_2" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11_2" result="shape" />
      </filter>
      <clipPath id="clip0_11_2">
        <rect width="54" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BATIcon
