import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'

import { WizardStepProps } from 'app-view/components/Wizard'
import { Loader } from 'app-view/components/Spinner'
import { get_amount } from 'app-view/lib/utils'

export const DuplicateOffer = ({ goTo }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { submitOffer, methodLabel, updateOffer }] = useP2POffer()
  const isSubmittedRef = useRef(false)
  const text = useRef('')

  const onConfirm = async () => {
    text.current =
      offer.type === 'buy'
        ? `${t('buy_duplicating', {
            amount: get_amount(offer.amount),
            method: methodLabel(offer.method),
          })}...`
        : `${t('sell_duplicating', {
            amount: get_amount(offer.amount),
            method: methodLabel(offer.method),
          })}...`
    const { success } = await submitOffer()
    // TODO: To send a notification to the user that we did match
    if (success) {
      goTo('no-match')
    }
  }

  React.useEffect(() => {
    if (
      !isSubmittedRef.current &&
      offer?.amount !== '0.00 BITUSD' &&
      offer.id === '' &&
      offer.created_at === ''
    ) {
      isSubmittedRef.current = true
      onConfirm()
    }
  }, [offer])

  return <Loader text={text.current} />
}
