import React from 'react'
import { DownloadIcon } from 'app-view/components/icons'
import styled from 'app-view/styles/styled'
import { useTranslation } from 'react-i18next'

const DownloadLink = styled.a`
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  font-size: 1rem;
  color: #0075ff;
  min-width: 100%;
  display: flex;
  align-items: center;
  padding: 19px 0;
  margin-top: 21px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 5px;
`

export const DownloadLinkButton = ({
  downloadLink,
  downloadLink2 = '',
  style,
}: {
  downloadLink: string
  downloadLink2?: string
  style?: React.CSSProperties // Define style prop
}) => {
  const { t } = useTranslation(['account'])
  const handleDownload = () => {
    downloadImage(downloadLink, 'bitcash-id-selfie.jpg')
    if (downloadLink2) downloadImage(downloadLink2, 'bitcash-id-photo.jpg')
  }
  const downloadImage = (dataUrl, fileName) => {
    const link = document.createElement('a')
    link.href = dataUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  return (
    <DownloadLink onClick={handleDownload} style={style}>
      <DownloadIcon style={{ width: '1rem', height: '1rem', padding: '2px' }} />
      {t('download_selfie')}
    </DownloadLink>
  )
}
