import { useStore } from 'app-engine/store'
import { ModalText } from 'app-view/components/Modal'
import { useWizard } from 'app-view/components/Wizard'
import { CheckIcon } from 'app-view/components/icons'
import { hasAdminPermissions } from 'app-view/lib/utils'
import styled from 'app-view/styles/styled'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const TextIcon = styled.span`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;

  > span {
    margin-left: 7px;
  }
`

export const CancelComplete: React.FC = () => {
  const { t } = useTranslation('p2p')
  const account = useStore.useAccount()
  const [, { close }] = useWizard()

  const isAdmin = hasAdminPermissions(account) && location.pathname.includes('/admin')

  useEffect(() => {
    const cancelClose = setTimeout(() => {
      close()
    }, 4000)

    return () => {
      clearTimeout(cancelClose)
    }
  }, [close])

  return (
    <ModalText>
      <TextIcon style={{ background: 'none' }}>
        <CheckIcon />
        <span>
          {isAdmin ? 'Order has been cancelled successfully to the user' : t('order_cancelled')}
        </span>
      </TextIcon>
    </ModalText>
  )
}
