import { ModalButtonsContainer } from 'app-view/components/Modal'
import { CancelButton } from 'app-view/components/buttons'
import { useTranslation } from 'react-i18next'

export const CloseButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation(['account'])
  return (
    <ModalButtonsContainer style={{ marginTop: 0 }} cancel>
      <CancelButton onClick={onClick}>{t('cancel_verification')}</CancelButton>
    </ModalButtonsContainer>
  )
}
