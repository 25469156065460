import React from 'react'
import { AddressInput } from 'pages/SwapView/steps/common/AddressInput'
import styled from 'app-view/styles/styled'
import { useTranslation } from 'react-i18next'
// TODO: We should have proper SVG icons for this.
import { FiEyeOff, FiEye } from 'react-icons/fi'

interface CopyAddressProps {
  address: string
  trimTo?: number
}

const StyledCopyAddress = styled.div`
  margin-bottom: 40px;
  width: 100%;
  padding: 0 1.25rem;
  position: relative;

  input {
    padding: 14px 2.625rem 14px 2.625rem;
  }
`

const ToggleAddressContainer = styled.span`
  position: absolute;
  left: 20px;
  width: calc(25% - 1.5rem);
  height: 64px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px;
    background: white;
    color: #a4a4a4;
  }
`

export const CopyAddress = ({ address, trimTo = 4 }: CopyAddressProps) => {
  const [message, setMessage] = React.useState('')
  const [fullAddress, setFullAddress] = React.useState(false)
  const { t } = useTranslation(['swap'])

  const copyAddressToClipboard = () => {
    const input = document.querySelector('[name=copy-text-input]') as HTMLInputElement

    input.value = address
    input.select()
    input.setSelectionRange(0, 99999)
    document.execCommand('copy')
    setMessage(t('swap:general.copied'))
  }

  return (
    <StyledCopyAddress>
      <AddressInput
        onTouchStart={copyAddressToClipboard}
        onCopy={copyAddressToClipboard}
        style={{ userSelect: 'all' }}
        value={
          fullAddress
            ? address
            : `…${address?.substring(address!.length - trimTo, address!.length)}`
        }
        readOnly
        message={message}
      />
      <ToggleAddressContainer onClick={() => setFullAddress((isFull) => !isFull)}>
        {fullAddress ? <FiEyeOff /> : <FiEye />}
      </ToggleAddressContainer>
    </StyledCopyAddress>
  )
}
