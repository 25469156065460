import {
  checkEmail,
  checkIfCountryIdIsRegistered,
  checkIfPhoneIsRegistered,
} from 'app-engine/services'
import { PhoneLookupAPIResponse, lookupValidPhoneNumber } from 'app-engine/services/phone-lookup'
import { useStore } from 'app-engine/store'
import parsePhoneNumber, { AsYouType } from 'libphonenumber-js'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useEffectOnce, useSetState } from 'react-use'

export interface FieldStateProps {
  valid: boolean
  loading: boolean
}

// eslint-disable-next-line no-undef
let timeout: NodeJS.Timeout
// eslint-disable-next-line no-undef
let id_timeout: NodeJS.Timeout

type useKYCFormProps = {
  submitAccountKYC: (data: any) => void
  setValue: any // TODO: type
  setError: any // TODO: type
  formState: any // TODO: type
  watch: any // TODO: type
  initialIsChangeableID?: boolean
}

export const useKYCForm = ({
  submitAccountKYC,
  setValue,
  setError,
  formState,
  watch,
  initialIsChangeableID = true,
}: useKYCFormProps) => {
  const { t } = useTranslation(['account'])
  // TODO: maybe we can use the websocket API to get the country code
  const getAccountCountryByIp = useStore.useGetAccountCountryByIp()
  const userCountryData = useStore.useUserCountryData()
  const [{ photoId, selfie, country, idType, isChangeableID }, setFormFieldState] = useSetState({
    isChangeableID: initialIsChangeableID,
    idType: 'national_id',
    country: '',
    selfie: null,
    photoId: null,
  })
  const [checkCountryIdState, setCheckCountryIdState] = useSetState<FieldStateProps>({
    valid: false,
    loading: false,
  })
  const [checkEmailState, setCheckEmailState] = useSetState<FieldStateProps>({
    valid: false,
    loading: false,
  })
  const [validPhoneNumberState, setValidPhoneNumberState] = useSetState<FieldStateProps>({
    valid: false,
    loading: false,
  })

  useEffectOnce(() => {
    getAccountCountryByIp()
  })

  const setSelfie = (selfie: string) => {
    setFormFieldState({ selfie })
    setValue('selfie', selfie)
  }

  const setPhotoId = (photoId: string) => {
    setFormFieldState({ photoId })
    setValue('photoId', photoId)
  }

  const setIdType = (value: string) => {
    setFormFieldState({ idType: value })
    setValue('idType', value)
  }

  const setCountry = (value: string) => {
    setFormFieldState({ country: value })
    setValue('country', value)
  }

  const handleCountryIdChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setValue('countryId', value)

    if (id_timeout) clearTimeout(id_timeout)

    if (value.length > 3) {
      setCheckCountryIdState({ loading: true })
      setError('countryId', {
        message: '',
      })
      id_timeout = setTimeout(() => {
        checkCountryId(value)
      }, 1150)
    } else {
      setCheckCountryIdState({ loading: false, valid: false })
    }
  }

  const checkCountryId = useCallback(
    async (country_id: string) => {
      try {
        // console.log()
        const exists = await checkIfCountryIdIsRegistered({ id: country_id, type: idType, country })
        if (exists) throw new Error(t('this_country_id_already_registered'))

        setCheckCountryIdState({
          valid: true,
          loading: false,
        })
      } catch (error) {
        console.log('checkCountryId::ERROR', { error })

        setCheckCountryIdState({
          valid: false,
        })
        setError('countryId', {
          message: (error as Error).message,
        })
      } finally {
        clearTimeout(id_timeout)

        setCheckCountryIdState({
          loading: false,
        })
      }
    },
    [idType, country, t, setCheckCountryIdState, setError],
  )

  const verifyPhoneNumber = async (phone: string) => {
    try {
      const exists = await checkIfPhoneIsRegistered(phone)

      if (exists) throw new Error(t('this_phone_number_already_registered'))

      const response: PhoneLookupAPIResponse = await lookupValidPhoneNumber(phone)

      if (!response.data?.valid) throw new Error(t('invalid_phone_provenance'))

      setValidPhoneNumberState({
        valid: true,
        loading: false,
      })
    } catch (error) {
      console.log('verifyPhoneNumber::ERROR', { error })

      setError('phoneNumber', {
        message: (error as Error).message,
      })
    } finally {
      clearTimeout(timeout)

      setValidPhoneNumberState({
        loading: false,
      })
    }
  }

  const formatUserPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log('countryData =>', countryData ? countryData : 'no country data retrieved.')

    setValidPhoneNumberState({ loading: true })
    setError('phoneNumber', {
      message: '',
    })

    const { value } = event.target
    let phone = value
    if (timeout) clearTimeout(timeout)

    try {
      const phoneNumber = parsePhoneNumber(
        value,
        userCountryData?.country_code ? userCountryData.country_code : undefined,
      )

      // * Setting first the international number with country code
      phone = phoneNumber ? phoneNumber.formatInternational().trim() : value.trim()
      setValue('phoneNumber', phone)

      if (!phoneNumber?.isValid()) throw new Error('Invalid phone number')
    } catch (error) {
      setError('phoneNumber', {
        message: (error as Error).message,
      })
      setValidPhoneNumberState({
        loading: false,
      })
      return
    }

    timeout = setTimeout(() => {
      verifyPhoneNumber(phone)
    }, 1150)
  }

  const verifyEmailAddress = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    setValue('emailAddress', value.trim())
    setError('emailAddress', {
      message: !value.match(emailRegex) ? 'Invalid email address' : '',
    })
    if (value.match(emailRegex)) {
      setCheckEmailState({
        valid: false,
        loading: true,
      })
      const isRegistered = await checkEmail(value)
      setError('emailAddress', {
        message: isRegistered ? t('account:this_email_already_registered') : '',
      })
      setCheckEmailState({
        valid: !isRegistered,
        loading: false,
      })
    }
  }

  const setFullNameValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setValue('fullName', value)
    setError('fullName', {
      message: '',
    })
  }

  const countryIdValue = watch('countryId')
  const fullNameValue = watch('fullName')
  const phoneValue = watch('phoneNumber')
  const emailValue = watch('emailAddress')
  const newsletterSubscriptionValue = watch('newsletterSubscription')
  const phoneNumberCountryCode = userCountryData?.country_code
    ? `+${parsePhoneNumber('123', userCountryData?.country_code)?.countryCallingCode}`
    : ''
  const formattedValue = new AsYouType(userCountryData?.country_code)
    .input(phoneValue)
    .replace(/\s/g, '-')
  const shouldOmitSubmit =
    Boolean(formState.errors.fullName?.message) ||
    Boolean(formState.errors.emailAddress?.message) ||
    Boolean(formState.errors.phoneNumber?.message) ||
    Boolean(formState.errors.countryId?.message) ||
    !Boolean(selfie) ||
    !Boolean(photoId) ||
    !validPhoneNumberState.valid ||
    !checkCountryIdState.valid ||
    !checkEmailState.valid ||
    formState.isValidating

  useEffect(() => {
    if (countryIdValue && countryIdValue.length > 3 && isChangeableID) {
      if (id_timeout) clearTimeout(id_timeout)
      setCheckCountryIdState({ loading: true })
      setError('countryId', {
        message: '',
      })
      id_timeout = setTimeout(() => {
        checkCountryId(countryIdValue)
      }, 1150)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idType, country, countryIdValue, isChangeableID])

  return {
    checkCountryId,
    handleCountryIdChange,
    verifyPhoneNumber,
    formatUserPhoneNumber,
    setFullNameValue,
    verifyEmailAddress,
    submitAccountKYC,
    setIdType,
    setCountry,
    countryIdValue,
    country,
    idType,
    fullNameValue,
    phoneValue,
    emailValue,
    newsletterSubscriptionValue,
    phoneNumberCountryCode,
    formattedValue,
    shouldOmitSubmit,
    validPhoneNumberState,
    checkCountryIdState,
    selfie,
    setSelfie,
    photoId,
    setPhotoId,
    isChangeableID,
    setIsChangeableID: (isChangeableID: boolean) => setFormFieldState({ isChangeableID }),
    setCheckEmailState,
    checkEmailState,
  }
}
