import React, { SVGProps } from 'react'

const BitIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 40 37"
    fill="none"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        fill: 'none',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.69 0C10.66-.051 2.033 2.548.463 11.264c-.742 4.12-.479 8.112 0 12.245.982 8.464 9.563 11.694 17.31 11.482 7.694-.21 16.304-2.795 17.312-11.482.48-4.133.742-8.126 0-12.245C33.709 3.625 25.997.05 18.69 0Z"
        fill="#00dc00"
      />
      <path
        d="M25.286 21.023a4.773 4.773 0 0 1-.503 2.333 4.82 4.82 0 0 1-1.571 1.805 7.822 7.822 0 0 1-2.94 1.253 2.515 2.515 0 0 1-.784 1.886 2.56 2.56 0 0 1-3.523 0 2.515 2.515 0 0 1-.784-1.965 10.764 10.764 0 0 1-4.477-2.21l2.239-3.087a7.226 7.226 0 0 0 4.816 1.805 4.587 4.587 0 0 0 1.94-.34 1.095 1.095 0 0 0 .731-.986.873.873 0 0 0-.199-.582 1.583 1.583 0 0 0-.681-.454 5.685 5.685 0 0 0-.9-.316 21.973 21.973 0 0 0-1.21-.3l-.89-.242c-.224-.05-.528-.133-.926-.242a8.89 8.89 0 0 1-.955-.315 16.873 16.873 0 0 1-.866-.39 3.673 3.673 0 0 1-.836-.518c-.24-.191-.465-.399-.676-.621a3.021 3.021 0 0 1-.563-.804 5.396 5.396 0 0 1-.333-.986 5.114 5.114 0 0 1-.134-1.199 4.716 4.716 0 0 1 .96-2.959 5.52 5.52 0 0 1 2.513-1.815c.15-.06.307-.107.467-.138a1.02 1.02 0 0 1 0-.172 2.514 2.514 0 0 1 .753-1.731 2.56 2.56 0 0 1 3.528-.058c.481.443.771 1.054.81 1.705 1.697.298 3.3.987 4.681 2.012l-2.264 3.082a7.566 7.566 0 0 0-4.284-1.48 4.117 4.117 0 0 0-1.676.292.993.993 0 0 0-.662.907c.001.177.058.35.164.493.103.145.233.269.383.365.21.125.437.222.672.291.26.094.526.171.796.232.234.064.567.133.995.237l1.03.217c.264.064.612.153 1.035.276.365.1.723.227 1.07.38l.95.493c.309.165.59.378.83.631.24.256.459.532.652.824.22.324.376.687.458 1.07.11.432.166.876.164 1.321z"
        fill="#fff"
      />
    </g>
  </svg>
)

export default BitIcon
