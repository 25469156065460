// access.ts

export default {
  slogan: 'Beli Kripto. Bayar Kripto.',
  login: 'Masuk',
  signup: 'Daftar',
  smartphone: 'Ponsel Pintar',
  login_with: '<span>$t(login) dengan</span><span>{{label}}</span>',
  login_with_different_account:
    '<span>$t(login) dengan</span><span>Akun atau Perangkat yang berbeda</span>',
  add_key: '<span>Tambahkan perangkat ke</span><span>Akun yang Terdaftar</span>',
  signup_with: '<span>$t(signup) dengan</span><span>{{label}}</span>',
  create_account: 'Buat Akun',
  more_options: 'Opsi Lainnya',
  registered_modal_text: `Apakah Anda terdaftar di Bitcash?
	
	`,
  auto_login_modal_text: `Apakah Anda ingin menggunakan akun yang terakhir masuk?
	
	`,
  no_devices: `Kesalahan memuat kredensial perangkat.
	Coba masukkan akun Anda lagi untuk masuk.`,
  no: 'Tidak',
  yes: 'Ya',
  register: 'Daftar',
  keep_session: 'Pertahankan Sesi',
  logout: 'Keluar',
  verification_complete: 'Verifikasi selesai.',
  your_session_expired: 'Sesi Anda telah berakhir. Silakan masuk kembali.',
  session_expired: 'Sesi Berakhir',
  your_session_expiring: 'Sesi Anda Akan Berakhir',
  session_about_expire:
    'Sesi Anda akan berakhir dalam kurang dari 5 menit.\n Apakah Anda ingin mempertahankannya?\n',
  Validating_credentials: 'Memvalidasi kredensial',
}
