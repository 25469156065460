import { WizardStep } from 'app-view/components/Wizard'
import { ActionSuccess } from 'pages/BankView/steps/common/ActionSuccess'
import { ExchangeAmount } from 'pages/BankView/steps/exchange/ExchangeAmount'

export const EXCHANGE_STEPS: WizardStep[] = [
  {
    name: 'bank-exchange-amount',
    component: ExchangeAmount,
  },
  {
    name: 'bank-exchange-success',
    component: ActionSuccess,
  },
]
