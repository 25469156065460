import React, { useState } from 'react'
import styled from 'app-view/styles/styled'
import { useTranslation } from 'react-i18next'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { BaseButton } from '../../../app-view/components/buttons/BaseButton'
import { ModalError } from 'app-view/components/Modal'
import { CheckIconWhite, ErrorIconWhite } from 'app-view/components/icons'
import { motion } from 'framer-motion/dist/framer-motion'
import { AccountWrapper } from './Shared'
import { SmallBoldText } from 'app-view/components/Styled'
import theme from 'app-config/theme'
import { toggleTrustNetwork } from 'app-engine/services'

type AccountItemType = 'trust_request'

const CoinOrderContainer = styled(motion.div)<{ type?: AccountItemType }>`
  background-color: #fff;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  ${({ type }) =>
    type === 'trust_request'
      ? `
      height: 80px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      padding-right: 0.625rem`
      : `height: 94px;
      `}
`

const ButtonsContainer = styled(motion.div)`
  margin-top: 14px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  flex-direction: row;
`

const Text = styled.p`
  font-size: 0.905rem;
  font-weight: 800;
  margin-bottom: 0;
  width: 45px;
  margin-right: 10px;
`

type SwapOrderItemProps = {
  account: string
  type?: AccountItemType
}

const ActionButton = styled(BaseButton)`
  min-width: 28px;
  width: 28px;
  min-height: 28px;
  height: 28px;
  border-radius: 50%;
  padding: 4px;
`

export const AccountItem = ({ account, type }: SwapOrderItemProps) => {
  const { t } = useTranslation(['p2p'])
  const [showButtons, setShowButtons] = useState(true)
  const [isAccepted, setIsAccepted] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [, globalModalActions] = useGlobalModal()

  const errorModal = (message = '') => {
    globalModalActions.open({
      content: () => <ModalError error={message} />,
      showClose: true,
      autoHide: false,
      title: 'Error',
      iconType: 'ERROR',
    })
  }

  const modifyTrustNetwork = async (reject: boolean) => {
    try {
      setShowButtons(false)
      await toggleTrustNetwork(account, reject)
      setIsAccepted(!reject)
      setShowMessage(true)
    } catch (error) {
      console.trace(error)
      errorModal((error as Error)?.message ?? error)
    }
  }

  return (
    <CoinOrderContainer
      type={type}
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -300, opacity: 0 }}
    >
      <RowContainer>
        <AccountWrapper account={account} trust={showButtons} />
        <SmallBoldText color={theme.colors.black}>{t('trust_back')}</SmallBoldText>
      </RowContainer>
      <RowContainer>
        {showButtons && (
          <ButtonsContainer
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ y1: -300, opacity: 0 }}
          >
            <ButtonContainer>
              <Text>{t('accept')}</Text>
              <ActionButton
                variant="white"
                style={{ padding: 1, marginLeft: 2 }}
                onClick={() => modifyTrustNetwork(false)}
              >
                <CheckIconWhite />
              </ActionButton>
            </ButtonContainer>
            <ButtonContainer>
              <Text>{t('reject')}</Text>
              <ActionButton variant="white" onClick={() => modifyTrustNetwork(true)}>
                <ErrorIconWhite fill="#fff" />
              </ActionButton>
            </ButtonContainer>
          </ButtonsContainer>
        )}
        {showMessage && (
          <ButtonsContainer
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -300, opacity: 0 }}
          >
            <ButtonContainer style={{ marginRight: 0 }}>
              <Text style={{ width: '100%' }}>{isAccepted ? t('accepted') : t('rejected')}</Text>
              <ActionButton variant="white" style={{ padding: 1, marginLeft: 2 }}>
                <CheckIconWhite />
              </ActionButton>
            </ButtonContainer>
          </ButtonsContainer>
        )}
      </RowContainer>
    </CoinOrderContainer>
  )
}
