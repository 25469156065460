export default {
  amount: 'Jumlah',
  leverage: 'Leverage',
  ltv: 'LTV',
  loan: 'Pinjaman',
  position: 'Posisi',
  price: 'Harga',
  custom: 'Kustom',
  select: 'Pilih',
  available: 'Saldo USD',
}
