import React from 'react'

const UntrustIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4054 9.41402L12.8201 8.00002C13.4439 7.37421 13.7942 6.52663 13.7942 5.64302C13.7942 4.75941 13.4439 3.91183 12.8201 3.28602C12.1945 2.66189 11.3468 2.31139 10.4631 2.31139C9.57938 2.31139 8.73174 2.66189 8.1061 3.28602L7.63477 3.75735L8.57743 4.70002L9.04877 4.22869C9.42445 3.85468 9.93298 3.64471 10.4631 3.64471C10.9932 3.64471 11.5017 3.85468 11.8774 4.22869C12.2518 4.6042 12.462 5.1128 12.462 5.64302C12.462 6.17324 12.2518 6.68184 11.8774 7.05735L10.4628 8.47135C10.3008 8.63228 10.1099 8.7612 9.9001 8.85135L9.04877 8.00002L9.99143 7.05735L9.5201 6.58602C9.21141 6.27543 8.84417 6.02918 8.43964 5.86153C8.0351 5.69388 7.60133 5.60817 7.16343 5.60935C7.00677 5.60935 6.8541 5.63069 6.70277 5.65335L2.57743 1.52869L1.63477 2.47135L13.6348 14.4714L14.5774 13.5287L10.8868 9.83802C11.0714 9.71535 11.2454 9.57402 11.4054 9.41402ZM7.16343 11.7714C6.78775 12.1454 6.27921 12.3553 5.7491 12.3553C5.21899 12.3553 4.71045 12.1454 4.33477 11.7714C3.96043 11.3958 3.75024 10.8872 3.75024 10.357C3.75024 9.8268 3.96043 9.3182 4.33477 8.94269L5.31877 7.95935L4.3761 7.01669L3.3921 8.00002C2.7683 8.62583 2.41801 9.47341 2.41801 10.357C2.41801 11.2406 2.7683 12.0882 3.3921 12.714C3.70135 13.0237 4.06874 13.2692 4.47318 13.4365C4.87761 13.6038 5.31111 13.6895 5.74877 13.6887C6.18654 13.6896 6.62018 13.604 7.02473 13.4367C7.42929 13.2694 7.79679 13.0238 8.1061 12.714L8.57743 12.2427L7.63477 11.3L7.16343 11.7714Z"
      fill="#4A81BD"
      fillOpacity="0.5"
    />
  </svg>
)

export default UntrustIcon
