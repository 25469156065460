export default {
  deposit: 'Deposit',
  withdrawal: 'Withdraw',
  success: 'Trade successful!',
  with_memo: 'With memo?',
  send: 'Send',
  loan: '{{ticker}} loan',
  leverage: 'Leverage',
  tab_usd_title: 'USD',
  tab_crypto_title: 'Crypto',
  deposit_success: 'Deposit successful!',
  amount_deposit: 'Amount to deposit:',
  currency_receive: 'Currency to receive:',
  amount_placeholder: 'Enter amount',
  transaction_id: 'Transaction ID',
  deposit_limit: 'Deposit Limit!',
  deposit_confirm:
    'Confirm your deposit of\n<span>{{base_amount}}</span> for <span>{{target_amount}}</span>.',
  exchange_rate_amount: 'Exchange Rate: {{exchange_rate}}',
  exchange_rate: 'Exchange Rate',
  exchange_rate_fee: 'Fee:',
  not_rate_found: 'Not available',
  account_label: 'Send to:',
  currency_label: 'Currency',
  amount_label: 'Amount:',
  amount: 'Amount',
  send_success: 'Sending is successful!',
  confirm_action: 'Confirm',
  send_action: 'Send',
  memo_label: 'Memo',
  back_action: 'Back',
  next_action: 'Next',
  bitcash_transfer: 'bitcash transfer',
  memo_placeholder: 'Enter message here',
  loading_action: 'Processing transaction...',
  exchange: 'Exchange',
  amount_withdraw: 'Amount to withdraw:',
  limit_alert: '24hr max. limit',
  withdraw_fee: 'Fee',
  withdraw_confirm:
    'Confirm withdrawal of\n<span>{{base_amount}}</span> for <span>{{target_amount}}<span>.',
  withdraw_success: 'Withdraw is successful!',
  exchange_success: 'Exchange is successful!',
  exchange_send: 'You Send',
  exchange_get: 'You Get',
  invalid_account_format: 'Invalid account format',
  indicate_account_send: 'Indicate the account to send',
  invalid_account_send: 'Invalid account to send',
  indicate_amount_send: 'Indicate the amount to send',
  invalid_amount: 'Invalid amount',
  invalid_amount_send: 'Invalid amount to send',
  required_memo: 'Indicate the required memo',
  memo_empty: 'Memo is empty',
  withdraw_helper: 'Daily limit is 5% to 10% of the total balance in your bank.',
  withdraw_reset_label: `
  
  Daily reset:
  `,
}
