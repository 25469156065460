import theme, { Theme } from 'app-config/theme'
import { Actions } from 'app-engine/graphql/generated/chaingraph'
import { useStore } from 'app-engine/store'
import { ChevronRightIcon } from 'app-view/components/icons'
import { Input, InputSection, Label } from 'app-view/components/InputField'
import { Loader } from 'app-view/components/Spinner'
import { ButtonWrapper, FlexBox, scrollbar_css } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import styled from 'app-view/styles/styled'
// ! Due to react-scripts version, we have to use the following import syntax for framer motion on some parts...
// ! Either way we must downgrade to 4.1.11 to use the normal import syntax
import { motion, MotionProps } from 'framer-motion/dist/framer-motion'
import { useSigningRequest } from 'hooks/use-signing-request'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

export function SignRequestCard({ close, next }: WizardStepProps) {
  const account = useStore.useAccount()
  const [{ actions, callbackUrl, resolved, esrType, esr }, { pushSignRequest, cancelRequest }] =
    useSigningRequest()
  const [loading, setLoading] = useState(false)

  const evmTransfer = resolved
    ? resolved.resolvedTransaction.actions.some(
        (action) =>
          action.account.toString().match(/^(bkbbanktest3|bank.bk)$/) &&
          action.name.toString() === 'stbtransfer',
      )
    : null
  const dBoardVoting = resolved
    ? resolved.resolvedTransaction.actions.some(
        (action) =>
          action.account.toString().match(/^(testrefendum)$/) && action.name.toString() === 'vote',
      )
    : null
  const pomeloGrantDonation = actions.some(
    (action) =>
      action.account.toString().match(/^(eosio.token)$/) &&
      action.name.toString() === 'transfer' &&
      action.data?.memo &&
      action.data.memo.match(/^(grant:)/) &&
      action.data?.to &&
      action.data.to.match(/^(app.pomelo)$/),
  )
  const defaultActiveIndex = !evmTransfer && !dBoardVoting && !pomeloGrantDonation ? 1 : -1

  const [activeIndex, setActiveIndex] = React.useState(defaultActiveIndex)

  const { t } = useTranslation('global')

  const toggleTransactionDataSection = (index: number) => {
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  const confirmSignRequest = async () => {
    setLoading(true)

    try {
      const { error } = await pushSignRequest()

      if (error) {
        console.error('Error pushing sign request:', error)
        return
      }

      next()
    } catch (error) {
      console.error('Error pushing sign request:', error)
    } finally {
      setLoading(false)
    }
  }

  const pomeloGrantData = actions.find((req: Actions) => req.data?.memo?.includes('grant:')) ?? {
    data: { memo: '' },
  }

  const isLogin = esrType === 'login'

  if (loading) return <Loader text="Processing signature request..." />

  let esrInfo = {}

  if (esr && esr.data) {
    esr.data.info.forEach((i) => {
      esrInfo = {
        ...esrInfo,
        [i.key]: i.value.utf8String,
      }
    })
  }

  return (
    <FlexBox
      flexDirection="column"
      alignItems="start"
      justifyContent="start"
      style={{ width: '100%', height: '100%' }}
    >
      <SignRequestCardComponent>
        <SignRequestCardHeader isLogin={isLogin}>
          {isLogin && (
            <h2>
              <Trans
                t={t}
                i18nKey={'login_request_description'}
                values={{
                  appName:
                    esrInfo['appName'] ||
                    callbackUrl.replace('https://', '').split('/')[0] ||
                    'bitlauncher.ai',
                }}
                components={[<b />]}
              />
            </h2>
          )}
          {(dBoardVoting || pomeloGrantDonation || evmTransfer) && (
            <h2>
              <b>{t('overview')}</b>
              {evmTransfer && (
                <Trans
                  t={t}
                  i18nKey={'evm_transfer_transaction'}
                  values={{
                    account,
                    amount: (actions[0] as Actions).data?.quantity?.quantity.toString(),
                    appName: esrInfo['appName'] || 'Bitlauncher',
                  }}
                  components={[<b />, <b />]}
                />
              )}
              {dBoardVoting && (
                <Trans
                  t={t}
                  i18nKey={'dboard_voting_transaction'}
                  values={{ option: 'yes' }}
                  components={[<b />, <b />]}
                />
              )}
              {pomeloGrantDonation && (
                <Trans
                  t={t}
                  i18nKey={'pomelo_grant_donation'}
                  values={{ grant: (pomeloGrantData as Actions)?.data.memo.split(':')[1] || '' }}
                  components={[<b />, <b />]}
                />
              )}
            </h2>
          )}
        </SignRequestCardHeader>
        {!isLogin && (
          <>
            <SignRequestCardCollapsibleCTA
              onClick={() => toggleTransactionDataSection(1)}
              active={activeIndex === 1}
            >
              {t('details')}
              <motion.span
                key="details"
                animate={{ transform: activeIndex === 1 ? 'rotateZ(90deg)' : 'rotateZ(0deg)' }}
                initial={{ transform: activeIndex === 1 ? 'rotateZ(0deg)' : 'rotateZ(90deg)' }}
              >
                <ChevronRightIcon fill="#000" />
              </motion.span>
            </SignRequestCardCollapsibleCTA>
            {activeIndex === 1 && (
              <SignRequestCardCollapsibleContent
                key="collapsible_content"
                {...animationCollapsibleOptions}
              >
                {actions.length > 0 &&
                  actions.map((action, index) => (
                    <React.Fragment key={`${action.account}_${action.name}_${index}`}>
                      <SignRequestCardCollapsibleContentItem>
                        <table>
                          <tr>
                            <th>{t('transaction')}</th>
                            <td>
                              <SignRequestTransactionActionWrapper as="b">
                                {action.account.toString()}
                                <ChevronRightIcon height={12} />
                                {action.name.toString()}
                              </SignRequestTransactionActionWrapper>
                            </td>
                          </tr>
                        </table>
                      </SignRequestCardCollapsibleContentItem>
                      <SignRequestCardCollapsibleContentItem>
                        <table>
                          <tr>
                            <th>{t('data')}</th>

                            {Object.keys(action.data).map((key) => (
                              <td>
                                <Label htmlFor="phoneNumber" pl={0} mb={0}>
                                  {key}
                                </Label>
                                <InputSection border="grey">
                                  <Input
                                    id={key}
                                    value={action.data[key as keyof typeof action.data]}
                                    fullradius={0}
                                    fontWeight="500"
                                    type="text"
                                    key={key}
                                    readOnly
                                  />
                                </InputSection>
                              </td>
                            ))}
                          </tr>
                        </table>
                      </SignRequestCardCollapsibleContentItem>
                    </React.Fragment>
                  ))}
              </SignRequestCardCollapsibleContent>
            )}
            <SignRequestCardCollapsibleCTA
              onClick={() => toggleTransactionDataSection(2)}
              active={activeIndex === 2}
            >
              {t('raw_data')}
              <motion.span
                key="raw_data"
                animate={{ transform: activeIndex === 2 ? 'rotateZ(90deg)' : 'rotateZ(0deg)' }}
                initial={{ transform: activeIndex === 2 ? 'rotateZ(0deg)' : 'rotateZ(90deg)' }}
              >
                <ChevronRightIcon fill="#000" />
              </motion.span>
            </SignRequestCardCollapsibleCTA>
            {activeIndex === 2 && (
              <SignRequestCardCollapsibleContent {...animationCollapsibleOptions}>
                <code>{JSON.stringify(actions, null, 2)}</code>
              </SignRequestCardCollapsibleContent>
            )}
          </>
        )}
        <Separator />
        <ButtonWrapper style={{ flexDirection: 'column', marginBlock: 20, gap: 16, maxWidth: 325 }}>
          <LargeGreenButton onClick={confirmSignRequest} style={{ marginBlock: 0 }}>
            {t('confirm')}
          </LargeGreenButton>
          <LargeBlackButton
            color={theme.colors.white}
            bgcolor={theme.colors.red}
            onClick={() => {
              cancelRequest()
              close()
            }}
            style={{ marginBlock: 0 }}
          >
            {t('cancel')}
          </LargeBlackButton>
        </ButtonWrapper>
      </SignRequestCardComponent>
    </FlexBox>
  )
}

export const Separator = styled.hr<{
  theme?: Theme | undefined
  as?: React.ElementType<any> | undefined
}>`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.gray};
  opacity: 0.5;
  border: none;
  margin-block: 0;
`

export const SignRequestCardComponent = styled.div<{
  theme?: Theme | undefined
  as?: React.ElementType<any> | undefined
  isLogin?: boolean
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 18px;

  > h2 {
    margin-block: ${(props) => (props.isLogin ? '75px' : '32px')};
  }
`

export const SignRequestCardHeader = styled(SignRequestCardComponent)`
  padding-top: 0;
  align-items: flex-start;
  gap: 8px;

  > h2 {
    width: 100%;
    white-space: pre-wrap;
    line-height: 1.8;
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    margin-inline: auto;
    justify-content: center;

    b {
      line-height: 1.8;
    }
  }
`

export const SignRequestCardCollapsibleCTA = styled.button<{
  theme?: Theme | undefined
  as?: React.ElementType<any> | undefined
  active?: boolean | undefined
}>`
  cursor: pointer;
  font-weight: 800;
  font-size: 1rem;
  display: flex;
  padding-block: 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  transition: background 0.2s ease-in-out;
  color: ${({ theme, active }) => (active ? theme.colors?.green : theme.colors?.black)};

  :not(:first-of-type) {
    border-top: 1px solid #e5e5e5;
  }
  :hover {
    background: #f5f5f5;
  }

  > span {
    transform-origin: center center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const SignRequestCardCollapsibleContent = styled(motion.ul)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  max-height: 240px;
  padding: 6px;
  margin-block: 0px;

  ${scrollbar_css}

  > code {
    font-size: 0.92rem;
    white-space: pre-wrap;
    margin: 0;
    padding: 16px;
    background: #eaeaea;
    border-radius: 24px;
  }
`

export const SignRequestCardCollapsibleContentItem = styled.li`
  display: inline-block;
  list-style-type: none;
  vertical-align: middle;

  > table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    > tr {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-block: 8px;
      gap: 8px;

      > th {
        width: 100%;
        border-bottom: 1px solid #e5e5e5;
        font-weight: 800;
        font-size: 1rem;
      }

      > td {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        width: 100%;
        padding-inline: 16px;

        > label {
          width: 100%;
          font-weight: 700;
          text-transform: capitalize;
          justify-content: flex-start;
          max-width: 330px;
        }
      }
    }
  }
`

export const SignRequestTransactionActionWrapper = styled.div<{
  theme?: Theme | undefined
  as?: React.ElementType<any> | undefined
}>`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 4px 24px;
  max-width: 100%;
  margin-left: auto;
  width: max-content;
  background: ${({ theme }) => theme.colors?.black};
  color: ${({ theme }) => theme.colors?.white};
  border-radius: 24px;
`

const animationCollapsibleOptions: MotionProps = {
  animate: {
    clipPath: 'inset(0% 0% 0% 0%)',
    height: 'auto',
  },
  initial: {
    clipPath: 'inset(0% 0% 100% 0%)',
    height: 0,
  },
  exit: {
    clipPath: 'inset(0% 0% 100% 0%)',
    height: 0,
  },
  transition: {
    duration: 0.42,
    delayChildren: 0.24,
    staggerChildren: 0.24,
  },
}
