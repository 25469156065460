import { TransactResult } from 'anchor-link'
import { apolloClient } from '../graphql/apollo-client'
import { config } from 'app-config'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { eosCoreApi } from 'app-engine/library/eosio'
import { timeout } from 'app-engine/library/utils'
import { StoreSlice } from 'app-engine/store'
import { SendTransaction2Response } from 'app-engine/store/eos-slice'
import produce from 'immer'
import { omit } from 'lodash'
import { divide, minus, times, plus } from 'number-precision'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { CoinGeckoPrices } from 'app-engine/types'

type SwapFee = {
  fee_percentage: number
  fee: number
  total_to_receive: number
  total_crypto: number
}

type BitcashSwapOrder = Bitcash.SwapOrdersSubscription['swap_orders'][number]
type SwapOrderBase = Omit<
  BitcashSwapOrder,
  'reg_account' | 'updated_at' | 'created_at' | 'id' | 'order_status'
>

export type SwapOrder = BitcashSwapOrder & SwapFee
export type InputSwapOrder = SwapOrderBase & SwapFee

type SwapOrderResponse = Promise<{
  success: boolean
}>
export type SwapSlice = {
  coinSwap: {
    swap: InputSwapOrder
    retry: boolean
    loading: boolean
    error: string
    submitted: boolean
  }
  startNewSwapOrder: (order_type: 'sell' | 'buy', coin_prices: CoinGeckoPrices) => void
  submitSwap: () => SwapOrderResponse
  resetSwapError: () => void
  setSwapBitcashAmount: (amount: number) => void
  setSwapAsset: (asset: string) => void
  setSwapAddress: (address: string) => void
  reset: () => void
}

const defaultSwap: InputSwapOrder = {
  order_type: 'buy',
  bitcash_amount: 15,
  bitcash_currency: 'BITUSD',
  asset: 'EOS',
  asset_amount: 0,
  bitcash_account: '',
  bitcash_trx: '',
  price: 0,
  wallet_address: '',
  fee: 0,
  fee_percentage: 0,
  total_to_receive: 0,
  total_crypto: 0,
}

const initialState = {
  swap: defaultSwap,
  retry: false,
  loading: false,
  error: '',
  submitted: false,
}

// Gem Fees
export const commissionPercentageByGem = (asset: string) => {
  const set_commission_fees = (coin: RegExp) => Boolean(asset?.match(coin))

  if (set_commission_fees(/^(USDT|EOS)/)) return 0.01
  if (set_commission_fees(/^BTC$/)) return 0.02
  if (set_commission_fees(/^ETH$/)) return 0.03
  // Layer 1
  if (set_commission_fees(/^(BNB|SOL|DOT|MATIC|AVAX|ATOM|FLOW|MINA|TLOS)$/)) return 0.04
  if (set_commission_fees(/^(GRIN)$/)) return 0.05

  // If coin no found on list, this will be default
  return 0.02
}

export const getOrderWithFee = (order: BitcashSwapOrder): SwapOrder => {
  const { asset, order_type, bitcash_amount, price } = order
  const isBuy = order_type === 'buy'
  const fee = times(commissionPercentageByGem(asset), bitcash_amount)
  const fee_percentage = times(commissionPercentageByGem(asset), 100)
  const total_to_receive = isBuy ? minus(bitcash_amount, fee) : bitcash_amount
  const amount_with_fee = isBuy ? minus(bitcash_amount, fee) : plus(bitcash_amount, fee)
  const total_crypto = divide(amount_with_fee, price)

  return {
    ...order,
    fee_percentage,
    fee,
    total_to_receive,
    total_crypto,
  }
}

export const createSwapSlice: StoreSlice<SwapSlice> = (set, get) => ({
  coinSwap: {
    ...initialState,
  },
  reset: () => {
    set({ coinSwap: { ...initialState } })
  },
  startNewSwapOrder: (order_type: 'sell' | 'buy', coin_prices: CoinGeckoPrices) => {
    const { coinSwap: state, account, resetSwapError } = get()
    resetSwapError()

    const { asset, bitcash_amount } = state.swap

    const isBuy = order_type === 'buy'
    const price = coin_prices[asset!]?.usd
    const fee = times(commissionPercentageByGem(asset), bitcash_amount)
    const fee_percentage = times(commissionPercentageByGem(asset), 100)
    const total_to_receive = isBuy ? minus(bitcash_amount, fee) : bitcash_amount
    const asset_amount = fixedAmountDecimals(divide(total_to_receive, price), asset, true)

    set(
      produce((state) => {
        const newSwapOrder: InputSwapOrder = {
          ...defaultSwap,
          order_type,
          bitcash_account: account,
          asset,
          bitcash_amount,
          price,
          asset_amount,
          fee_percentage,
          fee,
          total_to_receive,
        }
        state.coinSwap.swap = newSwapOrder
        state.coinSwap.retry = false
      }),
    )
  },
  submitSwap: async () => {
    // console.table(get().coin_prices)
    const { coinSwap: state, account, pushTransaction, authErrorFallback } = get()

    try {
      const isBuying = state.swap.order_type === 'buy'

      set(
        produce((state) => {
          state.coinSwap.loading = true
          state.coinSwap.error = ''
        }),
      )

      const transaction = {
        actions: [
          {
            account: config.contracts.bitcashBank,
            name: isBuying ? 'buygem' : 'sellgem',
            authorization: [
              {
                actor: state.swap.bitcash_account,
                permission: 'active',
              },
            ],
            data: {
              [isBuying ? 'buyer' : 'seller']: state.swap.bitcash_account,
              quantity: {
                quantity: `${state.swap.bitcash_amount}.00 ${state.swap.bitcash_currency}`,
                contract: config.contracts.bitcashToken,
              },
            },
          },
        ],
      }

      let transactionResult = null

      if (!state.retry) {
        transactionResult = await pushTransaction(transaction)

        await timeout(4000)
      }

      const { rows } = await eosCoreApi.get_table_rows({
        code: config.contracts.bitcashBank,
        scope: account,
        table: isBuying ? 'buygemsords' : 'sellgemsords',
        reverse: true,
      })

      if (!rows.length) {
        set(
          produce((state) => {
            state.coinSwap.retry = true
          }),
        )

        throw new Error('Failed to get last order id. Try again.')
      }

      const lastOrderId = rows[0].id.toString()
      const transactionId =
        (transactionResult as TransactResult)?.transaction?.id.toString() ||
        (transactionResult as SendTransaction2Response)?.transaction_id

      const { data, errors } = await apolloClient.mutate<
        Bitcash.CreateSwapMutation,
        Bitcash.CreateSwapMutationVariables
      >({
        mutation: Bitcash.CreateSwapDocument,
        variables: {
          object: {
            ...omit(state.swap, ['fee_percentage', 'fee', 'total_to_receive', 'total_crypto']),
            gems_id: lastOrderId,
            bitcash_trx: transactionId,
          },
        },
      })

      set(
        produce((state) => {
          state.coinSwap.retry = false
        }),
      )

      console.log('CreateSwapMutation', { data, errors })

      return { success: true }
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        set(
          produce((state) => {
            state.coinSwap.error = (error as Error).message
          }),
        )
        authErrorFallback(error)
      }
      return { success: false }
    } finally {
      set(
        produce((state) => {
          state.coinSwap.loading = false
        }),
      )
    }
  },
  resetSwapError: () => {
    set(
      produce((state) => {
        state.coinSwap.error = ''
      }),
    )
  },
  setSwapBitcashAmount: (amount: number) => {
    const {
      coinSwap: { swap },
    } = get()

    const isBuy = swap.order_type === 'buy'
    const fee = times(commissionPercentageByGem(swap.asset), amount)
    const total_to_receive = isBuy ? minus(amount, fee) : amount
    const asset_amount = fixedAmountDecimals(divide(total_to_receive, swap.price), swap.asset, true)

    set(
      produce((state) => {
        const newSwapOrder: InputSwapOrder = {
          ...swap,
          bitcash_amount: amount,
          asset_amount,
          fee,
          total_to_receive,
        }
        state.coinSwap.swap = newSwapOrder
      }),
    )
  },
  setSwapAsset: (asset: string) => {
    if (get().coinSwap.swap.asset === asset) return
    set(
      produce((state) => {
        state.coinSwap.swap.asset = asset
      }),
    )
  },
  setSwapAddress: (address: string) => {
    if (get().coinSwap.swap.wallet_address === address) return
    set(
      produce((state) => {
        state.coinSwap.swap.wallet_address = address
      }),
    )
  },
})
