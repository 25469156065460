import { query_rootGenqlSelection, bitcashClient, mutation_root } from 'graphql/bitcash'

function getSystemNotification(): {
  system_notifications: query_rootGenqlSelection['system_notifications']
} {
  return {
    system_notifications: {
      __args: {
        order_by: [{ created_at: 'desc' }],
      },
      type: true,
      updated_at: true,
      created_at: true,
      id: true,
      message: true,
      offer_id: true,
      p2p_offer: {
        buyer: true,
        seller: true,
        initiator: true,
        amount: true,
        buyer_confirmed_payment: true,
        seller_confirmed_payment: true,
        matched_datetime: true,
        updated_at: true,
        messages: {
          from: true,
          to: true,
          p2p_id: true,
        },
      },
      system_notification_read_statuses: {
        notification_id: true,
        created_at: true,
        reg_account: true,
      },
      system_type_notification: {
        type: true,
      },
    },
  }
}

async function updateSystemNotificationReadStatus({
  notification_id,
  reg_account,
}: {
  notification_id: string
  reg_account: string
}): Promise<mutation_root['update_system_notification_read_status']> {
  const { update_system_notification_read_status } = await bitcashClient.mutation({
    update_system_notification_read_status: {
      __args: {
        _set: {
          reg_account: reg_account,
          created_at: new Date().toISOString(),
        },
        where: {
          notification_id: {
            _eq: notification_id,
          },
        },
      },
      returning: {
        reg_account: true,
        created_at: true,
        notification_id: true,
        __typename: true,
      },
    },
  })

  return update_system_notification_read_status as mutation_root['update_system_notification_read_status']
}

export const systemNotificationService = {
  getSystemNotification,
  updateSystemNotificationReadStatus,
}
