import React from 'react'
import { BlackButton, GreenButton } from 'app-view/components/buttons'
import { ModalButtonsContainer } from 'app-view/components/Modal'
import { useBitcashSwapAssetsQuery } from 'app-engine/graphql/generated/bitcash'
import { useTranslation } from 'react-i18next'
import { CopyAddress } from 'app-view/components/CopyAddress'
import { useStore } from 'app-engine/store/index'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ModalText } from 'app-view/components/Modal'
import { SwapOrder, getOrderWithFee } from 'app-engine/store/swap-slice'
import { get_amount } from 'app-view/lib/utils'

export const DepositAddress = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['swap'])
  const {
    coinSwap: { swap },
  } = useStore()
  const { data } = useBitcashSwapAssetsQuery()

  const { asset, bitcash_currency, fee_percentage, bitcash_amount } = swap
  const { total_crypto } = getOrderWithFee(swap as SwapOrder)
  const totalToReceive = get_amount(`${bitcash_amount} ${bitcash_currency}`)
  const totalToSend = fixedAmountDecimals(total_crypto, asset)

  return (
    <>
      <ModalText>
        {"You're selling "}
        <span>{totalToSend}</span>
        {`
        with a ${fee_percentage}% fee for `}
        <span>{totalToReceive}</span>
        {'.\n\n'}
        {t('sell.depositAddress.text2')}
        {t('sell.depositAddress.text3')}
      </ModalText>
      <br />
      <CopyAddress
        address={data?.swap_assets.find((a) => a.asset === swap.asset)?.wallet_address || ''}
      />

      <ModalButtonsContainer>
        <BlackButton onClick={prev}>{t('back')}</BlackButton>
        <GreenButton onClick={next}>{t('next')}</GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
