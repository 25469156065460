import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { Controller, useForm } from 'react-hook-form'
import { H2, Form, Text, AccountFormWrapper } from 'pages/AccountView/components/TextElements'

import { HintError, Input, InputWrapper } from 'app-view/components/InputField'
import { InputSection } from 'app-view/components/InputField'
import { FormData, accountInputHandler, checkAccountExt } from 'pages/AccountView/utils'
import { useNavigate } from 'react-router-dom'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { checkIfAccountIsRegistered } from 'app-engine/services'

export const AddDeviceAccount: React.FC = () => {
  const { t } = useTranslation(['account'])
  const [, { setRegistrationData }] = useRegistration()
  const navigate = useNavigate()

  const form = useForm<FormData>({
    defaultValues: {
      account: '',
    },
  })

  const onSubmit = (data: FormData) => {
    setRegistrationData(data)
    navigate('/add-device/webauthn-cred')
  }

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <div>
          <H2>{t('add_device_account_title')}</H2>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('add_device_account_description'),
            }}
          />
        </div>
        <Controller
          name="account"
          control={form.control}
          rules={{
            required: t('create_account_user_account_error_required') as string,
            pattern: {
              value: /^([a-z]|[1-5]|\.bk){1,12}$/,
              message: t('create_account_user_account_error_pattern'),
            },
            validate: async (account) => {
              const exists = await checkIfAccountIsRegistered(checkAccountExt(account))
              return !exists ? (t('account_user_account_not_registered') as string) : true
            },
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={42}>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    accountInputHandler(event, {
                      form,
                      field: 'account',
                      message: t('create_account_user_account_error_pattern'),
                      domain: true,
                    })
                  }}
                  type="text"
                  placeholder={t('create_account_user_account_placeholder')}
                  maxLength={12}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error.message}</HintError>
              )}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      <div>
        <LargeGreenButton
          type="submit"
          disabled={form.formState.isValidating || form.formState.isSubmitting}
        >
          Next
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={() => navigate('/')}>
          {t('p2p:not_now')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
