import { HintError, Input, InputSection, InputWrapper } from 'app-view/components/InputField'
import { AccountFormWrapper, Form, H2, Text } from 'pages/AccountView/components/TextElements'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import * as Sentry from '@sentry/react'
import { Devices } from 'app-engine/graphql/generated/bitcash'
import { eosCoreApi } from 'app-engine/library/eosio'
import { useStore } from 'app-engine/store'
import { AccountDevices } from 'app-engine/store/account-slice'
import { DeviceSelector } from 'app-view/components/DeviceSelector'
import { get_error_modal_props } from 'app-view/components/GlobalModal/lib/utility'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { useNavigate } from 'react-router-dom'
import { AccessLoader, Spinner } from 'app-view/components/Spinner'
import { AnimatePresence } from 'framer-motion/dist/framer-motion'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { useAccountValidation } from 'pages/AccountView/hooks/useAccountValidation'
import { AccountInputHandlerProps, accountInputHandler } from 'pages/AccountView/utils'
import { Controller, useForm } from 'react-hook-form'

export const Account = () => {
  const { t } = useTranslation(['account', 'p2p', 'access'])
  const [, globalModalActions] = useGlobalModal()
  const navigate = useNavigate()
  const { loginWithWebAuthN, devices, account } = useStore()
  const [loader, setLoader] = React.useState(false)
  const [showDeviceSelector, setShowDeviceSelector] = useState(false)
  const [{ readyToLogin, loading, hint }, { validateUserAccount, updateState }] =
    useAccountValidation()
  const form = useForm<{ account: string }>({
    defaultValues: {
      account: '',
    },
  })

  const requestLoginAccount = async (device?: Devices) => {
    let error = ''
    setLoader(true)
    try {
      await loginWithWebAuthN(showDeviceSelector ? device : undefined)
      console.log('[p2p] navigate', 'loginWithWebAuthN')
      navigate('/p2p')
    } catch (err) {
      updateState({ readyToLogin: false })
      if (err instanceof Error) {
        Sentry.captureEvent({
          ...err,
          user: {
            username: form.getValues('account'),
          },
          message: `loginWithWebAuthnN::ERROR::${(err as Error).message}`,
        })
      }
      if (
        (err as Error).message.match(
          /React\s+error|Failed\s+to\s+fetch|Load\s+failed|The\s+operation\s+either\s+timed|(Expired|Duplicate)\s+(T|t)ransaction\s+at/,
        )
      ) {
        error = 'Web Authentication Error'
      } else error = t((err as Error).message)
      globalModalActions.open(get_error_modal_props(() => <ModalError error={error} />))
    } finally {
      setLoader(false)
      if (!error && showDeviceSelector) {
        globalModalActions.close()
        setShowDeviceSelector(false)
      }
    }
  }

  const onSubmit = async () => {
    const userPermissions = (await eosCoreApi.get_account(account)).getPermission('active')
    const keys = userPermissions.required_auth.keys.map(({ key }) => key.toString())
    const userKeys: AccountDevices[] = []

    devices.forEach((device) => {
      if (keys.includes(device.public_key)) {
        userKeys.push(device)
      }
    })

    if (userKeys.length > 1) return setShowDeviceSelector(true)

    return await requestLoginAccount()
  }

  const account_input_props: AccountInputHandlerProps = {
    form,
    field: 'account',
    message: t('create_account_user_account_error_pattern'),
    domain: true,
  }

  const isAccountInputValid = Object.keys(form.formState.errors).length === 0

  const shouldOmitSubmit =
    loading || !isAccountInputValid || form.formState.isValidating || form.formState.isSubmitting

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <div>
          <H2>{t('account_title')}</H2>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('account_title_description'),
            }}
          />
        </div>
        <Controller
          name="account"
          control={form.control}
          rules={{
            required: t('create_account_user_account_error_required') as string,
            pattern: {
              value: /^([a-z]|[1-5]|\.bk){1,12}$/,
              message: t('create_account_user_account_error_pattern'),
            },
            validate: async () => {
              // NOTE: If user fail/cancel once, directly it will request the user to login if user clicks right-away, but it won't show devices list:
              // => Edge case where user might fail or cancel when having a device list, asking for the first device instead showing the list.
              // => If user types his account again after first fail/cancel, the scenario above won't happen.
              // TODO: To pass validateUserAccount account_state response to submission if !isReady
              let isReady = readyToLogin

              if (!isReady) isReady = await validateUserAccount(form)

              return !isReady ? (t('account_user_account_not_registered') as string) : true
            },
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={42}>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (readyToLogin) updateState({ readyToLogin: false })
                    if (!loading) updateState({ loading: true })
                    if (hint) updateState({ hint: '' })

                    accountInputHandler(event, account_input_props, async () => {
                      await validateUserAccount(form)
                    })
                  }}
                  onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                    if (readyToLogin || !isAccountInputValid) return

                    accountInputHandler(event, account_input_props, async () => {
                      await validateUserAccount(form)
                    })
                  }}
                  type="text"
                  placeholder={t('create_account_user_account_placeholder')}
                  maxLength={12}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
                {loading && field.value && isAccountInputValid && <Spinner boxSize={20} />}
              </InputSection>
              {!form.formState.isValidating && (fieldState.error || hint) && (
                <HintError align="left">{fieldState.error?.message || hint}</HintError>
              )}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      {loader && <AccessLoader />}
      <AnimatePresence>
        {showDeviceSelector && (
          <DeviceSelector
            onClose={() => setShowDeviceSelector(false)}
            onDeviceSelection={requestLoginAccount}
          />
        )}
      </AnimatePresence>
      <div>
        <LargeGreenButton type="submit" disabled={shouldOmitSubmit || loader}>
          {t('account_setup_account_button')}
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={() => navigate('/')} disabled={loader}>
          {t('p2p:not_now')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
