import { query_rootGenqlSelection } from 'graphql/bitcash'

function getAccountProfile({ account }: { account: string }): {
  accounts_information: query_rootGenqlSelection['accounts_information']
} {
  return {
    accounts_information: {
      __args: {
        where: {
          account: {
            _eq: account,
          },
        },
      },
      id: true,
      account: true,
      email: true,
      newsletter_subscription: true,
      phone: true,
      full_name: true,
      selfie: true,
      photo_id: true,
      country_id: true,
      registration_ip: true,
      reg_account: {
        is_verified: true,
      },
    },
  }
}

function getPreferences({ account }: { account: string }): {
  preferences_by_pk: query_rootGenqlSelection['preferences_by_pk']
} {
  return {
    preferences_by_pk: {
      __args: {
        account,
      },
      language: true,
      currency: true,
      region: true,
      theme: true,
    },
  }
}

export const accountService = {
  getAccountProfile,
  getPreferences,
}
