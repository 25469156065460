import { HintError, Input, InputSection, InputWrapper, Label } from 'app-view/components/InputField'
import { AccountFormWrapper, Form, H2, Text } from 'pages/AccountView/components/TextElements'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useInsertDevicesOneMutation } from 'app-engine/graphql/generated/bitcash'
import { createWebAuthNKey } from 'app-engine/library/eosio/webauthn'
import { devicesCount } from 'app-engine/services'
import { get_error_modal_props } from 'app-view/components/GlobalModal/lib/utility'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { AccessLoader } from 'app-view/components/Spinner'
import { useAppleWarning } from 'app-view/hooks/useIosWarning'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { checkAccountExt } from 'pages/AccountView/utils'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

type StepData = { deviceName: any }

export function AddDeviceWebAuthNCred() {
  const { t } = useTranslation(['account', 'global'])
  const [{ account }] = useRegistration()
  const [insertDevicesOne] = useInsertDevicesOneMutation()
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const [, globalModalActions] = useGlobalModal()
  const openAppleWarning = useAppleWarning()

  const form = useForm<StepData>({
    defaultValues: {
      deviceName: `${account}'s device #`,
    },
  })

  const devices = useAsync(async () => await devicesCount(account), [])

  useEffect(() => {
    const deviceCount = (devices.value || 0) + 1

    form.setValue('deviceName', `${account}'s device #${deviceCount}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, devices.value])

  const onSubmit = async (data: StepData) => {
    const formatted_account = checkAccountExt(account)

    // TODO: remove apple warning
    openAppleWarning(async () => {
      try {
        setLoading(true)
        const { pubKey, credId } = await createWebAuthNKey(formatted_account)
        const res = await insertDevicesOne({
          variables: {
            object: {
              account: formatted_account,
              cred_id: credId,
              public_key: pubKey,
              device_name: data.deviceName,
            },
          },
        })
        setLoading(false)
        console.log('registring account response', res)
        navigate('/add-device/success')
      } catch (err) {
        setLoading(false)
        globalModalActions.open(
          get_error_modal_props(() => <ModalError error={(err as Error).message} />),
        )
      }
    })
  }

  return (
    <Form onSubmit={form.handleSubmit(onSubmit)}>
      <AccountFormWrapper>
        <div>
          <H2>{t('add_device_key_title')}</H2>
          <Text
            dangerouslySetInnerHTML={{
              __html: t('add_device_key_description', { account: account }),
            }}
          />
        </div>
        <Controller
          name="deviceName"
          control={form.control}
          rules={{
            required: t('create_account_key_device_error_required') as string,
          }}
          render={({ field, fieldState }) => (
            <InputWrapper mt={32}>
              <Label size="lg" pl={0}>
                {t('create_account_key_device_label')}
              </Label>
              <InputSection inputSize="sm">
                <Input
                  {...field}
                  placeholder={t('create_account_key_device_placeholder')}
                  fullradius={1}
                  fontWeight="500"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                />
              </InputSection>
              {!form.formState.isValidating && fieldState.error && (
                <HintError align="left">{fieldState.error.message}</HintError>
              )}
            </InputWrapper>
          )}
        />
      </AccountFormWrapper>
      {loading && <AccessLoader />}
      <div>
        <LargeGreenButton
          type="submit"
          disabled={form.formState.isValidating || form.formState.isSubmitting}
        >
          {t('add_device_key_submit')}
        </LargeGreenButton>
        <LargeBlackButton type="button" onClick={() => navigate(-1)}>
          {t('global:back')}
        </LargeBlackButton>
      </div>
    </Form>
  )
}
