export const showTokenSymbol = (coin: string) => {
  switch (coin) {
    case 'PETH':
      return 'ETH'
    case 'PBTC':
      return 'BTC'
    case 'BITUSD':
      return 'bitUSD'
    case 'BITVES':
      return 'bitVES'
    case 'BITCOP':
      return 'bitCOP'
    case 'BITMXN':
      return 'bitMXN'
    case 'BITARS':
      return 'bitARS'
    case 'BITCRC':
      return 'bitCRC'
    case 'BITEUR':
      return 'bitEUR'
    case 'BITCNY':
      return 'bitCNY'
    case 'BITCAD':
      return 'bitCAD'
    // Disable for a while
    // case 'BITUAH':
    //   return 'bitUAH'
    case 'BITGBR':
      return 'bitGBP'
    case 'BITAUD':
      return 'bitAUD'
    case 'BITTWD':
      return 'bitTWD'
    case 'BITMYK':
      return 'bitMYK'
    case 'BITNGN':
      return 'bitNGN'
    case 'BITUGX':
      return 'bitUGX'
    case 'BITIDR':
      return 'bitIDR'
    case 'BITVND':
      return 'bitVND'
    case 'BITTHB':
      return 'bitTHB'
    case 'BITSOL':
      return 'bitSOL'
    case 'BITCLP':
      return 'bitCLP'
    case 'BITKRW':
      return 'bitKRW'
    case 'BITYEN':
      return 'bitYEN'
    case 'BITKES':
      return 'bitKES'
    case 'BITMYR':
      return 'bitMYR'
    default:
      return coin
  }
}

export const highlighted_coins = [
  'EOS',
  'GRIN',
  'TLOS',
  'MINA',
  'FLOW',
  'AVAX',
  'ATOM',
  'DOT',
  'MATIC',
  'SOL',
  'BNB',
  'ETH',
  'BTC',
]
