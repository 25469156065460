export default {
  p2p: 'P2P',
  trade: 'Perdagangan',
  bank: 'Bank',
  swap: 'Tukar',
  filterBy: 'Filter Menurut',
  older: 'Lebih Lama',
  recent: 'Baru-Baru Ini',
  forex: 'Forex',
  from: 'Dari',
  to: 'Ke',
  sent: 'Terkirim',
  received: 'Diterima',
  p2pEmptyMsn: 'Aktivitas Bitcash P2P yang dikonfirmasi akan terlihat di sini.',
  tradeEmptyMsn: 'Aktivitas Perdagangan yang dikonfirmasi akan terlihat di sini.',
  paymentsEmptyMsn: 'Aktivitas Pembayaran yang dikonfirmasi akan terlihat di sini.',
  fee: 'biaya',

  deposit: 'deposit',
  transfer: 'transfer',
  'There is currently no buy history': 'Saat ini tidak ada riwayat pembelian',
  'There is currently no sell history': 'Saat ini tidak ada riwayat penjualan',
  'There is currently no history': 'Saat ini tidak ada riwayat',
  'There is currently no send history': 'Saat ini tidak ada riwayat pengiriman',
  'There is currently no receipt history': 'Saat ini tidak ada riwayat penerimaan',
  buy_slate_no_msg: 'Harap tunggu admin untuk mengirim pesan slatepack.',
  buy_slate_with_msg: 'Harap tunggu admin untuk menyelesaikan transaksi.',
  sell_slate_no_msg: 'Harap tunggu admin untuk menerima respons slatepack untuk finalisasi.',
  sell_slate_with_msg:
    'Finalisasi transaksi dengan respons slatepack. Tunggu admin untuk mengkonfirmasi.',
}
