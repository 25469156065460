import { SignRequestCard } from 'app-view/components/SignRequest/steps/BitcashSignRequestCard'
import { QRReaderCard } from 'app-view/components/SignRequest/steps/QRReaderCard'
import { SignSuccess } from 'app-view/components/SignRequest/steps/SignSuccess'
import { WizardStep } from 'app-view/components/Wizard'

export const SIGNING_REQUEST_STEPS: WizardStep[] = [
  {
    name: 'qr_reader',
    component: QRReaderCard,
  },
  {
    name: 'sign_request',
    component: SignRequestCard,
  },
  {
    name: 'sign_success',
    component: SignSuccess,
  },
]
