import { useSocket } from 'SocketProvider'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { useRealTimeUncompletedMutualTrustP2POffers } from 'app-view/hooks/use-realtime/use-real-time-uncompleted-mutual-trust-p2p-offers'
import { useMemo } from 'react'

export const useTrustP2POffersFilter = () => {
  const p2pTrustOffers = useRealTimeUncompletedMutualTrustP2POffers()
  const { account, isApplied, currencies, paymentMethods, isMultipleCurrency, prices } = useStore()
  const [{ preferences }, {}] = useProfile()
  const { onlineUsers } = useSocket()
  const { trustP2POffers, trustMyP2POffers, trustP2POffersCount } = useMemo(() => {
    const { trustP2POffers, trustMyP2POffers } = p2pTrustOffers.reduce(
      (offers, offer) => {
        if (!offer.matched && offer.initiator !== account) {
          let shouldReturn = true
          if (isApplied) {
            if (
              currencies.length &&
              currencies.filter((opt) => opt === offer.amount.split(' ')[1]).length === 0
            ) {
              shouldReturn = false
            } else if (
              paymentMethods.length &&
              paymentMethods.filter((opt) => opt === offer.method).length === 0
            ) {
              shouldReturn = false
            } else if (
              !isMultipleCurrency &&
              prices.length &&
              prices.filter(
                (opt) => Number(opt.split(' ')[0]) === Number(offer.amount.split(' ')[0]),
              ).length === 0
            ) {
              shouldReturn = false
            }
          }
          if (shouldReturn) {
            offers.trustP2POffers.push(offer)
          }
        } else if (offer.matched && (offer.seller === account || offer.buyer === account)) {
          offers.trustMyP2POffers.push(offer)
        }
        return offers
      },
      {
        trustMyP2POffers: [] as Array<P2POffer>,
        trustP2POffers: [] as Array<P2POffer>,
      },
    )
    return {
      trustP2POffers: trustP2POffers
        .sort((a, b) => {
          const currencyA = a.amount.split(' ')[1]
          const currencyB = b.amount.split(' ')[1]
          const dateA = Date.parse(a.created_at)
          const dateB = Date.parse(b.created_at)

          // If both currencies are the preferred currency, sort by value.
          if (currencyA === preferences.currency && currencyB === preferences.currency) {
            return dateB - dateA
          }

          // If one of the currencies is the preferred currency, prioritize it.
          if (currencyA === preferences.currency) return -1
          if (currencyB === preferences.currency) return 1

          // If currencies are the same and none of them are preferred, sort by value.
          if (currencyA === currencyB) {
            return dateB - dateA
          }

          // If currencies are different and none of them are preferred, sort by currency.
          return currencyA.localeCompare(currencyB)
        })
        // After sorting by currency, sort by online status to keep previous order.
        .sort((a, b) => {
          if (!onlineUsers[a.initiator] && !onlineUsers[b.initiator]) return -1

          const isOnlineA = onlineUsers[a.initiator] || 0
          const isOnlineB = onlineUsers[b.initiator] || 0

          return isOnlineB - isOnlineA
        }),
      trustMyP2POffers,
      trustP2POffersCount: trustP2POffers.length + trustMyP2POffers.length,
    }
  }, [
    p2pTrustOffers,
    account,
    preferences.currency,
    onlineUsers,
    currencies,
    isApplied,
    isMultipleCurrency,
    paymentMethods,
    prices,
  ])
  return { trustP2POffers, trustMyP2POffers, trustP2POffersCount }
}
