import * as Sentry from '@sentry/react'
import { createClient as createWsClient } from 'graphql-ws'
import { config as appConfig } from '../../app-config/index'
import { createClient } from './generated'
import { ClientOptions, GraphqlOperation } from './generated/runtime'

export * from './generated'

function createChaingraphClient({ config, options = {} }: GraphQLSdkProps = {}) {
  const apiUrl = appConfig.services.chaingraphGraphQL
  const { subscribe } = createWsClient({
    url: apiUrl.replace('http', 'ws'),
    ...options,
  })

  const client = createClient({
    fetcher: async (operation: GraphqlOperation | GraphqlOperation[]) => {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-chaingraph-api-key': appConfig.chaingraphKey,
      }

      // console.log(
      //   '\n ==> GraphQL Query : \n',
      //   JSON.stringify((operation as GraphqlOperation).query.replaceAll('"', ''))
      // )

      let fetchResponse
      try {
        fetchResponse = fetch(apiUrl, {
          method: 'POST',
          headers,
          body: JSON.stringify(operation),
          ...config,
        }).then((response) => response.json())
      } catch (error) {
        console.error('Error in graphql fetcher', error)

        Sentry.captureException({
          name: 'ChaingraphClientError',
          message: 'Error in graphql fetcher: ' + error.message,
          error,
        })

        throw error
      }

      return fetchResponse
    },
  })

  return {
    subscribe,
    ...client,
  }
}

export const chaingraphClient = createChaingraphClient()

export type ChaingraphClient = ReturnType<typeof createChaingraphClient>

type GraphQLSdkProps = {
  config?: RequestInit
  jwt?: string
  options?: ClientOptions
}
