import React, { useEffect, useMemo, useState } from 'react'
import styled from 'app-view/styles/styled'
import { Tabs } from 'app-view/components/Tabs'
import { AdminSwap } from './components/AdminSwap'
import { ChatProvider } from 'app-view/components/Chat/hooks/useChat'
import { AdminAccounts } from './components/AdminAccounts'
import { AdminGenerateShareLink } from './components/AdminGenerateShareLink'
import { useStore } from 'app-engine/store'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { AdminATM } from './components/AdminATM'
import { PageContainer } from 'app-view/components/Styled'
import { canAccessAdminView } from 'app-view/lib/utils'

export type AdminStatus =
  | 'approved_accounts'
  | 'pending_accounts'
  | 'open'
  | 'filled'
  | 'cancelled'
  | 'p2p'
  | 'share_link'
export type AdminTab = { label: string; value: AdminStatus }

const OptionContainer = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const AdminView: React.FC = () => {
  const account = useStore.useAccount()
  const navigate = useNavigate()
  const { t } = useTranslation(['global', 'account'])
  const location = useLocation()

  const tabs: AdminTab[] = [
    {
      label: t('global:open'),
      value: 'open',
    },
    {
      label: t('global:filled'),
      value: 'filled',
    },
    {
      label: t('global:cancelled'),
      value: 'cancelled',
    },
    {
      label: t('account:approved_accounts'),
      value: 'approved_accounts',
    },
    {
      label: t('account:pending_accounts'),
      value: 'pending_accounts',
    },
    {
      label: t('global:share_link'),
      value: 'share_link',
    },
    {
      label: t('global:p2p'),
      value: 'p2p',
    },
  ]

  const getTabFromURL = (currentTab: AdminTab): AdminTab => {
    const params = new URLSearchParams(location.search)
    const tabValue = params.get('tab')
    return tabs.find((tab) => tab.value === tabValue) || currentTab
  }

  const [currentTab, setCurrentTab] = useState(getTabFromURL(tabs[0]))

  useEffect(() => {
    setCurrentTab(getTabFromURL(currentTab))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!canAccessAdminView(account)) {
      console.log('[p2p] navigate', 'canAccessAdminView')
      navigate('/p2p', { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const activeTab = useMemo(() => {
    switch (currentTab.value) {
      case 'filled':
      case 'cancelled':
      case 'open':
        return <AdminSwap orderStatus={currentTab.value} />
      case 'approved_accounts':
      case 'pending_accounts':
        return <AdminAccounts status={currentTab.value} />
      case 'share_link':
        return <AdminGenerateShareLink />
      case 'p2p':
      default:
        return <AdminATM />
    }
  }, [currentTab.value])

  return (
    // @ts-expect-error
    <ChatProvider>
      <PageContainer>
        <Tabs
          tabs={tabs}
          currentTab={currentTab.value}
          setCurrentTab={setCurrentTab}
          style={{ overflowX: 'auto', overflowY: 'hidden', width: '100%', whiteSpace: 'nowrap' }}
        />
        <OptionContainer>{activeTab}</OptionContainer>
      </PageContainer>
    </ChatProvider>
  )
}
