import React, { useEffect, useRef, useState } from 'react'
import { HeaderStep } from './HeaderStep'
import { FooterStep } from './FooterStep'
import { KeyRecoveryPartner } from '../../components/KeyRecovery/KeyRecoveryPartner'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useKeyRecovery } from 'pages/DeviceKeysView/hooks/useKeyRecovery'
import { checkIfAccountIsRegistered } from 'app-engine/services'
import { checkAccountExt } from 'pages/AccountView/utils'
import { config } from 'app-config'
import { useStore } from 'app-engine/store'

export const RecoveryPartner = ({ next }: WizardStepProps) => {
  const { account: accountApp } = useStore()
  const [, { storePartners }] = useKeyRecovery()
  const [account, setAccount] = useState('')
  const checkAccount = useRef('')
  const [successStep, setSuccessStep] = useState(false)
  const [accountIsRegistered, setAccountIsRegistered] = useState(false)
  const [hasDao, setHasDao] = useState(false)
  const [partners, setPartners] = useState<string[]>([])
  const DaoRecovery = config.features.daoRecoveryPartner

  const addPartner = () => {
    if (account === accountApp) return
    if (partners.find((item) => item === checkAccount.current)) return
    setPartners([...partners, checkAccount.current])
    setSuccessStep(true)
    setAccount('')
  }

  const removePartner = (partner: string) => {
    const partnersRemoved = partners.filter((item) => item !== partner)
    setPartners(partnersRemoved)
    if (!partnersRemoved.length) setSuccessStep(false)
  }

  const submit = () => {
    if (hasDao) partners.push(DaoRecovery)
    storePartners({ partners, dao: hasDao })
    next()
  }

  useEffect(() => {
    checkAccount.current = checkAccountExt(account)
    checkIfAccountIsRegistered(checkAccount.current).then((isValid) =>
      setAccountIsRegistered(isValid),
    )
  }, [account])

  return (
    <>
      <HeaderStep />
      <KeyRecoveryPartner
        setAccount={setAccount}
        account={account}
        partners={partners}
        addPartner={addPartner}
        removePartner={removePartner}
        accountIsRegistered={accountIsRegistered}
        hasDao={hasDao}
        setHasDao={setHasDao}
      />
      <FooterStep isSuccessStep={successStep} clickSuccess={submit} />
    </>
  )
}
