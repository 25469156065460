import theme from 'app-config/theme'
import { getCurrencyData } from 'app-engine/library/currency-countries'
import { xLog } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { CoinSelector, CoinSelectorProps } from 'app-view/components/CoinSelector'
import { InputWrapper, Label } from 'app-view/components/InputField'
import { ButtonClose, ModalButtonsContainer, ModalConfirmButton } from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { ActionsContainer, CoinSelectorWrapper, FlexBox } from 'app-view/components/Styled'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ButtonTransparent } from 'app-view/components/buttons'
import { FilterTimesIcon } from 'app-view/components/icons'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useLoggedUser } from 'app-view/hooks/use-realtime/use-logged-user'
import { useRealTimePublicUnmatchedP2POffers } from 'app-view/hooks/use-realtime/use-real-time-public-unmatched-p2p-offers'
import { useRealTimeUncompletedMutualTrustP2POffers } from 'app-view/hooks/use-realtime/use-real-time-uncompleted-mutual-trust-p2p-offers'
import { AmountOption, getAmountOptions } from 'app-view/lib/amounts'
import styled from 'app-view/styles/styled'
import { SectionStable } from 'pages/P2PView/Styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { methods_props } from '../../hooks/useP2POffer'

const Button = styled(ModalConfirmButton)<{ wideActions?: boolean }>`
  max-width: 100%;
`

const LabelWithBorderBottom = styled.div`
  width: 100%;
  display: flex;
  height: 24px;
  font-weight: bold;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #eaeaea;
`

const ClearButtonContainer = styled(FlexBox)`
  padding: 0px 16px 0px 16px;
  position: absolute;
  top: 68px;
  left: 0;
  height: 34px;
  width: 100%;
  background-color: #f0f0f0;
  color: #787878;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #cccccc;
  > button {
    color: #787878;
    > svg > path {
      fill: #787878;
    }
  }
`

export const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 4px;
  background-color: white;
  vertical-align: middle;
  margin-right: 5px;
`

export const Filter = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const stableCoinOptions = bitcashRoom.useColyseusState((state) => state.stableCoins)
  const countriesData = bitcashRoom.useColyseusState((state) => state.countryData)
  const p2pEveryoneOffers = useRealTimePublicUnmatchedP2POffers()

  const p2pOpenOfferFilterType = useStore.useP2pOpenOfferFilterType()
  const isApplied = useStore.useIsApplied()
  const isMultipleCurrency = useStore.useIsMultipleCurrency()
  const currencies = useStore.useCurrencies()
  const paymentMethods = useStore.usePaymentMethods()
  const prices = useStore.usePrices()
  const resetP2POpenOfferFilter = useStore.useResetP2POpenOfferFilter()
  const toggleIsMultipleCurrency = useStore.useToggleIsMultipleCurrency()
  const [{ preferences }, {}] = useProfile()
  const setCurrencies = useStore.useSetCurrencies()
  const setPaymentMethod = useStore.useSetPaymentMethod()
  const setPrices = useStore.useSetPrices()
  const setApplied = useStore.useSetApplied()
  const setP2POpenFilterError = useStore.useSetP2POpenFilterError()
  const p2pTrustOffers = useRealTimeUncompletedMutualTrustP2POffers()

  const countryDataLoggedUser = useLoggedUser()

  const [loading, setLoading] = useState<'clear' | 'apply' | boolean>(false)
  const [coinOpt, setCoinOpt] = useState<string[]>(currencies)
  const [priceOpt, setPriceOpt] = useState<AmountOption[]>([])
  const [paymentOptions, setPaymentOptions] = useState<string[]>([])
  // temp filters
  const [tempPrices, setTempPrices] = useState(prices)
  const [tempCurrencies, setTempCurrencies] = useState(currencies)
  const [tempPaymentMethod, setTempPaymentMethod] = useState(paymentMethods)
  // const [tempIsMultipleCurrency, setTempIsMultipleCurrency] = useState(isMultipleCurrency)
  const [sendCoinSelectorAttr] = useState<CoinSelectorProps>({
    setCoin: (coin: any) => {
      setTempCurrencies([coin])
    },
    coinOpt,
    indicatorProps: {
      screen: 'p2p' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract: false,
    setUserInteract: () => {},
  })

  // const paymentOptions = [
  //   'bank_transfer',
  //   'paypal',
  //   'venmo',
  //   'cash_app',
  //   'zelle',
  //   'sinpe_movil',
  //   'oxxo',
  //   'ovo',
  //   'duitnow',
  //   'grab',
  //   'gojek',
  //   'opay',
  //   'palmpay',
  //   'kuda',
  //   'wechat',
  //   'alipay',
  //   'revolut',
  //   'bizum',
  //   'lyf',
  // ]

  const onConfirm = async () => {
    setLoading('apply')
    try {
      // if (isMultipleCurrency !== tempIsMultipleCurrency) {
      //   toggleIsMultipleCurrency()
      // }
      setPrices(tempPrices)
      setPaymentMethod(tempPaymentMethod)
      setCurrencies(tempCurrencies)
      setApplied()
      setTimeout(() => {
        setLoading(false)
        close()
      }, 500)
    } catch (error) {
      xLog({
        ERROR:
          '————————— Wizard from OfferTrustGlobalNetwork.tsx > Applying P2P Offers Filters  —————————',
        error: error as Error,
      })
      setLoading(false)

      setP2POpenFilterError(`We couldn't apply p2p offers filters. Try again.

      ERROR: ${(error as Error).message}`)
    }
  }

  const resetFilter = () => {
    setLoading('clear')
    try {
      resetP2POpenOfferFilter()
      setTimeout(() => {
        setLoading(false)
        close()
      }, 500)
    } catch (error) {
      xLog({
        ERROR:
          '————————— Wizard from OfferTrustGlobalNetwork.tsx > Clearing P2P Offers Filters  —————————',
        error: error as Error,
      })
      setLoading(false)

      setP2POpenFilterError(`We couldn't clear p2p offers filters. Try again.

      ERROR: ${(error as Error).message}`)
    }
  }

  const setMethodOptions = async (currency: string) => {
    const offerAmountSymbol = currency.replace('BIT', '')

    // ? We first get currency country code, so we can check it against the available payment method by local currency
    const countryCurrencyData = await getCurrencyData(offerAmountSymbol, countriesData)
    if (!countryCurrencyData) return
    const currencyCountryCode = countryCurrencyData.cca2
    // ? We check if the user is outside of the US, so we can show the international payment methods (base method only)
    const isUserOutsideOfUS = Boolean(countryDataLoggedUser?.countryCode !== 'US')
    const isInternationalCurrency =
      Boolean(offerAmountSymbol.match(/(USD|EUR)/g)) && isUserOutsideOfUS

    // ? We map the payment methods based on the currency country code and user's current location
    const method_array: string[] = !isInternationalCurrency
      ? [...(methods_props[currencyCountryCode] || []), ...methods_props.base]
      : // ? If the user is outside of the US, we only show the base payment methods along with current user location payment methods (if any for USD/EUR)
        [
          ...(methods_props[countryDataLoggedUser?.countryCode as string] || []),
          ...methods_props.base,
        ]

    method_array.map((option) => {
      const method = option.toLocaleLowerCase().replace(/\s/g, '_')
      setPaymentOptions((prevState) => {
        if (prevState.filter((item: string) => item === method).length === 0) {
          return [...prevState, method]
        } else return prevState
      })
    })
  }

  useEffect(() => {
    if (p2pOpenOfferFilterType === 'public') {
      if (
        stableCoinOptions &&
        stableCoinOptions.filter((opt) =>
          p2pEveryoneOffers.some((pos) => pos.amount.split(' ')[1] === opt),
        ).length > 0
      ) {
        setCoinOpt(
          stableCoinOptions.filter((opt) =>
            p2pEveryoneOffers.some((pos) => pos.amount.split(' ')[1] === opt),
          ),
        )
      } else if (preferences.currency) {
        setCoinOpt([preferences.currency])
      }
    } else {
      if (
        stableCoinOptions.filter((opt) =>
          p2pTrustOffers.some((pos) => pos.amount.split(' ')[1] === opt),
        ).length > 0
      ) {
        setCoinOpt(
          stableCoinOptions.filter((opt) =>
            p2pTrustOffers.some((pos) => pos.amount.split(' ')[1] === opt),
          ),
        )
      } else {
        if (preferences.currency) {
          setCoinOpt([preferences.currency])
        }
      }
    }
  }, [
    setCurrencies,
    p2pOpenOfferFilterType,
    stableCoinOptions,
    p2pTrustOffers,
    p2pEveryoneOffers,
    preferences.currency,
  ])

  useEffect(() => {
    // if (tempCurrencies.length && !tempIsMultipleCurrency) {
    if (tempCurrencies.length) {
      setPriceOpt(getAmountOptions(tempCurrencies[0]))
    }
    // }, [tempCurrencies, tempIsMultipleCurrency])
  }, [tempCurrencies])

  useEffect(() => {
    setTempPrices(prices)
    setTempCurrencies(currencies)
    setTempPaymentMethod(paymentMethods)
    // setTempIsMultipleCurrency(isMultipleCurrency)
  }, [prices, currencies, paymentMethods, isMultipleCurrency])

  useEffect(() => {
    if (tempCurrencies.length) {
      setPaymentOptions([])
      tempCurrencies.map((item: string) => {
        setMethodOptions(item)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCurrencies])

  if (loading)
    return (
      <Loader text={loading === 'apply' ? t('p2p:applyng_filters') : t('p2p:clearing_filters')} />
    )

  return (
    <div style={{ width: '95%' }}>
      {isApplied && (
        <ClearButtonContainer justifyContent="space-between">
          <ButtonTransparent
            style={{ fontWeight: 'bold' }}
            type="button"
            aria-label="Clear filters"
            onClick={resetFilter}
          >
            {t('p2p:clear_filter_btn_text')}
          </ButtonTransparent>
          <ButtonClose type="button" onClick={resetFilter} aria-label="Close Modal">
            <FilterTimesIcon
              style={{ width: '24px', height: '24px' }}
              color={theme.colors.darkGray}
            />
          </ButtonClose>
        </ClearButtonContainer>
      )}
      <ActionsContainer
        style={{
          height: '100%',
          maxHeight: '410px',
          marginTop: isApplied ? 'calc(34px + 2rem)' : '2rem',
          gap: 10,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <LabelWithBorderBottom>Currency</LabelWithBorderBottom>
        {/* <InputWrapper
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            columnGap: 14,
            maxWidth: '100%',
          }}
        >
          <Label size="md" htmlFor="select-multiple-currency" mb={0}>
            {t('p2p:select_multiple_currency')}
          </Label>
          <DeviceActionsToggle
            type="button"
            name="select-multiple-currency"
            // @ts-ignore
            isOn={tempIsMultipleCurrency}
            onClick={() => setTempIsMultipleCurrency(!tempIsMultipleCurrency)}
          />
        </InputWrapper>
        {tempIsMultipleCurrency ? (
          <FlexBox justifyContent="center" style={{ flexWrap: 'wrap' }}>
            {coinOpt.map((opt, index) => (
              <Label
                key={index}
                htmlFor={`currency${index}`}
                style={{ fontWeight: 600, verticalAlign: 'middle' }}
              >
                <CheckboxInput
                  id={`currency${index}`}
                  onChange={() => {
                    if (tempCurrencies.filter((currency) => currency === opt).length > 0) {
                      setTempCurrencies(tempCurrencies.filter((currency) => currency !== opt))
                    } else {
                      setTempCurrencies([...tempCurrencies, opt])
                    }
                  }}
                  type="checkbox"
                  style={{ height: 20, width: 20, marginRight: 10 }}
                  checked={tempCurrencies.filter((currency) => currency === opt).length > 0}
                />
                {opt.replace(/BIT/g, 'bit').replace(/GBR/g, 'GBP')}
              </Label>
            ))}
          </FlexBox>
        ) : (
          <SectionStable>
            <CoinSelectorWrapper>
              <CoinSelector {...sendCoinSelectorAttr} coinOpt={coinOpt} coin={tempCurrencies[0]} />
            </CoinSelectorWrapper>
          </SectionStable>
        )} */}
        <InputWrapper
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            columnGap: 14,
            maxWidth: '100%',
          }}
        >
          <SectionStable>
            <CoinSelectorWrapper>
              <CoinSelector {...sendCoinSelectorAttr} coinOpt={coinOpt} coin={tempCurrencies[0]} />
            </CoinSelectorWrapper>
          </SectionStable>
        </InputWrapper>
        <LabelWithBorderBottom>{t('p2p:payment_method')}</LabelWithBorderBottom>
        <FlexBox justifyContent="center" style={{ flexWrap: 'wrap' }}>
          {paymentOptions.map((opt, index) => (
            <Label
              key={index}
              htmlFor={`paymentMethod${index}`}
              style={{ fontWeight: 600, verticalAlign: 'middle' }}
            >
              <CheckboxInput
                id={`paymentMethod${index}`}
                onChange={() => {
                  if (tempPaymentMethod.filter((method) => method === opt).length > 0) {
                    setTempPaymentMethod(tempPaymentMethod.filter((method) => method !== opt))
                  } else {
                    setTempPaymentMethod([...tempPaymentMethod, opt])
                  }
                }}
                type="checkbox"
                style={{ height: 20, width: 20, marginRight: 10 }}
                checked={tempPaymentMethod.filter((method) => method === opt).length > 0}
              />
              {t(opt)}
            </Label>
          ))}
        </FlexBox>
        {/* <LabelWithBorderBottom style={{ opacity: tempIsMultipleCurrency ? '0.5' : '1' }}> */}
        <LabelWithBorderBottom>{t('p2p:prices')}</LabelWithBorderBottom>
        <FlexBox justifyContent="center" style={{ flexWrap: 'wrap' }}>
          {priceOpt &&
            priceOpt.map((opt, index) => (
              <Label
                key={index}
                htmlFor="newsletterSubscription"
                style={{
                  fontWeight: 600,
                  verticalAlign: 'middle',
                  // opacity: tempIsMultipleCurrency ? '0.5' : '1',
                }}
              >
                <CheckboxInput
                  id={`paymentMethod${index}`}
                  onChange={() => {
                    if (tempPrices.filter((price) => price === opt.value).length > 0) {
                      setTempPrices(tempPrices.filter((price) => price !== opt.value))
                    } else {
                      setTempPrices([...tempPrices, opt.value])
                    }
                  }}
                  // disabled={tempIsMultipleCurrency}
                  type="checkbox"
                  style={{ height: 20, width: 20, marginRight: 10 }}
                  checked={tempPrices.filter((price) => price === opt.value).length > 0}
                />
                {opt.label}
              </Label>
            ))}
        </FlexBox>
      </ActionsContainer>
      <ModalButtonsContainer
        /* @ts-ignore */
        style={{
          flexDirection: 'column-reverse',
          flexWrap: 'wrap',
        }}
      >
        <Button
          wideActions
          isDestructive
          onClick={() => {
            close()
          }}
        >
          {t('cancel')}
        </Button>
        <Button
          wideActions
          onClick={() => {
            onConfirm()
          }}
        >
          {t('global:confirm')}
        </Button>
      </ModalButtonsContainer>
    </div>
  )
}
