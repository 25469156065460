import { useStore } from 'app-engine/store'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { useRealTimeTrustNetworkMutualTrust } from './use-realtime-trust-network-mutual-trust'

const getPublicOffers = (rawOffers, account, mutualTrust) => {
  if (!rawOffers) return []
  const offerList = Object.values(rawOffers.toJSON()) as P2POffer[]
  return offerList.filter((offer) => {
    const participants = [offer.buyer, offer.seller]
    return participants.every(
      (participant) => participant !== account && !mutualTrust.includes(participant),
    )
  })
}

export const useRealTimePublicUnmatchedP2POffers = () => {
  const account = useStore.useAccount()
  const mutualTrust = useRealTimeTrustNetworkMutualTrust()
  const rawOffers = bitcashRoom.useColyseusState((state) => state.publicUnmatchedP2POffers)
  const publicOffers = getPublicOffers(rawOffers, account, mutualTrust)
  return publicOffers || []
}
