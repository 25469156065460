import * as React from 'react'
import { SVGProps } from 'react'

const ExchangeArrowsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={46}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 46 45"
    {...props}
  >
    <g clipPath="url(#exchange_icon)" fill="#0F121F">
      <path d="M8.794 20.323h3.043c-.434 2.174 1.305 11.085 10.868 12.824 0 0-6.52-5.434-4.347-12.823h3.477l-6.737-6.521-6.304 6.52ZM36.596 24.503h-3.042c.434-2.173-1.305-11.085-10.868-12.823 0 0 6.52 5.433 4.347 12.823h-3.478l6.738 6.52 6.303-6.52Z" />
    </g>
    <defs>
      <clipPath id="exchange_icon">
        <path fill="#fff" transform="translate(.5)" d="M0 0h45v45H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default ExchangeArrowsIcon
