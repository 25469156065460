import React from 'react'

const USDTBNBIcon = (props: any) => (
  <svg viewBox="0 0 62 58" {...props} id="usdtbnb-icon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.4949 0.00132486C17.394 -0.0869867 3.31684 4.22055 0.755737 18.6637C-0.455081 25.4906 -0.0261733 32.1073 0.755737 38.9566C2.35797 52.9824 16.3583 58.3349 28.9996 57.9839C41.5518 57.6356 55.6002 53.3518 57.2444 38.9561C58.0264 32.1073 58.4548 25.4906 57.2444 18.6637C54.9998 6.00651 42.416 0.0824639 30.4949 0.00132486Z"
      fill="#26A17B"
    />
    <path
      d="M31.9601 29.7788V29.7748C31.7899 29.7867 30.9115 29.8381 28.9568 29.8381C27.3939 29.8381 26.2979 29.7946 25.9101 29.7748V29.7788C19.8996 29.5137 15.4165 28.4691 15.4165 27.2147C15.4165 25.9644 19.9036 24.9158 25.9101 24.6507V28.7381C26.3018 28.7658 27.4295 28.8331 28.9845 28.8331C30.8522 28.8331 31.786 28.754 31.9601 28.7381V24.6547C37.9587 24.9237 42.4299 25.9683 42.4299 27.2187C42.4299 28.4691 37.9547 29.5137 31.9601 29.7827V29.7788ZM31.9601 24.2313V20.5752H40.3288V15H17.5453V20.5752H25.914V24.2313C19.1122 24.5439 14 25.8892 14 27.5036C14 29.118 19.1162 30.4633 25.914 30.7799V42.5H31.964V30.7799C38.754 30.4673 43.8583 29.1219 43.8583 27.5076C43.8583 25.8971 38.754 24.5478 31.964 24.2353"
      fill="white"
    />
    <circle cx="49" cy="45" r="13" fill="#F3BA2F" />
    <path
      d="M45.116 43.404L49 39.52L52.886 43.406L55.146 41.146L49 35L42.856 41.144L45.116 43.404ZM39 45L41.26 42.74L43.52 45L41.26 47.26L39 45ZM45.116 46.596L49 50.48L52.886 46.594L55.146 48.853L49 55L42.856 48.856L42.853 48.853L45.116 46.596ZM54.48 45L56.74 42.74L59 45L56.74 47.26L54.48 45ZM51.292 44.998H51.294V45L49 47.294L46.709 45.004L46.705 45L46.709 44.997L47.11 44.595L47.305 44.4L49 42.706L51.293 44.999L51.292 44.998Z"
      fill="white"
    />
  </svg>
)

export default USDTBNBIcon
