export default {
  settings: 'Pengaturan',
  device_keys: 'Perangkat &\nKunci',
  trade: 'Perdagangan',
  wallet: 'Dompet',
  history: 'Riwayat',
  p2p: 'P2P',
  swap: 'Tukar',
  bank: 'Bank',
  deposit: 'Deposit',
  send: 'Kirim',
  withdrawal: 'Tarik',
  faucet: 'Faucet',
  account: 'Masuk',
  create_account: 'Buat',
  create_account_webauthn_cred: 'Buat',
  register_account: 'Daftar',
  register_account_success: 'Terdaftar',
  about: 'Tentang',
  add_device_account: 'Tambah Perangkat',
  add_device_webauthn_cred: 'Tambah Perangkat',
  add_device_success: 'Perangkat Ditambahkan',
  notifications: 'Pemberitahuan',
  create_account_success: 'Akun Dibuat',
  create_account_kyc: 'Informasi Akun',
  register_account_kyc: 'Informasi Akun',
}
