const ChevronRightIcon = (props: any) => (
  <svg viewBox="0 0 407.436 407.436" width="1em" height="1em" {...props}>
    <path
      fill={props?.fill || '#fff'}
      d="M112.814 0L91.566 21.178l181.946 182.54-181.946 182.54 21.248 21.178 203.055-203.718z"
    />
  </svg>
)

export default ChevronRightIcon
