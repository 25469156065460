import { useStore } from 'app-engine/store'
import { useSigningRequest } from 'hooks/use-signing-request'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ExploreView = () => {
  const account = useStore.useAccount()
  const pubKey = useStore.usePub_key()
  const [, { requestSignature }] = useSigningRequest()
  const location = useLocation()

  const handleMessage = (event: MessageEvent) => {
    const { eventType } = event.data
    if (eventType === 'esr') requestSignature(event.data.code, { account, pubKey })
    if (eventType === 'url_change') console.log('url_change event', event.data)
  }

  useEffect(() => {
    // Listen to parent events for /explore experience
    window.addEventListener('message', handleMessage, false)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getIframeSrc = () => {
    const queryParams = new URLSearchParams(location.search)
    const src = queryParams.get('s')
    return src ? src : 'https://bitlauncher.ai/?bitcash_explorer=true'
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        overflowX: 'scroll',
        backgroundColor: 'black',
      }}
    >
      <iframe
        src={getIframeSrc()}
        style={{
          width: '100vw',
          height: '100vh',
          border: '0',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowX: 'scroll',
          background: 'black',
        }}
        title="External Content"
      />
    </div>
  )
}
