import React from 'react'

const SelfiePlaceHolder = (props: any) => (
  <svg
    version="1.0"
    viewBox="0 0 683.000000 683.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props}
  >
    <g
      transform="translate(0.000000,683.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path d="M3344 6611 c3 -5 -65 -11 -152 -14 -116 -3 -170 -9 -207 -22 -40 -15 -46 -20 -30 -26 11 -4 34 -8 50 -9 24 -1 15 -7 -45 -29 -201 -75 -442 -216 -537 -315 -36 -36 -47 -51 -28 -39 74 50 78 47 29 -23 -62 -92 -119 -208 -145 -296 -17 -56 -23 -110 -26 -235 -6 -185 4 -274 48 -442 26 -100 29 -118 17 -140 -21 -39 -13 -154 20 -282 50 -196 69 -237 134 -301 l58 -58 35 -152 c29 -130 43 -171 95 -273 l60 -120 0 -210 c0 -305 -23 -380 -151 -499 -132 -122 -342 -209 -871 -361 -505 -146 -565 -170 -676 -271 -225 -203 -321 -484 -432 -1264 -22 -154 -67 -437 -101 -635 -35 -205 -99 -455 -140 -552 l-18 -43 3014 0 3015 0 -19 49 c-31 76 -106 389 -140 576 -30 171 -41 239 -106 705 -94 667 -189 946 -387 1136 -106 102 -217 152 -517 235 -296 82 -390 110 -546 162 -348 117 -530 225 -609 360 -51 87 -61 152 -61 397 l1 225 32 48 c62 89 93 179 147 427 15 69 20 77 58 105 55 40 96 124 137 280 37 138 50 273 30 310 -11 21 -9 41 13 137 45 190 52 247 52 418 0 141 -4 178 -23 250 -30 112 -75 209 -139 297 -29 40 -50 73 -48 73 3 0 30 -14 60 -30 l56 -30 -23 31 c-26 35 -124 112 -180 140 -37 19 -52 34 -23 23 8 -4 15 -3 15 0 0 10 -68 34 -117 42 -38 5 -57 16 -88 47 -91 91 -218 153 -383 187 -103 21 -217 27 -208 11z m1266 -4174 c13 -7 35 -28 47 -46 22 -32 23 -43 23 -226 l0 -193 -37 -7 c-47 -8 -94 -37 -128 -76 -66 -79 0 -205 127 -240 l39 -10 -3 -334 c-3 -319 -4 -335 -24 -362 -11 -15 -36 -36 -55 -46 -32 -16 -108 -17 -1271 -15 l-1237 3 -30 29 c-17 17 -36 46 -41 65 -11 39 -14 579 -4 629 5 26 14 34 54 47 97 32 157 127 128 203 -16 42 -44 65 -123 97 l-60 24 0 188 c0 171 2 191 20 223 13 22 34 39 55 47 52 18 2485 18 2520 0z" />
      <path d="M2558 2210 c-71 -21 -125 -75 -165 -165 -14 -31 -17 -67 -18 -165 0 -149 19 -209 92 -290 45 -50 45 -51 41 -110 -5 -77 -21 -93 -122 -126 -125 -41 -155 -59 -177 -107 -24 -54 -24 -82 1 -107 19 -19 33 -20 424 -20 445 0 436 -1 436 61 0 76 -43 120 -150 154 -126 40 -157 69 -167 152 -5 49 -4 52 37 93 111 111 141 333 68 492 -46 98 -120 148 -218 147 -30 0 -67 -4 -82 -9z" />
      <path d="M3222 1790 c-35 -15 -48 -54 -28 -85 l16 -25 550 0 550 0 16 25 c21 32 7 71 -32 85 -32 13 -1040 12 -1072 0z" />
      <path d="M3205 1525 c-30 -29 -31 -45 -5 -73 l21 -22 513 0 c484 0 514 1 529 18 24 27 21 58 -9 81 -26 21 -36 21 -526 21 l-499 0 -24 -25z" />
    </g>
  </svg>
)

export default SelfiePlaceHolder
