import theme from 'app-config/theme'
import { globalCurrencies } from 'app-engine/library/currency-constants'
import { useStore } from 'app-engine/store'
import {
  HintError as HintErrorTemplate,
  Input,
  InputSection,
  InputWrapper,
} from 'app-view/components/InputField'
import Spinner from 'app-view/components/Spinner'
import { FlexBox } from 'app-view/components/Styled'
import { Wizard, useWizard } from 'app-view/components/Wizard'
import { GreenButton } from 'app-view/components/buttons'
import { TakePhotoIcon } from 'app-view/components/icons'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { useSelfie } from 'app-view/hooks/use-selfie'
import { FieldStateProps } from 'app-view/hooks/useKYCForm'
import styled from 'app-view/styles/styled'
import {
  ContainerSelect,
  CountryIdTypes,
  InputStyled,
  PhotoWrapper,
  baseCameraImageFrameStyles,
} from 'pages/AccountView/routes/shared/AccountFormFields'
import { KeySelect, OptionKeySelect } from 'pages/AccountView/routes/shared/KeySelect'
import { TAKE_SELFIE } from 'pages/AccountView/routes/shared/step-for-upload-selfie'
import { DownloadLinkButton } from 'pages/AccountView/routes/shared/step-for-upload-selfie/components/DownloadLinkButton'
import { CameraImageFrame } from 'pages/AccountView/routes/shared/step-for-upload-selfie/components/Styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'
import { Label } from './Shared'

interface ProfileFormFieldsProps {
  register: any // Replace with your form register function type
  formState: any // Replace with your form state type
  checkCountryIdState: FieldStateProps
  checkEmailState: FieldStateProps
  handleCountryIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  validPhoneNumberState: FieldStateProps
  verifyEmailAddress: (event: React.ChangeEvent<HTMLInputElement>) => void
  formatUserPhoneNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFullNameValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  phoneNumberCountryCode: string
  formattedValue: string
  phoneValue: string
  emailValue: string
  fullNameValue: string
  selfie: string
  setIdType: (value: string) => void
  setCountry: (value: string) => void
  countryIdValue: string
  country: string
  idType: string
  setSelfie: (selfie: string) => void
  photoId: string
  setPhotoId: (selfie: string) => void
  isChangeableSelfie: boolean
  isChangeablePhotoId: boolean
  isChangeableID: boolean
  preFullName: string
}

const HintError = styled(HintErrorTemplate)`
  position: initial;
`

const CheckboxInputSection = styled(InputSection)`
  display: inline-block;
  vertical-align: middle;
`

const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  border: 2px solid #999;
  border-radius: 4px;
  background-color: white;
  vertical-align: middle;
  margin-right: 5px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
`

export const ProfileFormFields: React.FC<ProfileFormFieldsProps> = ({
  register,
  formState,
  validPhoneNumberState,
  setFullNameValue,
  verifyEmailAddress,
  formatUserPhoneNumber,
  phoneNumberCountryCode,
  formattedValue,
  phoneValue,
  emailValue,
  fullNameValue,
  selfie,
  setSelfie,
  checkCountryIdState,
  handleCountryIdChange,
  setIdType,
  setCountry,
  countryIdValue,
  idType,
  country,
  isChangeableID,
  isChangeableSelfie,
  isChangeablePhotoId,
  setPhotoId,
  photoId,
  preFullName,
  checkEmailState,
}) => {
  const { t } = useTranslation(['profile', 'p2p'])
  const { userCountryData } = useStore()
  const [selectType, setSelectType] = useState<OptionKeySelect>(CountryIdTypes[0])
  const [selectCountry, setSelectCountry] = useState<OptionKeySelect>()
  const [countries, setCountries] = useState<OptionKeySelect[]>()
  const [{ accountProfile }, {}] = useProfile()
  const [{ open }, { start }] = useWizard()
  const {
    error,
    setError,
    selfie: preSelfie,
    setSelfie: setPreSelfie,
    downloadData,
    setDownloadData,
    idCard,
    downloadPhotoIDData,
    setDownloadPhotoIDData,
  } = useSelfie()

  useEffect(() => {
    if (accountProfile?.selfie) {
      setSelfie(accountProfile?.selfie)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountProfile?.selfie])

  useEffect(() => {
    if (accountProfile?.photo_id) {
      setPhotoId(accountProfile?.photo_id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountProfile?.selfie])

  useEffect(() => {
    if (idType && CountryIdTypes.filter((i) => i.value === idType).length > 0) {
      setSelectType(CountryIdTypes.filter((i) => i.value === idType)[0])
    }
    if (
      country &&
      countries.filter((i) => i.value.toLowerCase().replace(/\s/g, '-') === country).length > 0
    ) {
      setSelectCountry(
        countries.filter((i) => i.value.toLowerCase().replace(/\s/g, '-') === country)[0],
      )
    }
  }, [idType, country, countries])

  useDeepCompareEffect(() => {
    const keySelections = globalCurrencies.map((currency) => {
      const keySelection = {
        icon: currency.flag,
        label: currency.commonName,
        value: currency.commonName,
      }
      if (currency.commonName === userCountryData?.country_name?.toLowerCase() && isChangeableID) {
        setSelectCountry(keySelection)
        setCountry(currency.commonName.replace(/\s/g, '-'))
      }
      return keySelection
    })
    setCountries(keySelections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCountryData])

  const reTakeSelfie = () => {
    if (!selfie) {
      setDownloadData('')
      setSelfie('')
    }
    if (!photoId) {
      setDownloadPhotoIDData('')
      setPhotoId('')
    }
    if (accountProfile?.selfie) {
      setPreSelfie(accountProfile?.selfie)
    }
    start(TAKE_SELFIE)
  }

  return (
    <Container style={{ alignItems: 'center', width: '100%', gap: 0 }}>
      <InputWrapper>
        <Label htmlFor="countryId">{t('account:country_id_label')} *</Label>
        <InputSection
          inputSize="sm"
          border="grey"
          error={Boolean(
            isChangeableID &&
              !formState.isValidating &&
              countryIdValue &&
              formState.errors.countryId &&
              (formState.errors.countryId?.message ||
                (!checkCountryIdState.valid && !checkCountryIdState.loading)),
          )}
          style={{
            backgroundColor: isChangeableID ? 'white' : '#f2f2f2',
          }}
        >
          <ContainerSelect style={{ height: '100%' }}>
            <KeySelect
              disabled={!isChangeableID}
              options={CountryIdTypes}
              onChange={(data) => {
                if (isChangeableID) {
                  setSelectType(data)
                  setIdType(data.value)
                }
              }}
              value={selectType}
            />
            <InputStyled
              {...register('countryId', { required: true })}
              id="countryId"
              {...(isChangeableID ? { onChange: handleCountryIdChange } : '')}
              placeholder="xxxxxxxxxxxxxxxxxxx"
              value={countryIdValue}
              fullradius={1}
              readOnly={!isChangeableID}
              fontWeight="500"
              type="text"
              style={{
                borderColor: Boolean(
                  isChangeableID &&
                    countryIdValue &&
                    !formState.isValidating &&
                    (formState.errors.countryId?.message ||
                      (!checkCountryIdState.valid && !checkCountryIdState.loading)),
                )
                  ? theme.colors.red
                  : '#ccc',
                height: '100%',
              }}
              extraerror={Boolean(
                countryIdValue &&
                  !formState.isValidating &&
                  (formState.errors.countryId?.message ||
                    (!checkCountryIdState.valid && !checkCountryIdState.loading)),
              ).toString()}
              paddingspace="none"
            />
          </ContainerSelect>
          {checkCountryIdState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {isChangeableID &&
          !formState.isValidating &&
          countryIdValue &&
          (formState.errors.countryId?.message ||
            (!checkCountryIdState.valid && !checkCountryIdState.loading) ||
            checkCountryIdState.valid) && (
            <HintError
              align="left"
              error={Boolean(
                !formState.isValidating &&
                  (formState.errors.countryId?.message ||
                    (!checkCountryIdState.valid && !checkCountryIdState.loading)),
              )}
            >
              {formState.errors.countryId?.message ||
                (formState.errors.countryId &&
                  !checkCountryIdState.valid &&
                  t('account:id_invalid')) ||
                (!checkCountryIdState.loading && t('account:country_id_valid'))}
            </HintError>
          )}
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="country">{t('settings:country')} *</Label>
        <InputSection inputSize="sm" border="grey" style={{ display: 'flex', overflow: 'hidden' }}>
          <KeySelect
            disabled={!isChangeableID}
            options={countries}
            onChange={(data) => {
              if (isChangeableID) {
                setSelectCountry(data)
                setCountry(data.value.toLowerCase().replace(/\s/g, '-'))
              }
            }}
            value={selectCountry}
          />
        </InputSection>
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="fullName">{t('p2p:full_name_label')} *</Label>
        <InputSection
          inputSize="sm"
          border="grey"
          error={Boolean(!formState.isValidating && formState.errors.fullName && !fullNameValue)}
        >
          <Input
            radius="soft"
            {...register('fullName', { required: true })}
            onChange={setFullNameValue}
            value={fullNameValue}
            id="fullName"
            placeholder="Jane Doe"
            type="text"
            fullradius={1}
            fontWeight="500"
            readOnly={Boolean(preFullName)}
          />
        </InputSection>
        {!formState.isValidating && formState.errors.fullName && !fullNameValue && (
          <HintError error={formState.errors.fullName && !fullNameValue} align="left">
            {formState.errors.fullName?.message || t('account:full_name_invalid')}
          </HintError>
        )}
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="phoneNumber">{t('phone_number')} *</Label>
        <InputSection
          style={{ overflow: 'hidden' }}
          inputSize="sm"
          error={Boolean(
            !formState.isValidating &&
              (formState.errors.phoneNumber?.message || formState.errors.phoneNumber?.type),
          )}
          border="grey"
        >
          <Input
            radius="soft"
            {...register('phoneNumber', { required: true })}
            id="phoneNumber"
            onChange={formatUserPhoneNumber}
            placeholder={t('phone_number_placeholder', {
              phoneNumberCountryCode: phoneNumberCountryCode ? `(${phoneNumberCountryCode}) ` : '',
            })}
            value={formattedValue}
            fullradius={0}
            fontWeight="500"
            type="tel"
          />
          {validPhoneNumberState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {!phoneNumberCountryCode &&
          !phoneValue &&
          (!formState.errors.phoneNumber?.message || !validPhoneNumberState.valid) && (
            <HintError align="left">{t('include_country_code')}</HintError>
          )}
        {!formState.isValidating &&
          (formState.errors.phoneNumber?.message ||
            validPhoneNumberState.valid ||
            formState.errors.phoneNumber?.type) && (
            <HintError
              error={Boolean(
                !formState.isValidating &&
                  (formState.errors.phoneNumber?.message || formState.errors.phoneNumber?.type),
              )}
              align="left"
            >
              {formState.errors.phoneNumber?.message ||
                (formState.errors.phoneNumber?.type && t('account:phone_invalid')) ||
                t('phone_valid')}
            </HintError>
          )}
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="emailAddress">{t('email')} *</Label>
        <InputSection
          inputSize="sm"
          error={Boolean(
            !formState.isValidating &&
              (formState.errors.emailAddress?.message || formState.errors.emailAddress?.type),
          )}
          border="grey"
        >
          <Input
            radius="soft"
            {...register('emailAddress', { required: true })}
            inputMode="email"
            value={emailValue}
            id="emailAddress"
            onChange={verifyEmailAddress}
            placeholder="example@email.com"
            fullradius={1}
            fontWeight="500"
          />
          {checkEmailState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {!formState.isValidating &&
          (formState.errors.emailAddress?.message ||
            formState.errors.emailAddress?.type ||
            checkEmailState.valid) && (
            <HintError
              error={Boolean(
                !formState.isValidating &&
                  (formState.errors.emailAddress?.message || formState.errors.emailAddress?.type),
              )}
              align="left"
            >
              {formState.errors.emailAddress?.message ||
                (!checkEmailState.valid && t('account:email_invalid')) ||
                t('account:email_valid')}
            </HintError>
          )}
      </InputWrapper>
      <InputWrapper>
        <Label htmlFor="selfie">{t('account:selfie_label')} *</Label>
        <InputSection
          border="grey"
          style={{ height: 'auto' }}
          error={Boolean(
            !formState.isValidating &&
              ((preSelfie !== null ?? selfie !== null) ||
                (idCard !== null ?? photoId !== null) ||
                formState.isSubmitted) &&
              (!preSelfie ?? !selfie) &&
              !open,
          )}
        >
          <PhotoWrapper
            error={false}
            id="selfie"
            style={{
              flexDirection:
                Boolean(preSelfie ?? selfie) && Boolean(idCard ?? photoId) ? 'column' : 'row',
            }}
            selfie={Boolean(preSelfie ?? selfie) && Boolean(idCard ?? photoId)}
          >
            {(selfie || preSelfie) && (photoId || idCard) ? (
              <FlexBox justifyContent="space-between" style={{ width: '100%' }}>
                <CameraImageFrame
                  selfieStatus
                  style={{
                    ...baseCameraImageFrameStyles,
                    borderTopRightRadius: '0',
                    width: 'calc(50% - 5px)',
                    height: 'auto',
                  }}
                  src={selfie || preSelfie}
                />
                <CameraImageFrame
                  selfieStatus
                  style={{
                    ...baseCameraImageFrameStyles,
                    borderTopLeftRadius: '0',
                    width: 'calc(50% - 5px)',
                    height: 'auto',
                  }}
                  src={photoId || idCard}
                />
              </FlexBox>
            ) : (
              <TakePhotoIcon style={{ height: '46px', width: '46px' }} />
            )}
            <FlexBox
              flexDirection="column"
              alignItems="center"
              style={{ width: 'fit-content', padding: selfie ? '20px 0' : 0 }}
            >
              {isChangeableSelfie || isChangeablePhotoId ? (
                <GreenButton
                  disabled={!isChangeableSelfie && !isChangeablePhotoId}
                  onClick={reTakeSelfie}
                  type="button"
                  style={{
                    fontSize: '17px',
                    padding: '8px 12px 8px 12px',
                    minHeight: '33px',
                    marginTop: downloadData ? '10px' : '',
                  }}
                >
                  {downloadData ? t('account:retake_photos') : t('account:take_selfie_id')}
                </GreenButton>
              ) : null}
              {downloadData && downloadPhotoIDData ? (
                <DownloadLinkButton
                  style={{ border: 'none', marginTop: 0, padding: 0 }}
                  downloadLink={downloadData}
                  downloadLink2={downloadPhotoIDData}
                />
              ) : (
                ''
              )}
            </FlexBox>
          </PhotoWrapper>
        </InputSection>
        {Boolean(
          !formState.isValidating &&
            ((preSelfie !== null ?? selfie !== null) || formState.isSubmitted) &&
            ((idCard !== null ?? photoId !== null) || formState.isSubmitted) &&
            (!preSelfie ?? !selfie) &&
            (!idCard ?? !photoId) &&
            !open,
        ) && (
          <HintError error align="left">
            {t('account:photo_invalid')}
          </HintError>
        )}
      </InputWrapper>
      <InputWrapper>
        <CheckboxInputSection inputSize="sm">
          <Label
            htmlFor="newsletterSubscription"
            style={{ fontWeight: 600, verticalAlign: 'middle' }}
          >
            <CheckboxInput
              {...register('newsletterSubscription')}
              type="checkbox"
              style={{ height: 20, width: 20, marginRight: 10 }}
            />
            {t('newsletter')}
          </Label>
        </CheckboxInputSection>
      </InputWrapper>
      <Wizard
        error={error}
        resetError={() => {
          setError('')
        }}
      />
    </Container>
  )
}
