import { BitcashLoginLogoIcon } from 'app-view/components/icons'
import BitcashLogoBackgroundIcon from 'app-view/components/icons/stableicons/BitcashLogoBackgroundIcon'
import { MotionProps } from 'framer-motion/dist/framer-motion'
import {
  BackgroundContainer,
  Branding,
  MaintenanceContainer,
  LogoContainer,
  Slogan,
  Message,
  SupportChannel,
} from 'pages/MaintenanceView/components/style'

import { useTranslation } from 'react-i18next'
import { Link } from 'pages/AccountView/components/TextElements'

const discordUrl = 'https://discord.com/channels/856694458388054026/896893967914893343'

export const bitcashBgLogoAnimationProps: MotionProps = {
  animate: {
    x: ['-8.333%', '42%', '-42%', '8.333%', '-8.333%'],
    transition: {
      repeat: Infinity,
      duration: 110,
    },
  },
}

export const MaintenanceView = () => {
  const { t } = useTranslation(['maintenance', 'common'])

  return (
    <>
      <MaintenanceContainer>
        <div>
          <Branding>
            <BackgroundContainer {...bitcashBgLogoAnimationProps}>
              <BitcashLogoBackgroundIcon />
            </BackgroundContainer>
            <LogoContainer>
              <BitcashLoginLogoIcon width={290} />
            </LogoContainer>
          </Branding>
          <Slogan>{t('maintenaceSlogan')}</Slogan>
          <Message>{t('maintenanceMessage')}</Message>
          <SupportChannel>
            {t('maintenanceAction')}{' '}
            <Link href={discordUrl} target="_blank" rel="noopener noreferrer">
              {t('linkToDiscord')}
            </Link>
          </SupportChannel>
        </div>
      </MaintenanceContainer>
    </>
  )
}
