import React from 'react'

const SelfieInvalidIcon = (props: any) => (
  <svg viewBox="0 0 24 24" width="24" height="24" {...props}>
    <rect width="24" height="24" rx="12" fill="#DA291C" />
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="white"
    />
  </svg>
)

export default SelfieInvalidIcon
