import { StoreSlice } from 'app-engine/store'
import { plus, times } from 'number-precision'
// TODO: Reemplace with @greymass/eosio
import { asset } from 'eos-common'
import { Asset } from '@greymass/eosio'
import { eosCoreApi } from 'app-engine/library/eosio'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'
import { CoinGeckoPrices } from 'app-engine/types'
import { UserPosition } from 'app-view/hooks/use-user-positions'

export type WalletSlice = {
  wallet_balances: UserPosition[]
  wallet_total: number
  fetchWalletTotal: (coin_prices: CoinGeckoPrices) => Promise<void>
  reset: () => void
}
//
const default_wallet_state = {
  wallet_balances: [
    {
      symbol_code: 'EOS',
      balance: asset('0.0000 EOS'),
      usd_value: 0.0,
    },
    {
      symbol_code: 'USDT',
      balance: asset('0.0000 USDT'),
      usd_value: 0.0,
    },
  ],
  wallet_total: 0,
}

export const createWalletSlice: StoreSlice<WalletSlice> = (set, get) => ({
  ...default_wallet_state,
  reset: () => {
    set(default_wallet_state)
  },
  fetchWalletTotal: async (coin_prices) => {
    const { account } = get()
    if (!coin_prices || Object.keys(coin_prices).length === 0) return
    try {
      // Tokens to show on wallet
      const eos_token_data = findTokenBySymbolCode('EOS')
      const usdt_token_data = findTokenBySymbolCode('USDT')

      // We mutate the received wallet balances
      const mutate_new_balance = (b: string) => {
        const symbol_code = b.split(' ')[1]
        const asset_amount = parseFloat(b.split(' ')[0])
        const usdt_price = asset(`${coin_prices.USDT.usd} USD`)
        const eos_price = asset(`${coin_prices.EOS.usd} USD`)
        const coin_price = symbol_code === 'EOS' ? eos_price : usdt_price

        return {
          symbol_code,
          balance: asset(b),
          // We calculate
          usd_value: times(asset_amount, parseFloat(coin_price.toString().split(' ')[0])),
        }
      }
      // We set the balance of the received wallet balances
      const set_balance = (balances_result: Asset[]) => {
        if (balances_result.length === 0) return

        const new_balances = balances_result.map((b) => Asset.from(b).toString())
        const new_balance: UserPosition = new_balances.map(mutate_new_balance)[0]

        const wallet_balances = set_new_balance(get().wallet_balances, new_balance)
        const wallet_total = wallet_balances.reduce((prev, next) => ({
          symbol_code: 'USD',
          balance: asset(
            `${plus(prev.usd_value as number, next.usd_value as number).toString()} USD`,
          ),
          usd_value: plus(prev.usd_value as number, next.usd_value as number),
        })).usd_value as number
        // console.log('[LOG]', '[wallet_balances]', '[wallet_total]', { wallet_balances, wallet_total })
        set({ wallet_balances, wallet_total })
      }
      // We then finally update the wallet state to show latest wallet balances...
      const set_new_balance = (prev_state: UserPosition[], new_balance: UserPosition) =>
        prev_state.map((b) => (b.symbol_code === new_balance.symbol_code ? new_balance : b))

      await eosCoreApi
        .get_currency_balance(eos_token_data?.token_contract!, account)
        .then(set_balance)
        .finally(async () => {
          await eosCoreApi
            .get_currency_balance(usdt_token_data?.token_contract!, account, 'USDT')
            .then(set_balance)
        })
    } catch (error) {
      console.log('[ERROR getWalletTotal] ', error)
    }
  },
})
