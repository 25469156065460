import { useStore } from 'app-engine/store'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { parseUsers } from 'app-view/hooks/use-realtime/realtime.utils'
import { useMemo } from 'react'

export const useLoggedUser = () => {
  const account = useStore.useAccount()
  const users = bitcashRoom.useColyseusState((state) => state.users)
  const countryDataLoggedUser = useMemo(() => {
    if (!account || !users) return null
    const user = parseUsers(users).find((u) => u.account === account)
    return user
  }, [users, account])
  return countryDataLoggedUser
}
