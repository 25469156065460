import React from 'react'

const USDTETHIcon = () => (
  <svg viewBox="0 0 62 58" fill="none">
    <g clipPath="url(#clip0_6_13)">
      <path
        d="M30.495 0.000995068C17.394 -0.0870049 3.31698 4.22099 0.754978 18.664C-0.455023 25.491 -0.0260225 32.107 0.754978 38.957C2.35898 52.982 16.359 58.335 29 57.984C41.552 57.636 55.6 53.352 57.244 38.956C58.026 32.107 58.454 25.491 57.244 18.664C55 6.00699 42.416 0.0819951 30.494 -4.93246e-06L30.495 0.000995068Z"
        fill="#26A17B"
      />
      <path
        d="M31.96 29.779V29.775C31.79 29.787 30.911 29.838 28.957 29.838C27.394 29.838 26.297 29.795 25.91 29.775V29.779C19.9 29.514 15.416 28.469 15.416 27.215C15.416 25.965 19.904 24.915 25.91 24.651V28.738C26.302 28.766 27.43 28.833 28.985 28.833C30.852 28.833 31.786 28.754 31.96 28.738V24.655C37.959 24.924 42.43 25.968 42.43 27.219C42.43 28.469 37.955 29.514 31.96 29.783V29.779ZM31.96 24.231V20.575H40.329V15H17.545V20.575H25.914V24.231C19.112 24.544 14 25.89 14 27.504C14 29.118 19.116 30.464 25.914 30.78V42.5H31.964V30.78C38.754 30.467 43.858 29.122 43.858 27.508C43.858 25.898 38.754 24.548 31.964 24.235"
        fill="white"
      />
      <path
        d="M61.75 45C61.75 52.0416 56.0416 57.75 49 57.75C41.9584 57.75 36.25 52.0416 36.25 45C36.25 37.9584 41.9584 32.25 49 32.25C56.0416 32.25 61.75 37.9584 61.75 45Z"
        fill="white"
        stroke="#343434"
        strokeWidth="0.5"
      />
      <path
        d="M49.1096 48.9255L49.2421 49.0683L55.3109 45.1979L49.2421 34.3333L49.1096 34.8194V48.9255Z"
        fill="#343434"
      />
      <path d="M49.2421 49.0683V34.3333L43.1733 45.1978L49.2421 49.0683Z" fill="#8C8C8C" />
      <path
        d="M49.1674 55.4313L49.2421 55.6667L55.3145 46.4396L49.2422 50.3081L49.1675 50.4064L49.1674 55.4313Z"
        fill="#3C3C3B"
      />
      <path d="M43.1733 46.4395L49.2421 55.6666V50.308L43.1733 46.4395Z" fill="#8C8C8C" />
      <path d="M49.2421 42.2217V49.0683L55.3107 45.1979L49.2421 42.2217Z" fill="#141414" />
      <path d="M49.242 42.2217L43.1733 45.1978L49.242 49.0682V42.2217Z" fill="#393939" />
    </g>
    <defs>
      <clipPath id="clip0_6_13">
        <rect width="62" height="58" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default USDTETHIcon
