import createDinero from 'dinero.js'

export const createUsd = (amount: number): createDinero.Dinero =>
  createDinero({ amount, currency: 'USD', precision: 2 })
export const createBitcash = (amount: number): createDinero.Dinero =>
  // @ts-ignore
  createDinero({ amount, currency: 'BIT', precision: 8 })
export const createEos = (amount: number): createDinero.Dinero =>
  // @ts-ignore
  createDinero({ amount, currency: 'EOS', precision: 4 })
export const numberWithCommas = (x: number | string) => {
  const parts = typeof x === 'number' ? x.toString().split('.') : x.split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}
export const stablecoinMask = (amount: number, wallet?: boolean, symbol = '$'): string => {
  let strAmount

  if (amount < 0) {
    strAmount = wallet
      ? `- ${symbol}${amount.toFixed(2)}`
      : `- ${symbol}${numberWithCommas(amount.toFixed(2).replace('-', ''))}`
  } else {
    strAmount = wallet
      ? `${symbol}${amount.toFixed(2)}`
      : `${symbol}${numberWithCommas(amount.toFixed(2).replace('-', ''))}`
  }
  return strAmount
}
