import { useSocket } from 'SocketProvider'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { useProfile } from 'app-view/hooks/use-profile.hook'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useRealTimePublicUnmatchedP2POffers } from 'app-view/hooks/use-realtime/use-real-time-public-unmatched-p2p-offers'
import { useRealTimeUncompletedMutualTrustP2POffers } from 'app-view/hooks/use-realtime/use-real-time-uncompleted-mutual-trust-p2p-offers'
import { useRealTimeUncompletedOwnP2POffers } from 'app-view/hooks/use-realtime/use-real-time-uncompleted-own-p2p-offers'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import { asset } from 'eos-common'
import uniq from 'lodash.uniq'
import { useDeepCompareMemo } from 'use-deep-compare'

export const usePublicP2POffersFilter = () => {
  const userPositionsHook = useUserPositions()
  const userPositionsList = userPositionsHook.user_positions_list
  const userPositions = userPositionsHook.user_positions
  const [{ preferences }, {}] = useProfile()
  const p2pEveryoneOffers = useRealTimePublicUnmatchedP2POffers()
  const p2pTrustOffers = useRealTimeUncompletedMutualTrustP2POffers()
  const { isApplied, prices, currencies, paymentMethods, isMultipleCurrency } = useStore()
  const coins = bitcashRoom.useColyseusState((state) => state.stableCoins)
  const p2pOffers = useRealTimeUncompletedOwnP2POffers()
  const { onlineUsers } = useSocket()
  const coinOpt = coins
    ? coins.filter((opt) => userPositionsList.some((pos) => pos.symbol_code === opt))
    : []
  const availableCoins = uniq([...coinOpt, preferences.currency])
  const { publicMatchedP2P, publicUnmatchedP2P, publicEveryoneUnmatchedP2P, publicP2POffersCount } =
    useDeepCompareMemo(() => {
      const p2pTrustOfferIds = p2pTrustOffers.map((offer) => offer.id)
      const publicOffers = p2pOffers.filter((offer) => !p2pTrustOfferIds.includes(offer.id))
      const publicMatchedP2P = publicOffers.filter((offer) => offer.matched)
      const publicUnmatchedP2P = publicOffers.filter((offer) => !offer.matched)
      const tempPublicEveryoneUnmatchedP2P = p2pEveryoneOffers
        .map((offer) => {
          const isOfferDisabled = () => {
            try {
              const takeOfferAsBuyer = offer.type === 'sell'
              // Filter only the offers that you can take as the seller
              // because you need that amount to be available on your bank
              // while taking a sell offer as a buyer that does not matter, it should show anyway.
              if (takeOfferAsBuyer) return false
              const coin = offer.amount.split(' ')[1]
              const hasBalance = availableCoins.includes(coin)
              if (!hasBalance) return true
              return userPositions.get(coin)?.balance.isLessThan(asset(offer.amount))
            } catch (error) {
              console.error('isOfferDisabled::ERROR', error)
              return false
            }
          }
          let shouldReturn = true
          if (isApplied) {
            if (
              currencies.length &&
              currencies.filter((opt) => opt === offer.amount.split(' ')[1]).length === 0
            ) {
              shouldReturn = false
            } else if (
              paymentMethods.length &&
              paymentMethods.filter((opt) => opt === offer.method).length === 0
            ) {
              shouldReturn = false
            } else if (
              !isMultipleCurrency &&
              prices.length &&
              prices.filter(
                (opt) => Number(opt.split(' ')[0]) === Number(offer.amount.split(' ')[0]),
              ).length === 0
            ) {
              shouldReturn = false
            }
          }
          if (!shouldReturn) {
            return null
          }
          return {
            disabled: isOfferDisabled(),
            ...offer,
          }
        })
        .filter(Boolean) as P2POffer[]
      const publicEveryoneUnmatchedP2P = tempPublicEveryoneUnmatchedP2P
        .sort((a, b) => {
          const currencyA = a.amount.split(' ')[1]
          const currencyB = b.amount.split(' ')[1]
          const dateA = Date.parse(a.created_at)
          const dateB = Date.parse(b.created_at)

          // If both currencies are the preferred currency, sort by value.
          if (currencyA === preferences.currency && currencyB === preferences.currency) {
            return dateB - dateA
          }

          // If one of the currencies is the preferred currency, prioritize it.
          if (currencyA === preferences.currency) return -1
          if (currencyB === preferences.currency) return 1

          // If currencies are the same and none of them are preferred, sort by value.
          if (currencyA === currencyB) {
            return dateB - dateA
          }

          // If currencies are different and none of them are preferred, sort by currency.
          return currencyA.localeCompare(currencyB)
        })
        // After sorting by currency, sort by online status to keep previous order.
        .sort((a, b) => {
          if (!onlineUsers[a.initiator] && !onlineUsers[b.initiator]) return -1

          const isOnlineA = onlineUsers[a.initiator] || 0
          const isOnlineB = onlineUsers[b.initiator] || 0

          return isOnlineB - isOnlineA
        })

      return {
        publicMatchedP2P,
        publicP2POffersCount: publicOffers.length,
        publicUnmatchedP2P,
        publicEveryoneUnmatchedP2P,
      }
    }, [
      p2pTrustOffers,
      p2pOffers,
      p2pEveryoneOffers,
      isApplied,
      availableCoins,
      userPositions,
      currencies,
      paymentMethods,
      isMultipleCurrency,
      prices,
      preferences.currency,
      onlineUsers,
    ])
  return { publicMatchedP2P, publicUnmatchedP2P, publicEveryoneUnmatchedP2P, publicP2POffersCount }
}
