import theme from 'app-config/theme'
import { SwapOrder } from 'app-engine/store/swap-slice'
import { ChatProvider } from 'app-view/components/Chat/hooks/useChat'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { BoldText, Card, RowContainer, RowTextContainer, Text } from 'app-view/components/Styled'
import { SwapIcon } from 'app-view/components/SwapIcon'
import styled from 'app-view/styles/styled'
import { fixedAmountDecimals } from 'pages/SwapView/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalHistory } from '../Modal'

const getGemHistoryColor = (status: string) => {
  switch (status) {
    case 'open': {
      return '#EEEFF0'
    }
    case 'cancelled': {
      return 'rgba(250, 168, 168, 0.15);'
    }
    default: {
      return ''
    }
  }
}

const CoinOrderContainer = styled(Card)<{ orderStatus: string }>`
  background-color: ${(props: any) => getGemHistoryColor(props.orderStatus)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  cursor: pointer;
  padding: 0.83rem 1rem;
  height: 80px;
`

const { colors } = theme
export const SwapOrderItem: React.FC<{ order: SwapOrder }> = ({ order }) => {
  const { t } = useTranslation('global')
  const [, globalModalActions] = useGlobalModal()
  const isSell = order.order_type === 'sell'
  const openModal = () => {
    console.log('opening offer', order)
    globalModalActions.open({
      content: () => (
        // @ts-ignore
        <ChatProvider>
          <ModalHistory order={order} />
        </ChatProvider>
      ),
      showClose: true,
      autoHide: false,
    })
  }
  return (
    <CoinOrderContainer onClick={openModal} orderStatus={order.order_status || ''}>
      <RowContainer>
        <SwapIcon coin={order.asset} />
        <RowTextContainer>
          <Text color={isSell ? colors?.red : colors?.darkGray} style={{ userSelect: 'text' }}>
            {isSell ? '-' : ''}
            {fixedAmountDecimals(order.asset_amount, order.asset, true)}
          </Text>
          <BoldText color={colors?.darkGray}>{order.asset}</BoldText>
        </RowTextContainer>
      </RowContainer>
      <RowContainer>
        <RowTextContainer>
          <BoldText dollars color={!isSell ? colors?.red : colors?.black}>
            {!isSell ? '-' : ''}${order.bitcash_amount}
          </BoldText>
          <Text dollars color={colors?.black} onClick={openModal}>
            {order.order_status !== 'filled' && t(order.order_status || '')} @{' '}
            <span>${order.price}</span>
          </Text>
        </RowTextContainer>
      </RowContainer>
    </CoinOrderContainer>
  )
}
