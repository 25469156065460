import { useStore } from 'app-engine/store'
import { HistoryTabValueType } from 'app-engine/store/history-slice'
import { PageContainer } from 'app-view/components/Styled'
import { Tabs } from 'app-view/components/Tabs'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Bank } from './components/Bank'
import { Swap } from './components/Coin'
import { ExchangeHistory } from './components/Exchange'
import { P2P } from './components/P2P'
import { Trade } from './components/Trade'

export type HistoryContentProps = {
  content_id: string
}

const tabs = [
  {
    label: 'history:bank',
    value: 'bank',
  },
  {
    label: 'history:forex',
    value: 'forex',
  },
  {
    label: 'history:p2p',
    value: 'p2p',
  },
  {
    label: 'history:swap',
    value: 'swap',
  },
]

export const HistoryView: React.FC = () => {
  const { t } = useTranslation(['history'])
  const { historyTab: historyTabProps, setHistoryTab } = useStore()
  const location = useLocation()
  const historyTab = useMemo(
    () => ({
      ...historyTabProps,
      label: t(historyTabProps.label),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [historyTabProps.label],
  )

  const getSearchParameters = () => {
    const history_search = new URLSearchParams(location.search).get('tab') ?? ''
    const content_id = new URLSearchParams(location.search).get('content_id') ?? ''

    setHistoryTab({
      label: t(`history:${history_search || 'bank'}`),
      value: (history_search || 'bank') as HistoryTabValueType,
      content_id,
    })
  }

  useEffect(() => {
    if (!location.search) return

    getSearchParameters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  // NOTE: If user exit History View, we return to default value...
  useEffect(() => {
    return () => {
      setHistoryTab({
        label: t('history:bank'),
        value: 'bank' as HistoryTabValueType,
        content_id: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const setCurrentTab = (tab: { label: string; value: HistoryTabValueType }) => {
    setHistoryTab({
      label: t(`history:${tab.label}`),
      value: tab.value,
      content_id: '',
    })
  }

  return (
    <PageContainer>
      <Tabs
        tabs={tabs.map((tab) => ({ ...tab, label: t(tab.label) }))}
        currentTab={historyTab.value}
        setCurrentTab={setCurrentTab}
      />
      {historyTab.value === 'bank' && <Bank />}
      {historyTab.value === 'forex' && <ExchangeHistory />}
      {historyTab.value === 'p2p' && <P2P content_id={historyTab.content_id} />}
      {historyTab.value === 'swap' && <Swap content_id={historyTab.content_id} />}
      {historyTab.value === 'trade' && <Trade />}
    </PageContainer>
  )
}
