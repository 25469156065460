import React, { useCallback, useEffect, useState } from 'react'
import { motion, MotionProps } from 'framer-motion/dist/framer-motion'

type IndicatorIconType = 'pulse' | 'pulse-1' | 'swipe' | 'static-fill' | 'static-empty' | null
interface IndicatorIconState {
  type: IndicatorIconType
  wrapper: MotionProps
  circle: MotionProps
}
interface IndicatorIconProps {
  type: IndicatorIconType
}

const defaultState = {
  type: null,
  wrapper: {},
  circle: {},
}

const staticFillIcon = (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
    <circle cx="3.5" cy="3.5" r="2.5" fill="#29E0AD" stroke="#29E0AD" />
  </svg>
)

const staticEmptyIcon = (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none">
    <circle cx="3.5" cy="3.5" r="2.5" fill="white" stroke="#C0C0C0" />
  </svg>
)

const IndicatorIcon: React.FC<IndicatorIconProps> = ({ type }) => {
  const [animationOpt, setAnimationOpt] = useState<IndicatorIconState>(defaultState)

  const animationControl = useCallback(() => {
    switch (type) {
      case 'pulse': {
        return setAnimationOpt({
          type,
          wrapper: {
            animate: {
              scale: [1, 1.1, 0.9],
            },
            transition: {
              duration: 1,
              repeatType: 'reverse',
              repeatDelay: 1,
              repeat: Infinity,
            },
          },
          circle: {
            animate: {
              scale: [1, 1.1, 0.5],
            },
            transition: {
              duration: 1,
              repeatType: 'reverse',
              repeatDelay: 0.5,
              repeat: Infinity,
            },
          },
        })
      }
      case 'pulse-1': {
        return setAnimationOpt({
          type,
          wrapper: {
            animate: {
              scale: [1, 1.1, 0.9],
            },
            transition: {
              duration: 1,
              repeatType: 'reverse',
              repeatDelay: 1,
              repeat: 1,
            },
          },
          circle: {
            animate: {
              scale: [1, 1.1, 0.5],
            },
            transition: {
              duration: 1,
              repeatType: 'reverse',
              repeatDelay: 0.5,
              repeat: 1,
            },
          },
        })
      }
      case 'swipe': {
        return setAnimationOpt({
          type,
          wrapper: {
            animate: {
              y: [0, -10, -5, -10, 0, 10, 5, 10, 0, 0],
            },
            transition: {
              duration: 3,
              repeatType: 'reverse',
              repeatDelay: 2,
              repeat: Infinity,
            },
          },
          circle: {
            animate: {
              y: [0, -10, -5, -10, 0, 10, 5, 10, 0, 0],
            },
            transition: {
              duration: 2.9,
              repeatType: 'reverse',
              repeatDelay: 2.1,
              repeat: Infinity,
            },
          },
        })
      }
      default:
        return setAnimationOpt(defaultState)
    }
  }, [setAnimationOpt, type])

  useEffect(() => {
    if (type !== animationOpt.type) {
      animationControl()
    }
  }, [animationOpt.wrapper, animationOpt.circle, animationControl, animationOpt.type, type])

  const animatedIcon = (
    <svg width="16px" height="16px" viewBox="0 0 16 16">
      <g id="click-here" fill="#29E0AD" fillRule="nonzero">
        <motion.circle id="inner-dot" cx="8" cy="8" r="4" {...animationOpt.wrapper} />
        <motion.circle
          id="outter-dot"
          opacity="0.26"
          cx="8"
          cy="8"
          r="8"
          {...animationOpt.circle}
        />
      </g>
    </svg>
  )

  let icon = animatedIcon

  if (type === 'static-fill') {
    icon = staticFillIcon
  } else if (type === 'static-empty') {
    icon = staticEmptyIcon
  }

  return icon
}

export default IndicatorIcon
