import React from 'react'

const GrinIcon = (props: any) => (
  <svg viewBox="0 0 56 56" fill="none" {...props}>
    <path
      d="M54.2966 18.3766C52.2067 6.59227 40.4908 1.07678 29.3918 1.00123C17.1944 0.919015 4.08809 4.92948 1.70362 18.3766C0.576303 24.7327 0.975631 30.893 1.70362 37.2699C3.19535 50.3284 16.2301 55.3118 27.9997 54.985C39.6862 54.6607 52.7657 50.6724 54.2966 37.2695C55.0245 30.893 55.4234 24.7327 54.2966 18.3766Z"
      fill="white"
      stroke="url(#paint0_linear_8_18)"
    />
    <path
      d="M28 45C37.3888 45 45 37.3888 45 28C45 18.6112 37.3888 11 28 11C18.6112 11 11 18.6112 11 28C11 37.3888 18.6112 45 28 45Z"
      fill="black"
    />
    <path
      d="M27.9644 43.1843C36.3504 43.1843 43.1487 36.3861 43.1487 28C43.1487 19.6139 36.3504 12.8157 27.9644 12.8157C19.5783 12.8157 12.7801 19.6139 12.7801 28C12.7801 36.3861 19.5783 43.1843 27.9644 43.1843Z"
      fill="#FEF102"
    />
    <path
      d="M35.156 22.3037C34.8 21.4848 34.4618 19.8827 33.5361 19.5267C32.3434 19.0639 31.5958 22.0722 31.4178 22.8377H31.2398C30.9372 21.5382 30.5277 19.6869 29.1037 19.2775C28.445 22.0544 29.7979 25.0628 31.0618 27.466C32.4146 26.8251 33.0733 24.7958 33.1979 23.3717H33.3759L34.8 27.644C35.9393 27.3236 36.3487 26.1843 36.7581 25.1518C37.6482 22.9445 38.3958 20.2209 37.8262 17.8534C36.1173 18.334 35.601 20.7906 35.156 22.3037ZM18.067 28C19.5979 27.2702 20.3277 24.956 20.5592 23.3717H20.7372C20.9152 24.1372 21.3958 26.1131 22.3571 26.2377C23.5675 26.3979 24.2084 23.6921 24.2974 22.8377H24.4754C24.867 24.1728 25.2764 25.9173 26.6115 26.5759C27.1099 24.4932 26.5225 22.2859 25.7571 20.3455C25.5612 19.8471 25.1696 18.4942 24.4754 18.4942C23.3539 18.4942 22.7131 21.467 22.5173 22.3037H22.3393L20.9152 18.0314C18.4052 19.2241 17.0523 25.5969 18.067 28ZM15.3969 30.1361C17.2482 38.2534 25.8283 42.8461 33.5539 39.4105C36.3665 38.1644 38.5916 35.9393 39.8733 33.1623C40.2115 32.4503 40.8523 31.133 40.4785 30.1895C40.1047 29.2461 36.4199 30.7948 35.512 31.1864C35.3874 31.2398 35.2984 31.3644 35.2628 31.5068C35.2094 31.8984 35.4764 32.2722 35.8681 32.3257C35.8859 32.3257 35.9037 32.3257 35.9215 32.3257L37.4701 32.4503C34.6754 37.8618 27.1277 40.2471 21.8052 35.9215C20.6126 34.9602 19.5445 33.8209 18.8325 32.4503C18.4942 31.7916 18.1738 30.955 17.5864 30.4921C17.0167 30.0649 16.1623 29.9225 15.3969 30.1361Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_8_18"
        x1="1"
        y1="1"
        x2="78"
        y2="55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FEF102" />
        <stop offset="0.59375" stopColor="#E2D702" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
)

export default GrinIcon
