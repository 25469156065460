import { Cancel } from '../common/Cancel'
import { CancelComplete } from '../common/CancelComplete'
import { NoMatch } from '../common/NoMatch'
import { BuyAmount } from './BuyAmount'
import { BuyComplete } from './BuyComplete'
import { BuyConfirmation } from './BuyConfirmation'
import { BuyMatchedOffer } from './BuyMatchedOffer'
import { BuyMethod } from './BuyMethod'
import { BuyPayment } from './BuyPayment'
import { BuyPaymentConfirmed } from './BuyPaymentConfirmed'
import { BuyMatchedAltOffer } from './BuyMatchedAltOffer'
import { BuyMatchedConfirmOffer } from './BuyMatchedConfirmOffer'
import { BuyCancelOffer } from './BuyCancelOffer'
import { BuyCancelledOffer } from './BuyCancelledOffer'
import { WizardStep } from 'app-view/components/Wizard'
import { BuyStableCoin } from 'pages/P2PView/steps/buy-steps/BuyStableCoin'
import { Admin } from 'pages/P2PView/steps/common/Admin'
import { BuyMethodDetails } from 'pages/P2PView/steps/buy-steps/BuyMethodDetails'
import { DuplicateOffer } from '../common/DuplicateOffer'

export const BUY_STEPS: WizardStep[] = [
  {
    name: 'stable_coin',
    component: BuyStableCoin,
  },
  {
    name: 'amount',
    component: BuyAmount,
  },
  {
    name: 'method',
    component: BuyMethod,
  },
  // {
  //   name: 'method-bank',
  //   component: BuyMethod,
  // },
  {
    name: 'method-details',
    component: BuyMethodDetails,
  },
  {
    name: 'confirm',
    component: BuyConfirmation,
  },
  {
    name: 'no-match',
    component: NoMatch,
  },
  {
    name: 'match',
    component: BuyMatchedOffer,
  },
  {
    name: 'payment',
    component: BuyPayment,
  },
  {
    name: 'payment-confirmed',
    component: BuyPaymentConfirmed,
  },
  {
    name: 'complete',
    component: BuyComplete,
  },
  {
    name: 'match-2',
    component: BuyMatchedAltOffer,
  },
  {
    name: 'cancel-alt',
    component: BuyCancelOffer,
  },
  {
    name: 'cancel',
    component: Cancel,
  },
  {
    name: 'cancel-complete',
    component: CancelComplete,
  },
  {
    name: 'match-confirmed',
    component: BuyMatchedConfirmOffer,
  },
  {
    name: 'cancelled',
    component: BuyCancelledOffer,
  },
  {
    name: 'admin-cancel',
    component: Admin,
  },
  {
    name: 'duplicate',
    component: DuplicateOffer,
  },
]
