import { useStore } from 'app-engine/store'
import { useCallback, useMemo, useRef } from 'react'

// TODO: Reemplace with @greymass/eosio
import { asset, Asset, symbol } from 'eos-common'
import { useRealTimeUserPositions } from './use-realtime/use-realtime-user-positions'
import { useTokenPrices } from './use-token-prices'
import { useProfile } from './use-profile.hook'

export type UserPosition = {
  symbol_code: string
  balance: Asset
  usd_value: Asset | number
}

// TOTAL is a placeholder for TOTAL position (usd value)
const default_tokens = [...new Set(['4,EOS', '4,USDT', '2,BITUSD', '0,TOTAL', '0,STABLE'])].map(
  (tkn) => symbol(tkn),
)
const defaultUserPositions = default_tokens
  .map((tokenSymbol) => {
    const symbol_code = tokenSymbol.code().toString()
    return {
      symbol_code,
      balance: asset(
        0,
        symbol_code !== 'TOTAL' && symbol_code !== 'STABLE' ? tokenSymbol : symbol('USD', 2),
      ),
      usd_value: asset('0.00 USD'),
    }
  })
  .reduce((acc, curr) => {
    return acc.set(curr.symbol_code, curr)
  }, new Map<string, UserPosition>())

const getUserPositionList = (user_positions: Map<string, UserPosition>) => {
  return Array.from(user_positions?.values()) || []
}

const defaultUserPositionState = {
  user_positions: defaultUserPositions,
  user_positions_list: getUserPositionList(defaultUserPositions),
  userPositionsReady: false,
}

const useUserPositions = () => {
  const userPositionData = useRealTimeUserPositions()
  const tokenPricesHook = useTokenPrices()
  const [{ preferences }, {}] = useProfile()
  const bufferPositions = useRef<{
    user_positions: Map<string, UserPosition>
    user_positions_list: UserPosition[]
    userPositionsReady: boolean
  }>(defaultUserPositionState)

  const userPositions = useMemo(() => {
    if (!userPositionData || !tokenPricesHook.tokenPrices) {
      return bufferPositions.current
    }

    const user_positions_list: UserPosition[] = userPositionData.map((row) => {
      const balance = asset(row.dataBalanceQuantity)
      const symbol_code = balance.symbol.code().toString()
      const usd_value = tokenPricesHook.getUsdTokenValue(balance)

      return {
        symbol_code,
        balance,
        usd_value,
      }
    })

    // NOTE: calculate total usd value of total position every time positions array changes
    const new_total_usd_value = user_positions_list.reduce(
      (total, position) => total.plus(position.usd_value),
      asset('0.00 USD'),
    )
    // console.log('new_total_usd_value', new_total_usd_value.toString())
    // NOTE: calculate total usd value of total stable position every time positions array changes
    const new_stable_usd_value = user_positions_list
      .filter((position) => position.symbol_code.match(/BIT/))
      .reduce((total, position) => total.plus(position.usd_value), asset('0.00 USD'))

    // NOTE: when no positions found, then we set the default to BITUSD Amount to 0, since our Graph only respond to true value !== 0
    if (user_positions_list.length === 0) {
      user_positions_list.push({
        symbol_code: preferences?.currency,
        balance: asset(`0.00 ${preferences?.currency}`),
        usd_value: asset('0.00 BITUSD'),
      })
    }

    user_positions_list.push({
      symbol_code: 'TOTAL',
      balance: new_total_usd_value,
      usd_value: new_total_usd_value,
    })
    user_positions_list.push({
      symbol_code: 'STABLE',
      balance: new_stable_usd_value,
      usd_value: new_stable_usd_value,
    })
    const new_user_positions = new Map<string, UserPosition>()
    // update positions state
    const all_user_positions = [...user_positions_list]
    all_user_positions.forEach((position) => new_user_positions.set(position.symbol_code, position))
    // KEEP THIS FOR A WHILE PLEASE //
    const positions = []
    for (const [key, value] of new_user_positions.entries()) {
      const { symbol_code, balance, usd_value } = value
      const position = {
        key,
        symbol_code,
        balance: balance.toString(),
        usd_value: usd_value.toString(),
      }
      positions.push(position)
    }

    bufferPositions.current = {
      user_positions: new_user_positions,
      user_positions_list,
      userPositionsReady: true,
    }

    return {
      user_positions: new_user_positions,
      user_positions_list,
      userPositionsReady: true,
    }
  }, [userPositionData, preferences?.currency, tokenPricesHook]) // We update the user positions when the account or the delphiPricesHash changes

  const findUserPosition = useCallback(
    (tokenSymbolCode: string) => {
      const user_position = userPositions?.user_positions.get(tokenSymbolCode)
      if (!user_position) {
        // throw new Error(`position_not_found ${tokenSymbolCode}`)
        // console.log('[user_position][not_found]', tokenSymbolCode, user_position)
        return {
          symbol_code: tokenSymbolCode,
          balance: asset(`0.00 ${preferences?.currency}`),
          usd_value: asset('0.00 BITUSD'),
        }
      }
      return user_position
    },
    [userPositions?.user_positions, preferences?.currency],
  )

  return {
    ...userPositions,
    findUserPosition,
  }
}

export { useUserPositions }
