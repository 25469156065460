export default {
  fullscreen: '全屏',
  language: '语言',
  support: 'Discord 支持',
  about: '大约',
  api_status: '连接状态',
  logout: '退出',
  referralLinkLabel: '推荐链接',
  referralLinkSubLabel: '分享链接赚取bitcash代币',
  copiedLinkBtnLabel: '复制链接 ✓',
  copyLinkBtnLabel: '复制链接',
  profile: '个人资料选项',
  devices: '设备',
  no_devices: '您没有任何设备',
  delete_device: '您要删除此设备吗？',
  keys: 'Keys (密钥)',
  no_keys: '您没有任何密钥',
  delete_key: '您要删除此密钥吗？',
  delete: '删除',
  loading_devices_keys: '加载设备和密钥',
  enter_public_key: '请输入公钥',
  enter_valid_public_key: '请输入有效的公钥',
  public_key_modal_title: '输入公钥以将其添加为有效的签名方法到您的帐户',
  public_key: '公钥',
  add_key: '添加密钥',
  this_session: '此会话',
  key_recovery: '密钥恢复',
  add_partners: '添加合作伙伴',
  update_partner: '更新合作伙伴',
  generating_recovery_keys: '生成恢复 ID',
  id_type: 'ID 类型',
  country: '国家',
  not_found: '未找到bitcash帐户',
  partners_recommended: '建议最少3个合作伙伴',
  dao_recovery: 'DAO 恢复',
  info_primary:
    '恢复合作伙伴是您信任的人，他们可以在您丢失手机或设备并需要恢复帐户时识别和验证您。',
  info_secondary: '请输入每个恢复合作伙伴的bitcash帐户。',
  info_tertiary: 'DAO 恢复使bitcash组织能够作为您的恢复合作伙伴之一。',
  warning: '警告',
  warning_secondary: '如果您想删除/添加任何合作伙伴，您必须通过恢复过程。',
  recovery_partner: '恢复合作伙伴',
  agree: '我同意为我的密钥恢复过程添加这些合作伙伴',
  Submit: '提交',
  date_of_birth: '出生日期',
  register_ip: '注册 IP',
}
