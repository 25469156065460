import React, { useEffect } from 'react'
import { GreenButton } from 'app-view/components/buttons'
import { ModalButtonsContainer } from 'app-view/components/Modal'
import { useTranslation } from 'react-i18next'
import { useStore } from 'app-engine/store/index'
import { PasteAddress } from '../common/PasteAddress'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ModalText } from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'

export const UserAddress = ({ next }: WizardStepProps) => {
  const { t } = useTranslation(['swap'])
  const {
    setSwapAddress,
    submitSwap,
    account,
    coinSwap: { swap, loading },
  } = useStore()

  useEffect(() => {
    if (swap.asset) setSwapAddress(swap.asset === 'EOS' ? account : '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swap.asset, account])

  const confirmSell = async () => {
    const { success } = await submitSwap()
    if (success) next()
  }

  if (loading) return <Loader text={`${t('sell.loader')}...`} />

  return (
    <>
      <ModalText>
        {`${t('sell.userAddress.text1')} `}
        <span>${swap.bitcash_amount}</span>
        {`
        ${t('sell.userAddress.text2')}`}
      </ModalText>
      <br />
      <PasteAddress onPaste={setSwapAddress} />

      <ModalButtonsContainer>
        <GreenButton disabled={swap.wallet_address === ''} onClick={confirmSell}>
          {t('next')}
        </GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
