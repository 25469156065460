import { SellAmount } from './SellAmount'
import { SellMethod } from './SellMethod'
import { SellConfirmation } from './SellConfirmation'
import { SellMatchedOffer } from './SellMatchedOffer'
import { NoMatch } from '../common/NoMatch'
import { SellPayment } from './SellPayment'
import { SellComplete } from './SellComplete'
import { Cancel } from '../common/Cancel'
import { CancelComplete } from '../common/CancelComplete'
import { SellMatchedAltOffer } from './SellMatchedAltOffer'
import { SellCancelOffer } from './SellCancelOffer'
import { SellMatchedConfirmOffer } from './SellMatchedConfirmOffer'
import { SellCancelledOffer } from './SellCancelledOffer'
import { SellCancelDeny } from './SellCancelDeny'
import { SellStableCoin } from 'pages/P2PView/steps/sell-steps/SellStableCoin'
import { WizardStep } from 'app-view/components/Wizard'
import { Admin } from '../common/Admin'
import { SellMethodDetails } from 'pages/P2PView/steps/sell-steps/SellMethodDetails'
import { DuplicateOffer } from '../common/DuplicateOffer'

export const SELL_STEPS: WizardStep[] = [
  {
    name: 'stable_coin',
    component: SellStableCoin,
  },
  {
    name: 'amount',
    component: SellAmount,
  },
  {
    name: 'method',
    component: SellMethod,
  },
  // {
  //   name: 'method-bank',
  //   component: SellMethod,
  // },
  {
    name: 'method-details',
    component: SellMethodDetails,
  },
  {
    name: 'confirm',
    component: SellConfirmation,
  },
  {
    name: 'no-match',
    component: NoMatch,
  },
  {
    name: 'match',
    component: SellMatchedOffer,
  },
  {
    name: 'payment',
    component: SellPayment,
  },
  {
    name: 'complete',
    component: SellComplete,
  },
  {
    name: 'match-2',
    component: SellMatchedAltOffer,
  },
  {
    name: 'cancel-alt',
    component: SellCancelOffer,
  },
  {
    name: 'cancel',
    component: Cancel,
  },
  {
    name: 'cancel-complete',
    component: CancelComplete,
  },
  {
    name: 'match-confirmed',
    component: SellMatchedConfirmOffer,
  },
  {
    name: 'cancelled',
    component: SellCancelledOffer,
  },
  {
    name: 'cancel-deny',
    component: SellCancelDeny,
  },
  {
    name: 'admin-cancel',
    component: Admin,
  },
  {
    name: 'duplicate',
    component: DuplicateOffer,
  },
]
