import { createContextHook } from '@blockmatic/hooks-utils'
import { config } from 'app-config'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { ChatError } from 'app-engine/library/errors'
import { useStore } from 'app-engine/store'
import { NotificationTypeEnum } from 'app-engine/types/notifications'
import isEqual from 'lodash.isequal'
import { ChangeEvent, FormEvent, useEffect, useMemo } from 'react'
import { useSetState } from 'react-use'
import { useGlobalModal } from '../../GlobalModal/useGlobalModal'
import { ChatProps, IDType, UseChatAPI, UseChatState } from '../types'

const createChatObservable = (variables: Bitcash.MessagesSubscriptionVariables) => {
  try {
    const subscription = apolloClient.subscribe<
      Bitcash.MessagesSubscription,
      Bitcash.MessagesSubscriptionVariables
    >({
      query: Bitcash.MessagesDocument,
      variables,
      fetchPolicy: 'no-cache',
    })
    return subscription
  } catch (error) {
    console.error('createChatObservable - error to createte :', error)
    return null
  }
}

const initialState: UseChatState = {
  message: '',
  messages: [],
  id: {
    type: '',
    id: undefined,
  },
  from: '',
  to: '',
  loading: false,
  sending: false,
  error: null,
  isOpen: false,
  new_message: 0,
  readOnly: false,
}

const useChatFn = (): [UseChatState, UseChatAPI] => {
  const [state, setState] = useSetState<UseChatState>(initialState)
  const { message, messages, id, from, to, isOpen } = state
  const { authErrorFallback } = useStore()
  const [, globalModalActions] = useGlobalModal()

  const getMessagesForP2P = (chatId: string | undefined) => {
    return {
      p2p_id: chatId,
    }
  }

  const getMessagesForSwap = (chatId: string | undefined) => {
    return {
      swap_id: chatId,
    }
  }

  const getMessagesForSupport = (chatId: string | undefined) => {
    return {
      support_id: chatId,
    }
  }

  const getMessagesForTrustNetwork = () => {
    return {}
  }

  const getMessages = (chat: IDType) => {
    switch (chat.type) {
      case 'p2p':
        return getMessagesForP2P(chat.id)
      case 'support':
        return getMessagesForSupport(chat.id)
      case 'swap':
        return getMessagesForSwap(chat.id)
      case 'p2p-trust-network':
        return getMessagesForTrustNetwork()
      default:
        return {}
    }
  }

  const chatVariables = useMemo(() => {
    if (id.type === 'support') {
      return {
        where: {
          support_id: { _eq: id.id },
        },
        order_by: [{ timestamp: 'asc' }],
      } as Bitcash.MessagesSubscriptionVariables
    }
    if (id.type === 'p2p-trust-network') {
      return {
        where: {
          from: { _in: [from, to] },
          to: { _in: [from, to] },
          p2p_id: { _is_null: true },
          support_id: { _is_null: true },
          swap_id: { _is_null: true },
        },
        order_by: [{ timestamp: 'asc' }],
      } as Bitcash.MessagesSubscriptionVariables
    }
    if (id.type === 'swap') {
      return {
        where: {
          swap_id: { _eq: id.id },
        },
        order_by: [{ timestamp: 'asc' }],
      } as Bitcash.MessagesSubscriptionVariables
    }
    return {
      where: {
        p2p_id: {
          _eq: id.id,
        },
      },
      order_by: [{ timestamp: 'asc' }],
    } as Bitcash.MessagesSubscriptionVariables
  }, [from, id, to])

  const notifyNewMessage = (notify: number) => setState({ new_message: notify })
  const [bitcashSendMessageMutation] = Bitcash.useBitcashSendMessageMutation()
  const [bitcashInsertNotificationMutation] = Bitcash.useBitcashInsertNotificationMutation()

  useEffect(() => {
    if (!isOpen) return

    const chatObservable = createChatObservable(chatVariables)
    if (!chatObservable) {
      console.error('Failed to create chat observable')
      return
    }

    const chatSubscription = chatObservable.subscribe({
      next: ({ data, errors }) => {
        if (errors) {
          console.error('Error in chat subscription:', errors)
          return
        }
        if (data) {
          const msgs = data.messages
          if (msgs.length !== messages.length) {
            setState({ messages: msgs })
          }
        }
      },
      error: (error) => {
        console.error('Chat subscription error:', error)
      },
    })
    // eslint-disable-next-line consistent-return
    return () => {
      chatSubscription.unsubscribe()
    }
  }, [chatVariables, isOpen, messages.length, setState])

  const onTextChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { value },
    } = e

    setState({ message: value })
  }

  const setConversation = (from: string, to: string, id: IDType) => {
    if (!from || !to || !id) {
      console.warn("setConversation: 'from', 'to', or 'id' is undefined")
      return
    }
    !isEqual({ from: state.from, to: state.to, id: state.id }, { from, to, id }) &&
      setState({ from, to, id, messages: [] })
  }

  const sendMessage = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (message === '' || from === '' || to === '') return

    setState({ sending: true })

    try {
      const newMsg: Bitcash.BitcashSendMessageMutationVariables = {
        ...getMessages(id),
        message,
        from,
        to,
        timestamp: new Date(),
      }

      const { data, errors } = await bitcashSendMessageMutation({
        variables: {
          ...newMsg,
        },
        onError: (err) => authErrorFallback(err),
      })
      if (errors) throw new ChatError(errors[0].message)

      if (!errors) {
        const isAdminNotificationType =
          (config.bitcashAdmin === from && location.pathname.includes('admin')) ||
          id.type === 'support' ||
          id.type === 'swap'
            ? NotificationTypeEnum.COIN_MSG
            : NotificationTypeEnum.P2P_MSG
        const { data, errors } = await bitcashInsertNotificationMutation({
          variables: {
            object: {
              content_id: id.id,
              from,
              to,
              read: false,
              created_at: new Date(),
              type: isAdminNotificationType,
            },
          },
          onError: (err) => authErrorFallback(err),
        })
        console.log('notification data:', data)
        if (errors) throw new Error(errors[0].message)
      }

      if (data?.insert_messages?.returning[0]) {
        const newMessage: Bitcash.MessagesSubscription['messages'][number] =
          data?.insert_messages?.returning[0]
        setState({ messages: [...state.messages, newMessage], message: '' })
      }
    } catch (error) {
      if (error instanceof Error) {
        authErrorFallback(error)
        setState({ error: error.message })
      }
    } finally {
      setState({ sending: false })
    }
  }

  const noConsecutive = (msg: Bitcash.MessagesSubscription['messages'][number], i: number) =>
    (i > 0 && messages[i - 1]?.from !== msg.from) || i === 0

  const getTime = (timestamp: Date) => {
    const currentDate = new Date()
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const dateIndex = timestamp.getDay()
    const date = `${timestamp.getMonth() + 1}-${timestamp.getDate()}`
    const actualDate = `${currentDate.getMonth()}-${currentDate.getDate()}`
    const hr = timestamp.getHours() <= 9 ? `0${timestamp.getHours()}` : timestamp.getHours()
    const min = timestamp.getMinutes() <= 9 ? `0${timestamp.getMinutes()}` : timestamp.getMinutes()
    const time = `${timestamp.getHours() >= 0 && hr}:${min}`
    const digestedDate = `${
      !actualDate.match(date) ? `${days[dateIndex]} ${date} – ` : ''
    }${time}hr`

    return digestedDate
  }

  const onOpen = () => setState({ isOpen: true })
  const onOpenWithProps = (props: ChatProps) => setState({ isOpen: true, ...props })

  const onClose = () => {
    setState(initialState)
    globalModalActions.close()
  }

  const resetError = () => setState({ error: null })

  return [
    state,
    {
      onOpenWithProps,
      setConversation,
      getMessages,
      onTextChange,
      notifyNewMessage,
      sendMessage,
      noConsecutive,
      getTime,
      onClose,
      onOpen,
      resetError,
    },
  ]
}

export const [useChat, ChatProvider] = createContextHook(
  useChatFn,
  'You must wrap your application with <ChatProvider /> in order to useChat().',
)
