import {
  ModalButtonsContainer,
  ModalStepBlackButton,
  ModalStepGreenButton,
} from 'app-view/components/Modal'
import React from 'react'
import { Form } from 'pages/AccountView/components/TextElements'
import { WalletStepMainContentWrapper } from '../common/Styled'
import { InputWrapper, InputSection, Textarea, Label } from 'app-view/components/InputField'
import { useWallet } from 'pages/WalletView/hooks/useWallet'
import { WizardStepProps } from 'app-view/components/Wizard'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { Loader } from 'app-view/components/Spinner'
import { InputHandlerProps } from './SendToAccount'
import { useRealTimePairs } from 'app-view/hooks/use-realtime/use-realtime-pairs'

export const SendWithMemo = ({ next, prev }: WizardStepProps) => {
  const { t } = useTranslation(['wallet'])
  const pairs = useRealTimePairs()
  const [
    { action, loading, isReady, error },
    { submitAction, setError, onChangeSendHandler, setReady },
  ] = useWallet()
  const form = useForm<{ memo: string }>({
    defaultValues: {
      memo: action.memo,
    },
  })

  const onBlur = () => {
    window.scroll(0, 0)
  }

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!action.memo) return

    try {
      // TODO: If Memo Step is restored, pass this to memo step...
      // @ts-ignore TODO: fix types
      const amount = action.amount.amount.toString()
      const symbol = action.amount.symbol
      // @ts-ignore TODO: fix types
      const symbol_code = symbol.code().toString()
      // @ts-ignore TODO: fix types
      const sym_precision = amount.length - symbol.precision()
      const digested_amount = `${amount.substring(0, sym_precision)}.${amount.substring(
        sym_precision,
      )}`
      const trnx_quantity = `${digested_amount} ${symbol_code}`
      const pairKey = symbol_code + symbol_code
      const pairId = pairs?.[pairKey]?.id || ''
      const { success } = await submitAction({
        from: action.sender,
        to: action.receiver,
        quantity: trnx_quantity,
        //  pair_id:0 for EOS transfer, pair_id:1 for USDT
        memo: action.memo || `pair_id:${pairId}`,
      })

      if (success) next()
    } catch (err) {
      setError((err as Error).message)
      setReady(false)
    }
  }

  if (loading) return <Loader text={t('loading_action')} />

  const memo_input_props: InputHandlerProps = {
    // @ts-ignore TODO: fix types
    form,
    // @ts-ignore TODO: fix types
    field: 'memo',
    message: t('invalid_memo_format'),
  }

  return (
    <Form onSubmit={onSubmitHandler} wallet={!Boolean(error)}>
      <WalletStepMainContentWrapper>
        <Controller
          name="memo"
          control={form.control}
          rules={{
            required: t('required_memo'),
            pattern: { value: /(?=(^[\s\S]{1,}$)).*/g, message: t('memo_empty') },
            validate: () => (!isReady ? t('invalid_account_send') : true),
          }}
          render={({ field, fieldState }) => (
            <InputWrapper>
              <Label size="md" htmlFor="send-memo-input">
                {t('memo_label')}
              </Label>
              <InputSection radius="round" style={{ height: 200 }}>
                <Textarea
                  {...field}
                  placeholder={t('memo_placeholder')}
                  value={action.memo}
                  onBlur={onBlur}
                  onChange={(e) => onChangeSendHandler(e, memo_input_props)}
                  id="send-memo-input"
                  fullradius={1}
                  fontWeight="500"
                  maxLength={255}
                  cols={33}
                  paddingspace="wide"
                  error={Boolean(!form.formState.isValidating && fieldState.error)}
                  style={{ maxHeight: '100%' }}
                />
              </InputSection>
            </InputWrapper>
          )}
        />
      </WalletStepMainContentWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={prev}>
          {t('back_action')}
        </ModalStepBlackButton>
        <ModalStepGreenButton type="submit" disabled={!action.memo}>
          {t('send_action')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
