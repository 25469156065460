import React from 'react'

const BinanceCoinIcon = (props: any) => (
  <svg id="binance-coin-icon" viewBox="0 0 54 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3918 0.00123349C16.1944 -0.0809876 3.08809 3.92948 0.703618 17.3766C-0.423696 23.7327 -0.0243682 29.893 0.703618 36.2699C2.19535 49.3284 15.2301 54.3118 26.9997 53.985C38.6862 53.6607 51.7657 49.6724 53.2966 36.2695C54.0245 29.893 54.4234 23.7327 53.2966 17.3766C51.2067 5.59227 39.4908 0.0767767 28.3918 0.00123349Z"
      fill="#F3BA2F"
    />
    <rect width="36" height="36" transform="translate(9 9)" fill="#F3BA2F" />
    <path
      d="M27 45C36.9411 45 45 36.9411 45 27C45 17.0589 36.9411 9 27 9C17.0589 9 9 17.0589 9 27C9 36.9411 17.0589 45 27 45Z"
      fill="#F3BA2F"
    />
    <path
      d="M21.9508 24.9252L27 19.876L32.0518 24.9278L34.9898 21.9898L27 14L19.0128 21.9872L21.9508 24.9252ZM14 27L16.938 24.062L19.876 27L16.938 29.938L14 27ZM21.9508 29.0748L27 34.124L32.0518 29.0722L34.9898 32.0089L27 40L19.0128 32.0128L19.0089 32.0089L21.9508 29.0748ZM34.124 27L37.062 24.062L40 27L37.062 29.938L34.124 27ZM29.9796 26.9974H29.9822V27L27 29.9822L24.0217 27.0052L24.0165 27L24.0217 26.9961L24.543 26.4735L24.7965 26.22L27 24.0178L29.9809 26.9987L29.9796 26.9974Z"
      fill="white"
    />
  </svg>
)

export default BinanceCoinIcon
