import { capitalize } from 'app-engine/library/utils'

// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
export type ErrorWithMessage = {
  message: string
}

export function getErrorName(value: unknown): string | null {
  if (typeof value === 'object' && value !== null && 'name' in value) {
    const potentialError = value as { name: string }
    return potentialError.name
  } else {
    return null
  }
}

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

export function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.

    // NOTE: this seems strip out Error.name from DomExceptions
    // it worth to fix it, in the meantime I created getErrorName util
    return new Error(String(maybeError))
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message
}

export class SmartContractError extends Error {
  constructor(message: string) {
    super()
    this.name = 'SmartContractError'
    this.message = capitalize(message)
  }
}

export class ChatError extends Error {
  constructor(message: string) {
    super()
    this.name = 'ChatError'
    this.message = message
  }
}

export class AnchorError extends Error {
  constructor(message: string) {
    super()
    this.name = 'AnchorError'
    this.message = `'Error interacting with Anchor: ${message}`
  }
}

export class RenderError extends Error {
  constructor(message: string) {
    super()
    this.name = 'RenderError'
    this.message = message
  }
}

export class TokenPoketError extends Error {
  constructor(message: string) {
    super()
    this.name = 'TokenPoketError'
    this.message = message
  }
}

export class UnexpectedError extends Error {
  name = 'UnexpectedError'
  message = 'Unexpected error occurred. Please try again.'
}

export class DelphiPriceError extends Error {
  name = 'DelphiPriceError'
  message = 'Delphi price not found'
}

export class BackendError extends Error {
  name = 'BackendError'
  message = 'An error occurred communicating with the server. Please try again.'
}

export class WheelSelectError extends Error {
  constructor(message: string) {
    super()
    this.name = 'WheelSelectError'
    this.message = `Invalid action: ${message}`
  }
}

export class WebAuthError extends Error {
  constructor(message: string) {
    super()
    this.name = 'WebAuthNError'
    this.message = message
  }
}
