import { useStore } from 'app-engine/store'
import {
  ModalButtonsContainer,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { useWizard } from 'app-view/components/Wizard'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Cancel: React.FC<{ text: string }> = ({ text }) => {
  const { t } = useTranslation(['p2p'])
  const account = useStore.useAccount()
  const [, { goTo, close }] = useWizard()
  const [{ loading, offer }, { cancelOffer, autoCancelOffer }] = useP2POffer()

  const onCancel = async () => {
    if (offer.seller === account && offer.buyer_confirmed_payment) {
      return goTo('cancel-deny')
    }

    const { success } =
      !offer.sell_put_transaction && !offer.bitcashbank_id
        ? await cancelOffer()
        : await autoCancelOffer()

    if (success) {
      return goTo('cancel-complete')
    }

    return false
  }

  if (loading) return <Loader text={`${t('confirming_payment')}...`} />

  return (
    <>
      <ModalText
        dangerouslySetInnerHTML={{
          __html: `${text || ''}
        ${t('would_cancel_offer')}`,
        }}
      />
      <ModalButtonsContainer>
        <ModalStepBlackButton onClick={close}>{t('not_now')}</ModalStepBlackButton>
        <ModalStepGreenButton onClick={onCancel}>{t('Yes')}</ModalStepGreenButton>
      </ModalButtonsContainer>
    </>
  )
}
