import React from 'react'

const SolanaCoinIcon = (props: any) => (
  <svg id="solana-coin-icon" viewBox="0 0 54 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3918 0.00123349C16.1944 -0.0809876 3.08809 3.92948 0.703618 17.3766C-0.423696 23.7327 -0.0243682 29.893 0.703618 36.2699C2.19535 49.3284 15.2301 54.3118 26.9997 53.985C38.6862 53.6607 51.7657 49.6724 53.2966 36.2695C54.0245 29.893 54.4234 23.7327 53.2966 17.3766C51.2067 5.59227 39.4908 0.0767767 28.3918 0.00123349Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9625 33.8256C18.0557 33.7231 18.1693 33.6413 18.2959 33.5852C18.4224 33.5291 18.5595 33.5001 18.6979 33.5L41.3897 33.5186C41.4864 33.5188 41.5809 33.547 41.6617 33.5999C41.7426 33.6528 41.8065 33.7281 41.8453 33.8165C41.8843 33.9049 41.8967 34.0028 41.8811 34.0981C41.8655 34.1935 41.8226 34.2822 41.7574 34.3537L36.9252 39.6744C36.8319 39.7769 36.7183 39.8589 36.5915 39.9149C36.4648 39.971 36.3278 40 36.1891 40L13.4979 39.9814C13.4013 39.9812 13.3068 39.953 13.2259 39.9001C13.145 39.8472 13.0812 39.7719 13.0423 39.6835C13.0034 39.5951 12.9909 39.4972 13.0066 39.4019C13.0222 39.3065 13.0651 39.2178 13.1302 39.1463L17.9625 33.8256ZM41.7574 29.3963C41.8226 29.4678 41.8655 29.5565 41.8811 29.6519C41.8967 29.7472 41.8843 29.8451 41.8453 29.9335C41.8065 30.0219 41.7426 30.0972 41.6617 30.1501C41.5809 30.203 41.4864 30.2312 41.3897 30.2314L18.6985 30.25C18.56 30.25 18.4229 30.221 18.2962 30.1649C18.1694 30.1089 18.0557 30.0269 17.9625 29.9244L13.1302 24.6006C13.0651 24.5291 13.0222 24.4404 13.0066 24.345C12.9909 24.2497 13.0034 24.1518 13.0423 24.0634C13.0812 23.975 13.145 23.8997 13.2259 23.8468C13.3068 23.794 13.4013 23.7657 13.4979 23.7655L36.1897 23.7469C36.3283 23.747 36.4652 23.776 36.5919 23.8321C36.7185 23.8882 36.832 23.97 36.9252 24.0725L41.7574 29.3963ZM17.9625 14.3256C18.0557 14.2231 18.1693 14.1412 18.2959 14.0852C18.4224 14.0291 18.5595 14.0001 18.6979 14L41.3897 14.0186C41.4864 14.0188 41.5809 14.047 41.6617 14.0999C41.7426 14.1528 41.8065 14.228 41.8453 14.3164C41.8843 14.4049 41.8967 14.5027 41.8811 14.5981C41.8655 14.6935 41.8226 14.7822 41.7574 14.8537L36.9252 20.1744C36.8319 20.2769 36.7183 20.3589 36.5915 20.4149C36.4648 20.471 36.3278 20.5 36.1891 20.5L13.4979 20.4814C13.4013 20.4812 13.3068 20.453 13.2259 20.4001C13.145 20.3472 13.0812 20.2719 13.0423 20.1835C13.0034 20.0951 12.9909 19.9972 13.0066 19.9019C13.0222 19.8065 13.0651 19.7178 13.1302 19.6463L17.9625 14.3256Z"
      fill="url(#paint0_linear_4234:5644)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4234:5644"
        x1="13.8768"
        y1="40.567"
        x2="41.0109"
        y2="13.433"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9945FF" />
        <stop offset="0.2" stopColor="#7962E7" />
        <stop offset="1" stopColor="#00D18C" />
      </linearGradient>
    </defs>
  </svg>
)

export default SolanaCoinIcon
