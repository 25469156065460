export default {
  deposit: 'Depositar',
  withdrawal: 'Retirar',
  success: 'Trade exitosa!',
  send: 'Enviar',
  with_memo: 'Con memo?',
  loan: '{{ticker}} préstamo',
  leverage: 'Préstamo',
  tab_usd_title: 'Dólar',
  tab_crypto_title: 'Cripto',
  deposit_action: 'Depositar',
  deposit_success: '¡Depósito exitóso!',
  amount_deposit: 'Monto:',
  back_action: 'Regresar',
  next_action: 'Siguiente',
  amount_placeholder: 'Ingrese la cantidad',
  id_transacción: 'ID de transacción',
  deposit_limit: '¡Límite de depósito!',
  send_success: '¡El envío fue exitoso!',
  account_label: 'Enviar a:',
  currency_label: 'Moneda',
  amount_label: 'Monto:',
  send_label: 'Enviar',
  memo_label: 'Memo',
  bitcash_transfer: 'transferencia de bitcash',
  memo_placeholder: 'Ingrese un memo',
  loading_action: 'Processando transacción...',
  exchange: 'Intercambiar',
  exchange_send: 'Enviar',
  exchange_get: 'Recibir',
  exchange_rate_amount: 'Taza de Intercambio: {{exchange_rate}}',
  exchange_rate: 'Taza de Intercambio',
  exchange_rate_fee: 'Comisión:',
  not_rate_found: 'Not available',
  invalid_account_format: 'Formato de cuenta inválido',
  indicate_account_send: 'Indicar la cuenta a enviar',
  invalid_account_send: 'Cuenta inválida para enviar',
  indicate_amount_send: 'Indique la cantidad a enviar',
  invalid_amount: 'Cantidad inválida',
  invalid_amount_send: 'Cantidad inválida a enviar',
  required_memo: 'Indicar un memo',
  memo_empty: 'Memo esta vacío',
  limit_alert: 'Límite máx. de 24hr',
  withdraw_helper: 'El límite diario es de 5% al 10% del monto total en su banco.',
  withdraw_reset_label: `
  
  Reinicio Diario:
  `,
  withdraw_fee: 'Comisión',
  amount_withdraw: 'Monto a Retirar:',
  withdraw_confirm:
    'Confirmar retiro de\n<span>{{base_amount}}</span> para <span>{{target_amount}}<span>.',
  withdraw_success: '¡El retiro fue exitoso!',
  exchange_success: '¡El intercambio fue exitoso!',
}
