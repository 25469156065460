import {
  P2POffersSubscriptionVariables,
  useP2POffersSubscription,
} from 'app-engine/graphql/generated/bitcash'
import { hideBkExt, isMobile } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { Select } from 'app-view/components/Select'
import { ActionsContainer, HistoryFilterContainer } from 'app-view/components/Styled'
import { Wizard, WizardProvider, useWizard } from 'app-view/components/Wizard'
import { HistoryContentProps } from 'pages/HistoryView'
import { useAtmFilters } from 'pages/HistoryView/hooks/useAtmFilters'
import { FilterOption } from 'pages/P2PView/hooks/useP2PFilters'
import { P2POfferProvider, useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { BUY_STEPS } from 'pages/P2PView/steps/buy-steps'
import { SELL_STEPS } from 'pages/P2PView/steps/sell-steps'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { HistorySkeleton } from '../HistorySkeleton'
import { HighlightText } from '../Styled'
import { Offer } from './Offer'
import { useSystemNotifications } from 'app-view/hooks/use-systemNotification.hook'

const P2PComponent: React.FC<HistoryContentProps> = ({ content_id }) => {
  const { t } = useTranslation(['history', 'global', 'p2p'])
  const account = useStore.useAccount()
  const [, { goTo, start, close }] = useWizard()
  const [, { updateOffer }] = useP2POffer()
  const { historyTab, setHistoryTab } = useStore()
  const filter_options = useAtmFilters()
  const [filter, setFilter] = useState<string>(`...${t('global:loading')}`)
  const [variables, setVariables] = useState<P2POffersSubscriptionVariables | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { systemNotifications } = useSystemNotifications()
  const { data, loading, error } = useP2POffersSubscription({
    variables: variables!,
    skip: !account || !variables,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (loading || !data?.p2p_offers || !content_id) return () => {}

    const offer = data?.p2p_offers.find((offer) => offer.id === content_id)

    if (!offer) return () => {}

    loadHistoryOffer(offer)

    navigate({ pathname: location.pathname, search: '' }, { replace: true })

    return () => {
      setHistoryTab({ ...historyTab, content_id: '' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content_id, data?.p2p_offers, loading])

  // update filter and graphql subscription variables when the <Select> value changes
  const onFilterChange = (filter_option: FilterOption) => {
    if (!filter_option) return

    const { value, label } = filter_option
    setFilter(label)
    setVariables(value)
  }

  const loadHistoryOffer = (offer: P2POffer) => {
    const new_steps = offer.seller === account ? SELL_STEPS : BUY_STEPS
    const next_step = offer.completed ? 'complete' : 'cancelled'
    const newOffer = {
      ...offer,
      buyerLabel: hideBkExt(offer.buyer || ''),
      sellerLabel: hideBkExt(offer.seller || ''),
    }

    updateOffer(newOffer)
    start(new_steps)
    goTo(next_step)
  }

  const mergedOfferData = useMemo(() => {
    const mutatedOffers: (P2POffer & {
      systemNotificationType?: string
    })[] = []
    if (!data?.p2p_offers.length) return mutatedOffers
    data?.p2p_offers.forEach((offer) => {
      const systemNotificationData = systemNotifications.find(
        (notification) => notification.offer_id === offer.id,
      )

      if (systemNotificationData) {
        return mutatedOffers.push({
          ...offer,
          systemNotificationType: systemNotificationData.system_type_notification.type,
        })
      }
      return mutatedOffers.push(offer)
    })
    return mutatedOffers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.p2p_offers.length, systemNotifications.length])

  return (
    <>
      <HistoryFilterContainer isMobile={Boolean(isMobile)}>
        <Select
          label={t('history:filterBy')}
          options={filter_options}
          onChange={onFilterChange}
          gray
          width="100%"
        />
      </HistoryFilterContainer>
      <ActionsContainer>
        {!mergedOfferData?.length && !loading && (
          <HighlightText>{t('p2p:no_offers', { filter })}</HighlightText>
        )}
        {!loading && mergedOfferData
          ? mergedOfferData.map((offer) => (
              <Offer offer={offer} key={offer.id} onClick={loadHistoryOffer} />
            ))
          : null}
        {loading || (mergedOfferData && loading) ? <HistorySkeleton /> : null}
      </ActionsContainer>
      <Wizard error={error?.message as string} resetError={close} loading={loading} />
    </>
  )
}

export const P2P: React.FC<HistoryContentProps> = ({ content_id }) => {
  return (
    // @ts-expect-error
    <WizardProvider>
      {/* @ts-expect-error */}
      <P2POfferProvider>
        <P2PComponent content_id={content_id} />
      </P2POfferProvider>
    </WizardProvider>
  )
}
