import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatGlobesProps } from '../types'
import { useSocket } from 'SocketProvider'
import { ActivityContainer, ChatGlobe, SessionStatusContainer } from './Styled'
import { globeAnimOpt } from './ChatGlobesLoader'
import { ActivityIndicator } from 'app-view/components/ActivityIndicator'

const ChatGlobes = ({ messages, account, getTime, noConsecutive }: ChatGlobesProps) => {
  const { t } = useTranslation(['global'])
  const { onlineUsers } = useSocket()

  // Find the index of the last message that is not from the current user
  const lastNotMeIndex = useMemo(
    () =>
      messages
        ?.slice()
        .reverse()
        .findIndex((msg) => msg?.from !== account),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messages.length],
  )
  const absoluteLastNotMeIndex =
    lastNotMeIndex >= 0 ? messages.length - 1 - lastNotMeIndex : lastNotMeIndex
  return (
    <>
      {messages && messages.length > 0 ? (
        messages?.map((msg, i) => {
          const isMe = msg?.from === account
          const isLastNotMe = i === absoluteLastNotMeIndex
          const isOnline = msg?.from ? Boolean(onlineUsers[msg.from]) : false
          return (
            <ChatGlobe
              {...globeAnimOpt}
              isMe={isMe}
              style={{ marginTop: !noConsecutive(msg!, i) ? 0 : 8 }}
              key={`chat-globe-${msg?.id}-${i}`}
            >
              <SessionStatusContainer>
                {isLastNotMe ? (
                  <ActivityContainer>
                    <ActivityIndicator isOnline={isOnline} />
                  </ActivityContainer>
                ) : null}
              </SessionStatusContainer>
              <p>{msg?.message}</p>
              <footer>
                <small>{getTime(new Date(msg?.timestamp))}</small>
              </footer>
            </ChatGlobe>
          )
        })
      ) : (
        <ChatGlobe {...globeAnimOpt} isMe style={{ background: 'rgba(41, 224, 173, 0.1)' }}>
          <p style={{ opacity: 0.42 }}>{t('global:no_messages')}.</p>
        </ChatGlobe>
      )}
    </>
  )
}

export { ChatGlobes }
