import { KeyArrowIcon } from 'app-view/components/icons'
import React from 'react'
import styled from 'app-view/styles/styled'
import { ButtonTransparent } from 'app-view/components/buttons'
import { useWizard } from 'app-view/components/Wizard'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 18px 0;
`

const Text = styled.div`
  color: #5f5f5f;
  font-size: 19px;
  font-weight: 800;
  letter-spacing: -0.21px;
`

const ButtonBack = styled(ButtonTransparent)`
  position: absolute;
  left: 0;
`

export const HeaderStep = () => {
  const [{ index: indexState, steps }, { prev }] = useWizard()
  const isDisabled = Boolean(indexState === 0)

  return (
    <Container>
      <ButtonBack onClick={() => (!isDisabled ? prev() : null)} disabled={isDisabled}>
        <KeyArrowIcon />
      </ButtonBack>
      <Text>
        {indexState + 1} / {steps.length - 2}
      </Text>
    </Container>
  )
}
