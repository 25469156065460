import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { Amount } from 'pages/P2PView/steps/common/Amount'
import { WizardStepProps } from 'app-view/components/Wizard'
import { Asset } from 'eos-common'
import { useUserPositions } from 'app-view/hooks/use-user-positions'
import { cryptoAmount } from 'app-view/lib/utils'

export const SellAmount = ({ next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { updateOffer }] = useP2POffer()
  const userPositionsHook = useUserPositions()
  const findUserPosition = userPositionsHook.findUserPosition
  const [enoughBalance, setEnoughBalance] = useState(false)

  const disabledMsg = !enoughBalance ? 'Not enough balance' : undefined

  const onChange = (amount: string) => {
    // Ex. amount = "20000.00 BITCLP"
    const currentAsset = new Asset(amount)
    const currentAssetSymbol = currentAsset.toString().split(' ')[1] // BITCLP
    const coinBalance = findUserPosition(currentAssetSymbol).balance // CryptoBalance  32.00 BITCLP
    const availableBalance = parseFloat(coinBalance.toString().split(' ')[0])
    const assetAmount = Number(currentAsset.amount.divide(100))

    setEnoughBalance(availableBalance >= assetAmount) // 32 >= 20000 => false; 32 >= 32 => true
    updateOffer({ amount })
  }

  return (
    <Amount
      onChange={onChange}
      onNext={next}
      text={t('amount_to_sell', { symbol: cryptoAmount(offer.amount).split(' ')[1] })}
      disabled={disabledMsg}
    />
  )
}
