import { scrollbar_css_access } from 'app-view/components/Styled'
import { motion } from 'framer-motion/dist/framer-motion'
import styled from 'app-view/styles/styled'

export const MaintenanceContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding-top: 4vh;
  justify-content: start;
  flex-direction: column;
  background: linear-gradient(180deg, #161a33 0%, #0f111e 100%);
  position: relative;
  overflow-y: auto;
  gap: 42px;

  ${scrollbar_css_access}

  @media screen and (min-width: 500px) {
    justify-content: center;
  }
`

export const BackgroundContainer = styled(motion.div)`
  position: absolute;
  opacity: 0.05;
`

export const LogoContainer = styled(motion.div)`
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Branding = styled.div`
  transition: all 0.7s ease-in;
  position: relative;
  height: 172px;
`

export const Slogan = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.162672px;
  position: relative;
  color: #f2f2f2;
`

export const Message = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
  letter-spacing: -0.162672px;
  margin: 24px;
  color: #f2f2f2;
  position: relative;
`

export const SupportChannel = styled.div`
  display: flex; /* Use flexbox */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
  flex-direction: row; /* Arrange children in a row */
  gap: 8px; /* Add some space between the text and the link */

  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: -0.162672px;
  position: relative;
  color: #f2f2f2;
`
