// TODO: Translate this file!
export default {
  heading: 'Profile Options',
  payment_methods: 'Payment Methods',
  profile_information: 'Profile Information',
  phone_number: 'Phone Number',
  email: 'Email Address',
  newsletter: 'Newsletter Subscription',
  phone_number_placeholder: '{{phoneNumberCountryCode}}手机号码',
  include_country_code: '包括国家/地区代码',
  phone_valid: 'Phone number is valid!',
  delete_payment_option_warning: 'Are you sure you want to remove this payment method?',
  delete: 'Delete',
  cancel: 'Cancel',
  no_methods: 'No Previous Payment Method Added',
  save: 'Save Changes',
  revert: 'Revert',
  confirm: 'Confirm',
  select_method: `Select the payment method
	that you would like to save.`,
  profile_update_success: '个人资料更新成功',
  verification_status: '状态:',
}
