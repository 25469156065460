import global from './global'
import settings from './settings'
import header from './header'
import wallet from './wallet'
import trade from './trade'
import about from './about'
import p2p from './p2p'
import navigation from './navigation'
import history from './history'
import swap from './swap'
import notifications from './notifications'
import errors from './errors'
import onboarding from './onboarding'
import profile from './profile'
import maintenance from './maintenance'
import desktop from './desktop'

export default {
  global,
  settings,
  header,
  wallet,
  trade,
  about,
  p2p,
  navigation,
  history,
  swap,
  notifications,
  errors,
  onboarding,
  profile,
  maintenance,
  desktop,
}
