import { getCurrencyData } from 'app-engine/library/currency-countries'
import { HintError, Input, InputSection, InputWrapper } from 'app-view/components/InputField'
import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { ICurrencyByCountry } from 'app-view/hooks/use-realtime/currency-by-country.schema'
import { useLoggedUser } from 'app-view/hooks/use-realtime/use-logged-user'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import { Form } from 'pages/AccountView/components/TextElements'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'

const methodDetailsFormFields = {
  kuda: ['accountNumber'],
  bank_transfer: ['fullName', 'accountName', 'accountNumber'],
  opay: ['fullName', 'phoneNumber'],
  palmpay: ['fullName', 'phoneNumber'],
  oxxo: ['fullName', 'phoneNumber'],
  ovo: ['phoneNumber'],
  duitnow: ['phoneNumber'],
  gojek: ['phoneNumber'],
  sinpe_movil: ['phoneNumber'],
  grab: ['phoneNumber'],
}

export const MethodDetails = ({ onPrev, onNext, text }: MethodDetailsProps) => {
  const { t } = useTranslation(['p2p'])
  const countryDataLoggedUser = useLoggedUser()
  const countriesData = bitcashRoom.useColyseusState((state) => state.countryData)
  const [{ offer }, { updateOffer }] = useP2POffer()
  const defaultMethodDetails = {
    fullName: '',
    accountName: '',
    accountNumber: '',
    phoneNumber: '',
  }
  const buyerMethodDetails = offer.buyer_method_details
    ? JSON.parse(offer.buyer_method_details)
    : defaultMethodDetails
  const sellerMethodDetails = offer.seller_method_details
    ? JSON.parse(offer.seller_method_details)
    : defaultMethodDetails
  const defaultFullName =
    Boolean(buyerMethodDetails.fullName) || Boolean(sellerMethodDetails.fullName)
      ? buyerMethodDetails.fullName || sellerMethodDetails.fullName
      : defaultMethodDetails.fullName
  const defaultAccountName =
    Boolean(buyerMethodDetails.accountName) || Boolean(sellerMethodDetails.accountName)
      ? buyerMethodDetails.accountName || sellerMethodDetails.accountName
      : defaultMethodDetails.accountName
  const defaultAccountNumber =
    Boolean(buyerMethodDetails.accountNumber) || Boolean(sellerMethodDetails.accountNumber)
      ? buyerMethodDetails.accountNumber || sellerMethodDetails.accountNumber
      : defaultMethodDetails.accountNumber
  const defaultPhoneNumber =
    Boolean(buyerMethodDetails.phoneNumber) || Boolean(sellerMethodDetails.phoneNumber)
      ? buyerMethodDetails.phoneNumber || sellerMethodDetails.phoneNumber
      : defaultMethodDetails.phoneNumber
  const { register, handleSubmit, setValue, setError, watch, trigger, formState } = useForm({
    defaultValues: {
      fullName: defaultFullName,
      accountName: defaultAccountName,
      accountNumber: defaultAccountNumber,
      phoneNumber: defaultPhoneNumber,
    },
  })
  const [currencyCountryData, setCurrencyCountryData] = useSetState<ICurrencyByCountry>()

  const phoneValue = watch('phoneNumber')

  useEffect(() => {
    if (offer.method === 'bank_transfer') return

    const fetchCurrencyCountryData = async () => {
      const countryData = await getCurrencyData(
        offer.amount.includes('BITUSD') && countryDataLoggedUser
          ? countryDataLoggedUser.currency
          : offer.amount.split(' ')[1],
        countriesData,
      )

      setCurrencyCountryData(countryData)
    }

    fetchCurrencyCountryData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.amount, offer.method])

  const onSubmitHandler = handleSubmit(({ accountNumber, accountName, phoneNumber, fullName }) => {
    let success = false

    const formatUserData = (data: Partial<typeof defaultMethodDetails>) => JSON.stringify(data)
    const offerParticipantKey =
      offer.type === 'buy' ? 'buyer_method_details' : 'seller_method_details'

    try {
      switch (offer.method) {
        case 'kuda':
          success = Boolean(accountNumber)

          if (success) {
            updateOffer({
              [offerParticipantKey]: formatUserData({
                accountNumber: accountNumber.toUpperCase(),
              }),
            })
          }
          break
        case 'bank_transfer':
          success = Boolean(accountNumber && accountName && fullName)

          if (success) {
            updateOffer({
              [offerParticipantKey]: formatUserData({
                fullName,
                accountName,
                accountNumber: accountNumber.toUpperCase(),
              }),
            })
          }
          break
        case 'opay':
        case 'palmpay':
        case 'oxxo': {
          success = Boolean(fullName) && Boolean(phoneNumber) && phoneNumber.length >= 7

          if (success) {
            updateOffer({
              [offerParticipantKey]: formatUserData({
                fullName,
                phoneNumber: phoneNumber.trim().replace(/\s/g, '-'),
              }),
            })
          }
          break
        }
        case 'ovo':
        case 'duitnow':
        case 'gojek':
        case 'sinpe_movil':
        case 'grab':
          success = Boolean(phoneNumber) && phoneNumber.length >= 7

          if (success) {
            updateOffer({
              [offerParticipantKey]: formatUserData({
                phoneNumber: phoneNumber.trim().replace(/\s/g, '-'),
              }),
            })
          }
          break
        default:
          success = false
          break
      }
    } catch (error) {
      console.error('onSubmitHandler::MethodDetails::ERROR', error)
    } finally {
      console.log('onSubmitHandler::MethodDetails::success', success)
      onNext()
    }
  })

  const formatUserPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const phoneNumber = parsePhoneNumber(
      value,
      (currencyCountryData.cca2 ? currencyCountryData.cca2 : '') as CountryCode,
    )
    const nationalOrInternationalNumber = !currencyCountryData.cca2.match(/^(NG)$/)
      ? phoneNumber?.formatInternational()
      : phoneNumber?.formatNational()

    setValue('phoneNumber', phoneNumber ? nationalOrInternationalNumber : value.trim())
    trigger('phoneNumber').then(() => {
      setError('phoneNumber', {
        message: phoneNumber?.isValid() ? '' : 'Invalid phone number',
      })
    })
  }

  const phoneNumberCountryCode = currencyCountryData?.cca2
    ? `+${parsePhoneNumber('123', currencyCountryData?.cca2 as CountryCode)?.countryCallingCode}`
    : ''
  // * formatting the phone number to the country code after setting the international number
  const formattedValue = new AsYouType(currencyCountryData?.cca2 as CountryCode)
    .input(phoneValue)
    .replace(/\s/g, '-')
  const placeholderLabel = t('phone_number_label', {
    phoneNumberCountryCode: phoneNumberCountryCode ? `(${phoneNumberCountryCode}) ` : '',
  })

  const requiredFields =
    methodDetailsFormFields[offer.method as keyof typeof methodDetailsFormFields]
  const isFormValid =
    Object.keys(formState.errors).every(
      (error) => !Boolean(formState.errors[error as keyof typeof formState.errors]?.message),
    ) && requiredFields.every((field) => Boolean(watch(field as keyof typeof defaultMethodDetails)))
  const isSell = offer.type === 'sell'
  const methodDetailsFieldConfig = {
    required: isSell,
  }

  const updateField = async (
    event: React.ChangeEvent<HTMLInputElement>,
    field: keyof typeof defaultMethodDetails,
  ) => {
    const value = event.target.value

    if (value.trim()) {
      setValue(field, value.trim())
      setError(field, {
        message: '',
      })
    } else {
      setError(field, {
        message: 'required field',
      })
    }
  }

  return (
    <Form id="method-details" onSubmit={onSubmitHandler}>
      <ModalSelectWrapper>
        {offer.method.match(/^(bank_transfer|kuda)$/) ? (
          <>
            {offer.method === 'bank_transfer' && (
              <>
                <InputWrapper style={{ maxWidth: 235 }}>
                  <InputSection
                    border="grey"
                    error={Boolean(!formState.isValidating && formState.errors.fullName?.message)}
                  >
                    <Input
                      {...register('fullName', methodDetailsFieldConfig)}
                      onChange={(event) => updateField(event, 'fullName')}
                      defaultValue={defaultFullName}
                      placeholder={t('full_name_label')}
                      type="text"
                      fullradius={1}
                      fontWeight="500"
                    />
                  </InputSection>
                  {!formState.isValidating && formState.errors.fullName?.message && (
                    <HintError align="left">{formState.errors.fullName?.message}</HintError>
                  )}
                </InputWrapper>
                <InputWrapper style={{ maxWidth: 235 }}>
                  <InputSection
                    border="grey"
                    error={Boolean(
                      !formState.isValidating && formState.errors.accountName?.message,
                    )}
                  >
                    <Input
                      {...register('accountName', methodDetailsFieldConfig)}
                      onChange={(event) => updateField(event, 'accountName')}
                      defaultValue={defaultAccountName}
                      placeholder={t('bank_name_label')}
                      type="text"
                      fullradius={1}
                      fontWeight="500"
                    />
                  </InputSection>
                  {!formState.isValidating && formState.errors.accountName?.message && (
                    <HintError align="left">{formState.errors.accountName?.message}</HintError>
                  )}
                </InputWrapper>
              </>
            )}
            <InputWrapper style={{ maxWidth: 235 }}>
              <InputSection
                border="grey"
                error={Boolean(!formState.isValidating && formState.errors.accountNumber?.message)}
              >
                <Input
                  {...register('accountNumber', methodDetailsFieldConfig)}
                  onChange={(event) => updateField(event, 'accountNumber')}
                  defaultValue={defaultAccountNumber}
                  placeholder={t('account_number_label')}
                  type="text"
                  fullradius={1}
                  fontWeight="500"
                />
              </InputSection>
              {!formState.isValidating && formState.errors.accountNumber?.message && (
                <HintError align="left">{formState.errors.accountNumber?.message}</HintError>
              )}
            </InputWrapper>
          </>
        ) : (
          <>
            {offer.method.match(/^(oxxo|opay|palmpay)$/) && (
              <InputWrapper style={{ maxWidth: 235 }}>
                <InputSection
                  border="grey"
                  error={Boolean(!formState.isValidating && formState.errors.fullName?.message)}
                >
                  <Input
                    {...register('fullName', methodDetailsFieldConfig)}
                    onChange={(event) => updateField(event, 'fullName')}
                    defaultValue={defaultFullName}
                    placeholder={t('full_name_label')}
                    type="text"
                    fullradius={1}
                    fontWeight="500"
                  />
                </InputSection>
                {!formState.isValidating && formState.errors.fullName?.message && (
                  <HintError align="left">{formState.errors.fullName?.message}</HintError>
                )}
              </InputWrapper>
            )}
            <InputWrapper style={{ maxWidth: 235 }}>
              <InputSection
                border="grey"
                error={Boolean(
                  phoneValue && !formState.isValidating && formState.errors.phoneNumber?.message,
                )}
              >
                <Input
                  {...register('phoneNumber', methodDetailsFieldConfig)}
                  onChange={formatUserPhoneNumber}
                  placeholder={
                    currencyCountryData?.cca2 && !currencyCountryData.cca2.match(/^(NG)$/)
                      ? placeholderLabel
                      : placeholderLabel.split(' | ')[1]
                  }
                  value={formattedValue}
                  fullradius={1}
                  fontWeight="500"
                  type="text"
                />
              </InputSection>
              {!formState.isValidating && phoneValue && formState.errors.phoneNumber?.message && (
                <HintError align="left">{formState.errors.phoneNumber?.message}</HintError>
              )}
            </InputWrapper>
          </>
        )}
        <ModalText>{text}</ModalText>
      </ModalSelectWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={onPrev}>
          {t('Back')}
        </ModalStepBlackButton>
        <ModalStepGreenButton
          form="method-details"
          disabled={isSell ? !isFormValid : undefined}
          type="submit"
        >
          {t('Next')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}

export type MethodDetailsProps = {
  onPrev: () => void
  onNext: () => void
  text: string
}
