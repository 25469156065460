import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { useGlobalModal } from 'app-view/components/GlobalModal/useGlobalModal'
import { ModalError } from 'app-view/components/Modal'
import { WizardProvider } from 'app-view/components/Wizard'
import { SelfieProvider } from 'app-view/hooks/use-selfie'
import { LargeBlackButton, LargeGreenButton } from 'pages/AccountView/components/LargeButtons'
import { Form } from 'pages/AccountView/components/TextElements'
import { useRegistration } from 'pages/AccountView/hooks/useRegistration'
import { checkAccountExt } from 'pages/AccountView/utils'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useKYCForm } from '../../../../app-view/hooks/useKYCForm'
import { AccountFormFields } from './AccountFormFields'

interface AccountKYCFormProps {
  successPath: string
  createAccount?: boolean
}

export const AccountKYCForm: React.FC<AccountKYCFormProps> = ({
  successPath,
  createAccount = true,
}) => {
  const { t } = useTranslation(['account', 'global'])
  const navigate = useNavigate()
  const [{ account, referrer, cred_id, public_key, device_name, website, link }] = useRegistration()
  const [requestNewAccount] = Bitcash.useBitcashRequestNewAccountMutation()
  const [updateAccountReferralRegisterCount] =
    Bitcash.useUpdateAccountReferralRegisterCountMutation()
  const [, globalModalActions] = useGlobalModal()

  const { setValue, setError, formState, watch, handleSubmit, register } = useForm({
    defaultValues: {
      selfie: null,
      countryId: '',
      idType: 'national_id',
      country: '',
      fullName: '',
      phoneNumber: '',
      emailAddress: '',
      newsletterSubscription: true,
      photoId: null,
    },
  })

  const submitAccountKYC = handleSubmit(
    async ({
      selfie,
      countryId,
      idType,
      country,
      phoneNumber: phone_number,
      emailAddress: email_address,
      fullName: full_name,
      newsletterSubscription: newsletter_subscription,
      photoId,
    }) => {
      const formattedAccount = checkAccountExt(account)
      const formattedReferrer = checkAccountExt(referrer as string)

      try {
        const newAccountRequested = await requestNewAccount({
          variables: {
            account_data: {
              country_id: {
                id: countryId,
                type: idType,
                country,
              },
              account: formattedAccount,
              referrer: formattedReferrer,
              cred_id: cred_id || '',
              public_key: public_key,
              full_name,
              device_name,
              phone_number,
              email_address,
              newsletter_subscription,
              create_account: createAccount,
              source: website,
              selfie: selfie,
              photo_id: photoId,
            } as Bitcash.Request_New_Account_Input,
          },
        })

        if (link) {
          const incRegisterCount = await updateAccountReferralRegisterCount({
            variables: {
              link,
            },
          })
          console.log('registring account response::incRegisterCount', incRegisterCount)
        }

        console.log('registring account response::newAccountRequested', newAccountRequested)

        if (newAccountRequested.errors) throw new Error(newAccountRequested.errors[0].message)

        navigate(successPath)
      } catch (error) {
        console.error('submitAccountKYC::ERROR', { error })
        globalModalActions.open({
          content: () => <ModalError error={(error as Error).message} />,
          iconType: 'ERROR',
          showClose: true,
          title: 'Error',
        })
      }
    },
  )

  const {
    handleCountryIdChange,
    formatUserPhoneNumber,
    verifyEmailAddress,
    setFullNameValue,
    setIdType,
    setCountry,
    idType,
    country,
    countryIdValue,
    fullNameValue,
    phoneValue,
    emailValue,
    phoneNumberCountryCode,
    formattedValue,
    shouldOmitSubmit,
    checkCountryIdState,
    validPhoneNumberState,
    selfie,
    setSelfie,
    photoId,
    setPhotoId,
    checkEmailState,
  } = useKYCForm({ submitAccountKYC, setValue, setError, formState, watch })

  return (
    <Form id="account-kyc" onSubmit={submitAccountKYC}>
      <SelfieProvider>
        {/* @ts-expect-error */}
        <WizardProvider>
          <AccountFormFields
            register={register}
            formState={formState}
            checkCountryIdState={checkCountryIdState}
            validPhoneNumberState={validPhoneNumberState}
            handleCountryIdChange={handleCountryIdChange}
            verifyEmailAddress={verifyEmailAddress}
            formatUserPhoneNumber={formatUserPhoneNumber}
            setFullNameValue={setFullNameValue}
            setIdType={setIdType}
            setCountry={setCountry}
            phoneNumberCountryCode={phoneNumberCountryCode}
            formattedValue={formattedValue}
            phoneValue={phoneValue}
            emailValue={emailValue}
            fullNameValue={fullNameValue}
            countryIdValue={countryIdValue}
            country={country}
            idType={idType}
            selfie={selfie}
            setSelfie={setSelfie}
            photoId={photoId}
            setPhotoId={setPhotoId}
            checkEmailState={checkEmailState}
          />
          <div>
            <LargeGreenButton type="submit" disabled={shouldOmitSubmit}>
              {t('create_account_accounts_submit')}
            </LargeGreenButton>
            <LargeBlackButton type="button" onClick={() => navigate(-1)}>
              {t('global:back')}
            </LargeBlackButton>
          </div>
        </WizardProvider>
      </SelfieProvider>
    </Form>
  )
}
