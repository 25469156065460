import { WizardStepProps } from 'app-view/components/Wizard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Method } from './Method'
import { useStore } from 'app-engine/store'

export const ProfileMethodStep = ({ next, close }: WizardStepProps) => {
  const { t } = useTranslation(['profile'])
  const setCurrentMethod = useStore.useSetCurrentPaymentMethod()

  const onChange = async (option: string) => {
    setCurrentMethod(option)
  }

  return <Method onChange={onChange} onPrev={close} onNext={next} text={t('select_method')} />
}
