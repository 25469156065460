import { GreenButton } from 'app-view/components/buttons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'

import { useChat } from 'app-view/components/Chat/hooks/useChat'
import { WizardStepProps } from 'app-view/components/Wizard'
import { ModalText, ModalButtonsContainer } from 'app-view/components/Modal'
import { get_amount } from 'app-view/lib/utils'
import { useStore } from 'app-engine/store'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const BuyPaymentConfirmed = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()
  const [{ new_message }, { onOpenWithProps, notifyNewMessage }] = useChat()
  const { account } = useStore()

  const openChat = () => {
    notifyNewMessage(0)
    onOpenWithProps({
      from: account,
      to: (offer.buyer !== account ? offer.buyer : offer.seller) || '',
      id: {
        id: offer.id,
        type: 'p2p',
      },
    })
    close()
  }

  return (
    <>
      <ModalText
        dangerouslySetInnerHTML={{
          __html: t('buy_payment_confirmed', {
            amount: get_amount(offer.amount),
            seller: offer.sellerLabel,
            method: methodLabel(offer.method),
            methodDetails: isMethodDetails ? sellerMethodDetailsLabelData : '',
          }),
        }}
      />
      <ModalButtonsContainer>
        <GreenButton onClick={openChat}>
          {/* TODO: To create a new msg counter for green button */}
          {new_message > 0 && (
            <span style={{ background: '#0f4', fontWeight: 700, padding: 4, borderRadius: '50%' }}>
              {new_message}
            </span>
          )}
          {t('p2p:Contact')}
        </GreenButton>
      </ModalButtonsContainer>
    </>
  )
}
