import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { Amount } from 'pages/P2PView/steps/common/Amount'
import { WizardStepProps } from 'app-view/components/Wizard/types'
import { cryptoAmount } from 'app-view/lib/utils'

export const BuyAmount = ({ next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { updateOffer }] = useP2POffer()

  const onChange = (amount: string) => updateOffer({ amount })

  return (
    <Amount
      onChange={onChange}
      onNext={next}
      text={t('amount_to_buy', { symbol: cryptoAmount(offer.amount).split(' ')[1] })}
    />
  )
}
