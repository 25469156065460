import { WizardStepProps } from 'app-view/components/Wizard'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Text } from 'pages/AccountView/components/TextElements'
import { Loader } from 'app-view/components/Spinner'
import {
  ModalButtonsContainer,
  ModalStepGreenButton,
  ModalStepBlackButton,
} from 'app-view/components/Modal'
import { useStore } from 'app-engine/store'
import { StableCoinAmount } from 'app-view/components/StableCoinAmount'
import { BallIcon, ExchangeArrowsIcon } from 'app-view/components/icons'
import { FlexBox } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { SimpleTransparentButton } from 'app-view/components/buttons/ButtonTransparent'
import * as math from 'app-engine/library/math'
import { useBankWallet } from 'pages/BankView/hooks/useBankWallet'
import { NumberFormatValues } from 'react-number-format'
import Decimal from 'decimal.js'
import { isMobile } from 'app-engine/library/utils'
import { useLocalStorage } from 'react-use'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useProfile } from 'app-view/hooks/use-profile.hook'

const ExchangeRateSection = styled(FlexBox)`
  padding-top: 15px;
  max-width: 330px;
  width: 100%;
`

const ExchangeRateContainer = styled(FlexBox)`
  padding-left: 18px;
  align-items: flex-start;
  width: 131px;
  flex-direction: column;
  gap: 0px;
  p {
    margin-top: 4px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.199772px;
  }
`

const ExchangeRateLabelContainer = styled(FlexBox)`
  align-items: flex-end;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: -0.108448px;
  }
`

const ExchangeRateArrowContainer = styled(FlexBox)`
  position: absolute;
  width: 50%;
  left: calc(50% - 24px);
`

const Rate = styled.p`
  font-weight: 600;
  width: 100%;
`

export type CoinCode = string

export const ExchangeAmount = ({ next, close }: WizardStepProps) => {
  const delphiPrices = bitcashRoom.useColyseusState((state) => state.delphiPrices)
  const stableCoinOptions = bitcashRoom.useColyseusState((state) => state.stableCoins)
  const stableCoinPairs = bitcashRoom.useColyseusState((state) => state.stableCoinsPairs)
  const stableCoinFees = bitcashRoom.useColyseusState((state) => state.stableCoinsFees)

  const { t } = useTranslation('wallet')
  const {
    stableCoinFrom,
    stableCoinTo,
    stableAmountFrom,
    setStableCoinFrom,
    setStableCoinTo,
    setStableAmountFrom,
    swapStableCoins,
  } = useStore()
  const [{ preferences }, {}] = useProfile()

  const [{ loading }, { submitExchangeAction }] = useBankWallet()
  const [lastCryptoFrom, setLastCryptoFrom] = useLocalStorage<string>('bitcash:last_crypto_from')
  const [lastCryptoTo, setLastCryptoTo] = useLocalStorage<string>('bitcash:last_crypto_to')
  useEffect(() => {
    return () => {
      resetValues()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const defaultFrom = useMemo(() => {
    // Do we need preferences.currency
    if (lastCryptoFrom || stableCoinFrom) return lastCryptoFrom || stableCoinFrom || ''
    if (stableCoinOptions?.includes(preferences.currency)) return preferences.currency
    return 'BITUSD'
  }, [stableCoinOptions, preferences.currency, stableCoinFrom, lastCryptoFrom])

  const defaultTo = useMemo(() => {
    if (lastCryptoTo) return lastCryptoTo || ''
    return stableCoinOptions?.find((coin) => coin !== defaultFrom) || ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableCoinOptions, defaultFrom])

  useEffect(() => {
    if (defaultTo === stableCoinTo) return
    setStableCoinTo(defaultTo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTo])

  useEffect(() => {
    if (defaultFrom === stableCoinFrom) return
    setStableCoinFrom(defaultFrom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFrom])

  useEffect(() => {
    if (stableCoinFrom !== lastCryptoFrom) {
      setLastCryptoFrom(stableCoinFrom)
    }
    if (stableCoinTo !== lastCryptoTo) {
      setLastCryptoTo(stableCoinTo)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableCoinFrom, stableCoinTo, lastCryptoFrom, lastCryptoTo])

  const resetValues = useCallback(() => {
    setStableAmountFrom(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const coin_options_to = useMemo(() => {
    // TODO: Once we enable different combinations of stable exchange, remove ternary condition and keep else scenario...
    const options = stableCoinOptions?.filter((coin) =>
      stableCoinFrom !== 'BITUSD' ? coin === 'BITUSD' : coin !== stableCoinFrom,
    )

    if (stableCoinFrom !== 'BITUSD') {
      setStableCoinTo('BITUSD')
    }

    return options
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stableCoinOptions, stableCoinFrom])

  const swapCoins = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      // TODO: fix this - @Andler will do it [Priority Medium]
      if (isMobile) return
      swapStableCoins()
    },
    [swapStableCoins],
  )

  const onExchange = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!stableAmountFrom?.value) return
    const stableCoinKey = stableCoinTo.includes('BITUSD') ? stableCoinFrom : stableCoinTo
    const { success } = await submitExchangeAction({
      pair_id: stableCoinPairs?.get(stableCoinKey),
      coin: stableCoinFrom,
      amount: `${Number(stableAmountFrom?.value).toFixed(2)}`,
    })

    if (success) next()
  }

  // Example
  // 1505 BitCNY => bitUSD
  // 4567 bitUSD => BitCNY
  // 1505 BitCNY => BitCRC
  // 1505 BitCNY => bitUSD => BitCRC
  const displayRate = useMemo(() => {
    const calculateRate = (coin_from: CoinCode, coin_to: CoinCode): string => {
      if (!coin_to || !coin_from) return ''
      if (coin_to === coin_from) return '1'

      let base_rate: string | undefined

      if (coin_from === 'BITUSD') {
        base_rate = delphiPrices?.get(coin_to)?.displayValue

        return base_rate || ''
      } else if (coin_to === 'BITUSD') {
        base_rate = delphiPrices?.get(coin_from)?.displayValue
        base_rate = base_rate ? new Decimal(1).div(base_rate).toString() : ''

        return base_rate
      }

      try {
        const rate_1 = calculateRate(coin_from, 'BITUSD')
        const rate_2 = calculateRate('BITUSD', coin_to)

        // for (const [key, value] of delphiPrices) {
        //   if (key === coin_from || key === coin_to) {
        //      console.table(value)
        //   }
        // }
        let finalRate = ''
        if (rate_1 && rate_2) finalRate = new Decimal(rate_1).mul(rate_2).toString()
        return finalRate
      } catch (error) {
        return ''
      }
    }

    const formattedRate = math.formatStableCoinRate(calculateRate(stableCoinFrom, stableCoinTo))
    const rate = calculateRate(stableCoinFrom, stableCoinTo)

    return {
      formattedRate,
      rate,
    }
  }, [stableCoinTo, stableCoinFrom, delphiPrices])

  const amountExchangeTo = useMemo(() => {
    if (!stableAmountFrom?.value || !displayRate.rate) return ''

    try {
      const exchangeAmount = new Decimal(stableAmountFrom.value).mul(displayRate.rate)
      const finalExchangeAmount =
        !exchangeAmount.equals(0.0) && exchangeAmount.lessThanOrEqualTo(0.009)
          ? '< .01'
          : exchangeAmount.toFixed(2)

      return finalExchangeAmount
    } catch (error) {
      return ''
    }
  }, [displayRate.rate, stableAmountFrom])

  const onBlurAmountFrom = useCallback(() => {
    if (!stableAmountFrom?.floatValue) return
    const formattedValue = new Decimal(stableAmountFrom?.floatValue).toString()
    const from: NumberFormatValues = {
      formattedValue,
      value: formattedValue,
      floatValue: Number(parseFloat(formattedValue)),
    }
    setStableAmountFrom(from)
  }, [setStableAmountFrom, stableAmountFrom?.floatValue])

  const changeCoinFrom = useCallback(
    (new_coin: string) => {
      new_coin === stableCoinTo ? swapStableCoins() : setStableCoinFrom(new_coin)
    },
    [swapStableCoins, setStableCoinFrom, stableCoinTo],
  )

  const feePercentage = useMemo(() => {
    const fee = stableCoinFees?.get(stableCoinTo === 'BITUSD' ? stableCoinFrom : stableCoinTo)
    return fee ? fee : '--'
  }, [stableCoinFees, stableCoinFrom, stableCoinTo])

  const validExchangeAmount = amountExchangeTo && Number(amountExchangeTo) >= 0.009

  if (loading) return <Loader text={t('loading_action')} />

  return (
    <Form onSubmit={onExchange} wallet>
      <StableCoinAmount
        id={t('exchange_send')}
        label={t('exchange_send')}
        coin={stableCoinFrom || defaultFrom}
        initialCoin={defaultFrom}
        onChangeAmount={setStableAmountFrom}
        setCoin={changeCoinFrom}
        amount={stableAmountFrom?.formattedValue}
        onBlur={onBlurAmountFrom}
        coinOptions={stableCoinOptions}
      />
      <ExchangeRateSection>
        <ExchangeRateContainer>
          <ExchangeRateLabelContainer>
            <BallIcon />
            <Text>{t('exchange_rate')}</Text>
          </ExchangeRateLabelContainer>
          <Rate>{displayRate.formattedRate || t('not_rate_found')}</Rate>
          <ExchangeRateLabelContainer>
            <BallIcon />
            <Text>{`${t('exchange_rate_fee')} ${feePercentage}%`}</Text>
          </ExchangeRateLabelContainer>
        </ExchangeRateContainer>
        <ExchangeRateArrowContainer>
          <SimpleTransparentButton onClick={swapCoins}>
            <ExchangeArrowsIcon />
          </SimpleTransparentButton>
        </ExchangeRateArrowContainer>
      </ExchangeRateSection>
      <StableCoinAmount
        readOnly
        id={t('exchange_get')}
        label={t('exchange_get')}
        coin={stableCoinTo || defaultTo}
        initialCoin={defaultTo}
        amount={amountExchangeTo}
        setCoin={setStableCoinTo}
        coinOptions={coin_options_to}
        prefix={stableAmountFrom?.floatValue && !validExchangeAmount ? '< ' : undefined}
      />
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={close}>
          {t('back_action')}
        </ModalStepBlackButton>
        <ModalStepGreenButton type="submit" disabled={!validExchangeAmount}>
          {t('exchange')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}
