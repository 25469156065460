import { Card, CardText } from 'app-view/components/Styled'
import styled from 'app-view/styles/styled'
import { ReadProp } from 'pages/NotificationsView/components/NotificationItem'
import { Link } from 'react-router-dom'

type NotificationItemProps = {
  pos?: number
  bottomPos?: number
}

export const NotificationItem = styled(Card)<ReadProp & NotificationItemProps>`
  background-color: ${(props) => (!props.read ? '#CFECE0' : '#FFFFFF')};
  border: none;
  min-height: 86px;
  padding: 11px 16px 11px 11px;
  width: 100%;
  position: relative;
  list-style: none;

  &.stacked {
    position: absolute;
    background-color: ${(props) => (!props.read ? '#CFECE0' : '#FFFFFF')};
    border: 1px solid #cfece024;
    height: 80px;
    box-shadow: -1px 2px 10px 2px rgba(10, 11, 10, 0.02);
    z-index: ${({ pos, bottomPos }) => `${pos === 0 ? 100 : bottomPos || 0}`};
    margin-top: ${({ pos = 0 }) => `${pos * 5}px`};
    right: ${({ pos = 0 }) => `${pos * 5}px`};
    padding: 11px 16px 11px 11px;
    width: 100%;
  }
`

export const NotificationItemLink = styled(Link)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const IconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 11px;
  height: 100%;
`

export const TextContainer = styled.div`
  border-left: 1px solid #787878;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  justify-self: flex-start;
  min-height: 52px;
  padding-left: 11px;
`

export const MSGContainer = styled.p`
  display: flow-root;
  font-size: 0.813rem;
  height: 100%;
  width: calc(100% - 40px);
  color: initial;
  font-weight: 600;
  margin: 0;
`

export const StrongText = styled.strong`
  line-height: inherit;
`

export const DateText = styled(CardText)`
  justify-items: flex-end;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
`

export const SettingButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  width: 40px;
`
