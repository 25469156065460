import { WizardStep } from 'app-view/components/Wizard'
import { Alert } from './Alert'
import { TakePhotoId } from './take-image/TakePhotoId'
import { TakeSelfie } from './take-image/TakeSelfie'
import { KYCInfo } from './KYCInfo'

export const TAKE_SELFIE: WizardStep[] = [
  {
    name: 'alert',
    component: Alert,
  },
  {
    name: 'take_selfie',
    component: TakeSelfie,
  },
  {
    name: 'take_photo_id',
    component: TakePhotoId,
  },
  {
    name: 'info',
    component: KYCInfo,
  },
]
