import { MapSchema } from '@colyseus/schema'
import { CurrencyArray } from './bitcash-room.schema'
import { ICurrencyByCountry } from './currency-by-country.schema'
import { User, UserEntry } from './users.schema'

export const parseCountriesCurrencyFromColyseus = (
  _countryData: MapSchema<CurrencyArray, string>,
) => {
  const countries: Record<string, ICurrencyByCountry[]> = {}
  if (!_countryData) return null
  const parsedCountryData = _countryData.toJSON() as CurrencyArray
  if (!parsedCountryData || Object.keys(parsedCountryData).length === 0) return countries
  for (const [currency, currentCountries] of Object.entries(parsedCountryData)) {
    countries[currency] = currentCountries.data
  }
  return countries
}

export const parseUsers = (_users: MapSchema<User, string>) => {
  if (!_users) return []
  const parsedUsers = _users.toJSON() as Record<string, UserEntry>
  const users = Object.values(parsedUsers)
  return users
}
