import React from 'react'

const SIAIcon = (props: any) => (
  <svg viewBox="0 0 54 54" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.3918 0.00123349C16.1944 -0.0809876 3.08809 3.92948 0.703618 17.3766C-0.423696 23.7327 -0.0243682 29.893 0.703618 36.2699C2.19535 49.3284 15.2301 54.3118 26.9997 53.985C38.6862 53.6607 51.7657 49.6724 53.2966 36.2695C54.0245 29.893 54.4234 23.7327 53.2966 17.3766C51.2067 5.59227 39.4908 0.0767767 28.3918 0.00123349Z"
      fill="white"
      stroke="#20EE82"
      strokeWidth="0.5"
    />
    <g clipPath="url(#clip0_4621_5577)">
      <path
        d="M30.8766 26.7485V30.873H26.7486C25.9329 30.873 25.1354 30.6311 24.4571 30.1779C23.7789 29.7247 23.2502 29.0805 22.938 28.3269C22.6258 27.5732 22.5442 26.7439 22.7033 25.9438C22.8625 25.1437 23.2553 24.4088 23.8321 23.8319C24.4089 23.2551 25.1439 22.8623 25.944 22.7031C26.7441 22.544 27.5734 22.6256 28.327 22.9378C29.0807 23.25 29.7249 23.7787 30.1781 24.4569C30.6313 25.1352 30.8732 25.9327 30.8732 26.7485H30.8766ZM26.8031 19.8741C25.9003 19.8473 25.0012 19.9986 24.1569 20.3193C23.3126 20.64 22.5397 21.1238 21.8824 21.7432C21.2251 22.3626 20.6962 23.1053 20.326 23.9291C19.9558 24.7529 19.7514 25.6415 19.7246 26.5443C19.6978 27.447 19.849 28.3462 20.1697 29.1905C20.4904 30.0348 20.9743 30.8077 21.5936 31.465C22.213 32.1223 22.9558 32.6511 23.7796 33.0214C24.6033 33.3916 25.492 33.596 26.3947 33.6228H33.6229V26.8301C33.6229 23.039 30.5942 19.9014 26.8031 19.8741Z"
        fill="#20EE82"
      />
      <path
        d="M36.4714 17.0277C34.2308 14.787 31.285 13.389 28.1323 13.0702C24.9796 12.7515 21.8136 13.5316 19.1698 15.2785C19.3246 15.4495 19.4572 15.6393 19.5646 15.8434C22.077 14.1943 25.0805 13.4605 28.0703 13.7655C31.06 14.0704 33.8536 15.3954 35.9813 17.5177C41.0724 22.602 41.0724 30.8852 35.9813 35.9763C30.8902 41.0674 22.607 41.0674 17.5159 35.9763C15.3947 33.8477 14.0709 31.0537 13.7672 28.064C13.4635 25.0743 14.1985 22.0712 15.8484 19.5596C15.6442 19.4524 15.4543 19.3198 15.2835 19.1648C11.0942 25.4947 12.8332 34.0277 19.163 38.2169C25.4929 42.4062 34.0259 40.6672 38.2151 34.3373C39.9648 31.6935 40.747 28.5261 40.4293 25.3716C40.1115 22.2172 38.7132 19.2694 36.4714 17.0277Z"
        fill="#7F8C8D"
      />
      <path
        d="M16.1615 16.1559C16.3533 15.9632 16.598 15.8317 16.8645 15.778C17.1311 15.7244 17.4076 15.7509 17.6591 15.8544C17.9105 15.9578 18.1257 16.1335 18.2773 16.3592C18.4289 16.585 18.5102 16.8506 18.5108 17.1225C18.5115 17.3944 18.4315 17.6604 18.281 17.8868C18.1305 18.1133 17.9162 18.29 17.6653 18.3947C17.4143 18.4994 17.138 18.5273 16.8712 18.475C16.6043 18.4226 16.359 18.2923 16.1663 18.1005L16.1615 18.0957C15.9051 17.8381 15.7611 17.4894 15.7611 17.1258C15.7611 16.7623 15.9051 16.4136 16.1615 16.1559Z"
        fill="#20EE82"
      />
    </g>
    <defs>
      <clipPath id="clip0_4621_5577">
        <rect width="27.4993" height="27.5" fill="white" transform="translate(13 13)" />
      </clipPath>
    </defs>
  </svg>
)

export default SIAIcon
