import styled from 'app-view/styles/styled'
import { ExploreView } from 'pages/ExploreView'
import { NotFound } from 'pages/NotFound'
import { Route, Routes } from 'react-router-dom'
import { AddDeviceAccount, AddDeviceSuccess, AddDeviceWebAuthNCred } from './routes/add-device'
import {
  CreateAccount,
  CreateAccountKYC,
  CreateAccountSuccess,
  CreateAccountWebAuthNCred,
} from './routes/create-account'
import { Account } from './routes/login-device'
import {
  AdminMigrateDeviceAccount,
  MigrateDeviceAccount,
  MigrateDeviceSuccess,
} from './routes/migrate-account'
import {
  RegisterAccount,
  RegisterAccountKYC,
  RegisterAccountSuccess,
} from './routes/register-account'

const Container = styled.section`
  flex: 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  background: #f7f8f9;
  width: 100%;
  min-height: 100%;
  height: max-content;
  padding: 36px 44px 35px;

  * {
    width: 100%;
    white-space: pre-line;
    line-height: 1.33;
  }

  p > b {
    width: max-content;
  }
`

// keeps routes in sync with code in hooks/useNavigation
export const AccountView = () => (
  <Container>
    <Routes>
      <Route path="account" element={<Account />} />
      <Route path="create-account" element={<CreateAccount />} />
      <Route path="create-account/webauthn-cred" element={<CreateAccountWebAuthNCred />} />
      <Route path="create-account/kyc" element={<CreateAccountKYC />} />
      <Route path="create-account/success" element={<CreateAccountSuccess />} />
      <Route path="register-account" element={<RegisterAccount />} />
      <Route path="register-account/kyc" element={<RegisterAccountKYC />} />
      <Route path="register-account/success" element={<RegisterAccountSuccess />} />
      <Route path="add-device/account" element={<AddDeviceAccount />} />
      <Route path="migrate" element={<MigrateDeviceAccount />} />
      <Route path="migrate/success" element={<MigrateDeviceSuccess />} />
      <Route path="admin-migrate" element={<AdminMigrateDeviceAccount />} />
      <Route path="add-device/webauthn-cred" element={<AddDeviceWebAuthNCred />} />
      <Route path="add-device/success" element={<AddDeviceSuccess />} />
      <Route path="explore" element={<ExploreView />} />
      <Route path="bitlauncher" element={<ExploreView />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </Container>
)
