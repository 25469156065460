import { xLog } from 'app-engine/library/utils'
import { P2POffer } from 'app-engine/store/p2p-slice'
import {
  ModalButtonsContainer,
  ModalCancelButton,
  ModalConfirmButton,
  ModalText,
} from 'app-view/components/Modal'
import { Loader } from 'app-view/components/Spinner'
import { WizardStepProps } from 'app-view/components/Wizard'
import styled from 'app-view/styles/styled'
import { useTakeOffer } from 'pages/P2PView/hooks/useTakeOffer'
import { useTranslation } from 'react-i18next'

const CancelButton = styled(ModalCancelButton)<{ wideActions?: boolean }>`
  max-width: ${(props) => (props.wideActions ? '230px' : '150px')}};
`
const ConfirmButton = styled(ModalConfirmButton)<{ wideActions?: boolean }>`
  max-width: ${(props) => (props.wideActions ? '230px' : '150px')}};
`

export const Confirmation = ({ close }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const [
    { confirmationText, confirmationButtonText, offer, loading },
    { createOffer, setError, setLoading },
  ] = useTakeOffer()
  const onConfirm = async () => {
    setLoading(true)
    try {
      await createOffer({
        ...(offer as P2POffer),
        type: offer?.type === 'buy' ? 'sell' : 'buy',
      })
    } catch (error) {
      xLog({
        ERROR:
          '————————— globalModalActions.openConfirm from OfferTrustGlobalNetwork.tsx > handleOffer  —————————',
        error: error as Error,
      })

      setError(`We couldn't take the offer.

      ${(error as Error).message || ''}`)
    } finally {
      setLoading(false)
    }
  }

  if (loading)
    return (
      <Loader
        text={`${t(offer?.type === 'sell' ? 'p2p:creating_buy_offer' : 'p2p:creating_sell')} ...`}
      />
    )

  return (
    <div>
      <ModalText>{confirmationText}</ModalText>
      <ModalButtonsContainer
        /* @ts-ignore */
        style={{
          flexDirection: 'column-reverse',
          flexWrap: 'wrap',
        }}
      >
        {/* @ts-ignore */}
        <CancelButton
          wideActions
          onClick={() => {
            close()
          }}
        >
          {t('cancel')}
        </CancelButton>
        <ConfirmButton
          wideActions
          isDestructive={offer?.type !== 'sell'}
          onClick={() => {
            onConfirm()
          }}
        >
          {confirmationButtonText}
        </ConfirmButton>
      </ModalButtonsContainer>
    </div>
  )
}
