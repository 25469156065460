import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalError } from './Modal'

interface ErrorFallbackProps {
  resetErrorBoundary: () => void
}

export const ErrorFallback = ({ resetErrorBoundary }: ErrorFallbackProps) => {
  const { t } = useTranslation(['global'])
  return <ModalError resetError={resetErrorBoundary} error={t('global:generic_error')} />
}
