import { config } from 'app-config'

export const lookupValidPhoneNumber = async (phone: string): Promise<PhoneLookupAPIResponse> =>
  await new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${config.services.phoneLookup.host}${phone}`, {
        cache: 'no-store',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          apiKey: config.services.phoneLookup.apiKey,
        },
      }).then((res) => res.json())

      return resolve({ data: response, error: null }) as unknown as PhoneLookupAPIDataResponse
    } catch (error) {
      return reject({ data: null, error }) as unknown as Error
    }
  })

export type PhoneLookupAPIResponse = {
  data: PhoneLookupAPIDataResponse | null
  error: Error | null
}

export interface PhoneLookupAPIDataResponse {
  valid: boolean
  number: string
  local_format: string
  international_format: string
  country_prefix: string
  country_code: string
  country_name: string
  location: string
  carrier: string
  line_type: string
}
