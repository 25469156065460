import { motion } from 'framer-motion/dist/framer-motion'
import styled from 'app-view/styles/styled'
import { TimesIcon } from 'app-view/components/icons'
import { scrollbar_css, scrollbar_css_access } from 'app-view/components/Styled'
import { ChatGlobeProps } from '../types'

const ModalHeaderChat = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #29e0ad;
  border-radius: 10px 10px 0 0;
  padding: 0 45px;
  height: 3.75rem;
  font-size: 1.125rem;
  color: #fff;

  button {
    padding: 10px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`
const ContainerModalChat = styled.div`
  height: calc(100% - 3.75rem);
  background-color: #fff;
  padding: 10px 8px;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border-radius: 0 0 10px 10px;

  .container-modal {
    &__input {
      overflow-y: hidden;
      min-height: 40px;
      height: auto;
      max-height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      box-sizing: border-box;
      font-size: 0.9375rem !important;

      > button {
        margin: 0;
      }
    }
    &__textarea textarea,
    &__messages {
      font-size: 0.9375rem !important;
      ${scrollbar_css}

      &::-webkit-scrollbar-thumb {
        background: #eaeaea90;
        border: none;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #eaeaea;
      }

      &:focus {
        outline: none;
      }
    }
    &__textarea {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid #8e8e9370 !important;
      padding: 4px 3px 4px 12px;
      border-radius: 16px;
      min-height: 35px;
      height: auto;
      max-height: 80px;
      width: 85%;
      box-sizing: border-box;

      textarea {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background: #fff4;
        mix-blend-mode: normal;
        box-sizing: border-box;
        border: none;
        padding: 0 9px 0 0;
        line-height: 1.5;
        font-size: 0.9375rem;
        font-smoothing: subpixel-antialiased;

        ${scrollbar_css_access}
      }
    }
    &__messages {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      height: auto;
      max-height: calc(100% - 35px);
      width: 100%;
    }
  }
`
const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  padding: 0;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 45%;

    button {
      min-width: 45% !important;
    }
  }
`

const SessionStatusContainer = styled.span`
  position: relative;
  display: flex;
  justify-content: end;
`

const ActivityContainer = styled.span`
  position: relative;
  top: -22px;
  right: -9px;
`

const StyledTimesIcon = styled(TimesIcon)`
  stroke: #fff;
  width: 14px;
  height: 14px;

  g,
  path {
    fill: #fff;
  }
`
const ChatGlobe = styled(motion.div)<ChatGlobeProps>`
  background: ${({ isMe }) => (isMe ? 'rgba(41, 224, 173, 0.2)' : 'rgba(204, 204, 204, 0.2)')};
  width: 75.89%;
  margin: ${({ isMe }) => (isMe ? '8px 0 8px auto' : '8px auto 8px 0')};
  padding: 12px 6px;
  line-height: 1.5;
  font-size: 0.9375rem;

  p,
  footer {
    user-select: text;
  }

  footer {
    font-size: 0.8125rem;
    font-weight: bolder;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    small {
      opacity: 0.9133;
      font-weight: 300;
      margin-left: auto;
    }
  }
  p {
    color: #0f121f;
    white-space: pre-line;
  }
`

export {
  ContainerModalChat,
  ContainerButton,
  ChatGlobe,
  SessionStatusContainer,
  ActivityContainer,
  ModalHeaderChat,
  StyledTimesIcon,
}
