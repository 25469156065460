import { Schema } from '@colyseus/schema'
import * as Sentry from '@sentry/react'
import { Client, Room } from 'colyseus.js'
import { useSyncExternalStore } from 'react'

import { parseJwt } from 'app-engine/library/utils'
import { checkSessionStatus } from 'app-engine/store/auth-slice'
import { version } from '../../../../package.json'
import { store } from './store'

const logConnectionError = (error: Error, scope: string, roomName: string) => {
  const decodedToken = localStorage.getItem('bitcash_session')
    ? parseJwt(localStorage.getItem('bitcash_session'))
    : {}
  const username = decodedToken?.user?.account
  const message = `UseRealTime::Colyseus::ERROR::Failed to ${scope} to Colyseus room ${
    roomName ? roomName : 'NO ROOM NAME'
  }, ${username ? username : ''}`
  console.error(message, error, decodedToken)
  if (error instanceof Error) {
    Sentry.captureException({
      ...error,
      name: message,
      message: error.message,
      extras: {
        version,
        transactionErrorDetails: { ...error },
      },
      user: {
        username,
        roomName,
        exp: decodedToken?.exp,
        iat: decodedToken?.iat,
      },
    })
  }
}

export const colyseus = <S = Schema>(endpoint: string, schema?: new (...args: unknown[]) => S) => {
  const client = new Client(endpoint)
  const roomStore = store<Room<S> | undefined>(undefined)
  const messageStore = store<any>(null)
  const stateStore = store<S | undefined>(undefined)
  const isOnlineStore = store<boolean>(false)

  let connecting = false
  let roomName = ''

  const startColyseusConnection = async (options = {}) => {
    if (!roomName) throw new Error('Room name is required')
    const authToken = localStorage.getItem('bitcash_session') || ''
    client.auth.token = authToken
    const room = await client.joinOrCreate<S>(roomName, { ...options, authToken }, schema)
    await setupRoom(room)
    isOnlineStore.set(true)
  }

  const tryReconnect = async () => {
    try {
      const room = roomStore.get()
      if (!room || roomName) return false
      await client.reconnect<S>(room.reconnectionToken)
      return true
    } catch (error) {
      logConnectionError(error, 'tryReconnect', roomName)
      return false
    }
  }

  const restartConnection = async (options = {}) => {
    try {
      await startColyseusConnection(options)
      return true
    } catch (error) {
      logConnectionError(error, 'restartConnection', roomName)
      throw error
    }
  }

  const retryConnection = async (maxAttempts = 100) => {
    const delay = 1000
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      const authToken = localStorage.getItem('bitcash_session') || ''
      console.log(`Attempt ${attempt} to reconnect or start a new connection...`)
      const decodedJwt = parseJwt(authToken)
      if (!authToken || !decodedJwt || checkSessionStatus(decodedJwt) === 'expired') {
        console.log('No auth token found or session expired, skipping connection attempt.')
        await new Promise((resolve) => setTimeout(resolve, delay))
        return
      }
      if (await tryReconnect()) {
        console.log('Successfully reconnected!')
        return
      }
      try {
        await restartConnection()
        console.log('Successfully connected!')
        return
      } catch (error) {
        if (error.message.includes('seat reservation expired')) {
          console.log('Seat reservation expired, attempting immediate reconnection...')
          await new Promise((resolve) => setTimeout(resolve, 500)) // Almost immediately try to reconnect without waiting
          continue
        }
      }
      console.log(`Waiting ${delay}ms before next attempt...`)
      await new Promise((resolve) => setTimeout(resolve, delay))
    }
    const message = `Failed to establish a connection after ${maxAttempts} attempts.`
    console.error(message)
    logConnectionError(
      new Error(`Failed to establish a connection after ${maxAttempts} attempts.`),
      'retryConnection',
      roomName,
    )
  }

  const connectToColyseus = async (pRoomName: string, maxAttempts = 5000) => {
    const previousRoom = roomStore.get()
    if (previousRoom) {
      if (previousRoom.name === pRoomName) {
        console.log(
          'Already connected to Colyseus room',
          pRoomName,
          'disconnecting and reconnecting...',
        )
        await disconnectFromColyseus()
      }
    }
    console.log(
      `Connecting to Colyseus room ${roomName} at ${endpoint}...`,
      'there is a previous room: ',
      Boolean(previousRoom),
    )
    if (connecting || roomStore.get()) return

    connecting = true
    roomName = pRoomName
    await retryConnection(maxAttempts)
    connecting = false
  }

  const setupRoom = async (room: Room<S>) => {
    roomStore.set(room)
    stateStore.set(room.state)

    const updatedCollectionsMap: { [key in keyof S]?: boolean } = {}

    for (const [key, value] of Object.entries(room.state as Schema)) {
      if (typeof value !== 'object' || !value.clone || !value.onAdd || !value.onRemove) {
        continue
      }

      updatedCollectionsMap[key as keyof S] = false

      value.onAdd(() => {
        updatedCollectionsMap[key as keyof S] = true
      })

      value.onRemove(() => {
        updatedCollectionsMap[key as keyof S] = true
      })
    }

    room.onMessage('*', (type, message) => {
      // console.log(`Received message of type ${type}:`, message)
      messageStore.set({ type, message })
    })

    room.onStateChange((state) => {
      if (!state) return

      const copy = { ...state }

      for (const [key, update] of Object.entries(updatedCollectionsMap)) {
        if (!update) continue

        updatedCollectionsMap[key as keyof S] = false

        const value = state[key as keyof S] as unknown

        if ((value as Schema).clone) {
          //@ts-ignore
          copy[key as keyof S] = value.clone()
        }
      }

      stateStore.set(copy)
    })

    room.onLeave(async (code) => {
      console.log('left', room.name, code)
      roomStore.set(undefined)
      stateStore.set(undefined)
      isOnlineStore.set(false)
      // Attempt to reconnect after a delay
      await retryConnection() // Retry every 5 seconds, up to 100 attempts
    })

    // console.log(`Successfully connected to Colyseus room ${room.name} at ${endpoint}`)
  }

  const disconnectFromColyseus = async () => {
    const room = roomStore.get()
    if (!room) return

    roomStore.set(undefined)
    stateStore.set(undefined)

    try {
      await room.leave()
      console.log('Disconnected from Colyseus!')
    } catch (error) {
      logConnectionError(error, 'disconnectFromColyseus', roomName)
    }
  }

  const useColyseusRoom = () => {
    const subscribe = (callback: () => void) => roomStore.subscribe(() => callback())

    const getSnapshot = () => {
      const colyseus = roomStore.get()
      return colyseus
    }

    return useSyncExternalStore(subscribe, getSnapshot)
  }

  const useIsOnline = () => {
    const subscribe = (callback: () => void) => isOnlineStore.subscribe(() => callback())

    const getSnapshot = () => {
      const isOnline = isOnlineStore.get()
      return isOnline
    }

    return useSyncExternalStore(subscribe, getSnapshot)
  }

  const useColyseusMessage = () => {
    const subscribe = (callback: () => void) => messageStore.subscribe(() => callback())

    const getSnapshot = () => {
      const message = messageStore.get()
      return message
    }

    return useSyncExternalStore(subscribe, getSnapshot)
  }

  function useColyseusState(): S | undefined
  function useColyseusState<T extends (state: S) => unknown>(selector: T): ReturnType<T> | undefined
  function useColyseusState<T extends (state: S) => unknown>(selector?: T) {
    const subscribe = (callback: () => void) =>
      stateStore.subscribe(() => {
        callback()
      })

    const getSnapshot = () => {
      const state = stateStore.get()
      return state && selector ? selector(state) : state
    }

    return useSyncExternalStore(subscribe, getSnapshot)
  }

  return {
    client,
    connectToColyseus,
    disconnectFromColyseus,
    useColyseusRoom,
    useColyseusState,
    useIsOnline,
    useColyseusMessage,
  }
}
