import React from 'react'
import styled from 'app-view/styles/styled'
import { useStore } from 'app-engine/store'
import { ChatProps } from 'app-view/components/Chat/types'
import { useChat } from 'app-view/components/Chat/hooks/useChat'
import Chat from 'app-view/components/Chat'
import { ChatSupportIcon } from 'app-view/components/icons'
import { config } from 'app-config'
import { ButtonTransparent } from 'app-view/components/buttons/ButtonTransparent'

export const SupportChannel: React.FC = () => {
  const account = useStore.useAccount()
  const [, { onOpen }] = useChat()

  const chatParticipants: ChatProps = {
    from: account,
    to: config.bitcashAdmin,
    id: {
      type: 'support',
    },
  }

  const openSupportChat = () => onOpen()

  return (
    <>
      <SupportIconWrapper>
        <ButtonTransparent onClick={openSupportChat}>
          <ChatSupportIcon />
        </ButtonTransparent>
      </SupportIconWrapper>
      <Chat {...chatParticipants} />
    </>
  )
}

const SupportIconWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    padding: 0;

    svg {
      width: 100px;
      height: 100%;
    }
  }
`
