import { SendNotificationProps } from 'app-engine/types/notifications'
import registerReactServiceWorker from 'react-service-worker'
import { config } from 'app-config'
import { apolloClient } from '../graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'

export const bitcashNotificationReadMutation = async (id: string) =>
  await apolloClient.mutate({
    mutation: Bitcash.BitcashNotificationReadDocument,
    variables: {
      id,
    },
  })

export const requestNotificationPermission = async () => {
  try {
    return new Promise((resolve, reject) => {
      const permission_result = Notification.requestPermission((result) => {
        resolve(result)
      })

      if (permission_result) permission_result.then(resolve, reject)
    }).then((result) => {
      if (result !== 'granted') {
        console.log("We weren't granted permission.")
        // throw new Error("We weren't granted permission.")
      }

      return true
    })
  } catch (e) {
    console.log('Permission denied: ', e)
    return false
  }
}

export const sendNotification = async ({
  notification,
  message,
  content,
}: SendNotificationProps) => {
  const isP2P = notification.type.includes('P2P')
  const redirect_p2p_link =
    isP2P && (content?.data?.completed || content?.data?.cancelled) ? 'history?tab=p2p' : 'p2p'
  const redirect_link = isP2P ? redirect_p2p_link : 'history?tab=coins'
  const redirect_to = config.bitcashAdmin === notification.to ? 'admin' : redirect_link
  const title = isP2P ? 'Bitcash App | P2P Offer' : 'Bitcash App | Swap Order'

  try {
    const new_notification = new Notification(title, {
      body: message,
      icon: '/images/icons/bitcash_icon_512.png',
    })

    new_notification.onclick = async (event) => {
      event.preventDefault() // prevent the browser from focusing the Notification's tab

      try {
        await bitcashNotificationReadMutation(notification.id)
      } catch (error) {
        console.log('[OPENING NEW NOTIFICATION]', error)
      } finally {
        window.open(
          `${window.location.origin}/${redirect_to}${
            redirect_to.includes('?') ? '&' : '?'
          }content_id=${notification.content_id}`,
        )
      }
    }

    return new Promise((resolve, reject) => {
      try {
        resolve(new_notification)
      } catch (error) {
        console.log('[[ ERROR SERVICE WORKER ]]', error)
        reject(error)
      }
    }) as Promise<Notification>
  } catch (error) {
    console.error('[[ ERROR SERVICE WORKER - CREATING NEW NOTIFICATION ]]', error)

    return null
  }
}

export const registerServiceWorker = async () => {
  const swRegistration = await registerReactServiceWorker()
  return swRegistration
}
