import { MutationResult } from '@apollo/client'
import { createContextHook } from '@blockmatic/hooks-utils'
import * as Sentry from '@sentry/react'
import { TransactResult } from 'anchor-link'
import { config } from 'app-config'
import { apolloClient } from 'app-engine/graphql/apollo-client'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { getCurrencyData } from 'app-engine/library/currency-countries'
import { SmartContractError } from 'app-engine/library/errors'
import { hideBkExt, validateGraphqlError } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { SendTransaction2Response } from 'app-engine/store/eos-slice'
import { NotificationTypeEnum } from 'app-engine/types/notifications'
import { P2p_Offers_Step } from 'app-engine/types/p2pOffers'
import {
  LocalBankRegionsData,
  LocalBankSubregionsKeys,
  MethodOption,
  MethodsProps,
  P2PPaymentMethodsActions,
} from 'app-engine/types/p2pPaymentMethods'
import { useWizard } from 'app-view/components/Wizard'
import { WizardStep } from 'app-view/components/Wizard/types'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import { useLoggedUser } from 'app-view/hooks/use-realtime/use-logged-user'
import { findTokenBySymbolCode } from 'app-view/hooks/use-token-prices'
import {
  bank_transfer_data,
  countries_whitelist,
  getDAOAmountFee,
  hasAdminPermissions,
  isMethodWithDetails,
  regions_whitelist,
  sellerAssetAmountLessP2PFee,
} from 'app-view/lib/utils'
import isEqual from 'lodash.isequal'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useSetState } from 'react-use'
import { BUY_STEPS } from '../steps/buy-steps'
import { SELL_STEPS } from '../steps/sell-steps'
import { reserveOffer, unreserveOffer } from 'app-engine/services'

type UseP2POfferState = {
  offer: P2p_Offers_Step
  retryGetOfferId: boolean
  loading: boolean
  error: string
  submitted: boolean
  paymentOptions: MethodOption[]
  region_country_options: MethodOption[]
}

export type SerializedProfilePaymentMethods = {
  account: string
  methods: SerializedProfilePaymentMethod[]
}

export type SerializedProfilePaymentMethod = {
  method: string
  details: string
}

export type BankTransferDetails = {
  fullName: string
  accountNumber: string
  accountName: string
}

export type PhoneTransferDetails = {
  phoneNumber: string
  fullName?: string
}

export type PaymentMethodDetails = Partial<
  Record<'fullName' | 'accountName' | 'accountNumber' | 'phoneNumber', string>
>

export type DeserializedProfilePaymentMethod = {
  method: string
  details: PaymentMethodDetails
}

const initialState: UseP2POfferState = {
  // @ts-ignore // TODO: fix type
  offer: {
    bitcashbank_id: '',
    type: '',
    matched: false,
    method: '',
    buyer_method_details: '',
    seller_method_details: '',
    region: '',
    amount: '0.00 BITUSD',
    buyer_confirmed_payment: false,
    seller_confirmed_payment: false,
    created_at: '',
    id: '',
    cancelled: false,
    completed: false,
    initiator: '',
    updated_at: '',
  },
  retryGetOfferId: false,
  loading: false,
  error: '',
  submitted: false, // will be always false unless just initiated
  paymentOptions: [],
  region_country_options: [],
}

type OfferActionResponse = Promise<{
  success: boolean
}>
interface UseP2POfferActions extends P2PPaymentMethodsActions {
  updateOffer: (offerUpdate: any) => void
  setNewOffer: (type: 'sell' | 'buy') => void
  submitOffer: (offerProps?: any) => OfferActionResponse
  cancelOffer: () => OfferActionResponse
  autoCancelOffer: () => OfferActionResponse
  confirmPayment: () => OfferActionResponse
  resetError: () => void
  startNewOffer: (type: 'sell' | 'buy') => WizardStep[]
  setError: (error: string) => void
  setRegionCountryOptions: () => MethodOption[]
  setMethodOptions: () => Promise<MethodOption[]>
}

export type P2PConfirmPaymentVariablesTypes = Bitcash.Exact<{
  id: string
  buyer?: Bitcash.InputMaybe<string> | undefined
  seller?: Bitcash.InputMaybe<string> | undefined
  completed?: boolean | undefined
}>

const baseMethod = ['Bank Transfer']
export const methods_props: MethodsProps = {
  base: baseMethod,
  US: ['Cash App', 'PayPal'],
  CH: ['WeChat', 'Alipay'],
  GB: ['Revolut'],
  ES: ['Bizum'],
  DE: ['Lydia', 'Revolut', 'Lyf'],
  IT: ['Postepay'],
  FR: ['Lydia', 'Revolut', 'Lyf'],
  MY: ['Grab', 'DuitNow'],
  VN: ['Grab'],
  PH: ['Grab'],
  TH: ['Grab'],
  ID: ['Grab', 'Gojek', 'OVO'],
  NG: ['OPay', 'Kuda', 'PalmPay'],
  CR: ['SINPE Movil'],
  MX: ['OXXO'],
}

export const getCountryMethodsByCountry = (country: string): string[] => {
  const country_methods = (methods_props[country as keyof MethodsProps] || [])
    .concat(baseMethod)
    .filter((method) => isMethodWithDetails(method))
  return country_methods.map((method) => method.toLowerCase().split(' ').join('_'))
}

const mutated_region_props = {
  region: {},
}

Object.keys(bank_transfer_data.region).forEach((key) => {
  if (!regions_whitelist.some((region) => region === key)) return

  const data_key = key as keyof LocalBankSubregionsKeys

  mutated_region_props.region[data_key] = bank_transfer_data.region[data_key]
})

const getPreSellOffer = async (account: string) => {
  try {
    const { data, errors } = await apolloClient.query<
      Bitcash.BitcashPresellOffersQuery,
      Bitcash.BitcashPresellOffersQueryVariables
    >({
      query: Bitcash.BitcashPresellOffersDocument,
      variables: { account },
    })
    if (errors || !data) throw new Error(errors![0].message)
    return data.p2p_pre_sell_offers[0]
  } catch (error) {
    console.log('did not get swap notifications data => ', (error as Error).message)
    return undefined
  }
}

export const getParsedPaymentMethods = (account: string) => {
  try {
    const savedMethods = localStorage.getItem(`p2p_profile_payment_methods_${account}`)
    const paymentMethods: SerializedProfilePaymentMethod[] = savedMethods
      ? JSON.parse(savedMethods)
      : null
    const parsedMethods =
      paymentMethods?.reduce((acc, method) => {
        try {
          return [
            ...acc,
            {
              method: method.method,
              details: JSON.parse(method.details) as PaymentMethodDetails,
            },
          ]
        } catch (e) {
          console.error(e)
          return acc
        }
      }, [] as DeserializedProfilePaymentMethod[]) || []
    return parsedMethods
  } catch (e) {
    console.error(e)
    return []
  }
}

export const saveProfilePaymentMethods = (
  account: string,
  newMethod: DeserializedProfilePaymentMethod,
) => {
  const prevMethods: DeserializedProfilePaymentMethod[] = getParsedPaymentMethods(account)
  const prevMethod = prevMethods.find(
    (method) =>
      method.method === newMethod.method &&
      method.method !== 'bank_transfer' &&
      method.details === newMethod.details,
  )

  if (prevMethod) {
    prevMethod.details = newMethod.details
  } else {
    prevMethods.push(newMethod)
  }
  const newMethods = prevMethods.map((method) => ({
    method: method.method,
    details: JSON.stringify(method.details),
  }))
  localStorage.setItem(`p2p_profile_payment_methods_${account}`, JSON.stringify(newMethods))
}

export const deleteProfilePaymentMethod = (
  account: string,
  details: BankTransferDetails | PhoneTransferDetails,
) => {
  const prevMethods: DeserializedProfilePaymentMethod[] = getParsedPaymentMethods(account)
  const filteredMethods = prevMethods.filter(
    (m) => JSON.stringify(m.details) !== JSON.stringify(details),
  )
  const newMethods = filteredMethods.map((method) => ({
    method: method.method,
    details: JSON.stringify(method.details),
  }))
  localStorage.setItem(`p2p_profile_payment_methods_${account}`, JSON.stringify(newMethods))
}

const createSellOfferObservable = (txId: string) =>
  apolloClient.subscribe<
    Bitcash.FetchP2PSaleOfferSubscription,
    Bitcash.FetchP2PSaleOfferSubscriptionVariables
  >({
    query: Bitcash.FetchP2PSaleOfferDocument,
    variables: {
      sell_put_transaction: txId,
    },
    fetchPolicy: 'no-cache',
  })

type SellOffer = Bitcash.FetchP2PSaleOfferSubscription['p2p_offers'][number]

const fetchSellOffer = async (txId: string): Promise<SellOffer> => {
  const sellOfferObservable = createSellOfferObservable(txId)
  console.log('[fetchSellOffer] observable created', txId)

  const timeout = 60000 // 1 minute

  return new Promise((resolve, reject) => {
    // Track the start time
    const startTime = Date.now()

    // Subscribe to the observable
    const sellOfferSubscription = sellOfferObservable.subscribe(({ data, errors }) => {
      try {
        validateGraphqlError(errors)
        if (data && data.p2p_offers && data.p2p_offers.length > 0) {
          sellOfferSubscription.unsubscribe()
          resolve(data?.p2p_offers[0]) // Resolve the promise with the expected offer
        }
      } catch (error) {
        console.log('[ERROR][fetchSellOffer]', (error as Error).message)
        sellOfferSubscription.unsubscribe() // Ensure to clean up the subscription
        reject(error) // Reject the promise with the caught error
      }
    })

    // Set a timeout to handle cases where a response is not received within the time limit
    setTimeout(() => {
      const elapsedTime = Date.now() - startTime
      if (elapsedTime >= timeout) {
        sellOfferSubscription.unsubscribe() // Unsubscribe to clean up the subscription
        reject(new Error('Time limit reached.')) // Reject the promise with a timeout error
      }
    }, timeout)
  })
}

export const useP2POfferFn = (): [UseP2POfferState, UseP2POfferActions] => {
  const { account, verifyBitcashbankRegistration, pushTransaction, authErrorFallback } = useStore()
  const refreshSession = useStore.useRefreshSession()
  const countriesData = bitcashRoom.useColyseusState((state) => state.countryData)

  const { t } = useTranslation(['p2p', 'global', 'errors'])
  const [{ open }, { close }] = useWizard()
  const [state, setState] = useSetState(initialState)
  const [confirmP2pOffer] = Bitcash.useBitcashConfirmP2PPaymentMutation()
  const [bitcashInsertNotificationMutation] = Bitcash.useBitcashInsertNotificationMutation()
  const [makeP2PBuyOffer] = Bitcash.useMakeP2PBuyOfferMutation()
  const [makeP2PPreSellOffer] = Bitcash.useMakeP2PPreSellOfferMutation()
  const [cancelP2POffer] = Bitcash.useCancelP2POfferMutation()
  const [cancelApproveP2POffer] = Bitcash.useCancelApproveP2POfferMutation()
  const [autoCancelP2POffer] = Bitcash.useAutoCancelP2POfferMutation()
  const token = findTokenBySymbolCode('BITUSD')
  const location = useLocation()

  const countryDataLoggedUser = useLoggedUser()

  useEffect(() => {
    if (open) {
      setState({ error: '' })
    }
  }, [open, setState])

  // useP2PPaymentMethods Inclusion Starts

  const setRegionCountryOptions = () => {
    if (state.region_country_options.length > 0) return state.region_country_options

    const region_array: string[] = []
    const country_array: string[] = []

    // Regions first
    Object.keys(mutated_region_props.region).forEach((region) => {
      region_array.push(`${region}`)
    })

    // After pushing Regions, we push countries
    Object.keys(mutated_region_props.region).forEach((region) => {
      const region_key = region as keyof LocalBankRegionsData
      const key = region_key as keyof typeof mutated_region_props.region
      // @ts-ignore // TODO: fix type
      Object.keys(mutated_region_props.region[key].subregion).forEach((subregion) => {
        const subregion_key: keyof LocalBankSubregionsKeys =
          subregion as keyof LocalBankSubregionsKeys

        mutated_region_props.region[region_key].subregion[subregion_key].forEach(
          (country: string) => {
            if (!countries_whitelist.some((wl_country) => wl_country === country)) return

            country_array.push(`<${region}-><${subregion}->${country}`)
          },
        )
      })
    })

    // NOTE: If we want to sort by subregion, sort country_array before set_countries_opt

    const set_region_options = region_array.map((option) => ({
      label: option.replace(/_/g, ' ').replace(/<\/?[^>]+>/g, ''),
      value: option.toLocaleLowerCase().replace(/\s/g, '_').replace(/(<|>)/g, ''),
    }))
    const set_countries_options = country_array.map((option) => ({
      label: option.replace(/_/g, ' ').replace(/<\/?[^>]+>/g, ''),
      value: option.toLocaleLowerCase().replace(/\s/g, '_').replace(/(<|>)/g, ''),
    }))

    const sorted_set_countries_options = set_countries_options.sort((a, b) =>
      a.label.localeCompare(b.label),
    )

    setState({ region_country_options: [...set_region_options, ...sorted_set_countries_options] })

    return [...set_region_options, ...set_countries_options]
  }

  const setMethodOptions = async () => {
    const offerAmountSymbol = state.offer.amount.split(' ')[1].replace('BIT', '')
    if (!countriesData) return []
    // ? We first get currency country code, so we can check it against the available payment method by local currency
    const countryCurrencyData = await getCurrencyData(offerAmountSymbol, countriesData)
    const currencyCountryCode = countryCurrencyData.cca2
    // ? We check if the user is outside of the US, so we can show the international payment methods (base method only)
    const isUserOutsideOfUS = Boolean(countryDataLoggedUser?.countryCode !== 'US')
    const isInternationalCurrency =
      Boolean(offerAmountSymbol.match(/(USD|EUR)/g)) && isUserOutsideOfUS

    // ? We map the payment methods based on the currency country code and user's current location
    const method_array: string[] = !isInternationalCurrency
      ? [...(methods_props[currencyCountryCode] || []), ...methods_props.base]
      : // ? If the user is outside of the US, we only show the base payment methods along with current user location payment methods (if any for USD/EUR)
        [
          ...(methods_props[countryDataLoggedUser?.countryCode as string] || []),
          ...methods_props.base,
        ]

    const set_options = method_array.map((option) => ({
      label: option,
      value: option.toLocaleLowerCase().replace(/\s/g, '_'),
    }))

    setState({ paymentOptions: set_options })

    return set_options
  }

  const methodLabel = (method: string) => {
    const method_label =
      state.paymentOptions.find(({ value }) => value === method)?.label || method.replace('_', ' ')

    return method_label
      .split(' ')
      .map(
        (method) => `${method.substring(0, 1).toUpperCase()}${method.substring(1, method.length)}`,
      )
      .join(' ')
  }

  const formatRegion = (region: string) => {
    if (!region) return ''

    const option_label =
      state.region_country_options.find(({ value }) => value === region)?.label || ''
    const formatted_region = `${option_label.substring(0, 1).toUpperCase()}${option_label.substring(
      1,
      option_label.length,
    )}`

    return formatted_region
  }

  // useP2PPaymentMethods Inclusion Ends

  const resetError = () => {
    if (state.error.match(t('zero_balance'))) {
      close()
    }

    setState({
      error: '',
    })
  }

  const updateOffer = (offerUpdate: any) => {
    setState((prevState) => {
      const updatedOffer = { ...prevState.offer, ...offerUpdate }
      if (updatedOffer.region && prevState.region_country_options.length === 0)
        setRegionCountryOptions()
      if (!isEqual(prevState.offer, updatedOffer)) {
        return { offer: { ...updatedOffer } }
      } else return prevState
    })
  }

  const setNewOffer = (type: any) => {
    const role = type === 'sell' ? 'seller' : 'buyer'
    const newOffer = { ...initialState.offer, type, [role]: account, initiator: account }
    setState((prevState) => ({ offer: { ...prevState.offer, ...newOffer } }))
  }

  const pushP2PTransaction = async (name: 'sellp2p' | 'cancelp2p' | 'confirmp2p', data: any) => {
    const isAdmin =
      hasAdminPermissions(account) && name === 'cancelp2p' && location.pathname.includes('/admin')

    let response = null

    try {
      const transaction = {
        actions: [
          {
            account: config.contracts.bitcashBank,
            name,
            authorization: [
              isAdmin
                ? {
                    actor: config.contracts.bitcashBank,
                    permission: 'p2p',
                  }
                : {
                    actor: account,
                    permission: 'active',
                  },
            ],
            data,
          },
        ],
      }

      console.log('pushP2PTransaction ==> transaction: ', transaction)

      response = await pushTransaction(transaction)
    } catch (error) {
      console.error('failed to push transaction', error)
      authErrorFallback(error as Error)
      throw error
    }

    return response
  }

  const submitOffer = async (counterOffer?: P2p_Offers_Step) => {
    // * This identifies if the offer is a counter offer or a new offer. If it is a counter offer, it will have an id
    const offerData = counterOffer ? counterOffer : state.offer

    setState((prevState) => ({ loading: true, offer: { ...prevState.offer, ...offerData } }))
    try {
      const isSellOffer = offerData.type === 'sell'
      const trnx_quantity = counterOffer ? offerData.amount : state.offer.amount
      const variables = {
        amount: trnx_quantity,
        method: offerData.method,
        region: offerData.region,
      } as
        | Bitcash.MakeP2PPreSellOfferMutationVariables['object']
        | Bitcash.MakeP2PBuyOfferMutationVariables

      if (offerData.id) {
        variables.id = offerData.id
        const reserve_offer = await reserveOffer(offerData.id)
        console.log('offer reserved', offerData.id, reserve_offer)
        if (!reserve_offer) throw new Error('Offer already taken.')
      }

      let make_p2p_response
      let txId = ''

      if (isSellOffer) {
        const prevPreOffer = await getPreSellOffer(account)
        // Never should be pre sale offer without a sale offer

        if (prevPreOffer)
          throw new Error(
            `Please contact support. There is a pending offer${
              prevPreOffer.id ? ` #${prevPreOffer.id}` : ''
            }.`,
          )

        const quantity = sellerAssetAmountLessP2PFee(trnx_quantity)
        const feeQuantity = getDAOAmountFee(trnx_quantity, true)

        let response = null

        response = await pushP2PTransaction('sellp2p', {
          seller: account,
          quantity: {
            contract: token?.token_contract,
            quantity,
          },
          fee: {
            contract: token?.token_contract,
            quantity: feeQuantity,
          },
        })

        txId =
          (response as TransactResult)?.transaction?.id.toString() ||
          (response as SendTransaction2Response).transaction_id

        const sell_variables = {
          object: {
            ...variables,
            seller: account,
            bitcashbank_id: `${account}`,
            sell_put_transaction: txId,
            seller_method_details: offerData.seller_method_details,
          },
        } as unknown as Bitcash.MakeP2PPreSellOfferMutationVariables

        await makeP2PPreSellOffer({
          variables: sell_variables,
        })
        make_p2p_response = {
          data: await fetchSellOffer(txId),
        }
      } else {
        const isValidToken = await refreshSession()

        if (!isValidToken) throw new Error('JWTError solved.')

        await verifyBitcashbankRegistration()
        const buy_variables = {
          ...variables,
          buyer: account,
          buyer_method_details: offerData.buyer_method_details,
        } as unknown as Bitcash.MakeP2PBuyOfferMutationVariables
        make_p2p_response = (await makeP2PBuyOffer({
          variables: buy_variables,
        })) as MutationResult<Bitcash.MakeP2PBuyOfferMutation>
      }

      if (make_p2p_response?.error || !make_p2p_response.data)
        throw new SmartContractError('Unexpected Error')

      const response_sell_data = make_p2p_response.data as Bitcash.MakeP2PSellOfferMutation
      const response_buy_data = make_p2p_response.data as Bitcash.MakeP2PBuyOfferMutation
      const offer = isSellOffer
        ? (response_sell_data as Bitcash.P2p_Offers)
        : (response_buy_data.make_p2p_buy_offer as Bitcash.P2p_Offers)

      setState({
        submitted: true,
        offer: {
          ...offer,
          buyerLabel: offer.buyer ? hideBkExt(offer.buyer) : null,
          sellerLabel: offer.seller ? hideBkExt(offer.seller) : null,
        },
      })
      return { success: true }
    } catch (err) {
      console.log('Failed to create P2P Offer', err)

      if (offerData.id) {
        await unreserveOffer(offerData.id)
        console.log('offer unreserved', offerData.id)
      }

      if (err instanceof Error) {
        const message = err.message.match(/Time limit reached./)
          ? `createP2POffer::[Time limit reached]::${err.message}`
          : `createP2POffer::${err.message}`

        authErrorFallback(err)
        setState({ error: err.message })

        Sentry.captureException({
          ...err,
          name: 'SubmitNewP2POffer',
          message,
          extras: {
            transactionErrorDetails: { ...err },
          },
          user: {
            username: account,
          },
        })
      }
      return { success: false }
    } finally {
      // TODO: Take method details in local storage to be able to prefill the form in the next offer
      // TODO: list of methods at accounts_information table
      if (offerData.seller_method_details || offerData.buyer_method_details) {
        const offerMethodDetail =
          offerData.buyer === account
            ? offerData.buyer_method_details
            : offerData.seller_method_details

        if (offerMethodDetail) {
          const newUserMethod: DeserializedProfilePaymentMethod = {
            method: offerData.method,
            details: JSON.parse(offerMethodDetail),
          }
          saveProfilePaymentMethods(account, newUserMethod)
        }
      }

      setState({ loading: false })
    }
  }

  const cancelOffer = async () => {
    try {
      setState({ loading: true })

      const isValidToken = await refreshSession()

      if (!isValidToken) throw new Error('JWTError solved.')

      const isSell = state.offer.seller === account
      const isAdmin = hasAdminPermissions(account) && location.pathname.includes('/admin')

      if (isAdmin) {
        const response = await pushP2PTransaction('cancelp2p', {
          seller: state.offer.seller,
          id: state.offer.bitcashbank_id?.split('-')[1],
        })

        // ? We notify the user that the offer has been cancelled
        await bitcashInsertNotificationMutation({
          variables: {
            object: {
              content_id: state.offer.id,
              from: account,
              to: !isSell ? state.offer.seller : state.offer.buyer,
              read: false,
              created_at: new Date(),
              type: NotificationTypeEnum.P2P_UPT,
            },
          },
          onError: (err) => authErrorFallback(err),
        })

        const result = await cancelApproveP2POffer({
          variables: {
            cancel_p2p_approval: {
              id: state.offer.id,
              cancellation_transaction:
                (response as TransactResult)?.transaction?.id.toString() ||
                (response as SendTransaction2Response).transaction_id,
              cancellation_approved_by: account as string,
            },
          },
        })
        const offerUpdate = result.data?.cancel_p2p_approval

        if (!offerUpdate) throw new Error('Unable to update the offer for a cancelation approval.')

        updateOffer(offerUpdate)

        // ? We notify the User that the cancellation request has been completed
        // TODO: Notify User (take him to History > P2P) that is where the cancellation request is completed
      } else {
        const result = await cancelP2POffer({
          variables: { p2p_id: state.offer.id, cancelled_by: account as string },
        })
        const offerUpdate = result.data?.cancel_p2p_offer

        if (!offerUpdate) throw new SmartContractError('Unexpected Error')

        updateOffer(offerUpdate)

        // ? We notify the Admin that the offer has a cancellation request
        // TODO: Notify Admin (take him to Admin Panel > P2P) that there is a cancellation request
      }

      return { success: true }
    } catch (err) {
      if (err instanceof Error) {
        authErrorFallback(err)
        setState({ error: err.message })

        Sentry.captureException({
          ...err,
          name: 'CancelP2PError',
          message: err.message,
          extras: {
            transactionErrorDetails: { ...err },
          },
          user: {
            username: account,
          },
        })
      }
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const autoCancelOffer = async () => {
    let offerUpdate

    try {
      const isValidToken = await refreshSession()

      if (!isValidToken) throw new Error('JWTError solved.')

      setState({ loading: true })

      const result = await autoCancelP2POffer({
        variables: {
          p2p_id: state.offer.id,
          cancelled_by: account as string,
        },
      })
      offerUpdate = result.data?.auto_cancel_p2p_offer as P2p_Offers_Step

      if (!offerUpdate) throw new Error('Unexpected error while auto cancelling offer.')

      return { success: true }
    } catch (err) {
      if (err instanceof Error) {
        authErrorFallback(err)
        setState({ error: err.message })

        Sentry.captureException({
          ...err,
          name: 'AutoCancelP2PError',
          message: err.message,
          extras: {
            transactionErrorDetails: { ...err },
          },
          user: {
            username: account,
          },
        })
      }
      return { success: false }
    } finally {
      setState({
        loading: false,
        offer: {
          ...state.offer,
          ...((offerUpdate as P2p_Offers_Step) || {}),
        },
      })
    }
  }

  const confirmPayment = async () => {
    try {
      setState({ loading: true })
      const isSell = state.offer.seller === account

      let response = null

      if (isSell) {
        response = await pushP2PTransaction('confirmp2p', {
          seller: account,
          buyer: state.offer.buyer,
          id: state.offer.bitcashbank_id?.split('-')[1],
        })
      } else {
        const isValidToken = await refreshSession()

        if (!isValidToken) throw new Error('JWTError solved.')
      }

      const variables: P2PConfirmPaymentVariablesTypes = {
        id: state.offer.id,
        [isSell ? 'seller' : 'buyer']: account,
        // ? Do we still want the seller to complete the offer without buyer to confirm?
        // * we don't update completed field when not sell
        ...(isSell && {
          // completed: true,
          sell_settlement_transaction:
            (response as TransactResult)?.transaction?.id.toString() ||
            (response as SendTransaction2Response).transaction_id,
        }),
      }
      const confirm_p2p_offer_response = await confirmP2pOffer({ variables })
      const offer_update = confirm_p2p_offer_response.data?.confirm_p2p_payment

      if (!offer_update) throw new SmartContractError('Unexpected Error')

      updateOffer(offer_update)
      // send notification
      await bitcashInsertNotificationMutation({
        variables: {
          object: {
            content_id: state.offer.id,
            from: account,
            to: !isSell ? state.offer.seller : state.offer.buyer,
            read: false,
            created_at: new Date(),
            type: NotificationTypeEnum.P2P_UPT,
          },
        },
        onError: (err) => authErrorFallback(err),
      })
      return { success: true }
    } catch (err) {
      console.error(err)
      if (err instanceof Error) {
        authErrorFallback(err)
        setState({ error: err.message })

        Sentry.captureException({
          ...err,
          name: 'ConfirmingP2PError',
          message: err.message,
          extras: {
            transactionErrorDetails: { ...err },
          },
          user: {
            username: account,
          },
        })
      }
      return { success: false }
    } finally {
      setState({ loading: false })
    }
  }

  const startNewOffer = (type: 'sell' | 'buy') => {
    const newSteps = type === 'sell' ? SELL_STEPS : BUY_STEPS

    setNewOffer(type)
    return newSteps
  }

  const setError = (error: string) => setState({ error })

  // useEffect(() => console.log('🚀 New offerState', state.offer), [state.offer])

  return [
    state,
    {
      updateOffer,
      startNewOffer,
      submitOffer,
      autoCancelOffer,
      cancelOffer,
      confirmPayment,
      setNewOffer,
      resetError,
      setError,
      methodLabel,
      formatRegion,
      setRegionCountryOptions,
      setMethodOptions,
    },
  ]
}

export const [useP2POffer, P2POfferProvider] = createContextHook(
  useP2POfferFn,
  'You must wrap your application with <P2POfferProvider /> in order to useP2POffer().',
)
