import React, { SVGProps } from 'react'

const BitcashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.402 0C10.496-.051 2.002 2.548.456 11.264c-.73 4.12-.472 8.112 0 12.245.967 8.464 9.415 11.694 17.044 11.482 7.574-.21 16.052-2.795 17.044-11.482.472-4.133.73-8.126 0-12.245C33.19 3.625 25.596.05 18.402 0Z"
      fill="#00DC00"
    />
    <path
      d="M24.897 21.023a4.836 4.836 0 0 1-.496 2.333 4.799 4.799 0 0 1-1.547 1.805 7.64 7.64 0 0 1-2.895 1.253 2.532 2.532 0 0 1-.772 1.885 2.5 2.5 0 0 1-3.468 0 2.532 2.532 0 0 1-.772-1.964 10.535 10.535 0 0 1-4.408-2.21l2.204-3.087a7.051 7.051 0 0 0 4.742 1.805 4.455 4.455 0 0 0 1.91-.34 1.093 1.093 0 0 0 .72-.986.882.882 0 0 0-.195-.582 1.557 1.557 0 0 0-.672-.454 5.54 5.54 0 0 0-.886-.316 21.36 21.36 0 0 0-1.19-.3l-.878-.242c-.22-.05-.519-.133-.91-.242a8.658 8.658 0 0 1-.941-.315c-.23-.094-.524-.232-.853-.39a3.607 3.607 0 0 1-.822-.518 6.634 6.634 0 0 1-.667-.621 3.022 3.022 0 0 1-.553-.804 5.454 5.454 0 0 1-.329-.986 5.19 5.19 0 0 1-.132-1.199 4.766 4.766 0 0 1 .946-2.959 5.44 5.44 0 0 1 2.474-1.815c.148-.06.302-.107.46-.138a1.036 1.036 0 0 1 0-.172 2.533 2.533 0 0 1 .74-1.731 2.5 2.5 0 0 1 3.474-.058c.474.443.76 1.054.797 1.705a10.81 10.81 0 0 1 4.61 2.012l-2.229 3.082a7.376 7.376 0 0 0-4.218-1.48 3.998 3.998 0 0 0-1.65.292.98.98 0 0 0-.652.907c.001.178.057.35.162.493.101.145.23.269.377.365.207.125.43.223.661.291.256.094.518.171.784.232.23.064.558.133.98.237l1.014.217c.26.064.602.153 1.019.276.36.1.712.227 1.053.38l.935.493c.305.165.581.378.818.631.237.256.451.532.642.824.217.324.37.687.45 1.07.11.432.164.876.163 1.321Z"
      fill="#fff"
    />
  </svg>
)

export default BitcashIcon
