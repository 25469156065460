import { config } from 'app-config'
import { Actions_Bool_Exp } from 'app-engine/graphql/generated/chaingraph'
import { useStore } from 'app-engine/store'
import { enumOrderBy } from 'graphql/chaingraph'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { BankHistoryFilterProps } from 'services/bitcash/history'

export type FilterOption = {
  label: string
  value: BankHistoryFilterProps
}

const common_actions_bool: Actions_Bool_Exp = {
  chain: {
    _eq: config.eosChainName,
  },
  contract: {
    _eq: config.contracts.bitcashBank,
  },
}

export const useBankFilters = () => {
  const { t } = useTranslation('history')
  const account = useStore.useAccount()
  const filterOptions = useMemo<FilterOption[]>(() => {
    if (!account) return []

    const internal_transfers_sent: Actions_Bool_Exp = {
      action: {
        _in: ['stbtransfer', 'crptransfer'],
      },
      data: {
        _contains: {
          from: account,
        },
      },
    }

    const internal_transfers_received: Actions_Bool_Exp = {
      action: {
        _in: ['stbtransfer', 'crptransfer'],
      },
      data: {
        _contains: {
          to: account,
        },
      },
    }

    const deposits: Actions_Bool_Exp = {
      action: {
        _eq: 'depositlog',
      },
      data: {
        _contains: {
          from: account,
        },
      },
    }

    const withdrawals: Actions_Bool_Exp = {
      action: {
        _eq: 'stbwdllog',
      },
      data: {
        _contains: {
          account: account,
        },
      },
    }

    return [
      {
        label: `${t('received')}`,
        value: {
          order_by: [{ global_sequence: enumOrderBy.desc }],
          where: {
            ...common_actions_bool,
            ...internal_transfers_received,
          },
        },
      },
      {
        label: `${t('sent')}`,
        value: {
          order_by: [{ global_sequence: enumOrderBy.desc }],
          where: {
            ...common_actions_bool,
            ...internal_transfers_sent,
          },
        },
      },
      {
        label: t('recent'),
        value: {
          order_by: [{ global_sequence: enumOrderBy.desc }],
          where: {
            ...common_actions_bool,
            _or: [internal_transfers_sent, internal_transfers_received, withdrawals, deposits],
          },
        },
      },
      {
        label: t('older'),
        value: {
          order_by: [{ global_sequence: enumOrderBy.asc }],
          where: {
            ...common_actions_bool,
            _or: [internal_transfers_sent, internal_transfers_received, withdrawals, deposits],
          },
        },
      },
    ] as FilterOption[]
  }, [account, t])
  const [filterState, setFilterState] = useSetState<{
    variables: BankHistoryFilterProps
    filter: string
  }>({
    variables: filterOptions[0].value,
    filter: filterOptions[0].label,
  })

  // update filter and graphql subscription variables when the <Select> value changes
  const onFilterChange = (filterOption: FilterOption) => {
    if (!filterOption) return

    setFilterState({
      variables: filterOption.value,
      filter: filterOption.label,
    })
  }

  return { ...filterState, filterOptions, onFilterChange }
}
