import * as Sentry from '@sentry/react'
import * as faceapi from '@vladmandic/face-api'
import { config } from 'app-config'
import { isMobile } from 'app-engine/library/utils'
import {
  verifySelfie as verifySelfieBE,
  // verifyPhotoIdBE
} from 'app-engine/services'
import { useStore } from 'app-engine/store'
import { useWizard } from 'app-view/components/Wizard'
import { useSelfie } from 'app-view/hooks/use-selfie'
import * as ImageConversion from 'image-conversion'
import { dataURItoArrayBuffer } from 'pages/AccountView/routes/shared/step-for-upload-selfie/take-image/take-image-utils'
import piexif from 'piexifjs'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import Tesseract from 'tesseract.js'

export const useTakeImageHook = () => {
  const { t } = useTranslation(['account'])
  const account = useStore.useAccount()
  const [{ step }, { close }] = useWizard()
  const videoRef = useRef(null)
  const canvasRef = useRef(document.createElement('canvas'))
  const interval = useRef(null)
  const {
    setSelfie: setPreSelfie,
    setDownloadData,
    setIdCard,
    setDownloadPhotoIDData,
  } = useSelfie()
  const [
    {
      selfieImageSrc,
      photoIdImageSrc,
      selfieDownloadLink,
      photoIdDownloadLink,
      selfieStatus,
      photoIdStatus,
      processing,
      isCameraStarted,
      error,
      idModels,
      frameWidth,
      frameHeight,
    },
    setTakeImageState,
  ] = useSetState({
    selfieImageSrc: null,
    photoIdImageSrc: null,
    selfieStatus: null,
    photoIdStatus: null,
    selfieDownloadLink: null,
    photoIdDownloadLink: null,
    processing: false,
    isCameraStarted: false,
    error: '',
    idModels: null,
    frameWidth: 0,
    frameHeight: 0,
  })

  let mediaStream = null

  const isEyeOpen = (eyeLandmarks: unknown) => {
    const eyeTop = eyeLandmarks[1].y
    const eyeBottom = eyeLandmarks[5].y
    const eyeHeight = eyeBottom - eyeTop
    const eyeOpenThreshold = 0.2
    return eyeHeight > eyeOpenThreshold
  }

  const verifySelfie = async (imageSrc) => {
    // Load the image
    const image = await faceapi.fetchImage(imageSrc)
    // Detect faces in the image
    const detection = await faceapi
      .detectSingleFace(image, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
    // Check if at least one face is detected
    const isHuman = Boolean(detection)
    if (isHuman) {
      const leftEye = detection.landmarks.getLeftEye()
      const rightEye = detection.landmarks.getRightEye()

      const leftEyeOpen = isEyeOpen(leftEye)
      const rightEyeOpen = isEyeOpen(rightEye)

      return leftEyeOpen && rightEyeOpen
    }
    return false
  }

  const verifyPhotoId = async (imageSrc) => {
    try {
      const data = await Tesseract.recognize(imageSrc, 'eng', {})

      const {
        data: { text },
      } = data
      let realIdCard = false
      let country = ''
      let card_type = ''
      if (text.toLowerCase().indexOf('national') > -1 && text.toLowerCase().indexOf('ident') > -1)
        card_type = 'national'
      else if (
        text.toLowerCase().indexOf('national') > -1 &&
        text.toLowerCase().indexOf('driver') > -1
      ) {
        card_type = 'driver_license'
      } else if (text.toLowerCase().indexOf('passport') > -1) {
        card_type = 'passport'
      }
      const countries = Object.keys(idModels)
      if (countries) {
        for (let i = 0; i < countries.length; i++) {
          if (text.toLowerCase().includes(countries[i])) {
            country = countries[i]
            break
          }
        }
      }

      for (let i = 0; i < idModels?.[country]?.[card_type].length; i++) {
        const index = idModels[country][card_type][i]
        if (text.toLowerCase().includes(index)) {
          continue
        } else {
          realIdCard = false
          break
        }
      }
      return realIdCard
    } catch (error) {
      if (error instanceof Error) {
        setTakeImageState({ error: error.message })
      }
      return false
    }
  }

  const startCamera = async () => {
    try {
      mediaStream = await navigator.mediaDevices.getUserMedia({
        video: isMobile
          ? {
              facingMode: { exact: step === 'take_photo_id' ? 'environment' : 'user' },
            }
          : true,
      })
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream
        setTakeImageState((prev) => {
          if (prev.frameWidth) return
          return {
            frameWidth: videoRef.current.offsetWidth,
            frameHeight: videoRef.current.offsetHeight,
          }
        })
      }
      if (!mediaStream) {
        throw new Error('No mediaStream')
      }
    } catch (error) {
      if (error instanceof Error) {
        setTakeImageState({ error: error.message })
        Sentry.captureException(
          {
            ...error,
            message: 'Error starting camera: ' + error.message,
          },
          {
            extra: {
              account: account || 'No account',
            },
          },
        )
      }
    }
  }

  const startFaceDetection = async (videoElement) => {
    setTakeImageState((prevStatus) => ({
      ...prevStatus,
      error: '',
      selfieStatus: prevStatus.selfieStatus ? prevStatus.selfieStatus : null,
    }))

    canvasRef.current.width = videoElement.videoWidth
    canvasRef.current.height = videoElement.videoHeight

    const context = canvasRef.current.getContext('2d')

    // Capture photo
    context.drawImage(videoElement, 0, 0, canvasRef.current.width, canvasRef.current.height)
    const photoURL = canvasRef.current.toDataURL('image/jpeg')

    const realSelfie = await verifySelfie(photoURL)

    setTakeImageState((prevState) => ({ selfieStatus: prevState.selfieStatus || realSelfie }))
  }

  const startPhotoIdDetection = async (videoElement) => {
    setTakeImageState((prevStatus) => ({
      ...prevStatus,
      error: '',
      selfieStatus: prevStatus.photoIdStatus ? prevStatus.photoIdStatus : null,
    }))

    canvasRef.current.width = videoElement.videoWidth
    canvasRef.current.height = videoElement.videoHeight

    const context = canvasRef.current.getContext('2d')

    // Capture photo
    context.drawImage(videoElement, 0, 0, canvasRef.current.width, canvasRef.current.height)
    const photoURL = canvasRef.current.toDataURL('image/jpeg')

    const realPhotoId = await verifyPhotoId(photoURL)

    setTakeImageState((prevState) => ({ photoIdStatus: prevState.photoIdStatus || realPhotoId }))
  }

  const reTakeSelfie = () => {
    setPreSelfie('')
    setDownloadData('')
    setTakeImageState({
      selfieImageSrc: null,
      selfieDownloadLink: null,
      selfieStatus: null,
      error: '',
    })

    startCamera()
  }

  const reTakePhotoId = () => {
    setTakeImageState({
      photoIdImageSrc: null,
      photoIdDownloadLink: null,
      photoIdStatus: null,
      error: '',
    })

    startCamera()
  }

  useEffect(() => {
    const startCameraAndDetection = async () => {
      await startCamera()

      if (step === 'take_selfie') await loadFaceApiModels()
      else if (step === 'take_photo_id') await loadIDModels()
    }

    startCameraAndDetection()

    return () => {
      stopCamera()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (step !== 'take_selfie')
      return () => {
        if (interval.current) clearInterval(interval.current)
        interval.current = null
      }

    if (isCameraStarted && !interval.current) {
      interval.current = setInterval(() => {
        if (videoRef?.current?.srcObject) {
          startFaceDetection(videoRef.current)
        }
      }, 1000)
    }

    if ((selfieImageSrc || processing) && interval.current) {
      if (interval.current) clearInterval(interval.current)
      interval.current = null
    } else if (isCameraStarted && !processing && !interval.current) {
      interval.current = setInterval(() => {
        if (videoRef?.current?.srcObject) {
          startFaceDetection(videoRef.current)
        }
      }, 1000)
    }

    return () => {
      if (interval.current) clearInterval(interval.current)
      interval.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraStarted, processing, selfieImageSrc, step])

  useEffect(() => {
    if (step !== 'take_photo_id')
      return () => {
        if (interval.current) clearInterval(interval.current)
        interval.current = null
      }

    if (isCameraStarted && !interval.current) {
      interval.current = setInterval(() => {
        if (videoRef?.current?.srcObject) {
          // startPhotoIdDetection(videoRef.current) // TODO: update AI
        }
      }, 1000)
    }

    if ((photoIdImageSrc || processing) && interval.current) {
      if (interval.current) clearInterval(interval.current)
      interval.current = null
    } else if (isCameraStarted && !processing && !interval.current) {
      interval.current = setInterval(() => {
        if (videoRef?.current?.srcObject) {
          // startPhotoIdDetection(videoRef.current) // TODO: update AI
        }
      }, 1000)
    }

    return () => {
      if (interval.current) clearInterval(interval.current)
      interval.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCameraStarted, processing, photoIdImageSrc, step])

  const takeSelfie = async () => {
    try {
      setTakeImageState({ processing: true, selfieStatus: null })

      videoRef.current.pause()

      canvasRef.current.width = videoRef.current.videoWidth
      canvasRef.current.height = videoRef.current.videoHeight

      const context = canvasRef.current.getContext('2d')

      // Capture photo
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)

      const photoURL = await canvasRef.current.toDataURL('image/jpeg')

      // Get geolocation
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { coords } = position
        const { latitude, longitude } = coords

        // Read existing EXIF data (if any)
        const exifData = piexif.load(photoURL)

        // Create new EXIF data or update existing values
        const newExifData = {
          ...exifData,
          '0th': {
            ...exifData['0th'],
            '306': new Date().toISOString(), // DateTime
          },
          GPS: {
            ...exifData.GPS,
            0x0001: latitude > 0 ? 'N' : 'S', // GPSLatitudeRef
            0x0002: piexif.GPSHelper.degToDmsRational(latitude), // GPSLatitude
            0x0003: longitude > 0 ? 'E' : 'W', // GPSLongitudeRef
            0x0004: piexif.GPSHelper.degToDmsRational(longitude), // GPSLongitude
          },
        }

        // Encode the new EXIF data
        const newExifBytes = piexif.dump(newExifData)
        // Insert the new EXIF data into the image
        const updatedImageBytes = piexif.insert(newExifBytes, photoURL)
        const optimizedImageBytes = await compressImage(updatedImageBytes)
        const updatedImageSrc = URL.createObjectURL(
          new Blob([dataURItoArrayBuffer(optimizedImageBytes)], { type: 'image/jpeg' }),
        )
        const data = await verifySelfieBE(optimizedImageBytes as string)
        if (Boolean(data?.error)) {
          const timeout = setTimeout(() => {
            if (videoRef.current) videoRef.current.play()

            clearTimeout(timeout)
          }, 1000)
        } else {
          setPreSelfie(optimizedImageBytes as string)
          setDownloadData(updatedImageSrc)
        }
        setTakeImageState({
          error: Boolean(data?.error) ? t('invalid_photo') : '',
          selfieStatus: !Boolean(data?.error),
          selfieImageSrc: Boolean(data?.error) ? null : optimizedImageBytes,
          selfieDownloadLink: Boolean(data?.error) ? null : updatedImageSrc,
          processing: false,
        })
      })
    } catch (error) {
      console.log('Error taking selfie:', error)
      if (error instanceof Error) {
        setTakeImageState({ error: error.message })
        Sentry.captureException(
          {
            ...error,
            message: 'Error taking selfie: ' + error.message,
          },
          {
            extra: {
              account: account || 'No account',
            },
          },
        )
      }
    }
  }

  const takePhotoId = async () => {
    try {
      setTakeImageState({ processing: true, photoIdStatus: null })

      videoRef.current.pause()

      canvasRef.current.width = videoRef.current.videoWidth
      canvasRef.current.height = videoRef.current.videoHeight

      const context = canvasRef.current.getContext('2d')

      // Capture photo
      context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)

      const photoURL = await canvasRef.current.toDataURL('image/jpeg')

      // Get geolocation
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { coords } = position
        const { latitude, longitude } = coords

        // Read existing EXIF data (if any)
        const exifData = piexif.load(photoURL)

        // Create new EXIF data or update existing values
        const newExifData = {
          ...exifData,
          '0th': {
            ...exifData['0th'],
            '306': new Date().toISOString(), // DateTime
          },
          GPS: {
            ...exifData.GPS,
            0x0001: latitude > 0 ? 'N' : 'S', // GPSLatitudeRef
            0x0002: piexif.GPSHelper.degToDmsRational(latitude), // GPSLatitude
            0x0003: longitude > 0 ? 'E' : 'W', // GPSLongitudeRef
            0x0004: piexif.GPSHelper.degToDmsRational(longitude), // GPSLongitude
          },
        }

        // Encode the new EXIF data
        const newExifBytes = piexif.dump(newExifData)
        // Insert the new EXIF data into the image
        const updatedImageBytes = piexif.insert(newExifBytes, photoURL)
        const optimizedImageBytes = await compressImage(updatedImageBytes)
        const updatedImageSrc = URL.createObjectURL(
          new Blob([dataURItoArrayBuffer(optimizedImageBytes)], { type: 'image/jpeg' }),
        )
        // const data = await verifyPhotoIdBE(optimizedImageBytes as string) // TODO: Update AI
        const data = { error: false }
        if (Boolean(data?.error)) {
          const timeout = setTimeout(() => {
            if (videoRef.current) videoRef.current.play()

            clearTimeout(timeout)
          }, 1000)
        }

        setTakeImageState({
          error: Boolean(data?.error) ? t('invalid_photo_id') : '',
          photoIdStatus: !Boolean(data?.error),
          photoIdImageSrc: Boolean(data?.error) ? null : optimizedImageBytes,
          photoIdDownloadLink: Boolean(data?.error) ? null : updatedImageSrc,
          processing: false,
        })
      })
    } catch (error) {
      console.log('Error taking selfie:', error)
      if (error instanceof Error) {
        setTakeImageState({ error: error.message, processing: false })
        Sentry.captureException(
          {
            ...error,
            message: 'Error taking selfie: ' + error.message,
          },
          {
            extra: {
              account: account || 'No account',
            },
          },
        )
      }
    }
  }

  const loadFaceApiModels = async () => {
    await Promise.allSettled([
      faceapi.nets.tinyFaceDetector.loadFromUri(config.apolloURL + '/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri(config.apolloURL + '/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri(config.apolloURL + '/models'),
    ])

    setTakeImageState({ isCameraStarted: true })
  }

  const loadIDModels = async () => {
    try {
      const response = await fetch(config.apolloURL + '/photo_id/index.json')
      const data = await response.json()
      setTakeImageState({ idModels: data, isCameraStarted: true })
    } catch (error) {
      console.log('Error while loading id models: ', error)
      setTakeImageState({ error: error.message })
    }
  }

  const stopCamera = () => {
    // Stop the camera stream
    if (mediaStream) {
      const tracks = mediaStream.getTracks()
      tracks.forEach((track) => track.stop())
    }
  }

  const onConfirm = () => {
    setIdCard(photoIdImageSrc)
    setDownloadPhotoIDData(photoIdDownloadLink)
    close()
  }

  const compressImage = async (imageDataUri) => {
    try {
      // Compress the image to target size (100 KB)
      const compressedImageBlob = await ImageConversion.compressAccurately(
        new Blob([dataURItoArrayBuffer(imageDataUri)], { type: 'image/jpeg' }),
        {
          size: 30,
          type: ImageConversion.EImageType.JPEG,
        },
      )
      const compressedImage = await blobToBase64(compressedImageBlob)
      return compressedImage
    } catch (error) {
      console.error('Image compression error:', error)
      return ''
    }
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  return [
    {
      videoRef,
      canvasRef,
      selfieStatus,
      selfieImageSrc,
      selfieDownloadLink,
      photoIdStatus,
      photoIdImageSrc,
      photoIdDownloadLink,
      error,
      processing,
      isCameraStarted,
      frameWidth,
      frameHeight,
    },
    {
      takeSelfie,
      takePhotoId,
      reTakeSelfie,
      reTakePhotoId,
      onConfirm,
    },
  ]
}
