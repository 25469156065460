/* eslint-disable no-nested-ternary */
import { config } from 'app-config'
import theme from 'app-config/theme'
import * as Bitcash from 'app-engine/graphql/generated/bitcash'
import { hideBkExt } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import {
  AccountIcon,
  BoldText,
  CardBoldText,
  CardTag,
  FlexBox,
  RowContainer,
  RowTextContainer,
  TransactionCardContainer,
} from 'app-view/components/Styled'
import { get_amount, hasAdminPermissions } from 'app-view/lib/utils'
import React from 'react'
import { useTranslation } from 'react-i18next'

const { colors } = theme
export const Offer: React.FC<{
  offer: P2POffer & {
    systemNotificationType?: Bitcash.System_Type_Notification_Enum | string
  }
  onClick: (offer: P2POffer) => void
}> = ({ offer, onClick }) => {
  const account = useStore.useAccount()
  const { t } = useTranslation('p2p')
  const { amount, buyer, seller } = offer
  const isSell = seller === account

  // TODO: Use this structure on every card...
  const offerParties = isSell ? `${hideBkExt(buyer || '')}` : `${hideBkExt(seller || '')}`
  const isAdmin =
    offer.cancelled_by &&
    hasAdminPermissions(offer.cancelled_by) &&
    !offer.cancelled_by.match(new RegExp(`^(${buyer || ''}|${seller || ''})$`))
  const cancelledByLabel = isAdmin ? 'bitcash' : hideBkExt(offer.cancelled_by || '')
  const cancelByRequest = offer.cancelled_by === buyer || offer.cancelled_by === seller
  const failedOffer = offer.cancelled_by === 'eosignitejun' && !cancelByRequest
  const isCancelApproved = config.bitcashSupervisors.includes(offer.cancellation_approved_by || '')

  return (
    <TransactionCardContainer
      role="button"
      onClick={() => onClick(offer)}
      style={{ backgroundColor: offer.cancelled ? theme?.colors?.shallowPink : undefined }}
    >
      <FlexBox>
        <FlexBox flexDirection={'column'}>
          <FlexBox>
            <AccountIcon />
            <CardBoldText color={colors?.darkGray}>
              {offer.cancelled &&
                `${t('cancel_request_by')}:
            `}
              {offer.cancelled ? cancelledByLabel : offerParties}
            </CardBoldText>
          </FlexBox>
          {/* TODO: Review nested ternaries */}
          {offer.cancelled && offer.systemNotificationType && !cancelByRequest ? (
            <CardTag className="feedbackTag" color="white">
              {t(offer.systemNotificationType.toLowerCase())}
            </CardTag>
          ) : // Checking if the offer was cancelled by one of the parties
          offer.cancelled && cancelByRequest ? (
            <CardTag className="feedbackTag" color="white">
              {t('cancel_by_request')}
            </CardTag>
          ) : // Checking if the offer was cancelled by the Indexer self-cancellation
          offer.cancelled && failedOffer ? (
            <CardTag className="feedbackTag" color="white">
              {t('failed_to_complete_offer')}
            </CardTag>
          ) : null}
        </FlexBox>
      </FlexBox>
      <RowContainer>
        <RowTextContainer>
          {/* NOTE: If sell and cancelled, we should show a + amount? having in gray amounts? */}
          <FlexBox justifyContent="flex-end" alignItems="center">
            <BoldText
              color={isSell ? colors?.red : colors?.green}
              style={{ marginRight: '0.3rem', lineHeight: 1.9 }}
            >
              {isSell ? t('sell') : t('buy')}
            </BoldText>
            <BoldText color={colors?.black} dollars>
              {get_amount(amount)}
            </BoldText>
          </FlexBox>
          {offer.cancelled ? (
            <CardTag
              approved={isCancelApproved}
              color={isCancelApproved ? colors?.white : colors?.black}
            >
              {isCancelApproved ? t('cancel_approved') : t('cancel_requested')}
            </CardTag>
          ) : null}
        </RowTextContainer>
      </RowContainer>
    </TransactionCardContainer>
  )
}
