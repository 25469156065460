import { static_token_data } from 'app-engine/store/token-slice'
import { bitcashRoom } from 'app-view/hooks/use-realtime'
import bigInt from 'big-integer'
import { asset, Asset, symbol } from 'eos-common'
import { useCallback } from 'react'

export type TokenPrice = {
  symbol_code: string
  usd_price: Asset
}

export const findTokenBySymbolCode = (symbolCode) => {
  const token = static_token_data.find((token) => token.symbol_code === symbolCode)
  if (!token) console.error('token_not_found', { symbolCode: symbolCode })
  return token
}

const useTokenPrices = () => {
  const tokenPrices = bitcashRoom.useColyseusState((state) => state.tokenPrices)

  // ? removed useCallback due the amount of useCallback and useMemo on related files.
  // ? Is good to memoize, but not all the time, it can lead into unwanted re-renders.
  // ? If we depend on a function value that inside of that function depends on a state value (like this one), we should use useCallback.
  // ? The state from above will do the re-render, and the function will be memoized hence, on other places will take the new values.
  // * If doesn't take the new values, we can use useMemo/useCallback, but we should be careful with it.
  const findTokenPriceBySymbolCode = useCallback(
    (symbol_code) => {
      const default_token_price = {
        symbol_code: symbol_code.toString(),
        usd_price: asset('0.00 USD'),
        display_value: '0.00 USD',
      }
      try {
        const price = tokenPrices?.get(symbol_code)
        if (!price) return default_token_price
        return {
          symbol_code: price.symbolCode,
          usd_price: asset(price.usdPriceDisplayValue),
          display_value: price.usdPriceDisplayValue,
        }
      } catch (error) {
        console.log('[error]', error)
        return default_token_price
      }
    },
    [tokenPrices],
  )

  const getUsdTokenValue = (quantity: Asset) => {
    const usdSymbol = symbol('USD', 2)

    if (!quantity.amount.toJSNumber()) return asset(quantity.amount, usdSymbol)

    const symbol_code = quantity.symbol.code().toString()
    // return same amount if quantity symbol code is USDT or BITUSD
    if (symbol_code === 'BITUSD') return asset(quantity.amount, usdSymbol)
    if (symbol_code === 'USDT') return asset(quantity.amount.divide(100), usdSymbol)
    // price in USD cents
    const usd_price = asset(findTokenPriceBySymbolCode(symbol_code)?.usd_price)
    if (symbol_code === 'EOS' && usd_price.amount.notEquals(0)) {
      usd_price.amount = bigInt[1].times(10 ** 4).divide(usd_price.amount)
    }
    const price = usd_price.amount.equals(0)
      ? bigInt[1].times(10 ** usd_price.symbol.precision())
      : usd_price.amount

    // value in USD cents
    const usdPrecisionDiff = usdSymbol.precision() - quantity.symbol.precision() // with USD
    const xprecision = Math.pow(10, Math.abs(usdPrecisionDiff))

    let amount = quantity.amount.times(10 ** usd_price.symbol.precision())

    amount = usdPrecisionDiff < 0 ? amount.divide(xprecision) : amount.times(xprecision)

    if (!amount.toJSNumber()) return asset(quantity.amount, usdSymbol)

    let value = price

    try {
      value = amount.divide(price)
    } catch (error) {
      console.error('ERROR', error)
    }

    return asset(value, usdSymbol)
  }

  const convertToAsset = (quantity, symbol_code) => {
    const bitusd_token = findTokenBySymbolCode('BITUSD')
    const token = findTokenBySymbolCode(symbol_code)

    if (quantity.symbol.code().toString() === symbol_code) return quantity
    if (symbol_code === 'USDT')
      return asset(quantity.amount.multiply(100), bitusd_token?.token_symbol)
    if (quantity.symbol === token?.token_symbol)
      throw new Error(`cannot_convert ${quantity}, ${symbol_code}`)
    const price = findTokenPriceBySymbolCode(symbol_code)
    if (!price) {
      throw new Error(`cannot_convert ${quantity}, ${symbol_code}`)
    }
    const converted = quantity.amount
      .times(price.usd_price.amount)
      .divide(10 ** quantity.symbol.precision())
    const convertedAsset = asset(converted, token?.token_symbol)

    return convertedAsset
  }

  return { findTokenPriceBySymbolCode, getUsdTokenValue, convertToAsset, tokenPrices }
}

export { useTokenPrices }
