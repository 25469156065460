import React from 'react'

const PolkaDotCoinIcon = (props: any) => (
  <svg id="polkadot-coin-icon" viewBox="0 0 56 56" {...props}>
    <g id="PolkaDot">
      <path
        id="icon_background"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.3918 1.00123C17.1944 0.919012 4.08809 4.92948 1.70362 18.3766C0.576304 24.7327 0.975632 30.893 1.70362 37.2699C3.19535 50.3284 16.2301 55.3118 27.9997 54.985C39.6862 54.6607 52.7657 50.6724 54.2966 37.2695C55.0245 30.893 55.4234 24.7327 54.2966 18.3766C52.2067 6.59227 40.4908 1.07678 29.3918 1.00123Z"
        fill="white"
        stroke="#979797"
        strokeWidth="0.5"
      />
      <g id="POLKADOT 1" clipPath="url(#clip0_4234:5660)">
        <path
          id="Vector"
          d="M27.8495 15C22.4189 15.0218 18.0218 19.4189 18 24.8495C18 25.937 18.1755 27.0173 18.5194 28.0488C18.7759 28.751 19.5374 29.1291 20.252 28.912C20.9428 28.6471 21.3095 27.892 21.0893 27.1856C20.7974 26.3816 20.6675 25.5288 20.706 24.6749C20.8244 20.7526 24.0996 17.6686 28.0219 17.787C31.9442 17.9054 35.0283 21.1806 34.9099 25.1029C34.7987 28.7749 31.9068 31.7572 28.239 31.9805C28.239 31.9805 26.8564 32.0646 26.1688 32.1488C25.9153 32.1851 25.6639 32.235 25.4157 32.2984C25.3803 32.3347 25.3232 32.3347 25.2869 32.2994L25.2858 32.2984C25.2609 32.2662 25.2609 32.2205 25.2858 32.1883L25.4998 31.0207L26.7982 25.1808C26.9541 24.4516 26.4897 23.7338 25.7605 23.578C25.0313 23.4222 24.3135 23.8865 24.1577 24.6157C24.1577 24.6157 21.0695 38.91 21.0695 39.0399C20.8929 39.7348 21.3126 40.4411 22.0075 40.6177C22.0148 40.6198 22.0231 40.6219 22.0304 40.6229H22.102C22.7959 40.8026 23.5044 40.3851 23.683 39.6912C23.6861 39.6797 23.6893 39.6683 23.6913 39.6559C23.6893 39.634 23.6893 39.6122 23.6913 39.5914C23.7298 39.4232 24.1193 37.5212 24.1193 37.5212C24.4143 36.0857 25.5715 34.9856 27.0195 34.7633C27.3176 34.7176 28.5704 34.6335 28.5704 34.6335C33.9802 34.0944 37.9285 29.2714 37.3883 23.8616C36.896 18.9275 32.8085 15.1288 27.8495 15Z"
          fill="#1E1E1E"
        />
        <path
          id="Vector_2"
          d="M28.4469 37.7106C27.5587 37.5257 26.6903 38.096 26.5054 38.9831C26.5033 38.9914 26.5023 38.9997 26.5002 39.008C26.3081 39.8899 26.8669 40.7615 27.7499 40.9536C27.7509 40.9536 27.7519 40.9536 27.753 40.9547H27.7987C28.6629 41.1635 29.5324 40.6316 29.7401 39.7684C29.7422 39.7622 29.7432 39.7549 29.7453 39.7487V39.6583C29.898 38.767 29.3288 37.9121 28.4469 37.7106Z"
          fill="#E6007A"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4234:5660">
        <rect width="19.4382" height="26" fill="white" transform="translate(18 15)" />
      </clipPath>
    </defs>
  </svg>
)

export default PolkaDotCoinIcon
