export default {
  recently_trust: 'baru-baru ini mempercayai Anda.',
  trust_back: 'Apakah Anda ingin mempercayai kembali?',
  chat: 'Obrolan',
  untrust_user: 'Tidak Percaya Pengguna',
  matched: 'Cocok',
  unmatched: 'Tidak cocok',
  unmatched_user: 'Tidak cocok - Anda',
  unmatched_all: 'Tidak cocok - Semua',
  private_offers: 'Penawaran',
  private_my_offers: 'Penawaran Saya',
  trusted: 'Dipercaya',
  failure_message: 'Pesan Kegagalan',
  trust_request: 'Permintaan',
  error_body: `Kami mengalami masalah
	saat mencoba membuat penawaran
	$ Anda.`,
  error_body_payment: `Kami mengalami masalah
	saat mencoba mengkonfirmasi penawaran
	$ Anda.`,
  request_success: 'Permintaan berhasil dikirim',
  do_you_want: `Apakah Anda ingin menerima tawaran
  {{cryptoAmount}} {{methodDetails}}?`,
  error_body_cancel: `Kami mengalami masalah
	saat mencoba membatalkan penawaran
	$ Anda.`,
  sell: 'Jual',
  buy: 'Beli',
  offers: 'Penawaran yang Diselesaikan',
  mutual: 'Bersama',
  trust_users: 'Jaringan',
  cancelled_offer: 'Penawaran Dibatalkan',
  closed_offer: 'Penawaran Ditutup',
  open_offer: 'Penawaran Terbuka',

  clearing_filters: 'Menghapus P2P menawarkan filter...',
  applyng_filters: 'Menerapkan P2P menawarkan filter...',
  clear_filter_btn_text: 'Hapus filter',
  select_multiple_currency: 'Pilih beberapa mata uang',
  payment_method: 'Cara Pembayaran',
  prices: 'Harga',

  paypal: 'PayPal',
  venmo: 'Venmo',
  cash_app: 'Cash App',
  zelle: 'Zelle',
  bank_transfer: 'Transfer Bank',
  sinpe_movil: 'SINPE Móvil',
  oxxo: 'OXXO',
  ovo: 'OVO',
  duitnow: 'DuitNow',
  grab: 'Grab',
  gojek: 'Gojek',
  opay: 'OPay',
  palmpay: 'PalmPay',
  kuda: 'Kuda',
  wechat: 'WeChat',
  alipay: 'Alipay',
  revolut: 'Revolut',
  bizum: 'Bizum',
  lyf: 'Lyf',

  network: 'Jaringan',
  public: 'Publik',
  private: 'Pribadi',

  offer: 'penawaran',
  sell_now: 'Jual Sekarang',
  buy_now: 'Beli Sekarang',
  filter_by_title: 'Filter berdasarkan',
  filter_option_buy: 'Beli',
  filter_option_sell: 'Jual',
  filter_option_recent: 'Baru-baru ini',
  filter_option_older: 'Lebih lama',
  filter_option_open: 'Buka',
  filterOptionCancelled: 'Dibatalkan',
  filterOptionCompleted: 'Selesai',
  no_offers: 'Saat ini tidak ada penawaran {{filter}}',
  no_request: 'Saat ini tidak ada permintaan',
  no_accounts: 'Saat ini tidak ada akun',
  amount_to_sell: `Berapa banyak {{symbol}}
	yang ingin Anda jual?`,
  how_you_want_get_paid: `Bagaimana Anda ingin
	dibayar?`,
  sell_confirm: `Konfirmasikan penawaran Anda untuk menjual
	<span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

	Anda akan menerima <span>{{amount}}</span> menggunakan
	<span>{{method}}</span>.`,

  amount_to_buy: `Berapa banyak {{symbol}}
	yang ingin Anda beli?`,
  how_you_want_to_pay: `Bagaimana Anda ingin
	membayar?`,
  buy_confirm: `Konfirmasikan penawaran Anda untuk membeli
	<span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

	Anda akan mengirim <span>{{amount}}</span> menggunakan
	<span>{{method}}</span>.`,

  buy_duplicating: `Menduplikasi penawaran beli {{method}} {{amount}}`,
  sell_duplicating: `Menduplikasi penawaran jual {{method}} {{amount}}`,

  from: 'dari',
  using: 'menggunakan',
  ok: 'Oke',

  creating_buy_offer: 'Membuat penawaran beli',
  'Congrats we have found a match': 'Selamat kami telah menemukan pasangan',
  'wants to sell': 'ingin menjual',
  please_contact: 'Silahkan hubungi',
  'and send': 'dan kirim',

  'You are matched': 'Anda cocok',
  'Did you send': 'Apakah Anda mengirim',
  to: 'ke',
  on: 'pada',
  'send you': 'mengirim Anda',

  cancel_approved: 'Batalkan yang disetujui',
  cancel_requested: 'Batalkan yang diminta',
  confirmed_uncompleted_offer: 'Penawaran yang dikonfirmasi belum selesai',
  confirmed_uncompleted_offer_error: 'Penawaran yang dikonfirmasi belum selesai',
  on_chain_matching_preoffer_complete: 'Penawaran pra-pasangan yang cocok di rantai selesai',
  on_chain_matching_preoffer_start: 'Penawaran pra-pasangan yang cocok di rantai mulai',
  on_chain_matching_preoffer_timeout_error: 'Penawaran pra-pasangan yang cocok di rantai timeout',
  unconfirmed_offer_12h: 'Penawaran yang belum dikonfirmasi 12 jam',
  unconfirmed_offer_12h_error: 'Penawaran yang belum dikonfirmasi 12 jam error',
  unexpected_error: 'Kesalahan yang tidak terduga',
  unmatched_offer_48h: 'Penawaran yang tidak cocok 48 jam',
  unmatched_offer_48h_error: 'Penawaran yang tidak cocok 48 jam error',
  unprocessed_indexed_confirm_offer: 'Penawaran konfirmasi yang diindeks belum diproses',
  unprocessed_indexed_offer: 'Penawaran yang diindeks belum diproses',
  cancel_by_request: 'Batalkan permintaan',
  failed_to_complete_offer: 'Gagal menyelesaikan penawaran',

  'You have sent': 'Anda telah mengirim',
  'Please remind': 'Silakan ingatkan',
  'to confirm receipt of': 'untuk mengkonfirmasi penerimaan',
  Contact: 'Obrolan',

  Next: 'Berikutnya',
  Back: 'Kembali',
  Confirm: 'Konfirmasi',
  Done: 'Selesai',

  'If the problem persists, ask for support on Telegram':
    'Jika masalah berlanjut, minta dukungan di Telegram',

  no_match_yet: 'Maaf, belum ada pertandingan!',
  will_connect_with_buyer_soon: 'Kami akan menghubungkan Anda dengan pembeli sebentar lagi',
  will_connect_with_seller_soon: 'Kami akan menghubungkan Anda dengan penjual sebentar lagi',
  check_back_in_few_minutes: 'Silakan periksa kembali dalam beberapa menit',

  confirming_payment: 'Mengkonfirmasi pembayaran',
  Not_yet: 'Belum',
  Yes: 'Ya',
  No: 'Tidak',
  Retry: 'Coba lagi',
  'Work in progress!': 'Sedang dalam proses!',
  'You have sold': 'Anda telah menjual',
  creating_sell: 'Membuat penawaran jual',
  'Congrats we have found a match!': 'Selamat kami telah menemukan pasangan!',
  'wants to buy': 'ingin membeli',
  'Please ask': 'Silakan tanya',
  'to send you': 'untuk mengirim Anda',
  'Did you receive': 'Apakah Anda menerima',
  zero_balance: 'Anda tidak memiliki cukup saldo {{symbol}}',
  confirmed_with: 'Anda telah mengkonfirmasi dengan',
  but_did_not_send: 'tetapi tidak mengirim',
  'but have not': 'tetapi belum',
  would_cancel_offer: `Apakah Anda ingin membatalkan
  penawaran?`,
  not_now: 'Bukan sekarang',
  cancel_request_by: 'Batalkan permintaan oleh',
  order_cancelled: 'Pesanan Anda telah dibatalkan.',
  'Did you contact': 'Apakah Anda telah menghubungi',
  'Did you confirm sale with': 'Apakah Anda mengkonfirmasi penjualan dengan',
  we_are_sorry: 'Kami minta maaf',
  no_longer_wants_to_sell: 'tidak lagi ingin menjual',
  'Would you like to keep this offer': 'Apakah Anda ingin mempertahankan penawaran ini',
  'and find a new match': 'dan mencari pasangan baru',
  no_longer_wants_to_buy: 'tidak lagi ingin membeli',
  'You have cancelled your offer to buy': 'Anda telah membatalkan penawaran Anda untuk membeli',
  you_cancelled_offer_to_sell: 'Anda telah membatalkan penawaran Anda untuk menjual',
  'Offer has been cancelled to buy': 'Penawaran telah dibatalkan untuk membeli',
  offer_been_cancelled_to_sell: 'Penawaran telah dibatalkan untuk menjual',
  confirmed_sending_you: 'telah mengkonfirmasi mengirimkan kepada Anda',
  bank_transfer_region_label: ' wilayah.',
  bank_transfer_region: ' di {{region}}{{isRegion}}',
  push_transaction_error_action_cancel: 'membatalkan',
  push_transaction_error_action_confirm: 'mengkonfirmasi',
  push_transaction_error_action_sell: 'memulai',
  buy_matched_alt_offer: `<span>{{seller}}</span> ingin menjual
  <span>{{amount}}</span> (+{{cryptoFee}} biaya).

  Apakah Anda telah menghubungi <span>{{seller}}</span>
  dan mengkonfirmasi pembelian?

  `,
  buy_matched_confirm_offer: `<span>{{seller}}</span> ingin menjual
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

  Harap hubungi <span>{{seller}}</span>
  dan kirim <span>{{amount}}</span> menggunakan <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_matched_offer: `
  Selamat kami telah menemukan pasangan!
  <span>{{seller}}</span> ingin menjual
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

  Harap hubungi <span>{{seller}}</span>
  dan kirim <span>{{amount}}</span> menggunakan <span>{{method}}</span>
  {{methodDetails}}
  `,
  buy_payment: `Anda telah dipasangkan.
  <span>{{seller}}</span> ingin menjual
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

  Apakah Anda telah mengirim <span>{{amount}}</span> ke <span>{{seller}}</span> menggunakan <span>{{method}}</span> {{methodDetails}}

  `,
  buy_payment_confirmed: `Anda telah mengirim <span>{{amount}}</span> ke <span>{{seller}}</span> menggunakan <span>{{method}}</span> {{methodDetails}}

  Mohon ingatkan <span>{{seller}}</span> untuk mengkonfirmasi penerimaan <span>{{amount}}</span>`,
  buy_cancelled_offer: `{{sorry}}
  {{sellerInfo}} <span>{{amount}}</span> (+{{cryptoFee}} biaya) menggunakan <span>{{method}}</span> {{methodDetails}}
  Dibatalkan pada {{date}}`,
  sell_cancel_deny: `Kami minta maaf,
  <span>{{buyer}}</span> telah mengkonfirmasi pengiriman <span>{{amount}}</span> (+{{cryptoFee}} biaya) menggunakan <span>{{method}}</span> {{methodDetails}}

  Harap hubungi <span>{{buyer}}</span>.

  `,
  sell_matched_alt_offer: `<span>{{buyer}}</span> ingin membeli
  <span>{{amount}}</span> (+{{cryptoFee}} biaya).

  Apakah Anda telah mengkonfirmasi penjualan dengan
<span>{{buyer}}</span>?

  `,
  sell_matched_confirm_offer: `<span>{{buyer}}</span> ingin membeli
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

  Mohon minta <span>{{buyer}}</span>
  untuk mengirim Anda <span>{{amount}}</span> menggunakan <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_matched_offer: `
  Selamat kami telah menemukan pasangan!
  <span>{{buyer}}</span> ingin membeli
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

  Mohon minta <span>{{buyer}}</span>
  untuk mengirim Anda <span>{{amount}}</span> menggunakan <span>{{method}}</span>
  {{methodDetails}}
  `,
  sell_payment: `Anda telah dipasangkan.
  <span>{{buyer}}</span> ingin membeli
  <span>{{cryptoAmount}}</span> (+{{cryptoFee}} biaya).

  Apakah Anda telah menerima <span>{{amount}}</span> dari <span>{{buyer}}</span> menggunakan <span>{{method}}</span> {{methodDetails}}

  `,
  sell_cancelled_offer: `{{sorry}}
  {{buyerInfo}}
  <span>{{amount}}</span> (+{{cryptoFee}} biaya) menggunakan <span>{{method}}</span>
  {{methodDetails}}
  Dibatalkan pada {{date}}`,
  accept: 'Terima',
  reject: 'Tolak',
  accepted: 'Diterima',
  rejected: 'Ditolak',
  loader: 'memuat daftar jaringan kepercayaan...',
  select_region_country_search: `Silakan, pilih wilayah/negara atau,
  ketik untuk mencari.`,
  sell_select_stable: `Apa yang ingin
  Anda jual?`,
  buy_select_stable: `Apa yang ingin
  Anda beli?`,

  phone_number_label: '{{phoneNumberCountryCode}} | No. telepon',
  account_number_label: 'No. rekening',
  bank_name_label: 'Nama bank',
  full_name_label: 'Nama lengkap',

  seller_method_details: 'Mohon berikan informasi bank Anda untuk digunakan dalam menerima dana',
  seller_method_details_name_account:
    'Mohon berikan nama dan nomor rekening Anda untuk digunakan dalam menerima dana.',
  seller_method_details_phone: `Mohon berikan nomor telepon
untuk menerima dana.`,
  seller_method_details_account: `Mohon berikan nomor rekening
untuk menerima dana.`,
  buyer_method_details: 'Mohon berikan informasi bank Anda untuk digunakan dalam mengirim dana.',
  buyer_method_details_name_account:
    'Mohon berikan nama dan nomor rekening Anda untuk digunakan dalam mengirim dana.',
  buyer_method_details_phone: `Mohon berikan nomor telepon
untuk digunakan dalam mengirim dana.`,
  buyer_method_details_account: `Mohon berikan nomor rekening
untuk digunakan dalam mengirim dana.`,

  purchase_completed: `Pembelian selesai.
  Anda telah membeli <span>{{amount}}</span> (+{{cryptoFee}} biaya)
  dari <span>{{seller}}</span> menggunakan
  <span>{{method}}</span> {{sellerMethodDetails}} pada {{date}}.

  {{fundsSent}}
  {{methodDetails}}`,
  sell_complete: `Penjualan selesai.
  Anda telah menjual <span>{{amount}}</span> (+{{cryptoFee}} biaya)
  ke <span>{{buyer}}</span> menggunakan <span>{{method}}</span>
  {{buyerMethodDetails}} pada {{date}}.

  {{fundsReceived}}
  {{methodDetails}}`,

  funds_sent: 'Anda menerima dana ',

  seller_method_phone_detail_label: `ke nomor telepon #
  <span class="p2p-method-detail-phone" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span> {{region}}`,
  seller_method_bank_detail_label: `ke <span>{{fullName}}</span> dengan nomor rekening <span>{{accountName}}</span>
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  seller_method_account_detail_label: `ke nomor akun #
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  seller_method_phone_name_detail_label: `ke <span>{{fullName}}</span> dengan nomor telepon #
  <span class="p2p-method-detail-account" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_phone_detail_label: `dari nomor telepon #
  <span class="p2p-method-detail-phone" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span> {{region}}`,
  buyer_method_bank_detail_label: `dari <span>{{fullName}}</span> dengan nomor rekening <span>{{accountName}}</span>
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_account_detail_label: `dari nomor akun #
  <span class="p2p-method-detail-account" id="method-detail">{{accountNumber}}<i class="gg-clipboard"></i></span>
  {{region}}`,
  buyer_method_phone_name_detail_label: `dari <span>{{fullName}}</span> dengan nomor telepon #
  <span class="p2p-method-detail-account" id="method-detail">{{phone}}<i class="gg-clipboard"></i></span>
    {{ region }}`,

  copied: 'Berhasil disalin.',

  show_more: '<b class="p2p-show-details" id="show-more-details">… tunjukkan lebih banyak</b>',
  show_less: '<b class="p2p-show-details" id="show-less-details"> tunjukkan lebih sedikit</b>',
}
