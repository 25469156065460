import React from 'react'
import { useTranslation } from 'react-i18next'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import { get_amount } from 'app-view/lib/utils'
import { Cancel } from '../common/Cancel'
import { useP2PLabels } from 'pages/P2PView/hooks/useP2PLabels'

export const BuyCancelOffer: React.FC = () => {
  const { t } = useTranslation(['p2p'])
  const [{ offer }, { methodLabel }] = useP2POffer()
  const { isMethodDetails, sellerMethodDetailsLabelData } = useP2PLabels()

  return (
    <Cancel
      text={`${t('confirmed_with')}
      <span>${offer.sellerLabel}</span> ${t('but_did_not_send')}
      <span>${get_amount(offer.amount)}</span> ${t('using')} <span>${methodLabel(
        offer.method,
      )}</span>
      ${isMethodDetails ? sellerMethodDetailsLabelData : ''}
      `}
    />
  )
}
