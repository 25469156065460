import theme from 'app-config/theme'
import { ButtonTransparent } from 'app-view/components/buttons'
import styled from 'app-view/styles/styled'

export const PriceAmountWrapper = styled.div`
  grid-column: 2;
  width: 100%;
  max-width: 330px;
`

export const Price = styled.div`
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 70px;
  padding-top: 5px;
  padding-bottom: 5px;

  > div:first-of-type > * {
    font-weight: bold;
  }
`

export const SectionAmount = styled.div`
  max-width: 235px;
  padding: 0px 16px;
  width: 100%;
  margin: 0 auto 46px;
  border: 1px solid ${theme.colors?.gray};
  border-radius: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span[aria-label] {
    padding-right: 24px;
  }
`

export const SectionStable = styled(SectionAmount)`
  font-weight: bold;

  > div {
    width: 100%;
  }
`

export const ViewContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`

export const DuplicateRoundIconButton = styled(ButtonTransparent)`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  position: absolute;
  transform: translate(50%, 50%);
  left: -1rem;
  top: -0.83rem;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 0;
`
