import React from 'react'
import styled from 'app-view/styles/styled'
import {
  EOSIcon,
  BitcoinIcon,
  BitIcon,
  EthereumIcon,
  BitcashIcon,
  SnowCoinIcon,
  PNETIcon,
  GrinIcon,
  FTMIcon,
  MINAIcon,
  SOLIcon,
  BNBIcon,
  DOTIcon,
  AVAXIcon,
  BUSDIcon,
  USDTEOSIcon,
  USDTETHIcon,
  USDTFTMIcon,
  USDTTRONIcon,
  USDTAVAXIcon,
  USDTBNBIcon,
  USDTIcon,
  JungleIcon,
  BATIcon,
  FLOWIcon,
  IMXIcon,
  TLOSIcon,
  WNDIcon,
  SIAIcon,
  SimpleCoinIcon,
  ATOMCosmosIcon,
  PolygonIcon,
  VenezuelaStableIcon,
  ColombiaStableIcon,
  MexicoStableIcon,
  ArgentinaStableIcon,
  CostaRicaStableIcon,
  EURStableIcon,
  ChinaStableIcon,
  CanadaStableIcon,
  // UkraineStableIcon, // TODO: enable later
  AustraliaStableIcon,
  TaiwanStableIcon,
  UnitedKingdomStableIcon,
  MalaysiaStableIcon,
  NigeriaStableIcon,
  UgandaStableIcon,
  IndonesiaStableIcon,
  VietnamStableIcon,
  PeruStableIcon,
  ChileStableIcon,
  KenyanStableIcon,
  JapanStableIcon,
  SouthKoreaStableIcon,
} from 'app-view/components/icons'

const StyledIcon = styled.div`
  width: 54px;
  height: 54px;
  margin-right: 12px;
  box-sizing: border-box;

  svg {
    min-width: 100%;
    min-height: 100%;
  }
`

const Coin: React.FC<{ Icon: React.JSXElementConstructor<any> }> = ({ Icon, ...props }) => (
  <StyledIcon {...props}>
    <Icon />
  </StyledIcon>
)

export const SwapIcon = ({ coin, ...props }: any) => {
  switch (coin.toUpperCase()) {
    // NOTE: Might delete on later version, or keep for dev propose
    case 'JUNGLE':
      return <Coin Icon={JungleIcon} {...props} />
    case 'EOS':
    case 'MOCKEOS':
      return <Coin Icon={EOSIcon} {...props} />
    case 'PBTC':
    case 'BTC':
      return <Coin Icon={BitcoinIcon} {...props} />
    case 'PETH':
    case 'ETH':
      return <Coin Icon={EthereumIcon} {...props} />
    case 'BITUSD':
      return <Coin Icon={BitcashIcon} {...props} />
    case 'BITUSD-EXCHANGE':
      return <Coin Icon={BitIcon} {...props} />
    case 'BITVES':
      return <Coin Icon={VenezuelaStableIcon} {...props} />
    case 'BITCOP':
      return <Coin Icon={ColombiaStableIcon} {...props} />
    case 'BITMXN':
      return <Coin Icon={MexicoStableIcon} {...props} />
    case 'BITARS':
      return <Coin Icon={ArgentinaStableIcon} {...props} />
    case 'BITCRC':
      return <Coin Icon={CostaRicaStableIcon} {...props} />
    case 'BITEUR':
      return <Coin Icon={EURStableIcon} {...props} />
    case 'BITCNY':
      return <Coin Icon={ChinaStableIcon} {...props} />
    case 'BITCAD':
      return <Coin Icon={CanadaStableIcon} {...props} />
    // Disable for a while
    // case 'BITUAH':
    //   return <Coin Icon={UkraineStableIcon} {...props} />
    case 'BITGBP':
    case 'BITGBR':
      return <Coin Icon={UnitedKingdomStableIcon} {...props} />
    case 'BITAUD':
      return <Coin Icon={AustraliaStableIcon} {...props} />
    case 'BITTWD':
      return <Coin Icon={TaiwanStableIcon} {...props} />
    case 'BITNGN':
      return <Coin Icon={NigeriaStableIcon} {...props} />
    case 'BITMYR':
      return <Coin Icon={MalaysiaStableIcon} {...props} />
    case 'BITUGX':
      return <Coin Icon={UgandaStableIcon} {...props} />
    case 'BITIDR':
      return <Coin Icon={IndonesiaStableIcon} {...props} />
    case 'BITVND':
      return <Coin Icon={VietnamStableIcon} {...props} />
    case 'BITKES':
      return <Coin Icon={KenyanStableIcon} {...props} />
    case 'BITSOL':
      return <Coin Icon={PeruStableIcon} {...props} />
    case 'BITCLP':
      return <Coin Icon={ChileStableIcon} {...props} />
    case 'BITKRW':
      return <Coin Icon={SouthKoreaStableIcon} {...props} />
    case 'BITYEN':
      return <Coin Icon={JapanStableIcon} {...props} />
    case 'SNOW':
      return <Coin Icon={SnowCoinIcon} {...props} />
    case 'PNET':
      return <Coin Icon={PNETIcon} {...props} />
    case 'GRIN':
      return <Coin Icon={GrinIcon} {...props} />
    case 'MINA':
      return <Coin Icon={MINAIcon} {...props} />
    case 'FTM':
      return <Coin Icon={FTMIcon} {...props} />
    case 'SOL':
      return <Coin Icon={SOLIcon} {...props} />
    case 'BNB':
      return <Coin Icon={BNBIcon} {...props} />
    case 'DOT':
      return <Coin Icon={DOTIcon} {...props} />
    case 'AVAX':
      return <Coin Icon={AVAXIcon} {...props} />
    case 'BUSD':
      return <Coin Icon={BUSDIcon} {...props} />
    case 'USDTAVAX':
      return <Coin Icon={USDTAVAXIcon} {...props} />
    case 'USDTBNB':
      return <Coin Icon={USDTBNBIcon} {...props} />
    case 'USDTEOS':
      return <Coin Icon={USDTEOSIcon} {...props} />
    case 'USDTETH':
      return <Coin Icon={USDTETHIcon} {...props} />
    case 'USDTFMT':
      return <Coin Icon={USDTFTMIcon} {...props} />
    case 'USDTTRON':
      return <Coin Icon={USDTTRONIcon} {...props} />
    case 'USDT':
      return <Coin Icon={USDTIcon} {...props} />
    case 'BAT':
      return <Coin Icon={BATIcon} {...props} />
    case 'IMX':
      return <Coin Icon={IMXIcon} {...props} />
    case 'FLOW':
      return <Coin Icon={FLOWIcon} {...props} />
    case 'WND':
      return <Coin Icon={WNDIcon} {...props} />
    case 'TLOS':
      return <Coin Icon={TLOSIcon} {...props} />
    case 'SC':
      return <Coin Icon={SIAIcon} {...props} />
    case 'ATOM':
      return <Coin Icon={ATOMCosmosIcon} {...props} />
    case 'MATIC':
      return <Coin Icon={PolygonIcon} {...props} />
    case 'SIMPLE':
      return <Coin Icon={SimpleCoinIcon} {...props} />
    default:
      console.error(
        `\n\n ------------------------------ \n\nUnable to find icon for ${coin}\n\n ------------------------------ \n\n`,
      )
      return null
  }
}
