import { WizardStep } from 'app-view/components/Wizard'
import { ActionSuccess } from 'pages/WalletView/steps/common/ActionSuccess'
import { SendToAccount } from 'pages/WalletView/steps/send-steps/SendToAccount'
import { SendWithMemo } from 'pages/WalletView/steps/send-steps/SendWithMemo'

export const SEND_STEPS: WizardStep[] = [
  {
    name: 'send-to-account',
    component: SendToAccount,
  },
  {
    name: 'memo',
    component: SendWithMemo,
  },
  {
    name: 'success',
    component: ActionSuccess,
  },
]
