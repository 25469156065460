import styled from 'app-view/styles/styled'
import theme from 'app-config/theme'
const { colors } = theme

export const Label = styled.label<{ gray?: boolean; center?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  padding-block: 6px;
  color: ${({ gray }) => (gray ? colors.lightBlack : colors.black)};
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  margin: 0;
`
