import { ModalButtonsContainer, ModalErrorProps } from 'app-view/components/Modal'
import { GreenButton } from 'app-view/components/buttons'
import { motion } from 'framer-motion/dist/framer-motion'
import { Link } from 'pages/AccountView/components/TextElements'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalErrorText } from './Styled'

const discordUrl = 'https://discord.gg/6r5KSBgRh8'

// NOTE: Error message that start with $redirect_discord, they will be treated as perhaps support, contact error or something error related that we might need to get in touch within Discord channels
export const ModalError: React.FC<ModalErrorProps> = ({
  error = '',
  align,
  actionLabel,
  animationProps,
  resetError,
}) => {
  const { t } = useTranslation(['swap', 'errors'])
  let content
  if (error.match(/^\$redirect_discord/)) {
    content = (
      <>
        {error.replace('$redirect_discord', '')}
        <br />
        <br />
        <Link href={discordUrl} target="_blank" rel="noopener noreferrer">
          {/* ... */}
        </Link>
      </>
    )
  } else if (
    error.match(
      /ransaction\s+exceeded\s+the\s+current\s+(network|CPU)\s+usage\s+limit\s+imposed\s+on\s+the\s+transaction|is\s+greater\s+than\s+the\s+maximum\s+billable\s+CPU\s+time\s+for\s+the\s+transaction/,
    )
  ) {
    content = (
      <>
        You are out of power. Please go to:&nbsp;
        <a href="https://eospowerup.io/free/" target="_blank" rel="noopener noreferrer">
          eospowerup.io
        </a>
        &nbsp; and enter your account name that ends in '.bk' to charge your account. For automated
        powerups please reach out to support in the bitcash Discord.
      </>
    )
  } else {
    content = t(error.replace(/^(Error:\s|error:\s)/g, ''))
  }
  if (error.match(/(Offer already taken)/g)) {
    actionLabel = 'ok'
  }

  // TODO: Translate this...
  const isLoginRequest = error.includes(
    'No account found on device. Please log in first and retry.',
  )

  const goToLogin = () => {
    resetError()
    window.location.href = '/account'
  }

  return (
    <motion.div {...animationProps}>
      <ModalErrorText align={align}>{content}</ModalErrorText>
      {resetError && (
        <ModalButtonsContainer>
          <GreenButton onClick={() => (isLoginRequest ? goToLogin() : resetError())}>
            {/* TODO: Translate this... */}
            {isLoginRequest ? 'Log In' : actionLabel ?? t('swap:retry')}
          </GreenButton>
        </ModalButtonsContainer>
      )}
    </motion.div>
  )
}
