export default {
  p2p: 'P2P',
  trade: 'Trade',
  bank: 'Bank',
  swap: 'Swap',
  filterBy: 'Filter By',
  older: 'Older',
  recent: 'Recent',
  forex: 'Forex',
  from: 'From',
  to: 'To',
  sent: 'Sent',
  received: 'Received',
  p2pEmptyMsn: 'Confirmed Bitcash P2P activity will be reflected here.',
  tradeEmptyMsn: 'Confirmed Trade activity will be reflected here.',
  paymentsEmptyMsn: 'Confirmed Payments activity will be reflected here.',
  fee: 'fee',
  cancel_approved: 'Cancel Approved',
  cancel_requested: 'Cancel Requested',
  deposit: 'deposit',
  transfer: 'transfer',
  'There is currently no buy history': 'There is currently no buy history',
  'There is currently no sell history': 'There is currently no sell history',
  'There is currently no history': 'There is currently no history',
  'There is currently no send history': 'There is currently no send history',
  'There is currently no receipt history': 'There is currently no receipt history',
  buy_slate_no_msg: 'Please wait for admin to send you slatepack message.',
  buy_slate_with_msg: 'Please wait for admin to finalize transaction.',
  sell_slate_no_msg: 'Please wait for admin to receive slatepack response to finalize.',
  sell_slate_with_msg: 'Finalize transaction with slatepack response. Await admin to confirm.',
}
