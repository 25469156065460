import { useStore } from 'app-engine/store'
import { HintError, Input, InputSection, InputWrapper } from 'app-view/components/InputField'
import {
  ModalButtonsContainer,
  ModalSelectWrapper,
  ModalStepBlackButton,
  ModalStepGreenButton,
  ModalText,
} from 'app-view/components/Modal'
import { useLoggedUser } from 'app-view/hooks/use-realtime/use-logged-user'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import { Form } from 'pages/AccountView/components/TextElements'
import {
  DeserializedProfilePaymentMethod,
  saveProfilePaymentMethods,
} from 'pages/P2PView/hooks/useP2POffer'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const MethodDetails = ({ onPrev, onNext, text }: MethodDetailsProps) => {
  const { t } = useTranslation(['p2p', 'profile', 'account'])
  const currentPaymentMethod = useStore.useCurrentPaymentMethod()
  // const profilePaymentMethods = useStore.useProfilePaymentMethods()
  const account = useStore.useAccount()
  const countryDataLoggedUser = useLoggedUser()
  const profilePaymentMethod = {
    details: { accountName: '', accountNumber: '', phoneNumber: '', fullName: '' },
  } // profilePaymentMethods?.find((method) => method.method === currentPaymentMethod)

  const defaultFullName = profilePaymentMethod?.details?.fullName || ''
  const defaultAccountName = profilePaymentMethod?.details?.accountName || ''
  const defaultAccountNumber = profilePaymentMethod?.details?.accountNumber || ''
  const defaultPhoneNumber = profilePaymentMethod?.details?.phoneNumber || ''

  const { register, handleSubmit, setValue, setError, watch, formState } = useForm({
    defaultValues: {
      fullName: defaultFullName,
      accountName: defaultAccountName,
      accountNumber: defaultAccountNumber,
      phoneNumber: defaultPhoneNumber,
    },
  })

  const onSubmitHandler = handleSubmit(({ accountNumber, accountName, phoneNumber, fullName }) => {
    let success = false
    let profilePaymentMethod: DeserializedProfilePaymentMethod | undefined

    try {
      switch (currentPaymentMethod) {
        case 'kuda':
          success = Boolean(accountNumber)

          if (success) {
            profilePaymentMethod = {
              method: currentPaymentMethod,
              details: {
                accountNumber,
              },
            }
          }
          break
        case 'bank_transfer':
          success = Boolean(accountNumber && accountName && fullName)

          if (success) {
            profilePaymentMethod = {
              method: currentPaymentMethod,
              details: {
                fullName,
                accountNumber,
                accountName,
              },
            }
          }
          break
        case 'opay':
        case 'palmpay':
        case 'oxxo': {
          success = Boolean(fullName) && Boolean(phoneNumber) && phoneNumber.length >= 7

          if (success) {
            profilePaymentMethod = {
              method: currentPaymentMethod,
              details: {
                fullName,
                phoneNumber,
              },
            }
          }
          break
        }
        case 'ovo':
        case 'duitnow':
        case 'gojek':
        case 'sinpe_movil':
        case 'grab':
          success = Boolean(phoneNumber) && phoneNumber.length >= 7
          if (success) {
            profilePaymentMethod = {
              method: currentPaymentMethod,
              details: {
                phoneNumber,
              },
            }
          }
          break
        default:
          success = false
          break
      }
      if (success && profilePaymentMethod) {
        saveProfilePaymentMethods(account, profilePaymentMethod)
      }
    } catch (error) {
      console.error('onSubmitHandler::MethodDetails::ERROR', error)
    } finally {
      onNext()
    }
  })

  const formatUserPhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!countryDataLoggedUser?.countryCode) return

    const { value } = event.target
    const phoneNumber = parsePhoneNumber(
      value,
      (countryDataLoggedUser?.countryCode ? countryDataLoggedUser?.countryCode : '') as CountryCode,
    )
    const nationalOrInternationalNumber = !countryDataLoggedUser?.countryCode.match(/^(NG)$/)
      ? phoneNumber?.formatInternational()
      : phoneNumber?.formatNational()

    setValue('phoneNumber', phoneNumber ? (nationalOrInternationalNumber as string) : value.trim())
    setError('phoneNumber', {
      message: phoneNumber?.isValid() ? '' : 'Invalid phone number',
    })
  }

  const phoneValue = watch('phoneNumber')
  const phoneNumberCountryCode = countryDataLoggedUser?.countryCode
    ? `+${
        parsePhoneNumber('123', countryDataLoggedUser?.countryCode as CountryCode)
          ?.countryCallingCode
      }`
    : ''
  // * formatting the phone number to the country code after setting the international number
  const formattedValue = new AsYouType(countryDataLoggedUser?.countryCode as CountryCode)
    .input(phoneValue)
    .replace(/\s/g, '-')

  return (
    <Form id="method-details" onSubmit={onSubmitHandler}>
      <ModalSelectWrapper>
        {currentPaymentMethod.match(/^(bank_transfer|kuda)$/) ? (
          <>
            {currentPaymentMethod === 'bank_transfer' && (
              <>
                <InputWrapper style={{ maxWidth: 235 }}>
                  <InputSection
                    border="grey"
                    error={Boolean(!formState.isValidating && formState.errors.fullName?.message)}
                  >
                    <Input
                      {...register('fullName')}
                      onChange={(event) => {
                        setValue('fullName', event.target.value)
                      }}
                      defaultValue={defaultFullName}
                      placeholder={t('full_name_label')}
                      type="text"
                      fullradius={1}
                      fontWeight="500"
                    />
                  </InputSection>
                  {!formState.isValidating && formState.errors.fullName?.message && (
                    <HintError align="left">{formState.errors.fullName?.message}</HintError>
                  )}
                </InputWrapper>
                <InputWrapper style={{ maxWidth: 235 }}>
                  <InputSection
                    border="grey"
                    error={Boolean(
                      !formState.isValidating && formState.errors.accountName?.message,
                    )}
                  >
                    <Input
                      {...register('accountName')}
                      onChange={(event) => {
                        setValue('accountName', event.target.value)
                      }}
                      defaultValue={defaultAccountName}
                      placeholder={t('bank_name_label')}
                      type="text"
                      fullradius={1}
                      fontWeight="500"
                    />
                  </InputSection>
                  {!formState.isValidating && formState.errors.accountName?.message && (
                    <HintError align="left">{formState.errors.accountName?.message}</HintError>
                  )}
                </InputWrapper>
              </>
            )}
            <InputWrapper style={{ maxWidth: 235 }}>
              <InputSection
                border="grey"
                error={Boolean(!formState.isValidating && formState.errors.accountNumber?.message)}
              >
                <Input
                  {...register('accountNumber')}
                  onChange={(event) => {
                    setValue('accountNumber', event.target.value.toUpperCase())
                  }}
                  defaultValue={defaultAccountNumber}
                  placeholder={t('account_number_label')}
                  type="text"
                  fullradius={1}
                  fontWeight="500"
                />
              </InputSection>
              {!formState.isValidating && formState.errors.accountNumber?.message && (
                <HintError align="left">{formState.errors.accountNumber?.message}</HintError>
              )}
            </InputWrapper>
          </>
        ) : (
          <>
            {currentPaymentMethod === 'oxxo' && (
              <InputWrapper style={{ maxWidth: 235 }}>
                <InputSection
                  border="grey"
                  error={Boolean(!formState.isValidating && formState.errors.fullName?.message)}
                >
                  <Input
                    {...register('fullName')}
                    onChange={(event) => {
                      setValue('fullName', event.target.value)
                    }}
                    defaultValue={defaultFullName}
                    placeholder={t('full_name_label')}
                    type="text"
                    fullradius={1}
                    fontWeight="500"
                  />
                </InputSection>
                {!formState.isValidating && formState.errors.fullName?.message && (
                  <HintError align="left">{formState.errors.fullName?.message}</HintError>
                )}
              </InputWrapper>
            )}
            <InputWrapper style={{ maxWidth: 235 }}>
              <InputSection
                border="grey"
                error={Boolean(
                  phoneValue && !formState.isValidating && formState.errors.phoneNumber?.message,
                )}
              >
                <Input
                  {...register('phoneNumber')}
                  onChange={formatUserPhoneNumber}
                  placeholder={t('phone_number_label', {
                    phoneNumberCountryCode: phoneNumberCountryCode
                      ? `(${phoneNumberCountryCode}) `
                      : '',
                  })}
                  value={formattedValue}
                  fullradius={1}
                  fontWeight="500"
                  type="tel"
                />
              </InputSection>
              {!formState.isValidating && phoneValue && formState.errors.phoneNumber?.message && (
                <HintError align="left">{formState.errors.phoneNumber?.message}</HintError>
              )}
            </InputWrapper>
          </>
        )}
        <ModalText>{text}</ModalText>
      </ModalSelectWrapper>
      <ModalButtonsContainer>
        <ModalStepBlackButton type="button" onClick={onPrev}>
          {t('Back')}
        </ModalStepBlackButton>
        <ModalStepGreenButton
          form="method-details"
          disabled={
            Boolean(
              phoneValue && !formState.isValidating && formState.errors.phoneNumber?.message,
            ) && !formState.dirtyFields.phoneNumber
          }
          type="submit"
        >
          {t('confirm')}
        </ModalStepGreenButton>
      </ModalButtonsContainer>
    </Form>
  )
}

export type MethodDetailsProps = {
  onPrev: () => void
  onNext: () => void
  text: string
}
