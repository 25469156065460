import React, { useState } from 'react'
import styled from 'app-view/styles/styled'
import { StableTokenQuantityMaskedInput } from 'app-view/components/StableTokenQuantityMaskedInput'
import { InputSection, InputWrapper, Label } from 'app-view/components/InputField'
import { CoinSelectorWrapper } from 'app-view/components/Styled'
import { ScreenTypes } from 'app-engine/store/userguide-slice'
import { CoinSelector, CoinSelectorProps } from './CoinSelector'
import { NumberFormatValues, NumericFormatProps } from 'react-number-format'

interface StableCoinAmountProps extends NumericFormatProps {
  id?: string // Just for debugging
  readOnly?: boolean
  setCoin: Function
  onChangeAmount?: (props: NumberFormatValues) => void
  coinOptions: Array<any>
  coin: any // TODO: improve type
  initialCoin: any
  label: string
  isWallet?: boolean
  amount?: string
  onBlur?: () => void
}

const MaskedStableInput = styled(StableTokenQuantityMaskedInput)`
  height: 100%;
  border-left: 1px solid #eae7e7 !important;
  border-radius: 0 10px 10px 0 !important;
  width: 50%;
`

const AmountLabel = styled(Label)`
  display: flex !important;
  justify-content: center;
  font-weight: 800;
  font-size: 19px;
  line-height: 22px;
  color: #5f5f5f;
`

const Container = styled('div')`
  border: 1px solid #eae7e7;
  border-radius: 9px;
`

export const StableCoinAmount: React.FC<StableCoinAmountProps> = ({
  id,
  label,
  initialCoin,
  coin,
  setCoin,
  coinOptions,
  onChangeAmount,
  amount,
  onBlur,
  ...props
}) => {
  const [userInteract, setUserInteract] = useState(false)
  const sendCoinSelectorAttr: CoinSelectorProps = {
    id,
    coin,
    setCoin,
    coinOpt: coinOptions,
    indicatorProps: {
      screen: 'bank' as ScreenTypes,
      type: 'coin-selector',
    },
    userInteract,
    setUserInteract,
    isStableCoin: true,
    initialIndex: coinOptions.indexOf(initialCoin),
  }

  if (!coin) return null

  return (
    <InputWrapper>
      <AmountLabel size="md" htmlFor="amount-input">
        {label}
      </AmountLabel>
      <Container>
        <InputSection error={false}>
          <CoinSelectorWrapper>
            <CoinSelector {...sendCoinSelectorAttr} />
          </CoinSelectorWrapper>
          <MaskedStableInput
            {...props}
            token={coin}
            value={amount}
            onBlur={() => {
              if (onBlur) onBlur()
              window.scroll(0, 0)
            }}
            onValueChange={onChangeAmount}
            autoComplete="off"
          />
        </InputSection>
      </Container>
    </InputWrapper>
  )
}
