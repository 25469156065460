import styled from 'app-view/styles/styled'
import { SwapIcon } from 'app-view/components/SwapIcon'
import ReactSelectComponent from 'react-select'
import { scrollbar_css } from 'app-view/components/Styled'

export const WheelSelectWrapper = styled.div<{ oneThird?: boolean; label?: string }>`
  overflow: hidden;
  height: 100%;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 0;
  touch-action: none;
  width: 100%;
  grid-column-start: ${(props: any) => (props.label ? 2 : 1)};
  grid-column-end: ${(props: any) => (props.oneThird ? '3' : '4')};
`
export const WheelSelectContainer = styled.div<{ label?: string }>`
  display: contents;
  align-items: center;
  text-align: center;
  place-self: stretch stretch;
  justify-content: center;
  flex-basis: 60px;
  grid-column: ${(props: any) => (props.label ? 2 : 1)};
  margin-top: 0;
  user-select: none;
  text-transform: capitalize;
  width: 100%;

  > div {
    /* nested cos of padding */
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    height: 57.5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 6px;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;

    > div,
    > span {
      font-size: 19px;
      line-height: 1.5;
      margin: 0 auto 0;
    }

    > span.gray {
      color: #787878;
    }
  }
`

export const DesktopSelectContainer = styled.div<{
  compact?: boolean
  isCoinSelector?: boolean
  width?: string
}>`
  align-items: center;
  background-color: white;
  border-radius: ${({ compact }) => (compact ? 0 : '8px')};
  display: flex;
  gap: ${({ compact }) => (compact ? 0 : '0.5rem')};
  text-transform: ${({ isCoinSelector }) => (isCoinSelector ? 'none' : 'capitalize')};
  width: ${({ isCoinSelector, width }) => width || (isCoinSelector ? '125px' : '100%')};
`

export const WheelSelectMask = styled.div<{ isAndroid?: boolean }>`
  user-select: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  z-index: 3;
  border-radius: 0 10px 10px 0;
  background: ${(props: any) =>
    props.isAndroid
      ? 'transparent'
      : `linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 20%,
    rgba(255, 255, 255, 0) 50%,
    rgba(255, 255, 255, 0.8) 80%,
    rgba(255, 255, 255, 1) 100%
  )`};
`

export const WheelSelectItem = styled.div<{ icon?: string; isAndroid?: boolean }>`
  text-align: center;
  display: flex;
  justify-content: ${(props: any) => (props.icon ? 'flex-start' : 'center')};
  align-items: center;
  opacity: ${(props: any) => (props.isAndroid ? 0.6 : 1)};
  color: #000;
  position: relative;
  line-height: 1.5;
  font-size: ${(props: any) => (props.icon ? '1rem' : '1.188rem')};
  height: 2rem;

  margin-bottom: ${(props: any) => (props.icon ? '5px' : 0)};

  &.selected {
    z-index: 3;
    color: #000;
    opacity: 1;

    > svg {
      width: 2rem;
      height: 2rem;
    }
  }
`
export const SelectItemText = styled.span`
  text-align: left;
  padding-left: 0.5rem;
  width: 100%;
`
export const SelectInnerWrapper = styled.div`
  position: relative;
  transition: 300ms;
  transition-timing-function: ease-out;
`

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

export const Icon = styled(SwapIcon)`
  width: 30px;
  max-width: 30px;
  height: 30px;
  max-height: 30px;
  margin-right: 5px;
`

export const DesktopSelectLabel = styled.div`
  font-weight: bold;
`

export const ReactSelect = styled(ReactSelectComponent)`
  width: 100%;
  caret-color: transparent;

  div[class*='-control'] {
    border-left: 0px;
  }

  div[class*='-singleValue'] {
    margin-left: 0px;
    display: flex;
  }

  div[class*='-singleValue'] span {
    text-align: center;
  }
  div[class*='-indicatorContainer'] {
    padding-left: 4px;
    padding-right: 4px;
  }
  div[class*='-menu'] {
    width: 100%;

    > div {
      ${scrollbar_css}
    }
  }
`
