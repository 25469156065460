export default {
  settings: 'Ajustes',
  device_keys: 'Dispositivos\ny Claves',
  trade: 'Cambio',
  wallet: 'Billetera',
  history: 'Historial',
  deposit: 'Depósito',
  send: 'Enviar',
  withdrawal: 'Retiro',
  faucet: 'Grifo',
  p2p: 'P2P',
  swap: 'Canjear',
  account: 'Configurar',
  create_account: 'Crear',
  create_account_webauthn_cred: 'Crear',
  register_account: 'Registrarse',
  register_account_success: 'Registrado',
  about: 'Acerca de',
  add_device_account: 'Añadir Dispositivo',
  add_device_webauthn_cred: 'Añadir Dispositivo',
  add_device_success: 'Dispositivo Agregado',
  create_account_success: 'Cuenta Creada',
  notifications: 'Alertas',
  create_account_kyc: 'Información de cuenta',
  register_account_kyc: 'Información de cuenta',
}
