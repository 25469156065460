import { config } from 'app-config'

const headers = {
  'Access-Control-Allow-Origin': '*',
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

const getTokenWithIdentity = async (data: {
  pubKey: string
  signature: string
  account: string
  digest: string
}) => {
  const response = await fetch(`${config.bitcashAuth}/token/identity`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })

  return await response.json()
}

const getTokenWithTransactionId = async (data: { account: string; transactionId: string }) => {
  const response = await fetch(`${config.bitcashAuth}/token/transaction`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })

  return await response.json()
}

const refreshToken = async (token: string) => {
  const response = await fetch(`${config.bitcashAuth}/token/refresh`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ token }),
  })
  const data = await response.json()
  if (!response.ok || data.error) {
    if (data.error && data.error.match(/token/g)) {
      throw new Error('JWT')
    } else throw new Error('Unable to refresh session')
  }
  return data
}

export const bitcashAuthService = {
  getTokenWithIdentity,
  refreshToken,
  getTokenWithTransactionId,
}
