import theme from 'app-config/theme'
import { hideBkExt } from 'app-engine/library/utils'
import { useStore } from 'app-engine/store'
import { P2POffer } from 'app-engine/store/p2p-slice'
import { P2p_Offers_Step } from 'app-engine/types/p2pOffers'
import { useWizard } from 'app-view/components/Wizard'
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion'
import { useP2POffer } from 'pages/P2PView/hooks/useP2POffer'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { BUY_STEPS } from '../steps/buy-steps/index'
import { SELL_STEPS } from '../steps/sell-steps/index'
import { Offer } from './Offer'
import { OfferListSkeleton } from './OfferListSkeleton'
import {
  ListTitle,
  NoItems,
  OfferContainer,
  offerAnimationVariants,
  useGroupSimilarOffers,
} from './Shared'

type OfferListProps = {
  title: string
  offers: P2POffer[]
  offer: P2POffer | null
}

export const OfferList: React.FC<OfferListProps> = ({ title, offers, offer }) => {
  const { t } = useTranslation(['p2p'])
  const [, { start, goTo }] = useWizard()
  const [, { updateOffer }] = useP2POffer()
  const navigate = useNavigate()
  const { p2p, setInstructions, account } = useStore()
  const location = useLocation()
  const processedOffers = useGroupSimilarOffers(offers)

  const loadOffer = (offer: P2p_Offers_Step) => {
    const newSteps = offer.seller === account ? SELL_STEPS : BUY_STEPS

    updateOffer(offer)

    let nextStep = offer.matched ? 'match' : 'no-match'

    if (location.pathname.includes('/admin')) {
      nextStep = 'admin-cancel'

      start(newSteps)
      return goTo(nextStep)
    }

    if (offer.completed || (offer.seller_confirmed_payment && offer.seller === account)) {
      nextStep = 'complete'
    } else if (offer.buyer_confirmed_payment && offer.buyer === account) {
      nextStep = 'payment-confirmed'
    } else if (
      !offer.cancelled &&
      (offer.seller_confirmed_payment || offer.buyer_confirmed_payment)
    ) {
      nextStep = 'payment'
    } else if (offer.cancelled) {
      nextStep = 'cancelled'
    }

    start(newSteps)
    return goTo(nextStep)
  }

  const loadP2POffer = (offer: P2p_Offers_Step) => {
    const offerKeys = Object.keys(offer).filter((k) =>
      k.match(/^(matched|buyer_confirmed_payment|seller_confirmed_payment|completed|cancelled)$/),
    )

    const newOffer = {
      ...offer,
      buyerLabel: hideBkExt(offer.buyer || ''),
      sellerLabel: hideBkExt(offer.seller || ''),
    }

    loadOffer(newOffer)
    const offerStatus = offerKeys
      .filter((ok: string) => newOffer[ok] || (ok === 'matched' && !newOffer[ok])) // TODO: fix type
      .map((ok: string) => ({
        status: ok === 'matched' && !newOffer[ok] ? 'no-match' : ok,
        id: newOffer.id,
      }))

    offerStatus.map((os) => {
      const notificationOff = p2p.find((p2p: any) => p2p.status === os.status && p2p.id === os.id)

      if (!notificationOff) {
        setInstructions('p2p', os)
      }
    })
  }

  useEffect(() => {
    if (offer) {
      // @ts-ignore // TODO: fix type
      loadP2POffer(offer)
      navigate({ pathname: location.pathname, search: '' }, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer])

  return (
    <OfferContainer>
      <ListTitle color={theme.colors?.darkGray}>{title}</ListTitle>
      <AnimatePresence>
        {!processedOffers?.length ? (
          <motion.div
            key="no-items"
            variants={offerAnimationVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <NoItems>{t('no_offers', { filter: '' })}</NoItems>
          </motion.div>
        ) : (
          processedOffers.map((offer) => (
            <motion.div
              key={offer.id}
              variants={offerAnimationVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {/* @ts-ignore // TODO: fix type */}
              <Offer offer={offer} onClick={() => loadP2POffer(offer)} />
            </motion.div>
          ))
        )}
      </AnimatePresence>
      {!offers && (
        <motion.div
          key="skeleton"
          variants={offerAnimationVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <OfferListSkeleton />
        </motion.div>
      )}
    </OfferContainer>
  )
}
