import { chaingraphClient, query_rootGenqlSelection } from 'graphql/chaingraph'
import { omit } from 'lodash'
import { useEffect, useMemo, useRef } from 'react'
import useSWR, { SWRConfiguration, useSWRConfig } from 'swr'

const fetcher = (query: FetcherQueryGenqlSelection) => chaingraphClient.query(query)

export function useChaingraphSWR(
  query: FetcherQueryGenqlSelection,
  config: SWRConfiguration & { clearOnUnmount?: boolean; deepKey?: boolean } = {},
) {
  const dateRef = useRef(Date.now())
  // Dynamic key for every call
  const stringifiedQuery = JSON.stringify(query)
  const key = useMemo(
    () =>
      // If deep key is required to have a more unique key, by default would be the query
      config?.deepKey ? [stringifiedQuery, dateRef] : stringifiedQuery,
    [stringifiedQuery, config?.deepKey],
  )
  const { cache } = useSWRConfig()
  const { data, error, mutate, isLoading, isValidating } = useSWR(key, () => fetcher(query), {
    ...omit(config, ['clearOnUnmount', 'deepKey']),
  } as SWRConfiguration)

  // Clean cache on unmount
  useEffect(() => {
    return () => {
      // If cache is required to delete on unmount, by default would keep the cache on unmount
      if (config?.clearOnUnmount) {
        cache.delete(JSON.stringify(key))

        // console.log('chaingraph cache keys after cleanup', cache.keys())
      }
    }
  }, [cache, key, config?.clearOnUnmount])

  return { data, error, isLoading, isValidating, mutate, fetcher }
}

type FetcherQueryGenqlSelection = query_rootGenqlSelection & {
  __name?: string
}
