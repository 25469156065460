import React from 'react'
import { useTranslation } from 'react-i18next'
import { SwapAmount } from 'pages/SwapView/steps/common/SwapAmount'
import { WizardStepProps } from 'app-view/components/Wizard/types'
import { useStore } from 'app-engine/store'

export const BuySwapAmount = ({ next }: WizardStepProps) => {
  const { t } = useTranslation(['p2p'])
  const { setSwapBitcashAmount } = useStore()

  const onChange = (amount: number) => setSwapBitcashAmount(amount)

  return <SwapAmount onChange={onChange} onNext={next} text={t('amount_to_buy')} />
}
