// swap.ts
export default {
  price: 'Giá',
  available: 'sẵn có',
  confirm: 'Xác nhận',
  back: 'Trở lại',
  ok: 'Đồng ý',
  next: 'Tiếp theo',
  fee: 'Phí',
  total_to_receive: 'Tổng số nhận được',
  retry: 'Thử lại',
  buy: {
    loader: 'Đang tạo đơn mua của bạn',
    address: {
      text1: 'Số lượng mua',
      text2: 'Vui lòng dán địa chỉ ví mà bạn muốn nhận',
    },
    complete: {
      text1: `Chúc mừng!
		Bạn sẽ nhận được:`,
      text2: ' trước nửa đêm (GMT)',
      text3: `Bạn sẽ nhận được một tin nhắn slatepack trong cuộc trò chuyện hỗ trợ mà bạn cần nhập vào ví Grin của bạn để tạo phản hồi
		
		Vui lòng nhập phản hồi vào cuộc trò chuyện hỗ trợ để hoàn tất giao dịch.`,
    },
    slatepack: {
      text1: 'Số lượng mua',
      text2: 'Vui lòng xác nhận số lượng nhận',
    },
  },
  sell: {
    loader: 'Đang tạo đơn bán của bạn',
    userAddress: {
      text1: 'Số lượng bán:',
      text2: `Vui lòng dán địa chỉ ví
		mà bạn sẽ gửi từ`,
    },
    depositAddress: {
      text1: 'Số lượng bán:',
      text2: 'Vui lòng gửi ',
      text3: 'đến địa chỉ ví sau:',
    },
    complete: {
      text1: 'Số lượng bán:',
      text2: `Chúc mừng!
		Bạn sẽ nhận được`,
      text3: ' trước nửa đêm (GMT)',
      text4: 'Bạn sẽ nhận được:',
      slatepackMessage:
        'Bạn sẽ nhận được một tin nhắn phản hồi slatepack trong cuộc trò chuyện hỗ trợ mà bạn cần nhập vào ví Grin của bạn để hoàn tất giao dịch.',
    },
    slatepackForm: {
      text1: 'Số lượng bán:',
      text2: 'Vui lòng nhập tin nhắn slatepack:',
      placeholder: `BẮT ĐẦU SLATEPACK. H...
  ...g. KẾT THÚC SLATEPACK.`,
    },
  },
  general: {
    errorInputEmpty: 'Vui lòng điền vào trường này',
    errorInputInvalid: 'Độ dài địa chỉ không hợp lệ',
    copied: 'Đã sao chép!',
    pressToPaste: 'Dán địa chỉ ở đây',
  },
}
