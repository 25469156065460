// onboarding.ts
export default {
  select_language: 'Chọn ngôn ngữ của bạn',
  select_currency: 'Chọn tiền tệ',
  notifications: 'Thông báo',
  thank_you: 'Cảm ơn bạn!',
  primary_currency: 'Tiền tệ chính',
  allow_notifications: 'Cho phép thông báo',
  notifications_label:
    'Bạn sẽ nhận được thông báo khi nhận được tin nhắn từ hỗ trợ hoặc người dùng khác.',
  allow: 'Cho phép',
  block: 'Chặn',
  default_setting: 'Các cài đặt mặc định đã được cấu hình thành công.',
  change_setting: 'Bạn có thể thay đổi cài đặt này bất cứ lúc nào trong tab cài đặt.',
}
