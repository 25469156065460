import React from 'react'
import { Wizard } from 'app-view/components/Wizard'
import { useStore } from 'app-engine/store'

export const OpenOfferFilterWizard = () => {
  const { p2pOpenFilterError, setP2POpenFilterError } = useStore()

  return (
    <Wizard
      title="Open Offer Filter"
      iconType="FILTER"
      error={p2pOpenFilterError}
      resetError={() => {
        setP2POpenFilterError('')
      }}
    />
  )
}
