import styled from '@emotion/styled'
import theme from 'app-config/theme'
import { globalCurrencies } from 'app-engine/library/currency-constants'
import { useStore } from 'app-engine/store'
import {
  Fieldset,
  HintError,
  Input,
  InputSection,
  InputWrapper,
  Label,
} from 'app-view/components/InputField'
import Spinner from 'app-view/components/Spinner'
import { FlexBox } from 'app-view/components/Styled'
import { Wizard, useWizard } from 'app-view/components/Wizard'
import { GreenButton } from 'app-view/components/buttons'
import { TakePhotoIcon } from 'app-view/components/icons'
import { useSelfie } from 'app-view/hooks/use-selfie'
import { FieldStateProps } from 'app-view/hooks/useKYCForm'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect } from 'react-use'
import { KeySelect, OptionKeySelect } from './KeySelect'
import { TAKE_SELFIE } from './step-for-upload-selfie'
import { DownloadLinkButton } from './step-for-upload-selfie/components/DownloadLinkButton'
import { CameraImageFrame } from './step-for-upload-selfie/components/Styles'

export const ContainerSelect = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  .react-select__control {
    height: 100%;
    padding-right: 54%;
  }
`
export const InputStyled = styled(Input)`
  position: absolute !important;
  z-index: 3;
  height: 55px;
  padding: 0 15px;
  width: 54%;
  right: 0;
  border-radius: 0 9px 0px 0;
  border-width: 0 0 0 1px !important;
  border-style: solid;
  border-color: ${(props: { extraerror: string }) =>
    props.extraerror && props.extraerror === 'true' ? theme.colors.red : 'transparent'};
`
export const PhotoWrapper = styled.div<{ error: boolean; selfie: boolean }>`
  width: 100%;
  height: ${({ selfie }) => (selfie ? 'auto' : '76px')};
  background-color: white;
  border-radius: 9px;
  border-width: 1px !important;
  border-style: solid;
  border-color: ${({ error }) => (error ? theme.colors.red : 'transparent')};
  padding: ${({ selfie }) => (selfie ? '0' : '6px 20px')};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

interface AccountFormFieldsProps {
  register: any // Replace with your form register function type
  formState: any // Replace with your form state type
  checkCountryIdState: any
  checkEmailState: FieldStateProps
  validPhoneNumberState: any // Replace with your valid phone number state type
  handleCountryIdChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  verifyEmailAddress: (event: React.ChangeEvent<HTMLInputElement>) => void
  formatUserPhoneNumber: (event: React.ChangeEvent<HTMLInputElement>) => void
  setFullNameValue: (event: React.ChangeEvent<HTMLInputElement>) => void
  setIdType: (value: string) => void
  setCountry: (value: string) => void
  phoneNumberCountryCode: string
  formattedValue: string
  phoneValue: string
  emailValue: string
  fullNameValue: string
  countryIdValue: string
  country: string
  idType: string
  selfie: string
  photoId: string
  setSelfie: (selfie: string) => void
  setPhotoId: (photoId: string) => void
}

export const CountryIdTypes = [
  { label: 'National ID', value: 'national_id' },
  { label: 'License Number', value: 'license_number' },
  { label: 'Passport', value: 'passport' },
]

export const baseCameraImageFrameStyles = {
  border: 'none',
  width: '165px',
  height: '165px',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
}

export const AccountFormFields: React.FC<AccountFormFieldsProps> = ({
  register,
  formState,
  checkCountryIdState,
  validPhoneNumberState,
  handleCountryIdChange,
  setFullNameValue,
  verifyEmailAddress,
  formatUserPhoneNumber,
  setIdType,
  setCountry,
  phoneNumberCountryCode,
  formattedValue,
  phoneValue,
  emailValue,
  fullNameValue,
  countryIdValue,
  selfie,
  photoId,
  setSelfie,
  checkEmailState,
  setPhotoId,
}) => {
  const { t } = useTranslation(['account', 'p2p', 'settings'])

  const { userCountryData } = useStore()
  const [selectType, setSelectType] = useState<OptionKeySelect>(CountryIdTypes[0])
  const [selectCountry, setSelectCountry] = useState<OptionKeySelect>()
  const [countries, setCountries] = useState<OptionKeySelect[]>()
  const [{ open }, { start }] = useWizard()
  const {
    error,
    setError,
    selfie: preSelfie,
    downloadData,
    setDownloadData,
    idCard,
    downloadPhotoIDData,
    setDownloadPhotoIDData,
  } = useSelfie()

  useEffect(() => {
    if (preSelfie && selfie !== null) {
      console.log('preSelfie', Boolean(preSelfie))
      setSelfie(preSelfie)
    }
    if (idCard && photoId !== null) {
      console.log('idCard', Boolean(idCard))
      setPhotoId(idCard)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelfie, idCard])

  useDeepCompareEffect(() => {
    const keySelections = globalCurrencies.map((currency) => {
      const keySelection = {
        icon: currency.flag,
        label: currency.commonName,
        value: currency.commonName,
      }
      if (currency.commonName === userCountryData?.country_name?.toLowerCase()) {
        setSelectCountry(keySelection)
        setCountry(currency.commonName.replace(/\s/g, '-'))
      }
      return keySelection
    })
    setCountries(keySelections)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCountryData])

  const reTakeSelfie = () => {
    if (selfie === null) {
      setDownloadData('')
      setSelfie('')
    }
    if (photoId === null) {
      setDownloadPhotoIDData('')
      setPhotoId('')
    }
    start(TAKE_SELFIE)
  }

  return (
    <Fieldset>
      <legend>{t('account_details')}</legend>
      <InputWrapper>
        <Label size="lg" pl={0} htmlFor="countryId">
          {t('country_id_label')} *
        </Label>
        <InputSection
          error={Boolean(
            countryIdValue &&
              !formState.isValidating &&
              formState.errors.countryId?.message &&
              !checkCountryIdState.valid,
          )}
        >
          <ContainerSelect>
            <KeySelect
              options={CountryIdTypes}
              onChange={(data) => {
                setSelectType(data)
                setIdType(data.value)
              }}
              value={selectType}
            />
            <InputStyled
              {...register('countryId')}
              id="countryId"
              onChange={handleCountryIdChange}
              placeholder="xxxxxxxxxxxxxxxxxxx"
              value={countryIdValue}
              fullradius={1}
              fontWeight="500"
              type="text"
              extraerror={Boolean(
                countryIdValue &&
                  !formState.isValidating &&
                  formState.errors.countryId?.message &&
                  !checkCountryIdState.valid,
              ).toString()}
              paddingspace="none"
            />
          </ContainerSelect>
          {checkCountryIdState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {!formState.isValidating &&
          countryIdValue &&
          (formState.errors.countryId?.message || checkCountryIdState.valid) && (
            <HintError
              align="left"
              error={Boolean(
                countryIdValue &&
                  !formState.isValidating &&
                  formState.errors.countryId?.message &&
                  !checkCountryIdState.valid,
              )}
            >
              {formState.errors.countryId?.message ||
                (!checkCountryIdState.loading && t('country_id_valid'))}
            </HintError>
          )}
      </InputWrapper>
      <InputWrapper>
        <Label size="lg" pl={0}>
          {t('settings:country')} *
        </Label>
        <InputSection>
          <KeySelect
            options={countries}
            onChange={(data) => {
              setSelectCountry(data)
              setCountry(data.value.toLowerCase().replace(/\s/g, '-'))
            }}
            value={selectCountry}
          />
        </InputSection>
      </InputWrapper>

      <InputWrapper>
        <Label size="lg" pl={0} htmlFor="fullName">
          {t('p2p:full_name_label')}:
        </Label>
        <InputSection
          error={Boolean(
            fullNameValue && !formState.isValidating && formState.errors.fullName?.message,
          )}
        >
          <Input
            {...register('fullName')}
            id="fullName"
            value={fullNameValue}
            onChange={setFullNameValue}
            placeholder="Jane Doe"
            type="text"
            fullradius={1}
            fontWeight="500"
          />
        </InputSection>
        {fullNameValue && !formState.isValidating && formState.errors.fullName?.message && (
          <HintError align="left">{formState.errors.fullName?.message}</HintError>
        )}
      </InputWrapper>
      <InputWrapper>
        <Label size="lg" pl={0} htmlFor="phoneNumber">
          {t('phone_number_label')} *
        </Label>
        <InputSection
          error={Boolean(
            phoneValue && !formState.isValidating && formState.errors.phoneNumber?.message,
          )}
        >
          <Input
            {...register('phoneNumber')}
            id="phoneNumber"
            onChange={formatUserPhoneNumber}
            placeholder={t('phone_number_placeholder', {
              phoneNumberCountryCode: phoneNumberCountryCode ? `(${phoneNumberCountryCode}) ` : '',
            })}
            value={formattedValue}
            fullradius={1}
            fontWeight="500"
            type="tel"
          />
          {validPhoneNumberState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {!phoneNumberCountryCode &&
          !phoneValue &&
          (!formState.errors.phoneNumber?.message || !validPhoneNumberState.valid) && (
            <HintError align="left">{t('include_country_code')}</HintError>
          )}
        {!formState.isValidating &&
          phoneValue &&
          (formState.errors.phoneNumber?.message || validPhoneNumberState.valid) && (
            <HintError
              error={Boolean(
                phoneValue && !formState.isValidating && formState.errors.phoneNumber?.message,
              )}
              align="left"
            >
              {formState.errors.phoneNumber?.message || t('phone_valid')}
            </HintError>
          )}
      </InputWrapper>
      <InputWrapper mb={32} mt={32}>
        <Label size="lg" pl={0} htmlFor="emailAddress">
          {t('email_address_label')} *
        </Label>
        <InputSection
          inputSize="sm"
          error={Boolean(
            !formState.isValidating && emailValue && formState.errors.emailAddress?.message,
          )}
        >
          <Input
            {...register('emailAddress')}
            id="emailAddress"
            onChange={verifyEmailAddress}
            placeholder="example@email.com"
            inputMode="email"
            fullradius={1}
            fontWeight="500"
          />
          {checkEmailState.loading && <Spinner boxSize={20} />}
        </InputSection>
        {!formState.isValidating &&
          emailValue &&
          (formState.errors.emailAddress?.message || checkEmailState.valid) && (
            <HintError
              error={Boolean(!formState.isValidating && formState.errors.emailAddress?.message)}
              align="left"
            >
              {formState.errors.emailAddress?.message || t('account:email_valid')}
            </HintError>
          )}
      </InputWrapper>
      <InputWrapper>
        <Label size="lg" pl={0} htmlFor="selfie-photo-id">
          {t('selfie_label')} *
        </Label>
        <PhotoWrapper
          id="selfie-photo-id"
          error={Boolean(
            !formState.isValidating &&
              ((selfie !== null && !selfie) || (photoId !== null && !photoId)) &&
              !open,
          )}
          selfie={Boolean(selfie && photoId)}
          style={{ flexDirection: selfie && photoId ? 'column' : 'row' }}
        >
          {selfie && photoId ? (
            <FlexBox style={{ width: '100%' }}>
              <CameraImageFrame
                selfieStatus
                style={{
                  ...baseCameraImageFrameStyles,
                  borderTopRightRadius: '0',
                }}
                src={selfie}
              />
              <CameraImageFrame
                selfieStatus
                style={{
                  ...baseCameraImageFrameStyles,
                  borderTopLeftRadius: '0',
                }}
                src={photoId}
              />
            </FlexBox>
          ) : (
            <TakePhotoIcon style={{ height: '46px', width: '46px' }} />
          )}
          <FlexBox
            flexDirection="column"
            alignItems="center"
            style={{ width: 'fit-content', paddingRight: selfie ? '20px' : 0 }}
          >
            <GreenButton
              onClick={reTakeSelfie}
              type="button"
              style={{
                fontSize: '17px',
                padding: '8px 12px 8px 12px',
                minHeight: '33px',
                marginTop: downloadData ? '10px' : '',
              }}
            >
              {downloadData ? t('retake_photos') : t('take_selfie_id')}
            </GreenButton>
            {downloadData && downloadPhotoIDData ? (
              <DownloadLinkButton
                style={{ border: 'none', marginTop: 0, padding: 0 }}
                downloadLink={downloadData}
                downloadLink2={downloadPhotoIDData}
              />
            ) : (
              ''
            )}
          </FlexBox>
        </PhotoWrapper>
        {Boolean(
          !formState.isValidating &&
            ((selfie !== null && !selfie) || (photoId !== null && !photoId)) &&
            !open,
        ) && (
          <HintError error align="left">
            {t('photo_invalid')}
          </HintError>
        )}
      </InputWrapper>
      <InputWrapper mb={32} mt={32}>
        <InputSection inputSize="sm">
          <Label
            htmlFor="newsletterSubscription"
            mb={0}
            style={{ fontWeight: 600, textAlign: 'right', paddingInlineEnd: 10 }}
          >
            <input
              {...register('newsletterSubscription')}
              type="checkbox"
              style={{ height: 24, width: 24, marginRight: 24 }}
            />
            {t('subscribe_to_newsletter')}
          </Label>
        </InputSection>
      </InputWrapper>

      <Wizard
        error={error}
        resetError={() => {
          setError('')
        }}
      />
    </Fieldset>
  )
}
